import { styled, Typography } from "@mui/material";
import { BoxProps, Box } from "@mui/system";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { UsePortfolioGroupSummary } from "./usePortfolioGroupSummary";
import { CarPortfolioAssetTableData } from "../usePortfolioGroupVersionData";
import { Pie } from "react-chartjs-2";
import { CarSwitch } from "components/Switch";
import { CarIconDelete, CarIconRename } from "icons";
import { CarMenuButton } from "components/MenuButton";
import {
  formatDate,
  formatPercent,
  formatPercentFactor,
  isDefined,
  isOddEven,
} from "utils";
import { CarTooltip } from "components/Tooltip";
import { Fragment, useState } from "react";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

const StyledChart = styled("div")(({ theme }) => ({
  border: `solid 2px ${theme.palette.gray3}`,
  backgroundColor: theme.palette.gray1,
  borderRadius: "5px",
  padding: theme.spacing(2.5),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ".container": {
    display: "flex",
    columnGap: theme.spacing(8.5),
    rowGap: theme.spacing(3.5),
    flexWrap: "wrap",
    justifyContent: "center",
    maxWidth: 520,
    "& > *": {
      cursor: "pointer",
      flex: "none",
      width: 128,
      height: 128,
      border: `solid 2px`,
      borderColor: theme.palette.gray1,

      borderRadius: "64px",
      backgroundColor: theme.palette.white,
      display: "flex",
      flexDirection: "column",
      "&.selected": {
        borderColor: theme.palette.caravelOrangePrimary,
        backgroundColor: theme.palette.lightOrange,
      },
      ".chart": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
}));

const chartSize = 124;

interface HintValue {
  label: string;
  value: number;
}

const VersionChart = ({
  sx,
  tableData,
  selectedModuleId,
  onSelectedModuleIdChange,
}: {
  sx: BoxProps["sx"];
  selectedModuleId: string;
  onSelectedModuleIdChange: (value: string) => void;
  tableData: CarPortfolioAssetTableData;
}) => {
  const [hintValue, setHintValue] = useState<HintValue | undefined>();

  const plainData = tableData.assetRows.level1;

  return (
    <StyledChart sx={sx}>
      <div className="container">
        {tableData.bands
          .filter((i) => !i.isCash)
          // .filter((i) =>
          //   i.columns.some(
          //     (c) => c.columnId === CarPortfolioAssetColumnEnum.existing,
          //   ),
          // )
          .map((bd, idx) => {
            const labels = plainData.map((i) => i.name);
            const values = plainData.map(
              (i) =>
                i.modules.find((md) => md.moduleId === bd.moduleId)?.values?.[0]
                  ?.allocation ?? 0,
            );

            const id = `Module_${bd.moduleId}`;

            return (
              <div
                key={bd.moduleId}
                id={id}
                onClick={(e) => {
                  onSelectedModuleIdChange(bd.moduleId);
                }}
                className={bd.moduleId === selectedModuleId ? "selected" : ""}
              >
                <div className="chart">
                  <CarTooltip
                    title={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Typography variant="par02SemiBold">
                          {bd.label}
                        </Typography>
                        <Typography key={idx} variant="par01Regular">
                          {labels
                            .map(
                              (label, idx) =>
                                `${label} ${formatPercent(values[idx] * 100)}`,
                            )
                            .join("\n")}
                        </Typography>
                      </Box>
                    }
                    arrow={true}
                    preWrap
                  >
                    <div>
                      <Pie
                        width={chartSize}
                        height={chartSize}
                        options={{
                          responsive: false,
                          // animation: false,
                          layout: {
                            // padding: -10,
                          },
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                          onHover(event, elements, chart) {
                            const index = elements[0]?.index;

                            const newValue: HintValue | undefined = isDefined(
                              index,
                            )
                              ? {
                                  label: labels[index],
                                  value: values[index],
                                }
                              : undefined;

                            if (
                              newValue?.label !== hintValue?.label ||
                              newValue?.value !== hintValue?.value
                            )
                              setHintValue(newValue);
                          },
                        }}
                        data={{
                          labels,
                          datasets: [
                            {
                              label: "data",
                              data: values,
                              borderWidth: 0,
                              backgroundColor: plainData.map((i) => i.color),
                            },
                          ],
                        }}
                      />
                    </div>
                  </CarTooltip>
                </div>
              </div>
            );
          })}
      </div>
    </StyledChart>
  );
};

const VersionTable = ({
  sx,
  tableData,
}: {
  sx?: BoxProps["sx"];
  tableData: CarPortfolioAssetTableData;
}) => {
  const plainData = tableData.assetRows.level1;
  const bands = tableData.bands.filter((i) => !i.isCash);

  return (
    <CarInfoTable
      sx={{ gridTemplateColumns: `1.5fr repeat(${bands.length}, 1fr)`, ...sx }}
    >
      <CarInfoHeader text="" />
      {bands.map((b) => (
        <CarInfoHeader key={b.moduleId} text={b.label} />
      ))}
      {plainData.map((i, idx) => (
        <Fragment key={i.id}>
          <CarInfoCell
            sx={{ fontSize: "14px" }}
            isOdd={isOddEven(idx)}
            justifyContent="start"
          >
            <Box sx={{ display: "flex", gap: 1.75, alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "4px",
                  border: "1px solid",
                  borderColor: "gray6",
                  backgroundColor: i.color,
                  flexShrink: 0,
                }}
              />
              {i.name}
            </Box>
          </CarInfoCell>
          {i.modules
            .filter((m) => !m.isCash)
            .map((m) => (
              <CarInfoCell
                key={m.moduleId}
                sx={{ fontSize: "14px" }}
                isOdd={isOddEven(idx)}
                text={formatPercentFactor(m.values[0]?.allocation ?? 0, 1)}
              />
            ))}
        </Fragment>
      ))}
    </CarInfoTable>
  );
};

export interface PortfolioGroupSummaryProps {
  portfolioGroupSummary: UsePortfolioGroupSummary;
  sx?: BoxProps["sx"];
}

export const PortfolioGroupSummary = ({
  portfolioGroupSummary,
  sx,
}: PortfolioGroupSummaryProps) => {
  return (
    <RootPageBox
      sx={{
        p: 3.5,
        ...sx,
      }}
    >
      <TablePlaceHolder isLoading={portfolioGroupSummary.isLoading} rows={7}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h3SSemiBold">
            {portfolioGroupSummary.title}
          </Typography>
          {!portfolioGroupSummary.isEmpty && (
            <CarMenuButton
              items={[
                {
                  icon: <CarIconRename />,
                  label: "Rename",
                  onClick: portfolioGroupSummary.handleRename,
                },
                {
                  icon: <CarIconDelete />,
                  label: "Delete",
                  onClick: portfolioGroupSummary.handleDelete,
                },
              ]}
            />
          )}
          {!portfolioGroupSummary.isEmpty && (
            <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
              <Typography variant="par01Regular">
                Activate portfolio:
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.7 }}
                  className={
                    pendoClasses.assetAllocationDashboardActivatePortfolio
                  }
                />
              </Typography>
              <CarSwitch
                checked={portfolioGroupSummary.isActive}
                onChange={(e) =>
                  portfolioGroupSummary.handleActiveChange(e.target.checked)
                }
                size="small"
              />
            </Box>
          )}
        </Box>
        <Typography variant="mediumItalic" sx={{ mt: 1, height: 14 }}>
          {portfolioGroupSummary.activeDate
            ? `Became active on ${formatDate(portfolioGroupSummary.activeDate)}`
            : ""}
        </Typography>
        <VersionChart
          sx={{ mt: 2.5 }}
          tableData={portfolioGroupSummary.tableData}
          selectedModuleId={portfolioGroupSummary.selectedModuleId}
          onSelectedModuleIdChange={portfolioGroupSummary.setSelectedModuleId}
        />
        <VersionTable
          sx={{ flex: "auto", mt: 3 }}
          tableData={portfolioGroupSummary.tableData}
        />
      </TablePlaceHolder>
    </RootPageBox>
  );
};
