import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfHeading } from "./PdfHeading";
import { CarPdfMarkdown } from "components/PdfMarkdown";

export interface DisclosuresPdfPageProps {
  disclosures: UseDisclosures;
  pageProps: CarPdfPageProps;
}

export const DisclosuresPdfPage = (props: DisclosuresPdfPageProps) => (
  <CarPdfPage {...props.pageProps}>
    <CarPdfHeading label="Disclosures" style={{ marginBottom: 14 }} />
    <CarPdfMarkdown>
      {props.disclosures.getContent(
        CarDisclosureContentCode.PLANNING_COMPREHENSIVE_DISCLOSURE_MAIN_part_10,
      )}
    </CarPdfMarkdown>
  </CarPdfPage>
);
