import { Box, Typography } from "@mui/material";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { CarDonut } from "components/Donut";
import { formatNumber } from "utils";
import { usePlanTypes } from "app/usePlanTypes";
import { usePlanTypeId } from "app/usePlanId";
import { PlanResultsGoalSummaryTable } from "./PlanResultsGoalSummaryTable";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

export const PlanResultsCardSummaryCompactPlaceHolder = (props: {
  isLoading?: boolean;
}) => {
  return (
    <Box
      sx={{
        mt: 4,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      <TablePlaceHolder isLoading={props.isLoading} rows={8} sx={{ mx: 6 }}>
        <Box
          sx={{
            mx: 6,
            display: "flex",
            // alignItems: "center",
            // justifyContent: "center",
            gap: 16,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="h5SSemiBold">
              {`Published Plan: `}
              <Box component="span" sx={{ color: "caravelOrangePrimary" }}>
                -
              </Box>
            </Typography>
            <Typography variant="mediumItalic" sx={{ mt: 1, fontSize: "11px" }}>
              No information at this time
            </Typography>
          </Box>
        </Box>
      </TablePlaceHolder>
    </Box>
  );
};

interface PlanResultsCardSummaryProps {
  data: UsePlanResults;
}

export const PlanResultsCardSummaryCompact = ({
  data,
}: PlanResultsCardSummaryProps) => {
  const { items } = usePlanTypes();
  const planTypeId = usePlanTypeId();

  return (
    <Box
      sx={{
        mt: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          ml: 6,
          display: "flex",
          // alignItems: "center",
          // justifyContent: "center",
          gap: 16,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="h5SSemiBold">
            {`Published Plan: `}
            <Box component="span" sx={{ color: "caravelOrangePrimary" }}>
              {items.find((i) => i.id === planTypeId)?.label}
            </Box>
          </Typography>
          <Typography variant="mediumItalic" sx={{ mt: 1, fontSize: "11px" }}>
            Published on TODO
          </Typography>
          <Box sx={{ mt: 4, display: "flex", gap: 20 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="dis02SBold">
                {formatNumber(data.donuts.score)}
                <Typography
                  component="span"
                  variant="h1Bold"
                  sx={{ color: "gray6", ml: 0.5 }}
                >
                  %
                </Typography>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 0.25,
                }}
              >
                <Typography variant="subhRegular">Plan Score</Typography>
                <CarTooltipBox
                  className={pendoClasses.workbenchPlanResultsPlanScore}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="dis02SBold">
                {`${data.donuts.total.successCount}`}
                <Typography
                  component="span"
                  variant="h1Bold"
                  sx={{ color: "gray6", ml: 0.5 }}
                >
                  {`/${data.donuts.total.totalCount}`}
                </Typography>
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 0.25,
                }}
              >
                <Typography variant="subhRegular">Pass/Total</Typography>
                <CarTooltipBox
                  className={pendoClasses.workbenchPlanResultsPassTotal}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <CarDonut
          needPercent={data.donuts.needs.percentage}
          wantPercent={data.donuts.wants.percentage}
          dreamPercent={data.donuts.dreams.percentage}
        />
      </Box>

      <PlanResultsGoalSummaryTable
        sx={{
          mt: 5,
          mx: 6,
          rowGap: 2,
          "& .MuiTypography-caption": {
            fontSize: "12px",
          },
          "& .MuiTypography-par03SemiBold": {
            fontSize: "14px",
          },
        }}
        data={data}
      />
    </Box>
  );
};
