import { CarAccount } from "types";
import { CarAccountTypeField } from "components/AccountType";
import {
  CarManualAccountKindField,
  CarTextFieldDelayed,
  CarYesNoField,
} from "components/Inputs";
import { CarTableColumn } from "components/Table";
import { AccountType } from "const";
import { addAnnualSavingsIfNeeded } from "features/assets/account.utils";
import { CarCurrencyFieldDelayed } from "components/NumberField";
import { CarRelationshipField } from "components/RelationshipField";

type ManualAccountColumnKey =
  | "manualType"
  | "owner"
  | "description"
  | "accountType"
  | "savings"
  | "inputFormat"
  | "marketValue";

export const columns: Record<
  ManualAccountColumnKey,
  CarTableColumn<CarAccount>
> = {
  manualType: {
    id: "account_ui_input_type",
    label: "*Manual Type",
    fraction: 1.5,
    renderCell: ({ item, onChange }) => {
      return (
        <CarManualAccountKindField
          required
          value={item.account_ui_input_type}
          onChange={(account_ui_input_type) =>
            onChange({ ...item, account_ui_input_type })
          }
        />
      );
    },
  },
  owner: {
    id: "relationship_type",
    label: "*Owner",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarRelationshipField
          required
          value={item.relationship_type}
          onChange={(relationship_type) =>
            onChange({ ...item, relationship_type })
          }
        />
      );
    },
  },
  description: {
    id: "description",
    label: "*Description",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarTextFieldDelayed
          required
          value={item.description}
          onChange={(description) => onChange({ ...item, description })}
        />
      );
    },
  },
  accountType: {
    id: "account_type",
    label: "*Account Type",
    fraction: 1,
    renderCell: ({ item, onChange }) => (
      <CarAccountTypeField
        required
        value={item.account_type}
        onChange={(account_type) => onChange({ ...item, account_type })}
      />
    ),
  },
  savings: {
    id: "is_saving",
    label: "*Savings",
    fraction: 1,
    renderCell: ({ item, onChange }) => (
      <CarYesNoField
        value={item.is_saving}
        onChange={(is_saving) =>
          onChange(addAnnualSavingsIfNeeded({ ...item, is_saving }))
        }
      />
    ),
  },
  inputFormat: {
    id: "account_input_type",
    label: "Input Format",
    fraction: 1,
    renderCell: ({ item, onChange }) => (
      <CarYesNoField
        yesLabel="%"
        noLabel="$"
        value={item.account_input_type === AccountType.ManualPercent}
        onChange={(isPercent) =>
          onChange({
            ...item,
            account_input_type: isPercent
              ? AccountType.ManualPercent
              : AccountType.ManualDollar,
          })
        }
      />
    ),
  },
  marketValue: {
    id: "manual_percent_total_market_value",
    label: "*Market Value",
    fraction: 1,
    renderCell: ({ item, onChange }) => (
      <CarCurrencyFieldDelayed
        required
        removeZeroOnFocus
        value={item.manual_percent_total_market_value}
        onChange={(manual_percent_total_market_value) =>
          onChange({
            ...item,
            manual_percent_total_market_value,
          })
        }
      />
    ),
  },
};
