import { Box, styled, Typography } from "@mui/material";
import { memo } from "react";
import { formatCurrency, formatNumber, formatPercentFactor } from "utils";
import { IntegratorAccountHoldingsItem } from "./IntegratorAccountHoldingsDialog";

const StyledRowRoot = styled(Box)({
  alignSelf: "stretch",
  display: "flex",
  flexDirection: "column",
});

export const IntegratorAccountHoldingsDialogRow = memo(
  ({ item }: { item: IntegratorAccountHoldingsItem }) => {
    return (
      <StyledRowRoot>
        <Box
          sx={{
            height: 80,
            backgroundColor: "gray3",
            border: "1px solid",
            borderColor: "border",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
            alignItems: "center",
            justifyContent: "flex-start",
            px: 3.5,
          }}
        >
          <Typography variant="par02SemiBold">Ticker/CUSIP</Typography>
          <Typography variant="par02SemiBold">Description</Typography>
          <Typography variant="par02SemiBold">Shares</Typography>
          <Typography variant="par02SemiBold">Cost Basis</Typography>
          <Typography variant="par02SemiBold">Market Price</Typography>
          <Typography variant="par02SemiBold">Market Value</Typography>
        </Box>
        <Box
          sx={{
            height: 60,
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
            alignItems: "center",
            justifyContent: "flex-start",
            border: "1px solid",
            borderTopWidth: 0,
            borderColor: "border",
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: item.assetClasses.length
              ? undefined
              : "5px",
            backgroundColor: "gray1",
            px: 3.5,
            ".MuiTypography-root": {
              color: "black",
            },
          }}
        >
          <Typography variant="par02Regular">{item.tickerCusip}</Typography>
          <Typography variant="par02Regular">
            {item.description || "-"}
          </Typography>
          <Typography variant="par02Regular">
            {item.shares ? formatNumber(item.shares) : "-"}
          </Typography>
          <Typography variant="par02Regular">
            {item.costBasis ? formatCurrency(item.costBasis) : "-"}
          </Typography>
          <Typography variant="par02Regular">
            {formatCurrency(item.marketPrice)}
          </Typography>
          <Typography variant="par02Regular">
            {item.marketValue ? formatCurrency(item.marketValue) : "-"}
          </Typography>
        </Box>

        {item.assetClasses.length > 0 && (
          <Box
            sx={{
              width: 480,
              alignSelf: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                height: 60,
                backgroundColor: "gray3",
                border: "1px solid",
                borderTopWidth: 0,
                borderColor: "border",
                display: "grid",
                gridTemplateColumns: "4fr 1fr",
                gap: 1,
                alignItems: "center",
                justifyContent: "flex-start",
                px: 3.5,
              }}
            >
              <Typography variant="par02SemiBold">Asset Class</Typography>
              <Typography variant="par02SemiBold">Weighting</Typography>
            </Box>
            {item.assetClasses.map((ac) => (
              <Box
                key={ac.id}
                sx={{
                  height: 60,
                  display: "grid",
                  gridTemplateColumns: "4fr 1fr",
                  gap: 1,
                  alignItems: "center",
                  justifyContent: "flex-start",
                  border: "1px solid",
                  borderTopWidth: 0,
                  borderColor: "border",
                  "&:last-of-type": {
                    borderBottomLeftRadius: "5px",
                    borderBottomRightRadius: "5px",
                  },
                  backgroundColor: "gray1",
                  px: 3.5,
                  ".MuiTypography-root": {
                    color: "black",
                  },
                }}
              >
                <Typography variant="par02Regular">{ac.name}</Typography>
                <Typography variant="par02Regular">
                  {formatPercentFactor(ac.percentage)}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </StyledRowRoot>
    );
  },
);
