import { listenerMiddleware } from "./listenerMiddleware";
import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { api } from "api/carApi.manual";
import { errorMiddleware } from "api/errorMiddleware";
import { pendingSliceReducer } from "features/general/pendingSlice";
import { toastReducer } from "./toastSlice";
import { controlPanelSliceReducer } from "./controlPanelSlice";
import { authReducer } from "features/auth/authSlice";
import { drawerReducer } from "./drawerSlice";
import { dialogReducer } from "./dialogSlice";
import { registerDispatch } from "api/getAccessToken";
import { settingsSliceReducer } from "features/general/settingsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    pending: pendingSliceReducer,
    api: api.reducer,
    toast: toastReducer,
    controlPanel: controlPanelSliceReducer,
    drawer: drawerReducer,
    dialog: dialogReducer,
    settings: settingsSliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware, errorMiddleware, listenerMiddleware.middleware),
});

setupListeners(store.dispatch);

registerDispatch(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
