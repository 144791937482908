import { MenuItem } from "@mui/material";
import { CarSelectField, CarSelectFieldProps } from "./Inputs";
import { usePlanTypes } from "app/usePlanTypes";
import { useNavigate } from "react-router-dom";
import { useClientId, usePlanId, usePlanTypeId } from "app/usePlanId";
import { financialPlanningPages } from "route.const";

export const CarPlanTypeFieldField = (
  props: Omit<CarSelectFieldProps, "value" | "onChange">
) => {
  const { items } = usePlanTypes();
  const navigate = useNavigate();
  const clientId = useClientId();
  const planId = usePlanId();
  const planTypeId = usePlanTypeId();
  return (
    <CarSelectField
      {...props}
      value={planTypeId}
      onChange={(v) => {
        if (v) {
          navigate(financialPlanningPages.getWorkbenchUrl(clientId, planId, v));
        }
      }}
    >
      {items.map((i) => (
        <MenuItem key={i.id} value={i.id}>
          {i.label}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};
