import {
  RootPage,
  RootPageHeader,
  RootPageTransparentPaper,
  RootPageHeaderLink,
} from "components/RootPage";
import { assetClassAnalysisPages } from "route.const";
import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarRoundedPageControl } from "components/RoundedPageControl";
import { useState } from "react";
import { useAssetClassSummaryStat } from "./useAssetClassSummaryStat";
import { AssetClassAnalysisDashboardChart } from "./AssetClassAnalysisChart";
import {
  AssetClassAnalysisTable,
  getOverUndervaluedColumns,
} from "./AssetClassAnalysisTable";
import { pendoClasses } from "app/thirdParty/pendo";
import { SourceDataAsOf } from "pages/assetAllocation/SourceDataAsOf";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

export const AssetClassAnalysisDashboard = () => {
  const [pageId, setPageId] = useState<string | undefined>("overvalued");

  const data = useAssetClassSummaryStat({
    type: AssetClassLoadType.byLatestSimulationJob,
    filterByUsableInPortfolios: true,
  });

  const getContent = (isOvervalued: boolean) => {
    const contentRows = data.acSummaryItems.filter((i) =>
      isOvervalued ? (i.dft ?? 0) > 0 : (i.dft ?? 0) < 0,
    );
    return (
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            maxWidth: 600,
          }}
        >
          <AssetClassAnalysisDashboardChart
            isOvervalued={isOvervalued}
            isUndervalued={!isOvervalued}
            xStatId={"dft"}
            yStatId={"realPriceDrivenExpectedReturn"}
            items={contentRows}
          />

          <AssetClassAnalysisDashboardChart
            xStatId={"volatility"}
            yStatId={"realPriceDrivenExpectedReturn"}
            items={contentRows}
          />
        </Box>
        <AssetClassAnalysisTable
          sx={{ mt: 1.25 }}
          displayColumns={getOverUndervaluedColumns(true)}
          xStatId={"dft"}
          onXStatIdChange={() => null}
          yStatId={"realPriceDrivenExpectedReturn"}
          onYStatIdChange={() => null}
          items={contentRows}
        />
      </Box>
    );
  };

  return (
    <RootPage sx={{ backgroundColor: "gray1" }}>
      <RootPageHeader
        isSmall
        title="Asset Class Analysis"
        links={
          <>
            <RootPageHeaderLink
              label="Dashboard"
              url={assetClassAnalysisPages.dashboard}
              isActive // todo investigate why default behavior doesn't work
            />
            <RootPageHeaderLink
              label="Analyze"
              url={`../${assetClassAnalysisPages.analyzeUrl}`}
            />
          </>
        }
      />
      <RootPageTransparentPaper>
        <SourceDataAsOf />
        <TablePlaceHolder
          sx={{ mx: 7, mt: 10 }}
          isLoading={data.isLoading}
          rows={8}
        >
          <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
            Dashboard
          </Typography>
          <CarRoundedPageControl
            sx={{
              mt: 3,
            }}
            value={pageId}
            onChange={setPageId}
            items={[
              {
                value: "overvalued",
                label: "Overvalued",
                pendoClassName:
                  pendoClasses.assetClassAnalysisDashboardOvervalued,
                content: getContent(true),
              },
              {
                value: "undervalued",
                label: "Undervalued",
                pendoClassName:
                  pendoClasses.assetClassAnalysisDashboardUndervalued,
                content: getContent(false),
              },
            ]}
          />
        </TablePlaceHolder>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
