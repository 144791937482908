import { Navigate, Route, Routes } from "react-router-dom";
import { financialPlanningPages } from "route.const";
import { AddClientPage } from "./addClient/AddClientPage";
import { ClientListPage } from "./clientList/ClientListPage";
import { ClientSummaryPage } from "./clientSummary/ClientSummaryPage";
import { PlanInputPage } from "./planInput/PlanInputPage";
import { WorkbenchPage } from "./workbench/WorkbenchPage";
import { ProgressMonitoringPage } from "./progressMonitoring/ProgressMonitoringPage";

export const FinancialPlanningPage = () => {
  return (
    <Routes>
      <Route
        path={financialPlanningPages.clientListPath}
        element={<ClientListPage />}
      />
      <Route
        path={financialPlanningPages.addClientPath}
        element={<AddClientPage />}
      />

      <Route
        path={financialPlanningPages.clientSummaryPath}
        element={<ClientSummaryPage />}
      />
      <Route
        path={financialPlanningPages.planInputPath}
        element={<PlanInputPage />}
      />
      <Route
        path={financialPlanningPages.workbenchPath}
        element={<WorkbenchPage />}
      />
      <Route
        path={financialPlanningPages.progressMonitoringPath}
        element={<ProgressMonitoringPage />}
      />
      <Route
        path="*"
        element={
          <Navigate to={financialPlanningPages.clientListPath} replace />
        }
      />
    </Routes>
  );
};
