import { useMemo } from "react";
import {
  DecimalOptions,
  formatCurrency,
  formatNumber,
  formatPercentFactor,
  isDefined,
  isEvenOdd,
} from "utils";
import {
  writeFile,
  utils,
  WorkSheet,
  ColInfo,
  BookType,
  CellStyle,
  Range,
  CellStyleColor,
  BorderType,
} from "xlsx-js-style";

export enum ExcelDataFormat {
  general = "general",
  currency = "currency",
  percent = "percent",
  integer = "integer",
  float = "float",
  percentRounded = "percentRounded",
  boolean = "boolean",
}

export interface ExcelBand {
  label: string;
  span?: number;
}

export interface ExcelColumn {
  label: string;
  gridLabel?: string;
  format: ExcelDataFormat;
  decimalOptions?: DecimalOptions;
  charWidth?: number;
  fraction?: number | string;
  span?: number;
  pendoClassName?: string;
}
export interface ExcelTable {
  name: string;
  bands?: ExcelBand[];
  columns: ExcelColumn[];
  rows: unknown[][];
  onCustomizeWorkSheet?: (worksheet: WorkSheet) => void;
  onGetCellFormat?: (params: {
    rowIdx: number;
    colIdx: number;
    format: ExcelDataFormat;
  }) => ExcelDataFormat;
  onGetCellStyle?: (params: {
    rowIdx: number;
    colIdx: number;
    cellStyle: CellStyle;
  }) => CellStyle;
}

export const excelTableToGridTemplateColumns = (table: ExcelTable) =>
  table.columns
    .map(
      (i) =>
        `${
          isDefined(i.fraction)
            ? typeof i.fraction === "string"
              ? i.fraction
              : `${i.fraction}fr`
            : "1fr"
        }`,
    )
    .join(" ");

export const excelTableFormatValue = (value: unknown, column?: ExcelColumn) => {
  if (!isDefined(value)) {
    return "-";
  }

  switch (column?.format) {
    case ExcelDataFormat.currency:
      return formatCurrency(value as number, 0);
    case ExcelDataFormat.percent:
      return formatPercentFactor(value as number, column.decimalOptions ?? 1);
    case ExcelDataFormat.percentRounded:
      return formatPercentFactor(value as number, 0);
    case ExcelDataFormat.integer:
      return formatNumber(value as number, column.decimalOptions);
    case ExcelDataFormat.float:
      return formatNumber(value as number, column.decimalOptions);
    case ExcelDataFormat.boolean:
      return value ? "True" : "False";

    // ExcelDataFormat.general
    default:
      return String(value);
  }
};

const defaultCharWidth = 10;

const borderStyle: { color: CellStyleColor; style?: BorderType } = {
  color: { rgb: "888888" },
  style: "thin",
};

const getDecimalFormat = (decimalOptions?: DecimalOptions) => {
  if (!decimalOptions) {
    return "0.0";
  }
  const decimalChar = decimalOptions.forceShowDecimals ? "0" : "#";

  return `0.${new Array(decimalOptions.decimalPlaces)
    .fill(decimalChar)
    .join("")}`;
};

export const createExcelWorkSheet = (table: ExcelTable): WorkSheet => {
  const worksheet = utils.aoa_to_sheet(
    [
      table.bands?.map((i) => i.label),
      table.columns.map((i) => i.label),
      ...table.rows.map((row) => row.map((val) => (isDefined(val) ? val : ""))),
    ].filter(isDefined),
  );

  worksheet["!cols"] = table.columns.map<ColInfo>((col) => ({
    wch: col.charWidth ?? defaultCharWidth,
  }));

  const merges: Range[] = [];

  if (table.bands && table.bands.length !== table.columns.length) {
    throw new Error(
      `Table bands (${table.bands.length}) !== Table columns (${table.columns.length})`,
    );
  }

  table.bands?.forEach((band, idx) => {
    const cell = utils.encode_cell({ r: 0, c: idx });

    const headerCellStyle: CellStyle = {
      fill: {
        fgColor: { rgb: "DFE1E3" },
      },
      font: {
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        right: borderStyle,
        bottom: borderStyle,
      },
    };
    worksheet[cell].s = headerCellStyle;

    if ((band.span ?? 1) > 1) {
      merges.push({
        s: {
          r: 0,
          c: idx,
        },
        e: {
          r: 0,
          c: idx + (band.span ?? 1) - 1,
        },
      });
    }
  });

  if (merges.length) {
    worksheet["!merges"] = merges;
  }

  table.columns.forEach((col, idx) => {
    const cell = utils.encode_cell({ r: table.bands ? 1 : 0, c: idx });

    const headerCellStyle: CellStyle = {
      fill: {
        fgColor: { rgb: "DFE1E3" },
      },
      font: {
        bold: true,
      },
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: true,
      },
      border: {
        right: borderStyle,
      },
    };
    worksheet[cell].s = headerCellStyle;
  });

  for (let rowIdx = 0; rowIdx < table.rows.length; rowIdx++) {
    table.columns.forEach((col, colIdx) => {
      const cell = utils.encode_cell({
        r: rowIdx + (table.bands ? 2 : 1),
        c: colIdx,
      });

      let cellStyle: CellStyle = {
        fill: {
          fgColor: { rgb: isEvenOdd(rowIdx) ? "F0F3F6" : "FFFFFF" },
        },
        border: {
          right: borderStyle,
          bottom: rowIdx === table.rows.length - 1 ? borderStyle : undefined,
        },
      };

      if (table.onGetCellStyle) {
        cellStyle = table.onGetCellStyle({ rowIdx, colIdx, cellStyle });
      }

      if (!isDefined(worksheet[cell])) {
        console.error(`Cell ${cell} is not defined`);
      } else {
        worksheet[cell].s = cellStyle;

        // //  5 -- Currency,   0 decimal, black negative
        // 5:  '"$"#,##0_);\\("$"#,##0\\)',

        // //  6 -- Currency,   0 decimal, red   negative
        // 6:  '"$"#,##0_);[Red]\\("$"#,##0\\)',

        // //  7 -- Currency,   2 decimal, black negative
        // 7:  '"$"#,##0.00_);\\("$"#,##0.00\\)',

        // //  8 -- Currency,   2 decimal, red   negative
        // 8:  '"$"#,##0.00_);[Red]\\("$"#,##0.00\\)',

        const format =
          table.onGetCellFormat?.({ rowIdx, colIdx, format: col.format }) ??
          col.format;

        switch (format) {
          case ExcelDataFormat.currency:
            worksheet[cell].t = "n";
            worksheet[cell].z = '"$"#,##0_);\\("$"#,##0\\)'; //'"$"#,##0_);[Red]\\("$"#,##0\\)';
            break;

          case ExcelDataFormat.percent:
            worksheet[cell].t = "n";
            worksheet[cell].z = `${getDecimalFormat(col.decimalOptions)}%`;
            break;

          case ExcelDataFormat.percentRounded:
            worksheet[cell].t = "n";
            worksheet[cell].z = "0%";
            break;
          case ExcelDataFormat.integer:
            worksheet[cell].t = "n";
            break;
          case ExcelDataFormat.float:
            worksheet[cell].t = "n";
            worksheet[cell].z = getDecimalFormat(col.decimalOptions);
            break;

          default:
            break;
        }
      }
    });
  }

  table.onCustomizeWorkSheet?.(worksheet);

  return worksheet;
};

export const useExcelExport = () => {
  return useMemo(() => {
    const exportAllExcel = (params: {
      fileName?: string;
      filePrefix?: string;
      tables: ExcelTable[];
    }) => {
      const workbook = utils.book_new();

      const appendTable = (table: ExcelTable) => {
        utils.book_append_sheet(
          workbook,
          createExcelWorkSheet(table),
          table.name.slice(0, 31),
        );
      };

      params.tables.forEach((i) => appendTable(i));

      writeFile(
        workbook,
        params.fileName
          ? `${params.fileName}.xlsx`
          : `${params.filePrefix ?? ""} ${
              new Date().toISOString().split("T")[0]
            }.xlsx`.trim(),
        {
          bookType: "xlsx",
          compression: true,
        },
      );
    };

    const exportTable = (params: {
      filePrefix: string;
      table: ExcelTable;
      bookType: BookType;
    }) => {
      const workbook = utils.book_new();

      utils.book_append_sheet(
        workbook,
        createExcelWorkSheet(params.table),
        params.table.name,
      );

      writeFile(
        workbook,
        `${params.filePrefix} ${new Date().toISOString().split("T")[0]} - ${
          params.table.name
        }.${params.bookType}`,
        { bookType: params.bookType, compression: true },
      );
    };

    return {
      exportAllExcel,
      exportTable,
    };
  }, []);
};
