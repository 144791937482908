import { createContext, useContext, useMemo, useRef } from "react";
import {
  PlanResultRoot,
  useAlEngineRenderRetrieveQuery,
} from "api/carApi.generated";
import { refetchOnFocus } from "const";
import { usePlanTypes } from "./usePlanTypes";
import { useAppDispatch } from "./hooks";
import { useIsInflated, usePlanId, usePlanTypeId } from "./usePlanId";
import { PlanTypeCode } from "const";
import { api } from "api/carApi.manual";
import { useRenderJobStatus } from "./useRenderJobStatus";

interface RenderContextData {
  data?: PlanResultRoot;
  isLoading: boolean;
  isFetching: boolean;
}

const RenderContext = createContext<RenderContextData | undefined>(undefined);

export const RenderContextProvider = RenderContext.Provider; // for Client Progress Monitoring page

export const useRender = () => {
  const dispatch = useAppDispatch();
  const planId = usePlanId();
  const planTypes = usePlanTypes();
  const inflated = useIsInflated();

  const renderIsAfterRefetch = useRef<boolean>(false);

  const renderContextData = useContext(RenderContext);

  const jobStatus = useRenderJobStatus({
    skip: !!renderContextData,
  });

  const canRender = renderContextData ? true : jobStatus.canRender;
  const hasRenderError = renderContextData ? false : jobStatus.hasRenderError;

  const renderData = useAlEngineRenderRetrieveQuery(
    { id: planId },
    {
      refetchOnFocus,
      skip: !!renderContextData || !canRender || hasRenderError,
    },
  );

  const {
    data,
    isLoading: renderIsLoading,
    isFetching: renderIsFetching,
  } = renderContextData ?? renderData;

  if (canRender && !renderIsFetching) {
    renderIsAfterRefetch.current = false;
  }

  const isLoading =
    planTypes.isLoading ||
    renderIsLoading ||
    !canRender ||
    (renderIsAfterRefetch.current && renderIsFetching); // this cover case when ReCalc button pushed on workbench, the next render after ReCalc should be considered as "loading"

  const isFetching = renderIsFetching;

  const planTypeId = usePlanTypeId();

  const existingPlanId = planTypes.getPlanTypeIdByCode(PlanTypeCode.existing);
  const proposedPlanId = planTypes.getPlanTypeIdByCode(PlanTypeCode.proposed);
  const tradeTaxDeferId = planTypes.getPlanTypeIdByCode(PlanTypeCode.taxDefer);

  const selectedPlanLabel = planTypes.getPlanTypeLabel(planTypeId);
  const selectedPlanResultId = data?.results?.find(
    (i) => i.result_type === planTypeId,
  )?.id;

  return useMemo(() => {
    return {
      hasRenderError,
      id: data?.id,
      planResult: data?.results?.find((i) => i.result_type === planTypeId),
      existingResult: data?.results?.find(
        (i) => i.result_type === existingPlanId,
      ),
      proposedResult: data?.results?.find(
        (i) => i.result_type === proposedPlanId,
      ),
      tradeTaxDeferResult: data?.results?.find(
        (i) => i.result_type === tradeTaxDeferId,
      ),
      selectedPlanResultId,
      selectedPlanLabel,
      resultItems: data?.results ?? [],
      isLoading,
      isFetching,
      refetch: () => {
        dispatch(
          api.util.invalidateTags(["RenderJobStatus", "AlEngineRender"]),
        );
        renderIsAfterRefetch.current = true;
      },

      inflated,
    };
  }, [
    dispatch,
    data,
    isLoading,
    isFetching,
    planTypeId,
    selectedPlanResultId,
    selectedPlanLabel,
    existingPlanId,
    proposedPlanId,
    tradeTaxDeferId,
    inflated,
    hasRenderError,
  ]);
};
