import { Box } from "@mui/system";
import {
  RootPage,
  RootPageBox,
  RootPageHeader,
  RootPageHeaderLink,
  RootPageTransparentPaper,
} from "components/RootPage";
import { CarIconDelete, CarIconRename } from "icons";
import { CarMenuButton } from "components/MenuButton";
import { PlanParamsContextProvider, useClientId } from "app/usePlanId";
import { ProgressMonitoringStrategyAdjustment } from "./ProgressMonitoringStrategyAdjustment";
import { financialPlanningPages } from "route.const";
import { useClientUpdateLastReviewed } from "app/useClientUpdateLastReviewed";
import { Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { ProgressMonitoringGoalTimeline } from "./ProgressMonitoringGoalTimeline";
import { ProgressMonitoringAlerts } from "./ProgressMonitoringAlerts";
import { ProgressMonitoringProgressTowardsGoals } from "./ProgressMonitoringProgressTowardsGoals";
import { useProgressMonitoring } from "./useProgressMonitoring";
import { RenderContextProvider } from "app/useRender";

export const ProgressMonitoringPage = () => {
  const clientId = useClientId();
  useClientUpdateLastReviewed();
  const { clientSummary, isLoading, isFetching, render, chartResults } =
    useProgressMonitoring();

  return (
    <RootPage sx={{ paddingBottom: 0 }}>
      <RootPageHeader
        isSmall
        title={`The ${clientSummary.familyName} Family`}
        isLoading={clientSummary.isLoading}
        appTitle={clientSummary.clientFullName}
        titleActions={
          <CarMenuButton
            sx={{
              ml: 1,
              color: "white",
              svg: {
                fontSize: "28px",
              },
            }}
            items={[
              {
                label: "Rename",
                icon: <CarIconRename />,
                onClick: clientSummary.renameClient,
              },
              {
                label: "Delete",
                icon: <CarIconDelete />,
                onClick: clientSummary.deleteClient,
              },
            ]}
          />
        }
        links={
          <>
            <RootPageHeaderLink
              label="Client Summary"
              url={financialPlanningPages.getClientSummaryUrl(clientId)}
            />
            <RootPageHeaderLink
              label="Progress Monitoring"
              url={financialPlanningPages.getProgressMonitoringUrl(clientId)}
            />
          </>
        }
      />
      <RootPageTransparentPaper>
        <TablePlaceHolder
          isLoading={clientSummary.isLoading}
          sx={{ mt: 5 }}
          rows={8}
        >
          <Typography variant="h2SSemiBold" sx={{ mt: 3 }}>
            Progress Monitoring
          </Typography>
          {clientSummary.publishedPlanParams?.planId ? (
            <PlanParamsContextProvider
              value={
                clientSummary.publishedPlanParams
                  ? {
                      planId: clientSummary.publishedPlanParams.planId,
                      planTypeId: clientSummary.publishedPlanParams.planTypeId,
                      isInflated: undefined,
                    }
                  : undefined
              }
            >
              <RenderContextProvider
                value={{
                  isLoading,
                  isFetching,
                  data: render,
                }}
              >
                <Box
                  sx={{
                    mt: 3,
                    flex: "auto",
                    display: "grid",
                    gridTemplateColumns: "1fr 1.8fr",
                    gridTemplateRows: "min-content min-content",
                    columnGap: 5,
                    rowGap: 3.5,
                  }}
                >
                  <ProgressMonitoringStrategyAdjustment />
                  <ProgressMonitoringGoalTimeline />
                  <ProgressMonitoringAlerts />
                  <ProgressMonitoringProgressTowardsGoals
                    isLoading={isLoading}
                    data={chartResults}
                  />
                </Box>
              </RenderContextProvider>
            </PlanParamsContextProvider>
          ) : (
            <Box
              sx={{
                mt: 3,
                flex: "auto",
                display: "grid",
                gridTemplateColumns: "1fr",
                gridTemplateRows: "1fr",
              }}
            >
              <RootPageBox>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4SBold" sx={{ fontSize: 20 }}>
                    You haven’t published a plan yet
                  </Typography>
                </Box>
              </RootPageBox>
            </Box>
          )}
        </TablePlaceHolder>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
