import { Text } from "@react-pdf/renderer";
import { useFirm } from "pages/settings/useFirm";
import { PdfPage } from "components/Pdf";
import { CarPdfViewer } from "components/PdfViewer";
import { Theme, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  PageNumber,
  PageTitle,
  SectionHeader,
} from "../portfolioGroupAssetClassAnalysisPdfReport/PageElements";
import { SimulatedForwardProbabilitiesPdfChartAC } from "../portfolioGroupAssetClassAnalysisPdfReport/SimulatedForwardProbabilitiesPdfChart";
import { useSimulationSimulationJobLatestSimulationRetrieveQuery } from "api/carApi.generated";
import { useAssetClassExtendedStat } from "../../assetClassAnalysis/useAssetClassExtendedStat";
import { useSimulationGroups } from "../useSimulationGroups";
import { CarPdfDocument } from "components/PdfDocument";
import { format } from "date-fns";
import {
  SimulatedForwardProbabilitiesACData,
  useSimulatedForwardProbabilitiesACData,
} from "pages/assetClassAnalysis/ChartSimulatedForwardProbabilitiesAC";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

interface ReportPageProps {
  title: string;
  firmName: string;
  chartDateRange1: number;
  chartData: SimulatedForwardProbabilitiesACData;
  theme: Theme;
}

const ReportPage = (props: ReportPageProps) => {
  return (
    <PdfPage
      hideFooter
      style={{
        paddingTop: 37,
        paddingBottom: 50,
        paddingLeft: 36,
        paddingRight: 36,
      }}
      footerProps={{
        isDraft: false,
        advisorCompanyName: "",
        clientName: "",
      }}
    >
      <PageTitle firmName={props.firmName} title={props.title} />
      <SectionHeader
        style={{
          marginTop: 36,
        }}
      >
        {`Simulated Forward Probabilities - ${props.chartDateRange1} Years`}
      </SectionHeader>
      <Text
        style={{
          fontSize: 8,
          fontStyle: "italic",
          textAlign: "right",
          color: props.theme.palette.softBlack,
          marginRight: 10,
        }}
      >
        Internal Use Only
      </Text>
      <SimulatedForwardProbabilitiesPdfChartAC data={props.chartData} />
      <PageNumber />
    </PdfPage>
  );
};

export const ALL_ASSET_CLASSES_DATA_ID = "all";

export const AssetClassSimulatedForwardProbabilitiesPdfReportPage = () => {
  const [searchParams] = useSearchParams();
  const data1Id = searchParams.get("data1Id") ?? "";
  const data2Id = searchParams.get("data2Id") ?? "";
  const isReal = !JSON.parse(searchParams.get("isNominal") ?? "false");
  const isAdvanced = JSON.parse(searchParams.get("isAdvanced") ?? "false");
  const chartDateRange1 = Number.parseInt(
    searchParams.get("chartDateRange1") ?? "20",
    10,
  );
  const chartDateRange2 = Number.parseInt(
    searchParams.get("chartDateRange2") ?? "20",
    10,
  );

  const firm = useFirm();

  const latestSimulation =
    useSimulationSimulationJobLatestSimulationRetrieveQuery();

  const simulationGroups = useSimulationGroups();

  const getSimulationSuffix = (id?: string) =>
    simulationGroups.items.find((i) => i.latest_simulation?.id === id)
      ?.latest_simulation?.is_static
      ? "Static"
      : "Price Driven";

  const simulationJobId1 = latestSimulation.data?.id;
  const simulationJobId2 = simulationGroups.items.find(
    (i) =>
      i.latest_simulation?.id !== latestSimulation.data?.id &&
      i.latest_simulation?.is_static !== latestSimulation.data?.is_static,
  )?.latest_simulation?.id;

  const data1 = useAssetClassExtendedStat({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: simulationJobId1,
  });

  const data2 = useAssetClassExtendedStat({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: simulationJobId2,
  });

  const isLoading =
    firm.isLoading ||
    data1.isLoading ||
    data2.isLoading ||
    simulationGroups.isLoading ||
    latestSimulation.isLoading;

  const theme = useTheme();

  const { getChartData } = useSimulatedForwardProbabilitiesACData({
    nominalHeatmap1: data1.nominalHeatmap,
    realHeatmap1: data1.realHeatmap,
    simulationSuffix1: getSimulationSuffix(simulationJobId1),

    nominalHeatmap2: data2.nominalHeatmap,
    realHeatmap2: data2.realHeatmap,
    simulationSuffix2: getSimulationSuffix(simulationJobId2),

    isAdvanced,
    isReal,
    chartDateRange1,
    chartDateRange2,
  });

  const isSingle = data1Id !== ALL_ASSET_CLASSES_DATA_ID;

  if (isSingle) {
    const chartData = getChartData({ data1Id, data2Id });
    const title = data1.oneYearUnderPerf.find((i) => i.id === data1Id)?.label;

    return (
      <CarPdfViewer isLoading={isLoading}>
        <CarPdfDocument
          title={`Simulated Forward Probabilities ${title} - ${format(
            new Date(),
            "MM/dd/yyyy",
          )}`}
        >
          <ReportPage
            title={title ?? ""}
            firmName={firm.firm.name ?? ""}
            chartDateRange1={chartDateRange1}
            chartData={chartData}
            theme={theme}
          />
        </CarPdfDocument>
      </CarPdfViewer>
    );
  } else {
    return (
      <CarPdfViewer isLoading={isLoading}>
        <CarPdfDocument
          title={`Simulated Forward Probabilities ${format(
            new Date(),
            "MM/dd/yyyy",
          )}`}
        >
          {data1.oneYearUnderPerf.map((i) => {
            const chartData = getChartData({ data1Id: i.id, data2Id });

            return (
              <ReportPage
                key={i.id}
                title={i.label ?? ""}
                firmName={firm.firm.name ?? ""}
                chartDateRange1={chartDateRange1}
                chartData={chartData}
                theme={theme}
              />
            );
          })}
        </CarPdfDocument>
      </CarPdfViewer>
    );
  }
};
