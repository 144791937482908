import { Image, Text, View } from "@react-pdf/renderer";
import {
  fontPdf,
  FooterProps,
  HeaderCell,
  PdfCheckbox,
  PdfPage,
  PdfMarkdown,
  PdfSectionLabel,
  TextCell,
} from "components/Pdf";

import {
  BreakdownChartItem,
  UsePublishPlanHouseholdAllocation,
} from "../publishPlanDialog/usePublishPlanHouseholdAllocation";
import { CarAllocAsset } from "app/useAllocAssets";
import { formatPercent } from "utils";
import { getActionPdfHouseholdAllocationChart } from "./getActionPdfHousholdAllocationChart";
import { caravelColors } from "../../theme";

const AllocationTable = ({
  items,
  proposedLabel,
}: {
  items: CarAllocAsset[];
  proposedLabel: string;
}) => (
  <View
    style={{
      fontFamily: fontPdf.table.family,
      fontSize: fontPdf.table.normal.size,
    }}
  >
    <View style={{ flexDirection: "row", fontSize: fontPdf.table.normal.size }}>
      <HeaderCell style={{ paddingVertical: 12, fontSize: 12 }} />
      <HeaderCell style={{ paddingVertical: 12, fontSize: 12 }}>
        Existing Portfolio:
      </HeaderCell>
      <HeaderCell style={{ paddingVertical: 12, fontSize: 12 }}>
        {`${proposedLabel} Portfolio:`}
      </HeaderCell>
      <HeaderCell
        style={{ paddingVertical: 12, fontSize: 12, borderRightWidth: 1 }}
      >
        Difference:
      </HeaderCell>
    </View>

    {items.map((i, idx) => (
      <View
        style={{
          flexDirection: "row",
          fontFamily: fontPdf.table.family,
          fontSize: fontPdf.table.normal.size,
        }}
      >
        <TextCell
          style={{
            textAlign: "left",
            paddingLeft: 5,
            paddingVertical: 15,
            fontSize: 12,
          }}
        >
          {`${i.name}:`}
        </TextCell>
        <TextCell style={{ paddingVertical: 15, fontSize: 12 }}>
          {formatPercent(i.current_percent, 1)}
        </TextCell>
        <TextCell style={{ paddingVertical: 15, fontSize: 12 }}>
          {formatPercent(i.proposed_percent, 1)}
        </TextCell>
        <TextCell
          style={{
            paddingVertical: 15,
            fontSize: 12,
            borderRightWidth: 1,
          }}
        >
          {formatPercent(i.diff_percent, 1)}
        </TextCell>
      </View>
    ))}
  </View>
);

const Chart = (props: { items: BreakdownChartItem[]; title: string }) => (
  <View style={{ width: 210, alignItems: "center" }}>
    <Text
      style={{
        fontFamily: fontPdf.table.family,
        fontWeight: "bold",
        fontSize: 16,
        color: caravelColors.softBlack,
        textAlign: "center",
        height: 40,
      }}
    >
      {props.title}
    </Text>
    <Image
      style={{ width: 122, height: 122 }}
      source={getActionPdfHouseholdAllocationChart({
        items: props.items,
      })}
    />
  </View>
);

export interface HouseholdAllocationActionPdfPageProps {
  footerProps: FooterProps;
  publishPlanHouseholdAllocation: UsePublishPlanHouseholdAllocation;
}

export const HouseholdAllocationActionPdfPage = (
  props: HouseholdAllocationActionPdfPageProps
) => {
  return (
    <PdfPage footerProps={props.footerProps}>
      <PdfSectionLabel label="Household Allocation" />
      <PdfMarkdown>
        Here we display your existing portfolio strategy against the new
        strategy that you and your advisor have selected. You can see the
        numerical changes in the table below.
      </PdfMarkdown>
      <View
        style={{
          marginTop: 30,
          marginBottom: 45,
          flexDirection: "row",
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        <Chart
          title="Existing Strategy"
          items={props.publishPlanHouseholdAllocation.existingChart}
        />
        <Chart
          title={`${props.publishPlanHouseholdAllocation.portfolioLabel} Strategy`}
          items={props.publishPlanHouseholdAllocation.proposedChart}
        />
      </View>
      <AllocationTable
        proposedLabel={props.publishPlanHouseholdAllocation.portfolioLabel}
        items={props.publishPlanHouseholdAllocation.allocAssets.level1}
      />
      <PdfCheckbox style={{ marginTop: 35, alignSelf: "center", width: 380 }}>
        I/We understand the new investment strategy recommended by our advisor,
        the risks inherent in that strategy, and how those risks differ from our
        existing investment strategy.
      </PdfCheckbox>
      <PdfCheckbox style={{ marginTop: 35, alignSelf: "center", width: 380 }}>
        I/We understand that we may need to pay capital gains taxes to implement
        the new strategy, and that these taxes will be different from any
        estimated capital gains obligations.
      </PdfCheckbox>
    </PdfPage>
  );
};
