import { useMemo } from "react";
import { ConfirmDialog, ConfirmDialogProps } from "components/ConfirmDialog";
import { useDialog } from "./useDialog";

export type ConfirmParams = Omit<ConfirmDialogProps, "onApply" | "onCancel">;

export const useConfirm = () => {
  const dialog = useDialog();

  return useMemo(
    () => async (params: ConfirmParams) => dialog(ConfirmDialog, params),
    [dialog]
  );
};
