import React from "react";
import ReactPDF, { View, Text } from "@react-pdf/renderer";
import ReactMarkdown from "react-markdown";
import { CarPdfTypography } from "./PdfTypography";
import { CarPdfBox } from "./PdfBox";

export interface CarPdfMarkdownProps
  extends React.PropsWithChildren<Omit<ReactPDF.ViewProps, "children">> {
  children: string;
}

export const CarPdfMarkdown = ({ children, ...props }: CarPdfMarkdownProps) => (
  <View {...props}>
    {children.split("\n").map((i, idx) =>
      i.trim() ? (
        <Text key={idx}>
          <ReactMarkdown
            components={{
              h1: (props) => (
                <CarPdfTypography variant="h1">
                  {props.children}
                </CarPdfTypography>
              ),
              h2: (props) => (
                <CarPdfTypography variant="h2">
                  {props.children}
                </CarPdfTypography>
              ),
              h3: (props) => (
                <CarPdfTypography variant="h3">
                  {props.children}
                </CarPdfTypography>
              ),
              h4: (props) => (
                <CarPdfTypography variant="h4">
                  {props.children}
                </CarPdfTypography>
              ),
              h5: (props) => (
                <CarPdfTypography variant="h5">
                  {props.children}
                </CarPdfTypography>
              ),
              h6: (props) => (
                <CarPdfTypography variant="h6">
                  {props.children}
                </CarPdfTypography>
              ),
              div: (props) => (
                <CarPdfTypography variant="body1">
                  {props.children}
                </CarPdfTypography>
              ),
              span: (props) => (
                <CarPdfTypography variant="body1">
                  {props.children}
                </CarPdfTypography>
              ),
              pre: (props) => (
                <CarPdfTypography variant="body1">
                  {props.children}
                </CarPdfTypography>
              ),
              code: (props) => (
                <CarPdfTypography variant="body1">
                  {props.children}
                </CarPdfTypography>
              ),
              hr: (props) => (
                <Text
                  debug
                  style={{
                    height: 10,
                    width: "100%",
                    borderBottom: "1px solid black",
                  }}
                >
                  {props.children}
                </Text>
              ),
              p: (props) => (
                <CarPdfTypography
                  style={{
                    display: "flex",
                    marginTop: 100,
                  }}
                >
                  {props.children}
                </CarPdfTypography>
              ),
              strong: (props) => (
                <CarPdfTypography
                  variant="body1"
                  style={{ fontWeight: "bold" }}
                >
                  {props.children}
                </CarPdfTypography>
              ),
              em: (props) => (
                <CarPdfTypography
                  variant="body1"
                  style={{ fontStyle: "italic" }}
                >
                  {props.children}
                </CarPdfTypography>
              ),
            }}
          >
            {i}
          </ReactMarkdown>
        </Text>
      ) : (
        <CarPdfBox key={idx} style={{ height: 28 }}></CarPdfBox>
      ),
    )}
  </View>
);
