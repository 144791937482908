import { useLocalStorage } from "features/general/useLocalStorage";
import { useEffect, useMemo, useState } from "react";

// this will not save to local storage
export const useChartDataSelectorState = ({
  data1DefaultId,
  data2DefaultId,
}: {
  data1DefaultId?: string;
  data2DefaultId?: string;
}) => {
  const [data1Id, onData1IdChange] = useState(data1DefaultId);
  const [data2Id, onData2IdChange] = useState(data2DefaultId);

  useEffect(() => {
    if (data1DefaultId) {
      onData1IdChange(data1DefaultId);
    }
  }, [data1DefaultId]);

  useEffect(() => {
    if (data2DefaultId) {
      onData2IdChange(data2DefaultId);
    }
  }, [data2DefaultId]);

  return {
    data1Id,
    onData1IdChange,
    data2Id,
    onData2IdChange,
  };
};

export const useChartDataSelector = ({
  storageKey,
  data1DefaultId,
  data2DefaultId,
}: {
  storageKey: string;
  data1DefaultId?: string;
  data2DefaultId?: string;
}) => {
  const [data1Id, onData1IdChange] = useLocalStorage<string | undefined>(
    `${storageKey}_data1Id`,
    data1DefaultId,
  );
  const [data2Id, onData2IdChange] = useLocalStorage<string | undefined>(
    `${storageKey}_data2Id`,
    data2DefaultId,
  );

  useEffect(() => {
    if (data1DefaultId && !data1Id) {
      onData1IdChange(data1DefaultId);
    }
  }, [data1DefaultId, data1Id, onData1IdChange]);

  useEffect(() => {
    if (data2DefaultId && !data2Id) {
      onData2IdChange(data2DefaultId);
    }
  }, [data2DefaultId, data2Id, onData2IdChange]);

  return useMemo(
    () => ({
      data1Id,
      onData1IdChange,
      data2Id,
      onData2IdChange,
    }),
    [data1Id, onData1IdChange, data2Id, onData2IdChange],
  );
};

export type UseChartDataSelector = ReturnType<typeof useChartDataSelector>;
