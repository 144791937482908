import clsx from "clsx";
import { Box, Typography, styled } from "@mui/material";
import {
  CarCurrencyFieldDelayed,
  CarFactorPercentFieldDelayed,
} from "components/NumberField";
import { CarAccount, CarSimplePosition } from "types";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { AccountType } from "const";
import { formatPercent, formatCurrency, isOddEven } from "utils";
import { ErrorLabel } from "components/ErrorLabel";
import {
  PercentageValidationResult,
  summaryPercentageValid,
} from "features/assets/account.utils";
import { AccountManualSimpleHolding } from "api/carApi.generated";
import { v4 } from "uuid";
import { memo, useMemo } from "react";
import { useCallbackAsRef } from "app/useItemChangeCallback";

interface SimplePositionHeaderProps {
  className?: string;
  isPercentMode: boolean;
}

const SimplePositionHeader = styled((props: SimplePositionHeaderProps) => {
  return (
    <Box className={props.className}>
      <Typography variant="par02SemiBold" className="asset-class">
        Asset Class
      </Typography>
      <Typography variant="par02SemiBold" className="account">
        {`Account ${props.isPercentMode ? "%" : "$"}`}
      </Typography>
    </Box>
  );
})(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "stretch",
  width: "100%",
  ".asset-class": {
    marginRight: "auto",
  },
  ".account": {
    width: 105,
    textAlign: "center",
  },
}));

interface PositionItemProps {
  className?: string;
  assetClassLabel: string;
  isPercentMode: boolean;
  item: CarSimplePosition;
  onChange: (item: CarSimplePosition) => void;
}

const PositionItem = memo(
  styled(
    ({
      className,
      item,
      assetClassLabel,
      isPercentMode,
      onChange,
    }: PositionItemProps) => {
      return (
        <Box className={className}>
          <Typography variant="par01Regular">{assetClassLabel}</Typography>
          {isPercentMode ? (
            <CarFactorPercentFieldDelayed
              removeZeroOnFocus
              value={item.percentage}
              onChange={(percentage) =>
                onChange({ ...item, percentage: percentage ?? 0 })
              }
            />
          ) : (
            <CarCurrencyFieldDelayed
              removeZeroOnFocus
              value={item.market_value}
              decimalPlaces={2}
              onChange={(market_value) =>
                onChange({ ...item, market_value: market_value ?? 0 })
              }
            />
          )}
        </Box>
      );
    },
  )(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "stretch",
    width: "100%",
    gap: theme.spacing(0.5),
    "& > .MuiTypography-root": {
      marginRight: "auto",
    },
    ".MuiTextField-root": {
      maxWidth: 105,
    },
  })),
);

interface SimplePositionTotalProps {
  className?: string;
  assetClassLabel: string;
  isPercentMode: boolean;
  isGrandTotal?: boolean;
  value: number;
}

const SimplePositionTotal = styled(
  ({
    className,
    value,
    assetClassLabel,
    isPercentMode,
    isGrandTotal,
  }: SimplePositionTotalProps) => {
    return (
      <Box className={clsx(className, { "grand-total": isGrandTotal })}>
        <Typography
          className={"label"}
          variant={isGrandTotal ? "par03SemiBold" : "par02Regular"}
        >
          {assetClassLabel}
        </Typography>

        <Typography
          className="value"
          variant={isGrandTotal ? "par03SemiBold" : "par02Regular"}
        >
          {isPercentMode
            ? formatPercent(value * 100)
            : formatCurrency(value, 2)}
        </Typography>
      </Box>
    );
  },
)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "stretch",
  width: "100%",
  "&.grand-total .label": {
    color: theme.palette.black,
  },
  ".label": {
    marginRight: "auto",
  },
  ".value": {
    width: 105,
    textAlign: "right",
    color: theme.palette.black,
  },
}));

const StyledRoot = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4.5),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.palette.white,
  border: `solid 1px ${theme.palette.gray3}`,
  borderRadius: "5px",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(8),
  paddingRight: theme.spacing(8),
  ".inner": {
    display: "flex",
    flexDirection: "column",
    maxWidth: 1000,
  },
  ".totals-row": {
    width: 620,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(16),
    rowGap: theme.spacing(2),
    marginBottom: theme.spacing(7.5),
  },
  ".headers-row": {
    display: "grid",
    columnGap: theme.spacing(16),
    gridTemplateColumns: "1fr 1fr",
    marginBottom: theme.spacing(0.5),
  },
  ".items-row": {
    display: "grid",
    columnGap: theme.spacing(16),
    gridTemplateColumns: "1fr 1fr",
    gridAutoFlow: "column",
    rowGap: theme.spacing(2.5),
  },
}));

export interface ManualAccountRowSummaryPositionTableProps {
  item: CarAccount;
  onChange: (item: CarAccount) => void;
}

export const ManualAccountRowSummaryPositionTable = (
  props: ManualAccountRowSummaryPositionTableProps,
) => {
  const isPercentMode =
    props.item.account_input_type === AccountType.ManualPercent;

  const positions: Array<CarSimplePosition> = props.item.simple_holdings ?? [];

  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.byLatestSimulationJob,
  });

  const handleChange = useCallbackAsRef((item: CarSimplePosition) => {
    const alreadyHasItem = props.item.simple_holdings?.some(
      (i) => i.id === item.id,
    );

    let simple_holdings = props.item.simple_holdings ?? [];

    if (alreadyHasItem) {
      simple_holdings = simple_holdings.map((i) =>
        i.id === item.id ? item : i,
      );
    } else {
      if (item.market_value || item.percentage) {
        simple_holdings = [...simple_holdings, item];
      } else {
        // we can ignore change if item has not been yet created and no value were specified
        return;
      }
    }
    props.onChange({
      ...props.item,
      simple_holdings,
    });
  });

  const acItems =
    props.item.account_ui_input_type === "manual_summary_simple"
      ? assetClasses.simpleItems
      : assetClasses.items;

  // create and memorize all possible new positions: ui_key is used as react key, we need to preserve keys between renders
  const virtualPositions = useMemo<AccountManualSimpleHolding[]>(
    () =>
      acItems.map((assetClass) => ({
        id: v4(),
        ui_key: v4(),
        firm_asset_class_id: assetClass.firmAssetClassId,
        market_value: isPercentMode ? undefined : 0,
        percentage: isPercentMode ? 0 : undefined,
      })),
    [acItems, isPercentMode],
  );

  const renderedItems = acItems.map((assetClass) => {
    const position =
      positions.find(
        (i) => i.firm_asset_class_id === assetClass.firmAssetClassId,
      ) ??
      virtualPositions.find(
        (i) => i.firm_asset_class_id === assetClass.firmAssetClassId,
      );

    return position ? (
      <PositionItem
        key={position.ui_key}
        assetClassLabel={assetClass.name}
        item={position}
        isPercentMode={isPercentMode}
        onChange={handleChange}
      />
    ) : null;
  });

  const total = acItems.reduce((acc, i) => {
    const position = positions.find(
      (p) => p.firm_asset_class_id === i.firmAssetClassId,
    );
    return (
      acc +
      ((isPercentMode ? position?.percentage : position?.market_value) ?? 0)
    );
  }, 0);

  const getLevel1Total = (level1Id: string) =>
    acItems.reduce((acc, i) => {
      if (i.level1Id !== level1Id) {
        return acc;
      }

      const position = positions.find(
        (p) => p.firm_asset_class_id === i.firmAssetClassId,
      );
      return (
        acc +
        ((isPercentMode ? position?.percentage : position?.market_value) ?? 0)
      );
    }, 0);

  let percentageError = "";

  switch (summaryPercentageValid(props.item)) {
    case PercentageValidationResult.Less:
      percentageError = "The account sum is less than 100%";
      break;
    case PercentageValidationResult.More:
      percentageError = "The account sum is more than 100%";
      break;
    default:
      break;
  }

  return (
    <StyledRoot>
      <Box className="totals-row">
        {assetClasses.level1Items.map((i) => (
          <SimplePositionTotal
            key={i.id}
            value={getLevel1Total(i.id)}
            assetClassLabel={`${i.name} Total:`}
            isPercentMode={isPercentMode}
          />
        ))}
        {isOddEven(assetClasses.level1Items.length) && <div></div>}
        <SimplePositionTotal
          key="total"
          value={total}
          isGrandTotal
          assetClassLabel="Total:"
          isPercentMode={isPercentMode}
        />
      </Box>

      <Box className="inner">
        <Box className="headers-row">
          <SimplePositionHeader isPercentMode={isPercentMode} />
          <SimplePositionHeader isPercentMode={isPercentMode} />
        </Box>
        <ErrorLabel text={percentageError} />
        <Box
          className="items-row"
          sx={{
            gridTemplateRows: `repeat(${Math.ceil(
              renderedItems.length / 2,
            )}, 1fr)`,
          }}
        >
          {renderedItems}
        </Box>
      </Box>
    </StyledRoot>
  );
};
