import { useMemo } from "react";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import {
  SimulationJobResultAssetClass,
  useSimulationQaReportSummaryCreateQuery,
} from "api/carApi.generated";
import { DecimalOptions, formatDateTime, isDefined } from "utils";
import { useAssetClassGroupSelector } from "app/useAssetClassGroupSelector";
import {
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";

export interface CarSimulationSummaryColumn {
  id: string;
  label: string;
}

export interface CarSimulationSummaryValue {
  valueA: number | null;
  valueB: number | null;
  diff: number | null;
}

export interface CarSimulationSummaryRow {
  id: string;
  label: string;
  values: CarSimulationSummaryValue[];
}

export interface CarSimulationSummaryTable {
  simulationJobIdA?: string;
  simulationJobIdB?: string;
  name: string;
  columns: CarSimulationSummaryColumn[];
  rows: CarSimulationSummaryRow[];
}

interface UseSimulationABTestSummaryParams {
  simulationJobIdA: string;
  simulationJobIdB: string;
}

interface SimulationSummaryItem {
  id?: string;
  acLevel4Id: string;
  acName: string;
  isEquity: boolean;
  isFixedIncome: boolean;
  isCash: boolean;
  a?: SimulationJobResultAssetClass;
  b?: SimulationJobResultAssetClass;
}

const decimalOptions: DecimalOptions = {
  decimalPlaces: 2,
  forceShowDecimals: true,
};

const getSimulationSummaryTable = (params: {
  name: string;
  items: SimulationSummaryItem[];
  columns: {
    label: string;
    id: keyof Omit<
      SimulationJobResultAssetClass,
      "id" | "job" | "asset_class4" | "created_at" | "updated_at"
    >;
  }[];
}): CarSimulationSummaryTable => {
  return {
    name: params.name,
    columns: params.columns,
    rows: params.items.map((i) => ({
      id: i.acLevel4Id,
      label: i.acName,
      values: params.columns.map<CarSimulationSummaryValue>((c) => {
        const valueA = i.a?.[c.id] ?? null;
        const valueB = i.b?.[c.id] ?? null;
        const diff =
          isDefined(valueA) && isDefined(valueB) ? valueA - valueB : null;

        return {
          valueA,
          valueB,
          diff,
        };
      }),
    })),
  };
};

export const useSimulationABTestSummary = (
  params: UseSimulationABTestSummaryParams,
) => {
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: params.simulationJobIdA,
  });

  const assetClassGroupSelector = useAssetClassGroupSelector();

  const dataA = useSimulationQaReportSummaryCreateQuery({
    simulationQaReportRequestBase: {
      simulation_job_id: params.simulationJobIdA,
    },
  });

  const dataB = useSimulationQaReportSummaryCreateQuery({
    simulationQaReportRequestBase: {
      simulation_job_id: params.simulationJobIdB,
    },
  });

  const excelExport = useExcelExport();

  return useMemo(() => {
    const items =
      dataA.data?.asset_classes
        .map<SimulationSummaryItem | undefined>((a) => {
          const ac = assetClasses.items.find(
            (ac) => ac.level4Id === a.stat_result.asset_class4,
          );
          if (!ac) {
            return undefined;
          }

          return {
            acLevel4Id: ac.level4Id,
            acName: ac.name,
            isEquity: ac.isEquity,
            isFixedIncome: ac.isFixedIncome,
            isCash: ac.isCash,
            a: a.stat_result,
            b: dataB.data?.asset_classes.find(
              (b) => b.stat_result.asset_class4 === a.stat_result.asset_class4,
            )?.stat_result,
          };
        })
        .filter(isDefined)
        .filter((i) =>
          assetClassGroupSelector.isAssetClassInGroup(i.acLevel4Id),
        ) ?? [];

    const equityItems = items.filter((i) => i.isEquity);
    const fixedIncomeItems = items.filter((i) => i.isFixedIncome || i.isCash);
    const fixedIncomeSpreadItems = items.filter((i) => i.isFixedIncome);

    const tables: CarSimulationSummaryTable[] = [];

    if (dataA.data?.static_simulation) {
      tables.push(
        getSimulationSummaryTable({
          name: "Asset Classes",
          items,
          columns: [
            {
              label: "Expected 10 Year Return",
              id: "ten_year_expected_return",
            },
            {
              label: "Simulated 10 Year Real Return",
              id: "output_real_return_mean",
            },
            {
              label: "Simulated 10 Year Nominal Return",
              id: "output_nominal_return_mean",
            },
            {
              label: "Input Volatility",
              id: "input_return_sigma",
            },
            {
              label: "Output Volatility (Nominal)",
              id: "output_nominal_return_sigma",
            },
          ],
        }),
      );
    } else {
      if (equityItems.length > 0) {
        tables.push(
          getSimulationSummaryTable({
            name: "Equities",
            items: equityItems,
            columns: [
              {
                label: "Expected 10 Year Return",
                id: "ten_year_expected_return",
              },
              {
                label: "Simulated 10 Year Real Return",
                id: "output_real_return_mean",
              },
              {
                label: "Simulated 10 Year Nominal Return",
                id: "output_nominal_return_mean",
              },
              {
                label: "Input Volatility",
                id: "input_return_sigma",
              },
              {
                label: "Output Volatility (Nominal)",
                id: "output_nominal_return_sigma",
              },
            ],
          }),
        );
      }

      if (fixedIncomeItems.length > 0) {
        tables.push(
          getSimulationSummaryTable({
            name: "Fixed Income",
            items: fixedIncomeItems,
            columns: [
              {
                label: "Simulated Real Return",
                id: "output_real_return_mean",
              },
              {
                label: "Simulated Nominal Return",
                id: "output_nominal_return_mean",
              },
              {
                label: "Simulated Return Volatility (Nominal)",
                id: "output_nominal_return_sigma",
              },
              {
                label: "Simulated Yield Volatility",
                id: "output_yield_sigma",
              },
            ],
          }),
        );
      }

      if (fixedIncomeSpreadItems.length > 0) {
        tables.push(
          getSimulationSummaryTable({
            name: "Fixed Income Spreads",
            items: fixedIncomeSpreadItems,
            columns: [
              {
                label: "Historical Average Spread",
                id: "input_spread_mean",
              },
              {
                label: "Simulated Average Spread",
                id: "output_spread_mean",
              },
              {
                label: "Historic Spread Volatility",
                id: "input_spread_sigma",
              },
              {
                label: "Simulated Spread Volatility",
                id: "output_spread_sigma",
              },
            ],
          }),
        );
      }
    }

    const handleDownloadReports = () => {
      excelExport.exportAllExcel({
        fileName: `Simulation AB Test Summary - ${dataA.data
          ?.simulation_name} - ${formatDateTime(dataA.data?.simulation_date)
          .replaceAll("/", "-")
          .replaceAll(":", "-")} - ${dataB.data
          ?.simulation_name} - ${formatDateTime(dataB.data?.simulation_date)
          .replaceAll("/", "-")
          .replaceAll(":", "-")} - ${assetClassGroupSelector.items.find(
          (i) => i.code === assetClassGroupSelector.value,
        )?.name}`,
        tables: tables.map<ExcelTable>((i) => ({
          name: i.name,
          bands: [
            { label: "", span: 1 },
            ...i.columns.flatMap((c) => [
              { label: c.label, span: 3 },
              { label: "", span: 0 },
              { label: "", span: 0 },
            ]),
          ],
          columns: [
            {
              label: "",
              format: ExcelDataFormat.general,
              fraction: 1.5,
              charWidth: 40,
            },
            ...i.columns.flatMap((c) => [
              {
                label: "Sim A",
                format: ExcelDataFormat.percent,
                decimalOptions,
              },
              {
                label: "Sim B",
                format: ExcelDataFormat.percent,
                decimalOptions,
              },
              {
                label: "Diff",
                format: ExcelDataFormat.percent,
                decimalOptions,
              },
            ]),
          ],
          rows: i.rows.map((r) => [
            r.label,
            ...r.values.flatMap((v) => [v.valueA, v.valueB, v.diff]),
          ]),
        })),
      });
    };

    return {
      isLoading:
        assetClasses.isLoading ||
        assetClassGroupSelector.isLoading ||
        dataA.isLoading ||
        dataB.isLoading,
      assetClassGroupSelector,
      tables,
      handleDownloadReports,
    };
  }, [
    dataA,
    dataB,
    assetClasses.isLoading,
    assetClasses.items,
    assetClassGroupSelector,
    excelExport,
  ]);
};
