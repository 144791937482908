import { CarAllocAsset } from "app/useAllocAssets";
import { formatCurrency, formatPercent } from "utils";
import { AllocationBreakdownItem } from "../householdAllocation/useHouseholdAllocation";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfRowProps,
  CarPdfTable,
  CarPdfTableProps,
  CarPdfTextCell,
} from "components/PdfTable";

interface RowProps extends Omit<CarPdfRowProps, "children"> {
  label: string;
  values: string[];
}

const Row = ({ label, values, ...props }: RowProps) => (
  <CarPdfRow paddingVertical={10} {...props}>
    {[
      <CarPdfTextCell
        key={-1}
        style={{
          textAlign: "left",
          paddingLeft: 5,
        }}
        variant="tableBody3"
      >
        {label}
      </CarPdfTextCell>,
      ...values.map((i, idx) => (
        <CarPdfTextCell key={idx} variant="tableBody3">
          {i}
        </CarPdfTextCell>
      )),
    ]}
  </CarPdfRow>
);

interface CarPdfModulesTableProps
  extends Omit<CarPdfTableProps, "colWidths" | "children"> {
  breakdownItems: AllocationBreakdownItem[];
  allocItems?: CarAllocAsset[];
}

export const CarPdfModulesTable = ({
  breakdownItems,
  allocItems,
  ...props
}: CarPdfModulesTableProps) => (
  <CarPdfTable colWidths={[0.9, ...breakdownItems.map((i) => 1)]} {...props}>
    {[
      <CarPdfHeader key={-3} paddingVertical={14}>
        {[
          <CarPdfHeaderCell key={-1} />,
          ...breakdownItems.map((i, idx) => (
            <CarPdfHeaderCell key={idx}>{i.label}</CarPdfHeaderCell>
          )),
        ]}
      </CarPdfHeader>,
      <Row
        key={-2}
        label="Portfolio %"
        values={breakdownItems.map((i) => i.percentage ?? 0).map(formatPercent)}
      />,
      <Row
        key={-1}
        label="$ Invested"
        values={breakdownItems
          .map((i) => i.money ?? 0)
          .map((i) => formatCurrency(i, 0))}
      />,
      ...(allocItems ?? []).flatMap((i, idx) => [
        <Row
          key={`${idx}_1`}
          label={`${i.displayShortName} in $`}
          values={i.years.map((y) => y.dollar).map((d) => formatCurrency(d, 0))}
        />,
        <Row
          key={`${idx}_2`}
          label={`${i.displayShortName} in %`}
          values={i.years.map((y) => y.percentage).map(formatPercent)}
        />,
      ]),
    ]}
  </CarPdfTable>
);
