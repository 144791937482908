import { Box, Typography, styled, useTheme } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { PlanStats } from "api/carApi.generated";
import { Line } from "react-chartjs-2";
import { formatDisplayTypeValue } from "utils";
import { ChartDataset } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { CarChartLegend } from "components/ChartLegend";
import { format, getMonth, parseISO } from "date-fns";

const StyledRoot = styled(RootPageBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "none",
  padding: theme.spacing(3),
  gap: theme.spacing(1),
}));

interface ProgressMonitoringProgressTowardsGoalsProps {
  isLoading: boolean;
  data: PlanStats[];
}

const StyledChart = styled(Line)(({ theme }) => ({
  width: "100%",
  height: 200,
  maxHeight: 200,
})) as typeof Line;

export const ProgressMonitoringProgressTowardsGoals = ({
  isLoading,
  data,
}: ProgressMonitoringProgressTowardsGoalsProps) => {
  const theme = useTheme();

  const yFormatter = formatDisplayTypeValue("PERCENTAGE", {
    decimalPlaces: 0,
  });

  const labels = data.map((i) => {
    const date = parseISO(i.date);
    return getMonth(date) === 0 ? format(date, "Y") : format(date, "LLL.");
  });

  const formatXValue = (value: number | string) => {
    return typeof value === "number" ? labels.at(value) : value;
  };

  const formatYValue = (value: number | string) =>
    typeof value === "number" ? yFormatter(value) : "";

  const datasets: ChartDataset<"line", number[]>[] = [
    {
      id: "needs",
      label: "Needs",
      borderColor: theme.palette.needs,
      borderWidth: 3,
      pointRadius: 0,
      data: data.map((v) => v.needs.percentage / 100),
    },
    {
      id: "wants",
      label: "Wants",
      borderColor: theme.palette.wants,
      borderWidth: 3,
      pointRadius: 0,
      data: data.map((v) => v.wants.percentage / 100),
    },
    {
      id: "dreams",
      label: "Dreams",
      borderColor: theme.palette.dreams,
      borderWidth: 3,
      pointRadius: 0,
      data: data.map((v) => v.dreams.percentage / 100),
    },
  ];

  return (
    <StyledRoot>
      <TablePlaceHolder isLoading={isLoading} rows={3}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5SSemiBold">Progress Towards Goals</Typography>
          <CarChartLegend
            sx={{ ml: "auto" }}
            items={datasets.map((i) => ({
              color: i.borderColor?.toString() ?? "",
              label: i.label ?? "",
            }))}
          />
        </Box>
        <StyledChart
          datasetIdKey="id"
          data={{
            datasets,
            labels,
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                type: "category",
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "600",
                  },
                  callback: formatXValue,
                },
                border: { display: false },
                grid: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
              y: {
                type: "linear",
                offset: true,
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "600",
                  },
                  callback: formatYValue,
                },
                border: { display: false },
                grid: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              roundedBackground: {
                contextColor: theme.palette.gray1,
                backgroundColor: theme.palette.gray1,
                borderColor: theme.palette.gray7,
                borderRadius: 5,
              },
            },
          }}
          plugins={[ChartPlugins.roundedBackground]}
        />
      </TablePlaceHolder>
    </StyledRoot>
  );
};
