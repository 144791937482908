import {
  useQuestionnairePlanSocialSecurityCreateMutation,
  useQuestionnairePlanSocialSecurityDestroyMutation,
  useQuestionnairePlanSocialSecurityListQuery,
  useQuestionnairePlanSocialSecurityUpdateMutation,
  useQuestionnairePlanSpousalBenefitRetrieveQuery,
} from "api/carApi.generated";
import { CarSocialSecurity } from "types";
import { refetchOnFocus } from "const";
import { usePendingProxy } from "../general/usePendingProxy";
import { useOptimizedSocialSecurity } from "./useOptimizedSocialSecurity";
import { differenceInYears, parseISO } from "date-fns";
import { isDefined } from "utils";
import { usePlanId } from "app/usePlanId";
import { useRelationTypes } from "app/useRelationTypes";
import { useRelationshipLight } from "app/useRelationshipLight";

export const fakeSpousalId = "fakeSpousalId";
export interface CarSocialSecurityDisplay extends CarSocialSecurity {
  recommendedAgeToReceiveBenefit?: number;
  annualBenefitAtDesiredAge?: number;
  annualBenefitAtRecommendedAge?: number;
}

export const useSocialSecurity = () => {
  const planId = usePlanId();
  const socialSecurity = useQuestionnairePlanSocialSecurityListQuery(
    { planId },
    { refetchOnFocus }
  );
  const relationship = useRelationshipLight();
  const { isPrimary, isSecondary } = useRelationTypes();

  const spousalBenefit = useQuestionnairePlanSpousalBenefitRetrieveQuery(
    { id: planId },
    { refetchOnFocus }
  );

  const optimized = useOptimizedSocialSecurity();

  // always sort in the order - [primary, secondary]
  // then proxyItems sorted in the same way #2jkud0k
  const relationshipItems = [
    relationship.primaryItem,
    relationship.secondaryItem,
  ].filter(isDefined);

  const [createRel] = useQuestionnairePlanSocialSecurityCreateMutation();
  const [updateRel] = useQuestionnairePlanSocialSecurityUpdateMutation();
  const [deleteRel] = useQuestionnairePlanSocialSecurityDestroyMutation();

  const handleChange = (item: CarSocialSecurityDisplay) => {
    if (item.id === fakeSpousalId) {
      return Promise.resolve({ data: item });
    } else {
      return updateRel({
        planId,
        id: item.id ?? "",
        clientPlanSocialSecurity: item as Required<CarSocialSecurityDisplay>,
      });
    }
  };

  const handleDelete = (item: CarSocialSecurityDisplay) =>
    deleteRel({ planId, id: item.id ?? "" });

  const handleCreate = (item: CarSocialSecurityDisplay) => {
    return createRel({
      planId,
      clientPlanSocialSecurity: item as Required<CarSocialSecurityDisplay>,
    });
  };

  const items = socialSecurity.data ?? [];

  const proxy = usePendingProxy<CarSocialSecurityDisplay>({
    name: "socialSecurity",
    items,
    handleCreate,
    handleChange,
    handleDelete,
    normalizeItem: (item) => {
      if (item.id === fakeSpousalId) {
        item.start_age_of_benefit = item.start_age_of_benefit ?? 0;

        const primaryRel = relationshipItems.find(isPrimary);
        const secondaryRel = relationshipItems.find(isSecondary);
        const primarySs = items.find((i) => i.relationship === primaryRel?.id);

        // Not sure but should be like: Spousal_SAoB >= John_SAoB + RoundDown((Jane_DoB - John_DoB) / 365)
        if (
          primarySs?.start_age_of_benefit &&
          primaryRel?.date_of_birth &&
          secondaryRel?.date_of_birth
        ) {
          const minAgeOfBenefit =
            primarySs.start_age_of_benefit +
            differenceInYears(
              parseISO(primaryRel.date_of_birth),
              parseISO(secondaryRel.date_of_birth)
            );
          item.start_age_of_benefit = Math.max(
            item.start_age_of_benefit ?? 0,
            minAgeOfBenefit
          );
          return; // end of validation for fakeSpousalId
        }
      }

      const relationshipItem = relationshipItems.find(
        (i) => i.id === item.relationship
      );

      item.start_age_of_benefit = Math.max(item.start_age_of_benefit ?? 0, 62);
      // Start age of benefit must be less than live_until age
      if (
        relationshipItem?.live_until &&
        item.start_age_of_benefit >= relationshipItem?.live_until
      ) {
        delete item.start_age_of_benefit;
      }
    },
    validate: (item) => {
      const required: Array<keyof CarSocialSecurityDisplay> =
        item.id === fakeSpousalId
          ? ["start_age_of_benefit"]
          : ["relationship", "benefit_yearly", "start_age_of_benefit"];
      return required.every((i) => item[i] !== undefined);
    },
  });

  const canAddRow = relationshipItems.some(
    (ri) => !proxy.items.some((si) => si.relationship === ri.id)
  );

  const addRow = () => {
    const item = relationshipItems.find(
      (ri) => !proxy.items.some((si) => si.relationship === ri.id)
    );
    if (item) {
      proxy.handleCreate({
        relationship: item.id,
        start_age_of_benefit: Math.max(item.retirement_age ?? 0, 62),
        benefit_yearly: 0,
      });
    }
  };

  const primaryRelationId = relationshipItems.find(isPrimary)?.id;
  const secondaryRelationId = relationshipItems.find(isSecondary)?.id;

  // adding recommendedAge calculated field
  let proxyItems: Array<CarSocialSecurityDisplay> = proxy.items.map((item) => {
    if (item.relationship === primaryRelationId) {
      return {
        ...item,
        recommendedAgeToReceiveBenefit: optimized.recommendedAge.primary,
        annualBenefitAtDesiredAge: optimized.benefitPreCola.primary,
        annualBenefitAtRecommendedAge:
          optimized.annualBenefitAtRecommendedAge.primary,
      };
    } else if (item.relationship === secondaryRelationId) {
      return {
        ...item,
        recommendedAgeToReceiveBenefit: optimized.recommendedAge.secondary,
        annualBenefitAtDesiredAge: optimized.benefitPreCola.secondary,
        annualBenefitAtRecommendedAge:
          optimized.annualBenefitAtRecommendedAge.secondary,
      };
    } else {
      return item;
    }
  });

  let isClient2Visible = false;

  // adding fake record for "Spousal benefit"
  if (
    secondaryRelationId &&
    proxyItems.some((i) => i.relationship === secondaryRelationId)
  ) {
    // ensure sorting - [primary, secondary, spousalBenefit]
    proxyItems = [
      proxyItems.find((i) => i.relationship === primaryRelationId),
      proxyItems.find((i) => i.relationship === secondaryRelationId),
      {
        id: fakeSpousalId,
        ui_key: fakeSpousalId,
        created_at: "2100-12-31", // make sure record is last
        benefit_yearly: optimized.currentSpousalBenefit,
        relationship: secondaryRelationId,
        start_age_of_benefit: undefined, // CU-860pvge7j // spousalBenefit.data?.spousal_benefit_start_age, // only one really editable field
        recommendedAgeToReceiveBenefit: optimized.recommendedAge.spousal,
        annualBenefitAtDesiredAge: optimized.benefitPreCola.spousal,
        annualBenefitAtRecommendedAge:
          optimized.annualBenefitAtRecommendedAge.spousal,
      },
    ].filter(isDefined);
    isClient2Visible = true;
  }

  return {
    relationshipItems,
    isLoading:
      socialSecurity.isLoading ||
      relationship.isLoading ||
      optimized.isLoading ||
      spousalBenefit.isLoading,
    isClient2Visible,
    totalAnnualBenefitDesiredAge: proxyItems.reduce(
      (acc, i) => acc + (i.annualBenefitAtDesiredAge ?? 0),
      0
    ),
    totalAnnualBenefitRecommendedAge: proxyItems.reduce(
      (acc, i) => acc + (i.annualBenefitAtRecommendedAge ?? 0),
      0
    ),
    totalLifetimeBenefitDesiredAge: optimized.inputAge.total,
    totalLifetimeBenefitRecommendedAge: optimized.recommendedAge.total,

    canAddRow,
    addRow,
    ...proxy,
    items: proxyItems,
  };
};
