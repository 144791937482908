import { Box, BoxProps, Typography, styled } from "@mui/material";
import { formatCurrency } from "utils";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { RootPageBox } from "components/RootPage";
import { WorkbenchInflationSmall } from "../WorkbenchInflation";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

const TotalItem = (props: {
  sx?: BoxProps["sx"];
  label: string;
  value: number;
  pendoClass: string;
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "flex-end",
      // borderBottom: `solid 1px`,
      // borderBottomColor: "gray6",
      pb: 1,
      ...props.sx,
    }}
  >
    <Typography
      variant="h4SSemiBold"
      sx={{ mb: 0.5, whiteSpace: "pre" }}
    >{`${props.label} - `}</Typography>
    <Typography
      component="span"
      color="primary"
      sx={{ ml: 1, fontSize: "35px", lineHeight: "39px", fontWeight: 700 }}
    >
      {formatCurrency(props.value)}
    </Typography>
    <CarTooltipBox sx={{ ml: 0.5, mb: 1.5 }} className={props.pendoClass} />
  </Box>
);

const DetailItem = (props: {
  sx?: BoxProps["sx"];
  rowData: Array<{ title: string; value: number; isRedForNegative?: boolean }>;
}) => (
  <Box sx={{ display: "flex", flexDirection: "column", ...props.sx }}>
    {props.rowData.map((i, idx) => (
      <Box
        sx={{
          mt: 2.5,
          display: "grid",
          gridTemplateColumns: "1fr 0.6fr",
          columnGap: 1,
        }}
        key={idx}
      >
        <Typography
          variant="h6SSemiBold"
          color={i.isRedForNegative && i.value < 0 ? "error" : undefined}
        >
          {i.title}
        </Typography>
        <Typography
          sx={{ textAlign: "right", lineHeight: "16.1px" }}
          variant="h6SRegular"
          color={i.isRedForNegative && i.value < 0 ? "error" : undefined}
        >
          {formatCurrency(i.value)}
        </Typography>
      </Box>
    ))}
  </Box>
);

interface PlanResultsInflowsProps {
  sx?: BoxProps["sx"];
  data: UsePlanResults;
}

const StyledRoot = styled(RootPageBox)(({ theme }) => ({
  padding: theme.spacing(3.5),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(5),
  display: "grid",
  gridTemplateColumns: "1fr 1px 1fr 1px 1fr",
  columnGap: theme.spacing(2),
  position: "relative",
}));

const DividerVert = styled(Box)(({ theme }) => ({
  borderRight: "solid 1px",
  borderRightColor: theme.palette.gray3,
}));

const DividerHorz = styled(Box)(({ theme }) => ({
  borderBottom: "solid 1px",
  borderBottomColor: theme.palette.gray3,
}));

export const PlanResultsInflows = ({ sx, data }: PlanResultsInflowsProps) => {
  return (
    <StyledRoot sx={sx}>
      <WorkbenchInflationSmall
        sx={{ position: "absolute", right: 24, top: 30 }}
      />
      <DividerVert
        sx={{
          gridRow: "1 / span 3",
          gridColumn: 2,
        }}
      />
      <DividerVert
        sx={{
          gridRow: "1 / span 3",
          gridColumn: 4,
        }}
      />
      <DividerHorz
        sx={{
          gridRow: 2,
          gridColumn: "1 / span 5",
        }}
      />
      <TotalItem
        sx={{ gridArea: "1 / 1" }}
        label="Inflows"
        value={data.totals.inflows.totalPlanInflow}
        pendoClass={pendoClasses.workbenchPlanResultsInflows}
      />
      <TotalItem
        sx={{ gridArea: "1 / 3" }}
        label="Outflows"
        value={data.totals.goals.outflows}
        pendoClass={pendoClasses.workbenchPlanResultsOutflows}
      />
      <TotalItem
        sx={{ gridArea: "1 / 5" }}
        label={`Surplus/\nDeficit`}
        value={data.totals.surplus.surplusDeficit}
        pendoClass={pendoClasses.workbenchPlanResultsSurplusDeficit}
      />
      <DetailItem
        sx={{ gridArea: "3 / 1" }}
        rowData={[
          {
            title: "Current Investments",
            value: data.totals.inflows.currentInvestments,
          },
          {
            title: "Nominal Investment Returns",
            value: data.totals.inflows.investmentReturns,
          },
          {
            title: "Savings/Other Inflows",
            value: data.totals.inflows.savingsOtherInflowsNetOfTax,
          },
          {
            title: "Social Security with COLA/Other Inflows (Pretax)",
            value: data.totals.inflows.socialSecurityOtherInflowsNetOfTax,
          },
          {
            title: "Real Estate Sale (Pretax)",
            value: data.totals.inflows.realEstateNetSaleProceeds,
          },
        ]}
      />
      <DetailItem
        sx={{ gridArea: "3 / 3" }}
        rowData={[
          {
            title: "Total Goals Funded",
            value: data.totals.goals.totalGoalFunded,
          },
          {
            title: "Inflation",
            value: data.totals.goals.inflation,
          },
          {
            title: "Total Taxes Paid",
            value: data.totals.goals.totalTaxesPaid,
          },
        ]}
      />
      <DetailItem
        sx={{ gridArea: "3 / 5" }}
        rowData={[
          {
            title: "Total Unfunded Goals",
            value: -data.totals.surplus.unfundedGoals,
            isRedForNegative: true,
          },
          {
            title: "Taxable Account Surplus",
            value: data.totals.surplus.taxableAccountSurplus,
          },
          {
            title: "Tax Deferred Account Surplus",
            value: data.totals.surplus.taxDeferredAccountSurplus,
          },
          {
            title: "Social Security/ Additional Income",
            value: data.totals.surplus.socialSecurityAdditionalIncome,
          },
          {
            title: "Real Estate",
            value: data.totals.surplus.realEstate,
          },
        ]}
      />
    </StyledRoot>
  );
};
