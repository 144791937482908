import { createContext, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "./hooks";
import { selectIsInflated } from "features/general/settingsSlice";
import { isDefined } from "utils";

export const useClientId = () => {
  const { clientId } = useParams();
  return clientId ?? "";
};

export interface PlanParams {
  planId: string;
  planTypeId: string;
  isInflated?: boolean;
}

const PlanParamsContext = createContext<PlanParams | undefined>(undefined);

export const PlanParamsContextProvider = PlanParamsContext.Provider;

export const usePlanId = () => {
  const { planId: urlPlanId } = useParams();
  const contextPlanId = useContext(PlanParamsContext)?.planId;
  return urlPlanId ?? contextPlanId ?? "";
};

export const usePlanTypeId = () => {
  const { planTypeId: urlPlanTypeId } = useParams();
  const contextPlanTypeId = useContext(PlanParamsContext)?.planTypeId;

  return urlPlanTypeId ?? contextPlanTypeId;
};

export type GetInflatedValue = (
  inflatedValue: number | undefined,
  deflatedValue: number | undefined,
) => number;

export const useIsInflated = () => {
  const params = useParams();
  const urlIsInflated = isDefined(params.isInflated)
    ? Boolean(params.isInflated)
    : undefined;

  const contextIsInflated = useContext(PlanParamsContext)?.isInflated;
  const reduxIsInflated = useAppSelector(selectIsInflated);

  const isInflated = urlIsInflated ?? contextIsInflated ?? reduxIsInflated;

  return useMemo(() => {
    const getInflatedValue: GetInflatedValue = (inflatedValue, deflatedValue) =>
      (isInflated ? inflatedValue : deflatedValue) ?? 0;

    return {
      isInflated,
      getInflatedValue,
    };
  }, [isInflated]);
};
