import { Box, BoxProps, Typography } from "@mui/material";
import { CarSelectOptionField } from "components/Inputs";
import { CarButton } from "components/Buttons";
import { UseSimulationDataExplorerOutput } from "./useSimulationDataExplorerOutput";
import { CarAssetClassSelector } from "components/AssetClassSelector";

interface SimulationDataExplorerDisplayProps {
  sx?: BoxProps["sx"];
  output: UseSimulationDataExplorerOutput;
  isWhiteContext?: boolean;
}

export const SimulationDataExplorerDisplay = (
  props: SimulationDataExplorerDisplayProps,
) => {
  const { output } = props;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, ...props.sx }}>
      <Typography variant="h6SemiBold" sx={{ mt: 3 }}>
        Display
      </Typography>
      <Box sx={{ display: "flex", gap: 2, alignItems: "flex-end" }}>
        <CarAssetClassSelector
          sx={{
            width: 250,
            ".MuiSelect-select": {
              backgroundColor: props.isWhiteContext ? undefined : "white",
            },
          }}
          label="Asset Class"
          items={output.assetClassSelectorItems}
          valueField="code"
          value={output.display.assetClassCode}
          onChange={(assetClassCode) =>
            output.setDisplay((val) => ({ ...val, assetClassCode }))
          }
        />
        <CarSelectOptionField
          sx={{
            width: 200,
            ".MuiSelect-select": {
              backgroundColor: props.isWhiteContext ? undefined : "white",
            },
          }}
          label="Field (Y-Axis)"
          options={output.debugFieldSelectorOptions}
          value={output.display.filterDataType}
          onChange={(filterDataType) =>
            output.setDisplay((value) => ({ ...value, filterDataType }))
          }
        />
        <CarSelectOptionField
          sx={{
            width: 200,
            ".MuiSelect-select": {
              backgroundColor: props.isWhiteContext ? undefined : "white",
            },
          }}
          label="Time Horizon"
          {...output.timeHorizonSelector}
        />
        <CarButton
          sx={{ ml: "auto", mb: -0.5 }}
          disabled={!props.output.table || props.output.isLoading}
          onClick={props.output.handleDownloadAll}
        >
          Export
        </CarButton>
      </Box>
    </Box>
  );
};
