import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";

export interface CarTooltipProps extends TooltipProps {
  preWrap?: boolean;
}

export const CarTooltip = styled(
  ({ className, preWrap, ...props }: CarTooltipProps) => (
    <Tooltip arrow {...props} classes={{ popper: className }} />
  ),
)(({ theme, preWrap }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.gray1,
    ...theme.typography.par01Regular,
    color: theme.palette.black,
    border: `1px solid ${theme.palette.gray6}`,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5),
    whiteSpace: preWrap ? "pre-wrap" : "unset",
  },
  [`& .${tooltipClasses.arrow}::before`]: {
    backgroundColor: theme.palette.gray1,
    border: `1px solid ${theme.palette.gray6}`,
  },
}));
