import { Box, Collapse, Typography } from "@mui/material";
import { AddRowButton } from "components/Buttons";
import {
  CarCurrencyFieldDelayed,
  CarNumberFieldDelayed,
  CarCurrencyCalcField,
  CarNumberCalcField,
} from "components/NumberField";
import { CarTextCalcField } from "components/Inputs";
import { CarTable } from "components/Table";
import {
  fakeSpousalId,
  useSocialSecurity,
} from "features/socialSecurity/useSocialSecurity";
import React from "react";
import { formatCurrency } from "utils";
import { CarDisclosure } from "components/Disclosure";
import { getFullName } from "features/clientFamily/useRelationship";
import { TablePlaceHolder } from "components/PlaceHolder";

export const SocialSecurityQuick = React.memo(() => {
  const data = useSocialSecurity();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TablePlaceHolder isLoading={data.isLoading}>
        <Collapse in={data.items.length > 0}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1.5fr 2fr 2fr 2fr",
              height: 24,
            }}
          >
            <Box />
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ margin: "auto" }} variant="mediumItalic">
                Benefit At Full Retirement Age:
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ margin: "auto" }} variant="mediumItalic">
                When to receive:
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ margin: "auto" }} variant="mediumItalic">
                Annual Benefit:
              </Typography>
            </Box>
          </Box>
          <CarTable
            items={data.items}
            columns={[
              {
                id: "relationship",
                label: "Client",
                fraction: 1.5,
                renderCell: ({ item, onChange }) => (
                  <CarTextCalcField
                    value={
                      item.id === fakeSpousalId
                        ? "Spousal Benefit"
                        : getFullName(
                            data.relationshipItems.find(
                              (r) => r.id === item.relationship,
                            ),
                          )
                    }
                  />
                ),
              },
              {
                id: "benefit_yearly",
                label: "*Annual",
                fraction: 1,
                headerSx: {
                  backgroundColor: "gray3",
                },
                renderCell: ({ item, onChange }) =>
                  item.id === fakeSpousalId ? (
                    <CarCurrencyCalcField value={item.benefit_yearly} />
                  ) : (
                    <CarCurrencyFieldDelayed
                      required
                      removeZeroOnFocus
                      value={item.benefit_yearly}
                      onChange={(benefit_yearly) =>
                        onChange({ ...item, benefit_yearly })
                      }
                    />
                  ),
              },
              {
                id: "benefit_monthly" as any, // temp
                label: "*Monthly",
                fraction: 1,
                headerSx: {
                  backgroundColor: "gray3",
                },
                renderCell: ({ item, onChange }) => {
                  const value =
                    typeof item.benefit_yearly === "number"
                      ? Math.round(item.benefit_yearly / 12)
                      : undefined;
                  return item.id === fakeSpousalId ? (
                    <CarCurrencyCalcField value={value} />
                  ) : (
                    <CarCurrencyFieldDelayed
                      required
                      removeZeroOnFocus
                      value={value}
                      onChange={(value) =>
                        onChange({
                          ...item,
                          benefit_yearly: (value ?? 0) * 12,
                        })
                      }
                    />
                  );
                },
              },
              {
                id: "start_age_of_benefit",
                label: "*Desired Age",
                fraction: 1,
                renderCell: ({ item, onChange }) =>
                  item.id === fakeSpousalId ? (
                    <CarNumberCalcField value={item.start_age_of_benefit} />
                  ) : (
                    <CarNumberFieldDelayed
                      required
                      value={item.start_age_of_benefit}
                      onChange={(start_age_of_benefit) => {
                        onChange({ ...item, start_age_of_benefit });
                      }}
                    />
                  ),
              },
              {
                id: "recommendedAgeToReceiveBenefit",
                label: "Rec. Age",
                fraction: 1,
                renderCell: ({ item, onChange }) => (
                  <CarNumberCalcField
                    value={item.recommendedAgeToReceiveBenefit}
                  />
                ),
              },
              {
                id: "annualBenefitAtDesiredAge",
                label: "Desired Age",
                fraction: 1,
                headerSx: {
                  backgroundColor: "gray3",
                },
                renderCell: ({ item, onChange }) => (
                  <CarCurrencyCalcField
                    value={item.annualBenefitAtDesiredAge}
                  />
                ),
              },
              {
                id: "annualBenefitAtRecommendedAge",
                label: "Rec. Age",
                fraction: 1,
                headerSx: {
                  backgroundColor: "gray3",
                },
                renderCell: ({ item, onChange }) => (
                  <CarCurrencyCalcField
                    value={item.annualBenefitAtRecommendedAge}
                  />
                ),
              },
            ]}
            onCanDelete={(item) => item.id !== fakeSpousalId}
            onChange={data.handleChange}
            onDelete={data.handleDelete}
            pendoPrefix="social_security"
          />
          <Box
            sx={{
              mt: 2.5,
              display: "grid",
              gridTemplateColumns: "5.5fr 1fr 1fr",
            }}
          >
            <Typography textAlign="right" variant="h5SBold">
              Total Annual Benefit:
            </Typography>
            <Typography textAlign="center" variant="h5SBold">
              {formatCurrency(data.totalAnnualBenefitDesiredAge)}
            </Typography>
            <Typography textAlign="center" variant="h5SBold">
              {formatCurrency(data.totalAnnualBenefitRecommendedAge)}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1,
              display: "grid",
              gridTemplateColumns: "5.5fr 1fr 1fr",
            }}
          >
            <Typography textAlign="right" variant="h5SBold">
              Total Lifetime Benefit (Pre-COLA):
            </Typography>
            <Typography textAlign="center" variant="h5SBold">
              {formatCurrency(data.totalLifetimeBenefitDesiredAge)}
            </Typography>
            <Typography textAlign="center" variant="h5SBold">
              {formatCurrency(data.totalLifetimeBenefitRecommendedAge)}
            </Typography>
          </Box>
        </Collapse>
      </TablePlaceHolder>
      <AddRowButton
        sx={{ alignSelf: "flex-start", my: 1.5 }}
        disabled={!data.canAddRow}
        onClick={data.addRow}
      >
        Add Person
      </AddRowButton>
      <CarDisclosure>
        Recommended Social Security Strategy calculations are based on current
        benefits information and are for informational purposes. Please consult
        with your tax advisor and review our disclosures.
      </CarDisclosure>
    </Box>
  );
});
