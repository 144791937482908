import {
  useQuestionnairePlanHealthcareCreateMutation,
  useQuestionnairePlanHealthcareDestroyMutation,
  useQuestionnairePlanHealthcareListQuery,
  useQuestionnairePlanHealthcareUpdateMutation,
} from "api/carApi.generated";
import { CarHealthcare } from "types";
import { refetchOnFocus } from "const";
import { usePendingProxy } from "../general/usePendingProxy";
import { usePlanId } from "app/usePlanId";
import { useRelationshipLight } from "app/useRelationshipLight";
import { isDefined } from "utils";

export const useHealthcare = () => {
  const planId = usePlanId();
  const healthcare = useQuestionnairePlanHealthcareListQuery(
    { planId },
    { refetchOnFocus }
  );
  const relationship = useRelationshipLight();

  // always sort in the order - [primary, secondary]
  // then proxyItems sorted in the same way #2jkud0k
  const relationshipItems = [
    relationship.primaryItem,
    relationship.secondaryItem,
  ].filter(isDefined);

  const [createRel] = useQuestionnairePlanHealthcareCreateMutation();
  const [updateRel] = useQuestionnairePlanHealthcareUpdateMutation();
  const [deleteRel] = useQuestionnairePlanHealthcareDestroyMutation();

  const handleChange = (item: CarHealthcare) =>
    updateRel({
      planId,
      id: item.id ?? "",
      clientPlanHealthcare: item as Required<CarHealthcare>,
    });

  const handleDelete = (item: CarHealthcare) =>
    deleteRel({ planId, id: item.id ?? "" });

  const handleCreate = (item: CarHealthcare) =>
    createRel({
      planId,
      clientPlanHealthcare: item as Required<CarHealthcare>,
    });

  const { items: proxyItems, ...proxy } = usePendingProxy<CarHealthcare>({
    name: "healthcare",
    items: healthcare.data ?? [],
    handleCreate,
    handleChange,
    handleDelete,
    normalizeItem: (item, oldItem) => {
      if (!item.assumed_percent_above_average) {
        item.assumed_percent_above_average = 0;
      }
      const relationshipItem = relationshipItems.find(
        (i) => i.id === item.relationship
      );
      if (item.start_age) {
        // start_age not less than retirement_age
        if (
          relationshipItem?.retirement_age &&
          item.start_age < relationshipItem.retirement_age
        ) {
          item.start_age = relationshipItem.retirement_age;
        }

        // start_age less than live_until
        if (
          relationshipItem?.live_until &&
          item.start_age >= relationshipItem.live_until
        ) {
          item.start_age = relationshipItem.live_until - 1;
        }
      }
    },
    required: ["relationship", "start_age"],
  });

  const canAddRow = relationshipItems.some(
    (ri) => !proxyItems.some((si) => si.relationship === ri.id)
  );

  const addRow = () => {
    const item = relationshipItems.find(
      (ri) => !proxyItems.some((si) => si.relationship === ri.id)
    );
    if (item) {
      proxy.handleCreate({
        relationship: item.id,
        start_age: item.retirement_age ?? 0,
        assumed_percent_above_average: 0,
        inflation: 0,
      });
    }
  };

  proxyItems.sort((a, b) => {
    const aIndex = relationshipItems.findIndex((i) => i.id === a.relationship);
    const bIndex = relationshipItems.findIndex((i) => i.id === b.relationship);
    return aIndex - bIndex;
  });

  return {
    isLoading: healthcare.isLoading || relationship.isLoading,
    items: proxyItems,
    ...proxy,
    canAddRow,
    addRow,
    relationshipItems,
  };
};
