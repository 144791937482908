import { Box, useTheme } from "@mui/material";
import { ChartPlugins, ChartUtils } from "chartUtils";
import React from "react";
import { Chart } from "react-chartjs-2";
import { formatCurrency, formatPercent } from "utils";

export interface CalAllocationItem {
  id: string;
  name: string;
  proposed_percent: number;
  current_percent: number;
  proposed_dollars: number;
  current_dollars: number;
}

export interface CarAllocationChartProps {
  items: Array<CalAllocationItem>;
  isPercentMode: boolean;
  isLarge: boolean;
  portfolioLabel: string;
}

export const CarAllocationChart = React.memo(
  (props: CarAllocationChartProps) => {
    const theme = useTheme();
    const filteredItems = props.items.filter(
      (i) => i.current_percent > 0.1 || i.proposed_percent > 0.1,
    );

    const current = filteredItems.map((i) => ({
      x: i.id,
      y: props.isPercentMode ? i.current_percent : i.current_dollars,
    }));
    const proposed = filteredItems.map((i) => ({
      x: i.id,
      y: props.isPercentMode ? i.proposed_percent : i.proposed_dollars,
    }));

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "white",
          border: "solid 1px",
          borderColor: "gray6",
          borderRadius: "5px",
          height: 544,
          paddingTop: 6,
          paddingLeft: 3.5,
          paddingRight: 3.5,
          paddingBottom: 3.5,
        }}
      >
        <Chart
          type="bar"
          height={135}
          data={{
            labels: filteredItems.map((i) => i.name ?? ""),
            datasets: [
              {
                type: "sharpBar" as "bar",
                label: "Existing Portfolio",
                data: current.map((i) => i.y),
                backgroundColor: theme.palette.chartsColor.orange,
                hoverBackgroundColor: theme.palette.chartsColor.orange,
                barPercentage: 0.8,
                categoryPercentage: 0.56,
              },
              {
                type: "sharpBar" as "bar",
                label: `${props.portfolioLabel} Portfolio`,
                data: proposed.map((i) => i.y),
                backgroundColor: theme.palette.chartsColor.green,
                hoverBackgroundColor: theme.palette.chartsColor.green,
                barPercentage: 0.8,
                categoryPercentage: 0.56,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            animation: {
              duration: 0,
            },
            layout: {
              padding: {
                right: 1, // for some reason right border is cropped, so add right padding to fit it in image
              },
            },
            scales: {
              x: {
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: filteredItems.length > 3 ? 12 : 14,
                    weight: "400",
                  },
                  color: theme.palette.softBlack,
                },
                grid: {
                  color: ChartUtils.gridColor,
                  lineWidth: 1,
                  tickColor: theme.palette.white,
                },
              },
              y: {
                ticks: {
                  autoSkip: true,
                  font: {
                    family: theme.typography.fontFamily,
                    size: filteredItems.length > 3 ? 12 : 14,
                    weight: "400",
                  },
                  color: theme.palette.softBlack,
                  callback: (value) =>
                    typeof value === "number"
                      ? props.isPercentMode
                        ? formatPercent(value)
                        : formatCurrency(value)
                      : value,
                },
                grid: {
                  color: ChartUtils.gridColor,
                  lineWidth: 1,
                  tickColor: theme.palette.white,
                },
              },
            },
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 14,
                    weight: "400",
                  },
                  boxWidth: 21,
                  boxHeight: 21,
                  usePointStyle: true,
                  pointStyle: "rectRounded",
                  padding: 32,
                },
              },
              roundedBackground: {
                contextColor: theme.palette.white,
                backgroundColor: theme.palette.gray1,
                borderColor: theme.palette.gray7,
                borderRadius: 5,
              },
            },
          }}
          plugins={[ChartPlugins.roundedBackground]}
        />
      </Box>
    );
  },
);
