import { useMemo } from "react";
import { useAllocAssets } from "app/useAllocAssets";
export interface AllocationChartItem {
  angle?: number;
  label?: string;
  color?: string;
}

export interface AllocationBreakdownItem {
  moduleId: string;
  label: string;
  percentage?: number;
  money?: number;
  allocation: Array<AllocationChartItem>;
}

export const useHouseholdAllocation = () => {
  const data = useAllocAssets();

  return useMemo(() => {
    const { planResult, calcAllocAssets } = data;

    const allocAssets = calcAllocAssets();

    const totalModuleAmount =
      planResult?.modules.reduce(
        (acc, i) => acc + i.total_amount_invested,
        0
      ) ?? 0;

    const breakdown: Array<AllocationBreakdownItem> =
      planResult?.modules.map<AllocationBreakdownItem>((i) => ({
        moduleId: i.id,
        label: i.module_name,
        money: i.total_amount_invested,
        percentage: totalModuleAmount
          ? (i.total_amount_invested / totalModuleAmount) * 100
          : 0,
        allocation: [],
      })) ?? [];

    breakdown.push({
      moduleId: "total",
      label: "Total",
      money: breakdown.reduce((acc, i) => acc + (i.money ?? 0), 0),
      percentage: breakdown.reduce((acc, i) => acc + (i.percentage ?? 0), 0),
      allocation: [],
    });

    breakdown.forEach((i, idx) => {
      i.allocation = allocAssets.level2.map<AllocationChartItem>((ac) => {
        return {
          label: ac.name,
          color: ac.color,
          angle: ac.years[idx]?.percentage ?? 0,
        };
      });
    });

    return {
      isLoading: data.isLoading,
      breakdown,
      allocAssets,
    };
  }, [data]);
};

export type UseHouseholdAllocation = ReturnType<typeof useHouseholdAllocation>;
