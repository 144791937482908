import { Box, BoxProps, CircularProgress, Typography } from "@mui/material";
import { forwardRef, Ref } from "react";

export interface CarUpdateProgressProps extends BoxProps {
  percent: number;
}

export const CarUpdateProgress = forwardRef(
  ({ percent, ...props }: CarUpdateProgressProps, ref: Ref<any>) => {
    return (
      <Box
        {...props}
        ref={ref}
        sx={{
          ...props.sx,
          width: 56,
          height: 56,
          borderRadius: "50%",
          backgroundColor: "primary.main",
          boxShadow:
            "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          sx={{ position: "absolute", fontSize: "0.6rem", color: "white" }}
        >
          {`${Math.round(percent)}%`}
        </Typography>

        <CircularProgress
          variant="determinate"
          value={percent}
          sx={{ color: "white" }}
        />
      </Box>
    );
  }
);
