import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { useSimulationQaReportCorrelationsCreateQuery } from "api/carApi.generated";
import { DecimalOptions, formatDateTime } from "utils";
import {
  ExcelColumn,
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";
import { useMemo, useState } from "react";

interface CorResponse {
  report_type: string;
  static_simulation: boolean;
  simulation_id: string;
  simulation_date: string;
  simulation_group_id: string;
  simulation_name: string;
  result: { [key: string]: CorResult[] };
  asset_classes: CorAssetClass[];
}

interface CorAssetClass {
  id: string;
}

interface CorResult {
  real_corr: number;
  nominal_corr: number;
}

interface UseSimulationCorrelationsParams {
  simulationJobId: string;
}

const decimalOptions: DecimalOptions = {
  decimalPlaces: 2,
  forceShowDecimals: true,
};

export const useSimulationCorrelations = (
  params: UseSimulationCorrelationsParams,
) => {
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: params.simulationJobId,
  });

  const correlations = useSimulationQaReportCorrelationsCreateQuery({
    simulationQaReportRequestBase: {
      simulation_job_id: params.simulationJobId,
    },
  });

  const [isReal, setIsReal] = useState(false);

  const excelExport = useExcelExport();

  return useMemo(() => {
    const data = correlations.data as unknown as CorResponse | undefined; // todo fix in swagger

    const getTable = (isReal: boolean): ExcelTable => ({
      name: isReal ? "Real" : "Nominal",
      columns: [
        {
          label: "",
          format: ExcelDataFormat.general,
          charWidth: 40,
          fraction: "200px",
        },
        ...(data?.asset_classes.map<ExcelColumn>((ac) => ({
          label:
            assetClasses.items.find((i) => i.level4Id === ac.id)?.name ?? "",
          format: ExcelDataFormat.percent,
          decimalOptions,
          fraction: "100px",
        })) ?? []),
      ],
      rows: Object.entries(data?.result ?? {}).map(([key, value]) => [
        assetClasses.items.find((i) => i.level4Id === key)?.name ?? "",
        ...value.map<number>((v) => (isReal ? v.real_corr : v.nominal_corr)),
      ]),
    });

    const realTable = getTable(true);
    const nominalTable = getTable(false);

    const handleDownloadReports = () => {
      excelExport.exportAllExcel({
        fileName: `Simulation Correlations - ${data?.simulation_name} - ${formatDateTime(
          data?.simulation_date,
        )
          .replaceAll("/", "-")
          .replaceAll(":", "-")}`,
        tables: [realTable, nominalTable],
      });
    };

    return {
      isLoading: assetClasses.isLoading || correlations.isLoading,
      isReal,
      setIsReal,
      table: isReal ? realTable : nominalTable,
      handleDownloadReports,
    };
  }, [
    excelExport,
    assetClasses.isLoading,
    assetClasses.items,
    correlations.data,
    correlations.isLoading,
    isReal,
  ]);
};
