import { IconButton, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { CarClose, CarIconDelete } from "icons";
import { forwardRef, ReactNode } from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: 49,
  minWidth: 334,
  maxWidth: 334,
  border: "solid 2px",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(0.5),
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(1),
  ".content": {
    // paddingTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  ".title-row": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `solid 1px ${theme.palette.gray6}`,
    marginBottom: theme.spacing(1),
  },
  ".title": {
    fontSize: "25px",
    fontWeight: 700,
    color: theme.palette.softBlack,
  },
  ".close-button": {
    alignSelf: "flex-start",
    marginTop: theme.spacing(-1),
  },
  borderColor: theme.palette.red,
  backgroundColor: theme.palette.white,
}));

interface ErrorToastProps {
  onClose: () => void;
  title: string;
  children?: ReactNode;
}

export const ErrorToast = forwardRef((props: ErrorToastProps, ref) => (
  <StyledBox ref={ref}>
    <Box className="content">
      <Box className="title-row">
        <CarIconDelete sx={{ fontSize: "30px" }} />
        <Typography className="title">{props.title}</Typography>
      </Box>
      <Typography className="message" variant="par01Regular">
        {props.children}
      </Typography>
    </Box>
    <IconButton className="close-button" size="small" onClick={props.onClose}>
      <CarClose sx={{ color: "gray7" }} />
    </IconButton>
  </StyledBox>
));
