import { Box, Typography, styled, useTheme } from "@mui/material";
import { CarBurgerMenuButton } from "./BurgerMenuButton";
import { BoxProps } from "@mui/system";
import { useAppTitle } from "app/useAppTitle";
import headerBackground from "assets/header-background.jpg";
import { NavLink } from "react-router-dom";
import { UserBadge } from "app/UserBadge";
import { CarTooltipBox } from "./TooltipBox";

const APP_WIDTH = 1024;

const PAPER_TOP_SHIFT = 46;

export const paperShiftProps = { marginTop: `-${PAPER_TOP_SHIFT}px` };

export const RootPage = styled(({ children, sx, ...props }: BoxProps) => (
  <Box sx={{ ...sx, paddingBottom: 4 }} {...props}>
    {children}
  </Box>
))(({ theme }) => ({
  minHeight: "100vh",
  minWidth: APP_WIDTH,
  width: "100%",
  flex: "auto",
  backgroundColor: theme.palette.gray1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  alignItems: "stretch",
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexShrink: "0",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPositionX: "right",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(5.5),
  minHeight: 200,

  "&.is-small": {
    minHeight: 159,
  },

  ".title-row": {
    display: "flex",
    alignItems: "center",
    filter: "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.15))",
    gap: theme.spacing(1),
  },
  ".title": {
    color: theme.palette.white,
    [theme.breakpoints.up("xl")]: {
      marginLeft: theme.spacing(2),
    },
  },
}));

export interface RootPageHeaderProps {
  isLoading?: boolean;
  title: string;
  appTitle?: string;
  titleActions?: JSX.Element;
  links?: JSX.Element;
  isTransparent?: Boolean;
  isSmall?: boolean; // todo remove when header look is unified
}

export const RootPageHeader = (props: RootPageHeaderProps) => {
  useAppTitle(props.appTitle ?? props.title);

  const theme = useTheme();

  return (
    <StyledHeader
      sx={{
        backgroundColor: props.isTransparent ? undefined : `primary.main`,
        backgroundImage: props.isTransparent
          ? undefined
          : `url(${headerBackground})`,
        position: "relative",
      }}
      className={props.isSmall ? "is-small" : undefined}
    >
      <UserBadge />
      <Box className="title-row">
        <CarBurgerMenuButton />
        <Typography
          className="title"
          variant="h3SMedium"
          sx={{ userSelect: "none" }}
        >
          {props.isLoading ? "Loading..." : props.title}
        </Typography>
        {!props.isLoading && props.titleActions}
      </Box>
      <Box
        sx={{
          mt: 3,
          ml: 2,
          display: "flex",
          gap: 5,

          [theme.breakpoints.down("xl")]: {
            gap: 3,
          },
        }}
      >
        {props.links}
      </Box>
    </StyledHeader>
  );
};

const StyledLink = styled(NavLink)({
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
});

export interface RootPageHeaderLinkProps {
  className?: string;
  pendoClassName?: string;
  label: string;
  url: string;
  id?: string;
  end?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  target?: React.HTMLAttributeAnchorTarget;
  disabled?: boolean;
  isActive?: boolean;
}

export const RootPageHeaderLink = (props: RootPageHeaderLinkProps) => {
  return (
    <StyledLink
      onClick={props.disabled ? (e) => e.preventDefault() : props.onClick}
      to={props.url}
      end={props.end}
      target={props.target}
    >
      {({ isActive }) => (
        <>
          <Typography
            variant="h5SMedium"
            sx={{
              color: "white",
              cursor: props.disabled ? "default" : "pointer",
              position: "relative",
              // px: 1,
              pb: 0.4,
              borderBottom: "solid 2px",
              borderBottomColor:
                isActive || props.isActive ? "white" : "rgba(0, 0, 0, 0)",
              opacity: props.disabled ? 0.7 : 1,
              textAlign: "center",
              userSelect: "none",
            }}
          >
            {props.label}
          </Typography>
          {props.pendoClassName && (
            <CarTooltipBox
              sx={{ position: "absolute", right: -4, top: 8 }}
              className={props.pendoClassName}
            />
          )}
        </>
      )}
    </StyledLink>
  );
};

const StyledPaperLink = styled(StyledLink)(({ theme }) => ({
  minHeight: 34,
  maxHeight: 60,
  border: `solid 1px ${theme.palette.gray3}`,
  borderRadius: "10px 10px 0px 0px",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  backgroundColor: theme.palette.gray1,
  flexBasis: "content",
  flexShrink: 1,
  display: "flex",
  alignItems: "center",
  position: "relative",
  ".label": {
    fontSize: "15px",
    lineHeight: "18px",
    textAlign: "center",
  },
  "&.active": {
    backgroundColor: theme.palette.white,
    ".label": {
      color: theme.palette.caravelOrangePrimary,
    },
    borderBottomColor: theme.palette.white,
  },
}));

export const RootPagePaperLink = (props: RootPageHeaderLinkProps) => {
  return (
    <StyledPaperLink
      className={props.className}
      onClick={props.onClick}
      to={props.url}
      end={props.end}
      target={props.target}
    >
      <Typography variant="par01Regular" className="label">
        {props.label}
      </Typography>
      {props.pendoClassName && (
        <CarTooltipBox
          sx={{ position: "absolute", right: 7, top: 8 }}
          className={props.pendoClassName}
        />
      )}
    </StyledPaperLink>
  );
};

export const RootPagePaperLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  position: "absolute",
  bottom: "100%",
  zIndex: 1,
  // [theme.breakpoints.down("lg")]: {
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  // },
}));

export const RootPageTransparentPaper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4.5),
  marginRight: theme.spacing(4.5),
  marginBottom: theme.spacing(4.5),
  // [theme.breakpoints.down("lg")]: {
  //   marginLeft: 0,
  //   marginRight: 0,
  //   marginBottom: 0,
  // },
  display: "flex",
  flexDirection: "column",
  flex: "auto",
  position: "relative",
}));

export const RootPagePaper = styled(RootPageTransparentPaper)(({ theme }) => ({
  // minHeight: "100%",
  background: "white",
  boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05);",
  border: "solid 1px",
  borderColor: theme.palette.gray3,
  borderRadius: "15px",
  marginTop: -PAPER_TOP_SHIFT,
  padding: theme.spacing(4.5),
  [theme.breakpoints.down("lg")]: {
    borderRadius: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(2),
  },
}));

export const RootPageBox = styled(Box)(({ theme }) => ({
  background: theme.palette.white,
  boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05);",
  border: "solid 1px",
  borderRadius: "15px",
  borderColor: theme.palette.gray3,
  display: "flex",
  flexDirection: "column",
  flex: "auto",
}));
