import React, { useCallback } from "react";
import { Chart } from "react-chartjs-2";
import { Box } from "@mui/system";
import { Typography, useTheme } from "@mui/material";
import { formatCurrency } from "utils";
import { CapitalGainsChartData } from "./useCapitalGains";
import {
  ChartTooltip,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { ChartDataset } from "chart.js";
import { ChartPlugins } from "chartUtils";

interface CapitalGainsChartSurplusDeficitProps {
  data: CapitalGainsChartData;
}

const minChartWidth = 800;
const maxChartWidth = 1000;

export const CapitalGainsChartSurplusDeficit = ({
  data,
}: CapitalGainsChartSurplusDeficitProps) => {
  const theme = useTheme();
  const [ticksPosition, setTicksPosition] = React.useState<Array<number>>([]);
  const labelWidthsRef = React.useRef<Array<number>>([]);
  const [labelWidths, setLabelWidths] = React.useState<Array<number>>([]);
  const { tooltipPlugin, tooltipData } = useChartTooltip();
  const updateLabelWidths = () => {
    if (
      data.labels.length &&
      data.labels.every((i, idx) => labelWidthsRef.current[idx] > 0) &&
      (labelWidths.length !== data.labels.length ||
        labelWidths.some((l, idx) => l !== labelWidthsRef.current[idx]))
    ) {
      setTimeout(
        () =>
          setLabelWidths(
            data.labels.map((l, idx) => labelWidthsRef.current[idx]),
          ),
        0,
      );
    }
  };

  const renderTooltip: RenderTooltip = useCallback(
    (datasetIndex, dataIndex) => {
      const ds = data.datasets[datasetIndex];
      const value = ds?.data[dataIndex];
      return (
        <>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: 600,
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            {data.labels[dataIndex]}
            {" - "}
            {typeof value === "number" ? `${formatCurrency(value)}` : ""}
          </Box>
        </>
      );
    },
    [data],
  );

  const labelsOverlap =
    ticksPosition.length > 1 &&
    ticksPosition.length === labelWidths.length &&
    labelWidths.some((l, idx) => {
      return (
        idx > 0 &&
        l + labelWidths[idx - 1] > (ticksPosition[1] - ticksPosition[0]) * 2
      );
    });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          maxWidth: maxChartWidth,
          width: "100%",
          minWidth: minChartWidth,
          height: 360,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: -24,
            top: 0,
            bottom: 0,
            width: 24,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="par02Bold"
            sx={{
              transform: "rotate(-90deg)",
              flex: "1 1 auto",
              whiteSpace: "nowrap",
            }}
          >
            Surplus/Deficit
          </Typography>
        </Box>
        <ChartTooltip tooltipData={tooltipData} renderTooltip={renderTooltip} />
        <Chart
          type="bar"
          data={{
            labels: data.labels,
            datasets: data.datasets.map<ChartDataset<"bar", number[]>>(
              (ds, idx) => ({
                type: "sharpBar" as "bar",
                label: ds.label,
                data: ds.data,
                borderWidth: 0,
                backgroundColor: (ctx) => {
                  return typeof ctx.raw === "number" && ctx.raw > 0
                    ? "#27AE60"
                    : "#EB5757";
                },
                maxBarThickness: 43,
              }),
            ),
          }}
          options={{
            maintainAspectRatio: false,
            layout: {
              padding: {
                right: 1, // for some reason right border is cropped, so add right padding to fit it in image
                bottom: 34, // for yearTicks
              },
            },
            animation: {
              duration: 500,
              onComplete: function ({ chart }) {
                let positions: Array<number> =
                  (chart.scales.x as any)._gridLineItems?.map(
                    (i: any) => i.x1,
                  ) || [];

                setTicksPosition((old) =>
                  !old ||
                  old.length !== positions.length ||
                  old.some((i, idx) => i !== positions[idx])
                    ? positions
                    : old,
                );
              },
            },
            scales: {
              x: {
                ticks: {
                  display: false,
                },
                border: { display: false },
                grid: {
                  display: true,
                  color: theme.palette.gray2,
                  lineWidth: 2,
                  tickColor: theme.palette.white,
                  offset: false,
                },
              },
              y: {
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 16,
                    weight: "400",
                  },
                  color: "black",
                  callback: (value) =>
                    typeof value === "number" ? formatCurrency(value) : value,
                },
                border: { display: false },
                grid: {
                  display: true,
                  color: (ctx) => {
                    return ctx.tick.value === 0
                      ? theme.palette.gray7
                      : theme.palette.gray2;
                  },
                  lineWidth: 2,
                  tickColor: theme.palette.white,
                },
                offset: true,
              },
            },
            interaction: {
              mode: "point",
              intersect: false,
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: tooltipPlugin,
              roundedBackground: {
                contextColor: theme.palette.white,
                backgroundColor: theme.palette.gray1,
                borderColor: theme.palette.gray7,
                borderRadius: 5,
              },
            },
          }}
          plugins={[ChartPlugins.roundedBackground]}
        />
      </Box>
      {Boolean(
        ticksPosition &&
          ticksPosition.length &&
          ticksPosition.length === data.labels.length,
      ) && (
        <Box
          sx={{
            position: "relative",
            maxWidth: maxChartWidth,
            width: "100%",
            minWidth: minChartWidth,
            height: labelsOverlap ? 60 : 40,
          }}
        >
          {data.labels.map((l, idx) => (
            <Typography
              key={idx}
              variant="par02Regular"
              sx={{
                position: "absolute",
                left: ticksPosition[idx],
                top: idx % 2 && labelsOverlap ? 20 : 0,
                translate: "-50% 0",
                overflow: "visible",
                padding: "0 8px",
              }}
              noWrap
              ref={(val) => {
                labelWidthsRef.current[idx] = val?.clientWidth || 0;
                updateLabelWidths();
              }}
            >
              {l}
            </Typography>
          ))}
        </Box>
      )}
    </Box>
  );
};
