import { PDFViewer, Document } from "@react-pdf/renderer";
import { Box } from "@mui/system";
import { FooterProps, usePdfFontLoad } from "components/Pdf";
import { useUser } from "../../pages/settings/useUser";
import { Typography } from "@mui/material";
import { usePlanTypes } from "app/usePlanTypes";
import { useFirm } from "../../pages/settings/useFirm";
import { useSearchParams } from "react-router-dom";
import { usePlanTypeId } from "app/usePlanId";
import { useClientName } from "app/useClientName";
import { usePublishPlanHouseholdAllocation } from "../publishPlanDialog/usePublishPlanHouseholdAllocation";
import { TitleActionPdfPage } from "./TitleActionPdfPage";
import { GoalsActionPdfPage } from "./GoalsActionPdfPage";
import { RiskBarometerActionPdfPage } from "./RiskBarometerActionPdfPage";
import { RiskReportsActionPdfPage } from "./RiskReportsActionPdfPage";
import { DisclosuresActionPdfPage } from "./DisclosuresActionPdfPage";
import { HouseholdAllocationActionPdfPage } from "./HouseholdAllocationActionPdfPage";
import { ActionPlanActionPdfPage } from "./ActionPlanActionPdfPage";
import { usePublishPlanActionPlan } from "../publishPlanDialog/usePublishPlanActionPlan";
import { usePlanSummary } from "features/planSummary/usePlanSummary";
import { usePlanResults } from "features/planResults/usePlanResults";
import { useDisclosures } from "app/useDisclosures";

export const ActionReport = () => {
  const [searchParams] = useSearchParams();
  const pdfFont = usePdfFontLoad();
  const client = useClientName();
  const user = useUser();
  const firm = useFirm();

  const disclosures = useDisclosures();

  const planTypes = usePlanTypes();
  const planTypeId = usePlanTypeId();
  const portfolioLabel = planTypes.getPlanTypeLabel(planTypeId);

  const planSummary = usePlanSummary();
  const publishPlanHouseholdAllocation = usePublishPlanHouseholdAllocation();
  const planResults = usePlanResults();
  const publishPlanActionPlan = usePublishPlanActionPlan();

  const isLoading =
    pdfFont.isLoading ||
    disclosures.isLoading ||
    client.isLoading ||
    user.isLoading ||
    firm.isLoading ||
    planSummary.isLoading ||
    planResults.isLoading ||
    publishPlanActionPlan.isLoading ||
    planTypes.isLoading;

  const isDraft = searchParams.get("draft") === "true";

  const footerProps = (): FooterProps => ({
    isDraft,
    advisorCompanyName: firm.firm.name ?? "",
    clientName: client.clientFullName,
  });

  const getDocument = () => (
    <PDFViewer style={{ position: "absolute" }} width={"100%"} height={"100%"}>
      <Document>
        <TitleActionPdfPage
          clientName={client.clientFullName}
          advisorName={user.userFullName}
          logo={firm.firm.logo ?? undefined}
          portfolioLabel={portfolioLabel}
          footerProps={footerProps()}
        />
        <GoalsActionPdfPage
          goals={planSummary.goals}
          donuts={planResults.donuts}
          footerProps={footerProps()}
        />
        <HouseholdAllocationActionPdfPage
          publishPlanHouseholdAllocation={publishPlanHouseholdAllocation}
          footerProps={footerProps()}
        />
        <RiskBarometerActionPdfPage
          planResults={planResults}
          footerProps={footerProps()}
        />
        <RiskReportsActionPdfPage
          planResults={planResults}
          footerProps={footerProps()}
        />
        <ActionPlanActionPdfPage
          publishPlanActionPlan={publishPlanActionPlan}
          footerProps={footerProps()}
        />
        <DisclosuresActionPdfPage
          disclosures={disclosures}
          footerProps={footerProps()}
        />
      </Document>
    </PDFViewer>
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255,255,255, 0.9)",
      }}
    >
      <Typography variant="h4SemiBold">
        {isLoading ? "Loading Data..." : "Rendering Document..."}
      </Typography>
      {!isLoading && getDocument()}
    </Box>
  );
};
