import clsx from "clsx";
import { Box, BoxProps, ButtonBase, styled } from "@mui/material";
import { ReactElement } from "react";
import { CarTooltipBox } from "./TooltipBox";

export const CarFlatPageControlTab = styled(ButtonBase)(({ theme }) => ({
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  ...theme.typography.par01Regular,
  position: "relative",
  "&.selected": {
    color: theme.palette.primary.main,
    "&::after": {
      content: '""',
      width: "100%",
      bottom: -1,
      position: "absolute",
      borderBottom: `solid 2px ${theme.palette.primary.main}`,
    },
  },
}));

export interface CarFlatPageItem<T> {
  label: string;
  className?: string;
  pendoClassName?: string;
  value: T;
  content?: ReactElement;
}
export interface CarFlatPageControlProps<T> {
  value?: T;
  className?: string;
  sx?: BoxProps["sx"];
  items: CarFlatPageItem<T>[];
  onChange: (value: T) => void;
}

export const CarFlatPageControl = <T,>(props: CarFlatPageControlProps<T>) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      className={props.className}
    >
      <Box
        sx={{ ml: 1, display: "flex", justifyContent: "start" }}
        className={"flat-page-control-header"}
      >
        {props.items.map((i, idx) => (
          <CarFlatPageControlTab
            key={idx}
            className={clsx(
              "flat-page-control-button",
              { selected: props.value === i.value },
              i.className,
            )}
            onClick={(e) => props.onChange(i.value)}
          >
            {i.label}
            {i.pendoClassName && (
              <CarTooltipBox
                sx={{ ml: 0.5, mr: -1 }}
                className={i.pendoClassName}
              />
            )}
          </CarFlatPageControlTab>
        ))}
      </Box>
      {props.items.find((i) => i.value === props.value)?.content}
    </Box>
  );
};
