import { Box, BoxProps, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo, useMemo } from "react";
import {
  FlatPageRouteControl,
  FlatPageRouteTab,
} from "components/FlatPageRouteControl";
import { SimulationModelsModels } from "./SimulationModelsModels";
import { SimulationModelsRun } from "./SimulationModelsRun";
import { SimulationModelsBehavior } from "./SimulationModelsBehavior";

interface PagesProps {
  sx?: BoxProps["sx"];
}

const Pages = (props: PagesProps) => {
  const tabs = useMemo<Array<FlatPageRouteTab>>(() => {
    return [
      {
        url: "models",
        label: "Models",
        content: <SimulationModelsModels />,
      },
      {
        url: "behavior",
        label: "Behavior",
        content: <SimulationModelsBehavior />,
      },
      {
        url: "run",
        label: "Run",
        content: <SimulationModelsRun />,
      },
    ];
  }, []);
  return <FlatPageRouteControl tabs={tabs} sx={props.sx} />;
};

export const SimulationModels = memo(() => {
  return (
    <TablePlaceHolder sx={{ mx: 7, mt: 10 }} isLoading={false} rows={8}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
          Models
        </Typography>
        <Typography variant="mediumItalic">
          Source Data as of: MM/DD/YYYY
        </Typography>
      </Box>
      <Pages sx={{ mt: 4 }} />
    </TablePlaceHolder>
  );
});
