import { Box, styled } from "@mui/material";

export const AnalyzeModuleStyledPage = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(4.25),
  paddingRight: theme.spacing(4.25),
  backgroundColor: theme.palette.gray1,
  borderRadius: "5px",
  borderColor: theme.palette.gray6,
  borderStyle: "solid",
  borderWidth: "1px",
}));
