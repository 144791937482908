import { BoxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CarRadioYesNo } from "components/Radio";
import { RootPageBox } from "components/RootPage";
import { CarTooltip } from "components/Tooltip";
import { PlanProposedAllocationTable } from "features/householdAllocation/PlanProposedAllocationTable";
import {
  BreakdownChartItem,
  usePublishPlanHouseholdAllocation,
} from "features/publishPlanDialog/usePublishPlanHouseholdAllocation";
import _ from "lodash";
import { ReactElement, useState } from "react";
import { Pie } from "react-chartjs-2";
import { formatCurrency, formatPercent } from "utils";

const Chart = (props: {
  items: BreakdownChartItem[];
  title: string;
  isPercentMode: boolean;
}) => {
  const [hintValue, setHintValue] = useState<BreakdownChartItem | undefined>();
  const data = props.items.filter((v) =>
    props.isPercentMode ? v.percent > 0 : v.dollars > 0
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 4,
      }}
    >
      <Typography variant="h6SemiBold">{props.title}</Typography>
      <CarTooltip
        title={
          <>
            <Typography variant="par01SemiBold">
              {hintValue?.parentLabel ?? ""}
            </Typography>
            <Typography variant="par01Regular">
              {`${hintValue?.label ?? ""}: ${
                props.isPercentMode
                  ? formatPercent(hintValue?.percent ?? 0)
                  : formatCurrency(hintValue?.dollars ?? 0)
              }`}
            </Typography>
          </>
        }
        followCursor
        arrow={false}
      >
        <Box>
          <Pie
            width={272}
            height={272}
            options={{
              responsive: false,
              animation: {
                duration: 0,
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              borderColor: "transparent",
              onHover(event, elements, chart) {
                setHintValue(data[elements[0]?.index]);
              },
            }}
            data={{
              labels: data.map((i) => i.label),
              datasets: [
                {
                  label: "data",
                  data: data.map((i) =>
                    props.isPercentMode ? i.percent : i.dollars
                  ),
                  backgroundColor: data.map((i) => i.color),
                },
              ],
            }}
          />
        </Box>
      </CarTooltip>
    </Box>
  );
};

const ChartLegend = (props: { items: BreakdownChartItem[] }) => {
  const sortedItems = _.uniqBy(
    props.items
      .filter((v) => v.percent > 0)
      .sort((a, b) => b.percent - a.percent),
    "color"
  );

  return (
    <Box
      sx={{
        mt: 6,
        alignSelf: "center",
        width: 700,
        display: "flex",
        columnGap: 4,
        rowGap: 2,
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {sortedItems.map((i) => (
        <Box
          key={i.color}
          sx={{ flex: "none", display: "flex", gap: 1, alignItems: "center" }}
        >
          <Box
            sx={{
              width: 22,
              height: 22,
              borderRadius: "4px",
              backgroundColor: i.color,
            }}
          />
          <Typography variant="par02Regular">{i.label}</Typography>
        </Box>
      ))}
    </Box>
  );
};

interface PublishPlanRecommendedChangesProps {
  sx?: BoxProps["sx"];
  footer: ReactElement;
}

export const PublishPlanRecommendedChanges = ({
  sx,
  footer,
}: PublishPlanRecommendedChangesProps) => {
  const { portfolioLabel, allocAssets, existingChart, proposedChart } =
    usePublishPlanHouseholdAllocation();

  const [isPercentMode, setIsPercentMode] = useState(true);

  return (
    <RootPageBox sx={{ p: 5, pb: 8, ...sx }}>
      <Typography variant="h4SSemiBold">
        Recommended Changes in Portfolio Strategy
      </Typography>
      <Box sx={{ mt: 4, display: "flex", gap: 4, alignItems: "center" }}>
        <Typography variant="par02Regular">
          How would you like the allocation displayed?
        </Typography>
        <CarRadioYesNo
          row
          yesLabel="%"
          noLabel="$"
          value={isPercentMode}
          onChange={setIsPercentMode}
        />
      </Box>

      <Box sx={{ mt: 15, display: "flex", justifyContent: "center", gap: 18 }}>
        <Chart
          isPercentMode={isPercentMode}
          title="Existing Strategy:"
          items={existingChart}
        />
        <Chart
          isPercentMode={isPercentMode}
          title={`${portfolioLabel} Strategy:`}
          items={proposedChart}
        />
      </Box>
      <ChartLegend items={[...existingChart, ...proposedChart]} />
      <PlanProposedAllocationTable
        sx={{ mt: 5, mb: 4 }}
        items={allocAssets.level1}
        isPercentMode={isPercentMode}
        portfolioLabel={portfolioLabel}
      />
      {footer}
    </RootPageBox>
  );
};
