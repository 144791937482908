import { Box, BoxProps, styled } from "@mui/material";
import { isDebug } from "const";

export const CarTooltipBox = styled((props: BoxProps) => (
  <Box
    onClick={() => {
      if (isDebug) {
        navigator.clipboard.writeText(
          props.className?.split(" ").find((i) => i.startsWith("pendo")) ?? "",
        );
      }
    }}
    {...props}
  />
))(({ theme }) => ({
  width: 18,
  height: 18,
  position: "relative",
  // marginLeft: theme.spacing(0.5),
  // display: "inline-block",
  // verticalAlign: "text-bottom",
  flex: "none",
  "&::after": isDebug
    ? {
        top: 2,
        left: 2,
        position: "absolute",
        content: '""',
        width: 14,
        height: 14,
        // border: "solid 1px rgba(0,0,0,0)",
        borderRadius: "50%",
        backgroundColor: "pink",
      }
    : undefined,
}));
