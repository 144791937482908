import ReactPDF, {
  Circle,
  Font,
  G,
  Page,
  Path,
  Svg,
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import { primaryColor } from "../theme";
import ReactMarkdown from "react-markdown";

// @ts-ignore
import gelasioRegular from "assets/fonts/proximanova-regular-webfont.woff";
// @ts-ignore
import gelasioBold from "assets/fonts/proximanova-bold-webfont.woff";
// @ts-ignore
import gelasioSemiBold from "assets/fonts/proximanova-semibold-webfont.woff";
// @ts-ignore
import gelasioItalic from "assets/fonts/proximanova-regularit-webfont.woff";
// @ts-ignore
import robotoRegular from "assets/fonts/proximanova-regular-webfont.woff";
// @ts-ignore
import robotoBold from "assets/fonts/proximanova-bold-webfont.woff";
// @ts-ignore
import robotoItalic from "assets/fonts/proximanova-regularit-webfont.woff";

// proximanova - bold - webfont.woff;
// proximanova - regular - webfont.woff;
// proximanova - regularit - webfont.woff;

import { PropsWithChildren, useState } from "react";
import { useMount } from "app/useMount";
import { getChartData } from "chartUtils";
import { ChartConfiguration } from "chart.js";

const fontGelasio = "pdfGelasio";
const fontRoboto = "pdfRoboto";

const tableFontNormalSize = 10;
const tableFontSize = 8;

export const fontPdf = {
  main: {
    family: fontGelasio,
    size: 12,
    sectionSize: 16,
    subSectionSize: 13,
  },
  table: {
    family: fontRoboto,
    normal: {
      size: tableFontNormalSize,
      headerSize: tableFontNormalSize + 1,
    },
    dense: {
      size: tableFontSize,
      headerSize: tableFontSize + 1,
    },
  },
};

export const usePdfFontLoad = () => {
  const [isLoading, setIsLoading] = useState(true);

  useMount(() => {
    document.fonts.add(new FontFace(fontRoboto, `url(${robotoRegular})`));
    document.fonts.add(
      new FontFace(fontRoboto, `url(${robotoBold})`, {
        weight: "700",
      }),
    );
    document.fonts.add(
      new FontFace(fontRoboto, `url(${robotoItalic})`, {
        style: "italic",
      }),
    );

    Promise.all(Array.from(document.fonts.values()).map((i) => i.load()))
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        console.error("usePdfFontLoad", e);
        setIsLoading(false);
      });
  });

  return {
    isLoading,
  };
};

Font.register({
  family: fontGelasio,
  fonts: [
    {
      src: gelasioRegular,
    },
    {
      fontWeight: "semibold",
      src: gelasioSemiBold,
    },
    {
      fontWeight: "bold",
      src: gelasioBold,
    },
    {
      fontStyle: "italic",
      src: gelasioItalic,
    },
  ],
});

Font.register({
  family: fontRoboto,
  fonts: [
    {
      src: robotoRegular,
    },
    {
      fontWeight: "bold",
      src: robotoBold,
    },
    {
      fontStyle: "italic",
      src: robotoItalic,
    },
  ],
});

export interface FooterProps {
  isDraft?: boolean;
  advisorCompanyName: string;
  clientName: string;
  onRenderPageNumber?: (pageNumber: number) => void;
}

const Footer = (props: FooterProps) => (
  <View
    fixed
    // debug
    style={{
      position: "absolute",
      bottom: 38,
      left: 0,
      right: 0,
      textAlign: "center",
      fontFamily: fontPdf.table.family,
      fontStyle: "italic",
      fontSize: fontPdf.table.normal.size,
      paddingHorizontal: 46,
    }}
  >
    <Text style={{ fontSize: fontPdf.table.dense.size, lineHeight: 1.5 }}>
      This report, and its hypothetical illustrations, are intended to form a
      basis for further discussion with your legal, accounting and financial
      advisors.
    </Text>
    <Text style={{ fontSize: fontPdf.table.dense.size, lineHeight: 1.5 }}>
      Actual future investment returns, taxes and inflation are unknown. Do not
      rely upon this report to predict future investment performance.
    </Text>
    <View
      style={{
        backgroundColor: "#c3c3c3",
        height: 1,
      }}
    />
    <View
      style={{
        marginTop: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <Text>{props.advisorCompanyName}</Text>
      <Text
        style={{ position: "absolute", width: "100%", textAlign: "center" }}
        render={({ pageNumber, totalPages }) => {
          props.onRenderPageNumber?.(pageNumber);
          return `${pageNumber} / ${totalPages}`;
        }}
      />
      <Text>Created for: {props.clientName}</Text>
    </View>
  </View>
);

export interface PdfPageProps extends PropsWithChildren<ReactPDF.PageProps> {
  hideFooter?: boolean;
  footerProps: FooterProps;
  onRenderPageNumber?: (pageNumber: number) => void;
}

export const PdfPage = ({
  hideFooter,
  children,
  style,
  ...props
}: PdfPageProps) => (
  <Page
    size="LETTER"
    style={{
      paddingTop: 45,
      paddingBottom: 90,
      paddingHorizontal: 46,
      fontFamily: fontPdf.main.family,
      fontStyle: "normal",
      fontSize: fontPdf.main.size,
      ...style,
    }}
    {...props}
  >
    <View
      style={{
        position: "absolute",
        width: 10,
        height: "100vh",
        backgroundColor: primaryColor,
      }}
      fixed
    />
    {children}
    {!hideFooter && <Footer {...props.footerProps} />}
    {props.footerProps.isDraft && (
      <Svg
        viewBox="0 0 210 297"
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          height: "100vh",
          width: "100vw",
        }}
        fixed
      >
        <G
          transform="matrix(0.60508539,-0.86607874,0.86607874,0.60508539,-1.5095329,-7.8027002)"
          opacity="0.15"
          fill="#000"
        >
          <Path d="m -165.9403,197.30228 v -48.7839 h 15.01043 q 6.43304,0 11.49236,2.91498 5.09282,2.88146 7.94078,8.24233 2.84796,5.32736 2.84796,12.12896 v 2.24486 q 0,6.8016 -2.81445,12.09546 -2.78095,5.29386 -7.87378,8.20883 -5.09282,2.91497 -11.49236,2.94848 z m 10.05163,-40.64208 v 32.56727 h 4.85828 q 5.89696,0 9.01296,-3.85312 3.116,-3.85313 3.18302,-11.02329 v -2.57991 q 0,-7.43821 -3.0825,-11.25783 -3.0825,-3.85312 -9.01296,-3.85312 z" />
          <Path d="m -103.28516,179.44389 h -8.0078 v 17.85839 h -10.05163 v -48.7839 h 18.12644 q 8.644396,0 13.335155,3.85313 4.690758,3.85312 4.690758,10.88926 0,4.99231 -2.177852,8.34285 -2.144347,3.31704 -6.533557,5.29386 l 10.554208,19.93572 v 0.46908 h -10.788746 z m -8.0078,-8.14182 h 8.10831 q 3.786115,0 5.863451,-1.90981 2.077336,-1.94331 2.077336,-5.32736 0,-3.45106 -1.97682,-5.42788 -1.943314,-1.97682 -5.997467,-1.97682 h -8.07481 z" />
          <Path d="m -50.011546,187.25065 h -17.623851 l -3.350542,10.05163 h -10.68823 l 18.159939,-48.7839 h 9.314507 l 18.260454,48.7839 h -10.688229 z m -14.909912,-8.14182 h 12.195973 l -6.131492,-18.26045 z" />
          <Path d="M -2.0317818,177.36655 H -21.330904 v 19.93573 h -10.051627 v -48.7839 H 0.38060851 v 8.14182 H -21.330904 v 12.59804 h 19.2991222 z" />
          <Path d="M 43.602594,156.6602 H 28.659177 v 40.64208 H 18.60755 V 156.6602 H 3.865165 v -8.14182 h 39.737429 z" />
        </G>
      </Svg>
    )}
  </Page>
);

export interface PdfSectionLabelProps
  extends Omit<ReactPDF.ViewProps, "children"> {
  label: string;
}

export const PdfSectionLabel = ({ label, ...props }: PdfSectionLabelProps) => (
  <View {...props}>
    <Text style={{ fontSize: fontPdf.main.sectionSize }}>{label}</Text>
    <View
      style={{
        backgroundColor: primaryColor,
        marginTop: 10,
        width: 50,
        height: 2,
        marginBottom: 10,
      }}
    />
  </View>
);

export const PdfSubSectionLabel = ({
  label,
  style,
  ...props
}: PdfSectionLabelProps) => (
  <View
    {...props}
    style={{ flexDirection: "row", alignItems: "center", ...style }}
  >
    <View
      style={{
        backgroundColor: primaryColor,
        width: 27,
        marginRight: 5,
        height: 2,
      }}
    />
    <Text style={{ fontSize: fontPdf.main.subSectionSize }}>{label}</Text>
  </View>
);

export const HeaderCell = ({
  children,
  style,
  ...props
}: PropsWithChildren<ReactPDF.TextProps>) => (
  <Text
    {...props}
    style={{
      borderLeftWidth: 1,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: "#c3c3c3",
      backgroundColor: "#dde0e3",
      width: "100%",
      paddingHorizontal: 1,
      paddingVertical: 2,
      textAlign: "center",
      ...style,
    }}
  >
    {children}
  </Text>
);

export const TextCell = ({
  children,
  style,
  ...props
}: PropsWithChildren<ReactPDF.TextProps>) => (
  <Text
    style={{
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderColor: "#c3c3c3",
      width: "100%",
      overflow: "hidden",
      paddingVertical: 4,
      textAlign: "center",
      flexShrink: 1,
      ...style,
    }}
    {...props}
  >
    {children}
  </Text>
);

export const ViewCell = ({
  children,
  style,
  ...props
}: PropsWithChildren<ReactPDF.ViewProps>) => (
  <View
    style={{
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderColor: "#c3c3c3",
      width: "100%",
      overflow: "hidden",
      // paddingVertical: 4,
      textAlign: "center",
      flexShrink: 1,
      flexDirection: "row",
      ...style,
    }}
    {...props}
  >
    {children}
  </View>
);

export const FailureIcon = () => (
  <Svg style={{ width: 20, height: 20 }} viewBox="0 0 24 24">
    <Path
      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      fill="#e41420"
    />
  </Svg>
);
export const PartialIcon = () => (
  <Svg style={{ width: 20, height: 20 }} viewBox="0 0 24 24">
    <Circle cx="12" cy="12" r="8" fill="#ffeb3c" />
  </Svg>
);

export const SuccessIcon = () => (
  <Svg style={{ width: 20, height: 20 }} viewBox="0 0 24 24">
    <Path
      d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
      fill="#52bc5d"
    />
  </Svg>
);

export const GoalsLegend = ({
  children,
  style,
  ...props
}: PropsWithChildren<ReactPDF.ViewProps>) => (
  <View
    {...props}
    style={{
      marginVertical: 20,
      marginHorizontal: 40,
      flexDirection: "row",
      justifyContent: "space-around",
      fontFamily: fontPdf.table.family,
      fontSize: fontPdf.table.normal.headerSize,
      ...style,
    }}
  >
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <SuccessIcon />
      <Text style={{ marginLeft: 8 }}>Goal passed</Text>
    </View>
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <PartialIcon />
      <Text style={{ marginLeft: 8 }}>Goal partially funded</Text>
    </View>
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <FailureIcon />
      <Text style={{ marginLeft: 8 }}>Goal failed</Text>
    </View>
  </View>
);

interface PdfMarkdownProps extends Omit<ReactPDF.ViewProps, "children"> {
  children: string;
}

export const PdfMarkdown = ({ children, ...props }: PdfMarkdownProps) => (
  <View {...props}>
    {children.split("\n").map((i, idx) =>
      i.trim() ? (
        <Text>
          <ReactMarkdown
            key={idx}
            components={{
              h1: (props) => (
                <Text
                  style={{
                    fontSize: fontPdf.main.size + 5,
                    fontWeight: "bold",
                  }}
                >
                  {props.children}
                </Text>
              ),
              h2: (props) => (
                <Text
                  style={{
                    fontSize: fontPdf.main.size + 4,
                    fontWeight: "bold",
                  }}
                >
                  {props.children}
                </Text>
              ),
              h3: (props) => (
                <Text
                  style={{
                    fontSize: fontPdf.main.size + 3,
                    fontWeight: "bold",
                  }}
                >
                  {props.children}
                </Text>
              ),
              h4: (props) => (
                <Text
                  style={{
                    fontSize: fontPdf.main.size + 2,
                    fontWeight: "bold",
                  }}
                >
                  {props.children}
                </Text>
              ),
              h5: (props) => (
                <Text
                  style={{
                    fontSize: fontPdf.main.size + 1,
                    fontWeight: "bold",
                  }}
                >
                  {props.children}
                </Text>
              ),
              h6: (props) => (
                <Text
                  style={{ fontSize: fontPdf.main.size, fontWeight: "bold" }}
                >
                  {props.children}
                </Text>
              ),
              div: (props) => <Text>{props.children}</Text>,
              span: (props) => <Text>{props.children}</Text>,
              pre: (props) => <Text>{props.children}</Text>,
              code: (props) => <Text>{props.children}</Text>,
              hr: (props) => (
                <Text
                  debug
                  style={{
                    height: 10,
                    width: "100%",
                    borderBottom: "1px solid black",
                  }}
                >
                  {props.children}
                </Text>
              ),
              p: (props) => (
                <Text
                  style={{
                    display: "flex",
                    marginTop: 100,
                    fontSize: fontPdf.main.size,
                    // lineHeight: 2,
                  }}
                >
                  {props.children}
                </Text>
              ),
              strong: (props) => (
                <Text style={{ fontWeight: "bold" }}>{props.children}</Text>
              ),
              em: (props) => (
                <Text style={{ fontStyle: "italic" }}>{props.children}</Text>
              ),
            }}
          >
            {i}
          </ReactMarkdown>
        </Text>
      ) : (
        <View style={{ height: fontPdf.main.size }} />
      ),
    )}
  </View>
);

interface PdfCheckboxProps extends Omit<ReactPDF.ViewProps, "children"> {
  children: string;
}

export const PdfCheckbox = ({
  children,
  style,
  ...props
}: PdfCheckboxProps) => (
  <View style={{ flexDirection: "row", ...style }}>
    <Svg style={{ width: 16, height: 16, marginRight: 5 }} viewBox="0 0 24 24">
      <Path
        d="M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M19,5V19H5V5H19M10,17L6,13L7.41,11.58L10,14.17L16.59,7.58L18,9"
        fill={primaryColor}
      />
    </Svg>

    <Text
      style={{
        fontSize: fontPdf.main.size,
        fontStyle: "italic",
        lineHeight: "1.1",
      }}
    >
      {children}
    </Text>
  </View>
);

export interface PdfChartProps extends Omit<ReactPDF.ImageProps, "source"> {
  width: number;
  height: number;
  config: ChartConfiguration;
}

export const PdfChart = ({
  style,
  width,
  height,
  config,
  ...props
}: PdfChartProps) => (
  <Image
    style={{ ...style, width, height }}
    source={getChartData(width / 0.75, height / 0.75, config)}
    {...props}
  />
);
