import { Box, BoxProps, styled, Typography } from "@mui/material";
import { formatPercentFactor } from "utils";
import {
  CarSimulationSummaryColumn,
  CarSimulationSummaryRow,
  CarSimulationSummaryTable,
} from "./useSimulationABTestSummary";
import { CarSortIcon } from "components/SortIcon";
import { useState } from "react";

type SortField = "valueA" | "valueB" | "diff";

interface SortOrder {
  columnIndex: number;
  field: SortField;
  isAscending: boolean;
}

const SortIcon = styled(CarSortIcon)(({ theme }) => ({
  display: "inline-block",
  marginLeft: theme.spacing(0.3),
}));

const formatValue = (value?: number | null) =>
  value
    ? formatPercentFactor(value, {
        decimalPlaces: 2,
        forceShowDecimals: true,
      })
    : "-";

interface LabelHeaderProps {
  sx?: BoxProps["sx"];
  label?: string;
}

const LabelHeader = (props: LabelHeaderProps) => {
  return (
    <Box
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "table.background.header",
        "&:last-of-type": {
          borderRightWidth: 1,
        },
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
            backgroundColor: "white",
          },
        },
        ...props.sx,
      }}
    >
      <Box
        sx={{
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.label && (
          <Typography variant="h6SBold">{props.label}</Typography>
        )}
      </Box>
      <Box sx={{ flex: "auto" }} />
    </Box>
  );
};

const getGridTemplateColumns = (columnCount: number) =>
  `1.3fr repeat(${columnCount}, 1fr)`;

interface ColumnHeaderProps {
  sx?: BoxProps["sx"];
  column: CarSimulationSummaryColumn;
  columnIndex: number;
  sortOrder?: SortOrder;
  onSortOrderChange: (value?: SortOrder) => void;
}

const ColumnHeader = (props: ColumnHeaderProps) => {
  const getIsSorted = (field: SortField) =>
    props.columnIndex === props.sortOrder?.columnIndex &&
    field === props.sortOrder.field;

  const handleSort = (field: SortField) => {
    if (getIsSorted(field)) {
      if (props.sortOrder?.isAscending) {
        props.onSortOrderChange({
          columnIndex: props.columnIndex,
          field,
          isAscending: false,
        });
      } else {
        props.onSortOrderChange(undefined);
      }
    } else {
      props.onSortOrderChange({
        columnIndex: props.columnIndex,
        field,
        isAscending: true,
      });
    }
  };

  return (
    <Box
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        borderLeftWidth: 2,
        borderRightWidth: 2,
        backgroundColor: "table.background.header",
        "&:last-of-type": {
          borderRightWidth: 1,
        },
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
          },
        },
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          px: 1,
          py: 2,
          height: 60,
          width: "100%",
        }}
      >
        <Typography variant="h6SBold">{props.column.label}</Typography>
      </Box>
      <Box
        sx={{
          flex: "auto",
          display: "grid",
          gridTemplateColumns: `repeat(3, 1fr)`,
          "& > *": {
            backgroundColor: "white",
            borderStyle: "solid",
            borderColor: "table.border",
            borderWidth: 0,
            borderLeftWidth: 1,
            "&:first-of-type": {
              borderLeftWidth: 0,
            },
            py: 1.5,
            px: 0.5,
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          },
        }}
      >
        <Typography
          variant="par01Regular"
          sx={{ cursor: "pointer", userSelect: "none" }}
          onClick={() => handleSort("valueA")}
        >
          Sim A
          <SortIcon isSorted={getIsSorted("valueA")} />
        </Typography>
        <Typography
          variant="par01Regular"
          sx={{ cursor: "pointer", userSelect: "none" }}
          onClick={() => handleSort("valueB")}
        >
          Sim B<SortIcon isSorted={getIsSorted("valueB")} />
        </Typography>
        <Typography
          variant="par01Regular"
          sx={{ cursor: "pointer", userSelect: "none" }}
          onClick={() => handleSort("diff")}
        >
          Diff
          <SortIcon isSorted={getIsSorted("diff")} />
        </Typography>
      </Box>
    </Box>
  );
};

interface RowProps {
  sx?: BoxProps["sx"];
  row: CarSimulationSummaryRow;
}

const Row = (props: RowProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        minHeight: 60,
        gridTemplateColumns: "1fr",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(props.row.values.length),
        }}
      >
        <Box
          sx={{
            marginTop: "-1px",
            marginLeft: "-1px",
            border: 1,
            borderColor: "table.border",
            p: 1.5,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Typography variant="par02Regular">{props.row.label}</Typography>
        </Box>
        {props.row.values.map((val, idx) => {
          return (
            <Box
              key={idx}
              sx={{
                marginTop: "-1px",
                marginLeft: "-1px",
                border: 1,
                borderColor: "table.border",
                borderLeftWidth: 2,
                borderRightWidth: 2,
                "&:last-of-type": {
                  borderRightWidth: 1,
                },

                display: "grid",
                gridTemplateColumns: `repeat(3, 1fr)`,

                "& > *": {
                  borderStyle: "solid",
                  borderColor: "table.border",
                  borderWidth: 0,
                  borderLeftWidth: 1,
                  "&:first-of-type": {
                    borderLeftWidth: 0,
                  },

                  overflow: "hidden",
                  textOverflow: "ellipsis",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 1,
                  "& > *": {
                    maxWidth: 54,
                  },
                },
              }}
            >
              <Typography variant="par02Regular">
                {formatValue(val.valueA)}
              </Typography>
              <Typography variant="par02Regular">
                {formatValue(val.valueB)}
              </Typography>
              <Typography variant="par02Regular">
                {formatValue(val.diff)}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export interface SimulationABTestSummaryTableProps {
  sx?: BoxProps["sx"];
  table: CarSimulationSummaryTable;
}

export const SimulationABTestSummaryTable = ({
  sx,
  table,
}: SimulationABTestSummaryTableProps) => {
  const [sortOrder, setSortOrder] = useState<SortOrder>();

  const columns = table.columns;
  const rows = Array.from(table.rows);

  rows.sort((a, b) => {
    if (!sortOrder) {
      return 0;
    }

    const sortA = a.values.at(sortOrder.columnIndex)?.[sortOrder.field] ?? -1;
    const sortB = b.values.at(sortOrder.columnIndex)?.[sortOrder.field] ?? -1;

    return sortOrder.isAscending ? sortA - sortB : sortB - sortA;
  });

  return (
    <Box
      sx={{
        display: "grid",
        borderColor: "table.border",
        ".MuiTypography-root": {
          color: "table.text",
        },
        "& > *:first-of-type > *:first-of-type": {
          borderTopLeftRadius: "5px",
        },
        "& > *:first-of-type > *:last-of-type": {
          borderTopRightRadius: "5px",
        },
        "& > *:last-of-type > * > *:first-of-type": {
          borderBottomLeftRadius: "5px",
        },
        "& > *:last-of-type > * > *:last-of-type": {
          borderBottomRightRadius: "5px",
        },
        "& > *:nth-of-type(even)": {
          backgroundColor: "table.background.even",
        },
        "& > *:nth-of-type(odd)": {
          backgroundColor: "table.background.odd",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(table.columns.length),
          position: "sticky",
          top: 0,
          backgroundColor: "table.header",
          zIndex: 10,
        }}
      >
        <LabelHeader />
        {columns.map((i, idx) => (
          <ColumnHeader
            key={i.id}
            column={i}
            columnIndex={idx}
            sortOrder={sortOrder}
            onSortOrderChange={setSortOrder}
          />
        ))}
      </Box>
      {rows.map((i) => (
        <Row key={i.id} row={i} />
      ))}
    </Box>
  );
};
