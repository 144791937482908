import { Box, BoxProps, Theme, useTheme } from "@mui/material";
import { ChartDataset } from "chart.js";
import { Chart } from "react-chartjs-2";
import { useMemo, useRef } from "react";
import { formatCurrency } from "utils";
import {
  ChartTooltip,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { WorkbenchInflationSmall } from "../WorkbenchInflation";
import { createPendoClassName } from "app/thirdParty/pendo";
import { ChartPlugins, ChartUtils } from "chartUtils";

export interface PlanResultCardChartComboProps {
  sx?: BoxProps["sx"];
  pendoPrefix: string;
  datasets: ChartDataset[];
  yearLabels: number[];
  renderTooltip: RenderTooltip;
  maxY?: number;
  fromY?: (v: number) => number;
}

// todo deprecate
function useChartData(props: PlanResultCardChartComboProps, theme: Theme) {
  const { datasets, yearLabels } = props;
  return useMemo(() => {
    const colors = [
      theme.palette.chartsColor.blue,
      theme.palette.chartsColor.purple,
      theme.palette.chartsColor.yellow,
      theme.palette.chartsColor.aqua,
      theme.palette.chartsColor.green,
      theme.palette.chartsColor.red,
      theme.palette.chartsColor.orange,
      theme.palette.chartsColor.darkPurple,
      theme.palette.chartsColor.lightBlue,
      theme.palette.chartsColor.lightOrange,
      theme.palette.chartsColor.lightGreen,
      theme.palette.chartsColor.lightRed,
    ];
    let colorIndex = 0;
    const getColor = () => colors[colorIndex++ % colors.length];

    return {
      datasets: datasets.map((ds) => ({
        ...ds,
        backgroundColor: ds.backgroundColor ?? getColor(),
      })) as ChartDataset[],
      labels: yearLabels,
    };
  }, [datasets, yearLabels, theme]);
}

export function PlanResultCardChartCombo(props: PlanResultCardChartComboProps) {
  const { yearLabels, renderTooltip, maxY, fromY } = props;
  const theme = useTheme();

  const refForPlugin = useRef({ maxY, fromY });
  refForPlugin.current = { maxY, fromY };

  const chartData = useChartData(props, theme);

  const { tooltipPlugin, tooltipData } = useChartTooltip();

  const minX = (yearLabels[0] ?? 0) - 1;
  const maxX = (yearLabels[yearLabels.length - 1] ?? 0) + 1;

  return (
    <Box
      sx={{
        p: 8,
        flex: "1 1 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        position: "relative",
        ...props.sx,
      }}
    >
      <WorkbenchInflationSmall
        className="inflation-switch"
        sx={{ position: "absolute", right: 24, top: 34 }}
      />
      <ChartTooltip
        sx={{
          flex: "1 1 auto",
        }}
        tooltipData={tooltipData}
        renderTooltip={renderTooltip}
      >
        <Chart
          className={createPendoClassName(`${props.pendoPrefix}_chart`)}
          type="bar"
          data={chartData}
          options={{
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              x: {
                type: "linear",
                border: { display: false },
                grid: {
                  color: ChartUtils.gridColor,
                  lineWidth: 1,
                  tickColor: theme.palette.white,
                },
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "600",
                  },
                  color: theme.palette.softBlack,
                  format: {
                    useGrouping: false,
                  },
                  callback: (value) => {
                    return typeof value === "number" && value % 5 === 0
                      ? value
                      : "";
                  },
                },
                min: minX,
                max: maxX,
                offset: false,
              },
              y: {
                beginAtZero: true,
                border: { display: false },
                grid: {
                  color: ChartUtils.gridColor,
                  lineWidth: 1,
                  tickColor: theme.palette.white,
                },
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "600",
                  },
                  color: theme.palette.softBlack,
                  callback: (value) =>
                    typeof value === "number"
                      ? value % 10000 === 0
                        ? `${formatCurrency(value / 1000)}K`
                        : ""
                      : value,
                },
              },
              ...(fromY && maxY
                ? {
                    y2: {
                      position: "right",
                      beginAtZero: true,
                      border: { display: false },
                      grid: {
                        display: false,
                      },
                      ticks: {
                        font: {
                          family: theme.typography.fontFamily,
                          size: 15,
                          weight: "600",
                        },
                        color: theme.palette.softBlack,
                        callback: (value) => {
                          const { maxY, fromY } = refForPlugin.current;
                          return typeof value === "number" && fromY && maxY
                            ? `${formatCurrency(
                                value === maxY
                                  ? Math.round(fromY(value) / 1000)
                                  : Math.round(fromY(value) / 100000) * 100,
                              )}K`
                            : value;
                        },
                      },
                      max: maxY,
                    },
                  }
                : {}),
            },
            plugins: {
              legend: {
                position: "bottom",
                labels: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 14,
                    weight: "400",
                  },
                  boxWidth: 21,
                  boxHeight: 21,
                  usePointStyle: true,
                  pointStyle: "rectRounded",
                },
              },
              tooltip: tooltipPlugin,
              roundedBackground: {
                contextColor: theme.palette.white,
                backgroundColor: theme.palette.gray1,
                borderColor: theme.palette.gray7,
                borderRadius: 5,
              },
            },
          }}
          plugins={[ChartPlugins.roundedBackground]}
        />
      </ChartTooltip>
    </Box>
  );
}
