import { Box, BoxProps, Typography } from "@mui/material";

export const InternalUseWithDisclosures = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      alignSelf: "flex-end",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gap: 1.5,
      ...props.sx,
    }}
  >
    <Typography
      sx={{ alignSelf: "flex-end", color: "black" }}
      variant="mediumItalic"
    >
      INTERNAL USE ONLY
    </Typography>

    <Typography
      sx={{
        color: "caravelOrangePrimary",
        textDecoration: "underline",
        cursor: "pointer",
        alignSelf: "flex-end",
      }}
      component="a"
      variant="mediumItalic"
      href={"/settings/disclosures/allocation"}
      target="_blank"
    >
      *See our disclosures for details
    </Typography>
  </Box>
);

export const InternalUse = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      alignSelf: "flex-end",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gap: 1.5,
      ...props.sx,
    }}
  >
    <Typography
      sx={{ alignSelf: "flex-end", color: "black" }}
      variant="mediumItalic"
    >
      INTERNAL USE ONLY
    </Typography>
  </Box>
);

export const Disclosures = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      alignSelf: "flex-end",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      gap: 1.5,
      ...props.sx,
    }}
  >
    <Typography
      sx={{
        color: "caravelOrangePrimary",
        textDecoration: "underline",
        cursor: "pointer",
        alignSelf: "flex-end",
      }}
      component="a"
      variant="mediumItalic"
      href={"/settings/disclosures/allocation"}
      target="_blank"
    >
      *See our disclosures for details
    </Typography>
  </Box>
);
