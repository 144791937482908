import {
  useAlEnginePlanResultSimulationJobDisclosureRetrieveQuery,
  useAssetClassesFirmAssetClassesListQuery,
} from "api/carApi.generated";
import { useRender } from "app/useRender";

export interface CmaTableRow {
  label: string;
  simulatedMedianTenYearReturn: number;
  simulatedVolatility: number;
  sortOrder: number;
  isEquity: boolean;
}

export const useCma = () => {
  const render = useRender();
  const assetClasses = useAssetClassesFirmAssetClassesListQuery();

  const jobDisclosure =
    useAlEnginePlanResultSimulationJobDisclosureRetrieveQuery(
      {
        id: render.selectedPlanResultId ?? "",
      },
      {
        skip: !render.selectedPlanResultId,
      }
    );

  const items: CmaTableRow[] =
    jobDisclosure.data?.asset_class_results
      .map((i) => {
        const acItem = assetClasses.data?.find(
          (ac) => ac.asset_class?.id === i.asset_class4
        );

        return {
          label: acItem?.asset_class?.display_name ?? i.asset_class4 ?? "",
          sortOrder:
            (acItem?.asset_class?.ac_level3?.sort_order ?? 0) * 1000 +
            (acItem?.asset_class?.sort_order ?? 0),
          isEquity:
            !!acItem?.asset_class?.ac_level3?.ac_level2?.ac_level1?.is_equity,
          simulatedMedianTenYearReturn: i.output_nominal_return_mean ?? 0,
          simulatedVolatility: i.output_nominal_return_sigma ?? 0,
        };
      })
      .sort((a, b) => a.sortOrder - b.sortOrder) ?? [];

  const equityRows = items.filter((i) => i.isEquity);
  const fixedRows = items.filter((i) => !i.isEquity);

  return {
    isLoading:
      assetClasses.isLoading || render.isLoading || jobDisclosure.isLoading,
    equityRows,
    fixedRows,
  };
};

export type UseCma = ReturnType<typeof useCma>;
