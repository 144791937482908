import { ChartConfiguration } from "chart.js";
import { getChartData } from "../../chartUtils";
import { BreakdownChartItem } from "../publishPlanDialog/usePublishPlanHouseholdAllocation";

export interface ActionPdfHouseholdAllocationChartProps {
  items: BreakdownChartItem[];
}

export const getActionPdfHouseholdAllocationChart = ({
  items,
}: ActionPdfHouseholdAllocationChartProps) => {
  const config: ChartConfiguration<"pie"> = {
    type: "pie",
    options: {
      devicePixelRatio: 4,
      responsive: false,
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      borderColor: "transparent",
    },

    data: {
      labels: items.map((i) => i.label),
      datasets: [
        {
          label: "data",
          data: items.map((i) => i.percent),
          backgroundColor: items.map((i) => i.color),
        },
      ],
    },
  };

  return getChartData(122, 122, config as any);
};
