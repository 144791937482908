import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import {
  SimulationDataFilterInput,
  useSimulationQaReportSimDataFilterCreateMutation,
} from "api/carApi.generated";
import { useEffect, useMemo, useState } from "react";
import { useSimulationDebugFieldList } from "./useSimulationDebugFieldList";
import { CarSelectOption } from "components/Inputs";
import { useLocalStorage } from "features/general/useLocalStorage";
import { pendoClasses } from "app/thirdParty/pendo";

export enum ComparisonType {
  moreThan = "moreThan",
  lessThan = "lessThan",
  between = "between",
}

const years = new Array(100).fill(0).map((i, idx) => idx + 1);

export interface SimulationDataExplorerInput {
  assetClassCode?: string;
  filterDataType?: string;
  filterYear?: number;
  filterValueGt?: number;
  filterValueLt?: number;
  comparison?: ComparisonType;
}

interface UseSimulationDataExplorerParams {
  simulationJobId: string;
  defaultInput?: SimulationDataExplorerInput;
  storageKey?: string;
}

export const useSimulationDataExplorerInput = ({
  simulationJobId,
  defaultInput,
  storageKey,
}: UseSimulationDataExplorerParams) => {
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId,
  });
  const debugFieldsList = useSimulationDebugFieldList();

  const [input, setInput] = useLocalStorage<SimulationDataExplorerInput>(
    storageKey ? `${storageKey}_dataExplorerInput` : undefined,
    {
      ...defaultInput,
      filterYear: defaultInput?.filterYear ?? years.at(0),
      filterValueGt: defaultInput?.filterValueGt ?? 0,
      filterValueLt: defaultInput?.filterValueLt ?? 0,
      comparison: defaultInput?.comparison ?? ComparisonType.moreThan,
    },
  );

  const [paths, setPaths] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [lastRunInput, setLastRunInput] =
    useState<SimulationDataExplorerInput>();

  const debugFieldItems = useMemo(() => {
    const acItem = assetClasses.items.find(
      (i) => i.code === input.assetClassCode,
    );
    return acItem
      ? acItem.isEquity
        ? debugFieldsList.equityItems
        : debugFieldsList.fixedIncomeItems
      : [];
  }, [debugFieldsList, assetClasses, input.assetClassCode]);

  useEffect(() => {
    if (
      assetClasses.items.length &&
      !assetClasses.items.some((i) => i.code === input.assetClassCode)
    ) {
      setInput((val) => ({
        ...val,
        assetClassCode: assetClasses.items.at(0)?.code,
      }));
    }
  }, [assetClasses.items, input.assetClassCode, setInput]);

  useEffect(() => {
    if (
      debugFieldItems.length &&
      !debugFieldItems.some((i) => i.filterCode === input.filterDataType)
    ) {
      setInput((val) => ({
        ...val,
        filterDataType: debugFieldItems.at(0)?.filterCode,
      }));
    }
  }, [debugFieldItems, input.filterDataType, setInput]);

  const [simDataFilterReq] = useSimulationQaReportSimDataFilterCreateMutation();

  return useMemo(() => {
    const handleRun = async () => {
      setIsLoading(true);
      try {
        const ac_code = input.assetClassCode;
        const filter_data_type = input.filterDataType;
        const filter_year = input.filterYear;

        if (!ac_code || !filter_data_type || !filter_year) {
          return;
        }

        const filter_value_gt =
          (input.comparison === ComparisonType.lessThan
            ? undefined
            : input.filterValueGt) ?? -100000000;
        const filter_value_lt =
          (input.comparison === ComparisonType.moreThan
            ? undefined
            : input.filterValueLt) ?? 100000000;

        const simulationDataFilterInput: SimulationDataFilterInput = {
          simulation_job_id: simulationJobId,
          ac_code,
          filter_data_type,
          filter_year,
          filter_value_gt,
          filter_value_lt,
        };

        setLastRunInput(input);
        setPaths([]);

        const resp = await simDataFilterReq({
          simulationDataFilterInput,
        });

        if ("data" in resp) {
          setPaths(resp.data.path);
        }
      } finally {
        setIsLoading(false);
      }
    };

    return {
      isLoading: assetClasses.isLoading || debugFieldsList.isLoading,
      input,
      setInput,
      assetClassSelectorItems: assetClasses.items,

      debugFieldSelectorOptions: debugFieldItems.map<CarSelectOption<string>>(
        (i) => ({
          value: i.filterCode,
          label: i.label,
          pendoClass: pendoClasses.simulationFieldYAxis(i.filterCode),
        }),
      ),
      yearsSelectorOptions: years.map<CarSelectOption<number>>((i) => ({
        value: i,
        label: i.toString(),
      })),
      isRunning: isLoading,
      paths,
      lastRunInput,
      handleRun,
    };
  }, [
    isLoading,
    paths,
    lastRunInput,
    assetClasses.isLoading,
    assetClasses.items,
    debugFieldItems,
    debugFieldsList.isLoading,
    input,
    simDataFilterReq,
    setInput,
    simulationJobId,
  ]);
};

export type UseSimulationDataExplorerInput = ReturnType<
  typeof useSimulationDataExplorerInput
>;
