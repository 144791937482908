import clsx from "clsx";
import { Box, Typography, styled } from "@mui/material";

// @ts-ignore
import privacyPolicy from "../assets/Privacy Policy for Caravel Concepts.pdf";

export const CarFooter = styled(
  (props: {
    className?: string;
    isWhite?: boolean;
    isCopyrightOnly?: boolean;
    hasPrivacyPolicy?: boolean;
  }) => {
    return (
      <Box className={clsx(props.className, { white: props.isWhite })}>
        <Typography variant="par01Regular" className="copyright">
          {`Copyright Caravel Concepts ${new Date().getFullYear()}`}
        </Typography>
        {props.hasPrivacyPolicy && (
          <>
            <Box className="divider copyright" />
            <Typography
              className="copyright"
              component="a"
              href={privacyPolicy}
              target="_blank"
              variant="par01Regular"
            >
              Privacy Policy
            </Typography>
          </>
        )}
        {!props.isCopyrightOnly && (
          <>
            <Box className="divider" />
            <Typography
              component="a"
              href={"/settings/disclosures/allocation"}
              target="_blank"
              variant="par01Regular"
            >
              Allocation Disclosures
            </Typography>
            <Box className="divider" />
            <Typography
              component="a"
              href={"/settings/disclosures/planning"}
              target="_blank"
              variant="par01Regular"
            >
              Planning Disclosures
            </Typography>
            <Box className="divider" />
            <Typography
              component="a"
              href={"/settings/disclosures/glossary"}
              target="_blank"
              variant="par01Regular"
            >
              Glossary
            </Typography>
          </>
        )}
      </Box>
    );
  },
)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
  ".divider": {
    width: 1,
    height: 12,
    backgroundColor: theme.palette.softBlack,
  },
  ".copyright": {
    fontStyle: "italic",
  },
  a: {
    textDecoration: "none",
    color: theme.palette.softBlack,
  },

  "&.white .divider": {
    backgroundColor: theme.palette.white,
  },
  "&.white > *": {
    color: theme.palette.white,
  },
}));
