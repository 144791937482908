import { Box, BoxProps, IconButton, styled, Typography } from "@mui/material";
import { formatDate, formatPercent, formatPercentFactor } from "utils";
import { CmaModelChartData } from "api/carApi.generated";
import { InternalUse } from "./InternalUse";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const Divider = styled(Box)(({ theme }) => ({
  borderLeft: "1px solid",
  borderColor: theme.palette.gray3,
  alignSelf: "stretch",
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  minHeight: 80,
}));

const NavButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&.MuiIconButton-sizeSmall": {
    width: 20,
    height: 20,
  },
  "&.Mui-disabled": {
    backgroundColor: theme.palette.gray6,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  ".MuiSvgIcon-root": {
    color: theme.palette.white,
  },
}));

const LegendBox = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  border: "1px solid",
  borderColor: theme.palette.gray6,
  borderRadius: "4px",
  flexShrink: 0,
}));

interface NavInfo {
  canGoPrev: boolean;
  goPrev: () => void;
  canGoNext: boolean;
  goNext: () => void;
}

interface ChartDftTotalReturnsSummaryProps extends BoxProps {
  assetClassName: string;
  data: CmaModelChartData;
  showFormula?: boolean;
  realStaticExpectedReturn: number;
  isWhiteContext?: boolean;
  navInfo?: NavInfo;
}

export const ChartDftTotalReturnsSummary = ({
  assetClassName,
  data,
  realStaticExpectedReturn,
  showFormula,
  isWhiteContext,
  navInfo,
  ...props
}: ChartDftTotalReturnsSummaryProps) => (
  <Box
    {...props}
    sx={{
      display: "flex",
      flexDirection: "column",
      ...props.sx,
    }}
  >
    <Box sx={{ display: "flex", alignItems: "flex-end" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
        {navInfo && (
          <Box
            sx={{ mb: 0.25, display: "flex", gap: 1.25, userSelect: "none" }}
          >
            <NavButton
              size="small"
              disabled={!navInfo.canGoPrev}
              onClick={navInfo.goPrev}
            >
              <ChevronLeft sx={{ fontSize: "0.8em" }} />
            </NavButton>
            <NavButton
              size="small"
              disabled={!navInfo.canGoNext}
              onClick={navInfo.goNext}
            >
              <ChevronRight sx={{ fontSize: "0.8em" }} />
            </NavButton>
          </Box>
        )}
        <Typography variant="h6SemiBold">{assetClassName}</Typography>
      </Box>
      <Box
        sx={{
          ml: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <InternalUse />
        <Typography variant="h6SRegular">
          Start Date {formatDate(data.trend_x?.at(0))}
        </Typography>
      </Box>
    </Box>
    <Box
      sx={{
        mt: 1,
        display: "flex",
        justifyContent: "space-around",
        border: "2px solid",
        borderColor: "gray3",
        borderRadius: "5px",
        backgroundColor: isWhiteContext ? "gray1" : "white",
        px: 6,
        py: 2.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          sx={{ color: "softBlack", position: "relative" }}
        >
          Starting DFT
          <CarTooltipBox
            sx={{ position: "absolute", top: -3, right: -22 }}
            className={pendoClasses.assetClassAnalysisDftStartingDft}
          />
        </Typography>
        <Typography variant="h4Bold">
          {formatPercent(data.boundary_scatter_x?.at(0) ?? 0, 2)}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          sx={{ color: "softBlack", position: "relative" }}
        >
          Ending DFT
          <CarTooltipBox
            sx={{ position: "absolute", top: -3, right: -22 }}
            className={pendoClasses.assetClassAnalysisDftEndingDft}
          />
        </Typography>
        <Typography variant="h4Bold">
          {formatPercent(data.label_end_dft ?? 0, 2)}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "softBlack",
            whiteSpace: "pre-line",
            textAlign: "center",
            position: "relative",
          }}
        >
          {`Price-Driven\nExpected Return`}
          <CarTooltipBox
            sx={{ position: "absolute", bottom: 0, right: -22 }}
            className={
              pendoClasses.assetClassAnalysisDftPriceDrivenExpectedReturn
            }
          />
        </Typography>
        <Typography variant="h4Bold">
          {formatPercent(data.label_expected_return ?? 0, 2)}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
          <LegendBox sx={{ backgroundColor: "chartsColor.orange" }} />
          <Typography
            variant="caption"
            sx={{
              color: "softBlack",
              whiteSpace: "pre-line",
              textAlign: "center",
              position: "relative",
            }}
          >
            {`Static Expected\nReturn Trend Line Slope`}
            <CarTooltipBox
              sx={{ position: "absolute", bottom: 0, right: -22 }}
              className={
                pendoClasses.assetClassAnalysisDftStaticExpectedReturnTrendLineSlope
              }
            />
          </Typography>
        </Box>
        <Typography variant="h4Bold" sx={{ color: "chartsColor.orange" }}>
          {formatPercentFactor(realStaticExpectedReturn, { decimalPlaces: 2 })}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 1, alignItems: "flex-start" }}>
          <LegendBox sx={{ backgroundColor: "chartsColor.blue" }} />
          <Typography
            variant="caption"
            sx={{
              color: "softBlack",
              whiteSpace: "pre-line",
              textAlign: "start",
              lineHeight: "24px",
              position: "relative",
            }}
          >
            {`Historic Line ${
              showFormula
                ? `\nFormula: ${data.label_expected_return_formula}`
                : ""
            }`}
            <CarTooltipBox
              sx={{ position: "absolute", top: 2, right: -22 }}
              className={pendoClasses.assetClassAnalysisDftHistoricLine}
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);
