import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarButton } from "components/Buttons";
import { useNavigate } from "react-router-dom";
import { editPortfolioGroupPages } from "route.const";
import { useScrollTo } from "app/useScrollToTop";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { useEditPortfolioGroupSelection } from "./useEditPortfolioGroupSelection";
import {
  CarAssetClassLevelField,
  CarSelectOption,
  CarSelectOptionField,
} from "components/Inputs";
import { useSessionStorage } from "features/general/useLocalStorage";
import { AssetClassLevel } from "const";
import { AssetAllocationModulePieChart } from "./AssetAllocationModulePieChart";
import { CarChartLegend, CarLegendItem } from "components/ChartLegend";
import { AssetAllocationPerModuleTable } from "./AssetAllocationPerModuleTable";
import { CarPortfolioAssetColumnEnum } from "../usePortfolioGroupVersionData";

export const EditPortfolioGroupSelection = () => {
  const data = useEditPortfolioGroupSelection();
  const navigate = useNavigate();
  const scrollTo = useScrollTo();
  const pageContent = usePageContent();

  const [assetClassLevel, setAssetClassLevel] =
    useSessionStorage<AssetClassLevel>(
      "EditPortfolioGroupSelection_assetClassLevel",
      AssetClassLevel.level1,
    );

  const handleNext = () => {
    navigate(`..\\${editPortfolioGroupPages.publish}`); // todo change to reports when reports page is ready
    scrollTo("top");
  };

  const legendItems = new Map<string, CarLegendItem & { sort: number }>();

  const selectedRows = data.tableData.assetRows[assetClassLevel];
  data.tableData.bands.forEach((band, idx) => {
    const columnIdx = band.columns.findIndex(
      (i) => i.dataId === band.selectedDataId,
    );
    if (columnIdx < 0) {
      return;
    }
    const allocatedRows = selectedRows.filter(
      (r) => (r.modules[idx].values[columnIdx].allocation ?? 0) > 0,
    );
    allocatedRows.forEach((ar, idx) =>
      legendItems.set(ar.id, {
        color: ar.color,
        label: ar.name,
        datasetIndex: 0,
        dataIndex: idx,
        sort: ar.sort,
      }),
    );
  });

  return (
    <TablePlaceHolder
      isLoading={data.isLoading || pageContent.isLoading}
      rows={8}
      sx={{ mt: 5 }}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Selection
      </Typography>
      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.PORTFOLIO_GROUP_PUBLISH_SELECTION,
        )}
      />
      <Typography variant="h6SemiBold" sx={{ mt: 5 }}>
        What variations for each model do you want to show up as “Selected” for
        reports and publications?
      </Typography>
      <CarAssetClassLevelField
        sx={{
          width: 150,
          mt: 3.5,
          ".MuiInputLabel-root": {
            fontSize: "16px",
          },
          ".MuiOutlinedInput-root": {
            mt: 3,
          },
          ".MuiInputBase-input": {
            backgroundColor: "gray1",
          },
        }}
        label="Asset Class Level"
        value={assetClassLevel}
        onChange={setAssetClassLevel}
      />

      <Box
        sx={{
          mt: 10,
          alignSelf: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: (175 + 85) * data.tableData.bands.length - 85,
        }}
      >
        {data.tableData.bands.map((band, idx) => (
          <Box
            key={band.moduleId}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 6.5,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  textAlign: "center",
                  maxWidth: 175,
                  width: 175,
                  position: "absolute",
                  top: "calc(-100% - 8px)",
                }}
                variant="h6SBold"
              >
                {band.label}
              </Typography>
              <CarSelectOptionField
                sx={{
                  width: 175,
                  ".MuiInputBase-input": {
                    backgroundColor: "gray1",
                  },
                }}
                options={band.columns
                  .filter((i) => i.dataId)
                  .map<CarSelectOption<string>>((i) => ({
                    value: i.dataId,
                    label: i.label,
                  }))}
                value={band.selectedDataId}
                onChange={(dataId) => {
                  if (dataId) {
                    data.handleSelectedModuleDataChange({
                      moduleId: band.moduleId,
                      dataId,
                    });
                  }
                }}
              />
            </Box>
            <AssetAllocationModulePieChart
              size={180}
              key={band.moduleId}
              dataId={band.selectedDataId}
              moduleId={band.moduleId}
              rows={selectedRows}
            />
          </Box>
        ))}
      </Box>
      <CarChartLegend
        items={Array.from(legendItems.values()).sort((a, b) => a.sort - b.sort)}
        sx={{ mt: 5 }}
      />
      <AssetAllocationPerModuleTable
        sx={{ mt: 5 }}
        columnId={CarPortfolioAssetColumnEnum.selected}
        level={AssetClassLevel.level1}
        hideZeroValues={true}
        tableData={data.tableData}
      />

      <Box
        sx={{ mt: "auto", display: "flex", gap: 2.5, justifyContent: "center" }}
      >
        <CarButton sx={{ mt: 5, fontSize: "18px" }} onClick={handleNext}>
          Next
        </CarButton>
      </Box>
    </TablePlaceHolder>
  );
};
