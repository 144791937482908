import { useMemo, useRef } from "react";
import { isDefined } from "utils";

interface ChartHighlightValue {
  datasetIndex?: number;
  dataIndex?: number;
}

interface ChartHighlightInfo {
  value: ChartHighlightValue;
  subscriptions: Map<any, (value: ChartHighlightValue) => void>;
}

export const useChartHighlight = () => {
  const infoRef = useRef<ChartHighlightInfo>({
    value: { datasetIndex: undefined, dataIndex: undefined },
    subscriptions: new Map<any, (value: ChartHighlightValue) => void>(),
  });

  return useMemo(() => {
    return {
      setValue: (value: ChartHighlightValue) => {
        const currentValue = infoRef.current.value;
        if (
          value.dataIndex !== currentValue.dataIndex ||
          value.datasetIndex !== currentValue.datasetIndex
        ) {
          infoRef.current.value = { ...infoRef.current.value, ...value };
          Array.from(infoRef.current.subscriptions.values()).forEach(
            (callback) => callback(infoRef.current.value),
          );
        }
      },
      resetValue: () => {
        const currentValue = infoRef.current.value;
        if (
          isDefined(currentValue.dataIndex) ||
          isDefined(currentValue.datasetIndex)
        ) {
          infoRef.current.value = {
            datasetIndex: undefined,
            dataIndex: undefined,
          };
          Array.from(infoRef.current.subscriptions.values()).forEach(
            (callback) => callback(infoRef.current.value),
          );
        }
      },
      getValue: () => infoRef.current.value,
      subscribe: (chart: any, callback: (value: ChartHighlightValue) => void) =>
        infoRef.current.subscriptions.set(chart, callback),
      unsubscribe: (chart: any) => infoRef.current.subscriptions.delete(chart),
    };
  }, [infoRef]);
};

export type UseChartHighlight = ReturnType<typeof useChartHighlight>;
