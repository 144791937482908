import { usePortfolioPortfolioModuleDataTypeListQuery } from "api/carApi.generated";
import { useMemo } from "react";

export const useDataTypes = () => {
  const { data, isLoading, isFetching } =
    usePortfolioPortfolioModuleDataTypeListQuery();

  return useMemo(() => {
    const items = Array.from(data ?? []).sort(
      (a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0),
    );

    const userInputDataTypeId = items.find((i) => i.code === "USER_INPUT")?.id;
    const benchmarkDataTypeId =
      data?.find((i) => i.code === "BENCHMARK")?.id ?? "";

    return {
      isLoading,
      isFetching,
      items,
      userInputDataTypeId,
      benchmarkDataTypeId,
    };
  }, [data, isLoading, isFetching]);
};
