import { Box, Typography } from "@mui/material";
import background from "assets/barometer/background.png";
import mainNeedle from "assets/barometer/main-needle.svg";
import redNeedle from "assets/barometer/red-needle.svg";
import whiteNeedle from "assets/barometer/white-needle.svg";
import blueNeedle from "assets/barometer/blue-needle.svg";
import cap from "assets/barometer/cap.svg";
import { formatPercent } from "utils";

// const calcArcPath = (options: { startAngle: number; endAngle: number }) => {
//   function polarToCartesian(
//     centerX: number,
//     centerY: number,
//     radius: number,
//     angleInDegrees: number
//   ) {
//     const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180;

//     return {
//       x: centerX + radius * Math.cos(angleInRadians),
//       y: centerY + radius * Math.sin(angleInRadians),
//     };
//   }

//   const { w, h, r1, r2 } = {
//     w: 200,
//     h: 200,
//     r1: 169,
//     r2: 116,
//   };

//   const outerStart = polarToCartesian(w, h, r1, options.endAngle);
//   const outerEnd = polarToCartesian(w, h, r1, options.startAngle);
//   const largeArcFlag = options.endAngle - options.startAngle <= 180 ? "0" : "1";

//   const innerStart = polarToCartesian(w, h, r2, options.endAngle);
//   const innerEnd = polarToCartesian(w, h, r2, options.startAngle);

//   return [
//     "M",
//     outerStart.x,
//     outerStart.y,
//     "A",
//     r1,
//     r1,
//     0,
//     largeArcFlag,
//     0,
//     outerEnd.x,
//     outerEnd.y,
//     "L",
//     innerEnd.x,
//     innerEnd.y,
//     "A",
//     r2,
//     r2,
//     0,
//     largeArcFlag,
//     1,
//     innerStart.x,
//     innerStart.y,
//     "Z",
//   ].join(" ");
// };

// interface StripedSectorProps extends SvgIconProps {
//   startAngle: number;
//   endAngle: number;
// }

// const StripedSector = ({
//   startAngle,
//   endAngle,
//   ...props
// }: StripedSectorProps) => {
//   return (
//     <SvgIcon {...props} viewBox="0 0 400 400">
//       <defs>
//         <pattern
//           id="barometerSectorStripes"
//           patternUnits="userSpaceOnUse"
//           width="20"
//           height="20"
//           patternTransform="rotate(80)"
//         >
//           <line x1="0" y="0" x2="0" y2="20" stroke="#999999" strokeWidth="20" />
//           <line
//             x1="20"
//             y="0"
//             x2="20"
//             y2="20"
//             stroke="#FFFFFF"
//             strokeWidth="20"
//           />
//         </pattern>
//       </defs>
//       <path
//         fill="url(#barometerSectorStripes)"
//         stroke="none"
//         fillRule="evenodd"
//         opacity="0.3"
//         d={calcArcPath({
//           startAngle,
//           endAngle,
//         })}
//       />
//     </SvgIcon>
//   );
// };

interface RiskBarometerProps {
  size: number;
  value: number;
  valueRed: number;
  valueWhite: number;
  valueBlue: number;
  // sectorStart?: number;
  // sectorEnd?: number;
}

export const RiskBarometer = ({
  size,
  value,
  valueRed,
  valueWhite,
  valueBlue,
}: RiskBarometerProps) => {
  const degree = value * 2.22 - 111;
  const degreeRed = valueRed * 2.22 - 111;
  const degreeWhite = valueWhite * 2.22 - 111;
  const degreeBlue = valueBlue * 2.22 - 111;
  // const startAngle = Math.min(sectorStart ?? 0, sectorEnd ?? 0) * 2.28 - 114;
  // const endAngle = Math.max(sectorStart ?? 0, sectorEnd ?? 0) * 2.28 - 114;

  return (
    <Box sx={{ position: "relative", width: size, height: size, flex: "none" }}>
      <Box
        component="img"
        src={background}
        sx={{
          width: "100%",
          height: "100%",
          filter: "drop-shadow(3px 3px 2px rgba(0, 0, 0, .3))",
        }}
      />
      <Typography
        sx={{
          position: "absolute",
          left: 0,
          top: "83%",
          width: "100%",
          textAlign: "center",
          // color: "black",
          // fontSize: "13px",
        }}
        variant="par02SemiBold"
      >
        {`\u00A0${formatPercent(value)}`}
      </Typography>
      {/* <StripedSector
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        startAngle={startAngle}
        endAngle={endAngle}
      /> */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          filter: "drop-shadow(3px 3px 2px rgba(0, 0, 0, .4))",
          userSelect: "none",
        }}
      >
        <Box
          component="img"
          src={mainNeedle}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            transform: `rotate(${degree.toFixed(0)}deg)`,
            transition: `transform 1s`,
            userSelect: "none",
          }}
        />
        <Box
          component="img"
          src={cap}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            userSelect: "none",
          }}
        />
        <Box
          component="img"
          src={redNeedle}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            transform: `rotate(${degreeRed.toFixed(0)}deg)`,
            transition: `transform 1s`,
            userSelect: "none",
          }}
        />
        <Box
          component="img"
          src={whiteNeedle}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            transform: `rotate(${degreeWhite.toFixed(0)}deg)`,
            transition: `transform 1s`,
            userSelect: "none",
          }}
        />
        <Box
          component="img"
          src={blueNeedle}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            transform: `rotate(${degreeBlue.toFixed(0)}deg)`,
            transition: `transform 1s`,
            userSelect: "none",
          }}
        />
      </Box>
    </Box>
  );
};
