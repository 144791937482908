import { useMemo } from "react";
import { format } from "date-fns";
import {
  CmaModelChartData,
  useAssetClassesAssetClassStatisticViewRetrieveQuery,
  useSimulationSimulationJobGetSummaryStatisticsListQuery,
} from "api/carApi.generated";
import { ExcelDataFormat, ExcelTable } from "app/useExcelExport";
import {
  AssetClass,
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { AssetClassStatisticViewItemResponse } from "pages/assetAllocation/editPortfolioGroup/useAssetClassStatistics";
import { isDefined } from "utils";

export interface CarAssetClassAnalysisItem extends AssetClass {
  modelChartData?: CmaModelChartData;
  dft: number | null;
  realStaticExpectedReturn: number | null;
  realPriceDrivenExpectedReturn: number | null;
  nominalStaticExpectedReturn: number | null;
  nominalPriceDrivenExpectedReturn: number | null;
  volatility: number | null;
  alpha: number | null;
  beta: number | null;

  fixedIncomeDuration: number | null;
  fixedIncomeConvexity: number | null;
  currentEquityDividendYield: number | null;
  currentFixedIncomeYield: number | null;
}

export type CarAssetClassAnalysisItemKey = keyof Pick<
  CarAssetClassAnalysisItem,
  | "dft"
  | "nominalPriceDrivenExpectedReturn"
  | "nominalStaticExpectedReturn"
  | "realPriceDrivenExpectedReturn"
  | "realStaticExpectedReturn"
  | "volatility"
  | "alpha"
  | "beta"
  | "fixedIncomeDuration"
  | "fixedIncomeConvexity"
  | "currentEquityDividendYield"
  | "currentFixedIncomeYield"
>;

export const getDistanceFromTrendExcelTable = (params: {
  name: string;
  item: CarAssetClassAnalysisItem;
}): ExcelTable => {
  const data = params.item.modelChartData;

  return {
    name: params.name,
    columns: [
      {
        label: "",
        format: ExcelDataFormat.general,
        charWidth: 15,
      },
      {
        label: "Trend Line",
        format: ExcelDataFormat.percent,
        charWidth: 15,
      },
      {
        label: "Historic Line",
        format: ExcelDataFormat.percent,
        charWidth: 15,
      },
    ],
    rows:
      data?.trend_x?.map((i, idx) => [
        format(new Date(i), "MMM yyyy"),
        (data.trend_y_trend_line?.[idx] ?? 0) / 100,
        (data.trend_y_historic_line?.[idx] ?? 0) / 100,
      ]) ?? [],
  };
};

export const useAssetClassSummaryStat = (props: {
  type: AssetClassLoadType;
  simulationJobId?: string;
  filterByUsableInPortfolios?: boolean;
}) => {
  const assetClasses = useAssetClasses({
    type: props.type,
    simulationJobId: props.simulationJobId,
    filterByUsableInPortfolios: props.filterByUsableInPortfolios,
  });

  const summaryStat = useSimulationSimulationJobGetSummaryStatisticsListQuery(
    {
      id: assetClasses.simulationJobId ?? "",
    },
    {
      skip: !assetClasses.simulationJobId,
    },
  );
  console.log({
    assetClasses,
    ss: summaryStat.data,
    sji: assetClasses.simulationJobId,
  });

  const acStatData = useAssetClassesAssetClassStatisticViewRetrieveQuery();

  const acStatDataResponse = useMemo(
    () => acStatData.data as AssetClassStatisticViewItemResponse | undefined,
    [acStatData.data],
  );

  return useMemo(() => {
    const isLoading =
      assetClasses.isLoading || acStatData.isLoading || summaryStat.isLoading;

    const acSummaryItems = assetClasses.items
      .map<CarAssetClassAnalysisItem | undefined>((ac) => {
        const summaryStatItem = summaryStat.data?.find(
          (s) => s.asset_class4 === ac.level4Id,
        );

        const statDataItem = acStatDataResponse?.[ac.level4Id];

        const result: CarAssetClassAnalysisItem | undefined = summaryStatItem
          ? {
              ...ac,
              modelChartData: summaryStatItem.model_chart_data,
              dft: summaryStatItem.dft ?? summaryStatItem.current_yield ?? null,
              realStaticExpectedReturn:
                summaryStatItem.real_static_expected_return ?? null,
              realPriceDrivenExpectedReturn:
                summaryStatItem.real_dynamic_expected_return ?? null,
              nominalStaticExpectedReturn:
                summaryStatItem.nominal_static_expected_return ?? null,
              nominalPriceDrivenExpectedReturn:
                summaryStatItem.nominal_dynamic_expected_return ?? null,
              volatility: summaryStatItem.volatility ?? null,
              alpha: summaryStatItem.alpha ?? null,
              beta: summaryStatItem.beta ?? null,

              fixedIncomeDuration: statDataItem?.duration ?? null,
              fixedIncomeConvexity: statDataItem?.convexity ?? null,
              currentEquityDividendYield: statDataItem?.equity_yield ?? null,
              currentFixedIncomeYield: statDataItem?.yield_to_worst ?? null,
            }
          : undefined;
        return result;
      })
      .filter(isDefined);

    const distanceFromTrendItems = acSummaryItems.filter(
      (i) => !!i.modelChartData,
    );

    return {
      isLoading,
      acSummaryItems,
      distanceFromTrendItems,
    };
  }, [
    acStatData.isLoading,
    acStatDataResponse,
    assetClasses,
    summaryStat.data,
    summaryStat.isLoading,
  ]);
};
