import { usePortfolioPortfolioGroupVersionModulesDataMakeSelectedUpdateMutation } from "api/carApi.generated";
import { usePortfolioGroupVersionData } from "../usePortfolioGroupVersionData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { checkDefined } from "utils";

export const useEditPortfolioGroupSelection = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const data = usePortfolioGroupVersionData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    hideCash: true,
    show: {
      existing: false,
      selected: false,
      draft: true,
      benchmark: true,
    },
  });

  const [selectModuleData] =
    usePortfolioPortfolioGroupVersionModulesDataMakeSelectedUpdateMutation();

  const handleSelectedModuleDataChange = async (params: {
    moduleId: string;
    dataId: string;
  }) =>
    selectModuleData({
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      moduleId: params.moduleId,
      id: params.dataId,
    });

  return {
    portfolioGroupVersionId,
    portfolioGroupId,

    isError: data.isError,
    tableData: data.tableData,

    isLoading: data.isLoading || groupInfo.isLoading,

    handleSelectedModuleDataChange,
  };
};
