import { BoxProps } from "@mui/material";
import { CarAllocAsset } from "app/useAllocAssets";
import {
  CarInfoTable,
  CarInfoHeader,
  CarInfoExpandableRow,
} from "components/InfoTable";
import { formatCurrency, formatPercent } from "utils";
export interface PlanProposedAllocationTableProps {
  sx?: BoxProps["sx"];
  items: Array<CarAllocAsset>;
  isPercentMode: boolean;
  portfolioLabel: string;
}
export const PlanProposedAllocationTable = ({
  items,
  isPercentMode,
  portfolioLabel,
  sx,
}: PlanProposedAllocationTableProps) => {
  const getRenderItem = (level: number) => (i: CarAllocAsset) =>
    (
      <CarInfoExpandableRow
        key={i.id}
        caption={i.name}
        values={
          isPercentMode
            ? [
                formatPercent(i.current_percent),
                formatPercent(i.proposed_percent),
                formatPercent(i.diff_percent),
              ]
            : [
                formatCurrency(i.current_dollars),
                formatCurrency(i.proposed_dollars),
                formatCurrency(i.diff_dollars),
              ]
        }
        level={level}
        childContent={i.children.map(getRenderItem(level + 1))}
        sx={{
          gridColumn: "1 / span 4",
        }}
      />
    );
  return (
    <>
      <CarInfoTable sx={{ gridTemplateColumns: "repeat(4, 1fr)", ...sx }}>
        <CarInfoHeader text="" />
        <CarInfoHeader text="Existing:" />
        <CarInfoHeader text={`${portfolioLabel}:`} />
        <CarInfoHeader text="Difference:" />
        {items.map(getRenderItem(0))}
      </CarInfoTable>
    </>
  );
};
