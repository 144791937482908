import { AssetClassLevel } from "const";

export const assetAllocationPages = {
  dashboard: "dashboard",
  getPortfolioGroupsUrl: () => `/asset-allocation/dashboard`,

  editPortfolioGroup: "edit-portfolio-group/:portfolioGroupId/*",

  getEditPortfolioGroupUrl: ({
    portfolioGroupId,
    isPublished,
  }: {
    portfolioGroupId: string;
    isPublished: Boolean;
  }) =>
    `/asset-allocation/edit-portfolio-group/${portfolioGroupId}/${
      isPublished
        ? editPortfolioGroupPages.editCompare
        : editPortfolioGroupPages.assetClassSelection
    }`,

  viewPortfolioGroup:
    "view-portfolio-group/:portfolioGroupId/version/:portfolioGroupVersionId/*",

  getViewPortfolioGroupUrl: (
    portfolioGroupId: string,
    portfolioGroupVersionId: string,
  ) =>
    `/asset-allocation/view-portfolio-group/${portfolioGroupId}/version/${portfolioGroupVersionId}`,
};

export const portfolioAnalysisPages = {
  dashboard: "dashboard",
  geDashboardUrl: () => `/portfolio-analysis/dashboard`,

  editPortfolio: "edit-portfolio/:portfolioGroupId/*",

  getEditPortfolioUrl: (portfolioGroupId: string) =>
    `/portfolio-analysis/edit-portfolio/${portfolioGroupId}`,
};

export const financialPlanningPages = {
  clientListPath: "client-list",
  clientListUrl: "/financial-planning/client-list",
  addClientPath: "add-client",
  addClientUrl: "/financial-planning/add-client",

  clientSummaryPath: ":clientId/summary",
  getClientSummaryUrl: (clientId: string) =>
    `/financial-planning/${clientId}/summary`,

  planInputPath: ":clientId/plan/:planId/plan-input",
  getPlanInputUrl: (clientId: string, planId: string) =>
    `/financial-planning/${clientId}/plan/${planId}/plan-input`,

  workbenchPath: ":clientId/plan/:planId/workbench/type/:planTypeId/*",
  getWorkbenchUrl: (clientId: string, planId: string, planTypeId: string) =>
    `/financial-planning/${clientId}/plan/${planId}/workbench/type/${planTypeId}`,

  progressMonitoringPath: ":clientId/progress-monitoring",
  getProgressMonitoringUrl: (clientId: string) =>
    `/financial-planning/${clientId}/progress-monitoring`,
};

export const settingsPages = {
  notifications: "notifications",
  userSettings: "user-settings",
  firmSettings: "firm-settings",
  disclosuresUrl: "disclosures",
  disclosuresPath: "disclosures/*",
  billing: "billing",
  assetClassSettings: "asset-class-settings",
  backEndUrl: "back-end",
  backEndPath: "back-end/*",
};

export const notificationsPage = {
  new: "new",
  cleared: "cleared",
};

export const rootPages = {
  login: "login",
  startFreeTrial: "start-free-trial",
  logout: "logout", // no such page just url to display on link hover
  home: "/",

  notificationsUrl: "/notifications",
  notificationsPath: "notifications/*",

  settingsUrl: "/settings",
  settingsPath: "settings/*",

  debug: "/debug",
  pdfDebug: "/pdf-debug",
  debugIcons: "/debug-icons",
  contactUs: "contact-us",

  payment: "/payment",
  paymentComplete: "/payment-complete",

  acceptInvitationPath: "/accept-invitation/:invitationSecret",

  resetPasswordPath: "/reset-password/:resetPasswordToken",

  simulationUrl: "/simulation",
  simulationPath: "simulation/*",

  assetClassAnalysisUrl: "/asset-class-analysis",
  assetClassAnalysisPath: "asset-class-analysis/*",

  assetAllocationUrl: "/asset-allocation",
  assetAllocationPath: "asset-allocation/*",

  financialPlanningUrl: "financial-planning",
  financialPlanningPath: "financial-planning/*",

  portfolioAnalysisUrl: "portfolio-analysis",
  portfolioAnalysisPath: "portfolio-analysis/*",

  financialPlanningReport:
    "financial-planning-report/:clientId/plan/:planId/type/:planTypeId",
  getFinancialPlanningReportUrl: ({
    clientId,
    planId,
    planTypeId,
  }: {
    clientId: string;
    planId: string;
    planTypeId: string;
  }) =>
    `/financial-planning-report/${clientId}/plan/${planId}/type/${planTypeId}`,

  actionReport: "action-report/:clientId/plan/:planId/type/:planTypeId",
  getActionReportUrl: ({
    clientId,
    planId,
    planTypeId,
  }: {
    clientId: string;
    planId: string;
    planTypeId: string;
  }) => `/action-report/${clientId}/plan/${planId}/type/${planTypeId}`,

  assetAllocationSummaryReport:
    "asset-allocation-summary-report/:portfolioGroupId/version/:portfolioGroupVersionId/level/:assetClassLevel",
  getAssetAllocationSummaryReportUrl: ({
    portfolioGroupId,
    portfolioGroupVersionId,
    assetClassLevel,
    column1Id,
    column2Id,
  }: {
    portfolioGroupId: string;
    portfolioGroupVersionId: string;
    assetClassLevel: AssetClassLevel;
    column1Id: string;
    column2Id: string;
  }) =>
    `/asset-allocation-summary-report/${portfolioGroupId}/version/${portfolioGroupVersionId}/level/${assetClassLevel}?column1Id=${column1Id}&column2Id=${column2Id}`,

  portfolioGroupModulePdfReport:
    "portfolio-group-module-pdf-report/:portfolioGroupId/version/:portfolioGroupVersionId/module/:moduleId",
  getPortfolioGroupModulePdfReportUrl: ({
    portfolioGroupId,
    portfolioGroupVersionId,
    moduleId,
    data1Id,
    data2Id,
  }: {
    portfolioGroupId: string;
    portfolioGroupVersionId: string;
    moduleId: string;
    data1Id: string;
    data2Id: string;
  }) =>
    `/portfolio-group-module-pdf-report/${portfolioGroupId}/version/${portfolioGroupVersionId}/module/${moduleId}?data1Id=${data1Id}&data2Id=${data2Id}`,

  portfolioGroupAssetClassAnalysisPdfReport:
    "portfolio-group-asset-class-analysis-pdf-report/:portfolioGroupId/version/:portfolioGroupVersionId",
  getPortfolioGroupAssetClassAnalysisPdfReportUrl: ({
    portfolioGroupId,
    portfolioGroupVersionId,
    data1Id,
    data2Id,
    isNominal,
    chartDateRange,
  }: {
    portfolioGroupId: string;
    portfolioGroupVersionId: string;
    data1Id: string;
    data2Id: string;
    isNominal: boolean;
    chartDateRange: number;
  }) =>
    `/portfolio-group-asset-class-analysis-pdf-report/${portfolioGroupId}/version/${portfolioGroupVersionId}?data1Id=${data1Id}&data2Id=${data2Id}&isNominal=${isNominal}&chartDateRange=${chartDateRange}`,

  portfolioGroupPublishPdfReport:
    "portfolio-group-publish-pdf-report/:portfolioGroupId/version/:portfolioGroupVersionId",
  getPortfolioGroupPublishPdfReportUrl: ({
    portfolioGroupId,
    portfolioGroupVersionId,
    xStatId,
    yStatId,
  }: {
    portfolioGroupId: string;
    portfolioGroupVersionId: string;
    xStatId: string;
    yStatId: string;
  }) =>
    `/portfolio-group-publish-pdf-report/${portfolioGroupId}/version/${portfolioGroupVersionId}?x=${xStatId}&y=${yStatId}`,

  assetClassPerformanceRiskPdfReport:
    "/asset-class-performance-risk-pdf-report",
  getAssetClassPerformanceRiskPdfReportUrl: ({
    data1Id,
    data2Id,
  }: {
    data1Id: string;
    data2Id: string;
  }) =>
    `/asset-class-performance-risk-pdf-report?data1Id=${data1Id}&data2Id=${data2Id}`,

  assetClassSimulatedForwardProbabilitiesPdfReport:
    "asset-class-simulated-forward-probabilities-pdf-report",
  getAssetClassSimulatedForwardProbabilitiesPdfReportUrl: ({
    data1Id,
    data2Id,
    isNominal,
    isAdvanced,
    chartDateRange1,
    chartDateRange2,
  }: {
    data1Id: string;
    data2Id: string;
    isNominal: boolean;
    isAdvanced: boolean;
    chartDateRange1: number;
    chartDateRange2: number;
  }) =>
    `/asset-class-simulated-forward-probabilities-pdf-report?data1Id=${data1Id}&data2Id=${data2Id}&isNominal=${isNominal}&isAdvanced=${isAdvanced}&chartDateRange1=${chartDateRange1}&chartDateRange2=${chartDateRange2}`,

  assetClassDftPdfReport: "/asset-class-dft-pdf-report/:assetClassId",
  getAssetClassDftPdfReportUrl: ({ assetClassId }: { assetClassId: string }) =>
    `/asset-class-dft-pdf-report/${assetClassId}`,
};

export const editPortfolioGroupPages = {
  assetClassSelection: "asset-class-selection",
  createAllocations: "create-allocations",
  optimization: "optimization",
  editCompare: "edit-compare",
  analyze: "analyze",
  selection: "selection",
  reportsPath: "reports/*",
  reportsUrl: "reports",
  publish: "publish",
};

export const editPortfolioPages = {
  assetClassSelection: "asset-class-selection",
  createModel: "create-model",
  optimization: "optimization",
  editCompare: "edit-compare",
  analyze: "analyze",
};

export const assetClassAnalysisPages = {
  dashboard: "dashboard",
  analyzeUrl: "analyze",
  analyzePath: "analyze/*",
};

export const assetClassAnalysisAnalyzePages = {
  summary: "summary",
  performanceRisk: "performance-risk",
  multiPeriodReturnComparison: "multi-period-return-comparison",
  simulatedForwardProbabilities: "simulated-forward-probabilities",
  distanceFromTrend: "distance-from-trend",
  sectorAnalysis: "sector-analysis",
};

export const simulationPages = {
  priceDrivenUrl: "price-driven",
  priceDrivenPath: "price-driven/*",
  staticUrl: "static",
  staticPath: "static/*",
  modelsUrl: "models",
  modelsPath: "models/*",
  abTestUrl: "ab-test",
  abTestPath: "ab-test/*",
  runUrl: "run",
  runPath: "run/*",
};
