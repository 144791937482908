import { StressTestData } from "api/carApi.generated";
import { ExcelTable, ExcelDataFormat } from "../../app/useExcelExport";
import { roundTo } from "utils";
import { GetInflatedValue } from "app/usePlanId";
import { pendoClasses } from "app/thirdParty/pendo";

export const getMarketEnvironmentTable = (
  items: StressTestData[],
  getInflatedValue: GetInflatedValue,
): ExcelTable => {
  return {
    name: "Market Environment",
    columns: [
      {
        label: "",
        format: ExcelDataFormat.general,
        fraction: 1.1,
      },
      {
        label: "CPI",
        pendoClassName: pendoClasses.workbenchStressTestCpi,
        format: ExcelDataFormat.percent,
      },
      {
        label: "Large Cap",
        pendoClassName: pendoClasses.workbenchStressTestLargeCap,
        format: ExcelDataFormat.percent,
      },
      {
        label: "10-Year Treasury",
        pendoClassName: pendoClasses.workbenchStressTestTenYearTreasury,
        format: ExcelDataFormat.percent,
      },
      {
        label: "Beginning Portfolio",
        pendoClassName: pendoClasses.workbenchStressTestBeginningPortfolio,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Return on Assets $",
        pendoClassName: pendoClasses.workbenchStressTestReturnOnAssets,
        format: ExcelDataFormat.currency,
        gridLabel: "Return on Assets",
        span: 2,
      },
      {
        label: "Return on Assets %",
        pendoClassName: pendoClasses.workbenchStressTestReturnOnAssets,
        format: ExcelDataFormat.percent,
        span: 0,
      },
      {
        label: "Cash in Portfolio",
        pendoClassName: pendoClasses.workbenchStressTestCashInPortfolio,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Cash out Portfolio",
        pendoClassName: pendoClasses.workbenchStressTestCashOutPortfolio,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Ending Portfolio",
        pendoClassName: pendoClasses.workbenchStressTestEndingPortfolio,
        format: ExcelDataFormat.currency,
      },
    ],
    rows: [
      ...items.map((i) => [
        i.year_label,
        roundTo(i.observed_inflation_rate, 3),
        roundTo(i.observed_large_cap_return, 3),
        roundTo(i.observed_ten_year_treasury_return, 3),
        roundTo(
          getInflatedValue(
            i.beginning_total_portfolio_value,
            i.beginning_total_portfolio_value_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(
            i.return_on_asset_dollar,
            i.return_on_asset_dollar_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(i.return_on_asset, i.return_on_asset_deflated),
          3,
        ),
        roundTo(
          getInflatedValue(i.cash_in_portfolio, i.cash_in_portfolio_deflated),
        ),
        roundTo(
          getInflatedValue(i.cash_out_portfolio, i.cash_out_portfolio_deflated),
        ),
        roundTo(
          getInflatedValue(
            i.ending_total_portfolio_value,
            i.ending_total_portfolio_value_deflated,
          ),
        ),
      ]),
    ],
  };
};

export const getPlanCashflowTable = (
  items: StressTestData[],
  getInflatedValue: GetInflatedValue,
): ExcelTable => {
  return {
    name: "Plan Cashflow",
    columns: [
      {
        label: "",
        format: ExcelDataFormat.general,
        fraction: 1.1,
      },
      {
        label: "Total A/T Income, W/D, and Cashflow",
        pendoClassName:
          pendoClasses.workbenchStressTestTotalATIncomeWDAndCashflow,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Retirement Spending",
        pendoClassName: pendoClasses.workbenchStressTestRetirementSpending,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Medical Expenses",
        pendoClassName: pendoClasses.workbenchStressTestMedicalExpenses,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Other Goals Funded",
        pendoClassName: pendoClasses.workbenchStressTestOtherGoalsFunded,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Goals Met",
        pendoClassName: pendoClasses.workbenchStressTestGoalsMet,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Goals not Met",
        pendoClassName: pendoClasses.workbenchStressTestGoalsNotMet,
        format: ExcelDataFormat.currency,
      },
    ],
    rows: [
      ...items.map((i) => [
        i.year_label,
        roundTo(
          getInflatedValue(
            i.total_aftertax_income_and_withdrawls,
            i.total_aftertax_income_and_withdrawls_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(
            i.retirement_spending,
            i.retirement_spending_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(i.medical_expenses, i.medical_expenses_deflated),
        ),
        roundTo(
          getInflatedValue(i.other_goals_funded, i.other_goals_funded_deflated),
        ),
        roundTo(getInflatedValue(i.goals_met, i.goals_met_deflated)),
        roundTo(getInflatedValue(i.goals_not_met, i.goals_not_met_deflated)),
      ]),
    ],
  };
};

export const getNetWorthTable = (
  items: StressTestData[],
  getInflatedValue: GetInflatedValue,
): ExcelTable => {
  return {
    name: "Net Worth",
    columns: [
      {
        label: "",
        format: ExcelDataFormat.general,
        fraction: 1.1,
      },
      {
        label: "Beginning Total Portfolio",
        pendoClassName: pendoClasses.workbenchStressTestBeginningTotalPortfolio,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Return on Assets $",
        pendoClassName: pendoClasses.workbenchStressTestReturnOnAssets,
        format: ExcelDataFormat.currency,
        gridLabel: "Return on Assets",
        span: 2,
        fraction: 0.8,
      },
      {
        label: "Return on Assets %",
        pendoClassName: pendoClasses.workbenchStressTestReturnOnAssets,
        format: ExcelDataFormat.percent,
        span: 0,
        fraction: 0.8,
      },
      {
        label: "Savings",
        pendoClassName: pendoClasses.workbenchStressTestSavings,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Net A/T Cash Flow into Portfolio",
        pendoClassName:
          pendoClasses.workbenchStressTestNetATCashFlowIntoPortfolio,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Goals Funded",
        pendoClassName: pendoClasses.workbenchStressTestGoalsFunded,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Ending Total Portfolio",
        pendoClassName: pendoClasses.workbenchStressTestEndingTotalPortfolio,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Real Estate",
        pendoClassName: pendoClasses.workbenchStressTestRealEstate,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Mortgage",
        pendoClassName: pendoClasses.workbenchStressTestMortgage,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Total Net Worth",
        pendoClassName: pendoClasses.workbenchStressTestTotalNetWorth,
        format: ExcelDataFormat.currency,
      },
    ],
    rows: [
      ...items.map((i) => [
        i.year_label,
        roundTo(
          getInflatedValue(
            i.beginning_total_portfolio_value,
            i.beginning_total_portfolio_value_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(
            i.return_on_asset_dollar,
            i.return_on_asset_dollar_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(i.return_on_asset, i.return_on_asset_deflated),
          3,
        ),
        roundTo(
          getInflatedValue(i.net_worth_savings, i.net_worth_savings_deflated),
        ),
        roundTo(
          getInflatedValue(
            i.net_worth_cash_flow,
            i.net_worth_cash_flow_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(
            i.net_worth_goals_met,
            i.net_worth_goals_met_deflated,
          ) +
            getInflatedValue(
              i.net_worth_goals_not_met,
              i.net_worth_goals_not_met_deflated,
            ),
        ),
        roundTo(
          getInflatedValue(
            i.ending_total_portfolio_value,
            i.ending_total_portfolio_value_deflated,
          ) ?? 0,
        ),
        roundTo(i.real_estate),
        roundTo(i.mortgage),
        roundTo(
          getInflatedValue(i.total_net_worth, i.total_net_worth_deflated),
        ),
      ]),
    ],
  };
};

export const getTaxableIncomeAnalysisTable = (
  items: StressTestData[],
  getInflatedValue: GetInflatedValue,
): ExcelTable => {
  return {
    name: "Taxable Income Analysis",
    columns: [
      { label: "", format: ExcelDataFormat.general, fraction: 1.1 },
      {
        label: "Income, Social Security, Pension, Etc.",
        pendoClassName:
          pendoClasses.workbenchStressTestIncomeSocialSecurityPensionEtc,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Taxable Dividend & Interest",
        pendoClassName:
          pendoClasses.workbenchStressTestTaxableDividendAndInterest,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Taxable W/D (IRA, 401k, etc.)",
        pendoClassName: pendoClasses.workbenchStressTestTaxableWDIRA401kEtc,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Capital Gains",
        pendoClassName: pendoClasses.workbenchStressTestCapitalGains,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Gross Rental Income",
        pendoClassName: pendoClasses.workbenchStressTestGrossRentalIncome,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Total Taxable Income",
        pendoClassName: pendoClasses.workbenchStressTestTotalTaxableIncome,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Income Taxes Paid",
        pendoClassName: pendoClasses.workbenchStressTestIncomeTaxesPaid,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Capital Gains Taxes Paid",
        pendoClassName: pendoClasses.workbenchStressTestCapitalGainsTaxesPaid,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Effective Tax Rate",
        pendoClassName: pendoClasses.workbenchStressTestEffectiveTaxRate,
        format: ExcelDataFormat.percentRounded,
      },
    ],
    rows: [
      ...items.map((i) => [
        i.year_label,
        roundTo(
          getInflatedValue(
            i.social_security_pension_etc,
            i.social_security_pension_etc_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(
            i.taxable_dividend_interest,
            i.taxable_dividend_interest_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(i.taxable_withdrawl, i.taxable_withdrawl_deflated),
        ),
        roundTo(getInflatedValue(i.capital_gains, i.capital_gains_deflated)),
        roundTo(
          getInflatedValue(i.net_rental_income, i.net_rental_income_deflated),
        ),
        roundTo(
          getInflatedValue(
            i.total_taxable_income,
            i.total_taxable_income_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(i.income_taxes_paid, i.income_taxes_paid_deflated),
        ),
        roundTo(
          getInflatedValue(
            i.capital_gains_taxes_paid,
            i.capital_gains_taxes_paid_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(i.effective_tax_rate, i.effective_tax_rate_deflated),
          2,
        ),
      ]),
    ],
  };
};

export const getRealEstateTable = (
  items: StressTestData[],
  getInflatedValue: GetInflatedValue,
): ExcelTable => {
  return {
    name: "Real Estate",
    columns: [
      { label: "", format: ExcelDataFormat.general, fraction: 1.1 },
      {
        label: "RE Value",
        pendoClassName: pendoClasses.workbenchStressTestREValue,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Cost Basis",
        pendoClassName: pendoClasses.workbenchStressTestCostBasis,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Annual Mortgage Balance",
        pendoClassName: pendoClasses.workbenchStressTestAnnualMortgageBalance,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Total Mortgage Payment with Escrow",
        pendoClassName:
          pendoClasses.workbenchStressTestTotalMortgagePaymentWithEscrow,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Rent",
        pendoClassName: pendoClasses.workbenchStressTestRent,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Depreciation",
        pendoClassName: pendoClasses.workbenchStressTestDepreciation,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Operating Expenses",
        pendoClassName: pendoClasses.workbenchStressTestOperatingExpenses,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Net After Tax Rental Income",
        pendoClassName: pendoClasses.workbenchStressTestNetAfterTaxRentalIncome,
        format: ExcelDataFormat.currency,
      },
      {
        label: "Cashflow Net of Debt Service",
        pendoClassName:
          pendoClasses.workbenchStressTestCashflowNetOfDebtService,
        format: ExcelDataFormat.currency,
      },
    ],
    rows: [
      ...items.map((i) => [
        i.year_label,
        roundTo(i.real_estate),
        roundTo(i.real_estate_cost_basis),
        roundTo(i.mortgage),
        roundTo(i.real_estate_annual_total_payment),
        roundTo(getInflatedValue(i.rental_payment, i.rental_payment_deflated)),
        roundTo(i.rental_depreciation ?? 0),
        roundTo(
          getInflatedValue(i.rental_expenses, i.rental_expenses_deflated),
        ),
        roundTo(
          getInflatedValue(
            i.aftertax_rental_income,
            i.aftertax_rental_income_deflated,
          ),
        ),
        roundTo(
          getInflatedValue(
            i.real_estate_cashflow,
            i.real_estate_cashflow_deflated,
          ),
        ),
      ]),
    ],
  };
};
