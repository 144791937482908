import { useEffect, useMemo, useState } from "react";
import { useSimulationList } from "./useSimulationList";
import { useSearchParams } from "react-router-dom";
import { useSimulationGroups } from "pages/assetAllocation/useSimulationGroups";

interface SimulationSelectorParams {
  isStatic: boolean;
}

export const useSimulationSelector = (params: SimulationSelectorParams) => {
  const simulationGroups = useSimulationGroups();
  const simulationList = useSimulationList({});

  const availableOptions = params.isStatic
    ? simulationList.staticOptions
    : simulationList.dynamicOptions;

  const [searchParams] = useSearchParams();
  const pathSimulationJobId = searchParams.get("simulationJobId") ?? undefined;

  const [simulationJob1Id, setSimulationJob1Id] = useState<string | undefined>(
    pathSimulationJobId,
  );

  const [simulationJob2Id, setSimulationJob2Id] = useState<
    string | undefined
  >();

  const [simulationGroupId, setSimulationGroupId] = useState<string>();

  const isLoading = simulationGroups.isLoading || simulationList.isLoading;

  const simulationGroupOptions = useMemo(
    () =>
      simulationGroups.options.filter((i) =>
        availableOptions.some((o) => o.groupId === i.value),
      ),
    [availableOptions, simulationGroups.options],
  );

  const simulationJobOptions = useMemo(
    () => availableOptions.filter((i) => i.groupId === simulationGroupId),
    [availableOptions, simulationGroupId],
  );

  useEffect(() => {
    if (
      !isLoading &&
      !simulationGroupOptions.some((i) => i.value === simulationGroupId)
    ) {
      setSimulationGroupId(simulationGroupOptions.at(0)?.value);
    }
  }, [isLoading, simulationGroupOptions, simulationGroupId]);

  useEffect(() => {
    if (
      !isLoading &&
      !simulationJobOptions.some((i) => i.value === simulationJob1Id)
    ) {
      setSimulationJob1Id(simulationJobOptions.at(0)?.value);
    }
  }, [isLoading, simulationJobOptions, simulationJob1Id]);

  useEffect(() => {
    if (
      !isLoading &&
      !simulationJobOptions.some((i) => i.value === simulationJob2Id)
    ) {
      setSimulationJob2Id(
        simulationJobOptions.at(1)?.value ?? simulationJobOptions.at(0)?.value,
      );
    }
  }, [isLoading, simulationJobOptions, simulationJob2Id]);

  return useMemo(
    () => ({
      isLoading,

      simulationGroupOptions,

      simulationGroupId,
      handleSimulationGroupIdChange: setSimulationGroupId,

      simulationJobOptions,

      simulationJob1Id,
      handleSimulationJob1IdChange: setSimulationJob1Id,

      simulationJob2Id,
      handleSimulationJob2IdChange: setSimulationJob2Id,
    }),
    [
      isLoading,
      simulationGroupOptions,
      simulationJobOptions,
      simulationGroupId,
      simulationJob1Id,
      simulationJob2Id,
    ],
  );
};
