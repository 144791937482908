import { Box, BoxProps, styled } from "@mui/material";

export const PlaceHolder = styled(Box)(({ theme }) => ({
  borderRadius: 0.5,
  backgroundPosition: "103% 0%",
  background: `linear-gradient(90deg, ${theme.palette.gray1} 85%, ${theme.palette.gray2} 89%, ${theme.palette.gray1} 93%)`,
  animation: "placeHolderAnimation 1.2s ease-in-out infinite",
  backgroundSize: "900%",
  "@keyframes placeHolderAnimation": {
    "0%": {
      backgroundPosition: "103% 0%",
    },
    "100%": {
      backgroundPosition: "0% 0%",
    },
  },
}));
export interface TablePlaceHolderProps extends BoxProps {
  isLoading?: boolean;
  rows?: number;
}

export const TablePlaceHolder = ({
  isLoading,
  children,
  rows = 3,
  ...props
}: TablePlaceHolderProps) => {
  const placeHolderProps = {
    height: 40,
    width: "100%",
  };

  return isLoading ? (
    <Box
      {...props}
      sx={{
        py: 1,
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        ...props.sx,
      }}
    >
      {new Array(rows).fill(0).map((i, idx) => (
        <PlaceHolder key={idx} sx={placeHolderProps} />
      ))}
    </Box>
  ) : (
    <>{children}</>
  );
};
