import { Box, Typography } from "@mui/material";
import { pendoClasses } from "app/thirdParty/pendo";
import { useChartHighlight } from "app/useChartHighlight";
import { CarSwitch } from "components/Switch";
import { CarTooltipBox } from "components/TooltipBox";
import {
  CarPortfolioHeatmapChartDataset,
  CarPortfolioHeatmapChartItem,
} from "pages/assetAllocation/asset-allocation-types";
import {
  ChartDateRangeSelector,
  SimulatedForwardProbabilitiesChartLegend,
  SimulatedForwardProbabilitiesInternalChart,
} from "pages/assetAllocation/editPortfolioGroup/ChartSimulatedForwardProbabilities";
import { UseSimulatedForwardProbabilitiesAC } from "./useSimulatedForwardProbabilitiesAC";
import { CarAssetClassSelector } from "components/AssetClassSelector";

export const useSimulatedForwardProbabilitiesACData = ({
  nominalHeatmap1,
  realHeatmap1,
  simulationSuffix1,
  nominalHeatmap2,
  realHeatmap2,
  simulationSuffix2,
  isAdvanced,
  isReal,
  chartDateRange1,
  chartDateRange2,
}: {
  nominalHeatmap1: CarPortfolioHeatmapChartItem[];
  realHeatmap1: CarPortfolioHeatmapChartItem[];
  simulationSuffix1: string;
  nominalHeatmap2: CarPortfolioHeatmapChartItem[];
  realHeatmap2: CarPortfolioHeatmapChartItem[];
  simulationSuffix2: string;

  isAdvanced: boolean;
  isReal: boolean;
  chartDateRange1: number;
  chartDateRange2: number;
}) => {
  const realLabel = isReal ? "Real" : "Nominal";

  const selectedHeatmap1 = isReal ? realHeatmap1 : nominalHeatmap1;
  const selectedHeatmap2 = isReal ? realHeatmap2 : nominalHeatmap2;

  const getChartData = ({
    data1Id,
    data2Id,
  }: {
    data1Id?: string;
    data2Id?: string;
  }) => {
    const selectedItem1 = selectedHeatmap1.find((i) => i.id === data1Id);
    const selectedItem2 = selectedHeatmap2.find(
      (i) => i.id === (isAdvanced ? data2Id : data1Id),
    );

    const secondaryDateRange = isAdvanced ? chartDateRange2 : chartDateRange1;
    const secondarySuffix = isAdvanced ? simulationSuffix1 : simulationSuffix2;

    const selectedItem1Datasets =
      selectedItem1?.datasets.map<CarPortfolioHeatmapChartDataset>((ds) => ({
        ...ds,
        x: ds.x.slice(0, chartDateRange1 + 1),
        y: ds.y.slice(0, chartDateRange1 + 1),
      })) ?? [];

    const selectedItem2Datasets =
      selectedItem2?.datasets.map<CarPortfolioHeatmapChartDataset>((ds) => ({
        ...ds,
        x: ds.x.slice(0, secondaryDateRange + 1),
        y: ds.y.slice(0, secondaryDateRange + 1),
      })) ?? [];

    return {
      selectedHeatmap1,
      label1: `${simulationSuffix1}: ${selectedItem1?.fullLabel} Simulated Outcome (${realLabel})`,
      label1Downside: `${simulationSuffix1}: ${selectedItem1?.fullLabel} Simulated Downside (${realLabel})`,
      datasets1: selectedItem1Datasets,

      label2: `${secondarySuffix}: ${selectedItem2?.fullLabel} Simulated Outcome (${realLabel})`,
      label2Downside: `${secondarySuffix}: ${selectedItem2?.fullLabel} Simulated Downside (${realLabel})`,
      datasets2: selectedItem2Datasets,
    };
  };

  return {
    getChartData,
  };
};

export type SimulatedForwardProbabilitiesACData = ReturnType<
  ReturnType<typeof useSimulatedForwardProbabilitiesACData>["getChartData"]
>;

export const ChartSimulatedForwardProbabilitiesAC = ({
  data,
  isWhiteContext,
}: {
  data: UseSimulatedForwardProbabilitiesAC;
  isWhiteContext?: boolean;
}) => {
  const { getChartData } = useSimulatedForwardProbabilitiesACData({
    nominalHeatmap1: data.nominalHeatmap1,
    realHeatmap1: data.realHeatmap1,
    simulationSuffix1: data.simulationSuffix1,
    nominalHeatmap2: data.nominalHeatmap2,
    realHeatmap2: data.realHeatmap2,
    simulationSuffix2: data.simulationSuffix2,
    isAdvanced: data.isAdvanced,
    isReal: data.isReal,
    chartDateRange1: data.chartDateRange1,
    chartDateRange2: data.chartDateRange2,
  });

  const { data1Id, onData1IdChange, data2Id, onData2IdChange } =
    data.chartDataSelector;

  const {
    label1,
    label1Downside,
    datasets1,
    label2,
    label2Downside,
    datasets2,
  } = getChartData({
    data1Id,
    data2Id,
  });

  const chartHighlight = useChartHighlight();

  // const excelExport = useExcelExport();

  // const handleExport = () => {
  //   if (!selectedItem1 || !selectedItem2) {
  //     return;
  //   }

  //   const createTable = (
  //     chartItem: CarPortfolioHeatmapChartItem
  //   ): ExcelTable => ({
  //     columns: [
  //       {
  //         format: ExcelDataFormat.general,
  //         label: "percentile",
  //       },
  //       ...(chartItem.datasets[0]?.x.map<ExcelColumn>((x) => ({
  //         format: ExcelDataFormat.general,
  //         label: x.toString(),
  //       })) ?? []),
  //     ],
  //     name: chartItem.fullLabel.replace(/[\W]+/g, "_").slice(0, 31),
  //     rows: chartItem.datasets.map((ds) => [ds.label, ...ds.y]),
  //   });

  //   excelExport.exportAllExcel({
  //     filePrefix: "Simulated_Forward_Probabilities",
  //     tables: [createTable(selectedItem1), createTable(selectedItem2)],
  //   });
  // };

  const isTwoPanel = data.isAdvanced || !data.isSameJob;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
      <Box sx={{ display: "flex", gap: 6, alignItems: "flex-end" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="par01Regular">Simple</Typography>
          <CarSwitch
            size="small"
            checked={data.isAdvanced}
            onChange={(e, value) => data.setIsAdvanced(value)}
          />
          <Typography variant="par01Regular">Advanced</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="par01Regular">Real</Typography>
          <CarSwitch
            size="small"
            checked={!data.isReal}
            onChange={(e, value) => data.setIsReal(!value)}
          />
          <Typography variant="par01Regular">Nominal</Typography>
        </Box>
        <SimulatedForwardProbabilitiesChartLegend
          sx={{ ml: "auto" }}
          datasets={datasets1 ?? []}
          chartHighlight={chartHighlight}
          isWhiteContext={isWhiteContext}
        />
      </Box>
      {datasets1 && datasets2 && (
        <Box
          sx={{
            display: "grid",
            gap: 6,
            gridTemplateColumns: isTwoPanel ? "1fr 1fr" : "1fr",
            borderStyle: "solid",
            borderColor: "gray3",
            borderWidth: data.isAdvanced ? 0 : 1,
            backgroundColor: data.isAdvanced
              ? undefined
              : isWhiteContext
              ? "gray1"
              : "white",
          }}
        >
          <Box
            sx={{
              borderStyle: "solid",
              borderColor: "gray3",
              borderWidth: data.isAdvanced ? 1 : 0,
              backgroundColor: isWhiteContext ? "gray1" : "white",
              py: 3,
              px: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: 3,
              }}
            >
              <CarAssetClassSelector
                sx={{
                  width: 250,
                  ".MuiInputBase-root": {
                    backgroundColor: isWhiteContext ? "white" : "gray1",
                  },
                }}
                label="Asset Class"
                value={data1Id}
                onChange={onData1IdChange}
                valueField="firmAssetClassId"
                items={data.assetClasses1.items}
              />
              <ChartDateRangeSelector
                sx={{
                  width: 100,
                  ".MuiInputBase-root": {
                    backgroundColor: isWhiteContext ? "white" : "gray1",
                  },
                }}
                label={
                  <>
                    Time Frame
                    <CarTooltipBox
                      sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                      className={pendoClasses.editPortfolioAnalyzeTimeFrame}
                    />
                  </>
                }
                value={data.chartDateRange1}
                onChange={data.setChartDateRange1}
              />
            </Box>
            <SimulatedForwardProbabilitiesInternalChart
              sx={{ mt: 3 }}
              label={label1}
              datasets={datasets1}
              chartHighlight={chartHighlight}
            />
            <SimulatedForwardProbabilitiesInternalChart
              sx={{ mt: 4 }}
              label={label1Downside}
              datasets={datasets1}
              chartHighlight={chartHighlight}
              isDownside
            />
          </Box>
          {isTwoPanel && (
            <Box
              sx={{
                borderStyle: "solid",
                borderColor: "gray3",
                borderWidth: data.isAdvanced ? 1 : 0,
                ml: data.isAdvanced ? 0 : "1px",
                backgroundColor: isWhiteContext ? "gray1" : "white",
                py: 3,
                px: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 3,
                  height: 60,
                }}
              >
                {data.isAdvanced && (
                  <>
                    <CarAssetClassSelector
                      sx={{
                        width: 250,
                        ".MuiInputBase-root": {
                          backgroundColor: isWhiteContext ? "white" : "gray1",
                        },
                      }}
                      label="Asset Class"
                      value={data2Id}
                      onChange={onData2IdChange}
                      valueField="firmAssetClassId"
                      items={data.assetClasses2.items}
                    />
                    <ChartDateRangeSelector
                      sx={{
                        width: 100,
                        ".MuiInputBase-root": {
                          backgroundColor: isWhiteContext ? "white" : "gray1",
                        },
                      }}
                      label={
                        <>
                          Time Frame
                          <CarTooltipBox
                            sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                            className={
                              pendoClasses.editPortfolioAnalyzeTimeFrame
                            }
                          />
                        </>
                      }
                      value={data.chartDateRange2}
                      onChange={data.setChartDateRange2}
                    />
                  </>
                )}
              </Box>
              <SimulatedForwardProbabilitiesInternalChart
                sx={{ mt: 3 }}
                label={label2}
                datasets={datasets2}
                chartHighlight={chartHighlight}
              />
              <SimulatedForwardProbabilitiesInternalChart
                sx={{ mt: 4 }}
                label={label2Downside}
                datasets={datasets2}
                chartHighlight={chartHighlight}
                isDownside
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
