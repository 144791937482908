import { Box, BoxProps, Typography } from "@mui/material";
import { formatPercent, isDefined, isOddEven } from "utils";
import {
  CarPortfolioAssetColumnEnum,
  CarPortfolioAssetColumnId,
  CarPortfolioAssetModule,
  CarPortfolioAssetRow,
  CarPortfolioAssetTableData,
} from "../usePortfolioGroupVersionData";
import { AssetClassLevel } from "const";

const getGridTemplateColumns = (columnsCount: number) =>
  `1.5fr repeat(${columnsCount}, 1fr)`;

const getAllocationByColumnId = (
  module: CarPortfolioAssetModule,
  columnId: CarPortfolioAssetColumnId,
) => {
  let value = module.values.find((val) => val.columnId === columnId);

  // this covers the case for "Selection" page. On this page CarPortfolioAssetColumnEnum.selected column not in the list but the data still can be found
  if (!value && columnId === CarPortfolioAssetColumnEnum.selected) {
    value = module.values.find((val) => val.dataId === module.selectedDataId);
  }

  return value?.allocation;
};

interface ModuleHeaderProps {
  sx?: BoxProps["sx"];
  label: string;
}

const ModuleHeader = (props: ModuleHeaderProps) => (
  <Box
    sx={{
      minHeight: 60,
      marginLeft: "-1px",
      border: 1,
      borderColor: "table.border",
      backgroundColor: "table.background.header",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      p: 1,
      ...props.sx,
    }}
  >
    <Typography variant="h6SBold">{props.label}</Typography>
  </Box>
);

interface RowProps {
  sx?: BoxProps["sx"];
  columnId: CarPortfolioAssetColumnId;
  row: CarPortfolioAssetRow;
  isOdd?: boolean;
  isTotalRow?: boolean;
}

const Row = (props: RowProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        minHeight: 60,
        gridTemplateColumns: "1fr",
        backgroundColor: props.isOdd
          ? "table.background.even"
          : "table.background.odd",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(props.row.modules.length),
        }}
      >
        <Box
          sx={{
            marginTop: "-1px",
            marginLeft: "-1px",
            border: 1,
            borderColor: "table.border",
            p: 1.5,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            overflow: "hidden",
            borderBottomLeftRadius: props.isTotalRow ? "5px" : undefined,
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "4px",
              border: "1px solid",
              borderColor: "gray6",
              backgroundColor: props.row.color,
              opacity: props.isTotalRow ? 0 : 1,
              flex: "none",
            }}
          />
          <Typography
            variant={props.isTotalRow ? "par02SemiBold" : "par02Regular"}
          >
            {props.row.name}
          </Typography>
        </Box>
        {props.row.modules.map((mod, idx, arr) => {
          const isLastBand = idx === arr.length - 1;
          const value = getAllocationByColumnId(mod, props.columnId);
          return (
            <Box
              key={mod.moduleId}
              sx={{
                marginTop: "-1px",
                marginLeft: "-1px",
                border: 1,
                borderColor: "table.border",
                borderBottomRightRadius:
                  props.isTotalRow && isLastBand ? "5px" : undefined,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography
                variant={props.isTotalRow ? "par02SemiBold" : "par02Regular"}
              >
                {isDefined(value) ? formatPercent(value * 100, 1) : "-"}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export interface AssetAllocationPerModuleTableProps {
  sx?: BoxProps["sx"];
  columnId: CarPortfolioAssetColumnId;
  level: AssetClassLevel;
  tableData: CarPortfolioAssetTableData;
  hideZeroValues: boolean;
}

export const AssetAllocationPerModuleTable = ({
  sx,
  columnId,
  level,
  tableData,
  hideZeroValues,
}: AssetAllocationPerModuleTableProps) => {
  let rows = tableData.assetRows[level];
  if (hideZeroValues) {
    rows = rows.filter((i) =>
      i.modules.some((md) => {
        const value = getAllocationByColumnId(md, columnId) ?? 0;

        return value > 0;
      }),
    );
  }
  let totalRow: CarPortfolioAssetRow | undefined;
  switch (level) {
    case AssetClassLevel.level1:
      totalRow = tableData.assetRows.level1Total;
      break;
    case AssetClassLevel.level2:
      totalRow = tableData.assetRows.level2Total;
      break;
    case AssetClassLevel.level3:
      totalRow = tableData.assetRows.level3Total;
      break;
    case AssetClassLevel.level4:
      totalRow = tableData.assetRows.level4Total;
      break;
    default:
      break;
  }

  return (
    <Box
      sx={{
        display: "grid",
        borderColor: "table.border",
        ".MuiTypography-root": {
          color: "table.text",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(tableData.bands.length),
          position: "sticky",
          top: 0,
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            marginLeft: "-1px",
            border: 1,
            borderColor: "table.border",
            backgroundColor: "table.background.header",
            borderTopLeftRadius: "5px",
          }}
        />
        {tableData.bands.map((band, idx, arr) => (
          <ModuleHeader
            key={band.moduleId}
            label={band.label}
            sx={{
              borderTopRightRadius: idx === arr.length - 1 ? "5px" : undefined,
            }}
          />
        ))}
      </Box>
      {rows.map((i, idx, arr) => (
        <Row key={i.id} row={i} columnId={columnId} isOdd={!isOddEven(idx)} />
      ))}
      {totalRow && (
        <Row
          key={totalRow.id}
          row={totalRow}
          columnId={columnId}
          isOdd={!isOddEven(rows.length)}
          isTotalRow
        />
      )}
    </Box>
  );
};
