import {
  RootPage,
  RootPageHeader,
  RootPageHeaderLink,
  RootPageTransparentPaper,
} from "components/RootPage";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { rootPages, simulationPages } from "route.const";
import { SimulationSingle } from "./SimulationSingle";
import { useEffect } from "react";
import { useUser } from "pages/settings/useUser";
import { SimulationABTest } from "./SimulationABTest";
import { SimulationModels } from "./SimulationModels";
import { SimulationRun } from "./SimulationRun";

export const SimulationPage = () => {
  const { isLoading, isCaravelUser } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && !isCaravelUser) {
      navigate(rootPages.home);
    }
  }, [isCaravelUser, isLoading, navigate]);

  return (
    <RootPage sx={{ backgroundColor: "white" }}>
      <RootPageHeader
        isSmall
        title="Simulation Reports"
        links={
          <>
            <RootPageHeaderLink
              label="Price - Driven"
              url={simulationPages.priceDrivenUrl}
            />
            <RootPageHeaderLink
              label="Static"
              url={simulationPages.staticUrl}
            />
            <RootPageHeaderLink
              label="Models"
              url={simulationPages.modelsUrl}
            />
            <RootPageHeaderLink
              label="A/B Test"
              url={simulationPages.abTestUrl}
            />
            <RootPageHeaderLink label="Run" url={simulationPages.runUrl} />
          </>
        }
      />
      <RootPageTransparentPaper>
        <Routes>
          <Route
            path={simulationPages.priceDrivenPath}
            element={<SimulationSingle isStatic={false} />}
          />
          <Route
            path={simulationPages.staticPath}
            element={<SimulationSingle isStatic={true} />}
          />
          <Route
            path={simulationPages.modelsPath}
            element={<SimulationModels />}
          />
          <Route
            path={simulationPages.abTestPath}
            element={<SimulationABTest />}
          />
          <Route path={simulationPages.runPath} element={<SimulationRun />} />
          <Route
            path="*"
            element={<Navigate to={simulationPages.priceDrivenUrl} replace />}
          />
        </Routes>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
