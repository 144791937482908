import { useEffect, useMemo, useState } from "react";
import {
  useCommonAuthSelfPartialUpdateMutation,
  useCommonAuthSelfRetrieveQuery,
} from "api/carApi.generated";
import { CarUser } from "types";

import { startIntercom } from "app/thirdParty/intercom";
import { setSentryUser } from "app/thirdParty/sentry";
import { useDialog } from "app/useDialog";
import { TwoFactorQRCodeDialog } from "./TwoFactorQRCodeDialog";
import { TwoFactorDeactivateDialog } from "./TwoFactorDeactivateDialog";
import { TwoFactorInstallAppDialog } from "./TwoFactorInstallAppDialog";
import { startPendo } from "app/thirdParty/pendo";
import { isDebugBuild } from "const";

const defaultUser: CarUser = {
  id: "",
  email_confirmed: true,
};

const CARAVEL_FIRM_ID = "b08156fe-2ea6-40cf-9058-7664e0449dd7";

export const useUser = () => {
  const { data, isLoading } = useCommonAuthSelfRetrieveQuery();

  const normalizedUser = useMemo(() => {
    if (!isDebugBuild && data) {
      // Log 3rd Party
      setSentryUser(data);
      startIntercom(data);
      startPendo(data);
    }
    return data;
  }, [data]);

  const [patchUserReq, patchUserResult] =
    useCommonAuthSelfPartialUpdateMutation();

  const updateUser = async (user: CarUser) => {
    if (user.profile_image?.startsWith("http")) {
      user = { ...user };
      delete user.profile_image;
    }

    return await patchUserReq({
      patchedUser: user,
    });
  };

  const dialog = useDialog();

  const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);

  useEffect(() => {
    setIsTwoFactorEnabled(normalizedUser?.two_factor_enabled ?? false);
  }, [normalizedUser]);

  const onTwoFactorEnabledChange = async (value: boolean) => {
    if (value) {
      let result = true;
      setIsTwoFactorEnabled(result);
      try {
        result = (await dialog(TwoFactorInstallAppDialog, {})) ?? false;
        if (result) {
          result = (await dialog(TwoFactorQRCodeDialog, {})) ?? false;
        }
      } finally {
        setIsTwoFactorEnabled(result);
      }
    } else {
      let result = false;
      setIsTwoFactorEnabled(result);
      try {
        result = !((await dialog(TwoFactorDeactivateDialog, {})) ?? false);
      } finally {
        setIsTwoFactorEnabled(result);
      }
    }
  };

  const user = normalizedUser ?? defaultUser;

  const userFullName = `${user.first_name ?? ""} ${
    user.last_name ?? ""
  }`.trim();

  const isCaravelUser = user.firm?.id === CARAVEL_FIRM_ID;

  return {
    isLoading,
    isCaravelUser,
    isUpdating: patchUserResult.isLoading,
    user,
    userFullName,
    updateUser,
    isTwoFactorEnabled,
    onTwoFactorEnabledChange,
  };
};
