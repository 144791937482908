import { useQuestionnaireClientsRetrieveQuery } from "api/carApi.generated";
import { useRelationTypes } from "./useRelationTypes";
import { useClientId } from "./usePlanId";
import { isDefined } from "utils";

export const useClientName = () => {
  const clientId = useClientId();

  const relationTypes = useRelationTypes();

  const { data, isFetching: dataIsLoading } =
    useQuestionnaireClientsRetrieveQuery({
      id: clientId,
    });

  const isLoading = relationTypes.isLoading || dataIsLoading;

  const primaryItem = data?.active_relationships?.find(relationTypes.isPrimary);
  const secondaryItem = data?.active_relationships?.find(
    relationTypes.isSecondary,
  );
  const clientFullName = `${primaryItem?.first_name ?? ""} ${
    primaryItem?.last_name ?? ""
  }`.trim();

  const spouseFullName = secondaryItem
    ? `${secondaryItem?.first_name ?? ""} ${
        secondaryItem?.last_name ?? ""
      }`.trim()
    : undefined;

  const familyName = primaryItem?.last_name;

  const clientAndSpouseFullName = [clientFullName, spouseFullName]
    .filter(isDefined)
    .join(" & ");

  return {
    isLoading,
    clientFullName,
    spouseFullName,
    clientAndSpouseFullName,
    familyName,
  };
};
