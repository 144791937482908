import clsx from "clsx";
import { ExpandLess } from "@mui/icons-material";
import {
  Box,
  BoxProps,
  Collapse,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";
import { ReactNode, useState } from "react";
import { CarTooltipBox } from "./TooltipBox";

export const CarInfoTable = styled(Box)(({ theme }) => ({
  display: "grid",
  backgroundColor: theme.palette.white,
  clipPath: "inset(0 round 5px 5px 5px 5px)", // instead of border-radius 5px; overflow: hidden. overflow does not allow position: sticky
  position: "relative",
  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    border: "solid 1px",
    borderColor: theme.palette.gray6,
    borderRadius: "5px",
    zIndex: 1,
    pointerEvents: "none",
  },
}));

export const CarInfoHeader = styled(
  ({
    text,
    pendoClass,
    variant,
    justifyContent,
    className,
    isSticky,
  }: {
    text: string;
    pendoClass?: string;
    className?: string;
    justifyContent?: TypographyProps["justifyContent"];
    variant?: TypographyProps["variant"];
    isSticky?: boolean;
  }) => (
    <Typography
      variant={variant ?? "h6SBold"}
      justifyContent={justifyContent}
      className={clsx(className, { sticky: isSticky })}
    >
      {text ?? ""}
      {pendoClass ? (
        <CarTooltipBox sx={{ ml: 0.25 }} className={pendoClass} />
      ) : undefined}
    </Typography>
  ),
)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.gray3,
  borderRight: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: theme.palette.gray6,
  textAlign: "center",
  justifyContent: "center",
  whiteSpace: "pre-line",
  overflow: "hidden",
  minHeight: 60,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  "&.sticky": {
    position: "sticky",
    top: 0,
  },
}));

const StyledCell = styled(Box)(({ theme }) => ({
  borderRight: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: theme.palette.gray6,
  minHeight: 60,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...theme.typography.par02Regular,
  "&.left": {
    textAlign: "left",
    justifyContent: "start",
  },
  "&.even": {
    backgroundColor: theme.palette.gray2,
  },
  "&.level0": {
    backgroundColor: theme.palette.white,
  },
  "&.level1": {
    backgroundColor: theme.palette.gray1,
  },
  "&.level2": {
    backgroundColor: theme.palette.gray2,
  },
  "&.level3": {
    backgroundColor: theme.palette.gray3,
  },
  "&.level4": {
    backgroundColor: theme.palette.gray4,
  },
  "&.force-even-odd.even": {
    backgroundColor: theme.palette.gray2,
  },
}));

interface CarInfoCellProps extends BoxProps {
  text?: string;
  pendoClass?: string;
  isOdd?: boolean;
  isForceEvenOdd?: boolean;
}

export const CarInfoCell = ({
  text,
  pendoClass,
  isOdd,
  isForceEvenOdd,
  children,
  className,
  ...props
}: CarInfoCellProps) => (
  <StyledCell
    className={clsx(className, {
      even: !isOdd,
      "force-even-odd": isForceEvenOdd,
      left: props.justifyContent === "start",
    })}
    {...props}
  >
    {text ?? children}
    {pendoClass ? (
      <CarTooltipBox sx={{ ml: 0.25 }} className={pendoClass} />
    ) : undefined}
  </StyledCell>
);

interface CarInfoExpandableRowProps {
  sx?: BoxProps["sx"];
  caption: string;
  captionIsRegular?: boolean;
  isTotal?: boolean;
  isHighlighted?: boolean;
  gridTemplateColumns?: string;
  values: Array<string>;
  level: number;
  childContent?: Array<ReactNode>;
  defaultIsExpanded?: boolean;
  isOdd?: boolean;
  isForceEvenOdd?: boolean;
}
export const CarInfoExpandableRow = (props: CarInfoExpandableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(
    props.defaultIsExpanded ?? false,
  );

  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "1fr", ...props.sx }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns:
            props.gridTemplateColumns ??
            `repeat(${props.values.length + 1}, 1fr)`,
          [`& > *.level${props.level}`]: {
            backgroundColor: props.isTotal
              ? "gray3"
              : props.isHighlighted
              ? "lightOrange"
              : undefined,
          },
        }}
      >
        <CarInfoCell
          className={`level${props.level}`}
          justifyContent="start"
          isOdd={props.isOdd}
          isForceEvenOdd={props.isForceEvenOdd}
          sx={{
            cursor: props.childContent?.length ? "pointer" : undefined,
          }}
          onClick={() => setIsExpanded((value) => !value)}
        >
          <Typography
            variant={props.captionIsRegular ? "h6SRegular" : "h6SBold"}
            sx={{ ml: props.level * 2 }}
          >
            {props.caption}
          </Typography>
          {!!props.childContent?.length && (
            <ExpandLess
              fontSize="small"
              sx={{
                ml: "auto",
                transform: `rotate(${isExpanded ? 0 : 180}deg)`,
                transition: "transform 0.3s",
                color: "gray9",
              }}
            />
          )}
        </CarInfoCell>
        {props.values.map((i, idx) => (
          <CarInfoCell
            className={`level${props.level}`}
            isOdd={props.isOdd}
            isForceEvenOdd={props.isForceEvenOdd}
            text={i}
            key={idx}
          />
        ))}
      </Box>
      {!!props.childContent?.length && (
        <Collapse in={isExpanded} key={1}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
            }}
          >
            {props.childContent}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};
