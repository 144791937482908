import { Box } from "@mui/material";
import { ReactElement } from "react";
import { LoginMode, useLogin } from "./useLogin";
import { FullScreenBackground } from "components/FullScreenBackground";
import { CarFooter } from "components/Footer";
import { LoginContainer } from "./LoginContainer";
import { TwoFactorForm } from "./TwoFactorForm";
import { VerifyEmailForm } from "./VerifyEmailForm";

export const LoginPage = ({ showTrialPage }: { showTrialPage?: boolean }) => {
  const data = useLogin({ showTrialPage });

  const getContent: () => ReactElement = () => {
    switch (data.mode) {
      case LoginMode.login:
      case LoginMode.trial:
      case LoginMode.resetPassword:
      case LoginMode.resetPasswordSent:
        return <LoginContainer data={data} />;
      case LoginMode.verifyTwoFactor:
        return <TwoFactorForm data={data} />;
      case LoginMode.verifyEmail:
        return <VerifyEmailForm data={data} />;
      default:
        throw new Error("mode is not supported");
    }
  };

  return (
    <FullScreenBackground
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
        }}
      >
        {getContent()}
        <CarFooter
          sx={{
            mt: 2.5,
            alignSelf: "center",
            ".copyright": {
              fontSize: "12px",
            },
          }}
          isWhite
          isCopyrightOnly
          hasPrivacyPolicy
        />
      </Box>
    </FullScreenBackground>
  );
};
