import { LoginMode, UseLogin } from "./useLogin";
import { LoginLogo, LoginTransparentBox } from "./LoginComponents";
import { StartTrialForm } from "./StartTrialForm";
import { LoginForm } from "./LoginForm";

export const LoginContainer = ({ data }: { data: UseLogin }) => {
  const isTrial = data.mode === LoginMode.trial;
  return (
    <LoginTransparentBox
      sx={{ width: isTrial ? 1022 : 531, minHeight: 500, overflow: "hidden" }}
    >
      <LoginLogo />
      {!isTrial && <LoginForm data={data} />}
      {isTrial && <StartTrialForm data={data} />}
    </LoginTransparentBox>
  );
};
