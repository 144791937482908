import { Box, BoxProps, Typography } from "@mui/material";
import { CarNumberField } from "components/NumberField";
import { CarSelectOptionField } from "components/Inputs";
import { CarLoadingButton } from "components/Buttons";
import {
  ComparisonType,
  UseSimulationDataExplorerInput,
} from "./useSimulationDataExplorerInput";
import { CarAssetClassSelector } from "components/AssetClassSelector";

interface SimulationDataExplorerFilterProps {
  sx?: BoxProps["sx"];
  simulationDataExplorerInput: UseSimulationDataExplorerInput;
  isReadOnly?: boolean;
}

export const SimulationDataExplorerFilter = (
  props: SimulationDataExplorerFilterProps,
) => {
  const { input, setInput, ...data } = props.simulationDataExplorerInput;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, ...props.sx }}>
      <Typography variant="h6SemiBold">Filter where</Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          gap: 2,
        }}
      >
        <CarAssetClassSelector
          sx={{ width: 250 }}
          label="Asset Class"
          items={data.assetClassSelectorItems}
          valueField="code"
          disabled={props.isReadOnly}
          value={input.assetClassCode}
          onChange={(assetClassCode) =>
            setInput((val) => ({ ...val, assetClassCode }))
          }
        />
        <CarSelectOptionField
          sx={{ width: 200 }}
          label="Field"
          options={data.debugFieldSelectorOptions}
          disabled={props.isReadOnly}
          value={input.filterDataType}
          onChange={(filterDataType) =>
            setInput((val) => ({ ...val, filterDataType }))
          }
        />
        <Typography variant="par01SemiBold" sx={{ mb: 1.25 }}>
          is
        </Typography>
        <CarSelectOptionField
          sx={{ width: 150 }}
          label=">/< or Between>"
          options={[
            { label: "More than", value: ComparisonType.moreThan },
            { label: "Less than", value: ComparisonType.lessThan },
            { label: "Between", value: ComparisonType.between },
          ]}
          disabled={props.isReadOnly}
          value={input.comparison}
          onChange={(comparison) => setInput((val) => ({ ...val, comparison }))}
        />
        {input.comparison === ComparisonType.between && (
          <>
            <CarNumberField
              sx={{ width: 150 }}
              label="Min. Value"
              value={input.filterValueGt}
              decimalPlaces={2}
              disabled={props.isReadOnly}
              onChange={(filterValueGt) =>
                setInput((val) => ({ ...val, filterValueGt }))
              }
            />
            <CarNumberField
              sx={{ width: 150 }}
              label="Max. Value"
              decimalPlaces={2}
              disabled={props.isReadOnly}
              value={input.filterValueLt}
              onChange={(filterValueLt) =>
                setInput((val) => ({ ...val, filterValueLt }))
              }
            />
          </>
        )}

        {input.comparison === ComparisonType.lessThan && (
          <CarNumberField
            sx={{ width: 150 }}
            label="Value"
            decimalPlaces={2}
            disabled={props.isReadOnly}
            value={input.filterValueLt}
            onChange={(filterValueLt) =>
              setInput((val) => ({ ...val, filterValueLt }))
            }
          />
        )}
        {input.comparison === ComparisonType.moreThan && (
          <CarNumberField
            sx={{ width: 150 }}
            label="Value"
            decimalPlaces={2}
            disabled={props.isReadOnly}
            value={input.filterValueGt}
            onChange={(filterValueGt) =>
              setInput((val) => ({ ...val, filterValueGt }))
            }
          />
        )}
        <Typography variant="par01SemiBold" sx={{ mb: 1.25 }}>
          at
        </Typography>
        <CarSelectOptionField
          sx={{
            width: 150,
            ".MuiSelect-select": {
              textAlign: "center",
            },
          }}
          label="Year"
          disabled={props.isReadOnly}
          value={input.filterYear}
          options={data.yearsSelectorOptions}
          onChange={(filterYear) =>
            setInput((val) => ({
              ...val,
              filterYear,
            }))
          }
        />
        {!props.isReadOnly && (
          <CarLoadingButton onClick={data.handleRun} sx={{ mb: -0.5 }}>
            Run
          </CarLoadingButton>
        )}
      </Box>
    </Box>
  );
};
