import { Box, BoxProps, MenuItem } from "@mui/material";
import { UseStatAxisSelector } from "./useStatAxisSelector";
import { CarSelectField } from "components/Inputs";
import { CarPortfolioStatRow } from "../usePortfolioGroupVersionData";

interface StatAxisSelectorProps {
  items: CarPortfolioStatRow[];
  statAxisSelector: UseStatAxisSelector;
  isWhiteContext?: boolean;
  sx?: BoxProps["sx"];
}
export const StatAxisSelector = (props: StatAxisSelectorProps) => {
  return (
    <Box sx={{ display: "flex", gap: 3.5, ...props.sx }}>
      <CarSelectField
        sx={{
          width: 250,
          ".MuiInputLabel-root": {
            fontSize: "16px",
          },
          ".MuiInputBase-input": {
            backgroundColor: props.isWhiteContext ? "gray1" : "white",
          },
        }}
        label="X-axis"
        value={props.statAxisSelector.xStatId}
        onChange={(value) =>
          props.statAxisSelector.onXStatIdChange(value ?? "")
        }
      >
        {props.items.map((i) => (
          <MenuItem key={i.id} value={i.id}>
            {i.name}
          </MenuItem>
        ))}
      </CarSelectField>
      <CarSelectField
        sx={{
          width: 250,
          ".MuiInputLabel-root": {
            fontSize: "16px",
          },
          ".MuiInputBase-input": {
            backgroundColor: props.isWhiteContext ? "gray1" : "white",
          },
        }}
        label="Y-axis"
        value={props.statAxisSelector.yStatId}
        onChange={(value) =>
          props.statAxisSelector.onYStatIdChange(value ?? "")
        }
      >
        {props.items.map((i) => (
          <MenuItem key={i.id} value={i.id}>
            {i.name}
          </MenuItem>
        ))}
      </CarSelectField>
    </Box>
  );
};
