import { Avatar, Box, BoxProps } from "@mui/material";
import { CarFileSelectButton } from "./FileSelectButton";
import { PhotoCameraOutlined } from "@mui/icons-material";

export interface CarAvatarFieldProps extends BoxProps {
  value?: string;
  onFileSelected: (selection: File[]) => void;
}

export const CarAvatarField = ({
  value,
  onFileSelected,
  ...props
}: CarAvatarFieldProps) => (
  <Box
    {...props}
    sx={{
      display: "flex",
      position: "relative",
      ...props.sx,
    }}
  >
    <Avatar
      sx={{
        width: 90,
        height: 90,
        borderStyle: "solid",
        borderWidth: 3,
        borderColor: "primary.main",
      }}
      src={value}
    />
    <CarFileSelectButton
      color="inherit"
      sx={{
        backgroundColor: "primary.main",
        "&:hover": {
          backgroundColor: "primary.light",
        },
        color: "common.white",
        width: 30,
        height: 30,
        position: "absolute",
        bottom: 0,
        right: 0,
      }}
      size="small"
      accept="image/png, image/jpeg"
      onFileSelected={onFileSelected}
    >
      <PhotoCameraOutlined fontSize="small" />
    </CarFileSelectButton>
  </Box>
);
