import { BoxProps, Typography } from "@mui/material";
import { CarTextField } from "components/Inputs";
import { RootPageBox } from "components/RootPage";

interface PublishPlanNotesProps {
  sx?: BoxProps["sx"];
  value?: string;
  onChange: (value?: string) => void;
}

export const PublishPlanNotes = ({
  sx,
  value,
  onChange,
}: PublishPlanNotesProps) => {
  return (
    <RootPageBox sx={{ p: 4, gap: 2.5, ...sx }}>
      <Typography variant="h4SSemiBold">Notes</Typography>
      <CarTextField value={value} onChange={onChange} multiline minRows={5} />
    </RootPageBox>
  );
};
