import { Box, styled } from "@mui/material";
import { usePlanResults } from "features/planResults/usePlanResults";
import { TablePlaceHolder } from "components/PlaceHolder";
import { PlanResultsCarousel } from "../planResults/PlanResultsCarousel";
// import { PublishPlanYearSummary } from "./PublishPlanYearSummary";
import { usePublishPlanActionPlan } from "features/publishPlanDialog/usePublishPlanActionPlan";
import { usePlanSummary } from "features/planSummary/usePlanSummary";
import { PublishPlanGoals } from "./PublishPlanGoals";
import { CarCheckbox } from "components/Checkbox";
import { useState } from "react";
import { PublishPlanRecommendedChanges } from "./PublishPlanRecommendedChanges";
import { PublishPlanRisk } from "./PublishPlanRisk";
import {
  useAlEnginePlanResultPublishPlanResultUpdateMutation,
  useQuestionnaireClientsNotesCreateMutation,
} from "api/carApi.generated";
import { useSuccessToast } from "app/useSuccessToast";
import { CarButton } from "components/Buttons";
import { PublishPlanNotes } from "./PublishPlanNotes";
import { financialPlanningPages } from "route.const";
import { useNavigate } from "react-router-dom";
import { useClientId } from "app/usePlanId";
import { v4 } from "uuid";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

const ApproveCheckbox = styled(CarCheckbox)(({ theme }) => ({
  alignItems: "start",
  ".MuiTypography-root": {
    marginTop: theme.spacing(1.3),
    userSelect: "none",
    ...theme.typography.mediumItalic,
  },
}));

const CheckboxGroup = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
}));

const acceptGoals = `I/We approve the financial plan summarized above, and believe that the goals expressed and the estimated probability of achieving those goals are consistent with our long term financial plans.`;

const acceptHouseholdAlloc1 = `I/We understand the new investment strategy recommended by our advisor, the risks inherent in that strategy, and how
those risks differ from our existing investment strategy.`;

const acceptHouseholdAlloc2 = `I/We understand that we may need to pay capital gains taxes to implement the new strategy, and that these taxes will
be different from any estimated capital gains obligations.`;

const acceptRisk1 = `I/We have reviewed the estimated investment risks summarized above and understand the risks inherent in the new
investment strategy recommended by our advisor.`;

const acceptRisk2 = `I/We have the risk tolerance to accept these risks as a part of our overall financial plan.`;

const acceptRisk3 = `I/We understand that the investment risk of the new strategy is higher than our prior profiled risk tolerance level. The
planning process has given us a better understanding of the tradeoff between our financial goals and investment risks,
and that makes us comfortable increasing our risk tolerance profile to the new level.`;

export const PublishPlan = () => {
  const [publishMut] = useAlEnginePlanResultPublishPlanResultUpdateMutation();
  const [addNoteMut] = useQuestionnaireClientsNotesCreateMutation();

  const pageContent = usePageContent();
  const planResults = usePlanResults();
  const publishPlanActionPlan = usePublishPlanActionPlan();
  const planSummary = usePlanSummary();

  const toast = useSuccessToast();

  const [isGoalsApproved, setIsGoalsApproved] = useState(false);

  const [isHouseholdAlloc1Approved, setIsHouseholdAlloc1Approved] =
    useState(false);
  const [isHouseholdAlloc2Approved, setIsHouseholdAlloc2Approved] =
    useState(false);

  const [isRisk1Approved, setIsRisk1Approved] = useState(false);
  const [isRisk2Approved, setIsRisk2Approved] = useState(false);
  const [isRisk3Approved, setIsRisk3Approved] = useState(false);

  const [notes, setNotes] = useState<string | undefined>("");

  const navigate = useNavigate();
  const clientId = useClientId();

  const canPublish =
    isGoalsApproved &&
    isHouseholdAlloc1Approved &&
    isHouseholdAlloc2Approved &&
    isRisk1Approved &&
    isRisk2Approved &&
    (!planResults.isRiskMoreThanProfiled || isRisk3Approved);

  const publishPlan = async () => {
    if (!canPublish || !planResults.selectedPlanResultId) {
      return;
    }

    const publishResult = await publishMut({
      id: planResults.selectedPlanResultId,
      planResult: undefined as any,
    });

    if ("data" in publishResult) {
      if (notes?.trim()) {
        await addNoteMut({
          clientId,
          clientNote: {
            id: v4(),
            created_at: new Date().toISOString(),
            note_source: "Publish Plan",
            note_text: notes?.trim(),
          },
        });
      }

      toast({
        kind: "success",
        message: "Success! This plan has been published",
      });

      // if (result.printActionPlan) {
      //   openInNewTab(
      //     rootPages.getActionReportUrl({
      //       clientId,
      //       planId,
      //       planTypeId,
      //     })
      //   );
      // }

      navigate(financialPlanningPages.getClientSummaryUrl(clientId));
    }

    return;
  };

  return (
    <TablePlaceHolder
      isLoading={
        pageContent.isLoading ||
        planResults.isLoading ||
        publishPlanActionPlan.isLoading ||
        planSummary.isLoading
      }
      rows={10}
    >
      <CarPageContent
        isWhiteContext
        content={pageContent.getContent(
          CarPageContentCode.PLAN_WORKBENCH_PUBLISH_PLAN_DIRECTIONS,
        )}
      />
      <Box
        sx={{
          mt: 6,
          width: 1088,
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PlanResultsCarousel data={planResults} />
        {/* <PublishPlanYearSummary data={publishPlanActionPlan} sx={{ mt: 6 }} /> */}
        <PublishPlanGoals
          goals={planSummary.goals}
          sx={{ mt: 6 }}
          footer={
            <CheckboxGroup sx={{ mt: 3 }}>
              <ApproveCheckbox
                label={acceptGoals}
                checked={isGoalsApproved}
                onChange={(e, checked) => setIsGoalsApproved(checked)}
              />
            </CheckboxGroup>
          }
        />
        <PublishPlanRecommendedChanges
          sx={{ mt: 10 }}
          footer={
            <CheckboxGroup>
              <ApproveCheckbox
                label={acceptHouseholdAlloc1}
                checked={isHouseholdAlloc1Approved}
                onChange={(e, checked) => setIsHouseholdAlloc1Approved(checked)}
              />
              <ApproveCheckbox
                label={acceptHouseholdAlloc2}
                checked={isHouseholdAlloc2Approved}
                onChange={(e, checked) => setIsHouseholdAlloc2Approved(checked)}
              />
            </CheckboxGroup>
          }
        />
        <PublishPlanRisk
          sx={{ mt: 10 }}
          data={planResults}
          footer={
            <CheckboxGroup>
              <ApproveCheckbox
                label={acceptRisk1}
                checked={isRisk1Approved}
                onChange={(e, checked) => setIsRisk1Approved(checked)}
              />
              <ApproveCheckbox
                label={acceptRisk2}
                checked={isRisk2Approved}
                onChange={(e, checked) => setIsRisk2Approved(checked)}
              />
              {planResults.isRiskMoreThanProfiled && (
                <ApproveCheckbox
                  label={acceptRisk3}
                  checked={isRisk3Approved}
                  onChange={(e, checked) => setIsRisk3Approved(checked)}
                />
              )}
            </CheckboxGroup>
          }
        />
        <PublishPlanNotes sx={{ mt: 8 }} value={notes} onChange={setNotes} />
        <CarButton
          sx={{ mt: 10, alignSelf: "center", fontSize: "18px", minWidth: 192 }}
          onClick={publishPlan}
          disabled={!canPublish}
        >
          Publish Portfolio
        </CarButton>
      </Box>
    </TablePlaceHolder>
  );
};
