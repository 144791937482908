import { useClientSummary } from "../clientSummary/useClientSummary";
import { useAlEngineRenderProgressMonitoringResultsRetrieveQuery } from "api/carApi.generated";

export const useProgressMonitoring = () => {
  const clientSummary = useClientSummary();

  const progressMonitoringQuery =
    useAlEngineRenderProgressMonitoringResultsRetrieveQuery(
      {
        id: clientSummary.publishedPlanParams?.planId ?? "",
      },
      { skip: !clientSummary.publishedPlanParams?.planId },
    );

  return {
    isLoading: clientSummary.isLoading || progressMonitoringQuery.isLoading,
    isFetching: progressMonitoringQuery.isFetching,
    clientSummary,
    render: progressMonitoringQuery.data?.render_results,
    chartResults: Array.from(
      progressMonitoringQuery.data?.chart_results ?? [],
    ).sort((a, b) => a.date.localeCompare(b.date)),
  };
};
