import { PdfChart, PdfChartProps } from "components/Pdf";
import { ChartConfiguration } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { useTheme } from "@mui/material";
import { Image as PdfImage, Text, View } from "@react-pdf/renderer";
import { CarPortfolioChartItem } from "../asset-allocation-types";
import { formatPercentFactor } from "utils";
import { getMultiPeriodReturnComparisonChartData } from "../editPortfolioGroup/ChartMultiPeriodReturnComparison";
import { ChartDataset } from "chart.js";

const stripedBlue = new Image();
stripedBlue.src =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURZS74m6c0s+RzegAAAAJcEhZcwAADsEAAA7BAbiRa+0AAAA0SURBVBjTfc3LDQAgCATRoQPov1nXOPGmXJa88AEmlaCSzQ8QCmEQdnMGENK4gXBPvL81CzKfBEEcf/J0AAAAAElFTkSuQmCC";

const stripedGreen = new Image();
stripedGreen.src =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURb7Moqu+huWQCDwAAAAJcEhZcwAADsEAAA7BAbiRa+0AAAA0SURBVBjTfc3LDQAgCATRoQPov1nXOPGmXJa88AEmlaCSzQ8QCmEQdnMGENK4gXBPvL81CzKfBEEcf/J0AAAAAElFTkSuQmCC";

interface PortfolioGroupMultiPeriodReturnComparisonPdfChartProps
  extends Omit<PdfChartProps, "config"> {
  data: CarPortfolioChartItem[];
  data1Id: string;
  data2Id: string;
}

const PdfChartLegend = ({
  config,
  hasMaxFee,
}: {
  config: ChartConfiguration<"line" | "scatter">;
  hasMaxFee: boolean;
}) => {
  return (
    <View
      style={{
        marginTop: 6,
        marginLeft: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {config.data.datasets.map((ds, idx) => {
        const backgroundLegend =
          "backgroundLegend" in ds
            ? (ds["backgroundLegend"] as string)
            : undefined;
        return (
          <View
            key={idx}
            style={{
              marginLeft: 6,
              marginRight: 6,
              flexDirection: "row",
              alignItems: "flex-start",
              height: 34,
            }}
          >
            {backgroundLegend ? (
              <PdfImage
                src={backgroundLegend}
                style={{
                  width: 10,
                  height: 10,
                  marginRight: 4,
                }}
              />
            ) : (
              <View
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: String(ds.backgroundColor ?? "black"),
                  marginRight: 4,
                }}
              />
            )}
            <Text
              style={{
                fontSize: 10,
              }}
            >
              {ds.label}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export const PortfolioGroupMultiPeriodReturnComparisonPdfChart = ({
  data,
  data1Id,
  data2Id,
  ...props
}: PortfolioGroupMultiPeriodReturnComparisonPdfChartProps) => {
  const theme = useTheme();

  const { bar1, bar2, bar1MaxFee, bar2MaxFee, yLabel, xLabels } =
    getMultiPeriodReturnComparisonChartData({
      data,
      data1Id,
      data2Id,
    });

  const font = {
    size: 14,
    weight: "400",
    lineHeight: 1,
  };

  const datasets: Array<ChartDataset<"bar", number[]>> = [];

  datasets.push({
    ...bar1,
    backgroundColor: theme.palette.chartsColor.green,
  });

  if (bar1MaxFee) {
    datasets.push({
      ...bar1MaxFee,
      backgroundColor: ({ chart: { ctx } }) =>
        ctx.createPattern(stripedGreen, "repeat") ?? undefined,
      ...{ backgroundLegend: stripedGreen.src },
    });
  }

  datasets.push({
    ...bar2,
    backgroundColor: theme.palette.chartsColor.blue,
  });

  if (bar2MaxFee) {
    datasets.push({
      ...bar2MaxFee,
      backgroundColor: ({ chart: { ctx } }) =>
        ctx.createPattern(stripedBlue, "repeat") ?? undefined,
      ...{ backgroundLegend: stripedBlue.src },
    });
  }

  const config: ChartConfiguration<"bar", number[]> = {
    type: "bar",
    options: {
      devicePixelRatio: 4,
      responsive: false,
      animation: false,
      borderColor: "transparent",

      layout: {
        padding: { right: 1 }, // for some reason right border is cropped, so add right padding to fit it in image
      },

      scales: {
        x: {
          type: "category",
          ticks: {
            font,
            color: theme.palette.black,
            padding: 6,
          },
          border: { display: false },
          grid: {
            drawTicks: false,
            color: theme.palette.gray2,
            lineWidth: 1,
          },
          title: {
            display: false,
          },
          labels: xLabels,
        },
        y: {
          ticks: {
            font,
            color: theme.palette.black,
            padding: 6,
            callback: (value) =>
              typeof value === "number" ? formatPercentFactor(value) : value,
          },
          border: { display: false },
          grid: {
            drawTicks: false,
            color: theme.palette.gray2,
            lineWidth: 1,
          },
          title: {
            display: true,
            text: yLabel,
            color: theme.palette.black,
            font,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        roundedBackground: {
          contextColor: theme.palette.white,
          backgroundColor: "#FAFAFA", //theme.palette.gray1,
          borderColor: "#B1B1B3", // theme.palette.gray7,
          borderRadius: 0,
        },
      },
    },
    data: {
      datasets,
    },
    plugins: [ChartPlugins.roundedBackground],
  };

  return (
    <>
      <PdfChart config={config as any} {...props} />
      <PdfChartLegend
        config={config as any}
        hasMaxFee={!!bar1MaxFee && !!bar2MaxFee}
      />
    </>
  );
};
