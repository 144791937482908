import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import { ComponentProps, useCallback, useMemo } from "react";
import { Chart } from "react-chartjs-2";
import { ChartDataset } from "chart.js";
import {
  ChartTooltip,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { formatPercentFactor } from "utils";
import { ChartPlugins } from "chartUtils";
import { chartsColor } from "theme";
import { CarChartLegend, CarLegendItem } from "components/ChartLegend";
import { CarPortfolioAssetStatTableData } from "../usePortfolioGroupVersionStatTableData";

type Options = ComponentProps<typeof Chart>["options"];

interface SectorSummaryChartData {
  datasets: ChartDataset<"bar", number[]>[];
  yLabel: string;
  xLabels: string[];
}

export const getHistoricalMarketsSummaryChartData = ({
  data,
  moduleId,
}: {
  data: CarPortfolioAssetStatTableData;
  moduleId: string;
}): SectorSummaryChartData => {
  const band = data.bands.find((i) => i.moduleId === moduleId);

  const datasets: ChartDataset<"bar", number[]>[] =
    band?.columns.map<ChartDataset<"bar", number[]>>((cl, idx) => ({
      id: cl.columnId,
      type: "sharpBar" as "bar",
      label: cl.label,
      data: data.historicalStatRows.map<number>(
        (row) =>
          row.modules
            .find((i) => i.moduleId === moduleId)
            ?.values.find((val) => val.columnId === cl.columnId)?.allocation ??
          0,
      ),
      backgroundColor: Object.values(chartsColor)[idx],
      barPercentage: 0.8,
      categoryPercentage: 0.4,
    })) ?? [];

  return {
    datasets,
    yLabel: "Historical Return",
    xLabels: data.historicalStatRows.map((i) => i.name),
  };
};

interface ChartHistoricalMarketSummaryProps {
  sx?: BoxProps["sx"];
  data: CarPortfolioAssetStatTableData;
  moduleId: string;
  isWhiteContext?: boolean;
}

export const ChartHistoricalMarketSummary = ({
  sx,
  data,
  moduleId,
  isWhiteContext,
}: ChartHistoricalMarketSummaryProps) => {
  const theme = useTheme();
  const { tooltipPlugin, tooltipData } = useChartTooltip();

  const { datasets, yLabel, xLabels } = getHistoricalMarketsSummaryChartData({
    data,
    moduleId,
  });

  const renderTooltip: RenderTooltip = useCallback(
    (datasetIndex, dataIndex) => {
      const ds = datasets[datasetIndex];
      const value = ds?.data?.[dataIndex] ?? 0;
      const periodStr = xLabels[dataIndex] ?? "";
      return (
        <Box
          sx={{
            fontSize: 15,
            fontWeight: 600,
            color: "black",
            whiteSpace: "nowrap",
          }}
        >
          <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
            {ds?.label}
          </Typography>
          <br />
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {periodStr}
          </Typography>
          <br />
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {formatPercentFactor(value, 1)}
          </Typography>
        </Box>
      );
    },
    [datasets, xLabels],
  );

  const options = useMemo<Options>(
    () => ({
      maintainAspectRatio: false,
      animation: {
        duration: 500,
      },
      scales: {
        x: {
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 13,
              weight: "600",
            },
            color: theme.palette.softBlack,
          },
          grid: {
            display: false,
          },
          type: "category",
          labels: xLabels, // .map(i.length > 11 ? i.split(" ") : [i])),
        },
        y: {
          title: {
            display: true,
            text: yLabel,
            font: {
              family: theme.typography.fontFamily,
              size: 16,
              weight: "600",
            },
            color: theme.palette.softBlack,
          },
          beginAtZero: true,
          offset: false,
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 15,
              weight: "600",
            },
            color: theme.palette.softBlack,
            callback: (value) =>
              typeof value === "number" ? formatPercentFactor(value, 1) : value,
          },
          border: { display: false },
          grid: {
            display: true,
            color: (ctx) => {
              return ctx.tick.value === 0
                ? theme.palette.gray7
                : theme.palette.gray2;
            },
            lineWidth: 1,
            tickColor: theme.palette.white,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltipPlugin,
        roundedBackground: {
          contextColor: isWhiteContext
            ? theme.palette.white
            : theme.palette.gray1,
          backgroundColor: isWhiteContext
            ? theme.palette.gray1
            : theme.palette.white,
          borderColor: theme.palette.gray7,
          borderRadius: 5,
        },
      },
    }),
    [theme, tooltipPlugin, isWhiteContext, xLabels, yLabel],
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", ...sx }}>
      <ChartTooltip
        tooltipData={tooltipData}
        renderTooltip={renderTooltip}
        sx={{
          width: "100%",
          height: 470,
        }}
      >
        <Chart
          type="bar"
          datasetIdKey="id"
          data={{
            datasets,
          }}
          options={options}
          plugins={[ChartPlugins.roundedBackground]}
        />
      </ChartTooltip>
      <CarChartLegend
        items={datasets.map<CarLegendItem>((ds, idx) => ({
          color: String(ds.backgroundColor) ?? "",
          label: ds.label ?? "",
          datasetIndex: 0,
          dataIndex: idx,
        }))}
        sx={{ ml: "auto", mt: 5 }}
      />
    </Box>
  );
};
