import {
  ClientPlanAdditionalIncome,
  useQuestionnairePlanAdditionalIncomeCreateMutation,
  useQuestionnairePlanAdditionalIncomeDestroyMutation,
  useQuestionnairePlanAdditionalIncomeListQuery,
  useQuestionnairePlanAdditionalIncomeUpdateMutation,
} from "api/carApi.generated";
import { CarAdditionalIncome } from "types";
import { refetchOnFocus } from "const";
import { usePendingProxy } from "../general/usePendingProxy";
import _ from "lodash";
import { usePlanId } from "app/usePlanId";

export const useAdditionalIncome = () => {
  const planId = usePlanId();
  const { data, isLoading } = useQuestionnairePlanAdditionalIncomeListQuery(
    { planId },
    { refetchOnFocus }
  );

  const [createRel] = useQuestionnairePlanAdditionalIncomeCreateMutation();
  const [updateRel] = useQuestionnairePlanAdditionalIncomeUpdateMutation();
  const [deleteRel] = useQuestionnairePlanAdditionalIncomeDestroyMutation();

  const handleChange = (item: CarAdditionalIncome) =>
    updateRel({
      planId,
      id: item.id ?? "",
      clientPlanAdditionalIncome: item as ClientPlanAdditionalIncome,
    });

  const handleDelete = (item: CarAdditionalIncome) =>
    deleteRel({ planId, id: item.id ?? "" });

  const handleCreate = (item: CarAdditionalIncome) =>
    createRel({
      planId,
      clientPlanAdditionalIncome: item as ClientPlanAdditionalIncome,
    });

  const proxy = usePendingProxy<CarAdditionalIncome>({
    name: "additionalIncome",
    items: data ?? [],
    handleCreate,
    handleChange,
    handleDelete,
    normalizeItem: (item, oldItem) => {
      if (!item.amount) {
        delete item.amount;
      }

      if (item.amount && item.amount < 0) {
        delete item.amount;
      }

      // if changed start date than copy value to the end date
      if (
        item.start_date_caravel &&
        !_.isEqual(item.start_date_caravel, oldItem?.start_date_caravel)
      ) {
        item.end_date_caravel = { ...item.start_date_caravel };
      }
    },
    required: [
      "description",
      "start_date_caravel",
      "end_date_caravel",
      "amount",
      "frequency",
      // "inflation",
      "taxes",
    ],
  });

  const addRow = () =>
    proxy.handleCreate({
      frequency: 1,
      inflation: 0,
      taxes: "TAXABLE",
    });

  return {
    isLoading,
    ...proxy,
    addRow,
  };
};
