import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { useEffect, useMemo, useState } from "react";
import {
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";
import { DecimalOptions } from "utils";
import { useSimulationDebugFieldList } from "./useSimulationDebugFieldList";
import { useDialog } from "app/useDialog";
import { SimulationAnalyzePathsDialog } from "./SimulationAnalyzePathsDialog";
import { useSimulationList } from "./useSimulationList";
import { CarSelectOption } from "components/Inputs";
import { useSimulationQaReportSimAnalyzePathsCreateQuery } from "api/carApi.generated";
import { useTimeHorizonSelector } from "./useTimeHorizonSelector";
import { pendoClasses } from "app/thirdParty/pendo";

export interface CarSimDataColumn {
  year: number;
}

export interface CarSimDataRow {
  path: number;
  values: number[];
}

export interface CarSimDataTable {
  columns: CarSimDataColumn[];
  rows: CarSimDataRow[];
}

export interface SimulationDataExplorerDisplay {
  assetClassCode?: string;
  filterDataType?: string;
}

interface SortOrder {
  colIndex: number;
  isAscending: boolean;
}
export interface SimulationDataExplorerPresentation {
  checkedPaths: number[];
  itemsPerPage: number;
  pageNumber: number;
  sortOrder?: SortOrder;
  viewSelected: boolean;
}

const decimalOptions: DecimalOptions = {
  decimalPlaces: 2,
  forceShowDecimals: true,
};

interface UseSimulationDataExplorerOutputParams {
  simulationJobId: string;
  isRunning: boolean;
  defaultDisplay: SimulationDataExplorerDisplay;
  paths: number[];
}

export const useSimulationDataExplorerOutput = ({
  simulationJobId,
  isRunning,
  defaultDisplay,
  paths,
}: UseSimulationDataExplorerOutputParams) => {
  const excelExport = useExcelExport();
  const dialog = useDialog();

  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId,
  });
  const debugFieldsList = useSimulationDebugFieldList();
  const simulationList = useSimulationList({});

  const [display, setDisplay] =
    useState<SimulationDataExplorerDisplay>(defaultDisplay);

  const timeHorizonSelector = useTimeHorizonSelector();

  const [presentation, setPresentation] =
    useState<SimulationDataExplorerPresentation>({
      checkedPaths: [],
      itemsPerPage: 20,
      pageNumber: 0,
      viewSelected: false,
    });

  const displayAcCode = display.assetClassCode ?? "";
  const simAnalyzePaths = useSimulationQaReportSimAnalyzePathsCreateQuery(
    {
      simulationAnalyzePathsInput: {
        simulation_job_id: simulationJobId,
        ac_code: displayAcCode,
        filter_data_type: display.filterDataType ?? "",
        paths,
      },
    },
    {
      skip: !displayAcCode || !display.filterDataType || !paths.length,
    },
  );

  const debugFieldItems = useMemo(() => {
    const acItem = assetClasses.items.find((i) => i.code === displayAcCode);
    return acItem
      ? acItem.isEquity
        ? debugFieldsList.equityItems
        : debugFieldsList.fixedIncomeItems
      : debugFieldsList.items;
  }, [debugFieldsList, assetClasses, displayAcCode]);

  useEffect(() => {
    if (
      debugFieldItems.length &&
      !debugFieldItems.some((i) => i.filterCode === display.filterDataType)
    ) {
      setDisplay((value) => ({
        ...value,
        filterDataType: debugFieldItems.at(0)?.filterCode,
      }));
    }
  }, [debugFieldItems, display.filterDataType]);

  return useMemo(() => {
    const displayField = debugFieldsList.items.find(
      (i) => i.filterCode === display.filterDataType,
    );

    const isLogarithmic = displayField?.graphScale === "logarithmic";
    const isPercentage = displayField?.fieldType === "percentage";

    const pointHintLabel = displayField?.label ?? "";

    const simulationName =
      simulationList.getSimulationName(simulationJobId) ?? "";

    const fileSuffix = `${
      simulationList.isSimulationStatic(simulationJobId)
        ? "Static"
        : "Price-Driven"
    } - ${simulationName.replaceAll("/", "-").replaceAll(":", "-")}`;

    const handlePathClick = (pathNumber: number) => {
      const assetClassCode = display?.assetClassCode;
      if (simulationJobId && assetClassCode) {
        dialog(SimulationAnalyzePathsDialog, {
          simulationJobId,
          assetClassCode,
          pathNumber,
          fileSuffix,
        });
      }
    };

    const handleSortColumn = (columnIndex: number) => {
      let sortOrder: SortOrder | undefined = undefined;
      if (presentation.sortOrder?.colIndex === columnIndex) {
        if (presentation.sortOrder.isAscending) {
          sortOrder = { colIndex: columnIndex, isAscending: false };
        } else {
          sortOrder = undefined;
        }
      } else {
        sortOrder = { colIndex: columnIndex, isAscending: true };
      }
      setPresentation((val) => ({ ...val, pageNumber: 0, sortOrder }));
    };

    const columns: CarSimDataColumn[] =
      simAnalyzePaths.data?.data.at(0)?.map((i, idx) => ({ year: idx })) ?? [];

    let rows = Array.from(
      simAnalyzePaths.data?.data.map((row, rowIdx) => ({
        path: simAnalyzePaths.data?.path.at(rowIdx) ?? 0,
        values: row,
      })) ?? [],
    );

    const totalRowCount = rows.length;

    const so = presentation.sortOrder;
    if (so) {
      rows.sort((a, b) => {
        const aValue = a.values.at(so.colIndex);
        const bValue = b.values.at(so.colIndex);

        if (typeof aValue === "number" && typeof bValue === "number") {
          return so.isAscending ? aValue - bValue : bValue - aValue;
        } else {
          return 0;
        }
      });
    }

    if (presentation.viewSelected) {
      rows = rows.filter((i) => presentation.checkedPaths.includes(i.path));
    }

    const filteredRowCount = rows.length;

    rows = rows.slice(
      presentation.pageNumber * presentation.itemsPerPage,
      (presentation.pageNumber + 1) * presentation.itemsPerPage,
    );

    const fullTable: CarSimDataTable | undefined = totalRowCount
      ? {
          columns,
          rows,
        }
      : undefined;

    const filteredTable: CarSimDataTable | undefined = fullTable
      ? {
          columns: timeHorizonSelector.sliceTimeHorizon(fullTable.columns),
          rows: rows.map((i) => ({
            ...i,
            values: timeHorizonSelector.sliceTimeHorizon(i.values),
          })),
        }
      : undefined;

    const minValue =
      filteredTable?.rows.reduce((acc, i) => Math.min(acc, ...i.values), 0) ??
      0;

    const maxValue =
      filteredTable?.rows.reduce((acc, i) => Math.max(acc, ...i.values), 0) ??
      0;

    const handleExport = (onlyFiltered?: boolean) => {
      const exportTable = onlyFiltered ? filteredTable : fullTable;

      const table: ExcelTable = {
        name: "Years",
        columns: [
          { label: "Path", format: ExcelDataFormat.integer },
          ...(exportTable?.columns.map((i, idx) => ({
            label: i.year.toString(),
            format: ExcelDataFormat.percent,
            decimalOptions,
          })) ?? []),
        ],
        rows:
          exportTable?.rows.map((row, rowIdx) => [row.path, ...row.values]) ??
          [],
      };

      excelExport.exportAllExcel({
        fileName: `Simulation Filter Data - ${fileSuffix}`,
        tables: [table],
      });
    };

    const isPathChecked = (path: number) =>
      presentation.checkedPaths.includes(path);
    const handleCheckPath = (path: number) =>
      setPresentation((val) => ({
        ...val,
        checkedPaths: isPathChecked(path)
          ? val.checkedPaths.filter((i) => i !== path)
          : [...val.checkedPaths, path],
      }));

    const handleClearSelection = () => {
      setPresentation((val) => ({
        ...val,
        viewSelected: false,
        checkedPaths: [],
      }));
    };

    const itemsPerPageSelectorOptions: CarSelectOption<number>[] = [
      5, 10, 20, 50, 100, 200, 500, 1000,
    ].map((i) => ({ label: i.toString(), value: i }));

    const totalPageCount = Math.ceil(
      filteredRowCount / presentation.itemsPerPage,
    );

    const paginationLabel = `Show ${
      presentation.pageNumber * presentation.itemsPerPage + 1
    } - ${Math.min(
      (presentation.pageNumber + 1) * presentation.itemsPerPage,
      filteredRowCount,
    )} of ${filteredRowCount} paths`;

    const canGoPrevPage = presentation.pageNumber > 0;
    const handleGoPrevPage = () => {
      setPresentation((val) => ({
        ...val,
        pageNumber: Math.max(val.pageNumber - 1, 0),
      }));
    };
    const canGoNextPage = presentation.pageNumber < totalPageCount - 1;
    const handleGoNextPage = () => {
      setPresentation((val) => ({
        ...val,
        pageNumber: Math.min(val.pageNumber + 1, totalPageCount - 1),
      }));
    };

    return {
      isLoading: simAnalyzePaths.isFetching || isRunning,
      table: filteredTable,
      isLogarithmic,
      isPercentage,
      minValue,
      maxValue,
      pointHintLabel,
      handleDownloadAll: () => handleExport(false),
      handleDownloadSelected: () => handleExport(true),
      handlePathClick,

      assetClassSelectorItems: assetClasses.items,

      presentation,
      setPresentation,

      isPathChecked,
      handleCheckPath,
      handleClearSelection,

      itemsPerPageSelectorOptions,
      handleItemsPerPageChange: (value?: number) => {
        setPresentation((val) => ({
          ...val,
          pageNumber: 0,
          itemsPerPage: value ?? itemsPerPageSelectorOptions.at(0)?.value ?? 20,
        }));
      },
      paginationLabel,
      canGoPrevPage,
      handleGoPrevPage,
      canGoNextPage,
      handleGoNextPage,

      handleSortColumn,

      handleViewSelectedChange: (viewSelected: boolean) => {
        setPresentation((val) => ({
          ...val,
          pageNumber: 0,
          viewSelected,
        }));
      },

      debugFieldSelectorOptions: debugFieldItems.map<CarSelectOption<string>>(
        (i) => ({
          value: i.filterCode,
          label: i.label,
          pendoClass: pendoClasses.simulationFieldYAxis(i.filterCode),
        }),
      ),
      display,
      setDisplay,
      timeHorizonSelector,
    };
  }, [
    assetClasses.items,
    debugFieldsList.items,
    simulationList,
    simulationJobId,
    simAnalyzePaths.data?.data,
    simAnalyzePaths.data?.path,
    simAnalyzePaths.isFetching,
    presentation,
    timeHorizonSelector,
    isRunning,
    debugFieldItems,
    display,
    dialog,
    excelExport,
  ]);
};

export type UseSimulationDataExplorerOutput = ReturnType<
  typeof useSimulationDataExplorerOutput
>;
