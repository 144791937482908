import {
  useQuestionnairePlanDeriskCreateMutation,
  useQuestionnairePlanDeriskListQuery,
  useQuestionnairePlanDeriskUpdateMutation,
} from "api/carApi.generated";
import { usePlanId } from "app/usePlanId";
import { refetchOnFocus } from "const";

export const useDerisk = () => {
  const planId = usePlanId();
  const derisk = useQuestionnairePlanDeriskListQuery(
    {
      planId,
    },
    { refetchOnFocus }
  );

  const [createDerisk] = useQuestionnairePlanDeriskCreateMutation();
  const [updateDerisk] = useQuestionnairePlanDeriskUpdateMutation();

  const deriskItem = derisk.data?.[0];

  const setDeriskModuleId = async (moduleId?: string) => {
    if (!moduleId) {
      return;
    }

    if (deriskItem?.id) {
      return updateDerisk({
        planId,
        id: deriskItem.id,
        clientPlanDeRiskDetails: {
          id: deriskItem.id,
          portfolio_module: moduleId,
        },
      });
    } else {
      return createDerisk({
        planId,
        clientPlanDeRiskDetails: {
          portfolio_module: moduleId,
        },
      });
    }
  };

  return {
    isLoading: derisk.isLoading,
    isFetching: derisk.isFetching,
    deriskModuleId: deriskItem?.portfolio_module,
    setDeriskModuleId,
  };
};
