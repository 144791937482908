import { ChartDataset } from "chart.js";
import { UsePlanResults } from "features/planResults/usePlanResults";
import {
  PlanResultCardChartComboProps,
  PlanResultCardChartCombo,
} from "./PlanResultCardChartCombo";
import { useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { formatCurrency } from "utils";
import { RenderTooltip } from "components/ChartTooltip";

export interface PlanResultCardNetWorthProps {
  data: UsePlanResults;
}

export function PlanResultCardNetWorth({ data }: PlanResultCardNetWorthProps) {
  const theme = useTheme();

  const chartProps: PlanResultCardChartComboProps = useMemo(() => {
    const datasets: ChartDataset[] = [
      {
        type: "line",
        label: "Client Net Worth",
        data: data.barChart.years.map((y) => y.amount.totalNetWorth),
        fill: true,
        borderColor: theme.palette.chartsColor.blue,
        backgroundColor: theme.palette.chartsColor.blueBackground,
      },
    ];

    if (
      data.barChart.years.some(
        (y) => y.amount.totalNetWorth !== y.amount.endingTotalPortfolioValue
      )
    ) {
      datasets.push({
        type: "line",
        label: "Investment Portfolio",
        data: data.barChart.years.map(
          (y) => y.amount.endingTotalPortfolioValue
        ),
        fill: true,
        borderColor: theme.palette.chartsColor.green,
        backgroundColor: theme.palette.chartsColor.greenBackground,
      });
    }

    const yearLabels = data.barChart.years.map((y) => y.year);

    const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
      const value = datasets[datasetIndex]?.data[dataIndex];
      return (
        <Box
          sx={{
            fontSize: 15,
            fontWeight: 600,
            color: "black",
            whiteSpace: "nowrap",
          }}
        >
          {yearLabels[dataIndex]}
          {" - "}
          {typeof value === "number" ? `${formatCurrency(value / 1000)}K` : ""}
        </Box>
      );
    };

    return {
      pendoPrefix: "net_worth",
      datasets,
      yearLabels,
      renderTooltip,
    };
  }, [data, theme]);

  return <PlanResultCardChartCombo {...chartProps} />;
}
