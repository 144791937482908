import { Box, useTheme } from "@mui/material";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { ChartDataset } from "chart.js";
import { useMemo } from "react";
import { formatCurrency } from "utils";
import {
  PlanResultCardChartCombo,
  PlanResultCardChartComboProps,
} from "./PlanResultCardChartCombo";
import { RenderTooltip } from "components/ChartTooltip";

interface PlanResultCardGoalNetWorthComboProps {
  data: UsePlanResults;
}

export function PlanResultCardGoalNetWorthCombo({
  data,
}: PlanResultCardGoalNetWorthComboProps) {
  const theme = useTheme();

  const chartProps: PlanResultCardChartComboProps = useMemo(() => {
    const maxY = Math.max(
      ...data.barChart.years.map(
        (y) => y.amount.need + y.amount.want + y.amount.dream,
      ),
      ...data.barChart.years.map((y) => y.amount.halfFailed),
      ...data.barChart.years.map((y) => y.amount.failed),
    );

    const maxY2 = Math.max(
      ...data.barChart.years.map((y) => y.amount.totalNetWorth),
      ...data.barChart.years.map((y) => y.amount.endingTotalPortfolioValue),
    );

    const toY = (v: number) => (maxY * maxY2 === 0 ? v : (v / maxY2) * maxY);
    const fromY = (v: number) => (maxY * maxY2 === 0 ? v : (v / maxY) * maxY2);

    const datasets: ChartDataset[] = [
      {
        type: "sharpBar" as "bar",
        label: "All goals",
        data: data.barChart.years.map(
          (y) => y.amount.need + y.amount.want + y.amount.dream,
        ),
        stack: "bar-stacked",
        backgroundColor: theme.palette.chartsColor.orange,
        order: 0,
      },
      {
        type: "sharpBar" as "bar",
        label: "Partially Funded Goal",
        data: data.barChart.years.map((y) => y.amount.halfFailed),
        stack: "bar-stacked",
        backgroundColor: theme.palette.yellow,
        order: 0,
      },
      {
        type: "sharpBar" as "bar",
        label: "Failed Goal",
        data: data.barChart.years.map((y) => y.amount.failed),
        stack: "bar-stacked",
        backgroundColor: theme.palette.red,
        order: 0,
      },
      {
        type: "line",
        label: "Client Net Worth",
        data: data.barChart.years.map((y) => toY(y.amount.totalNetWorth)),
        fill: true,
        borderColor: theme.palette.chartsColor.blue,
        backgroundColor: theme.palette.chartsColor.blueBackground,
        order: 1,
      },
    ];

    if (
      data.barChart.years.some(
        (y) => y.amount.totalNetWorth !== y.amount.endingTotalPortfolioValue,
      )
    ) {
      datasets.push({
        type: "line",
        label: "Investment Portfolio",
        data: data.barChart.years.map((y) =>
          toY(y.amount.endingTotalPortfolioValue),
        ),
        fill: true,
        borderColor: theme.palette.chartsColor.green,
        backgroundColor: theme.palette.chartsColor.greenBackground,
        order: 1,
      });
    }

    const yearLabels = data.barChart.years.map((y) => y.year);

    const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
      const value = datasets[datasetIndex]?.data[dataIndex];

      const formatValue = (v: number) => {
        return `${formatCurrency((datasetIndex > 2 ? fromY(v) : v) / 1000)}K`;
      };

      return (
        <>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: 600,
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            {yearLabels[dataIndex]}
            {" - "}
            {typeof value === "number" ? formatValue(value) : ""}
          </Box>
        </>
      );
    };

    return {
      pendoPrefix: "goal_net_worth_combo",
      datasets,
      yearLabels,
      renderTooltip,
      maxY,
      fromY,
    };
  }, [data, theme]);

  return <PlanResultCardChartCombo {...chartProps} />;
}
