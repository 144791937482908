import {
  DisplayTypeEnum2,
  useAssetClassesFirmAssetClassesListQuery,
  usePortfolioPortfolioGroupVersionAssetClassesListQuery,
  usePortfolioPortfolioGroupVersionRetrieveQuery,
  usePortfolioPortfolioGroupVersionStatusListQuery,
} from "api/carApi.generated";
import { CarAssetClassHierarchy } from "types";
import { debugLog, epsilonEquals, isDefined } from "utils";
import { useDataTypes } from "./useDataTypes";
import { useOptimizationJobs } from "./editPortfolioGroup/useOptimizationJobs";

export enum CarPortfolioAssetColumnEnum {
  existing = "existing",
  draft = "draft",
  benchmark = "benchmark",
  selected = "selected",
}

export type CarPortfolioAssetColumnId = CarPortfolioAssetColumnEnum | string;

export interface CarPortfolioAssetColumn {
  columnId: CarPortfolioAssetColumnId;
  yNavKey: string;
  dataId: string;
  portfolioGroupVersionId: string;
  // dataTypeId: string;
  isEditable: boolean;
  label: string;
  hasError: boolean;
}
export interface CarPortfolioAssetBand {
  moduleId: string;
  selectedDataId: string;
  label: string;
  sort: number;
  isCash: boolean;
  columns: CarPortfolioAssetColumn[];
}
export interface CarPortfolioAssetValue {
  columnId: CarPortfolioAssetColumnId;
  yNavKey: string;
  moduleId: string;
  dataId: string;
  id: string;
  allocation: number | undefined;
  isEditable: boolean;
  horizonYear?: number;
  simulationJobId?: string;
  portfolioGroupVersionId: string;
}

export interface CarPortfolioAssetModule {
  moduleId: string;
  isCash: boolean;
  selectedDataId: string;
  values: CarPortfolioAssetValue[];
}

export interface CarPortfolioAssetRow {
  firmAssetClassId?: string;
  parentId?: string;
  id: string;
  name: string;
  shortName: string;
  code: string;
  sort: number;
  color: string;
  children: CarPortfolioAssetRow[];
  modules: CarPortfolioAssetModule[];
  zeroValuesOnly: boolean;
}

export interface CarPortfolioStatRow {
  id: string;
  groupId?: string;
  name: string;
  code: string;
  pendoClassName: string;
  displayType: DisplayTypeEnum2;
  modules: CarPortfolioAssetModule[];
  percentile?: number;
}

export interface CarPortfolioStatGroup {
  id: string;
  name: string;
  code: string;
  isHistorical: boolean;
  rows: CarPortfolioStatRow[];
}
export interface CarPortfolioAssetTableData {
  bands: CarPortfolioAssetBand[];
  assetRows: {
    level1: CarPortfolioAssetRow[];
    level1Total?: CarPortfolioAssetRow;

    level2: CarPortfolioAssetRow[];
    level2Total?: CarPortfolioAssetRow;

    level3: CarPortfolioAssetRow[];
    level3Total?: CarPortfolioAssetRow;

    level4: CarPortfolioAssetRow[];
    level4Total?: CarPortfolioAssetRow;
  };
}

export interface CarPortfolioShowConfig {
  existing: boolean;
  benchmark: boolean;
  draft: boolean;
  selected: boolean;
  optimizationIds?: string[];
}

export const usePortfolioGroupVersionData = (params: {
  versionId?: string;
  publishedVersionId?: string;
  show?: CarPortfolioShowConfig;
  hideCash?: boolean;
  isBenchmarkEditable?: boolean;
  isPublishedOnly?: boolean;
  filterByModuleId?: string;
}) => {
  const statuses = usePortfolioPortfolioGroupVersionStatusListQuery();
  const assetClasses = useAssetClassesFirmAssetClassesListQuery();

  const version = usePortfolioPortfolioGroupVersionRetrieveQuery(
    {
      id: params.versionId ?? "",
    },
    {
      skip: !params.versionId,
    },
  );

  const publishedVersion = usePortfolioPortfolioGroupVersionRetrieveQuery(
    {
      id: params.publishedVersionId ?? "",
    },
    {
      skip: !params.publishedVersionId,
    },
  );

  const versionClasses = usePortfolioPortfolioGroupVersionAssetClassesListQuery(
    {
      portfolioGroupVersionId: params.versionId ?? "",
    },
    {
      skip: !params.versionId,
    },
  );

  const publishedVersionClasses =
    usePortfolioPortfolioGroupVersionAssetClassesListQuery(
      {
        portfolioGroupVersionId: params.publishedVersionId ?? "",
      },
      {
        skip: !params.publishedVersionId,
      },
    );

  const canLoadJobs =
    params.versionId &&
    (!params.show ||
      !params.show.optimizationIds ||
      params.show.optimizationIds?.length ||
      params.show.selected);

  const optimizationJobs = useOptimizationJobs({
    portfolioGroupVersionId: params.versionId ?? "",
    skip: !canLoadJobs,
  });

  const dataTypes = useDataTypes();

  const modules = Array.from(version.data?.portfolio_modules ?? []);
  const sortedModuleItems = params.filterByModuleId
    ? modules.filter((i) => i.id === params.filterByModuleId)
    : modules
        .filter((i) => !params.hideCash || !i.is_cash)
        .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0));

  const modToPublishedModMap = new Map<
    string | undefined,
    string | undefined
  >();

  sortedModuleItems.forEach((mod) => {
    modToPublishedModMap.set(
      mod.id,
      publishedVersion.data?.portfolio_modules?.find(
        (md) => md.title === mod.title,
      )?.id,
    );
  });

  const getDataIdByTypeId = (moduleId?: string, dataTypeId?: string) =>
    sortedModuleItems
      .find((md) => md.id === moduleId)
      ?.portfolio_module_data?.find((d) => d.type_id === dataTypeId)?.id;

  const getDataIdByJobId = (moduleId?: string, jobId?: string) =>
    sortedModuleItems
      .find((md) => md.id === moduleId)
      ?.portfolio_module_data?.find((d) => d.optimizer_job_root === jobId)?.id;

  const getPublishedDataIdByTypeId = (
    moduleId?: string,
    dataTypeId?: string,
  ) => {
    const publishedModuleId = modToPublishedModMap.get(moduleId);

    return publishedModuleId
      ? publishedVersion.data?.portfolio_modules
          ?.find((md) => md.id === publishedModuleId)
          ?.portfolio_module_data?.find((d) => d.type_id === dataTypeId)?.id
      : undefined;
  };

  const bands: CarPortfolioAssetBand[] = sortedModuleItems.map((i) => ({
    moduleId: i.id ?? "",
    selectedDataId: i.portfolio_module_data?.find((i) => i.selected)?.id ?? "",
    label: i.title,
    sort: i.sort_order ?? 0,
    isCash: !!i.is_cash,
    columns: [],
  }));

  bands.forEach((b) => {
    if (b.isCash) {
      if (b.isCash) {
        const dataId =
          getDataIdByTypeId(b.moduleId, dataTypes.userInputDataTypeId) ?? "";
        b.columns.push({
          columnId: CarPortfolioAssetColumnEnum.draft,
          yNavKey: `cash_${dataId}`,
          dataId,
          portfolioGroupVersionId: params.versionId ?? "",
          // dataTypeId: getDataT
          label: "-",
          isEditable: false,
          hasError: false,
        });
      }
    } else {
      if (!params.show || params.show.existing) {
        const dataId =
          getPublishedDataIdByTypeId(
            b.moduleId,
            dataTypes.userInputDataTypeId,
          ) ?? "";

        b.columns.push({
          columnId: CarPortfolioAssetColumnEnum.existing,
          yNavKey: `existing_${dataId}`,
          dataId,
          portfolioGroupVersionId: params.publishedVersionId ?? "",
          label: "Existing",
          isEditable: false,
          hasError: false,
        });
      }

      if (!params.show || params.show.selected) {
        const dataId = b.selectedDataId;
        // let label: string;

        // if (getDataIdByTypeId(b.moduleId, userInputDataTypeId) === dataId) {
        //   label = "Draft";
        // } else if (
        //   getDataIdByTypeId(b.moduleId, benchmarkDataTypeId) === dataId
        // ) {
        //   label = "Bench.";
        // } else {
        //   label =
        //     jobsList.data?.find(
        //       (job) => getDataIdByJobId(b.moduleId, job.id) === dataId
        //     )?.title ?? "-";
        // }

        b.columns.push({
          columnId: CarPortfolioAssetColumnEnum.selected,
          yNavKey: `selected_${dataId}`,
          dataId,
          portfolioGroupVersionId: params.versionId ?? "",
          label: "Selected",
          isEditable: false,
          hasError: false,
        });
      }

      if (!params.show || params.show.draft) {
        const dataId =
          getDataIdByTypeId(b.moduleId, dataTypes.userInputDataTypeId) ?? "";
        b.columns.push({
          columnId: CarPortfolioAssetColumnEnum.draft,
          yNavKey: `draft_${dataId}`,
          dataId,
          portfolioGroupVersionId: params.versionId ?? "",
          label: params.isPublishedOnly ? "Existing" : "Draft",
          isEditable: true,
          hasError: false,
        });
      }

      if (!params.show || params.show.benchmark) {
        const dataId =
          getDataIdByTypeId(b.moduleId, dataTypes.benchmarkDataTypeId) ?? "";
        b.columns.push({
          columnId: CarPortfolioAssetColumnEnum.benchmark,
          yNavKey: `benchmark_${dataId}`,
          dataId,
          portfolioGroupVersionId: params.versionId ?? "",
          label: "Bench.",
          isEditable: !!params.isBenchmarkEditable,
          hasError: false,
        });
      }

      optimizationJobs.items
        .filter(
          (job) =>
            !params.show ||
            !params.show.optimizationIds ||
            params.show.optimizationIds.includes(job.id),
        )
        .forEach((job) => {
          const dataId = getDataIdByJobId(b.moduleId, job.id) ?? "";

          b.columns.push({
            columnId: job.id,
            yNavKey: `optimization_${dataId}_${job.id}`,
            dataId,
            portfolioGroupVersionId: params.versionId ?? "",
            label: job.title,
            isEditable: true,
            hasError: false,
          });
        });
    }
  });

  // fill portfolioModuleDataIds from all visible bands - columns
  const portfolioModuleDataIds = new Set<string>();
  bands.forEach((b) =>
    b.columns.forEach((c) => {
      if (c.dataId) {
        portfolioModuleDataIds.add(c.dataId);
      } else {
        debugLog(
          `No data for Module: ${b.label} Column: ${c.label} columnId=${c.columnId}`,
        );
      }
    }),
  );

  const isLoading =
    dataTypes.isLoading ||
    statuses.isLoading ||
    assetClasses.isLoading ||
    version.isLoading ||
    publishedVersion.isLoading ||
    optimizationJobs.isLoading ||
    versionClasses.isLoading ||
    publishedVersionClasses.isLoading;

  const isFetching =
    dataTypes.isFetching ||
    statuses.isFetching ||
    assetClasses.isFetching ||
    version.isFetching ||
    publishedVersion.isFetching ||
    optimizationJobs.isFetching ||
    versionClasses.isFetching ||
    publishedVersionClasses.isFetching;

  const getDataItem = (moduleId?: string, dataId?: string) =>
    sortedModuleItems
      .find((md) => md.id === moduleId)
      ?.portfolio_module_data?.find((d) => d.id === dataId);

  const getPublishedDataItem = (moduleId?: string, dataId?: string) => {
    const publishedModuleId = modToPublishedModMap.get(moduleId);

    return publishedModuleId
      ? publishedVersion.data?.portfolio_modules
          ?.find((md) => md.id === publishedModuleId)
          ?.portfolio_module_data?.find((d) => d.id === dataId)
      : undefined;
  };

  const isAssetClassOptionActive = (firmAssetClassId?: string) =>
    versionClasses.data?.find((i) => i.firm_asset_class_id === firmAssetClassId)
      ?.is_active;

  const getAssetClassOptionId = (firmAssetClassId?: string) =>
    versionClasses.data?.find((i) => i.firm_asset_class_id === firmAssetClassId)
      ?.id;

  const getPublishedAssetClassOptionId = (firmAssetClassId?: string) =>
    publishedVersionClasses.data?.find(
      (i) => i.firm_asset_class_id === firmAssetClassId,
    )?.id;

  const getAllocationData = (
    moduleId: string,
    dataId: string,
    isPublished: boolean,
    firmAssetClassId?: string,
  ) => {
    const dataItem = isPublished
      ? getPublishedDataItem(moduleId, dataId)
      : getDataItem(moduleId, dataId);

    if (!dataItem) {
      if (!isLoading) {
        // console.error("dataItem not found", dataItem);
      }
      return undefined;
    }

    const optionId = isPublished
      ? getPublishedAssetClassOptionId(firmAssetClassId)
      : getAssetClassOptionId(firmAssetClassId);

    if (!optionId) {
      if (!isLoading) {
        // console.error("optionId not found", dataItem);
      }
      return undefined;
    }

    const allocItem = dataItem?.allocations?.find(
      (al) => al.portfolio_asset_class_options_id === optionId,
    );

    if (!isLoading && !allocItem) {
      // console.error("allocItem not found", dataItem, optionId);
    }

    return allocItem;
  };

  const getTableData = (): CarPortfolioAssetTableData => {
    const items = assetClasses.data ?? [];

    function getAssetClassModules(
      firmAssetClassId?: string,
    ): CarPortfolioAssetModule[] {
      return bands.map((bd) => ({
        moduleId: bd.moduleId,
        isCash: bd.isCash,
        selectedDataId: bd.selectedDataId,
        values: bd.columns.map((col) => {
          const item = getAllocationData(
            bd.moduleId,
            col.dataId,
            col.columnId === CarPortfolioAssetColumnEnum.existing,
            firmAssetClassId,
          );

          const result: CarPortfolioAssetValue = {
            columnId: col.columnId,
            yNavKey: col.yNavKey,
            id: item?.id ?? "",
            moduleId: bd.moduleId,
            isEditable: col.isEditable,
            dataId: item?.portfolio_module_data_id ?? "",
            portfolioGroupVersionId: col.portfolioGroupVersionId ?? "",
            allocation: item?.allocation,
          };

          return result;
        }),
      }));
    }

    const extractAssetItem = (
      items: Array<CarAssetClassHierarchy>,
      extractor: (
        i: CarAssetClassHierarchy,
      ) => CarPortfolioAssetRow | undefined,
    ) => {
      return items
        .reduce((acc, i) => {
          const item = extractor(i);
          return item ? [...acc.filter((v) => v.id !== item.id), item] : acc;
        }, [] as Array<CarPortfolioAssetRow>)
        .sort((a, b) => a.sort - b.sort);
    };

    const calcModules = (items: Array<CarPortfolioAssetRow>) => {
      function sumModules(
        a: CarPortfolioAssetModule,
        b?: CarPortfolioAssetModule,
      ): CarPortfolioAssetModule {
        return {
          moduleId: a.moduleId,
          isCash: a.isCash,
          selectedDataId: a.selectedDataId,
          values: a.values.map((i, idx) => {
            const alloc1 = i.allocation ?? 0;
            const alloc2 = b?.values[idx]?.allocation ?? 0;

            return {
              ...i,
              isEditable: false,
              allocation: alloc1 + alloc2,
            };
          }),
        };
      }

      return items
        .map((i) => i.modules)
        .reduce((acc, i) => {
          const [a, b] = acc.length > i.length ? [acc, i] : [i, acc];
          return a.map((item, idx) => sumModules(item, b[idx]));
        }, [] as Array<CarPortfolioAssetModule>);
    };

    const zeroValuesOnly = (modules: CarPortfolioAssetModule[]) =>
      !modules.every((i) =>
        i.values.every((val, idx, arr) => (val.allocation ?? 0) === 0),
      );

    const level4 = extractAssetItem(items, (i) => {
      const v = i.asset_class;

      if (!i.is_active || !v?.is_active) {
        return undefined;
      }

      const modules = getAssetClassModules(i.id);
      return (
        v && {
          firmAssetClassId: i.id,
          parentId: i.asset_class?.ac_level3?.id,
          id: v.id ?? "",
          name: v.display_name ?? "",
          shortName: v.display_short_name ?? "",
          code: v.code ?? "",
          color: v.color_code ?? "",
          sort: v.sort_order ?? 0,
          children: [],
          modules: getAssetClassModules(i.id),
          zeroValuesOnly: zeroValuesOnly(modules),
        }
      );
    }).filter((i) => isAssetClassOptionActive(i.firmAssetClassId));

    const level3 = extractAssetItem(items, (i) => {
      const v = i.asset_class?.ac_level3;
      const children = level4.filter((i) => i.parentId === v?.id);
      return (
        v && {
          parentId: i.asset_class?.ac_level3?.ac_level2?.id,
          id: v.id ?? "",
          name: v.name ?? "",
          shortName: v.name ?? "",
          code: v.code ?? "",
          color: v.color_code ?? "",
          sort: v.sort_order ?? 0,
          children,
          modules: calcModules(children),
          zeroValuesOnly: children.some((ch) => ch.zeroValuesOnly),
        }
      );
    }).filter((i) => i.children.length);

    const level2 = extractAssetItem(items, (i) => {
      const v = i.asset_class?.ac_level3?.ac_level2;
      const children = level3.filter((i) => i.parentId === v?.id);
      return (
        v && {
          parentId: i.asset_class?.ac_level3?.ac_level2?.ac_level1?.id,
          id: v.id ?? "",
          name: v.name ?? "",
          shortName: v.name ?? "",
          code: v.code ?? "",
          color: v.color_code ?? "",
          sort: v.sort_order ?? 0,
          children,
          modules: calcModules(children),
          zeroValuesOnly: children.some((ch) => ch.zeroValuesOnly),
        }
      );
    }).filter((i) => i.children.length);

    const level1 = extractAssetItem(items, (i) => {
      const v = i.asset_class?.ac_level3?.ac_level2?.ac_level1;
      const children = level2.filter((i) => i.parentId === v?.id);
      return (
        v && {
          id: v.id ?? "",
          name: v.name ?? "",
          shortName: v.name ?? "",
          code: v.code ?? "",
          color: v.color_code ?? "",
          sort: v.sort_order ?? 0,
          children,
          modules: calcModules(children),
          zeroValuesOnly: children.some((ch) => ch.zeroValuesOnly),
        }
      );
    }).filter((i) => i.children.length);

    const getTotal = (
      id: string,
      items: CarPortfolioAssetRow[],
    ): CarPortfolioAssetRow => ({
      id: id,
      name: "Total",
      shortName: "Total",
      code: id,
      color: "",
      sort: 10000,
      children: [],
      modules: calcModules(items),
      zeroValuesOnly: items.some((ch) => ch.zeroValuesOnly),
    });

    const level1Total = getTotal("level1Total", level1);
    const level2Total = getTotal("level2Total", level2);
    const level3Total = getTotal("level3Total", level3);
    const level4Total = getTotal("level4Total", level4);

    const totalHasError = (moduleId: string, dataId: string) => {
      const totalValue = level1Total.modules
        .find((m) => m.moduleId === moduleId)
        ?.values.find((v) => v.dataId === dataId);
      return totalValue && isDefined(totalValue.allocation)
        ? !epsilonEquals(totalValue.allocation, 1)
        : false;
    };

    bands.forEach((b) => {
      b.columns = b.columns.map((c) => ({
        ...c,
        hasError: totalHasError(b.moduleId, c.dataId),
      }));
    });

    return {
      bands,
      assetRows: {
        level1,
        level1Total,
        level2,
        level2Total,
        level3,
        level3Total,
        level4,
        level4Total,
      },
    };
  };

  const tableData: CarPortfolioAssetTableData = isLoading
    ? {
        bands: [],
        assetRows: {
          level1: [],
          level2: [],
          level3: [],
          level4: [],
        },
      }
    : getTableData();

  const isError = tableData.bands.some((b) =>
    b.columns.some((c) => c.hasError),
  );

  return {
    isLoading,
    isFetching,
    isReadOnly: !statuses.data?.find((i) => i.id === version.data?.status_id)
      ?.editable,
    tableData,
    assetAllocationIsLoading: version.isLoading || publishedVersion.isLoading,
    sortedModuleItems,
    isError,
  };
};
