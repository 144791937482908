import {
  NotificationTierThreshold,
  useNotificationsNotificationTierThresholdListQuery,
  useNotificationsNotificationTierThresholdUpdateMutation,
} from "api/carApi.generated";
import { useTiers } from "app/useTiers";
import { isDefined } from "utils";

export interface CarTierSetting {
  label: string;
  value: NotificationTierThreshold;
}

export const useSettingsTiers = () => {
  const tiers = useTiers();
  const tierThreshold = useNotificationsNotificationTierThresholdListQuery();

  const [update] = useNotificationsNotificationTierThresholdUpdateMutation();

  const items: CarTierSetting[] = tiers.items
    .map<CarTierSetting | undefined>((tier) => {
      const value = tierThreshold.data?.find((i) => i.client_tier === tier.id);
      return value
        ? {
            label: tier.name,
            value,
          }
        : undefined;
    })
    .filter(isDefined);

  const handleChange = (value: NotificationTierThreshold) => {
    update({
      id: value.id ?? "",
      notificationTierThreshold: value,
    });
  };

  return {
    isLoading: tiers.isLoading || tierThreshold.isLoading,
    items,
    handleChange,
  };
};

export type UseSettingsTiers = ReturnType<typeof useSettingsTiers>;
