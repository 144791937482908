import { Box } from "@mui/system";
import { CarButton } from "./Buttons";
import { BaseDialog } from "./BaseDialog";
import { Typography } from "@mui/material";

export interface RenderErrorDialogProps {
  errorMessage?: string;
  onApply: () => void;
  onCancel: () => void;
}

export const RenderErrorDialog = (props: RenderErrorDialogProps) => {
  const errorMessage = props.errorMessage
    ? `We are sorry, but the page will not load. Please go back and fix the error: \n\n${props.errorMessage}`
    : `We are sorry, but the page will not load. Please go back and try again. (back button takes you to plan input where you have to click next again)`;

  return (
    <BaseDialog
      onClose={props.onCancel}
      hideCloseButton
      actions={
        <>
          <CarButton
            sx={{ width: 200, alignSelf: "center" }}
            onClick={props.onCancel}
          >
            Go Back to Plan Input
          </CarButton>
        </>
      }
    >
      <Box
        sx={{
          width: 400,
          py: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Typography variant="h4SemiBold">Something went wrong :(</Typography>
        <Typography variant="par01Regular" sx={{ whiteSpace: "pre-line" }}>
          {errorMessage}
        </Typography>
      </Box>
    </BaseDialog>
  );
};
