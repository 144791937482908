import {
  PageSectionBlock,
  useCommonPageSelectionBlockRetrieveQuery,
  useCommonPageSelectionBlockCreateMutation,
  useCommonPageSelectionBlockUpdateMutation,
  useCommonPageSectionListQuery,
  useCommonPageSelectionBlockTypeListQuery,
} from "api/carApi.generated";
import { useForm } from "./useForm";
import { FormEditorDialog } from "./FormEditorDialog";
import { CarSelectOption } from "components/Inputs";
import { checkDefined, sortBySortOrder } from "utils";

interface PageSectionBlockEditorDialogProps {
  id?: string;
  pageSectionId: string;
  nextSortOrder?: number;
  onApply: () => void;
  onCancel: () => void;
}

export const PageSectionBlockEditorDialog = (
  props: PageSectionBlockEditorDialogProps,
) => {
  const query = useCommonPageSelectionBlockRetrieveQuery(
    {
      id: props.id ?? "",
    },
    { skip: !props.id },
  );

  const [create] = useCommonPageSelectionBlockCreateMutation();
  const [update] = useCommonPageSelectionBlockUpdateMutation();

  const pageSections = useCommonPageSectionListQuery();

  const pageSectionOptions =
    sortBySortOrder(pageSections.data)
      .filter((i) => i.is_active)
      .map<CarSelectOption<string>>((i) => ({
        label: `${i.title} (${i.code})`,
        value: checkDefined(i.id),
      })) ?? [];

  const blockTypes = useCommonPageSelectionBlockTypeListQuery();

  const blockTypeOptions =
    blockTypes.data?.map<CarSelectOption<string>>((i) => ({
      label: `${i.display_label} (${i.code})`,
      value: checkDefined(i.id),
    })) ?? [];

  const form = useForm<PageSectionBlock>({
    entityLabel: "Page Section Block",
    getItemName: (item) =>
      `${
        pageSectionOptions.find((i) => i.value === item.page_section)?.label ??
        ""
      } ${
        blockTypeOptions.find((i) => i.value === item.block_type)?.label ?? ""
      }`.trim(),
    isLoading:
      query.isLoading || pageSections.isLoading || blockTypes.isLoading,
    item: query.data,
    getNewItem: () => ({
      page_section: props.pageSectionId,
      is_active: true,
      sort_order: props.nextSortOrder,
    }),
    onClose: props.onCancel,
    onCreate: (item) =>
      create({
        pageSectionBlock: item,
      }),

    onUpdate: (item) =>
      update({
        id: item.id ?? "",
        pageSectionBlock: item,
      }),
    onDelete: async (item) => {
      // todo
    },
    fieldDefs: [
      {
        key: "page_section",
        type: "select",
        options: pageSectionOptions,
        label: "Page Section",
        description: "The page section this content belongs to",
        isRequired: true,
      },
      {
        key: "block_type",
        type: "select",
        options: blockTypeOptions,
        label: "Block Type",
        description: "The type of block",
        isRequired: true,
      },
      {
        key: "content",
        type: "markdown",
        label: "Content",
        description: "What the user will see on the screen",
        isRequired: true,
      },
      {
        key: "is_active",
        type: "boolean",
        label: "Is Active",
        description: "Is this page section block active",
      },
      {
        key: "sort_order",
        type: "integer",
        label: "Sort Order",
        description: "Sort order of page section block",
      },
    ],
  });

  return <FormEditorDialog form={form} />;
};
