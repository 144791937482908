import { UseHouseholdAllocation } from "../householdAllocation/useHouseholdAllocation";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfSubHeading } from "./PdfHeading";
import { CarPdfMarkdown } from "components/PdfMarkdown";
import { CarPdfTypography } from "components/PdfTypography";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfAllocationChart } from "./PdfAllocationChart";
import { CarPdfModulesTable } from "./PdfModulesTable";
import { CarPdfAllocationTable } from "./PdfAllocationTable";

interface PortfolioModuleBreakdownHouseholdPdfPageProps {
  portfolioLabel: string;
  householdAllocation: UseHouseholdAllocation;
  disclosures: UseDisclosures;
  pageProps: CarPdfPageProps;
}

export const PortfolioModuleBreakdownHouseholdPdfPage = (
  props: PortfolioModuleBreakdownHouseholdPdfPageProps,
) => {
  const text1 = props.disclosures.getContent(
    CarDisclosureContentCode.CMPAS_CLIENT_REPORT_PORTFOLIO_MODULE_BREAKDOWN_BY_HOUSEHOLD_part_10,
  );

  return (
    <CarPdfPage {...props.pageProps}>
      <CarPdfSubHeading label="Portfolio Module Breakdown: Household" />
      <CarPdfMarkdown style={{ marginTop: 10 }}>{text1}</CarPdfMarkdown>
      <CarPdfTypography
        style={{ marginTop: 20, marginBottom: 10 }}
        variant="h6"
      >
        Modules
      </CarPdfTypography>
      <CarPdfModulesTable
        breakdownItems={props.householdAllocation.breakdown}
        allocItems={props.householdAllocation.allocAssets.level1}
      />
      <CarPdfAllocationChart
        width={518}
        height={320}
        items={props.householdAllocation.allocAssets.level1}
        portfolioLabel={props.portfolioLabel}
      />
      <CarPdfBox wrap={false}>
        <CarPdfTypography
          style={{ marginTop: 20, marginBottom: 10 }}
          variant="h6"
        >
          Allocation
        </CarPdfTypography>
        <CarPdfAllocationTable
          portfolioLabel={props.portfolioLabel}
          items={props.householdAllocation.allocAssets.level1}
        />
      </CarPdfBox>
    </CarPdfPage>
  );
};
