import { usePlanTypeId } from "app/usePlanId";
import { useHouseholdAllocation } from "../householdAllocation/useHouseholdAllocation";
import { usePlanTypes } from "app/usePlanTypes";

export interface BreakdownChartItem {
  parentLabel: string;
  label: string;
  color?: string;
  dollars: number;
  percent: number;
}

export const usePublishPlanHouseholdAllocation = () => {
  const householdAllocation = useHouseholdAllocation();
  const planTypes = usePlanTypes();
  const planTypeId = usePlanTypeId();
  const portfolioLabel = planTypes.getPlanTypeLabel(planTypeId);
  const isLoading = householdAllocation.isLoading || planTypes.isLoading;

  const getParentLabel = (id?: string) =>
    householdAllocation.allocAssets.level1.find((i) => i.id)?.name ?? "";

  const existingChart =
    householdAllocation.allocAssets.level2.map<BreakdownChartItem>((i) => ({
      parentLabel: getParentLabel(i.parentId),
      label: i.name,
      color: i.color,
      dollars: i.current_dollars,
      percent: i.current_percent,
    }));

  const proposedChart =
    householdAllocation.allocAssets.level2.map<BreakdownChartItem>((i) => ({
      parentLabel: getParentLabel(i.parentId),
      label: i.name,
      color: i.color,
      dollars: i.proposed_dollars,
      percent: i.proposed_percent,
    }));

  return {
    isLoading,
    portfolioLabel,
    allocAssets: householdAllocation.allocAssets,
    existingChart,
    proposedChart,
  };
};

export type UsePublishPlanHouseholdAllocation = ReturnType<
  typeof usePublishPlanHouseholdAllocation
>;
