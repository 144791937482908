import { ChartConfiguration } from "chart.js";
import { getChartData } from "../../chartUtils";
import { errorColor } from "../../theme";
import { PlanResultDonutItem } from "../planResults/calcPlanResultDonuts";
import { fontPdf } from "components/Pdf";

export interface PdfDonutChartProps {
  item: PlanResultDonutItem;
  color: string;
  title: string;
  title2?: string;
}

interface Size {
  ascent: number;
  width: number;
  height: number;
}

const getSize = (
  ctx: CanvasRenderingContext2D,
  font: string,
  text: string,
): Size => {
  if (!text) {
    return {
      ascent: 0,
      height: 0,
      width: 0,
    };
  }
  ctx.save();
  try {
    ctx.font = font;
    const titleSize = ctx.measureText(text);
    return {
      width: titleSize.actualBoundingBoxLeft + titleSize.actualBoundingBoxRight,
      height:
        titleSize.fontBoundingBoxDescent + titleSize.fontBoundingBoxAscent,
      ascent: titleSize.fontBoundingBoxDescent,
    };
  } finally {
    ctx.restore();
  }
};

export const getPdfDonutChart = (props: PdfDonutChartProps) => {
  const config: ChartConfiguration<"doughnut"> = {
    type: "doughnut",
    options: {
      responsive: false,
      animation: false,
      cutout: "76%",
      layout: {
        // padding: -10,
      },
    },
    data: {
      datasets: [
        {
          label: "Data",
          data: [props.item.percentage, 100 - props.item.percentage],
          backgroundColor: [props.color, errorColor],
          borderWidth: 0,
        },
      ],
    },
    plugins: [
      {
        id: "CenterText",
        beforeDraw: ({ chartArea, ctx, ...rest }) => {
          const mainFontSize = 42;
          const subFontSize = 21;

          const mainFont = `700 ${mainFontSize}px ${fontPdf.table.family}`;
          const subFont = `400 ${subFontSize}px ${fontPdf.table.family}`;

          const line1 = `${props.item.successCount}/${props.item.totalCount}`;
          const s1 = getSize(ctx, mainFont, line1);

          // bring everything up a bit
          s1.ascent = s1.ascent * 1.2;

          // reduce space between line1 and line 2
          s1.height = s1.height * 0.7;

          const line2 = props.title;
          const s2 = getSize(ctx, subFont, line2);

          const line3 = props.title2 ?? "";
          const s3 = getSize(ctx, subFont, line3);

          const x = (chartArea.right - chartArea.left) / 2;
          const y = (chartArea.bottom - chartArea.top) / 2;

          ctx.save();
          try {
            ctx.fillStyle = "#4b4b4b";
            ctx.translate(
              x,
              y - (s1.height + s2.height + s3.height) / 2 + s1.ascent,
            );

            ctx.font = mainFont;
            ctx.fillText(line1, -(s1.width / 2), 0);
            ctx.translate(0, s1.height);

            ctx.font = subFont;
            ctx.fillText(line2, -(s2.width / 2), 0);
            ctx.translate(0, s2.height);

            if (line3) {
              ctx.font = subFont;
              ctx.fillText(line3, -(s3.width / 2), 0);
            }
          } finally {
            ctx.restore();
          }
          ctx.restore();
        },
      },
    ],
  };

  return getChartData(300, 300, config as any);
};
