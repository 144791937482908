import { SvgIcon, Typography, useTheme } from "@mui/material";
import { Box, BoxProps } from "@mui/system";

interface NeedleProps {
  color: string;
}
const Needle = (props: NeedleProps) => (
  <SvgIcon
    width="26"
    height="26"
    viewBox="0 0 35 35"
    fill="none"
    sx={{ color: "gray9" }}
  >
    <path
      d="m30.42 10.22 0.7002-0.5761c0.085-0.06523 0.1626-0.14 0.2314-0.2228 0.7581-0.8183 1.229-1.871 1.34-2.993s-0.1462-2.25-0.7297-3.207c-0.5834-0.9566-1.461-1.688-2.494-2.079-1.033-0.3912-2.164-0.4202-3.216-0.08248-0.8254 0.269-1.571 0.7475-2.167 1.391-0.0678 0.07025-0.1306 0.1454-0.1878 0.2249l-0.6094 0.9435-20.5 30zm-3.311-5.388c0.295-0.09809 0.612-0.1037 0.9101-0.01602 0.2982 0.08764 0.564 0.2645 0.7632 0.5079 0.1992 0.2434 0.3228 0.5421 0.3549 0.8579s-0.0288 0.6342-0.1748 0.9144c-0.146 0.2801-0.3705 0.5092-0.6446 0.6578-0.2741 0.1486-0.5854 0.2099-0.8939 0.1761-0.3084-0.03382-0.5999-0.1612-0.8371-0.3659-0.2372-0.2046-0.4092-0.4772-0.4939-0.7826-0.1127-0.3964-0.0695-0.8222 0.1203-1.186 0.1899-0.3643 0.5114-0.6381 0.8958-0.763z"
      fill={props.color}
      stroke="currentColor"
      strokeWidth=".25"
      strokeMiterlimit="10"
    />
  </SvgIcon>
);

export const RiskBarometerLegend = (props: Pick<BoxProps, "sx">) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.gray1,
        border: `solid 2px ${theme.palette.gray3}`,
        borderRadius: "5px",
        display: "flex",
        gap: 1,
        alignItems: "center",
        justifyContent: "center",
        ".MuiTypography-root": {
          fontSize: "12px",
        },
        "& > div": {
          display: "flex",
          alignItems: "center",
          gap: 1,
        },
        ...props.sx,
      }}
    >
      <Box>
        <Needle color={theme.palette.white} />
        <Typography variant="par01Regular">Existing</Typography>
      </Box>
      <Box>
        <Needle color={theme.palette.red} />
        <Typography variant="par01Regular">Profiled</Typography>
      </Box>
      <Box>
        <Needle color={theme.palette.caravelBlueSecondary} />
        <Typography variant="par01Regular">Proposed</Typography>
      </Box>
      <Box>
        <Needle color={theme.palette.black} />
        <Typography variant="par01Regular">Plan input</Typography>
      </Box>
    </Box>
  );
};
