import { useEffect, useState } from "react";
import { debugLog } from "./utils";

export function useDelayed<T>(props: {
  value?: T | null;
  onChange: (value?: T) => void;
  onCancel?: () => void;
  onBlur?: unknown;
  skipPostOnEnter?: boolean;
  [key: string]: any;
}) {
  const [pendingValue, setPendingValue] = useState(props.value);
  const [forceReload, setForceReload] = useState(false);

  const postChanges = () => {
    setTimeout(() => {
      if (props.value === pendingValue) {
        props.onCancel?.();
      } else {
        props.onChange(pendingValue ?? undefined);
      }
      setForceReload(true);
    }, 100);
  };

  // whenever outside value changes - reset pending
  useEffect(() => setPendingValue(props.value), [props.value]);

  // in some cases outside value could be the same (request has been rejected), so forcing reload after the post
  useEffect(() => {
    if (forceReload) {
      setPendingValue(props.value);
      setForceReload(false);
    }
  }, [props.value, forceReload]);

  return {
    value: pendingValue,
    onChange: setPendingValue,
    onBlur: (...params: any) => {
      if (typeof props.onBlur === "function") {
        props.onBlur(...params);
      }
      postChanges();
    },
    onKeyPress: (e: any) => {
      if (!props.skipPostOnEnter && e?.key === "Enter") {
        debugLog("Pressed Enter");
        postChanges();
      }
    },
  };
}
