import { useSimulationSimulationJobAvailableSimulationGroupsListQuery } from "api/carApi.generated";
import { CarSelectOption } from "components/Inputs";
import { useMemo } from "react";
import { checkDefined } from "utils";

export const useSimulationGroups = () => {
  const { data, isLoading } =
    useSimulationSimulationJobAvailableSimulationGroupsListQuery();

  return useMemo(
    () => ({
      items: data ?? [], // todo remove. deprecated
      options: (data ?? []).map<CarSelectOption<string>>((i) => ({
        value: checkDefined(i.id),
        label: checkDefined(i.name),
      })),
      defOptionValue: data?.at(0)?.id,

      isLoading,

      lastStaticSimulationJobId: data?.find(
        (i) => i.latest_simulation?.is_static,
      )?.latest_simulation?.id,

      lastPriceDrivenSimulationJobId: data?.find(
        (i) => !i.latest_simulation?.is_static,
      )?.latest_simulation?.id,

      getSimulationGroupId: (isStatic: boolean) =>
        data?.find((i) => i.latest_simulation?.is_static === isStatic)?.id,

      getSimulationSuffix: (
        simulationGroupId?: string | null,
        isLong?: boolean,
      ) => {
        const item = data?.find((i) => i.id === simulationGroupId);
        if (!item) {
          return "";
        }

        return isLong
          ? ` (${item.name ?? ""})`
          : item.latest_simulation?.is_static
          ? " (S)"
          : " (PD)";
      },

      getSimulationGroupName: (simulationGroupId?: string | null) => {
        const item = data?.find((i) => i.id === simulationGroupId);
        if (!item) {
          return "";
        }

        return item.name ?? "";
      },

      getAssetClass4Filter: (simulationGroupId?: string | null) => {
        const item = data?.find((i) => i.id === simulationGroupId);
        return (acLevel4Id: string) =>
          item?.asset_classes?.some(
            (ac: { id: string; name: string }) => ac.id === acLevel4Id,
          );
      },
    }),
    [data, isLoading],
  );
};
