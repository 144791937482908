import { useDispatch } from "react-redux";
import {
  DisplayTypeEnum2,
  GoalStatusEnum,
  PlanResult,
  useQuestionnairePlanRetrieveQuery,
  useQuestionnairePlanRiskRetrieveQuery,
} from "api/carApi.generated";
import { useAppSelector } from "app/hooks";
import { usePlanId } from "app/usePlanId";
import { useRender } from "app/useRender";
import { refetchOnFocus } from "const";
import { formatPercent, isDefined, priorityNumToString } from "utils";
import { calcPlanResultDonuts } from "./calcPlanResultDonuts";
import { controlPanelActions } from "app/controlPanelSlice";
import { useBarChart } from "./useBarChart";
import { useDerisk } from "./useDerisk";
import { useReturnScenarios } from "app/useReturnScenarios";
import { useRiskStatType } from "app/useRiskStatType";
export interface PlanResultsTotals {
  goals: {
    totalGoalFunded: number;
    inflation: number;
    totalTaxesPaid: number;
    outflows: number;
  };
  inflows: {
    currentInvestments: number;
    investmentReturns: number;
    savingsOtherInflowsNetOfTax: number;
    socialSecurityOtherInflowsNetOfTax: number;
    realEstateNetSaleProceeds: number;
    totalPlanInflow: number;
  };
  surplus: {
    taxableAccountSurplus: number;
    taxDeferredAccountSurplus: number;
    socialSecurityAdditionalIncome: number;
    unfundedGoals: number;
    realEstate: number;
    surplusDeficit: number;
  };
}

export interface PlanResultsRiskData {
  value: number;
  valueRed: number;
  valueWhite: number;
  valueBlue: number;
}

export interface PlanResultGoalAsset {
  label: string;
  amount: number;
}
export interface PlanResultsGoal {
  goalId: string;
  hintValue?: number;
  status: GoalStatusEnum;
  fundedByAssets: PlanResultGoalAsset[];

  priority: number;
  startDate: string;
  endDate: string;
  name: string;
  piiName: string;
  nameWithPriority: string;
  annualAmountWithoutInflation: number;
}

export interface PlanResultsRiskTableRow {
  label: string;
  existingPortfolio?: number;
  proposedPortfolio?: number;
  selectedPortfolio?: number;
  displayType: DisplayTypeEnum2;
}

interface PlanResultsRiskMark {
  value: number; // fake value
  label: string;
  moduleId: string;
  realValue: number;
}

export const usePlanResults = () => {
  const dispatch = useDispatch();
  const render = useRender();

  const cpDeriskItem = useAppSelector((state) => state.controlPanel.derisk);

  const planId = usePlanId();

  const clientPlan = useQuestionnairePlanRetrieveQuery(
    { id: planId },
    { refetchOnFocus },
  );

  const planRisk = useQuestionnairePlanRiskRetrieveQuery(
    { id: planId },
    { refetchOnFocus },
  );

  const riskStatType = useRiskStatType();
  const returnScenarios = useReturnScenarios();

  const derisk = useDerisk();

  const {
    existingResult,
    proposedResult,
    tradeTaxDeferResult,
    planResult: selectedResult,
    inflated: { getInflatedValue },
  } = render;

  const riskMarks: PlanResultsRiskMark[] =
    proposedResult?.derisking_results.map((i, idx, arr) => {
      const realValue = (i.equity_weight ?? 0) * 100;
      const value = Math.trunc((96 / (arr.length - 1)) * idx) + 2;

      return {
        label: `${i.portfolio_module_name}\n${formatPercent(realValue)}`,
        value,
        moduleId: i.portfolio_module,
        realValue,
      };
    }) ?? [];

  const selectedRiskModuleId =
    cpDeriskItem?.portfolio_module ?? derisk.deriskModuleId;

  const valueRed = planRisk.data?.calculated_risk_score ?? 0;
  const valueWhite = (existingResult?.risk_stats_equity_weight ?? 0) * 100;
  const valueBlue = (proposedResult?.risk_stats_equity_weight ?? 0) * 100;
  const valueDefer = (tradeTaxDeferResult?.risk_stats_equity_weight ?? 0) * 100;

  const riskValue =
    selectedResult === proposedResult
      ? valueBlue
      : selectedResult === existingResult
      ? valueWhite
      : selectedResult === tradeTaxDeferResult
      ? valueDefer
      : riskMarks.find((i) => i.moduleId === selectedRiskModuleId)?.realValue ??
        0;

  const fakeRiskValue =
    riskMarks.find((i) => i.moduleId === selectedRiskModuleId)?.value ?? 0;

  const setFakeRiskValue = (value: number) => {
    const newRiskModuleId = riskMarks.find((i) => i.value === value)?.moduleId;

    if (!newRiskModuleId) {
      return;
    }

    dispatch(
      controlPanelActions.setDerisk({ portfolio_module: newRiskModuleId }),
    );
  };

  const getRiskStatValue = (
    planResult: PlanResult | undefined,
    riskStatTypeId?: string,
  ) =>
    planResult?.risk_stat_containers
      // just a placeholder for real find
      .find((i) => i.plan_result === planResult.id) // TODO clarify what this array means
      ?.risk_stat_results.find((i) => i.risk_stat_type_id === riskStatTypeId)
      ?.risk_stat_value;

  const getHistoricStatValue = (
    planResult: PlanResult | undefined,
    returnScenarioId?: string,
  ) =>
    planResult?.risk_stat_containers
      // just a placeholder for real find
      .find((i) => i.plan_result === planResult.id) // TODO clarify what this array means
      ?.historic_results.find((i) => i.scenario_id === returnScenarioId)
      ?.observed_return;

  const simulationBasedRiskReport =
    riskStatType.planningToolItems.map<PlanResultsRiskTableRow>((i) => ({
      label: i.name ?? "",
      displayType: i.display_type ?? "PERCENTAGE",
      existingPortfolio: getRiskStatValue(existingResult, i.id),
      proposedPortfolio: getRiskStatValue(proposedResult, i.id),
      selectedPortfolio: getRiskStatValue(selectedResult, i.id),
    })) ?? [];

  const historicalRiskReport =
    returnScenarios.items.map<PlanResultsRiskTableRow>((i) => ({
      label: i.name ?? "",
      displayType: "PERCENTAGE",
      existingPortfolio: getHistoricStatValue(existingResult, i.id),
      proposedPortfolio: getHistoricStatValue(proposedResult, i.id),
      selectedPortfolio: getHistoricStatValue(selectedResult, i.id),
    }));

  const risk = {
    riskValue,
    valueRed,
    valueWhite,
    valueBlue,
    riskAreaStart: 0, // Math.max((renderRiskData.profiled_risk ?? 0) - 20, 0),
    riskAreaEnd: 0, //Math.min((renderRiskData.profiled_risk ?? 0) + 20, 100),
    fakeRiskValue,
    setFakeRiskValue,
    selectedPlanLabel: render.selectedPlanLabel,
    historicalRiskReport,
    simulationBasedRiskReport,
    marks: riskMarks.length > 0 ? riskMarks : undefined,
  };

  const riskData: PlanResultsRiskData = {
    value: risk.riskValue,
    valueRed: risk.valueRed,
    valueWhite: risk.valueWhite,
    valueBlue: risk.valueBlue,
  };

  // todo clarify
  // outflow_total_goal_amount_inflated

  const totals: PlanResultsTotals = {
    goals: {
      totalGoalFunded: selectedResult?.outflow_pre_inflation ?? 0,
      inflation: getInflatedValue(
        selectedResult?.outflow_inflation_adjustment,
        selectedResult?.outflow_inflation_adjustment_without_inflation,
      ),
      totalTaxesPaid: getInflatedValue(
        selectedResult?.outflow_withdrawl_taxes,
        selectedResult?.outflow_withdrawl_taxes_deflated,
      ),
      outflows: getInflatedValue(
        selectedResult?.outflow_total,
        selectedResult?.outflow_total_deflated,
      ),
    },
    inflows: {
      currentInvestments: selectedResult?.inflow_current_assets ?? 0,
      investmentReturns: getInflatedValue(
        selectedResult?.inflow_nominal_investment_return,
        selectedResult?.inflow_nominal_investment_return_deflated,
      ),
      savingsOtherInflowsNetOfTax: getInflatedValue(
        selectedResult?.inflow_savings_other_inflows_net_tax,
        selectedResult?.inflow_savings_other_inflows_net_tax_deflated,
      ),
      socialSecurityOtherInflowsNetOfTax: getInflatedValue(
        selectedResult?.inflow_social_security_other_income_net_tax,
        selectedResult?.inflow_social_security_other_income_net_tax_deflated,
      ),
      realEstateNetSaleProceeds: getInflatedValue(
        selectedResult?.inflow_real_estate_net_sale_proceeds,
        selectedResult?.inflow_real_estate_net_sale_proceeds_deflated,
      ),
      totalPlanInflow: getInflatedValue(
        selectedResult?.inflow_total,
        selectedResult?.inflow_total_deflated,
      ),
    },
    surplus: {
      taxableAccountSurplus: getInflatedValue(
        selectedResult?.taxable_surplus_total,
        selectedResult?.taxable_surplus_total_deflated,
      ),
      taxDeferredAccountSurplus: getInflatedValue(
        selectedResult?.taxexempt_surplus_total,
        selectedResult?.taxexempt_surplus_total_deflated,
      ),
      socialSecurityAdditionalIncome: getInflatedValue(
        selectedResult?.social_security_add_income_surplus_total,
        selectedResult?.social_security_add_income_surplus_total_deflated,
      ),
      unfundedGoals: getInflatedValue(
        selectedResult?.unfunded_goals_total,
        selectedResult?.unfunded_goals_total_deflated,
      ),
      realEstate: getInflatedValue(
        selectedResult?.real_estate_surplus_total,
        selectedResult?.real_estate_surplus_total_deflated,
      ),
      surplusDeficit: getInflatedValue(
        selectedResult?.surplus_deficit_total,
        selectedResult?.surplus_deficit_total_deflated,
      ),
    },
  };

  // console.log(
  //   "render.planResult?.goal_results",
  //   render.planResult?.goal_results.length,
  //   render.planResult?.goal_results
  // );

  const goalResults =
    render.planResult?.goal_results.map<PlanResultsGoal>((goalResult) => {
      const fundedByAssets = Array.from(
        new Set(goalResult.asset_results?.map((i) => i.asset_result_id) ?? []),
      )
        .map((i) => {
          const arItem = render.planResult?.asset_results.find(
            (ar) => ar.id === i,
          );

          const goalAmount =
            goalResult.asset_results
              ?.filter((ar) => ar.asset_result_id === i)
              .reduce(
                (acc, ar) =>
                  acc +
                  getInflatedValue(
                    ar.goal_amount_funded_with_inflation,
                    ar.goal_amount_funded_without_inflation,
                  ),

                0,
              ) ?? 0;

          return arItem
            ? {
                label: arItem.pii_base_asset_name,
                amount: goalAmount,
              }
            : undefined;
        })
        .filter(isDefined)
        .filter((i) => i.amount > 0);

      return {
        goalId: goalResult.base_goal_id,
        hintValue: goalResult.goal_annual_amount_met_hint ?? undefined,
        status: goalResult.goal_status,
        priority: goalResult.base_goal_priority,
        startDate: goalResult.base_goal_start_date,
        endDate: goalResult.base_goal_end_date,
        name: goalResult.base_goal_name,
        piiName: goalResult.pii_base_goal_name,
        nameWithPriority: `${goalResult.base_goal_name} (${priorityNumToString(
          goalResult.base_goal_priority,
        )?.[0]})`,
        annualAmountWithoutInflation:
          goalResult.input_annual_amount_without_inflation,
        fundedByAssets,
      };
    }) ?? [];

  const barChart = useBarChart({
    getInflatedValue,
    goalResults: render.planResult?.goal_results ?? [],
    yearStats: render.planResult?.year_stats ?? [],
  });

  const firstYearStat = render.planResult?.year_stats?.[0];

  return {
    hasRenderError: render.hasRenderError,
    isLoading:
      render.isLoading ||
      clientPlan.isLoading ||
      planRisk.isLoading ||
      riskStatType.isLoading ||
      returnScenarios.isLoading ||
      derisk.isLoading,
    isFetching:
      render.isFetching ||
      clientPlan.isFetching ||
      planRisk.isFetching ||
      riskStatType.isFetching ||
      returnScenarios.isFetching ||
      derisk.isFetching,
    isPlanPublished: !!clientPlan.data?.published_data?.published_plan_type_id,
    clientPlan: clientPlan.data,
    goalResults,
    donuts: calcPlanResultDonuts(
      render.inflated.isInflated,
      render.planResult,
      clientPlan.data,
    ),
    barChart,
    refetch: render.refetch,
    risk,
    riskData,
    totals,
    isRiskMoreThanProfiled: risk.riskValue > risk.valueRed,
    selectedPlanResultId: render.selectedPlanResultId,
    totalNetWorth: render.planResult?.initial_net_worth ?? 0,
    beginningTotalPortfolio: getInflatedValue(
      firstYearStat?.beginning_total_portfolio_value,
      firstYearStat?.beginning_total_portfolio_value_deflated,
    ),
  };
};

export type UsePlanResults = ReturnType<typeof usePlanResults>;
