import { Dialog, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CmaModelChartData } from "api/carApi.generated";
import { CarDownloadButton } from "components/Buttons";
import { useChartActiveElement } from "components/ChartTooltip";
import { CarClose } from "icons";
import { ChartDftTotalReturns } from "pages/assetAllocation/editPortfolioGroup/ChartDftTotalReturns";
import { ChartDftTotalReturnsSummary } from "pages/assetAllocation/editPortfolioGroup/ChartDftTotalReturnsSummary";
import { ChartDftVersusTenYearReturn } from "pages/assetAllocation/editPortfolioGroup/ChartDftVersusTenYearReturn";
import { useState } from "react";
import { rootPages } from "route.const";
import { openInNewTab } from "utils";
import { CarAssetClassAnalysisItem } from "./useAssetClassSummaryStat";

export interface DftChartDialogProps {
  items: CarAssetClassAnalysisItem[];
  assetClassId: string;
  onApply: (value: boolean) => void;
  onCancel: () => void;
}

export const DftChartDialog = (props: DftChartDialogProps) => {
  const chartItems = props.items.filter((i) => i.modelChartData);

  const [assetClassId, setAssetClassId] = useState(props.assetClassId);
  const currentItem = chartItems.find((i) => i.level4Id === assetClassId);

  const currentItemIndex = chartItems.findIndex(
    (i) => i.level4Id === assetClassId,
  );
  const prevId =
    currentItemIndex > 0
      ? chartItems.at(currentItemIndex - 1)?.level4Id
      : undefined;
  const nextId = chartItems.at(currentItemIndex + 1)?.level4Id;

  const activeElement = useChartActiveElement();

  const handleShowPdf = () => {
    openInNewTab(
      rootPages.getAssetClassDftPdfReportUrl({
        assetClassId: props.assetClassId,
      }),
    );
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "15px",
          position: "relative",
          width: "1200px",
          height: "95vh",
          p: 7,
          pt: 8,
        },
      }}
      maxWidth="xl"
      open={true}
      onClose={(e, reason) => props.onCancel()}
    >
      <IconButton
        sx={{
          position: "absolute",
          right: 10,
          top: 10,
          color: "gray6",
        }}
        onClick={props.onCancel}
      >
        <CarClose />
      </IconButton>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h2SSemiBold">Distance From Trend</Typography>
        <CarDownloadButton
          onClick={handleShowPdf}
          sx={{ ml: "auto", mr: -1, alignSelf: "center" }}
        >
          Download Reports
        </CarDownloadButton>
      </Box>
      {currentItem && (
        <>
          <ChartDftTotalReturnsSummary
            sx={{ mt: 5 }}
            assetClassName={currentItem.name}
            navInfo={{
              canGoPrev: !!prevId,
              goPrev: () => setAssetClassId(prevId ?? ""),
              canGoNext: !!nextId,
              goNext: () => setAssetClassId(nextId ?? ""),
            }}
            data={currentItem.modelChartData as CmaModelChartData}
            realStaticExpectedReturn={currentItem.realStaticExpectedReturn ?? 0}
            isWhiteContext
          />
          <Box
            sx={{
              mt: 6.5,
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            <ChartDftTotalReturns
              assetClassName={currentItem.name}
              data={currentItem.modelChartData as CmaModelChartData}
              pendoPrefix="asset_class_analysis_dft_total_returns"
              activeElement={activeElement}
              isWhiteContext={true}
            />
            <ChartDftVersusTenYearReturn
              sx={{ mt: 5 }}
              assetClassName={currentItem.name}
              data={currentItem.modelChartData as CmaModelChartData}
              pendoPrefix="asset_class_analysis_dft_versus_ten_year_return"
              activeElement={activeElement}
              isWhiteContext={true}
            />
          </Box>
        </>
      )}
    </Dialog>
  );
};
