import { Image, View } from "@react-pdf/renderer";
import {
  FooterProps,
  PdfMarkdown,
  PdfPage,
  PdfSectionLabel,
} from "components/Pdf";

import legend from "assets/pdf/barometer-legend.png";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { getPdfRiskBarometer } from "./getPdfRiskBarometer";
export interface RiskBarometerActionPdfPageProps {
  footerProps: FooterProps;
  planResults: UsePlanResults;
}

export const RiskBarometerActionPdfPage = (
  props: RiskBarometerActionPdfPageProps,
) => {
  const getBarometerImage = async () =>
    getPdfRiskBarometer(props.planResults.riskData);
  return (
    <PdfPage footerProps={props.footerProps}>
      <PdfSectionLabel label="Risk" />
      <PdfMarkdown>
        {`The black needle is pointing to the new risk level you have taken on in your updated financial plan. You can view the differences between your existing strategy and your newly published strategy in the simulated based and historical risk report.

Legend:

**•    Proposed (blue indicator)** - the portfolio risk level tat, according to our calculations, best aligns your investment risk with the funding needs of your financial goals.

**•    Existing (white indicator)** - the risk level of your current investment holdings.

**•    Profiled (red indicator)** - your targeted risk level based upon the profiling process conducted by you and your advisor.

**•    Plan Input (large black needle)** - the risk level that is currently being used to calculate plan results. This may be the Proposed, Existing or a customized “Derisked” portfolio alternative.`}
      </PdfMarkdown>
      <View
        style={{
          marginTop: 80,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Image
          style={{
            height: 210,
            width: 210,
            marginLeft: -20,
          }}
          src={getBarometerImage}
        />
        <View
          style={{
            borderColor: "#c3c3c3",
            borderWidth: 1,
            marginLeft: 60,
          }}
        >
          <Image
            style={{
              height: 144,
            }}
            src={legend}
          />
        </View>
      </View>
    </PdfPage>
  );
};
