import { Box, BoxProps } from "@mui/material";
import { useEffect, useRef } from "react";

class Point {
  public x: number;
  public y: number;
  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

const outCubic = (t: number, b: number, c: number, d: number) => {
  t /= d;
  t--;
  return c * (t * t * t + 1) + b;
};

function cubeBezier(p0: Point, c0: Point, c1: Point, p1: Point, t: number) {
  var p = new Point(0, 0);
  var nt = 1 - t;

  p.x =
    nt * nt * nt * p0.x +
    3 * nt * nt * t * c0.x +
    3 * nt * t * t * c1.x +
    t * t * t * p1.x;
  p.y =
    nt * nt * nt * p0.y +
    3 * nt * nt * t * c0.y +
    3 * nt * t * t * c1.y +
    t * t * t * p1.y;

  return p;
}

class Particle {
  private ctx: CanvasRenderingContext2D;
  private p0: Point;
  private p1: Point;
  private p2: Point;
  private p3: Point;
  private time: number;
  private duration: number;
  private color: string;
  private x: number = 0;
  private y: number = 0;
  private r: number = 0;
  private sy: number = 0;
  private w: number;
  private h: number;
  public complete: boolean;

  constructor(
    ctx: CanvasRenderingContext2D,
    p0: Point,
    p1: Point,
    p2: Point,
    p3: Point
  ) {
    this.ctx = ctx;
    this.p0 = p0;
    this.p1 = p1;
    this.p2 = p2;
    this.p3 = p3;

    this.time = 0;
    this.duration = 3 + Math.random() * 2;
    this.color = "#" + Math.floor(Math.random() * 0xffffff).toString(16);

    this.w = 8;
    this.h = 6;

    this.complete = false;
  }

  public update = () => {
    this.time = Math.min(this.duration, this.time + 1 / 60);

    var f = outCubic(this.time, 0, 1, this.duration);
    var p = cubeBezier(this.p0, this.p1, this.p2, this.p3, f);

    var dx = p.x - this.x;
    var dy = p.y - this.y;

    this.r = Math.atan2(dy, dx) + Math.PI * 0.5;
    this.sy = Math.sin(Math.PI * f * 10);
    this.x = p.x;
    this.y = p.y;

    this.complete = this.time === this.duration;
  };

  public draw = () => {
    this.ctx.save();
    this.ctx.translate(this.x, this.y);
    this.ctx.rotate(this.r);
    this.ctx.scale(1, this.sy);

    this.ctx.fillStyle = this.color;
    this.ctx.fillRect(-this.w * 0.5, -this.h * 0.5, this.w, this.h);

    this.ctx.restore();
  };
}

class Animation {
  private ctx: CanvasRenderingContext2D;
  private canvasWidth: number;
  private canvasHeight: number;
  private particles: Particle[] = [];
  private stopped = false;

  constructor(
    ctx: CanvasRenderingContext2D,
    canvasWidth: number,
    canvasHeight: number
  ) {
    this.ctx = ctx;
    this.canvasWidth = canvasWidth;
    this.canvasHeight = canvasHeight;

    this.createParticles();

    requestAnimationFrame(this.loop);
  }

  private createParticles = () => {
    this.particles.length = 0;

    this.particles = new Array(128).fill(0).map(() => {
      const p0 = new Point(this.canvasWidth * 0.5, this.canvasHeight * 0.5);
      const p1 = new Point(
        Math.random() * this.canvasWidth,
        Math.random() * this.canvasHeight
      );
      const p2 = new Point(
        Math.random() * this.canvasWidth,
        Math.random() * this.canvasHeight
      );
      const p3 = new Point(
        Math.random() * this.canvasWidth,
        this.canvasHeight + 64
      );

      return new Particle(this.ctx, p0, p1, p2, p3);
    });
  };

  private loop = () => {
    this.particles.forEach((p) => p.update());
    this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    this.particles.forEach((p) => p.draw());

    if (this.particles.every((i) => i.complete)) {
      // reset
      // this.createParticles();
      this.stop();
    }

    if (!this.stopped) {
      requestAnimationFrame(this.loop);
    }
  };

  public stop = () => {
    this.stopped = true;
  };
}

export const Confetti = (props: BoxProps) => {
  const ref = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const ctx = ref.current?.getContext("2d");

    if (!ref.current || !ctx) {
      return;
    }

    const canvasRect = ref.current.getBoundingClientRect();
    ref.current.width = canvasRect.width;
    ref.current.height = canvasRect.height;

    const animation = new Animation(ctx, canvasRect.width, canvasRect.height);

    return () => animation.stop();
  }, []);

  return <Box ref={ref} component="canvas" {...props} />;
};
