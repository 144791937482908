import {
  AssetClassGicsSubIndustryWeights,
  GicsIndustrySubGroup,
  useAssetClassesAssetClassGicsSubIndustryWeightsListQuery,
  useDataProvidersGicsIndustrySubGroupListQuery,
} from "api/carApi.generated";

export type CarGicsItemValue = {
  acLevel4Id: string;
  weight: number;
};

export type CarGicsItem = {
  parentId?: string;
  id: string;
  code: string;
  name: string;
  description: string;
  children: Array<CarGicsItem>;
  value1: number;
  value2: number;
  diff: number;
};

export type CarGicsReport = {
  level1: CarGicsItem[];
  level2: CarGicsItem[];
  level3: CarGicsItem[];
  level4: CarGicsItem[];
};

export type UseGics = ReturnType<typeof useGics>;

const roundTo = (value?: number) =>
  value ? Math.round(value * 1000 + Number.EPSILON) / 1000 : 0; // round to percent with 1 decimal

const filterZeroValue = (item: CarGicsItem) => item.value1 || item.value2;

export type OnGetGicsValue = (
  groupWeights: AssetClassGicsSubIndustryWeights[],
) => number;

export const useGics = ({
  onGetValue1,
  onGetValue2,
}: {
  onGetValue1: OnGetGicsValue;
  onGetValue2: OnGetGicsValue;
}) => {
  const gics = useDataProvidersGicsIndustrySubGroupListQuery();
  const gicsWeights =
    useAssetClassesAssetClassGicsSubIndustryWeightsListQuery();

  const isLoading = gics.isLoading || gicsWeights.isLoading;
  const gicsData = gics.data;
  const weightData = gicsWeights.data;

  const items = gicsData ?? [];

  const extractGicsItem = (
    items: Array<GicsIndustrySubGroup>,
    extractor: (i: GicsIndustrySubGroup) => CarGicsItem | undefined,
  ) => {
    return items.reduce((acc, i) => {
      const item = extractor(i);
      return item ? [...acc.filter((v) => v.id !== item.id), item] : acc;
    }, [] as Array<CarGicsItem>);
  };

  const calcTotals = (items: Array<CarGicsItem>) => {
    return items.reduce(
      (acc, i) => ({
        value1: acc.value1 + i.value1,
        value2: acc.value2 + i.value2,
        diff: acc.diff + i.diff,
      }),
      {
        value1: 0,
        value2: 0,
        diff: 0,
      },
    );
  };

  const level4 = extractGicsItem(items, (i) => {
    const v = i;

    const groupWeights =
      weightData?.filter((i) => i.gics_sub_industry_group === v.id) ?? [];

    const value1 = roundTo(onGetValue1(groupWeights));
    const value2 = roundTo(onGetValue2(groupWeights));

    return (
      v && {
        parentId: i.gics_industry?.id,
        id: v.id ?? "",
        name: v.name ?? "",
        code: v.code ?? "",
        description: v.description ?? "",
        children: [],
        value1,
        value2,
        diff: value1 - value2,
      }
    );
  }).filter(filterZeroValue);

  const level3 = extractGicsItem(items, (i) => {
    const v = i.gics_industry;
    const children = level4.filter((i) => i.parentId === v?.id);
    return (
      v && {
        parentId: i.gics_industry?.gics_industry_group?.id,
        id: v.id ?? "",
        name: v.name ?? "",
        code: v.code ?? "",
        description: v.description ?? "",
        children: children.length > 1 ? children : [],
        ...calcTotals(children),
      }
    );
  }).filter(filterZeroValue);

  const level2 = extractGicsItem(items, (i) => {
    const v = i.gics_industry?.gics_industry_group;
    const children = level3.filter((i) => i.parentId === v?.id);
    return (
      v && {
        parentId: i.gics_industry?.gics_industry_group?.gics_sector?.id,
        id: v.id ?? "",
        name: v.name ?? "",
        code: v.code ?? "",
        description: v.description ?? "",
        children,
        ...calcTotals(children),
      }
    );
  }).filter(filterZeroValue);

  const level1 = extractGicsItem(items, (i) => {
    const v = i.gics_industry?.gics_industry_group?.gics_sector;
    const children = level2.filter((i) => i.parentId === v?.id);
    return (
      v && {
        id: v.id ?? "",
        name: v.name ?? "",
        code: v.code ?? "",
        description: v.description ?? "",
        children,
        ...calcTotals(children),
      }
    );
  }).filter(filterZeroValue);

  const report: CarGicsReport = {
    level1,
    level2,
    level3,
    level4,
  };

  return {
    isLoading,
    report,
    hasWeightForAC: (acLevel4Id?: string) =>
      weightData?.some((i) => i.ac_level4 === acLevel4Id),
  };
};
