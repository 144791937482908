import { Box, MenuItem, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo } from "react";
import { CarSelectField } from "components/Inputs";
import { CarDownloadButton } from "components/Buttons";
import { useLocalStorage } from "features/general/useLocalStorage";
import { CarAssetClassSelector } from "components/AssetClassSelector";
import { CarSwitch } from "components/Switch";
import { useSimulationABTestReturns } from "./useSimulationABTestReturns";
import { SimulationABTestPercentileTable } from "./SimulationABTestPercentileTable";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";

interface SimulationABTestReturnsProps {
  simulationJobIdA: string;
  simulationJobIdB: string;
}

const timeHorizonItems: { id: string; label: string }[] = [
  {
    id: "short",
    label: "Short",
  },
  {
    id: "extended",
    label: "Extended",
  },
];

export const SimulationABTestReturns = memo(
  (props: SimulationABTestReturnsProps) => {
    const [timeHorizon, setTimeHorizon] = useLocalStorage<string | undefined>(
      "SimulationABTestReturns_timeHorizon",
      "short",
    );

    const data = useSimulationABTestReturns({
      simulationJobIdA: props.simulationJobIdA,
      simulationJobIdB: props.simulationJobIdB,
    });

    const pageContent = usePageContent();

    return (
      <TablePlaceHolder
        sx={{ mx: 7, mt: 5 }}
        isLoading={data.assetClassSelector.isLoading || pageContent.isLoading}
        rows={8}
      >
        <CarPageContent
          sx={{ mb: 3 }}
          content={pageContent.getContent(
            CarPageContentCode.SIMULATION_ANALYSIS_AB_TEST_RETURNS,
          )}
          isWhiteContext
        />
        <Box sx={{ mt: 1, display: "flex", gap: 5, alignItems: "flex-end" }}>
          <CarAssetClassSelector
            label="Asset Class"
            sx={{
              width: 250,
              ".MuiInputLabel-root": {
                fontSize: "16px",
              },
              ".MuiOutlinedInput-root": {
                marginTop: "28px",
                bgcolor: "white",
              },
            }}
            {...data.assetClassSelector}
          />
          <CarSelectField
            label="Time Horizon"
            sx={{
              width: 216,
              ".MuiInputLabel-root": {
                fontSize: "16px",
              },
              ".MuiOutlinedInput-root": {
                marginTop: "28px",
                bgcolor: "white",
              },
            }}
            value={timeHorizon}
            onChange={setTimeHorizon}
          >
            {timeHorizonItems.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.label}
              </MenuItem>
            ))}
          </CarSelectField>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
            <Typography variant="par01Regular">Real</Typography>
            <CarSwitch
              size="small"
              checked={!data.isReal}
              onChange={(e, value) => data.setIsReal(!value)}
            />
            <Typography variant="par01Regular">Nominal</Typography>
          </Box>
          <CarDownloadButton
            sx={{ ml: "auto", mb: 0.5 }}
            onClick={data.handleDownloadReports}
          >
            Download Reports
          </CarDownloadButton>
        </Box>
        <TablePlaceHolder sx={{ mt: 5 }} isLoading={data.isFetching} rows={5}>
          {data.assetClassSelector.value && (
            <>
              <Typography variant="subhSemiBold" sx={{ mt: 5, mb: 1 }}>
                {data.assetClassName}
              </Typography>
              <SimulationABTestPercentileTable
                table={data.table}
                isExtended={timeHorizon === "extended"}
                onValueClick={data.handleValueClick}
              />
              {data.inflationTable && (
                <>
                  <Typography variant="subhSemiBold" sx={{ mt: 5, mb: 1 }}>
                    Inflation
                  </Typography>
                  <SimulationABTestPercentileTable
                    table={data.inflationTable}
                    isExtended={timeHorizon === "extended"}
                    onValueClick={data.handleValueClick}
                  />
                </>
              )}
            </>
          )}
        </TablePlaceHolder>
      </TablePlaceHolder>
    );
  },
);
