import { Box, Typography } from "@mui/material";
import { CarTooltip } from "components/Tooltip";
import { CSSProperties, useState } from "react";
import { Pie } from "react-chartjs-2";
import { formatPercent } from "utils";
import { CarPortfolioAssetRow } from "../usePortfolioGroupVersionData";

interface ModuleChartItem {
  label: string;
  value: number;
  color: CSSProperties["color"];
}

interface AssetAllocationModulePieChartProps {
  size: number;
  rows: CarPortfolioAssetRow[];
  moduleId: string;
  dataId?: string;
  pendoClassName?: string;
}

export const AssetAllocationModulePieChart = (
  props: AssetAllocationModulePieChartProps,
) => {
  const [hintValue, setHintValue] = useState<ModuleChartItem | undefined>();

  const data = props.rows
    .map<ModuleChartItem>((row) => ({
      label: row.name,
      color: row.color,
      value:
        row.modules
          .find((mod) => mod.moduleId === props.moduleId)
          ?.values.find((val) => val.dataId === props.dataId)?.allocation ?? 0,
    }))
    .filter((i) => i.value > 0);

  if (data.length === 0) {
    data.push({ label: "", value: 1, color: "#B2B3B5" });
  }

  return (
    <CarTooltip
      title={
        hintValue && hintValue.label ? (
          <>
            <Typography variant="par02SemiBold" sx={{ fontSize: "15px" }}>
              {hintValue.label}
            </Typography>
            <Typography variant="par01Regular" sx={{ fontSize: "13px" }}>
              {formatPercent(hintValue.value * 100)}
            </Typography>
          </>
        ) : (
          ""
        )
      }
      followCursor
      arrow={false}
      preWrap
    >
      <Box>
        <Pie
          className={props.pendoClassName}
          width={props.size}
          height={props.size}
          options={{
            responsive: false,
            animation: {
              duration: 500,
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            borderColor: "transparent",
            onHover: (e, elements) => {
              const newData = data[elements[0]?.index];
              if (
                newData?.label !== hintValue?.label ||
                newData?.value !== hintValue?.value
              )
                setHintValue(newData);
            },
          }}
          data={{
            labels: data.map((i) => i.label),
            datasets: [
              {
                label: "data",
                data: data.map((i) => i.value),
                backgroundColor: data.map((i) => i.color),
              },
            ],
          }}
        />
      </Box>
    </CarTooltip>
  );
};
