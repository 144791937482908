import { Box, Typography } from "@mui/material";
import { Warning } from "@mui/icons-material";

export interface ErrorLabelProps {
  text: string;
}

export const ErrorLabel = (props: ErrorLabelProps) => (
  <Box
    sx={{
      display: "flex",
      fontSize: 11,
      alignItems: "center",
      minHeight: 17,
      visibility: props.text.length > 0 ? "visible" : "hidden",
    }}
  >
    <Warning color="error" fontSize="inherit" sx={{ mr: 0.6 }} />
    <Typography fontSize="inherit" color="error" fontWeight={700}>
      {props.text}
    </Typography>
  </Box>
);
