import {
  Box,
  BoxProps,
  IconButton,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import { formatDisplayTypeValue, isDefined } from "utils";
import { chartsColor } from "theme";
import { memo, useCallback, useRef } from "react";
import {
  ChartTooltip,
  defaultChartTooltipData,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { ChartPlugins } from "chartUtils";
import { useChartHighlight } from "app/useChartHighlight";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { ChartDataset } from "chart.js";
import { PointPrefixedOptions } from "chart.js";
import {
  CarSimDataTable,
  UseSimulationDataExplorerOutput,
} from "./useSimulationDataExplorerOutput";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const chartHeight = 580;

const StyledPageButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  "&.Mui-disabled": {
    backgroundColor: theme.palette.gray6,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.main,
  },
  ".MuiSvgIcon-root": {
    color: theme.palette.white,
  },
}));

type PathDataset = ChartDataset<
  "line",
  {
    x: number;
    y: number;
  }[]
> & {
  color: string;
  path: number;
  isChecked: boolean;
};

export const getSimDataTableChartData = (
  table?: CarSimDataTable,
  isPathChecked?: (path: number) => boolean,
) => {
  const datasets =
    table?.rows.map<PathDataset>((row, rowIdx) => ({
      id: rowIdx.toString(),
      label: row.path.toString(),
      path: row.path,
      isChecked: isPathChecked?.(row.path) ?? false,
      data: row.values.map((i, idx) => ({
        x: table.columns.at(idx)?.year ?? 0,
        y: i as number,
      })),
      color: Object.values(chartsColor)[rowIdx] ?? "#A0A0A0",
    })) ?? [];

  return {
    datasets,
  };
};
export interface SimulationDataExplorerChartProps {
  sx?: BoxProps["sx"];
  output: UseSimulationDataExplorerOutput;
  isWhiteContext?: boolean;
}

const StyledLine = styled(Line)(({ theme }) => ({
  width: "100%",
  height: chartHeight,
  maxHeight: chartHeight,
})) as typeof Line;

type ChartRef = ChartJSOrUndefined<
  "line",
  {
    x: number;
    y: number;
  }[],
  unknown
>;

export const SimulationDataExplorerChart = memo(
  (props: SimulationDataExplorerChartProps) => {
    const chartHighlight = useChartHighlight();
    const { datasets } = getSimDataTableChartData(
      props.output.table,
      props.output.isPathChecked,
    );

    const { tooltipPlugin, tooltipData, setTooltipData } = useChartTooltip({
      chartHighlight,
    });
    const chartRef = useRef<ChartRef | null>();

    const chartRefCallback = useCallback(
      (value: ChartRef | null) => {
        chartHighlight.unsubscribe(chartRef.current);
        chartRef.current = value;

        chartHighlight.subscribe(chartRef.current, (hlValue) => {
          if (
            !isDefined(hlValue.datasetIndex) ||
            !isDefined(hlValue.dataIndex)
          ) {
            setTooltipData(defaultChartTooltipData);
            return;
          }

          if (chartRef.current) {
            const { width, height } = chartRef.current.canvas;
            const data = chartRef.current.data.datasets.at(hlValue.datasetIndex)
              ?.data;

            const value = data?.at(hlValue.dataIndex);

            if (value) {
              const x = chartRef.current.scales.x.getPixelForValue(value.x);
              const y = chartRef.current.scales.y.getPixelForValue(value.y);
              setTooltipData({
                opacity: 1,
                x,
                y,
                canvasSize: { width, height },
                datasetIndex: hlValue.datasetIndex,
                dataIndex: hlValue.dataIndex,
              });
            }
          }

          chartRef.current?.update();
        });
      },
      [chartHighlight, setTooltipData],
    );

    const minMaxRef = useRef({ minX: 0, maxX: 0, minY: 0, maxY: 0 });
    minMaxRef.current = {
      minX: Math.min(...datasets.flatMap((ds) => ds.data.map((d) => d.x))),
      maxX: Math.max(...datasets.flatMap((ds) => ds.data.map((d) => d.x))),
      minY: props.output.minValue, // Math.min(...datasets.flatMap((ds) => ds.data.map((d) => d.y))),
      maxY: props.output.maxValue, // Math.max(...datasets.flatMap((ds) => ds.data.map((d) => d.y))),
    };

    const theme = useTheme();

    const xFormatter = formatDisplayTypeValue("INTEGER");
    const yFormatter = props.output.isPercentage
      ? formatDisplayTypeValue("PERCENTAGE")
      : formatDisplayTypeValue("FLOAT");

    const formatXValue = (value: number | string) =>
      typeof value === "number" ? xFormatter(value) : "";

    const formatYValue = (value: number | string) =>
      typeof value === "number" ? yFormatter(value) : "";

    const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
      const ds = datasets[datasetIndex];
      if (!ds) {
        return <></>;
      }

      const xValue = ds.data?.[dataIndex]?.x ?? 0;
      const yValue = ds.data?.[dataIndex]?.y ?? 0;
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
            <Box
              sx={{
                width: 20,
                height: 20,
                border: "1px solid",
                borderColor: "gray6",
                borderRadius: "4px",
                backgroundColor: ds.color,
                flexShrink: 0,
              }}
            />
            <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
              {`Path: ${ds.label}`}
            </Typography>
          </Box>
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {`Year: ${formatXValue(xValue)}`}
          </Typography>
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {`${props.output.pointHintLabel}: ${formatYValue(yValue)}`}
          </Typography>
        </Box>
      );
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <ChartTooltip
          tooltipData={tooltipData}
          renderTooltip={renderTooltip}
          sx={{
            ...props.sx,
          }}
        >
          <StyledLine
            ref={chartRefCallback}
            datasetIdKey="id"
            data={{
              datasets: datasets.map<
                ChartDataset<"line", { x: number; y: number }[]>
              >((ds) => ({
                id: ds.id,
                label: ds.label,
                data: ds.data,
                borderColor: ds.color,
                backgroundColor: ds.color,
                pointBackgroundColor: ds.color,
                pointBorderColor: ds.color,
                borderWidth: 1,
                pointRadius: 2,
                color: ds.color,
              })),
            }}
            options={{
              // animation: true,
              onClick: (event, el, ch) => {},
              onHover: (event, elements, chart) => {
                const hoverDatasetIndex = elements.at(0)?.datasetIndex ?? -1;
                chart.config.data.datasets.forEach((i, idx) => {
                  const ds = i as typeof i &
                    PointPrefixedOptions & { color: string };

                  if (hoverDatasetIndex < 0) {
                    ds.borderWidth = 1;
                    ds.order = 0;

                    ds.borderColor = ds.color;
                    ds.pointBorderColor = ds.color;
                    ds.pointBackgroundColor = ds.color;
                  } else if (idx === hoverDatasetIndex) {
                    ds.borderWidth = 3;
                    ds.order = 0;

                    const lineColor = "#000000"; // emphasize(ds.color, 0.3);

                    ds.borderColor = lineColor;
                    ds.pointBorderColor = lineColor;
                    ds.pointBackgroundColor = lineColor;
                  } else {
                    ds.borderWidth = 1;
                    ds.order = 10;

                    const lineColor = alpha(ds.color, 0.4);

                    ds.borderColor = lineColor;
                    ds.pointBorderColor = lineColor;
                    ds.pointBackgroundColor = lineColor;
                  }
                });

                chart.update();
              },
              maintainAspectRatio: false,
              scales: {
                x: {
                  type: "linear",
                  ticks: {
                    font: {
                      family: theme.typography.fontFamily,
                      size: 15,
                      weight: "400",
                    },
                    callback: formatXValue,
                  },
                  border: { display: false },
                  grid: {
                    color: (ctx) => {
                      return ctx.tick.value <= minMaxRef.current.minX ||
                        ctx.tick.value >= minMaxRef.current.maxX
                        ? "transparent"
                        : theme.palette.gray2;
                    },
                    lineWidth: 2,
                    tickColor: theme.palette.white,
                  },
                  // title: {
                  //   display: true,
                  //   text: "Years",
                  //   color: theme.palette.softBlack,
                  //   font: {
                  //     family: theme.typography.fontFamily,
                  //     size: 15,
                  //     weight: "600",
                  //     lineHeight: 1,
                  //   },
                  //   padding: {
                  //     top: 10,
                  //   },
                  // },
                },
                y: {
                  type: props.output.isLogarithmic ? "logarithmic" : "linear",
                  min: props.output.minValue,
                  max: props.output.maxValue,
                  ticks: {
                    font: {
                      family: theme.typography.fontFamily,
                      size: 15,
                      weight: "400",
                    },
                    callback: formatYValue,
                  },
                  border: { display: false },
                  grid: {
                    color: (ctx) => {
                      return ctx.tick.value <= minMaxRef.current.minY ||
                        ctx.tick.value >= minMaxRef.current.maxY
                        ? "transparent"
                        : theme.palette.gray2;
                    },
                    lineWidth: 2,
                    tickColor: theme.palette.white,
                  },
                  title: {
                    display: true,
                    text: props.output.pointHintLabel,
                    color: theme.palette.softBlack,
                    font: {
                      family: theme.typography.fontFamily,
                      size: 15,
                      weight: "600",
                      lineHeight: 1,
                    },
                    padding: {
                      bottom: 20,
                    },
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: tooltipPlugin,
                roundedBackground: {
                  contextColor: props.isWhiteContext
                    ? theme.palette.white
                    : theme.palette.gray1,
                  backgroundColor: props.isWhiteContext
                    ? theme.palette.gray1
                    : theme.palette.white,
                  borderColor: theme.palette.gray3,
                  borderRadius: 5,
                },
              },
              events: [
                "mousemove",
                "mouseout",
                "click",
                "touchstart",
                "touchmove",
                "dblclick",
              ],
            }}
            plugins={[
              ChartPlugins.roundedBackground,
              {
                id: "dblClickListener",
                afterEvent: (chart, { event, inChartArea }) => {
                  if (inChartArea && event.type === "dblclick") {
                    const datasetIndex = chart.getActiveElements().at(0)
                      ?.datasetIndex;
                    const ds = datasets.at(datasetIndex ?? -1);
                    if (isDefined(ds)) {
                      if (!ds.isChecked) {
                        props.output.handleCheckPath(ds.path);
                      }
                    }
                  }
                },
              },
            ]}
          />
        </ChartTooltip>
        <Typography
          variant="par01SemiBold"
          sx={{ mt: 2, fontSize: "15px", alignSelf: "center" }}
        >
          {props.output.paginationLabel}
        </Typography>
        <Box sx={{ mt: 1, alignSelf: "center", display: "flex", gap: 1.5 }}>
          <StyledPageButton
            size="small"
            disabled={!props.output.canGoPrevPage}
            onClick={props.output.handleGoPrevPage}
          >
            <ChevronLeft fontSize="small" />
          </StyledPageButton>
          <StyledPageButton
            size="small"
            disabled={!props.output.canGoNextPage}
            onClick={props.output.handleGoNextPage}
          >
            <ChevronRight fontSize="small" />
          </StyledPageButton>
        </Box>
      </Box>
    );
  },
);
