import { PaletteOptions } from "@mui/material";

export const chartsColor = {
  green: "#A9BD86",
  aqua: "#AFD6D7",
  orange: "#DFAC7F",
  blue: "#6E9DD8",
  purple: "#A08DBD",
  yellow: "#F0D47F",
  red: "#D79591",
  darkPurple: "#7074A5",
  darkOrange: "#CF7E37",
  darkGreen: "#6E8643",
  darkRed: "#A45A55",
  darkBlue: "#31356B",
  lightBlue: "#7CCBEE",
  lightOrange: "#FAEADC",
  lightGreen: "#DEECC6",
  lightRed: "#F8E0DF",
  lightAqua: "#D3EEFA",
  lightPurple: "#DCD2EC",
  dirtyGreen: "#64A1A3",
  dirtyYellow: "#E7D398",
  dirtyOrange: "#BA9D46",
  blueBackground: "rgba(110, 157, 216, 0.2)",
  greenBackground: "rgba(169, 189, 134, 0.2)",
  failed: "#FF0000",
  halfFailed: "#FFFF00",
};

// new colors
export const caravelColors = {
  caravelOrangePrimary: "rgba(241, 150, 73, 1)",
  caravelBlueSecondary: "rgba(70, 148, 182, 1)",
  caravelNavyTertiary: "rgba(33, 54, 97, 1)",
  white: "rgba(255, 255, 255, 1)",
  gray1: "rgba(250, 250, 250, 1)",
  gray2: "rgba(236, 238, 240, 1)",
  gray3: "rgba(223, 225, 227, 1)",
  gray4: "rgba(209, 211, 213, 1)",
  gray5: "rgba(194, 196, 198, 1)",
  gray6: "rgba(178, 179, 181, 1)",
  gray7: "rgba(159, 160, 161, 1)",
  gray8: "rgba(136, 137, 138, 1)",
  gray9: "rgba(107, 108, 109, 1)",
  softBlack: "rgba(85, 85, 85, 1)",
  black: "#000000",
  green: "#27AE60",
  red: "#EB5757",
  yellow: "#F2C94C",
  lightOrange: "#FAEADC",
  lightBlue: "#7CCBEE",
  lightRed: "#F8E0DF",
  lightGreen: "#DEECC6",
  chartsColor,
};

// deprecated
export const primaryColor = caravelColors.caravelOrangePrimary;
export const errorColor = caravelColors.red;
export const successColor = caravelColors.green;
export const needsColor = caravelColors.caravelBlueSecondary;
export const wantsColor = "#EE8022";
export const dreamsColor = "#E0CD1F";
export const disabledColor = "#C4C4C4";

type CaravelColors = typeof caravelColors;

declare module "@mui/material/styles" {
  interface Palette extends CaravelColors {
    needs: string;
    wants: string;
    dreams: string;
    disabled: string;

    table: {
      text: string;
      background: {
        level1: string;
        level2: string;
        level3: string;
        level4: string;
        header: string;
        odd: string;
        even: string;
      };
      error: string;
      border: string;
    };
  }
  // allow configuration using `createTheme`
  interface PaletteOptions extends CaravelColors {
    needs: string;
    wants: string;
    dreams: string;
    disabled: string;

    border: string;

    table: {
      text: string;
      background: {
        level1: string;
        level2: string;
        level3: string;
        level4: string;
        header: string;
        odd: string;
        even: string;
      };
      error: string;
      border: string;
    };
  }
}

export const paletteOptions: PaletteOptions = {
  ...caravelColors,

  needs: needsColor,
  wants: wantsColor,
  dreams: dreamsColor,
  disabled: disabledColor,

  border: caravelColors.gray6,

  table: {
    text: caravelColors.softBlack,
    background: {
      level1: caravelColors.white,
      level2: caravelColors.gray1,
      level3: caravelColors.gray2,
      level4: caravelColors.gray3,
      header: caravelColors.gray3,
      odd: caravelColors.white,
      even: caravelColors.gray2,
    },
    error: caravelColors.red,
    border: caravelColors.gray6,
  },

  primary: {
    // light: will be calculated from palette.primary.main,
    main: caravelColors.caravelOrangePrimary,
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    main: caravelColors.caravelBlueSecondary,
  },
  text: {
    primary: caravelColors.softBlack,
    secondary: "#999999",
  },
  background: {
    default: caravelColors.gray2,
  },
  success: {
    main: caravelColors.green,
  },
  error: {
    main: caravelColors.red,
  },
};
