import { Fragment } from "react";
import { Typography, Box, MenuItem } from "@mui/material";
import { CarFactorPercentFieldDelayed } from "components/NumberField";
import { CarSelectField } from "components/Inputs";
import { UseSettingsTiers } from "./useSettingsTiers";

interface MonthReminderItem {
  label: string;
  value: number;
}

const monthReminderItems: Array<MonthReminderItem> = [
  { label: "1 month", value: 1 },
  { label: "2 months", value: 2 },
  { label: "3 months", value: 3 },
  { label: "4 months", value: 4 },
  { label: "5 months", value: 5 },
  { label: "6 months", value: 6 },
  { label: "7 months", value: 7 },
  { label: "8 months", value: 8 },
  { label: "9 months", value: 9 },
  { label: "10 months", value: 10 },
  { label: "11 months", value: 11 },
  { label: "12 months", value: 12 },
];

export const SectionTierSettings = ({ data }: { data: UseSettingsTiers }) => {
  return (
    <Box
      sx={{
        border: "solid 1px",
        borderColor: "gray3",
        borderRadius: "5px",
        backgroundColor: "white",
        p: 4.5,

        display: "grid",
        rowGap: 2,
        columnGap: 6.5,
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gridTemplateRows: "auto 1px",
        alignItems: "center",
        justifyContent: "center",
        ".MuiInputBase-root": {
          backgroundColor: "gray1",
        },
        "& > .MuiTypography-par01Regular": {
          fontWeight: 500,
        },
      }}
    >
      <Box />
      <Typography
        variant="par01Regular"
        textAlign="center"
        alignSelf="flex-end"
      >
        Surplus Drop
      </Typography>
      <Typography
        variant="par01Regular"
        textAlign="center"
        alignSelf="flex-end"
      >
        Surplus Increase
      </Typography>
      <Typography
        variant="par01Regular"
        textAlign="center"
        alignSelf="flex-end"
      >
        Total Allocation Difference
      </Typography>
      <Typography
        variant="par01Regular"
        textAlign="center"
        alignSelf="flex-end"
      >
        Review Plan
      </Typography>
      {data.items.map((i) => (
        <Fragment key={i.value.id}>
          <Box
            sx={{
              mr: -2,
              borderBottom: "solid 1px",
              borderColor: "gray3",
              gridColumn: "1 / span 5",
            }}
          />
          <Typography variant="par01SemiBold" textAlign="center">
            {i.label}
          </Typography>
          <CarFactorPercentFieldDelayed
            value={i.value.delta_surplus_drop_percentage}
            onChange={(delta_surplus_drop_percentage) =>
              data.handleChange({
                ...i.value,
                delta_surplus_drop_percentage,
              })
            }
          />
          <CarFactorPercentFieldDelayed
            value={i.value.delta_surplus_increase_percentage}
            onChange={(delta_surplus_increase_percentage) =>
              data.handleChange({
                ...i.value,
                delta_surplus_increase_percentage,
              })
            }
          />
          <CarFactorPercentFieldDelayed
            value={i.value.total_delta_allocation_difference_threshold}
            onChange={(total_delta_allocation_difference_threshold) =>
              data.handleChange({
                ...i.value,
                total_delta_allocation_difference_threshold,
              })
            }
          />
          <CarSelectField
            value={i.value.review_reminder_months as any}
            onChange={(review_reminder_months: any) =>
              data.handleChange({
                ...i.value,
                review_reminder_months,
              })
            }
          >
            {monthReminderItems.map((i) => (
              <MenuItem key={i.value} value={i.value}>
                {i.label}
              </MenuItem>
            ))}
          </CarSelectField>
        </Fragment>
      ))}
    </Box>
  );
};
