import {
  DisplayTypeEnum2,
  useAssetClassesFirmAssetClassesListQuery,
  usePortfolioConstraintChoicesListQuery,
  usePortfolioPortfolioGroupVersionAssetClassesListQuery,
  usePortfolioPortfolioGroupVersionOptimizerAllConsListQuery,
} from "api/carApi.generated";
import { validatePercentile } from "app/thirdParty/sentry";
import { CarAssetClassHierarchy } from "types";
import { isDefined } from "utils";

export interface CarPortfolioConstrBand {
  moduleId: string;
  label: string;
  sort: number;
  isCash: boolean;
}

export interface CarPortfolioConstrValue {
  id: string;
  moduleId: string;
  constraintTypeId: string;
  constraintTypeCode: string;
  value: number;
  percentile?: number;
  enabled: boolean;
}

export interface CarPortfolioConstrRow {
  constraintTypeId: string;
  name: string;
  code: string;
  sort: number;
  inputType: DisplayTypeEnum2;
  maxValue?: number;
  minValue?: number;
  values: CarPortfolioConstrValue[];
  percentile?: number;
}

export interface CarPortfolioConstrHorizonValue {
  moduleId: string;
  value: number;
}

export interface CarPortfolioConstrHorizonRow {
  name: string;
  values: CarPortfolioConstrHorizonValue[];
}

export interface CarPortfolioConstrAcValue {
  id: string;
  moduleId: string;
  assetClass4Id: string;
  maxAllocation: number | undefined;
  minAllocation: number | undefined;
  isEditable: boolean;
}

export interface CarPortfolioConstrAcRow {
  firmAssetClassId?: string;
  parentId?: string;
  id: string;
  name: string;
  code: string;
  sort: number;
  color?: string;
  children: CarPortfolioConstrAcRow[];
  values: CarPortfolioConstrAcValue[];
}

export interface CarPortfolioConstrTable {
  bands: CarPortfolioConstrBand[];
  horizonRow: CarPortfolioConstrHorizonRow;
  rows: CarPortfolioConstrRow[];
  acRows: CarPortfolioConstrAcRow[];
}

export const usePortfolioGroupVersionConstraints = (
  portfolioGroupVersionId?: string,
) => {
  const assetClasses = useAssetClassesFirmAssetClassesListQuery();
  const constrChoices = usePortfolioConstraintChoicesListQuery();

  const versionClasses = usePortfolioPortfolioGroupVersionAssetClassesListQuery(
    {
      portfolioGroupVersionId: portfolioGroupVersionId ?? "",
    },
    { skip: !portfolioGroupVersionId },
  );

  const versionConstraints =
    usePortfolioPortfolioGroupVersionOptimizerAllConsListQuery(
      {
        portfolioGroupVersionId: portfolioGroupVersionId ?? "",
      },
      { skip: !portfolioGroupVersionId },
    );

  const isLoading =
    assetClasses.isLoading ||
    constrChoices.isLoading ||
    versionConstraints.isLoading ||
    versionClasses.isLoading;

  const sortedModuleItems = Array.from(versionConstraints.data ?? []).sort(
    (a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0),
  );

  const getModuleItem = (moduleId?: string) =>
    sortedModuleItems.find((md) => md.id === moduleId);

  const isAssetClassOptionActive = (firmAssetClassId?: string) =>
    versionClasses.data?.find((i) => i.firm_asset_class_id === firmAssetClassId)
      ?.is_active;

  const getConstraint = (moduleId: string, constraintTypeId: string) => {
    const moduleItem = getModuleItem(moduleId);

    const constraint = moduleItem?.optimizer_constraints?.find(
      (c) => c.constraint_type === constraintTypeId,
    );

    if (!constraint) {
      // console.error("Constraint not found", moduleId, constraintTypeId);
      throw new Error(`Constraint not found ${moduleId} ${constraintTypeId}`);
    }

    return constraint;
  };

  const getAllocationConstraint = (
    moduleId: string,
    assetClass4Id?: string,
  ) => {
    const moduleItem = getModuleItem(moduleId);

    const acConstraint = moduleItem?.optimizer_asset_class_constraints?.find(
      (al) => al.asset_class_4 === assetClass4Id,
    );

    if (!acConstraint) {
      // console.error("AC Constraint not found", moduleId, assetClass4Id);
      throw new Error(`AC Constraint not found ${moduleId} ${assetClass4Id}`);
    }

    return acConstraint;
  };

  const getTable = (): CarPortfolioConstrTable => {
    const bands: CarPortfolioConstrBand[] = sortedModuleItems
      .map((i) => ({
        moduleId: i.id ?? "",
        label: i.title,
        sort: i.sort_order ?? 0,
        isCash: !!i.is_cash,
      }))
      .filter((i) => !i.isCash);

    const getHorizonRow = (): CarPortfolioConstrHorizonRow => {
      if (isLoading) {
        return { name: "", values: [] };
      }

      return {
        name: "Target B/E Horizon",
        values: bands.map<CarPortfolioConstrHorizonValue>((bd) => {
          const item = getModuleItem(bd.moduleId);

          return {
            moduleId: bd.moduleId,
            value: item?.overridden_assigned_al_horizon ?? 0,
          };
        }),
      };
    };

    const getConstrRows = (): CarPortfolioConstrRow[] => {
      if (isLoading) {
        return [];
      }

      return (constrChoices.data ?? [])
        .map<CarPortfolioConstrRow>((i) => {
          const percentile: Array<number | null | undefined> = [];

          const row: CarPortfolioConstrRow = {
            constraintTypeId: i.id ?? "",
            name: i.name ?? "",
            code: i.code ?? "",
            sort: i.sort_order ?? 0,
            inputType: i.input_type ?? "PERCENTAGE",
            maxValue: i.max_val ?? undefined,
            minValue: i.min_val ?? undefined,
            values: bands.map<CarPortfolioConstrValue>((bd) => {
              const item = getConstraint(bd.moduleId, i.id ?? "");

              percentile.push(i.has_percentile ? item?.percentile : undefined);

              return {
                id: item.id ?? "",
                moduleId: item.portfolio_module_id ?? "",
                constraintTypeId: item.constraint_type ?? "",
                constraintTypeCode: item.constraint_type_code ?? "",
                value: item.value ?? 0,
                percentile: item.percentile ?? 0,
                enabled: item.enabled ?? false,
              };
            }),
          };

          row.percentile = validatePercentile(percentile.filter(isDefined))[0];

          return row;
        })
        .sort((a, b) => a.sort - b.sort);
    };

    const getConstrAcRows = (): CarPortfolioConstrAcRow[] => {
      if (isLoading) {
        return [];
      }

      const items = assetClasses.data ?? [];

      function getAssetClassValues(
        assetClass4Id: string,
      ): CarPortfolioConstrAcValue[] {
        return bands.map((bd) => {
          const item = getAllocationConstraint(bd.moduleId, assetClass4Id);

          return {
            isCash: bd.isCash,
            id: item.id ?? "",
            moduleId: bd.moduleId,
            assetClass4Id,
            minAllocation: item.min_allocation,
            maxAllocation: item.max_allocation,
            isEditable: true,
          };
        });
      }

      const extractAssetItem = (
        items: Array<CarAssetClassHierarchy>,
        extractor: (
          i: CarAssetClassHierarchy,
        ) => CarPortfolioConstrAcRow | undefined,
      ) => {
        return items
          .reduce((acc, i) => {
            const item = extractor(i);
            return item ? [...acc.filter((v) => v.id !== item.id), item] : acc;
          }, [] as Array<CarPortfolioConstrAcRow>)
          .sort((a, b) => a.sort - b.sort);
      };

      const calcValues = (items: Array<CarPortfolioConstrAcRow>) => {
        function sumValues(
          a: CarPortfolioConstrAcValue,
          b?: CarPortfolioConstrAcValue,
        ): CarPortfolioConstrAcValue {
          return {
            id: `fake_${a.moduleId}_${a.assetClass4Id}`, // fake id,
            moduleId: a.moduleId,
            assetClass4Id: a.assetClass4Id,
            isEditable: false,
            maxAllocation: (a.maxAllocation ?? 0) + (b?.maxAllocation ?? 0),
            minAllocation: (a.minAllocation ?? 0) + (b?.minAllocation ?? 0),
          };
        }

        // const totalCount = items.length;

        return items
          .map((i) => i.values)
          .reduce((acc, i) => {
            const [a, b] = acc.length > i.length ? [acc, i] : [i, acc];
            return a.map((item, idx) => sumValues(item, b[idx]));
          }, [] as Array<CarPortfolioConstrAcValue>)
          .map((i) => ({
            ...i,
            minAllocation: undefined, // (i.minAllocation ?? 0) / totalCount,
            maxAllocation: undefined, // (i.maxAllocation ?? 0) / totalCount,
          }));
      };

      const level4 = extractAssetItem(items, (i) => {
        const v = i.asset_class;

        if (!i.is_active || !v?.is_active) {
          return undefined;
        }

        return (
          v && {
            firmAssetClassId: i.id,
            parentId: i.asset_class?.ac_level3?.id,
            id: v.id ?? "",
            name: v.display_name ?? "",
            code: v.code ?? "",
            sort: v.sort_order ?? 0,
            children: [],
            values: getAssetClassValues(i.asset_class?.id ?? ""),
          }
        );
      }).filter((i) => isAssetClassOptionActive(i.firmAssetClassId));

      const level3 = extractAssetItem(items, (i) => {
        const v = i.asset_class?.ac_level3;
        const children = level4.filter((i) => i.parentId === v?.id);
        return (
          v && {
            parentId: i.asset_class?.ac_level3?.ac_level2?.id,
            id: v.id ?? "",
            name: v.name ?? "",
            code: v.code ?? "",
            sort: v.sort_order ?? 0,
            children,
            values: calcValues(children),
          }
        );
      }).filter((i) => i.children.length);

      const level2 = extractAssetItem(items, (i) => {
        const v = i.asset_class?.ac_level3?.ac_level2;
        const children = level3.filter((i) => i.parentId === v?.id);
        return (
          v && {
            parentId: i.asset_class?.ac_level3?.ac_level2?.ac_level1?.id,
            id: v.id ?? "",
            name: v.name ?? "",
            code: v.code ?? "",
            sort: v.sort_order ?? 0,
            color: v.color_code,
            children,
            values: calcValues(children),
          }
        );
      }).filter((i) => i.children.length);

      const level1 = extractAssetItem(items, (i) => {
        const v = i.asset_class?.ac_level3?.ac_level2?.ac_level1;
        const children = level2.filter((i) => i.parentId === v?.id);
        return (
          v && {
            id: v.id ?? "",
            name: v.name ?? "",
            code: v.code ?? "",
            sort: v.sort_order ?? 0,
            children,
            values: calcValues(children),
          }
        );
      }).filter((i) => i.children.length);

      return level1.map((l1) => ({
        ...l1,
        children: l1.children
          .flatMap((l2) => l2.children)
          .flatMap((l3) => l3.children),
      }));
    };

    return {
      bands,
      horizonRow: getHorizonRow(),
      rows: getConstrRows(),
      acRows: getConstrAcRows(),
    };
  };

  const table: CarPortfolioConstrTable = isLoading
    ? {
        bands: [],
        horizonRow: { name: "", values: [] },
        rows: [],
        acRows: [],
      }
    : getTable();

  return {
    isLoading,
    table,
  };
};
