import { CarPdfBox, CarPdfBoxProps } from "components/PdfBox";
import {
  CarPdfIconFailure,
  CarPdfIconPartial,
  CarPdfIconSuccess,
} from "components/PdfIcon";
import { CarPdfTypography } from "components/PdfTypography";

export const CarPdfGoalsLegend = ({ style, ...props }: CarPdfBoxProps) => (
  <CarPdfBox
    style={{
      paddingHorizontal: 20,
      flexDirection: "row",
      justifyContent: "space-around",
      ...style,
    }}
    {...props}
  >
    <CarPdfBox style={{ flexDirection: "row", alignItems: "center" }}>
      <CarPdfIconSuccess />
      <CarPdfTypography variant="tableBody3" style={{ marginLeft: 4 }}>
        Goal passed
      </CarPdfTypography>
    </CarPdfBox>
    <CarPdfBox style={{ flexDirection: "row", alignItems: "center" }}>
      <CarPdfIconPartial />
      <CarPdfTypography variant="tableBody3" style={{ marginLeft: 4 }}>
        Goal partially funded
      </CarPdfTypography>
    </CarPdfBox>
    <CarPdfBox style={{ flexDirection: "row", alignItems: "center" }}>
      <CarPdfIconFailure />
      <CarPdfTypography variant="tableBody3" style={{ marginLeft: 4 }}>
        Goal failed
      </CarPdfTypography>
    </CarPdfBox>
  </CarPdfBox>
);
