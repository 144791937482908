import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material";
import { typographyOptions } from "./typography";
import { paletteOptions } from "./palette";

import proximanovaRegularWoff2 from "assets/fonts/proximanova-regular-webfont.woff2";
// import proximanovaRegularWoff from "assets/fonts/proximanova-regular-webfont.woff";

import proximanovaRegularItWoff2 from "assets/fonts/proximanova-regularit-webfont.woff2";
// import proximanovaRegularItWoff from "assets/fonts/proximanova-regularit-webfont.woff";

import proximanovaMediumWoff2 from "assets/fonts/proximanova-medium-webfont.woff2";
// import proximanovaMediumWoff from "assets/fonts/proximanova-medium-webfont.woff";

import proximanovaSemiboldWoff2 from "assets/fonts/proximanova-semibold-webfont.woff2";
// import proximanovaSemiboldWoff from "assets/fonts/proximanova-semibold-webfont.woff";

import proximanovaBoldWoff2 from "assets/fonts/proximanova-bold-webfont.woff2";
// import proximanovaBoldWoff from "assets/fonts/proximanova-bold-webfont.woff";

import proximanovaExtraboldWoff2 from "assets/fonts/proximanova-extrabold-webfont.woff2";
// import proximanovaExtraboldWoff from "assets/fonts/proximanova-extrabold-webfont.woff";

const datePickerOverrides = {
  MuiDateCalendar: {
    styleOverrides: {
      root: {
        "button.MuiPickersYear-yearButton.Mui-selected": {
          "&:focus, &:hover": {
            backgroundColor: paletteOptions.caravelOrangePrimary,
          },
        },
        "button.MuiPickersMonth-monthButton.Mui-selected": {
          "&:focus, &:hover": {
            backgroundColor: paletteOptions.caravelOrangePrimary,
          },
        },
        "button.MuiPickersDay-root.Mui-selected": {
          "&:focus, &:hover": {
            backgroundColor: paletteOptions.caravelOrangePrimary,
          },
        },
        "button.MuiPickersDay-root": {
          fontSize: "14px",
          fontWeight: 400,
        },

        ".MuiPickersCalendarHeader-label": {
          ...typographyOptions.h6SemiBold,
        },
      },
    },
  },
};

document.fonts.add(
  new FontFace("proximanova", `url('${proximanovaRegularWoff2}')`, {
    style: "normal",
    weight: "400",
  }),
);
document.fonts.add(
  new FontFace("proximanova", `url('${proximanovaRegularItWoff2}')`, {
    style: "italic",
    weight: "400",
  }),
);
document.fonts.add(
  new FontFace("proximanova", `url('${proximanovaMediumWoff2}')`, {
    style: "normal",
    weight: "500",
  }),
);
document.fonts.add(
  new FontFace("proximanova", `url('${proximanovaSemiboldWoff2}')`, {
    style: "normal",
    weight: "600",
  }),
);
document.fonts.add(
  new FontFace("proximanova", `url('${proximanovaBoldWoff2}')`, {
    style: "normal",
    weight: "700",
  }),
);
document.fonts.add(
  new FontFace("proximanova", `url('${proximanovaExtraboldWoff2}')`, {
    style: "normal",
    weight: "800",
  }),
);

document.fonts.forEach((f) => f.load());

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      //below lg - mobile layout
      lg: 1280, // normal page without sidebar
      // lgx: 1340, // break point for
      xl: 1440, // normal page
    },
  },
  palette: paletteOptions,
  typography: typographyOptions,
  components: {
    // MuiCssBaseline: {
    // styleOverrides: `
    //   @font-face {
    //     font-family: 'proximanova';
    //     src: url('${proximanovaRegularWoff2}') format('woff2'),
    //         url('${proximanovaRegularWoff}') format('woff');
    //     font-weight: 400;
    //     font-style: normal;
    //   }

    //   @font-face {
    //     font-family: 'proximanova';
    //     src: url('${proximanovaRegularItWoff2}') format('woff2'),
    //          url('${proximanovaRegularItWoff}') format('woff');
    //     font-weight: 400;
    //     font-style: italic;
    //   }

    //   @font-face {
    //     font-family: 'proximanova';
    //     src: url('${proximanovaSemiboldWoff2}') format('woff2'),
    //          url('${proximanovaSemiboldWoff}') format('woff');
    //     font-weight: 600;
    //     font-style: normal;
    //   }

    //   @font-face {
    //     font-family: 'proximanova';
    //     src: url('${proximanovaBoldWoff2}') format('woff2'),
    //          url('${proximanovaBoldWoff}') format('woff');
    //     font-weight: 700;
    //     font-style: normal;
    //   }

    //   @font-face {
    //     font-family: 'proximanova';
    //     src: url('${proximanovaExtraboldWoff2}') format('woff2'),
    //         url('${proximanovaExtraboldWoff}') format('woff');
    //     font-weight: 800;
    //     font-style: normal;
    //   }
    // `,
    // },

    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          textTransform: "none",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 13,
          overflow: "hidden",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          transform: "translate(0px, -3px) !important",
          fontSize: 14,
          fontWeight: 400,
          color: "#555",
          maxWidth: "100%",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.MuiInputLabel-root": {
            pointerEvents: "auto",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: "11px 10px",
          height: "18px",
          minHeight: "18px",
          ...typographyOptions.par01Regular,
          color: paletteOptions.black,
          "textarea&": {
            padding: "0px !important",
          },
        },
        root: {
          flexWrap: "nowrap",
          backgroundColor: paletteOptions.gray1,
          "label + &": {
            marginTop: 20,
          },
          "& fieldset": {
            top: 0,
            borderRadius: "5px",
            borderWidth: 2,
            borderColor: paletteOptions.gray3,
            "& legend": {
              display: "none",
            },
          },
          "&:hover fieldset": {
            borderColor: `${paletteOptions.gray3} !important`,
          },
          "&.Mui-disabled fieldset": {
            borderColor: `${paletteOptions.gray3} !important`,
          },
          "&.Mui-focused:hover fieldset": {
            borderColor: `${paletteOptions.caravelOrangePrimary} !important`,
          },
          "&.Mui-error:hover fieldset": {
            borderColor: `${paletteOptions.red} !important`,
          },
        },
        adornedEnd: {
          paddingRight: 0,
          color: "#C4C4C4",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "#6B6C6D",
        },
        iconOutlined: {
          right: 0,
        },
        select: {
          paddingRight: "20px !important",
          height: "18px",
          minHeight: "18px",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: typographyOptions.fontFamily,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: typographyOptions.fontFamily,
          textTransform: "none",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1SSemiBold: "h1",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: paletteOptions.softBlack,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: paletteOptions.gray6,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: `4px 4px 20px 0px #0000000D`,
          borderRadius: "5px",
          border: "solid 1px",
          borderColor: paletteOptions.gray3,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(200, 200, 200, 0.6)",
        },
      },
    },
    ...datePickerOverrides,
  },
});
