import SimpleBar from "simplebar-react";
import { Box, Typography, styled } from "@mui/material";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { useMemo } from "react";
import { formatCurrency } from "utils";
import { CarTooltip } from "components/Tooltip";
import { WorkbenchInflationSmall } from "../WorkbenchInflation";

export interface PlanResultCardPassFailProps {
  data: UsePlanResults;
}

function useCardData(data: UsePlanResults) {
  return useMemo(() => {
    const rowsData = data.goalResults.map((goalResult) => {
      const sum = data.barChart.years.reduce(
        (s, y) => {
          const goalItem = y.goals.find((i) => i.goalId === goalResult.goalId);
          s.amount += goalItem?.amount ?? 0;
          s.failedAmount += goalItem?.failedAmount ?? 0;
          s.successAmount += goalItem?.successAmount ?? 0;
          if ((goalItem?.successAmount ?? 0) > 0) {
            s.minSuccessYear = s.minSuccessYear || y.year;
            s.maxSuccessYear = y.year;
          }
          if ((goalItem?.failedAmount ?? 0) > 0) {
            s.minFailedYear = s.minFailedYear || y.year;
            s.maxFailedYear = y.year;
          }
          return s;
        },
        {
          amount: 0,
          failedAmount: 0,
          successAmount: 0,
          minFailedYear: 0,
          maxFailedYear: 0,
          minSuccessYear: 0,
          maxSuccessYear: 0,
        },
      );

      const unfundedPercent = sum.amount
        ? Math.round((sum.failedAmount / sum.amount) * 100)
        : 0;
      return {
        goalResult,
        sum,
        fundedPercent: 100 - unfundedPercent,
        unfundedPercent,
        title: `${goalResult.name} (${
          goalResult.priority === 0
            ? "N"
            : goalResult.priority === 1
            ? "W"
            : "D"
        })`,
      };
    });

    const sum = rowsData.reduce(
      (s, d) => {
        s.amount += d.sum.amount;
        s.failedAmount += d.sum.failedAmount;
        s.successAmount += d.sum.successAmount;
        return s;
      },
      { amount: 0, failedAmount: 0, successAmount: 0 },
    );

    return { rowsData, sum };
  }, [data]);
}

type RowItem = ReturnType<typeof useCardData>["rowsData"][0];

function SumItem({
  sum,
  name,
  success,
}: {
  sum: number;
  name: string;
  success?: boolean;
}) {
  return (
    <Box sx={{ display: "inline-block" }}>
      <Box
        sx={{ color: success ? "green" : "red", fontSize: 30, fontWeight: 600 }}
      >
        {formatCurrency(sum, 0)}
      </Box>
      <Box sx={{ color: "softBlack", fontSize: 15, fontWeight: 400 }}>
        {name}
      </Box>
    </Box>
  );
}

function PercentBlock({
  row,
  funded,
  full,
}: {
  row: RowItem;
  funded?: boolean;
  full?: boolean;
}) {
  const title = funded
    ? `${row.fundedPercent}% Funded - ${formatCurrency(
        row.sum.successAmount,
        0,
      )}`
    : `${row.unfundedPercent}% Unfunded - ${formatCurrency(
        row.sum.failedAmount,
        0,
      )}`;

  const renderTooltip = () => {
    const infoLine = funded
      ? `Funded ${formatCurrency(row.sum.successAmount, 0)} per year ${
          row.sum.minSuccessYear
        } - ${row.sum.maxSuccessYear}`
      : `Unfunded ${formatCurrency(row.sum.failedAmount, 0)} per year ${
          row.sum.minFailedYear
        } - ${row.sum.maxFailedYear}`;
    return (
      <>
        <Typography sx={{ fontSize: 15, fontWeight: 600, color: "black" }}>
          {row.title}
        </Typography>
        <Typography sx={{ fontSize: 13, fontWeight: 400, color: "black" }}>
          {infoLine}
        </Typography>
      </>
    );
  };

  return (
    <CarTooltip title={renderTooltip()} disableInteractive>
      <Box
        sx={{
          width: `${funded ? row.fundedPercent : row.unfundedPercent}%`,
          minWidth: 10,
          height: 32,
          backgroundColor: funded ? "green" : "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderTopRightRadius: funded && !full ? undefined : "5px",
          borderBottomRightRadius: funded && !full ? undefined : "5px",
          overflow: "hidden",
        }}
      >
        {title}
      </Box>
    </CarTooltip>
  );
}

function LegendItem({ pass }: { pass?: boolean }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "12px" }}>
      <Box
        sx={{
          width: 24,
          height: 24,
          borderRadius: "4px",
          backgroundColor: pass ? "green" : "red",
        }}
      />
      <Typography variant="par03Regular">{pass ? "Pass" : "Fail"}</Typography>
    </Box>
  );
}

const SimpleBarStyled = styled(SimpleBar)({
  height: "100%",
  width: "100%",
});

export function PlanResultCardPassFail({ data }: PlanResultCardPassFailProps) {
  const cardData = useCardData(data);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        position: "relative",
      }}
    >
      <WorkbenchInflationSmall
        sx={{ position: "absolute", right: 24, top: 34 }}
      />
      <Box
        sx={{
          margin: "48px 140px 28px 100px",
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            columnGap: 12,
          }}
        >
          <SumItem
            sum={cardData.sum.successAmount}
            name="Total Funded Goals"
            success
          />
          <SumItem
            sum={cardData.sum.failedAmount}
            name="Total Unfunded Goals"
          />
        </Box>
        <SimpleBarStyled>
          <Box
            sx={{
              flex: "1 1 auto",
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              rowGap: "12px",
              mt: 3,
              mb: 3,
              mr: 2,
              padding: "12px 0",
              overflowY: "auto",
            }}
          >
            {cardData.rowsData.map((row) => (
              <Box
                key={row.title}
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="par02Regular"
                  sx={{
                    width: "25%",
                    lineHeight: "16px",
                    marginRight: "28px",
                    textAlign: "right",
                  }}
                >
                  {row.title}
                </Typography>
                <Box
                  sx={{
                    width: "75%",
                    height: 32,
                    flex: "1 1 auto",
                    display: "flex",
                    flexWrap: "nowrap",
                    borderRadius: "5px",
                    position: "relative",
                    color: "white",
                    whiteSpace: "nowrap",
                    "&::before": {
                      content: "''",
                      width: 20,
                      height: 4,
                      position: "absolute",
                      left: -20,
                      top: 15,
                      border: "solid 2px",
                      borderColor: "gray7",
                    },
                    "&::after": {
                      content: "''",
                      width: 4,
                      height: 32 + 24,
                      position: "absolute",
                      left: -4,
                      top: -12,
                      border: "solid 2px",
                      borderColor: "gray7",
                    },
                  }}
                >
                  {row.sum.successAmount > 0 && (
                    <PercentBlock
                      row={row}
                      funded
                      full={row.sum.failedAmount === 0}
                    />
                  )}
                  {row.sum.failedAmount > 0 && (
                    <PercentBlock
                      row={row}
                      full={row.sum.successAmount === 0}
                    />
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </SimpleBarStyled>
        <Box sx={{ display: "flex", justifyContent: "center", columnGap: 12 }}>
          <LegendItem pass />
          <LegendItem />
        </Box>
      </Box>
    </Box>
  );
}
