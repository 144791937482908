import { Typography } from "@mui/material";
import { CarDisclosureContentCode, useDisclosures } from "app/useDisclosures";
import {
  FlatPageRouteControl,
  FlatPageRouteTab,
} from "components/FlatPageRouteControl";
import { CarMarkdown } from "components/Markdown";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useMemo } from "react";

const disclosurePages = {
  allocation: "allocation",
  planning: "planning",
  glossary: "glossary",
  termsOfUse: "terms-of-use",
};

export const SettingsDisclosures = () => {
  const { getContent, isLoading } = useDisclosures();

  const tabs = useMemo<FlatPageRouteTab[]>(() => {
    return [
      {
        url: disclosurePages.allocation,
        label: "Allocation",
        content: (
          <CarMarkdown>
            {getContent(
              CarDisclosureContentCode.ASSET_ALLOCATION_COMPREHENSIVE_DISCLOSURE_MAIN_part_10,
            )}
          </CarMarkdown>
        ),
      },
      {
        url: disclosurePages.planning,
        label: "Planning",
        content: (
          <CarMarkdown>
            {getContent(
              CarDisclosureContentCode.PLANNING_COMPREHENSIVE_DISCLOSURE_MAIN_part_10,
            )}
          </CarMarkdown>
        ),
      },
      {
        url: disclosurePages.glossary,
        label: "Glossary",
        content: (
          <CarMarkdown>
            {getContent(CarDisclosureContentCode.GLOSSARY_part_10)}
          </CarMarkdown>
        ),
      },
      {
        url: disclosurePages.termsOfUse,
        label: "Terms of Use",
        content: (
          <CarMarkdown>
            {getContent(CarDisclosureContentCode.TERMS_OF_USE_MAIN_part_10)}
          </CarMarkdown>
        ),
      },
    ];
  }, [getContent]);

  return (
    <TablePlaceHolder sx={{ mt: 5 }} isLoading={isLoading} rows={8}>
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Disclosures
      </Typography>
      <FlatPageRouteControl
        tabs={tabs}
        sx={{
          ".content": {
            backgroundColor: "white",
            py: 5.5,
            px: 6.5,
          },
        }}
      />
    </TablePlaceHolder>
  );
};
