import { CarPortfolioShowConfig } from "../usePortfolioGroupVersionData";
import { useLocalStorage } from "features/general/useLocalStorage";
import { useMemo } from "react";
import { useOptimizationJobs } from "./useOptimizationJobs";

interface UseShowConfigSelectorParams {
  portfolioGroupId: string;
  portfolioGroupVersionId?: string;
  publishedVersionId?: string;
  isSinglePortfolioMode?: boolean;
}

export const saveConfigInStorage = (params: {
  portfolioGroupId: string;
  config: CarPortfolioShowConfig;
}) =>
  localStorage.setItem(
    `useShowConfigSelector_${params.portfolioGroupId}_showConfig`,
    JSON.stringify(params.config),
  );

export const useShowConfigSelector = (params: UseShowConfigSelectorParams) => {
  const maxVisibleDataCount = params.isSinglePortfolioMode ? 10 : 4;
  const [showConfig, setShowConfig] = useLocalStorage<CarPortfolioShowConfig>(
    `useShowConfigSelector_${params.portfolioGroupId}_showConfig`,
    {
      existing: !!params.publishedVersionId,
      benchmark: true,
      draft: true,
      selected: false,
      optimizationIds: [],
    },
  );
  const optimizationJobs = useOptimizationJobs({
    portfolioGroupVersionId: params.portfolioGroupVersionId,
  });

  const runningJobs = optimizationJobs.items.filter((job) => job.isRunning);
  const completedJobs = optimizationJobs.items.filter(
    (job) => !job.isRunning && !job.allErrors,
  );

  const allJobs = [...completedJobs, ...runningJobs];

  const showHideConfigData = (key: "existing" | "draft" | "benchmark") => {
    setShowConfig((oldValue) => {
      const isVisible = oldValue[key];

      const optimizationIds =
        oldValue.optimizationIds?.filter((id) =>
          allJobs.some((job) => job.id === id),
        ) ?? [];

      if (isVisible) {
        return { ...oldValue, [key]: false, optimizationIds };
      }

      const newValue = { ...oldValue, [key]: true, optimizationIds };

      let visibleDataCount =
        (newValue.existing ? 1 : 0) +
        (newValue.draft ? 1 : 0) +
        (newValue.benchmark ? 1 : 0) +
        newValue.optimizationIds.length;

      while (visibleDataCount > maxVisibleDataCount) {
        --visibleDataCount;
        newValue.optimizationIds.shift();
      }

      return newValue;
    });
  };

  const showHideConfigOptData = (jobId: string) => {
    setShowConfig((oldValue) => {
      const optimizationIds =
        oldValue.optimizationIds?.filter((id) =>
          allJobs.some((job) => job.id === id),
        ) ?? [];

      const isVisible = optimizationIds.includes(jobId);

      if (isVisible) {
        return {
          ...oldValue,
          optimizationIds: optimizationIds.filter((i) => i !== jobId),
        };
      }

      const newValue = {
        ...oldValue,
        optimizationIds: [...optimizationIds, jobId],
      };

      let visibleDataCount =
        (newValue.existing ? 1 : 0) +
        (newValue.draft ? 1 : 0) +
        (newValue.benchmark ? 1 : 0) +
        newValue.optimizationIds.length;
      while (visibleDataCount > maxVisibleDataCount) {
        --visibleDataCount;
        newValue.optimizationIds.shift();
      }
      return newValue;
    });
  };

  const normalizedConfig = useMemo(
    () => ({
      ...showConfig,
      existing: params.publishedVersionId ? showConfig.existing : false,
    }),
    [showConfig, params.publishedVersionId],
  );

  return {
    isLoading: optimizationJobs.isLoading,
    config: normalizedConfig,
    canShowExisting: !!params.publishedVersionId,
    setShowConfig,
    showHideConfigData,
    showHideConfigOptData,
    completedJobs,
    runningJobs,
  };
};

export type UseShowConfigSelector = ReturnType<typeof useShowConfigSelector>;
