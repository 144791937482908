import { MenuItem } from "@mui/material";
import { CarSelectField, CarSelectFieldProps } from "components/Inputs";

interface SelectAssetColumnFieldProps extends CarSelectFieldProps {
  items: Array<{ label: string; value: string }>;
}

export const SelectAssetColumnField = ({
  items,
  sx,
  ...props
}: SelectAssetColumnFieldProps) => (
  <CarSelectField
    {...props}
    sx={{
      "& .MuiOutlinedInput-root": {
        backgroundColor: "white",
      },
      ...sx,
    }}
  >
    {items.map((i) => (
      <MenuItem key={i.value} value={i.value}>
        {i.label}
      </MenuItem>
    ))}
  </CarSelectField>
);
