import { Typography, TypographyProps } from "@mui/material";
import { formatCurrency } from "utils";

export interface CarTotalLabelProps extends TypographyProps {
  label: string;
  value: number;
}

export const CarTotalLabel = ({
  label,
  value,
  ...props
}: CarTotalLabelProps) => (
  <Typography {...props}>
    {`${label}:\u00A0\u00A0${formatCurrency(value)}`}
  </Typography>
);
