import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarButton, CarDownloadButton } from "components/Buttons";
import { BaseDialog } from "components/BaseDialog";
import { useSimulationDataExplorer } from "./useSimulationDataExplorer";
import { SimulationDataExplorerChart } from "./SimulationDataExplorerChart";
import { SimulationDataExplorerFilter } from "./SimulationDataExplorerFilter";
import { SimulationDataExplorerTable } from "./SimulationDataExplorerTable";
import { useEffect } from "react";
import { SimulationDataExplorerDisplay } from "./SimulationDataExplorerDisplay";
import { ComparisonType } from "./useSimulationDataExplorerInput";
import { CarSelectOptionField } from "components/Inputs";
import { CarSwitch } from "components/Switch";
import { CarIconShare } from "icons";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

interface SimulationDataExplorerDialogProps {
  title: string;
  subTitle: string;
  simulationJobId: string;
  assetClassCode?: string;
  filterDataType?: string;
  filterYear?: number;
  filterValueGt?: number;
  filterValueLt?: number;
  onApply: () => void;
  onCancel: () => void;
}

export const SimulationDataExplorerDialog = (
  props: SimulationDataExplorerDialogProps,
) => {
  const { input, output, handleCopyUrl } = useSimulationDataExplorer({
    simulationJobId: props.simulationJobId,
    defaultInput: {
      assetClassCode: props.assetClassCode,
      filterDataType: props.filterDataType,
      comparison: ComparisonType.between,
      filterValueGt: props.filterValueGt,
      filterValueLt: props.filterValueLt,
      filterYear: props.filterYear,
    },
  });

  const pageContent = usePageContent();

  useEffect(() => {
    if (!input.isLoading) {
      input.handleRun();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.isLoading, input.input]);

  return (
    <BaseDialog
      sx={{ width: "100vw", maxWidth: "100vw", height: "100vh" }}
      onClose={props.onCancel}
      actions={null}
    >
      <Typography variant="h2SSemiBold">{props.title}</Typography>
      <Typography variant="h3SSemiBold" sx={{ fontWeight: 400 }}>
        {props.subTitle} 1
      </Typography>
      <TablePlaceHolder
        isLoading={input.isLoading || pageContent.isLoading}
        rows={8}
        sx={{ mt: 4 }}
      >
        <CarPageContent
          sx={{ mt: 3 }}
          content={pageContent.getContent(
            CarPageContentCode.SIMULATION_ANALYSIS_DATA_EXPLORER_FILTER_LB,
          )}
        />
        <SimulationDataExplorerFilter
          sx={{ mt: 4 }}
          simulationDataExplorerInput={input}
          isReadOnly
        />
        <SimulationDataExplorerDisplay
          sx={{ mt: 2 }}
          output={output}
          isWhiteContext
        />
        <TablePlaceHolder sx={{ mt: 5 }} isLoading={output.isLoading} rows={8}>
          {output.table ? (
            <>
              <Box
                sx={{
                  mt: 5,
                  backgroundColor: "gray1",
                  border: "1px solid",
                  borderColor: "gray3",
                  display: "flex",
                  flexDirection: "column",
                  p: 5,
                }}
              >
                <SimulationDataExplorerChart output={output} />
              </Box>
              <Box
                sx={{
                  mt: 5,
                  backgroundColor: "gray1",
                  border: "1px solid",
                  borderColor: "gray3",
                  display: "flex",
                  flexDirection: "column",
                  p: 5,
                  pt: 2.5,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <CarButton
                    sx={{
                      height: 32,
                      width: 90,
                      fontSize: "13px",
                      fontWeight: 600,
                    }}
                    variant="outlined"
                    onClick={output.handleClearSelection}
                    size="small"
                  >
                    Clear All
                  </CarButton>
                  <Box
                    sx={{
                      ml: 4,
                      mb: 0.5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      gap: 1,
                    }}
                  >
                    <Typography variant="par01Regular">View All</Typography>
                    <CarSwitch
                      size="small"
                      checked={output.presentation.viewSelected}
                      onChange={(e, checked) =>
                        output.handleViewSelectedChange(checked)
                      }
                    />
                    <Typography variant="par01Regular">
                      View Selected
                    </Typography>
                  </Box>
                  <Typography
                    variant="par02SemiBold"
                    sx={{
                      alignSelf: "center",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translateX(-50%) translateY(-50%)",
                    }}
                  >
                    Years
                  </Typography>
                  <CarDownloadButton
                    sx={{ ml: "auto" }}
                    onClick={output.handleDownloadSelected}
                  >
                    Download Reports
                  </CarDownloadButton>
                  <CarDownloadButton
                    startIcon={<CarIconShare color="primary" />}
                    onClick={handleCopyUrl}
                  >
                    Copy Url
                  </CarDownloadButton>
                  <CarSelectOptionField
                    sx={{
                      ml: 2,
                      width: 130,
                      ".MuiSelect-select": {
                        backgroundColor: "white",
                      },
                    }}
                    label="Show"
                    options={output.itemsPerPageSelectorOptions}
                    value={output.presentation.itemsPerPage}
                    onChange={output.handleItemsPerPageChange}
                  />
                </Box>
                <SimulationDataExplorerTable
                  sx={{
                    mt: 2.5,
                    maxHeight: "calc(100vh - 210px)",
                  }}
                  output={output}
                />
              </Box>
            </>
          ) : (
            <Box
              sx={{
                mt: 5,
                flex: "auto",
                backgroundColor: "gray1",
                border: "1px solid",
                borderColor: "gray3",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="par02Regular">
                No data loaded yet.
              </Typography>
            </Box>
          )}
        </TablePlaceHolder>
      </TablePlaceHolder>
    </BaseDialog>
  );
};
