import ReactPDF, { G, Page, Path, Svg } from "@react-pdf/renderer";
import { PropsWithChildren } from "react";
import { pdfLetterSize } from "pdfUtils";
import { CarPdfBox } from "./PdfBox";
import { CarPdfTypography } from "./PdfTypography";
import { useTheme } from "@mui/material";

interface CarPdfPageFooterProps {
  hideStripe?: boolean;
  footerBottom?: number;
  footerDisclosures: string;
  onRenderPageNumber?: (pageNumber: number) => void;
}

const CarPdfPageFooter = (props: CarPdfPageFooterProps) => {
  const theme = useTheme();
  return (
    <CarPdfBox
      fixed
      // debug
      style={{
        position: "absolute",
        bottom: props.footerBottom ?? 28,
        left: 47,
        right: 47,
      }}
    >
      {!props.hideStripe && (
        <CarPdfBox
          style={{
            backgroundColor: theme.palette.primary.main,
            height: 2,
            marginBottom: 6,
          }}
        />
      )}
      {props.footerDisclosures && (
        <CarPdfTypography variant="disclosures" style={{ textAlign: "center" }}>
          {props.footerDisclosures}
        </CarPdfTypography>
      )}
      <CarPdfTypography
        variant="pageNumber"
        style={{ marginTop: 10, width: "100%", textAlign: "center" }}
        render={({ pageNumber }) => {
          props.onRenderPageNumber?.(pageNumber);
          return `Page ${pageNumber}`;
        }}
      />
    </CarPdfBox>
  );
};

export interface CarPdfPageProps
  extends PropsWithChildren<Omit<ReactPDF.PageProps, "size" | "dpi">> {
  hideFooter?: boolean;
  hideStripe?: boolean;
  footerBottom?: number;
  footerDisclosures: string;
  isDraft?: boolean;
  onRenderPageNumber?: (pageNumber: number) => void;
}

export const CarPdfPage = ({
  children,
  style,
  footerDisclosures,
  hideFooter,
  hideStripe,
  footerBottom,
  isDraft,
  onRenderPageNumber,
  ...props
}: CarPdfPageProps) => (
  <Page
    size={pdfLetterSize}
    style={{
      paddingTop: 47,
      paddingLeft: 47,
      paddingRight: 47,
      paddingBottom: hideFooter ? 47 : 100,
      ...style,
    }}
    {...props}
  >
    {children}
    {!hideFooter && (
      <CarPdfPageFooter
        hideStripe={hideStripe}
        footerBottom={footerBottom}
        footerDisclosures={footerDisclosures}
        onRenderPageNumber={onRenderPageNumber}
      />
    )}
    {isDraft && (
      <Svg
        viewBox="0 0 210 297"
        style={{
          position: "absolute",
          left: 0,
          top: -30,
          height: "100vh",
          width: "100vw",
        }}
        fixed
      >
        <G
          transform="matrix(0.60508539,-0.86607874,0.86607874,0.60508539,-1.5095329,-7.8027002)"
          opacity="0.075"
          fill="rgb(0,30,60)"
        >
          <Path d="m -165.9403,197.30228 v -48.7839 h 15.01043 q 6.43304,0 11.49236,2.91498 5.09282,2.88146 7.94078,8.24233 2.84796,5.32736 2.84796,12.12896 v 2.24486 q 0,6.8016 -2.81445,12.09546 -2.78095,5.29386 -7.87378,8.20883 -5.09282,2.91497 -11.49236,2.94848 z m 10.05163,-40.64208 v 32.56727 h 4.85828 q 5.89696,0 9.01296,-3.85312 3.116,-3.85313 3.18302,-11.02329 v -2.57991 q 0,-7.43821 -3.0825,-11.25783 -3.0825,-3.85312 -9.01296,-3.85312 z" />
          <Path d="m -103.28516,179.44389 h -8.0078 v 17.85839 h -10.05163 v -48.7839 h 18.12644 q 8.644396,0 13.335155,3.85313 4.690758,3.85312 4.690758,10.88926 0,4.99231 -2.177852,8.34285 -2.144347,3.31704 -6.533557,5.29386 l 10.554208,19.93572 v 0.46908 h -10.788746 z m -8.0078,-8.14182 h 8.10831 q 3.786115,0 5.863451,-1.90981 2.077336,-1.94331 2.077336,-5.32736 0,-3.45106 -1.97682,-5.42788 -1.943314,-1.97682 -5.997467,-1.97682 h -8.07481 z" />
          <Path d="m -50.011546,187.25065 h -17.623851 l -3.350542,10.05163 h -10.68823 l 18.159939,-48.7839 h 9.314507 l 18.260454,48.7839 h -10.688229 z m -14.909912,-8.14182 h 12.195973 l -6.131492,-18.26045 z" />
          <Path d="M -2.0317818,177.36655 H -21.330904 v 19.93573 h -10.051627 v -48.7839 H 0.38060851 v 8.14182 H -21.330904 v 12.59804 h 19.2991222 z" />
          <Path d="M 43.602594,156.6602 H 28.659177 v 40.64208 H 18.60755 V 156.6602 H 3.865165 v -8.14182 h 39.737429 z" />
        </G>
      </Svg>
    )}
  </Page>
);
