import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

const initialState = {
  isOpened: false,
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    openDrawer: (state, action: PayloadAction<undefined>) => {
      state.isOpened = true;
    },
    closeDrawer: (state, action: PayloadAction<undefined>) => {
      state.isOpened = false;
    },
  },
});

export const { openDrawer, closeDrawer } = drawerSlice.actions;

export const selectDrawerIsOpened = (state: RootState) => state.drawer.isOpened;

export const drawerReducer = drawerSlice.reducer;
