import { CarUser } from "types";

declare const window: any;

export const startPendo = (user: CarUser) => {
  if (window.pendo && window.pendo.initialize) {
    window.pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        full_name: user.first_name + " " + user.last_name,
      },

      account: {
        id: user.firm?.id,
        name: user.firm?.name,
      },
    });
  }
};

export const shutdownPendo = () => {
  if (window.pendo && window.pendo.clearSession) {
    window.pendo.clearSession();
  }
};

export const createPendoClassName = (id: string) => `pendo-${id}`;

const labelToClassName = (label: string) =>
  label.replace(/[^a-z0-9]/g, (s) => {
    const charCode = s.charCodeAt(0);
    if (charCode === 32) {
      return "-";
    }
    if (charCode >= 65 && charCode <= 90) {
      return "_" + s.toLowerCase();
    }
    return `__${("000" + charCode.toString(16)).slice(-4)}`;
  });

const labelToCode = (label: string) =>
  label
    .trim()
    .toLocaleLowerCase()
    .replace(/[^a-z0-9]/g, "_");

// todo migrate all pendo class names here
export const pendoClasses = {
  assetClassMonthlyReport: (id: string) =>
    createPendoClassName(`assetClassMonthlyReport_${id}`),
  assetClassMonthlyReportAC: (code: string) =>
    createPendoClassName(`assetClassMonthlyReportAC_${code}`),

  simulationBasedRiskReport: (code?: string) =>
    createPendoClassName(`simulationBasedRiskReport_${code}`),
  historicalRiskReport: (code?: string) =>
    createPendoClassName(`historicalRiskReport_${code}`),

  analyzePortfolioGroupReportsSummary: createPendoClassName(
    "analyzePortfolioGroupReportsSummary",
  ),

  role: (code?: string) => createPendoClassName(`role_${code}`),

  analyzePortfolioGroupAllocationPerModuleReportChart: (moduleIndex: number) =>
    createPendoClassName(
      `module_${moduleIndex}_asset_allocation_per_module_report_chart`,
    ),

  analyzePortfolioGroupReportsModuleOneYearUnderPerfChart: (
    moduleIndex: number,
  ) =>
    createPendoClassName(
      `module_${moduleIndex}_one_year_under_performance_chart`,
    ),

  analyzePortfolioGroupReportsModuleThreeYearUnderPerfChart: (
    moduleIndex: number,
  ) =>
    createPendoClassName(
      `module_${moduleIndex}_three_year_under_performance_chart`,
    ),

  analyzePortfolioGroupReportsModuleCumulativeRelativePerfChart: (
    moduleIndex: number,
  ) =>
    createPendoClassName(
      `module_${moduleIndex}_cumulative_relative_performance_chart`,
    ),

  // begin todo apply
  analyzePortfolioGroupReportsModuleMultiPeriodReturnComparisonChart: (
    moduleIndex: number,
  ) =>
    createPendoClassName(
      `module_${moduleIndex}_multi_period_return_comparison_chart`,
    ),
  // end todo apply

  assetClassAnalysisOneYearUnderPerformance:
    "asset_class_analysis_one_year_under_performance",

  assetClassAnalysisThreeYearUnderPerformance:
    "asset_class_analysis_three_year_under_performance",

  assetClassAnalysisCumulativeRelativePerformance:
    "asset_class_analysis_cumulative_relative_performance",

  analyzePortfolioGroupEditCompareStatSection: (code: string) =>
    createPendoClassName(`analyzePortfolioGroupEditCompareStatSection_${code}`),

  // addPortfolioButton: createPendoClassName("addPortfolioButton"), // todo remove?

  portfolioGroupNameAddButton: createPendoClassName(
    "portfolioGroupNameAddButton",
  ),
  assetClassSelectionNextButton: createPendoClassName(
    "assetClassSelectionNextButton",
  ),

  // Asset Class Analysis Dashboard
  assetClassAnalysisDashboardOvervalued: createPendoClassName(
    "assetClassAnalysisDashboardOvervalued",
  ),
  assetClassAnalysisDashboardUndervalued: createPendoClassName(
    "assetClassAnalysisDashboardUndervalued",
  ),

  assetClassAnalysisDftSingleCompare: createPendoClassName(
    "assetClassAnalysisDftSingleCompare",
  ),

  assetClassAnalysisDftStartingDft: createPendoClassName(
    "assetClassAnalysisDftStartingDft",
  ),

  assetClassAnalysisDftEndingDft: createPendoClassName(
    "assetClassAnalysisDftEndingDft",
  ),

  assetClassAnalysisDftPriceDrivenExpectedReturn: createPendoClassName(
    "assetClassAnalysisDftPriceDrivenExpectedReturn",
  ),

  assetClassAnalysisDftStaticExpectedReturnTrendLineSlope: createPendoClassName(
    "assetClassAnalysisDftStaticExpectedReturnTrendLineSlope",
  ),

  assetClassAnalysisDftHistoricLine: createPendoClassName(
    "assetClassAnalysisDftHistoricLine",
  ),

  assetClassAnalysisDftValueAddedMonthlyIndex: createPendoClassName(
    "assetClassAnalysisDftValueAddedMonthlyIndex",
  ),

  assetClassAnalysisDftAssetClassTotalReturns: createPendoClassName(
    "assetClassAnalysisDftAssetClassTotalReturns",
  ),

  assetClassAnalysisDftCompoundAnnualReturn: createPendoClassName(
    "assetClassAnalysisDftCompoundAnnualReturn",
  ),

  assetClassAnalysisDftRealTenYearReturn: createPendoClassName(
    "assetClassAnalysisDftRealTenYearReturn",
  ),

  assetClassAnalysisDftExpectedReturnTrendLine: createPendoClassName(
    "assetClassAnalysisDftExpectedReturnTrendLine",
  ),

  assetClassAnalysisDftUpperAndLowerBounds: createPendoClassName(
    "assetClassAnalysisDftUpperAndLowerBounds",
  ),

  assetClassAnalysisDftAssetClassTenYearRealReturn: createPendoClassName(
    "assetClassAnalysisDftAssetClassTenYearRealReturn",
  ),

  // Asset Allocation Dashboard
  assetAllocationDashboardSelectGroup: createPendoClassName(
    "assetAllocationDashboardSelectGroup",
  ),
  assetAllocationDashboardAddPortfolioGroup: createPendoClassName(
    "assetAllocationDashboardAddPortfolioGroup",
  ),
  assetAllocationDashboardActivatePortfolio: createPendoClassName(
    "assetAllocationDashboardActivatePortfolio",
  ),
  assetAllocationDashboardRevisions: createPendoClassName(
    "assetAllocationDashboardRevisions",
  ),
  assetAllocationDashboardEfficientFrontier: createPendoClassName(
    "assetAllocationDashboardEfficientFrontier",
  ),

  // Portfolio Analysis Dashboard
  portfolioAnalysisDashboardSelectModel: createPendoClassName(
    "portfolioAnalysisDashboardSelectModel",
  ),
  portfolioAnalysisDashboardAddPortfolioModel: createPendoClassName(
    "portfolioAnalysisDashboardAddPortfolioModel",
  ),
  portfolioAnalysisDashboardStyleBox: createPendoClassName(
    "portfolioAnalysisDashboardStyleBox",
  ),
  portfolioAnalysisDashboardSectorAnalysis: createPendoClassName(
    "portfolioAnalysisDashboardSectorAnalysis",
  ),

  editPortfolioPortfolioModelVariations: createPendoClassName(
    "editPortfolioPortfolioModelVariations",
  ),

  // Edit Portfolio / Asset Class Selection
  editPortfolioAssetClassSelectionAssetClassGroupings: createPendoClassName(
    "editPortfolioAssetClassSelectionAssetClassGroupings",
  ),

  // Edit Portfolio / Create Model
  editPortfolioCreateModelOptimizationType: (code: string) =>
    createPendoClassName(`editPortfolioCreateModelOptimizationType_${code}`),

  // Edit Portfolio / Edit Compare
  editPortfolioEditCompareTabAssets: createPendoClassName(
    "editPortfolioEditCompareTabAssets",
  ),

  analyzePortfolioGroupEditCompareTabStat: (code: string) =>
    createPendoClassName(`analyzePortfolioGroupEditCompareTabStat_${code}`),

  editPortfolioEditCompareTabAssetClassStatistics: createPendoClassName(
    "editPortfolioEditCompareTabAssetClassStatistics",
  ),

  editPortfolioEditCompareShowInputOnly: createPendoClassName(
    "editPortfolioEditCompareShowInputOnly",
  ),

  editPortfolioEditCompareDefineBenchmark: createPendoClassName(
    "editPortfolioEditCompareDefineBenchmark",
  ),

  editPortfolioEditCompareHideEmptyValues: createPendoClassName(
    "editPortfolioEditCompareHideEmptyValues",
  ),

  // Edit Portfolio / Optimization
  editPortfolioOptimizationLoadPreviousOptimization: createPendoClassName(
    "editPortfolioOptimizationLoadPreviousOptimization",
  ),
  editPortfolioOptimizationViewOptimizationTypes: createPendoClassName(
    "editPortfolioOptimizationViewOptimizationTypes",
  ),

  editPortfolioOptimizationMinimumAllocation: createPendoClassName(
    "editPortfolioOptimizationMinimumAllocation",
  ),

  editPortfolioOptimizationObjectiveFunction: createPendoClassName(
    "editPortfolioOptimizationObjectiveFunction",
  ),

  editPortfolioOptimizationConstraint: (code: string) =>
    createPendoClassName(`editPortfolioOptimizationConstraint_${code}`),

  // Edit Portfolio / Analyze
  editPortfolioAnalyzeReportFocus: createPendoClassName(
    "editPortfolioAnalyzeReportFocus",
  ),

  editPortfolioAnalyzeSectorFocus: createPendoClassName(
    "editPortfolioAnalyzeSectorFocus",
  ),

  editPortfolioAnalyzeAssetClassLevel: createPendoClassName(
    "editPortfolioAnalyzeAssetClassLevel",
  ),

  editPortfolioAnalyzeData: createPendoClassName("editPortfolioAnalyzeData"),
  editPortfolioAnalyzeRealNominal: createPendoClassName(
    "editPortfolioAnalyzeRealNominal",
  ),

  editPortfolioAnalyzeTimeFrame: createPendoClassName(
    "editPortfolioAnalyzeTimeFrame",
  ),
  editPortfolioAnalyzePortfolioVariation: createPendoClassName(
    "editPortfolioAnalyzePortfolioVariation",
  ),

  editPortfolioAnalyzeSingleCompare: createPendoClassName(
    "editPortfolioAnalyzeSingleCompare",
  ),

  editPortfolioAnalyzeSelectionForPublish: createPendoClassName(
    "editPortfolioAnalyzeSelectionForPublish",
  ),

  // Edit Portfolio / Publish
  editPortfolioPublishShowInputOnly: createPendoClassName(
    "editPortfolioPublishShowInputOnly",
  ),

  editPortfolioPublishExistingBenchmark: createPendoClassName(
    "editPortfolioPublishExistingBenchmark",
  ),

  editPortfolioPublishHideEmptyValues: createPendoClassName(
    "editPortfolioPublishHideEmptyValues",
  ),

  addModuleButton: createPendoClassName("addModuleButton"),
  publishGroupButton: createPendoClassName("publishGroupButton"),

  planInputSection: (id: string) =>
    createPendoClassName(`planInputSection_${id}`),

  planInputIntegratorAccounts: createPendoClassName(
    "planInputIntegratorAccounts",
  ),
  planInputManualAccounts: createPendoClassName("planInputManualAccounts"),

  stressScenarioReports: (code: string) =>
    createPendoClassName(`stressScenarioReports_${code}`),

  workbenchSectionRisk: createPendoClassName("workbenchSectionRisk"),

  workbenchPlanResultsCarousel: (id: string) =>
    createPendoClassName(`workbenchPlanResultsCarousel_${id}`),

  workbenchPlanResultType: (code: string) =>
    createPendoClassName(`workbenchPlanResultType_${code}`),

  workbenchInflation: createPendoClassName(`workbenchInflation`),

  workbenchTabPlanResults: createPendoClassName(`workbenchTabPlanResults`),
  workbenchTabCapGains: createPendoClassName(`workbenchTabCapGains`),
  workbenchTabStressTest: createPendoClassName(`workbenchTabStressTest`),
  workbenchTabHouseholdAllocation: createPendoClassName(
    `workbenchTabHouseholdAllocation`,
  ),
  workbenchTabAllocationByAccount: createPendoClassName(
    `workbenchTabAllocationByAccount`,
  ),
  workbenchTabPublishPlan: createPendoClassName(`workbenchTabPublishPlan`),

  workbenchPlanResultsInflows: createPendoClassName(
    `workbenchPlanResultsInflows`,
  ),

  workbenchPlanResultsOutflows: createPendoClassName(
    `workbenchPlanResultsOutflows`,
  ),

  workbenchPlanResultsSurplusDeficit: createPendoClassName(
    `workbenchPlanResultsSurplusDeficit`,
  ),
  workbenchPlanResultsGoalsPassPartialFail: createPendoClassName(
    `workbenchPlanResultsGoalsPassPartialFail`,
  ),
  workbenchPlanResultsGoalsGoals: createPendoClassName(
    `workbenchPlanResultsGoalsGoals`,
  ),
  workbenchPlanResultsGoalsAnnualAmount: createPendoClassName(
    `workbenchPlanResultsGoalsAnnualAmount`,
  ),
  workbenchPlanResultsPlanScore: createPendoClassName(
    `workbenchPlanResultsPlanScore`,
  ),
  workbenchPlanResultsPassTotal: createPendoClassName(
    `workbenchPlanResultsPassTotal`,
  ),

  workbenchCapGainsCurrentPortfolio: createPendoClassName(
    `workbenchCapGainsCurrentPortfolio`,
  ),
  workbenchCapGainsCapitalGainTaxes: createPendoClassName(
    `workbenchCapGainsCapitalGainTaxes`,
  ),
  workbenchCapGainsAfterTaxPortfolioBalance: createPendoClassName(
    `workbenchCapGainsAfterTaxPortfolioBalance`,
  ),
  workbenchCapGainsPlanResults: createPendoClassName(
    `workbenchCapGainsPlanResults`,
  ),
  workbenchCapGainsSurplusDeficit: createPendoClassName(
    `workbenchCapGainsSurplusDeficit`,
  ),
  workbenchCapGainsSummaryAssetAllocation: createPendoClassName(
    `workbenchCapGainsSummaryAssetAllocation`,
  ),
  workbenchCapGainsAssetClassLevel: createPendoClassName(
    `workbenchCapGainsAssetClassLevel`,
  ),

  workbenchStressTestStressScenarios: createPendoClassName(
    `workbenchStressTestStressScenarios`,
  ),

  workbenchStressTestStressScenarioReports: createPendoClassName(
    `workbenchStressTestStressScenarioReports`,
  ),

  workbenchStressTestStressScenariosGoalsAndPriority: createPendoClassName(
    `workbenchStressTestStressScenariosGoalsAndPriority`,
  ),
  workbenchStressTestStressScenariosYears: createPendoClassName(
    `workbenchStressTestStressScenariosYears`,
  ),
  workbenchStressTestStressScenariosAnnualAmount: createPendoClassName(
    `workbenchStressTestStressScenariosAnnualAmount`,
  ),
  workbenchStressTestStressScenariosTotalAmount: createPendoClassName(
    `workbenchStressTestStressScenariosTotalAmount`,
  ),
  workbenchStressTestStressScenariosScenarioType: (scenarioType: string) =>
    createPendoClassName(
      `workbenchStressTestStressScenariosScenarioType_${scenarioType}`,
    ),

  workbenchStressTestCpi: createPendoClassName(`workbenchStressTestCpi`),
  workbenchStressTestLargeCap: createPendoClassName(
    `workbenchStressTestLargeCap`,
  ),
  workbenchStressTestTenYearTreasury: createPendoClassName(
    `workbenchStressTestTenYearTreasury`,
  ),
  workbenchStressTestBeginningPortfolio: createPendoClassName(
    `workbenchStressTestBeginningPortfolio`,
  ),
  workbenchStressTestReturnOnAssets: createPendoClassName(
    `workbenchStressTestReturnOnAssets`,
  ),
  workbenchStressTestCashInPortfolio: createPendoClassName(
    `workbenchStressTestCashInPortfolio`,
  ),
  workbenchStressTestCashOutPortfolio: createPendoClassName(
    `workbenchStressTestCashOutPortfolio`,
  ),
  workbenchStressTestEndingPortfolio: createPendoClassName(
    `workbenchStressTestEndingPortfolio`,
  ),
  workbenchStressTestTotalATIncomeWDAndCashflow: createPendoClassName(
    `workbenchStressTestTotalATIncomeWDAndCashflow`,
  ),
  workbenchStressTestRetirementSpending: createPendoClassName(
    `workbenchStressTestRetirementSpending`,
  ),
  workbenchStressTestMedicalExpenses: createPendoClassName(
    `workbenchStressTestMedicalExpenses`,
  ),
  workbenchStressTestOtherGoalsFunded: createPendoClassName(
    `workbenchStressTestOtherGoalsFunded`,
  ),
  workbenchStressTestGoalsMet: createPendoClassName(
    `workbenchStressTestGoalsMet`,
  ),
  workbenchStressTestGoalsNotMet: createPendoClassName(
    `workbenchStressTestGoalsNotMet`,
  ),
  workbenchStressTestBeginningTotalPortfolio: createPendoClassName(
    `workbenchStressTestBeginningTotalPortfolio`,
  ),
  workbenchStressTestSavings: createPendoClassName(
    `workbenchStressTestSavings`,
  ),
  workbenchStressTestNetATCashFlowIntoPortfolio: createPendoClassName(
    `workbenchStressTestNetATCashFlowIntoPortfolio`,
  ),
  workbenchStressTestGoalsFunded: createPendoClassName(
    `workbenchStressTestGoalsFunded`,
  ),
  workbenchStressTestEndingTotalPortfolio: createPendoClassName(
    `workbenchStressTestEndingTotalPortfolio`,
  ),
  workbenchStressTestRealEstate: createPendoClassName(
    `workbenchStressTestRealEstate`,
  ),
  workbenchStressTestMortgage: createPendoClassName(
    `workbenchStressTestMortgage`,
  ),
  workbenchStressTestTotalNetWorth: createPendoClassName(
    `workbenchStressTestTotalNetWorth`,
  ),
  workbenchStressTestIncomeSocialSecurityPensionEtc: createPendoClassName(
    `workbenchStressTestIncomeSocialSecurityPensionEtc`,
  ),
  workbenchStressTestTaxableDividendAndInterest: createPendoClassName(
    `workbenchStressTestTaxableDividendAndInterest`,
  ),
  workbenchStressTestTaxableWDIRA401kEtc: createPendoClassName(
    `workbenchStressTestTaxableWDIRA401kEtc`,
  ),
  workbenchStressTestCapitalGains: createPendoClassName(
    `workbenchStressTestCapitalGains`,
  ),
  workbenchStressTestGrossRentalIncome: createPendoClassName(
    `workbenchStressTestGrossRentalIncome`,
  ),
  workbenchStressTestTotalTaxableIncome: createPendoClassName(
    `workbenchStressTestTotalTaxableIncome`,
  ),
  workbenchStressTestIncomeTaxesPaid: createPendoClassName(
    `workbenchStressTestIncomeTaxesPaid`,
  ),
  workbenchStressTestCapitalGainsTaxesPaid: createPendoClassName(
    `workbenchStressTestCapitalGainsTaxesPaid`,
  ),
  workbenchStressTestEffectiveTaxRate: createPendoClassName(
    `workbenchStressTestEffectiveTaxRate`,
  ),
  workbenchStressTestREValue: createPendoClassName(
    `workbenchStressTestREValue`,
  ),
  workbenchStressTestCostBasis: createPendoClassName(
    `workbenchStressTestCostBasis`,
  ),
  workbenchStressTestAnnualMortgageBalance: createPendoClassName(
    `workbenchStressTestAnnualMortgageBalance`,
  ),
  workbenchStressTestTotalMortgagePaymentWithEscrow: createPendoClassName(
    `workbenchStressTestTotalMortgagePaymentWithEscrow`,
  ),
  workbenchStressTestRent: createPendoClassName(`workbenchStressTestRent`),
  workbenchStressTestDepreciation: createPendoClassName(
    `workbenchStressTestDepreciation`,
  ),
  workbenchStressTestOperatingExpenses: createPendoClassName(
    `workbenchStressTestOperatingExpenses`,
  ),
  workbenchStressTestNetAfterTaxRentalIncome: createPendoClassName(
    `workbenchStressTestNetAfterTaxRentalIncome`,
  ),
  workbenchStressTestCashflowNetOfDebtService: createPendoClassName(
    `workbenchStressTestCashflowNetOfDebtService`,
  ),

  workbenchHouseholdAllocationModules: createPendoClassName(
    `workbenchHouseholdAllocationModules`,
  ),

  workbenchHouseholdAllocationAmountInvested: createPendoClassName(
    `workbenchHouseholdAllocationAmountInvested`,
  ),

  workbenchHouseholdAllocationPortfolio: createPendoClassName(
    `workbenchHouseholdAllocationPortfolio`,
  ),

  workbenchHouseholdAllocationAssetClassLevel: createPendoClassName(
    `workbenchHouseholdAllocationAssetClassLevel`,
  ),

  workbenchAllocationByAccountCurrentAccountBalance: createPendoClassName(
    `workbenchAllocationByAccountCurrentAccountBalance`,
  ),

  // workbenchAllocationByAccountModules: createPendoClassName(
  //   `workbenchAllocationByAccountModules`,
  // ),

  // workbenchAllocationByAccountAmountInvested: createPendoClassName(
  //   `workbenchAllocationByAccountAmountInvested`,
  // ),

  // workbenchAllocationByAccountPortfolio: createPendoClassName(
  //   `workbenchAllocationByAccountPortfolio`,
  // ),

  workbenchAllocationByAccountTotalToFundEachGoal: createPendoClassName(
    `workbenchAllocationByAccountTotalToFundEachGoal`,
  ),

  clientSummaryClientPlans: createPendoClassName(`clientSummaryClientPlans`),

  efficientFrontierAnalysisLegend: (label: string) =>
    createPendoClassName(
      `efficientFrontierAnalysisLegend_${labelToClassName(label)}`,
    ),

  simulationModelsModels: (label: string) =>
    createPendoClassName(`simulationModelsModels_${labelToCode(label)}`),

  simulationFieldYAxis: (code: string) =>
    createPendoClassName(`simulationFieldYAxis_${code}`),

  simulationRunSettingsTrials: createPendoClassName(
    `simulationRunSettingsTrials`,
  ),
  simulationRunSettingsYearsInSimulation: createPendoClassName(
    `simulationRunSettingsYearsInSimulation`,
  ),
  simulationRunSettingsTimeframeForCalculatingCorrelationsAndVolatility:
    createPendoClassName(
      `simulationRunSettingsTimeframeForCalculatingCorrelationsAndVolatility`,
    ),
  simulationRunSettingsCapitalMarketAssumptions: createPendoClassName(
    `simulationRunSettingsCapitalMarketAssumptions`,
  ),
  simulationRunSettingsModelVersion: createPendoClassName(
    `simulationRunSettingsModelVersion`,
  ),
  simulationRunSettingsDistributions: createPendoClassName(
    `simulationRunSettingsDistributions`,
  ),
  simulationRunSettingsUseBoundaries: createPendoClassName(
    `simulationRunSettingsUseBoundaries`,
  ),
  simulationRunSettingsUseCrisisMode: createPendoClassName(
    `simulationRunSettingsUseCrisisMode`,
  ),
  simulationRunSettingsYearsBetweenCrisis: createPendoClassName(
    `simulationRunSettingsYearsBetweenCrisis`,
  ),
  simulationRunSettingsDurationOfCrisisInYears: createPendoClassName(
    `simulationRunSettingsDurationOfCrisisInYears`,
  ),
  simulationRunSettingsTargetInflationRates: createPendoClassName(
    `simulationRunSettingsTargetInflationRates`,
  ),
  simulationRunSettingsYear: createPendoClassName(`simulationRunSettingsYear`),
  simulationRunSettingsRatePercent: createPendoClassName(
    `simulationRunSettingsRatePercent`,
  ),
};
