import { BaseDialog } from "components/BaseDialog";
import { FormFieldDef, UseForm } from "./useForm";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarLoadingPromiseButton } from "components/Buttons";
import { CarNumberField } from "components/NumberField";
import { Box, styled, Typography } from "@mui/material";
import { CarSelectOptionField, CarTextField } from "components/Inputs";
import { CarCheckbox, CarCheckboxGroup } from "components/Checkbox";
import { ReactNode } from "react";
import { CarMarkdown } from "components/Markdown";

interface FormEditorDialogProps<T extends Object> {
  form: UseForm<T>;
}

const StyledRow = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  paddingTop: theme.spacing(3.5),
  paddingBottom: theme.spacing(7),
  borderBottom: "1px solid",
  borderBottomColor: theme.palette.gray3,
  "&:last-of-type": {
    borderBottomWidth: 0,
  },
}));

const EditorRow = (props: {
  def: FormFieldDef<any>;
  children: ReactNode;
  fullWidth?: boolean;
}) => {
  return (
    <StyledRow>
      {props.def.type !== "boolean" && (
        <Typography
          variant="par02SemiBold"
          sx={{ width: 135 }}
        >{`${props.def.label}:`}</Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          flex: props.fullWidth ? "auto" : undefined,
        }}
      >
        {props.children}
        <Typography
          sx={{
            fontSize: "12px",
            color: "gray7",
            position: "absolute",
            top: "calc(100% + 4px)",
            left: props.def.type === "boolean" ? 8 : 0,
            whiteSpace: "nowrap",
            overflow: "visible",
          }}
          variant="par01Regular"
        >
          {props.def.description}
        </Typography>
      </Box>
    </StyledRow>
  );
};

export const FormEditorDialog = <T extends Object>({
  form,
}: FormEditorDialogProps<T>) => {
  return (
    <BaseDialog
      sx={{ width: "1200px", maxWidth: "1200px" }}
      onClose={form.handleClose}
    >
      <TablePlaceHolder isLoading={form.isLoading} rows={8}>
        <Typography variant="h2SSemiBold">
          {`${form.entityLabel}: `}
          <Box component="span" sx={{ fontWeight: 400 }}>
            {form.name}
          </Box>
        </Typography>
        <Box
          sx={{
            mt: 3,
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            mr: -1,
            pr: 1,
          }}
        >
          {form.fieldDefs.map((i) => {
            switch (i.type) {
              case "string":
                return (
                  <EditorRow key={i.key.toString()} def={i}>
                    <CarTextField
                      sx={{ width: 350 }}
                      required={i.isRequired}
                      value={form.value[i.key] as unknown as string}
                      onChange={(v) =>
                        form.onChange({ ...form.value, [i.key]: v })
                      }
                    />
                  </EditorRow>
                );
              case "memo":
                return (
                  <EditorRow key={i.key.toString()} def={i} fullWidth>
                    <CarTextField
                      fullWidth
                      required={i.isRequired}
                      multiline
                      maxRows={6}
                      rows={4}
                      value={form.value[i.key] as unknown as string}
                      onChange={(v) =>
                        form.onChange({ ...form.value, [i.key]: v })
                      }
                    />
                  </EditorRow>
                );
              case "markdown":
                return (
                  <EditorRow key={i.key.toString()} def={i} fullWidth>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "softBlack" }}
                      >
                        Editor:
                      </Typography>
                      <CarTextField
                        fullWidth
                        required={i.isRequired}
                        multiline
                        maxRows={6}
                        rows={4}
                        value={form.value[i.key] as unknown as string}
                        onChange={(v) =>
                          form.onChange({ ...form.value, [i.key]: v })
                        }
                      />
                      <Typography
                        variant="subtitle1"
                        sx={{ mt: 3, color: "softBlack" }}
                      >
                        Markdown Preview:
                      </Typography>
                      <CarMarkdown
                        sx={{
                          backgroundColor: "gray1",
                          border: "2px solid",
                          borderRadius: "4px",
                          borderColor: "gray3",
                          px: 2,
                          py: 1,
                          minHeight: 100,
                        }}
                      >
                        {form.value[i.key] as unknown as string}
                      </CarMarkdown>
                    </Box>
                  </EditorRow>
                );
              case "integer":
                return (
                  <EditorRow key={i.key.toString()} def={i}>
                    <CarNumberField
                      sx={{ width: 100 }}
                      required={i.isRequired}
                      value={form.value[i.key] as unknown as number}
                      onChange={(v) =>
                        form.onChange({ ...form.value, [i.key]: v })
                      }
                    />
                  </EditorRow>
                );
              case "boolean":
                return (
                  <EditorRow key={i.key.toString()} def={i}>
                    <CarCheckbox
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontWeight: 600,
                        },
                      }}
                      label={i.label}
                      required={i.isRequired}
                      checked={form.value[i.key] as unknown as boolean}
                      onChange={(e, v) =>
                        form.onChange({ ...form.value, [i.key]: v })
                      }
                    />
                  </EditorRow>
                );
              case "select":
                return (
                  <EditorRow key={i.key.toString()} def={i}>
                    <CarSelectOptionField
                      sx={{ width: 350 }}
                      options={i.options ?? []}
                      required={i.isRequired}
                      value={form.value[i.key] as unknown as string}
                      onChange={(v) =>
                        form.onChange({ ...form.value, [i.key]: v })
                      }
                    />
                  </EditorRow>
                );
              case "multiselect":
                return (
                  <EditorRow key={i.key.toString()} def={i} fullWidth>
                    <CarCheckboxGroup
                      sx={{
                        width: "100%",
                        border: "2px solid",
                        borderRadius: "5px",
                        borderColor: "gray3",
                        backgroundColor: "gray1",
                        p: 2,
                        // maxHeight: 300,
                        // overflowY: "auto",
                        ".MuiFormGroup-root": {
                          marginLeft: 0,
                          marginTop: 0,
                        },
                        ".MuiTypography-par02Regular": {
                          fontSize: "14px",
                          color: "black",
                        },
                      }}
                      showCheckAll
                      items={
                        i.options?.map((ch) => ({
                          id: String(ch.value),
                          label: ch.label,
                        })) ?? []
                      }
                      checkedItems={
                        (form.value[i.key] ?? []) as unknown as string[]
                      }
                      onChange={(v) =>
                        form.onChange({ ...form.value, [i.key]: v })
                      }
                    />
                  </EditorRow>
                );
              default:
                return <></>;
            }
          })}
        </Box>
        <Box sx={{ display: "flex", gap: 5, mt: 1 }}>
          <CarLoadingPromiseButton
            sx={{ width: 150 }}
            onClick={form.handleSave}
          >
            Save
          </CarLoadingPromiseButton>
          <CarLoadingPromiseButton
            sx={{ width: 210 }}
            variant="outlined"
            onClick={form.handleSaveAndAdd}
          >
            Save & Add Another
          </CarLoadingPromiseButton>
          <CarLoadingPromiseButton
            sx={{ width: 210 }}
            variant="outlined"
            onClick={form.handleSaveAndKeep}
          >
            Save & Keep Editing
          </CarLoadingPromiseButton>
          {/* <CarLoadingPromiseButton
            variant="contained"
            color="error"
            sx={{ ml: "auto", width: 150 }}
            onClick={form.handleDelete}
          >
            Delete
          </CarLoadingPromiseButton> */}
        </Box>
      </TablePlaceHolder>
    </BaseDialog>
  );
};
