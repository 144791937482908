import {
  useDataProvidersStyleBoxListQuery,
  usePortfolioBenchmarkEquityStyleBoxHeatmapListQuery,
  usePortfolioPortfolioGroupDestroyMutation,
  usePortfolioPortfolioGroupPartialUpdateMutation,
  usePortfolioPortfolioModuleDataEquityStyleBoxWeightsListQuery,
} from "api/carApi.generated";
import { useDialog } from "app/useDialog";
import { RenameDialog } from "components/RenameDialog";
import { DeleteItemDialog } from "components/DeleteItemDialog";
import { useSuccessToast } from "app/useSuccessToast";
import { usePortfolioGroupInfo } from "pages/assetAllocation/usePortfolioGroupInfo";
import {
  CarPortfolioAssetColumnEnum,
  usePortfolioGroupVersionData,
} from "pages/assetAllocation/usePortfolioGroupVersionData";
import { useNavigate } from "react-router-dom";
import { portfolioAnalysisPages } from "route.const";
import { checkDefined, roundTo } from "utils";
import { usePortfolioGroupVersionSectorSummary } from "pages/assetAllocation/usePortfolioGroupVersionSectorSummary";

export interface CarStyleBoxItem {
  id: string;
  code: string;
  name: string;
  description: string;
  location: number;
  weight: number;
}

export const usePortfolioSummary = (portfolioGroupId?: string) => {
  const [updateRel] = usePortfolioPortfolioGroupPartialUpdateMutation();
  const [deleteRel] = usePortfolioPortfolioGroupDestroyMutation();
  const dialog = useDialog();
  const toast = useSuccessToast();
  const navigate = useNavigate();

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const modulesData = usePortfolioGroupVersionData({
    publishedVersionId: groupInfo.publishedVersionId,
    versionId: groupInfo.draftVersionId,
    show: {
      draft: false,
      benchmark: true,
      existing: true,
      selected: false,
      optimizationIds: [],
    },
    hideCash: true,
  });

  const existingModuleDataId = modulesData.tableData.bands
    .at(0)
    ?.columns.find((c) => c.columnId === CarPortfolioAssetColumnEnum.existing)
    ?.dataId;

  const benchmarkModuleDataId = modulesData.tableData.bands
    .at(0)
    ?.columns.find((c) => c.columnId === CarPortfolioAssetColumnEnum.benchmark)
    ?.dataId;

  const styleBox = useDataProvidersStyleBoxListQuery();

  const styleBoxWeights =
    usePortfolioPortfolioModuleDataEquityStyleBoxWeightsListQuery(
      {
        portModuleDataId: existingModuleDataId ?? "",
      },
      {
        skip: !existingModuleDataId,
      },
    );

  const sectorSummary = usePortfolioGroupVersionSectorSummary({
    portfolioGroupId,
    data1Id: existingModuleDataId,
    data2Id: benchmarkModuleDataId,
  });

  const getStyleBoxWeightById = (id?: string) => {
    // todo ask Marc to change swagger
    const weightStr = styleBoxWeights.data?.find(
      (wi) => wi["style_box"] === id,
    )?.["weight"];

    if (weightStr) {
      return roundTo(Number.parseFloat(weightStr), 2);
    } else {
      return 0;
    }
  };

  const styleBoxHeatmap = usePortfolioBenchmarkEquityStyleBoxHeatmapListQuery(
    {
      portModuleDataId: existingModuleDataId ?? "",
      benchmarkModuleDataId: benchmarkModuleDataId ?? "",
    },
    {
      skip: !existingModuleDataId || !benchmarkModuleDataId,
    },
  );

  const getHeatmapWeightById = (id?: string) => {
    // todo ask Marc to change swagger
    const weightStr = styleBoxHeatmap.data?.find(
      (wi) => wi["style_box"] === id,
    )?.["weight"];

    if (weightStr) {
      return roundTo(Number.parseFloat(weightStr), 2);
    } else {
      return 0;
    }
  };

  const getStyleBoxItems = (valueGetter: (id?: string) => number) =>
    styleBox.data
      ?.filter((i) => i.is_equity && (i.style_box_location ?? 0) > 0)
      .map<CarStyleBoxItem>((i) => ({
        id: checkDefined(i.id),
        code: i.code ?? "",
        name: i.name ?? "",
        description: i.description ?? "",
        location: i.style_box_location ?? 0,
        weight: valueGetter(i.id),
      }))
      .sort((a, b) => a.location - b.location) ?? [];

  const styleBoxPortfolio = getStyleBoxItems(getStyleBoxWeightById);
  const styleBoxPortfolioVsBenchmark = getStyleBoxItems(getHeatmapWeightById);

  const handleRename = async () => {
    if (!portfolioGroupId) {
      return;
    }

    dialog(RenameDialog, {
      label: "Portfolio Model Name",
      value: groupInfo.title,
      applyLabel: "Rename",
      onRename: async (newTitle) => {
        return (
          "data" in
          (await updateRel({
            id: portfolioGroupId ?? "",
            patchedPortfolioGroup: {
              title: newTitle,
            },
          }))
        );
      },
    });
  };

  const handleDelete = async () => {
    if (!portfolioGroupId) {
      return;
    }

    if (
      !(await dialog(DeleteItemDialog, {
        itemName: groupInfo.title,
        itemTypeName: "portfolio model",
      }))
    ) {
      return;
    }

    const result = await deleteRel({ id: portfolioGroupId });

    if ("data" in result) {
      toast({
        kind: "success",
        message: "Success! This portfolio model has been deleted",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
  };

  const handleEdit = () => {
    if (!portfolioGroupId) {
      return;
    }
    navigate(portfolioAnalysisPages.getEditPortfolioUrl(portfolioGroupId));
  };

  return {
    isLoading:
      groupInfo.isLoading ||
      modulesData.isLoading ||
      styleBox.isLoading ||
      styleBoxWeights.isLoading ||
      styleBoxHeatmap.isLoading,
    title: groupInfo.title,
    isEmpty: false, // todo clarify if we need to show anything if we have only draft
    handleRename,
    handleDelete,
    handleEdit,
    tableData: modulesData.tableData,
    styleBoxPortfolio,
    styleBoxPortfolioVsBenchmark,

    sectorSummary: {
      report: sectorSummary.report,
      label1: groupInfo.title,
      label2: "Benchmark",
    },
  };
};

export type UsePortfolioSummary = ReturnType<typeof usePortfolioSummary>;
