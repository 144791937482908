import { Typography, styled } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import {
  BreakdownChartItem,
  usePublishPlanHouseholdAllocation,
} from "features/publishPlanDialog/usePublishPlanHouseholdAllocation";

import { Box } from "@mui/system";
import { CarTooltip } from "components/Tooltip";
import { Pie } from "react-chartjs-2";
import { formatPercent, isOddEven } from "utils";
import { Fragment, useState } from "react";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";

const StyledRoot = styled(RootPageBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "none",
  padding: theme.spacing(3.5),
}));

const Chart = (props: { items: BreakdownChartItem[]; title: string }) => {
  const [hintValue, setHintValue] = useState<BreakdownChartItem | undefined>();
  const data = props.items.filter((v) => v.percent > 0);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 1.5,
      }}
    >
      <Typography variant="par02Regular">{props.title}</Typography>
      <CarTooltip
        title={
          <>
            <Typography variant="par01SemiBold">
              {hintValue?.parentLabel ?? ""}
            </Typography>
            <Typography variant="par01Regular">
              {`${hintValue?.label ?? ""}: ${formatPercent(
                hintValue?.percent ?? 0,
              )}`}
            </Typography>
          </>
        }
        followCursor
        arrow={false}
      >
        <Box>
          <Pie
            width={142}
            height={142}
            options={{
              responsive: false,
              animation: {
                duration: 0,
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              borderColor: "transparent",
              onHover(event, elements, chart) {
                setHintValue(data[elements[0]?.index]);
              },
            }}
            data={{
              labels: data.map((i) => i.label),
              datasets: [
                {
                  label: "data",
                  data: data.map((i) => i.percent),
                  backgroundColor: data.map((i) => i.color),
                },
              ],
            }}
          />
        </Box>
      </CarTooltip>
    </Box>
  );
};

export const ProgressMonitoringStrategyAdjustment = () => {
  const { allocAssets, existingChart, proposedChart, isLoading } =
    usePublishPlanHouseholdAllocation();

  return (
    <StyledRoot>
      <TablePlaceHolder isLoading={isLoading} rows={5}>
        <Typography variant="h5SSemiBold">
          Portfolio Strategy Adjustment
        </Typography>
        <Box
          sx={{
            mt: 3.5,
            display: "flex",
            gap: 1,
            justifyContent: "space-around",
          }}
        >
          <Chart title="Existing Portfolio" items={existingChart} />
          <Chart title="New Strategy" items={proposedChart} />
        </Box>
        <CarInfoTable
          sx={{
            mt: 3.5,
            gridTemplateColumns: "1fr 1fr 1fr 1fr",
            "& > *": {
              minHeight: 40,
              fontSize: "14px",
              fontWeight: 400,
            },
          }}
        >
          <CarInfoHeader text="" />
          <CarInfoHeader text="Existing" />
          <CarInfoHeader text="Proposed" />
          <CarInfoHeader text="Difference" />
          {allocAssets.level1.map((row, rowIdx) => (
            <Fragment key={rowIdx}>
              <CarInfoCell
                isOdd={isOddEven(rowIdx)}
                justifyContent="start"
                text={row.name}
              />
              <CarInfoCell
                isOdd={isOddEven(rowIdx)}
                text={formatPercent(row.current_percent)}
              />
              <CarInfoCell
                isOdd={isOddEven(rowIdx)}
                text={formatPercent(row.proposed_percent)}
              />
              <CarInfoCell
                isOdd={isOddEven(rowIdx)}
                text={formatPercent(row.diff_percent)}
              />
            </Fragment>
          ))}
        </CarInfoTable>
      </TablePlaceHolder>
    </StyledRoot>
  );
};
