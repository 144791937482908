import {
  CarCarousel,
  CarCarouselPage,
  CarCarouselProps,
} from "components/Carousel";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { PlanResultsCardSummary } from "./PlanResultsCardSummary";
import { PlanResultCardNetWorth } from "./PlanResultCardNetWorth";
import { PlanResultCardGoalTimeline } from "./PlanResultCardGoalTimeline";
import { PlanResultCardGoalNetWorthCombo } from "./PlanResultCardGoalNetWorthCombo";
import { PlanResultCardPassFail } from "./PlanResultCardPassFail";
import { PlanResultsCardSummaryCompact } from "./PlanResultsCardSummaryCompact";
// import { PlanResultCardAccountsFunding } from "./PlanResultCardAccountsFunding"; CU-860qkzzzh

interface PlanResultsCarouselProps extends Omit<CarCarouselProps, "pages"> {
  data: UsePlanResults;
  showTabs?: boolean;
}

export const PlanResultsCarousel = ({
  data,
  showTabs,
  ...props
}: PlanResultsCarouselProps) => {
  const pages: CarCarouselPage[] = [
    {
      id: "summary",
      label: "Summary",
      render: () =>
        props.isCompact ? (
          <PlanResultsCardSummaryCompact data={data} />
        ) : (
          <PlanResultsCardSummary data={data} />
        ),
    },
    {
      id: "passFail",
      label: "Pass/Fail",
      render: () => <PlanResultCardPassFail data={data} />,
    },
    {
      id: "netWorth",
      label: "Net Worth",
      render: () => <PlanResultCardNetWorth data={data} />,
    },
    {
      id: "goalTimeline",
      label: "Goal Timeline",
      render: () => <PlanResultCardGoalTimeline data={data.barChart} />,
    },
    // {
    //   label: "Accounts Funding",
    //   render: () => <PlanResultCardAccountsFunding data={data} />,
    // },
    {
      id: "goalNetWorthCombo",
      label: "Goals/Net Worth",
      render: () => <PlanResultCardGoalNetWorthCombo data={data} />,
    },
  ];

  return <CarCarousel {...props} pages={pages} showTabs={showTabs} />;
};
