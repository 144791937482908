import { Box, Typography, useTheme } from "@mui/material";
import {
  CarPortfolioChartItem,
  CarPortfolioStatPeriod,
} from "../asset-allocation-types";
import { CarSelectChartField } from "./SelectChartField";
import { ComponentProps, useCallback, useMemo, useRef } from "react";
import { Chart } from "react-chartjs-2";
import { formatPercentFactor, isDefined } from "utils";
import {
  ChartTooltip,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { format, getYear, parseISO } from "date-fns";
import { UseChartDataSelector } from "./useChartDataSelector";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { ChartTypeRegistry } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { ChartDataset } from "chart.js";
import { Disclosures, InternalUse } from "./InternalUse";

type Options = ComponentProps<typeof Chart>["options"];

export interface OutUnderPerfChartDataPoint {
  x: string;
  y: number;
  tooltipValue?: number;
}

interface PortfolioGroupOutUnderPerfChartData {
  line1: ChartDataset<"line", OutUnderPerfChartDataPoint[]>;
  line2: ChartDataset<"line", OutUnderPerfChartDataPoint[]>;
  lineOutUnder: ChartDataset<"scatter", OutUnderPerfChartDataPoint[]>;
  minY: number;
  maxY: number;
  minY2: number;
  maxY2: number;
  y1Label: string;
  y2Label: string;
}

const y1Label = "Portfolio Data & Benchmark";
const y2Label = "Out/Under Performance";

export const getPortfolioGroupOutUnderPerfChartData = ({
  data,
  startYear,
  endYear,
  data1Id,
  data2Id,
  isRelative,
}: {
  data: CarPortfolioChartItem[];
  startYear?: number;
  endYear?: number;
  data1Id: string;
  data2Id: string;
  isRelative: boolean;
}): PortfolioGroupOutUnderPerfChartData => {
  const data1 = data.find((i) => i.id === data1Id);
  const data2 = data.find((i) => i.id === data2Id);

  if (!data1 || !data2) {
    return {
      line1: {
        data: [],
      },
      line2: {
        data: [],
      },
      lineOutUnder: {
        data: [],
      },
      minY: 0,
      maxY: 0,
      minY2: 0,
      maxY2: 0,
      y1Label,
      y2Label,
    };
  }

  const calcTooltipValue = (val1?: number, val2?: number) =>
    isDefined(val1) && isDefined(val2) ? val1 - val2 : undefined;

  const yearFilter = (value: OutUnderPerfChartDataPoint) => {
    if (!startYear || !endYear) {
      return true;
    }

    const yearValue = getYear(parseISO(value.x));
    return yearValue >= startYear && yearValue <= endYear;
  };

  const relativeMap = (
    value: OutUnderPerfChartDataPoint,
    idx: number,
    arr: OutUnderPerfChartDataPoint[],
  ) => {
    return isRelative ? { ...value, y: value.y / arr[0].y } : value; // CU-86887vw0q
  };

  const line1: ChartDataset<"line", OutUnderPerfChartDataPoint[]> = {
    type: "line",
    label: data1.label,
    data: data1.x
      .map<OutUnderPerfChartDataPoint>((x, i) => {
        const tooltipValue = data1.tooltipValues?.[i];

        return {
          x,
          y: data1.y[i],
          tooltipValue: tooltipValue ? tooltipValue : undefined,
        };
      })
      .filter(yearFilter)
      .map(relativeMap),
  };

  const line2: ChartDataset<"line", OutUnderPerfChartDataPoint[]> = {
    type: "line",
    label: data2.label,
    data: data2.x
      .map<OutUnderPerfChartDataPoint>((x, i) => {
        const tooltipValue = data2.tooltipValues?.[i];
        return {
          x,
          y: data2.y[i],
          tooltipValue: tooltipValue ? tooltipValue : undefined,
        };
      })
      .filter(yearFilter)
      .map(relativeMap),
  };

  const lineOutUnder: ChartDataset<"scatter", OutUnderPerfChartDataPoint[]> = {
    type: "scatter",
    label: "Out/Under Performance",
    data: line1.data.map((l1, idx) => ({
      x: l1.x,
      y: l1.y - line2.data[idx]?.y ?? 0,
      tooltipValue: calcTooltipValue(
        l1.tooltipValue,
        line2.data[idx]?.tooltipValue,
      ),
    })),
  };

  let minY = Math.min(
    0,
    ...line1.data.map((d) => d.y),
    ...line2.data.map((d) => d.y),
  );

  let maxY = Math.max(
    ...line1.data.map((d) => d.y),
    ...line2.data.map((d) => d.y),
  );

  let minY2 = Math.min(...lineOutUnder?.data.map((d) => d.y));
  let maxY2 = Math.max(...lineOutUnder?.data.map((d) => d.y));

  minY = Math.min(minY, minY2);
  maxY = Math.max(maxY, maxY2);

  let range = maxY - minY;

  minY = Math.min(minY, 0 - range * 0.1);

  minY2 = minY;
  maxY2 = maxY;

  return {
    line1,
    line2,
    lineOutUnder,
    minY,
    maxY,
    minY2,
    maxY2,
    y1Label,
    y2Label,
  };
};

interface ChartOutUnderDataProps {
  periods: CarPortfolioStatPeriod[];
  data: CarPortfolioChartItem[];
  startYear?: number;
  endYear?: number;
  data1Id: string;
  data2Id: string;
  isRelative: boolean;
  pendoClass: string;
  isWhiteContext?: boolean;
}

const ChartOutUnder = ({
  periods,
  data,
  startYear,
  endYear,
  data1Id,
  data2Id,
  isRelative,
  pendoClass,
  isWhiteContext,
}: ChartOutUnderDataProps) => {
  const theme = useTheme();

  const { tooltipPlugin, tooltipData } = useChartTooltip();
  const chartRef = useRef<ChartJSOrUndefined<
    keyof ChartTypeRegistry,
    {
      x: string;
      y: number;
    }[],
    unknown
  > | null>();

  const { line1, line2, lineOutUnder, minY, maxY, y1Label, y2Label } =
    getPortfolioGroupOutUnderPerfChartData({
      data,
      startYear,
      endYear,
      data1Id,
      data2Id,
      isRelative,
    });

  const renderTooltip: RenderTooltip = useCallback(
    (datasetIndex, dataIndex) => {
      const ds =
        datasetIndex === 0 ? line1 : datasetIndex === 1 ? line2 : lineOutUnder;
      const value = ds?.data?.[dataIndex]?.y ?? 0;
      const tooltipValue = ds?.data?.[dataIndex]?.tooltipValue;
      const dateStr = ds?.data?.[dataIndex]?.x;
      const date = dateStr ? format(new Date(dateStr), "yyyy MMM") : undefined;
      return (
        <>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: 600,
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
              {ds?.label}
            </Typography>
            {Boolean(date) && (
              <>
                <br />
                <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
                  {date}
                </Typography>
              </>
            )}
            <br />
            {isDefined(tooltipValue) ? (
              <>
                <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
                  Annual Performance {formatPercentFactor(value, 1)}
                </Typography>
                <br />
                <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
                  3 Year Cumulative {formatPercentFactor(tooltipValue, 1)}
                </Typography>
              </>
            ) : (
              <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
                {formatPercentFactor(value, 1)}
              </Typography>
            )}
          </Box>
        </>
      );
    },
    [line1, line2, lineOutUnder],
  );

  const options: Options = useMemo(
    () => ({
      maintainAspectRatio: false,
      animation: {
        duration: 500,
      },
      scales: {
        x: {
          // offset: true,
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
          type: "time",
          time: {
            unit: "year",
          },
        },
        y: {
          offset: true,
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 15,
              weight: "600",
            },
            color: theme.palette.softBlack,
            callback: (value) =>
              typeof value === "number" ? formatPercentFactor(value) : value,
          },
          border: { display: false },
          grid: {
            display: true,
            color: (ctx) => {
              return ctx.tick.value === 0
                ? theme.palette.gray7
                : theme.palette.gray2;
            },
            lineWidth: 1,
            tickColor: theme.palette.white,
          },
          min: minY,
          max: maxY,
        },
        y2: {
          offset: true,
          position: "right",
          border: { display: false },
          grid: {
            display: false,
          },
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 15,
              weight: "600",
            },
            color: theme.palette.softBlack,
            callback: (value) =>
              typeof value === "number" ? formatPercentFactor(value) : value,
          },
          // min: minY2,
          // max: maxY2,
        },
      },
      interaction: {
        mode: "point",
        intersect: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltipPlugin,
        roundedBackground: {
          contextColor: isWhiteContext
            ? theme.palette.white
            : theme.palette.gray1,
          backgroundColor: isWhiteContext
            ? theme.palette.gray1
            : theme.palette.white,
          borderColor: theme.palette.gray7,
          borderRadius: 5,
        },
        periods: {
          backgroundColor: "rgba(236, 238, 240, 0.5)",
          borderColor: "rgba(178, 179, 181, 0.5)",
          font: {
            family: theme.typography.fontFamily,
            size: 14,
            weight: 600,
          },
          textColor: theme.palette.softBlack,
          items: periods.map((p) => ({
            start: new Date(p.startDate).getTime(),
            end: new Date(p.endDate).getTime(),
            label: p.label,
          })),
        },
        yearTicks: {
          color: theme.palette.gray6,
          font: {
            family: theme.typography.fontFamily,
            size: 13,
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          limits: {
            x: {
              min: "original",
              max: "original",
              minRange: 5 * 365 * 24 * 60 * 60 * 1000, // max zoom level - 5 years
            },
            y: {
              min: minY,
              max: maxY,
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
        },
      },
    }),
    [minY, maxY, theme, tooltipPlugin, periods, isWhiteContext],
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: 396,
          position: "relative",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: -24,
            top: 0,
            bottom: 0,
            width: 24,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="par02Bold"
            sx={{
              transform: "rotate(-90deg)",
              flex: "1 1 auto",
              whiteSpace: "nowrap",
            }}
          >
            {y1Label}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: -24,
            top: 0,
            bottom: 0,
            width: 24,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="par02Bold"
            sx={{
              transform: "rotate(90deg)",
              flex: "1 1 auto",
              whiteSpace: "nowrap",
            }}
          >
            {y2Label}
          </Typography>
        </Box>
        <ChartTooltip tooltipData={tooltipData} renderTooltip={renderTooltip} />
        <Chart
          className={pendoClass}
          ref={chartRef}
          onDoubleClick={() => {
            chartRef.current && chartRef.current.resetZoom();
          }}
          type="line"
          datasetIdKey="id"
          data={{
            datasets:
              line1 && line2 && lineOutUnder
                ? [
                    {
                      id: "line1",
                      type: "line",
                      label: line1.label,
                      data: line1.data,
                      borderColor: theme.palette.chartsColor.purple,
                      pointBorderColor: theme.palette.chartsColor.purple,
                      pointBackgroundColor: theme.palette.chartsColor.purple,
                      pointBorderWidth: 0,
                      pointRadius: 0,
                      borderWidth: 2,
                    },
                    {
                      id: "line2",
                      type: "line",
                      label: line2.label,
                      data: line2.data,
                      borderColor: theme.palette.chartsColor.green,
                      pointBorderColor: theme.palette.chartsColor.green,
                      pointBackgroundColor: theme.palette.chartsColor.green,
                      pointBorderWidth: 0,
                      pointRadius: 0,
                      borderWidth: 2,
                    },
                    {
                      id: "lineOutUnder",
                      type: "scatter",
                      label: lineOutUnder.label,
                      data: lineOutUnder.data,
                      yAxisID: "y2",
                      borderColor: theme.palette.chartsColor.orange,
                      pointBorderColor: theme.palette.chartsColor.orange,
                      pointBackgroundColor: theme.palette.chartsColor.orange,
                      pointRadius: 2,
                      pointBorderWidth: 2,
                    },
                  ]
                : [],
          }}
          options={options}
          plugins={[
            ChartPlugins.roundedBackground,
            ChartPlugins.periods,
            ChartPlugins.yearTicks,
          ]}
        />
      </Box>
    </Box>
  );
};

export const ChartOutUnderPerformance = ({
  label,
  selectorLabel1,
  selectorLabel2,
  data,
  startYear,
  endYear,
  periods,
  chartDataSelector: { data1Id, data2Id, onData1IdChange, onData2IdChange },
  isRelative,
  pendoClass,
  isWhiteContext,
}: {
  label?: string;
  selectorLabel1: string;
  selectorLabel2: string;
  data: CarPortfolioChartItem[];
  startYear?: number;
  endYear?: number;
  periods: CarPortfolioStatPeriod[];
  chartDataSelector: UseChartDataSelector;
  isRelative: boolean;
  pendoClass: string;
  isWhiteContext?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "flex-end", px: 6 }}>
        {label && <Typography variant="h6SemiBold">{label}</Typography>}
        <InternalUse sx={{ ml: "auto", color: "black" }} />
      </Box>
      <ChartOutUnder
        periods={periods}
        data={data}
        startYear={startYear}
        endYear={endYear}
        data1Id={data1Id ?? ""}
        data2Id={data2Id ?? ""}
        isRelative={isRelative}
        pendoClass={pendoClass}
        isWhiteContext={isWhiteContext}
      />
      <Disclosures sx={{ mr: 6 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          columnGap: 3,
        }}
      >
        <CarSelectChartField
          sx={{
            alignSelf: "flex-start",
            width: 200,
            ".MuiInputBase-root": {
              backgroundColor: "white",
            },
          }}
          label={selectorLabel1}
          value={data1Id}
          onChange={onData1IdChange}
          items={data}
          chartColor={theme.palette.chartsColor.purple}
        />
        <CarSelectChartField
          sx={{
            alignSelf: "flex-start",
            width: 200,
            ".MuiInputBase-root": {
              backgroundColor: "white",
            },
          }}
          label={selectorLabel2}
          value={data2Id}
          onChange={onData2IdChange}
          items={data}
          chartColor={theme.palette.chartsColor.green}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: 1,
            mb: 1,
          }}
        >
          <Box
            sx={{
              width: 24,
              height: 24,
              borderRadius: 4,
              border: "1px solid",
              borderColor: "gray6",
              backgroundColor: theme.palette.chartsColor.orange,
            }}
          />
          <Typography variant="par01Regular">Out/Under Performance</Typography>
        </Box>
      </Box>
    </Box>
  );
};
