import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { ClientPlanRelationship } from "api/carApi.generated";
import { useRelationTypes } from "app/useRelationTypes";
import { CarButton, CarLoadingButton } from "components/Buttons";
import { CarDateField } from "components/DateField";
import { CarSuffixField, CarTextField } from "components/Inputs";
import {
  RootPage,
  RootPageHeader,
  RootPageHeaderLink,
  RootPageTransparentPaper,
} from "components/RootPage";
import { CarClient, CarSuffix } from "types";
import { useAddClient } from "./useAddClient";
import { financialPlanningPages } from "route.const";
import { CarTierField } from "components/TierField";
import { useNavigate } from "react-router-dom";

export const AddClientPage = () => {
  const navigate = useNavigate();
  const data = useAddClient();
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [suffix, setSuffix] = useState<CarSuffix | undefined>("");
  const [dob, setDob] = useState<string | undefined>();
  const [spouseFirstName, setSpouseFirstName] = useState<string | undefined>();
  const [spouseLastName, setSpouseLastName] = useState<string | undefined>();
  const [spouseSuffix, setSpouseSuffix] = useState<CarSuffix | undefined>("");
  const [spouseDob, setSpouseDob] = useState<string | undefined>();
  const [address, setAddress] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [tierId, setTierId] = useState<string | null | undefined>();
  const [notes, setNotes] = useState<string | undefined>();

  const [showErrors, setShowErrors] = useState(false);
  const { primaryTypeId, secondaryTypeId } = useRelationTypes();

  const handleCancel = () => {
    navigate(financialPlanningPages.clientListUrl);
  };

  const handleAddClient = () => {
    let primaryRelation: ClientPlanRelationship | undefined = undefined;
    let secondaryRelation: ClientPlanRelationship | undefined = undefined;

    if (!!firstName && !!lastName && !!dob) {
      primaryRelation = {
        household: undefined as any,
        first_name: firstName,
        last_name: lastName,
        suffix: (suffix as CarSuffix) || undefined,
        date_of_birth: dob,
        relation_type: primaryTypeId,
      };
    } else {
      setShowErrors(true);
      return;
    }

    // only check if any of spouse fields are set
    if (
      !!spouseFirstName ||
      !!spouseLastName ||
      !!spouseSuffix ||
      !!spouseDob
    ) {
      if (!!spouseFirstName && !!spouseLastName && !!spouseDob) {
        secondaryRelation = {
          household: undefined as any,
          first_name: spouseFirstName,
          last_name: spouseLastName,
          suffix: (spouseSuffix as CarSuffix) || undefined,
          date_of_birth: spouseDob,
          relation_type: secondaryTypeId,
        };
      } else {
        setShowErrors(true);
        return;
      }
    }

    const client: CarClient = {
      id: "",
      address,
      email: email ?? "",
      phone_num: phone ?? "",
      advisor_notes: notes ?? "",
      tier: tierId,
      status: null,
      last_reviewed: null,
      created_at: "",
      updated_at: "",
      active_relationships: [],
    };

    if (primaryRelation) {
      data.addClient({
        client,
        primaryRelation,
        secondaryRelation,
      });
    }
  };

  const spouseIsRequired = Boolean(
    showErrors &&
      (spouseFirstName || spouseLastName || spouseSuffix || spouseDob),
  );

  return (
    <RootPage sx={{ paddingBottom: 0, backgroundColor: "white" }}>
      <RootPageHeader
        isSmall
        title="Financial Planning"
        links={
          <>
            <RootPageHeaderLink
              label="Client List"
              url={financialPlanningPages.clientListUrl}
            />
            <RootPageHeaderLink
              label="Add Client"
              url={financialPlanningPages.addClientUrl}
            />
          </>
        }
      />
      <RootPageTransparentPaper>
        <Typography variant="h2SSemiBold" sx={{ mt: 3 }}>
          Add Client
        </Typography>
        <Box
          sx={{
            mt: 7,
            display: "grid",
            gridTemplateColumns: "1fr 1fr 0.5fr 0.7fr 0.8fr 1fr",
            columnGap: 7.5,
            rowGap: 7,
            maxWidth: 1230,
            "& .MuiInputLabel-root": {
              fontSize: "18px",
            },
            "& .MuiOutlinedInput-root": {
              marginTop: "24px",
              backgroundColor: "gray1",
            },
            "& .MuiOutlinedInput-input": {
              fontSize: "16px",
              lineHeight: "22px",
              height: 28,
            },
            // border: "1px solid red",
          }}
        >
          <CarTextField
            required={showErrors}
            autoFocus
            label="*Client's First Name"
            value={firstName}
            onChange={setFirstName}
          />
          <CarTextField
            required={showErrors}
            label="*Client's Last Name"
            value={lastName}
            onChange={setLastName}
          />
          <CarSuffixField
            required={showErrors}
            label="Suffix"
            value={suffix}
            onChange={setSuffix}
          />
          <CarDateField
            required={showErrors}
            label="*Date of Birth"
            value={dob}
            onChange={setDob}
          />
          <CarTextField
            label="Client Phone"
            value={phone}
            onChange={setPhone}
          />
          <CarTextField
            label="Client Email"
            value={email}
            onChange={setEmail}
          />
          <CarTextField
            required={spouseIsRequired}
            label="Spouse First Name"
            value={spouseFirstName}
            onChange={setSpouseFirstName}
          />
          <CarTextField
            required={spouseIsRequired}
            label="Spouse Last Name"
            value={spouseLastName}
            onChange={setSpouseLastName}
          />
          <CarSuffixField
            required={spouseIsRequired}
            label="Suffix"
            value={spouseSuffix}
            onChange={setSpouseSuffix}
          />
          <CarDateField
            required={spouseIsRequired}
            label="Date of Birth"
            value={spouseDob}
            onChange={setSpouseDob}
          />
          <div />
          <div />
          <CarTextField
            sx={{ gridColumn: "1 / span 2" }}
            label="Address"
            multiline
            maxRows={6}
            rows={3}
            value={address}
            onChange={setAddress}
          />
          <CarTierField label="*Tier" value={tierId} onChange={setTierId} />
          <CarTextField
            sx={{ gridColumn: "4 / span 3" }}
            label="Notes"
            multiline
            maxRows={6}
            rows={3}
            value={notes}
            onChange={setNotes}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 3.5, mt: "auto", mx: "auto" }}>
          <CarButton
            sx={{
              width: 200,
              minHeight: 63,
              fontSize: "18px",
            }}
            variant="outlined"
            onClick={handleCancel}
          >
            Cancel
          </CarButton>
          <CarLoadingButton
            sx={{
              width: 200,
              minHeight: 63,
              fontSize: "18px",
            }}
            variant="contained"
            onClick={handleAddClient}
            isLoading={data.isLoading}
          >
            Add Client
          </CarLoadingButton>
        </Box>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
