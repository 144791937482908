import { Navigate, Route, Routes } from "react-router-dom";
import { portfolioAnalysisPages } from "route.const";
import { PortfolioAnalysisDashboardPage } from "./portfolioAnalysisDashboard/PortfolioAnalysisDashboardPage";
import { EditPortfolioPage } from "./editPortfolio/EditPortfolioPage";

export const PortfolioAnalysisPage = () => {
  return (
    <Routes>
      <Route
        path={portfolioAnalysisPages.dashboard}
        element={<PortfolioAnalysisDashboardPage />}
      />
      <Route
        path={portfolioAnalysisPages.editPortfolio}
        element={<EditPortfolioPage />}
      />
      <Route
        path="*"
        element={<Navigate to={portfolioAnalysisPages.dashboard} replace />}
      />
    </Routes>
  );
};
