import ReactPDF, { Text } from "@react-pdf/renderer";
import {
  proximanovaCondensedFontFamily,
  proximanovaFontFamily,
} from "pdfUtils";

export type PdfFontVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "body1"
  | "body2"
  | "tableHeading"
  | "tableBody1"
  | "tableBody2"
  | "tableBody3"
  | "tableBody4"
  | "barGraph1"
  | "barGraph2"
  | "disclosures"
  | "internalUse"
  | "pageNumber";

interface PdfFontVariantInfo {
  variant: PdfFontVariant;
  style: {
    fontSize: number;
    fontFamily?: string;
    fontStyle?: "italic" | "normal";
    fontWeight?: "normal" | "medium" | "semibold" | "bold";
    // letterSpacing?: number | string;
    lineHeight: number;
  };
}

const variants: PdfFontVariantInfo[] = [
  {
    variant: "h1",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "semibold",
      fontSize: 40,
      lineHeight: 48.72,
    },
  },
  {
    variant: "h2",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "semibold",
      fontSize: 25,
      lineHeight: 30.45,
    },
  },
  {
    variant: "h3",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "semibold",
      fontSize: 20,
      lineHeight: 24.36,
    },
  },
  {
    variant: "h4",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "semibold",
      fontSize: 17,
      lineHeight: 20.71,
    },
  },
  {
    variant: "h5",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "semibold",
      fontSize: 15,
      lineHeight: 18.27,
    },
  },
  {
    variant: "h6",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "semibold",
      fontSize: 13,
      lineHeight: 15.83,
    },
  },
  {
    variant: "body1",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: 14.62,
    },
  },
  {
    variant: "body2",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "normal", // should be medium
      fontSize: 13,
      lineHeight: 15.83,
    },
  },
  {
    variant: "tableHeading",
    style: {
      fontFamily: proximanovaCondensedFontFamily,
      fontWeight: "bold",
      fontSize: 12,
      lineHeight: 12,
    },
  },
  {
    variant: "tableBody1",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: 12,
    },
  },
  {
    variant: "tableBody2",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "bold",
      fontSize: 12,
      lineHeight: 12,
    },
  },
  {
    variant: "tableBody3",
    style: {
      fontFamily: proximanovaCondensedFontFamily,
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: 12,
    },
  },
  {
    variant: "tableBody4",
    style: {
      fontFamily: proximanovaCondensedFontFamily,
      fontWeight: "bold",
      fontSize: 12,
      lineHeight: 12,
    },
  },
  {
    variant: "barGraph1",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "normal",
      fontSize: 12,
      lineHeight: 14.62,
    },
  },
  {
    variant: "barGraph2",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "normal",
      fontSize: 10,
      lineHeight: 12.18,
    },
  },
  {
    variant: "disclosures",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "normal",
      fontStyle: "italic",
      fontSize: 10,
      lineHeight: 12.18,
    },
  },
  {
    variant: "internalUse",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "normal",
      fontStyle: "italic",
      fontSize: 8,
      lineHeight: 8,
    },
  },
  {
    variant: "pageNumber",
    style: {
      fontFamily: proximanovaFontFamily,
      fontWeight: "normal",
      fontSize: 10,
      lineHeight: 12.18,
    },
  },
];

type TextProps = React.PropsWithChildren<ReactPDF.TextProps> &
  Partial<ReactPDF.SVGTextProps>;

export interface CarPdfTypographyProps extends TextProps {
  variant?: PdfFontVariant;
}

export const CarPdfTypography = ({
  variant = "body1",
  style,
  ...props
}: CarPdfTypographyProps) => {
  const variantStyle = variants.find((i) => i.variant === variant)?.style;

  if (!variantStyle) {
    throw new Error("CarPdfTypography variant style not found");
  }

  return (
    <Text
      style={{
        ...variantStyle,
        fontSize: variantStyle.fontSize,
        lineHeight: variantStyle.lineHeight / variantStyle.fontSize,
        fontStyle: variantStyle.fontStyle ?? "normal",
        fontWeight: variantStyle.fontWeight ?? "normal",
        color: "#231F20",
        ...style,
      }}
      {...props}
    />
  );
};
