import { Box, BoxProps, Typography } from "@mui/material";
import { Fragment, ReactNode } from "react";

export interface SettingsListItem {
  label: string;
  description: string;
  content: ReactNode;
}

interface SettingsListProps {
  sx?: BoxProps["sx"];
  items: SettingsListItem[];
}

export const SettingsList = (props: SettingsListProps) => {
  return (
    <Box
      sx={{
        ...props.sx,
        display: "grid",
        gridTemplateColumns: "1fr 0.1fr 2fr",
      }}
    >
      {props.items.map((i, idx, arr) => {
        const isLast = idx === arr.length - 1;
        return (
          <Fragment key={idx}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                borderBottomWidth: isLast ? 0 : 1,
                borderBottomStyle: "solid",
                borderBottomColor: "gray3",
                pt: 6,
                pb: isLast ? 0 : 7.5,
              }}
            >
              <Typography variant="h6SemiBold">{i.label}</Typography>
              <Typography
                variant="par02Regular"
                color="gray6"
                sx={{ whiteSpace: "pre-line" }}
              >
                {i.description}
              </Typography>
            </Box>
            <Box
              sx={{
                borderBottomWidth: isLast ? 0 : 1,
                borderBottomStyle: "solid",
                borderBottomColor: "gray3",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderBottomWidth: isLast ? 0 : 1,
                borderBottomStyle: "solid",
                borderBottomColor: "gray3",
                pt: 6,
                pb: isLast ? 0 : 7.5,
              }}
            >
              {i.content}
            </Box>
          </Fragment>
        );
      })}
    </Box>
  );
};
