import { Box, BoxProps } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import {
  SimulationBehaviorCash,
  useAssetClassesSimulationBehaviorCashListQuery,
  useAssetClassesSimulationBehaviorCashUpdateMutation,
} from "api/carApi.generated";
import { CarNumberFieldDelayed } from "components/NumberField";
import { CarTextFieldDelayed } from "components/Inputs";

interface SimulationModelsBehaviorCashProps {
  assetClassId: string;
  sx?: BoxProps["sx"];
}

export const SimulationModelsBehaviorCash = (
  props: SimulationModelsBehaviorCashProps,
) => {
  const data = useAssetClassesSimulationBehaviorCashListQuery({
    id: "",
  });

  const [updateMut] = useAssetClassesSimulationBehaviorCashUpdateMutation();

  const item = data.data?.at(0);

  const updateItem = (value: SimulationBehaviorCash) => {
    updateMut({
      id: value.id ?? "",
      simulationBehaviorCash: value,
    });
  };

  return (
    <Box sx={{ ...props.sx, display: "flex", flexDirection: "column" }}>
      <TablePlaceHolder isLoading={data.isLoading} rows={8}>
        {item && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "180px 180px",
              columnGap: 11,
              rowGap: 5,
              ".MuiInputBase-root": {
                backgroundColor: "white",
              },
            }}
          >
            <CarNumberFieldDelayed
              label="Model Slope"
              decimalPlaces={10}
              value={item.new_model_beta}
              onChange={(new_model_beta) =>
                updateItem({
                  ...item,
                  new_model_beta: new_model_beta ?? 0,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Model Intercept"
              decimalPlaces={10}
              value={item.new_model_intercept}
              onChange={(new_model_intercept) =>
                updateItem({
                  ...item,
                  new_model_intercept: new_model_intercept ?? 0,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Model Slope Override"
              decimalPlaces={10}
              value={item.model_slope_override}
              onChange={(model_slope_override) =>
                updateItem({
                  ...item,
                  model_slope_override: model_slope_override ?? 0,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Absolute Upper Boundary"
              decimalPlaces={3}
              value={item.absolute_upper_boundary}
              onChange={(absolute_upper_boundary) =>
                updateItem({
                  ...item,
                  absolute_upper_boundary: absolute_upper_boundary ?? 0,
                })
              }
            />
            <CarTextFieldDelayed
              label="Model Slope Override Rational"
              sx={{ gridColumn: "1 / span 2" }}
              fullWidth
              multiline
              rows={5}
              value={item.model_slope_override_rationale}
              onChange={(model_slope_override_rationale) =>
                updateItem({
                  ...item,
                  model_slope_override_rationale:
                    model_slope_override_rationale,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Max. Step"
              decimalPlaces={1}
              value={item.max_step}
              onChange={(max_step) =>
                updateItem({
                  ...item,
                  max_step: max_step ?? 0,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Min. Yield"
              decimalPlaces={4}
              value={item.min_yield}
              onChange={(min_yield) =>
                updateItem({ ...item, min_yield: min_yield ?? 0 })
              }
            />
            <CarNumberFieldDelayed
              label="Sigma"
              decimalPlaces={16}
              value={item.sigma}
              onChange={(sigma) => updateItem({ ...item, sigma: sigma ?? 0 })}
            />
            <CarNumberFieldDelayed
              label="Crisis Mode Returns"
              decimalPlaces={1}
              value={item.crisis_mode_returns}
              onChange={(crisis_mode_returns) =>
                updateItem({ ...item, crisis_mode_returns })
              }
            />
          </Box>
        )}
      </TablePlaceHolder>
    </Box>
  );
};
