import {
  PortfolioAssetClassOptions,
  PortfolioGroupVersion,
  usePortfolioPortfolioGroupVersionAssetClassesListQuery,
  usePortfolioPortfolioGroupVersionRetrieveQuery,
} from "api/carApi.generated";

import { isDefined } from "utils";

export interface CarPortfolioGroupVersionAllocation {
  level4Id: string;
  value: number;
}

export const usePortfolioGroupVersionAllocation = (params: {
  versionId?: string;
  publishedVersionId?: string;
}) => {
  const version = usePortfolioPortfolioGroupVersionRetrieveQuery(
    {
      id: params.versionId ?? "",
    },
    {
      skip: !params.versionId,
    },
  );

  const publishedVersion = usePortfolioPortfolioGroupVersionRetrieveQuery(
    {
      id: params.publishedVersionId ?? "",
    },
    {
      skip: !params.publishedVersionId,
    },
  );

  const versionClasses = usePortfolioPortfolioGroupVersionAssetClassesListQuery(
    {
      portfolioGroupVersionId: params.versionId ?? "",
    },
    {
      skip: !params.versionId,
    },
  );

  const publishedVersionClasses =
    usePortfolioPortfolioGroupVersionAssetClassesListQuery(
      {
        portfolioGroupVersionId: params.publishedVersionId ?? "",
      },
      {
        skip: !params.publishedVersionId,
      },
    );

  const getDataAssetAllocations = (dataId?: string) => {
    const getAllocations = (
      version?: PortfolioGroupVersion,
      options?: PortfolioAssetClassOptions[],
    ) => {
      const allocations = version?.portfolio_modules
        ?.find((i) => i.portfolio_module_data?.some((dt) => dt.id === dataId))
        ?.portfolio_module_data?.find((dt) => dt.id === dataId)?.allocations;

      return allocations
        ?.map<CarPortfolioGroupVersionAllocation | undefined>((i) => {
          const val = options?.find(
            (vac) =>
              vac.is_active && vac.id === i.portfolio_asset_class_options_id,
          );

          return val
            ? {
                level4Id: val?.firm_asset_class__asset_class__id ?? "",
                value: i.allocation ?? 0,
              }
            : undefined;
        })
        .filter(isDefined);
    };

    return (
      getAllocations(version.data, versionClasses.data) ??
      getAllocations(publishedVersion.data, publishedVersionClasses.data)
    );
  };

  const isLoading =
    version.isLoading ||
    versionClasses.isLoading ||
    publishedVersion.isLoading ||
    publishedVersionClasses.isLoading;

  return {
    isLoading,
    getDataAssetAllocations,
  };
};
