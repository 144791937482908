import { CarTrialForm } from "pages/login/useLogin";

const portalId = "22104810";
const formId = "7c535b86-f6e2-4fdd-86d5-9caf7102a363";

export const hubspotPostStartTrial = async (trialForm: CarTrialForm) => {
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

  if (!trialForm.firstName || !trialForm.lastName || !trialForm.email) {
    return;
  }

  return fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      fields: [
        {
          objectTypeId: "0-1",
          name: "firstname",
          value: trialForm.firstName.trim(),
        },
        {
          objectTypeId: "0-1",
          name: "lastname",
          value: trialForm.lastName.trim(),
        },
        {
          objectTypeId: "0-1",
          name: "email",
          value: trialForm.email.trim(),
        },
      ],
    }),
  });
};
