import { useQuestionnaireClientTierListQuery } from "api/carApi.generated";

export const useTiers = () => {
  const tiers = useQuestionnaireClientTierListQuery();

  return {
    isLoading: tiers.isLoading,
    items: Array.from(tiers.data ?? [])
      .filter((i) => i.is_active)
      .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0)),

    getTier: (tierId?: string | null) =>
      tiers.data?.find((i) => i.id === tierId),
  };
};
