import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfHeading } from "./PdfHeading";
import { CarPdfMarkdown } from "components/PdfMarkdown";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";

export interface ImportantInformationPdfPageProps {
  pageProps: CarPdfPageProps;
  disclosures: UseDisclosures;
}

export const ImportantInformationPdfPage = (
  props: ImportantInformationPdfPageProps,
) => (
  <CarPdfPage {...props.pageProps}>
    <CarPdfHeading label="Important Information" style={{ marginBottom: 14 }} />
    <CarPdfMarkdown>
      {props.disclosures.getContent(
        CarDisclosureContentCode.CMPAS_CLIENT_REPORT_IMPORTANT_INFORMATION_part_10,
      )}
    </CarPdfMarkdown>
  </CarPdfPage>
);
