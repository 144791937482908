import { TablePlaceHolder } from "components/PlaceHolder";
import { AssetClassAnalysisChart } from "./AssetClassAnalysisChart";
import {
  AssetClassAnalysisTable,
  alphaVsBetaColumns,
  getOverUndervaluedColumns,
  getPriceDrivenColumns,
  getStaticColumns,
  getStaticVsPriceDrivenColumns,
  statisticsColumns,
} from "./AssetClassAnalysisTable";
import { useLocalStorage } from "features/general/useLocalStorage";
import {
  CarAssetClassAnalysisItemKey,
  useAssetClassSummaryStat,
} from "./useAssetClassSummaryStat";
import { Box, MenuItem, Typography, styled } from "@mui/material";
import { CarSelectField } from "components/Inputs";
import { useState } from "react";
import { CarFlatPageControl } from "components/FlatPageControl";
import { CarSwitch } from "components/Switch";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { useAssetClassGroupSelector } from "app/useAssetClassGroupSelector";
import { CarPageContent } from "components/PageContent";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

const StyledPage = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(4.5),
  paddingTop: theme.spacing(3.5),
  backgroundColor: theme.palette.white,
  borderRadius: "5px",
  borderColor: theme.palette.gray6,
  borderStyle: "solid",
  borderWidth: "1px",
}));

export enum AssetClassAnalysisTabId {
  static = "static",
  priceDriven = "priceDriven",
  overUndervalued = "overUndervalued",
  staticVsPriceDriven = "staticVsPriceDriven",
  alphaVsBeta = "alphaVsBeta",
  statistics = "statistics",
  advanced = "advanced",
}

export const AssetClassAnalysisSummary = () => {
  const pageContent = usePageContent();

  const [xStatId, onXStatIdChange] =
    useLocalStorage<CarAssetClassAnalysisItemKey>(
      "AssetClassAnalysisSummary_xStatId",
      "dft",
    );
  const [yStatId, onYStatIdChange] =
    useLocalStorage<CarAssetClassAnalysisItemKey>(
      `AssetClassAnalysisSummary_yStatId`,
      "realStaticExpectedReturn",
    );

  const assetClassGroupSelector = useAssetClassGroupSelector();

  const [pageId, setPageId] = useState<string | undefined>(
    AssetClassAnalysisTabId.static,
  );

  const [isReal, setIsReal] = useState<boolean>(true);

  const data = useAssetClassSummaryStat({
    type: AssetClassLoadType.byLatestSimulationJob,
    filterByUsableInPortfolios: true,
  });

  const rows = data.acSummaryItems.filter((i) =>
    assetClassGroupSelector.isAssetClassInGroup(i.level4Id),
  );

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 10 }}
      isLoading={
        data.isLoading ||
        assetClassGroupSelector.isLoading ||
        pageContent.isLoading
      }
      rows={8}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Summary
      </Typography>
      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY_DIRECTIONS,
        )}
      />
      <Box
        sx={{
          mt: 5,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "gray1",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "gray3",
          p: 4,
          pt: 6,
        }}
      >
        <CarSelectField
          label="Asset Class Groupings"
          sx={{
            width: 216,
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              marginTop: "28px",
              bgcolor: "white",
            },
            mb: 3.5,
          }}
          value={assetClassGroupSelector.value}
          onChange={assetClassGroupSelector.onChange}
        >
          {assetClassGroupSelector.items.map((i) => (
            <MenuItem key={i.code} value={i.code}>
              {i.name}
            </MenuItem>
          ))}
        </CarSelectField>
        <AssetClassAnalysisChart
          xStatId={xStatId}
          yStatId={yStatId}
          items={rows}
        />

        <Box
          sx={{
            mt: 4,
            alignSelf: "flex-end",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="par01Regular">Real</Typography>
          <CarSwitch
            size="small"
            checked={!isReal}
            onChange={(e, value) => setIsReal(!value)}
          />
          <Typography variant="par01Regular">Nominal</Typography>
        </Box>

        <CarFlatPageControl
          sx={{
            mt: 3,
            "& > .flat-page-control-header": {
              justifyContent: "space-around",
              "& > .flat-page-control-button": {
                fontSize: "18px",
                lineHeight: "22px",
              },
            },
          }}
          value={pageId}
          onChange={setPageId}
          items={[
            {
              value: AssetClassAnalysisTabId.static,
              label: "Static",
              content: (
                <StyledPage>
                  <CarPageContent
                    sx={{ mb: 4.5 }}
                    content={pageContent.getContent(
                      CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY_STATIC_VIEW,
                    )}
                  />
                  <AssetClassAnalysisTable
                    displayColumns={getStaticColumns(isReal)}
                    xStatId={xStatId}
                    onXStatIdChange={onXStatIdChange}
                    yStatId={yStatId}
                    onYStatIdChange={onYStatIdChange}
                    items={rows}
                  />
                </StyledPage>
              ),
            },
            {
              value: AssetClassAnalysisTabId.priceDriven,
              label: "Price-Driven",
              content: (
                <StyledPage>
                  <CarPageContent
                    sx={{ mb: 4.5 }}
                    content={pageContent.getContent(
                      CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY_PRICE_DRIVEN_VIEW,
                    )}
                  />
                  <AssetClassAnalysisTable
                    displayColumns={getPriceDrivenColumns(isReal)}
                    xStatId={xStatId}
                    onXStatIdChange={onXStatIdChange}
                    yStatId={yStatId}
                    onYStatIdChange={onYStatIdChange}
                    items={rows}
                  />
                </StyledPage>
              ),
            },
            {
              value: AssetClassAnalysisTabId.overUndervalued,
              label: "Over/Undervalued",
              content: (
                <StyledPage>
                  <CarPageContent
                    sx={{ mb: 4.5 }}
                    content={pageContent.getContent(
                      CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY_OVERUNDER_VALUED_VIEW,
                    )}
                  />
                  <AssetClassAnalysisTable
                    displayColumns={getOverUndervaluedColumns(isReal)}
                    xStatId={xStatId}
                    onXStatIdChange={onXStatIdChange}
                    yStatId={yStatId}
                    onYStatIdChange={onYStatIdChange}
                    items={rows}
                  />
                </StyledPage>
              ),
            },
            {
              value: AssetClassAnalysisTabId.staticVsPriceDriven,
              label: "Static vs. Price-Driven",
              content: (
                <StyledPage>
                  <CarPageContent
                    sx={{ mb: 4.5 }}
                    content={pageContent.getContent(
                      CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY_STATIC_VS_PRICE_DRIVEN_VIEW,
                    )}
                  />
                  <AssetClassAnalysisTable
                    displayColumns={getStaticVsPriceDrivenColumns(isReal)}
                    xStatId={xStatId}
                    onXStatIdChange={onXStatIdChange}
                    yStatId={yStatId}
                    onYStatIdChange={onYStatIdChange}
                    items={rows}
                  />
                </StyledPage>
              ),
            },
            {
              value: AssetClassAnalysisTabId.alphaVsBeta,
              label: "Alpha vs. Beta",
              content: (
                <StyledPage>
                  <CarPageContent
                    sx={{ mb: 4.5 }}
                    content={pageContent.getContent(
                      CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY_ALPHA_BETA_VIEW,
                    )}
                  />
                  <AssetClassAnalysisTable
                    displayColumns={alphaVsBetaColumns}
                    xStatId={xStatId}
                    onXStatIdChange={onXStatIdChange}
                    yStatId={yStatId}
                    onYStatIdChange={onYStatIdChange}
                    items={rows}
                  />
                </StyledPage>
              ),
            },
            {
              value: AssetClassAnalysisTabId.statistics,
              label: "Statistics",
              content: (
                <StyledPage>
                  <CarPageContent
                    sx={{ mb: 4.5 }}
                    content={pageContent.getContent(
                      CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY_STATISTICS_VIEW,
                    )}
                  />
                  <AssetClassAnalysisTable
                    displayColumns={statisticsColumns}
                    xStatId={xStatId}
                    onXStatIdChange={onXStatIdChange}
                    yStatId={yStatId}
                    onYStatIdChange={onYStatIdChange}
                    items={rows}
                  />
                </StyledPage>
              ),
            },
            // {
            //   value: AssetClassAnalysisTabId.advanced,
            //   label: "Advanced",
            //   content: (
            //     <StyledPage>
            //       <CarPageContent
            //         sx={{ mb: 4.5 }}
            //         content={pageContent.getContent(
            //           CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY_ADVANCED_VIEW,
            //         )}
            //       />
            //       <AssetClassAnalysisTable
            //         displayColumns={allColumns}
            //         xStatId={xStatId}
            //         onXStatIdChange={onXStatIdChange}
            //         yStatId={yStatId}
            //         onYStatIdChange={onYStatIdChange}
            //         items={rows}
            //         showSelector
            //       />
            //     </StyledPage>
            //   ),
            // },
          ]}
        />
      </Box>
    </TablePlaceHolder>
  );
};
