import {
  useNotificationsNotificationSettingListQuery,
  useNotificationsNotificationSettingUpdateMutation,
  useNotificationsNotificationTypeListQuery,
} from "api/carApi.generated";
import { useTiers } from "app/useTiers";
import { isDefined } from "utils";

export interface CarNotificationTierSetting {
  id: string;
  tierId: string;
  notificationTypeId: string;
  enabled: boolean;
  label: string;
}

export interface CarNotificationSetting {
  notificationTypeId: string;
  label: string;
  description: string;
  tiers: CarNotificationTierSetting[];
}

export const useAlerts = () => {
  const tiers = useTiers();
  const notificationTypes = useNotificationsNotificationTypeListQuery();
  const notificationSettings = useNotificationsNotificationSettingListQuery();
  const [update] = useNotificationsNotificationSettingUpdateMutation();

  const items: CarNotificationSetting[] = Array.from(
    notificationTypes.data ?? [],
  )
    .filter((i) => i.is_active)
    .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0))
    .map<CarNotificationSetting>((notificationType) => ({
      notificationTypeId: notificationType.id ?? "",
      label: notificationType.name ?? "",
      description: notificationType.description ?? "",
      tiers: tiers.items
        .map<CarNotificationTierSetting | undefined>((tierItem) => {
          const settingItem = notificationSettings.data?.find(
            (i) =>
              i.notification_type === notificationType.id &&
              i.client_tier === tierItem.id,
          );

          return settingItem
            ? {
                id: settingItem.id ?? "",
                notificationTypeId: notificationType.id ?? "",
                tierId: tierItem.id ?? "",
                label: tierItem.name,
                enabled: !!settingItem.is_enabled,
              }
            : undefined;
        })
        .filter(isDefined),
    }));

  const handleChange = (item: CarNotificationTierSetting) => {
    update({
      id: item.id,
      notificationSetting: {
        id: item.id,
        client_tier: item.tierId,
        notification_type: item.notificationTypeId,
        is_enabled: item.enabled,
      },
    });
  };

  return {
    isLoading:
      notificationTypes.isLoading ||
      tiers.isLoading ||
      notificationSettings.isLoading,
    items,
    handleChange,
  };
};

export type UseAlerts = ReturnType<typeof useAlerts>;
