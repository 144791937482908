import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { formatISO, getYear, addYears } from "date-fns";
import parseISO from "date-fns/parseISO";
import { SelectArrow } from "icons";
import { useDelayed } from "../useDelayed";
import { InputBaseProps } from "@mui/material";
import { isDefined } from "utils";
export interface CarDateFieldProps
  extends Omit<DatePickerProps<Date>, "value" | "onChange"> {
  required?: boolean;
  onBlur?: InputBaseProps["onBlur"];
  onKeyPress?: InputBaseProps["onKeyPress"];
  value?: string | null;
  onChange: (value?: string) => void;
}

export const CarDateField = ({
  value,
  className,
  onBlur,
  onKeyPress,
  onChange,
  required,
  ...props
}: CarDateFieldProps) => (
  <DatePicker
    sx={{
      ".MuiInputBase-input": {
        paddingRight: 0,
      },
      ".MuiInputAdornment-root": {
        margin: 0,
        width: 24,
        flex: "none",
        ".MuiIconButton-root": {
          width: 24,
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    }}
    slots={{
      openPickerIcon: SelectArrow,
    }}
    slotProps={{
      openPickerButton: {
        disableRipple: true,
        disableFocusRipple: true,
        disableTouchRipple: true,
        tabIndex: -1,
      },
      textField: {
        onBlur,
        onKeyPress,
        error: required ? !isDefined(value) : undefined,
      },
    }}
    minDate={parseISO("1900-01-01")}
    value={(value && parseISO(value)) || null}
    onChange={(date, context) => {
      if (context.validationError) {
        return;
      }

      if (date instanceof Date && isFinite(date.getTime())) {
        let newDate: Date = date;

        if (getYear(newDate) < 100) {
          newDate = addYears(newDate, 1900);
        }

        if (getYear(newDate) < 1900) {
          onChange(undefined);
        } else {
          onChange(formatISO(newDate, { representation: "date" }));
        }
      } else {
        onChange(undefined);
      }
    }}
    {...props}
  />
);

export const CarDateFieldDelayed = ({ ...props }: CarDateFieldProps) => {
  const delayed = useDelayed(props);
  return <CarDateField {...props} {...delayed} onClose={delayed.onBlur} />;
};
