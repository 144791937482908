import clsx from "clsx";
import { ReactNode, memo, useRef, useState } from "react";
import {
  Box,
  BoxProps,
  CircularProgress,
  Collapse,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { DragIndicator, ExpandLess } from "@mui/icons-material";
import { DeleteRowButton } from "components/Buttons";
import { formatPercent, isDefined, roundTo } from "utils";
import { CarFactorPercentFieldDelayed } from "components/NumberField";
import { CarIconEdit, ErrorTriangle } from "icons";
import {
  CarPortfolioAssetBand,
  CarPortfolioAssetColumnEnum,
  CarPortfolioAssetRow,
  CarPortfolioAssetTableData,
  CarPortfolioAssetValue,
} from "./usePortfolioGroupVersionData";
import { UseExpand, useCollapse } from "features/general/useExpand";
import {
  getKeyNavProps,
  useKeyboardNavigation,
} from "app/useKeyboardNavigation";
import _ from "lodash";
import { CarTextFieldDelayed } from "components/Inputs";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

const StyledField = styled(CarFactorPercentFieldDelayed)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  ".MuiOutlinedInput-root:hover fieldset": {
    borderColor: `${theme.palette.gray7} !important`,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.gray6,
  },
  ".MuiInputBase-root": {
    backgroundColor: theme.palette.white,
  },
}));

const StyledHeaderField = styled(CarTextFieldDelayed)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  ".MuiOutlinedInput-root:hover fieldset": {
    borderColor: `${theme.palette.gray7} !important`,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.gray6,
  },
  ".MuiInputBase-root": {
    backgroundColor: theme.palette.white,
  },
}));

const ColumnError = () => {
  return (
    <Box
      sx={{
        position: "relative",
        "& > div": {
          position: "absolute",
          color: "table.error",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: -20,
          width: "100%",
          overflow: "hidden",
          ".label": {
            fontSize: 11,
            fontWeight: 700,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        },
      }}
    >
      <div>
        <ErrorTriangle fontSize="small" />
        <div className="label">Total does not = 100%</div>
      </div>
    </Box>
  );
};

interface LabelHeaderProps {
  sx?: BoxProps["sx"];
  hideActionsRow?: boolean;
  isReadOnly: boolean;
}

const LabelHeader = (props: LabelHeaderProps) => {
  return (
    <Box
      sx={{
        border: 1,
        borderColor: "table.border",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "table.background.header",
        "&:last-of-type": {
          borderRightWidth: 1,
        },
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
          },
        },
        ...props.sx,
      }}
    >
      <Box
        sx={{
          height: 60,
        }}
      />
      {!props.isReadOnly && !props.hideActionsRow && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            px: 1,
            height: 40,
            backgroundColor: "white",
          }}
        >
          <Typography variant="par02Regular">Actions</Typography>
        </Box>
      )}
      <Box
        sx={{
          flex: "auto",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          px: 1,
        }}
      >
        <Typography variant="par02Regular">
          Portfolio Model Variations
          <CarTooltipBox
            sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
            className={pendoClasses.editPortfolioPortfolioModelVariations}
          />
        </Typography>
      </Box>
    </Box>
  );
};

interface ColumnInfo {
  isCash: boolean;
}

const getGridTemplateColumns = (columns: ColumnInfo[]) => {
  const hasCash = columns.some((i) => i.isCash);

  if (!hasCash && columns.length === 1) {
    return `0.3fr 1fr`; // covers single portfolio mode
  } else {
    return `1fr ${columns.map((i) => (i.isCash ? "0.5fr" : "1fr")).join(" ")}`;
  }
};

interface ModuleHeaderProps {
  sx?: BoxProps["sx"];
  band: CarPortfolioAssetBand;
  hideActionsRow?: boolean;
  isReadOnly: boolean;
  onDelete: (moduleId: string) => void;
  onRename: (moduleId: string, newLabel: string) => Promise<void>;
  onMove: (fromModuleId: string, toModuleId: string) => void;
}

const ModuleHeader = (props: ModuleHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isEditing, setIsEditing] = useState(false);
  const [isRenaming, setIsRenaming] = useState(false);

  const handleChange = async (value?: string) => {
    const trimmedValue = value?.trim();
    setIsEditing(false);
    if (trimmedValue) {
      setIsRenaming(true);
      try {
        await props.onRename(props.band.moduleId, trimmedValue);
      } finally {
        setIsRenaming(false);
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <Box
      ref={ref}
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        borderLeftWidth: props.band.isCash ? 1 : 2,
        borderRightWidth: 2,
        backgroundColor: "table.background.header",
        "&:last-of-type": {
          borderRightWidth: 1,
        },
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
          },
        },
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
        ...props.sx,
      }}
      onDragOver={
        props.band.isCash
          ? undefined
          : (e) => {
              e.preventDefault();
              e.dataTransfer.dropEffect = "move";
            }
      }
      onDrop={
        props.band.isCash
          ? undefined
          : (e) => {
              e.preventDefault();
              props.onMove(
                e.dataTransfer.getData("text/plain"),
                props.band.moduleId,
              );
            }
      }
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          px: 1,
          py: 2,
          height: 60,
          width: "100%",
        }}
      >
        {isEditing ? (
          <StyledHeaderField
            autoFocus
            value={props.band.label}
            onChange={handleChange}
            onCancel={handleCancel}
          />
        ) : (
          <Typography variant="h6SBold">
            {props.band.label}
            {!props.isReadOnly && !props.band.isCash && (
              <Box sx={{ position: "relative", display: "inline" }}>
                {isRenaming ? (
                  <CircularProgress
                    color="inherit"
                    size={17}
                    sx={{
                      position: "absolute",
                      width: 17,
                      height: 17,
                      cursor: "pointer",
                      color: "caravelOrangePrimary",
                      ml: 0.25,
                      transform: "translateY(2px)",
                    }}
                  />
                ) : (
                  <CarIconEdit
                    sx={{
                      position: "absolute",
                      width: 17,
                      height: 17,
                      cursor: "pointer",
                      color: "caravelOrangePrimary",
                      ml: 0.25,
                      transform: "translateY(2px)",
                    }}
                    onClick={() => setIsEditing(true)}
                  />
                )}
              </Box>
            )}
          </Typography>
        )}
      </Box>
      {!props.isReadOnly && !props.hideActionsRow && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 40,
            backgroundColor: "table.background.level1",
          }}
        >
          {!props.band.isCash && (
            <>
              <IconButton
                size="small"
                sx={{ fontSize: 20, cursor: "grab" }}
                draggable
                onDragStart={(e) => {
                  e.dataTransfer.setData("text/plain", props.band.moduleId);
                  if (ref.current) {
                    e.dataTransfer.setDragImage(ref.current, 8, 80);
                  }
                  e.dataTransfer.dropEffect = "move";
                }}
              >
                <DragIndicator fontSize="inherit" sx={{ color: "gray7" }} />
              </IconButton>
              <DeleteRowButton
                sx={{ color: "caravelOrangePrimary" }}
                onClick={() => props.onDelete(props.band.moduleId)}
              />
            </>
          )}
        </Box>
      )}
      <Box
        sx={{
          flex: "auto",
          display: "grid",
          gridTemplateColumns: `repeat(${props.band.columns.length}, 1fr)`,
          "& > *": {
            borderStyle: "solid",
            borderColor: "table.border",
            backgroundColor: "white",
            borderWidth: 0,
            borderLeftWidth: 1,
            "&:first-of-type": {
              borderLeftWidth: 0,
            },
            py: 1.5,
            px: 0.5,
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          },
        }}
      >
        {props.band.columns.map((i) => (
          <Typography key={i.columnId} variant="mediumItalic">
            {i.label}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

const StyledCell = styled("div")(({ theme }) => ({
  borderStyle: "solid",
  borderColor: theme.palette.table.border,
  borderWidth: 0,
  borderLeftWidth: 1,
  "&:first-of-type": {
    borderLeftWidth: 0,
  },

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),

  overflow: "hidden",
  textOverflow: "ellipsis",

  "& > *": {
    maxWidth: 54,
  },

  ...theme.typography.par02Regular,
  "&.total": {
    ...theme.typography.par02Bold,
  },
  "&.error": {
    color: theme.palette.red,
  },
  "&.less": {
    backgroundColor: theme.palette.lightRed,
  },
  "&.more": {
    backgroundColor: theme.palette.lightGreen,
  },
}));

interface RowValueProps {
  isTotalRow?: boolean;
  isReadOnly: boolean;
  benchmarkValue?: number;
  value: CarPortfolioAssetValue;
  onChange: (value: CarPortfolioAssetValue) => void;
  error?: boolean;
  xNavKey: string;
  yNavKey: string;
}

const RowValue = memo(
  ({
    value,
    onChange,
    benchmarkValue,
    isTotalRow,
    isReadOnly,
    error,
    xNavKey,
    yNavKey,
  }: RowValueProps) => {
    const skipColorCoding =
      value.columnId === CarPortfolioAssetColumnEnum.existing ||
      value.columnId === CarPortfolioAssetColumnEnum.benchmark;

    return (
      <StyledCell
        key={value.columnId}
        className={clsx({
          total: isTotalRow,
          error: isTotalRow && roundTo(value.allocation ?? 0, 3) !== 1,
          less:
            !skipColorCoding &&
            isDefined(benchmarkValue) &&
            isDefined(value.allocation)
              ? roundTo(value.allocation, 3) < roundTo(benchmarkValue, 3)
              : undefined,
          more:
            !skipColorCoding &&
            isDefined(benchmarkValue) &&
            isDefined(value.allocation)
              ? roundTo(value.allocation, 3) > roundTo(benchmarkValue, 3)
              : undefined,
        })}
      >
        {!isReadOnly && value.isEditable && isDefined(value.allocation) ? (
          <StyledField
            defaultValue={0}
            decimalPlaces={0}
            value={value.allocation}
            error={error}
            inputProps={getKeyNavProps({
              x: xNavKey,
              y: yNavKey,
            })}
            onChange={(nv) => {
              onChange({
                ...value,
                allocation: nv ?? 0,
              });
            }}
          />
        ) : isDefined(value.allocation) ? (
          formatPercent(value.allocation * 100, 1)
        ) : (
          "-"
        )}
      </StyledCell>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps),
);

interface RowProps {
  expand: UseExpand;
  item: CarPortfolioAssetRow;
  level: number;
  details?: ReactNode[];
  isReadOnly: boolean;
  isTotalRow?: boolean;
  color?: string;
  hideZeroValues?: boolean;
  isLastRow: boolean;
  sx?: BoxProps["sx"];
  onHasError: (moduleId: string, dataId: string) => boolean;
  onAllocationChange: (value: CarPortfolioAssetValue) => void;
}

const Row = (props: RowProps) => {
  const [isExpanded, setIsExpanded] = props.expand.getState(
    props.item.id ?? "",
  );

  const isLastRow = props.isLastRow && (!isExpanded || !props.details?.length);

  const backgroundColor = props.color
    ? props.color
    : props.isTotalRow
    ? "gray2"
    : `table.background.level${props.level + 1}`;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          minHeight: 60,
          gridTemplateColumns: getGridTemplateColumns(props.item.modules),
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderLeft: 1,
            borderRight: 1,
            borderColor: "table.border",
            p: 1.5,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            overflow: "hidden",
            cursor: props.details?.length ? "pointer" : undefined,
            backgroundColor,
            borderBottomLeftRadius: isLastRow ? "5px" : undefined,
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Typography
            variant={props.isTotalRow ? "par02Bold" : "par02Regular"}
            sx={{ ml: props.level * 2 }}
          >
            {props.item.name}
          </Typography>
          {!!props.details?.length && (
            <ExpandLess
              fontSize="small"
              sx={{
                ml: "auto",
                color: "gray9",
                transform: `rotate(${isExpanded ? 0 : 180}deg)`,
                transition: "transform 0.3s",
              }}
            />
          )}
        </Box>
        {props.item.modules.map((md, idx, arr) => {
          const benchmarkValue = md.values.find(
            (i) => i.columnId === CarPortfolioAssetColumnEnum.benchmark,
          )?.allocation;
          return (
            <Box
              key={md.moduleId}
              sx={{
                marginLeft: "-1px",
                borderBottom: 1,
                borderLeft: 1,
                borderRight: 1,
                borderColor: "table.border",
                borderLeftWidth: md.isCash ? 1 : 2,
                borderRightWidth: 2,
                "&:last-of-type": {
                  borderRightWidth: 1,
                },
                backgroundColor,
                borderBottomRightRadius:
                  isLastRow && idx === arr.length - 1 ? "5px" : undefined,
                overflow: "hidden",

                display: "grid",
                gridTemplateColumns: `repeat(${md.values.length}, 1fr)`,
              }}
            >
              {md.values.map((v) => (
                <RowValue
                  key={v.columnId}
                  isTotalRow={props.isTotalRow}
                  benchmarkValue={benchmarkValue}
                  value={v}
                  onChange={props.onAllocationChange}
                  isReadOnly={props.isReadOnly}
                  error={props.onHasError(md.moduleId, v.dataId)}
                  xNavKey={props.item.id}
                  yNavKey={v.yNavKey}
                />
              ))}
            </Box>
          );
        })}
      </Box>
      {!!props.details?.length && (
        <Collapse in={isExpanded} unmountOnExit>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
            }}
          >
            {props.details}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};
export interface PortfolioGroupAssetAllocationTableProps {
  sx?: BoxProps["sx"];
  tableData: CarPortfolioAssetTableData;
  onAllocationChange: (value: CarPortfolioAssetValue) => void;
  onModuleDelete: (moduleId: string) => void;
  onModuleRename: (moduleId: string, newLabel: string) => Promise<void>;
  onModuleMove: (fromModuleId: string, toModuleId: string) => void;
  hideActionsRow?: boolean;
  isReadOnly: boolean;
  storageKey: string;
  hideZeroValues?: boolean;
  isTreeView: boolean;
}

export const PortfolioGroupAssetAllocationTable = ({
  storageKey,
  tableData: {
    bands,
    assetRows: { level1, level4, level1Total: totalRow },
  },
  onAllocationChange,
  onModuleDelete,
  onModuleRename,
  onModuleMove,
  hideActionsRow,
  isReadOnly,
  sx,
  hideZeroValues,
  isTreeView,
}: PortfolioGroupAssetAllocationTableProps) => {
  const expand = useCollapse(`InputAndAnalysisAsset_${storageKey}`);

  const { ref } = useKeyboardNavigation();

  const rows = isTreeView ? level1 : level4;
  const getRenderItem =
    (
      level: number,
      isTotalRow?: boolean,
      color?: string,
      isParentLastRow?: boolean,
    ) =>
    (i: CarPortfolioAssetRow, idx: number, arr: Array<unknown>) => {
      return (
        <Row
          key={i.id}
          item={i}
          level={level}
          color={color}
          isReadOnly={isReadOnly}
          hideZeroValues={hideZeroValues}
          isTotalRow={isTotalRow}
          details={i.children
            .filter((ch) => !hideZeroValues || ch.zeroValuesOnly)
            .map(getRenderItem(level + 1, false, undefined, isParentLastRow))}
          onAllocationChange={onAllocationChange}
          onHasError={(moduleId, dataId) =>
            bands
              .find((bd) => bd.moduleId === moduleId)
              ?.columns.find((cl) => cl.dataId === dataId)?.hasError ?? false
          }
          expand={expand}
          isLastRow={!!isParentLastRow && idx === arr.length - 1}
        />
      );
    };

  return (
    <Box
      ref={ref}
      sx={{
        display: "grid",
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "table.background.level1",
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: getGridTemplateColumns(bands),
          }}
        >
          <>
            <div />
            {bands.map((i) =>
              i.columns.some((c) => c.hasError) ? (
                <ColumnError key={i.moduleId} />
              ) : (
                <div key={i.moduleId} />
              ),
            )}
          </>
          <LabelHeader
            hideActionsRow={hideActionsRow}
            isReadOnly={isReadOnly}
            sx={{
              borderTopLeftRadius: "5px",
            }}
          />
          {bands.map((i, idx, arr) => (
            <ModuleHeader
              key={i.moduleId}
              band={i}
              hideActionsRow={hideActionsRow}
              isReadOnly={isReadOnly}
              onRename={onModuleRename}
              onDelete={onModuleDelete}
              onMove={onModuleMove}
              sx={{
                borderTopRightRadius:
                  idx === arr.length - 1 ? "5px" : undefined,
              }}
            />
          ))}
        </Box>
        {totalRow && getRenderItem(0, true)(totalRow, 0, [])}
      </Box>
      {rows
        .filter((i) => !hideZeroValues || i.zeroValuesOnly)
        .map((i, idx, arr) =>
          getRenderItem(
            0,
            false,
            isTreeView ? undefined : `table.background.level4`,
            idx === arr.length - 1,
          )(i, idx, arr),
        )}
    </Box>
  );
};
