import clsx from "clsx";
import { Box, BoxProps, Typography, styled } from "@mui/material";
import { PropsWithChildren, memo } from "react";

interface StyleProps {
  isStarted: boolean;
  isFailed: boolean;
}

const size = 40;
const margin = size / 2;
const lineWidth = 5;

const radius = 17;
const calcDashArray = (percent: number) =>
  (percent / 100) * 2 * Math.PI * radius;

const getStrokeDasharray = (percent: number) =>
  `${calcDashArray(percent)}, ${calcDashArray(100 - percent)}`;

const StyledSvg = styled("svg")<StyleProps>(
  ({ theme, isStarted, isFailed }) => ({
    position: "absolute",
    width: "100%",
    height: "100%",

    ".ring": {
      stroke: theme.palette.gray4,
      fill: "transparent",
    },
    "@keyframes doneAnimation": {
      "0%": {
        strokeDasharray: getStrokeDasharray(0),
      },
      "100%": {
        strokeDasharray: getStrokeDasharray(100),
      },
    },
    ".percent": {
      fill: "transparent",
      opacity: 1,
      stroke: isStarted
        ? isFailed
          ? theme.palette.red
          : theme.palette.green
        : theme.palette.gray4,
      transition: `stroke-dasharray 1s`,
      strokeDasharray: getStrokeDasharray(0),
      strokeDashoffset: calcDashArray(25),
      animation: "doneAnimation 0.5s ease-in-out forwards",
    },
    "@keyframes loadingAnimation": {
      "0%": {
        strokeDashoffset: calcDashArray(25),
        strokeDasharray: getStrokeDasharray(0),
      },
      "50%": {
        strokeDashoffset: calcDashArray(-30),
        strokeDasharray: getStrokeDasharray(75),
      },
      "100%": {
        strokeDashoffset: calcDashArray(-175),
        strokeDasharray: getStrokeDasharray(0),
      },
    },
    ".loading": {
      fill: "transparent",
      opacity: 1,
      stroke: theme.palette.green,
      animation: "loadingAnimation 1.4s ease-in-out infinite",
    },
  }),
);

interface CircleProps {
  radius: number;
  isLoading: boolean;
}

const Circle = (props: CircleProps) => (
  <>
    <circle
      className="ring"
      cx={margin}
      cy={margin}
      r={props.radius}
      strokeWidth={lineWidth}
    />
    <circle
      className={clsx("percent", {
        loading: props.isLoading,
      })}
      cx={margin}
      cy={margin}
      r={props.radius}
      strokeWidth={lineWidth}
      strokeLinecap="round"
    />
  </>
);

export interface JobStatusProps {
  sx?: BoxProps["sx"];
  jobCount: number;
  completedCount: number;
  failedCount: number;
  hideLabel?: boolean;
  isLarge?: boolean;
}

export const JobStatus = memo((props: PropsWithChildren<JobStatusProps>) => (
  <Box
    sx={{
      position: "relative",
      width: size,
      height: size,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      ...props.sx,
    }}
  >
    <StyledSvg
      viewBox={`0 0 ${size} ${size}`}
      isFailed={props.failedCount > 0}
      isStarted={props.jobCount > 0}
    >
      <Circle
        radius={radius}
        isLoading={props.completedCount + props.failedCount < props.jobCount}
      />
    </StyledSvg>
    {!props.hideLabel && (
      <Typography
        variant="par01Regular"
        sx={{ fontSize: props.isLarge ? "14px" : "11px" }}
      >{`${props.completedCount}/${props.jobCount}`}</Typography>
    )}
  </Box>
));
