import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import {
  AccountHoldingIntegrated,
  useAccountsAccountHoldingsRetrieveQuery,
} from "api/carApi.generated";
import { BaseDialog } from "components/BaseDialog";
import { IntegratorAccountHoldingsDialogRow } from "./IntegratorAccountHoldingsDialogRow";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";

interface IntegratorAccountHoldingsDialogProps {
  accountId: string;
  accountNumber: string;
  onApply: () => void;
  onCancel: () => void;
}

export type IntegratorAccountHoldingsACItem = {
  id: string;
  name: string;
  percentage: number;
};

export interface IntegratorAccountHoldingsItem {
  id: string;
  tickerCusip: string;
  description: string;
  shares: number;
  costBasis: number;
  marketPrice: number;
  marketValue: number;
  assetClasses: IntegratorAccountHoldingsACItem[];
}

export const IntegratorAccountHoldingsDialog = (
  props: IntegratorAccountHoldingsDialogProps,
) => {
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.byLatestSimulationJob,
  });
  const holdings = useAccountsAccountHoldingsRetrieveQuery({
    accountId: props.accountId,
  });

  let data =
    (holdings.data as unknown as AccountHoldingIntegrated[] | undefined) ?? []; // todo fix in swagger

  const items = data
    .filter((i) => i.firm_security.is_active)
    .map<IntegratorAccountHoldingsItem>((i) => ({
      id: i.id ?? "",
      tickerCusip: i.firm_security.ticker ?? "-",
      description: i.description || "-",
      shares: i.shares ?? 0,
      costBasis: i.cost_basis ?? 0,
      marketPrice: (i.market_value ?? 0) / (i.shares ?? 0) || 0,
      marketValue: i.market_value ?? 0,
      assetClasses:
        i.firm_security.asset_classes
          ?.filter((i) => i.is_active)
          .map<IntegratorAccountHoldingsACItem>((holdingAc) => ({
            id: holdingAc.id ?? "",
            name:
              assetClasses.items.find(
                (j) => j.level4Id === holdingAc.firm_asset_class?.asset_class,
              )?.name ?? "",
            percentage: holdingAc.percentage ?? 0,
          })) ?? [],
    }));

  return (
    <BaseDialog
      sx={{ width: "1200px", maxWidth: "100vw" }}
      onClose={props.onCancel}
      actions={null}
    >
      <Typography variant="h2SSemiBold">{`Account Number: ${props.accountNumber}`}</Typography>
      <TablePlaceHolder
        sx={{ mt: 5 }}
        isLoading={holdings.isLoading || assetClasses.isLoading}
        rows={8}
      >
        <Box
          sx={{
            mt: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 6.5,
            overflowY: "auto",
            pr: 1,
            mr: -2,
          }}
        >
          {items.map((i) => (
            <IntegratorAccountHoldingsDialogRow key={i.id} item={i} />
          ))}
        </Box>
      </TablePlaceHolder>
    </BaseDialog>
  );
};
