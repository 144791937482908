import { EmailOutlined, LockOutlined } from "@mui/icons-material";
import { Box, InputAdornment, Typography } from "@mui/material";
import { LoginMode, UseLogin } from "./useLogin";
import { LoginButton, LoginField } from "./LoginComponents";

const FORM_WIDTH = 531;

const LoginSection = ({ data }: { data: UseLogin }) => {
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Enter") {
      data.login();
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h1SSemiBold">Sign in</Typography>
      <LoginField
        sx={{ mt: 2 }}
        placeholder="Email"
        required={data.loginForm.requiredMode}
        value={data.loginForm.email}
        onChange={(email) =>
          data.setLoginForm((value) => ({ ...value, email }))
        }
        inputProps={{
          type: "email",
          autoComplete: "username",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ mr: 0 }}>
              <EmailOutlined />
            </InputAdornment>
          ),
        }}
        onKeyDown={handleKeyDown}
      />
      <LoginField
        sx={{ mt: 2, width: "100%" }}
        placeholder="Password"
        required={data.loginForm.requiredMode}
        value={data.loginForm.password}
        onChange={(password) =>
          data.setLoginForm((value) => ({ ...value, password }))
        }
        inputProps={{
          type: "password",
          autoComplete: "current-password",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ mr: 0 }}>
              <LockOutlined />
            </InputAdornment>
          ),
        }}
        onKeyDown={handleKeyDown}
      />
      <Box
        sx={{
          mt: 5,
          display: "flex",
          justifyContent: "stretch",
          width: "100%",
          gap: 3.25,
        }}
      >
        <LoginButton onClick={data.login}>Log In</LoginButton>
        <LoginButton variant="outlined" onClick={data.goTrialPage}>
          Start Trial
        </LoginButton>
      </Box>
      <Typography
        variant="par01SemiBold"
        sx={{ mt: 2, ml: 2.5, cursor: "pointer" }}
        onClick={data.goToResetPasswordPage}
      >
        Forgot Password?
      </Typography>
    </Box>
  );
};

const ResetPasswordSection = ({ data }: { data: UseLogin }) => {
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Enter") {
      data.resetPassword();
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography variant="h1SSemiBold" sx={{ alignSelf: "center", mt: 5 }}>
        Forgot your password?
      </Typography>
      <LoginField
        sx={{ mt: 2 }}
        placeholder="Email"
        required={data.loginForm.requiredMode}
        value={data.loginForm.email}
        onChange={(email) =>
          data.setLoginForm((value) => ({ ...value, email }))
        }
        inputProps={{
          type: "email",
          autoComplete: "off",
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ mr: 0 }}>
              <EmailOutlined />
            </InputAdornment>
          ),
        }}
        onKeyDown={handleKeyDown}
      />
      <Box
        sx={{
          mt: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: 2,
        }}
      >
        <LoginButton sx={{ width: 260 }} onClick={data.resetPassword}>
          Reset Password
        </LoginButton>
        <Typography
          variant="par01Regular"
          sx={{ cursor: "pointer" }}
          onClick={data.goToLoginPage}
        >
          or{" "}
          <Box component="span" sx={{ textDecoration: "underline" }}>
            sign in
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

const PaperPlaneIcon = () => (
  <svg
    width="92"
    height="84"
    viewBox="0 0 92 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0726 56.6653L1.84217 45.595L0 44.7157L1.84217 43.822L90.0031 0.977302L92 0L91.3715 2.13623L67.7231 81.9204L67.3464 83.2052L66.2432 82.4653L38.3915 63.7726L32.0953 77.622L30.2522 77.4548L25.0726 56.6672V56.6653ZM4.53672 44.7013L26.3285 55.0883L26.7475 55.2834L26.859 55.7437L31.4803 74.2414L37.0789 61.9141L37.5536 60.8532L38.5174 61.4951L66.2288 80.0763L88.8442 3.71317L4.53672 44.7013Z"
      fill="#F19649"
    />
    <path
      d="M37.5528 60.8669L81.5564 10.5815L26.5372 56.7203L25.2812 55.2126L89.7928 1.10156L91.1612 2.49785L39.0327 62.1508L37.5528 60.8669Z"
      fill="#F19649"
    />
    <path
      d="M30.043 76.6271L45.3012 66.4648L46.3909 68.0841L30.559 78.6239L30.043 76.6271Z"
      fill="#F19649"
    />
    <path
      d="M2.10938 43.7394L48.1925 36.1862L49.0016 36.0469L49.2813 36.8291L52.5486 46.2658L50.691 46.9077L47.7034 38.2523L2.43034 45.6796L2.10938 43.7394Z"
      fill="#F19649"
    />
    <path
      d="M56.3463 41.948L54.4609 42.4922L65.8401 81.9161L67.7254 81.3719L56.3463 41.948Z"
      fill="#F19649"
    />
  </svg>
);

const RecoveryLinkSentSection = ({ data }: { data: UseLogin }) => (
  <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <PaperPlaneIcon />
    <Typography variant="h1SSemiBold" sx={{ mt: 2 }}>
      Recovery link sent
    </Typography>
    <Typography variant="par03Regular" sx={{ mt: 1.5, fontSize: "22px" }}>
      Check your email.
    </Typography>
    <Typography
      variant="par01Regular"
      sx={{ mt: 2.5, cursor: "pointer" }}
      onClick={data.goToLoginPage}
    >
      or{" "}
      <Box component="span" sx={{ textDecoration: "underline" }}>
        sign in
      </Box>
    </Typography>
  </Box>
);

export const LoginForm = ({ data }: { data: UseLogin }) => {
  const index =
    data.mode === LoginMode.login
      ? 0
      : data.mode === LoginMode.resetPassword
      ? 1
      : 2;

  return (
    <Box
      sx={{
        mx: -7,
        display: "flex",
        transform: `translate(-${index * FORM_WIDTH}px)`,
        transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",

        "& > *": {
          width: FORM_WIDTH,
          flex: "none",
          px: 7,
        },
      }}
    >
      <LoginSection data={data} />
      <ResetPasswordSection data={data} />
      <RecoveryLinkSentSection data={data} />
    </Box>
  );
};
