import { useTheme } from "@mui/material";
import ReactPDF, { View, Text } from "@react-pdf/renderer";
import { format } from "date-fns";
import { PropsWithChildren } from "react";
import { caravelColors } from "theme";

export const PageTitle = (props: { firmName?: string; title?: string }) => (
  <View fixed>
    <Text
      style={{
        fontSize: 12,
        fontWeight: "bold",
      }}
    >
      {props.firmName}
    </Text>
    <Text
      style={{
        marginTop: 2,
        color: caravelColors.caravelOrangePrimary,
        fontSize: 12,
        fontWeight: "bold",
      }}
    >
      {`${props.title} - ${format(new Date(), "MM/dd/yyyy")}`}
    </Text>
  </View>
);

export const PageNumber = () => (
  <View
    fixed
    style={{
      position: "absolute",
      bottom: 38,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 11,
    }}
  >
    <Text
      style={{ position: "absolute", width: "100%", textAlign: "center" }}
      render={({ pageNumber }) => {
        return `Page ${pageNumber}`;
      }}
    />
  </View>
);

export const SectionHeader = ({
  children,
  style,
  ...props
}: PropsWithChildren<ReactPDF.TextProps>) => {
  const theme = useTheme();
  return (
    <Text
      style={{
        fontSize: 12,
        fontWeight: "semibold",
        color: theme.palette.black,
        marginBottom: 8,
        ...style,
      }}
      {...props}
    >
      {children}
    </Text>
  );
};
