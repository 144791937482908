import {
  useAssetClassesFirmAssetClassImplementationListQuery,
  useAssetClassesFirmAssetClassImplementationUpdateMutation,
} from "api/carApi.generated";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { useFirm } from "./useFirm";

export interface CarSettingsAssetClassImplementation {
  id: string;
  firmAssetClassId: string;
  ui_key: string; // for table key
  assetClassName: string;
  targetName: string;
  targetTicker: string;
}

export const useSettingsAssetClassImplementation = () => {
  const firm = useFirm();
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.allFirm,
  });
  const data = useAssetClassesFirmAssetClassImplementationListQuery();

  const [update] = useAssetClassesFirmAssetClassImplementationUpdateMutation();

  const handleChange = (value: CarSettingsAssetClassImplementation) => {
    const implItem = data.data?.find((item) => item.asset_class === value.id);

    if (implItem) {
      update({
        id: implItem.id ?? "",
        firmAssetClassImplementation: {
          ...implItem,
          target_name: value.targetName,
          target_ticker: value.targetTicker,
        },
      });
    } else {
    }
  };

  const items = assetClasses.items.map<CarSettingsAssetClassImplementation>(
    (i) => {
      const implItem = data.data?.find(
        (item) => item.asset_class === i.level4Id,
      );

      return {
        id: i.level4Id,
        firmAssetClassId: i.firmAssetClassId,
        ui_key: i.level4Id,
        assetClassName: i.name,
        targetName: implItem?.target_name ?? "",
        targetTicker: implItem?.target_ticker ?? "",
      };
    },
  );

  return {
    isLoading: firm.isLoading || assetClasses.isLoading || data.isLoading,
    items,
    findByFirmAssetClassId: (firmAssetClassId?: string) =>
      items.find((i) => i.firmAssetClassId === firmAssetClassId),
    handleChange,
  };
};

export type UseSettingsAssetClassImplementation = ReturnType<
  typeof useSettingsAssetClassImplementation
>;
