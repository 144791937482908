import { MenuItem } from "@mui/material";
import { CarSelectField, CarSelectFieldProps } from "./Inputs";
import { useRelationshipLight } from "app/useRelationshipLight";

export interface CarRelationshipFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value?: string;
  onChange: (value?: string) => void;
}

export const CarRelationshipField = ({
  value,
  onChange,
  ...props
}: CarRelationshipFieldProps) => {
  const data = useRelationshipLight();

  return (
    <CarSelectField {...props} value={value} onChange={onChange}>
      {data.items.map((i) => (
        <MenuItem key={i.id} value={i.id}>
          {`${i.first_name} ${i.last_name}`}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};
