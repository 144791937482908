import clsx from "clsx";
import { Box, BoxProps, ButtonBase, styled } from "@mui/material";
import { ReactElement } from "react";
import { CarTooltipBox } from "./TooltipBox";

export const StyledTabButton = styled(ButtonBase)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  ...theme.typography.par02Regular,
  position: "relative",
  backgroundColor: theme.palette.white,
  borderTopLeftRadius: "15px",
  borderTopRightRadius: "15px",
  borderColor: theme.palette.gray3,
  borderStyle: "solid",
  borderWidth: "1px",
  borderBottomWidth: 0,
  "&.selected": {
    color: theme.palette.primary.main,
    "&::after": {
      content: '""',
      width: "100%",
      bottom: -1,
      position: "absolute",
      borderBottom: `solid 2px ${theme.palette.white}`,
    },
  },
}));

const StyledPanel = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(5),
  backgroundColor: theme.palette.white,
  borderRadius: "15px",
  borderTopLeftRadius: 0,
  borderColor: theme.palette.gray3,
  borderStyle: "solid",
  borderWidth: "1px",
  boxShadow: "4px 4px 20px 0px #0000000D",
}));

export interface CarRoundedPageItem<T> {
  label: string;
  className?: string;
  pendoClassName?: string;
  value: T;
  content?: ReactElement;
}
export interface CarRoundedPageControlProps<T> {
  value?: T;
  className?: string;
  sx?: BoxProps["sx"];
  items: CarRoundedPageItem<T>[];
  onChange: (value: T) => void;
}

export const CarRoundedPageControl = <T,>(
  props: CarRoundedPageControlProps<T>,
) => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      className={props.className}
    >
      <Box
        sx={{ display: "flex", justifyContent: "start", gap: 1 }}
        className={"rounded-page-control-header"}
      >
        {props.items.map((i, idx) => (
          <StyledTabButton
            key={idx}
            className={clsx(
              "rounded-page-control-button",
              { selected: props.value === i.value },
              i.className,
            )}
            onClick={(e) => props.onChange(i.value)}
          >
            {i.label}
            {i.pendoClassName && (
              <CarTooltipBox
                sx={{ ml: 0.5, mr: -1 }}
                className={i.pendoClassName}
              />
            )}
          </StyledTabButton>
        ))}
      </Box>
      <StyledPanel className="rounded-page-control-panel">
        {props.items.find((i) => i.value === props.value)?.content}
      </StyledPanel>
    </Box>
  );
};
