import { Box, BoxProps, Fab, useTheme, Zoom } from "@mui/material";
import { useRef, useState } from "react";
import { useScrollTo } from "app/useScrollToTop";
import { Update } from "@mui/icons-material";
import { UpdateProgressData, useControlPanelSave } from "./useControlPanelSave";
import { useMount } from "app/useMount";
import { CarUpdateProgress } from "components/UpdateProgress";
import { UsePlanResults } from "features/planResults/usePlanResults";

interface ControlPanelUpdateProps {
  sx?: BoxProps["sx"];
  data: UsePlanResults;
}

const fabMarginX = 64;
const fabMarginY = 26;
const fabSize = 56;

export const ControlPanelUpdate = (props: ControlPanelUpdateProps) => {
  const theme = useTheme();
  const { isUpdateAvailable, handleUpdate } = useControlPanelSave();
  const [isUpdating, setIsUpdating] = useState(false);

  const [top, setTop] = useState(0);
  // const [debug, setDebug] = useState("");
  const [progress, setProgress] = useState<UpdateProgressData>({
    current: 0,
    total: 0,
  });

  const scrollTo = useScrollTo();

  const updateClick = async () => {
    setIsUpdating(true);
    try {
      await handleUpdate(setProgress);
      props.data.refetch();
    } finally {
      setIsUpdating(false);
      setProgress({ current: 0, total: 0 });
    }
    scrollTo("top");
  };

  const requestRef = useRef<number>();
  const boxRef = useRef<HTMLElement>();

  const animate = (time: DOMHighResTimeStamp) => {
    requestRef.current = requestAnimationFrame(animate);
    const parentElem = boxRef.current?.parentElement;
    if (parentElem) {
      const parentRect = parentElem.getBoundingClientRect();

      const offset =
        window.innerHeight - (fabMarginY + fabSize) - parentRect.top;
      const minOffset = fabMarginY;
      const maxOffset = parentRect.height - (fabMarginY + fabSize);

      const finalOffset = Math.round(
        Math.max(minOffset, Math.min(offset, maxOffset))
      );

      if (top !== finalOffset) {
        setTop(finalOffset);
      }

      // setDebug(
      //   `window.innerHeight: ${window.innerHeight} parentRect.height: ${parentRect.height} offset: ${offset} maxOffset: ${maxOffset} `
      // );
    }
  };

  useMount(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  });

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  if (props.data.isPlanPublished) {
    return <></>;
  }

  return (
    <Box
      ref={boxRef}
      sx={{
        position: "absolute",
        width: 56,
        height: 56,
        right: -fabMarginX,
        top,
      }}
    >
      {/* <Box sx={{ position: "absolute", left: -1200, backgroundColor: "pink" }}>
        {debug}
      </Box> */}
      <Zoom
        in={isUpdateAvailable && !isUpdating}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${
            isUpdateAvailable && !isUpdating ? transitionDuration.exit : 0
          }ms`,
        }}
        unmountOnExit
      >
        <Fab
          sx={{
            "&:hover": {
              backgroundColor: "primary.light",
            },
          }}
          color="primary"
        >
          <Update
            sx={{ width: 48, height: 48, color: "white" }}
            onClick={updateClick}
          />
        </Fab>
      </Zoom>

      <Zoom
        in={isUpdateAvailable && isUpdating}
        timeout={transitionDuration}
        style={{
          transitionDelay: `${
            isUpdateAvailable && isUpdating ? transitionDuration.exit : 0
          }ms`,
        }}
        unmountOnExit
      >
        <CarUpdateProgress
          percent={
            progress.total > 0 ? (progress.current / progress.total) * 100 : 0
          }
        />
      </Zoom>
    </Box>
  );
};
