import { Box, Collapse } from "@mui/material";
import React from "react";
import { RISK_ASSESSMENT_ID } from "const";
import { CarRiskField } from "components/Inputs";
import { RiskDetailQuestionare } from "./RiskDetailQuestionare";
import { useRisk } from "features/risk/useRisk";
import { CarDisclosure } from "components/Disclosure";

export const RiskQuick = React.memo(() => {
  const { item, questions, handleChange, handleFinish, canFinish } = useRisk();

  const getRiskValue = () => {
    if (item?.using_questions) {
      return RISK_ASSESSMENT_ID;
    }
    if (item?.eq_weight === undefined || item?.eq_weight === undefined) {
      return "";
    } else {
      return `${item?.eq_weight},${item?.fi_weight}`;
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <CarRiskField
        sx={{
          ".MuiOutlinedInput-root": {
            backgroundColor: "white",
          },
        }}
        style={{ width: 170 }}
        label="Risk Ratio"
        value={getRiskValue()}
        onChange={(value) => {
          if (value === RISK_ASSESSMENT_ID) {
            handleChange({
              ...item,
              using_questions: true,
              eq_weight: 0,
              fi_weight: 100,
            });
          } else {
            const [eq_weight, fi_weight] = (value ?? "")
              .split(",")
              .map((i) => Number.parseInt(i) || 0);
            handleChange({
              ...item,
              using_questions: false,
              eq_weight,
              fi_weight,
            });
          }
        }}
      />
      <Collapse in={item?.using_questions && !!questions?.length}>
        {questions?.length && (
          <RiskDetailQuestionare
            answers={item?.question_answers ?? []}
            onChange={(question_answers) =>
              handleChange({
                ...item,
                question_answers,
              })
            }
            canFinish={canFinish}
            onFinish={handleFinish}
            questions={questions ?? []}
          />
        )}
      </Collapse>
      <CarDisclosure sx={{ mx: 2.5 }}>
        The risk level is driven by the length of time before goals are met.
        Please review the risk questionnaire to determine how your answers may
        impact the "risk barometer". Please see our disclosures for more
        information.
      </CarDisclosure>
    </Box>
  );
});
