import { Typography, Box, IconButton } from "@mui/material";
import { CarButton } from "components/Buttons";
import { Fragment } from "react";
import { formatCurrency, formatDate, isDefined, openInNewTab } from "utils";
import { CarDownload, CarIconDocument } from "icons";
import { UseSettingsBilling } from "./useSettingsBilling";

const upperCaseFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const SectionBilling = ({ data }: { data: UseSettingsBilling }) => {
  return (
    <>
      <Box
        sx={{
          mt: 5,
          maxWidth: 600,
          display: "grid",
          alignItems: "center",
          textAlign: "center",
          gridTemplateColumns: "1fr 1.5fr 1fr 1fr",
        }}
      >
        <Typography variant="caption">Cycle</Typography>
        <Typography variant="caption">Next Billing Period</Typography>
        <Typography variant="caption">Seats</Typography>
        <Typography variant="caption">Total</Typography>
        <Box
          sx={{
            my: 0.5,
            borderBottom: "solid 1px",
            borderColor: "gray6",
            gridColumn: "1 /span 4",
          }}
        />
        <Typography variant="h4SSemiBold">Yearly</Typography>
        <Typography variant="h4SSemiBold">
          {data.nextBillingPeriod ? formatDate(data.nextBillingPeriod) : "-"}
        </Typography>
        <Typography variant="h4SSemiBold">{data.seats}</Typography>
        <Typography variant="h4SSemiBold" color="primary">
          {isDefined(data.totalAmount) ? formatCurrency(data.totalAmount) : "-"}
        </Typography>
      </Box>
      {data.paymentMethods.length > 0 && (
        <>
          <Typography variant="h5Bold" sx={{ mt: 10 }}>
            Payment Methods
          </Typography>
          <Box
            sx={{
              mt: 2,
              maxWidth: 600,
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              gridTemplateColumns: "auto auto auto",
              gap: 1,
            }}
          >
            <Box
              sx={{
                mt: -1,
                borderBottom: "solid 1px",
                borderColor: "gray6",
                gridColumn: "1 /span 3",
              }}
            />
            {data.paymentMethods.map((i, idx) => (
              <Fragment key={idx}>
                <Box sx={{ m: "auto" }}>{i.card.icon}</Box>
                <Typography variant="h6SRegular" textAlign="start">
                  {`${i.card.name} ${i.cardNumber}`}
                </Typography>
                <Typography variant="h6SRegular" textAlign="start">
                  {`Expiration Date: ${i.expDate}`}
                </Typography>
              </Fragment>
            ))}
          </Box>
        </>
      )}
      {data.invoices.length > 0 && (
        <>
          <Typography variant="h5Bold" sx={{ mt: 10 }}>
            Invoice History
          </Typography>
          <Box
            sx={{
              mt: 4,
              maxWidth: 600,
              display: "grid",
              alignItems: "center",
              textAlign: "center",
              gridTemplateColumns: "1fr 1fr 1fr 80px",
              gap: 1,
            }}
          >
            <Typography variant="caption">Date</Typography>
            <Typography variant="caption">Total</Typography>
            <Typography variant="caption">Status</Typography>
            <Typography variant="caption" textAlign="center">
              Invoice
            </Typography>
            <Box
              sx={{
                mt: -1,
                borderBottom: "solid 1px",
                borderColor: "gray6",
                gridColumn: "1 /span 4",
              }}
            />
            {data.invoices.map((i) => (
              <Fragment key={i.id}>
                <Typography variant="h6SRegular">
                  {formatDate(i.created ?? "")}
                </Typography>
                <Typography variant="h6SRegular">
                  {formatCurrency(Number.parseFloat(i.amount_due ?? "0"), 2)}
                </Typography>
                <Typography variant="h6SRegular">
                  {upperCaseFirstLetter(i.status ?? "")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  {i.hosted_invoice_url && (
                    <IconButton
                      color="primary"
                      onClick={() => openInNewTab(i.hosted_invoice_url ?? "")}
                    >
                      <CarIconDocument />
                    </IconButton>
                  )}
                  {i.invoice_pdf && (
                    <IconButton
                      color="primary"
                      onClick={() => openInNewTab(i.invoice_pdf ?? "")}
                    >
                      <CarDownload />
                    </IconButton>
                  )}
                </Box>
              </Fragment>
            ))}
          </Box>
        </>
      )}
      <CarButton
        onClick={data.handleManageSubscription}
        variant="text"
        sx={{
          mt: 2,
          textDecoration: "underline",
          width: 250,
          fontSize: "23px",
          fontWeight: 700,
          letterSpacing: "-0.02em",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        Manage Subscription
      </CarButton>
    </>
  );
};
