import { useMemo } from "react";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { useSimulationQaReportSummaryCreateQuery } from "api/carApi.generated";
import { DecimalOptions, formatDateTime, isDefined } from "utils";
import { useAssetClassGroupSelector } from "app/useAssetClassGroupSelector";
import {
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";

interface UseSimulationSummaryParams {
  simulationJobId: string;
}

interface SimulationSummaryItem {
  id?: string;
  acLevel4Id: string;
  acName: string;
  isEquity: boolean;
  isFixedIncome: boolean;
  isCash: boolean;
  input_return_sigma?: number | null;
  output_real_return_mean?: number | null;
  output_real_return_sigma?: number | null;
  output_nominal_return_mean?: number | null;
  output_nominal_return_sigma?: number | null;
  ten_year_expected_return?: number | null;
  input_yield_sigma?: number | null;
  output_yield_sigma?: number | null;
  input_spread_mean?: number | null;
  output_spread_mean?: number | null;
  input_spread_sigma?: number | null;
  output_spread_sigma?: number | null;
}

const decimalOptions: DecimalOptions = {
  decimalPlaces: 2,
  forceShowDecimals: true,
};

export const useSimulationSummary = (params: UseSimulationSummaryParams) => {
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: params.simulationJobId,
  });

  const assetClassGroupSelector = useAssetClassGroupSelector();

  const { data, isLoading } = useSimulationQaReportSummaryCreateQuery({
    simulationQaReportRequestBase: {
      simulation_job_id: params.simulationJobId,
    },
  });

  const excelExport = useExcelExport();

  return useMemo(() => {
    const items =
      data?.asset_classes
        .map<SimulationSummaryItem | undefined>((i) => {
          const ac = assetClasses.items.find(
            (ac) => ac.level4Id === i.stat_result.asset_class4,
          );
          if (!ac) {
            return undefined;
          }

          return {
            acLevel4Id: ac.level4Id,
            acName: ac.name,
            isEquity: ac.isEquity,
            isFixedIncome: ac.isFixedIncome,
            isCash: ac.isCash,
            ...i.stat_result,
          };
        })
        .filter(isDefined)
        .filter((i) =>
          assetClassGroupSelector.isAssetClassInGroup(i.acLevel4Id),
        ) ?? [];

    const equityItems = items.filter((i) => i.isEquity);
    const fixedIncomeItems = items.filter((i) => i.isFixedIncome || i.isCash);
    const fixedIncomeSpreadItems = items.filter((i) => i.isFixedIncome);

    const tables: ExcelTable[] = [];

    if (data?.static_simulation) {
      tables.push({
        name: "Asset Classes",
        columns: [
          {
            label: "",
            format: ExcelDataFormat.general,
            fraction: 1.5,
            charWidth: 40,
          },
          {
            label: "Expected 10 Year Return",
            format: ExcelDataFormat.percent,
            decimalOptions,
          },
          {
            label: "Simulated 10 Year Real Return",
            format: ExcelDataFormat.percent,
            decimalOptions,
          },
          {
            label: "Simulated 10 Year Nominal Return",
            format: ExcelDataFormat.percent,
            decimalOptions,
          },
          {
            label: "Input Volatility",
            format: ExcelDataFormat.percent,
            decimalOptions,
          },
          {
            label: "Output Volatility (Nominal)",
            format: ExcelDataFormat.percent,
            decimalOptions,
          },
        ],
        rows: items.map((i) => [
          i.acName,
          i.ten_year_expected_return,
          i.output_real_return_mean,
          i.output_nominal_return_mean,
          i.input_return_sigma,
          i.output_nominal_return_sigma,
        ]),
      });
    } else {
      if (equityItems.length > 0) {
        tables.push({
          name: "Equities",
          columns: [
            {
              label: "",
              format: ExcelDataFormat.general,
              fraction: 1.5,
              charWidth: 40,
            },
            {
              label: "Expected 10 Year Return",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Simulated 10 Year Real Return",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Simulated 10 Year Nominal Return",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Input Volatility",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Output Volatility (Nominal)",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
          ],
          rows: equityItems.map((i) => [
            i.acName,
            i.ten_year_expected_return,
            i.output_real_return_mean,
            i.output_nominal_return_mean,
            i.input_return_sigma,
            i.output_nominal_return_sigma,
          ]),
        });
      }

      if (fixedIncomeItems.length > 0) {
        tables.push({
          name: "Fixed Income",
          columns: [
            {
              label: "",
              format: ExcelDataFormat.general,
              fraction: 1.5,
              charWidth: 40,
            },
            {
              label: "Simulated Real Return",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Simulated Nominal Return",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Simulated Return Volatility (Nominal)",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Simulated Yield Volatility",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
          ],
          rows: fixedIncomeItems.map((i) => [
            i.acName,
            i.output_real_return_mean,
            i.output_nominal_return_mean,
            i.output_nominal_return_sigma,
            i.output_yield_sigma,
          ]),
        });
      }

      if (fixedIncomeSpreadItems.length > 0) {
        tables.push({
          name: "Fixed Income Spreads",
          columns: [
            {
              label: "",
              format: ExcelDataFormat.general,
              fraction: 1.5,
              charWidth: 40,
            },
            {
              label: "Historical Average Spread",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Simulated Average Spread",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Historic Spread Volatility",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
            {
              label: "Simulated Spread Volatility",
              format: ExcelDataFormat.percent,
              decimalOptions,
            },
          ],
          rows: fixedIncomeSpreadItems.map((i) => [
            i.acName,
            i.input_spread_mean,
            i.output_spread_mean,
            i.input_spread_sigma,
            i.output_spread_sigma,
          ]),
        });
      }
    }

    const handleDownloadReports = () => {
      excelExport.exportAllExcel({
        fileName: `Simulation Summary - ${data?.simulation_name} - ${formatDateTime(
          data?.simulation_date,
        )
          .replaceAll("/", "-")
          .replaceAll(":", "-")} - ${assetClassGroupSelector.items.find(
          (i) => i.code === assetClassGroupSelector.value,
        )?.name}`,
        tables,
      });
    };

    return {
      isLoading:
        assetClasses.isLoading ||
        assetClassGroupSelector.isLoading ||
        isLoading,
      assetClassGroupSelector,
      tables,
      handleDownloadReports,
    };
  }, [assetClassGroupSelector, assetClasses, excelExport, data, isLoading]);
};
