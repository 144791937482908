import { useCallback } from "react";
import { v4 } from "uuid";
import {
  usePortfolioPortfolioGroupPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesCreateMutation,
  usePortfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesDestroyMutation,
  usePortfolioPortfolioGroupVersionModulesPartialUpdateMutation,
} from "api/carApi.generated";
import { useConfirm } from "app/useConfirm";
import {
  CarPortfolioAssetValue,
  usePortfolioGroupVersionData,
} from "../usePortfolioGroupVersionData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { useLocalStorage } from "features/general/useLocalStorage";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { useShowConfigSelector } from "./useShowConfigSelector";
import { checkDefined } from "utils";
import { usePortfolioGroupVersionStatTableData } from "../usePortfolioGroupVersionStatTableData";
import { useAssetClassStatistics } from "./useAssetClassStatistics";

const MAX_MODULE_COUNT = 7;

export const useEditPortfolioGroupEditCompare = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();
  const confirm = useConfirm();

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const showConfigSelector = useShowConfigSelector({
    portfolioGroupId,
    portfolioGroupVersionId,
    publishedVersionId,
    isSinglePortfolioMode: groupInfo.isSinglePortfolioMode,
  });

  const [hideZeroValues, setHideZeroValues] = useLocalStorage<boolean>(
    "EditPortfolioGroupEditCompare_hideZeroValues",
    true,
  );
  const [isBenchmarkEditable, setIsBenchmarkEditable] =
    useLocalStorage<boolean>(
      "EditPortfolioGroupEditCompare_isBenchmarkEditable",
      true,
    );

  const [isTreeView, setIsTreeView] = useLocalStorage<boolean>(
    "EditPortfolioGroupEditCompare_isTreeView",
    true,
  );

  const data = usePortfolioGroupVersionData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    show: showConfigSelector.config,
    hideCash: groupInfo.isSinglePortfolioMode,
    isBenchmarkEditable,
  });

  const versionStatTableData = usePortfolioGroupVersionStatTableData({
    bands: data.tableData.bands,
    skip: data.assetAllocationIsLoading,
  });

  const assetClassStatistics = useAssetClassStatistics({
    simulationGroupId: groupInfo.simulationGroupId,
  });

  const [createVersionModule] =
    usePortfolioPortfolioGroupVersionModulesCreateMutation();

  const [updateVersionModule] =
    usePortfolioPortfolioGroupVersionModulesPartialUpdateMutation();

  const [deleteVersionModule] =
    usePortfolioPortfolioGroupVersionModulesDestroyMutation();

  const [allocUpdate] =
    usePortfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdateMutation();

  const [portfolioGroupUpdate] =
    usePortfolioPortfolioGroupPartialUpdateMutation();

  const handleModuleAdd = async () => {
    const maxModuleIndex = data.tableData.bands.reduce((acc, band) => {
      const labelMatch = /model\s+(\d+)/gim.exec(band.label);

      const num = Number.parseInt(labelMatch?.[1] ?? "", 10) || 0;

      return Math.max(acc, num);
    }, 0);

    return createVersionModule({
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      portfolioModule: {
        id: v4(),
        title: `Model ${maxModuleIndex + 1}`,
        sort_order: data.sortedModuleItems.length,
      },
    });
  };

  const handleModuleRename = async (moduleId: string, newLabel: string) => {
    const moduleItem = data.sortedModuleItems.find((i) => i.id === moduleId);

    if (!moduleItem) {
      return;
    }

    await updateVersionModule({
      id: moduleItem.id ?? "",
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      patchedPortfolioModule: {
        title: newLabel,
      },
    });

    if (groupInfo.isSinglePortfolioMode) {
      await portfolioGroupUpdate({
        id: portfolioGroupId ?? "",
        patchedPortfolioGroup: {
          title: newLabel,
        },
      });
    }
  };

  const handleModuleDelete = async (moduleId: string) => {
    await confirm({
      label: "Delete Model",
      message: `Are you sure you want to delete model "${data.sortedModuleItems.find(
        (i) => i.id === moduleId,
      )?.title}"?`,
      applyLabel: "Delete",
      onApplying: async () => {
        await deleteVersionModule({
          portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
          id: moduleId,
        });
        return true;
      },
    });
  };

  const handleModuleMove = async (fromModuleId: string, toModuleId: string) => {
    if (fromModuleId === toModuleId) {
      return;
    }

    const startIndex = data.sortedModuleItems.findIndex(
      (i) => i.id === fromModuleId,
    );
    const endIndex = data.sortedModuleItems.findIndex(
      (i) => i.id === toModuleId,
    );

    const result = Array.from(data.sortedModuleItems);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((i, idx) => {
      if (i.sort_order !== idx) {
        updateVersionModule({
          id: i.id ?? "",
          portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
          patchedPortfolioModule: {
            sort_order: idx,
          },
        });
      }
    });
  };

  const handleAllocationChange = useCallback(
    (value: CarPortfolioAssetValue) => {
      allocUpdate({
        id: value.id,
        dataId: value.dataId,
        moduleId: value.moduleId,
        portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
        patchedPortfolioModuleDataAllocation: {
          allocation: value.allocation,
        },
      });
    },
    [portfolioGroupVersionId, allocUpdate],
  );

  return {
    portfolioGroupVersionId,
    portfolioGroupId,

    isLoading:
      showConfigSelector.isLoading ||
      data.isLoading ||
      versionStatTableData.isLoading ||
      groupInfo.isLoading ||
      assetClassStatistics.isLoading,

    title: groupInfo.title,
    simulationSuffix: groupInfo.getSimulationSuffix(true),
    simulationGroupName: groupInfo.getSimulationGroupName(),

    showConfigSelector,

    isError: data.isError,
    tableData: data.tableData,
    statTableData: versionStatTableData.statTableData,
    assetClassStatisticsItems: assetClassStatistics.items,

    isSinglePortfolioMode: groupInfo.isSinglePortfolioMode,

    canAddModule:
      data.sortedModuleItems.filter((i) => !i.is_cash).length <
      MAX_MODULE_COUNT,
    handleModuleAdd,
    handleModuleRename,
    handleModuleMove,
    handleModuleDelete,

    handleAllocationChange,

    isBenchmarkEditable,
    setIsBenchmarkEditable,

    hideZeroValues,
    setHideZeroValues,

    isTreeView,
    setIsTreeView,
  };
};
