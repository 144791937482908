import { Box } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarButton } from "components/Buttons";
import { useNavigate } from "react-router-dom";
import { editPortfolioGroupPages } from "route.const";
import { useScrollTo } from "app/useScrollToTop";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { useEffect } from "react";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupVersionModules } from "../usePortfolioGroupVersionModules";
import { AnalyzeSummary } from "./analyze/AnalyzeSummary";
import { CarSelectOptionField, CarSelectOption } from "components/Inputs";
import { AnalyzeModule } from "./analyze/AnalyzeModule";
import { useLocalStorage } from "features/general/useLocalStorage";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";

export const EditPortfolioGroupAnalyze = () => {
  const navigate = useNavigate();
  const scrollTo = useScrollTo();
  const pageContent = usePageContent();

  const [reportFocus, setReportFocus] = useLocalStorage(
    "EditPortfolioGroupAnalyze_reportFocus",
    "summary",
  );

  const { portfolioGroupId } = usePortfolioGroupParams();

  const groupInfo = usePortfolioGroupInfo({
    portfolioGroupId,
  });

  const { portfolioGroupVersionId } = groupInfo;

  const versionModules = usePortfolioGroupVersionModules({
    portfolioGroupVersionId,
    hideCash: true,
  });

  const moduleItems = versionModules.items.map<CarSelectOption<string>>(
    (i) => ({
      label: i.title,
      value: i.id ?? "",
    }),
  );

  const shouldSwitchToSummary =
    reportFocus !== "summary" &&
    !versionModules.isLoading &&
    !moduleItems.some((i) => i.value === reportFocus);

  useEffect(() => {
    if (shouldSwitchToSummary) {
      setReportFocus("summary");
    }
  }, [shouldSwitchToSummary, setReportFocus]);

  const handleNext = () => {
    navigate(`..\\${editPortfolioGroupPages.selection}`);
    scrollTo("top");
  };

  const reportFocusItems: CarSelectOption<string>[] = [
    {
      label: "Summary",
      value: "summary",
    },
    ...moduleItems,
  ];

  const reportFocusSelector = (
    <CarSelectOptionField
      sx={{
        width: 200,

        ".MuiInputLabel-root": {
          fontSize: "16px",
        },
        ".MuiOutlinedInput-root": {
          mt: 3,
        },
        ".MuiInputBase-input": {
          backgroundColor: "gray1",
        },
      }}
      label={
        <>
          Report Focus
          <CarTooltipBox
            sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
            className={pendoClasses.editPortfolioAnalyzeReportFocus}
          />
        </>
      }
      options={reportFocusItems}
      value={reportFocus}
      onChange={(value) => {
        if (value) {
          setReportFocus(value);
        }
      }}
    />
  );

  return (
    <TablePlaceHolder
      isLoading={versionModules.isLoading || pageContent.isLoading}
      rows={8}
      sx={{ mt: 5 }}
    >
      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          groupInfo.isSinglePortfolioMode
            ? CarPageContentCode.PORTFOLIO_ANALYSIS_ANALYZE_OVERVIEW
            : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE,
        )}
      />
      {groupInfo.isSinglePortfolioMode ? (
        <AnalyzeModule
          moduleId={moduleItems.at(0)?.value ?? ""}
          moduleIdx={0}
          reportFocusSelector={<></>}
        />
      ) : reportFocus === "summary" ? (
        <AnalyzeSummary reportFocusSelector={reportFocusSelector} />
      ) : (
        <AnalyzeModule
          moduleId={reportFocus}
          moduleIdx={moduleItems.findIndex((i) => i.value === reportFocus)}
          reportFocusSelector={reportFocusSelector}
        />
      )}
      {!groupInfo.isSinglePortfolioMode && (
        <Box
          sx={{
            mt: "auto",
            display: "flex",
            gap: 2.5,
            justifyContent: "center",
          }}
        >
          <CarButton sx={{ mt: 5 }} onClick={handleNext}>
            Next
          </CarButton>
        </Box>
      )}
    </TablePlaceHolder>
  );
};
