import { Box, Typography } from "@mui/material";
import { CarRadioGroup } from "components/Radio";
import { CarScrollBox } from "components/ScrollBox";
import { TablePlaceHolder } from "components/PlaceHolder";

interface PortfolioItem {
  value: string;
  label: string;
}
export interface FoundPortfoliosProps {
  label: string;
  value?: string;
  onChange: (value?: string) => void;
  items: Array<PortfolioItem>;
  isLoading: boolean;
}

export const FoundPortfolios = ({
  label,
  value,
  onChange,
  items,
  isLoading,
}: FoundPortfoliosProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        p: 6,
        pt: 3.5,
      }}
    >
      <Typography variant="par02SemiBold">{label}</Typography>
      <TablePlaceHolder
        sx={{
          mt: 1,
          height: 168,
        }}
        isLoading={isLoading}
      >
        <CarScrollBox
          sx={{
            mt: 1,
            height: 168,
            display: "flex",
            flexDirection: "column",
          }}
          autoHide={false}
        >
          <CarRadioGroup
            sx={{
              ".MuiFormGroup-root": {
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                rowGap: 0,
              },
            }}
            value={value ?? ""}
            onChange={(e, value) => onChange(value)}
            items={items}
          />
        </CarScrollBox>
      </TablePlaceHolder>
    </Box>
  );
};
