import {
  Box,
  FormControl,
  InputLabel,
  OutlinedTextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { CarTextField } from "./Inputs";
import { useDelayed } from "../useDelayed";
import { formatCurrency, formatPercent, isDefined } from "utils";
import { forwardRef, Ref } from "react";

interface NumberFormatCustomProps {
  inputRef: (instance: typeof NumericFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  prefix?: string;
  suffix?: string;
  thousandSeparator?: boolean;
  minValue?: number;
  maxValue?: number;
}

const NumberFormatCustom = forwardRef(
  (props: NumberFormatCustomProps, ref: Ref<any>) => {
    const { inputRef, onChange, maxValue, minValue, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          if ((other as any).value !== values.value) {
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
          }
        }}
        thousandSeparator={props.thousandSeparator}
        // isNumericString
        prefix={props.prefix}
        suffix={props.suffix}
        isAllowed={({ floatValue }) => {
          const minValue1 = minValue ?? -2147483647;
          const maxValue1 = maxValue ?? 2147483646;
          return (
            floatValue === undefined ||
            (floatValue >= minValue1 && floatValue <= maxValue1)
          );
        }}
      />
    );
  },
);

export interface CarNumberFieldProps
  extends Omit<OutlinedTextFieldProps, "variant" | "value" | "onChange"> {
  thousandSeparator?: boolean;
  prefix?: string;
  suffix?: string;
  value?: number | null;
  minValue?: number;
  maxValue?: number;
  decimalPlaces?: number;
  removeZeroOnFocus?: boolean;
  defaultValue?: number;
  onChange: (value?: number) => void;
}

export const CarNumberField = ({
  removeZeroOnFocus,
  defaultValue,
  thousandSeparator,
  prefix,
  suffix,
  minValue,
  maxValue,
  decimalPlaces: decimalScale,
  value,
  onChange,
  InputProps,
  ...props
}: CarNumberFieldProps) => (
  <CarTextField
    {...props}
    value={
      isDefined(defaultValue) && value === defaultValue
        ? null
        : value?.toString()
    }
    onFocus={(e) => {
      if (removeZeroOnFocus && value === 0) {
        onChange(undefined);
      } else {
        setTimeout(() => {
          e.target.select();
        }, 100);
      }
    }}
    onChange={(value) => {
      const newValue = value ? Number.parseFloat(value) : Number.NaN;
      onChange(Number.isNaN(newValue) ? defaultValue : newValue);
    }}
    InputProps={{
      ...InputProps,
      inputComponent: NumberFormatCustom as any,
    }}
    inputProps={{
      thousandSeparator,
      decimalScale: decimalScale ?? 0,
      prefix,
      suffix,
      minValue,
      maxValue,
      ...props.inputProps,
      style: { textAlign: "center", paddingLeft: 4, paddingRight: 4 },
    }}
  />
);

export const CarNumberFieldDelayed = ({ ...props }: CarNumberFieldProps) => {
  const delayed = useDelayed(props);
  return <CarNumberField {...props} {...delayed} />;
};

// es todo, Ask if this should be replaced with CarFactorPercentField ???
export const CarPercentField = (props: CarNumberFieldProps) => (
  <CarNumberField thousandSeparator suffix="%" {...props} />
);

export const CarPercentFieldDelayed = ({ ...props }: CarNumberFieldProps) => {
  const delayed = useDelayed(props);
  return <CarPercentField {...props} {...delayed} />;
};

export interface CarFactorPercentFieldProps
  extends Omit<CarNumberFieldProps, "minValue" | "maxValue"> {
  minFactorValue?: number;
  maxFactorValue?: number;
}

export const CarFactorPercentField = ({
  minFactorValue,
  maxFactorValue,
  value,
  onChange,
  ...props
}: CarFactorPercentFieldProps) => (
  <CarNumberField
    thousandSeparator
    suffix="%"
    {...props}
    value={value ? value * 100 : value}
    onChange={(newValue) => onChange(newValue ? newValue / 100 : newValue)}
    minValue={isDefined(minFactorValue) ? minFactorValue * 100 : 0}
    maxValue={isDefined(maxFactorValue) ? maxFactorValue * 100 : 100}
  />
);

export const CarFactorPercentFieldDelayed = ({
  ...props
}: CarFactorPercentFieldProps) => {
  const delayed = useDelayed(props);
  return <CarFactorPercentField {...props} {...delayed} />;
};

export const CarCurrencyField = (props: CarNumberFieldProps) => (
  <CarNumberField thousandSeparator prefix="$" {...props} />
);

export const CarCurrencyFieldDelayed = ({ ...props }: CarNumberFieldProps) => {
  const delayed = useDelayed(props);
  return <CarCurrencyField {...props} {...delayed} />;
};

export interface CarNumberCalcFieldProps extends TypographyProps {
  value?: number;
  formatter?: (value: number) => string;
  label?: string;
}

export const CarNumberCalcField = ({
  label,
  value,
  formatter,
  className,
  ...props
}: CarNumberCalcFieldProps) => {
  return (
    <FormControl className={className}>
      {label ? (
        <InputLabel htmlFor="car-number-calc-field">{label}</InputLabel>
      ) : undefined}
      <Box
        id="car-number-calc-field"
        sx={{
          mt: label ? "20px" : undefined,
          width: "100%",
          height: "42.6875px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          textAlign="center"
          variant="par01Regular"
          {...props}
          sx={{ color: "black", ...props.sx }}
        >
          {value === undefined
            ? "-"
            : formatter
            ? formatter(value)
            : value.toString()}
        </Typography>
      </Box>
    </FormControl>
  );
};

export const CarCurrencyCalcField = (props: CarNumberCalcFieldProps) => (
  <CarNumberCalcField formatter={formatCurrency} {...props} />
);

export const CarPercentCalcField = (props: CarNumberCalcFieldProps) => (
  <CarNumberCalcField formatter={formatPercent} {...props} />
);
