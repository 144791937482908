import { Fragment, useRef } from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import {
  CarPortfolioAssetBand,
  CarPortfolioAssetTableData,
  CarPortfolioAssetValue,
  CarPortfolioStatRow,
} from "./usePortfolioGroupVersionData";
import {
  formatDisplayTypeValue,
  formatPercent,
  isDefined,
  isOddEven,
} from "utils";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { useDialog } from "app/useDialog";
import { PortfolioGroupFiftyPercentileDialog } from "./PortfolioGroupFiftyPercentileDialog";

interface LabelHeaderProps {
  sx?: BoxProps["sx"];
  label?: string;
  showVariationsLabel?: boolean;
}

const LabelHeader = (props: LabelHeaderProps) => {
  return (
    <Box
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "table.background.header",
        "&:last-of-type": {
          borderRightWidth: 1,
        },
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
            backgroundColor: "white",
          },
        },
        ...props.sx,
      }}
    >
      <Box
        sx={{
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.label && (
          <Typography variant="h6SBold">{props.label}</Typography>
        )}
      </Box>
      {props.showVariationsLabel ? (
        <Box
          sx={{
            flex: "auto",
            display: "flex",
            alignItems: "center",
            backgroundColor: "white",
            px: 1.5,
          }}
        >
          <Typography variant="par02Regular">
            Portfolio Model Variations
          </Typography>
          <CarTooltipBox
            sx={{ ml: "auto", mr: -1.25 }}
            className={pendoClasses.editPortfolioPortfolioModelVariations}
          />
        </Box>
      ) : (
        <Box sx={{ flex: "auto" }} />
      )}
    </Box>
  );
};

const getGridTemplateColumns = (showPercentile: boolean, bandCount: number) =>
  bandCount > 1
    ? `1fr ${showPercentile ? "80px" : ""} 0.5fr repeat(${bandCount - 1}, 1fr)`
    : `0.3fr ${showPercentile ? "80px" : ""} 1fr`;

interface ModuleHeaderProps {
  sx?: BoxProps["sx"];
  band: CarPortfolioAssetBand;
}

const ModuleHeader = (props: ModuleHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={ref}
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        borderLeftWidth: props.band.isCash ? 1 : 2,
        borderRightWidth: 2,
        backgroundColor: "table.background.header",
        "&:last-of-type": {
          borderRightWidth: 1,
        },
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
          },
        },
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          px: 1,
          py: 2,
          height: 60,
          width: "100%",
        }}
      >
        <Typography variant="h6SBold">{props.band.label}</Typography>
      </Box>
      <Box
        sx={{
          flex: "auto",
          display: "grid",
          gridTemplateColumns: `repeat(${props.band.columns.length}, 1fr)`,
          "& > *": {
            backgroundColor: "white",
            borderStyle: "solid",
            borderColor: "table.border",
            borderWidth: 0,
            borderLeftWidth: 1,
            "&:first-of-type": {
              borderLeftWidth: 0,
            },
            py: 1.5,
            px: 0.5,
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          },
        }}
      >
        {props.band.columns.map((i) => (
          <Typography key={i.columnId} variant="mediumItalic">
            {i.label}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

interface TimeFrameRowProps {
  item: CarPortfolioStatRow;
  showPercentile: boolean;
}

const TimeFrameRow = (props: TimeFrameRowProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        minHeight: 40,
        gridTemplateColumns: "1fr",
        backgroundColor: "table.background.even",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(
            props.showPercentile,
            props.item.modules.length,
          ),
        }}
      >
        <Box
          sx={{
            marginTop: "-1px",
            marginLeft: "-1px",
            border: 1,
            borderColor: "table.border",
            p: 1.5,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Typography variant="par02Regular">E(R) Time Frame</Typography>
          <CarTooltipBox
            sx={{ ml: "auto", mr: -1.25 }}
            className={props.item.pendoClassName + "_timeFrame"}
          />
        </Box>
        {props.showPercentile && (
          <Box
            sx={{
              marginTop: "-1px",
              marginLeft: "-1px",
              border: 1,
              borderColor: "table.border",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        )}
        {props.item.modules.map((md, idx, arr) => {
          return (
            <Box
              key={md.moduleId}
              sx={{
                marginTop: "-1px",
                marginLeft: "-1px",
                border: 1,
                borderColor: "table.border",
                borderLeftWidth: md.isCash ? 1 : 2,
                borderRightWidth: 2,
                "&:last-of-type": {
                  borderRightWidth: 1,
                },

                display: "grid",
                gridTemplateColumns: `repeat(${md.values.length}, 1fr)`,

                "& > *": {
                  borderStyle: "solid",
                  borderColor: "table.border",
                  borderWidth: 0,
                  borderLeftWidth: 1,
                  "&:first-of-type": {
                    borderLeftWidth: 0,
                  },

                  overflow: "hidden",
                  textOverflow: "ellipsis",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 1,
                  "& > *": {
                    maxWidth: 54,
                  },
                },
              }}
            >
              {md.values.map((v) => (
                <Typography key={v.columnId} variant="mediumItalic">
                  {formatDisplayTypeValue("INTEGER")(v.horizonYear)}
                </Typography>
              ))}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

interface RowProps {
  sx?: BoxProps["sx"];
  item: CarPortfolioStatRow;
  isOdd?: boolean;
  isLastRow: boolean;
  showPercentile: boolean;
  tableData: CarPortfolioAssetTableData;
}

const Row = (props: RowProps) => {
  const dialog = useDialog();
  const isExpectedReturn = props.item.code === "EXPECTED_RETURN";

  const handleValueClick = (value: CarPortfolioAssetValue) => {
    if (value.simulationJobId && isDefined(value.horizonYear)) {
      dialog(PortfolioGroupFiftyPercentileDialog, {
        moduleId: value.moduleId,
        dataId: value.dataId,
        tableData: props.tableData,
        simulationJobId: value.simulationJobId,
        horizonYear: value.horizonYear,
      });
    }
  };

  return (
    <Box
      sx={{
        display: "grid",
        minHeight: 60,
        gridTemplateColumns: "1fr",
        backgroundColor: props.isOdd
          ? "table.background.even"
          : "table.background.odd",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(
            props.showPercentile,
            props.item.modules.length,
          ),
        }}
      >
        <Box
          sx={{
            marginTop: "-1px",
            marginLeft: "-1px",
            border: 1,
            borderColor: "table.border",
            p: 1.5,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            overflow: "hidden",
            borderBottomLeftRadius: props.isLastRow ? "5px" : undefined,
          }}
        >
          <Typography variant="par02Regular">{props.item.name}</Typography>
          <CarTooltipBox
            sx={{ ml: "auto", mr: -1.25 }}
            className={props.item.pendoClassName}
          />
        </Box>
        {props.showPercentile && (
          <Box
            sx={{
              marginTop: "-1px",
              marginLeft: "-1px",
              border: 1,
              borderColor: "table.border",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="par02Regular">
              {isDefined(props.item.percentile)
                ? formatPercent(props.item.percentile)
                : "-"}
            </Typography>
          </Box>
        )}
        {props.item.modules.map((md, idx, arr) => {
          const isLastBand = idx === arr.length - 1;
          return (
            <Box
              key={md.moduleId}
              sx={{
                marginTop: "-1px",
                marginLeft: "-1px",
                border: 1,
                borderColor: "table.border",
                borderLeftWidth: md.isCash ? 1 : 2,
                borderRightWidth: 2,
                borderBottomRightRadius:
                  props.isLastRow && isLastBand ? "5px" : undefined,
                "&:last-of-type": {
                  borderRightWidth: 1,
                },

                display: "grid",
                gridTemplateColumns: `repeat(${md.values.length}, 1fr)`,

                "& > *": {
                  borderStyle: "solid",
                  borderColor: "table.border",
                  borderWidth: 0,
                  borderLeftWidth: 1,
                  "&:first-of-type": {
                    borderLeftWidth: 0,
                  },

                  overflow: "hidden",
                  textOverflow: "ellipsis",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  py: 1,
                  "& > *": {
                    maxWidth: 54,
                  },
                },
              }}
            >
              {md.values.map((v) => (
                <Typography
                  key={v.columnId}
                  variant="par02Regular"
                  sx={
                    isExpectedReturn
                      ? {
                          cursor: "pointer",
                          textDecoration: "underline",
                          "&.MuiTypography-par02Regular": {
                            color: "chartsColor.blue",
                          },
                        }
                      : undefined
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleValueClick(v);
                  }}
                >
                  {formatDisplayTypeValue(props.item.displayType)(v.allocation)}
                </Typography>
              ))}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

interface PortfolioGroupAssetStatTableProps {
  tableData: CarPortfolioAssetTableData;
  rows: CarPortfolioStatRow[];
  sx?: BoxProps["sx"];
  showPercentile: boolean;
}

export const PortfolioGroupAssetStatTable = ({
  sx,
  tableData,
  rows,
  showPercentile,
}: PortfolioGroupAssetStatTableProps) => {
  const hasTimeFrameRow = rows.some((i) => i.code === "EXPECTED_RETURN");

  return (
    <Box
      sx={{
        display: "grid",
        borderColor: "table.border",
        ".MuiTypography-root": {
          color: "table.text",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(
            showPercentile,
            tableData.bands.length,
          ),
          position: "sticky",
          top: 0,
          backgroundColor: "table.header",
          zIndex: 10,
          "& > *:first-of-type": {
            borderTopLeftRadius: "5px",
          },
          "& > *:last-of-type": {
            borderTopRightRadius: "5px",
          },
        }}
      >
        <LabelHeader showVariationsLabel />
        {showPercentile && <LabelHeader label="PCTL" />}
        {tableData.bands.map((i, idx, arr) => (
          <ModuleHeader key={i.moduleId} band={i} />
        ))}
      </Box>
      {rows.map((i, idx, arr) => {
        const row = (
          <Row
            key={i.id}
            item={i}
            isOdd={hasTimeFrameRow ? !isOddEven(idx) : isOddEven(idx)}
            isLastRow={idx === arr.length - 1}
            showPercentile={showPercentile}
            tableData={tableData}
          />
        );

        return i.code === "EXPECTED_RETURN" ? (
          <Fragment key={i.id}>
            <TimeFrameRow showPercentile={showPercentile} item={i} />
            {row}
          </Fragment>
        ) : (
          row
        );
      })}
    </Box>
  );
};
