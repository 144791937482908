import { PdfChart, PdfChartProps } from "components/Pdf";
import { ChartConfiguration } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { useTheme } from "@mui/material";
import { Text, View } from "@react-pdf/renderer";
import { formatPercentFactor } from "utils";
import { ChartDataset } from "chart.js";
import { getSectorSummaryChartData } from "../editPortfolioGroup/ChartSectorSummary";
import { CarGicsItem } from "app/useGics";

interface PortfolioGroupSectorSummaryPdfChartProps
  extends Omit<PdfChartProps, "config"> {
  data: CarGicsItem[];
  label1: string;
  label2: string;
}

const PdfChartLegend = ({ config }: { config: ChartConfiguration<"bar"> }) => {
  return (
    <View
      style={{
        marginTop: 6,
        marginLeft: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {config.data.datasets.map((ds, idx) => (
        <View
          key={idx}
          style={{
            marginLeft: 6,
            marginRight: 6,
            flexDirection: "row",
            alignItems: "flex-start",
            height: 34,
          }}
        >
          <View
            style={{
              width: 10,
              height: 10,
              backgroundColor: String(ds.backgroundColor ?? "black"),
              marginRight: 4,
            }}
          />
          <Text
            style={{
              fontSize: 10,
            }}
          >
            {ds.label}
          </Text>
        </View>
      ))}
    </View>
  );
};

export const PortfolioGroupSectorSummaryPdfChart = ({
  data,
  label1,
  label2,
  ...props
}: PortfolioGroupSectorSummaryPdfChartProps) => {
  const theme = useTheme();

  const { bar1, bar2, yLabel, xLabels } = getSectorSummaryChartData({
    data,
    label1,
    label2,
  });

  const font = {
    size: 14,
    weight: "400",
    lineHeight: 1,
  };

  const datasets: Array<ChartDataset<"bar", number[]>> = [
    {
      ...bar1,
      backgroundColor: theme.palette.chartsColor.green,
      barPercentage: 0.8,
      categoryPercentage: 0.4,
    },
    {
      ...bar2,
      backgroundColor: theme.palette.chartsColor.blue,
      barPercentage: 0.8,
      categoryPercentage: 0.4,
    },
  ];

  const config: ChartConfiguration<"bar", number[]> = {
    type: "bar",
    options: {
      devicePixelRatio: 4,
      responsive: false,
      animation: false,
      borderColor: "transparent",

      layout: {
        padding: { right: 1 }, // for some reason right border is cropped, so add right padding to fit it in image
      },

      scales: {
        x: {
          type: "category",
          ticks: {
            font,
            color: theme.palette.black,
            padding: 6,
          },
          border: { display: false },
          grid: {
            drawTicks: false,
            color: theme.palette.gray2,
            lineWidth: 1,
          },
          title: {
            display: false,
          },
          labels: xLabels,
        },
        y: {
          beginAtZero: true,
          offset: false,
          ticks: {
            font,
            color: theme.palette.black,
            padding: 6,
            callback: (value) =>
              typeof value === "number" ? formatPercentFactor(value, 1) : value,
          },
          border: { display: false },
          grid: {
            drawTicks: false,
            color: theme.palette.gray2,
            lineWidth: 1,
          },
          title: {
            display: true,
            text: yLabel,
            color: theme.palette.black,
            font,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        roundedBackground: {
          contextColor: theme.palette.white,
          backgroundColor: "#FAFAFA", //theme.palette.gray1,
          borderColor: "#B1B1B3", // theme.palette.gray7,
          borderRadius: 0,
        },
      },
    },
    data: {
      datasets,
    },
    plugins: [ChartPlugins.roundedBackground],
  };

  return (
    <>
      <PdfChart config={config as any} {...props} />
      <PdfChartLegend config={config as any} />
    </>
  );
};
