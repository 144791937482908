import { Box, Button, alpha } from "@mui/material";
import blackDiamondLogo1 from "assets/black_diamond_logo_1.png";
import { useQuestionnaireExternalListAvailableIntegratorsListQuery } from "api/carApi.generated";
import { refetchOnFocus } from "const";
import { styled } from "@mui/material";
import { successColor, errorColor } from "../../theme";
import { useDialog } from "app/useDialog";
import { BlackDiamondConnectDialog } from "./BlackDiamondConnectDialog";
import { TablePlaceHolder } from "components/PlaceHolder";
import { BlackDiamondDisconnectDialog } from "./BlackDiamondDisconnectDialog";

const IntegrationButton = styled(Button)(({ theme, color }) => {
  const mainColor = color === "success" ? successColor : errorColor;
  return {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    borderRadius: 7,
    height: 48,
    width: 212,
    boxShadow: `3px 3px 10px 0px ${alpha(mainColor, 0.3)} !important`,

    "&.MuiButton-outlined": {
      color: mainColor,
      borderColor: mainColor,
      backgroundColor: "white",
    },
    "&.MuiButton-contained": {
      backgroundColor: mainColor,
      color: "white",
    },
  };
});

interface IntegrationItemProps {
  id: string;
  logoUrl: string;
  isConnected: boolean;
  onConnect: (id: string) => void;
  onDisconnect: (id: string) => void;
}

const IntegrationItem = (props: IntegrationItemProps) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 2.5 }}>
    <Box
      sx={{
        width: 214,
        height: 132,
        borderRadius: "5px",
        border: "solid 4px",
        borderColor: "caravelOrangePrimary",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box component={"img"} src={props.logoUrl} sx={{ width: "100%" }} />
    </Box>
    {props.isConnected ? (
      <>
        <IntegrationButton color="success" variant="contained">
          Connected
        </IntegrationButton>
        <IntegrationButton
          variant="outlined"
          onClick={() => props.onDisconnect(props.id)}
        >
          Disconnect
        </IntegrationButton>
      </>
    ) : (
      <IntegrationButton
        variant="outlined"
        color="success"
        onClick={() => props.onConnect(props.id)}
      >
        Connect
      </IntegrationButton>
    )}
  </Box>
);

export const SettingsIntegrations = () => {
  const dialog = useDialog();

  const integratorsList =
    useQuestionnaireExternalListAvailableIntegratorsListQuery(undefined, {
      refetchOnFocus,
    });

  const integratorItems: IntegrationItemProps[] = [
    {
      id: "BlackDiamond",
      logoUrl: blackDiamondLogo1,
      onConnect: async () => {
        await dialog(BlackDiamondConnectDialog, {});
      },
      onDisconnect: async () => {
        await dialog(BlackDiamondDisconnectDialog, {});
      },
      isConnected: false,
    },
  ]
    .filter((i) => integratorsList.data?.some((il) => il.id === i.id))
    .map((i) => ({
      ...i,
      isConnected:
        integratorsList.data?.find((il) => il.id === i.id)?.enabled ?? false,
    }));

  return (
    <TablePlaceHolder isLoading={integratorsList.isLoading} rows={5}>
      <Box sx={{ display: "flex" }}>
        {integratorItems.map((i) => (
          <IntegrationItem key={i.id} {...i} />
        ))}
      </Box>
    </TablePlaceHolder>
  );
};
