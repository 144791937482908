import { Typography } from "@mui/material";
import { BoxProps } from "@mui/system";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { UsePortfolioSummary } from "./usePortfolioSummary";
import { ChartSectorSummaryDashboard } from "pages/assetAllocation/editPortfolioGroup/ChartSectorSummary";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

interface PortfolioSectorAnalysisProps {
  portfolioSummary: UsePortfolioSummary;
  sx?: BoxProps["sx"];
}

export const PortfolioSectorAnalysis = ({
  portfolioSummary,
  sx,
}: PortfolioSectorAnalysisProps) => {
  return (
    <RootPageBox
      sx={{
        p: 3,
        ...sx,
        gap: 3,
      }}
    >
      <TablePlaceHolder isLoading={portfolioSummary.isLoading} rows={12}>
        <Typography variant="h5SSemiBold">
          {`Sector Analysis - ${portfolioSummary.title}`}
          <CarTooltipBox
            sx={{ display: "inline-block", ml: 0.5, mb: -0.25 }}
            className={pendoClasses.portfolioAnalysisDashboardSectorAnalysis}
          />
        </Typography>
        <ChartSectorSummaryDashboard {...portfolioSummary.sectorSummary} />
      </TablePlaceHolder>
    </RootPageBox>
  );
};
