import { Font } from "@react-pdf/renderer";

// @ts-ignore
import proximanovaRegular from "assets/fonts/proximanova-regular-webfont.woff";
// @ts-ignore
import proximanovaRegularIt from "assets/fonts/proximanova-regularit-webfont.woff";
// @ts-ignore
import proximanovaSemiBold from "assets/fonts/proximanova-semibold-webfont.woff";
// @ts-ignore
import proximanovaBold from "assets/fonts/proximanova-bold-webfont.woff";
// @ts-ignore
import proximanovaCondensedRegular from "assets/fonts/proximanova-condensed-regular-webfont.woff";
// @ts-ignore
import proximanovaCondensedBold from "assets/fonts/proximanova-condensed-bold-webfont.woff";
import { useState } from "react";
import { useMount } from "app/useMount";

export const proximanovaFontFamily = "proximanova";
export const proximanovaCondensedFontFamily = "proximanova-condensed";

Font.register({
  family: proximanovaFontFamily,
  fonts: [
    {
      fontWeight: "normal",
      src: proximanovaRegular,
    },
    {
      fontWeight: "normal",
      fontStyle: "italic",
      src: proximanovaRegularIt,
    },
    {
      fontWeight: "semibold",
      src: proximanovaSemiBold,
    },
    {
      fontWeight: "bold",
      src: proximanovaBold,
    },
  ],
});

Font.register({
  family: proximanovaCondensedFontFamily,
  fonts: [
    {
      fontWeight: "normal",
      src: proximanovaCondensedRegular,
    },
    {
      fontWeight: "bold",
      src: proximanovaCondensedBold,
    },
  ],
});

const dpi = 72;

export const pdfLetterSize = {
  width: 8.5 * dpi,
  height: 11 * dpi,
};

export const usePdfFontLoad = () => {
  const [isLoading, setIsLoading] = useState(true);

  useMount(() => {
    document.fonts.add(
      new FontFace(
        proximanovaCondensedFontFamily,
        `url(${proximanovaCondensedRegular})`,
      ),
    );
    document.fonts.add(
      new FontFace(
        proximanovaCondensedFontFamily,
        `url(${proximanovaCondensedBold})`,
        {
          weight: "700",
        },
      ),
    );

    Promise.all(Array.from(document.fonts.values()).map((i) => i.load()))
      .then(() => {
        setIsLoading(false);
      })
      .catch((e) => {
        console.error("usePdfFontLoad", e);
        setIsLoading(false);
      });
  });

  return {
    isLoading,
  };
};
