import React, { useCallback } from "react";

import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import {
  ScrollContextProvider,
  scrollTo,
  ScrollToDestination,
} from "./useScrollToTop";
import { Drawer, styled, useMediaQuery, useTheme } from "@mui/material";
import { SideBar } from "./SideBar";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "./hooks";
import { closeDrawer, selectDrawerIsOpened } from "./drawerSlice";
import { useDispatch } from "react-redux";

const SimpleBarStyled = styled(SimpleBar)({
  height: "100%",
  width: "100%",
  ".main-page-layout-content": {
    minHeight: "100%",
    display: "flex",
  },
});

export const MainPageLayout = () => {
  const drawerOpen = useAppSelector(selectDrawerIsOpened);

  const theme = useTheme();
  const isSideBarVisible = useMediaQuery(theme.breakpoints.up("xl"));

  const scrollableNodeRef = React.createRef<HTMLDivElement>();

  const dispatch = useDispatch();

  const handleCloseDrawer = () => dispatch(closeDrawer());

  const handleScroll = useCallback(
    (destination: ScrollToDestination) => {
      setTimeout(() => {
        if (scrollableNodeRef.current) {
          scrollTo(destination, scrollableNodeRef.current);
        }
      }, 100);
    },
    [scrollableNodeRef],
  );

  return (
    <>
      {isSideBarVisible ? (
        <SideBar />
      ) : (
        <Drawer anchor="left" open={drawerOpen} onClose={handleCloseDrawer}>
          <SideBar afterClick={handleCloseDrawer} />
        </Drawer>
      )}
      <SimpleBarStyled
        classNames={{ contentEl: "main-page-layout-content" }}
        scrollableNodeProps={{ ref: scrollableNodeRef }}
      >
        <ScrollContextProvider value={handleScroll}>
          <Outlet />
        </ScrollContextProvider>
      </SimpleBarStyled>
    </>
  );
};
