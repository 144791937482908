import { SectionState } from "types";
import { useAdditionalIncome } from "../additionalIncome/useAdditionalIncome";
import { useAccount } from "../assets/useAccount";
import { useRelationship } from "../clientFamily/useRelationship";
import { useGoal } from "../goals/useGoal";
import { useHealthcare } from "../healthcare/useHealthcare";
import { useRealEstate } from "../realEstate/useRealEstate";
import { useRisk } from "../risk/useRisk";
import { useSocialSecurity } from "../socialSecurity/useSocialSecurity";

enum FinancialPlanSectionIndex {
  ClientFamily = 1,
  SocialSecurity = 2,
  Healthcare = 3,
  AdditionalIncome = 4,
  Assets = 5,
  RealEstate = 6,
  Goals = 7,
  Risk = 8,
}

export const useFinancialPlanNavigate = () => {
  const relationship = useRelationship();
  const socialSecurity = useSocialSecurity();
  const healthcare = useHealthcare();
  const additionalIncome = useAdditionalIncome();
  const account = useAccount();
  const realEstate = useRealEstate();
  const goal = useGoal();
  const risk = useRisk();

  const sections = [
    {
      pageIndex: FinancialPlanSectionIndex.ClientFamily,
      label: "Client Family",
      valid: relationship.state === SectionState.complete,
    },
    {
      pageIndex: FinancialPlanSectionIndex.SocialSecurity,
      label: "Social Security",
      valid: socialSecurity.state !== SectionState.workInProgress,
    },
    {
      pageIndex: FinancialPlanSectionIndex.Healthcare,
      label: "Post-Retirement Health Care",
      valid: healthcare.state !== SectionState.workInProgress,
    },
    {
      pageIndex: FinancialPlanSectionIndex.AdditionalIncome,
      label: "Additional Income",
      valid: additionalIncome.state !== SectionState.workInProgress,
    },
    {
      pageIndex: FinancialPlanSectionIndex.Assets,
      label: "Assets",
      valid: account.state === SectionState.complete,
    },
    {
      pageIndex: FinancialPlanSectionIndex.Goals,
      label: "Goals",
      valid: goal.state === SectionState.complete,
    },
    {
      pageIndex: FinancialPlanSectionIndex.RealEstate,
      label: "Real Estate",
      valid: realEstate.state !== SectionState.workInProgress,
    },
    {
      pageIndex: FinancialPlanSectionIndex.Risk,
      label: "Risk",
      valid: risk.state === SectionState.complete,
    },
  ];

  return {
    isLoading:
      relationship.isLoading ||
      socialSecurity.isLoading ||
      healthcare.isLoading ||
      additionalIncome.isLoading ||
      account.isLoading ||
      realEstate.isLoading ||
      goal.isLoading ||
      risk.isLoading,
    canGoToWorkbench: sections.every((i) => i.valid),
    invalidSection: sections.find((s) => !s.valid),
  };
};

export type UseFinancialPlanNavigate = ReturnType<
  typeof useFinancialPlanNavigate
>;
