import { Box, Typography } from "@mui/material";
import { CarCheckbox, CarCheckboxGroup } from "components/Checkbox";
import { CarScrollBox } from "components/ScrollBox";
import { TablePlaceHolder } from "components/PlaceHolder";

interface AccountItem {
  value: string;
  label: string;
}

export interface FoundAccountsProps {
  portfolioName?: string;
  checkedItems: Array<string>;
  onChange: (value: Array<string>) => void;
  items: Array<AccountItem>;
  isLoading: boolean;
}

export const FoundAccounts = ({
  items,
  portfolioName,
  checkedItems,
  onChange,
  isLoading,
}: FoundAccountsProps) => {
  const allItemsChecked = items.every((i) =>
    checkedItems.some((c) => c === i.value)
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        p: 6,
        pt: 3.5,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="par02SemiBold">{`Found Accounts${
          portfolioName ? " in " + portfolioName : ""
        }:`}</Typography>
        <CarCheckbox
          sx={{
            visibility: isLoading || items.length === 0 ? "hidden" : "visible",
          }}
          label="Select All"
          checked={allItemsChecked}
          onChange={(e, checked) => {
            if (allItemsChecked) {
              onChange([]);
            } else {
              onChange(items.map((i) => i.value ?? ""));
            }
          }}
        />
      </Box>
      <TablePlaceHolder
        sx={{
          mt: 1,
          height: 168,
        }}
        isLoading={isLoading}
      >
        <CarScrollBox
          sx={{
            mt: 1,
            height: 168,
            display: "flex",
            flexDirection: "column",
          }}
          autoHide={false}
        >
          <CarCheckboxGroup
            sx={{
              ".MuiFormGroup-root": {
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                rowGap: 0,
              },
            }}
            items={items.map((i) => ({
              label: i.label ?? "",
              id: i.value ?? "",
            }))}
            checkedItems={checkedItems}
            onChange={onChange}
          />
        </CarScrollBox>
      </TablePlaceHolder>
    </Box>
  );
};
