import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useCommonAuthLoginCreateMutation,
  useCommonAuthRequestResetPasswordCreateMutation,
  useCommonAuthStartFreeTrialCreateMutation,
  useCommonAuthTwoFactorLoginUpdateMutation,
  useCommonAuthVerifyEmailResendUpdateMutation,
  useCommonAuthVerifyEmailUpdateMutation,
} from "api/carApi.generated";
import { setAuthSession, setAuthLocal } from "features/auth/authSlice";
import { useAuth } from "features/auth/useAuth";
import { CarAuthInfo } from "types";
import { debugLog, isDefined } from "utils";
import { showToast } from "app/toastSlice";
import { useLogout } from "app/useLogout";
import { hubspotPostStartTrial } from "app/thirdParty/hubspot";

export enum LoginMode {
  login = "login",
  trial = "trial",
  resetPassword = "resetPassword",
  resetPasswordSent = "resetPasswordSent",
  verifyEmail = "verifyEmail",
  verifyTwoFactor = "verifyTwoFactor",
}

export interface CarLoginForm {
  requiredMode?: boolean;
  email?: string;
  password?: string;
  rememberMe?: boolean;
}

export interface CarTrialForm {
  requiredMode?: boolean;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  confirmPassword?: string;
  firmName?: string;
  // crdNumber?: number;
}

export const useLogin = ({ showTrialPage }: { showTrialPage?: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as any)?.from?.pathname || "/";

  const dispatch = useDispatch();
  const { logout } = useLogout();

  const [loginReq] = useCommonAuthLoginCreateMutation();
  const [trialReq] = useCommonAuthStartFreeTrialCreateMutation();
  const [verifyEmailReq] = useCommonAuthVerifyEmailUpdateMutation();
  const [verifyTwoFactorReq] = useCommonAuthTwoFactorLoginUpdateMutation();
  const [resendCodeReq] = useCommonAuthVerifyEmailResendUpdateMutation();
  const [requestResetPassReq] =
    useCommonAuthRequestResetPasswordCreateMutation();

  const [mode, setMode] = useState<LoginMode>(
    showTrialPage ? LoginMode.trial : LoginMode.login,
  );

  const [verificationCode, setVerificationCode] = useState<string | undefined>(
    "",
  );

  const [twoFactorCode, setTwoFactorCode] = useState<string | undefined>("");

  const auth = useAuth();

  const [loginForm, setLoginForm] = useState<CarLoginForm>({});

  const [trialForm, setTrialForm] = useState<CarTrialForm>({});

  useEffect(() => {
    if (auth.incomplete_reason === "email_verify") {
      setMode(LoginMode.verifyEmail);
    } else if (auth.incomplete_reason === "two_factor") {
      setMode(LoginMode.verifyTwoFactor);
    } else if (
      mode === LoginMode.verifyEmail ||
      mode === LoginMode.verifyTwoFactor
    ) {
      setMode(LoginMode.login);
    }
  }, [auth, mode]);

  const login = async () => {
    if ([loginForm.email?.trim(), loginForm.password?.trim()].some((i) => !i)) {
      setLoginForm((value) => ({ ...value, requiredMode: true }));
      dispatch(
        showToast({
          kind: "error",
          message: "Please fill all fields",
        }),
      );

      return;
    }
    const response = await loginReq({
      customTokenObtainPair: {
        email: loginForm.email?.toLocaleLowerCase() ?? "",
        password: loginForm.password ?? "",
      },
    });
    if ("data" in response) {
      debugLog("login response", JSON.stringify(response.data, null, 5));
      // if (loginForm.rememberMe) {
      dispatch(setAuthLocal(response.data));
      // } else {
      //   dispatch(setAuthSession(response.data));
      // }
      navigate(from, { replace: true });
    }
  };

  const goTrialPage = async () => {
    setMode(LoginMode.trial);
  };

  const goToResetPasswordPage = async () => {
    setMode(LoginMode.resetPassword);
    setLoginForm((value) => ({ ...value, requiredMode: false }));
  };

  const goToResetPasswordSentPage = async () => {
    setMode(LoginMode.resetPasswordSent);
    setLoginForm((value) => ({ ...value, requiredMode: false }));
  };

  const goToLoginPage = async () => {
    setMode(LoginMode.login);
    setLoginForm((value) => ({ ...value, requiredMode: false }));
  };

  const startTrial = async () => {
    if (
      [
        trialForm.email?.trim(),
        trialForm.firstName?.trim(),
        trialForm.lastName?.trim(),
        trialForm.password?.trim(),
        trialForm.confirmPassword?.trim(),
        trialForm.firmName?.trim(),
        // trialForm.crdNumber,
      ].some((i) => !i)
    ) {
      setTrialForm((value) => ({ ...value, requiredMode: true }));
      dispatch(
        showToast({
          kind: "error",
          message: "Please complete all fields",
        }),
      );

      return;
    }
    if (trialForm.password?.trim() !== trialForm.confirmPassword?.trim()) {
      dispatch(
        showToast({
          kind: "error",
          message: "Passwords do not match",
        }),
      );

      return;
    }

    const response = await trialReq({
      freeTrialStart: {
        first_name: trialForm.firstName?.trim() ?? "",
        last_name: trialForm.lastName?.trim() ?? "",
        email: trialForm.email?.trim().toLocaleLowerCase() ?? "",
        password: trialForm.password?.trim() ?? "",
        firm_name: trialForm.firmName?.trim() ?? "",
        // fnra_number: trialForm.crdNumber ?? 0,
      },
    });

    if ("data" in response) {
      debugLog(
        "freeTrialStart response",
        JSON.stringify(response.data, null, 5),
      );

      const hubspotResponse = await hubspotPostStartTrial(trialForm);
      if (isDefined(hubspotResponse)) {
        debugLog(
          "hubspotResponse response",
          JSON.stringify(await hubspotResponse.text(), null, 5),
        );
      }

      dispatch(setAuthSession(response.data));
    }
  };

  const goBack = () => setMode(LoginMode.login);

  const verifyEmail = async () => {
    if (!verificationCode) {
      dispatch(
        showToast({
          kind: "error",
          message: "Please enter verification code",
        }),
      );

      return;
    }

    const response = await verifyEmailReq({
      userEmailConfirmation: {
        token: (verificationCode ?? "") as any, // todo Fix in swagger???
      },
    });
    if ("data" in response) {
      const verifyResponse = response.data as unknown as CarAuthInfo;

      debugLog("verifyEmail response", JSON.stringify(verifyResponse, null, 5));
      dispatch(setAuthSession(verifyResponse));
      navigate(from, { replace: true });
    }
  };

  const verifyTwoFactor = async () => {
    const response = await verifyTwoFactorReq({
      userTwoFactorAuth: {
        code: twoFactorCode ?? "",
      },
    });
    if ("data" in response) {
      debugLog(
        "verifyTwoFactor response",
        JSON.stringify(response.data, null, 5),
      );
      dispatch(setAuthSession(response.data));
      navigate(from, { replace: true });
    }
  };

  const resendCode = async () => {
    const response = await resendCodeReq();
    if ("data" in response) {
      debugLog("resendCode response", JSON.stringify(response.data, null, 5));
      dispatch(
        showToast({
          kind: "success",
          message: "Verification code has been sent to your email address",
        }),
      );
    }
  };

  const resetPassword = async () => {
    if (!loginForm.email?.trim()) {
      setLoginForm((value) => ({ ...value, requiredMode: true }));
      dispatch(
        showToast({
          kind: "error",
          message: "Please enter email",
        }),
      );

      return;
    }

    const response = await requestResetPassReq({
      requestResetPassword: {
        email: loginForm.email.toLocaleLowerCase() ?? "",
      },
    });

    if ("data" in response) {
      debugLog(
        "requestResetPassReq response",
        JSON.stringify(response.data, null, 5),
      );
      goToResetPasswordSentPage();
    }
  };

  return {
    mode,

    loginForm,
    setLoginForm,

    trialForm,
    setTrialForm,

    verificationCode,
    setVerificationCode,

    goToLoginPage,
    login,

    goTrialPage,
    startTrial,

    goBack,

    canVerifyEmail: true,
    verifyEmail,

    twoFactorCode,
    setTwoFactorCode,

    canVerifyTwoFactor: !!twoFactorCode,
    verifyTwoFactor,

    resendCode,

    logout,

    goToResetPasswordPage,
    goToResetPasswordSentPage,
    resetPassword,
  };
};

export type UseLogin = ReturnType<typeof useLogin>;
