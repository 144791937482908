import { styled, Switch, SwitchProps } from "@mui/material";

export const CarSwitch = styled<
  (props: Omit<SwitchProps, "value">) => JSX.Element
>(Switch)(() => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 11,
    opacity: "1 !important",
    backgroundColor: "#B2B3B5",
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
    backgroundColor: "white",
  },
  "&.MuiSwitch-sizeSmall": {
    padding: 4,
    "& .MuiSwitch-track": {
      borderRadius: 8,
    },
    "& .MuiSwitch-thumb": {
      width: 12,
      height: 12,
      margin: 2,
      marginTop: 2,
    },
  },
}));
