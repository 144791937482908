import { Box } from "@mui/material";
import { usePortfolioGroupVersionStatData } from "../../usePortfolioGroupVersionStatData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "../../usePortfolioGroupInfo";
import { ChartOutUnderPerformance } from "./../ChartOutUnder";
import { useChartDataSelector } from "./../useChartDataSelector";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { useYearRangeSelector } from "./../useYearRangeSelector";
import { getYear, parseISO } from "date-fns";
import { YearRangeSelector } from "./../YearRangeSelector";
import { pendoClasses } from "app/thirdParty/pendo";
import { AnalyzeModuleStyledPage } from "./AnalyzeModuleStyledPage";

interface AnalyzeModulePerformanceRiskProps {
  moduleId: string;
  moduleIdx: number;
}

export const AnalyzeModulePerformanceRisk = (
  props: AnalyzeModulePerformanceRiskProps,
) => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const pageContent = usePageContent();

  const { moduleId, moduleIdx } = props;

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const statData = usePortfolioGroupVersionStatData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    filterByModuleId: props.moduleId,
  });

  const years = Array.from(
    new Set(
      statData.oneYearUnderPerf.at(0)?.x.map((i) => getYear(parseISO(i))) ?? [],
    ),
  );

  const yearRangeSelector = useYearRangeSelector({
    years,
  });

  const defaultIds = statData.getDefaultDataIdsForModule(moduleId);

  const chartDataSelector = useChartDataSelector({
    storageKey: `EditPortfolioGroupAnalyze_${portfolioGroupVersionId}`,
    ...defaultIds,
  });

  return (
    <AnalyzeModuleStyledPage>
      <TablePlaceHolder
        isLoading={
          groupInfo.isLoading || statData.isLoading || pageContent.isLoading
        }
        rows={5}
      >
        <CarPageContent
          sx={{ mb: 5 }}
          isWhiteContext
          content={pageContent.getContent(
            groupInfo.isSinglePortfolioMode
              ? CarPageContentCode.PORTFOLIO_ANALYSIS_ANALYZE_PERFORMANCE_RISK
              : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_PERFORMANCE_RISK,
          )}
        />
        <YearRangeSelector
          sx={{ ml: 3 }}
          yearRangeSelector={yearRangeSelector}
        />
        <Box
          sx={{
            mt: 5,
            display: "flex",
            flexDirection: "column",
            gap: 5,
            px: 3,
          }}
        >
          <ChartOutUnderPerformance
            selectorLabel1="Portfolio Variation 1"
            selectorLabel2="Portfolio Variation 2"
            label="1 Year Under Performance Risk"
            data={statData.oneYearUnderPerf}
            periods={statData.periods}
            startYear={yearRangeSelector.startYear}
            endYear={yearRangeSelector.endYear}
            isRelative={false}
            chartDataSelector={chartDataSelector}
            pendoClass={pendoClasses.analyzePortfolioGroupReportsModuleOneYearUnderPerfChart(
              moduleIdx,
            )}
          />

          <ChartOutUnderPerformance
            selectorLabel1="Portfolio Variation 1"
            selectorLabel2="Portfolio Variation 2"
            label="3 Year Under Performance Risk"
            data={statData.threeYearUnderPerf}
            periods={statData.periods}
            startYear={yearRangeSelector.startYear}
            endYear={yearRangeSelector.endYear}
            isRelative={false}
            chartDataSelector={chartDataSelector}
            pendoClass={pendoClasses.analyzePortfolioGroupReportsModuleThreeYearUnderPerfChart(
              moduleIdx,
            )}
          />
          <ChartOutUnderPerformance
            selectorLabel1="Portfolio Variation 1"
            selectorLabel2="Portfolio Variation 2"
            label="Cumulative Relative Performance"
            data={statData.cumRelPerf}
            periods={statData.periods}
            chartDataSelector={chartDataSelector}
            startYear={yearRangeSelector.startYear}
            endYear={yearRangeSelector.endYear}
            isRelative={true}
            pendoClass={pendoClasses.analyzePortfolioGroupReportsModuleCumulativeRelativePerfChart(
              moduleIdx,
            )}
          />
        </Box>
      </TablePlaceHolder>
    </AnalyzeModuleStyledPage>
  );
};
