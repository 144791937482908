import { Box, BoxProps, Typography, styled, useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import { formatDisplayTypeValue, isDefined } from "utils";
import { chartsColor } from "theme";
import { ReactElement, useCallback, useRef } from "react";
import { useRiskStatType } from "app/useRiskStatType";
import {
  ChartTooltip,
  defaultChartTooltipData,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { ChartPlugins } from "chartUtils";
import { CarChartLegend } from "components/ChartLegend";
import { useChartHighlight } from "app/useChartHighlight";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { CarPortfolioAssetStatTableData } from "./usePortfolioGroupVersionStatTableData";

const maxChartWidth = 1160;
const chartHeight = 580;

export const getPortfolioGroupAssetStatChartData = (
  statTableData: CarPortfolioAssetStatTableData,
  xStatId?: string,
  yStatId?: string,
) => {
  const bands = statTableData.bands.filter((b) => !b.isCash);

  const firstBandColumns = bands?.[0]?.columns ?? [];

  const allColumnLabelsAreSame = (colIdx: number) =>
    bands.every(
      (bd) => bd.columns[colIdx]?.label === firstBandColumns[colIdx]?.label,
    );

  const getRowName = (statId?: string) =>
    statTableData.historicalStatRows.find((i) => i.id === statId)?.name ??
    statTableData.simulationBasedStatRows.find((i) => i.id === statId)?.name ??
    "";

  const getDataSets = (maxFee: boolean) => {
    if (maxFee && !statTableData.simulationBasedStatRowsMaxFee.length) {
      return [];
    }

    const rows = [
      ...statTableData.historicalStatRows,
      ...(maxFee
        ? statTableData.simulationBasedStatRowsMaxFee
        : statTableData.simulationBasedStatRows),
    ];

    const xRow = rows.find((r) => r.id === xStatId);
    const yRow = rows.find((r) => r.id === yStatId);

    const getData = (columnIdx: number) =>
      bands.map((b) => {
        const xModule = xRow?.modules.find((m) => m.moduleId === b.moduleId);
        const yModule = yRow?.modules.find((m) => m.moduleId === b.moduleId);
        return {
          x: xModule?.values[columnIdx]?.allocation ?? 0,
          y: yModule?.values[columnIdx]?.allocation ?? 0,
        };
      });

    return firstBandColumns.map((col, idx) => ({
      label: allColumnLabelsAreSame(idx) ? col.label : "Selected",
      color: Object.values(chartsColor)[idx],
      data: getData(idx),
    }));
  };

  return {
    xLabel: getRowName(xStatId),
    yLabel: getRowName(yStatId),
    getModuleName: (dataIndex: number) => bands[dataIndex]?.label,
    getDataIndexByModuleId: (moduleId?: string) =>
      bands.findIndex((i) => i.moduleId === moduleId),
    getModuleIdByDataIndex: (dataIndex: number) => bands[dataIndex]?.moduleId,
    datasets: getDataSets(false),
    maxFeeDatasets: getDataSets(true),
  };
};

export interface PortfolioGroupAssetStatChartProps {
  sx?: BoxProps["sx"];
  statTableData: CarPortfolioAssetStatTableData;
  xStatId?: string;
  yStatId?: string;
  isWhiteContext?: boolean;
  renderAxisControls?: () => ReactElement;
}

const StyledLine = styled(Line)(({ theme }) => ({
  width: "100%",
  maxWidth: maxChartWidth,
  height: chartHeight,
  maxHeight: chartHeight,
})) as typeof Line;

type ChartRef = ChartJSOrUndefined<
  "line",
  {
    x: number;
    y: number;
  }[],
  unknown
>;

export const PortfolioGroupAssetStatChart = (
  props: PortfolioGroupAssetStatChartProps,
) => {
  const chartHighlight = useChartHighlight();
  const riskStatType = useRiskStatType();

  const { datasets, getModuleName, xLabel, yLabel } =
    getPortfolioGroupAssetStatChartData(
      props.statTableData,
      props.xStatId,
      props.yStatId,
    );

  const { tooltipPlugin, tooltipData, setTooltipData } = useChartTooltip({
    chartHighlight,
  });
  const chartRef = useRef<ChartRef | null>();

  const chartRefCallback = useCallback(
    (value: ChartRef | null) => {
      chartHighlight.unsubscribe(chartRef.current);
      chartRef.current = value;

      chartHighlight.subscribe(chartRef.current, (hlValue) => {
        if (!isDefined(hlValue.datasetIndex) || !isDefined(hlValue.dataIndex)) {
          setTooltipData(defaultChartTooltipData);
          return;
        }

        if (chartRef.current) {
          const { width, height } = chartRef.current.canvas;
          const data = chartRef.current.data.datasets.at(hlValue.datasetIndex)
            ?.data;

          const value = data?.at(hlValue.dataIndex);

          if (value) {
            const x = chartRef.current.scales.x.getPixelForValue(value.x);
            const y = chartRef.current.scales.y.getPixelForValue(value.y);
            setTooltipData({
              opacity: 1,
              x,
              y,
              canvasSize: { width, height },
              datasetIndex: hlValue.datasetIndex,
              dataIndex: hlValue.dataIndex,
            });
          }
        }

        chartRef.current?.update();
      });
    },
    [chartHighlight, setTooltipData],
  );

  const minMaxRef = useRef({ minX: 0, maxX: 0, minY: 0, maxY: 0 });
  minMaxRef.current = {
    minX: Math.min(...datasets.flatMap((ds) => ds.data.map((d) => d.x))),
    maxX: Math.max(...datasets.flatMap((ds) => ds.data.map((d) => d.x))),
    minY: Math.min(...datasets.flatMap((ds) => ds.data.map((d) => d.y))),
    maxY: Math.max(...datasets.flatMap((ds) => ds.data.map((d) => d.y))),
  };

  const theme = useTheme();

  const xFormatter = formatDisplayTypeValue(
    riskStatType.getDisplayTypeById(props.xStatId) ?? "PERCENTAGE",
  );
  const yFormatter = formatDisplayTypeValue(
    riskStatType.getDisplayTypeById(props.yStatId) ?? "PERCENTAGE",
  );

  const formatXValue = (value: number | string) =>
    typeof value === "number" ? xFormatter(value) : "";

  const formatYValue = (value: number | string) =>
    typeof value === "number" ? yFormatter(value) : "";

  const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
    const ds = datasets[datasetIndex];
    if (!ds) {
      return <></>;
    }

    const xValue = ds.data?.[dataIndex]?.x ?? 0;
    const yValue = ds.data?.[dataIndex]?.y ?? 0;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="par01SemiBold" sx={{ fontSize: 15, mb: 1 }}>
          {`${ds.label} - ${getModuleName(dataIndex)}`}
        </Typography>
        <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
          {`${xLabel}: ${formatXValue(xValue)}`}
        </Typography>
        <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
          {`${yLabel}: ${formatYValue(yValue)}`}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <ChartTooltip
        tooltipData={tooltipData}
        renderTooltip={renderTooltip}
        sx={{
          ...props.sx,
        }}
      >
        <StyledLine
          ref={chartRefCallback}
          data={{
            datasets: datasets.map((ds) => ({
              label: ds.label,
              data: ds.data,
              borderColor: ds.color,
              backgroundColor: ds.color,
              pointBackgroundColor: ds.color,
              pointBorderColor: ds.color,
              borderWidth: 2,
              pointRadius: 3,
            })),
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: {
                type: "linear",
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "400",
                  },
                  callback: formatXValue,
                },
                border: { display: false },
                grid: {
                  color: (ctx) => {
                    return ctx.tick.value <= minMaxRef.current.minX ||
                      ctx.tick.value >= minMaxRef.current.maxX
                      ? "transparent"
                      : theme.palette.gray2;
                  },
                  lineWidth: 2,
                  tickColor: theme.palette.white,
                },
                title: {
                  display: true,
                  text: xLabel,
                  color: theme.palette.softBlack,
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "600",
                    lineHeight: 1,
                  },
                  padding: {
                    top: 10,
                  },
                },
              },
              y: {
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "400",
                  },
                  callback: formatYValue,
                },
                border: { display: false },
                grid: {
                  color: (ctx) => {
                    return ctx.tick.value <= minMaxRef.current.minY ||
                      ctx.tick.value >= minMaxRef.current.maxY
                      ? "transparent"
                      : theme.palette.gray2;
                  },
                  lineWidth: 2,
                  tickColor: theme.palette.white,
                },
                title: {
                  display: true,
                  text: yLabel,
                  color: theme.palette.softBlack,
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "600",
                    lineHeight: 1,
                  },
                  padding: {
                    bottom: 20,
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: tooltipPlugin,
              roundedBackground: {
                contextColor: props.isWhiteContext
                  ? theme.palette.white
                  : theme.palette.gray1,
                backgroundColor: props.isWhiteContext
                  ? theme.palette.gray1
                  : theme.palette.white,
                borderColor: theme.palette.gray3,
                borderRadius: 5,
              },
            },
          }}
          plugins={[ChartPlugins.roundedBackground]}
        />
      </ChartTooltip>
      <CarChartLegend
        sx={{ mt: 1, ml: 9 }}
        items={datasets.map((ds, idx) => ({
          label: ds.label,
          color: ds.color,
          datasetIndex: idx,
          dataIndex: 0,
        }))}
        chartHighlight={chartHighlight}
      />
    </Box>
  );
};
