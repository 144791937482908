import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarStressTestData } from "types";
import { formatCurrency, formatPercentFactor } from "utils";
import { CarPdfSubHeading } from "./PdfHeading";
import {
  CarPdfHeader,
  CarPdfTable,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTextCell,
} from "components/PdfTable";

export interface TaxableIncomeAnalysisPdfPageProps {
  pageProps: CarPdfPageProps;
  items: Array<CarStressTestData>;
}

export const TaxableIncomeAnalysisPdfPage = (
  props: TaxableIncomeAnalysisPdfPageProps,
) => (
  <CarPdfPage {...props.pageProps}>
    <CarPdfSubHeading label="Taxable Income Analysis" />
    <CarPdfTable
      style={{
        marginTop: 15,
      }}
      colWidths={[0.8, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
    >
      <CarPdfHeader paddingVertical={14}>
        <CarPdfHeaderCell />
        <CarPdfHeaderCell>
          Income, Social Security, Pension, Etc.
        </CarPdfHeaderCell>
        <CarPdfHeaderCell>Taxable Dividend & Interest</CarPdfHeaderCell>
        <CarPdfHeaderCell>Taxable W/D (IRA, 401k, etc.)</CarPdfHeaderCell>
        <CarPdfHeaderCell>Capital Gains</CarPdfHeaderCell>
        <CarPdfHeaderCell>Gross Rental Income</CarPdfHeaderCell>
        <CarPdfHeaderCell style={{ paddingHorizontal: 6 }}>
          Total Taxable Income
        </CarPdfHeaderCell>
        <CarPdfHeaderCell>Income Taxes Paid</CarPdfHeaderCell>
        <CarPdfHeaderCell>Capital Gains Taxes Paid</CarPdfHeaderCell>
        <CarPdfHeaderCell>Effective Tax Rate</CarPdfHeaderCell>
      </CarPdfHeader>
      {props.items.map((i, idx) => (
        <CarPdfRow
          key={i.year}
          style={{
            backgroundColor: idx % 2 === 0 ? "white" : "#f3f6f8",
          }}
          textVariant="tableBody3"
          paddingVertical={10}
        >
          <CarPdfTextCell variant="tableBody4">
            {i.year.toString()}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.social_security_pension_etc ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.taxable_dividend_interest ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.taxable_withdrawl ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.capital_gains ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.net_rental_income ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.total_taxable_income ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.income_taxes_paid ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.capital_gains_taxes_paid)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatPercentFactor(i.effective_tax_rate)}
          </CarPdfTextCell>
        </CarPdfRow>
      ))}
    </CarPdfTable>
  </CarPdfPage>
);
