import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { useFirm } from "pages/settings/useFirm";
import { usePortfolioGroupInfo } from "pages/assetAllocation/usePortfolioGroupInfo";
import { usePortfolioGroupVersionData } from "pages/assetAllocation/usePortfolioGroupVersionData";
import { useRiskStatType } from "app/useRiskStatType";
import { CarPdfViewer } from "components/PdfViewer";
import { PortfolioGroupAssetStatPdfChart } from "./PortfolioGroupAssetStatPdfChart";
import { PortfolioGroupAssetStatPdfTable } from "./PortfolioGroupAssetStatPdfTable";
import { PortfolioGroupAssetAllocationPdfTable } from "./PortfolioGroupAssetAllocationPdfTable";
import { formatDisplayTypeValue } from "utils";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { useUser } from "pages/settings/useUser";
import { CarPdfDocument } from "components/PdfDocument";
import { CarPdfPage } from "components/PdfPage";
import {
  CarPdfHeadingAC,
  CarPdfInternalUse,
} from "features/financialPlanningReport/PdfHeading";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfTypography } from "components/PdfTypography";
import { usePortfolioGroupVersionStatTableData } from "../usePortfolioGroupVersionStatTableData";

export const PortfolioGroupPublishPdfReportPage = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const [searchParams] = useSearchParams();
  const xStatId = searchParams.get("x") ?? "";
  const yStatId = searchParams.get("y") ?? "";

  const firm = useFirm();
  const user = useUser();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const data = usePortfolioGroupVersionData({
    versionId: groupInfo.portfolioGroupVersionId,
    publishedVersionId: groupInfo.publishedVersionId,
    show: {
      existing: true,
      selected: true,
      benchmark: true,
      draft: false,
      optimizationIds: [],
    },
    isBenchmarkEditable: false,
  });

  const versionStatTableData = usePortfolioGroupVersionStatTableData({
    bands: data.tableData.bands,
    skip: data.assetAllocationIsLoading,
  });

  const { tableData } = data;
  const { statTableData } = versionStatTableData;

  const riskStatType = useRiskStatType();

  const isLoading =
    firm.isLoading ||
    riskStatType.isLoading ||
    groupInfo.isLoading ||
    data.isLoading ||
    versionStatTableData.isLoading;

  const xFormatter = formatDisplayTypeValue(
    riskStatType.getDisplayTypeById(xStatId) ?? "PERCENTAGE",
  );
  const yFormatter = formatDisplayTypeValue(
    riskStatType.getDisplayTypeById(yStatId) ?? "PERCENTAGE",
  );

  const date = format(new Date(), "MM/dd/yyyy");
  const documentTitle = `${groupInfo.title} - ${date}`;

  return (
    <CarPdfViewer isLoading={isLoading}>
      <CarPdfDocument
        title={documentTitle}
        author={`${user.userFullName}, ${firm.firm.name ?? ""}`}
      >
        <CarPdfPage
          style={{
            paddingTop: 35,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          hideStripe
          footerBottom={10}
          footerDisclosures=""
        >
          <CarPdfHeadingAC
            firmName={firm.firm.name ?? ""}
            label={documentTitle}
          />
          <CarPdfInternalUse style={{ marginTop: 30, marginRight: 24 }} />
          <PortfolioGroupAssetStatPdfChart
            width={520}
            height={250}
            style={{ marginLeft: 10 }}
            data={statTableData}
            xStatId={xStatId}
            yStatId={yStatId}
            xFormatter={xFormatter}
            yFormatter={yFormatter}
          />

          <CarPdfTypography
            variant="body1"
            style={{ marginTop: 32, marginBottom: 14 }}
          >
            Simulation Based Risk Report (No fee)
          </CarPdfTypography>
          <PortfolioGroupAssetStatPdfTable
            bands={statTableData.bands}
            rows={statTableData.simulationBasedStatRows}
            xStatId={xStatId}
            yStatId={yStatId}
          />
          <CarPdfBox break />
          {statTableData.simulationBasedStatRowsMaxFee.length > 0 && (
            <>
              <CarPdfTypography variant="body1" style={{ marginBottom: 14 }}>
                Simulation Based Risk Report (Max fee)
              </CarPdfTypography>
              <PortfolioGroupAssetStatPdfTable
                bands={statTableData.bands}
                rows={statTableData.simulationBasedStatRowsMaxFee}
                xStatId={xStatId}
                yStatId={yStatId}
                style={{ marginBottom: 30 }}
              />
            </>
          )}
          <CarPdfTypography variant="body1" style={{ marginBottom: 14 }}>
            Historical Risk Report
          </CarPdfTypography>
          <PortfolioGroupAssetStatPdfTable
            bands={statTableData.bands}
            rows={statTableData.historicalStatRows}
            xStatId={xStatId}
            yStatId={yStatId}
          />
          <CarPdfTypography break variant="body1" style={{ marginBottom: 14 }}>
            Assets - Level 4
          </CarPdfTypography>
          <PortfolioGroupAssetAllocationPdfTable
            bands={tableData.bands}
            rows={tableData.assetRows.level4}
          />
        </CarPdfPage>
      </CarPdfDocument>
    </CarPdfViewer>
  );
};
