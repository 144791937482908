import {
  useQuestionnaireClientsNotesCreateMutation,
  useQuestionnaireClientsNotesListQuery,
} from "api/carApi.generated";
import { useClientId } from "app/usePlanId";
import { v4 } from "uuid";

export const useNotes = () => {
  const clientId = useClientId();
  const notes = useQuestionnaireClientsNotesListQuery({
    clientId,
  });
  const [addNoteMut] = useQuestionnaireClientsNotesCreateMutation();

  const addNote = (noteSource: string, noteText: string) =>
    addNoteMut({
      clientId,
      clientNote: {
        id: v4(),
        created_at: new Date().toISOString(),
        note_source: noteSource,
        note_text: noteText,
      },
    });

  return {
    items: [...(notes.data ?? [])].sort((a, b) =>
      (b.created_at ?? "").localeCompare(a.created_at ?? "")
    ),
    isLoading: notes.isLoading,
    addNote,
  };
};
