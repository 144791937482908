import { CarUser } from "types";

declare const window: any;

export const startIntercom = (user: CarUser) => {
  if (window.Intercom) {
    window.Intercom("boot", {
      app_id: "ovc51ad1",
      hide_default_launcher: true,
      email: user.email,
      user_hash: user.intercom_hmac,
      firm_id: user.firm?.id,
      firm_name: user.firm?.name,
    });

    window.Intercom("onShow", () =>
      window.document.body.classList.add("intercom-is-visible")
    );
    window.Intercom("onHide", () =>
      window.document.body.classList.remove("intercom-is-visible")
    );
  }
};

export const showIntercom = () => {
  if (window.Intercom) {
    window.Intercom("show");
  }
};

export const shutdownIntercom = () => {
  if (window.Intercom) {
    window.Intercom("shutdown");
  }
};
