import { useEffect } from "react";
import { useRiskStatType } from "app/useRiskStatType";
import { useSessionStorage } from "features/general/useLocalStorage";

export const useStatAxisSelector = () => {
  const { xStatDefaultId, yStatDefaultId } = useRiskStatType();

  const [xStatId, onXStatIdChange] = useSessionStorage<string>(
    "StatAxisSelector_xStatId",
    "",
  );
  const [yStatId, onYStatIdChange] = useSessionStorage<string>(
    `StatAxisSelector_yStatId`,
    "",
  );

  useEffect(() => {
    if (!xStatId && xStatDefaultId) {
      onXStatIdChange(xStatDefaultId);
    }
    if (!yStatId && yStatDefaultId) {
      onYStatIdChange(yStatDefaultId);
    }
  }, [
    xStatDefaultId,
    yStatDefaultId,
    xStatId,
    yStatId,
    onXStatIdChange,
    onYStatIdChange,
  ]);

  return {
    xStatId,
    onXStatIdChange,
    yStatId,
    onYStatIdChange,
  };
};

export type UseStatAxisSelector = ReturnType<typeof useStatAxisSelector>;
