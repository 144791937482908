import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarStressTestData } from "types";
import { formatCurrency } from "utils";
import { CarPdfSubHeading } from "./PdfHeading";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTable,
  CarPdfTextCell,
} from "components/PdfTable";

export interface PlanCashflowPdfPageProps {
  pageProps: CarPdfPageProps;
  items: Array<CarStressTestData>;
}

export const PlanCashflowPdfPage = (props: PlanCashflowPdfPageProps) => (
  <CarPdfPage {...props.pageProps}>
    <CarPdfSubHeading label="Plan Cashflow" />
    <CarPdfTable
      style={{
        marginTop: 15,
      }}
      colWidths={[0.5, 1, 1, 1, 1, 1, 1]}
    >
      <CarPdfHeader paddingVertical={14}>
        <CarPdfHeaderCell />
        <CarPdfHeaderCell>Total A/T Income, W/D and Cashflow</CarPdfHeaderCell>
        <CarPdfHeaderCell>Retirement Spending</CarPdfHeaderCell>
        <CarPdfHeaderCell style={{ paddingHorizontal: 16 }}>
          Medical Expenses
        </CarPdfHeaderCell>
        <CarPdfHeaderCell>Other Goals Funded</CarPdfHeaderCell>
        <CarPdfHeaderCell>Goals Met</CarPdfHeaderCell>
        <CarPdfHeaderCell>Goals Not Met</CarPdfHeaderCell>
      </CarPdfHeader>
      {props.items.map((i, idx) => (
        <CarPdfRow
          key={i.year}
          style={{
            backgroundColor: idx % 2 === 0 ? "white" : "#f3f6f8",
          }}
          textVariant="tableBody3"
          paddingVertical={10}
        >
          <CarPdfTextCell variant="tableBody4">
            {i.year.toString()}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.total_aftertax_income_and_withdrawls)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.retirement_spending)}
          </CarPdfTextCell>
          <CarPdfTextCell>{formatCurrency(i.medical_expenses)}</CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.other_goals_funded)}
          </CarPdfTextCell>
          <CarPdfTextCell>{formatCurrency(i.goals_met)}</CarPdfTextCell>
          <CarPdfTextCell>{formatCurrency(i.goals_not_met)}</CarPdfTextCell>
        </CarPdfRow>
      ))}
    </CarPdfTable>
  </CarPdfPage>
);
