import {
  usePortfolioPortfolioGroupDestroyMutation,
  usePortfolioPortfolioGroupPartialUpdateMutation,
} from "api/carApi.generated";
import { useDialog } from "app/useDialog";
import { RenameDialog } from "components/RenameDialog";
import { useConfirm } from "app/useConfirm";
import { DeleteItemDialog } from "components/DeleteItemDialog";
import { useSuccessToast } from "app/useSuccessToast";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { usePortfolioGroupVersionData } from "../usePortfolioGroupVersionData";
import { useState } from "react";
import { usePortfolioGroupVersionStatTableData } from "../usePortfolioGroupVersionStatTableData";

export const usePortfolioGroupSummary = (portfolioGroupId?: string) => {
  const [updateRel] = usePortfolioPortfolioGroupPartialUpdateMutation();
  const [deleteRel] = usePortfolioPortfolioGroupDestroyMutation();
  const dialog = useDialog();
  const confirm = useConfirm();
  const toast = useSuccessToast();

  const [selectedModuleId, setSelectedModuleId] = useState("");

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const modulesData = usePortfolioGroupVersionData({
    versionId: groupInfo.publishedVersionId ?? groupInfo.draftVersionId, // todo clarify
    show: {
      draft: true,
      benchmark: true,
      existing: false,
      selected: false,
      optimizationIds: [],
    },
    isPublishedOnly: true,
  });

  const versionStatTableData = usePortfolioGroupVersionStatTableData({
    bands: modulesData.tableData.bands,
    skip: modulesData.assetAllocationIsLoading,
  });

  const handleRename = async () => {
    if (!portfolioGroupId) {
      return;
    }

    dialog(RenameDialog, {
      label: "Portfolio Group Name",
      value: groupInfo.title,
      applyLabel: "Rename",
      onRename: async (newTitle) => {
        return (
          "data" in
          (await updateRel({
            id: portfolioGroupId ?? "",
            patchedPortfolioGroup: {
              title: newTitle,
            },
          }))
        );
      },
    });
  };

  const handleDelete = async () => {
    if (!portfolioGroupId) {
      return;
    }

    if (
      !(await dialog(DeleteItemDialog, {
        itemName: groupInfo.title,
        itemTypeName: "portfolio group",
      }))
    ) {
      return;
    }

    const result = await deleteRel({ id: portfolioGroupId });

    if ("data" in result) {
      toast({
        kind: "success",
        message: "Success! This portfolio group has been deleted",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
  };

  const handleActiveChange = async (value: boolean) => {
    if (value) {
      confirm({
        label: `Activate "${groupInfo.title}"?`,
        message: `By turning on the active toggle, you will be publishing "${groupInfo.title}" to CMPAS and replacing the older version with your changes.`,
        applyLabel: "Activate",
        onApplying: async () => {
          await updateRel({
            id: portfolioGroupId ?? "",
            patchedPortfolioGroup: {
              mark_active: value,
            },
          });

          return true;
        },
      });
    }
  };

  return {
    isLoading:
      groupInfo.isLoading ||
      modulesData.isLoading ||
      versionStatTableData.isLoading,
    title: groupInfo.title,
    isEmpty: false, // todo clarify if we need to show anything if we have only draft
    isActive: groupInfo.isActive,
    activeDate: groupInfo.activeDate,
    handleRename,
    handleDelete,
    handleActiveChange,
    tableData: modulesData.tableData,
    statTableData: versionStatTableData.statTableData,
    selectedModuleId,
    setSelectedModuleId,
  };
};

export type UsePortfolioGroupSummary = ReturnType<
  typeof usePortfolioGroupSummary
>;
