import { useState } from "react";
import { Box } from "@mui/system";
import { CarLoadingButton } from "./Buttons";
import { CarTextField } from "./Inputs";
import { BaseDialog } from "./BaseDialog";

export interface RenameDialogProps {
  label: string;
  value: string;
  applyLabel: string;
  applyPendoClassName?: string;
  onRename: (value: string) => Promise<boolean>;
  onApply: () => void;
  onCancel: () => void;
}

export const RenameDialog = (props: RenameDialogProps) => {
  const [value, setValue] = useState<string | undefined>(props.value);
  const [isLoading, setIsLoading] = useState(false);

  const handleApply = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      if (await props.onRename((value ?? "").trim())) {
        props.onApply();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseDialog
      onClose={props.onCancel}
      actions={
        <CarLoadingButton
          className={props.applyPendoClassName}
          sx={{ alignSelf: "center", fontSize: "18px" }}
          onClick={handleApply}
          disabled={!(value ?? "").trim()}
          isLoading={isLoading}
        >
          {props.applyLabel}
        </CarLoadingButton>
      }
    >
      <Box
        sx={{
          width: 323,
          pb: 1,
          // py: 2,
        }}
        ref={(value: HTMLDivElement | null) => {
          if (value) {
            const input = value.getElementsByClassName("MuiInputBase-input")[0];
            if (input) {
              setTimeout(() => {
                (input as HTMLInputElement).focus();
              }, 100);
            }
          }
        }}
      >
        <CarTextField
          sx={{
            label: {
              fontSize: "18px",
            },
            ".MuiInputBase-root": {
              mt: 3.25,
            },
            input: {
              fontSize: "16px",
              color: "black",
              padding: 1.6,
            },
          }}
          autoFocus
          label={props.label}
          value={value}
          onChange={setValue}
          fullWidth
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleApply();
            }
          }}
        />
      </Box>
    </BaseDialog>
  );
};
