import { useParams } from "react-router-dom";

export const usePortfolioGroupParams = () => {
  const params = useParams();

  const portfolioGroupId = params.portfolioGroupId;
  if (!portfolioGroupId) {
    throw new Error("portfolioGroupId is undefined");
  }

  // const portfolioGroupVersionId = params.portfolioGroupVersionId;
  // if (!portfolioGroupVersionId) {
  //   throw new Error("portfolioGroupVersionId is undefined");
  // }

  return {
    portfolioGroupId,
    // portfolioGroupVersionId,
  };
};
