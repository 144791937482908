import { UseSettingsAssetClassImplementation } from "./useSettingsAssetClassImplementation";
import { CarTextFieldDelayed } from "components/Inputs";
import { Box, Typography } from "@mui/material";

export const SectionAssetClassImplementation = ({
  data,
}: {
  data: UseSettingsAssetClassImplementation;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.25 }}>
      <Box
        sx={{
          mb: -0.5,
          display: "grid",
          gridTemplateColumns: "1fr 1fr 0.8fr",
          gap: 4,
          px: 3,
        }}
      >
        <Typography variant="caption" sx={{ color: "gray6" }}>
          Asset Class
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: "gray6" }}
          textAlign="center"
        >
          Name
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: "gray6" }}
          textAlign="center"
        >
          Ticker
        </Typography>
      </Box>
      {data.items.map((i) => (
        <Box
          key={i.id}
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 0.8fr",
            gap: 4,
            border: "1px solid",
            borderColor: "gray6",
            py: 1.25,
            px: 3,
            borderRadius: "5px",
            backgroundColor: "white",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="par01Regular">{i.assetClassName}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CarTextFieldDelayed
              fullWidth
              sx={{ minWidth: 100, maxWidth: 300 }}
              value={i.targetName}
              onChange={(value) =>
                data.handleChange({ ...i, targetName: value ?? "" })
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CarTextFieldDelayed
              fullWidth
              sx={{ minWidth: 100, maxWidth: 300 }}
              value={i.targetTicker}
              onChange={(value) =>
                data.handleChange({ ...i, targetTicker: value ?? "" })
              }
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};
