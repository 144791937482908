import { useNotificationsNotificationListQuery } from "api/carApi.generated";
import { isDebug, refetchOnFocus } from "const";
import { useEffect } from "react";

const NOTIFICATION_REFETCH_TIMEOUT = isDebug ? 60 * 1000 : 10 * 60 * 1000;

export const useNotificationCount = () => {
  const { data, refetch, isFetching } = useNotificationsNotificationListQuery(
    undefined,
    { refetchOnFocus },
  );

  useEffect(() => {
    if (isFetching) {
      return;
    }

    const timeout = setTimeout(refetch, NOTIFICATION_REFETCH_TIMEOUT);

    return () => clearTimeout(timeout);
  }, [refetch, isFetching]);

  return data?.filter((i) => !i.is_read && !i.is_snoozed).length ?? 0;
};
