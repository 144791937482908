import { Box, BoxProps, Typography, styled, useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import { formatDisplayTypeValue } from "utils";
import { ReactElement, useCallback, useEffect, useRef } from "react";
import { useRiskStatType } from "app/useRiskStatType";
import {
  ChartTooltip,
  OnTooltipChange,
  RenderTooltip,
  defaultChartTooltipData,
  useChartTooltip,
} from "components/ChartTooltip";
import { pendoClasses } from "app/thirdParty/pendo";
import { getPortfolioGroupAssetStatChartData } from "../PortfolioGroupAssetStatChart";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { CarPortfolioAssetStatTableData } from "../usePortfolioGroupVersionStatTableData";
import { ChartPlugins } from "chartUtils";

const maxChartWidth = 1160;
const chartHeight = 244;

interface LegendItemProps {
  color: string;
  label: string;
}

const LegendItem = (props: LegendItemProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: 15,
          height: 15,
          backgroundColor: props.color,
          borderRadius: "4px",
          border: "1px solid",
          borderColor: "gray6",
          mr: 1,
          mt: "-2px",
        }}
      />
      <Typography
        variant="par01Regular"
        sx={{ fontSize: "13px" }}
        className={pendoClasses.efficientFrontierAnalysisLegend(props.label)}
      >
        {props.label}
      </Typography>
    </Box>
  );
};

export interface PortfolioGroupComparisonChartProps {
  sx?: BoxProps["sx"];
  statTableData: CarPortfolioAssetStatTableData;
  selectedModuleId?: string;
  onSelectedModuleIdChange?: (value: string) => void;
  xStatId?: string;
  yStatId?: string;
  renderAxisControls?: () => ReactElement;
}

const StyledLineChart = styled(Line)(({ theme }) => ({}));

export const PortfolioGroupComparisonChart = (
  props: PortfolioGroupComparisonChartProps,
) => {
  const chartRef = useRef<
    ChartJSOrUndefined<
      "line",
      {
        x: number;
        y: number;
      }[],
      unknown
    >
  >();

  const riskStatType = useRiskStatType();

  const {
    datasets,
    getModuleName,
    xLabel,
    yLabel,
    getDataIndexByModuleId,
    getModuleIdByDataIndex,
  } = getPortfolioGroupAssetStatChartData(
    props.statTableData,
    props.xStatId,
    props.yStatId,
  );

  const { onSelectedModuleIdChange } = props;

  const onTooltipChange = useCallback<OnTooltipChange>(
    ({ dataIndex, datasetIndex }) => {
      if (datasetIndex === 0) {
        onSelectedModuleIdChange?.(getModuleIdByDataIndex(dataIndex) ?? "");
      } else {
        onSelectedModuleIdChange?.("");
      }
    },
    [getModuleIdByDataIndex, onSelectedModuleIdChange],
  );

  const { tooltipPlugin, tooltipData, setTooltipData } = useChartTooltip({
    onTooltipChange,
  });

  const minMaxRef = useRef({ minX: 0, maxX: 0, minY: 0, maxY: 0 });
  minMaxRef.current = {
    minX: Math.min(...datasets.flatMap((ds) => ds.data.map((d) => d.x))),
    maxX: Math.max(...datasets.flatMap((ds) => ds.data.map((d) => d.x))),
    minY: Math.min(...datasets.flatMap((ds) => ds.data.map((d) => d.y))),
    maxY: Math.max(...datasets.flatMap((ds) => ds.data.map((d) => d.y))),
  };

  const selectedModuleDataIndex = getDataIndexByModuleId(
    props.selectedModuleId,
  );

  useEffect(() => {
    if (!props.selectedModuleId || selectedModuleDataIndex < 0) {
      setTooltipData(defaultChartTooltipData);
      return;
    }
  }, [setTooltipData, selectedModuleDataIndex, props.selectedModuleId]);

  useEffect(() => {
    if (chartRef.current && selectedModuleDataIndex >= 0) {
      const { width, height } = chartRef.current.canvas;
      const x = chartRef.current.scales.x.getPixelForValue(
        datasets[0].data?.[selectedModuleDataIndex].x,
      );
      const y = chartRef.current.scales.y.getPixelForValue(
        datasets[0].data?.[selectedModuleDataIndex].y,
      );
      setTooltipData({
        opacity: 1,
        x,
        y,
        canvasSize: { width, height },
        datasetIndex: 0,
        dataIndex: selectedModuleDataIndex,
      });
    }
  }, [
    datasets,
    setTooltipData,
    selectedModuleDataIndex,
    props.selectedModuleId,
  ]);

  const theme = useTheme();

  const xFormatter = formatDisplayTypeValue(
    riskStatType.getDisplayTypeById(props.xStatId) ?? "PERCENTAGE",
  );
  const yFormatter = formatDisplayTypeValue(
    riskStatType.getDisplayTypeById(props.yStatId) ?? "PERCENTAGE",
  );

  const formatXValue = (value: number | string) =>
    typeof value === "number" ? xFormatter(value) : "";

  const formatYValue = (value: number | string) =>
    typeof value === "number" ? yFormatter(value) : "";

  const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
    const ds = datasets[datasetIndex];
    if (!ds) {
      return <></>;
    }

    const xValue = ds.data?.[dataIndex]?.x ?? 0;
    const yValue = ds.data?.[dataIndex]?.y ?? 0;
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="par01SemiBold" sx={{ fontSize: 15, mb: 1 }}>
          {`${ds.label} - ${getModuleName(dataIndex)}`}
        </Typography>
        <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
          {`${xLabel}: ${formatXValue(xValue)}`}
        </Typography>
        <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
          {`${yLabel}: ${formatYValue(yValue)}`}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        ...props.sx,
      }}
    >
      <ChartTooltip
        tooltipData={tooltipData}
        renderTooltip={renderTooltip}
        shiftY={19}
      />
      <Box sx={{ display: "flex", gap: 3.5, justifyContent: "flex-end" }}>
        {datasets.map((ds, idx) => (
          <LegendItem key={idx} label={ds.label} color={ds.color} />
        ))}
      </Box>
      <StyledLineChart
        ref={chartRef}
        sx={{
          width: "100%",
          maxWidth: maxChartWidth,
          height: chartHeight,
          maxHeight: chartHeight,
        }}
        data={{
          datasets: datasets.map((ds) => ({
            label: ds.label,
            data: ds.data,
            borderColor: ds.color,
            backgroundColor: ds.color,
            pointBackgroundColor: ds.color,
            pointBorderColor: ds.color,
            borderWidth: 1,
            pointRadius: 3,
          })),
        }}
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              type: "linear",
              offset: true,
              ticks: {
                display: true,
                font: {
                  family: theme.typography.fontFamily,
                  size: 13,
                  weight: "600",
                },
                callback: formatXValue,
              },
              border: { display: false },
              grid: {
                drawTicks: false,
                color: theme.palette.gray2,
                lineWidth: 1,
              },
              title: {
                display: false,
              },
            },
            y: {
              offset: true,
              ticks: {
                font: {
                  family: theme.typography.fontFamily,
                  size: 13,
                  weight: "600",
                },
                callback: formatYValue,
              },
              border: { display: false },
              grid: {
                drawTicks: false,
                color: theme.palette.gray2,
                lineWidth: 1,
              },
              title: {
                display: true,
                text: yLabel,
                color: theme.palette.softBlack,
                font: {
                  family: theme.typography.fontFamily,
                  size: 16,
                  weight: "600",
                  lineHeight: 1,
                },
                padding: {
                  bottom: 20,
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: tooltipPlugin,
            roundedBackground: {
              contextColor: theme.palette.white,
              backgroundColor: theme.palette.gray1,
              borderColor: theme.palette.gray7,
              borderRadius: 5,
            },
          },
        }}
        plugins={[ChartPlugins.roundedBackground]}
      />
    </Box>
  );
};
