import { Box, BoxProps, styled } from "@mui/material";
import {
  CarFrequencyField,
  CarInflationField,
  CarPriorityField,
  CarTextFieldDelayed,
} from "components/Inputs";
import { CarDateExtraField } from "components/DateExtraField";
import { CarCurrencyFieldDelayed } from "components/NumberField";
import { CarTableMultiRow } from "components/Table";
import { CarTaxPurposeField } from "components/TaxPurposeField";
import { CarGoal } from "types";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { DragIndicator } from "@mui/icons-material";
import { isDateExtraSame, setDisplayName } from "utils";
import { memo } from "react";

interface GoalRowProps {
  sx?: BoxProps["sx"];
  item: CarGoal;
  onCanDelete: (value: CarGoal) => boolean;
  onChange: (value: CarGoal) => void;
  onDelete: (value: CarGoal) => void;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  pendoPrefix: string;
}

const StyledRoot = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
});

const StyledDragHandle = styled(Box)(({ theme }) => ({
  position: "absolute",
  left: -24,
  top: 26,
  color: theme.palette.gray6,
}));

export const GoalRow = memo((props: GoalRowProps) => {
  return (
    <StyledRoot sx={props.sx}>
      <StyledDragHandle {...props.dragHandleProps}>
        <DragIndicator />
      </StyledDragHandle>
      <CarTableMultiRow
        item={props.item}
        rows={[
          [
            {
              id: "tax_purpose",
              label: "*Goal Type",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarTaxPurposeField
                  required
                  value={item.tax_purpose}
                  onChange={(tax_purpose) =>
                    onChange({
                      ...item,
                      tax_purpose,
                    })
                  }
                />
              ),
            },
            {
              id: "description",
              label: "*Description",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarTextFieldDelayed
                  required
                  value={item.pii_description}
                  onChange={(pii_description) =>
                    onChange({ ...item, pii_description })
                  }
                />
              ),
            },
            {
              id: "priority",
              label: "*Priority",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarPriorityField
                  required
                  value={item.priority}
                  onChange={(priority) => onChange({ ...item, priority })}
                />
              ),
            },
            {
              id: "start_date_caravel",
              label: "*Start Date",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarDateExtraField
                  required
                  value={item.start_date_caravel}
                  onChange={(start_date_caravel) =>
                    onChange({ ...item, start_date_caravel })
                  }
                />
              ),
            },
            {
              id: "end_date_caravel",
              label: "*End Date",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarDateExtraField
                  required
                  minValue={item.start_date_caravel}
                  value={item.end_date_caravel}
                  onChange={(end_date_caravel) =>
                    onChange({ ...item, end_date_caravel })
                  }
                />
              ),
            },
            {
              id: "frequency",
              label: "*Frequency",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarFrequencyField
                  required
                  value={item.frequency}
                  disabled={isDateExtraSame(
                    item.start_date_caravel,
                    item.end_date_caravel,
                  )}
                  onChange={(frequency) => onChange({ ...item, frequency })}
                />
              ),
            },
          ],
          [
            {
              id: "amount",
              label: "*Annual Amount",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarCurrencyFieldDelayed
                  required
                  value={item.amount}
                  onChange={(amount) => onChange({ ...item, amount })}
                />
              ),
            },
            {
              id: "inflation",
              label: "*Inflation",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarInflationField
                  value={item.inflation}
                  onChange={(inflation) => onChange({ ...item, inflation })}
                />
              ),
            },
          ],
        ]}
        onChange={props.onChange}
        onDelete={props.onDelete}
        pendoPrefix={props.pendoPrefix}
      />
    </StyledRoot>
  );
});

setDisplayName({ GoalRow });
