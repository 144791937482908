import { Box, ButtonBase, Typography, styled } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { PortfolioGroupAssetAllocationTable } from "../PortfolioGroupAssetAllocationTable";
import { useEditPortfolioGroupEditCompare } from "./useEditPortfolioGroupEditCompare";
import { PortfolioGroupAssetStatTable } from "../PortfolioGroupAssetStatTable";
import { PortfolioGroupAssetStatChart } from "../PortfolioGroupAssetStatChart";
import { CarSwitch } from "components/Switch";
import { useStatAxisSelector } from "./useStatAxisSelector";
import {
  CarButton,
  CarDownloadButton,
  CarLoadingPromiseButtonAddRow,
} from "components/Buttons";
import { useExportAssetAllocation } from "../useExportAssetAllocation";
import { InternalUseWithDisclosures } from "./InternalUse";
import { useNavigate } from "react-router-dom";
import { editPortfolioGroupPages, editPortfolioPages } from "route.const";
import { useScrollTo } from "app/useScrollToTop";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarPageContent } from "components/PageContent";
import { StatAxisSelector } from "./StatAxisSelector";
import {
  CarFlatPageControl,
  CarFlatPageItem,
} from "components/FlatPageControl";
import { useState } from "react";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { ShowConfigSelector } from "./ShowConfigSelector";
import { CarTooltipBox } from "components/TooltipBox";
import { AssetClassStatisticsTable } from "./AssetClassStatisticsTable";

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  borderRadius: "5px",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  ...theme.typography.par01Regular,
  "&.selected": {
    color: theme.palette.primary.main,
  },
}));

const StyledPage = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(4.25),
  paddingRight: theme.spacing(4.25),
  backgroundColor: theme.palette.gray1,
  borderRadius: "5px",
  borderColor: theme.palette.gray6,
  borderStyle: "solid",
  borderWidth: "1px",
}));

export const EditPortfolioGroupEditCompare = () => {
  const data = useEditPortfolioGroupEditCompare();
  const statAxis = useStatAxisSelector();
  const navigate = useNavigate();
  const scrollTo = useScrollTo();
  const pageContent = usePageContent();

  const [currentTab, setCurrentTab] = useState<string | undefined>("assets");

  const exportAssetAllocation = useExportAssetAllocation();

  const handleExport = () => {
    exportAssetAllocation({
      tableData: data.tableData,
    });
  };

  const handleNext = () => {
    if (data.isSinglePortfolioMode) {
      navigate(`..\\${editPortfolioGroupPages.analyze}`);
    } else {
      navigate(`..\\${editPortfolioPages.analyze}`);
    }
    scrollTo("top");
  };

  return (
    <TablePlaceHolder
      isLoading={data.isLoading || pageContent.isLoading}
      rows={8}
      sx={{ mt: 5 }}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Edit/Compare
      </Typography>
      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          data.isSinglePortfolioMode
            ? CarPageContentCode.PORTFOLIO_ANALYSIS_EDIT_COMPARE
            : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_EDIT_COMPARE,
        )}
      />
      <Box
        sx={{
          mt: 4,
          display: "grid",
          gap: 4,
          gridTemplateColumns: "0.6fr 1fr",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography sx={{ mt: "19px" }} variant="h6SemiBold">
            Control Panel
          </Typography>
          <Box
            sx={{
              mt: 1.25,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "gray1",
              border: "1px solid",
              borderColor: "gray3",
              borderRadius: "5px",
              px: 3,
              py: 4,
              height: 505,
              gap: 7,
            }}
          >
            <StatAxisSelector
              statAxisSelector={statAxis}
              items={data.statTableData.statGroups.flatMap((i) => i.rows)}
            />
            <ShowConfigSelector showConfigSelector={data.showConfigSelector} />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end", ml: 11 }}>
            <Typography variant="h6SemiBold">Interactive Graph</Typography>
            <InternalUseWithDisclosures sx={{ ml: "auto", mr: 2 }} />
          </Box>
          <PortfolioGroupAssetStatChart
            statTableData={data.statTableData}
            isWhiteContext
            {...statAxis}
          />
        </Box>
      </Box>
      <CarFlatPageControl
        sx={{
          mt: 5,
          ".flat-page-control-header": {
            ml: 3,
            gap: 3,
          },
          ".flat-page-control-button": {
            p: 1,
            fontSize: "18px",
          },
        }}
        items={[
          {
            label: "Assets",
            value: "assets",
            pendoClassName: pendoClasses.editPortfolioEditCompareTabAssets,
            content: (
              <StyledPage>
                <Box
                  sx={{
                    mt: -1,
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "flex-end",
                    gap: 5,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="par02Regular">
                      Show Input Only
                    </Typography>
                    <CarTooltipBox
                      sx={{ ml: 0.5 }}
                      className={
                        pendoClasses.editPortfolioEditCompareShowInputOnly
                      }
                    />
                    <CarSwitch
                      size="small"
                      checked={!data.isTreeView}
                      onChange={(e, value) => data.setIsTreeView(!value)}
                    />
                  </Box>
                  {data.showConfigSelector.config.benchmark && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="par02Regular">
                        Define Benchmark
                      </Typography>
                      <CarTooltipBox
                        sx={{ ml: 0.5 }}
                        className={
                          pendoClasses.editPortfolioEditCompareShowInputOnly
                        }
                      />
                      <CarSwitch
                        size="small"
                        checked={data.isBenchmarkEditable}
                        onChange={(e, value) =>
                          data.setIsBenchmarkEditable(value)
                        }
                      />
                    </Box>
                  )}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="par02Regular">
                      Hide Empty Values
                    </Typography>
                    <CarTooltipBox
                      sx={{ ml: 0.5 }}
                      className={
                        pendoClasses.editPortfolioEditCompareHideEmptyValues
                      }
                    />
                    <CarSwitch
                      size="small"
                      checked={data.hideZeroValues}
                      onChange={(e, value) => data.setHideZeroValues(value)}
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, ml: "auto" }}>
                    {!data.isSinglePortfolioMode && (
                      <CarLoadingPromiseButtonAddRow
                        className={pendoClasses.addModuleButton}
                        sx={{ alignSelf: "flex-end" }}
                        disabled={!data.canAddModule}
                        onClick={data.handleModuleAdd}
                      >
                        Add Model
                      </CarLoadingPromiseButtonAddRow>
                    )}
                    <CarDownloadButton onClick={handleExport}>
                      Download
                    </CarDownloadButton>
                  </Box>
                </Box>

                <PortfolioGroupAssetAllocationTable
                  sx={{ mt: 2 }}
                  storageKey={data.portfolioGroupId}
                  tableData={data.tableData}
                  onAllocationChange={data.handleAllocationChange}
                  onModuleRename={data.handleModuleRename}
                  onModuleDelete={data.handleModuleDelete}
                  onModuleMove={data.handleModuleMove}
                  isReadOnly={false}
                  hideActionsRow={data.isSinglePortfolioMode}
                  hideZeroValues={data.hideZeroValues}
                  isTreeView={data.isTreeView}
                />
              </StyledPage>
            ),
          },
          ...data.statTableData.statGroups
            .filter((i) => !i.isHistorical)
            .map<CarFlatPageItem<string>>((group) => ({
              label: `${group.name}${
                group.isHistorical ? "" : data.simulationSuffix
              }`,
              value: group.code,
              pendoClassName:
                pendoClasses.analyzePortfolioGroupEditCompareTabStat(
                  group.code,
                ),
              content: (
                <StyledPage>
                  <PortfolioGroupAssetStatTable
                    tableData={data.tableData}
                    rows={group.rows}
                    showPercentile={group.code === "SIMULATION"}
                  />
                </StyledPage>
              ),
            })),
          {
            label: "Asset Class Statistics",
            value: "assetClassStatistics",
            pendoClassName:
              pendoClasses.editPortfolioEditCompareTabAssetClassStatistics,
            content: (
              <StyledPage>
                <AssetClassStatisticsTable
                  simulationGroupName={data.simulationGroupName}
                  items={data.assetClassStatisticsItems}
                />
              </StyledPage>
            ),
          },
        ]}
        value={currentTab}
        onChange={setCurrentTab}
      />
      <Box sx={{ mt: 5, display: "flex", gap: 2.5, justifyContent: "center" }}>
        <CarButton onClick={handleNext}>Next</CarButton>
      </Box>
    </TablePlaceHolder>
  );
};
