import { Box, MenuItem, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo, useEffect, useState } from "react";
import { InternalUseWithDisclosures } from "../assetAllocation/editPortfolioGroup/InternalUse";
import {
  AssetClassAnalysisSectorAnalysisTable,
  SectorAnalysisLegendItem,
} from "./AssetClassAnalysisSectorAnalysisTable";
import { useAssetClassAnalysisSectorAnalysis } from "./useAssetClassAnalysisSectorAnalysis";
import { ChartSectorSummary } from "../assetAllocation/editPortfolioGroup/ChartSectorSummary";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarSelectField } from "components/Inputs";
import { CarPageContent } from "components/PageContent";

const legendItems: Array<{ level: number; label: string }> = [
  { level: 0, label: "Industry Group" },
  { level: 1, label: "Industry" },
  { level: 2, label: "Subgroup" },
];

export const AssetClassAnalysisSectorAnalysis = memo(() => {
  const data = useAssetClassAnalysisSectorAnalysis();
  const pageContent = usePageContent();

  const [sectorFocusId, setSectorFocusId] = useState<string | undefined>(
    undefined,
  );

  const [highlightLevel, setHighlightLevel] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!sectorFocusId && data.report.level1.length > 0) {
      setSectorFocusId(data.report.level1[0].id);
    }
  }, [sectorFocusId, data.report.level1]);

  const sectorFocusItem = data.report.level1.find(
    (i) => i.id === sectorFocusId,
  );

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 10 }}
      isLoading={data.isLoading || pageContent.isLoading}
      rows={8}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Sector Analysis
      </Typography>
      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS_SECTOR_GRAPH,
        )}
      />

      <Box
        sx={{
          mt: 5,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "gray1",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "gray3",
          py: 5,
          px: 12.5,
        }}
      >
        <InternalUseWithDisclosures />
        <ChartSectorSummary
          selectorLabel1="Asset Class 1"
          selectorLabel2="Asset Class 2"
          report={data.report}
          chartItems={data.chartItems}
          chartDataSelector={data.chartDataSelector}
          pendoPrefix=""
        />
      </Box>

      <AssetClassAnalysisSectorAnalysisTable
        sx={{ mt: 7, mx: 10 }}
        label1={data.label1}
        label2={data.label2}
        items={data.report.level1}
        isExpandable={false}
      />

      <Box sx={{ mt: 7.5, display: "flex", alignItems: "flex-end" }}>
        <CarSelectField
          label="Sector Focus"
          sx={{
            width: 200,
          }}
          value={sectorFocusId}
          onChange={setSectorFocusId}
        >
          {data.report.level1.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          ))}
        </CarSelectField>
        <Box sx={{ ml: "auto", mb: 0.5, display: "flex", gap: 2 }}>
          {legendItems.map((i) => (
            <SectorAnalysisLegendItem
              key={i.level}
              level={i.level}
              label={i.label}
              isHighlighted={highlightLevel === i.level}
              onHighlightChange={(value) =>
                setHighlightLevel(value ? i.level : undefined)
              }
            />
          ))}
        </Box>
      </Box>
      {sectorFocusItem && (
        <>
          <Typography variant="h6SemiBold" sx={{ mt: 5.5 }}>
            {sectorFocusItem?.name}
          </Typography>
          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "gray1",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "gray3",
              py: 3.5,
              px: 5,
            }}
          >
            <CarPageContent
              content={pageContent.getContent(
                CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS_ANALYSIS_DETAIL_SECTOR_GRAPH,
              )}
              isWhiteContext
            />
            <AssetClassAnalysisSectorAnalysisTable
              sx={{ mt: 4 }}
              label1={data.label1}
              label2={data.label2}
              items={sectorFocusItem.children}
              isExpandable
              showTotal
              highlightLevel={highlightLevel}
            />
          </Box>
        </>
      )}
    </TablePlaceHolder>
  );
});
