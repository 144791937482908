import { useTheme, Box } from "@mui/material";
import { CarButton } from "./Buttons";
import { useState } from "react";
import { CarCheckbox } from "./Checkbox";
import { BaseDialog } from "./BaseDialog";
import { toTitleCase } from "utils";
import { CarIconDelete } from "icons";

interface DeleteItemDialogProps {
  itemName: string;
  itemTypeName: string;
  onApply: (result: boolean) => void;
  onCancel: () => void;
}

export function DeleteItemDialog({
  itemName,
  itemTypeName = "client",
  onApply,
  onCancel,
}: DeleteItemDialogProps) {
  const [accepted, setAccepted] = useState<boolean>(false);
  const theme = useTheme();

  const title = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: theme.spacing(1),
        color: "softBlack",
        fontSize: "25px",
        fontWeight: 600,
      }}
    >
      <CarIconDelete
        sx={{
          mt: -0.5,
          color: "red",
          fontSize: "30px",
        }}
      />
      Delete {toTitleCase(itemTypeName)}?
    </Box>
  );

  return (
    <BaseDialog
      title={title}
      subtitle={`Are you sure you want to delete “${itemName}”?`}
      onClose={onCancel}
      actions={
        <>
          <CarButton
            sx={{ fontSize: "18px" }}
            onClick={onCancel}
            variant="outlined"
          >
            Cancel
          </CarButton>
          <CarButton
            sx={{ fontSize: "18px", marginLeft: theme.spacing(1) }}
            onClick={() => onApply(true)}
            disabled={!accepted}
            color="error"
          >
            Delete
          </CarButton>
        </>
      }
      sx={{
        width: 455,
      }}
    >
      <CarCheckbox
        checked={accepted}
        onChange={(e, checked) => setAccepted(checked)}
        label={`Click here to accept that you will be deleting ${itemTypeName} data.`}
        sx={{
          // width: 300,
          // margin: theme.spacing( 2, 4),
          m: 0,
          mt: 1,
          mb: 2,
          ".MuiFormControlLabel-label": {
            fontSize: 12,
            fontWeight: 400,
            fontStyle: "italic",
          },
        }}
      />
    </BaseDialog>
  );
}
