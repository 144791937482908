import { Box, Typography } from "@mui/material";
import { FullScreenBackground } from "components/FullScreenBackground";
import { CarFooter } from "components/Footer";
import { PaymentForm } from "./PaymentForm";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "features/general/useLocalStorage";
import { rootPages } from "route.const";
import { useFirm } from "pages/settings/useFirm";
import { LoginTransparentBox } from "pages/login/LoginComponents";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useLogout } from "app/useLogout";
import { useCommonBillingActivateBillingCreateQuery } from "api/carApi.generated";

const isPaymentSkippedKey = "isPaymentSkipped";

export const PaymentChecker = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    firm: { is_paid },
    isTrialExpired,
  } = useFirm();
  const [isPaymentSkipped] = useSessionStorage(isPaymentSkippedKey, false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if ((!is_paid && !isPaymentSkipped) || isTrialExpired) {
      navigate(rootPages.payment);
    }
  }, [isLoading, is_paid, isPaymentSkipped, isTrialExpired, navigate]);

  return <></>;
};

export const PaymentPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPaymentSkipped] = useSessionStorage(isPaymentSkippedKey, false);
  const navigate = useNavigate();

  const activateBilling = useCommonBillingActivateBillingCreateQuery();

  const firm = useFirm();
  const { logout } = useLogout();

  useEffect(() => {
    if (firm.isPaid) {
      navigate(rootPages.assetAllocationUrl);
    }
  }, [firm.isPaid, navigate]);

  const handleSkipPayment = () => {
    setPaymentSkipped(true);
    navigate(rootPages.assetAllocationUrl);
  };

  const handleBackToLogin = () => {
    logout();
  };

  return (
    <FullScreenBackground
      sx={{
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        display: "flex",
      }}
    >
      <Box
        sx={{
          m: "auto",
          py: 2,
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          minHeight: 0,
        }}
      >
        <LoginTransparentBox sx={{ px: "77px", py: "54px", minWidth: 680 }}>
          <TablePlaceHolder
            isLoading={firm.isLoading || activateBilling.isLoading}
            rows={5}
          >
            <PaymentForm
              amount={activateBilling.data?.stripe_invoice_amount_due ?? 0}
              clientSecret={
                activateBilling.data
                  ?.stripe_invoice_payment_intent_client_secret ?? ""
              }
              onSkipPayment={
                firm.isTrialExpired ? undefined : handleSkipPayment
              }
            />
            <Typography
              variant="par02Regular"
              sx={{
                alignSelf: "center",
                mt: 2,
                mb: -3,
                span: {
                  color: "primary.main",
                  textDecoration: "underline",
                  cursor: "pointer",
                },
              }}
            >
              Back to <span onClick={handleBackToLogin}>Login</span>
            </Typography>
          </TablePlaceHolder>
        </LoginTransparentBox>
        <CarFooter
          sx={{ py: 1.5, alignSelf: "center" }}
          isWhite
          isCopyrightOnly
          hasPrivacyPolicy
        />
      </Box>
    </FullScreenBackground>
  );
};
