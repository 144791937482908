import { Box, BoxProps, styled, Typography } from "@mui/material";
import { CarSimulationStatItem } from "./useSimulationReturns";
import {
  DecimalOptions,
  formatNumber,
  formatPercentFactor,
  isDefined,
} from "utils";
import { Fragment } from "react";
import { ExcelDataFormat } from "app/useExcelExport";

const Divider = styled(Box)(({ theme }) => ({
  borderLeft: "1px solid",
  borderColor: theme.palette.gray3,
  alignSelf: "stretch",
  marginLeft: theme.spacing(3),
  marginRight: theme.spacing(3),
  minHeight: 80,
}));

const formatValue = ({
  format,
  value,
  decimalOptions = {
    decimalPlaces: 2,
    forceShowDecimals: true,
  },
}: {
  format?: ExcelDataFormat;
  value?: number | null;
  decimalOptions?: DecimalOptions;
}) =>
  isDefined(value)
    ? format === ExcelDataFormat.float
      ? formatNumber(value, decimalOptions)
      : formatPercentFactor(value, decimalOptions)
    : "-";

const StatBoxItem = (props: { item: CarSimulationStatItem }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Typography
      variant="caption"
      sx={{ color: "softBlack", textAlign: "center" }}
    >
      {props.item.label}
    </Typography>
    <Typography variant="h4Bold">{formatValue(props.item)}</Typography>
  </Box>
);

export const SimulationStatBox = (props: {
  sx?: BoxProps["sx"];
  items: CarSimulationStatItem[];
  isWhiteContext?: boolean;
}) => (
  <Box
    sx={{
      mt: 1,
      display: "flex",
      justifyContent: "space-around",
      border: "2px solid",
      borderColor: "gray3",
      borderRadius: "5px",
      backgroundColor: props.isWhiteContext ? "gray1" : "white",
      px: 6,
      py: 2.5,
      ...props.sx,
    }}
  >
    {props.items.map((i, idx, arr) => (
      <Fragment key={idx}>
        <StatBoxItem item={i} />
        {idx !== arr.length - 1 && <Divider />}
      </Fragment>
    ))}
  </Box>
);
