import { useEffect, useState } from "react";
import { BaseDialog } from "components/BaseDialog";
import { CarLoadingButton } from "components/Buttons";
import { usePortfolioPortfolioGroupVersionRetrieveQuery } from "api/carApi.generated";
import { BoxProps, Box, Typography, styled } from "@mui/material";
import SimpleBar from "simplebar-react";
import { CarComboField } from "components/ComboField";
import { East } from "@mui/icons-material";
import { TablePlaceHolder } from "components/PlaceHolder";

const Icon = (props: BoxProps) => (
  <Box
    component="svg"
    fill="none"
    viewBox="0 0 101 92"
    sx={{
      flex: "none",
      width: 101,
      height: 92,
      ...props.sx,
    }}
  >
    <path
      d="M64.9996 16.6992H29.2996C21.2146 16.6992 14.5996 23.3142 14.5996 31.3992C14.5996 39.4842 21.2146 46.0992 29.2996 46.0992H52.3996H48.1996H67.0996C75.1846 46.0992 81.7996 52.7142 81.7996 60.7992C81.7996 68.8842 75.1846 75.4992 67.0996 75.4992H31.3996"
      stroke="#F19649"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M58.7002 25.0969L69.2002 16.6969L58.7002 8.29688"
      stroke="#F19649"
      strokeWidth="3"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M27.2 90.1969L14.6 60.7969L2 90.1969"
      stroke="#F19649"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.49414 79.6992H22.7061"
      stroke="#F19649"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M91.2496 16.7C95.2816 16.7 98.5996 20.018 98.5996 24.05C98.5996 28.082 95.2816 31.4 91.2496 31.4H77.5996V2H91.2496C95.2816 2 98.5996 5.318 98.5996 9.35C98.5996 13.382 95.2816 16.7 91.2496 16.7ZM91.2496 16.7H77.5996"
      stroke="#F19649"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Box>
);

const StyledCombo = styled(CarComboField)(({ theme }) => ({
  ".MuiOutlinedInput-root": {
    backgroundColor: theme.palette.gray1,
    input: {
      padding: "13px 10px !important",
      fontWeight: 600,
      fontSize: "16px",
      letterSpacing: "-0.04em",
    },
  },
}));

export type MappingModuleItem = {
  value: string;
  label: string;
};

export interface MappingModulesDialogProps {
  mappingModuleItems: MappingModuleItem[];
  portfolioGroupVersionId: string;
  subtitle: string;
  onAccept: (mapping: { [key: string]: string }) => Promise<boolean>;
  onApply: () => void;
  onCancel: () => void;
}

export const MappingModulesDialog = (props: MappingModulesDialogProps) => {
  const [mapping, setMapping] = useState<{
    [key: string]: string;
  }>({});

  const [newModules, setNewModules] = useState<MappingModuleItem[]>([]);
  const oldModules = props.mappingModuleItems;

  const [isLoading, setIsLoading] = useState(false);

  const version = usePortfolioPortfolioGroupVersionRetrieveQuery({
    id: props.portfolioGroupVersionId,
  });

  useEffect(() => {
    if (version.isLoading) {
      return;
    }
    const nm =
      version.data?.portfolio_modules
        ?.filter((i) => !i.is_cash)
        .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0))
        .map<MappingModuleItem>((i) => ({
          value: i.id ?? "",
          label: i.title,
        })) ?? [];
    setNewModules(nm);

    const initialMapping: {
      [key: string]: string;
    } = {};

    oldModules.forEach((old) => {
      const newModule = nm.find((i) => i.label === old.label);

      if (newModule) {
        initialMapping[newModule.value] = old.value;
      }
    });

    setMapping(initialMapping);
  }, [version, oldModules]);

  const handleApply = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      if (await props.onAccept(mapping)) {
        props.onApply();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseDialog
      onClose={props.onCancel}
      sx={{
        width: 900,
        maxWidth: 900,
        maxHeight: "95vh",
        px: "55px",
        py: "55px", //"70px",
        borderRadius: "15px",
      }}
      actions={
        <>
          <CarLoadingButton
            sx={{ alignSelf: "center", fontSize: "18px" }}
            onClick={handleApply}
            // disabled={!(value ?? "").trim()}
            autoFocus
            isLoading={isLoading}
          >
            Accept
          </CarLoadingButton>
        </>
      }
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Icon />
        <Typography variant="h1SSemiBold" sx={{ mt: 2 }}>
          Mapping Modules
        </Typography>
        <Typography
          variant="par03SemiBold"
          sx={{
            mt: 1,
            color: "gray6",
            lineHeight: "30px",
            letterSpacing: "-0.04em",
            textAlign: "center",
          }}
        >
          {props.subtitle}
        </Typography>
        <TablePlaceHolder isLoading={version.isLoading} rows={5}>
          <Box
            sx={{
              mt: 1.5, // 3.8,
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <Box
              sx={{
                display: "flex",
                borderBottom: "solid 1px",
                borderBottomColor: "gray6",
                pb: 2,
              }}
            >
              <Typography
                sx={{
                  ml: 1,
                  fontSize: "13px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: "gray6",
                }}
              >
                Old Modules
              </Typography>
              <Typography
                sx={{
                  ml: 52.5,
                  fontSize: "13px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  color: "gray6",
                }}
              >
                New Modules
              </Typography>
            </Box>
            <SimpleBar
              style={{
                width: "calc(100% + 18px)",
                minHeight: 0,
                maxHeight: "100%",
                // maxHeight: 400,
                paddingRight: 18,
                marginBottom: 10, // 38,
              }}
            >
              {newModules.map((i) => (
                <Box
                  key={i.value}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    borderBottom: "solid 1px",
                    borderBottomColor: "gray6",
                    py: 2.2,
                    gap: 0.5,
                  }}
                >
                  <StyledCombo
                    sx={{ ml: 1, width: 280, mr: 9 }}
                    value={mapping[i.value] ?? ""}
                    items={oldModules}
                    onChange={(value) =>
                      setMapping((oldValue) => ({
                        ...oldValue,
                        [i.value]: value ?? "",
                      }))
                    }
                  />

                  <Typography
                    variant="par01Regular"
                    sx={{ color: "gray6", fontSize: "13px" }}
                  >
                    Maps to
                  </Typography>
                  <East sx={{ color: "softBlack", fontSize: "1.5em" }} />
                  <Typography
                    variant="h6SSemiBold"
                    sx={{ ml: 8, pb: 0.25, width: 280 }}
                  >
                    {i.label}
                  </Typography>
                </Box>
              ))}
            </SimpleBar>
          </Box>
        </TablePlaceHolder>
      </Box>
    </BaseDialog>
  );
};
