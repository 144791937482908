import { IconButton, Typography, styled } from "@mui/material";
import { Box, lighten } from "@mui/system";
import { CarClose } from "icons";
import { forwardRef, ReactNode } from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: 49,
  minWidth: 398,
  maxWidth: 398,
  border: "solid 2px",
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(0.5),
  padding: theme.spacing(2),
  ".close-button": {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(-1),
  },
  h5: {
    color: "black",
    fontSize: "15px",
  },
  "&.success": {
    borderColor: theme.palette.green,
    backgroundColor: lighten(theme.palette.green, 0.6),
    svg: {
      color: theme.palette.green,
    },
  },
  "&.error": {
    borderColor: theme.palette.red,
    backgroundColor: lighten(theme.palette.red, 0.6),
    svg: {
      color: theme.palette.red,
    },
  },
  "&.info": {
    borderColor: theme.palette.lightBlue,
    backgroundColor: lighten(theme.palette.lightBlue, 0.6),
    svg: {
      color: theme.palette.lightBlue,
    },
  },
}));

interface SuccessToastProps {
  kind: "success" | "error" | "info";
  onClose: () => void;
  children?: ReactNode;
}

export const SuccessToast = forwardRef((props: SuccessToastProps, ref) => (
  <StyledBox ref={ref} className={props.kind}>
    <Typography variant="h5">{props.children}</Typography>
    <IconButton className="close-button" size="small" onClick={props.onClose}>
      <CarClose />
    </IconButton>
  </StyledBox>
));
