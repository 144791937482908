import {
  Account,
  AccountHolding,
  AccountManualSimpleHolding,
  CaravelDate,
  ClientPlan,
  ClientPlanRisk,
  CustomTokenObtainPair,
  ExternalBlackDiamondAccount,
  ExternalBlackDiamondPortfolio,
  GoalResult,
  GoalStatusEnum,
  LinkBlackDiamondAccounts,
  PatchedAccount,
  PatchedClient,
  PatchedClientPlanAdditionalIncome,
  PatchedClientPlanGoal,
  PatchedClientPlanHealthcare,
  PatchedClientPlanRealEstate,
  PatchedClientPlanSocialSecurity,
  PatchedClientPlanDeRiskDetails,
  PatchedUser,
  PlanResult,
  PortfolioGroup,
  RiskQuestion,
  Savings,
  SavingsContributionData,
  StressTestData,
  SuffixEnum,
  UsStateEnum,
  PatchedClientPlanRelationship,
  AccountUiInputTypeEnum,
  ClientNote,
  ClientPlanRealEstateRefinance,
  PaymentPlanEnum,
  ClientTier,
  ClientPlanStatusEnum,
  PatchedInvitation,
  FirmAssetClassImplementation,
  FirmAssetClassDetail,
  Firm,
} from "./api/carApi.generated";

export enum SectionState {
  notTouched = "notTouched",
  workInProgress = "workInProgress",
  complete = "complete",
}

export type CarAuthInfo = Omit<CustomTokenObtainPair, "email" | "password">;

export type CarUser = PatchedUser;

export type CarInvitation = PatchedInvitation;

export type CarFirm = Firm;

export type CarClient = PatchedClient;

export type CarRelationship = PatchedClientPlanRelationship;

export type CarSocialSecurity = PatchedClientPlanSocialSecurity;

export type CarAdditionalIncome = PatchedClientPlanAdditionalIncome;

export type CarHealthcare = PatchedClientPlanHealthcare;

export type CarGoal = PatchedClientPlanGoal;

export type CarRisk = Partial<ClientPlanRisk>;

export type CarDeRiskDetails = PatchedClientPlanDeRiskDetails;

export type CarBlackDiamondAccount = ExternalBlackDiamondAccount;

export type CarBlackDiamondPortfolio = ExternalBlackDiamondPortfolio;

export type CarRiskQuestion = RiskQuestion;

export type CarLinkExternalCreate = LinkBlackDiamondAccounts;

export type CarSavings = Partial<Savings>;

export type CarPosition = AccountHolding;

export type CarSimplePosition = AccountManualSimpleHolding;

export type CarAccount = Omit<PatchedAccount, "savings"> & {
  savings?: Array<CarSavings>;
};

export type CarRequiredAccount = Account;

export type CarRealEstate = PatchedClientPlanRealEstate;
export type CarRealEstateMortgage = ClientPlanRealEstateRefinance;

export type CarClientPlan = Partial<ClientPlan>;

export type CarStateKind = UsStateEnum;
export type CarTaxesKind = NonNullable<CarAdditionalIncome["taxes"]>;
export type CarInflationKind = NonNullable<CarAdditionalIncome["inflation"]>;
export type CarDateExtra = CaravelDate;
export type CarDateExtraKind = NonNullable<CarDateExtra["kind"]>;
export type CarPriorityKind = NonNullable<CarGoal["priority"]>;
export type CarGoalIcon = NonNullable<CarGoal["icon"]>;
export type CarRiskQuestionAnswers = NonNullable<CarRisk["question_answers"]>;
export type CarSavingsExtra = SavingsContributionData;
export type CarSavingsExtraKind = "PERCENT" | "DOLLAR" | "MAX";
export type CarAccountInputType = NonNullable<CarAccount["account_input_type"]>;
export type CarAccountInputKind = AccountUiInputTypeEnum;
export type CarRealEstateType = NonNullable<CarRealEstate["real_estate_type"]>;
export type CarPaymentPlanKind = PaymentPlanEnum;

export type CarSuffix = SuffixEnum | "";

export type CarPlanResult = PlanResult;

export type CarGoalResult = GoalResult;

export type CarAssetClassHierarchy = FirmAssetClassDetail;

export type CarGoalStatusEnum = GoalStatusEnum;

export type CarStressTestData = StressTestData;

export type CarPortfolioGroup = PortfolioGroup;

export type CarClientPlanStatus = ClientPlanStatusEnum;

export type CarClientNote = ClientNote;

export type CarClientTier = ClientTier;

export type CarAssetClassImplementation = FirmAssetClassImplementation;

export type IdObj = {
  id?: string;
  created_at?: string;
  ui_key?: string;
};
export interface CarComboItem {
  label: string;
  value: any;
}
