import { useMemo } from "react";
import { useLogout } from "./useLogout";

export const useSideBar = () => {
  const helpUrl = "https://docs.caravelconcepts.com/en/";

  const { logout } = useLogout();
  return useMemo(
    () => ({
      logout,
      helpUrl,
    }),
    [logout],
  );
};
