import { Box, Typography, styled } from "@mui/material";
import { useClientId } from "app/usePlanId";
import { CarSelectOption, CarSelectOptionField } from "components/Inputs";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { isDebug } from "const";
import { addMinutes, addMonths, format, formatISO } from "date-fns";
import {
  CarNotification,
  useNotifications,
} from "pages/notifications/useNotifications";
import { financialPlanningPages } from "route.const";
import SimpleBar from "simplebar-react";

const StyledRoot = styled(RootPageBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "none",
  padding: theme.spacing(3),
  gap: theme.spacing(1),
}));

const NotificationLink = (props: { url: string; label: string }) => (
  <Typography
    component="a"
    href={props.url}
    target="_blank"
    variant="par01SemiBold"
    sx={{ color: "caravelOrangePrimary", cursor: "pointer", fontSize: "12px" }}
  >
    {props.label}
  </Typography>
);

const snoozeOptions: Array<CarSelectOption<number>> = [
  { label: "1 month", value: 1 },
  { label: "3 months", value: 3 },
  { label: "6 months", value: 6 },
  { label: "12 months", value: 12 },
];

interface AlertItemProps {
  item: CarNotification;
  onSnooze: (item: CarNotification, value: string) => void;
}

const AlertItem = ({ item, onSnooze }: AlertItemProps) => {
  const handleSnooze = (value?: number) => {
    if (!value) {
      return;
    }
    if (isDebug) {
      onSnooze(item, formatISO(addMinutes(new Date(), 1)));
    } else {
      onSnooze(item, formatISO(addMonths(new Date(), value)));
    }
  };

  return (
    <Box
      sx={{
        border: "solid 1px",
        borderColor: "gray6",
        borderRadius: "5px",
        backgroundColor: "gray1",
        p: 2,
        minHeight: 100,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography
        variant="mediumItalic"
        sx={{ fontSize: "11px", fontWeight: 600 }}
      >
        {format(item.date, "Pp")}
      </Typography>
      <Typography variant="par01Regular" sx={{ whiteSpace: "pre-line" }}>
        {item.description}
      </Typography>
      <Box sx={{ display: "flex", gap: 2.5, alignItems: "center" }}>
        {item.clientId && item.planId && (
          <NotificationLink
            url={
              item.planResultId
                ? financialPlanningPages.getWorkbenchUrl(
                    item.clientId,
                    item.planId,
                    item.planResultId,
                  )
                : financialPlanningPages.getPlanInputUrl(
                    item.clientId,
                    item.planId,
                  )
            }
            label="View Plan"
          />
        )}
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Typography variant="par01Regular" sx={{ fontSize: "12px" }}>
            Snooze for:
          </Typography>
          <CarSelectOptionField
            sx={{
              width: 114,
              ".MuiSelect-select": {
                pt: "9px",
                pb: "5px",
                backgroundColor: "white",
              },
            }}
            options={snoozeOptions}
            value={undefined}
            onChange={handleSnooze}
          />
        </Box>
      </Box>
    </Box>
  );
};

const EmptyAlert = () => (
  <Box
    sx={{
      border: "solid 1px",
      borderColor: "gray6",
      borderRadius: "5px",
      backgroundColor: "gray1",
      p: 2,
      minHeight: 180,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Typography variant="mediumItalic" sx={{ fontSize: "12px" }}>
      No alerts at this time
    </Typography>
  </Box>
);

export const ProgressMonitoringAlerts = () => {
  const data = useNotifications(false);
  const clientId = useClientId();
  const items = data.items.filter((n) => n.clientId === clientId);
  return (
    <StyledRoot>
      <TablePlaceHolder isLoading={data.isLoading} rows={3}>
        <Typography variant="h5SSemiBold">Alerts</Typography>
        <Box
          sx={{
            mt: 1,
            position: "relative",
            flex: "auto",
            minHeight: 190,
          }}
        >
          <SimpleBar
            autoHide={false}
            forceVisible={items.length > 1 ? "y" : undefined}
            style={{
              position: "absolute",
              width: "calc(100% + 14px)",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              paddingRight: "14px",
            }}
          >
            {items.length === 0 && <EmptyAlert />}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
              {items.map((i) => (
                <AlertItem
                  key={i.id}
                  item={i}
                  onSnooze={data.handleSnoozeNotification}
                />
              ))}
            </Box>
          </SimpleBar>
        </Box>
      </TablePlaceHolder>
    </StyledRoot>
  );
};
