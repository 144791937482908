import { useSimulationQaReportYieldsCreateQuery } from "api/carApi.generated";
import { useMemo } from "react";
import {
  CarSimulationABTestPercentileValue,
  getSimulationPercentileExcelTable,
  simPercentileToTable,
} from "./useSimulationABTestReturns";
import { useExcelExport } from "app/useExcelExport";
import { formatDateTime, formatNumber } from "utils";
import { SimulationDataExplorerDialog } from "./SimulationDataExplorerDialog";
import { useDialog } from "app/useDialog";
import { useSimulationAssetClassSelector } from "./useSimulationAssetClassSelector";

export const useSimulationABTestYields = (params: {
  simulationJobIdA: string;
  simulationJobIdB: string;
}) => {
  const assetClassSelector = useSimulationAssetClassSelector({
    storageKey: "useSimulationABTestYields_assetClassSelector",
    simulationJobId: params.simulationJobIdA,
    isCashAndFixedIncomeOnly: true,
  });
  const excelExport = useExcelExport();
  const dialog = useDialog();

  const assetClassCode = assetClassSelector.value ?? "";

  const reportYieldsA = useSimulationQaReportYieldsCreateQuery(
    {
      simulationQaSummaryReportRequestAssetClass: {
        simulation_job_id: params.simulationJobIdA,
        asset_class_code: assetClassCode,
      },
    },
    {
      skip: !assetClassCode,
    },
  );

  const reportYieldsB = useSimulationQaReportYieldsCreateQuery(
    {
      simulationQaSummaryReportRequestAssetClass: {
        simulation_job_id: params.simulationJobIdB,
        asset_class_code: assetClassCode,
      },
    },
    {
      skip: !assetClassCode,
    },
  );

  const dataA = reportYieldsA.data;
  const dataB = reportYieldsB.data;

  return useMemo(() => {
    const table = simPercentileToTable({
      simulationJobIdA: params.simulationJobIdA,
      simulationJobIdB: params.simulationJobIdB,
      assetClassCode: assetClassSelector.value ?? "",
      valueA: dataA?.asset_classes.at(0)?.sim_percentile,
      valueB: dataB?.asset_classes.at(0)?.sim_percentile,
    });

    const inflationTable = simPercentileToTable({
      simulationJobIdA: params.simulationJobIdA,
      simulationJobIdB: params.simulationJobIdB,
      valueA: dataA?.cpi.sim_percentile,
      valueB: dataB?.cpi.sim_percentile,
    });

    const assetClassName = assetClassSelector.selectedItem?.name ?? "";

    const handleDownloadReports = () => {
      excelExport.exportAllExcel({
        fileName: `Simulation AB Test Yields - ${dataA?.simulation_name} - ${formatDateTime(
          dataA?.simulation_date,
        )
          .replaceAll("/", "-")
          .replaceAll(":", "-")} - ${dataB?.simulation_name} - ${formatDateTime(
          dataB?.simulation_date,
        )
          .replaceAll("/", "-")
          .replaceAll(":", "-")} - ${assetClassName}`,
        tables: [
          getSimulationPercentileExcelTable({
            name: assetClassName,
            table,
          }),
          getSimulationPercentileExcelTable({
            name: `Inflation`,
            table: inflationTable,
          }),
        ],
      });
    };

    const handleValueClick = (
      value: CarSimulationABTestPercentileValue,
      isAValue: boolean,
    ) => {
      dialog(SimulationDataExplorerDialog, {
        title: assetClassName,
        subTitle: `${formatNumber(
          value.percentile,
        )}th percentile for Yields in Year ${value.year}`,
        simulationJobId: isAValue
          ? table.simulationJobIdA
          : table.simulationJobIdB,
        assetClassCode: table.assetClassCode,
        filterDataType: "yields",
        filterYear: value.year,
        filterValueGt:
          (isAValue
            ? value.valueA.simLowerBound
            : value.valueB.simLowerBound) ?? undefined,
        filterValueLt:
          (isAValue
            ? value.valueA.simUpperBound
            : value.valueB.simUpperBound) ?? undefined,
      });
    };

    return {
      isLoading: reportYieldsA.isLoading || reportYieldsB.isLoading,
      isFetching: reportYieldsA.isFetching || reportYieldsB.isFetching,
      table,
      inflationTable,
      assetClassSelector,
      assetClassName,
      handleDownloadReports,
      handleValueClick,
    };
  }, [
    assetClassSelector,
    dataA?.asset_classes,
    dataA?.cpi.sim_percentile,
    dataA?.simulation_date,
    dataA?.simulation_name,
    dataB?.asset_classes,
    dataB?.cpi.sim_percentile,
    dataB?.simulation_date,
    dataB?.simulation_name,
    dialog,
    excelExport,
    params.simulationJobIdA,
    params.simulationJobIdB,
    reportYieldsA.isFetching,
    reportYieldsA.isLoading,
    reportYieldsB.isFetching,
    reportYieldsB.isLoading,
  ]);
};
