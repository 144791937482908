import { Box, styled, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { Fragment, memo, useEffect, useMemo, useState } from "react";
import { CarSelectOption, CarSelectOptionField } from "components/Inputs";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { CarFlatPageControl } from "components/FlatPageControl";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import {
  ExcelDataFormat,
  ExcelTable,
  excelTableFormatValue,
  excelTableToGridTemplateColumns,
} from "app/useExcelExport";
import { isDefined, isOddEven } from "utils";
import { useSimulationModels } from "./useSimulationModels";
import { CarDownloadButton } from "components/Buttons";
import { SimulationStatBox } from "./SimulationStatBox";
import { SimulationModelsChart } from "./SimulationModelsChart";
import { chartsColor } from "theme";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";

const StyledBox = styled(Box)(({ theme }) => ({
  border: "solid 1px",
  borderColor: theme.palette.gray6,
  borderRadius: "5px",
  backgroundColor: theme.palette.white,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(5.5),
}));

interface SimulationModelsModelsProps {}

const DataTable = ({ table }: { table: ExcelTable }) => {
  return (
    <StyledBox>
      <CarInfoTable
        sx={{ gridTemplateColumns: excelTableToGridTemplateColumns(table) }}
      >
        {table.columns.map((col, idx) => (
          <CarInfoHeader
            key={idx}
            text={col.label}
            pendoClass={col.pendoClassName}
            isSticky
          />
        ))}
        {table.rows.map((row, rowIdx) => (
          <Fragment key={rowIdx}>
            {row.map((val, valIdx) => (
              <CarInfoCell
                key={valIdx}
                isOdd={isOddEven(rowIdx)}
                justifyContent={
                  table.columns.at(valIdx)?.format === ExcelDataFormat.general
                    ? "start"
                    : undefined
                }
                text={excelTableFormatValue(val, table.columns.at(valIdx))}
              />
            ))}
          </Fragment>
        ))}
      </CarInfoTable>
    </StyledBox>
  );
};

const Legend = () => (
  <Box
    sx={{
      mt: 3,
      minHeight: 80,
      display: "flex",
      justifyContent: "space-around",
      border: "2px solid",
      borderColor: "gray3",
      borderRadius: "5px",
      backgroundColor: "white",
      alignItems: "center",
      px: 6,
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="caption"
        sx={{ color: "softBlack", textAlign: "center" }}
      >
        <Box
          sx={{
            display: "inline-block",
            height: 3,
            width: 18,
            backgroundColor: "softBlack",
            borderRadius: "2px",
            mb: 0.3,
            mr: 1.5,
          }}
        />
        Trend Line for Spread Data
      </Typography>
    </Box>
    <Box
      sx={{ borderRight: "1px solid", borderRightColor: "gray3", height: 60 }}
    />
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 1.5,
        }}
      >
        <Typography
          variant="caption"
          sx={{ color: "softBlack", textAlign: "center" }}
        >
          <Box
            sx={{
              mb: -0.6,
              mr: 1.5,
              display: "inline-block",
              height: 20,
              width: 20,
              backgroundColor: chartsColor.blue,
              borderRadius: "50%",
              border: "1px solid",
              borderColor: "gray6",
            }}
          />
          Historical Points
        </Typography>
        <Typography
          variant="caption"
          sx={{ color: "softBlack", textAlign: "center" }}
        >
          <Box
            sx={{
              mb: -0.6,
              mr: 1.5,
              display: "inline-block",
              height: 20,
              width: 20,
              backgroundColor: chartsColor.red,
              borderRadius: "50%",
              border: "1px solid",
              borderColor: "gray6",
            }}
          />
          Most Recent Point
        </Typography>
      </Box>
    </Box>
  </Box>
);

export const SimulationModelsModels = memo(
  (props: SimulationModelsModelsProps) => {
    const [assetClassId, setAssetClassId] = useState<string | undefined>();
    const [regressionResultItemIndex, setRegressionResultItemIndex] = useState<
      number | undefined
    >();
    const [currentTab, setCurrentTab] = useState(0);

    const assetClasses = useAssetClasses({
      type: AssetClassLoadType.allFirm,
    });

    const assetClassOptions = useMemo(
      () =>
        assetClasses.items.map<CarSelectOption<string>>((i) => ({
          label: i.name,
          value: i.level4Id,
        })),
      [assetClasses.items],
    );

    useEffect(() => {
      if (
        !assetClasses.isLoading &&
        !assetClassOptions.some((i) => i.value === assetClassId)
      ) {
        setAssetClassId(assetClassOptions.at(0)?.value);
      }
    }, [assetClassId, assetClassOptions, assetClasses.isLoading]);

    const assetClassItem = assetClasses.items.find(
      (i) => i.level4Id === assetClassId,
    );

    const data = useSimulationModels({
      assetClassId,
      assetClassName: assetClassItem?.name,
    });

    const regressionResultItemCount = data.regressionResultItems.length;
    useEffect(() => {
      if (
        !isDefined(regressionResultItemIndex) ||
        regressionResultItemIndex >= regressionResultItemCount
      ) {
        setRegressionResultItemIndex(0);
      }
    }, [regressionResultItemCount, regressionResultItemIndex]);

    const currentRegressionResultItem = data.regressionResultItems.at(
      regressionResultItemIndex ?? -1,
    );

    const pageContent = usePageContent();

    return (
      <TablePlaceHolder
        sx={{ mx: 7, mt: 10 }}
        isLoading={assetClasses.isLoading || pageContent.isLoading}
        rows={8}
      >
        <CarPageContent
          sx={{ mb: 3 }}
          content={pageContent.getContent(
            CarPageContentCode.SIMULATION_ANALYSIS_MODELS_MODELS,
          )}
          isWhiteContext
        />
        <Box sx={{ mt: 2, display: "flex" }}>
          <CarSelectOptionField
            sx={{
              width: 300,
              ".MuiInputBase-input": {
                backgroundColor: "white",
              },
            }}
            label="Asset Class"
            options={assetClassOptions}
            value={assetClassId}
            onChange={(value) => setAssetClassId(value)}
          />
          <CarDownloadButton
            sx={{ ml: "auto", mb: 0.5 }}
            onClick={data.handleDownloadReports}
          >
            Download Reports
          </CarDownloadButton>
        </Box>
        <TablePlaceHolder
          sx={{ mx: 7, mt: 10 }}
          isLoading={data.isLoading}
          rows={8}
        >
          <Box sx={{ mt: 6.5, display: "flex", gap: 5.5 }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Typography
                  variant="subhSemiBold"
                  sx={{ fontSize: "19px", ml: 11 }}
                >
                  {assetClassItem?.name} -
                </Typography>
                <CarSelectOptionField
                  sx={{
                    width: 150,
                    ".MuiInputBase-input": {
                      backgroundColor: "white",
                    },
                  }}
                  options={data.regressionResultItems.map<
                    CarSelectOption<number>
                  >((i, idx) => ({
                    value: idx,
                    label: i.label,
                  }))}
                  value={regressionResultItemIndex}
                  onChange={setRegressionResultItemIndex}
                />
              </Box>
              <SimulationModelsChart
                data={currentRegressionResultItem?.data}
                isFixedIncome={assetClassItem?.isFixedIncome}
              />
            </Box>
            <Box
              sx={{
                mt: 1.75,
                display: "flex",
                flexDirection: "column",
                flex: "auto",
              }}
            >
              <Typography variant="subhSemiBold" sx={{ fontSize: "19px" }}>
                {`Regression Statistics - ${assetClassItem?.name}`}
              </Typography>
              <SimulationStatBox
                sx={{
                  mt: 1.5,
                }}
                items={data.regressionStat}
              />
              {currentRegressionResultItem && (
                <>
                  <Typography
                    variant="subhSemiBold"
                    sx={{ mt: 4, fontSize: "19px" }}
                  >
                    {`Regression Statistics - ${currentRegressionResultItem.label}`}
                  </Typography>
                  <SimulationStatBox
                    sx={{
                      mt: 1.5,
                    }}
                    items={currentRegressionResultItem.statItems}
                  />
                </>
              )}
              <Legend />
            </Box>
          </Box>
          <CarFlatPageControl
            sx={{
              mt: 5,
              ".flat-page-control-header": {
                ml: 3,
                gap: 3,
              },
              ".flat-page-control-button": {
                p: 1,
                fontSize: "18px",
              },
            }}
            items={data.tables.map((i, idx) => ({
              label: i.name,
              value: idx,
              content: <DataTable table={i} />,
            }))}
            value={currentTab}
            onChange={setCurrentTab}
          />
        </TablePlaceHolder>
      </TablePlaceHolder>
    );
  },
);
