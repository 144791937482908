import { Box } from "@mui/material";
import { RootPage } from "./components/RootPage";
import { CarVirtualTable } from "components/VirtualTable";

export const Debug = () => {
  return (
    <RootPage>
      <Box sx={{ p: 3 }}>
        <CarVirtualTable
          sx={{
            width: "100%",
            height: "calc(100vh - 200px)",
          }}
          columnCount={1000}
          rowCount={1000}
          onRenderCell={(col, row) => `${col} ${row}`}
          onRenderColumnHeader={(col) => `Header ${col}`}
          onRenderRowHeader={(row) => `Row ${row}`}
          onRenderColumnRowHeader={() => "Test"}
        />
      </Box>
    </RootPage>
  );
};
