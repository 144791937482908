import { useState } from "react";
import { api } from "api/carApi.generated";
import { usePlanId } from "app/usePlanId";

export const useRaymondJamesSearch = () => {
  const planId = usePlanId();
  const [searchAccounts, { isFetching: isAccountsLoading, data: accounts }] =
    api.useLazyDataProvidersSearchRaymondAccountsListQuery();
  const [
    searchPortfolios,
    { isFetching: isPortfoliosLoading, data: portfolios },
  ] = api.useLazyDataProvidersSearchRaymondHouseholdsListQuery();

  const [
    searchPortfolioAccounts,
    { isFetching: isPortfolioAccountsLoading, data: portfolioAccounts },
  ] = api.useLazyDataProvidersSearchRaymondAccountsByHouseholdListQuery();

  const [showPortfolios, setShowPortfolios] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState<
    string | undefined
  >();

  const [linkRel, { isLoading: linkIsLoading }] =
    api.useDataProvidersPlanAccountsLinkRayJayExternalCreateMutation();

  const handleLink = (accountIds: string[]) => {
    linkRel({
      planId,
      linkExternalAccounts: {
        integrator: "RaymondJames",
        account_ids: accountIds,
      },
    });
  };

  const handleSearch = (searchCriterion: string, accounts: boolean) => {
    setSelectedPortfolioId(undefined);
    if (accounts) {
      searchAccounts({ searchCriterion });
      setShowPortfolios(false);
      setShowAccounts(true);
    } else {
      searchPortfolios({ searchCriterion });
      setShowPortfolios(true);
      setShowAccounts(false);
    }
  };

  const handleSelect = () => {
    if (showPortfolios) {
      searchPortfolioAccounts({
        externalHouseholdId: selectedPortfolioId ?? "",
      });
      setShowPortfolios(false);
      setShowAccounts(true);
    }
  };

  const handleBack = () => {
    setShowAccounts(false);
    setShowPortfolios(true);
    // setSelectedPortfolio(undefined);
  };

  return {
    showAccounts,
    accounts:
      (selectedPortfolioId
        ? isPortfolioAccountsLoading
          ? []
          : portfolioAccounts
        : accounts) ?? [],
    showPortfolios,
    portfolios: portfolios ?? [],
    isAccountsLoading,
    isPortfoliosLoading,
    isPortfolioAccountsLoading,
    isSelecting: isPortfolioAccountsLoading,
    isSearching: isAccountsLoading || isPortfoliosLoading,
    selectedPortfolioId,
    handleSelectedPortfolioIdChange: setSelectedPortfolioId,
    handleSelect,
    handleSearch,
    handleBack,
    linkIsLoading,
    handleLink,
  };
};
