import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CarGoal,
  CarSocialSecurity,
  CarRelationship,
  CarClientPlan,
  CarSavings,
  CarAccount,
  CarDeRiskDetails,
} from "types";

export type CpRelationship = Pick<
  CarRelationship,
  "id" | "retirement_age" | "live_until"
>;

export type CpSocialSecurity = Pick<
  CarSocialSecurity,
  "id" | "start_age_of_benefit"
>;

export type CpGoal = Pick<
  CarGoal,
  | "id"
  | "position"
  | "amount"
  | "enabled"
  | "start_date_caravel"
  | "end_date_caravel"
  | "priority"
  | "frequency"
>;

export type CpClientPlan = Pick<
  CarClientPlan,
  "id" | "needs_percentile" | "wants_percentile" | "dreams_percentile"
>;

export type CpSavings = Pick<
  CarSavings,
  "id" | "base_contribution" | "start_date_caravel" | "end_date_caravel"
>;
export type CpAccount = Pick<CarAccount, "id"> & {
  savings: Array<CpSavings>;
};

export type CpDeRiskDetails = Pick<CarDeRiskDetails, "id" | "portfolio_module">;

const initialState = {
  relationship: [] as Array<CpRelationship>,
  socialSecurity: [] as Array<CpSocialSecurity>,
  goals: [] as Array<CpGoal>,
  accounts: [] as Array<CpAccount>,
  derisk: undefined as CpDeRiskDetails | undefined,
  clientPlan: undefined as CpClientPlan | undefined,
};
export const controlPanelSlice = createSlice({
  name: "controlPanel",
  initialState,
  reducers: {
    setRelationship: (state, { payload }: PayloadAction<CpRelationship>) => {
      state.relationship = state.relationship.filter(
        (i) => i.id !== payload.id
      );
      state.relationship.push(payload);
    },
    removeRelationship: (
      state,
      { payload }: PayloadAction<CpRelationship["id"] | undefined>
    ) => {
      state.relationship = state.relationship.filter((i) => i.id !== payload);
    },

    setSocialSecurity: (
      state,
      { payload }: PayloadAction<CpSocialSecurity>
    ) => {
      state.socialSecurity = state.socialSecurity.filter(
        (i) => i.id !== payload.id
      );
      state.socialSecurity.push(payload);
    },
    removeSocialSecurity: (
      state,
      { payload }: PayloadAction<CpSocialSecurity["id"] | undefined>
    ) => {
      state.socialSecurity = state.socialSecurity.filter(
        (i) => i.id !== payload
      );
    },

    setGoal: (state, { payload }: PayloadAction<CpGoal>) => {
      state.goals = state.goals.filter((i) => i.id !== payload.id);
      state.goals.push(payload);
    },
    removeGoal: (state, { payload }: PayloadAction<CpGoal["id"]>) => {
      state.goals = state.goals.filter((i) => i.id !== payload);
    },

    setAccount: (state, { payload }: PayloadAction<CpAccount>) => {
      state.accounts = state.accounts.filter((i) => i.id !== payload.id);
      state.accounts.push(payload);
    },
    removeAccount: (
      state,
      { payload }: PayloadAction<CpAccount["id"] | undefined>
    ) => {
      state.accounts = state.accounts.filter((i) => i.id !== payload);
    },

    setClientPlan: (
      state,
      { payload }: PayloadAction<CpClientPlan | undefined>
    ) => {
      state.clientPlan = payload
        ? { ...state.clientPlan, ...payload }
        : undefined;
    },

    setDerisk: (
      state,
      { payload }: PayloadAction<CpDeRiskDetails | undefined>
    ) => {
      state.derisk = payload ? { ...state.derisk, ...payload } : undefined;
    },

    reset: (state, { payload }: PayloadAction<undefined>) => {
      return initialState;
    },
  },
});

export const controlPanelSliceReducer = controlPanelSlice.reducer;
export const controlPanelActions = controlPanelSlice.actions;
