import legend from "assets/pdf/barometer-legend.png";
import { formatCurrency, formatDisplayTypeValue, isDefined } from "utils";
import {
  PlanResultsRiskTableRow,
  UsePlanResults,
} from "../planResults/usePlanResults";
import { CarPdfRiskBarometer } from "./PdfRiskBarometer";
import { useLocalStorage } from "../general/useLocalStorage";
import { DisplayTypeEnum2 } from "api/carApi.generated";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfMarkdown } from "components/PdfMarkdown";
import { CarPdfSubHeading } from "./PdfHeading";
import { CarPdfImage } from "components/PdfImage";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfTypography } from "components/PdfTypography";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfHeaderProps,
  CarPdfRow,
  CarPdfRowProps,
  CarPdfTable,
  CarPdfTextCell,
} from "components/PdfTable";

interface HeaderProps extends Omit<CarPdfHeaderProps, "children"> {
  selectedPlanLabel: string;
}

const Header = ({ selectedPlanLabel, ...props }: HeaderProps) => (
  <CarPdfHeader paddingVertical={14} {...props}>
    <CarPdfHeaderCell />
    <CarPdfHeaderCell>Existing Portfolio</CarPdfHeaderCell>
    <CarPdfHeaderCell>Proposed Portfolio</CarPdfHeaderCell>
    <CarPdfHeaderCell>{selectedPlanLabel}</CarPdfHeaderCell>
  </CarPdfHeader>
);

const formatReportRowValue = (
  value: number | undefined,
  displayType: DisplayTypeEnum2,
  convertToDollarFromTotal: number | undefined,
) =>
  displayType === "PERCENTAGE" && isDefined(convertToDollarFromTotal)
    ? formatCurrency(((value ?? 0) / 100) * convertToDollarFromTotal) // TODO Yevhen - check if we still need this and how it works
    : formatDisplayTypeValue(displayType)(value);

interface RowProps extends Omit<CarPdfRowProps, "children"> {
  item: PlanResultsRiskTableRow;
  convertToDollarFromTotal?: number;
}

const Row = ({ item, convertToDollarFromTotal, ...props }: RowProps) => (
  <CarPdfRow paddingVertical={10} {...props}>
    <CarPdfTextCell
      style={{
        textAlign: "left",
        paddingHorizontal: 5,
      }}
    >
      {item.label}
    </CarPdfTextCell>
    <CarPdfTextCell>
      {formatReportRowValue(
        item.existingPortfolio,
        item.displayType,
        convertToDollarFromTotal,
      )}
    </CarPdfTextCell>
    <CarPdfTextCell>
      {formatReportRowValue(
        item.proposedPortfolio,
        item.displayType,
        convertToDollarFromTotal,
      )}
    </CarPdfTextCell>
    <CarPdfTextCell>
      {formatReportRowValue(
        item.selectedPortfolio,
        item.displayType,
        convertToDollarFromTotal,
      )}
    </CarPdfTextCell>
  </CarPdfRow>
);

export interface RiskPdfPageProps {
  pageProps: CarPdfPageProps;
  planResults: UsePlanResults;
  disclosures: UseDisclosures;
}

export const RiskPdfPage = (props: RiskPdfPageProps) => {
  const [allocationInPercent] = useLocalStorage<boolean>(
    "RiskControlPanel-allocationInPercent",
    true,
  );

  const text1 = props.disclosures.getContent(
    CarDisclosureContentCode["CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_10"],
  );
  const text2 = props.disclosures.getContent(
    CarDisclosureContentCode["CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_20"],
  );
  const text3 = props.disclosures.getContent(
    CarDisclosureContentCode["CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_30"],
  );
  const text4 = props.disclosures.getContent(
    CarDisclosureContentCode["CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_40"],
  );

  return (
    <CarPdfPage {...props.pageProps}>
      <CarPdfSubHeading label="Risk" />
      <CarPdfMarkdown style={{ marginTop: 10 }}>{text1}</CarPdfMarkdown>
      <CarPdfBox
        style={{
          marginTop: 55,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CarPdfRiskBarometer
          style={{
            height: 250,
            width: 250,
            // marginLeft: -30,
          }}
          planResultsRiskData={props.planResults.riskData}
        />
        <CarPdfBox
          style={{
            marginLeft: 60,
          }}
        >
          <CarPdfImage
            style={{
              marginTop: 25,
              height: 312 / 2,
            }}
            src={legend}
          />
        </CarPdfBox>
      </CarPdfBox>
      <CarPdfMarkdown break>{text2}</CarPdfMarkdown>
      <CarPdfTypography
        variant="h6"
        style={{
          marginTop: 25,
        }}
      >
        Simulation Based Risk Report
      </CarPdfTypography>
      <CarPdfMarkdown style={{ marginTop: 6, marginBottom: 10 }}>
        {text3}
      </CarPdfMarkdown>
      <CarPdfTable colWidths={[1.8, 1, 1, 1]}>
        <Header
          selectedPlanLabel={props.planResults.risk.selectedPlanLabel ?? ""}
        />
        {props.planResults.risk.simulationBasedRiskReport.map((i, idx) => (
          <Row
            key={idx}
            item={i}
            convertToDollarFromTotal={
              allocationInPercent
                ? undefined
                : props.planResults.beginningTotalPortfolio
            }
          />
        ))}
      </CarPdfTable>
      <CarPdfTypography
        variant="h6"
        style={{
          marginTop: 35,
        }}
      >
        Historical Based Risk Report
      </CarPdfTypography>
      <CarPdfMarkdown style={{ marginTop: 6, marginBottom: 10 }}>
        {text4}
      </CarPdfMarkdown>
      <CarPdfTable colWidths={[1.8, 1, 1, 1]}>
        <Header
          selectedPlanLabel={props.planResults.risk.selectedPlanLabel ?? ""}
        />
        {props.planResults.risk.historicalRiskReport.map((i, idx) => (
          <Row
            key={idx}
            item={i}
            convertToDollarFromTotal={
              allocationInPercent
                ? undefined
                : props.planResults.beginningTotalPortfolio
            }
          />
        ))}
      </CarPdfTable>
    </CarPdfPage>
  );
};
