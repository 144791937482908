import {
  SimulationPercentileYearsData,
  useSimulationQaReportReturnsNominalCreateQuery,
  useSimulationQaReportReturnsRealCreateQuery,
} from "api/carApi.generated";
import { useDialog } from "app/useDialog";
import {
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";
import { useLocalStorage } from "features/general/useLocalStorage";
import { useMemo } from "react";
import { formatDateTime, formatNumber, isDefined } from "utils";
import { SimulationDataExplorerDialog } from "./SimulationDataExplorerDialog";
import { useSimulationAssetClassSelector } from "./useSimulationAssetClassSelector";

export interface CarSimulationABTestPercentileColumn {
  year: number;
  isExtended: boolean;
}

export interface CarSimulationABTestPercentileJobValue {
  sim: number | null;
  his: number | null;
  simLowerBound: number | null;
  simUpperBound: number | null;
}

export interface CarSimulationABTestPercentileValue {
  percentile: number;
  isExtended: boolean;
  year: number;
  valueA: CarSimulationABTestPercentileJobValue;
  valueB: CarSimulationABTestPercentileJobValue;
  diff: number | null;
}

export interface CarSimulationABTestPercentileRow {
  percentile: number;
  values: CarSimulationABTestPercentileValue[];
}

export interface CarSimulationABTestPercentileTable {
  simulationJobIdA: string;
  simulationJobIdB: string;
  assetClassCode?: string;
  columns: CarSimulationABTestPercentileColumn[];
  rows: CarSimulationABTestPercentileRow[];
}

export const simPercentileToTable = ({
  simulationJobIdA,
  simulationJobIdB,
  assetClassCode,
  valueA,
  valueB,
}: {
  simulationJobIdA: string;
  simulationJobIdB: string;
  assetClassCode?: string;
  valueA?: SimulationPercentileYearsData;
  valueB?: SimulationPercentileYearsData;
}): CarSimulationABTestPercentileTable => {
  const columns: CarSimulationABTestPercentileColumn[] = [
    ...(valueA?.years.map((year) => ({
      year,
      isExtended: false,
    })) ?? []),
    ...(valueA?.years_extended.map((year) => ({
      year,
      isExtended: true,
    })) ?? []),
  ];
  const table: CarSimulationABTestPercentileTable = {
    simulationJobIdA,
    simulationJobIdB,
    assetClassCode,
    columns,
    rows: Object.keys(
      valueA?.results ?? {},
    ).map<CarSimulationABTestPercentileRow>((key) => ({
      percentile: Number.parseFloat(key),
      values: columns.map<CarSimulationABTestPercentileValue>((col) => {
        const valA = col.isExtended
          ? valueA?.results_extended[key]?.[col.year.toString()]
          : valueA?.results[key]?.[col.year.toString()];

        const valB = col.isExtended
          ? valueB?.results_extended[key]?.[col.year.toString()]
          : valueB?.results[key]?.[col.year.toString()];

        const valASim = valA?.sim;
        const valBSim = valB?.sim;

        return {
          percentile: Number.parseFloat(key),
          isExtended: col.isExtended,
          year: col.year,
          valueA: {
            sim: valA?.sim ?? null,
            his: valA?.his ?? null,
            simLowerBound: valA?.sim_lower_bound ?? null,
            simUpperBound: valA?.sim_upper_bound ?? null,
          },
          valueB: {
            sim: valB?.sim ?? null,
            his: valB?.his ?? null,
            simLowerBound: valB?.sim_lower_bound ?? null,
            simUpperBound: valB?.sim_upper_bound ?? null,
          },
          diff:
            isDefined(valASim) && isDefined(valBSim) ? valASim - valBSim : null,
        };
      }),
    })),
  };

  return table;
};

export const getSimulationPercentileExcelTable = (params: {
  name: string;
  table: CarSimulationABTestPercentileTable;
}): ExcelTable => {
  return {
    name: params.name,
    bands: [
      { label: "" },
      ...params.table.columns.flatMap((i) => [
        {
          label: i.year.toString(),
          span: 3,
        },
        {
          label: "",
        },
        {
          label: "",
        },
      ]),
    ],
    columns: [
      {
        label: "",
        format: ExcelDataFormat.percent,
      },
      ...params.table.columns.flatMap((i) => [
        {
          label: "Sim A",
          format: ExcelDataFormat.percent,
        },
        {
          label: "Sim B",
          format: ExcelDataFormat.percent,
        },
        {
          label: "Diff",
          format: ExcelDataFormat.percent,
        },
      ]),
    ],
    rows: params.table.rows.map((row) => [
      (row.percentile ?? 0) / 100,
      ...row.values.flatMap((val) => [
        val.valueA.sim,
        val.valueB.sim,
        val.diff,
      ]),
    ]),
  };
};

export interface CarSimulationStatItem {
  label: string;
  value?: number | null;
  format?: ExcelDataFormat;
}

export const useSimulationABTestReturns = (params: {
  simulationJobIdA: string;
  simulationJobIdB: string;
}) => {
  const assetClassSelector = useSimulationAssetClassSelector({
    storageKey: "useSimulationABTestReturns_assetClassSelector",
    simulationJobId: params.simulationJobIdA,
  });
  const dialog = useDialog();

  const [isReal, setIsReal] = useLocalStorage<boolean>(
    "useSimulationABTestReturns_isReal",
    true,
  );

  const assetClassCode = assetClassSelector.value ?? "";

  const returnsRealA = useSimulationQaReportReturnsRealCreateQuery(
    {
      simulationQaSummaryReportRequestAssetClass: {
        simulation_job_id: params.simulationJobIdA,
        asset_class_code: assetClassCode,
      },
    },
    {
      skip: !isReal || !assetClassCode,
    },
  );

  const returnsNominalA = useSimulationQaReportReturnsNominalCreateQuery(
    {
      simulationQaSummaryReportRequestAssetClass: {
        simulation_job_id: params.simulationJobIdA,
        asset_class_code: assetClassCode,
      },
    },
    {
      skip: isReal || !assetClassCode,
    },
  );

  const returnsRealB = useSimulationQaReportReturnsRealCreateQuery(
    {
      simulationQaSummaryReportRequestAssetClass: {
        simulation_job_id: params.simulationJobIdB,
        asset_class_code: assetClassCode,
      },
    },
    {
      skip: !isReal || !assetClassCode,
    },
  );

  const returnsNominalB = useSimulationQaReportReturnsNominalCreateQuery(
    {
      simulationQaSummaryReportRequestAssetClass: {
        simulation_job_id: params.simulationJobIdB,
        asset_class_code: assetClassCode,
      },
    },
    {
      skip: isReal || !assetClassCode,
    },
  );

  const excelExport = useExcelExport();

  return useMemo(() => {
    const realDataA = returnsRealA.data;
    const nominalDataA = returnsNominalA.data;

    const realDataB = returnsRealB.data;
    const nominalDataB = returnsNominalB.data;

    const table = simPercentileToTable({
      simulationJobIdA: params.simulationJobIdA,
      simulationJobIdB: params.simulationJobIdB,
      assetClassCode,
      valueA: isReal
        ? realDataA?.asset_classes.at(0)?.sim_percentile
        : nominalDataA?.asset_classes.at(0)?.sim_percentile,
      valueB: isReal
        ? realDataB?.asset_classes.at(0)?.sim_percentile
        : nominalDataB?.asset_classes.at(0)?.sim_percentile,
    });

    const inflationTable = isReal
      ? simPercentileToTable({
          simulationJobIdA: params.simulationJobIdA,
          simulationJobIdB: params.simulationJobIdB,
          valueA: realDataA?.cpi.sim_percentile,
          valueB: realDataB?.cpi.sim_percentile,
        })
      : undefined;

    const assetClassName =
      assetClassSelector.items.find((i) => i.code === assetClassCode)?.name ??
      "";

    const handleDownloadReports = () => {
      const dataA = isReal ? realDataA : nominalDataA;
      const dataB = isReal ? realDataB : nominalDataB;

      excelExport.exportAllExcel({
        fileName: `Simulation AB Test Returns (${
          isReal ? "Real" : "Nominal"
        }) - ${dataA?.simulation_name} - ${formatDateTime(
          dataA?.simulation_date,
        )
          .replaceAll("/", "-")
          .replaceAll(":", "-")} - ${dataB?.simulation_name} - ${formatDateTime(
          dataB?.simulation_date,
        )
          .replaceAll("/", "-")
          .replaceAll(":", "-")} - ${assetClassName}`,
        tables: [
          getSimulationPercentileExcelTable({
            name: assetClassName,
            table,
          }),
          inflationTable
            ? getSimulationPercentileExcelTable({
                name: `Inflation`,
                table: inflationTable,
              })
            : undefined,
        ].filter(isDefined),
      });
    };

    const handleValueClick = (
      value: CarSimulationABTestPercentileValue,
      isAValue: boolean,
    ) => {
      const assetClass = assetClassSelector.items.find(
        (i) => i.code === table.assetClassCode,
      );

      if (!assetClass) {
        return;
      }

      const filterDataType = assetClass.isEquity
        ? "tri_sim"
        : isReal
        ? "ret_annual_real"
        : "ret_annual_nominal";

      dialog(SimulationDataExplorerDialog, {
        title: assetClassName,
        subTitle: `${formatNumber(
          value.percentile,
        )}th percentile for Returns (${isReal ? "Real" : "Nominal"}) in Year ${
          value.year
        }`,
        simulationJobId: isAValue
          ? table.simulationJobIdA
          : table.simulationJobIdB,
        assetClassCode: table.assetClassCode,
        filterDataType,
        filterYear: value.year,
        filterValueGt:
          (isAValue
            ? value.valueA.simLowerBound
            : value.valueB.simLowerBound) ?? undefined,
        filterValueLt:
          (isAValue
            ? value.valueA.simUpperBound
            : value.valueB.simUpperBound) ?? undefined,
      });
    };

    return {
      isLoading:
        returnsRealA.isLoading ||
        returnsNominalA.isLoading ||
        returnsRealB.isLoading ||
        returnsNominalB.isLoading,
      isFetching:
        returnsRealA.isFetching ||
        returnsNominalA.isLoading ||
        returnsRealB.isFetching ||
        returnsNominalB.isLoading,
      isReal,
      setIsReal,
      table,
      inflationTable,
      assetClassSelector,
      assetClassName,
      handleDownloadReports,
      handleValueClick,
    };
  }, [
    returnsRealA.data,
    returnsRealA.isLoading,
    returnsRealA.isFetching,
    returnsNominalA.data,
    returnsNominalA.isLoading,
    returnsRealB.data,
    returnsRealB.isLoading,
    returnsRealB.isFetching,
    returnsNominalB.data,
    returnsNominalB.isLoading,
    params.simulationJobIdA,
    params.simulationJobIdB,
    assetClassCode,
    isReal,
    assetClassSelector,
    setIsReal,
    excelExport,
    dialog,
  ]);
};
