import { TablePlaceHolder } from "components/PlaceHolder";
import { CarRadioYesNo } from "components/Radio";
import { ReactElement, useState } from "react";
import { Box, Typography } from "@mui/material";
import { CarLoadingButton } from "components/Buttons";
import { usePortfolioGroupNewOptimizer } from "./usePortfolioGroupNewOptimizer";
import { PortfolioGroupConstrAcTable } from "./PortfolioGroupConstrAcTable";
import { PortfolioGroupConstrTable } from "./PortfolioGroupConstrTable";
import { useDialog } from "app/useDialog";
import { RenameDialog } from "components/RenameDialog";
import { useScrollTo } from "app/useScrollToTop";
import { useErrorToast } from "app/useSuccessToast";
import { CarFactorPercentFieldDelayed } from "components/NumberField";
import { formatPercentFactor } from "utils";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

interface PortfolioGroupNewOptimizerProps {
  viewMode?: boolean;
  status?: ReactElement;
  onOptimizationRunFinished?: (optimizerRunId: string) => void;
}

export const PortfolioGroupNewOptimizer = (
  props: PortfolioGroupNewOptimizerProps,
) => {
  const data = usePortfolioGroupNewOptimizer();
  const scrollTo = useScrollTo();

  const [isLaunching, setIsLaunching] = useState(false);
  const dialog = useDialog();
  const errorToast = useErrorToast();

  const handleRun = async () => {
    if (!data.canLaunch) {
      errorToast({
        message: "Please fill all required fields",
      });
      return;
    }

    let title = "";
    await dialog(RenameDialog, {
      label: "Name Optimization",
      value: "",
      applyLabel: "Save",
      onRename: async (value) => {
        title = value;
        return true;
      },
    });

    if (!title) {
      return;
    }

    setIsLaunching(true);
    try {
      const result = await data.handleLaunch(title);
      if ("data" in result) {
        scrollTo("top");
        if (result.data.id) {
          props.onOptimizationRunFinished?.(result.data.id);
        }
      }
    } finally {
      setIsLaunching(false);
    }
  };

  return (
    <TablePlaceHolder isLoading={data.isLoading} rows={8}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", mb: 6.5 }}>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
                minHeight: 25,
              }}
            >
              <Typography variant="par02SemiBold">
                Minimum Allocation:
              </Typography>
              <CarTooltipBox
                className={
                  pendoClasses.editPortfolioOptimizationMinimumAllocation
                }
              />
              {props.viewMode ? (
                <Typography variant="par02SemiBold" sx={{ ml: 1 }}>
                  {formatPercentFactor(data.minAllocation ?? 0)}
                </Typography>
              ) : (
                <CarFactorPercentFieldDelayed
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "white",
                    },
                    maxWidth: 138,
                  }}
                  disabled={props.viewMode}
                  value={data.minAllocation}
                  onChange={data.handleMinAllocationChange}
                />
              )}
            </Box>
            <Box sx={{ mt: 5, display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="par02SemiBold">
                Objective Function:
              </Typography>
              <CarTooltipBox
                sx={{ mr: -1 }}
                className={
                  pendoClasses.editPortfolioOptimizationObjectiveFunction
                }
              />
              <CarRadioYesNo
                sx={{
                  ".MuiFormGroup-root": {
                    gap: 2,
                  },
                }}
                disabled={props.viewMode}
                value={data.isMaxReturn}
                row
                yesLabel="Max Return"
                noLabel="Max Relative Return"
                onChange={data.handleIsMaxReturnChange}
              />
            </Box>
            {/* <Box sx={{ mt: 5, display: "flex", alignItems: "center", gap: 2 }}>
              <Typography variant="par02SemiBold">CMA Model:</Typography>
              <CarRadioYesNo
                sx={{
                  ".MuiFormGroup-root": {
                    gap: 2,
                  },
                }}
                disabled={props.viewMode}
                value={data.isMaxReturn}
                row
                yesLabel="Static"
                noLabel="Price-Driven"
                onChange={data.handleIsMaxReturnChange}
              />
            </Box> */}
          </Box>
          {props.status}
        </Box>
        <Typography variant="h6SSemiBold" sx={{ mb: 1.5 }}>
          Constraints {data.simulationSuffix}
        </Typography>
        <PortfolioGroupConstrTable
          table={data.table}
          readOnly={props.viewMode}
          onConstrHorizonValueChange={data.handleConstrHorizonChange}
          onConstrValueChange={data.handleConstrChange}
        />

        <Typography
          sx={{ mt: 2, mb: 5, alignSelf: "flex-end", color: "black" }}
          variant="mediumItalic"
        >
          Constraints without a value are ignored.
        </Typography>
        <PortfolioGroupConstrAcTable
          table={data.table}
          readOnly={props.viewMode}
          onConstrValueChange={data.handleConstrChange}
          onConstrAcValueChange={data.handleAcConstrChange}
          storageKey="EditPortfolioGroupOptimizer"
        />
        <CarLoadingButton
          isLoading={isLaunching}
          sx={{ mt: 3, fontSize: "18px", alignSelf: "center" }}
          variant="contained"
          onClick={handleRun}
        >
          Run
        </CarLoadingButton>
      </Box>
    </TablePlaceHolder>
  );
};
