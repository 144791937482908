import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarStressTestData } from "types";
import { formatCurrency } from "utils";
import { CarPdfSubHeading } from "./PdfHeading";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTable,
  CarPdfTextCell,
} from "components/PdfTable";

export interface RealEstatePdfPageProps {
  pageProps: CarPdfPageProps;
  items: Array<CarStressTestData>;
}

export const RealEstatePdfPage = (props: RealEstatePdfPageProps) => (
  <CarPdfPage {...props.pageProps}>
    <CarPdfSubHeading label="Real Estate" />
    <CarPdfTable
      style={{
        marginTop: 15,
      }}
      colWidths={[0.7, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
    >
      <CarPdfHeader paddingVertical={14}>
        <CarPdfHeaderCell />
        <CarPdfHeaderCell>RE Value</CarPdfHeaderCell>
        <CarPdfHeaderCell>Cost Basis</CarPdfHeaderCell>
        <CarPdfHeaderCell>Annual Mortgage Balance</CarPdfHeaderCell>
        <CarPdfHeaderCell>Total Mortgage Payment with Escrow</CarPdfHeaderCell>
        <CarPdfHeaderCell>Rent</CarPdfHeaderCell>
        <CarPdfHeaderCell>Depreciation</CarPdfHeaderCell>
        <CarPdfHeaderCell>Operating Expenses</CarPdfHeaderCell>
        <CarPdfHeaderCell>Net After Tax Rental Income</CarPdfHeaderCell>
        <CarPdfHeaderCell>Cashflow Net of Debt Service</CarPdfHeaderCell>
      </CarPdfHeader>
      {props.items.map((i, idx) => (
        <CarPdfRow
          key={i.year}
          style={{
            backgroundColor: idx % 2 === 0 ? "white" : "#f3f6f8",
          }}
          textVariant="tableBody3"
          paddingVertical={10}
        >
          <CarPdfTextCell variant="tableBody4">
            {i.year.toString()}
          </CarPdfTextCell>
          <CarPdfTextCell>{formatCurrency(i.real_estate ?? 0)}</CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.real_estate_cost_basis ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>{formatCurrency(i.mortgage ?? 0)}</CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.real_estate_annual_total_payment ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.rental_payment ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.rental_depreciation ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.rental_expenses ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.aftertax_rental_income ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.real_estate_cashflow ?? 0)}
          </CarPdfTextCell>
        </CarPdfRow>
      ))}
    </CarPdfTable>
  </CarPdfPage>
);
