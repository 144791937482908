import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfHeading } from "./PdfHeading";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfMarkdown } from "components/PdfMarkdown";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfTypography } from "components/PdfTypography";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
  priorityNumToString,
} from "utils";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTable,
  CarPdfTextCell,
} from "components/PdfTable";

export interface PublishedPlanPdfPageProps {
  pageProps: CarPdfPageProps;
  disclosures: UseDisclosures;
  planResults: UsePlanResults;
  publishedPlan: string;
}

export const PublishedPlanPdfPage = ({
  planResults,
  pageProps,
  publishedPlan,
  disclosures,
}: PublishedPlanPdfPageProps) => {
  return (
    <CarPdfPage {...pageProps}>
      <CarPdfHeading
        label={`Published Plan - ${publishedPlan}`}
        style={{ marginBottom: 20 }}
      />
      <CarPdfMarkdown>
        {disclosures.getContent(
          CarDisclosureContentCode[
            "CMPAS_CLIENT_REPORT_SUMMARY:_AM_I_GOING_TO_BE_OK?_part_10"
          ],
        )}
      </CarPdfMarkdown>
      <CarPdfBox style={{ marginTop: 20, flexDirection: "row", gap: 35 }}>
        <CarPdfBox
          style={{
            height: 130,
            alignItems: "center",
            flexDirection: "row",
            gap: 57,
            flex: "none",
            flexBasis: 220,
          }}
        >
          <CarPdfBox>
            <CarPdfTypography
              variant="h1"
              style={{ fontWeight: "bold" }}
            >{`${formatNumber(planResults.donuts.score)}%`}</CarPdfTypography>
            <CarPdfTypography variant="h4">Plan Score</CarPdfTypography>
          </CarPdfBox>
          <CarPdfBox>
            <CarPdfTypography variant="h1" style={{ fontWeight: "bold" }}>
              {`${planResults.donuts.total.successCount}/${planResults.donuts.total.totalCount}`}
            </CarPdfTypography>
            <CarPdfTypography variant="h4">Pass/Total</CarPdfTypography>
          </CarPdfBox>
        </CarPdfBox>
        <CarPdfMarkdown style={{ width: 266 }}>
          {disclosures.getContent(
            CarDisclosureContentCode[
              "CMPAS_CLIENT_REPORT_SUMMARY:_AM_I_GOING_TO_BE_OK?_part_20"
            ],
          )}
        </CarPdfMarkdown>
      </CarPdfBox>
      <CarPdfTable
        style={{ marginTop: 20, marginBottom: 20 }}
        colWidths={[0.75, 0.6, 0.6, 1, 1, 1, 1, 0.5]}
      >
        <CarPdfHeader paddingVertical={10}>
          <CarPdfHeaderCell>Priority:</CarPdfHeaderCell>
          <CarPdfHeaderCell>Passed vs. Total</CarPdfHeaderCell>
          <CarPdfHeaderCell>Prob. of Success</CarPdfHeaderCell>
          <CarPdfHeaderCell>Fund Required</CarPdfHeaderCell>
          <CarPdfHeaderCell>Initial Balance</CarPdfHeaderCell>
          <CarPdfHeaderCell>Savings & Income</CarPdfHeaderCell>
          <CarPdfHeaderCell>Expected Return</CarPdfHeaderCell>
          <CarPdfHeaderCell>Shortfall</CarPdfHeaderCell>
        </CarPdfHeader>
        {planResults.donuts.summaryItems.map((i, idx) => (
          <CarPdfRow key={idx} paddingVertical={10}>
            <CarPdfTextCell variant="tableBody3">{`${priorityNumToString(
              i.priority,
            )}s`}</CarPdfTextCell>
            <CarPdfTextCell variant="tableBody3">{i.passFail}</CarPdfTextCell>
            <CarPdfTextCell variant="tableBody3">
              {formatPercent(i.probOfSuccess)}
            </CarPdfTextCell>
            <CarPdfTextCell variant="tableBody3">
              {formatCurrency(i.fundsRequired)}
            </CarPdfTextCell>
            <CarPdfTextCell variant="tableBody3">
              {formatCurrency(i.initialBalance)}
            </CarPdfTextCell>
            <CarPdfTextCell variant="tableBody3">
              {formatCurrency(i.savingsAndIncome)}
            </CarPdfTextCell>
            <CarPdfTextCell variant="tableBody3">
              {formatCurrency(i.expReturn)}
            </CarPdfTextCell>
            <CarPdfTextCell variant="tableBody3">
              {formatCurrency(i.shortfall)}
            </CarPdfTextCell>
          </CarPdfRow>
        ))}
      </CarPdfTable>
      <CarPdfMarkdown wrap={false}>
        {disclosures.getContent(
          CarDisclosureContentCode[
            "CMPAS_CLIENT_REPORT_SUMMARY:_AM_I_GOING_TO_BE_OK?_part_30"
          ],
        )}
      </CarPdfMarkdown>
    </CarPdfPage>
  );
};
