import { Typography, styled } from "@mui/material";
import { formatCurrency } from "utils";
import { TablePlaceHolder } from "components/PlaceHolder";
import { usePlanResults } from "features/planResults/usePlanResults";
import { RootPageBox } from "components/RootPage";

const StyledRoot = styled(RootPageBox)(({ theme }) => ({
  height: 160,
  display: "flex",
  flexDirection: "column",
  flex: "none",
  paddingTop: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  gap: theme.spacing(1),
}));

export const ClientSummaryTotalNetWorthPlaceholder = (props: {
  isLoading: boolean;
}) => {
  return (
    <StyledRoot>
      <TablePlaceHolder isLoading={props.isLoading} rows={2}>
        <Typography variant="h5SSemiBold">Total Net Worth</Typography>
        <Typography variant="h2Bold" color="primary">
          {`$-`}
        </Typography>
        <Typography
          variant="mediumItalic"
          color="softBlack"
          sx={{ alignSelf: "flex-end", fontSize: "11px" }}
        >
          No information at this time
        </Typography>
      </TablePlaceHolder>
    </StyledRoot>
  );
};

export const ClientSummaryTotalNetWorth = () => {
  const data = usePlanResults();

  return (
    <StyledRoot>
      <TablePlaceHolder isLoading={data.isLoading} rows={2}>
        <Typography variant="h5SSemiBold">Total Net Worth</Typography>
        <Typography variant="h2Bold" color="primary" sx={{ letterSpacing: 0 }}>
          {formatCurrency(data.totalNetWorth)}
        </Typography>
        <Typography
          variant="mediumItalic"
          color="gray6"
          sx={{ alignSelf: "flex-end", fontSize: "11px" }}
        >
          As of TODO
        </Typography>
      </TablePlaceHolder>
    </StyledRoot>
  );
};
