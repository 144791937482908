import ReactPDF, { Document } from "@react-pdf/renderer";
import { PropsWithChildren } from "react";
export interface CarPdfDocumentProps
  extends PropsWithChildren<
    Omit<ReactPDF.DocumentProps, "creator" | "producer">
  > {}

export const CarPdfDocument = ({ children, ...props }: CarPdfDocumentProps) => (
  <Document
    creator={`Caravel Concepts ${new Date().getFullYear()}`}
    producer="CMPAS"
    {...props}
  >
    {children}
  </Document>
);
