import { LoginPage } from "../pages/login/LoginPage";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "features/auth/useAuth";
import { MainPageLayout } from "./MainPageLayout";
import { HomePage } from "../pages/home/HomePage";
import { SettingsPage } from "../pages/settings/SettingsPage";
import { AssetAllocationPage } from "../pages/assetAllocation/AssetAllocationPage";
import { NotificationsPage } from "../pages/notifications/NotificationsPage";
import { rootPages } from "route.const";
import { isDebug } from "const";
import { Debug } from "../Debug";
import { AppDialogs } from "./AppDialogs";
import { AUTH_INFO_KEY } from "features/auth/authSlice";
import { Toast } from "./Toast";
import { FinancialPlanningReport } from "features/financialPlanningReport/FinancialPlanningReport";
import { ReactNode } from "react";
import { DebugIcons } from "../DebugIcons";
import { ActionReport } from "features/actionReport/ActionReport";
import { Box, CssBaseline, GlobalStyles, styled } from "@mui/material";
import { globalStyles } from "../theme/globalStyles";
import { FinancialPlanningPage } from "pages/financialPlanning/FinancialPlanningPage";
import { PaymentChecker, PaymentPage } from "pages/payment/PaymentPage";
import { AcceptInvitationPage } from "pages/acceptInvitation/AcceptInvitationPage";
import { PaymentCompletePage } from "pages/payment/PaymentCompletePage";
import { ResetPasswordPage } from "pages/resetPassword/ResetPasswordPage";
import { AssetAllocationSummaryReport } from "features/assetAllocationReport/AssetAllocationSummaryReport";
import { PortfolioGroupPublishPdfReportPage } from "pages/assetAllocation/portfolioGroupPublishPdfReport/PortfolioGroupPublishPdfReportPage";
import { PortfolioGroupModulePdfReportPage } from "pages/assetAllocation/portfolioGroupModulePdfReport/PortfolioGroupModulePdfReportPage";
import { PortfolioGroupAssetClassAnalysisPdfReportPage } from "pages/assetAllocation/portfolioGroupAssetClassAnalysisPdfReport/PortfolioGroupAssetClassAnalysisPdfReportPage";
import { AssetClassPerformanceRiskPdfReport } from "pages/assetAllocation/assetClassPerformanceRiskPdfReport/AssetClassPerformanceRiskPdfReport";
import { AssetClassSimulatedForwardProbabilitiesPdfReportPage } from "pages/assetAllocation/assetClassSimulatedForwardProbabilitiesPdfReport/AssetClassSimulatedForwardProbabilitiesPdfReportPage";
import { AssetClassDftPdfReportPage } from "pages/assetAllocation/assetClassDftPdfReport/AssetClassDftPdfReportPage";
import { PdfDebug } from "PdfDebug";
import { IntercomButton } from "components/IntercomButton";
import { AssetClassAnalysisPage } from "pages/assetClassAnalysis/AssetClassAnalysisPage";
import { PortfolioAnalysisPage } from "pages/portfolioAnalysis/PortfolioAnalysisPage";
import { SimulationPage } from "pages/simulation/SimulationPage";

const RequireAuth = (props: { children?: ReactNode }) => {
  const auth = useAuth();
  let location = useLocation();

  // Check if there is a query param named "auth" in the URL
  let [searchParams] = useSearchParams();
  const auth_string = searchParams.get("auth");
  if (auth_string) {
    searchParams.delete("auth");
    sessionStorage.setItem(AUTH_INFO_KEY, auth_string);
    localStorage.removeItem(AUTH_INFO_KEY);
    // TODO Reload the page
  }

  if (!auth.is_complete) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to={"/" + rootPages.login} state={{ from: location }} replace />
    );
  }

  return <>{props.children}</>;
};

const AppRoot = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "stretch",
});

export const App = () => {
  return (
    <AppRoot>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
      <Routes>
        <Route path={rootPages.login} element={<LoginPage />} />
        <Route
          path={rootPages.startFreeTrial}
          element={<LoginPage showTrialPage />}
        />
        <Route
          path={rootPages.acceptInvitationPath}
          element={<AcceptInvitationPage />}
        />
        <Route
          path={rootPages.resetPasswordPath}
          element={<ResetPasswordPage />}
        />
        <Route
          path={rootPages.financialPlanningReport}
          element={
            <RequireAuth>
              <FinancialPlanningReport />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.actionReport}
          element={
            <RequireAuth>
              <ActionReport />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.assetAllocationSummaryReport}
          element={
            <RequireAuth>
              <AssetAllocationSummaryReport />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.portfolioGroupModulePdfReport}
          element={
            <RequireAuth>
              <PortfolioGroupModulePdfReportPage />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.portfolioGroupAssetClassAnalysisPdfReport}
          element={
            <RequireAuth>
              <PortfolioGroupAssetClassAnalysisPdfReportPage />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.portfolioGroupPublishPdfReport}
          element={
            <RequireAuth>
              <PortfolioGroupPublishPdfReportPage />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.assetClassPerformanceRiskPdfReport}
          element={
            <RequireAuth>
              <AssetClassPerformanceRiskPdfReport />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.assetClassSimulatedForwardProbabilitiesPdfReport}
          element={
            <RequireAuth>
              <AssetClassSimulatedForwardProbabilitiesPdfReportPage />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.assetClassDftPdfReport}
          element={
            <RequireAuth>
              <AssetClassDftPdfReportPage />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.payment}
          element={
            <RequireAuth>
              <PaymentPage />
            </RequireAuth>
          }
        />
        <Route
          path={rootPages.paymentComplete}
          element={
            <RequireAuth>
              <PaymentCompletePage />
            </RequireAuth>
          }
        />
        {isDebug && <Route path={rootPages.pdfDebug} element={<PdfDebug />} />}
        <Route
          path={rootPages.home}
          element={
            <RequireAuth>
              <MainPageLayout />
              <PaymentChecker />
              <IntercomButton />
            </RequireAuth>
          }
        >
          <Route index element={<HomePage />} />
          <Route path={rootPages.settingsPath} element={<SettingsPage />} />
          <Route
            path={rootPages.notificationsPath}
            element={<NotificationsPage />}
          />
          <Route path={rootPages.simulationPath} element={<SimulationPage />} />
          <Route
            path={rootPages.assetClassAnalysisPath}
            element={<AssetClassAnalysisPage />}
          />
          <Route
            path={rootPages.portfolioAnalysisPath}
            element={<PortfolioAnalysisPage />}
          />
          <Route
            path={rootPages.assetAllocationPath}
            element={<AssetAllocationPage />}
          />
          {isDebug && <Route path={rootPages.debug} element={<Debug />} />}
          {isDebug && (
            <Route path={rootPages.debugIcons} element={<DebugIcons />} />
          )}

          <Route
            path={rootPages.financialPlanningPath}
            element={<FinancialPlanningPage />}
          />

          <Route path="*" element={<Navigate to={rootPages.home} />} />
        </Route>
      </Routes>
      <AppDialogs />
      <Toast />
    </AppRoot>
  );
};
