import { Box } from "@mui/material";
import React from "react";
import {
  quickInputSectionPaddingX,
  quickInputSectionPaddingY,
} from "../QuickInputSection";
import { AssetsIntegration } from "./Search/AssetsIntegration";
import { useAccount } from "features/assets/useAccount";
import {
  ManualAccountsContent,
  ManualAccountsSection,
} from "./ManualAccountsSection";
import { getAccountsTotals } from "features/assets/account.utils";
import { IntegratorAccountsSection } from "./IntegratorAccountsSection";
import { CarTotalLabel } from "components/TotalLabel";
import { useQuestionnaireExternalListAvailableIntegratorsListQuery } from "api/carApi.generated";
import { TablePlaceHolder } from "components/PlaceHolder";

export const AssetsQuick = React.memo(() => {
  const integrators =
    useQuestionnaireExternalListAvailableIntegratorsListQuery();

  const data = useAccount();

  const isLoading = data.isLoading || integrators.isLoading;

  const hasIntegrator =
    data.integrationItems.length > 0 ||
    integrators.data?.some((i) => i.enabled);

  const integratorTotals = getAccountsTotals(data.integrationItems);
  const manualTotals = getAccountsTotals(data.manualItems);

  const allTotals = getAccountsTotals([
    ...data.integrationItems,
    ...data.manualItems,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <TablePlaceHolder isLoading={isLoading}>
        {hasIntegrator ? (
          <>
            <AssetsIntegration />
            <Box
              sx={{
                mx: -quickInputSectionPaddingX,
                px: quickInputSectionPaddingX,
                mb: -quickInputSectionPaddingY,
                bgcolor: "background.paper",
                display: "flex",
                flexDirection: "column",
                pt: 2.5,
                pb: 3.5,
              }}
            >
              <IntegratorAccountsSection />
              <ManualAccountsSection />
              <Box
                sx={{
                  mt: 4,
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  columnGap: 10,
                  rowGap: 1,
                }}
              >
                <CarTotalLabel
                  variant="h5SSemiBold"
                  label="Total Value of Manual Accounts"
                  value={manualTotals.marketValue}
                />
                <CarTotalLabel
                  variant="h5SSemiBold"
                  label="Total Annual Savings of Manual Accounts"
                  value={data.annualSavings.manual}
                />
                <CarTotalLabel
                  variant="h5SSemiBold"
                  label="Total Value of Integrator Accounts"
                  value={integratorTotals.marketValue}
                />
                <CarTotalLabel
                  variant="h5SSemiBold"
                  label="Total Annual Savings of Integrator Accounts"
                  value={data.annualSavings.integrated}
                />
                <CarTotalLabel
                  sx={{
                    pt: 1.5,
                    borderTop: "solid 1px",
                    borderColor: "softBlack",
                  }}
                  variant="h5Bold"
                  label="Total Value of All Accounts"
                  value={allTotals.marketValue}
                />
                <CarTotalLabel
                  sx={{
                    pt: 1.5,
                    borderTop: "solid 1px",
                    borderColor: "softBlack",
                  }}
                  variant="h5Bold"
                  label="Total Annual Savings of All Accounts"
                  value={data.annualSavings.total}
                />
              </Box>
            </Box>
          </>
        ) : (
          <>
            <ManualAccountsContent />
            <Box
              sx={{
                mt: 4,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: 10,
                rowGap: 1,
              }}
            >
              <CarTotalLabel
                variant="h5SBold"
                label="Total Value of Accounts"
                value={manualTotals.marketValue}
              />
              <CarTotalLabel
                variant="h5SBold"
                label="Total Annual Savings of Accounts"
                value={data.annualSavings.manual}
              />
            </Box>
          </>
        )}
      </TablePlaceHolder>
    </Box>
  );
});
