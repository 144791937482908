import { BoxProps } from "@mui/material";
import { CarChipGroup } from "components/Chip";
import { UseShowConfigSelector } from "./useShowConfigSelector";

export const ShowConfigSelector = ({
  showConfigSelector,
  sx,
}: {
  showConfigSelector: UseShowConfigSelector;
  sx?: BoxProps["sx"];
}) => {
  const jobs = [
    ...showConfigSelector.completedJobs,
    ...showConfigSelector.runningJobs,
  ];

  return (
    <CarChipGroup
      sx={sx}
      label="Portfolio Variations"
      items={[
        ...(showConfigSelector.canShowExisting
          ? [
              {
                label: "Existing",
                value: "existing",
                isChecked: showConfigSelector.config.existing,
              },
            ]
          : []),
        {
          label: "Benchmark",
          value: "benchmark",
          isChecked: showConfigSelector.config.benchmark,
        },
        {
          label: "Draft",
          value: "draft",
          isChecked: showConfigSelector.config.draft,
        },
        ...jobs.map((job) => ({
          label: job.title ?? "",
          value: job.id ?? "",
          isChecked:
            showConfigSelector.config.optimizationIds?.includes(job.id ?? "") ??
            false,
        })),
      ]}
      onClick={(value) => {
        if (
          value === "existing" ||
          value === "benchmark" ||
          value === "draft"
        ) {
          showConfigSelector.showHideConfigData(value);
        } else {
          showConfigSelector.showHideConfigOptData(value);
        }
      }}
    />
  );
};
