import { Box, Typography } from "@mui/material";
import { useCapitalGains } from "./useCapitalGains";
import { CarInfoTable, CarInfoHeader, CarInfoCell } from "components/InfoTable";
import { formatCurrency, formatPercent } from "utils";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CapitalGainsSummaryAssetAllocation } from "./CapitalGainsSummaryAssetAllocation";
import { CarDisclosure } from "components/Disclosure";
import { useState } from "react";
import { CarAssetClassLevelField } from "components/Inputs";
import { CarCapitalGainsAllocAsset } from "./calcCapitalGainsAllocAssets";
import { CapitalGainsCharts } from "./CapitalGainsCharts";
import { AssetClassLevel } from "const";
import { WorkbenchInflationSmall } from "../WorkbenchInflation";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarTooltipBox } from "components/TooltipBox";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

export const CapitalGains = () => {
  const data = useCapitalGains();
  const pageContent = usePageContent();

  const columnCount = data.capitalGainsScenario.columns.length;

  const [assetClassLevel, setAssetClassLevel] = useState<AssetClassLevel>(
    AssetClassLevel.level1,
  );

  let selectedLevelItems: Array<CarCapitalGainsAllocAsset> = [];
  switch (assetClassLevel) {
    case "level1":
      selectedLevelItems = data.summaryAssetAllocations.level1;
      break;
    case "level2":
      selectedLevelItems = data.summaryAssetAllocations.level2;
      break;
    case "level3":
      selectedLevelItems = data.summaryAssetAllocations.level3;
      break;
    case "level4":
      selectedLevelItems = data.summaryAssetAllocations.level4;
      break;
  }

  const tableHeader = (
    <>
      <CarInfoHeader text="" />
      {data.capitalGainsScenario.columns.map((i) => (
        <CarInfoHeader key={i.id} text={i.label} />
      ))}
    </>
  );

  return (
    <TablePlaceHolder
      isLoading={pageContent.isLoading || data.isLoading}
      rows={10}
    >
      <CarPageContent
        isWhiteContext
        content={pageContent.getContent(
          CarPageContentCode.PLAN_WORKBENCH_CAP_GAINS_DIRECTIONS,
        )}
      />
      <Box
        sx={{
          mt: 4,
          width: 1088,
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <CapitalGainsCharts data={data} />
        <WorkbenchInflationSmall sx={{ mt: 2, alignSelf: "flex-end" }} />
        <Typography sx={{ mt: 3, mb: 1 }} variant="h6SemiBold">
          Capital Gains Paid
        </Typography>
        <CarInfoTable
          sx={{
            gridTemplateColumns: `1.5fr repeat(${columnCount}, 1fr)`,
          }}
        >
          {tableHeader}
          <CarInfoCell
            text="Current Portfolio"
            justifyContent="start"
            isOdd
            pendoClass={pendoClasses.workbenchCapGainsCurrentPortfolio}
          />
          {data.capitalGainsScenario.currentPortfolio.map((i, idx) => (
            <CarInfoCell key={idx} text={formatCurrency(i)} isOdd />
          ))}

          <CarInfoCell
            text="Capital Gains Taxes"
            justifyContent="start"
            pendoClass={pendoClasses.workbenchCapGainsCapitalGainTaxes}
          />
          {data.capitalGainsScenario.capitalGainTaxes.map((i, idx) => (
            <CarInfoCell key={idx} text={formatCurrency(i)} />
          ))}

          <CarInfoCell
            text="After Tax Portfolio Balance"
            justifyContent="start"
            isOdd
            pendoClass={pendoClasses.workbenchCapGainsAfterTaxPortfolioBalance}
          />
          {data.capitalGainsScenario.afterTaxPortfolioBalance.map((i, idx) => (
            <CarInfoCell key={idx} text={formatCurrency(i)} isOdd />
          ))}
        </CarInfoTable>
        <Box sx={{ display: "flex", mt: 6.5, mb: 1 }}>
          <Typography variant="h6SemiBold">Plan Results</Typography>
          <CarTooltipBox
            sx={{ ml: 0.5, mt: 0.25 }}
            className={pendoClasses.workbenchCapGainsPlanResults}
          />
        </Box>
        <CarInfoTable
          sx={{
            gridTemplateColumns: `1.5fr repeat(${columnCount}, 1fr)`,
          }}
        >
          {tableHeader}
          <CarInfoCell text="Needs" justifyContent="start" isOdd />
          {data.planResults.needs.map((i, idx) => (
            <CarInfoCell key={idx} text={formatPercent(i)} isOdd />
          ))}

          <CarInfoCell text="Needs & Wants" justifyContent="start" />
          {data.planResults.needsAndWants.map((i, idx) => (
            <CarInfoCell key={idx} text={formatPercent(i)} />
          ))}

          <CarInfoCell
            text="Needs, Wants & Dreams"
            justifyContent="start"
            isOdd
          />
          {data.planResults.needsWantsAndDreams.map((i, idx) => (
            <CarInfoCell key={idx} text={formatPercent(i)} isOdd />
          ))}

          <CarInfoCell
            text="Surplus/Deficit"
            justifyContent="start"
            pendoClass={pendoClasses.workbenchCapGainsSurplusDeficit}
          />
          {data.planResults.surplusDeficit.map((i, idx) => (
            <CarInfoCell key={idx} text={formatCurrency(i)} />
          ))}
        </CarInfoTable>

        <Box sx={{ display: "flex", alignItems: "center", mt: 6.5, mb: 1 }}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6SemiBold">
              Summary Asset Allocation
            </Typography>
            <CarTooltipBox
              sx={{ ml: 0.5, mt: 0.25 }}
              className={pendoClasses.workbenchCapGainsSummaryAssetAllocation}
            />
          </Box>
          <CarTooltipBox
            sx={{ ml: "auto", mr: 0.5 }}
            className={pendoClasses.workbenchCapGainsAssetClassLevel}
          />
          <Typography sx={{ mr: 1 }} variant="par02Regular">
            Asset Class Level
          </Typography>
          <CarAssetClassLevelField
            sx={{ maxWidth: 200 }}
            fullWidth
            hideLevel4
            value={assetClassLevel}
            onChange={setAssetClassLevel}
          />
        </Box>
        <CapitalGainsSummaryAssetAllocation
          items={selectedLevelItems}
          header={
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `1.5fr repeat(${columnCount}, 1fr)`,
              }}
            >
              {tableHeader}
            </Box>
          }
        />
        <CarDisclosure sx={{ mt: 2 }}>
          Caravel does not provide tax advice, and actual capital gains tax
          obligations will vary from the approximations used in the MAPS
          analysis. You should consult a professional tax advisor before making
          any changes in your portfolio and should not rely on CMPAS
          calculations for tax purposes.
        </CarDisclosure>
      </Box>
    </TablePlaceHolder>
  );
};
