import { useState } from "react";
import {
  Box,
  BoxProps,
  IconButton,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import { CarNotification } from "./useNotifications";
import {
  addMinutes,
  addMonths,
  format,
  formatDistance,
  formatISO,
  isToday,
  isYesterday,
} from "date-fns";
import { CarIconCheckBold, CarIconRefresh } from "icons";
import { assetAllocationPages, financialPlanningPages } from "route.const";
import { CarMarkdown } from "components/Markdown";
import { CarButton } from "components/Buttons";
import { CarSelectField } from "components/Inputs";
import { isDebug } from "const";

const NotificationLink = (props: { url: string; label: string }) => (
  <Typography
    component="a"
    href={props.url}
    target="_blank"
    variant="par02SemiBold"
    sx={{ color: "caravelOrangePrimary", cursor: "pointer" }}
  >
    {props.label}
  </Typography>
);

interface SnoozeItem {
  label: string;
  value: number;
}

const snoozeItems: Array<SnoozeItem> = [
  { label: "1 month", value: 1 },
  { label: "3 months", value: 3 },
  { label: "6 months", value: 6 },
  { label: "12 months", value: 12 },
];

const StyledMarkdown = styled(CarMarkdown)(({ theme }) => ({
  p: {
    ...theme.typography.par02Regular,
  },
}));

interface NotificationCardProps {
  sx?: BoxProps["sx"];
  value: CarNotification;
  onClearClick: () => void;
  onSnooze: (value: string) => void;
}

export const NotificationCard = ({
  sx,
  value,
  onClearClick,
  onSnooze,
}: NotificationCardProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const [snooze, setSnooze] = useState(snoozeItems[0].value);

  const dateStr = isToday(value.date)
    ? formatDistance(value.date, new Date())
    : isYesterday(value.date)
    ? format(value.date, "p")
    : format(value.date, "PPPp");

  const Icon = value.isCleared ? CarIconRefresh : CarIconCheckBold;

  const handleSaveClick = () => {
    if (isDebug) {
      onSnooze(formatISO(addMinutes(new Date(), 1)));
    } else {
      onSnooze(formatISO(addMonths(new Date(), snooze)));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        ...sx,
      }}
    >
      <Box
        sx={{
          flex: "auto",
          borderRadius: "5px",
          border: `solid 1px`,
          borderColor: "gray3",
          overflow: "hidden",
          boxShadow: `0px 4px 4px 0px #55555540`,
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            borderBottom: "solid 1px",
            borderColor: "gray3",
            p: 2.5,
            pl: 3,
          }}
        >
          <Box
            sx={{
              width: 5,
              height: 25,
              backgroundColor: value.levelColor,
              mr: 1.5,
            }}
          ></Box>
          <Typography variant="h6Bold">{`${value.levelName}: ${value.label}`}</Typography>

          <Typography
            variant="par01SemiBold"
            sx={{
              ml: "auto",
              color: "gray7",
              fontSize: "12px",
              lineHeight: "14px",
            }}
          >
            {dateStr}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "gray2",
            display: "flex",
            flexDirection: "column",
            p: 2.5,
            gap: 2.5,
          }}
        >
          <StyledMarkdown>{value.description}</StyledMarkdown>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "240px auto",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
              }}
            >
              {value.clientId && (
                <NotificationLink
                  url={financialPlanningPages.getClientSummaryUrl(
                    value.clientId,
                  )}
                  label="Client Profile"
                />
              )}
              {value.clientId && value.planId && (
                <NotificationLink
                  url={
                    value.planResultId
                      ? financialPlanningPages.getWorkbenchUrl(
                          value.clientId,
                          value.planId,
                          value.planResultId,
                        )
                      : financialPlanningPages.getPlanInputUrl(
                          value.clientId,
                          value.planId,
                        )
                  }
                  label="View Plan"
                />
              )}
              {value.portfolioGroupId && (
                <NotificationLink
                  url={assetAllocationPages.getEditPortfolioGroupUrl({
                    portfolioGroupId: value.portfolioGroupId,
                    isPublished: false, // todo clarify
                  })}
                  label="View Asset Allocation"
                />
              )}
            </Box>
            {!value.isCleared && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
                <Typography variant="par02Regular">Snooze for:</Typography>
                <CarSelectField
                  sx={{
                    width: 114,
                    ".MuiSelect-select": {
                      pt: "9px",
                      pb: "5px",
                      backgroundColor: "white",
                    },
                  }}
                  value={snooze as any}
                  onChange={(value: any) => setSnooze(value)}
                >
                  {snoozeItems.map((i) => (
                    <MenuItem key={i.value} value={i.value}>
                      {i.label}
                    </MenuItem>
                  ))}
                </CarSelectField>
                <CarButton
                  sx={{ ml: 1, width: 104, height: 34 }}
                  onClick={handleSaveClick}
                >
                  Save
                </CarButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: 86, display: "flex", justifyContent: "flex-end" }}>
        {!value.isCleared && (
          <IconButton
            sx={{
              backgroundColor: "caravelOrangePrimary",
              boxShadow: "0px 4px 4px 0px #55555540",
              color: "white",
              "&:hover": {
                backgroundColor: "caravelOrangePrimary",
              },
            }}
            size="large"
            onClick={() => {
              setIsClicked(true);
              setTimeout(() => {
                setIsClicked(false);
                onClearClick();
              }, 100);
            }}
          >
            <Icon
              sx={
                isClicked
                  ? { width: 28, height: 28 }
                  : { width: 30, height: 30 }
              }
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
