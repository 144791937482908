import { Box, BoxProps, styled } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { Fragment, useState } from "react";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { formatNumber, isOddEven } from "utils";
import {
  SimulationBehaviorEquity,
  useAssetClassesSimulationBehaviorEquityListQuery,
  useAssetClassesSimulationBehaviorEquityUpdateMutation,
} from "api/carApi.generated";
import { CarNumberFieldDelayed } from "components/NumberField";
import { CarDateField } from "components/DateField";
import { CarFlatPageControl } from "components/FlatPageControl";

const StyledBox = styled(Box)(({ theme }) => ({
  border: "solid 1px",
  borderColor: theme.palette.gray6,
  borderRadius: "5px",
  backgroundColor: theme.palette.white,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(5.5),
}));

enum BoundaryTypes {
  TYPE_UPPER = 0,
  TYPE_LOWER = 1,
}

interface BoundaryItem {
  year: number;
  upperValue?: number;
  lowerValue?: number;
}

const BoundaryTable = (props: { items: BoundaryItem[] }) => {
  return (
    <StyledBox>
      <CarInfoTable sx={{ gridTemplateColumns: "0.5fr 1fr 1fr" }}>
        <CarInfoHeader text="" />
        <CarInfoHeader text="Lower Boundary" />
        <CarInfoHeader text="Upper Boundary" />
        {props.items.map((row, rowIdx) => (
          <Fragment key={rowIdx}>
            <CarInfoCell
              isOdd={isOddEven(rowIdx)}
              justifyContent="start"
              text={row.year.toString()}
            />
            <CarInfoCell
              isOdd={isOddEven(rowIdx)}
              text={
                row.lowerValue
                  ? formatNumber(row.lowerValue, { decimalPlaces: 6 })
                  : "-"
              }
            />
            <CarInfoCell
              isOdd={isOddEven(rowIdx)}
              text={
                row.upperValue
                  ? formatNumber(row.upperValue, { decimalPlaces: 6 })
                  : "-"
              }
            />
          </Fragment>
        ))}
      </CarInfoTable>
    </StyledBox>
  );
};

interface SimulationModelsBehaviorEquityProps {
  assetClassId: string;
  sx?: BoxProps["sx"];
}

export const SimulationModelsBehaviorEquity = (
  props: SimulationModelsBehaviorEquityProps,
) => {
  const [currentTab, setCurrentTab] = useState(0);

  const data = useAssetClassesSimulationBehaviorEquityListQuery({
    id: "",
  });

  const [updateMut] = useAssetClassesSimulationBehaviorEquityUpdateMutation();

  const item = data.data?.find(
    (be) => be.assetclasslevel4_set?.includes(props.assetClassId),
  );

  const updateItem = (value: SimulationBehaviorEquity) => {
    updateMut({
      id: value.id ?? "",
      simulationBehaviorEquity: value,
    });
  };

  const getBoundaryItems = (isManual: boolean): BoundaryItem[] => {
    const boundaries =
      item?.boundary_group?.boundaries?.filter((i) => i.manual === isManual) ??
      [];

    const years = Array.from(new Set(boundaries.map((i) => i.year))).sort(
      (a, b) => a - b,
    );

    return years.map<BoundaryItem>((year) => ({
      year,
      lowerValue: boundaries.find(
        (i) => i.boundary_type === BoundaryTypes.TYPE_LOWER && i.year === year,
      )?.value,
      upperValue: boundaries.find(
        (i) => i.boundary_type === BoundaryTypes.TYPE_UPPER && i.year === year,
      )?.value,
    }));
  };

  const manualItems = getBoundaryItems(true);
  const systemItems = getBoundaryItems(false);

  return (
    <Box sx={{ ...props.sx, display: "flex", flexDirection: "column" }}>
      <TablePlaceHolder isLoading={data.isLoading} rows={8}>
        {item && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "180px 180px",
              columnGap: 11,
              rowGap: 5,
              ".MuiInputBase-root": {
                backgroundColor: "white",
              },
            }}
          >
            <CarNumberFieldDelayed
              label="Trend Slope"
              decimalPlaces={10}
              value={item.trend_growth}
              onChange={(trend_growth) =>
                updateItem({ ...item, trend_growth: trend_growth ?? 0 })
              }
            />
            <CarNumberFieldDelayed
              label="Trend Intercept"
              decimalPlaces={10}
              value={item.trend_initial}
              onChange={(trend_initial) =>
                updateItem({
                  ...item,
                  trend_initial: trend_initial ?? 0,
                })
              }
            />
            <CarDateField
              sx={{
                ".MuiOutlinedInput-root": { backgroundColor: "white" },
                ".MuiButtonBase-root": {
                  marginRight: 0,
                },
              }}
              label="Trend Start Date"
              value={item.trend_start_date}
              onChange={(trend_start_date) =>
                updateItem({
                  ...item,
                  trend_start_date: trend_start_date ?? "",
                })
              }
            />
            <CarNumberFieldDelayed
              label="Model Intercept"
              decimalPlaces={10}
              value={item.intercept}
              onChange={(intercept) =>
                updateItem({ ...item, intercept: intercept ?? 0 })
              }
            />
            <CarNumberFieldDelayed
              label="Model Slope"
              decimalPlaces={10}
              value={item.slope}
              onChange={(slope) => updateItem({ ...item, slope: slope ?? 0 })}
            />
          </Box>
        )}
        {(manualItems.length || systemItems.length) && (
          <CarFlatPageControl
            sx={{
              mt: 5,
              ".flat-page-control-header": {
                ml: 3,
                gap: 3,
              },
              ".flat-page-control-button": {
                p: 1,
                fontSize: "18px",
              },
            }}
            items={[
              {
                label: "Boundary Group (Manual)",
                value: 0,
                content: <BoundaryTable items={manualItems} />,
              },
              {
                label: "Boundary Group (System Generated)",
                value: 1,
                content: <BoundaryTable items={systemItems} />,
              },
            ]}
            value={currentTab}
            onChange={setCurrentTab}
          />
        )}
      </TablePlaceHolder>
    </Box>
  );
};
