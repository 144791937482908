import { selectDialog } from "./dialogSlice";
import { useAppSelector } from "./hooks";

export const AppDialogs = () => {
  const { items: dialogItems } = useAppSelector(selectDialog);

  return (
    <>
      {dialogItems.map(({ id, comp: Comp, props }) => (
        <Comp key={id} {...props} />
      ))}
    </>
  );
};
