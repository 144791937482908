import {
  BoxProps,
  Dialog,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { CarClose } from "icons";
import { ReactNode, PropsWithChildren, ReactElement } from "react";

export type BaseDialogProps = PropsWithChildren<{
  title?: string | ReactElement;
  subtitle?: string | ReactElement;
  hideCloseButton?: boolean;
  onClose: () => void;
  actions?: ReactNode;
  sx?: BoxProps["sx"];
}>;

export const BaseDialog = (props: BaseDialogProps) => {
  return (
    <Dialog
      PaperProps={{
        sx: {
          py: 6,
          px: 7,
          position: "relative",
          borderRadius: "15px",
          ...props.sx,
        },
      }}
      maxWidth="sm"
      open={true}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          props.onClose();
        }
      }}
    >
      {!props.hideCloseButton && (
        <IconButton
          sx={{ position: "absolute", right: 8, top: 8, color: "gray6" }}
          onClick={props.onClose}
        >
          <CarClose sx={{ fontSize: "1.8rem" }} />
        </IconButton>
      )}
      {props.title && (
        <Typography variant="h4SemiBold">{props.title}</Typography>
      )}
      {props.subtitle && (
        <>
          <Divider sx={{ mt: 0.5, mb: 1 }} />
          <Typography variant="par01Regular" sx={{ mb: 2 }}>
            {props.subtitle}
          </Typography>
        </>
      )}
      {props.children}
      {props.actions && (
        <Box sx={{ mt: 2, display: "flex", gap: 2, justifyContent: "center" }}>
          {props.actions}
        </Box>
      )}
    </Dialog>
  );
};
