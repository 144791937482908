import { TablePlaceHolder } from "components/PlaceHolder";
import { memo } from "react";
import { useSimulatedForwardProbabilitiesAC } from "pages/assetClassAnalysis/useSimulatedForwardProbabilitiesAC";
import { ChartSimulatedForwardProbabilitiesAC } from "pages/assetClassAnalysis/ChartSimulatedForwardProbabilitiesAC";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";

interface SimulationABTestSimulatedForwardProbabilitiesProps {
  simulationJobId1: string;
  simulationJobId2: string;
}

export const SimulationABTestSimulatedForwardProbabilities = memo(
  (props: SimulationABTestSimulatedForwardProbabilitiesProps) => {
    const data = useSimulatedForwardProbabilitiesAC({
      simulationJobId1: props.simulationJobId1,
      simulationJobId2: props.simulationJobId2,
    });

    const pageContent = usePageContent();

    return (
      <TablePlaceHolder
        sx={{ mx: 7, mt: 5 }}
        isLoading={data.isLoading || pageContent.isLoading}
        rows={8}
      >
        <CarPageContent
          sx={{ mb: 3 }}
          content={pageContent.getContent(
            CarPageContentCode.SIMULATION_ANALYSIS_AB_TEST_SIMULATED_FORWARD_PROBABILITIES,
          )}
          isWhiteContext
        />
        <ChartSimulatedForwardProbabilitiesAC data={data} />
      </TablePlaceHolder>
    );
  },
);
