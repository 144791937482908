import { v4 } from "uuid";
import { DisplayTypeEnum2 } from "api/carApi.generated";
import { debugLog, isDefined, roundTo } from "utils";
import { RiskStatTypeCodeEnum, useRiskStatType } from "app/useRiskStatType";
import { useReturnScenarios } from "app/useReturnScenarios";
import { usePortfolioModuleData } from "./usePortfolioModuleData";
import { pendoClasses } from "app/thirdParty/pendo";
import { validatePercentile } from "app/thirdParty/sentry";
import {
  CarPortfolioAssetBand,
  CarPortfolioAssetColumnEnum,
  CarPortfolioAssetModule,
  CarPortfolioAssetValue,
} from "./usePortfolioGroupVersionData";

export interface CarPortfolioStatRow {
  id: string;
  groupId?: string;
  name: string;
  code: string;
  pendoClassName: string;
  displayType: DisplayTypeEnum2;
  modules: CarPortfolioAssetModule[];
  percentile?: number;
}

export interface CarPortfolioStatGroup {
  id: string;
  name: string;
  code: string;
  isHistorical: boolean;
  rows: CarPortfolioStatRow[];
}
export interface CarPortfolioAssetStatTableData {
  bands: CarPortfolioAssetBand[];
  historicalStatRows: CarPortfolioStatRow[];
  statGroups: CarPortfolioStatGroup[];
  simulationBasedStatRows: CarPortfolioStatRow[];
  simulationBasedStatRowsMaxFee: CarPortfolioStatRow[];
}

export const usePortfolioGroupVersionStatTableData = (params: {
  skip?: boolean;
  bands: CarPortfolioAssetBand[];
}) => {
  const riskStatType = useRiskStatType();
  const returnScenarios = useReturnScenarios();

  // fill portfolioModuleDataIds from all visible bands - columns
  const portfolioModuleDataIds = new Set<string>();
  params.bands.forEach((b) =>
    b.columns.forEach((c) => {
      if (c.dataId) {
        portfolioModuleDataIds.add(c.dataId);
      } else {
        debugLog(
          `No data for Module: ${b.label} Column: ${c.label} columnId=${c.columnId}`,
        );
      }
    }),
  );

  const portfolioModuleData = usePortfolioModuleData({
    portfolioModuleDataIds: Array.from(portfolioModuleDataIds),
    skip: params.skip,
  });

  const isLoading =
    riskStatType.isLoading ||
    returnScenarios.isLoading ||
    portfolioModuleData.isLoading;

  const isFetching =
    riskStatType.isFetching ||
    returnScenarios.isFetching ||
    portfolioModuleData.isFetching;

  const getTableData = (): CarPortfolioAssetStatTableData => {
    const expectedReturnTypeId = riskStatType.getRiskStatTypeIdByCode(
      RiskStatTypeCodeEnum.EXPECTED_RETURN,
    );

    const getSimulationBasedStatRows = (maxFee: boolean) =>
      riskStatType.assetAllocationItems.map<CarPortfolioStatRow>((i) => {
        let percentile: Array<number | null | undefined> = [];

        const row: CarPortfolioStatRow = {
          id: i.id ?? "",
          name: i.name ?? "",
          code: i.code ?? "",
          groupId: i.calculation_type?.id,
          pendoClassName: pendoClasses.simulationBasedRiskReport(i.code),
          displayType: i.display_type ?? "PERCENTAGE",
          modules: params.bands.map((bd) => ({
            isCash: bd.isCash,
            moduleId: bd.moduleId,
            selectedDataId: bd.selectedDataId,
            values: bd.columns.map<CarPortfolioAssetValue>((col) => {
              const data = portfolioModuleData.getRiskStatData({
                moduleDataId: col.dataId,
                riskStatTypeId: i.id,
                maxFee,
              });

              let value = data?.risk_stat_value;

              percentile.push(data?.risk_stat_percentile);

              if (i.is_frontend_derrived) {
                switch (i.code) {
                  case RiskStatTypeCodeEnum.EXPECTED_REL_PERF:
                    const currentExpReturn =
                      portfolioModuleData.getRiskStatData({
                        moduleDataId: col.dataId,
                        riskStatTypeId: expectedReturnTypeId,
                        maxFee,
                      })?.risk_stat_value;

                    const benchExpReturn = portfolioModuleData.getRiskStatData({
                      moduleDataId: bd.columns.find(
                        (c) =>
                          c.columnId === CarPortfolioAssetColumnEnum.benchmark,
                      )?.dataId,
                      riskStatTypeId: expectedReturnTypeId,
                      maxFee,
                    })?.risk_stat_value;

                    value =
                      isDefined(currentExpReturn) && isDefined(benchExpReturn)
                        ? roundTo(currentExpReturn, 3) -
                          roundTo(benchExpReturn, 3) // 0.025 = 2.5%
                        : undefined;
                    break;
                  default:
                    throw new Error(
                      `FE calculation is not implemented for ${i.code}`,
                    );
                }
              }

              return {
                columnId: col.columnId,
                id: v4(),
                dataId: col.dataId,
                moduleId: bd.moduleId,
                yNavKey: col.yNavKey,
                isEditable: false,
                allocation: value,
                horizonYear: data?.extra_data?.["horizon_year"],
                simulationJobId: portfolioModuleData.getSimulationJobId({
                  moduleDataId: col.dataId,
                }),
                portfolioGroupVersionId: col.portfolioGroupVersionId,
              };
            }),
          })),
        };

        row.percentile = validatePercentile(percentile.filter(isDefined))[0];
        return row;
      });

    const historicalStatRows = returnScenarios.items.map<CarPortfolioStatRow>(
      (i) => {
        const row: CarPortfolioStatRow = {
          id: i.id ?? "",
          name: i.name ?? "",
          code: i.code ?? "",
          pendoClassName: pendoClasses.historicalRiskReport(i.code),
          displayType: "PERCENTAGE",
          modules: params.bands.map((bd) => ({
            isCash: bd.isCash,
            moduleId: bd.moduleId,
            selectedDataId: bd.selectedDataId,
            values: bd.columns.map<CarPortfolioAssetValue>((col) => {
              return {
                columnId: col.columnId,
                id: v4(),
                dataId: col.dataId,
                moduleId: bd.moduleId,
                yNavKey: col.yNavKey,
                isEditable: false,
                allocation: portfolioModuleData.getHistoricStatData({
                  moduleDataId: col.dataId,
                  returnScenarioId: i.id,
                }),
                simulationJobId: portfolioModuleData.getSimulationJobId({
                  moduleDataId: col.dataId,
                }),
                portfolioGroupVersionId: col.portfolioGroupVersionId,
              };
            }),
          })),
        };
        return row;
      },
    );

    const simulationBasedStatRows = getSimulationBasedStatRows(false);

    const statGroups = riskStatType.calcTypes
      .map<CarPortfolioStatGroup>((ct) => ({
        id: ct.id ?? "",
        name: ct.name ?? "",
        code: ct.code ?? "",
        isHistorical: false,
        rows: simulationBasedStatRows.filter((r) => r.groupId === ct.id),
      }))
      .filter((i) => i.rows.length > 0);

    statGroups.push({
      id: "historical",
      name: "Historical Based Risk Report",
      code: "HISTORICAL",
      isHistorical: true,
      rows: historicalStatRows,
    });

    return {
      bands: params.bands,
      statGroups,
      simulationBasedStatRows,
      simulationBasedStatRowsMaxFee: getSimulationBasedStatRows(true).filter(
        (row) =>
          row.modules.some((mod) =>
            mod.values.some((val) => isDefined(val.allocation)),
          ),
      ),
      historicalStatRows,
    };
  };

  const statTableData: CarPortfolioAssetStatTableData = isLoading
    ? {
        bands: [],
        statGroups: [],
        simulationBasedStatRows: [],
        simulationBasedStatRowsMaxFee: [],
        historicalStatRows: [],
      }
    : getTableData();

  return {
    isLoading,
    isFetching,
    statTableData,
  };
};
