import React from "react";
import { Collapse, Typography, styled } from "@mui/material";
import {
  CarAccordion,
  CarAccordionDetails,
  CarAccordionSummary,
} from "components/Accordion";
import { useExpandSingle } from "features/general/useExpand";
import { useAccount } from "features/assets/useAccount";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarTableRoot } from "components/Table";
import { AddRowButton } from "components/Buttons";
import { ManualAccountRowProps } from "./manual-account-types";
import { ManualAccountRowSummarySimple } from "./ManualAccountRowSummarySimple";
import { ManualAccountRowSummaryAdvanced } from "./ManualAccountRowSummaryAdvanced";
import { ManualAccountRowDetailedHoldings } from "./ManualAccountRowDetailedHoldings";
import { CarAccount } from "types";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

const getRowComponent = (item: CarAccount): React.FC<ManualAccountRowProps> => {
  switch (item.account_ui_input_type) {
    case "manual_summary_simple":
      return ManualAccountRowSummarySimple;
    case "manual_summary_advanced":
      return ManualAccountRowSummaryAdvanced;
    case "manual_detailed_holdings":
      return ManualAccountRowDetailedHoldings;
    default:
      return ManualAccountRowSummarySimple;
  }
};

const StyledTableRoot = styled(CarTableRoot)(({ theme }) => ({
  ".account-row": {
    marginTop: theme.spacing(7.5),
  },
  ".account-row-collapse:first-of-type .account-row": {
    marginTop: 0,
  },
}));

export const ManualAccountsContent = React.memo(() => {
  const data = useAccount();
  return (
    <>
      <TablePlaceHolder isLoading={data.isLoading}>
        <StyledTableRoot>
          {data.manualItems.map((i, idx) => {
            const Comp = getRowComponent(i);
            return (
              <Collapse key={i.ui_key} className="account-row-collapse">
                <Comp
                  className="account-row"
                  item={i}
                  onChange={data.handleChange}
                  onDelete={data.handleDelete}
                  pendoPrefix="manual_accounts"
                />
              </Collapse>
            );
          })}
        </StyledTableRoot>
      </TablePlaceHolder>
      <AddRowButton
        sx={{ alignSelf: "flex-start", mt: 1.5 }}
        onClick={data.addRow}
      >
        Add Account
      </AddRowButton>
    </>
  );
});

export const ManualAccountsSection = React.memo(() => {
  const expand = useExpandSingle("ManualAccountsSection", false);
  return (
    <CarAccordion {...expand}>
      <CarAccordionSummary>
        <Typography variant="h5SSemiBold">
          Manual Accounts
          <CarTooltipBox
            sx={{ display: "inline", ml: 0.5, mt: 0.25 }}
            className={pendoClasses.planInputManualAccounts}
          />
        </Typography>
      </CarAccordionSummary>
      <CarAccordionDetails
        sx={{
          backgroundColor: "gray1",
          pl: 7,
          pr: 3,
          py: 6,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <ManualAccountsContent />
      </CarAccordionDetails>
    </CarAccordion>
  );
});
