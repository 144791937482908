import { Box } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo } from "react";
import { SimulationModelsBehaviorEquity } from "./SimulationModelsBehaviorEquity";
import { SimulationModelsBehaviorCash } from "./SimulationModelsBehaviorCash";
import { SimulationModelsBehaviorFixedIncomeSpread } from "./SimulationModelsBehaviorFixedIncomeSpread";
import { CarAssetClassSelector } from "components/AssetClassSelector";
import { SimulationModelsBehaviorInflation } from "./SimulationModelsBehaviorInflation";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { useSimulationAssetClassSelector } from "./useSimulationAssetClassSelector";

interface SimulationModelsBehaviorProps {}

export const SimulationModelsBehavior = memo(
  (props: SimulationModelsBehaviorProps) => {
    const assetClassSelector = useSimulationAssetClassSelector({
      includeInflationItem: true,
    });

    const pageContent = usePageContent();

    const acItem = assetClassSelector.selectedItem;

    return (
      <TablePlaceHolder
        sx={{ mt: 10 }}
        isLoading={assetClassSelector.isLoading || pageContent.isLoading}
        rows={8}
      >
        <CarPageContent
          sx={{ mb: 3 }}
          content={pageContent.getContent(
            CarPageContentCode.SIMULATION_ANALYSIS_MODELS_BEHAVIOR,
          )}
          isWhiteContext
        />
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 5 }}>
          <CarAssetClassSelector
            sx={{
              width: 300,
              ".MuiInputBase-input": {
                backgroundColor: "white",
              },
            }}
            label="Selection"
            {...assetClassSelector}
          />
          {acItem?.isFixedIncome && (
            <SimulationModelsBehaviorFixedIncomeSpread
              assetClassId={acItem.level4Id}
            />
          )}
          {acItem?.isEquity && (
            <SimulationModelsBehaviorEquity assetClassId={acItem.level4Id} />
          )}
          {acItem?.isCash && (
            <SimulationModelsBehaviorCash assetClassId={acItem.level4Id} />
          )}
          {acItem?.isInflation && <SimulationModelsBehaviorInflation />}
        </Box>
      </TablePlaceHolder>
    );
  },
);
