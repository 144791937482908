import {
  CmpasModule,
  useCommonCmpasModuleRetrieveQuery,
  useCommonCmpasModuleCreateMutation,
  useCommonCmpasModuleUpdateMutation,
} from "api/carApi.generated";
import { useForm } from "./useForm";
import { FormEditorDialog } from "./FormEditorDialog";

interface ModuleEditorDialogProps {
  id?: string;
  nextSortOrder?: number;
  onApply: () => void;
  onCancel: () => void;
}

export const ModuleEditorDialog = (props: ModuleEditorDialogProps) => {
  const query = useCommonCmpasModuleRetrieveQuery(
    {
      id: props.id ?? "",
    },
    { skip: !props.id },
  );

  const [create] = useCommonCmpasModuleCreateMutation();
  const [update] = useCommonCmpasModuleUpdateMutation();

  const form = useForm<CmpasModule>({
    entityLabel: "Module",
    getItemName: (item) => `${item.name} (${item.code})`,
    isLoading: query.isLoading,
    item: query.data,
    getNewItem: () => ({
      code: "",
      short_id: "",
      name: "",
      is_active: true,
      sort_order: props.nextSortOrder,
    }),
    onClose: props.onCancel,
    onCreate: (item) =>
      create({
        cmpasModule: item,
      }),

    onUpdate: (item) =>
      update({
        id: item.id ?? "",
        cmpasModule: item,
      }),
    onDelete: async (item) => {
      // todo
    },
    fieldDefs: [
      {
        key: "code",
        type: "string",
        label: "Code",
        description: "Unique code for the module",
        isRequired: true,
      },
      {
        key: "short_id",
        type: "string",
        label: "Short ID",
        description:
          "The short ID for the module to help identify it for pages",
        isRequired: true,
      },
      {
        key: "name",
        type: "string",
        label: "Name",
        description: "Name of the module",
        isRequired: true,
      },
      {
        key: "description",
        type: "memo",
        label: "Description",
        description: "Description of the module",
      },
      {
        key: "is_active",
        type: "boolean",
        label: "Is Active",
        description: "Is this module active",
      },
      {
        key: "sort_order",
        type: "integer",
        label: "Sort Order",
        description: "Sort order of module",
      },
    ],
  });

  return <FormEditorDialog form={form} />;
};
