import { PortfolioGroupOptimizerRuns } from "./PortfolioGroupOptimizerRuns";
import { PortfolioGroupNewOptimizer } from "./PortfolioGroupNewOptimizer";
import { CarAssetAllocationSection } from "../viewPortfolioGroup/AssetAllocationSection";
import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import { PortfolioGroupOptimizerTemplates } from "./PortfolioGroupOptimizerTemplates";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarPageContent } from "components/PageContent";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { CarSwitch } from "components/Switch";
import { useLocalStorage } from "features/general/useLocalStorage";

const Section = (props: { title: string; children: ReactElement }) => (
  <Box
    sx={{
      flex: "auto",
      display: "flex",
      flexDirection: "column",
      width: 0, // makes both sections even size
    }}
  >
    <Box sx={{ display: "flex", pl: 1 }}>
      <Typography variant="h6SemiBold">{props.title}</Typography>
    </Box>
    <Box
      sx={{
        mt: 0.6,
        borderTop: "1px solid",
        borderTopColor: "gray6",
        backgroundColor: "gray1",
        p: 3.5,
        display: "flex",
      }}
    >
      {props.children}
    </Box>
  </Box>
);

export const EditPortfolioGroupOptimizer = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const data = usePortfolioGroupInfo({ portfolioGroupId });
  const pageContent = usePageContent();

  const [isAdvanced, setIsAdvanced] = useLocalStorage<boolean>(
    "EditPortfolioGroupOptimizer_isAdvanced",
    false,
  );

  return (
    <TablePlaceHolder
      isLoading={data.isLoading || pageContent.isLoading}
      rows={8}
      sx={{ mt: 5 }}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Optimizer
      </Typography>
      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_OPTIMIZER,
        )}
      />

      <Box sx={{ mt: 5, display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="par01Regular">Simple</Typography>
        <CarSwitch
          size="small"
          checked={isAdvanced}
          onChange={(e, value) => setIsAdvanced(value)}
        />
        <Typography variant="par01Regular">Advanced</Typography>
      </Box>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          flexDirection: "row",
          gap: 9,
        }}
      >
        <Section title="Optimization Runs">
          <PortfolioGroupOptimizerRuns />
        </Section>
        <Section title="Templates">
          <PortfolioGroupOptimizerTemplates />
        </Section>
      </Box>
      <CarAssetAllocationSection
        storageKey="EditPortfolioGroupOptimizer-workSpace"
        label="Work Space"
        defaultExpanded
      >
        <PortfolioGroupNewOptimizer />
      </CarAssetAllocationSection>
    </TablePlaceHolder>
  );
};
