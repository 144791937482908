import {
  usePortfolioOptimizerTemplatesCreateMutation,
  usePortfolioOptimizerTemplatesLoadJobUpdateMutation,
  usePortfolioPortfolioGroupVersionOptimizerJobsDestroyMutation,
  usePortfolioPortfolioGroupVersionOptimizerJobsPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionRetrieveQuery,
} from "api/carApi.generated";
import { useDialog } from "app/useDialog";
import { useSuccessToast } from "app/useSuccessToast";
import { DeleteItemDialog } from "components/DeleteItemDialog";
import { RenameDialog } from "components/RenameDialog";
import { useShowConfigSelector } from "./useShowConfigSelector";
import { useNavigate } from "react-router-dom";
import { editPortfolioGroupPages, editPortfolioPages } from "route.const";
import {
  MappingModuleItem,
  MappingModulesDialog,
} from "./MappingModulesDialog";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { checkDefined } from "utils";
import {
  OptimizationJobRoot,
  useOptimizationJobs,
} from "./useOptimizationJobs";

export const usePortfolioGroupOptimizerRuns = ({
  portfolioGroupId,
}: {
  portfolioGroupId: string;
}) => {
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;

  const dialog = useDialog();
  const toast = useSuccessToast();
  const navigate = useNavigate();

  const optimizationJobs = useOptimizationJobs({
    portfolioGroupVersionId,
  });

  const draftVersion = usePortfolioPortfolioGroupVersionRetrieveQuery(
    {
      id: portfolioGroupVersionId ?? "",
    },
    {
      skip: !portfolioGroupVersionId,
    },
  );

  const [updateJob] =
    usePortfolioPortfolioGroupVersionOptimizerJobsPartialUpdateMutation();
  const [deleteJob] =
    usePortfolioPortfolioGroupVersionOptimizerJobsDestroyMutation();
  const [createTemplate] = usePortfolioOptimizerTemplatesCreateMutation();
  const [loadJob] = usePortfolioOptimizerTemplatesLoadJobUpdateMutation();

  const { setShowConfig } = useShowConfigSelector({
    portfolioGroupId,
    portfolioGroupVersionId,
  });

  const handleRename = (item: OptimizationJobRoot) => {
    dialog(RenameDialog, {
      label: "Name Optimization",
      value: item.title ?? "",
      applyLabel: "Save",
      onRename: async (title) => {
        const result = await updateJob({
          portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
          id: item.id ?? "",
          patchedPortfolioGroupVersionOptimizationJobRoot: {
            title,
          },
        });
        return "data" in result;
      },
    });
  };

  const handleDelete = async (item: OptimizationJobRoot) => {
    if (
      !(await dialog(DeleteItemDialog, {
        itemName: item.title ?? "",
        itemTypeName: "optimization",
      }))
    ) {
      return false;
    }

    const deleteJobResponse = await deleteJob({
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      id: item.id ?? "",
    });

    const result = "data" in deleteJobResponse;

    if (result) {
      toast({
        kind: "success",
        message: "Success! This optimization has been deleted",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
    return result;
  };

  const handleAnalyze = async (optimizationJobRootId: string) => {
    setShowConfig((oldValue) => ({
      ...oldValue,
      optimizationIds: [optimizationJobRootId],
    }));
    navigate(
      groupInfo.isSinglePortfolioMode
        ? `..\\${editPortfolioPages.editCompare}`
        : `..\\${editPortfolioGroupPages.editCompare}`,
    );
  };

  const handleLoad = async (item: OptimizationJobRoot): Promise<boolean> => {
    let loadResult = false;
    await dialog(MappingModulesDialog, {
      subtitle:
        "Select from the dropdown which old model should map to your new model.",
      mappingModuleItems: item.jobs.map<MappingModuleItem>((i) => ({
        value: i.id,
        label: i.moduleTitle,
      })),
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      onAccept: async (mapping) => {
        const result = await loadJob({
          optimizerTemplateContainerLoadFromJob: {
            source_job_id: item.id,
            portfolio_group_version_id: checkDefined(portfolioGroupVersionId),
            // for some reason this call needs mapping in reverse order
            mapping: Object.fromEntries(
              Object.entries(mapping).map(([a, b]) => [b, a]),
            ),
          },
        });
        loadResult = "data" in result;
        return loadResult;
      },
    });
    return loadResult;
  };

  const handleLoadSingle = async (item: OptimizationJobRoot) => {
    const draftModules =
      draftVersion.data?.portfolio_modules?.filter((i) => !i.is_cash) ?? [];

    if (!item.jobs) {
      console.error("handleLoadSingle: Item has no jobs", item);
      return false;
    }
    if (item.jobs.length > 1) {
      console.error("handleLoadSingle: Item has more than one job", item);
      return false;
    }

    if (draftModules.length === 0) {
      console.error("handleLoadSingle: Draft has no modules", draftVersion);
      return false;
    }

    if (draftModules.length > 1) {
      console.error(
        "handleLoadSingle: Draft has has more than one module",
        draftVersion,
      );
      return false;
    }

    const jobId = item.jobs[0].id ?? "";
    const draftModuleId = draftModules[0].id ?? "";

    const result = await loadJob({
      optimizerTemplateContainerLoadFromJob: {
        source_job_id: item.id,
        portfolio_group_version_id: checkDefined(portfolioGroupVersionId),
        mapping: { [jobId]: draftModuleId },
      },
    });
    return "data" in result;
  };

  const handleCreateTemplate = async (item: OptimizationJobRoot) => {
    dialog(RenameDialog, {
      label: "Name of Template",
      value: item.title ?? "",
      applyLabel: "Create",
      onRename: async (name) => {
        const result = await createTemplate({
          optimizerTemplateContainer: {
            name,
            optimizer_job_id: item.id,
          },
        });
        return "data" in result;
      },
    });
  };

  return {
    isLoading:
      groupInfo.isLoading ||
      optimizationJobs.isLoading ||
      draftVersion.isLoading,
    items: optimizationJobs.items,
    getDraftModuleId: () =>
      draftVersion.data?.portfolio_modules?.find((i) => !i.is_cash)?.id,
    handleRename,
    handleDelete,
    handleAnalyze,
    handleLoad,
    handleLoadSingle,
    handleCreateTemplate,
  };
};
