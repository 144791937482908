import {
  OptimizerTemplateContainer,
  usePortfolioOptimizerTemplatesDestroyMutation,
  usePortfolioOptimizerTemplatesListQuery,
  usePortfolioOptimizerTemplatesLoadTemplateUpdateMutation,
  usePortfolioOptimizerTemplatesPartialUpdateMutation,
} from "api/carApi.generated";
import { useDialog } from "app/useDialog";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { useSuccessToast } from "app/useSuccessToast";
import { DeleteItemDialog } from "components/DeleteItemDialog";
import { RenameDialog } from "components/RenameDialog";
import {
  MappingModuleItem,
  MappingModulesDialog,
} from "./MappingModulesDialog";
import { checkDefined } from "utils";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";

export const usePortfolioGroupOptimizerTemplates = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;
  const dialog = useDialog();
  const toast = useSuccessToast();

  const templateList = usePortfolioOptimizerTemplatesListQuery();

  const [updateTemplate] =
    usePortfolioOptimizerTemplatesPartialUpdateMutation();
  const [deleteTemplate] = usePortfolioOptimizerTemplatesDestroyMutation();
  const [loadTemplate] =
    usePortfolioOptimizerTemplatesLoadTemplateUpdateMutation();

  const handleRename = (item: OptimizerTemplateContainer) => {
    dialog(RenameDialog, {
      label: "Name of Template",
      value: item.name ?? "",
      applyLabel: "Save",
      onRename: async (name) => {
        const result = await updateTemplate({
          id: item.id ?? "",
          patchedOptimizerTemplateContainer: {
            name: name ?? "",
          },
        });
        return "data" in result;
      },
    });
  };

  const handleDelete = async (item: OptimizerTemplateContainer) => {
    if (
      !(await dialog(DeleteItemDialog, {
        itemName: item.name ?? "",
        itemTypeName: "template",
      }))
    ) {
      return;
    }

    const result = await deleteTemplate({
      id: item.id ?? "",
    });

    if ("data" in result) {
      toast({
        kind: "success",
        message: "Success! This template has been deleted",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
  };

  const handleLoad = async (item: OptimizerTemplateContainer) => {
    dialog(MappingModulesDialog, {
      subtitle:
        "The “Old Modules” were the portfolio models you used when you first created this template. You will now need to map your new models to these old models to utilize the same settings.",
      mappingModuleItems: Array.from(item.modules ?? [])
        .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0))
        ?.map<MappingModuleItem>((i) => ({
          value: i.id ?? "",
          label: i.module_name ?? "",
        })),
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      onAccept: async (mapping) => {
        const result = await loadTemplate({
          id: item.id ?? "",
          optimizerTemplateContainerLoad: {
            portfolio_group_version_id: checkDefined(portfolioGroupVersionId),
            mapping,
          },
        });
        return "data" in result;
      },
    });
  };

  return {
    isLoading: groupInfo.isLoading || templateList.isLoading,
    items: templateList.data ?? [],
    handleLoad,
    handleRename,
    handleDelete,
  };
};
