import { ReactElement } from "react";
import { Box } from "@mui/material";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "../../usePortfolioGroupInfo";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarFlatPageControl } from "components/FlatPageControl";
import { usePortfolioGroupVersionData } from "../../usePortfolioGroupVersionData";
import { CarSelectOption, CarSelectOptionField } from "components/Inputs";
import { usePortfolioPortfolioGroupVersionModulesDataMakeSelectedUpdateMutation } from "api/carApi.generated";
import { AnalyzeModuleSummary } from "./AnalyzeModuleSummary";
import { AnalyzeModulePerformanceRisk } from "./AnalyzeModulePerformanceRisk";
import { AnalyzeModuleMultiPeriodReturn } from "./AnalyzeModuleMultiPeriodReturn";
import { AnalyzeModuleSimulatedForwardProbabilities } from "./AnalyzeModuleSimulatedForwardProbabilities";
import { AnalyzeModuleSectorAnalysis } from "./AnalyzeModuleSectorAnalysis";
import { AnalyzeModuleScenarioAnalysis } from "./AnalyzeModuleScenarioAnalysis";
import { useLocalStorage } from "features/general/useLocalStorage";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { AnalyzeSummary_assetClassLevel } from "./AnalyzeSummary";
import { checkDefined } from "utils";

interface AnalyzeModuleProps {
  reportFocusSelector: ReactElement;
  moduleId: string;
  moduleIdx: number;
}

export const AnalyzeModule = (props: AnalyzeModuleProps) => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const { moduleId, moduleIdx } = props;

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const data = usePortfolioGroupVersionData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    show: {
      benchmark: true,
      existing: false,
      draft: true,
      selected: false,
    },
    filterByModuleId: moduleId,
    hideCash: true,
  });

  const [currentTab, setCurrentTab] = useLocalStorage<string | undefined>(
    "AnalyzeModule_currentTab",
    "summary",
  );

  // const handleShowPdf = () => {
  //   if (
  //     portfolioGroupId &&
  //     portfolioGroupVersionId &&
  //     moduleId &&
  //     chartDataSelector.data1Id &&
  //     chartDataSelector.data2Id
  //   ) {
  //     openInNewTab(
  //       rootPages.getPortfolioGroupModulePdfReportUrl({
  //         portfolioGroupId,
  //         portfolioGroupVersionId,
  //         moduleId,
  //         data1Id: chartDataSelector.data1Id,
  //         data2Id: chartDataSelector.data2Id,
  //       }),
  //     );
  //   }
  // };

  const [selectModuleData] =
    usePortfolioPortfolioGroupVersionModulesDataMakeSelectedUpdateMutation();

  const handleSelectedModuleDataChange = async (params: {
    moduleId: string;
    dataId: string;
  }) =>
    selectModuleData({
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      moduleId: params.moduleId,
      id: params.dataId,
    });

  const currentModuleBand = data.tableData.bands.find(
    (i) => i.moduleId === moduleId,
  );

  const selectionItems =
    currentModuleBand?.columns
      .filter((i) => !!i.dataId)
      .map<CarSelectOption<string>>((i) => ({
        value: i.dataId,
        label: i.label,
      })) ?? [];

  return (
    <TablePlaceHolder
      sx={{ mt: 5 }}
      isLoading={groupInfo.isLoading || data.isLoading}
      rows={8}
    >
      {/* <CarDownloadButton
        onClick={handleShowPdf}
        sx={{ zIndex: 1, mt: -1, mb: -3, alignSelf: "flex-end" }}
      >
        Download Reports
      </CarDownloadButton> */}
      {!groupInfo.isSinglePortfolioMode && (
        <Box sx={{ mt: 5, display: "flex", gap: 3.5 }}>
          {props.reportFocusSelector}
          <CarSelectOptionField
            label={
              <>
                Selection for Publish
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                  className={
                    pendoClasses.editPortfolioAnalyzeSelectionForPublish
                  }
                />
              </>
            }
            sx={{
              ml: "auto",
              width: 200,
              ".MuiInputLabel-root": {
                fontSize: "16px",
              },
              ".MuiOutlinedInput-root": {
                mt: 3,
              },
              ".MuiInputBase-input": {
                backgroundColor: "gray1",
              },
            }}
            options={selectionItems}
            value={currentModuleBand?.selectedDataId ?? ""}
            onChange={(dataId) => {
              if (dataId && currentModuleBand) {
                handleSelectedModuleDataChange({
                  moduleId: currentModuleBand.moduleId,
                  dataId,
                });
              }
            }}
          />
        </Box>
      )}
      <CarFlatPageControl
        sx={{
          mt: 4,
          ".flat-page-control-header": {
            // ml: 3,
            // gap: 3,
            justifyContent: "space-around",
          },
          ".flat-page-control-button": {
            p: 1,
            fontSize: "18px",
          },
        }}
        items={[
          {
            label: "Summary",
            value: "summary",
            content: (
              <AnalyzeModuleSummary
                assetClassLevelStorageKey={AnalyzeSummary_assetClassLevel}
                moduleId={moduleId}
              />
            ),
          },
          {
            label: "Performance Risk",
            value: "performance-risk",
            content: (
              <AnalyzeModulePerformanceRisk
                moduleId={moduleId}
                moduleIdx={moduleIdx}
              />
            ),
          },
          {
            label: "Multi-Period Return",
            value: "multi-period-return",
            content: (
              <AnalyzeModuleMultiPeriodReturn
                moduleId={moduleId}
                moduleIdx={moduleIdx}
              />
            ),
          },
          {
            label: "Simulated Forward Probabilities",
            value: "simulated-forward-probabilities",
            content: (
              <AnalyzeModuleSimulatedForwardProbabilities moduleId={moduleId} />
            ),
          },
          {
            label: "Scenario Analysis",
            value: "scenario-analysis",
            content: <AnalyzeModuleScenarioAnalysis moduleId={moduleId} />,
          },
          {
            label: "Sector Analysis",
            value: "sector-analysis",
            content: (
              <AnalyzeModuleSectorAnalysis
                moduleId={moduleId}
                moduleIdx={moduleIdx}
              />
            ),
          },
        ]}
        value={currentTab}
        onChange={setCurrentTab}
      />
    </TablePlaceHolder>
  );
};
