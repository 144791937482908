import { useNavigate } from "react-router-dom";
import {
  ClientPlanRelationship,
  useQuestionnaireClientsCreateMutation,
} from "api/carApi.generated";
import { CarClient } from "types";
import { financialPlanningPages } from "route.const";
import { isDefined } from "utils";

interface AddClientParams {
  client: CarClient;
  primaryRelation: ClientPlanRelationship;
  secondaryRelation?: ClientPlanRelationship;
}

const DEFAULT_RETIREMENT_AGE = 70;
const DEFAULT_LIVE_UNTIL_AGE = 93;

export const useAddClient = () => {
  const [putClientReq, putClientResult] =
    useQuestionnaireClientsCreateMutation();

  const navigate = useNavigate();

  const addClient = async ({
    client,
    primaryRelation,
    secondaryRelation,
  }: AddClientParams) => {
    const clientResponse = await putClientReq({
      client: {
        ...client,
        active_relationships: [
          {
            ...primaryRelation,
            income: 0,
            state_of_residence: "VA",
            retirement_age: DEFAULT_RETIREMENT_AGE,
            retirement_state: "VA",
            live_until: DEFAULT_LIVE_UNTIL_AGE,
          },
          secondaryRelation
            ? {
                ...secondaryRelation,
                retirement_age: DEFAULT_RETIREMENT_AGE,
                live_until: DEFAULT_LIVE_UNTIL_AGE,
                income: 0,
              }
            : undefined,
        ].filter(isDefined),
      } as Required<CarClient>,
    });

    if (!("data" in clientResponse)) {
      return;
    }

    const clientId = clientResponse.data.id;
    const planId = clientResponse.data.plans?.[0].id;

    if (clientId && planId) {
      navigate(financialPlanningPages.getPlanInputUrl(clientId, planId));
    }
  };

  return {
    isLoading: putClientResult.isLoading,
    addClient,
  };
};
