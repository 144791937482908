import { Typography } from "@mui/material";
import { BoxProps, Box } from "@mui/system";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { Pie } from "react-chartjs-2";
import { CarIconDelete, CarIconRename } from "icons";
import { CarMenuButton } from "components/MenuButton";
import {
  formatPercent,
  formatPercentFactor,
  isDefined,
  isOddEven,
} from "utils";
import { CarTooltip } from "components/Tooltip";
import { Fragment, useState } from "react";
import { CarPortfolioAssetTableData } from "pages/assetAllocation/usePortfolioGroupVersionData";
import { UsePortfolioSummary } from "./usePortfolioSummary";
import { CarButton } from "components/Buttons";
import { CarInfoCell, CarInfoTable } from "components/InfoTable";

const chartSize = 130;
interface HintValue {
  label: string;
  value: number;
}
const VersionChart = ({
  sx,
  tableData,
}: {
  sx?: BoxProps["sx"];
  tableData: CarPortfolioAssetTableData;
}) => {
  const [hintValue, setHintValue] = useState<HintValue | undefined>();

  const plainData = tableData.assetRows.level1.flatMap((i) => i.children);

  return (
    <Box
      sx={{
        border: "solid 2px",
        borderColor: "gray3",
        backgroundColor: "gray1",
        borderRadius: "5px",
        padding: 2.5,
        display: "flex",
        ...sx,
      }}
    >
      {tableData.bands.map((bd) => {
        const labels = plainData.map((i) => i.name);
        const values = plainData.map(
          (i) =>
            i.modules.find((md) => md.moduleId === bd.moduleId)?.values?.[0]
              ?.allocation ?? 0,
        );

        return (
          <CarTooltip
            key={bd.moduleId}
            title={
              hintValue
                ? `${hintValue.label}\n${formatPercent(hintValue.value * 100)}`
                : ""
            }
            followCursor
            arrow={false}
            preWrap
          >
            <div>
              <Pie
                width={chartSize}
                height={chartSize}
                options={{
                  responsive: false,
                  // animation: false,
                  layout: {
                    // padding: -10,
                  },
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  onHover(event, elements, chart) {
                    const index = elements[0]?.index;

                    const newValue: HintValue | undefined = isDefined(index)
                      ? {
                          label: labels[index],
                          value: values[index],
                        }
                      : undefined;

                    if (
                      newValue?.label !== hintValue?.label ||
                      newValue?.value !== hintValue?.value
                    )
                      setHintValue(newValue);
                  },
                }}
                data={{
                  labels,
                  datasets: [
                    {
                      label: "data",
                      data: values,
                      borderWidth: 0,
                      backgroundColor: plainData.map((i) => i.color),
                    },
                  ],
                }}
              />
            </div>
          </CarTooltip>
        );
      })}
    </Box>
  );
};

const VersionTable = ({
  sx,
  tableData,
}: {
  sx?: BoxProps["sx"];
  tableData: CarPortfolioAssetTableData;
}) => {
  const plainData = tableData.assetRows.level1.flatMap((i) => i.children);

  return (
    <CarInfoTable sx={{ gridTemplateColumns: "1.5fr 1fr", ...sx }}>
      {plainData.map((i, idx) => (
        <Fragment key={i.id}>
          <CarInfoCell
            sx={{ fontSize: "14px" }}
            isOdd={isOddEven(idx)}
            justifyContent="start"
          >
            <Box sx={{ display: "flex", gap: 1.75, alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  borderRadius: "4px",
                  border: "1px solid",
                  borderColor: "gray6",
                  backgroundColor: i.color,
                  flexShrink: 0,
                }}
              />
              {i.name}
            </Box>
          </CarInfoCell>
          <CarInfoCell
            sx={{ fontSize: "14px" }}
            isOdd={isOddEven(idx)}
            text={formatPercentFactor(
              i.modules[0]?.values[0]?.allocation ?? 0,
              1,
            )}
          />
        </Fragment>
      ))}
    </CarInfoTable>
  );
};

interface PortfolioSummaryProps {
  portfolioSummary: UsePortfolioSummary;
  sx?: BoxProps["sx"];
}

export const PortfolioSummary = ({
  portfolioSummary,
  sx,
}: PortfolioSummaryProps) => {
  return (
    <RootPageBox
      sx={{
        p: 3,
        ...sx,
      }}
    >
      <TablePlaceHolder isLoading={portfolioSummary.isLoading} rows={5}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h3SSemiBold">
            {portfolioSummary.title}
          </Typography>
          {!portfolioSummary.isEmpty && (
            <CarMenuButton
              items={[
                {
                  icon: <CarIconRename />,
                  label: "Rename",
                  onClick: portfolioSummary.handleRename,
                },
                {
                  icon: <CarIconDelete />,
                  label: "Delete",
                  onClick: portfolioSummary.handleDelete,
                },
              ]}
            />
          )}
          <CarButton
            variant="outlined"
            size="small"
            sx={{ mt: 0.75, ml: "auto", width: 100, height: 40 }}
            onClick={portfolioSummary.handleEdit}
          >
            Edit
          </CarButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            mt: 4.5,
            ml: 1,
            gap: 3.5,
            alignItems: "center",
          }}
        >
          <VersionChart tableData={portfolioSummary.tableData} />
          <VersionTable
            tableData={portfolioSummary.tableData}
            sx={{ flex: "auto" }}
          />
        </Box>
      </TablePlaceHolder>
    </RootPageBox>
  );
};
