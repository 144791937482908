import { Avatar, AvatarProps } from "@mui/material";

const stringToColor = (string: string) => {
  let stringUniqueHash = Array.from(string).reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);
  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
};

interface CarAvatarProps extends Omit<AvatarProps, "children" | "alt" | "src"> {
  src?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

export const CarAvatar = ({
  firstName,
  lastName,
  email,
  src,
  ...props
}: CarAvatarProps) => {
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : email ?? "";

  const abbr = (
    firstName && lastName ? `${firstName[0]}${lastName[0]}` : email?.[0] ?? ""
  ).toUpperCase();

  return (
    <Avatar
      alt={fullName}
      src={src ?? undefined}
      {...props}
      sx={{
        ...props.sx,
        bgcolor: !src && fullName ? stringToColor(fullName) : "gray4",
      }}
    >
      {abbr}
    </Avatar>
  );
};
