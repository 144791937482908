import {
  RootPage,
  RootPageHeader,
  RootPageHeaderLink,
  RootPageTransparentPaper,
} from "components/RootPage";
import { SettingsUserSettings } from "./SettingsUserSettings";
import { Navigate, Route, Routes } from "react-router-dom";
import { settingsPages } from "route.const";
import { SettingsNotificationSettings } from "./SettingsNotificationSettings";
import { SettingsFirmSettings } from "./SettingsFirmSettings";
import { SettingsDisclosures } from "./SettingsDisclosures";
import { useUser } from "./useUser";
import { useRoles } from "app/useRoles";
import { SettingsAssetClassSettings } from "./SettingsAssetClassSettings";
import { SettingsBackEnd } from "./SettingsBackEnd";

export const SettingsPage = () => {
  const user = useUser();
  const roles = useRoles();

  const isAdmin = roles.isAdminRole(user.user.role);

  return (
    <RootPage sx={{ paddingBottom: 0 }}>
      <RootPageHeader
        title="Settings"
        isSmall
        links={
          <>
            <RootPageHeaderLink
              label="User Settings"
              url={settingsPages.userSettings}
            />
            {isAdmin && (
              <RootPageHeaderLink
                label="Firm Settings"
                url={settingsPages.firmSettings}
              />
            )}
            <RootPageHeaderLink
              label="Notifications"
              url={settingsPages.notifications}
            />
            <RootPageHeaderLink
              label="Asset Class Settings"
              url={settingsPages.assetClassSettings}
            />
            <RootPageHeaderLink
              label="Disclosures"
              url={settingsPages.disclosuresUrl}
            />
            {user.isCaravelUser && (
              <RootPageHeaderLink
                label="Back End"
                url={settingsPages.backEndUrl}
              />
            )}
          </>
        }
      />
      <RootPageTransparentPaper>
        <Routes>
          <Route
            path={settingsPages.userSettings}
            element={<SettingsUserSettings />}
          />
          <Route
            path={settingsPages.notifications}
            element={<SettingsNotificationSettings />}
          />
          {isAdmin && (
            <Route
              path={settingsPages.firmSettings}
              element={<SettingsFirmSettings />}
            />
          )}
          <Route
            path={settingsPages.disclosuresPath}
            element={<SettingsDisclosures />}
          />
          <Route
            path={settingsPages.assetClassSettings}
            element={<SettingsAssetClassSettings />}
          />
          {user.isCaravelUser && (
            <Route
              path={settingsPages.backEndPath}
              element={<SettingsBackEnd />}
            />
          )}
          <Route
            path="*"
            element={<Navigate to={settingsPages.userSettings} replace />}
          />
        </Routes>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
