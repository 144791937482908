import { PdfChart, PdfChartProps } from "components/Pdf";
import { ChartConfiguration } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { getPortfolioGroupAssetStatChartData } from "../PortfolioGroupAssetStatChart";
import { useTheme } from "@mui/material";
import { Text, View } from "@react-pdf/renderer";
import { CarPortfolioAssetStatTableData } from "../usePortfolioGroupVersionStatTableData";

interface PortfolioGroupAssetStatPdfChartProps
  extends Omit<PdfChartProps, "config"> {
  data: CarPortfolioAssetStatTableData;
  xStatId?: string;
  yStatId?: string;
  xFormatter: (value?: number) => string;
  yFormatter: (value?: number) => string;
}

const PdfChartLegend = ({ config }: { config: ChartConfiguration<"line"> }) => {
  return (
    <View
      style={{
        marginLeft: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 140,
        paddingRight: 140,
        flexWrap: "wrap",
      }}
    >
      {config.data.datasets.map((ds, idx) => {
        return (
          <View
            key={idx}
            style={{
              flexDirection: "row",
              marginTop: 12,
              alignItems: "center",
            }}
          >
            {ds.borderDash ? (
              <View style={{ flexDirection: "row", marginRight: 4 }}>
                <View
                  style={{
                    width: 7,
                    height: 2,
                    backgroundColor: String(ds.backgroundColor),
                  }}
                />
                <View
                  style={{
                    marginLeft: 6,
                    width: 7,
                    height: 2,
                    backgroundColor: String(ds.backgroundColor),
                  }}
                />
              </View>
            ) : (
              <View
                style={{
                  width: 20,
                  height: 2,
                  backgroundColor: String(ds.backgroundColor),
                  marginRight: 4,
                }}
              />
            )}
            <Text
              style={{
                fontSize: 10,
              }}
            >
              {ds.label}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export const PortfolioGroupAssetStatPdfChart = ({
  data,
  xStatId,
  yStatId,
  xFormatter,
  yFormatter,
  ...props
}: PortfolioGroupAssetStatPdfChartProps) => {
  const theme = useTheme();

  const { datasets, maxFeeDatasets, xLabel, yLabel } =
    getPortfolioGroupAssetStatChartData(data, xStatId, yStatId);

  const formatXValue = (value: number | string) =>
    typeof value === "number" ? xFormatter(value) : "";

  const formatYValue = (value: number | string) =>
    typeof value === "number" ? yFormatter(value) : "";

  const font = {
    size: 14,
    weight: "400",
    lineHeight: 1,
  };

  const config: ChartConfiguration<"line"> = {
    type: "line",
    options: {
      devicePixelRatio: 4,
      responsive: false,
      animation: false,
      borderColor: "transparent",

      scales: {
        x: {
          type: "linear",
          ticks: {
            font,
            color: theme.palette.black,
            callback: formatXValue,
            padding: 6,
          },
          border: { display: false },
          grid: {
            drawTicks: false,
            color: theme.palette.gray2,
            lineWidth: 1,
          },
          title: {
            display: true,
            text: xLabel,
            color: theme.palette.black,
            font,
          },
        },
        y: {
          ticks: {
            font,
            callback: formatYValue,
            color: theme.palette.black,
            padding: 6,
          },
          border: { display: false },
          grid: {
            drawTicks: false,
            color: theme.palette.gray2,
            lineWidth: 1,
          },
          title: {
            display: true,
            text: yLabel,
            color: theme.palette.black,
            font,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        roundedBackground: {
          contextColor: theme.palette.white,
          backgroundColor: theme.palette.gray1,
          borderColor: theme.palette.gray7,
          borderRadius: 0,
        },
      },
    },
    data: {
      datasets: [
        ...datasets.map((ds) => ({
          label: ds.label,
          data: ds.data,
          borderColor: ds.color,
          backgroundColor: ds.color,
          pointBackgroundColor: ds.color,
          pointBorderColor: ds.color,
          borderWidth: 2,
          pointRadius: 2,
        })),
        ...maxFeeDatasets
          .map((ds) => ({
            label: `${ds.label} (Max. fee)`,
            data: ds.data,
            borderColor: ds.color,
            backgroundColor: ds.color,
            pointBackgroundColor: ds.color,
            pointBorderColor: ds.color,
            borderWidth: 2,
            pointRadius: 2,
            borderDash: [5, 5],
          }))
          .slice(0, 2),
      ],
    },
    plugins: [ChartPlugins.roundedBackground],
  };

  return (
    <>
      <PdfChart config={config} {...props} />
      <PdfChartLegend config={config} />
    </>
  );
};
