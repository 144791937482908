import { PdfChart, PdfChartProps } from "components/Pdf";
import { ChartConfiguration, FontSpec } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { useTheme } from "@mui/material";
import ReactPDF, { Text, View } from "@react-pdf/renderer";
import { CmaModelChartData } from "api/carApi.generated";
import {
  DftTotalReturnsDataPoint,
  getPortfolioGroupDftTotalReturnsData,
} from "../editPortfolioGroup/ChartDftTotalReturns";
import { typographyOptions } from "theme/typography";
import { formatDate, formatPercent, formatPercentFactor } from "utils";

interface PortfolioGroupDftTotalReturnsPdfChartProps
  extends Omit<PdfChartProps, "config"> {
  data: CmaModelChartData;
  assetClassName: string;
}

const PdfChartLegend = ({
  config,
}: {
  config: ChartConfiguration<"line" | "scatter">;
}) => {
  return (
    <View
      style={{
        marginTop: 5,
        marginLeft: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {config.data.datasets.map((ds, idx) => {
        return (
          <View
            key={idx}
            style={{
              marginLeft: 16,
              marginRight: 16,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                backgroundColor: String(
                  ds.borderColor ?? ds.pointBorderColor ?? "black",
                ),
                borderRadius: ds.type === "scatter" ? 5 : 0,
                marginRight: 4,
              }}
            />
            <Text
              style={{
                fontSize: 10,
              }}
            >
              {ds.label}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export const PortfolioGroupDftTotalReturnsPdfChart = ({
  data,
  assetClassName,
  ...props
}: PortfolioGroupDftTotalReturnsPdfChartProps) => {
  const theme = useTheme();

  const calcData = getPortfolioGroupDftTotalReturnsData({
    data,
    assetClassName,
  });

  if (!calcData) {
    return <></>;
  }

  const { trendLine, historicLine, yLabel } = calcData;

  const font: Partial<FontSpec> = {
    size: 14,
    weight: "400",
    lineHeight: 1,
    family: typographyOptions.fontFamily,
  };

  const config: ChartConfiguration<"line", DftTotalReturnsDataPoint[]> = {
    type: "line",
    options: {
      devicePixelRatio: 4,
      responsive: false,
      animation: false,
      borderColor: "transparent",

      layout: {
        padding: {
          right: 1, // for some reason right border is cropped, so add right padding to fit it in image
          bottom: 34, // for yearTicks
        },
      },

      scales: {
        x: {
          type: "time",
          ticks: {
            display: false,
            padding: 10,
          },
          grid: {
            display: false,
          },
          time: {
            unit: "year",
          },
        },
        y: {
          offset: false,
          type: "logarithmic",
          ticks: {
            font,
            color: theme.palette.black,
            callback: (value) => " ", // this will roughly align charts on left side ,
          },
          grid: {
            display: true,
            drawTicks: false,
            color: (ctx) =>
              ctx.tick.value === 0 ? "#B1B1B3" : "rgba(0, 0, 0, 0)",

            lineWidth: 1,
          },
          title: {
            display: true,
            text: yLabel,
            color: theme.palette.black,
            font,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        roundedBackground: {
          contextColor: theme.palette.white,
          backgroundColor: "#FAFAFA", //theme.palette.gray1,
          borderColor: "#B1B1B3", // theme.palette.gray7,
          borderRadius: 0,
        },
        yearTicks: {
          font: {
            ...font,
            size: 12,
          },
          color: "#B1B1B3",
          marginTop: 36,
        },
      },
    },
    data: {
      datasets: [
        {
          ...historicLine,
          borderColor: theme.palette.chartsColor.blue,
          pointBorderWidth: 0,
          pointRadius: 0,
          borderWidth: 1,
        },
        {
          ...trendLine,
          borderColor: theme.palette.chartsColor.orange,
          pointBorderWidth: 0,
          pointRadius: 0,
          borderWidth: 1,
        },
      ],
    },
    plugins: [ChartPlugins.roundedBackground, ChartPlugins.yearTicks],
  };

  return (
    <>
      <PdfChart config={config as any} {...props} />
      <PdfChartLegend config={config as any} />
    </>
  );
};

interface DftTotalReturnsPdfSummaryProps extends ReactPDF.ViewProps {
  data: CmaModelChartData;
  realStaticExpectedReturn: number;
}

export const DftTotalReturnsPdfSummary = ({
  data,
  realStaticExpectedReturn,
  ...props
}: DftTotalReturnsPdfSummaryProps) => (
  <View
    {...props}
    style={{
      ...props.style,
      fontSize: 10,
    }}
  >
    <Text>Start Date: {formatDate(data.label_start_date)}</Text>
    <View style={{ flexDirection: "row" }}>
      <Text>Starting DFT: {formatPercent(data.label_start_dft ?? 0, 2)}</Text>
      <Text style={{ marginLeft: 10 }}>
        Ending DFT: {formatPercent(data.label_end_dft ?? 0, 2)}
      </Text>
    </View>
    <View style={{ flexDirection: "row" }}>
      <Text>
        Expected Return: {formatPercent(data.label_expected_return ?? 0, 2)}
      </Text>
      <Text style={{ marginLeft: 10 }}>
        Expected Return Formula: {data.label_expected_return_formula}
      </Text>
    </View>
    <Text>
      Real Expected Return (Trend Line):{" "}
      {formatPercentFactor(realStaticExpectedReturn, { decimalPlaces: 2 })}
    </Text>
  </View>
);
