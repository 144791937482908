import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo, useState } from "react";
import { useChartDataSelector } from "../assetAllocation/editPortfolioGroup/useChartDataSelector";
import { CarDownloadButton } from "components/Buttons";
import { openInNewTab } from "utils";
import { rootPages } from "route.const";
import { useAssetClassSummaryStat } from "./useAssetClassSummaryStat";
import { ChartDftTotalReturns } from "pages/assetAllocation/editPortfolioGroup/ChartDftTotalReturns";
import { ChartDftVersusTenYearReturn } from "pages/assetAllocation/editPortfolioGroup/ChartDftVersusTenYearReturn";
import { useChartActiveElement } from "components/ChartTooltip";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { ChartDftTotalReturnsSummary } from "pages/assetAllocation/editPortfolioGroup/ChartDftTotalReturnsSummary";
import { CarSwitch } from "components/Switch";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { CmaModelChartData } from "api/carApi.generated";
import { CarAssetClassSelector } from "components/AssetClassSelector";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

const sliceModelChartData = (
  startIndex: number,
  model?: CmaModelChartData,
): CmaModelChartData | undefined => {
  if (!model) {
    return undefined;
  }

  if (startIndex < 0) {
    startIndex = 0;
  }

  return {
    ...model,
    trend_x: model.trend_x?.slice(startIndex),
    trend_y_trend_line: model.trend_y_trend_line?.slice(startIndex),
    trend_y_historic_line: model.trend_y_historic_line?.slice(startIndex),
    boundary_x: model.boundary_x?.slice(startIndex),
    boundary_y_upper: model.boundary_y_upper?.slice(startIndex),
    boundary_y_expected_ret: model.boundary_y_expected_ret?.slice(startIndex),
    boundary_y_lower: model.boundary_y_lower?.slice(startIndex),
    boundary_scatter_x: model.boundary_scatter_x?.slice(startIndex),
    boundary_scatter_y: model.boundary_scatter_y?.slice(startIndex),
  };
};

export const AssetClassAnalysisDistanceFromTrend = memo(() => {
  const [isCompare, setIsCompare] = useState(false);

  const assetClassAnalysis = useAssetClassSummaryStat({
    type: AssetClassLoadType.byLatestSimulationJob,
    filterByUsableInPortfolios: true,
  });

  const dftItems = assetClassAnalysis.distanceFromTrendItems;
  const enabledDftItems = dftItems.filter((i) => !i.isDisabled);

  const chartDataSelector = useChartDataSelector({
    storageKey: "AssetClassAnalysisDistanceFromTrend_chartDataSelector",
    data1DefaultId: enabledDftItems.at(0)?.level4Id,
    data2DefaultId: enabledDftItems.at(1)?.level4Id,
  });

  const item1 = dftItems.find((i) => i.level4Id === chartDataSelector.data1Id);

  const item2 = dftItems.find((i) => i.level4Id === chartDataSelector.data2Id);

  const activeElement1 = useChartActiveElement();
  const activeElement2 = useChartActiveElement();

  const pageContent = usePageContent();

  const handleShowPdf = () => {
    if (chartDataSelector.data1Id) {
      openInNewTab(
        rootPages.getAssetClassDftPdfReportUrl({
          assetClassId: chartDataSelector.data1Id,
        }),
      );
    }
  };

  let item1ModelChartData = item1?.modelChartData;
  let item2ModelChartData = item2?.modelChartData;

  if (isCompare && item1ModelChartData && item2ModelChartData) {
    const item1XStartValue = item1ModelChartData.trend_x?.at(0);
    const item2XStartValue = item2ModelChartData.trend_x?.at(0);
    const item1XIndex =
      item1ModelChartData.trend_x?.findIndex((i) => i === item2XStartValue) ??
      -1;
    const item2XIndex =
      item2ModelChartData.trend_x?.findIndex((i) => i === item1XStartValue) ??
      -1;

    item1ModelChartData = sliceModelChartData(item1XIndex, item1ModelChartData);
    item2ModelChartData = sliceModelChartData(item2XIndex, item2ModelChartData);
  }

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 10 }}
      isLoading={assetClassAnalysis.isLoading || pageContent.isLoading}
      rows={8}
    >
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
          Distance From Trend
        </Typography>
        <CarDownloadButton onClick={handleShowPdf} sx={{ ml: "auto" }}>
          Download Reports
        </CarDownloadButton>
      </Box>

      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.ASSET_CLASS_MONTHLY_DISTANCE_FROM_TREND_DIRECTIONS,
        )}
      />

      <Box
        sx={{
          mt: 5,
          mb: 3,
          display: "flex",
          alignItems: "flex-end",
          gap: 5,
        }}
      >
        <CarAssetClassSelector
          label={isCompare ? "Asset Class 1" : "Asset Class"}
          sx={{
            width: 250,
          }}
          items={dftItems}
          valueField="level4Id"
          value={chartDataSelector.data1Id}
          onChange={chartDataSelector.onData1IdChange}
        />
        {isCompare && (
          <CarAssetClassSelector
            label="Asset Class 2"
            sx={{
              width: 250,
            }}
            items={dftItems}
            valueField="level4Id"
            value={chartDataSelector.data2Id}
            onChange={chartDataSelector.onData2IdChange}
          />
        )}

        <Box sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="par01Regular">Single</Typography>
          <CarSwitch
            size="small"
            checked={isCompare}
            onChange={(e, value) => setIsCompare(value)}
          />
          <Typography variant="par01Regular">Compare</Typography>
          <CarTooltipBox
            sx={{ ml: -0.5 }}
            className={pendoClasses.assetClassAnalysisDftSingleCompare}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: 5,
          backgroundColor: "gray1",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "gray3",
          p: 6.5,
          pb: 13,
        }}
      >
        {item1 && item1ModelChartData && (
          <>
            <ChartDftTotalReturnsSummary
              assetClassName={item1.name}
              data={item1ModelChartData}
              realStaticExpectedReturn={item1?.realStaticExpectedReturn ?? 0}
              isWhiteContext={false}
            />
            <ChartDftTotalReturns
              sx={{ mt: 6.5 }}
              assetClassName={item1.name}
              data={item1ModelChartData}
              pendoPrefix="asset_class_analysis_dft_total_returns"
              activeElement={activeElement1}
              isWhiteContext={false}
            />
            {!isCompare && (
              <ChartDftVersusTenYearReturn
                sx={{ mt: 6.5 }}
                assetClassName={item1.name}
                data={item1ModelChartData}
                pendoPrefix="asset_class_analysis_dft_versus_ten_year_return"
                activeElement={activeElement1}
                isWhiteContext={false}
              />
            )}
          </>
        )}
        {isCompare && item2 && item2ModelChartData && (
          <>
            <ChartDftTotalReturnsSummary
              sx={{ mt: 6.5 }}
              assetClassName={item2.name}
              data={item2ModelChartData}
              realStaticExpectedReturn={item2?.realStaticExpectedReturn ?? 0}
              isWhiteContext={false}
            />
            <ChartDftTotalReturns
              sx={{ mt: 6.5 }}
              assetClassName={item2.name}
              data={item2ModelChartData}
              pendoPrefix="asset_class_analysis_dft_total_returns"
              activeElement={activeElement2}
              isWhiteContext={false}
            />
          </>
        )}
      </Box>
    </TablePlaceHolder>
  );
});
