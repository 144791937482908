import { usePortfolioPortfolioGroupListQuery } from "api/carApi.generated";
import { refetchOnFocus } from "const";
import { useSimulationGroups } from "pages/assetAllocation/useSimulationGroups";
import { useNavigate } from "react-router-dom";
import { assetAllocationPages } from "route.const";

export const usePortfolioList = () => {
  const simulationGroups = useSimulationGroups();
  const portfolioGroup = usePortfolioPortfolioGroupListQuery(undefined, {
    refetchOnFocus,
  });

  const navigate = useNavigate();

  const items = Array.from(portfolioGroup.data ?? [])
    .sort((a, b) => {
      const aActive = a.is_active ? 1 : 0;
      const bActive = b.is_active ? 1 : 0;
      return (
        bActive - aActive ||
        (b.created_at ?? "").localeCompare(a.created_at ?? "")
      );
    })
    .filter((i) => i.is_single_portfolio_module)
    .map((i) => ({
      ...i,
      title: `${i.title}${simulationGroups.getSimulationSuffix(
        i.simulation_group,
      )}`,
    }));

  const gotoPortfolioGroup = (portfolioGroupId: string) => {
    const versions =
      items.find((i) => i.id === portfolioGroupId)?.versions ?? [];

    navigate(
      assetAllocationPages.getEditPortfolioGroupUrl({
        portfolioGroupId,
        isPublished: versions.length > 1,
      }),
    );
  };

  return {
    items,
    isLoading: simulationGroups.isLoading || portfolioGroup.isLoading,
    gotoPortfolioGroup,
  };
};

export type UsePortfolioList = ReturnType<typeof usePortfolioList>;
