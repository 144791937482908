import { CarDateExtraField } from "components/DateExtraField";
import {
  CarPaymentPlanKindField,
  CarRealEstateTypeField,
  CarTextFieldDelayed,
  CarYesNoField,
} from "components/Inputs";
import {
  CarCurrencyFieldDelayed,
  CarFactorPercentFieldDelayed,
  CarNumberCalcField,
} from "components/NumberField";
import { requiredLabel } from "utils";
import { CarTableColumn } from "components/Table";
import { CarRealEstate } from "types";
import { addMortgage, changeMortgage } from "features/realEstate/useRealEstate";

const mortgageBalanceEntered = (item: CarRealEstate) =>
  Boolean(item.mortgage_balance && item.mortgage_balance > 0);

type RealEstateColumnKey =
  | "realEstateType"
  | "purchaseDate"
  | "description"
  | "realEstateValue"
  | "costBasis"
  | "mortgageBalance"
  | "interestRate"
  | "sellDate"
  | "primaryResidence"
  | "piPayment"
  | "monthlyTotalPayment"
  | "maturityYear"
  | "monthlyRentalIncome"
  | "monthlyExpenses"
  | "monthlyDepreciation"
  | "simulatedInflAdjRent"
  | "simulatedInflAdjExpenses"
  | "secondMortgage"
  | "secondMortgageAmount"
  | "secondMortgageStartDate"
  | "secondMortgageInterestRate"
  | "secondMortgageLoadDuration";

export const columns: Record<
  RealEstateColumnKey,
  CarTableColumn<CarRealEstate>
> = {
  realEstateType: {
    id: "real_estate_type",
    label: "*Real Estate Type",
    fraction: 1.5,
    renderCell: ({ item, onChange }) => {
      return (
        <CarRealEstateTypeField
          required
          value={item.real_estate_type}
          onChange={(real_estate_type) =>
            onChange({ ...item, real_estate_type })
          }
        />
      );
    },
  },
  purchaseDate: {
    id: "start_date_caravel",
    label: "*Purchase Date",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarDateExtraField
          required
          value={item.start_date_caravel}
          onChange={(start_date_caravel) =>
            onChange({ ...item, start_date_caravel })
          }
        />
      );
    },
  },
  description: {
    id: "name",
    label: "*Description",
    fraction: 1.5,
    renderCell: ({ item, onChange }) => {
      return (
        <CarTextFieldDelayed
          required
          value={item.name}
          onChange={(name) => onChange({ ...item, name })}
        />
      );
    },
  },
  realEstateValue: {
    id: "real_estate_value",
    label: "*Real Estate Value",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarCurrencyFieldDelayed
          required
          value={item.real_estate_value}
          onChange={(real_estate_value) =>
            onChange({ ...item, real_estate_value })
          }
        />
      );
    },
  },
  costBasis: {
    id: "cost_basis",
    label: "*Cost Basis",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarCurrencyFieldDelayed
          required
          value={item.cost_basis}
          onChange={(cost_basis) => onChange({ ...item, cost_basis })}
        />
      );
    },
  },
  mortgageBalance: {
    id: "mortgage_balance",
    label: "*Mortgage Balance",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarCurrencyFieldDelayed
          value={item.mortgage_balance}
          onChange={(mortgage_balance) =>
            onChange({ ...item, mortgage_balance })
          }
        />
      );
    },
  },
  interestRate: {
    id: "interest_rate",
    label: ({ item }) =>
      requiredLabel("Interest Rate", mortgageBalanceEntered(item)),
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarFactorPercentFieldDelayed
          required={mortgageBalanceEntered(item)}
          disabled={item.mortgage_balance === 0}
          decimalPlaces={1}
          value={item.interest_rate}
          onChange={(interest_rate) => onChange({ ...item, interest_rate })}
          minFactorValue={-0.08}
          maxFactorValue={0.08}
        />
      );
    },
  },
  sellDate: {
    id: "end_date_caravel",
    label: "*Sell Date",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarDateExtraField
          required
          value={item.end_date_caravel}
          onChange={(end_date_caravel) =>
            onChange({ ...item, end_date_caravel })
          }
        />
      );
    },
  },

  primaryResidence: {
    id: "primary_residence_ind",
    label: "*Primary Residence",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarYesNoField
          required
          value={item.primary_residence_ind ?? undefined}
          onChange={(primary_residence_ind) =>
            onChange({ ...item, primary_residence_ind })
          }
        />
      );
    },
  },

  piPayment: {
    id: "mortgage_pi_payment",
    label: ({ item }) =>
      requiredLabel("P&I Payment", mortgageBalanceEntered(item)),
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarCurrencyFieldDelayed
          required={mortgageBalanceEntered(item)}
          disabled={item.mortgage_balance === 0}
          value={item.mortgage_pi_payment}
          onChange={(mortgage_pi_payment) =>
            onChange({ ...item, mortgage_pi_payment })
          }
        />
      );
    },
  },
  monthlyTotalPayment: {
    id: "mortgage_monthly_total_payment",
    label: ({ item }) =>
      mortgageBalanceEntered(item)
        ? "*Mo. Total Payment"
        : "*Mo. Taxes and Insurance",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarCurrencyFieldDelayed
          required
          value={item.mortgage_monthly_total_payment}
          onChange={(mortgage_monthly_total_payment) =>
            onChange({ ...item, mortgage_monthly_total_payment })
          }
        />
      );
    },
  },
  maturityYear: {
    id: "maturity_year",
    label: "Maturity Year",
    fraction: 1,
    renderCell: ({ item, onChange }) => (
      <CarNumberCalcField value={item.maturity_year ?? undefined} />
    ),
  },

  monthlyRentalIncome: {
    id: "rental_monthly_rental_income",
    label: "*Mo. Rental Income",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarCurrencyFieldDelayed
          required
          value={item.rental_monthly_rental_income}
          onChange={(rental_monthly_rental_income) =>
            onChange({ ...item, rental_monthly_rental_income })
          }
        />
      );
    },
  },
  monthlyExpenses: {
    id: "rental_monthly_expenses",
    label: "*Mo. Expenses",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarCurrencyFieldDelayed
          required
          value={item.rental_monthly_expenses}
          onChange={(rental_monthly_expenses) =>
            onChange({ ...item, rental_monthly_expenses })
          }
        />
      );
    },
  },
  monthlyDepreciation: {
    id: "rental_monthly_depreciation",
    label: "Mo. Depreciation",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarCurrencyFieldDelayed
          value={item.rental_monthly_depreciation}
          onChange={(rental_monthly_depreciation) =>
            onChange({ ...item, rental_monthly_depreciation })
          }
        />
      );
    },
  },
  simulatedInflAdjRent: {
    id: "rental_inflation_adj_rent",
    label: "*Infl. Adj. - Rent",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarFactorPercentFieldDelayed
          required
          value={item.rental_inflation_adj_rent}
          onChange={(rental_inflation_adj_rent) =>
            onChange({ ...item, rental_inflation_adj_rent })
          }
          minFactorValue={-0.08}
          maxFactorValue={0.08}
        />
      );
    },
  },
  simulatedInflAdjExpenses: {
    id: "rental_inflation_adj_expenses",
    label: "*Infl. Adj. - Expenses",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarFactorPercentFieldDelayed
          required
          value={item.rental_inflation_adj_expenses}
          onChange={(rental_inflation_adj_expenses) =>
            onChange({ ...item, rental_inflation_adj_expenses })
          }
          minFactorValue={-0.08}
          maxFactorValue={0.08}
        />
      );
    },
  },

  secondMortgage: {
    id: "refinance_data",
    label: "2nd Mortgage",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      return (
        <CarYesNoField
          required
          value={!!item.refinance_data}
          onChange={(value) => {
            if (value) {
              if (!item.refinance_data) {
                onChange(addMortgage(item));
              }
            } else {
              onChange({ ...item, refinance_data: null });
            }
          }}
        />
      );
    },
  },

  secondMortgageAmount: {
    id: "refinance_data.refinance_amount" as any,
    label: "*Amount",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      const mortgage = item.refinance_data;

      if (!mortgage) {
        throw Error("Mortgage is not assigned");
      }

      return (
        <CarCurrencyFieldDelayed
          required
          value={mortgage.refinance_amount}
          onChange={(refinance_amount) =>
            onChange(
              changeMortgage(item, {
                ...mortgage,
                refinance_amount: refinance_amount ?? 0,
              })
            )
          }
        />
      );
    },
  },
  secondMortgageStartDate: {
    id: "refinance_data.refinance_date" as any,
    label: "*Start Date",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      const mortgage = item.refinance_data;

      if (!mortgage) {
        throw Error("Mortgage is not assigned");
      }

      return (
        <CarDateExtraField
          required
          value={mortgage.refinance_date}
          onChange={(refinance_date) =>
            onChange(
              changeMortgage(item, {
                ...mortgage,
                refinance_date,
              })
            )
          }
        />
      );
    },
  },
  secondMortgageInterestRate: {
    id: "refinance_data.interest_rate" as any,
    label: "*Interest Rate",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      const mortgage = item.refinance_data;

      if (!mortgage) {
        throw Error("Mortgage is not assigned");
      }

      return (
        <CarFactorPercentFieldDelayed
          required
          minFactorValue={-0.08}
          maxFactorValue={0.08}
          value={mortgage.interest_rate}
          onChange={(interest_rate) =>
            onChange(
              changeMortgage(item, {
                ...mortgage,
                interest_rate,
              })
            )
          }
        />
      );
    },
  },
  secondMortgageLoadDuration: {
    id: "refinance_data.payment_plan" as any,
    label: "Loan Duration",
    fraction: 1,
    renderCell: ({ item, onChange }) => {
      const mortgage = item.refinance_data;

      if (!mortgage) {
        throw Error("Mortgage is not assigned");
      }

      return (
        <CarPaymentPlanKindField
          value={mortgage.payment_plan}
          onChange={(payment_plan) =>
            onChange(
              changeMortgage(item, {
                ...mortgage,
                payment_plan,
              })
            )
          }
        />
      );
    },
  },
};
