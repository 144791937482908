import { Snackbar } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./hooks";
import { hideToast, selectToast } from "./toastSlice";
import { SuccessToast } from "components/SuccessToast";
import { ErrorToast } from "components/ErrorToast";

export const Toast = React.memo(() => {
  const toast = useAppSelector(selectToast);
  const dispatch = useDispatch();
  const handleClose = (
    event?: React.SyntheticEvent<any> | Event,
    reason?: string
  ) => {
    if (reason !== "clickaway") {
      dispatch(hideToast());
    }
  };

  return (
    <Snackbar
      open={toast.open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={toast.anchorOrigin}
    >
      {toast.kind === "errorMessage" ? (
        <ErrorToast onClose={handleClose} title={toast.title ?? "Error"}>
          {toast.message}
        </ErrorToast>
      ) : (
        <SuccessToast onClose={handleClose} kind={toast.kind}>
          {toast.message}
        </SuccessToast>
      )}
    </Snackbar>
  );
});
