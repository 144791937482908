import { v4 } from "uuid";
import { useCallback, useEffect, useState } from "react";
import { IdObj, SectionState } from "types";
import { useAppSelector } from "app/hooks";
import { useDispatch } from "react-redux";
import { pendingActions } from "./pendingSlice";
import { useCallbackAsRef } from "app/useItemChangeCallback";

export interface IdProxyOptions<T extends IdObj> {
  name: string;
  items: Array<T>;
  handleCreate: (item: T) => Promise<{ data?: T; error?: unknown }>;
  handleChange: (item: T) => Promise<{ data?: T; error?: unknown }>;
  handleDelete: (item: T) => Promise<{ data?: unknown; error?: unknown }>;
  required?: Array<keyof T>;
  validate?: (item: T) => boolean;
  normalizeItem?: (item: T, oldItem?: T) => void;
}

type PendingData<T extends IdObj> = Array<T> | undefined;

export function usePendingProxy<T extends IdObj>(options: IdProxyOptions<T>) {
  const dispatch = useDispatch();

  const [lockedItems, setLockedItems] = useState<{ [key: string]: boolean }>(
    {}
  );

  const pendingCreate = useAppSelector(
    (state) => state.pending.create[options.name]
  ) as PendingData<T>;

  const setIsUpdating = (value: boolean) =>
    dispatch(pendingActions.setIsUpdating(value));

  const isPendingCreate = (id?: string) =>
    !!pendingCreate?.some((i) => i.id === id);
  const setPendingCreate = (item: T) =>
    dispatch(pendingActions.setPendingCreate({ key: options.name, item }));
  const removeFromPendingCreate = useCallback(
    (id?: string) =>
      dispatch(pendingActions.removePendingCreate({ key: options.name, id })),
    [options.name, dispatch]
  );

  const pendingChange = useAppSelector(
    (state) => state.pending.change[options.name]
  ) as PendingData<T>;

  const isPendingChange = (id?: string) =>
    !!pendingChange?.some((i) => i.id === id);
  const setPendingChange = (item: T) =>
    dispatch(pendingActions.setPendingChange({ key: options.name, item }));
  const removeFromPendingChange = useCallback(
    (id?: string) =>
      dispatch(pendingActions.removePendingChange({ key: options.name, id })),
    [options.name, dispatch]
  );

  const handleCreate = useCallbackAsRef(async (item: T) => {
    setIsUpdating(true);
    try {
      if (options.normalizeItem) {
        options.normalizeItem(
          item,
          items.find((i) => i.id === item.id)
        );
      }

      const newItem = {
        ...item,
        id: v4(),
        ui_key: v4(),
        created_at: new Date().toISOString(),
      };

      setPendingCreate(newItem);
      if (validate(newItem)) {
        const res = await options.handleCreate(newItem);
        if (res.data) {
          removeFromPendingCreate(newItem.id);
        }
        return res;
      }
    } finally {
      setIsUpdating(false);
    }
  });

  const validate = (item: T) => {
    if (options.required && !options.required.every((i) => !!item[i])) {
      return false;
    }
    if (options.validate) {
      return options.validate(item);
    }
    return true;
  };

  // this removes items in case when item has been deleted from remote, but still has pending copy.
  useEffect(() => {
    pendingChange
      ?.filter((i) => !options.items.some((ri) => ri.id === i.id))
      .forEach((i) => removeFromPendingChange(i.id));
  }, [options.items, pendingChange, removeFromPendingChange]);

  const items = [
    ...options.items.filter(
      (i) => !isPendingCreate(i.id) && !isPendingChange(i.id)
    ), // filtering out items that overlap with pending
    ...(pendingChange ?? []),
    ...(pendingCreate ?? []),
  ].sort((a, b) => (a.created_at ?? "").localeCompare(b.created_at ?? ""));

  const handleChange = useCallbackAsRef(async (item: T) => {
    setIsUpdating(true);
    try {
      if (lockedItems[item.id ?? ""]) {
        return;
      }

      setLockedItems((i) => ({ ...i, [item.id ?? ""]: true }));
      try {
        if (options.normalizeItem) {
          options.normalizeItem(
            item,
            items.find((i) => i.id === item.id)
          );
        }

        if (isPendingCreate(item.id)) {
          setPendingCreate(item);
          if (validate(item)) {
            const res = await options.handleCreate(item);
            if (res.data) {
              removeFromPendingCreate(item.id);
            }
            return res;
          }
          return;
        }

        if (isPendingChange(item.id)) {
          setPendingChange(item);
          if (validate(item)) {
            const res = await options.handleChange(item);
            if (res.data) {
              removeFromPendingChange(item.id);
            }
            return res;
          }
          return;
        }
        if (validate(item)) {
          return await options.handleChange(item);
        } else {
          setPendingChange(item);
        }
      } finally {
        setLockedItems((i) => ({ ...i, [item.id ?? ""]: false }));
      }
    } finally {
      setIsUpdating(false);
    }
  });

  const handleDelete = useCallbackAsRef(async (item: T) => {
    setIsUpdating(true);
    try {
      if (lockedItems[item.id ?? ""]) {
        return;
      }

      setLockedItems((i) => ({ ...i, [item.id ?? ""]: true }));
      try {
        if (isPendingCreate(item.id)) {
          removeFromPendingCreate(item.id);
        } else {
          removeFromPendingChange(item.id);
          return await options.handleDelete(item);
        }
      } finally {
        setLockedItems((i) => ({ ...i, [item.id ?? ""]: false }));
      }
    } finally {
      setIsUpdating(false);
    }
  });

  const getState = () => {
    if (!!pendingCreate?.length || !!pendingChange?.length) {
      return SectionState.workInProgress;
    }
    if (items.length === 0) {
      return SectionState.notTouched;
    }
    return SectionState.complete;
  };

  return {
    items,
    handleCreate,
    handleChange,
    handleDelete,
    state: getState(),
  };
}
