import {
  useQuestionnaireClientsDestroyMutation,
  useQuestionnaireClientsRetrieveQuery,
  useQuestionnaireClientsUpdateMutation,
  useQuestionnairePlanDestroyMutation,
  useQuestionnairePlanPartialUpdateMutation,
  useQuestionnairePlanRelationshipsUpdateMutation,
  useQuestionnairePlanCopyUpdateMutation,
} from "api/carApi.generated";
import { CarClient, CarClientPlan } from "types";
import { useRelationTypes } from "app/useRelationTypes";
import { formatDate, openInNewTab } from "utils";
import { PlanParams, useClientId } from "app/usePlanId";
import { useNavigate } from "react-router";
import { financialPlanningPages, rootPages } from "route.const";
import { useDialog } from "app/useDialog";
import { DeleteItemDialog } from "components/DeleteItemDialog";
import { RenameDialog } from "components/RenameDialog";
import { useSuccessToast } from "app/useSuccessToast";
import { planStatusItems } from "const";
import { usePlanTypes } from "app/usePlanTypes";
import { pendingActions } from "features/general/pendingSlice";
import { useAppDispatch } from "app/hooks";

interface ClientRelationItem {
  name: string;
  relation: string;
  dob: string;
}

export const useClientSummary = () => {
  const dispatch = useAppDispatch();
  const dialog = useDialog();
  const clientId = useClientId();
  if (!clientId) {
    throw new Error("clientId is empty");
  }

  const relationTypes = useRelationTypes();
  const planTypes = usePlanTypes();

  const toast = useSuccessToast();

  const { data, isFetching: clientIsLoading } =
    useQuestionnaireClientsRetrieveQuery({
      id: clientId,
    });

  const [updateClientReq, { isLoading: updateClientIsLoading }] =
    useQuestionnaireClientsUpdateMutation();
  const [deleteClientReq, { isLoading: deleteClientIsLoading }] =
    useQuestionnaireClientsDestroyMutation();

  const [updateRelationshipReq, { isLoading: updateRelationshipIsLoading }] =
    useQuestionnairePlanRelationshipsUpdateMutation();

  const [updatePlanReq, { isLoading: updatePlanIsLoading }] =
    useQuestionnairePlanPartialUpdateMutation();
  const [deletePlanReq, { isLoading: deletePlanIsLoading }] =
    useQuestionnairePlanDestroyMutation();

  const [copyPlanReq, { isLoading: copyPlanIsLoading }] =
    useQuestionnairePlanCopyUpdateMutation();

  const isLoading =
    relationTypes.isLoading ||
    planTypes.isLoading ||
    clientIsLoading ||
    updateClientIsLoading ||
    deleteClientIsLoading ||
    copyPlanIsLoading ||
    deletePlanIsLoading ||
    updateRelationshipIsLoading ||
    updatePlanIsLoading;

  const navigate = useNavigate();

  const plans = [...(data?.plans ?? [])].sort((a, b) => {
    const statusIndex =
      planStatusItems.findIndex((i) => i.value === a.status) -
      planStatusItems.findIndex((i) => i.value === b.status);
    return (
      statusIndex || (b.created_at ?? "").localeCompare(a.created_at ?? "")
    );
  });

  const activePlanId =
    plans.find((i) => i.status === "DRAFT")?.id ??
    plans.find((i) => i.status === "PUBLISHED")?.id;

  const publishedPlan = plans.find((i) => i.status === "PUBLISHED");

  const publishedPlanParams: PlanParams | undefined =
    publishedPlan?.published_data
      ? {
          planId: publishedPlan.id ?? "",
          planTypeId: publishedPlan.published_data.published_plan_type_id,
          isInflated: true, // todo clarify
        }
      : undefined;

  const activeRelationships = data?.active_relationships ?? [];
  const primaryItem = activeRelationships.find(relationTypes.isPrimary);
  const secondaryItem = activeRelationships.find(relationTypes.isSecondary);

  const familyName = primaryItem?.last_name;

  const clientFullName = `${primaryItem?.first_name ?? ""} ${
    primaryItem?.last_name ?? ""
  }`.trim();

  const spouseFullName = `${secondaryItem?.first_name ?? ""} ${
    secondaryItem?.last_name ?? ""
  }`.trim();

  const updateClient = async (client: CarClient) => {
    const response = await updateClientReq({
      id: client.id ?? "",
      client: client as Required<CarClient>,
    });
    return "data" in response;
  };

  const renameClient = () => {
    if (!primaryItem) {
      return;
    }

    dialog(RenameDialog, {
      label: "Client Name",
      value: clientFullName,
      applyLabel: "Rename",
      onRename: async (value: string) => {
        if (!primaryItem || !activePlanId) {
          return false;
        }

        const names = value.split(" ");
        const first_name = names.shift()?.trim() || "";
        const last_name = names.join(" ").trim();

        const result = await updateRelationshipReq({
          planId: activePlanId,
          id: primaryItem.id ?? "",
          clientPlanRelationship: {
            ...primaryItem,
            first_name,
            last_name,
          },
        });

        return "data" in result;
      },
    });
  };

  const deleteClient = async () => {
    if (!clientId) {
      return;
    }

    if (
      !(await dialog(DeleteItemDialog, {
        itemName: clientFullName,
        itemTypeName: "client",
      }))
    ) {
      return;
    }

    const result = await deleteClientReq({ id: clientId });

    if ("data" in result) {
      navigate(financialPlanningPages.clientListUrl);

      toast({
        kind: "success",
        message: "Success! This client has been deleted",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
  };

  const relationItems = activeRelationships
    .filter((i) => !relationTypes.isPrimary(i) && !relationTypes.isSecondary(i))
    .map<ClientRelationItem>((i) => ({
      name: `${i.first_name}`,
      relation: (
        relationTypes.items.find((rt) => rt.id === i.relation_type)?.label ?? ""
      ).toLocaleLowerCase(),
      dob: formatDate(i.date_of_birth || ""),
    }));

  const navigateToPlan = ({
    planId,
    isDraft,
    planTypeId,
  }: {
    planId?: string;
    isDraft: boolean;
    planTypeId?: string;
  }) => {
    if (!planId) {
      return;
    }

    if (isDraft) {
      dispatch(pendingActions.clearPendingChanges());
      navigate(financialPlanningPages.getPlanInputUrl(clientId, planId));
    } else {
      if (planTypeId) {
        navigate(
          financialPlanningPages.getWorkbenchUrl(clientId, planId, planTypeId),
        );
      }
    }
  };

  const renamePlan = async (clientPlan: CarClientPlan) => {
    await dialog(RenameDialog, {
      label: "Draft Plan Name",
      value: clientPlan.name ?? "",
      applyLabel: "Save",
      onRename: async (name) => {
        const result = await updatePlanReq({
          id: clientPlan.id ?? "",
          patchedClientPlan: {
            name: name,
          },
        });
        return "data" in result;
      },
    });
  };

  const deletePlan = async (clientPlan: CarClientPlan) => {
    if (
      await dialog(DeleteItemDialog, {
        itemName: clientPlan.name ?? "",
        itemTypeName: "client plan",
      })
    ) {
      await deletePlanReq({
        id: clientPlan.id ?? "",
      });
    }
  };

  const viewPlanPdf = (planId?: string) => {
    const planTypeId = plans.find((i) => i.id === planId)?.published_data
      ?.published_plan_type_id;

    if (planId && planTypeId) {
      openInNewTab(
        rootPages.getFinancialPlanningReportUrl({
          clientId,
          planId,
          planTypeId,
        }),
      );
    }
  };

  const viewPlanActionReportPdf = (planId?: string) => {
    const planTypeId = plans.find((i) => i.id === planId)?.published_data
      ?.published_plan_type_id;

    if (planId && planTypeId) {
      openInNewTab(
        rootPages.getActionReportUrl({ clientId, planId, planTypeId }),
      );
    }
  };

  const addDraftPlan = async () => {
    if (!activePlanId) {
      return;
    }

    let newPlanId: string | undefined;

    await dialog(RenameDialog, {
      label: "Draft Plan Name",
      value: "",
      applyLabel: "Save",
      onRename: async (name) => {
        const planResponse = await copyPlanReq({
          id: activePlanId,
          clientPlanCopyRequest: {
            name,
            client: clientId,
          },
        });

        if (!("data" in planResponse)) {
          return false;
        }

        const planId = planResponse.data.id ?? "";
        newPlanId = planId;

        return true;
      },
    });

    navigateToPlan({ planId: newPlanId, isDraft: true });
  };

  return {
    isLoading,
    client: data,
    plans,
    updateClient,
    deleteClient,
    renameClient,
    publishedPlanParams,
    familyName,
    clientFullName,
    spouseFullName,
    relationItems,
    addDraftPlan,
    deletePlan,
    renamePlan,
    navigateToPlan,
    viewPlanPdf,
    viewPlanActionReportPdf,
    getPlanTypeLabel: planTypes.getPlanTypeLabel,
  };
};

export type UseClientSummary = ReturnType<typeof useClientSummary>;
