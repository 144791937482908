import { usePortfolioPortfolioGroupPartialUpdateMutation } from "api/carApi.generated";
import { usePortfolioGroupVersionData } from "../usePortfolioGroupVersionData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { useLocalStorage } from "features/general/useLocalStorage";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { usePortfolioGroupVersionStatTableData } from "../usePortfolioGroupVersionStatTableData";
import { useAssetClassStatistics } from "./useAssetClassStatistics";

export const useEditPortfolioGroupPublish = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { publishedVersionId, portfolioGroupVersionId } = groupInfo;

  const [hideZeroValues, setHideZeroValues] = useLocalStorage<boolean>(
    "EditPortfolioGroupPublish_hideZeroValues",
    true,
  );

  const [isTreeView, setIsTreeView] = useLocalStorage<boolean>(
    "EditPortfolioGroupEditCompare_isTreeView",
    true,
  );

  const [showBenchmark, setShowBenchmark] = useLocalStorage<boolean>(
    "EditPortfolioGroupEditCompare_showBenchmark",
    true,
  );

  const data = usePortfolioGroupVersionData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    show: {
      existing: !showBenchmark,
      selected: true,
      benchmark: showBenchmark,
      draft: false,
      optimizationIds: [],
    },
    isBenchmarkEditable: false,
  });

  const versionStatTableData = usePortfolioGroupVersionStatTableData({
    bands: data.tableData.bands,
    skip: data.assetAllocationIsLoading,
  });

  const assetClassStatistics = useAssetClassStatistics({
    simulationGroupId: groupInfo.simulationGroupId,
  });

  const [updatePortfolioGroup] =
    usePortfolioPortfolioGroupPartialUpdateMutation();

  const canPublish = () =>
    !data.tableData.bands.some((b) => b.columns.some((c) => c.hasError));

  const handlePublish = () => {
    if (!canPublish()) {
      throw new Error("Cannot publish portfolio group");
    }

    return updatePortfolioGroup({
      id: portfolioGroupId,
      patchedPortfolioGroup: {
        do_publish: true,
      },
    });
  };

  return {
    portfolioGroupVersionId,
    portfolioGroupId,

    title: groupInfo.title,
    simulationSuffix: groupInfo.getSimulationSuffix(true),
    simulationGroupName: groupInfo.getSimulationGroupName(),

    isError: data.isError,
    tableData: data.tableData,
    statTableData: versionStatTableData.statTableData,

    assetClassStatisticsItems: assetClassStatistics.items,

    isLoading:
      data.isLoading ||
      versionStatTableData.isLoading ||
      groupInfo.isLoading ||
      assetClassStatistics.isLoading,

    handlePublish,
    canPublish,

    hideZeroValues,
    setHideZeroValues,

    isTreeView,
    setIsTreeView,

    showBenchmark,
    setShowBenchmark,
  };
};
