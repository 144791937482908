import React, { useCallback, useEffect, useRef } from "react";
import { Box, Collapse, Typography, styled } from "@mui/material";
import { AddRowButton } from "components/Buttons";
import { CarPercentFieldDelayed } from "components/NumberField";
import { CarTableRoot } from "components/Table";
import { useGoal } from "features/goals/useGoal";
import { formatCurrency } from "utils";
import { CarDisclosure } from "components/Disclosure";
import { TablePlaceHolder } from "components/PlaceHolder";
import { GoalRow } from "./GoalRow";
import {
  DragDropContext,
  Draggable,
  DraggableChildrenFn,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

const StyledPercent = styled(CarPercentFieldDelayed)(({ theme }) => ({
  maxWidth: 100,
  ".MuiInputLabel-root": {
    ...theme.typography.h6SSemiBold,
    alignSelf: "center",
    left: "unset",
  },

  ".MuiOutlinedInput-root": {
    backgroundColor: theme.palette.white,
  },
}));

export const GoalsQuick = React.memo(() => {
  const data = useGoal();
  const isGoalBeenAdded = useRef(false);

  const { addSingleGoal } = data;

  useEffect(() => {
    if (!isGoalBeenAdded.current) {
      isGoalBeenAdded.current = addSingleGoal();
    }
  }, [addSingleGoal]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    data.handleMove(result.source.index, result.destination.index);
  };

  const canDelete = data.items.length > 1;

  const handleCanDelete = useCallback(() => canDelete, [canDelete]);

  const renderChild: DraggableChildrenFn = (provided, snapshot, rubric) => {
    const index = rubric.source.index;
    const item = data.items[index];

    return (
      <Box
        {...provided.draggableProps}
        ref={provided.innerRef}
        sx={{
          backgroundColor: snapshot.isDragging ? "gray1" : "",
          borderRadius: "3px",
          mb: snapshot.isDragging ? 0 : 7,
          boxShadow: snapshot.isDragging
            ? "0px 0px 3px rgba(128, 128, 128, 0.2)"
            : "none",
        }}
        style={{
          ...provided.draggableProps.style,
        }}
      >
        <GoalRow
          item={item}
          dragHandleProps={provided.dragHandleProps}
          onChange={data.handleChange}
          onDelete={data.handleDelete}
          onCanDelete={handleCanDelete}
          pendoPrefix="goals"
        />
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ display: "flex", gap: 14 }}>
        <StyledPercent
          minValue={1}
          maxValue={100}
          label="Needs"
          value={data.clientPlanData?.needs_percentile}
          onChange={(needs_percentile) =>
            data.handleClientPlanChange({
              ...data.clientPlanData,
              needs_percentile,
            })
          }
        />
        <StyledPercent
          label="Wants"
          minValue={1}
          maxValue={100}
          value={data.clientPlanData?.wants_percentile}
          onChange={(wants_percentile) =>
            data.handleClientPlanChange({
              ...data.clientPlanData,
              wants_percentile,
            })
          }
        />
        <StyledPercent
          label="Dreams"
          minValue={1}
          maxValue={100}
          value={data.clientPlanData?.dreams_percentile}
          onChange={(dreams_percentile) =>
            data.handleClientPlanChange({
              ...data.clientPlanData,
              dreams_percentile,
            })
          }
        />
      </Box>
      <CarDisclosure sx={{ my: 7 }}>
        Inputs drive the return assumptions for the simulation. A 90% value will
        drive calculations based on the lowest 10% market returns available for
        the simulation. A 80% value will drive calculations based on the lowest
        20% market returns available for the simulation. Please see our
        disclosures for more information.
      </CarDisclosure>
      <TablePlaceHolder isLoading={data.isLoading}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list" renderClone={renderChild}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <CarTableRoot>
                  {data.items.map((i, idx) => (
                    <Collapse key={i.ui_key}>
                      <Draggable
                        draggableId={i.id ?? ""}
                        index={idx}
                        children={renderChild}
                      />
                    </Collapse>
                  ))}
                </CarTableRoot>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </TablePlaceHolder>
      <AddRowButton
        sx={{ alignSelf: "flex-start", mt: -5 }}
        onClick={() => data.addRow("GENERIC")}
      >
        Add Goal
      </AddRowButton>
      <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
        <Typography
          variant="h5SBold"
          sx={{ mt: 3, ml: 1 }}
        >{`Total Annual Goal Amount: ${formatCurrency(
          data.totalAmount
        )}`}</Typography>
        <Typography
          variant="h5SBold"
          sx={{ mt: 3, ml: 1 }}
        >{`Total Lifetime Goal Amount: ${formatCurrency(
          data.totalAmountAcrossPlan
        )}`}</Typography>
      </Box>
    </Box>
  );
});
