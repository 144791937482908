import clsx from "clsx";
import { Box, BoxProps, Typography, styled } from "@mui/material";
import {
  CarIconRename,
  CarIconDelete,
  CarIconLoad,
  CarIconCreateTemplate,
  CarIconAnalyze,
} from "icons";
import { CarMenuButton } from "components/MenuButton";
import { formatDate, isOddEven } from "utils";
import { TablePlaceHolder } from "components/PlaceHolder";
import { JobStatus } from "./JobStatus";
import { usePortfolioGroupOptimizerRuns } from "./usePortfolioGroupOptimizerRuns";
import { CarTooltip } from "components/Tooltip";
import SimpleBar from "simplebar-react";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";

const Header = (props: BoxProps) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "1fr 0.6fr 0.7fr 0.6fr",
      mb: 0.5,
      ...props.sx,
    }}
  >
    <Typography
      variant="par01Regular"
      textAlign="start"
      sx={{ pl: 2, fontSize: "13px", color: "gray6" }}
    >
      Name
    </Typography>
    <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Status
    </Typography>
    <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Created on
    </Typography>
    <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Actions
    </Typography>
  </Box>
);
const Row = styled(Box)(({ theme }) => ({
  height: 62,
  border: "solid 1px",
  borderColor: theme.palette.gray6,
  backgroundColor: theme.palette.white,
  "&.odd": {
    backgroundColor: theme.palette.gray2,
  },
  borderRadius: "5px",
  display: "grid",
  gridTemplateColumns: "1fr 0.6fr 0.7fr 0.6fr",
  alignItems: "center",
  width: "calc(100% - 10px)",
  marginBottom: theme.spacing(1.5),
  "&:last-of-type": {
    marginBottom: 0,
  },
}));

export const PortfolioGroupOptimizerRuns = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const data = usePortfolioGroupOptimizerRuns({ portfolioGroupId });

  if (!data.isLoading && data.items.length === 0) {
    return (
      <Box
        sx={{
          flex: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 311,
        }}
      >
        <Typography variant="par01Regular" sx={{ color: "gray6" }}>
          No optimizations have been created yet
        </Typography>
      </Box>
    );
  }

  return (
    <TablePlaceHolder
      isLoading={data.isLoading}
      sx={{ height: 311, flex: "auto" }}
      rows={5}
    >
      <Box
        sx={{
          flex: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />
        <Box
          sx={{
            position: "relative",
            flex: "auto",
            height: 288,
          }}
        >
          <SimpleBar
            autoHide={false}
            forceVisible="y"
            style={{
              position: "absolute",
              width: "calc(100% + 18px)",
              height: "100%",
              paddingRight: 8,
            }}
          >
            {data.items.map((row, idx) => {
              const jobCount = row.jobs.length;
              const completedCount = row.jobs.filter(
                (i) => i.isCompleted,
              ).length;
              const failedCount = row.jobs.filter((i) => i.isError).length;

              const renderTooltip = () => {
                return failedCount ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      maxWidth: 170,
                    }}
                  >
                    <Typography
                      variant="par01Regular"
                      sx={{ fontSize: 15, fontWeight: 600 }}
                    >
                      Failed
                    </Typography>
                    <Typography
                      variant="par01Regular"
                      sx={{ fontSize: 13, fontWeight: 400 }}
                    >
                      {`${failedCount} out of ${jobCount} optimization could not be completed.`}
                    </Typography>
                  </Box>
                ) : (
                  <></>
                );
              };

              let status = (
                <Box>
                  <JobStatus
                    jobCount={jobCount}
                    completedCount={completedCount}
                    failedCount={failedCount}
                  />
                </Box>
              );

              if (failedCount) {
                status = (
                  <CarTooltip title={renderTooltip()} disableInteractive>
                    {status}
                  </CarTooltip>
                );
              }

              return (
                <Row
                  key={row.id}
                  className={clsx({
                    odd: isOddEven(idx),
                  })}
                >
                  <Typography
                    variant="par02Regular"
                    sx={{ pl: 2, textAlign: "start" }}
                  >
                    {row.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {status}
                  </Box>
                  <Typography
                    variant="par02Regular"
                    sx={{ textAlign: "center" }}
                  >
                    {formatDate(row.createdOn)}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CarMenuButton
                      items={[
                        {
                          icon: <CarIconAnalyze />,
                          label: "Analyze",
                          onClick: () => data.handleAnalyze(row.id ?? ""),
                        },
                        {
                          icon: <CarIconLoad />,
                          label: "Load",
                          onClick: () => data.handleLoad(row),
                        },
                        {
                          icon: <CarIconCreateTemplate />,
                          label: "Create Template",
                          onClick: () => data.handleCreateTemplate(row),
                        },

                        {
                          label: "Rename",
                          icon: <CarIconRename />,
                          onClick: () => data.handleRename(row),
                        },
                        {
                          label: "Delete",
                          icon: <CarIconDelete />,
                          onClick: () => data.handleDelete(row),
                        },
                      ]}
                      position="left"
                    />
                  </Box>
                </Row>
              );
            })}
          </SimpleBar>
        </Box>
      </Box>
    </TablePlaceHolder>
  );
};
