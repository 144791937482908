import { Box } from "@mui/system";
import { Dialog } from "@mui/material";
import { CarButton } from "components/Buttons";
import { CarDisclosureContentCode, useDisclosures } from "app/useDisclosures";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarMarkdown } from "components/Markdown";
import { CarScrollBox } from "components/ScrollBox";

export interface TermsOfUseDialogProps {
  onApply: () => void;
  onCancel: () => void;
}

export const TermsOfUseDialog = (props: TermsOfUseDialogProps) => {
  const disclosures = useDisclosures({ skipOverrides: true });
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "6px",
          position: "relative",
          height: "95vh",
          p: 8,
          pb: 4,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
      maxWidth="xl"
      open={true}
      onClose={(e, reason) => props.onCancel()}
    >
      <TablePlaceHolder
        rows={10}
        isLoading={disclosures.isLoading}
        sx={{ width: 1024 }}
      >
        <CarScrollBox sx={{ flex: "auto", width: 1024, minHeight: 0 }}>
          <CarMarkdown
            sx={{
              width: 1024,
              "& > *": {
                color: "black !important",
              },
            }}
          >
            {disclosures.getContent(
              CarDisclosureContentCode.TERMS_OF_USE_MAIN_part_10
            )}
          </CarMarkdown>
        </CarScrollBox>
      </TablePlaceHolder>
      <Box sx={{ m: "auto" }} />
      <CarButton
        sx={{ mt: 4, flex: "none", width: 200, alignSelf: "center" }}
        onClick={props.onCancel}
        variant="outlined"
      >
        Back
      </CarButton>
    </Dialog>
  );
};
