import {
  AssetClass,
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { useLocalStorage } from "features/general/useLocalStorage";
import { useEffect, useMemo } from "react";

interface UseSimulationAssetClassSelectorParams {
  storageKey?: string;
  simulationJobId?: string;
  isCashAndFixedIncomeOnly?: boolean;
  includeInflationItem?: boolean;
}

const cashFixedIncomeFilter = (ac: AssetClass) => ac.isCash || ac.isFixedIncome;

export const useSimulationAssetClassSelector = (
  params: UseSimulationAssetClassSelectorParams,
) => {
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: params.simulationJobId,
    filter: params.isCashAndFixedIncomeOnly ? cashFixedIncomeFilter : undefined,
    includeInflationItem: params.includeInflationItem,
  });

  const [value, setValue] = useLocalStorage<string | undefined>(
    params.storageKey,
    undefined,
  );

  useEffect(() => {
    const acItems = assetClasses.items.filter((ac) => !ac.isDisabled);

    if (!assetClasses.isLoading && !acItems.some((i) => i.code === value)) {
      setValue(acItems.at(0)?.code);
    }
  }, [assetClasses, value, setValue]);

  return useMemo(() => {
    const selectedItem = assetClasses.items.find((i) => i.code === value);

    return {
      isLoading: assetClasses.isLoading,
      selectedItem,
      items: assetClasses.items,
      valueField: "code" as "code",
      value,
      onChange: (newValue: string) => setValue(newValue),
    };
  }, [assetClasses, value, setValue]);
};

export type UseSimulationAssetClassSelector = ReturnType<
  typeof useSimulationAssetClassSelector
>;
