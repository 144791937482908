import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  BoxProps,
  SvgIcon,
  SvgIconProps,
  Typography,
} from "@mui/material";
import { formatNumber, formatPercentFactor, isDefined, isOddEven } from "utils";
import { CarRadio } from "components/Radio";
import {
  CarAssetClassAnalysisItem,
  CarAssetClassAnalysisItemKey,
} from "./useAssetClassSummaryStat";
import { useDialog } from "app/useDialog";
import { DftChartDialog } from "pages/assetClassAnalysis/DftChartDialog";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarTooltipBox } from "components/TooltipBox";
import { chartsColor } from "theme";

interface IxyStatState {
  xStatId: CarAssetClassAnalysisItemKey;
  onXStatIdChange: (value: CarAssetClassAnalysisItemKey) => void;
  yStatId: CarAssetClassAnalysisItemKey;
  onYStatIdChange: (value: CarAssetClassAnalysisItemKey) => void;
}

interface Column {
  id: CarAssetClassAnalysisItemKey;
  label: string;
  formatValue: (value?: number | null) => string;
}

const columnFormatPercent = (value?: number | null) =>
  isDefined(value)
    ? formatPercentFactor(value, {
        decimalPlaces: 1,
        forceShowDecimals: true,
      })
    : "-";

const columnFormatAlpha = (value?: number | null) =>
  isDefined(value)
    ? formatNumber(value, {
        decimalPlaces: 4,
        forceShowDecimals: true,
      })
    : "-";

const columnFormatNumber = (value?: number | null) =>
  isDefined(value)
    ? formatNumber(value, {
        decimalPlaces: 1,
        forceShowDecimals: true,
      })
    : "-";

export const getStaticColumns = (isReal?: boolean): Column[] => [
  isReal
    ? {
        id: "realStaticExpectedReturn",
        label: "Static Expected Return (Real)",
        formatValue: columnFormatPercent,
      }
    : {
        id: "nominalStaticExpectedReturn",
        label: "Static Expected Return (Nominal)",
        formatValue: columnFormatPercent,
      },
  {
    id: "volatility",
    label: "Volatility",
    formatValue: columnFormatPercent,
  },
];

export const getPriceDrivenColumns = (isReal?: boolean): Column[] => [
  isReal
    ? {
        id: "realPriceDrivenExpectedReturn",
        label: "Price-Driven Expected Return (Real)",
        formatValue: columnFormatPercent,
      }
    : {
        id: "nominalPriceDrivenExpectedReturn",
        label: "Price-Driven Expected Return (Nominal)",
        formatValue: columnFormatPercent,
      },
  {
    id: "volatility",
    label: "Volatility",
    formatValue: columnFormatPercent,
  },
];

export const getOverUndervaluedColumns = (isReal?: boolean): Column[] => [
  isReal
    ? {
        id: "realPriceDrivenExpectedReturn",
        label: "Price-Driven Expected Return (Real)",
        formatValue: columnFormatPercent,
      }
    : {
        id: "nominalPriceDrivenExpectedReturn",
        label: "Price-Driven Expected Return (Nominal)",
        formatValue: columnFormatPercent,
      },
  {
    id: "dft",
    label: "Distance From Trend / Current Yield",
    formatValue: columnFormatPercent,
  },
  {
    id: "volatility",
    label: "Volatility",
    formatValue: columnFormatPercent,
  },
];

export const getStaticVsPriceDrivenColumns = (isReal?: boolean): Column[] => [
  isReal
    ? {
        id: "realStaticExpectedReturn",
        label: "Static Expected Return (Real)",
        formatValue: columnFormatPercent,
      }
    : {
        id: "nominalStaticExpectedReturn",
        label: "Static Expected Return (Nominal)",
        formatValue: columnFormatPercent,
      },
  isReal
    ? {
        id: "realPriceDrivenExpectedReturn",
        label: "Price-Driven Expected Return (Real)",
        formatValue: columnFormatPercent,
      }
    : {
        id: "nominalPriceDrivenExpectedReturn",
        label: "Price-Driven Expected Return (Nominal)",
        formatValue: columnFormatPercent,
      },
  {
    id: "volatility",
    label: "Volatility",
    formatValue: columnFormatPercent,
  },
];

export const alphaVsBetaColumns: Column[] = [
  {
    id: "alpha",
    label: "Alpha",
    formatValue: columnFormatAlpha,
  },
  {
    id: "beta",
    label: "Beta",
    formatValue: columnFormatAlpha,
  },
];

export const statisticsColumns: Column[] = [
  {
    id: "fixedIncomeDuration",
    label: "Fixed Income Duration",
    formatValue: columnFormatNumber,
  },
  {
    id: "fixedIncomeConvexity",
    label: "Fixed Income Convexity",
    formatValue: columnFormatNumber,
  },
  {
    id: "currentEquityDividendYield",
    label: "Current Equity Dividend Yield",
    formatValue: columnFormatPercent,
  },
  {
    id: "currentFixedIncomeYield",
    label: "Current Fixed Income Yield",
    formatValue: columnFormatPercent,
  },
];

export const allColumns: Column[] = [
  {
    id: "dft",
    label: "DFT/Yield",
    formatValue: columnFormatPercent,
  },
  {
    id: "realStaticExpectedReturn",
    label: "Real Static E(R)",
    formatValue: columnFormatPercent,
  },
  {
    id: "realPriceDrivenExpectedReturn",
    label: "Real Price Driven E(R)",
    formatValue: columnFormatPercent,
  },
  {
    id: "nominalStaticExpectedReturn",
    label: "Nominal Static E(R)",
    formatValue: columnFormatPercent,
  },
  {
    id: "nominalPriceDrivenExpectedReturn",
    label: "Nominal Price Driven E(R)",
    formatValue: columnFormatPercent,
  },
  {
    id: "volatility",
    label: "Volatility",
    formatValue: columnFormatPercent,
  },
  {
    id: "alpha",
    label: "Alpha",
    formatValue: columnFormatAlpha,
  },
  {
    id: "beta",
    label: "Beta",
    formatValue: columnFormatAlpha,
  },
];

export const getAssetClassAnalysisLabel = (id: string) =>
  allColumns.find((i) => i.id === id)?.label ?? "";

export const getAssetClassAnalysisFormatValue =
  (id: string) => (value?: number | null) =>
    allColumns.find((i) => i.id === id)?.formatValue(value) ?? "-";

const SortIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="11" viewBox="0 0 8 11">
    <path
      d="M8 6.72222L4 11L-1.86988e-07 6.72222L8 6.72222ZM8 4.27778L-2.93838e-07 4.27778L4 -1.74846e-07L8 4.27778Z"
      fill="currentColor"
    />
  </SvgIcon>
);

interface LabelHeaderProps {
  sx?: BoxProps["sx"];
  label?: string;
  showSelector?: boolean;
}

const LabelHeader = (props: LabelHeaderProps) => {
  return (
    <Box
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "table.background.header",
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
          },
        },
        ...props.sx,
      }}
    >
      <Box
        sx={{
          height: 80,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.label && (
          <Typography variant="h6SBold">{props.label}</Typography>
        )}
      </Box>
      {props.showSelector && (
        <Box
          sx={{
            flex: "auto",
            backgroundColor: "white",
          }}
        />
      )}
    </Box>
  );
};

const getGridTemplateColumns = (columnsCount: number) =>
  `1.5fr repeat(${columnsCount}, 1fr)`;

interface ModuleHeaderProps extends IxyStatState {
  sx?: BoxProps["sx"];
  id: CarAssetClassAnalysisItemKey;
  label: string;
  showSelector?: boolean;
  sortId?: CarAssetClassAnalysisItemKey;
  onSort: (id: CarAssetClassAnalysisItemKey) => void;
}

const ModuleHeader = (props: ModuleHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={ref}
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        backgroundColor: "table.background.header",
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
          },
        },
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          px: 1,
          py: 2,
          height: 80,
          width: "100%",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => props.onSort(props.id)}
      >
        <Typography variant="par02Regular">{props.label}</Typography>
        <CarTooltipBox
          sx={{ position: "absolute", right: 0, top: 0 }}
          className={pendoClasses.assetClassMonthlyReport(props.id)}
        />
        <SortIcon
          sx={{
            ml: 0.3,
            fontSize: "0.8em",
            color: props.sortId === props.id ? "caravelOrangePrimary" : "gray7",
          }}
        />
      </Box>
      {props.showSelector && (
        <Box
          sx={{
            flex: "auto",
            display: "grid",
            gridTemplateColumns: `repeat(2, 1fr)`,
            "& > *": {
              backgroundColor: "white",
              borderStyle: "solid",
              borderColor: "table.border",
              borderWidth: 0,
              borderLeftWidth: 1,
              "&:first-of-type": {
                borderLeftWidth: 0,
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              py: 1,
            },
          }}
        >
          <Box>
            <Typography variant="h6SBold">X</Typography>
            <CarRadio
              checked={props.xStatId === props.id}
              onChange={() => props.onXStatIdChange(props.id)}
            />
          </Box>
          <Box>
            <Typography variant="h6SBold">Y</Typography>
            <CarRadio
              checked={props.yStatId === props.id}
              onChange={() => props.onYStatIdChange(props.id)}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

interface RowProps {
  sx?: BoxProps["sx"];
  displayColumns: Column[];
  item: CarAssetClassAnalysisItem;
  isOdd?: boolean;
  isLastRow: boolean;
  onShowChart: () => void;
}

const Row = (props: RowProps) => {
  const hasChart = !!props.item.modelChartData;

  return (
    <Box
      sx={{
        display: "grid",
        minHeight: 60,
        gridTemplateColumns: "1fr",
        backgroundColor: props.isOdd
          ? "table.background.even"
          : "table.background.odd",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(
            props.displayColumns.length,
          ),
        }}
      >
        <Box
          sx={{
            marginTop: "-1px",
            marginLeft: "-1px",
            border: 1,
            borderColor: "table.border",
            p: 1.5,
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            overflow: "hidden",
            borderBottomLeftRadius: props.isLastRow ? "5px" : undefined,
          }}
        >
          <Box
            sx={{
              width: 20,
              height: 20,
              borderRadius: "4px",
              border: "1px solid",
              borderColor: "gray6",
              backgroundColor: props.item.color,
              flexShrink: 0,
              mr: 2,
            }}
          />
          <Typography
            variant="par02Regular"
            sx={{
              "&.MuiTypography-root": {
                color: hasChart ? chartsColor.blue : undefined,
              },
              textDecoration: hasChart ? "underline" : undefined,
              cursor: hasChart ? "pointer" : undefined,
            }}
            onClick={props.onShowChart}
          >
            {props.item.name}
          </Typography>
          <CarTooltipBox
            sx={{ ml: "auto", mr: -1.25 }}
            className={pendoClasses.assetClassMonthlyReportAC(props.item.code)}
          />
        </Box>

        {props.displayColumns.map((col, idx, arr) => {
          const isLastBand = idx === arr.length - 1;
          const value = props.item[col.id];
          return (
            <Box
              key={col.id}
              sx={{
                marginTop: "-1px",
                marginLeft: "-1px",
                border: 1,
                borderColor: "table.border",
                borderBottomRightRadius:
                  props.isLastRow && isLastBand ? "5px" : undefined,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Typography variant="par02Regular">
                {col.formatValue(value)}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export interface AssetClassAnalysisTableProps extends IxyStatState {
  sx?: BoxProps["sx"];
  displayColumns: Column[];
  showSelector?: boolean;
  items: CarAssetClassAnalysisItem[];
}

interface GridSort {
  id: CarAssetClassAnalysisItemKey;
  direction: "asc" | "desc";
}

export const AssetClassAnalysisTable = ({
  sx,
  items: rows,
  displayColumns,
  showSelector,
  ...xyStatState
}: AssetClassAnalysisTableProps) => {
  const [sortColumn, setSortColumn] = useState<GridSort | undefined>();
  const dialog = useDialog();

  const handleSort = (id: CarAssetClassAnalysisItemKey) => {
    let direction: "asc" | "desc" = "asc";

    if (sortColumn?.id === id) {
      direction = sortColumn.direction === "asc" ? "desc" : "asc";
    }

    setSortColumn({ id, direction });
  };

  const sortedRows = useMemo(
    () =>
      sortColumn
        ? [...rows].sort((a, b) => {
            const aValue = a[sortColumn.id] ?? 0;
            const bValue = b[sortColumn.id] ?? 0;
            return sortColumn.direction === "asc"
              ? aValue - bValue
              : bValue - aValue;
          })
        : rows,
    [rows, sortColumn],
  );

  const handleShowChart = (item: CarAssetClassAnalysisItem) => {
    if (item.modelChartData) {
      dialog(DftChartDialog, {
        items: sortedRows,
        assetClassId: item.level4Id,
      });
    }
  };

  const yColumnId = displayColumns[0].id;
  const xColumnId = displayColumns[1].id;

  useEffect(() => {
    if (!showSelector) {
      xyStatState.onXStatIdChange(xColumnId);
      xyStatState.onYStatIdChange(yColumnId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xColumnId, yColumnId, showSelector]);

  return (
    <Box
      sx={{
        display: "grid",
        borderColor: "table.border",
        ".MuiTypography-root": {
          color: "table.text",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(displayColumns.length),
          position: "sticky",
          top: 0,
          backgroundColor: "table.header",
          zIndex: 10,
          "& > *:first-of-type": {
            borderTopLeftRadius: "5px",
          },
          "& > *:last-of-type": {
            borderTopRightRadius: "5px",
          },
        }}
      >
        <LabelHeader showSelector={showSelector} />
        {displayColumns.map((col, idx, arr) => (
          <ModuleHeader
            key={col.id}
            id={col.id}
            sortId={sortColumn?.id}
            onSort={handleSort}
            label={col.label}
            showSelector={showSelector}
            {...xyStatState}
          />
        ))}
      </Box>
      {sortedRows.map((i, idx, arr) => (
        <Row
          key={i.level4Id}
          item={i}
          isOdd={!isOddEven(idx)}
          displayColumns={displayColumns}
          {...xyStatState}
          isLastRow={idx === arr.length - 1}
          onShowChart={() => handleShowChart(i)}
        />
      ))}
    </Box>
  );
};
