import { useCallback } from "react";
import { showToast, ToastState } from "./toastSlice";
import { useAppDispatch } from "./hooks";

export type SuccessToastParams = Omit<ToastState, "open" | "customToast">;

export const useSuccessToast = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (params: SuccessToastParams) =>
      dispatch(
        showToast({
          ...params,
        })
      ),
    [dispatch]
  );
};

export interface ErrorToastParams
  extends Omit<ToastState, "open" | "customToast" | "kind"> {
  title?: string;
}

export const useErrorToast = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    (params: ErrorToastParams) =>
      dispatch(
        showToast({
          kind: "error",
          ...params,
        })
      ),
    [dispatch]
  );
};
