import { Box } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { Fragment, memo } from "react";
import { CarSelectOptionField } from "components/Inputs";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { useSimulationModelsRun } from "./useSimulationModelsRun";
import { isOddEven } from "utils";
import { CarLoadingButton } from "components/Buttons";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

export const SimulationModelsRun = memo(() => {
  const data = useSimulationModelsRun({});

  const pageContent = usePageContent();

  return (
    <>
      <CarPageContent
        sx={{ mb: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.SIMULATION_ANALYSIS_MODELS_RUN,
        )}
        isWhiteContext
      />
      <Box sx={{ mt: 2, display: "flex", gap: 5, alignItems: "flex-end" }}>
        <CarSelectOptionField
          sx={{
            width: 300,
            ".MuiInputBase-input": {
              backgroundColor: "white",
            },
          }}
          label="Selection"
          options={data.selectionOptions}
          value={data.selection}
          onChange={data.setSelection}
        />
        <CarLoadingButton
          sx={{ mb: -0.5 }}
          isLoading={data.isRunning}
          onClick={data.handleRun}
          disabled={!data.canRun}
          variant="outlined"
        >
          Run
        </CarLoadingButton>
      </Box>
      <TablePlaceHolder sx={{ mt: 5.75 }} isLoading={data.isLoading} rows={8}>
        {data.items.length > 0 && (
          <CarInfoTable sx={{ mt: 5.75, gridTemplateColumns: "1fr 2fr" }}>
            <CarInfoHeader text="" />
            <CarInfoHeader text="Status" />
            {data.items.map((row, rowIdx) => (
              <Fragment key={row.id}>
                <CarInfoCell
                  isOdd={isOddEven(rowIdx)}
                  justifyContent={"start"}
                  text={row.name}
                />
                <CarInfoCell isOdd={isOddEven(rowIdx)} text={row.status} />
              </Fragment>
            ))}
          </CarInfoTable>
        )}
      </TablePlaceHolder>
    </>
  );
});
