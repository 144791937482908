import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { Fragment } from "react";
import { formatNumber, formatPercentFactor, isOddEven } from "utils";
import { AssetClassStatisticsItem } from "./useAssetClassStatistics";
import { getAssetClassIcon } from "components/AssetClassSelector";
import { Box } from "@mui/material";

export const AssetClassStatisticsTable = ({
  simulationGroupName,
  items,
}: {
  simulationGroupName: string;
  items: AssetClassStatisticsItem[];
}) => {
  return (
    <CarInfoTable
      sx={{ gridTemplateColumns: "1.8fr 1fr 0.8fr 1fr 1fr 1fr 1fr" }}
    >
      <CarInfoHeader isSticky text="" />
      <CarInfoHeader
        isSticky
        text={`${simulationGroupName}\nExpected Return`}
      />
      <CarInfoHeader isSticky text="Volatility" />
      <CarInfoHeader isSticky text={`Fixed Income\nDuration`} />
      <CarInfoHeader isSticky text={`Fixed Income\nConvexity`} />
      <CarInfoHeader isSticky text={`Current Equity\nDividend Yield`} />
      <CarInfoHeader isSticky text={`Current Fixed\nIncome Yield`} />
      {items.map((i, idx) => (
        <Fragment key={i.level4Id}>
          <CarInfoCell isOdd={isOddEven(idx)} justifyContent="start">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              {getAssetClassIcon(i)}
              {i.name}
            </Box>
          </CarInfoCell>
          <CarInfoCell
            isOdd={isOddEven(idx)}
            text={formatPercentFactor(i.cmaModelExpectedReturn, 1)}
          />
          <CarInfoCell
            isOdd={isOddEven(idx)}
            text={formatPercentFactor(i.volatility, 1)}
          />
          <CarInfoCell
            isOdd={isOddEven(idx)}
            text={formatNumber(i.fixedIncomeDuration, 1)}
          />
          <CarInfoCell
            isOdd={isOddEven(idx)}
            text={formatNumber(i.fixedIncomeConvexity, 1)}
          />
          <CarInfoCell
            isOdd={isOddEven(idx)}
            text={formatPercentFactor(i.currentEquityDividendYield, 1)}
          />
          <CarInfoCell
            isOdd={isOddEven(idx)}
            text={formatPercentFactor(i.currentFixedIncomeYield, 1)}
          />
        </Fragment>
      ))}
    </CarInfoTable>
  );
};
