import {
  useSimulationSimulationJobGetSummaryStatisticsListQuery,
  useSimulationSimulationJobLatestSimulationRetrieveQuery,
} from "api/carApi.generated";

export const useLatestSimulationDate = () => {
  const latestSimulation =
    useSimulationSimulationJobLatestSimulationRetrieveQuery();

  const simulationJobId = latestSimulation.data?.id;

  const summaryStat = useSimulationSimulationJobGetSummaryStatisticsListQuery(
    {
      id: simulationJobId ?? "",
    },
    {
      skip: !simulationJobId,
    },
  );

  return {
    isLoading: latestSimulation.isLoading || summaryStat.isLoading,
    latestSimulationDate:
      summaryStat.data?.find((i) => i.asset_class_as_of_date)
        ?.asset_class_as_of_date ?? undefined,
  };
};
