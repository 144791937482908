import { Navigate, Route, Routes } from "react-router-dom";
import { assetClassAnalysisPages } from "route.const";
import { AssetClassAnalysisAnalyze } from "./AssetClassAnalysisAnalyze";
import { AssetClassAnalysisDashboard } from "./AssetClassAnalysisDashboard";

export const AssetClassAnalysisPage = () => {
  return (
    <Routes>
      <Route
        path={assetClassAnalysisPages.dashboard}
        element={<AssetClassAnalysisDashboard />}
      />
      <Route
        path={assetClassAnalysisPages.analyzePath}
        element={<AssetClassAnalysisAnalyze />}
      />
      <Route
        path="*"
        element={<Navigate to={assetClassAnalysisPages.dashboard} />}
      />
    </Routes>
  );
};
