import { useMemo } from "react";
import { useAccountsAccountTypeListQuery } from "api/carApi.generated";
import { CarAccount } from "types";

export const useAccountTypes = () => {
  const { data, isLoading } = useAccountsAccountTypeListQuery();

  return useMemo(() => {
    const isAccountTaxable = (item: CarAccount) =>
      data?.find((i) => i.id === item.account_type)?.tax_type.is_taxable ||
      false;
    const canAccountHaveEmployerMatch = (item: CarAccount) =>
      data?.find((i) => i.id === item.account_type)?.tax_type
        .can_have_employer_match || false;
    const items = data ?? [];
    return {
      isAccountTaxable,
      canAccountHaveEmployerMatch,
      isLoading,
      items,
    };
  }, [data, isLoading]);
};
