import { Dialog, Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useQuestionnaireExternalBlackDiamondConnectionDestroyMutation } from "api/carApi.generated";
import { CarButton, CarLoadingButton } from "components/Buttons";
import { CarClose } from "icons";

export interface BlackDiamondDisconnectDialogProps {
  onApply: (value: boolean) => void;
  onCancel: () => void;
}

export const BlackDiamondDisconnectDialog = (
  props: BlackDiamondDisconnectDialogProps
) => {
  const [disconnectBlackDiamond, { isLoading }] =
    useQuestionnaireExternalBlackDiamondConnectionDestroyMutation();

  const handleDisconnect = async () => {
    if (isLoading) {
      return;
    }
    const response = await disconnectBlackDiamond();

    if ("data" in response) {
      props.onApply(true);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "6px",
          p: 5,
          position: "relative",
          width: 392,
        },
      }}
      maxWidth="lg"
      open={true}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          props.onCancel();
        }
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: 10, top: 10, color: "gray6" }}
        onClick={props.onCancel}
      >
        <CarClose />
      </IconButton>
      <Typography variant="h4SBold">Disconnect</Typography>
      <Divider sx={{ mt: 0.5, mb: 1.5, borderColor: "gray6" }} />
      <Typography variant="par02Regular">
        Are you sure you want to disconnect your Black Diamond account? You will
        no longer be able to link new accounts to client plans, and existing
        connections will stop updating.
      </Typography>

      <Box sx={{ mt: 6, display: "flex", gap: 2 }}>
        <CarButton
          sx={{ fontSize: "18px" }}
          onClick={props.onCancel}
          variant="outlined"
        >
          Back
        </CarButton>
        <CarLoadingButton
          sx={{ fontSize: "18px" }}
          onClick={handleDisconnect}
          isLoading={isLoading}
          color="error"
        >
          Disconnect
        </CarLoadingButton>
      </Box>
    </Dialog>
  );
};
