import { BoxProps } from "@mui/material";
import {
  FlatPageRouteControl,
  FlatPageRouteTab,
} from "components/FlatPageRouteControl";
import { useMemo } from "react";
import { SimulationSummary } from "./SimulationSummary";
import { SimulationCorrelations } from "./SimulationCorrelations";
import { SimulationReturns } from "./SimulationReturns";
import { SimulationYields } from "./SimulationYields";
import { SimulationDataExplorer } from "./SimulationDataExplorer";
import { SimulationDistanceFromTrend } from "./SimulationDistanceFromTrend";
import { SimulationSimulatedForwardProbabilities } from "./SimulationSimulatedForwardProbabilities";

interface SimulationDetailsProps {
  sx?: BoxProps["sx"];
  simulationJobId: string;
  isStatic: boolean;
}

export const SimulationDetails = (props: SimulationDetailsProps) => {
  const tabs = useMemo<Array<FlatPageRouteTab>>(() => {
    return [
      {
        url: "summary",
        label: "Summary",
        content: (
          <SimulationSummary
            simulationJobId={props.simulationJobId}
            isStatic={props.isStatic}
          />
        ),
      },
      {
        url: "distance-from-trend",
        label: "Distance From Trend",
        content: (
          <SimulationDistanceFromTrend
            simulationJobId={props.simulationJobId}
          />
        ),
      },
      {
        url: "simulated-forward-probabilities",
        label: "Simulated Forward Probabilities",
        content: (
          <SimulationSimulatedForwardProbabilities
            simulationJobId={props.simulationJobId}
          />
        ),
      },
      {
        url: "returns",
        label: "Returns",

        content: (
          <SimulationReturns
            simulationJobId={props.simulationJobId}
            isStatic={props.isStatic}
          />
        ),
      },
      {
        url: "yields",
        label: "Yields",

        content: <SimulationYields simulationJobId={props.simulationJobId} />,
      },
      {
        url: "data-explorer",
        label: "Data Explorer",

        content: (
          <SimulationDataExplorer simulationJobId={props.simulationJobId} />
        ),
      },
      {
        url: "correlations",
        label: "Correlations",

        content: (
          <SimulationCorrelations
            simulationJobId={props.simulationJobId}
            isStatic={props.isStatic}
          />
        ),
      },
    ].filter((i) =>
      props.isStatic
        ? i.url === "summary" ||
          i.url === "simulated-forward-probabilities" ||
          i.url === "returns" ||
          i.url === "correlations"
        : true,
    );
  }, [props.isStatic, props.simulationJobId]);
  return <FlatPageRouteControl tabs={tabs} sx={props.sx} />;
};
