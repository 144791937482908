import {
  License,
  Role,
  useCommonBillingActivateBillingV2RetrieveQuery,
} from "api/carApi.generated";
import { useMemo } from "react";

export const useLicenses = () => {
  const data = useCommonBillingActivateBillingV2RetrieveQuery();

  return useMemo(() => {
    const items = (data.data ?? []) as unknown as License[]; // todo fix in swagger

    const getRoleLicense = (role?: Role) =>
      items?.find((i) => i.role_code === role?.code);

    return {
      isLoading: data.isLoading,
      getRoleLicense,
    };
  }, [data]);
};
