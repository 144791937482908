import { HeaderCell, TextCell, ViewCell, fontPdf } from "components/Pdf";
import ReactPDF, { View, Text } from "@react-pdf/renderer";
import {
  CarPortfolioAssetBand,
  CarPortfolioStatRow,
} from "pages/assetAllocation/usePortfolioGroupVersionData";
import { formatDisplayTypeValue, isOddEven } from "utils";

import { useTheme } from "@mui/material";

const cashColumnShrink = 1.13;
const titleColumnWidth = 500;

const Row = ({
  row,
  backgroundColor,
}: {
  row: CarPortfolioStatRow;
  backgroundColor?: string;
}) => {
  return (
    <View
      wrap={false}
      style={{
        flexDirection: "row",
        backgroundColor,
        height: 30,
      }}
    >
      <ViewCell
        style={{
          width: titleColumnWidth,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          padding: 4,
          paddingRight: 4,
        }}
      >
        <Text>{row.name}</Text>
      </ViewCell>
      {row.modules.map((mod, idx, arr) => (
        <ViewCell
          key={mod.moduleId}
          style={{
            flexShrink: mod.isCash ? cashColumnShrink : undefined,
            borderRightWidth: idx === arr.length - 1 ? 1 : 0,
            alignItems: "stretch",
          }}
        >
          {mod.values.map((val, valIdx, valArr) => (
            <ViewCell
              key={val.columnId}
              style={{
                borderWidth: 0,
                borderRightWidth: valIdx === valArr.length - 1 ? 0 : 1,
                alignItems: "center",
              }}
            >
              <Text style={{ width: "100%", textAlign: "center" }}>
                {formatDisplayTypeValue(row.displayType)(val.allocation)}
              </Text>
            </ViewCell>
          ))}
        </ViewCell>
      ))}
    </View>
  );
};

interface PortfolioGroupAssetStatPdfTablePros
  extends Omit<ReactPDF.ViewProps, "children"> {
  bands: CarPortfolioAssetBand[];
  rows: CarPortfolioStatRow[];
  xStatId?: string;
  yStatId?: string;
}

export const PortfolioGroupAssetStatPdfTable = ({
  bands,
  rows,
  xStatId,
  yStatId,
  style,
  ...props
}: PortfolioGroupAssetStatPdfTablePros) => {
  const theme = useTheme();
  return (
    <View
      style={{
        flexDirection: "column",
        fontFamily: fontPdf.table.family,
        fontSize: fontPdf.table.dense.size,
        ...style,
      }}
      {...props}
    >
      <View fixed>
        <View
          style={{
            flexDirection: "row",
            fontSize: fontPdf.table.dense.headerSize,
            height: 32,
          }}
        >
          <HeaderCell style={{ width: titleColumnWidth }} />
          {bands.map((band, idx, arr) => (
            <HeaderCell
              key={band.moduleId}
              style={{
                paddingTop: 4,
                flexShrink: band.isCash ? cashColumnShrink : undefined,
                borderRightWidth: idx === arr.length - 1 ? 1 : 0,
              }}
            >
              {band.label}
            </HeaderCell>
          ))}
        </View>
        <View
          style={{
            flexDirection: "row",
            fontSize: fontPdf.table.dense.size - 2,
            fontStyle: "italic",
            height: 16,
          }}
        >
          <TextCell style={{ width: titleColumnWidth }} />
          {bands.map((band, idx, arr) => (
            <ViewCell
              key={band.moduleId}
              style={{
                flexShrink: band.isCash ? cashColumnShrink : undefined,
                borderRightWidth: idx === arr.length - 1 ? 1 : 0,
                alignItems: "stretch",
              }}
            >
              {band.columns.map((col, colIdx, colArr) => (
                <ViewCell
                  key={col.columnId}
                  style={{
                    borderWidth: 0,
                    borderRightWidth: colIdx === colArr.length - 1 ? 0 : 1,
                    alignItems: "center",
                  }}
                >
                  <Text style={{ width: "100%", textAlign: "center" }}>
                    {col.label}
                  </Text>
                </ViewCell>
              ))}
            </ViewCell>
          ))}
        </View>
      </View>
      {rows.map((row, idx) => {
        const backgroundColor =
          row.id === xStatId || row.id === yStatId
            ? theme.palette.chartsColor.yellow
            : isOddEven(idx)
            ? theme.palette.white
            : theme.palette.gray2;
        return <Row key={row.id} row={row} backgroundColor={backgroundColor} />;
      })}
    </View>
  );
};
