import { Box, ButtonBase, Typography, styled } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { PortfolioGroupAssetAllocationTable } from "../PortfolioGroupAssetAllocationTable";
import { PortfolioGroupAssetStatTable } from "../PortfolioGroupAssetStatTable";
import { PortfolioGroupAssetStatChart } from "../PortfolioGroupAssetStatChart";
import { CarSwitch } from "components/Switch";
import { useStatAxisSelector } from "./useStatAxisSelector";
import {
  CarButton,
  CarDownloadButton,
  CarLoadingPromiseButtonAddRow,
} from "components/Buttons";
import { useExportAssetAllocation } from "../useExportAssetAllocation";
import { useNavigate } from "react-router-dom";
import { editPortfolioGroupPages, editPortfolioPages } from "route.const";
import { useScrollTo } from "app/useScrollToTop";
import { CarAssetAllocationSectionBox } from "../viewPortfolioGroup/AssetAllocationSection";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarPageContent } from "components/PageContent";
import { InternalUseWithDisclosures } from "./InternalUse";
import { StatAxisSelector } from "./StatAxisSelector";
import { usePortfolioGroupCreateAllocations } from "../usePortfolioGroupCreateAllocations";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { showToast } from "app/toastSlice";
import { useAppDispatch } from "app/hooks";
import { CarCheckboxGroup } from "components/Checkbox";
import { useOptimizerDynamicRules } from "./useOptimizerDynamicRules";
import { saveConfigInStorage } from "./useShowConfigSelector";
import {
  CarFlatPageControl,
  CarFlatPageItem,
} from "components/FlatPageControl";
import { CarTooltipBox } from "components/TooltipBox";
import { AssetClassStatisticsTable } from "./AssetClassStatisticsTable";
import { useState } from "react";

export const StyledButton = styled(ButtonBase)(({ theme }) => ({
  borderRadius: "5px",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  ...theme.typography.par01Regular,
  "&.selected": {
    color: theme.palette.primary.main,
  },
}));

const StyledPage = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(4.25),
  paddingRight: theme.spacing(4.25),
  backgroundColor: theme.palette.gray1,
  borderRadius: "5px",
  borderColor: theme.palette.gray6,
  borderStyle: "solid",
  borderWidth: "1px",
}));

export const EditPortfolioGroupCreateAllocations = () => {
  const data = usePortfolioGroupCreateAllocations();
  const statAxis = useStatAxisSelector();
  const navigate = useNavigate();
  const scrollTo = useScrollTo();

  const exportAssetAllocation = useExportAssetAllocation();
  const pageContent = usePageContent();

  const [currentTab, setCurrentTab] = useState<string | undefined>("assets");

  const optimizerDynamicRules = useOptimizerDynamicRules();

  const dispatch = useAppDispatch();

  const hasErrors = data.tableData.bands.some((b) =>
    b.columns.some((c) => c.hasError),
  );

  const showErrorToast = (message: string) =>
    dispatch(
      showToast({
        kind: "error",
        message,
      }),
    );

  const handleExport = () => {
    exportAssetAllocation({
      tableData: data.tableData,
    });
  };

  const handleNext = () => {
    if (data.isSinglePortfolioMode) {
      navigate(`..\\${editPortfolioPages.optimization}`);
    } else {
      navigate(`..\\${editPortfolioGroupPages.optimization}`);
    }
    scrollTo("top");
  };

  const handlePublish = async () => {
    if (hasErrors) {
      showErrorToast("Please fix asset allocation errors first");
      return;
    }

    data.handlePublish((successJobIds: string[]) => {
      saveConfigInStorage({
        portfolioGroupId: data.portfolioGroupId,
        config: {
          existing: true,
          benchmark: true,
          draft: true,
          selected: false,
          optimizationIds: successJobIds,
        },
      });

      if (data.isSinglePortfolioMode) {
        navigate(`..\\${editPortfolioPages.editCompare}`);
      } else {
        navigate(`..\\${editPortfolioGroupPages.editCompare}`);
      }
      scrollTo("top");
    });
  };

  const filteredGroups = data.statTableData.statGroups.filter(
    (i) => i.code === "SIMULATION",
  );

  return (
    <TablePlaceHolder
      isLoading={
        data.isLoading ||
        pageContent.isLoading ||
        optimizerDynamicRules.isLoading
      }
      rows={8}
      sx={{ mt: 5 }}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        {data.isSinglePortfolioMode
          ? "Create Portfolio Model"
          : "Create Allocations"}
      </Typography>
      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          data.isSinglePortfolioMode
            ? CarPageContentCode.PORTFOLIO_ANALYSIS_CREATE_MODEL
            : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_CREATE_ALLOCATION,
        )}
      />
      {!data.isSinglePortfolioMode && (
        <>
          <StatAxisSelector
            statAxisSelector={statAxis}
            sx={{ mt: 5 }}
            isWhiteContext
            items={filteredGroups.flatMap((i) => i.rows)}
          />
          <Box
            sx={{
              mt: 5,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "gray1",
              border: "1px solid",
              borderColor: "gray3",
              p: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <InternalUseWithDisclosures sx={{ mr: 2 }} />
              <PortfolioGroupAssetStatChart
                statTableData={data.statTableData}
                {...statAxis}
              />
            </Box>
          </Box>
        </>
      )}

      <CarFlatPageControl
        sx={{
          mt: 5,
          ".flat-page-control-header": {
            ml: 3,
            gap: 3,
          },
          ".flat-page-control-button": {
            p: 1,
            fontSize: "18px",
          },
        }}
        items={[
          {
            label: "Assets",
            value: "assets",
            pendoClassName: pendoClasses.editPortfolioEditCompareTabAssets,
            content: (
              <StyledPage>
                <Box
                  sx={{
                    mt: -1,
                    display: "flex",
                    alignItems: "center",
                    // justifyContent: "flex-end",
                    gap: 5,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="par02Regular">
                      Show Input Only
                    </Typography>
                    <CarTooltipBox
                      sx={{ ml: 0.5 }}
                      className={
                        pendoClasses.editPortfolioEditCompareShowInputOnly
                      }
                    />
                    <CarSwitch
                      size="small"
                      checked={!data.isTreeView}
                      onChange={(e, value) => data.setIsTreeView(!value)}
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: 1, ml: "auto" }}>
                    {!data.isSinglePortfolioMode && (
                      <CarLoadingPromiseButtonAddRow
                        className={pendoClasses.addModuleButton}
                        sx={{ alignSelf: "flex-end" }}
                        disabled={!data.canAddModule}
                        onClick={data.handleModuleAdd}
                      >
                        Add Model
                      </CarLoadingPromiseButtonAddRow>
                    )}
                    <CarDownloadButton onClick={handleExport}>
                      Download
                    </CarDownloadButton>
                  </Box>
                </Box>

                <PortfolioGroupAssetAllocationTable
                  sx={{ mt: 2 }}
                  storageKey={data.portfolioGroupId}
                  tableData={data.tableData}
                  onAllocationChange={data.handleAllocationChange}
                  onModuleRename={data.handleModuleRename}
                  onModuleDelete={data.handleModuleDelete}
                  onModuleMove={data.handleModuleMove}
                  isReadOnly={data.isPublished}
                  hideActionsRow={data.isSinglePortfolioMode}
                  hideZeroValues={false}
                  isTreeView={data.isTreeView}
                />
              </StyledPage>
            ),
          },
          ...filteredGroups.map<CarFlatPageItem<string>>((group) => ({
            label: `${group.name}${
              group.isHistorical ? "" : data.simulationSuffix
            }`,
            value: group.code,
            pendoClassName:
              pendoClasses.analyzePortfolioGroupEditCompareTabStat(group.code),
            content: (
              <StyledPage>
                <PortfolioGroupAssetStatTable
                  tableData={data.tableData}
                  rows={group.rows}
                  showPercentile={group.code === "SIMULATION"}
                />
              </StyledPage>
            ),
          })),
          {
            label: "Asset Class Statistics",
            value: "assetClassStatistics",
            pendoClassName:
              pendoClasses.editPortfolioEditCompareTabAssetClassStatistics,
            content: (
              <StyledPage>
                <AssetClassStatisticsTable
                  simulationGroupName={data.simulationGroupName}
                  items={data.assetClassStatisticsItems}
                />
              </StyledPage>
            ),
          },
        ]}
        value={currentTab}
        onChange={setCurrentTab}
      />
      {data.isSinglePortfolioMode && !data.isPublished && (
        <CarAssetAllocationSectionBox sx={{ mt: 5 }} label="Optimization Types">
          <CarPageContent
            sx={{ mt: -1 }}
            content={pageContent.getContent(
              CarPageContentCode.PORTFOLIO_ANALYSIS_CREATE_MODEL_AUTO_OPTIMIZATION,
            )}
            isWhiteContext
          />
          <CarCheckboxGroup
            sx={{
              alignSelf: "flex-start",
              mt: 4,
              ".MuiFormGroup-root": {
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                columnGap: 9,
                rowGap: 1,
              },
              ".MuiCheckbox-root.MuiCheckbox-colorPrimary": {
                color: "gray9",
                "&.Mui-checked": {
                  color: "primary.main",
                },
              },
              ".MuiTypography-root.MuiFormControlLabel-label": {
                ml: 1,
                fontSize: "18px",
              },
            }}
            items={optimizerDynamicRules.items.map((i) => ({
              ...i,
              pendoClassName:
                pendoClasses.editPortfolioCreateModelOptimizationType(i.code),
            }))}
            checkedItems={data.checkedDynamicRules}
            onChange={data.setCheckedDynamicRules}
            showCheckAll
          />
        </CarAssetAllocationSectionBox>
      )}
      <Box sx={{ mt: 5, display: "flex", gap: 2.5, justifyContent: "center" }}>
        {data.isPublished ? (
          <CarButton onClick={handleNext}>Next</CarButton>
        ) : (
          <CarButton
            sx={{ width: 180 }}
            className={pendoClasses.publishGroupButton}
            onClick={handlePublish}
          >
            {data.isSinglePortfolioMode ? "Publish & Run" : "Publish Group"}
          </CarButton>
        )}
      </Box>
    </TablePlaceHolder>
  );
};
