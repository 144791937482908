import { Text, View } from "@react-pdf/renderer";
import {
  fontPdf,
  FooterProps,
  HeaderCell,
  PdfCheckbox,
  PdfPage,
  PdfSectionLabel,
  TextCell,
} from "components/Pdf";

import { formatDisplayTypeValue, isDefined } from "utils";
import {
  PlanResultsRiskTableRow,
  UsePlanResults,
} from "../planResults/usePlanResults";

const Header = (props: { selectedPlanLabel: string }) => (
  <View
    style={{
      flexDirection: "row",
      fontFamily: fontPdf.table.family,
      fontSize: fontPdf.table.normal.headerSize,
    }}
  >
    <HeaderCell style={{ paddingVertical: 12 }} />
    <HeaderCell style={{ paddingVertical: 12 }}>Existing Portfolio:</HeaderCell>
    <HeaderCell style={{ paddingVertical: 12 }}>Proposed Portfolio:</HeaderCell>
    <HeaderCell style={{ paddingVertical: 12, borderRightWidth: 1 }}>
      Difference:
    </HeaderCell>
  </View>
);

const Row = ({ item }: { item: PlanResultsRiskTableRow }) => (
  <View
    style={{
      flexDirection: "row",
      fontFamily: fontPdf.table.family,
      fontSize: fontPdf.table.normal.size,
    }}
  >
    <TextCell
      style={{
        textAlign: "left",
        paddingLeft: 5,
      }}
    >
      {`${item.label}:`}
    </TextCell>
    <TextCell>
      {formatDisplayTypeValue(item.displayType)(item.existingPortfolio)}
    </TextCell>
    <TextCell>
      {formatDisplayTypeValue(item.displayType)(item.proposedPortfolio)}
    </TextCell>
    <TextCell
      style={{
        borderRightWidth: 1,
      }}
    >
      {formatDisplayTypeValue(item.displayType)(
        isDefined(item.existingPortfolio) && isDefined(item.proposedPortfolio)
          ? item.existingPortfolio - item.proposedPortfolio
          : undefined
      )}
    </TextCell>
  </View>
);

export interface RiskReportsActionPdfPageProps {
  footerProps: FooterProps;
  planResults: UsePlanResults;
}

export const RiskReportsActionPdfPage = (
  props: RiskReportsActionPdfPageProps
) => {
  return (
    <PdfPage footerProps={props.footerProps}>
      <PdfSectionLabel label="Risk" />
      <Text
        style={{
          fontFamily: fontPdf.table.family,
          fontSize: fontPdf.table.normal.headerSize,
          fontWeight: "bold",
          marginBottom: 8,
        }}
      >
        Simulation Based Risk Report:
      </Text>
      <Header
        selectedPlanLabel={props.planResults.risk.selectedPlanLabel ?? ""}
      />
      {props.planResults.risk.simulationBasedRiskReport.map((i, idx) => (
        <Row key={idx} item={i} />
      ))}
      <Text
        style={{
          marginTop: 60,
          fontFamily: fontPdf.table.family,
          fontSize: fontPdf.table.normal.headerSize,
          fontWeight: "bold",
          marginBottom: 8,
        }}
      >
        Historical Risk Report:
      </Text>
      <Header
        selectedPlanLabel={props.planResults.risk.selectedPlanLabel ?? ""}
      />
      {props.planResults.risk.historicalRiskReport.map((i, idx) => (
        <Row key={idx} item={i} />
      ))}
      <PdfCheckbox style={{ marginTop: 35, alignSelf: "center", width: 380 }}>
        I/We have reviewed the estimated investment risks summarized above and
        understand the risks inherent in the new investment strategy recommended
        by our advisor.
      </PdfCheckbox>
      <PdfCheckbox style={{ marginTop: 35, alignSelf: "center", width: 380 }}>
        I/We have the risk tolerance to accept these risks as a part of our
        overall financial plan.
      </PdfCheckbox>
      {props.planResults.isRiskMoreThanProfiled && (
        <PdfCheckbox style={{ marginTop: 35, alignSelf: "center", width: 380 }}>
          I/We understand that the investment risk of the new strategy is higher
          than our prior profiled risk tolerance level. The planning process has
          given us a better understanding of the tradeoff between our financial
          goals and investment risks, and that makes us comfortable increasing
          our risk tolerance profile to the new level.
        </PdfCheckbox>
      )}
    </PdfPage>
  );
};
