import {
  Role,
  useCommonCmpasModuleListQuery,
  useCommonRolesCreateMutation,
  useCommonRolesRetrieveQuery,
  useCommonRolesUpdateMutation,
  useCommonRoleTypesListQuery,
} from "api/carApi.generated";
import { useForm } from "./useForm";
import { FormEditorDialog } from "./FormEditorDialog";
import { CarSelectOption } from "components/Inputs";
import { checkDefined, sortBySortOrder } from "utils";

interface RoleEditorDialogProps {
  id?: string;
  nextSortOrder?: number;
  onApply: () => void;
  onCancel: () => void;
}

export const RoleEditorDialog = (props: RoleEditorDialogProps) => {
  const query = useCommonRolesRetrieveQuery(
    {
      id: props.id ?? "",
    },
    { skip: !props.id },
  );

  const [create] = useCommonRolesCreateMutation();
  const [update] = useCommonRolesUpdateMutation();

  const roleTypes = useCommonRoleTypesListQuery();
  const modules = useCommonCmpasModuleListQuery();

  const form = useForm<Role>({
    entityLabel: "User Role",
    getItemName: (item) => `${item.name} (${item.code})`,
    isLoading: query.isLoading || roleTypes.isLoading || modules.isLoading,
    item: query.data,
    getNewItem: () => ({
      code: "",
      name: "",
      is_active: true,
      sort_order: props.nextSortOrder,
    }),
    onClose: props.onCancel,
    onCreate: (item) =>
      create({
        role: item,
      }),

    onUpdate: (item) =>
      update({
        id: item.id ?? "",
        role: item,
      }),
    onDelete: async (item) => {
      // todo
    },
    fieldDefs: [
      {
        key: "code",
        type: "string",
        label: "Code",
        description: "Unique code for the role",
        isRequired: true,
      },
      {
        key: "name",
        type: "string",
        label: "Name",
        description: "Name of the role",
        isRequired: true,
      },
      {
        key: "role_type",
        type: "select",
        label: "Type",
        description: "Type of the role",
        isRequired: true,
        options: sortBySortOrder(roleTypes.data)
          ?.filter((i) => i.is_active)
          .map<CarSelectOption<string>>((i) => ({
            label: `${i.name} (${i.code})`,
            value: checkDefined(i.id),
          })),
      },
      {
        key: "module_access",
        type: "multiselect",
        label: "Module Access",
        description: "The modules this role has access to",
        options: sortBySortOrder(modules.data)
          ?.filter((i) => i.is_active)
          .map<CarSelectOption<string>>((i) => ({
            label: `${i.name} (${i.code})`,
            value: checkDefined(i.id),
          })),
      },
      {
        key: "description",
        type: "memo",
        label: "Description",
        description: "Description of the role",
      },
      {
        key: "is_active",
        type: "boolean",
        label: "Is Active",
        description: "Is this role active",
      },
      {
        key: "sort_order",
        type: "integer",
        label: "Sort Order",
        description: "Sort order of role",
      },
    ],
  });

  return <FormEditorDialog form={form} />;
};
