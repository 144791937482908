import { PdfChart, PdfChartProps } from "components/Pdf";
import { ChartConfiguration, FontSpec } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { useTheme } from "@mui/material";
import { Text, View } from "@react-pdf/renderer";
import { CmaModelChartData } from "api/carApi.generated";
import { typographyOptions } from "theme/typography";
import {
  getDftVersusTenYearReturnData,
  DftVersusTenYearReturnDataPoint,
} from "../editPortfolioGroup/ChartDftVersusTenYearReturn";
import { formatPercent } from "utils";

interface PortfolioGroupDftVersusTenYearReturnPdfChartProps
  extends Omit<PdfChartProps, "config"> {
  data: CmaModelChartData;
  assetClassName: string;
}

const PdfChartLegend = ({
  config,
}: {
  config: ChartConfiguration<"line" | "scatter">;
}) => {
  return (
    <View
      style={{
        marginTop: 5,
        marginLeft: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {config.data.datasets.map((ds, idx) => {
        return (
          <View
            key={idx}
            style={{
              marginLeft: 16,
              marginRight: 16,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                backgroundColor: String(
                  ds.borderColor ?? ds.pointBorderColor ?? "black",
                ),
                borderRadius: ds.type === "scatter" ? 5 : 0,
                marginRight: 4,
              }}
            />
            <Text
              style={{
                fontSize: 10,
              }}
            >
              {ds.label}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export const PortfolioGroupDftVersusTenYearReturnPdfChart = ({
  data,
  assetClassName,
  ...props
}: PortfolioGroupDftVersusTenYearReturnPdfChartProps) => {
  const theme = useTheme();

  const calcData = getDftVersusTenYearReturnData({
    data,
    assetClassName,
    upperBondColor: theme.palette.chartsColor.orange,
    lowerBondColor: theme.palette.chartsColor.purple,
    expectedReturnColor: theme.palette.chartsColor.green,
    realReturnColor: theme.palette.chartsColor.blue,
  });

  if (!calcData) {
    return <></>;
  }

  const {
    upperBondLine,
    lowerBondLine,
    expectedReturnTrendLine,
    realReturnScatter,
    range,
    yLabel,
  } = calcData;

  const datasets = [
    upperBondLine,
    lowerBondLine,
    expectedReturnTrendLine,
    realReturnScatter,
  ];

  const font: Partial<FontSpec> = {
    size: 14,
    weight: "400",
    lineHeight: 1,
    family: typographyOptions.fontFamily,
  };

  const config: ChartConfiguration<
    "line" | "scatter",
    DftVersusTenYearReturnDataPoint[]
  > = {
    type: "line",
    options: {
      devicePixelRatio: 4,
      responsive: false,
      animation: false,
      borderColor: "transparent",

      layout: {
        padding: {
          right: 1, // for some reason right border is cropped, so add right padding to fit it in image
        },
      },

      scales: {
        x: {
          type: "linear",
          min: range.minX,
          max: range.maxX,
          ticks: {
            padding: 6,
            font,
            callback: (value) =>
              typeof value === "number" ? formatPercent(value, 1) : value,
          },
          grid: {
            display: true,
            drawTicks: false,
          },
        },
        y: {
          type: "linear",
          min: range.minY,
          max: range.maxY,
          offset: false,

          ticks: {
            font,
            color: theme.palette.black,
            callback: (value) =>
              typeof value === "number" ? formatPercent(value, 1) : value,
          },
          grid: {
            display: true,
            drawTicks: false,
          },
          title: {
            display: true,
            text: yLabel,
            color: theme.palette.black,
            font,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        roundedBackground: {
          contextColor: theme.palette.white,
          backgroundColor: "#FAFAFA", //theme.palette.gray1,
          borderColor: "#B1B1B3", // theme.palette.gray7,
          borderRadius: 0,
        },
      },
    },
    data: {
      datasets,
    },
    plugins: [ChartPlugins.roundedBackground],
  };

  return (
    <>
      <PdfChart config={config as any} {...props} />
      <PdfChartLegend config={config as any} />
    </>
  );
};
