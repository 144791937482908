import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IdObj } from "types";

const initialState = {
  create: {} as { [key: string]: Array<IdObj> },
  change: {} as { [key: string]: Array<IdObj> },
  isUpdating: false,
};

export const pendingSlice = createSlice({
  name: "pending",
  initialState,
  reducers: {
    setPendingCreate: (
      state,
      { payload: { key, item } }: PayloadAction<{ key: string; item: IdObj }>
    ) => {
      const old = state.create[key] ?? [];
      if (old.some((i) => i.id === item.id)) {
        state.create[key] = old.map((i) => (i.id === item.id ? item : i));
      } else {
        state.create[key] = [...old, item];
      }
    },
    removePendingCreate: (
      state,
      { payload: { key, id } }: PayloadAction<{ key: string; id?: string }>
    ) => {
      const old = state.create[key] ?? [];
      state.create[key] = old.filter((i) => i.id !== id);
    },
    setPendingChange: (
      state,
      { payload: { key, item } }: PayloadAction<{ key: string; item: IdObj }>
    ) => {
      const old = state.change[key] ?? [];
      if (old.some((i) => i.id === item.id)) {
        state.change[key] = old.map((i) => (i.id === item.id ? item : i));
      } else {
        state.change[key] = [...old, item];
      }
    },
    removePendingChange: (
      state,
      { payload: { key, id } }: PayloadAction<{ key: string; id?: string }>
    ) => {
      const old = state.change[key] ?? [];
      state.change[key] = old.filter((i) => i.id !== id);
    },

    setIsUpdating: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdating = payload;
    },

    clearPendingChanges: () => {
      return initialState;
    },
  },
});

export const pendingSliceReducer = pendingSlice.reducer;
export const pendingActions = pendingSlice.actions;

// const isPendingCreate = (id?: string) =>
// pendingCreate.some((i) => i.id === id);
// const removeFromPendingCreate = (id?: string) =>
// setPendingCreate((old) => old.filter((i) => i.id !== id));

// const isPendingChange = (id?: string) =>
// pendingChange.some((i) => i.id === id);
// const removeFromPendingChange = (id?: string) =>
// setPendingChange((old) => old.filter((i) => i.id !== id));
