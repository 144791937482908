import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo, useState } from "react";
import { CarButton, CarLoadingButton } from "components/Buttons";
import { CarSelectOptionField } from "components/Inputs";
import { SimulationDetails } from "./SimulationDetails";
import { useDialog } from "app/useDialog";
import { SimulationSettingsDialog } from "./SimulationSettingsDialog";
import { CarCheckbox } from "components/Checkbox";
import { useSimulationSimulationJobAdminPublishUpdateMutation } from "api/carApi.generated";
import { useSuccessToast } from "app/useSuccessToast";
import { useSimulationSelector } from "./useSimulationSelector";

export interface SimulationSingleProps {
  isStatic: boolean;
}

export const SimulationSingle = memo((props: SimulationSingleProps) => {
  const simulationSelector = useSimulationSelector({
    isStatic: props.isStatic,
  });

  const [sendNotifications, setSendNotifications] = useState(true);

  const dialog = useDialog();
  const toast = useSuccessToast();

  const [publishRequest, publishResponse] =
    useSimulationSimulationJobAdminPublishUpdateMutation();

  const handleRunSettings = () => {
    if (!simulationSelector.simulationGroupId) {
      return;
    }

    dialog(SimulationSettingsDialog, {
      simulationGroupId: simulationSelector.simulationGroupId,
    });
  };

  const handlePublish = () => {
    if (!simulationSelector.simulationJob1Id) {
      return;
    }

    publishRequest({
      id: simulationSelector.simulationJob1Id,
      simulationJobPublish: {
        send_notifications: sendNotifications,
      },
    }).then((v) => {
      if ("data" in v) {
        toast({
          kind: "success",
          message: "Success! This simulation has been published",
        });
      }
    });
  };

  // const handleExport = () => {};

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 10 }}
      isLoading={simulationSelector.isLoading}
      rows={8}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
          {props.isStatic ? "Static" : "Price - Driven"}
        </Typography>
        <Typography variant="mediumItalic">
          Source Data as of: MM/DD/YYYY
        </Typography>
      </Box>
      <CarSelectOptionField
        sx={{ width: 300, mt: 3.5 }}
        label="Simulation Group"
        options={simulationSelector.simulationGroupOptions}
        value={simulationSelector.simulationGroupId}
        onChange={simulationSelector.handleSimulationGroupIdChange}
      />
      <Box sx={{ display: "flex", mt: 5, alignItems: "flex-end", gap: 5 }}>
        <CarSelectOptionField
          sx={{ width: 300, mb: 0.25 }}
          label="Load Simulation Run"
          options={simulationSelector.simulationJobOptions}
          value={simulationSelector.simulationJob1Id}
          onChange={simulationSelector.handleSimulationJob1IdChange}
        />
        <CarLoadingButton
          sx={{ fontSize: "18px", width: 151 }}
          variant="contained"
          onClick={handlePublish}
          isLoading={publishResponse.isLoading}
        >
          Publish
        </CarLoadingButton>
        <CarCheckbox
          sx={{
            mb: 0.75,
            ".MuiFormControlLabel-label": {
              fontSize: 18,
            },
          }}
          label="Send notification"
          checked={sendNotifications}
          onChange={(e, checked) => setSendNotifications(checked)}
        />
        <CarButton
          sx={{ ml: "auto", fontSize: "18px", width: 163 }}
          variant="outlined"
          onClick={handleRunSettings}
        >
          Run Settings
        </CarButton>
        {/* <CarButton
          sx={{ ml: 3.5, fontSize: "18px" }}
          variant="contained"
          disabled={!currentSimulationJobId}
          onClick={handleExport}
        >
          Export
        </CarButton> */}
      </Box>
      {simulationSelector.simulationJob1Id && (
        <SimulationDetails
          sx={{ mt: 5 }}
          simulationJobId={simulationSelector.simulationJob1Id}
          isStatic={props.isStatic}
        />
      )}
    </TablePlaceHolder>
  );
});
