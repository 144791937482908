import React, { ReactElement, useEffect, useState } from "react";
import { ClientFamilyQuick } from "./clientFamily/ClientFamilyQuick";
import { GoalsQuick } from "./goals/GoalsQuick";
import { QuickInputSection } from "./QuickInputSection";
import { AssetsQuick } from "./assets/AssetsQuick";
import { SocialSecurityQuick } from "./socialSecurity/SocialSecurityQuick";
import { AdditionalIncomeQuick } from "./additionalIncome/AdditionalIncomeQuick";
import { RiskQuick } from "./risk/RiskQuick";
import { HealthcareQuick } from "./healthcare/HealthcareQuick";
import { RealEstateQuick } from "./realEstate/RealEstateQuick";
import {
  RootPage,
  RootPageHeader,
  RootPageHeaderLink,
  RootPageTransparentPaper,
} from "components/RootPage";
import { useClientPlan } from "features/planResults/useClientPlan";
import { useAppDispatch } from "app/hooks";
import { useFinancialPlanNavigate } from "features/general/useFinancialPlanNavigate";
import { financialPlanningPages } from "route.const";
import { useClientId, usePlanId } from "app/usePlanId";
import { usePlanTypes } from "app/usePlanTypes";
import { showToast } from "app/toastSlice";
import { CarButton } from "components/Buttons";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useScrollTo } from "app/useScrollToTop";
import { pendoClasses } from "app/thirdParty/pendo";
import { useClientUpdateLastReviewed } from "app/useClientUpdateLastReviewed";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { TablePlaceHolder } from "components/PlaceHolder";

const PlanInputHeader = () => {
  const clientPlan = useClientPlan();
  const clientId = useClientId();
  const planId = usePlanId();
  const scrollTo = useScrollTo();

  const currentPageLabel = "Plan Input";
  const appTitle = clientPlan.isLoading
    ? currentPageLabel
    : `${clientPlan.planName} - ${currentPageLabel}`;

  const dispatch = useAppDispatch();
  const nav = useFinancialPlanNavigate();
  const planTypes = usePlanTypes();

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [update, setUpdate] = useState(0);

  const canGoToWorkbench = () => {
    if (nav.isLoading) {
      return false;
    }
    if (nav.canGoToWorkbench) {
      return true;
    } else {
      dispatch(
        showToast({
          kind: "errorMessage",
          message: `The "${
            nav.invalidSection?.label ?? "section"
          }" is incomplete. Please fill in this section completely.`,
        }),
      );
      return false;
    }
  };

  const portalNode = document.getElementById("planInputNext");

  if (!portalNode) {
    // YS we need this to make sure that portal node has been rendered
    setTimeout(() => setUpdate((count) => count + 1), 100);
  }

  const isPlanPublished = !!clientPlan.publishedPlanTypeId;

  useEffect(() => {
    if (isPlanPublished) {
      navigate(financialPlanningPages.getClientSummaryUrl(clientId));
    }
  }, [isPlanPublished, navigate, clientId]);

  return (
    <>
      <RootPageHeader
        isSmall
        title={clientPlan.planName}
        appTitle={appTitle}
        isLoading={clientPlan.isLoading}
        links={
          <>
            <RootPageHeaderLink
              label="Client Summary"
              url={financialPlanningPages.getClientSummaryUrl(clientId)}
            />
            <RootPageHeaderLink
              label="Plan Input"
              url={financialPlanningPages.getPlanInputUrl(clientId, planId)}
            />
            <RootPageHeaderLink
              label="Workbench"
              url={financialPlanningPages.getWorkbenchUrl(
                clientId,
                planId,
                planTypes.items[0]?.id ?? "",
              )}
              disabled={nav.isLoading}
              onClick={(e) => {
                if (!canGoToWorkbench()) {
                  e.preventDefault();
                }
              }}
            />
            <RootPageHeaderLink
              label="Progress Monitoring"
              url={financialPlanningPages.getProgressMonitoringUrl(clientId)}
            />
          </>
        }
      />
      {portalNode &&
        createPortal(
          <CarButton
            disabled={nav.isLoading}
            onClick={(e) => {
              if (canGoToWorkbench()) {
                navigate(
                  financialPlanningPages.getWorkbenchUrl(
                    clientId,
                    planId,
                    planTypes.items[0]?.id ?? "",
                  ),
                );
                scrollTo("top");
              }
            }}
          >
            Next
          </CarButton>,
          portalNode,
        )}
    </>
  );
};

const sections: Array<{
  id: string;
  label: string;
  labelClassName?: string;
  content: ReactElement;
}> = [
  {
    id: "clientFamily",
    label: "Client Family*",
    content: <ClientFamilyQuick />,
  },
  {
    id: "socialSecurity",
    label: "Social Security",
    content: <SocialSecurityQuick />,
  },
  {
    id: "healthcare",
    label: "After Retirement Healthcare",
    content: <HealthcareQuick />,
  },
  {
    id: "additionalIncome",
    label: "Additional Income",
    content: <AdditionalIncomeQuick />,
  },
  {
    id: "assets",
    label: "Assets*",
    content: <AssetsQuick />,
  },
  {
    id: "realEstateCurrent",
    label: "Real Estate - Current Properties",
    content: <RealEstateQuick isFuture={false} />,
  },
  {
    id: "realEstateFuture",
    label: "Real Estate - Future Properties",
    content: <RealEstateQuick isFuture={true} />,
  },
  {
    id: "goals",
    label: "Goals*",
    content: <GoalsQuick />,
  },
  {
    id: "risk",
    label: "Profiled Risk*",
    content: <RiskQuick />,
  },
];

export const PlanInputPage = () => {
  useClientUpdateLastReviewed();
  const pageContent = usePageContent();

  return (
    <RootPage sx={{ paddingBottom: 0, backgroundColor: "white" }}>
      <PlanInputHeader />
      <RootPageTransparentPaper>
        <TablePlaceHolder
          isLoading={pageContent.isLoading}
          sx={{ mt: 5 }}
          rows={8}
        >
          <Typography variant="h2SSemiBold" sx={{ mt: 3 }}>
            Plan Input
          </Typography>
          <CarPageContent
            sx={{ mt: 3 }}
            content={pageContent.getContent(
              CarPageContentCode.PLANNING_PLAN_INPUT_DIRECTIONS,
            )}
          />

          {sections.map((section) => (
            <QuickInputSection
              key={section.id}
              id={section.id}
              label={section.label}
              pendoClassName={pendoClasses.planInputSection(section.id)}
              content={section.content}
            />
          ))}
          <Box id="planInputNext" sx={{ mt: 3, alignSelf: "center" }} />
        </TablePlaceHolder>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
