import { usePortfolioGetPortfolioModuleDataCreateQuery } from "api/carApi.generated";

export interface ExtStatItemResult {
  type: string;
  index: any[];
  values: number[];
}

export const usePortfolioModuleData = ({
  portfolioModuleDataIds,
  skip,
}: {
  skip?: boolean;
  portfolioModuleDataIds: string[];
}) => {
  const portfolioModuleData = usePortfolioGetPortfolioModuleDataCreateQuery(
    {
      portfolioModuleDataStatisticSetBatchViewRequests: {
        portfolio_module_data_ids: portfolioModuleDataIds,
      },
    },
    {
      skip: skip || portfolioModuleDataIds.length === 0,
    },
  );

  const items = portfolioModuleData.data ?? [];

  const getStatItemResult = (params: {
    moduleDataId?: string;
    type: string;
    maxFee?: boolean;
  }) => {
    const extStat = items?.find(
      (i) =>
        i.portfolio_module_data_id === params.moduleDataId &&
        (params.maxFee ? !!i.fee_pct_annual : !i.fee_pct_annual),
    )?.extended_statistics as ExtStatItemResult[] | undefined;

    return Array.isArray(extStat) // bug on BE, might return empty object instead of array
      ? extStat.find((ir) => ir.type === params.type)
      : undefined;
  };

  const getRiskStatData = (params: {
    moduleDataId?: string;
    riskStatTypeId?: string;
    maxFee?: boolean;
  }) =>
    items
      ?.find(
        (i) =>
          i.portfolio_module_data_id === params.moduleDataId &&
          (params.maxFee ? !!i.fee_pct_annual : !i.fee_pct_annual),
      )
      ?.risk_stat_results?.find(
        (i) => i.risk_stat_type_id === params.riskStatTypeId,
      );

  const getHistoricStatData = (params: {
    moduleDataId?: string;
    returnScenarioId?: string;
  }) =>
    items
      ?.find((i) => i.portfolio_module_data_id === params.moduleDataId)
      ?.historic_results?.find((i) => i.scenario_id === params.returnScenarioId)
      ?.observed_return;

  const getSimulationJobId = (params: { moduleDataId?: string }) =>
    items?.find((i) => i.portfolio_module_data_id === params.moduleDataId)
      ?.simulation_job_id;

  return {
    isLoading: portfolioModuleData.isLoading,
    isFetching: portfolioModuleData.isFetching,
    getStatItemResult,
    getRiskStatData,
    getHistoricStatData,
    getSimulationJobId,
  };
};
