import {
  useSimulationMarketDataFixedIncomeRetrieveQuery,
  useSimulationQaReportYieldsCreateQuery,
  useSimulationSimYieldCurvesListQuery,
} from "api/carApi.generated";
import { useMemo } from "react";
import {
  CarSimulationPercentileValue,
  CarSimulationStatItem,
  getSimulationPercentileExcelTable,
  simPercentileToTable,
} from "./useSimulationReturns";
import { useLocalStorage } from "features/general/useLocalStorage";
import { ExcelDataFormat, useExcelExport } from "app/useExcelExport";
import { formatDateTime, formatNumber } from "utils";
import { SimulationDataExplorerDialog } from "./SimulationDataExplorerDialog";
import { useDialog } from "app/useDialog";
import { SimulationYieldsDialog } from "./SimulationYieldsDialog";
import { YieldCurveItem, YieldCurveRate } from "./SimulationYieldsChart2d";
import { useSimulationAssetClassSelector } from "./useSimulationAssetClassSelector";

export enum SimulationTimeHorizon {
  short = "short",
  extended = "extended",
}

export const useSimulationYields = (params: { simulationJobId: string }) => {
  const assetClassSelector = useSimulationAssetClassSelector({
    storageKey: "useSimulationYields_assetClassSelector",
    simulationJobId: params.simulationJobId,
    isCashAndFixedIncomeOnly: true,
  });
  const excelExport = useExcelExport();
  const dialog = useDialog();

  const [timeHorizon, setTimeHorizon] = useLocalStorage<string>(
    "useSimulationYields_timeHorizon",
    SimulationTimeHorizon.short,
  );

  const yieldCurves = useSimulationSimYieldCurvesListQuery({
    simJobId: params.simulationJobId,
  });

  const reportYields = useSimulationQaReportYieldsCreateQuery(
    {
      simulationQaSummaryReportRequestAssetClass: {
        simulation_job_id: params.simulationJobId,
        asset_class_code: assetClassSelector.value ?? "",
      },
    },
    {
      skip: !assetClassSelector.value,
    },
  );

  const assetClassId = assetClassSelector.selectedItem?.level4Id ?? "";

  const summaryYields = useSimulationMarketDataFixedIncomeRetrieveQuery(
    {
      simulationJobId: params.simulationJobId,
      assetClassId,
    },
    {
      skip: !assetClassId,
    },
  );

  const data = reportYields.data;

  return useMemo(() => {
    const table = simPercentileToTable({
      simulationJobId: params.simulationJobId,
      assetClassCode: assetClassSelector.value,
      value: data?.asset_classes.at(0)?.sim_percentile,
    });

    const statResult = data?.asset_classes.at(0)?.stat_result;

    const tableStat: CarSimulationStatItem[] = [
      {
        label: "Input Volatility",
        value: statResult?.input_yield_sigma,
      },
      {
        label: "Output Volatility",
        value: statResult?.output_yield_sigma,
      },
      {
        label: "Current Yield",
        value: summaryYields.data?.current_yield,
      },
      {
        label: "Duration",
        value: summaryYields.data?.duration,
        format: ExcelDataFormat.float,
      },
      {
        label: "Convexity",
        value: summaryYields.data?.convexity,
        format: ExcelDataFormat.float,
      },
      {
        label: "OAS",
        value: summaryYields.data?.option_adjusted_spread,
        format: ExcelDataFormat.float,
      },
    ];

    const inflationTable = simPercentileToTable({
      simulationJobId: params.simulationJobId,
      value: data?.cpi.sim_percentile,
    });

    const inflationStatResult = data?.cpi.stat_result;

    const inflationTableStat: CarSimulationStatItem[] = [
      { label: "Starting Yield", value: inflationStatResult?.initial_yield },
      {
        label: "Output Volatility",
        value: inflationStatResult?.output_yield_sigma,
      },
    ];

    const assetClassName = assetClassSelector.selectedItem?.name ?? "";

    const handleDownloadReports = () => {
      excelExport.exportAllExcel({
        fileName: `Simulation Yields - ${data?.simulation_name} - ${formatDateTime(
          data?.simulation_date,
        )
          .replaceAll("/", "-")
          .replaceAll(":", "-")} - ${assetClassName}`,
        tables: [
          getSimulationPercentileExcelTable({
            name: assetClassName,
            table,
          }),
          getSimulationPercentileExcelTable({
            name: `Inflation`,
            table: inflationTable,
          }),
        ],
      });
    };

    const handleValueClick = (value: CarSimulationPercentileValue) => {
      dialog(SimulationDataExplorerDialog, {
        title: assetClassName,
        subTitle: `${formatNumber(
          value.percentile,
        )}th percentile for Yields in Year ${value.year}`,
        simulationJobId: table.simulationJobId,
        assetClassCode: table.assetClassCode,
        filterDataType: "yields",
        filterYear: value.year,
        filterValueGt: value.simLowerBound ?? undefined,
        filterValueLt: value.simUpperBound ?? undefined,
      });
    };

    const handlePercentileClick = (percentile: number) => {
      dialog(SimulationYieldsDialog, {
        title: assetClassName,
        subTitle: `${formatNumber(percentile)}th Percentile`,
        simulationJobId: table.simulationJobId,
        isExtended: timeHorizon === SimulationTimeHorizon.extended,
        percentile,
      });
    };

    const handleYearClick = (year: number) => {
      dialog(SimulationYieldsDialog, {
        title: assetClassName,
        subTitle: `${formatNumber(year)} Year`,
        simulationJobId: table.simulationJobId,
        year,
      });
    };

    const yieldCurveItems =
      yieldCurves.data?.map<YieldCurveItem>((i) => ({
        id: i.yield_curve_type.id ?? "",
        label: i.yield_curve_type.name,
        rates: i.rates.map<YieldCurveRate>((r) => ({
          position: {
            id: r.index.position.id ?? "",
            label: r.index.position.label ?? "",
            position: r.index.position.position ?? 0,
          },
          rate: r.rate,
        })),
      })) ?? [];

    return {
      isLoading:
        reportYields.isLoading ||
        summaryYields.isLoading ||
        yieldCurves.isLoading,
      isFetching:
        reportYields.isFetching ||
        summaryYields.isFetching ||
        yieldCurves.isFetching,
      table,
      tableStat,
      inflationTable,
      inflationTableStat,
      assetClassSelector,
      yieldCurveItems,
      assetClassName,
      handleDownloadReports,
      handleValueClick,
      handlePercentileClick,
      handleYearClick,
      timeHorizon,
      setTimeHorizon,
    };
  }, [
    params.simulationJobId,
    data?.asset_classes,
    data?.cpi.sim_percentile,
    data?.cpi.stat_result,
    data?.simulation_name,
    data?.simulation_date,
    summaryYields.data?.current_yield,
    summaryYields.data?.duration,
    summaryYields.data?.convexity,
    summaryYields.data?.option_adjusted_spread,
    summaryYields.isLoading,
    summaryYields.isFetching,
    assetClassSelector,
    yieldCurves.data,
    yieldCurves.isLoading,
    yieldCurves.isFetching,
    reportYields.isLoading,
    reportYields.isFetching,
    excelExport,
    dialog,
    timeHorizon,
    setTimeHorizon,
  ]);
};
