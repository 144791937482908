import { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "api/carApi.manual";
import { useDialog } from "app/useDialog";
import { useClientId, usePlanId } from "app/usePlanId";
import { useRenderJobStatus } from "app/useRenderJobStatus";
import { RenderErrorDialog } from "components/RenderErrorDialog";
import { financialPlanningPages } from "route.const";
import { setDisplayName } from "utils";

interface RenderErrorHandlerProps {
  isPlanPublished: boolean;
}

export const RenderErrorHandler = memo(
  ({ isPlanPublished }: RenderErrorHandlerProps) => {
    const { hasRenderError, errorMessage } = useRenderJobStatus({
      suppressRefetch: true,
    });
    const dialog = useDialog();
    const errorHasBeenShown = useRef(false);
    const navigate = useNavigate();
    const planId = usePlanId();
    const clientId = useClientId();
    const dispatch = useDispatch();

    useEffect(() => {
      if (hasRenderError && !errorHasBeenShown.current) {
        errorHasBeenShown.current = true;

        dialog(RenderErrorDialog, { errorMessage }).then(() => {
          if (planId && clientId) {
            navigate(financialPlanningPages.getPlanInputUrl(clientId, planId));

            setTimeout(() => {
              dispatch(
                api.util.invalidateTags(["RenderJobStatus", "AlEngineRender"])
              );
            }, 1000);
          }
        });
      }
    }, [
      dispatch,
      dialog,
      hasRenderError,
      navigate,
      planId,
      clientId,
      errorMessage,
    ]);

    return <></>;
  }
);

setDisplayName({ RenderErrorHandler });
