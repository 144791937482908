import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo, useState } from "react";
import { CarSelectOption, CarSelectOptionField } from "components/Inputs";
import {
  SimulationTimeHorizon,
  useSimulationYields,
} from "./useSimulationYields";
import { SimulationPercentileTable } from "./SimulationPercentileTable";
import { CarDownloadButton } from "components/Buttons";
import { SimulationStatBox } from "./SimulationStatBox";
import { CarAssetClassSelector } from "components/AssetClassSelector";
import { SimulationYieldsChart2d } from "./SimulationYieldsChart2d";
import { CollapseSection } from "./SimulationSummary";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { isDefined } from "utils";

interface SimulationYieldsProps {
  simulationJobId: string;
}

const timeHorizonOptions: CarSelectOption<string>[] = [
  {
    value: SimulationTimeHorizon.short,
    label: "Short",
  },
  {
    value: SimulationTimeHorizon.extended,
    label: "Extended",
  },
];

export const SimulationYields = memo((props: SimulationYieldsProps) => {
  const data = useSimulationYields({
    simulationJobId: props.simulationJobId,
  });

  const [hiddenDatasetIds, setHiddenDatasetIds] = useState<string[]>([]);

  const pageContent = usePageContent();

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 5 }}
      isLoading={data.assetClassSelector.isLoading || pageContent.isLoading}
      rows={8}
    >
      <CarPageContent
        sx={{ mb: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.SIMULATION_ANALYSIS_YIELDS,
        )}
        isWhiteContext
      />
      <Box sx={{ mt: 1, display: "flex", gap: 5, alignItems: "flex-end" }}>
        <CarAssetClassSelector
          label="Asset Class"
          sx={{
            width: 250,
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              marginTop: "28px",
              bgcolor: "white",
            },
          }}
          {...data.assetClassSelector}
        />
        <CarSelectOptionField
          label="Time Horizon"
          sx={{
            width: 216,
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              marginTop: "28px",
              bgcolor: "white",
            },
          }}
          value={data.timeHorizon}
          onChange={(value) => isDefined(value) && data.setTimeHorizon(value)}
          options={timeHorizonOptions}
        />
        <CarDownloadButton
          sx={{ ml: "auto", mb: 0.5 }}
          onClick={data.handleDownloadReports}
        >
          Download Reports
        </CarDownloadButton>
      </Box>
      <TablePlaceHolder sx={{ mt: 5 }} isLoading={data.isFetching} rows={5}>
        {data.assetClassSelector.value && (
          <>
            <Typography variant="subhSemiBold" sx={{ mt: 5 }}>
              {data.assetClassName}
            </Typography>
            <SimulationStatBox
              sx={{
                mt: 1,
              }}
              items={data.tableStat}
            />
            <CollapseSection label="Interactive Chart">
              <SimulationYieldsChart2d
                hiddenDatasetIds={hiddenDatasetIds}
                setHiddenDatasetIds={setHiddenDatasetIds}
                data={data.yieldCurveItems}
                datasetsLabel=""
                isWhiteContext
              />
            </CollapseSection>
            <SimulationPercentileTable
              sx={{ mt: 5 }}
              table={data.table}
              isExtended={data.timeHorizon === SimulationTimeHorizon.extended}
              onValueClick={data.handleValueClick}
              onPercentileClick={data.handlePercentileClick}
              onYearClick={data.handleYearClick}
            />
            <SimulationStatBox
              sx={{
                mt: 5,
              }}
              items={data.inflationTableStat}
            />
            <Typography variant="subhSemiBold" sx={{ mt: 5, mb: 1 }}>
              Inflation
            </Typography>
            <SimulationPercentileTable
              table={data.inflationTable}
              isExtended={data.timeHorizon === SimulationTimeHorizon.extended}
              onValueClick={data.handleValueClick}
            />
          </>
        )}
      </TablePlaceHolder>
    </TablePlaceHolder>
  );
});
