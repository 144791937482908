import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCommonAuthAcceptInvitationCreateMutation } from "api/carApi.generated";
import { setAuthSession } from "features/auth/authSlice";
import { debugLog } from "utils";
import { showToast } from "app/toastSlice";
import { useParams } from "react-router";
import { rootPages } from "route.const";

export interface CarRegisterForm {
  requiredMode?: boolean;
  firstName?: string;
  lastName?: string;
  password?: string;
  confirmPassword?: string;
}

export const useAcceptInvitation = () => {
  const navigate = useNavigate();

  const { invitationSecret } = useParams();

  const dispatch = useDispatch();

  const [acceptInvitationReq] = useCommonAuthAcceptInvitationCreateMutation();

  const [registerForm, setRegisterForm] = useState<CarRegisterForm>({});

  const register = async () => {
    if (!invitationSecret) {
      return;
    }

    if (
      [
        registerForm.firstName?.trim(),
        registerForm.lastName?.trim(),
        registerForm.password?.trim(),
        registerForm.confirmPassword?.trim(),
      ].some((i) => !i)
    ) {
      setRegisterForm((value) => ({ ...value, requiredMode: true }));
      dispatch(
        showToast({
          kind: "error",
          message: "Please complete all fields",
        })
      );

      return;
    }
    if (
      registerForm.password?.trim() !== registerForm.confirmPassword?.trim()
    ) {
      dispatch(
        showToast({
          kind: "error",
          message: "Passwords do not match",
        })
      );

      return;
    }

    const response = await acceptInvitationReq({
      acceptInvitation: {
        secret_string: invitationSecret,
        first_name: registerForm.firstName?.trim() ?? "",
        last_name: registerForm.lastName?.trim() ?? "",
        password: registerForm.password?.trim() ?? "",
      },
    });

    if ("data" in response) {
      debugLog(
        "acceptInvitation response",
        JSON.stringify(response.data, null, 5)
      );
      dispatch(setAuthSession(response.data));

      navigate(rootPages.home);
    }
  };

  return {
    registerForm,
    setRegisterForm,
    register,
  };
};
