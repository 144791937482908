import { Box, styled } from "@mui/material";
import { CarTableMultiRow } from "components/Table";
import { columns } from "./ManualAccountColumns";
import { ManualAccountRowProps } from "./manual-account-types";
import { Savings } from "./Savings";
import { ManualAccountRowDetailedHoldingsPositionTable } from "./ManualAccountRowDetailedHoldingsPositionTable";
import { memo } from "react";
import { setDisplayName } from "utils";

const StyledRoot = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const ManualAccountRowDetailedHoldings = memo(
  (props: ManualAccountRowProps) => (
    <StyledRoot className={props.className}>
      <CarTableMultiRow
        item={props.item}
        rows={[
          [
            columns.manualType,
            columns.owner,
            columns.description,
            columns.accountType,
            columns.savings,
          ],
        ]}
        onChange={props.onChange}
        onDelete={props.onDelete}
        pendoPrefix={props.pendoPrefix}
      />
      <Savings item={props.item} onChange={props.onChange} />
      <ManualAccountRowDetailedHoldingsPositionTable
        item={props.item}
        onChange={props.onChange}
      />
    </StyledRoot>
  )
);

setDisplayName({ ManualAccountRowDetailedHoldings });
