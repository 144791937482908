import { Typography, styled, Box } from "@mui/material";
import {
  CarNotificationSetting,
  CarNotificationTierSetting,
  UseAlerts,
} from "./useSettingsNotifications";
import clsx from "clsx";
import { CarStatusSuccess, CarStatusPartial } from "icons";

const gridTemplateColumns = "2.5fr 1fr 1fr 1fr";

interface CheckProps {
  active?: boolean;
  required?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}

const Check = styled((props: CheckProps) => {
  const { onClick, className, active } = props;
  return (
    <span className={className} onClick={onClick}>
      {active ? (
        <CarStatusSuccess className="check-icon" />
      ) : (
        <CarStatusPartial className="check-icon" />
      )}
    </span>
  );
})(({ theme, active, required }) => ({
  position: "relative",
  "&:after": !required
    ? {}
    : {
        content: "'*'",
        position: "absolute",
        top: -2,
        right: -2,
        fontSize: 28,
      },
  ".check-icon": {
    color: active ? theme.palette.green : theme.palette.disabled,
    cursor: required ? "default" : "pointer",
    width: 28,
    height: 28,
    margin: 10,
  },
}));

interface NotificationTierItemProps {
  value: CarNotificationTierSetting;
  onChange: (val: CarNotificationTierSetting) => void;
  isLast?: boolean;
}

const NotificationTierItem = (props: NotificationTierItemProps) => {
  const { value, onChange, isLast } = props;
  return (
    <>
      <Box
        className={clsx("cell", { "tier-underline": !isLast })}
        sx={{ pl: 2 }}
      >
        <Typography variant="par01Regular" color="softBlack">
          {value?.label}
        </Typography>
      </Box>
      <Box className={clsx("cell cell-center", { "tier-underline": !isLast })}>
        <Check
          active={value.enabled}
          // required={browserRequired}
          onClick={() => onChange({ ...value, enabled: !value.enabled })}
        />
      </Box>
      <Box
        className={clsx("cell cell-center", { "tier-underline": !isLast })}
      />
    </>
  );
};

interface NotificationRowProps {
  value: CarNotificationSetting;
  onNotificationTierSettingChange: (
    newValue: CarNotificationTierSetting,
  ) => void;
}

const NotificationRow = (props: NotificationRowProps) => {
  const { value, onNotificationTierSettingChange } = props;
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: gridTemplateColumns,
        backgroundColor: "white",
        border: "solid 1px",
        borderColor: "gray3",
        borderRadius: "5px",
        px: 4,
        py: 1,
      }}
    >
      <Box
        sx={{
          gridRow: value.tiers.length
            ? `span ${value.tiers.length}`
            : undefined,
          pr: 6,
          pt: 2.5,
        }}
      >
        <Box sx={{ pb: 2 }}>
          <Typography variant="h6SemiBold">{value.label}</Typography>
        </Box>
        <Box>
          <Typography variant="par02Regular" color="gray6">
            {value.description}
          </Typography>
        </Box>
      </Box>
      {value.tiers.map((item, idx, arr) => (
        <NotificationTierItem
          key={idx}
          value={item}
          onChange={onNotificationTierSettingChange}
          isLast={arr.length === idx + 1}
        />
      ))}
    </Box>
  );
};

const StyledContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  ".tier-underline": {
    borderBottom: "solid 1px",
    borderColor: theme.palette.gray3,
  },
  ".cell": {
    display: "flex",
    alignItems: "center",
  },
  ".cell-center": {
    justifyContent: "center",
  },
}));

export const SectionAlerts = ({ data }: { data: UseAlerts }) => {
  return (
    <StyledContent>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns,
          px: 4,
          mb: 2,
        }}
      >
        <Box
          className="cell cell-center"
          sx={{
            gridColumn: 3,
          }}
        >
          <Typography variant="h5SSemiBold">In App</Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {data.items.map((item) => (
          <NotificationRow
            key={item.notificationTypeId}
            value={item}
            onNotificationTierSettingChange={data.handleChange}
          />
        ))}
      </Box>
    </StyledContent>
  );
};
