import { SnackbarOrigin } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactElement } from "react";
import { RootState } from "./store";

export interface ToastState {
  open: boolean;
  kind: "error" | "info" | "success" | "errorMessage";
  title?: string;
  message: string;
  anchorOrigin?: SnackbarOrigin;
  renderToast?: (params: { onClose: () => void }) => ReactElement;
}

const initialState: ToastState = {
  open: false,
  kind: "info",
  message: "",
  anchorOrigin: {
    horizontal: "right",
    vertical: "bottom",
  },
};

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<Omit<ToastState, "open">>) => {
      return { ...initialState, ...action.payload, open: true };
    },
    hideToast: (state, action: PayloadAction<undefined>) => {
      state.open = false;
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;

export const selectToast = (state: RootState) => state.toast;

export const toastReducer = toastSlice.reducer;
