import { useCmaReturnScenariosListQuery } from "api/carApi.generated";
import { useMemo } from "react";

export const useReturnScenarios = () => {
  const { data, isLoading, isFetching } = useCmaReturnScenariosListQuery();

  return useMemo(() => {
    const items =
      data
        ?.filter((i) => i.is_active)
        .sort((a, b) => a.start_date?.localeCompare(b.start_date ?? "") ?? 0) ??
      [];

    return {
      isLoading,
      isFetching,
      items,
    };
  }, [data, isLoading, isFetching]);
};
