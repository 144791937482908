import { Fragment, ReactElement } from "react";
import { BoxProps, Typography } from "@mui/material";
import { SummaryGoal } from "features/planSummary/usePlanSummary";
import {
  formatCurrency,
  formatDate,
  isOddEven,
  priorityNumToString,
} from "utils";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { RootPageBox } from "components/RootPage";
import { CarDisclosure } from "components/Disclosure";

export interface PublishPlanGoalsProps {
  sx?: BoxProps["sx"];
  goals: SummaryGoal[];
  footer: ReactElement;
}

export const PublishPlanGoals = ({
  sx,
  goals,
  footer,
}: PublishPlanGoalsProps) => {
  return (
    <RootPageBox sx={{ p: 5, pb: 8, ...sx }}>
      <Typography variant="h4SSemiBold">Goals</Typography>
      <CarInfoTable
        sx={{
          mt: 3,
          gridTemplateColumns: "2fr 1fr 1fr 1fr 1fr",
        }}
      >
        <CarInfoHeader text="Goals & Priority" justifyContent="start" />
        <CarInfoHeader text="Start" />
        <CarInfoHeader text="End" />
        <CarInfoHeader text="*Annual Amount" />
        <CarInfoHeader text="*Total:" />
        {goals.map((i, idx) => (
          <Fragment key={i.id}>
            <CarInfoCell
              isOdd={isOddEven(idx)}
              text={`${i.description} (${priorityNumToString(i.priority)[0]})`}
              justifyContent="start"
            />
            <CarInfoCell
              isOdd={isOddEven(idx)}
              text={formatDate(i.startDate)}
            />
            <CarInfoCell isOdd={isOddEven(idx)} text={formatDate(i.endDate)} />
            <CarInfoCell
              isOdd={isOddEven(idx)}
              text={formatCurrency(i.annualAmount)}
            />
            <CarInfoCell
              isOdd={isOddEven(idx)}
              text={formatCurrency(i.totalAmountBeforeInflation)}
            />
          </Fragment>
        ))}
      </CarInfoTable>
      <CarDisclosure sx={{ mt: 2.5, ml: 2 }}>
        *Amounts are represented in today's dollars (pre-inflation).
      </CarDisclosure>
      {footer}
    </RootPageBox>
  );
};
