import { useGics } from "app/useGics";
import { usePortfolioGroupInfo } from "./usePortfolioGroupInfo";
import {
  CarPortfolioGroupVersionAllocation,
  usePortfolioGroupVersionAllocation,
} from "./usePortfolioGroupVersionAllocation";

export const usePortfolioGroupVersionSectorSummary = ({
  portfolioGroupId,
  data1Id,
  data2Id,
}: {
  portfolioGroupId?: string;
  data1Id?: string;
  data2Id?: string;
}) => {
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const portfolioGroupVersionAllocation = usePortfolioGroupVersionAllocation({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
  });

  const data1Allocations: CarPortfolioGroupVersionAllocation[] =
    portfolioGroupVersionAllocation.getDataAssetAllocations(data1Id) ?? [
      { level4Id: data1Id ?? "", value: 1 },
    ]; // if allocation not found assume that data1Id is asset class id

  const data2Allocations: CarPortfolioGroupVersionAllocation[] =
    portfolioGroupVersionAllocation.getDataAssetAllocations(data2Id) ?? [
      { level4Id: data2Id ?? "", value: 1 },
    ];

  const gics = useGics({
    onGetValue1: (groupWeights) =>
      groupWeights.reduce(
        (acc, i) =>
          acc +
          (i.weight ?? 0) *
            (data1Allocations.find((alloc) => alloc.level4Id === i.ac_level4)
              ?.value ?? 0),
        0,
      ),
    onGetValue2: (groupWeights) =>
      groupWeights.reduce(
        (acc, i) =>
          acc +
          (i.weight ?? 0) *
            (data2Allocations.find((alloc) => alloc.level4Id === i.ac_level4)
              ?.value ?? 0),
        0,
      ),
  });

  return {
    isLoading: portfolioGroupVersionAllocation.isLoading || gics.isLoading,
    report: gics.report,
  };
};
