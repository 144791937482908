import {
  Box,
  Slider,
  SliderProps,
  Typography,
  SliderThumb,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CircleSlice } from "icons";
import { addYears } from "date-fns";

const CustomSliderThumb = ({ ...props }: any) => {
  return (
    <SliderThumb {...props}>
      {props.children}
      <CircleSlice />
    </SliderThumb>
  );
};

interface SliderValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const SliderValueLabel = ({ children, open, value }: SliderValueLabelProps) => {
  return (
    <Box position="absolute" style={children.props.style}>
      <Box
        top={-50}
        left={-50}
        width={100}
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        hidden={!open}
      >
        {value}
      </Box>
      {children}
    </Box>
  );
};

const StyledSlider = styled((props: SliderProps) => (
  <Slider
    components={{
      ValueLabel: SliderValueLabel,
      Thumb: CustomSliderThumb,
    }}
    {...props}
  />
))(({ theme }) => ({
  ".MuiSlider-rail": {
    height: 16,
    borderRadius: 8,
    backgroundColor: theme.palette.gray3,
  },
  ".MuiSlider-track": {
    height: 16,
  },
  ".MuiSlider-mark": {
    display: "none",
  },
  ".MuiSlider-markLabel": {
    marginTop: theme.spacing(0.25),
    ...theme.typography.par01Regular,
  },
  ".MuiSlider-thumb": {
    width: 19,
    height: 19,
    top: theme.spacing(0.25),
  },
}));

const getMarks = (min: number, max: number) => {
  const getMarkInfo = (markGap: number) => {
    const minMark = Math.ceil(min / markGap) * markGap;
    const maxMark = Math.floor(max / markGap) * markGap;
    const marksCount = Math.floor((maxMark - minMark) / markGap) + 1;
    return { minMark, maxMark, marksCount, markGap };
  };

  let info = getMarkInfo(5);
  if (info.marksCount > 12) {
    info = getMarkInfo(10);
  }

  return Array(info.marksCount)
    .fill(0)
    .map((v, idx) => idx * info.markGap + info.minMark)
    .map((v) => ({ value: v, label: v.toString() }));
};
interface AgeSliderProps
  extends Omit<
    SliderProps,
    | "value"
    | "onChange"
    | "onChangeCommitted"
    | "valueLabelDisplay"
    | "max"
    | "min"
    | "valueLabelFormat"
    | "marks"
  > {
  retAge: number;
  liveUntil: number;
  onChange: (retAge: number, liveUntil: number) => void;
}

export const AgeSlider = ({
  retAge: propsRetAge,
  liveUntil: propsLiveUntil,
  onChange,
  ...props
}: AgeSliderProps) => {
  const minAge = 45;
  const maxAge = 110;

  const [retAge, setRetAge] = useState(propsRetAge);
  const [liveUntil, setLiveUntil] = useState(propsLiveUntil);

  useEffect(() => {
    setRetAge(propsRetAge);
    setLiveUntil(propsLiveUntil);
  }, [propsRetAge, propsLiveUntil]);

  const handleChange = ((e: any, newValue: number[]) => {
    setRetAge(newValue[0]);
    setLiveUntil(newValue[1]);
  }) as any;

  return (
    <StyledSlider
      value={[retAge, liveUntil]}
      onChange={handleChange}
      onChangeCommitted={() => onChange(retAge, liveUntil)}
      valueLabelDisplay="on"
      min={minAge}
      max={maxAge}
      valueLabelFormat={(v: number, index: number) => (
        <>
          <Typography variant="par03SemiBold">
            {index === 0 ? `Retirement Age` : `Live Until`}
          </Typography>
          <Typography variant="par02Regular">{v}</Typography>
        </>
      )}
      marks={getMarks(minAge, maxAge)}
      {...props}
    />
  );
};

interface AgeSliderSingleProps
  extends Omit<
    SliderProps,
    | "value"
    | "onChange"
    | "valueLabelDisplay"
    | "min"
    | "max"
    | "valueLabelFormat"
    | "marks"
  > {
  value: number;
  minAge: number;
  maxAge: number;
  onChange: (value: number) => void;
}

export const AgeSliderSingle = ({
  value,
  onChange,
  minAge,
  maxAge,
  ...props
}: AgeSliderSingleProps) => {
  const handleChange = ((e: any, newValue: number) => {
    onChange(newValue);
  }) as any;
  return (
    <StyledSlider
      value={value}
      onChange={handleChange}
      valueLabelDisplay="on"
      track={false}
      min={minAge}
      max={maxAge}
      marks={getMarks(minAge, maxAge)}
      valueLabelFormat={(v: number, index: number) => null}
      {...props}
    />
  );
};

interface YearSliderProps
  extends Omit<
    SliderProps,
    | "value"
    | "onChange"
    | "valueLabelDisplay"
    | "min"
    | "max"
    | "valueLabelFormat"
    | "marks"
  > {
  value: number;
  onChange: (value: number) => void;
}

const yearMarks = Array(5)
  .fill(0)
  .map((v, idx) => idx * 20 + 2030)
  .map((v) => ({ value: v, label: v.toString() }));

export const YearSlider = ({ value, onChange, ...props }: YearSliderProps) => {
  const handleChange = ((e: any, newValue: number) => {
    onChange(newValue);
  }) as any;
  const minYear = addYears(new Date(), 1).getFullYear();
  const maxYear = minYear + 99;

  return (
    <StyledSlider
      value={value}
      onChange={handleChange}
      valueLabelDisplay="on"
      min={minYear}
      track={false}
      max={maxYear}
      marks={yearMarks}
      valueLabelFormat={(v: number, index: number) => null}
      {...props}
    />
  );
};

interface CustomSliderProps
  extends Omit<
    SliderProps,
    "value" | "onChange" | "valueLabelDisplay" | "valueLabelFormat"
  > {
  value: number;
  onChange: (value: number) => void;
}

export const CustomSlider = ({
  value,
  onChange,

  ...props
}: CustomSliderProps) => {
  const handleChange = ((e: any, newValue: number) => {
    onChange(newValue);
  }) as any;

  return (
    <StyledSlider
      value={value}
      onChange={handleChange}
      valueLabelDisplay="on"
      track={false}
      marks={yearMarks}
      valueLabelFormat={(v: number, index: number) => null}
      {...props}
    />
  );
};
