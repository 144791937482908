import { ReactElement, useState } from "react";
import {
  IconButton,
  IconButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  styled,
} from "@mui/material";
import { DotsHorizontal } from "icons";

export interface CarMenuItem {
  label: string;
  icon: ReactElement;
  hidden?: boolean;
  onClick: () => void;
}
export interface CarMenuButtonProps
  extends Omit<IconButtonProps, "children" | "onClick" | "color" | "size"> {
  items: CarMenuItem[];
  position?: "left" | "right";
  renderButton?: (props: CarMenuButtonProps) => ReactElement;
}

export const StyledMenuButtonMenu = styled(Menu)(
  ({ theme, transformOrigin }) => ({
    ".MuiPaper-root": {
      border: "solid 1px",
      borderColor: theme.palette.gray6,
      borderRadius: "5px",
      overflow: "visible",
      "&::after": {
        left: transformOrigin?.horizontal === "left" ? 10 : undefined,
        right: transformOrigin?.horizontal === "right" ? 10 : undefined,
        top: -5,
        position: "absolute",
        content: '""',
        width: 10,
        height: 10,
        border: "solid 1px",
        borderColor: theme.palette.gray6,
        backgroundColor: theme.palette.white,
        transform: "rotate(-45deg)",
        clipPath: "polygon(0 0, 100% 0, 100% 100%)",
        zIndex: 1,
      },
      "& .MuiList-root": {
        paddingTop: theme.spacing(0.75),
        paddingBottom: theme.spacing(0.75),
      },
      ".MuiListItemIcon-root": {
        minWidth: 28,
        "& svg": {
          color: theme.palette.primary.main,
          fontSize: "1rem",
        },
      },
      ".MuiListItemText-primary": {
        ...theme.typography.par01SemiBold,
        fontSize: "13px",
      },
    },
  }),
);

export const CarMenuButton = (props: CarMenuButtonProps) => {
  const { items, position = "right", renderButton, ...rest } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const horizontal = position === "left" ? "right" : "left";

  const button = renderButton ? (
    renderButton({ items, ...rest, ...{ onClick: handleMenuClick } })
  ) : (
    <IconButton
      onClick={handleMenuClick}
      color="inherit"
      size="small"
      {...rest}
      sx={{ color: "primary.main", ...rest.sx }}
    >
      <DotsHorizontal />
    </IconButton>
  );

  return (
    <>
      {button}
      {open && (
        <StyledMenuButtonMenu
          anchorEl={anchorEl}
          open={open}
          onClose={(e: any) => {
            e.stopPropagation?.();
            e.preventDefault?.();
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal,
          }}
        >
          {items
            .filter((i) => !i.hidden)
            .map((i, idx) => (
              <MenuItem
                key={idx}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setAnchorEl(null);
                  i.onClick();
                }}
              >
                <ListItemIcon>{i.icon}</ListItemIcon>
                <ListItemText>{i.label}</ListItemText>
              </MenuItem>
            ))}
        </StyledMenuButtonMenu>
      )}
    </>
  );
};
