import { useAssetClassesFirmAssetClassesListQuery } from "api/carApi.generated";
import { useRender } from "app/useRender";
import { PriorityNumber } from "const";
import { CarPlanResult } from "types";
import { isDefined } from "utils";
import { usePlanTypes } from "app/usePlanTypes";
import { calcCapitalGainsAllocAssets } from "./calcCapitalGainsAllocAssets";

export interface CapitalGainsChartData {
  labels: Array<string>;
  datasets: Array<{ label: string; data: Array<number> }>;
}

export interface CapitalGainsColumn {
  id: string;
  label: string;
}
export interface CapitalGainsScenario {
  columns: Array<CapitalGainsColumn>;
  currentPortfolio: Array<number>;
  capitalGainTaxes: Array<number>;
  afterTaxPortfolioBalance: Array<number>;
}

export interface CapitalGainsPlanResults {
  needs: Array<number>;
  needsAndWants: Array<number>;
  needsWantsAndDreams: Array<number>;
  surplusDeficit: Array<number>;
}

export const useCapitalGains = () => {
  const {
    isLoading,
    resultItems,
    inflated: { getInflatedValue },
  } = useRender();
  const assetClasses = useAssetClassesFirmAssetClassesListQuery();
  const planTypes = usePlanTypes();

  const getPlanResult = (resultType?: string) =>
    resultItems.find((i) => i.result_type === resultType);

  const columns = planTypes.items
    .filter((i) => getPlanResult(i.id))
    .map<CapitalGainsColumn>((i) => ({
      id: i.id ?? "",
      label: i.label,
    }));

  const sortedPlanResults = columns
    .map((i) => getPlanResult(i.id))
    .filter(isDefined);

  const capitalGainsScenario: CapitalGainsScenario = {
    columns,
    currentPortfolio: sortedPlanResults.map(
      (i) => i.cap_gains_current_invested_amount
    ),
    capitalGainTaxes: sortedPlanResults.map((i) => i.cap_gains_cg_taxes_paid),
    afterTaxPortfolioBalance: sortedPlanResults.map(
      (i) => i.cap_gains_after_tax_portfolio_balance
    ),
  };

  const calcGoalSuccess = (pr: CarPlanResult, goalPriorities: number[]) => {
    const totalGoals = pr.goal_results.filter((i) =>
      goalPriorities.includes(i.base_goal_priority)
    );
    const successfulGoals = totalGoals.filter(
      (i) => i.goal_status === "success"
    ).length;

    return totalGoals.length > 0
      ? (successfulGoals / totalGoals.length) * 100
      : 100;
  };

  const planResults: CapitalGainsPlanResults = {
    needs: sortedPlanResults.map((i) =>
      calcGoalSuccess(i, [PriorityNumber.Need])
    ),
    needsAndWants: sortedPlanResults.map((i) =>
      calcGoalSuccess(i, [PriorityNumber.Need, PriorityNumber.Want])
    ),
    needsWantsAndDreams: sortedPlanResults.map((i) =>
      calcGoalSuccess(i, [
        PriorityNumber.Need,
        PriorityNumber.Want,
        PriorityNumber.Dream,
      ])
    ),
    surplusDeficit: columns.map((c) => {
      const pr = getPlanResult(c.id);

      return getInflatedValue(
        pr?.surplus_deficit_total,
        pr?.surplus_deficit_total_deflated
      );
    }),
  };

  const averagePlanResult = planResults.needs.map(
    (i, idx) =>
      (i +
        planResults.needsAndWants[idx] +
        planResults.needsWantsAndDreams[idx]) /
      3
  );

  const weightedAverageChart: CapitalGainsChartData = {
    labels: columns.map((c) => c.label ?? ""),
    datasets: [
      {
        label: "data",
        data: columns.map((c, idx) => averagePlanResult[idx]),
      },
    ],
  };
  const surplusDeficitChart: CapitalGainsChartData = {
    labels: columns.map((c) => c.label ?? ""),
    datasets: [
      {
        label: "data",
        data: columns.map((c, idx) => planResults.surplusDeficit[idx]),
      },
    ],
  };

  const summaryAssetAllocations = calcCapitalGainsAllocAssets(
    assetClasses.data ?? [],
    sortedPlanResults
  );

  return {
    isLoading: isLoading || assetClasses.isLoading,
    weightedAverageChart,
    surplusDeficitChart,
    capitalGainsScenario,
    planResults,
    summaryAssetAllocations,
  };
};

export type UseCapitalGains = ReturnType<typeof useCapitalGains>;
