import { Box, SvgIcon, Typography } from "@mui/material";
import { UseLogin } from "./useLogin";
import { LoginButton, LoginTransparentBox } from "./LoginComponents";
import { VerificationCode } from "./VerificationCode";

export const VerifyEmailForm = ({ data }: { data: UseLogin }) => {
  return (
    <LoginTransparentBox
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        pt: 10,
      }}
    >
      <SvgIcon
        color="inherit"
        sx={{
          mt: -1,
          mx: "auto",
          width: 55,
          height: 44,
          color: "softBlack",
        }}
        viewBox="0 0 40 32"
      >
        <path
          d="M3 32C2.2 32 1.5 31.7 0.9 31.1C0.3 30.5 0 29.8 0 29V3C0 2.2 0.3 1.5 0.9 0.9C1.5 0.3 2.2 0 3 0H37C37.8 0 38.5 0.3 39.1 0.9C39.7 1.5 40 2.2 40 3V29C40 29.8 39.7 30.5 39.1 31.1C38.5 31.7 37.8 32 37 32H3ZM20 16.9L3 5.75V29H37V5.75L20 16.9ZM20 13.9L36.8 3H3.25L20 13.9ZM3 5.75V3V5.75V29V5.75Z"
          fill="currentColor"
        />
      </SvgIcon>

      <Typography variant="h3SemiBold" sx={{ ml: 2, mt: 2.5 }}>
        We just sent you an email.
      </Typography>
      <Typography variant="par02Regular" sx={{ ml: 3, mt: 1 }}>
        Please enter the code below.
      </Typography>
      <Typography
        sx={{
          mt: 9,
          ml: 3,
        }}
        variant="mediumItalic"
      >
        Confirmation Code
      </Typography>
      <VerificationCode
        sx={{ mt: 4.5, mx: "auto" }}
        value={data.verificationCode}
        onChange={data.setVerificationCode}
      />

      <LoginButton
        sx={{
          mt: 9,
          mx: "auto",
          minWidth: 200,
        }}
        disabled={!data.canVerifyEmail}
        onClick={data.verifyEmail}
      >
        Verify
      </LoginButton>
      <Box sx={{ mt: 2, mb: 1.5, mx: "auto", display: "flex" }}>
        <Typography
          variant="par03Regular"
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={data.resendCode}
        >
          Resend code
        </Typography>
        {/* <Typography variant="par03Regular" sx={{ mx: 0.5 }}>
          or
        </Typography>
        <Typography
          variant="par03Regular"
          sx={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={data.logOut}
        >
          Logout
        </Typography> */}
      </Box>
    </LoginTransparentBox>
  );
};
