import { useEffect, useMemo } from "react";
import {
  useAlEngineRenderPlanResultJobStatusListQuery,
  useAlEngineStressTestScenarioTypeListQuery,
  PlanResultJob,
} from "api/carApi.generated";
import { refetchOnFocus } from "const";
import { isDefined } from "utils";
import { useAppSelector } from "./hooks";
import { usePlanId } from "./usePlanId";
import { QueryStatus } from "@reduxjs/toolkit/query";

export const useRenderJobStatus = ({
  suppressRefetch,
  skip,
}: {
  suppressRefetch?: boolean;
  skip?: boolean;
}) => {
  const planId = usePlanId();

  const isPlanUpdating = useAppSelector((state) => state.pending.isUpdating);

  const scenarioList = useAlEngineStressTestScenarioTypeListQuery();

  const jobStatus = useAlEngineRenderPlanResultJobStatusListQuery(
    { id: planId },
    {
      refetchOnFocus,
      skip: isPlanUpdating || scenarioList.isLoading || skip,
    },
  );

  const { refetch } = jobStatus;

  const isBaseJob = (job: PlanResultJob) =>
    scenarioList.data?.find((i) => i.id === job.stress_scenario_type)?.code ===
    "BASE";

  // console.log(
  //   `Base: ${jobStatus.data
  //     ?.filter(isBaseJob)
  //     .map((i) => i.status)
  //     .join(",")}, Other:${jobStatus.data
  //     ?.filter((i) => !isBaseJob(i))
  //     .map((i) => i.status)
  //     .join(",")}`
  // );

  const isRenderCalcDone =
    !scenarioList.isLoading &&
    !jobStatus.isFetching &&
    jobStatus.data &&
    jobStatus.data
      .filter(isBaseJob)
      .every((i) => i.status === "C" || i.status === "E");

  const error = jobStatus.error as any;
  let errorMessage: string | undefined = Array.isArray(error?.data)
    ? error.data[0]
    : undefined;

  if (typeof errorMessage !== "string") {
    errorMessage = undefined;
  }

  const hasRenderError =
    !jobStatus.isFetching &&
    (isDefined(jobStatus.error) ||
      jobStatus.data?.filter(isBaseJob).some((i) => i.status === "E"));

  const canRender = !isPlanUpdating && isRenderCalcDone;
  const isFetching = jobStatus.isFetching;

  useEffect(() => {
    if (
      suppressRefetch ||
      jobStatus.isFetching ||
      isRenderCalcDone ||
      hasRenderError
    ) {
      return;
    }

    if (jobStatus.status !== QueryStatus.uninitialized) {
      const timeout = setTimeout(refetch, 500);

      return () => clearTimeout(timeout);
    }
  }, [
    suppressRefetch,
    refetch,
    isRenderCalcDone,
    jobStatus.status,
    jobStatus.data,
    jobStatus.isFetching,
    hasRenderError,
  ]);

  return useMemo(() => {
    return {
      canRender,
      isRenderCalcDone,
      hasRenderError,
      isLoading: !isRenderCalcDone,
      isFetching,
      refetch,
      errorMessage,
    };
  }, [
    canRender,
    hasRenderError,
    isRenderCalcDone,
    refetch,
    isFetching,
    errorMessage,
  ]);
};
