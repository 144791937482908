import { Collapse, styled } from "@mui/material";
import { AddRowButton } from "components/Buttons";
import { CarAccount } from "types";
import { addAnnualSavings } from "features/assets/account.utils";
import { SavingsTable } from "./SavingsTable";
import { theme } from "theme";

export interface SavingsProps {
  item: CarAccount;
  onChange: (item: CarAccount) => void;
}

const StyledCollapse = styled(Collapse)({
  marginTop: theme.spacing(3.5),
  alignSelf: "flex-end",
  width: 700,
  ".MuiCollapse-wrapperInner": {
    display: "flex",
    flexDirection: "column",
  },
  ".add-row": {
    marginTop: theme.spacing(1.5),
    alignSelf: "flex-end",
  },
});

export const Savings = (props: SavingsProps) => (
  <StyledCollapse in={props.item.is_saving}>
    <SavingsTable item={props.item} onChange={props.onChange} />
    <AddRowButton
      className="add-row"
      onClick={() => props.onChange(addAnnualSavings(props.item))}
    >
      Add Savings
    </AddRowButton>
  </StyledCollapse>
);
