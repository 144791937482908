import { useEffect, useMemo, useState } from "react";
import { useAssetClassGroups } from "app/useAssetClassGroups";

export const useAssetClassGroupSelector = () => {
  const acGroups = useAssetClassGroups();

  const [groupId, setGroupId] = useState<string | undefined>();

  useEffect(() => {
    if (!groupId && acGroups.groups.length > 0) {
      setGroupId(acGroups.groups[0].code);
    }
  }, [groupId, acGroups.groups]);

  return useMemo(() => {
    const groupIds =
      acGroups.groups
        .find((i) => i.code === groupId)
        ?.items.map((i) => i.id ?? "") ?? [];

    const isAssetClassInGroup = (assetClassLevel4Id?: string) =>
      groupIds.some((i) => i === assetClassLevel4Id);

    return {
      isLoading: acGroups.isLoading,
      items: acGroups.groups,
      value: groupId,
      onChange: setGroupId,
      isAssetClassInGroup,
    };
  }, [acGroups.groups, acGroups.isLoading, groupId]);
};
