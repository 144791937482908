import { useState } from "react";
import { useAssetClassExtendedStat } from "./useAssetClassExtendedStat";
import { useChartDataSelector } from "../assetAllocation/editPortfolioGroup/useChartDataSelector";
import { useSimulationSimulationJobRetrieveQuery } from "api/carApi.generated";
import { openInNewTab } from "utils";
import { rootPages } from "route.const";
import { ALL_ASSET_CLASSES_DATA_ID } from "../assetAllocation/assetClassSimulatedForwardProbabilitiesPdfReport/AssetClassSimulatedForwardProbabilitiesPdfReportPage";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

export const useSimulatedForwardProbabilitiesAC = (params: {
  simulationJobId1?: string;
  simulationJobId2?: string;
}) => {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [isReal, setIsReal] = useState(true);
  const [chartDateRange1, setChartDateRange1] = useState(20);
  const [chartDateRange2, setChartDateRange2] = useState(20);

  const job1Info = useSimulationSimulationJobRetrieveQuery(
    {
      id: params.simulationJobId1 ?? "",
    },
    {
      skip: !params.simulationJobId1,
    },
  );

  const job2Info = useSimulationSimulationJobRetrieveQuery(
    {
      id: params.simulationJobId2 ?? "",
    },
    {
      skip: !params.simulationJobId2,
    },
  );

  const data1 = useAssetClassExtendedStat({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: job1Info.data?.id,
  });

  const data2 = useAssetClassExtendedStat({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: job2Info.data?.id,
  });

  const enabledItems = data1.oneYearUnderPerf.filter((i) => !i.isDisabled);

  const chartDataSelector = useChartDataSelector({
    storageKey: "useSimulatedForwardProbabilitiesAC_chartDataSelector",
    data1DefaultId: enabledItems.at(0)?.id,
    data2DefaultId: enabledItems.at(1)?.id,
  });

  const handleShowPdf = () => {
    if (chartDataSelector.data1Id && chartDataSelector.data2Id) {
      openInNewTab(
        rootPages.getAssetClassSimulatedForwardProbabilitiesPdfReportUrl({
          data1Id: chartDataSelector.data1Id,
          data2Id: chartDataSelector.data2Id,
          isNominal: !isReal,
          isAdvanced,
          chartDateRange1,
          chartDateRange2,
        }),
      );
    }
  };

  const handleShowPdfAll = () => {
    if (chartDataSelector.data1Id && chartDataSelector.data2Id) {
      openInNewTab(
        rootPages.getAssetClassSimulatedForwardProbabilitiesPdfReportUrl({
          data1Id: ALL_ASSET_CLASSES_DATA_ID,
          data2Id: chartDataSelector.data2Id,
          isNominal: !isReal,
          isAdvanced,
          chartDateRange1,
          chartDateRange2,
        }),
      );
    }
  };

  return {
    isLoading:
      job1Info.isLoading ||
      job2Info.isLoading ||
      data1.isLoading ||
      data2.isLoading,
    handleShowPdf,
    handleShowPdfAll,
    isSameJob: params.simulationJobId1 === params.simulationJobId2,
    realHeatmap1: data1.realHeatmap,
    nominalHeatmap1: data1.nominalHeatmap,
    simulationSuffix1: job1Info.data?.is_static ? "Static" : "Price Driven",
    realHeatmap2: data2.realHeatmap,
    nominalHeatmap2: data2.nominalHeatmap,

    assetClasses1: data1.assetClasses,
    assetClasses2: data2.assetClasses,
    chartDataSelector: chartDataSelector,

    simulationSuffix2: job2Info.data?.is_static ? "Static" : "Price Driven",
    isAdvanced: isAdvanced,
    setIsAdvanced: setIsAdvanced,
    isReal: isReal,
    setIsReal: setIsReal,
    chartDateRange1: chartDateRange1,
    setChartDateRange1: setChartDateRange1,
    chartDateRange2: chartDateRange2,
    setChartDateRange2: setChartDateRange2,
  };
};

export type UseSimulatedForwardProbabilitiesAC = ReturnType<
  typeof useSimulatedForwardProbabilitiesAC
>;
