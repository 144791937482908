import { TransitionGroup } from "react-transition-group";
import {
  Box,
  BoxProps,
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { CarIconDeleteFilled } from "icons";
import { createPendoClassName } from "app/thirdParty/pendo";

export interface IdItem {
  id?: string;
  ui_key?: string;
}

export interface RenderCellProps<T extends IdItem> {
  item: T;
  onChange: (item: T) => void;
}

export interface RenderLabelProps<T extends IdItem> {
  item: T;
}

export interface CarTableColumn<T extends IdItem> {
  id: keyof T;
  label:
    | string
    | ReactElement
    | ((props: RenderLabelProps<T>) => string | ReactElement);
  fraction: number | string;
  renderCell: (props: RenderCellProps<T>) => ReactElement;
  cellSx?: BoxProps["sx"];
  headerSx?: BoxProps["sx"];
}

function getGridTemplateColumns<T extends IdItem>(
  columns: Array<CarTableColumn<T>>,
) {
  const result = columns
    .map((i) =>
      typeof i.fraction === "number" ? `${i.fraction}fr` : i.fraction,
    )
    .join(" ");
  return result;
}

const StyledRow = styled(Box)(({ theme }) => ({
  display: "grid",
  position: "relative",
  paddingLeft: 1,
  paddingTop: 1,
}));

const StyledHeaderCell = styled(Box)(({ theme }) => ({
  marginLeft: -1,
  marginTop: -1,
  border: `solid 1px ${theme.palette.gray6}`,
  backgroundColor: theme.palette.gray2,
  ...theme.typography.par01SemiBold,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
}));

const StyledCell = styled(Box)(({ theme }) => ({
  marginLeft: -1,
  marginTop: -1,
  border: `solid 1px ${theme.palette.gray6}`,
  backgroundColor: theme.palette.white,
  position: "relative",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "center",
  minWidth: 0,
}));

const StyledDeleteRowButton = styled(
  ({ onClick, ...props }: IconButtonProps) => {
    const [clicked, setClicked] = useState(false); // should not be clicked twice
    return (
      <IconButton
        onClick={(e) => {
          if (onClick && !clicked) {
            setClicked(true);
            onClick(e);
          }
        }}
        {...props}
        size="small"
        tabIndex={-1}
      >
        <CarIconDeleteFilled fontSize="inherit" />
      </IconButton>
    );
  },
)(({ theme }) => ({
  color: theme.palette.red,
  position: "absolute",
  top: "50%",
  left: 0,
  translate: "-100% -50%",
}));

export interface CarTableRowProps<T extends IdItem> {
  className?: string;
  sx?: BoxProps["sx"];
  columns: Array<CarTableColumn<T>>;
  item: T;
  isFirstRow: boolean;
  isLastRow: boolean;
  noGaps?: boolean;
  onChange: (item: T) => void;
  onCanDelete?: (item: T) => boolean;
  onDelete?: (item: T) => void;
  pendoPrefix?: string;
}

export type CarTableSpecificRowProps<T extends IdItem> = Omit<
  CarTableRowProps<T>,
  "className" | "sx" | "columns"
>;

export function CarTableRow<T extends IdItem>(props: CarTableRowProps<T>) {
  const header = props.isFirstRow
    ? props.columns.map((col, colIdx, colArr) => {
        const firstColumn = colIdx === 0;
        const lastColumn = colIdx === colArr.length - 1;

        return (
          <StyledHeaderCell
            key={`header_${String(col.id)}`}
            sx={col.headerSx}
            style={{
              gridRow: 1,
              gridColumn: colIdx + 1,
              borderTopLeftRadius: firstColumn ? "5px" : undefined,
              borderTopRightRadius: lastColumn ? "5px" : undefined,
            }}
            className={
              props.pendoPrefix
                ? createPendoClassName(`${props.pendoPrefix}_${String(col.id)}`)
                : undefined
            }
          >
            {typeof col.label === "function"
              ? col.label({ item: props.item })
              : col.label}
          </StyledHeaderCell>
        );
      })
    : null;

  const cells = props.columns.map((col, colIdx, colArr) => {
    let cellContent = col.renderCell({
      item: props.item,
      onChange: props.onChange,
    });

    const withGaps = !props.noGaps;
    const firstColumn = colIdx === 0;
    const lastColumn = colIdx === colArr.length - 1;

    const canDelete =
      props.onDelete &&
      (props.onCanDelete ? props.onCanDelete?.(props.item) : true);

    const deleteRowButton =
      firstColumn && canDelete ? (
        <StyledDeleteRowButton onClick={(e) => props.onDelete?.(props.item)} />
      ) : null;

    return (
      <StyledCell
        key={col.id as string}
        sx={col.cellSx}
        className="table-cell"
        style={{
          gridRow: 2,
          gridColumn: colIdx + 1,
          borderTopLeftRadius:
            withGaps && firstColumn && !props.isFirstRow ? "5px" : undefined,
          borderTopRightRadius:
            withGaps && lastColumn && !props.isFirstRow ? "5px" : undefined,

          borderBottomLeftRadius:
            firstColumn && (withGaps || props.isLastRow) ? "5px" : undefined,
          borderBottomRightRadius:
            lastColumn && (withGaps || props.isLastRow) ? "5px" : undefined,
        }}
      >
        {cellContent}
        {deleteRowButton}
      </StyledCell>
    );
  });

  return (
    <StyledRow
      className={props.className}
      sx={props.sx}
      style={{
        marginTop: props.isFirstRow ? 0 : props.noGaps ? -1 : 20,
        gridTemplateColumns: getGridTemplateColumns(props.columns),
      }}
    >
      {header}
      {cells}
    </StyledRow>
  );
}

export interface CarTableMultiRowProps<T extends IdItem> {
  className?: string;
  sx?: BoxProps["sx"];
  rows: Array<Array<CarTableColumn<T>>>;
  item: T;
  onChange: (item: T) => void;
  onCanDelete?: (item: T) => boolean;
  onDelete?: (item: T) => void;
  pendoPrefix: string;
}

export function CarTableMultiRow<T extends IdItem>(
  props: CarTableMultiRowProps<T>,
) {
  const totalColumnsLength = props.rows[0]?.length ?? 0;

  const header = props.rows.flatMap((row, rowIdx, rowArr) =>
    row.map((col, colIdx, colArr) => {
      const firstColumn = colIdx === 0;
      const lastColumn = colIdx === colArr.length - 1;
      const firstRow = rowIdx === 0;

      const prevRow = rowArr[rowIdx - 1];
      const prevRowHasLessColumns = prevRow
        ? prevRow.length < row.length
        : true;

      return (
        <StyledHeaderCell
          key={`header_${String(col.id)}`}
          sx={col.headerSx}
          style={{
            gridRow: rowIdx * 2 + 1,
            gridColumn: totalColumnsLength - colArr.length + colIdx + 1,
            borderTopLeftRadius:
              firstColumn && prevRowHasLessColumns ? "5px" : undefined,
            borderTopRightRadius: firstRow && lastColumn ? "5px" : undefined,
          }}
          className={createPendoClassName(
            `${props.pendoPrefix}_${String(col.id)}`,
          )}
        >
          {typeof col.label === "function"
            ? col.label({ item: props.item })
            : col.label}
        </StyledHeaderCell>
      );
    }),
  );

  const cells = props.rows.flatMap((row, rowIdx, rowArr) =>
    row.map((col, colIdx, colArr) => {
      let cellContent = col.renderCell({
        item: props.item,
        onChange: props.onChange,
      });

      const firstColumn = colIdx === 0;
      const lastColumn = colIdx === colArr.length - 1;
      const firstRow = rowIdx === 0;
      const lastRow = rowIdx === rowArr.length - 1;

      const nextRow = rowArr[rowIdx + 1];
      const nextRowHasLessColumns = nextRow
        ? nextRow.length < row.length
        : true;

      const canDelete = props.onCanDelete
        ? props.onCanDelete?.(props.item)
        : true;

      const deleteRowButton =
        firstRow && firstColumn && canDelete ? (
          <StyledDeleteRowButton
            onClick={(e) => props.onDelete?.(props.item)}
          />
        ) : null;

      return (
        <StyledCell
          key={`cell_${String(col.id)}`}
          sx={col.cellSx}
          style={{
            gridRow: rowIdx * 2 + 2,
            gridColumn: totalColumnsLength - colArr.length + colIdx + 1,
            borderBottomLeftRadius:
              firstColumn && nextRowHasLessColumns ? "5px" : undefined,
            borderBottomRightRadius: lastRow && lastColumn ? "5px" : undefined,
          }}
        >
          {cellContent}
          {deleteRowButton}
        </StyledCell>
      );
    }),
  );

  return (
    <StyledRow
      className={props.className}
      sx={props.sx}
      style={{ gridTemplateColumns: getGridTemplateColumns(props.rows[0]) }}
    >
      {header}
      {cells}
    </StyledRow>
  );
}

export const CarTableRoot = styled(TransitionGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
}));

export interface CarTableProps<T extends IdItem> {
  sx?: BoxProps["sx"];
  items: Array<T>;
  columns: Array<CarTableColumn<T>>;
  noGaps?: boolean;
  timeout?: number;
  onChange: (item: T) => void;
  onCanDelete?: (item: T) => boolean;
  onDelete?: (item: T) => void;
  pendoPrefix: string;
}

// YS This is workaround for weird bug when for some unknown reason we have records with duplicated ui_key fields.
// If we have duplicated ui_keys we fall back to id field. In this case some animation can be junky but at least ui will render properly
const getKeyField = (items: IdItem[]) =>
  new Set(items.map((i) => i.ui_key)).size === items.length ? "ui_key" : "id";

/**
 * @deprecated use CarTableMemoTable instead
 */
export function CarTable<T extends IdItem>(props: CarTableProps<T>) {
  const keyField = getKeyField(props.items);

  return (
    <CarTableRoot sx={props.sx}>
      {props.items.map((item, rowIdx, rowArr) => (
        <Collapse key={item[keyField]} timeout={props.timeout}>
          <CarTableRow
            item={item}
            isFirstRow={rowIdx === 0}
            isLastRow={rowIdx === rowArr.length - 1}
            noGaps={props.noGaps}
            columns={props.columns}
            onChange={props.onChange}
            onCanDelete={props.onCanDelete}
            onDelete={props.onDelete}
            pendoPrefix={props.pendoPrefix}
          />
        </Collapse>
      ))}
    </CarTableRoot>
  );
}

export interface CarMemoTableProps<T extends IdItem> {
  sx?: BoxProps["sx"];
  items: Array<T>;
  rowComponent: (props: CarTableSpecificRowProps<T>) => JSX.Element | null;
  rowComponentExtraProps?: Record<string, any>; // todo proper typing
  noGaps?: boolean;
  timeout?: number;
  onChange: (item: T) => void;
  onCanDelete?: (item: T) => boolean;
  onDelete?: (item: T) => void;
  pendoPrefix?: string;
}

export function CarTableMemoTable<T extends IdItem>(
  props: CarMemoTableProps<T>,
) {
  const keyField = getKeyField(props.items);
  const RowComponent = props.rowComponent;
  return (
    <CarTableRoot sx={props.sx}>
      {props.items.map((item, rowIdx, rowArr) => (
        <Collapse key={item[keyField]} timeout={props.timeout}>
          <RowComponent
            item={item}
            isFirstRow={rowIdx === 0}
            isLastRow={rowIdx === rowArr.length - 1}
            noGaps={props.noGaps}
            onChange={props.onChange}
            onCanDelete={props.onCanDelete}
            onDelete={props.onDelete}
            pendoPrefix={props.pendoPrefix}
            {...props.rowComponentExtraProps}
          />
        </Collapse>
      ))}
    </CarTableRoot>
  );
}
