import { Box, BoxProps, MenuItem, Typography, useTheme } from "@mui/material";
import {
  CarPortfolioHeatmapChartDataset,
  CarPortfolioHeatmapChartItem,
} from "../asset-allocation-types";
import { CarSelectChartField } from "./SelectChartField";
import { useCallback, useMemo, useRef } from "react";
import { Line } from "react-chartjs-2";
import { UseChartDataSelector } from "./useChartDataSelector";
import { ChartDataset } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { CarSelectField, CarSelectFieldProps } from "components/Inputs";
import { CarSwitch } from "components/Switch";
import {
  ChartTooltip,
  RenderTooltip,
  defaultChartTooltipData,
  useChartTooltip,
} from "components/ChartTooltip";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { formatCurrency, formatPercentFactor } from "utils";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { UseChartHighlight, useChartHighlight } from "app/useChartHighlight";
import { CarChartLegend } from "components/ChartLegend";

interface InternalChartProps {
  sx?: BoxProps["sx"];
  label: string;
  datasets: CarPortfolioHeatmapChartDataset[];
  isDownside?: boolean;
  chartHighlight: UseChartHighlight;
}

type DS = ChartDataset<"line", number[]>;
export const SimulatedForwardProbabilitiesInternalChart = (
  props: InternalChartProps,
) => {
  const theme = useTheme();

  const datasets: DS[] = props.datasets.map<DS>((ds) => ({
    label: ds.label,
    data: ds.y,
    borderColor: (ctx, options) => {
      return ctx.datasetIndex === props.chartHighlight.getValue().datasetIndex
        ? theme.palette.lightOrange
        : ds.color;
    },
    backgroundColor: (ctx, options) => {
      return ctx.datasetIndex === props.chartHighlight.getValue().datasetIndex
        ? theme.palette.lightOrange
        : ds.color;
    },
    fill: ds.fill,
    pointRadius: 0,
    borderWidth: 2,
  }));
  const { tooltipData, setTooltipData } = useChartTooltip();
  const chartRef = useRef<ChartJSOrUndefined<
    "line",
    number[],
    number
  > | null>();

  const chartRefCallback = useCallback(
    (value: ChartJSOrUndefined<"line", number[], number> | null) => {
      props.chartHighlight.unsubscribe(chartRef.current);
      chartRef.current = value;
      props.chartHighlight.subscribe(
        chartRef.current,
        () => chartRef.current?.update(),
      );
    },
    [chartRef, props.chartHighlight],
  );

  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      const chart = chartRef.current;
      if (chart) {
        const chartRect = chart.canvas.getBoundingClientRect();
        const mousePoint = {
          x: e.clientX - chartRect.left,
          y: e.clientY - chartRect.top,
        };

        if (!chart.isPointInArea(mousePoint)) {
          setTooltipData(defaultChartTooltipData);
          props.chartHighlight.resetValue();
          return;
        }
        const elements = chart.getElementsAtEventForMode(
          e.nativeEvent,
          "index",
          { intersect: false },
          false,
        );
        elements.sort((l, r) => l.element.y - r.element.y);

        const visibleElements = elements.filter(
          (el) =>
            el.element.y > chart.chartArea.top &&
            el.element.y < chart.chartArea.bottom,
        );

        if (visibleElements.length) {
          visibleElements.sort(
            (l, r) =>
              Math.abs(mousePoint.y - l.element.y) -
              Math.abs(mousePoint.y - r.element.y),
          );
          const closestElementIdx = elements.indexOf(visibleElements[0]);
          const range =
            mousePoint.y < visibleElements[0].element.y
              ? [elements[closestElementIdx - 1], elements[closestElementIdx]]
              : [elements[closestElementIdx], elements[closestElementIdx + 1]];

          if (range[0] && range[1]) {
            props.chartHighlight.setValue({
              datasetIndex:
                range[0].datasetIndex > 2
                  ? range[1].datasetIndex
                  : range[0].datasetIndex,
            });
          }

          if (range[0] && range[1]) {
            setTooltipData((old) =>
              old.datasetIndex !== range[0].datasetIndex ||
              old.dataIndex !== range[0].index
                ? {
                    datasetIndex: range[0].datasetIndex,
                    dataIndex: range[0].index,
                    x: range[0].element.x,
                    y: Math.max(
                      (range[0].element.y + range[1].element.y) / 2,
                      chart.chartArea.top,
                    ),
                    canvasSize: {
                      width: chart.canvas.width,
                      height: chart.canvas.height,
                    },
                    opacity: 1,
                  }
                : old,
            );
          } else {
            setTooltipData(defaultChartTooltipData);
            props.chartHighlight.resetValue();
          }
        } else {
          setTooltipData(defaultChartTooltipData);
          props.chartHighlight.resetValue();
        }
      }
    },
    [setTooltipData, props.chartHighlight],
  );
  const handleMouseLeave = useCallback(() => {
    setTooltipData(defaultChartTooltipData);
    props.chartHighlight.resetValue();
  }, [setTooltipData, props.chartHighlight]);

  const renderTooltip = useCallback<RenderTooltip>(
    (datasetIndex, dataIndex) => {
      const formatValue = (value: number, year: number) =>
        `${formatCurrency(value, 2)} (${formatPercentFactor(
          Math.pow(value / 100, year ? 1 / year : 0) - 1,
          { decimalPlaces: 1, forceShowDecimals: true },
        )})`;

      const year = props.datasets[0]?.x[dataIndex] ?? 0;
      const upperValue = props.datasets[datasetIndex].y[dataIndex] ?? 0;
      const lowerValue = props.datasets[datasetIndex + 1].y[dataIndex] ?? 0;

      return (
        <Box sx={{ color: "softBlack" }}>
          Year: {year}
          <br />
          Percentile:{" "}
          {
            props.datasets[datasetIndex <= 2 ? datasetIndex : datasetIndex + 1]
              ?.label
          }
          <br />
          Upper: {formatValue(upperValue, year)}
          <br />
          Lower: {formatValue(lowerValue, year)}
        </Box>
      );
    },
    [props.datasets],
  );

  return (
    <Box sx={{ flex: "auto", height: 450, position: "relative", ...props.sx }}>
      <Line
        ref={chartRefCallback}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{ position: "absolute" }}
        data={{
          labels: props.datasets[0]?.x,
          datasets,
        }}
        plugins={[ChartPlugins.roundedBackground]}
        options={{
          maintainAspectRatio: false,
          plugins: {
            roundedBackground: {
              backgroundColor: theme.palette.white,
              contextColor: theme.palette.white,
              borderColor: theme.palette.gray7,
              borderRadius: 5,
            },
            title: {
              display: true,
              align: "start",
              fullSize: false,

              text: props.label,
              font: {
                family: theme.typography.fontFamily,
                size: 19,
                weight: "600",
              },
              color: theme.palette.softBlack,
            },
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Year",
                font: {
                  family: theme.typography.fontFamily,
                  size: 16,
                  weight: "600",
                },
                color: theme.palette.softBlack,
              },
              border: { display: false },
              grid: {
                display: true,
                color: theme.palette.gray2,
                tickWidth: 0,
              },
              ticks: {
                font: {
                  family: theme.typography.fontFamily,
                  size: 13,
                  weight: "600",
                },
                color: theme.palette.softBlack,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: "Value ($)",
                font: {
                  family: theme.typography.fontFamily,
                  size: 16,
                  weight: "600",
                },
                color: theme.palette.softBlack,
              },
              border: { display: false },
              grid: {
                display: true,
                color: theme.palette.gray2,
                tickWidth: 0,
              },
              ticks: {
                font: {
                  family: theme.typography.fontFamily,
                  size: 15,
                  weight: "600",
                },
                color: theme.palette.softBlack,
              },
              max: props.isDownside ? 100 : undefined,
            },
          },
        }}
      />
      <ChartTooltip tooltipData={tooltipData} renderTooltip={renderTooltip} />
    </Box>
  );
};

export const SimulatedForwardProbabilitiesChartLegend = (props: {
  sx?: BoxProps["sx"];
  datasets: CarPortfolioHeatmapChartDataset[];
  chartHighlight: UseChartHighlight;
  isWhiteContext?: boolean;
}) => {
  return (
    <CarChartLegend
      sx={{
        border: "1px solid",
        borderColor: "gray3",
        backgroundColor: props.isWhiteContext ? "gray1" : "white",
        p: 0.25,
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        columnGap: 1,
        ...props.sx,
      }}
      label="Percentile"
      labelVariant="par01Regular"
      items={props.datasets.map((i, idx) => ({
        label: i.label,
        color: i.color,
        datasetIndex: idx,
      }))}
      chartHighlight={props.chartHighlight}
      size="small"
    />
  );
};

interface ChartDateRangeSelectorProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value: number;
  onChange: (value: number) => void;
}

export const ChartDateRangeSelector = (props: ChartDateRangeSelectorProps) => {
  return (
    <CarSelectField {...(props as any)}>
      {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((i) => (
        <MenuItem key={i} value={i}>
          {`${i} Years`}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};

export const ChartSimulatedForwardProbabilities = ({
  nominalHeatmap,
  realHeatmap,
  chartDataSelector: { data1Id, onData1IdChange, data2Id, onData2IdChange },
  isReal,
  setIsReal,
  isCompare,
  setIsCompare,
  chartDateRange1,
  setChartDateRange1,
  chartDateRange2,
  setChartDateRange2,
}: {
  nominalHeatmap: CarPortfolioHeatmapChartItem[];
  realHeatmap: CarPortfolioHeatmapChartItem[];
  chartDataSelector: UseChartDataSelector;
  isReal: boolean;
  setIsReal: (value: boolean) => void;
  isCompare: boolean;
  setIsCompare: (value: boolean) => void;

  chartDateRange1: number;
  setChartDateRange1: (value: number) => void;

  chartDateRange2: number;
  setChartDateRange2: (value: number) => void;
}) => {
  const chartHighlight = useChartHighlight();

  const realLabel = isReal ? "Real" : "Nominal";

  const data = isReal ? realHeatmap : nominalHeatmap;

  const selectedItem1 = data.find((i) => i.id === data1Id);
  const selectedItem1Datasets = useMemo(
    () =>
      selectedItem1?.datasets.map<CarPortfolioHeatmapChartDataset>((ds) => ({
        ...ds,
        x: ds.x.slice(0, chartDateRange1 + 1),
        y: ds.y.slice(0, chartDateRange1 + 1),
      })),
    [selectedItem1, chartDateRange1],
  );

  const selectedItem2 = data.find((i) => i.id === data2Id);
  const selectedItem2Datasets = useMemo(
    () =>
      selectedItem2?.datasets.map<CarPortfolioHeatmapChartDataset>((ds) => ({
        ...ds,
        x: ds.x.slice(0, chartDateRange2 + 1),
        y: ds.y.slice(0, chartDateRange2 + 1),
      })),
    [selectedItem2, chartDateRange2],
  );

  // const excelExport = useExcelExport();

  // const handleExport = () => {
  //   if (!selectedItem) {
  //     return;
  //   }

  //   const createTable = (
  //     chartItem: CarPortfolioHeatmapChartItem,
  //   ): ExcelTable => ({
  //     columns: [
  //       {
  //         format: ExcelDataFormat.general,
  //         label: "percentile",
  //       },
  //       ...(chartItem.datasets[0]?.x.map<ExcelColumn>((x) => ({
  //         format: ExcelDataFormat.general,
  //         label: x.toString(),
  //       })) ?? []),
  //     ],
  //     name: chartItem.fullLabel.replace(/[\W]+/g, "_").slice(0, 31),
  //     rows: chartItem.datasets.map((ds) => [ds.label, ...ds.y]),
  //   });

  //   excelExport.exportAllExcel({
  //     filePrefix: "Simulated_Forward_Probabilities",
  //     tables: [createTable(selectedItem)],
  //   });
  // };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          columnGap: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="par01Regular">Real</Typography>
          <CarSwitch
            size="small"
            checked={!isReal}
            onChange={(e, value) => setIsReal(!value)}
          />
          <Typography variant="par01Regular">Nominal</Typography>
          <CarTooltipBox
            sx={{ ml: -0.5 }}
            className={pendoClasses.editPortfolioAnalyzeRealNominal}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="par01Regular">Single</Typography>
          <CarSwitch
            size="small"
            checked={isCompare}
            onChange={(e, value) => setIsCompare(value)}
          />
          <Typography variant="par01Regular">Compare</Typography>
          <CarTooltipBox
            sx={{ ml: -0.5 }}
            className={pendoClasses.editPortfolioAnalyzeSingleCompare}
          />
        </Box>
        {/* <CarButton onClick={handleExport}>Export to Excel</CarButton> */}
        <SimulatedForwardProbabilitiesChartLegend
          sx={{ ml: "auto" }}
          datasets={selectedItem1?.datasets ?? []}
          chartHighlight={chartHighlight}
        />
      </Box>
      {selectedItem1Datasets && (
        <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
          <Box
            sx={{
              flex: "auto",
              display: "flex",
              // flexWrap: "wrap",
              flexDirection: "column",
              gap: 2,
              justifyContent: "space-around",
              backgroundColor: "white",
              border: "1px solid",
              borderColor: "gray3",
              p: 3,
            }}
          >
            <Box
              sx={{
                ml: 3,
                display: "flex",
                alignItems: "center",
                columnGap: 3,
              }}
            >
              <CarSelectChartField
                sx={{
                  width: 200,
                  ".MuiInputBase-root": {
                    backgroundColor: "gray1",
                  },
                }}
                value={data1Id}
                label={
                  <>
                    Portfolio Variation
                    <CarTooltipBox
                      sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                      className={
                        pendoClasses.editPortfolioAnalyzePortfolioVariation
                      }
                    />
                  </>
                }
                onChange={onData1IdChange}
                items={data}
              />
              <ChartDateRangeSelector
                sx={{
                  width: 100,
                  ".MuiInputBase-root": {
                    backgroundColor: "gray1",
                  },
                }}
                label={
                  <>
                    Time Frame
                    <CarTooltipBox
                      sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                      className={pendoClasses.editPortfolioAnalyzeTimeFrame}
                    />
                  </>
                }
                value={chartDateRange1}
                onChange={setChartDateRange1}
              />
            </Box>
            <SimulatedForwardProbabilitiesInternalChart
              label={`${selectedItem1?.label} Simulated Outcome (${realLabel})`}
              datasets={selectedItem1Datasets}
              chartHighlight={chartHighlight}
            />
            <SimulatedForwardProbabilitiesInternalChart
              label={`${selectedItem1?.label} Simulated Downside (${realLabel})`}
              datasets={selectedItem1Datasets}
              isDownside
              chartHighlight={chartHighlight}
            />
          </Box>
          {isCompare && selectedItem2Datasets && (
            <Box
              sx={{
                flex: "auto",
                display: "flex",
                // flexWrap: "wrap",
                flexDirection: "column",
                gap: 2,
                justifyContent: "space-around",
                backgroundColor: "white",
                border: "1px solid",
                borderColor: "gray3",
                p: 3,
              }}
            >
              <Box
                sx={{
                  ml: 3,
                  display: "flex",
                  alignItems: "center",
                  columnGap: 3,
                }}
              >
                <CarSelectChartField
                  sx={{
                    width: 200,
                    ".MuiInputBase-root": {
                      backgroundColor: "gray1",
                    },
                  }}
                  label={
                    <>
                      Portfolio Variation
                      <CarTooltipBox
                        sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                        className={
                          pendoClasses.editPortfolioAnalyzePortfolioVariation
                        }
                      />
                    </>
                  }
                  value={data2Id}
                  onChange={onData2IdChange}
                  items={data}
                />
                <ChartDateRangeSelector
                  sx={{
                    width: 100,
                    ".MuiInputBase-root": {
                      backgroundColor: "gray1",
                    },
                  }}
                  label={
                    <>
                      Time Frame
                      <CarTooltipBox
                        sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                        className={pendoClasses.editPortfolioAnalyzeTimeFrame}
                      />
                    </>
                  }
                  value={chartDateRange2}
                  onChange={setChartDateRange2}
                />
              </Box>
              <SimulatedForwardProbabilitiesInternalChart
                label={`${selectedItem2?.label} Simulated Outcome (${realLabel})`}
                datasets={selectedItem2Datasets}
                chartHighlight={chartHighlight}
              />
              <SimulatedForwardProbabilitiesInternalChart
                label={`${selectedItem2?.label} Simulated Downside (${realLabel})`}
                datasets={selectedItem2Datasets}
                isDownside
                chartHighlight={chartHighlight}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
