import { HeaderCell, ViewCell, fontPdf } from "components/Pdf";
import ReactPDF, { View, Text } from "@react-pdf/renderer";
import {
  CarPortfolioAssetBand,
  CarPortfolioAssetRow,
} from "pages/assetAllocation/usePortfolioGroupVersionData";
import { formatDisplayTypeValue, isOddEven } from "utils";

import { useTheme } from "@mui/material";

const titleColumnWidth = 1000;

const Row = ({
  row,
  backgroundColor,
}: {
  row: CarPortfolioAssetRow;
  backgroundColor?: string;
}) => {
  return (
    <View
      wrap={false}
      style={{
        flexDirection: "row",
        backgroundColor,
        height: 30,
      }}
    >
      <ViewCell
        style={{
          width: titleColumnWidth,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          padding: 4,
          paddingRight: 4,
        }}
      >
        <Text>{row.name}</Text>
      </ViewCell>
      {row.modules.map((mod, idx, arr) => (
        <ViewCell
          key={mod.moduleId}
          style={{
            borderRightWidth: idx === arr.length - 1 ? 1 : 0,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text>
            {formatDisplayTypeValue("PERCENTAGE")(
              mod.values.find((i) => i.dataId === mod.selectedDataId)
                ?.allocation
            )}
          </Text>
        </ViewCell>
      ))}
    </View>
  );
};

interface PortfolioGroupAssetAllocationPdfTableProps
  extends Omit<ReactPDF.ViewProps, "children"> {
  bands: CarPortfolioAssetBand[];
  rows: CarPortfolioAssetRow[];
}

export const PortfolioGroupAssetAllocationPdfTable = ({
  bands,
  rows,
  style,
  ...props
}: PortfolioGroupAssetAllocationPdfTableProps) => {
  const theme = useTheme();
  return (
    <View
      style={{
        flexDirection: "column",
        fontFamily: fontPdf.table.family,
        fontSize: fontPdf.table.dense.size,
        ...style,
      }}
      {...props}
    >
      <View
        fixed
        style={{
          flexDirection: "row",
          fontSize: fontPdf.table.dense.headerSize,
          height: 32,
        }}
      >
        <HeaderCell style={{ width: titleColumnWidth }} />
        {bands.map((band, idx, arr) => (
          <HeaderCell
            key={band.moduleId}
            style={{
              paddingTop: 4,
              borderRightWidth: idx === arr.length - 1 ? 1 : 0,
            }}
          >
            {band.label}
          </HeaderCell>
        ))}
      </View>
      {rows.map((row, idx) => {
        const backgroundColor = isOddEven(idx)
          ? theme.palette.white
          : theme.palette.gray2;
        return <Row key={row.id} row={row} backgroundColor={backgroundColor} />;
      })}
    </View>
  );
};
