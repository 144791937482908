import { Box, styled } from "@mui/material";
import {
  CarTextCalcField,
  CarTextFieldDelayed,
  CarYesNoField,
} from "components/Inputs";
import { CarCurrencyCalcField } from "components/NumberField";
import { CarTableRow, CarTableSpecificRowProps } from "components/Table";
import { Savings } from "./Savings";
import { addAnnualSavingsIfNeeded } from "features/assets/account.utils";
import { CarAccountTypeField } from "components/AccountType";
import { CarAccount } from "types";
import { memo } from "react";
import { setDisplayName } from "utils";
import { useDialog } from "app/useDialog";
import { IntegratorAccountHoldingsDialog } from "./IntegratorAccountHoldingsDialog";

const StyledRowRoot = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

enum IntegratorAccountCustodian {
  Schwab = "Schwab",
  // todo add black diamond
}

export const IntegratorAccountRow = memo(
  (props: CarTableSpecificRowProps<CarAccount>) => {
    const dialog = useDialog();
    return (
      <StyledRowRoot>
        <CarTableRow
          {...props}
          columns={[
            {
              id: "last_four",
              label: "Account #",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarTextCalcField
                  valueProps={
                    item.custodian === IntegratorAccountCustodian.Schwab
                      ? {
                          sx: {
                            color: "primary.main",
                            textDecoration: "underline",
                            cursor: "pointer",
                          },
                          onClick: () =>
                            dialog(IntegratorAccountHoldingsDialog, {
                              accountId: item.id ?? "",
                              accountNumber: item.last_four ?? "",
                            }),
                        }
                      : undefined
                  }
                  value={item.last_four}
                />
              ),
            },
            {
              id: "description",
              label: "*Description",
              fraction: 1,
              renderCell: ({ item, onChange }) => {
                return (
                  <CarTextFieldDelayed
                    required
                    value={item.description}
                    onChange={(description) =>
                      onChange({ ...item, description })
                    }
                  />
                );
              },
            },
            {
              id: "custodian",
              label: "Custodian",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarTextCalcField value={item.custodian} />
              ),
            },
            {
              id: "market_value",
              label: "Market Value",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarCurrencyCalcField value={item.market_value} />
              ),
            },
            {
              id: "cost_basis",
              label: "Cost Basis",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarCurrencyCalcField value={item.cost_basis} />
              ),
            },
            {
              id: "account_type",
              label: "*Account Type",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarAccountTypeField
                  required
                  value={item.account_type}
                  onChange={(account_type) =>
                    onChange({ ...item, account_type })
                  }
                />
              ),
            },
            {
              id: "is_saving",
              label: "Savings",
              fraction: 1,
              renderCell: ({ item, onChange }) => (
                <CarYesNoField
                  value={item.is_saving}
                  onChange={(is_saving) =>
                    onChange(addAnnualSavingsIfNeeded({ ...item, is_saving }))
                  }
                />
              ),
            },
          ]}
        />
        <Savings item={props.item} onChange={props.onChange} />
      </StyledRowRoot>
    );
  },
);

setDisplayName({ IntegratorAccountRow });
