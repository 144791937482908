import { Box, Collapse, styled } from "@mui/material";
import React, { FC } from "react";
import { AddRowButton } from "components/Buttons";
import { isFutureItem, useRealEstate } from "features/realEstate/useRealEstate";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarTableRoot } from "components/Table";
import { RealEstateRowProps } from "./real-estate-types";
import { RealEstateRowOwnerOccupied } from "./RealEstateRowOwnerOccupied";
import { RealEstateRowRental } from "./RealEstateRowRental";
import { CarRealEstate } from "types";
import { RealEstateRowFutureRental } from "./RealEstateRowFutureRental";
import { RealEstateRowFutureOwnerOccupied } from "./RealEstateRowFutureOwnerOccupied";

const getRowComponent = (item: CarRealEstate): FC<RealEstateRowProps> => {
  if (isFutureItem(item)) {
    return item.real_estate_type === "RENTAL"
      ? RealEstateRowFutureRental
      : RealEstateRowFutureOwnerOccupied;
  } else {
    return item.real_estate_type === "RENTAL"
      ? RealEstateRowRental
      : RealEstateRowOwnerOccupied;
  }
};

const StyledTableRoot = styled(CarTableRoot)(({ theme }) => ({
  ".real-estate-row": {
    marginTop: theme.spacing(7.5),
  },
  ".real-estate-row-collapse:first-of-type .real-estate-row": {
    marginTop: 0,
  },
}));

export const RealEstateQuick = React.memo((props: { isFuture: boolean }) => {
  const data = useRealEstate();

  const items = props.isFuture ? data.futureItems : data.currentItems;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TablePlaceHolder isLoading={data.isLoading}>
        <StyledTableRoot>
          {items.map((i, idx) => {
            const Comp = getRowComponent(i);
            return (
              <Collapse key={i.ui_key} className="real-estate-row-collapse">
                <Comp
                  className="real-estate-row"
                  item={i}
                  onChange={data.handleChange}
                  onDelete={data.handleDelete}
                  pendoPrefix="real_estate"
                />
              </Collapse>
            );
          })}
        </StyledTableRoot>
      </TablePlaceHolder>
      <AddRowButton
        sx={{ alignSelf: "flex-start", mt: 1.5 }}
        onClick={props.isFuture ? data.addFutureRow : data.addCurrentRow}
      >
        Add Real Estate
      </AddRowButton>
    </Box>
  );
});
