import React, { useState } from "react";
import { Box } from "@mui/system";
import { styled } from "@mui/material";
import { CapitalGainsChartSurplusDeficit } from "./CapitalGainsChartSurplusDeficit";
import { CarFlatPageControl } from "components/FlatPageControl";
import { UseCapitalGains } from "./useCapitalGains";

interface CapitalGainsChartsProps {
  data: UseCapitalGains;
}

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  border: "solid 1px",
  borderColor: theme.palette.gray6,
  borderRadius: "5px",
  height: 510,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const CapitalGainsCharts = ({ data }: CapitalGainsChartsProps) => {
  const [pageIndex, setPageIndex] = useState(0);

  return (
    <CarFlatPageControl
      value={pageIndex}
      onChange={setPageIndex}
      items={[
        {
          value: 0,
          label: "Surplus/Deficit",
          content: (
            <StyledBox>
              <CapitalGainsChartSurplusDeficit
                data={data.surplusDeficitChart}
              />
            </StyledBox>
          ),
        },
      ]}
    />
  );
};
