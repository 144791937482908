import { styled, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { usePlanResults } from "features/planResults/usePlanResults";
import { RootPageBox } from "components/RootPage";
import { PlanResultCardGoalTimeline } from "../workbench/planResults/PlanResultCardGoalTimeline";

const StyledRoot = styled(RootPageBox)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "none",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

export const ProgressMonitoringGoalTimeline = () => {
  const data = usePlanResults();

  return (
    <StyledRoot>
      <TablePlaceHolder isLoading={data.isLoading} rows={5}>
        <Typography variant="h5SSemiBold">Goal Timeline</Typography>
        <PlanResultCardGoalTimeline
          sx={{
            mt: 2,
            p: 0,
            ".inflation-switch": {
              right: 17,
              top: -25,
            },
          }}
          data={data.barChart}
        />
      </TablePlaceHolder>
    </StyledRoot>
  );
};
