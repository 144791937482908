import { emptyApi as api } from "./carApi.empty";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    accountsAccountHoldingsRetrieve: build.query<
      AccountsAccountHoldingsRetrieveApiResponse,
      AccountsAccountHoldingsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/accounts/account_holdings/${queryArg.accountId}/`,
      }),
    }),
    accountsAccountTypeList: build.query<
      AccountsAccountTypeListApiResponse,
      AccountsAccountTypeListApiArg
    >({
      query: () => ({ url: `/api/accounts/account_type/` }),
    }),
    accountsAccountTypeRetrieve: build.query<
      AccountsAccountTypeRetrieveApiResponse,
      AccountsAccountTypeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/accounts/account_type/${queryArg.id}/`,
      }),
    }),
    accountsTaxTypeRulesDetailList: build.query<
      AccountsTaxTypeRulesDetailListApiResponse,
      AccountsTaxTypeRulesDetailListApiArg
    >({
      query: () => ({ url: `/api/accounts/tax_type_rules_detail/` }),
    }),
    alEngineAlAssetTypeList: build.query<
      AlEngineAlAssetTypeListApiResponse,
      AlEngineAlAssetTypeListApiArg
    >({
      query: () => ({ url: `/api/al_engine/al_asset_type/` }),
    }),
    alEngineAlAssetTypeRetrieve: build.query<
      AlEngineAlAssetTypeRetrieveApiResponse,
      AlEngineAlAssetTypeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/al_asset_type/${queryArg.id}/`,
      }),
    }),
    alEngineDebugInputJsonRetrieve: build.query<
      AlEngineDebugInputJsonRetrieveApiResponse,
      AlEngineDebugInputJsonRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/debug_input_json/${queryArg.planResultRootId}/${queryArg.planResultTypeId}`,
      }),
    }),
    alEngineDebugOutputJsonRetrieve: build.query<
      AlEngineDebugOutputJsonRetrieveApiResponse,
      AlEngineDebugOutputJsonRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/debug_output_json/${queryArg.planResultRootId}`,
      }),
    }),
    alEnginePlanResultRetrieve: build.query<
      AlEnginePlanResultRetrieveApiResponse,
      AlEnginePlanResultRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/plan_result/${queryArg.id}/`,
      }),
    }),
    alEnginePlanResultActionPlanRetrieve: build.query<
      AlEnginePlanResultActionPlanRetrieveApiResponse,
      AlEnginePlanResultActionPlanRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/plan_result/${queryArg.id}/action_plan/`,
      }),
    }),
    alEnginePlanResultPublishPlanResultUpdate: build.mutation<
      AlEnginePlanResultPublishPlanResultUpdateApiResponse,
      AlEnginePlanResultPublishPlanResultUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/plan_result/${queryArg.id}/publish_plan_result/`,
        method: "PUT",
        body: queryArg.planResult,
      }),
    }),
    alEnginePlanResultSimulationJobDisclosureRetrieve: build.query<
      AlEnginePlanResultSimulationJobDisclosureRetrieveApiResponse,
      AlEnginePlanResultSimulationJobDisclosureRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/plan_result/${queryArg.id}/simulation_job_disclosure/`,
      }),
    }),
    alEnginePlanResultStressTestContainerRetrieve: build.query<
      AlEnginePlanResultStressTestContainerRetrieveApiResponse,
      AlEnginePlanResultStressTestContainerRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/plan_result_stress_test_container/${queryArg.id}/`,
      }),
    }),
    alEnginePlanResultTypeList: build.query<
      AlEnginePlanResultTypeListApiResponse,
      AlEnginePlanResultTypeListApiArg
    >({
      query: () => ({ url: `/api/al_engine/plan_result_type/` }),
    }),
    alEnginePlanResultTypeRetrieve: build.query<
      AlEnginePlanResultTypeRetrieveApiResponse,
      AlEnginePlanResultTypeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/plan_result_type/${queryArg.id}/`,
      }),
    }),
    alEngineRenderRetrieve: build.query<
      AlEngineRenderRetrieveApiResponse,
      AlEngineRenderRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/al_engine/render/${queryArg.id}/` }),
    }),
    alEngineRenderPlanResultJobStatusList: build.query<
      AlEngineRenderPlanResultJobStatusListApiResponse,
      AlEngineRenderPlanResultJobStatusListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/render/${queryArg.id}/plan_result_job_status/`,
      }),
    }),
    alEngineRenderProgressMonitoringResultsRetrieve: build.query<
      AlEngineRenderProgressMonitoringResultsRetrieveApiResponse,
      AlEngineRenderProgressMonitoringResultsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/render/${queryArg.id}/progress_monitoring_results/`,
      }),
    }),
    alEngineStressTestContainerRetrieve: build.query<
      AlEngineStressTestContainerRetrieveApiResponse,
      AlEngineStressTestContainerRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/stress_test_container/${queryArg.id}/`,
      }),
    }),
    alEngineStressTestScenarioTypeList: build.query<
      AlEngineStressTestScenarioTypeListApiResponse,
      AlEngineStressTestScenarioTypeListApiArg
    >({
      query: () => ({ url: `/api/al_engine/stress_test_scenario_type/` }),
    }),
    alEngineStressTestScenarioTypeRetrieve: build.query<
      AlEngineStressTestScenarioTypeRetrieveApiResponse,
      AlEngineStressTestScenarioTypeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/al_engine/stress_test_scenario_type/${queryArg.id}/`,
      }),
    }),
    assetClassesAssetClassGicsSubIndustryWeightsList: build.query<
      AssetClassesAssetClassGicsSubIndustryWeightsListApiResponse,
      AssetClassesAssetClassGicsSubIndustryWeightsListApiArg
    >({
      query: () => ({
        url: `/api/asset_classes/asset_class_gics_sub_industry_weights/`,
      }),
    }),
    assetClassesAssetClassLevel4ListRetrieve: build.query<
      AssetClassesAssetClassLevel4ListRetrieveApiResponse,
      AssetClassesAssetClassLevel4ListRetrieveApiArg
    >({
      query: () => ({ url: `/api/asset_classes/asset_class_level4_list` }),
    }),
    assetClassesAssetClassStatisticViewRetrieve: build.query<
      AssetClassesAssetClassStatisticViewRetrieveApiResponse,
      AssetClassesAssetClassStatisticViewRetrieveApiArg
    >({
      query: () => ({ url: `/api/asset_classes/asset_class_statistic_view` }),
    }),
    assetClassesBoundaryList: build.query<
      AssetClassesBoundaryListApiResponse,
      AssetClassesBoundaryListApiArg
    >({
      query: (queryArg) => ({ url: `/api/asset_classes/boundary/` }),
    }),
    assetClassesBoundaryCreate: build.mutation<
      AssetClassesBoundaryCreateApiResponse,
      AssetClassesBoundaryCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary/`,
        method: "POST",
        body: queryArg.boundary,
      }),
    }),
    assetClassesBoundaryRetrieve: build.query<
      AssetClassesBoundaryRetrieveApiResponse,
      AssetClassesBoundaryRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary/${queryArg.id}/`,
      }),
    }),
    assetClassesBoundaryUpdate: build.mutation<
      AssetClassesBoundaryUpdateApiResponse,
      AssetClassesBoundaryUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.boundary,
      }),
    }),
    assetClassesBoundaryPartialUpdate: build.mutation<
      AssetClassesBoundaryPartialUpdateApiResponse,
      AssetClassesBoundaryPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedBoundary,
      }),
    }),
    assetClassesBoundaryDestroy: build.mutation<
      AssetClassesBoundaryDestroyApiResponse,
      AssetClassesBoundaryDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    assetClassesBoundaryGroupList: build.query<
      AssetClassesBoundaryGroupListApiResponse,
      AssetClassesBoundaryGroupListApiArg
    >({
      query: (queryArg) => ({ url: `/api/asset_classes/boundary_group/` }),
    }),
    assetClassesBoundaryGroupCreate: build.mutation<
      AssetClassesBoundaryGroupCreateApiResponse,
      AssetClassesBoundaryGroupCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary_group/`,
        method: "POST",
        body: queryArg.boundaryGroup,
      }),
    }),
    assetClassesBoundaryGroupRetrieve: build.query<
      AssetClassesBoundaryGroupRetrieveApiResponse,
      AssetClassesBoundaryGroupRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary_group/${queryArg.id}/`,
      }),
    }),
    assetClassesBoundaryGroupUpdate: build.mutation<
      AssetClassesBoundaryGroupUpdateApiResponse,
      AssetClassesBoundaryGroupUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary_group/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.boundaryGroup,
      }),
    }),
    assetClassesBoundaryGroupPartialUpdate: build.mutation<
      AssetClassesBoundaryGroupPartialUpdateApiResponse,
      AssetClassesBoundaryGroupPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary_group/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedBoundaryGroup,
      }),
    }),
    assetClassesBoundaryGroupDestroy: build.mutation<
      AssetClassesBoundaryGroupDestroyApiResponse,
      AssetClassesBoundaryGroupDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/boundary_group/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    assetClassesFirmAssetClassImplementationList: build.query<
      AssetClassesFirmAssetClassImplementationListApiResponse,
      AssetClassesFirmAssetClassImplementationListApiArg
    >({
      query: () => ({
        url: `/api/asset_classes/firm_asset_class_implementation/`,
      }),
    }),
    assetClassesFirmAssetClassImplementationRetrieve: build.query<
      AssetClassesFirmAssetClassImplementationRetrieveApiResponse,
      AssetClassesFirmAssetClassImplementationRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/firm_asset_class_implementation/${queryArg.id}/`,
      }),
    }),
    assetClassesFirmAssetClassImplementationUpdate: build.mutation<
      AssetClassesFirmAssetClassImplementationUpdateApiResponse,
      AssetClassesFirmAssetClassImplementationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/firm_asset_class_implementation/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.firmAssetClassImplementation,
      }),
    }),
    assetClassesFirmAssetClassImplementationPartialUpdate: build.mutation<
      AssetClassesFirmAssetClassImplementationPartialUpdateApiResponse,
      AssetClassesFirmAssetClassImplementationPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/firm_asset_class_implementation/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedFirmAssetClassImplementation,
      }),
    }),
    assetClassesFirmAssetClassesList: build.query<
      AssetClassesFirmAssetClassesListApiResponse,
      AssetClassesFirmAssetClassesListApiArg
    >({
      query: () => ({ url: `/api/asset_classes/firm_asset_classes/` }),
    }),
    assetClassesFirmAssetClassesRetrieve: build.query<
      AssetClassesFirmAssetClassesRetrieveApiResponse,
      AssetClassesFirmAssetClassesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/firm_asset_classes/${queryArg.id}/`,
      }),
    }),
    assetClassesFirmAssetClassesUpdate: build.mutation<
      AssetClassesFirmAssetClassesUpdateApiResponse,
      AssetClassesFirmAssetClassesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/firm_asset_classes/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.firmAssetClassDetail,
      }),
    }),
    assetClassesFirmAssetClassesPartialUpdate: build.mutation<
      AssetClassesFirmAssetClassesPartialUpdateApiResponse,
      AssetClassesFirmAssetClassesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/firm_asset_classes/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedFirmAssetClassDetail,
      }),
    }),
    assetClassesSimulationBehaviorCashList: build.query<
      AssetClassesSimulationBehaviorCashListApiResponse,
      AssetClassesSimulationBehaviorCashListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_cash/`,
      }),
    }),
    assetClassesSimulationBehaviorCashRetrieve: build.query<
      AssetClassesSimulationBehaviorCashRetrieveApiResponse,
      AssetClassesSimulationBehaviorCashRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_cash/${queryArg.id}/`,
      }),
    }),
    assetClassesSimulationBehaviorCashUpdate: build.mutation<
      AssetClassesSimulationBehaviorCashUpdateApiResponse,
      AssetClassesSimulationBehaviorCashUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_cash/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.simulationBehaviorCash,
      }),
    }),
    assetClassesSimulationBehaviorCashPartialUpdate: build.mutation<
      AssetClassesSimulationBehaviorCashPartialUpdateApiResponse,
      AssetClassesSimulationBehaviorCashPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_cash/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSimulationBehaviorCash,
      }),
    }),
    assetClassesSimulationBehaviorEquityList: build.query<
      AssetClassesSimulationBehaviorEquityListApiResponse,
      AssetClassesSimulationBehaviorEquityListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_equity/`,
      }),
    }),
    assetClassesSimulationBehaviorEquityRetrieve: build.query<
      AssetClassesSimulationBehaviorEquityRetrieveApiResponse,
      AssetClassesSimulationBehaviorEquityRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_equity/${queryArg.id}/`,
      }),
    }),
    assetClassesSimulationBehaviorEquityUpdate: build.mutation<
      AssetClassesSimulationBehaviorEquityUpdateApiResponse,
      AssetClassesSimulationBehaviorEquityUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_equity/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.simulationBehaviorEquity,
      }),
    }),
    assetClassesSimulationBehaviorEquityPartialUpdate: build.mutation<
      AssetClassesSimulationBehaviorEquityPartialUpdateApiResponse,
      AssetClassesSimulationBehaviorEquityPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_equity/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSimulationBehaviorEquity,
      }),
    }),
    assetClassesSimulationBehaviorFixedIncomeSpreadList: build.query<
      AssetClassesSimulationBehaviorFixedIncomeSpreadListApiResponse,
      AssetClassesSimulationBehaviorFixedIncomeSpreadListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_fixed_income_spread/`,
      }),
    }),
    assetClassesSimulationBehaviorFixedIncomeSpreadRetrieve: build.query<
      AssetClassesSimulationBehaviorFixedIncomeSpreadRetrieveApiResponse,
      AssetClassesSimulationBehaviorFixedIncomeSpreadRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_fixed_income_spread/${queryArg.id}/`,
      }),
    }),
    assetClassesSimulationBehaviorFixedIncomeSpreadUpdate: build.mutation<
      AssetClassesSimulationBehaviorFixedIncomeSpreadUpdateApiResponse,
      AssetClassesSimulationBehaviorFixedIncomeSpreadUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_fixed_income_spread/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.simulationBehaviorFixedIncomeSpread,
      }),
    }),
    assetClassesSimulationBehaviorFixedIncomeSpreadPartialUpdate:
      build.mutation<
        AssetClassesSimulationBehaviorFixedIncomeSpreadPartialUpdateApiResponse,
        AssetClassesSimulationBehaviorFixedIncomeSpreadPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/asset_classes/simulation_behavior_fixed_income_spread/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedSimulationBehaviorFixedIncomeSpread,
        }),
      }),
    assetClassesSimulationBehaviorInflationList: build.query<
      AssetClassesSimulationBehaviorInflationListApiResponse,
      AssetClassesSimulationBehaviorInflationListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_inflation/`,
      }),
    }),
    assetClassesSimulationBehaviorInflationRetrieve: build.query<
      AssetClassesSimulationBehaviorInflationRetrieveApiResponse,
      AssetClassesSimulationBehaviorInflationRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_inflation/${queryArg.id}/`,
      }),
    }),
    assetClassesSimulationBehaviorInflationUpdate: build.mutation<
      AssetClassesSimulationBehaviorInflationUpdateApiResponse,
      AssetClassesSimulationBehaviorInflationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_inflation/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.simulationBehaviorInflation,
      }),
    }),
    assetClassesSimulationBehaviorInflationPartialUpdate: build.mutation<
      AssetClassesSimulationBehaviorInflationPartialUpdateApiResponse,
      AssetClassesSimulationBehaviorInflationPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/asset_classes/simulation_behavior_inflation/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSimulationBehaviorInflation,
      }),
    }),
    automationReceiveLambdaEventCreate: build.mutation<
      AutomationReceiveLambdaEventCreateApiResponse,
      AutomationReceiveLambdaEventCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/automation/receive_lambda_event`,
        method: "POST",
        body: queryArg.recieveEvent,
      }),
    }),
    cmaAssetClassRegressionRetrieve: build.query<
      CmaAssetClassRegressionRetrieveApiResponse,
      CmaAssetClassRegressionRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/asset_class_regression/${queryArg.assetClassId}/`,
      }),
    }),
    cmaCmaDataList: build.query<
      CmaCmaDataListApiResponse,
      CmaCmaDataListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/cma_data/`,
        params: { search: queryArg.search },
      }),
    }),
    cmaCmaDataRetrieve: build.query<
      CmaCmaDataRetrieveApiResponse,
      CmaCmaDataRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/cma/cma_data/${queryArg.id}/` }),
    }),
    cmaCmaGroupList: build.query<
      CmaCmaGroupListApiResponse,
      CmaCmaGroupListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/cma_group/`,
        params: { search: queryArg.search },
      }),
    }),
    cmaCmaGroupRetrieve: build.query<
      CmaCmaGroupRetrieveApiResponse,
      CmaCmaGroupRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/cma/cma_group/${queryArg.id}/` }),
    }),
    cmaDataSourceList: build.query<
      CmaDataSourceListApiResponse,
      CmaDataSourceListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/data_source/`,
        params: { search: queryArg.search },
      }),
    }),
    cmaDataSourceRetrieve: build.query<
      CmaDataSourceRetrieveApiResponse,
      CmaDataSourceRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/cma/data_source/${queryArg.id}/` }),
    }),
    cmaGenerateAssetClassSimBehaviorCreate: build.mutation<
      CmaGenerateAssetClassSimBehaviorCreateApiResponse,
      CmaGenerateAssetClassSimBehaviorCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/generate_asset_class_sim_behavior/`,
        method: "POST",
        body: queryArg.genModelsAssetClassList,
      }),
    }),
    cmaGetCurrentCpiDefinitionsList: build.query<
      CmaGetCurrentCpiDefinitionsListApiResponse,
      CmaGetCurrentCpiDefinitionsListApiArg
    >({
      query: () => ({ url: `/api/cma/get_current_cpi_definitions/` }),
    }),
    cmaReturnScenariosList: build.query<
      CmaReturnScenariosListApiResponse,
      CmaReturnScenariosListApiArg
    >({
      query: () => ({ url: `/api/cma/return_scenarios/` }),
    }),
    cmaReturnScenariosRetrieve: build.query<
      CmaReturnScenariosRetrieveApiResponse,
      CmaReturnScenariosRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/return_scenarios/${queryArg.id}/`,
      }),
    }),
    cmaRiskStatTypeList: build.query<
      CmaRiskStatTypeListApiResponse,
      CmaRiskStatTypeListApiArg
    >({
      query: () => ({ url: `/api/cma/risk_stat_type/` }),
    }),
    cmaRiskStatTypeRetrieve: build.query<
      CmaRiskStatTypeRetrieveApiResponse,
      CmaRiskStatTypeRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/cma/risk_stat_type/${queryArg.id}/` }),
    }),
    cmaSandpCmaGroupList: build.query<
      CmaSandpCmaGroupListApiResponse,
      CmaSandpCmaGroupListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/sandp_cma_group/`,
        params: { search: queryArg.search },
      }),
    }),
    cmaSandpCmaGroupRetrieve: build.query<
      CmaSandpCmaGroupRetrieveApiResponse,
      CmaSandpCmaGroupRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/sandp_cma_group/${queryArg.id}/`,
      }),
    }),
    cmaSandpDataEquityList: build.query<
      CmaSandpDataEquityListApiResponse,
      CmaSandpDataEquityListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/sandp_data_equity/`,
        params: { search: queryArg.search },
      }),
    }),
    cmaSandpDataEquityRetrieve: build.query<
      CmaSandpDataEquityRetrieveApiResponse,
      CmaSandpDataEquityRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/sandp_data_equity/${queryArg.id}/`,
      }),
    }),
    cmaSandpDataFixedIncomeList: build.query<
      CmaSandpDataFixedIncomeListApiResponse,
      CmaSandpDataFixedIncomeListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/sandp_data_fixed_income/`,
        params: { search: queryArg.search },
      }),
    }),
    cmaSandpDataFixedIncomeRetrieve: build.query<
      CmaSandpDataFixedIncomeRetrieveApiResponse,
      CmaSandpDataFixedIncomeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/cma/sandp_data_fixed_income/${queryArg.id}/`,
      }),
    }),
    cmaYieldCurvePostionsList: build.query<
      CmaYieldCurvePostionsListApiResponse,
      CmaYieldCurvePostionsListApiArg
    >({
      query: () => ({ url: `/api/cma/yield_curve_postions/` }),
    }),
    commonAuthAcceptInvitationCreate: build.mutation<
      CommonAuthAcceptInvitationCreateApiResponse,
      CommonAuthAcceptInvitationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/accept_invitation/`,
        method: "POST",
        body: queryArg.acceptInvitation,
      }),
    }),
    commonAuthLoginCreate: build.mutation<
      CommonAuthLoginCreateApiResponse,
      CommonAuthLoginCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/login/`,
        method: "POST",
        body: queryArg.customTokenObtainPair,
      }),
    }),
    commonAuthRefreshCreate: build.mutation<
      CommonAuthRefreshCreateApiResponse,
      CommonAuthRefreshCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/refresh/`,
        method: "POST",
        body: queryArg.customTokenRefresh,
      }),
    }),
    commonAuthRequestResetPasswordCreate: build.mutation<
      CommonAuthRequestResetPasswordCreateApiResponse,
      CommonAuthRequestResetPasswordCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/request_reset_password/`,
        method: "POST",
        body: queryArg.requestResetPassword,
      }),
    }),
    commonAuthResetPasswordCreate: build.mutation<
      CommonAuthResetPasswordCreateApiResponse,
      CommonAuthResetPasswordCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/reset_password/`,
        method: "POST",
        body: queryArg.resetPassword,
      }),
    }),
    commonAuthSelfRetrieve: build.query<
      CommonAuthSelfRetrieveApiResponse,
      CommonAuthSelfRetrieveApiArg
    >({
      query: () => ({ url: `/api/common/auth/self/` }),
    }),
    commonAuthSelfUpdate: build.mutation<
      CommonAuthSelfUpdateApiResponse,
      CommonAuthSelfUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/self/`,
        method: "PUT",
        body: queryArg.user,
      }),
    }),
    commonAuthSelfPartialUpdate: build.mutation<
      CommonAuthSelfPartialUpdateApiResponse,
      CommonAuthSelfPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/self/`,
        method: "PATCH",
        body: queryArg.patchedUser,
      }),
    }),
    commonAuthSelfFirmRetrieve: build.query<
      CommonAuthSelfFirmRetrieveApiResponse,
      CommonAuthSelfFirmRetrieveApiArg
    >({
      query: () => ({ url: `/api/common/auth/self_firm/` }),
    }),
    commonAuthSelfFirmUpdate: build.mutation<
      CommonAuthSelfFirmUpdateApiResponse,
      CommonAuthSelfFirmUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/self_firm/`,
        method: "PUT",
        body: queryArg.firm,
      }),
    }),
    commonAuthSelfFirmPartialUpdate: build.mutation<
      CommonAuthSelfFirmPartialUpdateApiResponse,
      CommonAuthSelfFirmPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/self_firm/`,
        method: "PATCH",
        body: queryArg.patchedFirm,
      }),
    }),
    commonAuthStartFreeTrialCreate: build.mutation<
      CommonAuthStartFreeTrialCreateApiResponse,
      CommonAuthStartFreeTrialCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/start_free_trial/`,
        method: "POST",
        body: queryArg.freeTrialStart,
      }),
    }),
    commonAuthTwoFactorLoginUpdate: build.mutation<
      CommonAuthTwoFactorLoginUpdateApiResponse,
      CommonAuthTwoFactorLoginUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/two_factor_login/`,
        method: "PUT",
        body: queryArg.userTwoFactorAuth,
      }),
    }),
    commonAuthTwoFactorLoginPartialUpdate: build.mutation<
      CommonAuthTwoFactorLoginPartialUpdateApiResponse,
      CommonAuthTwoFactorLoginPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/two_factor_login/`,
        method: "PATCH",
        body: queryArg.patchedUserTwoFactorAuth,
      }),
    }),
    commonAuthTwoFactorSettingsCreate: build.mutation<
      CommonAuthTwoFactorSettingsCreateApiResponse,
      CommonAuthTwoFactorSettingsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/two_factor_settings/`,
        method: "POST",
        body: queryArg.userTwoFactorSettings,
      }),
    }),
    commonAuthTwoFactorSettingsUpdate: build.mutation<
      CommonAuthTwoFactorSettingsUpdateApiResponse,
      CommonAuthTwoFactorSettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/two_factor_settings/`,
        method: "PUT",
        body: queryArg.userTwoFactorSettings,
      }),
    }),
    commonAuthTwoFactorSettingsPartialUpdate: build.mutation<
      CommonAuthTwoFactorSettingsPartialUpdateApiResponse,
      CommonAuthTwoFactorSettingsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/two_factor_settings/`,
        method: "PATCH",
        body: queryArg.patchedUserTwoFactorSettings,
      }),
    }),
    commonAuthVerifyEmailUpdate: build.mutation<
      CommonAuthVerifyEmailUpdateApiResponse,
      CommonAuthVerifyEmailUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/verify_email/`,
        method: "PUT",
        body: queryArg.userEmailConfirmation,
      }),
    }),
    commonAuthVerifyEmailPartialUpdate: build.mutation<
      CommonAuthVerifyEmailPartialUpdateApiResponse,
      CommonAuthVerifyEmailPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/auth/verify_email/`,
        method: "PATCH",
        body: queryArg.patchedUserEmailConfirmation,
      }),
    }),
    commonAuthVerifyEmailResendUpdate: build.mutation<
      CommonAuthVerifyEmailResendUpdateApiResponse,
      CommonAuthVerifyEmailResendUpdateApiArg
    >({
      query: () => ({
        url: `/api/common/auth/verify_email_resend/`,
        method: "PUT",
      }),
    }),
    commonAuthVerifyEmailResendPartialUpdate: build.mutation<
      CommonAuthVerifyEmailResendPartialUpdateApiResponse,
      CommonAuthVerifyEmailResendPartialUpdateApiArg
    >({
      query: () => ({
        url: `/api/common/auth/verify_email_resend/`,
        method: "PATCH",
      }),
    }),
    commonBillingActivateBillingCreate: build.query<
      CommonBillingActivateBillingCreateApiResponse,
      CommonBillingActivateBillingCreateApiArg
    >({
      query: () => ({
        url: `/api/common/billing/activate_billing/`,
        method: "POST",
      }),
    }),
    commonBillingActivateBillingV2Retrieve: build.query<
      CommonBillingActivateBillingV2RetrieveApiResponse,
      CommonBillingActivateBillingV2RetrieveApiArg
    >({
      query: () => ({ url: `/api/common/billing/activate_billing_v2/` }),
    }),
    commonBillingActivateBillingV2Create: build.mutation<
      CommonBillingActivateBillingV2CreateApiResponse,
      CommonBillingActivateBillingV2CreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/billing/activate_billing_v2/`,
        method: "POST",
        body: queryArg.activateBillingV2,
      }),
    }),
    commonBillingBillingCheckStatusCreate: build.query<
      CommonBillingBillingCheckStatusCreateApiResponse,
      CommonBillingBillingCheckStatusCreateApiArg
    >({
      query: () => ({
        url: `/api/common/billing/billing_check_status/`,
        method: "POST",
      }),
    }),
    commonBillingBillingGetInfoRetrieve: build.query<
      CommonBillingBillingGetInfoRetrieveApiResponse,
      CommonBillingBillingGetInfoRetrieveApiArg
    >({
      query: () => ({ url: `/api/common/billing/billing_get_info/` }),
    }),
    commonBillingStripePriceList: build.query<
      CommonBillingStripePriceListApiResponse,
      CommonBillingStripePriceListApiArg
    >({
      query: () => ({ url: `/api/common/billing/stripe_price/` }),
    }),
    commonBillingStripePriceRetrieve: build.query<
      CommonBillingStripePriceRetrieveApiResponse,
      CommonBillingStripePriceRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/billing/stripe_price/${queryArg.id}/`,
      }),
    }),
    commonCmpasModuleList: build.query<
      CommonCmpasModuleListApiResponse,
      CommonCmpasModuleListApiArg
    >({
      query: () => ({ url: `/api/common/cmpas_module/` }),
    }),
    commonCmpasModuleCreate: build.mutation<
      CommonCmpasModuleCreateApiResponse,
      CommonCmpasModuleCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/cmpas_module/`,
        method: "POST",
        body: queryArg.cmpasModule,
      }),
    }),
    commonCmpasModuleRetrieve: build.query<
      CommonCmpasModuleRetrieveApiResponse,
      CommonCmpasModuleRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/cmpas_module/${queryArg.id}/`,
      }),
    }),
    commonCmpasModuleUpdate: build.mutation<
      CommonCmpasModuleUpdateApiResponse,
      CommonCmpasModuleUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/cmpas_module/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.cmpasModule,
      }),
    }),
    commonCmpasModulePartialUpdate: build.mutation<
      CommonCmpasModulePartialUpdateApiResponse,
      CommonCmpasModulePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/cmpas_module/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedCmpasModule,
      }),
    }),
    commonCmpasModuleHierarchyList: build.query<
      CommonCmpasModuleHierarchyListApiResponse,
      CommonCmpasModuleHierarchyListApiArg
    >({
      query: () => ({ url: `/api/common/cmpas_module_hierarchy/` }),
    }),
    commonCmpasModuleHierarchyRetrieve: build.query<
      CommonCmpasModuleHierarchyRetrieveApiResponse,
      CommonCmpasModuleHierarchyRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/cmpas_module_hierarchy/${queryArg.id}/`,
      }),
    }),
    commonCmpasModuleSectionList: build.query<
      CommonCmpasModuleSectionListApiResponse,
      CommonCmpasModuleSectionListApiArg
    >({
      query: () => ({ url: `/api/common/cmpas_module_section/` }),
    }),
    commonCmpasModuleSectionCreate: build.mutation<
      CommonCmpasModuleSectionCreateApiResponse,
      CommonCmpasModuleSectionCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/cmpas_module_section/`,
        method: "POST",
        body: queryArg.cmpasModuleSectionDetail,
      }),
    }),
    commonCmpasModuleSectionRetrieve: build.query<
      CommonCmpasModuleSectionRetrieveApiResponse,
      CommonCmpasModuleSectionRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/cmpas_module_section/${queryArg.id}/`,
      }),
    }),
    commonCmpasModuleSectionUpdate: build.mutation<
      CommonCmpasModuleSectionUpdateApiResponse,
      CommonCmpasModuleSectionUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/cmpas_module_section/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.cmpasModuleSectionDetail,
      }),
    }),
    commonCmpasModuleSectionPartialUpdate: build.mutation<
      CommonCmpasModuleSectionPartialUpdateApiResponse,
      CommonCmpasModuleSectionPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/cmpas_module_section/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedCmpasModuleSectionDetail,
      }),
    }),
    commonFirmMembersList: build.query<
      CommonFirmMembersListApiResponse,
      CommonFirmMembersListApiArg
    >({
      query: () => ({ url: `/api/common/firm_members/` }),
    }),
    commonFirmMembersRetrieve: build.query<
      CommonFirmMembersRetrieveApiResponse,
      CommonFirmMembersRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/firm_members/${queryArg.id}/`,
      }),
    }),
    commonFirmMembersUpdate: build.mutation<
      CommonFirmMembersUpdateApiResponse,
      CommonFirmMembersUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/firm_members/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.firmMember,
      }),
    }),
    commonFirmMembersPartialUpdate: build.mutation<
      CommonFirmMembersPartialUpdateApiResponse,
      CommonFirmMembersPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/firm_members/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedFirmMember,
      }),
    }),
    commonFirmMembersDestroy: build.mutation<
      CommonFirmMembersDestroyApiResponse,
      CommonFirmMembersDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/firm_members/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    commonHealthRetrieve: build.query<
      CommonHealthRetrieveApiResponse,
      CommonHealthRetrieveApiArg
    >({
      query: () => ({ url: `/api/common/health` }),
    }),
    commonInvitationsList: build.query<
      CommonInvitationsListApiResponse,
      CommonInvitationsListApiArg
    >({
      query: () => ({ url: `/api/common/invitations/` }),
    }),
    commonInvitationsCreate: build.mutation<
      CommonInvitationsCreateApiResponse,
      CommonInvitationsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/invitations/`,
        method: "POST",
        body: queryArg.invitation,
      }),
    }),
    commonInvitationsRetrieve: build.query<
      CommonInvitationsRetrieveApiResponse,
      CommonInvitationsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/common/invitations/${queryArg.id}/` }),
    }),
    commonInvitationsUpdate: build.mutation<
      CommonInvitationsUpdateApiResponse,
      CommonInvitationsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/invitations/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.invitation,
      }),
    }),
    commonInvitationsPartialUpdate: build.mutation<
      CommonInvitationsPartialUpdateApiResponse,
      CommonInvitationsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/invitations/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedInvitation,
      }),
    }),
    commonInvitationsDestroy: build.mutation<
      CommonInvitationsDestroyApiResponse,
      CommonInvitationsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/invitations/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    commonPageSectionList: build.query<
      CommonPageSectionListApiResponse,
      CommonPageSectionListApiArg
    >({
      query: () => ({ url: `/api/common/page_section/` }),
    }),
    commonPageSectionCreate: build.mutation<
      CommonPageSectionCreateApiResponse,
      CommonPageSectionCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_section/`,
        method: "POST",
        body: queryArg.pageSection,
      }),
    }),
    commonPageSectionRetrieve: build.query<
      CommonPageSectionRetrieveApiResponse,
      CommonPageSectionRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_section/${queryArg.id}/`,
      }),
    }),
    commonPageSectionUpdate: build.mutation<
      CommonPageSectionUpdateApiResponse,
      CommonPageSectionUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_section/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.pageSection,
      }),
    }),
    commonPageSectionPartialUpdate: build.mutation<
      CommonPageSectionPartialUpdateApiResponse,
      CommonPageSectionPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_section/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPageSection,
      }),
    }),
    commonPageSelectionBlockList: build.query<
      CommonPageSelectionBlockListApiResponse,
      CommonPageSelectionBlockListApiArg
    >({
      query: () => ({ url: `/api/common/page_selection_block/` }),
    }),
    commonPageSelectionBlockCreate: build.mutation<
      CommonPageSelectionBlockCreateApiResponse,
      CommonPageSelectionBlockCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_selection_block/`,
        method: "POST",
        body: queryArg.pageSectionBlock,
      }),
    }),
    commonPageSelectionBlockRetrieve: build.query<
      CommonPageSelectionBlockRetrieveApiResponse,
      CommonPageSelectionBlockRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_selection_block/${queryArg.id}/`,
      }),
    }),
    commonPageSelectionBlockUpdate: build.mutation<
      CommonPageSelectionBlockUpdateApiResponse,
      CommonPageSelectionBlockUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_selection_block/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.pageSectionBlock,
      }),
    }),
    commonPageSelectionBlockPartialUpdate: build.mutation<
      CommonPageSelectionBlockPartialUpdateApiResponse,
      CommonPageSelectionBlockPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_selection_block/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPageSectionBlock,
      }),
    }),
    commonPageSelectionBlockTypeList: build.query<
      CommonPageSelectionBlockTypeListApiResponse,
      CommonPageSelectionBlockTypeListApiArg
    >({
      query: () => ({ url: `/api/common/page_selection_block_type/` }),
    }),
    commonPageSelectionBlockTypeCreate: build.mutation<
      CommonPageSelectionBlockTypeCreateApiResponse,
      CommonPageSelectionBlockTypeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_selection_block_type/`,
        method: "POST",
        body: queryArg.pageSectionBlockType,
      }),
    }),
    commonPageSelectionBlockTypeRetrieve: build.query<
      CommonPageSelectionBlockTypeRetrieveApiResponse,
      CommonPageSelectionBlockTypeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_selection_block_type/${queryArg.id}/`,
      }),
    }),
    commonPageSelectionBlockTypeUpdate: build.mutation<
      CommonPageSelectionBlockTypeUpdateApiResponse,
      CommonPageSelectionBlockTypeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_selection_block_type/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.pageSectionBlockType,
      }),
    }),
    commonPageSelectionBlockTypePartialUpdate: build.mutation<
      CommonPageSelectionBlockTypePartialUpdateApiResponse,
      CommonPageSelectionBlockTypePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/page_selection_block_type/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPageSectionBlockType,
      }),
    }),
    commonRoleAllowedSectionsList: build.query<
      CommonRoleAllowedSectionsListApiResponse,
      CommonRoleAllowedSectionsListApiArg
    >({
      query: () => ({ url: `/api/common/role_allowed_sections/` }),
    }),
    commonRoleHierarchyList: build.query<
      CommonRoleHierarchyListApiResponse,
      CommonRoleHierarchyListApiArg
    >({
      query: () => ({ url: `/api/common/role_hierarchy/` }),
    }),
    commonRoleHierarchyRetrieve: build.query<
      CommonRoleHierarchyRetrieveApiResponse,
      CommonRoleHierarchyRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/role_hierarchy/${queryArg.id}/`,
      }),
    }),
    commonRoleTypesList: build.query<
      CommonRoleTypesListApiResponse,
      CommonRoleTypesListApiArg
    >({
      query: () => ({ url: `/api/common/role_types/` }),
    }),
    commonRoleTypesCreate: build.mutation<
      CommonRoleTypesCreateApiResponse,
      CommonRoleTypesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/role_types/`,
        method: "POST",
        body: queryArg.roleType,
      }),
    }),
    commonRoleTypesRetrieve: build.query<
      CommonRoleTypesRetrieveApiResponse,
      CommonRoleTypesRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/common/role_types/${queryArg.id}/` }),
    }),
    commonRoleTypesUpdate: build.mutation<
      CommonRoleTypesUpdateApiResponse,
      CommonRoleTypesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/role_types/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.roleType,
      }),
    }),
    commonRoleTypesPartialUpdate: build.mutation<
      CommonRoleTypesPartialUpdateApiResponse,
      CommonRoleTypesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/role_types/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedRoleType,
      }),
    }),
    commonRolesList: build.query<
      CommonRolesListApiResponse,
      CommonRolesListApiArg
    >({
      query: () => ({ url: `/api/common/roles/` }),
    }),
    commonRolesCreate: build.mutation<
      CommonRolesCreateApiResponse,
      CommonRolesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/roles/`,
        method: "POST",
        body: queryArg.role,
      }),
    }),
    commonRolesRetrieve: build.query<
      CommonRolesRetrieveApiResponse,
      CommonRolesRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/common/roles/${queryArg.id}/` }),
    }),
    commonRolesUpdate: build.mutation<
      CommonRolesUpdateApiResponse,
      CommonRolesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/roles/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.role,
      }),
    }),
    commonRolesPartialUpdate: build.mutation<
      CommonRolesPartialUpdateApiResponse,
      CommonRolesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/common/roles/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedRole,
      }),
    }),
    contentDiclosureSubCategoriesList: build.query<
      ContentDiclosureSubCategoriesListApiResponse,
      ContentDiclosureSubCategoriesListApiArg
    >({
      query: () => ({ url: `/api/content/diclosure_sub_categories/` }),
    }),
    contentDiclosureSubCategoriesRetrieve: build.query<
      ContentDiclosureSubCategoriesRetrieveApiResponse,
      ContentDiclosureSubCategoriesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/content/diclosure_sub_categories/${queryArg.id}/`,
      }),
    }),
    contentFirmDisclosureOverrideList: build.query<
      ContentFirmDisclosureOverrideListApiResponse,
      ContentFirmDisclosureOverrideListApiArg
    >({
      query: () => ({ url: `/api/content/firm_disclosure_override/` }),
    }),
    contentFirmDisclosureOverrideRetrieve: build.query<
      ContentFirmDisclosureOverrideRetrieveApiResponse,
      ContentFirmDisclosureOverrideRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/content/firm_disclosure_override/${queryArg.id}/`,
      }),
    }),
    contentFirmDisclosureSectionBlockOverrideList: build.query<
      ContentFirmDisclosureSectionBlockOverrideListApiResponse,
      ContentFirmDisclosureSectionBlockOverrideListApiArg
    >({
      query: () => ({
        url: `/api/content/firm_disclosure_section_block_override/`,
      }),
    }),
    contentFirmDisclosureSectionBlockOverrideRetrieve: build.query<
      ContentFirmDisclosureSectionBlockOverrideRetrieveApiResponse,
      ContentFirmDisclosureSectionBlockOverrideRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/content/firm_disclosure_section_block_override/${queryArg.id}/`,
      }),
    }),
    contentFirmTargetBaseContentOverrideList: build.query<
      ContentFirmTargetBaseContentOverrideListApiResponse,
      ContentFirmTargetBaseContentOverrideListApiArg
    >({
      query: () => ({ url: `/api/content/firm_target_base_content_override/` }),
    }),
    contentFirmTargetBaseContentOverrideRetrieve: build.query<
      ContentFirmTargetBaseContentOverrideRetrieveApiResponse,
      ContentFirmTargetBaseContentOverrideRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/content/firm_target_base_content_override/${queryArg.id}/`,
      }),
    }),
    contentGetTargetDocumentRetrieve: build.query<
      ContentGetTargetDocumentRetrieveApiResponse,
      ContentGetTargetDocumentRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/content/get_target_document`,
        params: { document_code: queryArg.documentCode },
      }),
    }),
    contentPageContentList: build.query<
      ContentPageContentListApiResponse,
      ContentPageContentListApiArg
    >({
      query: () => ({ url: `/api/content/page_content/` }),
    }),
    contentTargetsList: build.query<
      ContentTargetsListApiResponse,
      ContentTargetsListApiArg
    >({
      query: () => ({ url: `/api/content/targets/` }),
    }),
    contentTargetsRetrieve: build.query<
      ContentTargetsRetrieveApiResponse,
      ContentTargetsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/content/targets/${queryArg.id}/` }),
    }),
    dataProvidersGicsIndustrySubGroupList: build.query<
      DataProvidersGicsIndustrySubGroupListApiResponse,
      DataProvidersGicsIndustrySubGroupListApiArg
    >({
      query: () => ({ url: `/api/data_providers/gics_industry_sub_group/` }),
    }),
    dataProvidersPlanAccountsLinkRayJayExternalCreate: build.mutation<
      DataProvidersPlanAccountsLinkRayJayExternalCreateApiResponse,
      DataProvidersPlanAccountsLinkRayJayExternalCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/data_providers/plan/${queryArg.planId}/accounts/link_ray_jay_external`,
        method: "POST",
        body: queryArg.linkExternalAccounts,
      }),
    }),
    dataProvidersPlanAccountsLinkSchwabExternalCreate: build.mutation<
      DataProvidersPlanAccountsLinkSchwabExternalCreateApiResponse,
      DataProvidersPlanAccountsLinkSchwabExternalCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/data_providers/plan/${queryArg.planId}/accounts/link_schwab_external`,
        method: "POST",
        body: queryArg.linkExternalAccounts,
      }),
    }),
    dataProvidersSearchRaymondAccountsList: build.query<
      DataProvidersSearchRaymondAccountsListApiResponse,
      DataProvidersSearchRaymondAccountsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/data_providers/search_raymond_accounts`,
        params: { search_criterion: queryArg.searchCriterion },
      }),
    }),
    dataProvidersSearchRaymondAccountsByHouseholdList: build.query<
      DataProvidersSearchRaymondAccountsByHouseholdListApiResponse,
      DataProvidersSearchRaymondAccountsByHouseholdListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/data_providers/search_raymond_accounts_by_household/${queryArg.externalHouseholdId}`,
      }),
    }),
    dataProvidersSearchRaymondHouseholdsList: build.query<
      DataProvidersSearchRaymondHouseholdsListApiResponse,
      DataProvidersSearchRaymondHouseholdsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/data_providers/search_raymond_households`,
        params: { search_criterion: queryArg.searchCriterion },
      }),
    }),
    dataProvidersSearchSchwabAccountsList: build.query<
      DataProvidersSearchSchwabAccountsListApiResponse,
      DataProvidersSearchSchwabAccountsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/data_providers/search_schwab_accounts`,
        params: { search_criterion: queryArg.searchCriterion },
      }),
    }),
    dataProvidersStyleBoxList: build.query<
      DataProvidersStyleBoxListApiResponse,
      DataProvidersStyleBoxListApiArg
    >({
      query: () => ({ url: `/api/data_providers/style_box/` }),
    }),
    notificationsNotificationList: build.query<
      NotificationsNotificationListApiResponse,
      NotificationsNotificationListApiArg
    >({
      query: () => ({ url: `/api/notifications/notification/` }),
    }),
    notificationsNotificationRetrieve: build.query<
      NotificationsNotificationRetrieveApiResponse,
      NotificationsNotificationRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification/${queryArg.id}/`,
      }),
    }),
    notificationsNotificationUpdate: build.mutation<
      NotificationsNotificationUpdateApiResponse,
      NotificationsNotificationUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.notification,
      }),
    }),
    notificationsNotificationPartialUpdate: build.mutation<
      NotificationsNotificationPartialUpdateApiResponse,
      NotificationsNotificationPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedNotification,
      }),
    }),
    notificationsNotificationGetReadNotificationsList: build.query<
      NotificationsNotificationGetReadNotificationsListApiResponse,
      NotificationsNotificationGetReadNotificationsListApiArg
    >({
      query: () => ({
        url: `/api/notifications/notification/get_read_notifications/`,
      }),
    }),
    notificationsNotificationGetSnoozedNotificationsList: build.query<
      NotificationsNotificationGetSnoozedNotificationsListApiResponse,
      NotificationsNotificationGetSnoozedNotificationsListApiArg
    >({
      query: () => ({
        url: `/api/notifications/notification/get_snoozed_notifications/`,
      }),
    }),
    notificationsNotificationLevelList: build.query<
      NotificationsNotificationLevelListApiResponse,
      NotificationsNotificationLevelListApiArg
    >({
      query: () => ({ url: `/api/notifications/notification_level/` }),
    }),
    notificationsNotificationLevelRetrieve: build.query<
      NotificationsNotificationLevelRetrieveApiResponse,
      NotificationsNotificationLevelRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification_level/${queryArg.id}/`,
      }),
    }),
    notificationsNotificationSettingList: build.query<
      NotificationsNotificationSettingListApiResponse,
      NotificationsNotificationSettingListApiArg
    >({
      query: () => ({ url: `/api/notifications/notification_setting/` }),
    }),
    notificationsNotificationSettingRetrieve: build.query<
      NotificationsNotificationSettingRetrieveApiResponse,
      NotificationsNotificationSettingRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification_setting/${queryArg.id}/`,
      }),
    }),
    notificationsNotificationSettingUpdate: build.mutation<
      NotificationsNotificationSettingUpdateApiResponse,
      NotificationsNotificationSettingUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification_setting/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.notificationSetting,
      }),
    }),
    notificationsNotificationSettingPartialUpdate: build.mutation<
      NotificationsNotificationSettingPartialUpdateApiResponse,
      NotificationsNotificationSettingPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification_setting/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedNotificationSetting,
      }),
    }),
    notificationsNotificationTierThresholdList: build.query<
      NotificationsNotificationTierThresholdListApiResponse,
      NotificationsNotificationTierThresholdListApiArg
    >({
      query: () => ({ url: `/api/notifications/notification_tier_threshold/` }),
    }),
    notificationsNotificationTierThresholdRetrieve: build.query<
      NotificationsNotificationTierThresholdRetrieveApiResponse,
      NotificationsNotificationTierThresholdRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification_tier_threshold/${queryArg.id}/`,
      }),
    }),
    notificationsNotificationTierThresholdUpdate: build.mutation<
      NotificationsNotificationTierThresholdUpdateApiResponse,
      NotificationsNotificationTierThresholdUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification_tier_threshold/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.notificationTierThreshold,
      }),
    }),
    notificationsNotificationTierThresholdPartialUpdate: build.mutation<
      NotificationsNotificationTierThresholdPartialUpdateApiResponse,
      NotificationsNotificationTierThresholdPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification_tier_threshold/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedNotificationTierThreshold,
      }),
    }),
    notificationsNotificationTypeList: build.query<
      NotificationsNotificationTypeListApiResponse,
      NotificationsNotificationTypeListApiArg
    >({
      query: () => ({ url: `/api/notifications/notification_type/` }),
    }),
    notificationsNotificationTypeRetrieve: build.query<
      NotificationsNotificationTypeRetrieveApiResponse,
      NotificationsNotificationTypeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/notifications/notification_type/${queryArg.id}/`,
      }),
    }),
    portfolioBenchmarkEquityStyleBoxHeatmapList: build.query<
      PortfolioBenchmarkEquityStyleBoxHeatmapListApiResponse,
      PortfolioBenchmarkEquityStyleBoxHeatmapListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/benchmark_equity_style_box_heatmap/${queryArg.portModuleDataId}/${queryArg.benchmarkModuleDataId}/`,
      }),
    }),
    portfolioConstraintChoicesList: build.query<
      PortfolioConstraintChoicesListApiResponse,
      PortfolioConstraintChoicesListApiArg
    >({
      query: () => ({ url: `/api/portfolio/constraint_choices/` }),
    }),
    portfolioConstraintChoicesRetrieve: build.query<
      PortfolioConstraintChoicesRetrieveApiResponse,
      PortfolioConstraintChoicesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/constraint_choices/${queryArg.id}/`,
      }),
    }),
    portfolioGetPortfolioModuleDataCreate: build.query<
      PortfolioGetPortfolioModuleDataCreateApiResponse,
      PortfolioGetPortfolioModuleDataCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/get_portfolio_module_data`,
        method: "POST",
        body: queryArg.portfolioModuleDataStatisticSetBatchViewRequests,
      }),
    }),
    portfolioOptimizerDynamicRulesList: build.query<
      PortfolioOptimizerDynamicRulesListApiResponse,
      PortfolioOptimizerDynamicRulesListApiArg
    >({
      query: () => ({ url: `/api/portfolio/optimizer_dynamic_rules/` }),
    }),
    portfolioOptimizerTemplatesList: build.query<
      PortfolioOptimizerTemplatesListApiResponse,
      PortfolioOptimizerTemplatesListApiArg
    >({
      query: () => ({ url: `/api/portfolio/optimizer_templates/` }),
    }),
    portfolioOptimizerTemplatesCreate: build.mutation<
      PortfolioOptimizerTemplatesCreateApiResponse,
      PortfolioOptimizerTemplatesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/optimizer_templates/`,
        method: "POST",
        body: queryArg.optimizerTemplateContainer,
      }),
    }),
    portfolioOptimizerTemplatesRetrieve: build.query<
      PortfolioOptimizerTemplatesRetrieveApiResponse,
      PortfolioOptimizerTemplatesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/optimizer_templates/${queryArg.id}/`,
      }),
    }),
    portfolioOptimizerTemplatesUpdate: build.mutation<
      PortfolioOptimizerTemplatesUpdateApiResponse,
      PortfolioOptimizerTemplatesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/optimizer_templates/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.optimizerTemplateContainer,
      }),
    }),
    portfolioOptimizerTemplatesPartialUpdate: build.mutation<
      PortfolioOptimizerTemplatesPartialUpdateApiResponse,
      PortfolioOptimizerTemplatesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/optimizer_templates/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedOptimizerTemplateContainer,
      }),
    }),
    portfolioOptimizerTemplatesDestroy: build.mutation<
      PortfolioOptimizerTemplatesDestroyApiResponse,
      PortfolioOptimizerTemplatesDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/optimizer_templates/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    portfolioOptimizerTemplatesLoadTemplateUpdate: build.mutation<
      PortfolioOptimizerTemplatesLoadTemplateUpdateApiResponse,
      PortfolioOptimizerTemplatesLoadTemplateUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/optimizer_templates/${queryArg.id}/load_template/`,
        method: "PUT",
        body: queryArg.optimizerTemplateContainerLoad,
      }),
    }),
    portfolioOptimizerTemplatesLoadJobUpdate: build.mutation<
      PortfolioOptimizerTemplatesLoadJobUpdateApiResponse,
      PortfolioOptimizerTemplatesLoadJobUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/optimizer_templates/load_job/`,
        method: "PUT",
        body: queryArg.optimizerTemplateContainerLoadFromJob,
      }),
    }),
    portfolioPortfolioGroupList: build.query<
      PortfolioPortfolioGroupListApiResponse,
      PortfolioPortfolioGroupListApiArg
    >({
      query: () => ({ url: `/api/portfolio/portfolio_group/` }),
    }),
    portfolioPortfolioGroupCreate: build.mutation<
      PortfolioPortfolioGroupCreateApiResponse,
      PortfolioPortfolioGroupCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group/`,
        method: "POST",
        body: queryArg.portfolioGroup,
      }),
    }),
    portfolioPortfolioGroupRetrieve: build.query<
      PortfolioPortfolioGroupRetrieveApiResponse,
      PortfolioPortfolioGroupRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupUpdate: build.mutation<
      PortfolioPortfolioGroupUpdateApiResponse,
      PortfolioPortfolioGroupUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioGroup,
      }),
    }),
    portfolioPortfolioGroupPartialUpdate: build.mutation<
      PortfolioPortfolioGroupPartialUpdateApiResponse,
      PortfolioPortfolioGroupPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPortfolioGroup,
      }),
    }),
    portfolioPortfolioGroupDestroy: build.mutation<
      PortfolioPortfolioGroupDestroyApiResponse,
      PortfolioPortfolioGroupDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    portfolioPortfolioGroupVersionList: build.query<
      PortfolioPortfolioGroupVersionListApiResponse,
      PortfolioPortfolioGroupVersionListApiArg
    >({
      query: () => ({ url: `/api/portfolio/portfolio_group_version/` }),
    }),
    portfolioPortfolioGroupVersionRetrieve: build.query<
      PortfolioPortfolioGroupVersionRetrieveApiResponse,
      PortfolioPortfolioGroupVersionRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionUpdate: build.mutation<
      PortfolioPortfolioGroupVersionUpdateApiResponse,
      PortfolioPortfolioGroupVersionUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioGroupVersion,
      }),
    }),
    portfolioPortfolioGroupVersionPartialUpdate: build.mutation<
      PortfolioPortfolioGroupVersionPartialUpdateApiResponse,
      PortfolioPortfolioGroupVersionPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPortfolioGroupVersion,
      }),
    }),
    portfolioPortfolioGroupVersionAssetClassesList: build.query<
      PortfolioPortfolioGroupVersionAssetClassesListApiResponse,
      PortfolioPortfolioGroupVersionAssetClassesListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/asset_classes/`,
      }),
    }),
    portfolioPortfolioGroupVersionAssetClassesRetrieve: build.query<
      PortfolioPortfolioGroupVersionAssetClassesRetrieveApiResponse,
      PortfolioPortfolioGroupVersionAssetClassesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/asset_classes/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionAssetClassesUpdate: build.mutation<
      PortfolioPortfolioGroupVersionAssetClassesUpdateApiResponse,
      PortfolioPortfolioGroupVersionAssetClassesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/asset_classes/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioAssetClassOptions,
      }),
    }),
    portfolioPortfolioGroupVersionAssetClassesPartialUpdate: build.mutation<
      PortfolioPortfolioGroupVersionAssetClassesPartialUpdateApiResponse,
      PortfolioPortfolioGroupVersionAssetClassesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/asset_classes/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPortfolioAssetClassOptions,
      }),
    }),
    portfolioPortfolioGroupVersionModulesList: build.query<
      PortfolioPortfolioGroupVersionModulesListApiResponse,
      PortfolioPortfolioGroupVersionModulesListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesCreate: build.mutation<
      PortfolioPortfolioGroupVersionModulesCreateApiResponse,
      PortfolioPortfolioGroupVersionModulesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/`,
        method: "POST",
        body: queryArg.portfolioModule,
      }),
    }),
    portfolioPortfolioGroupVersionModulesConstraintsList: build.query<
      PortfolioPortfolioGroupVersionModulesConstraintsListApiResponse,
      PortfolioPortfolioGroupVersionModulesConstraintsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/constraints/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesConstraintsRetrieve: build.query<
      PortfolioPortfolioGroupVersionModulesConstraintsRetrieveApiResponse,
      PortfolioPortfolioGroupVersionModulesConstraintsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/constraints/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesConstraintsUpdate: build.mutation<
      PortfolioPortfolioGroupVersionModulesConstraintsUpdateApiResponse,
      PortfolioPortfolioGroupVersionModulesConstraintsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/constraints/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioModuleConstraints,
      }),
    }),
    portfolioPortfolioGroupVersionModulesConstraintsPartialUpdate:
      build.mutation<
        PortfolioPortfolioGroupVersionModulesConstraintsPartialUpdateApiResponse,
        PortfolioPortfolioGroupVersionModulesConstraintsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/constraints/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedPortfolioModuleConstraints,
        }),
      }),
    portfolioPortfolioGroupVersionModulesConstraintsAcList: build.query<
      PortfolioPortfolioGroupVersionModulesConstraintsAcListApiResponse,
      PortfolioPortfolioGroupVersionModulesConstraintsAcListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/constraints_ac/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesConstraintsAcRetrieve: build.query<
      PortfolioPortfolioGroupVersionModulesConstraintsAcRetrieveApiResponse,
      PortfolioPortfolioGroupVersionModulesConstraintsAcRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/constraints_ac/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesConstraintsAcUpdate: build.mutation<
      PortfolioPortfolioGroupVersionModulesConstraintsAcUpdateApiResponse,
      PortfolioPortfolioGroupVersionModulesConstraintsAcUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/constraints_ac/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioModuleConstraintsAc,
      }),
    }),
    portfolioPortfolioGroupVersionModulesConstraintsAcPartialUpdate:
      build.mutation<
        PortfolioPortfolioGroupVersionModulesConstraintsAcPartialUpdateApiResponse,
        PortfolioPortfolioGroupVersionModulesConstraintsAcPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/constraints_ac/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedPortfolioModuleConstraintsAc,
        }),
      }),
    portfolioPortfolioGroupVersionModulesDataList: build.query<
      PortfolioPortfolioGroupVersionModulesDataListApiResponse,
      PortfolioPortfolioGroupVersionModulesDataListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesDataAllocationsList: build.query<
      PortfolioPortfolioGroupVersionModulesDataAllocationsListApiResponse,
      PortfolioPortfolioGroupVersionModulesDataAllocationsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.dataId}/allocations/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesDataAllocationsRetrieve: build.query<
      PortfolioPortfolioGroupVersionModulesDataAllocationsRetrieveApiResponse,
      PortfolioPortfolioGroupVersionModulesDataAllocationsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.dataId}/allocations/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesDataAllocationsUpdate: build.mutation<
      PortfolioPortfolioGroupVersionModulesDataAllocationsUpdateApiResponse,
      PortfolioPortfolioGroupVersionModulesDataAllocationsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.dataId}/allocations/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioModuleDataAllocation,
      }),
    }),
    portfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdate:
      build.mutation<
        PortfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdateApiResponse,
        PortfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.dataId}/allocations/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedPortfolioModuleDataAllocation,
        }),
      }),
    portfolioPortfolioGroupVersionModulesDataRetrieve: build.query<
      PortfolioPortfolioGroupVersionModulesDataRetrieveApiResponse,
      PortfolioPortfolioGroupVersionModulesDataRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesDataUpdate: build.mutation<
      PortfolioPortfolioGroupVersionModulesDataUpdateApiResponse,
      PortfolioPortfolioGroupVersionModulesDataUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioModuleData,
      }),
    }),
    portfolioPortfolioGroupVersionModulesDataPartialUpdate: build.mutation<
      PortfolioPortfolioGroupVersionModulesDataPartialUpdateApiResponse,
      PortfolioPortfolioGroupVersionModulesDataPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPortfolioModuleData,
      }),
    }),
    portfolioPortfolioGroupVersionModulesDataMakeSelectedUpdate: build.mutation<
      PortfolioPortfolioGroupVersionModulesDataMakeSelectedUpdateApiResponse,
      PortfolioPortfolioGroupVersionModulesDataMakeSelectedUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.id}/make_selected/`,
        method: "PUT",
      }),
    }),
    portfolioPortfolioGroupVersionModulesRetrieve: build.query<
      PortfolioPortfolioGroupVersionModulesRetrieveApiResponse,
      PortfolioPortfolioGroupVersionModulesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionModulesUpdate: build.mutation<
      PortfolioPortfolioGroupVersionModulesUpdateApiResponse,
      PortfolioPortfolioGroupVersionModulesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioModule,
      }),
    }),
    portfolioPortfolioGroupVersionModulesPartialUpdate: build.mutation<
      PortfolioPortfolioGroupVersionModulesPartialUpdateApiResponse,
      PortfolioPortfolioGroupVersionModulesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPortfolioModule,
      }),
    }),
    portfolioPortfolioGroupVersionModulesDestroy: build.mutation<
      PortfolioPortfolioGroupVersionModulesDestroyApiResponse,
      PortfolioPortfolioGroupVersionModulesDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerAllConsList: build.query<
      PortfolioPortfolioGroupVersionOptimizerAllConsListApiResponse,
      PortfolioPortfolioGroupVersionOptimizerAllConsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_all_cons/`,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerAllConsRetrieve: build.query<
      PortfolioPortfolioGroupVersionOptimizerAllConsRetrieveApiResponse,
      PortfolioPortfolioGroupVersionOptimizerAllConsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_all_cons/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerJobsList: build.query<
      PortfolioPortfolioGroupVersionOptimizerJobsListApiResponse,
      PortfolioPortfolioGroupVersionOptimizerJobsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_jobs/`,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerJobsCreate: build.mutation<
      PortfolioPortfolioGroupVersionOptimizerJobsCreateApiResponse,
      PortfolioPortfolioGroupVersionOptimizerJobsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_jobs/`,
        method: "POST",
        body: queryArg.portfolioGroupVersionOptimizationJobRoot,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerJobsRetrieve: build.query<
      PortfolioPortfolioGroupVersionOptimizerJobsRetrieveApiResponse,
      PortfolioPortfolioGroupVersionOptimizerJobsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_jobs/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerJobsUpdate: build.mutation<
      PortfolioPortfolioGroupVersionOptimizerJobsUpdateApiResponse,
      PortfolioPortfolioGroupVersionOptimizerJobsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_jobs/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioGroupVersionOptimizationJobRoot,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerJobsPartialUpdate: build.mutation<
      PortfolioPortfolioGroupVersionOptimizerJobsPartialUpdateApiResponse,
      PortfolioPortfolioGroupVersionOptimizerJobsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_jobs/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedPortfolioGroupVersionOptimizationJobRoot,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerJobsDestroy: build.mutation<
      PortfolioPortfolioGroupVersionOptimizerJobsDestroyApiResponse,
      PortfolioPortfolioGroupVersionOptimizerJobsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_jobs/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerSettingsList: build.query<
      PortfolioPortfolioGroupVersionOptimizerSettingsListApiResponse,
      PortfolioPortfolioGroupVersionOptimizerSettingsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_settings/`,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerSettingsCreate: build.mutation<
      PortfolioPortfolioGroupVersionOptimizerSettingsCreateApiResponse,
      PortfolioPortfolioGroupVersionOptimizerSettingsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_settings/`,
        method: "POST",
        body: queryArg.portfolioGroupVersionOptimizerSettings,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerSettingsRetrieve: build.query<
      PortfolioPortfolioGroupVersionOptimizerSettingsRetrieveApiResponse,
      PortfolioPortfolioGroupVersionOptimizerSettingsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_settings/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerSettingsUpdate: build.mutation<
      PortfolioPortfolioGroupVersionOptimizerSettingsUpdateApiResponse,
      PortfolioPortfolioGroupVersionOptimizerSettingsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_settings/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.portfolioGroupVersionOptimizerSettings,
      }),
    }),
    portfolioPortfolioGroupVersionOptimizerSettingsPartialUpdate:
      build.mutation<
        PortfolioPortfolioGroupVersionOptimizerSettingsPartialUpdateApiResponse,
        PortfolioPortfolioGroupVersionOptimizerSettingsPartialUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/optimizer_settings/${queryArg.id}/`,
          method: "PATCH",
          body: queryArg.patchedPortfolioGroupVersionOptimizerSettings,
        }),
      }),
    portfolioPortfolioGroupVersionStatusList: build.query<
      PortfolioPortfolioGroupVersionStatusListApiResponse,
      PortfolioPortfolioGroupVersionStatusListApiArg
    >({
      query: () => ({ url: `/api/portfolio/portfolio_group_version_status/` }),
    }),
    portfolioPortfolioGroupVersionStatusRetrieve: build.query<
      PortfolioPortfolioGroupVersionStatusRetrieveApiResponse,
      PortfolioPortfolioGroupVersionStatusRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_group_version_status/${queryArg.id}/`,
      }),
    }),
    portfolioPortfolioModuleDataEquityStyleBoxWeightsList: build.query<
      PortfolioPortfolioModuleDataEquityStyleBoxWeightsListApiResponse,
      PortfolioPortfolioModuleDataEquityStyleBoxWeightsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_module_data_equity_style_box_weights/${queryArg.portModuleDataId}/`,
      }),
    }),
    portfolioPortfolioModuleDataTypeList: build.query<
      PortfolioPortfolioModuleDataTypeListApiResponse,
      PortfolioPortfolioModuleDataTypeListApiArg
    >({
      query: () => ({ url: `/api/portfolio/portfolio_module_data_type/` }),
    }),
    portfolioPortfolioModuleDataTypeRetrieve: build.query<
      PortfolioPortfolioModuleDataTypeRetrieveApiResponse,
      PortfolioPortfolioModuleDataTypeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/portfolio_module_data_type/${queryArg.id}/`,
      }),
    }),
    portfolioRunAutoOptimizationsRetrieve: build.mutation<
      PortfolioRunAutoOptimizationsRetrieveApiResponse,
      PortfolioRunAutoOptimizationsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/run_auto_optimizations/${queryArg.portModuleId}/`,
        method: "GET",
      }),
    }),
    portfolioRunDynamicOptimizationsCreate: build.mutation<
      PortfolioRunDynamicOptimizationsCreateApiResponse,
      PortfolioRunDynamicOptimizationsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/portfolio/run_dynamic_optimizations/${queryArg.portModuleId}/`,
        method: "POST",
        body: queryArg.processOptimizerDynamicRules,
      }),
    }),
    questionnaireClientTierList: build.query<
      QuestionnaireClientTierListApiResponse,
      QuestionnaireClientTierListApiArg
    >({
      query: () => ({ url: `/api/questionnaire/client_tier/` }),
    }),
    questionnaireClientTierRetrieve: build.query<
      QuestionnaireClientTierRetrieveApiResponse,
      QuestionnaireClientTierRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/client_tier/${queryArg.id}/`,
      }),
    }),
    questionnaireClientsList: build.query<
      QuestionnaireClientsListApiResponse,
      QuestionnaireClientsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/`,
        params: { search: queryArg.search },
      }),
    }),
    questionnaireClientsCreate: build.mutation<
      QuestionnaireClientsCreateApiResponse,
      QuestionnaireClientsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/`,
        method: "POST",
        body: queryArg.client,
      }),
    }),
    questionnaireClientsNotesList: build.query<
      QuestionnaireClientsNotesListApiResponse,
      QuestionnaireClientsNotesListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.clientId}/notes/`,
      }),
    }),
    questionnaireClientsNotesCreate: build.mutation<
      QuestionnaireClientsNotesCreateApiResponse,
      QuestionnaireClientsNotesCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.clientId}/notes/`,
        method: "POST",
        body: queryArg.clientNote,
      }),
    }),
    questionnaireClientsNotesRetrieve: build.query<
      QuestionnaireClientsNotesRetrieveApiResponse,
      QuestionnaireClientsNotesRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.clientId}/notes/${queryArg.id}/`,
      }),
    }),
    questionnaireClientsNotesUpdate: build.mutation<
      QuestionnaireClientsNotesUpdateApiResponse,
      QuestionnaireClientsNotesUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.clientId}/notes/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.clientNote,
      }),
    }),
    questionnaireClientsNotesPartialUpdate: build.mutation<
      QuestionnaireClientsNotesPartialUpdateApiResponse,
      QuestionnaireClientsNotesPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.clientId}/notes/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClientNote,
      }),
    }),
    questionnaireClientsNotesDestroy: build.mutation<
      QuestionnaireClientsNotesDestroyApiResponse,
      QuestionnaireClientsNotesDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.clientId}/notes/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnaireClientsRetrieve: build.query<
      QuestionnaireClientsRetrieveApiResponse,
      QuestionnaireClientsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.id}/`,
      }),
    }),
    questionnaireClientsUpdate: build.mutation<
      QuestionnaireClientsUpdateApiResponse,
      QuestionnaireClientsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.client,
      }),
    }),
    questionnaireClientsPartialUpdate: build.mutation<
      QuestionnaireClientsPartialUpdateApiResponse,
      QuestionnaireClientsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClient,
      }),
    }),
    questionnaireClientsDestroy: build.mutation<
      QuestionnaireClientsDestroyApiResponse,
      QuestionnaireClientsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnaireClientsCurrentAllocationDetailRetrieve: build.query<
      QuestionnaireClientsCurrentAllocationDetailRetrieveApiResponse,
      QuestionnaireClientsCurrentAllocationDetailRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/clients/${queryArg.id}/current_allocation_detail/`,
      }),
    }),
    questionnaireExternalBlackDiamondConnectionCreate: build.mutation<
      QuestionnaireExternalBlackDiamondConnectionCreateApiResponse,
      QuestionnaireExternalBlackDiamondConnectionCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/external/black_diamond/connection`,
        method: "POST",
        body: queryArg.blackDiamondIntegration,
      }),
    }),
    questionnaireExternalBlackDiamondConnectionDestroy: build.mutation<
      QuestionnaireExternalBlackDiamondConnectionDestroyApiResponse,
      QuestionnaireExternalBlackDiamondConnectionDestroyApiArg
    >({
      query: () => ({
        url: `/api/questionnaire/external/black_diamond/connection`,
        method: "DELETE",
      }),
    }),
    questionnaireExternalBlackDiamondPortfolioAccountsList: build.query<
      QuestionnaireExternalBlackDiamondPortfolioAccountsListApiResponse,
      QuestionnaireExternalBlackDiamondPortfolioAccountsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/external/black_diamond/portfolio/${queryArg.externalPortfolioId}/accounts`,
      }),
    }),
    questionnaireExternalBlackDiamondSearchAccountsList: build.query<
      QuestionnaireExternalBlackDiamondSearchAccountsListApiResponse,
      QuestionnaireExternalBlackDiamondSearchAccountsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/external/black_diamond/search_accounts`,
        params: { search_criterion: queryArg.searchCriterion },
      }),
    }),
    questionnaireExternalBlackDiamondSearchPortfoliosList: build.query<
      QuestionnaireExternalBlackDiamondSearchPortfoliosListApiResponse,
      QuestionnaireExternalBlackDiamondSearchPortfoliosListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/external/black_diamond/search_portfolios`,
        params: { search_criterion: queryArg.searchCriterion },
      }),
    }),
    questionnaireExternalCollegeDataRetrieve: build.query<
      QuestionnaireExternalCollegeDataRetrieveApiResponse,
      QuestionnaireExternalCollegeDataRetrieveApiArg
    >({
      query: () => ({ url: `/api/questionnaire/external/college_data` }),
    }),
    questionnaireExternalCollegeStatisticsRetrieve: build.query<
      QuestionnaireExternalCollegeStatisticsRetrieveApiResponse,
      QuestionnaireExternalCollegeStatisticsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/external/college_statistics/${queryArg.id}/`,
      }),
    }),
    questionnaireExternalListAvailableIntegratorsList: build.query<
      QuestionnaireExternalListAvailableIntegratorsListApiResponse,
      QuestionnaireExternalListAvailableIntegratorsListApiArg
    >({
      query: () => ({
        url: `/api/questionnaire/external/list_available_integrators`,
      }),
    }),
    questionnaireGetLifeExpectancyRetrieve: build.query<
      QuestionnaireGetLifeExpectancyRetrieveApiResponse,
      QuestionnaireGetLifeExpectancyRetrieveApiArg
    >({
      query: () => ({ url: `/api/questionnaire/get_life_expectancy` }),
    }),
    questionnairePlanList: build.query<
      QuestionnairePlanListApiResponse,
      QuestionnairePlanListApiArg
    >({
      query: () => ({ url: `/api/questionnaire/plan/` }),
    }),
    questionnairePlanCreate: build.mutation<
      QuestionnairePlanCreateApiResponse,
      QuestionnairePlanCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/`,
        method: "POST",
        body: queryArg.clientPlan,
      }),
    }),
    questionnairePlanRetrieve: build.query<
      QuestionnairePlanRetrieveApiResponse,
      QuestionnairePlanRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/api/questionnaire/plan/${queryArg.id}/` }),
    }),
    questionnairePlanUpdate: build.mutation<
      QuestionnairePlanUpdateApiResponse,
      QuestionnairePlanUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.clientPlan,
      }),
    }),
    questionnairePlanPartialUpdate: build.mutation<
      QuestionnairePlanPartialUpdateApiResponse,
      QuestionnairePlanPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClientPlan,
      }),
    }),
    questionnairePlanDestroy: build.mutation<
      QuestionnairePlanDestroyApiResponse,
      QuestionnairePlanDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnairePlanCopyUpdate: build.mutation<
      QuestionnairePlanCopyUpdateApiResponse,
      QuestionnairePlanCopyUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/copy/`,
        method: "PUT",
        body: queryArg.clientPlanCopyRequest,
      }),
    }),
    questionnairePlanOptimizedSocialSecurityRetrieve: build.query<
      QuestionnairePlanOptimizedSocialSecurityRetrieveApiResponse,
      QuestionnairePlanOptimizedSocialSecurityRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/optimized_social_security/`,
      }),
    }),
    questionnairePlanRiskRetrieve: build.query<
      QuestionnairePlanRiskRetrieveApiResponse,
      QuestionnairePlanRiskRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/risk/`,
      }),
    }),
    questionnairePlanRiskUpdate: build.mutation<
      QuestionnairePlanRiskUpdateApiResponse,
      QuestionnairePlanRiskUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/risk/`,
        method: "PUT",
        body: queryArg.clientPlanRisk,
      }),
    }),
    questionnairePlanSavingsThisYearRetrieve: build.query<
      QuestionnairePlanSavingsThisYearRetrieveApiResponse,
      QuestionnairePlanSavingsThisYearRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/savings_this_year/`,
      }),
    }),
    questionnairePlanSpousalBenefitRetrieve: build.query<
      QuestionnairePlanSpousalBenefitRetrieveApiResponse,
      QuestionnairePlanSpousalBenefitRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/spousal_benefit/`,
      }),
    }),
    questionnairePlanSpousalBenefitUpdate: build.mutation<
      QuestionnairePlanSpousalBenefitUpdateApiResponse,
      QuestionnairePlanSpousalBenefitUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.id}/spousal_benefit/`,
        method: "PUT",
        body: queryArg.clientPlanSpousalBenefit,
      }),
    }),
    questionnairePlanAccountsLinkExternalCreate: build.mutation<
      QuestionnairePlanAccountsLinkExternalCreateApiResponse,
      QuestionnairePlanAccountsLinkExternalCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/accounts/link_external`,
        method: "POST",
        body: queryArg.linkBlackDiamondAccounts,
      }),
    }),
    questionnairePlanAccountsList: build.query<
      QuestionnairePlanAccountsListApiResponse,
      QuestionnairePlanAccountsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/accounts/`,
      }),
    }),
    questionnairePlanAccountsCreate: build.mutation<
      QuestionnairePlanAccountsCreateApiResponse,
      QuestionnairePlanAccountsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/accounts/`,
        method: "POST",
        body: queryArg.account,
      }),
    }),
    questionnairePlanAccountsRetrieve: build.query<
      QuestionnairePlanAccountsRetrieveApiResponse,
      QuestionnairePlanAccountsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/accounts/${queryArg.id}/`,
      }),
    }),
    questionnairePlanAccountsUpdate: build.mutation<
      QuestionnairePlanAccountsUpdateApiResponse,
      QuestionnairePlanAccountsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/accounts/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.account,
      }),
    }),
    questionnairePlanAccountsPartialUpdate: build.mutation<
      QuestionnairePlanAccountsPartialUpdateApiResponse,
      QuestionnairePlanAccountsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/accounts/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedAccount,
      }),
    }),
    questionnairePlanAccountsDestroy: build.mutation<
      QuestionnairePlanAccountsDestroyApiResponse,
      QuestionnairePlanAccountsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/accounts/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnairePlanAdditionalIncomeList: build.query<
      QuestionnairePlanAdditionalIncomeListApiResponse,
      QuestionnairePlanAdditionalIncomeListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/additional_income/`,
      }),
    }),
    questionnairePlanAdditionalIncomeCreate: build.mutation<
      QuestionnairePlanAdditionalIncomeCreateApiResponse,
      QuestionnairePlanAdditionalIncomeCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/additional_income/`,
        method: "POST",
        body: queryArg.clientPlanAdditionalIncome,
      }),
    }),
    questionnairePlanAdditionalIncomeRetrieve: build.query<
      QuestionnairePlanAdditionalIncomeRetrieveApiResponse,
      QuestionnairePlanAdditionalIncomeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/additional_income/${queryArg.id}/`,
      }),
    }),
    questionnairePlanAdditionalIncomeUpdate: build.mutation<
      QuestionnairePlanAdditionalIncomeUpdateApiResponse,
      QuestionnairePlanAdditionalIncomeUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/additional_income/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.clientPlanAdditionalIncome,
      }),
    }),
    questionnairePlanAdditionalIncomePartialUpdate: build.mutation<
      QuestionnairePlanAdditionalIncomePartialUpdateApiResponse,
      QuestionnairePlanAdditionalIncomePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/additional_income/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClientPlanAdditionalIncome,
      }),
    }),
    questionnairePlanAdditionalIncomeDestroy: build.mutation<
      QuestionnairePlanAdditionalIncomeDestroyApiResponse,
      QuestionnairePlanAdditionalIncomeDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/additional_income/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnairePlanDeriskList: build.query<
      QuestionnairePlanDeriskListApiResponse,
      QuestionnairePlanDeriskListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/derisk/`,
      }),
    }),
    questionnairePlanDeriskCreate: build.mutation<
      QuestionnairePlanDeriskCreateApiResponse,
      QuestionnairePlanDeriskCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/derisk/`,
        method: "POST",
        body: queryArg.clientPlanDeRiskDetails,
      }),
    }),
    questionnairePlanDeriskRetrieve: build.query<
      QuestionnairePlanDeriskRetrieveApiResponse,
      QuestionnairePlanDeriskRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/derisk/${queryArg.id}/`,
      }),
    }),
    questionnairePlanDeriskUpdate: build.mutation<
      QuestionnairePlanDeriskUpdateApiResponse,
      QuestionnairePlanDeriskUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/derisk/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.clientPlanDeRiskDetails,
      }),
    }),
    questionnairePlanDeriskPartialUpdate: build.mutation<
      QuestionnairePlanDeriskPartialUpdateApiResponse,
      QuestionnairePlanDeriskPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/derisk/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClientPlanDeRiskDetails,
      }),
    }),
    questionnairePlanDeriskDestroy: build.mutation<
      QuestionnairePlanDeriskDestroyApiResponse,
      QuestionnairePlanDeriskDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/derisk/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnairePlanGoalsList: build.query<
      QuestionnairePlanGoalsListApiResponse,
      QuestionnairePlanGoalsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/goals/`,
      }),
    }),
    questionnairePlanGoalsCreate: build.mutation<
      QuestionnairePlanGoalsCreateApiResponse,
      QuestionnairePlanGoalsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/goals/`,
        method: "POST",
        body: queryArg.clientPlanGoal,
      }),
    }),
    questionnairePlanGoalsRetrieve: build.query<
      QuestionnairePlanGoalsRetrieveApiResponse,
      QuestionnairePlanGoalsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/goals/${queryArg.id}/`,
      }),
    }),
    questionnairePlanGoalsUpdate: build.mutation<
      QuestionnairePlanGoalsUpdateApiResponse,
      QuestionnairePlanGoalsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/goals/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.clientPlanGoal,
      }),
    }),
    questionnairePlanGoalsPartialUpdate: build.mutation<
      QuestionnairePlanGoalsPartialUpdateApiResponse,
      QuestionnairePlanGoalsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/goals/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClientPlanGoal,
      }),
    }),
    questionnairePlanGoalsDestroy: build.mutation<
      QuestionnairePlanGoalsDestroyApiResponse,
      QuestionnairePlanGoalsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/goals/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnairePlanHealthcareList: build.query<
      QuestionnairePlanHealthcareListApiResponse,
      QuestionnairePlanHealthcareListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/healthcare/`,
      }),
    }),
    questionnairePlanHealthcareCreate: build.mutation<
      QuestionnairePlanHealthcareCreateApiResponse,
      QuestionnairePlanHealthcareCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/healthcare/`,
        method: "POST",
        body: queryArg.clientPlanHealthcare,
      }),
    }),
    questionnairePlanHealthcareRetrieve: build.query<
      QuestionnairePlanHealthcareRetrieveApiResponse,
      QuestionnairePlanHealthcareRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/healthcare/${queryArg.id}/`,
      }),
    }),
    questionnairePlanHealthcareUpdate: build.mutation<
      QuestionnairePlanHealthcareUpdateApiResponse,
      QuestionnairePlanHealthcareUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/healthcare/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.clientPlanHealthcare,
      }),
    }),
    questionnairePlanHealthcarePartialUpdate: build.mutation<
      QuestionnairePlanHealthcarePartialUpdateApiResponse,
      QuestionnairePlanHealthcarePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/healthcare/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClientPlanHealthcare,
      }),
    }),
    questionnairePlanHealthcareDestroy: build.mutation<
      QuestionnairePlanHealthcareDestroyApiResponse,
      QuestionnairePlanHealthcareDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/healthcare/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnairePlanRealEstateList: build.query<
      QuestionnairePlanRealEstateListApiResponse,
      QuestionnairePlanRealEstateListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/real_estate/`,
      }),
    }),
    questionnairePlanRealEstateCreate: build.mutation<
      QuestionnairePlanRealEstateCreateApiResponse,
      QuestionnairePlanRealEstateCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/real_estate/`,
        method: "POST",
        body: queryArg.clientPlanRealEstate,
      }),
    }),
    questionnairePlanRealEstateRetrieve: build.query<
      QuestionnairePlanRealEstateRetrieveApiResponse,
      QuestionnairePlanRealEstateRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/real_estate/${queryArg.id}/`,
      }),
    }),
    questionnairePlanRealEstateUpdate: build.mutation<
      QuestionnairePlanRealEstateUpdateApiResponse,
      QuestionnairePlanRealEstateUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/real_estate/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.clientPlanRealEstate,
      }),
    }),
    questionnairePlanRealEstatePartialUpdate: build.mutation<
      QuestionnairePlanRealEstatePartialUpdateApiResponse,
      QuestionnairePlanRealEstatePartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/real_estate/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClientPlanRealEstate,
      }),
    }),
    questionnairePlanRealEstateDestroy: build.mutation<
      QuestionnairePlanRealEstateDestroyApiResponse,
      QuestionnairePlanRealEstateDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/real_estate/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnairePlanRelationshipsList: build.query<
      QuestionnairePlanRelationshipsListApiResponse,
      QuestionnairePlanRelationshipsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/relationships/`,
        params: { search: queryArg.search },
      }),
    }),
    questionnairePlanRelationshipsCreate: build.mutation<
      QuestionnairePlanRelationshipsCreateApiResponse,
      QuestionnairePlanRelationshipsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/relationships/`,
        method: "POST",
        body: queryArg.clientPlanRelationship,
      }),
    }),
    questionnairePlanRelationshipsRetrieve: build.query<
      QuestionnairePlanRelationshipsRetrieveApiResponse,
      QuestionnairePlanRelationshipsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/relationships/${queryArg.id}/`,
      }),
    }),
    questionnairePlanRelationshipsUpdate: build.mutation<
      QuestionnairePlanRelationshipsUpdateApiResponse,
      QuestionnairePlanRelationshipsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/relationships/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.clientPlanRelationship,
      }),
    }),
    questionnairePlanRelationshipsPartialUpdate: build.mutation<
      QuestionnairePlanRelationshipsPartialUpdateApiResponse,
      QuestionnairePlanRelationshipsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/relationships/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClientPlanRelationship,
      }),
    }),
    questionnairePlanRelationshipsDestroy: build.mutation<
      QuestionnairePlanRelationshipsDestroyApiResponse,
      QuestionnairePlanRelationshipsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/relationships/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnairePlanSocialSecurityList: build.query<
      QuestionnairePlanSocialSecurityListApiResponse,
      QuestionnairePlanSocialSecurityListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/social_security/`,
      }),
    }),
    questionnairePlanSocialSecurityCreate: build.mutation<
      QuestionnairePlanSocialSecurityCreateApiResponse,
      QuestionnairePlanSocialSecurityCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/social_security/`,
        method: "POST",
        body: queryArg.clientPlanSocialSecurity,
      }),
    }),
    questionnairePlanSocialSecurityRetrieve: build.query<
      QuestionnairePlanSocialSecurityRetrieveApiResponse,
      QuestionnairePlanSocialSecurityRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/social_security/${queryArg.id}/`,
      }),
    }),
    questionnairePlanSocialSecurityUpdate: build.mutation<
      QuestionnairePlanSocialSecurityUpdateApiResponse,
      QuestionnairePlanSocialSecurityUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/social_security/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.clientPlanSocialSecurity,
      }),
    }),
    questionnairePlanSocialSecurityPartialUpdate: build.mutation<
      QuestionnairePlanSocialSecurityPartialUpdateApiResponse,
      QuestionnairePlanSocialSecurityPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/social_security/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedClientPlanSocialSecurity,
      }),
    }),
    questionnairePlanSocialSecurityDestroy: build.mutation<
      QuestionnairePlanSocialSecurityDestroyApiResponse,
      QuestionnairePlanSocialSecurityDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/plan/${queryArg.planId}/social_security/${queryArg.id}/`,
        method: "DELETE",
      }),
    }),
    questionnaireRelationTypeList: build.query<
      QuestionnaireRelationTypeListApiResponse,
      QuestionnaireRelationTypeListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/relation_type/`,
        params: { search: queryArg.search },
      }),
    }),
    questionnaireRelationTypeRetrieve: build.query<
      QuestionnaireRelationTypeRetrieveApiResponse,
      QuestionnaireRelationTypeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/relation_type/${queryArg.code}/`,
      }),
    }),
    questionnaireRiskQuestionList: build.query<
      QuestionnaireRiskQuestionListApiResponse,
      QuestionnaireRiskQuestionListApiArg
    >({
      query: () => ({ url: `/api/questionnaire/risk_question/` }),
    }),
    questionnaireRiskQuestionRetrieve: build.query<
      QuestionnaireRiskQuestionRetrieveApiResponse,
      QuestionnaireRiskQuestionRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/risk_question/${queryArg.id}/`,
      }),
    }),
    questionnaireTaxPurposeList: build.query<
      QuestionnaireTaxPurposeListApiResponse,
      QuestionnaireTaxPurposeListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/tax_purpose/`,
        params: { search: queryArg.search },
      }),
    }),
    questionnaireTaxPurposeRetrieve: build.query<
      QuestionnaireTaxPurposeRetrieveApiResponse,
      QuestionnaireTaxPurposeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/questionnaire/tax_purpose/${queryArg.code}/`,
      }),
    }),
    simulationJobHistoryListCreate: build.query<
      SimulationJobHistoryListCreateApiResponse,
      SimulationJobHistoryListCreateApiArg
    >({
      query: () => ({
        url: `/api/simulation/job_history_list/`,
        method: "POST",
      }),
    }),
    simulationMarketDataFixedIncomeRetrieve: build.query<
      SimulationMarketDataFixedIncomeRetrieveApiResponse,
      SimulationMarketDataFixedIncomeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/market_data/fixed_income/`,
        params: {
          asset_class_id: queryArg.assetClassId,
          simulation_job_id: queryArg.simulationJobId,
        },
      }),
    }),
    simulationQaReportCorrelationsCreate: build.query<
      SimulationQaReportCorrelationsCreateApiResponse,
      SimulationQaReportCorrelationsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/qa_report/correlations/`,
        method: "POST",
        body: queryArg.simulationQaReportRequestBase,
      }),
    }),
    simulationQaReportReturnsNominalCreate: build.query<
      SimulationQaReportReturnsNominalCreateApiResponse,
      SimulationQaReportReturnsNominalCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/qa_report/returns_nominal/`,
        method: "POST",
        body: queryArg.simulationQaSummaryReportRequestAssetClass,
      }),
    }),
    simulationQaReportReturnsRealCreate: build.query<
      SimulationQaReportReturnsRealCreateApiResponse,
      SimulationQaReportReturnsRealCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/qa_report/returns_real/`,
        method: "POST",
        body: queryArg.simulationQaSummaryReportRequestAssetClass,
      }),
    }),
    simulationQaReportSimAnalyzePathsCreate: build.query<
      SimulationQaReportSimAnalyzePathsCreateApiResponse,
      SimulationQaReportSimAnalyzePathsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/qa_report/sim_analyze_paths/`,
        method: "POST",
        body: queryArg.simulationAnalyzePathsInput,
      }),
    }),
    simulationQaReportSimDataFilterCreate: build.mutation<
      SimulationQaReportSimDataFilterCreateApiResponse,
      SimulationQaReportSimDataFilterCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/qa_report/sim_data_filter/`,
        method: "POST",
        body: queryArg.simulationDataFilterInput,
      }),
    }),
    simulationQaReportSimPathCalcualtionDataCreate: build.query<
      SimulationQaReportSimPathCalcualtionDataCreateApiResponse,
      SimulationQaReportSimPathCalcualtionDataCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/qa_report/sim_path_calcualtion_data/`,
        method: "POST",
        body: queryArg.simulationAnalyzePathCalcFieldsInput,
      }),
    }),
    simulationQaReportSummaryCreate: build.query<
      SimulationQaReportSummaryCreateApiResponse,
      SimulationQaReportSummaryCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/qa_report/summary/`,
        method: "POST",
        body: queryArg.simulationQaReportRequestBase,
      }),
    }),
    simulationQaReportYieldsCreate: build.query<
      SimulationQaReportYieldsCreateApiResponse,
      SimulationQaReportYieldsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/qa_report/yields/`,
        method: "POST",
        body: queryArg.simulationQaSummaryReportRequestAssetClass,
      }),
    }),
    simulationSettingInflationExpectedMeanList: build.query<
      SimulationSettingInflationExpectedMeanListApiResponse,
      SimulationSettingInflationExpectedMeanListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/setting_inflation_expected_mean/`,
      }),
    }),
    simulationSettingInflationExpectedMeanCreate: build.mutation<
      SimulationSettingInflationExpectedMeanCreateApiResponse,
      SimulationSettingInflationExpectedMeanCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/setting_inflation_expected_mean/`,
        method: "POST",
        body: queryArg.settingInflationExpectedMean,
      }),
    }),
    simulationSettingInflationExpectedMeanRetrieve: build.query<
      SimulationSettingInflationExpectedMeanRetrieveApiResponse,
      SimulationSettingInflationExpectedMeanRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/setting_inflation_expected_mean/${queryArg.id}/`,
      }),
    }),
    simulationSettingInflationExpectedMeanUpdate: build.mutation<
      SimulationSettingInflationExpectedMeanUpdateApiResponse,
      SimulationSettingInflationExpectedMeanUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/setting_inflation_expected_mean/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.settingInflationExpectedMean,
      }),
    }),
    simulationSettingInflationExpectedMeanPartialUpdate: build.mutation<
      SimulationSettingInflationExpectedMeanPartialUpdateApiResponse,
      SimulationSettingInflationExpectedMeanPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/setting_inflation_expected_mean/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSettingInflationExpectedMean,
      }),
    }),
    simulationSimJobYieldCurvesByPercentileList: build.query<
      SimulationSimJobYieldCurvesByPercentileListApiResponse,
      SimulationSimJobYieldCurvesByPercentileListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/sim_job_yield_curves_by_percentile/${queryArg.simJobId}/${queryArg.percentile}/`,
      }),
    }),
    simulationSimJobYieldCurvesByYearList: build.query<
      SimulationSimJobYieldCurvesByYearListApiResponse,
      SimulationSimJobYieldCurvesByYearListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/sim_job_yield_curves_by_year/${queryArg.simJobId}/${queryArg.year}/`,
      }),
    }),
    simulationSimYieldCurvesList: build.query<
      SimulationSimYieldCurvesListApiResponse,
      SimulationSimYieldCurvesListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/sim_yield_curves/${queryArg.simJobId}/`,
      }),
    }),
    simulationSimulartionGroupDetailsCreate: build.query<
      SimulationSimulartionGroupDetailsCreateApiResponse,
      SimulationSimulartionGroupDetailsCreateApiArg
    >({
      query: () => ({
        url: `/api/simulation/simulartion_group_details/`,
        method: "POST",
      }),
    }),
    simulationSimulationJobList: build.query<
      SimulationSimulationJobListApiResponse,
      SimulationSimulationJobListApiArg
    >({
      query: () => ({ url: `/api/simulation/simulation_job/` }),
    }),
    simulationSimulationJobRetrieve: build.query<
      SimulationSimulationJobRetrieveApiResponse,
      SimulationSimulationJobRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job/${queryArg.id}/`,
      }),
    }),
    simulationSimulationJobGetCondensedSummaryStatisticsList: build.query<
      SimulationSimulationJobGetCondensedSummaryStatisticsListApiResponse,
      SimulationSimulationJobGetCondensedSummaryStatisticsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job/${queryArg.id}/get_condensed_summary_statistics/`,
      }),
    }),
    simulationSimulationJobGetExtendedStatisticsRetrieve: build.query<
      SimulationSimulationJobGetExtendedStatisticsRetrieveApiResponse,
      SimulationSimulationJobGetExtendedStatisticsRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job/${queryArg.id}/get_extended_statistics/`,
      }),
    }),
    simulationSimulationJobGetFiftyPercentileList: build.query<
      SimulationSimulationJobGetFiftyPercentileListApiResponse,
      SimulationSimulationJobGetFiftyPercentileListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job/${queryArg.id}/get_fifty_percentile/`,
      }),
    }),
    simulationSimulationJobGetSummaryStatisticsList: build.query<
      SimulationSimulationJobGetSummaryStatisticsListApiResponse,
      SimulationSimulationJobGetSummaryStatisticsListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job/${queryArg.id}/get_summary_statistics/`,
      }),
    }),
    simulationSimulationJobAvailableSimulationGroupsList: build.query<
      SimulationSimulationJobAvailableSimulationGroupsListApiResponse,
      SimulationSimulationJobAvailableSimulationGroupsListApiArg
    >({
      query: () => ({
        url: `/api/simulation/simulation_job/available_simulation_groups/`,
      }),
    }),
    simulationSimulationJobLatestSimulationRetrieve: build.query<
      SimulationSimulationJobLatestSimulationRetrieveApiResponse,
      SimulationSimulationJobLatestSimulationRetrieveApiArg
    >({
      query: () => ({
        url: `/api/simulation/simulation_job/latest_simulation/`,
      }),
    }),
    simulationSimulationJobAdminList: build.query<
      SimulationSimulationJobAdminListApiResponse,
      SimulationSimulationJobAdminListApiArg
    >({
      query: () => ({ url: `/api/simulation/simulation_job_admin/` }),
    }),
    simulationSimulationJobAdminCreate: build.mutation<
      SimulationSimulationJobAdminCreateApiResponse,
      SimulationSimulationJobAdminCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job_admin/`,
        method: "POST",
        body: queryArg.simulationJobCreate,
      }),
    }),
    simulationSimulationJobAdminRetrieve: build.query<
      SimulationSimulationJobAdminRetrieveApiResponse,
      SimulationSimulationJobAdminRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job_admin/${queryArg.id}/`,
      }),
    }),
    simulationSimulationJobAdminUpdate: build.mutation<
      SimulationSimulationJobAdminUpdateApiResponse,
      SimulationSimulationJobAdminUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job_admin/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.simulationJob,
      }),
    }),
    simulationSimulationJobAdminPartialUpdate: build.mutation<
      SimulationSimulationJobAdminPartialUpdateApiResponse,
      SimulationSimulationJobAdminPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job_admin/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSimulationJob,
      }),
    }),
    simulationSimulationJobAdminPublishUpdate: build.mutation<
      SimulationSimulationJobAdminPublishUpdateApiResponse,
      SimulationSimulationJobAdminPublishUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_job_admin/${queryArg.id}/publish/`,
        method: "PUT",
        body: queryArg.simulationJobPublish,
      }),
    }),
    simulationSimulationModelTypeList: build.query<
      SimulationSimulationModelTypeListApiResponse,
      SimulationSimulationModelTypeListApiArg
    >({
      query: (queryArg) => ({ url: `/api/simulation/simulation_model_type/` }),
    }),
    simulationSimulationModelTypeRetrieve: build.query<
      SimulationSimulationModelTypeRetrieveApiResponse,
      SimulationSimulationModelTypeRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_model_type/${queryArg.id}/`,
      }),
    }),
    simulationSimulationModelVersionList: build.query<
      SimulationSimulationModelVersionListApiResponse,
      SimulationSimulationModelVersionListApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_model_version/`,
      }),
    }),
    simulationSimulationOutputDebugFieldsList: build.query<
      SimulationSimulationOutputDebugFieldsListApiResponse,
      SimulationSimulationOutputDebugFieldsListApiArg
    >({
      query: () => ({ url: `/api/simulation/simulation_output_debug_fields/` }),
    }),
    simulationSimulationSettingList: build.query<
      SimulationSimulationSettingListApiResponse,
      SimulationSimulationSettingListApiArg
    >({
      query: () => ({ url: `/api/simulation/simulation_setting/` }),
    }),
    simulationSimulationSettingRetrieve: build.query<
      SimulationSimulationSettingRetrieveApiResponse,
      SimulationSimulationSettingRetrieveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_setting/${queryArg.id}/`,
      }),
    }),
    simulationSimulationSettingUpdate: build.mutation<
      SimulationSimulationSettingUpdateApiResponse,
      SimulationSimulationSettingUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_setting/${queryArg.id}/`,
        method: "PUT",
        body: queryArg.simulationSetting,
      }),
    }),
    simulationSimulationSettingPartialUpdate: build.mutation<
      SimulationSimulationSettingPartialUpdateApiResponse,
      SimulationSimulationSettingPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/simulation/simulation_setting/${queryArg.id}/`,
        method: "PATCH",
        body: queryArg.patchedSimulationSetting,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type AccountsAccountHoldingsRetrieveApiResponse =
  /** status 200  */ AccountHoldingIntegrated;
export type AccountsAccountHoldingsRetrieveApiArg = {
  accountId: string;
};
export type AccountsAccountTypeListApiResponse =
  /** status 200  */ AccountType[];
export type AccountsAccountTypeListApiArg = void;
export type AccountsAccountTypeRetrieveApiResponse =
  /** status 200  */ AccountType;
export type AccountsAccountTypeRetrieveApiArg = {
  /** A UUID string identifying this account type. */
  id: string;
};
export type AccountsTaxTypeRulesDetailListApiResponse =
  /** status 200  */ TaxTypeRulesDetail[];
export type AccountsTaxTypeRulesDetailListApiArg = void;
export type AlEngineAlAssetTypeListApiResponse =
  /** status 200  */ AlResultAssetType[];
export type AlEngineAlAssetTypeListApiArg = void;
export type AlEngineAlAssetTypeRetrieveApiResponse =
  /** status 200  */ AlResultAssetType;
export type AlEngineAlAssetTypeRetrieveApiArg = {
  /** A UUID string identifying this al result asset type. */
  id: string;
};
export type AlEngineDebugInputJsonRetrieveApiResponse = unknown;
export type AlEngineDebugInputJsonRetrieveApiArg = {
  planResultRootId: string;
  planResultTypeId: string;
};
export type AlEngineDebugOutputJsonRetrieveApiResponse = unknown;
export type AlEngineDebugOutputJsonRetrieveApiArg = {
  planResultRootId: string;
};
export type AlEnginePlanResultRetrieveApiResponse =
  /** status 200  */ PlanResult;
export type AlEnginePlanResultRetrieveApiArg = {
  /** A UUID string identifying this plan result. */
  id: string;
};
export type AlEnginePlanResultActionPlanRetrieveApiResponse =
  /** status 200  */ ActionPlanPlanResult;
export type AlEnginePlanResultActionPlanRetrieveApiArg = {
  /** A UUID string identifying this plan result. */
  id: string;
};
export type AlEnginePlanResultPublishPlanResultUpdateApiResponse =
  /** status 200  */ PlanResult;
export type AlEnginePlanResultPublishPlanResultUpdateApiArg = {
  /** A UUID string identifying this plan result. */
  id: string;
  planResult: PlanResult;
};
export type AlEnginePlanResultSimulationJobDisclosureRetrieveApiResponse =
  /** status 200  */ SimulationJobDisclosure;
export type AlEnginePlanResultSimulationJobDisclosureRetrieveApiArg = {
  /** A UUID string identifying this plan result. */
  id: string;
};
export type AlEnginePlanResultStressTestContainerRetrieveApiResponse =
  /** status 200  */ PlanResultStress;
export type AlEnginePlanResultStressTestContainerRetrieveApiArg = {
  /** A UUID string identifying this plan result. */
  id: string;
};
export type AlEnginePlanResultTypeListApiResponse =
  /** status 200  */ PlanResultType[];
export type AlEnginePlanResultTypeListApiArg = void;
export type AlEnginePlanResultTypeRetrieveApiResponse =
  /** status 200  */ PlanResultType;
export type AlEnginePlanResultTypeRetrieveApiArg = {
  /** A UUID string identifying this plan result type. */
  id: string;
};
export type AlEngineRenderRetrieveApiResponse =
  /** status 200  */ PlanResultRoot;
export type AlEngineRenderRetrieveApiArg = {
  /** A UUID string identifying this plan result root. */
  id: string;
};
export type AlEngineRenderPlanResultJobStatusListApiResponse =
  /** status 200  */ PlanResultJob[];
export type AlEngineRenderPlanResultJobStatusListApiArg = {
  /** A UUID string identifying this plan result root. */
  id: string;
};
export type AlEngineRenderProgressMonitoringResultsRetrieveApiResponse =
  /** status 200  */ PlanResultProgress;
export type AlEngineRenderProgressMonitoringResultsRetrieveApiArg = {
  /** A UUID string identifying this plan result root. */
  id: string;
};
export type AlEngineStressTestContainerRetrieveApiResponse =
  /** status 200  */ StressTestContainer;
export type AlEngineStressTestContainerRetrieveApiArg = {
  /** A UUID string identifying this stress test container. */
  id: string;
};
export type AlEngineStressTestScenarioTypeListApiResponse =
  /** status 200  */ StressScenarioType[];
export type AlEngineStressTestScenarioTypeListApiArg = void;
export type AlEngineStressTestScenarioTypeRetrieveApiResponse =
  /** status 200  */ StressScenarioType;
export type AlEngineStressTestScenarioTypeRetrieveApiArg = {
  /** A UUID string identifying this stress scenario type. */
  id: string;
};
export type AssetClassesAssetClassGicsSubIndustryWeightsListApiResponse =
  /** status 200  */ AssetClassGicsSubIndustryWeights[];
export type AssetClassesAssetClassGicsSubIndustryWeightsListApiArg = void;
export type AssetClassesAssetClassLevel4ListRetrieveApiResponse =
  /** status 200  */ AssetClassList;
export type AssetClassesAssetClassLevel4ListRetrieveApiArg = void;
export type AssetClassesAssetClassStatisticViewRetrieveApiResponse = unknown;
export type AssetClassesAssetClassStatisticViewRetrieveApiArg = void;
export type AssetClassesBoundaryListApiResponse = /** status 200  */ Boundary[];
export type AssetClassesBoundaryListApiArg = {
  id: string;
};
export type AssetClassesBoundaryCreateApiResponse = /** status 201  */ Boundary;
export type AssetClassesBoundaryCreateApiArg = {
  id: string;
  boundary: Boundary;
};
export type AssetClassesBoundaryRetrieveApiResponse =
  /** status 200  */ Boundary;
export type AssetClassesBoundaryRetrieveApiArg = {
  id: string;
};
export type AssetClassesBoundaryUpdateApiResponse = /** status 200  */ Boundary;
export type AssetClassesBoundaryUpdateApiArg = {
  id: string;
  boundary: Boundary;
};
export type AssetClassesBoundaryPartialUpdateApiResponse =
  /** status 200  */ Boundary;
export type AssetClassesBoundaryPartialUpdateApiArg = {
  id: string;
  patchedBoundary: PatchedBoundary;
};
export type AssetClassesBoundaryDestroyApiResponse = unknown;
export type AssetClassesBoundaryDestroyApiArg = {
  id: string;
};
export type AssetClassesBoundaryGroupListApiResponse =
  /** status 200  */ BoundaryGroup[];
export type AssetClassesBoundaryGroupListApiArg = {
  id: string;
};
export type AssetClassesBoundaryGroupCreateApiResponse =
  /** status 201  */ BoundaryGroup;
export type AssetClassesBoundaryGroupCreateApiArg = {
  id: string;
  boundaryGroup: BoundaryGroup;
};
export type AssetClassesBoundaryGroupRetrieveApiResponse =
  /** status 200  */ BoundaryGroup;
export type AssetClassesBoundaryGroupRetrieveApiArg = {
  id: string;
};
export type AssetClassesBoundaryGroupUpdateApiResponse =
  /** status 200  */ BoundaryGroup;
export type AssetClassesBoundaryGroupUpdateApiArg = {
  id: string;
  boundaryGroup: BoundaryGroup;
};
export type AssetClassesBoundaryGroupPartialUpdateApiResponse =
  /** status 200  */ BoundaryGroup;
export type AssetClassesBoundaryGroupPartialUpdateApiArg = {
  id: string;
  patchedBoundaryGroup: PatchedBoundaryGroup;
};
export type AssetClassesBoundaryGroupDestroyApiResponse = unknown;
export type AssetClassesBoundaryGroupDestroyApiArg = {
  id: string;
};
export type AssetClassesFirmAssetClassImplementationListApiResponse =
  /** status 200  */ FirmAssetClassImplementation[];
export type AssetClassesFirmAssetClassImplementationListApiArg = void;
export type AssetClassesFirmAssetClassImplementationRetrieveApiResponse =
  /** status 200  */ FirmAssetClassImplementation;
export type AssetClassesFirmAssetClassImplementationRetrieveApiArg = {
  /** A UUID string identifying this firm asset class implementation. */
  id: string;
};
export type AssetClassesFirmAssetClassImplementationUpdateApiResponse =
  /** status 200  */ FirmAssetClassImplementation;
export type AssetClassesFirmAssetClassImplementationUpdateApiArg = {
  /** A UUID string identifying this firm asset class implementation. */
  id: string;
  firmAssetClassImplementation: FirmAssetClassImplementation;
};
export type AssetClassesFirmAssetClassImplementationPartialUpdateApiResponse =
  /** status 200  */ FirmAssetClassImplementation;
export type AssetClassesFirmAssetClassImplementationPartialUpdateApiArg = {
  /** A UUID string identifying this firm asset class implementation. */
  id: string;
  patchedFirmAssetClassImplementation: PatchedFirmAssetClassImplementation;
};
export type AssetClassesFirmAssetClassesListApiResponse =
  /** status 200  */ FirmAssetClassDetail[];
export type AssetClassesFirmAssetClassesListApiArg = void;
export type AssetClassesFirmAssetClassesRetrieveApiResponse =
  /** status 200  */ FirmAssetClassDetail;
export type AssetClassesFirmAssetClassesRetrieveApiArg = {
  /** A UUID string identifying this firm asset class. */
  id: string;
};
export type AssetClassesFirmAssetClassesUpdateApiResponse =
  /** status 200  */ FirmAssetClassDetail;
export type AssetClassesFirmAssetClassesUpdateApiArg = {
  /** A UUID string identifying this firm asset class. */
  id: string;
  firmAssetClassDetail: FirmAssetClassDetail;
};
export type AssetClassesFirmAssetClassesPartialUpdateApiResponse =
  /** status 200  */ FirmAssetClassDetail;
export type AssetClassesFirmAssetClassesPartialUpdateApiArg = {
  /** A UUID string identifying this firm asset class. */
  id: string;
  patchedFirmAssetClassDetail: PatchedFirmAssetClassDetail;
};
export type AssetClassesSimulationBehaviorCashListApiResponse =
  /** status 200  */ SimulationBehaviorCash[];
export type AssetClassesSimulationBehaviorCashListApiArg = {
  id: string;
};
export type AssetClassesSimulationBehaviorCashRetrieveApiResponse =
  /** status 200  */ SimulationBehaviorCash;
export type AssetClassesSimulationBehaviorCashRetrieveApiArg = {
  id: string;
};
export type AssetClassesSimulationBehaviorCashUpdateApiResponse =
  /** status 200  */ SimulationBehaviorCash;
export type AssetClassesSimulationBehaviorCashUpdateApiArg = {
  id: string;
  simulationBehaviorCash: SimulationBehaviorCash;
};
export type AssetClassesSimulationBehaviorCashPartialUpdateApiResponse =
  /** status 200  */ SimulationBehaviorCash;
export type AssetClassesSimulationBehaviorCashPartialUpdateApiArg = {
  id: string;
  patchedSimulationBehaviorCash: PatchedSimulationBehaviorCash;
};
export type AssetClassesSimulationBehaviorEquityListApiResponse =
  /** status 200  */ SimulationBehaviorEquity[];
export type AssetClassesSimulationBehaviorEquityListApiArg = {
  id: string;
};
export type AssetClassesSimulationBehaviorEquityRetrieveApiResponse =
  /** status 200  */ SimulationBehaviorEquity;
export type AssetClassesSimulationBehaviorEquityRetrieveApiArg = {
  id: string;
};
export type AssetClassesSimulationBehaviorEquityUpdateApiResponse =
  /** status 200  */ SimulationBehaviorEquity;
export type AssetClassesSimulationBehaviorEquityUpdateApiArg = {
  id: string;
  simulationBehaviorEquity: SimulationBehaviorEquity;
};
export type AssetClassesSimulationBehaviorEquityPartialUpdateApiResponse =
  /** status 200  */ SimulationBehaviorEquity;
export type AssetClassesSimulationBehaviorEquityPartialUpdateApiArg = {
  id: string;
  patchedSimulationBehaviorEquity: PatchedSimulationBehaviorEquity;
};
export type AssetClassesSimulationBehaviorFixedIncomeSpreadListApiResponse =
  /** status 200  */ SimulationBehaviorFixedIncomeSpread[];
export type AssetClassesSimulationBehaviorFixedIncomeSpreadListApiArg = {
  id: string;
};
export type AssetClassesSimulationBehaviorFixedIncomeSpreadRetrieveApiResponse =
  /** status 200  */ SimulationBehaviorFixedIncomeSpread;
export type AssetClassesSimulationBehaviorFixedIncomeSpreadRetrieveApiArg = {
  id: string;
};
export type AssetClassesSimulationBehaviorFixedIncomeSpreadUpdateApiResponse =
  /** status 200  */ SimulationBehaviorFixedIncomeSpread;
export type AssetClassesSimulationBehaviorFixedIncomeSpreadUpdateApiArg = {
  id: string;
  simulationBehaviorFixedIncomeSpread: SimulationBehaviorFixedIncomeSpread;
};
export type AssetClassesSimulationBehaviorFixedIncomeSpreadPartialUpdateApiResponse =
  /** status 200  */ SimulationBehaviorFixedIncomeSpread;
export type AssetClassesSimulationBehaviorFixedIncomeSpreadPartialUpdateApiArg =
  {
    id: string;
    patchedSimulationBehaviorFixedIncomeSpread: PatchedSimulationBehaviorFixedIncomeSpread;
  };
export type AssetClassesSimulationBehaviorInflationListApiResponse =
  /** status 200  */ SimulationBehaviorInflation[];
export type AssetClassesSimulationBehaviorInflationListApiArg = {
  id: string;
};
export type AssetClassesSimulationBehaviorInflationRetrieveApiResponse =
  /** status 200  */ SimulationBehaviorInflation;
export type AssetClassesSimulationBehaviorInflationRetrieveApiArg = {
  id: string;
};
export type AssetClassesSimulationBehaviorInflationUpdateApiResponse =
  /** status 200  */ SimulationBehaviorInflation;
export type AssetClassesSimulationBehaviorInflationUpdateApiArg = {
  id: string;
  simulationBehaviorInflation: SimulationBehaviorInflation;
};
export type AssetClassesSimulationBehaviorInflationPartialUpdateApiResponse =
  /** status 200  */ SimulationBehaviorInflation;
export type AssetClassesSimulationBehaviorInflationPartialUpdateApiArg = {
  id: string;
  patchedSimulationBehaviorInflation: PatchedSimulationBehaviorInflation;
};
export type AutomationReceiveLambdaEventCreateApiResponse =
  /** status 200  */ RecieveEvent;
export type AutomationReceiveLambdaEventCreateApiArg = {
  recieveEvent: RecieveEvent;
};
export type CmaAssetClassRegressionRetrieveApiResponse =
  /** status 200  */ AssetClassRegressionResponse;
export type CmaAssetClassRegressionRetrieveApiArg = {
  assetClassId: string;
};
export type CmaCmaDataListApiResponse = /** status 200  */ GenericCmaData[];
export type CmaCmaDataListApiArg = {
  /** A search term. */
  search?: string;
};
export type CmaCmaDataRetrieveApiResponse = /** status 200  */ GenericCmaData;
export type CmaCmaDataRetrieveApiArg = {
  /** A UUID string identifying this generic cma data. */
  id: string;
};
export type CmaCmaGroupListApiResponse = /** status 200  */ GenericCmaGroup[];
export type CmaCmaGroupListApiArg = {
  /** A search term. */
  search?: string;
};
export type CmaCmaGroupRetrieveApiResponse = /** status 200  */ GenericCmaGroup;
export type CmaCmaGroupRetrieveApiArg = {
  /** A UUID string identifying this generic cma group. */
  id: string;
};
export type CmaDataSourceListApiResponse = /** status 200  */ DataSource[];
export type CmaDataSourceListApiArg = {
  /** A search term. */
  search?: string;
};
export type CmaDataSourceRetrieveApiResponse = /** status 200  */ DataSource;
export type CmaDataSourceRetrieveApiArg = {
  /** A UUID string identifying this data source. */
  id: string;
};
export type CmaGenerateAssetClassSimBehaviorCreateApiResponse =
  /** status 200  */ GenModelsAssetClassRunStatus;
export type CmaGenerateAssetClassSimBehaviorCreateApiArg = {
  genModelsAssetClassList: GenModelsAssetClassList;
};
export type CmaGetCurrentCpiDefinitionsListApiResponse =
  /** status 200  */ YieldCurveRate[];
export type CmaGetCurrentCpiDefinitionsListApiArg = void;
export type CmaReturnScenariosListApiResponse =
  /** status 200  */ ReturnScenarios[];
export type CmaReturnScenariosListApiArg = void;
export type CmaReturnScenariosRetrieveApiResponse =
  /** status 200  */ ReturnScenarios;
export type CmaReturnScenariosRetrieveApiArg = {
  /** A UUID string identifying this return scenarios. */
  id: string;
};
export type CmaRiskStatTypeListApiResponse = /** status 200  */ RiskStatType[];
export type CmaRiskStatTypeListApiArg = void;
export type CmaRiskStatTypeRetrieveApiResponse =
  /** status 200  */ RiskStatType;
export type CmaRiskStatTypeRetrieveApiArg = {
  /** A UUID string identifying this risk stat type. */
  id: string;
};
export type CmaSandpCmaGroupListApiResponse =
  /** status 200  */ SAndPCmaGroup[];
export type CmaSandpCmaGroupListApiArg = {
  /** A search term. */
  search?: string;
};
export type CmaSandpCmaGroupRetrieveApiResponse =
  /** status 200  */ SAndPCmaGroup;
export type CmaSandpCmaGroupRetrieveApiArg = {
  /** A UUID string identifying this s and p cma group. */
  id: string;
};
export type CmaSandpDataEquityListApiResponse =
  /** status 200  */ SAndPDataEquity[];
export type CmaSandpDataEquityListApiArg = {
  /** A search term. */
  search?: string;
};
export type CmaSandpDataEquityRetrieveApiResponse =
  /** status 200  */ SAndPDataEquity;
export type CmaSandpDataEquityRetrieveApiArg = {
  /** A UUID string identifying this s and p data equity. */
  id: string;
};
export type CmaSandpDataFixedIncomeListApiResponse =
  /** status 200  */ SAndPDataFixedIncome[];
export type CmaSandpDataFixedIncomeListApiArg = {
  /** A search term. */
  search?: string;
};
export type CmaSandpDataFixedIncomeRetrieveApiResponse =
  /** status 200  */ SAndPDataFixedIncome;
export type CmaSandpDataFixedIncomeRetrieveApiArg = {
  /** A UUID string identifying this s and p data fixed income. */
  id: string;
};
export type CmaYieldCurvePostionsListApiResponse =
  /** status 200  */ YieldCurvePosition[];
export type CmaYieldCurvePostionsListApiArg = void;
export type CommonAuthAcceptInvitationCreateApiResponse =
  /** status 201  */ CustomTokenObtainPair;
export type CommonAuthAcceptInvitationCreateApiArg = {
  acceptInvitation: AcceptInvitation;
};
export type CommonAuthLoginCreateApiResponse =
  /** status 200  */ CustomTokenObtainPair;
export type CommonAuthLoginCreateApiArg = {
  customTokenObtainPair: CustomTokenObtainPair;
};
export type CommonAuthRefreshCreateApiResponse =
  /** status 200  */ CustomTokenRefresh;
export type CommonAuthRefreshCreateApiArg = {
  customTokenRefresh: CustomTokenRefresh;
};
export type CommonAuthRequestResetPasswordCreateApiResponse =
  /** status 201  */ RequestResetPassword;
export type CommonAuthRequestResetPasswordCreateApiArg = {
  requestResetPassword: RequestResetPassword;
};
export type CommonAuthResetPasswordCreateApiResponse =
  /** status 201  */ CustomTokenObtainPair;
export type CommonAuthResetPasswordCreateApiArg = {
  resetPassword: ResetPassword;
};
export type CommonAuthSelfRetrieveApiResponse = /** status 200  */ User;
export type CommonAuthSelfRetrieveApiArg = void;
export type CommonAuthSelfUpdateApiResponse = /** status 200  */ User;
export type CommonAuthSelfUpdateApiArg = {
  user: User;
};
export type CommonAuthSelfPartialUpdateApiResponse = /** status 200  */ User;
export type CommonAuthSelfPartialUpdateApiArg = {
  patchedUser: PatchedUser;
};
export type CommonAuthSelfFirmRetrieveApiResponse = /** status 200  */ Firm;
export type CommonAuthSelfFirmRetrieveApiArg = void;
export type CommonAuthSelfFirmUpdateApiResponse = /** status 200  */ Firm;
export type CommonAuthSelfFirmUpdateApiArg = {
  firm: Firm;
};
export type CommonAuthSelfFirmPartialUpdateApiResponse =
  /** status 200  */ Firm;
export type CommonAuthSelfFirmPartialUpdateApiArg = {
  patchedFirm: PatchedFirm;
};
export type CommonAuthStartFreeTrialCreateApiResponse =
  /** status 201  */ CustomTokenObtainPair;
export type CommonAuthStartFreeTrialCreateApiArg = {
  freeTrialStart: FreeTrialStart;
};
export type CommonAuthTwoFactorLoginUpdateApiResponse =
  /** status 200  */ CustomTokenObtainPair;
export type CommonAuthTwoFactorLoginUpdateApiArg = {
  userTwoFactorAuth: UserTwoFactorAuth;
};
export type CommonAuthTwoFactorLoginPartialUpdateApiResponse =
  /** status 200  */ CustomTokenObtainPair;
export type CommonAuthTwoFactorLoginPartialUpdateApiArg = {
  patchedUserTwoFactorAuth: PatchedUserTwoFactorAuth;
};
export type CommonAuthTwoFactorSettingsCreateApiResponse =
  /** status 201  */ UserTwoFactorSettings;
export type CommonAuthTwoFactorSettingsCreateApiArg = {
  userTwoFactorSettings: UserTwoFactorSettings;
};
export type CommonAuthTwoFactorSettingsUpdateApiResponse =
  /** status 200  */ UserTwoFactorSettings;
export type CommonAuthTwoFactorSettingsUpdateApiArg = {
  userTwoFactorSettings: UserTwoFactorSettings;
};
export type CommonAuthTwoFactorSettingsPartialUpdateApiResponse =
  /** status 200  */ UserTwoFactorSettings;
export type CommonAuthTwoFactorSettingsPartialUpdateApiArg = {
  patchedUserTwoFactorSettings: PatchedUserTwoFactorSettings;
};
export type CommonAuthVerifyEmailUpdateApiResponse =
  /** status 200  */ UserEmailConfirmation;
export type CommonAuthVerifyEmailUpdateApiArg = {
  userEmailConfirmation: UserEmailConfirmation;
};
export type CommonAuthVerifyEmailPartialUpdateApiResponse =
  /** status 200  */ UserEmailConfirmation;
export type CommonAuthVerifyEmailPartialUpdateApiArg = {
  patchedUserEmailConfirmation: PatchedUserEmailConfirmation;
};
export type CommonAuthVerifyEmailResendUpdateApiResponse = unknown;
export type CommonAuthVerifyEmailResendUpdateApiArg = void;
export type CommonAuthVerifyEmailResendPartialUpdateApiResponse = unknown;
export type CommonAuthVerifyEmailResendPartialUpdateApiArg = void;
export type CommonBillingActivateBillingCreateApiResponse =
  /** status 201  */ ActivateBilling;
export type CommonBillingActivateBillingCreateApiArg = void;
export type CommonBillingActivateBillingV2RetrieveApiResponse =
  /** status 200  */ License;
export type CommonBillingActivateBillingV2RetrieveApiArg = void;
export type CommonBillingActivateBillingV2CreateApiResponse =
  /** status 200  */ ActivateBillingV2;
export type CommonBillingActivateBillingV2CreateApiArg = {
  activateBillingV2: ActivateBillingV2;
};
export type CommonBillingBillingCheckStatusCreateApiResponse =
  /** status 201  */ BillingCheckStatus;
export type CommonBillingBillingCheckStatusCreateApiArg = void;
export type CommonBillingBillingGetInfoRetrieveApiResponse =
  /** status 200  */ StripeSubscription;
export type CommonBillingBillingGetInfoRetrieveApiArg = void;
export type CommonBillingStripePriceListApiResponse =
  /** status 200  */ StripePrice[];
export type CommonBillingStripePriceListApiArg = void;
export type CommonBillingStripePriceRetrieveApiResponse =
  /** status 200  */ StripePrice;
export type CommonBillingStripePriceRetrieveApiArg = {
  id: string;
};
export type CommonCmpasModuleListApiResponse = /** status 200  */ CmpasModule[];
export type CommonCmpasModuleListApiArg = void;
export type CommonCmpasModuleCreateApiResponse = /** status 201  */ CmpasModule;
export type CommonCmpasModuleCreateApiArg = {
  cmpasModule: CmpasModule;
};
export type CommonCmpasModuleRetrieveApiResponse =
  /** status 200  */ CmpasModule;
export type CommonCmpasModuleRetrieveApiArg = {
  /** A UUID string identifying this cmpas module. */
  id: string;
};
export type CommonCmpasModuleUpdateApiResponse = /** status 200  */ CmpasModule;
export type CommonCmpasModuleUpdateApiArg = {
  /** A UUID string identifying this cmpas module. */
  id: string;
  cmpasModule: CmpasModule;
};
export type CommonCmpasModulePartialUpdateApiResponse =
  /** status 200  */ CmpasModule;
export type CommonCmpasModulePartialUpdateApiArg = {
  /** A UUID string identifying this cmpas module. */
  id: string;
  patchedCmpasModule: PatchedCmpasModule;
};
export type CommonCmpasModuleHierarchyListApiResponse =
  /** status 200  */ CmpasModuleHierarchy[];
export type CommonCmpasModuleHierarchyListApiArg = void;
export type CommonCmpasModuleHierarchyRetrieveApiResponse =
  /** status 200  */ CmpasModuleHierarchy;
export type CommonCmpasModuleHierarchyRetrieveApiArg = {
  /** A UUID string identifying this cmpas module. */
  id: string;
};
export type CommonCmpasModuleSectionListApiResponse =
  /** status 200  */ CmpasModuleSectionDetail[];
export type CommonCmpasModuleSectionListApiArg = void;
export type CommonCmpasModuleSectionCreateApiResponse =
  /** status 201  */ CmpasModuleSectionDetail;
export type CommonCmpasModuleSectionCreateApiArg = {
  cmpasModuleSectionDetail: CmpasModuleSectionDetail;
};
export type CommonCmpasModuleSectionRetrieveApiResponse =
  /** status 200  */ CmpasModuleSectionDetail;
export type CommonCmpasModuleSectionRetrieveApiArg = {
  /** A UUID string identifying this cmpas module section. */
  id: string;
};
export type CommonCmpasModuleSectionUpdateApiResponse =
  /** status 200  */ CmpasModuleSectionDetail;
export type CommonCmpasModuleSectionUpdateApiArg = {
  /** A UUID string identifying this cmpas module section. */
  id: string;
  cmpasModuleSectionDetail: CmpasModuleSectionDetail;
};
export type CommonCmpasModuleSectionPartialUpdateApiResponse =
  /** status 200  */ CmpasModuleSectionDetail;
export type CommonCmpasModuleSectionPartialUpdateApiArg = {
  /** A UUID string identifying this cmpas module section. */
  id: string;
  patchedCmpasModuleSectionDetail: PatchedCmpasModuleSectionDetail;
};
export type CommonFirmMembersListApiResponse = /** status 200  */ FirmMember[];
export type CommonFirmMembersListApiArg = void;
export type CommonFirmMembersRetrieveApiResponse =
  /** status 200  */ FirmMember;
export type CommonFirmMembersRetrieveApiArg = {
  /** A UUID string identifying this user. */
  id: string;
};
export type CommonFirmMembersUpdateApiResponse = /** status 200  */ FirmMember;
export type CommonFirmMembersUpdateApiArg = {
  /** A UUID string identifying this user. */
  id: string;
  firmMember: FirmMember;
};
export type CommonFirmMembersPartialUpdateApiResponse =
  /** status 200  */ FirmMember;
export type CommonFirmMembersPartialUpdateApiArg = {
  /** A UUID string identifying this user. */
  id: string;
  patchedFirmMember: PatchedFirmMember;
};
export type CommonFirmMembersDestroyApiResponse = unknown;
export type CommonFirmMembersDestroyApiArg = {
  /** A UUID string identifying this user. */
  id: string;
};
export type CommonHealthRetrieveApiResponse = /** status 200  */ string;
export type CommonHealthRetrieveApiArg = void;
export type CommonInvitationsListApiResponse = /** status 200  */ Invitation[];
export type CommonInvitationsListApiArg = void;
export type CommonInvitationsCreateApiResponse = /** status 201  */ Invitation;
export type CommonInvitationsCreateApiArg = {
  invitation: Invitation;
};
export type CommonInvitationsRetrieveApiResponse =
  /** status 200  */ Invitation;
export type CommonInvitationsRetrieveApiArg = {
  /** A UUID string identifying this invitation. */
  id: string;
};
export type CommonInvitationsUpdateApiResponse = /** status 200  */ Invitation;
export type CommonInvitationsUpdateApiArg = {
  /** A UUID string identifying this invitation. */
  id: string;
  invitation: Invitation;
};
export type CommonInvitationsPartialUpdateApiResponse =
  /** status 200  */ Invitation;
export type CommonInvitationsPartialUpdateApiArg = {
  /** A UUID string identifying this invitation. */
  id: string;
  patchedInvitation: PatchedInvitation;
};
export type CommonInvitationsDestroyApiResponse = unknown;
export type CommonInvitationsDestroyApiArg = {
  /** A UUID string identifying this invitation. */
  id: string;
};
export type CommonPageSectionListApiResponse = /** status 200  */ PageSection[];
export type CommonPageSectionListApiArg = void;
export type CommonPageSectionCreateApiResponse = /** status 201  */ PageSection;
export type CommonPageSectionCreateApiArg = {
  pageSection: PageSection;
};
export type CommonPageSectionRetrieveApiResponse =
  /** status 200  */ PageSection;
export type CommonPageSectionRetrieveApiArg = {
  /** A UUID string identifying this page section. */
  id: string;
};
export type CommonPageSectionUpdateApiResponse = /** status 200  */ PageSection;
export type CommonPageSectionUpdateApiArg = {
  /** A UUID string identifying this page section. */
  id: string;
  pageSection: PageSection;
};
export type CommonPageSectionPartialUpdateApiResponse =
  /** status 200  */ PageSection;
export type CommonPageSectionPartialUpdateApiArg = {
  /** A UUID string identifying this page section. */
  id: string;
  patchedPageSection: PatchedPageSection;
};
export type CommonPageSelectionBlockListApiResponse =
  /** status 200  */ PageSectionBlock[];
export type CommonPageSelectionBlockListApiArg = void;
export type CommonPageSelectionBlockCreateApiResponse =
  /** status 201  */ PageSectionBlock;
export type CommonPageSelectionBlockCreateApiArg = {
  pageSectionBlock: PageSectionBlock;
};
export type CommonPageSelectionBlockRetrieveApiResponse =
  /** status 200  */ PageSectionBlock;
export type CommonPageSelectionBlockRetrieveApiArg = {
  /** A UUID string identifying this page section block. */
  id: string;
};
export type CommonPageSelectionBlockUpdateApiResponse =
  /** status 200  */ PageSectionBlock;
export type CommonPageSelectionBlockUpdateApiArg = {
  /** A UUID string identifying this page section block. */
  id: string;
  pageSectionBlock: PageSectionBlock;
};
export type CommonPageSelectionBlockPartialUpdateApiResponse =
  /** status 200  */ PageSectionBlock;
export type CommonPageSelectionBlockPartialUpdateApiArg = {
  /** A UUID string identifying this page section block. */
  id: string;
  patchedPageSectionBlock: PatchedPageSectionBlock;
};
export type CommonPageSelectionBlockTypeListApiResponse =
  /** status 200  */ PageSectionBlockType[];
export type CommonPageSelectionBlockTypeListApiArg = void;
export type CommonPageSelectionBlockTypeCreateApiResponse =
  /** status 201  */ PageSectionBlockType;
export type CommonPageSelectionBlockTypeCreateApiArg = {
  pageSectionBlockType: PageSectionBlockType;
};
export type CommonPageSelectionBlockTypeRetrieveApiResponse =
  /** status 200  */ PageSectionBlockType;
export type CommonPageSelectionBlockTypeRetrieveApiArg = {
  /** A UUID string identifying this page section block type. */
  id: string;
};
export type CommonPageSelectionBlockTypeUpdateApiResponse =
  /** status 200  */ PageSectionBlockType;
export type CommonPageSelectionBlockTypeUpdateApiArg = {
  /** A UUID string identifying this page section block type. */
  id: string;
  pageSectionBlockType: PageSectionBlockType;
};
export type CommonPageSelectionBlockTypePartialUpdateApiResponse =
  /** status 200  */ PageSectionBlockType;
export type CommonPageSelectionBlockTypePartialUpdateApiArg = {
  /** A UUID string identifying this page section block type. */
  id: string;
  patchedPageSectionBlockType: PatchedPageSectionBlockType;
};
export type CommonRoleAllowedSectionsListApiResponse =
  /** status 200  */ RoleAllowedCmpasSections[];
export type CommonRoleAllowedSectionsListApiArg = void;
export type CommonRoleHierarchyListApiResponse =
  /** status 200  */ RoleHierarchy[];
export type CommonRoleHierarchyListApiArg = void;
export type CommonRoleHierarchyRetrieveApiResponse =
  /** status 200  */ RoleHierarchy;
export type CommonRoleHierarchyRetrieveApiArg = {
  /** A UUID string identifying this role. */
  id: string;
};
export type CommonRoleTypesListApiResponse = /** status 200  */ RoleType[];
export type CommonRoleTypesListApiArg = void;
export type CommonRoleTypesCreateApiResponse = /** status 201  */ RoleType;
export type CommonRoleTypesCreateApiArg = {
  roleType: RoleType;
};
export type CommonRoleTypesRetrieveApiResponse = /** status 200  */ RoleType;
export type CommonRoleTypesRetrieveApiArg = {
  /** A UUID string identifying this Role Type. */
  id: string;
};
export type CommonRoleTypesUpdateApiResponse = /** status 200  */ RoleType;
export type CommonRoleTypesUpdateApiArg = {
  /** A UUID string identifying this Role Type. */
  id: string;
  roleType: RoleType;
};
export type CommonRoleTypesPartialUpdateApiResponse =
  /** status 200  */ RoleType;
export type CommonRoleTypesPartialUpdateApiArg = {
  /** A UUID string identifying this Role Type. */
  id: string;
  patchedRoleType: PatchedRoleType;
};
export type CommonRolesListApiResponse = /** status 200  */ Role[];
export type CommonRolesListApiArg = void;
export type CommonRolesCreateApiResponse = /** status 201  */ Role;
export type CommonRolesCreateApiArg = {
  role: Role;
};
export type CommonRolesRetrieveApiResponse = /** status 200  */ Role;
export type CommonRolesRetrieveApiArg = {
  /** A UUID string identifying this role. */
  id: string;
};
export type CommonRolesUpdateApiResponse = /** status 200  */ Role;
export type CommonRolesUpdateApiArg = {
  /** A UUID string identifying this role. */
  id: string;
  role: Role;
};
export type CommonRolesPartialUpdateApiResponse = /** status 200  */ Role;
export type CommonRolesPartialUpdateApiArg = {
  /** A UUID string identifying this role. */
  id: string;
  patchedRole: PatchedRole;
};
export type ContentDiclosureSubCategoriesListApiResponse =
  /** status 200  */ DisclosureSubCategory[];
export type ContentDiclosureSubCategoriesListApiArg = void;
export type ContentDiclosureSubCategoriesRetrieveApiResponse =
  /** status 200  */ DisclosureSubCategory;
export type ContentDiclosureSubCategoriesRetrieveApiArg = {
  /** A UUID string identifying this Disclosure Sub Category. */
  id: string;
};
export type ContentFirmDisclosureOverrideListApiResponse =
  /** status 200  */ FirmDisclosureOverride[];
export type ContentFirmDisclosureOverrideListApiArg = void;
export type ContentFirmDisclosureOverrideRetrieveApiResponse =
  /** status 200  */ FirmDisclosureOverride;
export type ContentFirmDisclosureOverrideRetrieveApiArg = {
  /** A UUID string identifying this Firm Disclosure Override. */
  id: string;
};
export type ContentFirmDisclosureSectionBlockOverrideListApiResponse =
  /** status 200  */ FirmDisclosureSectionBlockOverride[];
export type ContentFirmDisclosureSectionBlockOverrideListApiArg = void;
export type ContentFirmDisclosureSectionBlockOverrideRetrieveApiResponse =
  /** status 200  */ FirmDisclosureSectionBlockOverride;
export type ContentFirmDisclosureSectionBlockOverrideRetrieveApiArg = {
  /** A UUID string identifying this Firm Disclosure Section Block. */
  id: string;
};
export type ContentFirmTargetBaseContentOverrideListApiResponse =
  /** status 200  */ FirmTargetBaseContentOverride[];
export type ContentFirmTargetBaseContentOverrideListApiArg = void;
export type ContentFirmTargetBaseContentOverrideRetrieveApiResponse =
  /** status 200  */ FirmTargetBaseContentOverride;
export type ContentFirmTargetBaseContentOverrideRetrieveApiArg = {
  /** A UUID string identifying this Firm Target Base Content Override. */
  id: string;
};
export type ContentGetTargetDocumentRetrieveApiResponse =
  /** status 200  */ TargetBase;
export type ContentGetTargetDocumentRetrieveApiArg = {
  /** The target_base code to search for. */
  documentCode: string;
};
export type ContentPageContentListApiResponse =
  /** status 200  */ SectionContentLookup[];
export type ContentPageContentListApiArg = void;
export type ContentTargetsListApiResponse = /** status 200  */ TargetBase[];
export type ContentTargetsListApiArg = void;
export type ContentTargetsRetrieveApiResponse =
  /** status 200  */ TargetBaseNested;
export type ContentTargetsRetrieveApiArg = {
  /** A UUID string identifying this Target. */
  id: string;
};
export type DataProvidersGicsIndustrySubGroupListApiResponse =
  /** status 200  */ GicsIndustrySubGroup[];
export type DataProvidersGicsIndustrySubGroupListApiArg = void;
export type DataProvidersPlanAccountsLinkRayJayExternalCreateApiResponse =
  unknown;
export type DataProvidersPlanAccountsLinkRayJayExternalCreateApiArg = {
  planId: string;
  linkExternalAccounts: LinkExternalAccounts;
};
export type DataProvidersPlanAccountsLinkSchwabExternalCreateApiResponse =
  unknown;
export type DataProvidersPlanAccountsLinkSchwabExternalCreateApiArg = {
  planId: string;
  linkExternalAccounts: LinkExternalAccounts;
};
export type DataProvidersSearchRaymondAccountsListApiResponse =
  /** status 200  */ ExternalAccountSearch[];
export type DataProvidersSearchRaymondAccountsListApiArg = {
  /** The string to search by. */
  searchCriterion: string;
};
export type DataProvidersSearchRaymondAccountsByHouseholdListApiResponse =
  /** status 200  */ ExternalAccountSearch[];
export type DataProvidersSearchRaymondAccountsByHouseholdListApiArg = {
  externalHouseholdId: string;
};
export type DataProvidersSearchRaymondHouseholdsListApiResponse =
  /** status 200  */ ExternalRaymondJamesHousehold[];
export type DataProvidersSearchRaymondHouseholdsListApiArg = {
  /** The string to search by. */
  searchCriterion: string;
};
export type DataProvidersSearchSchwabAccountsListApiResponse =
  /** status 200  */ ExternalAccountSearch[];
export type DataProvidersSearchSchwabAccountsListApiArg = {
  /** The string to search by. */
  searchCriterion: string;
};
export type DataProvidersStyleBoxListApiResponse =
  /** status 200  */ StyleBox[];
export type DataProvidersStyleBoxListApiArg = void;
export type NotificationsNotificationListApiResponse =
  /** status 200  */ Notification[];
export type NotificationsNotificationListApiArg = void;
export type NotificationsNotificationRetrieveApiResponse =
  /** status 200  */ Notification;
export type NotificationsNotificationRetrieveApiArg = {
  /** A UUID string identifying this notification. */
  id: string;
};
export type NotificationsNotificationUpdateApiResponse =
  /** status 200  */ Notification;
export type NotificationsNotificationUpdateApiArg = {
  /** A UUID string identifying this notification. */
  id: string;
  notification: Notification;
};
export type NotificationsNotificationPartialUpdateApiResponse =
  /** status 200  */ Notification;
export type NotificationsNotificationPartialUpdateApiArg = {
  /** A UUID string identifying this notification. */
  id: string;
  patchedNotification: PatchedNotification;
};
export type NotificationsNotificationGetReadNotificationsListApiResponse =
  /** status 200  */ Notification[];
export type NotificationsNotificationGetReadNotificationsListApiArg = void;
export type NotificationsNotificationGetSnoozedNotificationsListApiResponse =
  /** status 200  */ Notification[];
export type NotificationsNotificationGetSnoozedNotificationsListApiArg = void;
export type NotificationsNotificationLevelListApiResponse =
  /** status 200  */ NotificationLevel[];
export type NotificationsNotificationLevelListApiArg = void;
export type NotificationsNotificationLevelRetrieveApiResponse =
  /** status 200  */ NotificationLevel;
export type NotificationsNotificationLevelRetrieveApiArg = {
  /** A UUID string identifying this notification level. */
  id: string;
};
export type NotificationsNotificationSettingListApiResponse =
  /** status 200  */ NotificationSetting[];
export type NotificationsNotificationSettingListApiArg = void;
export type NotificationsNotificationSettingRetrieveApiResponse =
  /** status 200  */ NotificationSetting;
export type NotificationsNotificationSettingRetrieveApiArg = {
  /** A UUID string identifying this notification setting. */
  id: string;
};
export type NotificationsNotificationSettingUpdateApiResponse =
  /** status 200  */ NotificationSetting;
export type NotificationsNotificationSettingUpdateApiArg = {
  /** A UUID string identifying this notification setting. */
  id: string;
  notificationSetting: NotificationSetting;
};
export type NotificationsNotificationSettingPartialUpdateApiResponse =
  /** status 200  */ NotificationSetting;
export type NotificationsNotificationSettingPartialUpdateApiArg = {
  /** A UUID string identifying this notification setting. */
  id: string;
  patchedNotificationSetting: PatchedNotificationSetting;
};
export type NotificationsNotificationTierThresholdListApiResponse =
  /** status 200  */ NotificationTierThreshold[];
export type NotificationsNotificationTierThresholdListApiArg = void;
export type NotificationsNotificationTierThresholdRetrieveApiResponse =
  /** status 200  */ NotificationTierThreshold;
export type NotificationsNotificationTierThresholdRetrieveApiArg = {
  /** A UUID string identifying this notification tier threshold. */
  id: string;
};
export type NotificationsNotificationTierThresholdUpdateApiResponse =
  /** status 200  */ NotificationTierThreshold;
export type NotificationsNotificationTierThresholdUpdateApiArg = {
  /** A UUID string identifying this notification tier threshold. */
  id: string;
  notificationTierThreshold: NotificationTierThreshold;
};
export type NotificationsNotificationTierThresholdPartialUpdateApiResponse =
  /** status 200  */ NotificationTierThreshold;
export type NotificationsNotificationTierThresholdPartialUpdateApiArg = {
  /** A UUID string identifying this notification tier threshold. */
  id: string;
  patchedNotificationTierThreshold: PatchedNotificationTierThreshold;
};
export type NotificationsNotificationTypeListApiResponse =
  /** status 200  */ NotificationType[];
export type NotificationsNotificationTypeListApiArg = void;
export type NotificationsNotificationTypeRetrieveApiResponse =
  /** status 200  */ NotificationType;
export type NotificationsNotificationTypeRetrieveApiArg = {
  /** A UUID string identifying this notification type. */
  id: string;
};
export type PortfolioBenchmarkEquityStyleBoxHeatmapListApiResponse =
  /** status 200  */ {
    [key: string]: string;
  }[];
export type PortfolioBenchmarkEquityStyleBoxHeatmapListApiArg = {
  benchmarkModuleDataId: string;
  portModuleDataId: string;
};
export type PortfolioConstraintChoicesListApiResponse =
  /** status 200  */ ConstraintChoices[];
export type PortfolioConstraintChoicesListApiArg = void;
export type PortfolioConstraintChoicesRetrieveApiResponse =
  /** status 200  */ ConstraintChoices;
export type PortfolioConstraintChoicesRetrieveApiArg = {
  /** A UUID string identifying this constraint choices. */
  id: string;
};
export type PortfolioGetPortfolioModuleDataCreateApiResponse =
  /** status 200  */ PortfolioModuleDataStatisticSet[];
export type PortfolioGetPortfolioModuleDataCreateApiArg = {
  portfolioModuleDataStatisticSetBatchViewRequests: PortfolioModuleDataStatisticSetBatchViewRequests;
};
export type PortfolioOptimizerDynamicRulesListApiResponse =
  /** status 200  */ OptimizerDynamicRules[];
export type PortfolioOptimizerDynamicRulesListApiArg = void;
export type PortfolioOptimizerTemplatesListApiResponse =
  /** status 200  */ OptimizerTemplateContainer[];
export type PortfolioOptimizerTemplatesListApiArg = void;
export type PortfolioOptimizerTemplatesCreateApiResponse =
  /** status 201  */ OptimizerTemplateContainer;
export type PortfolioOptimizerTemplatesCreateApiArg = {
  optimizerTemplateContainer: OptimizerTemplateContainer;
};
export type PortfolioOptimizerTemplatesRetrieveApiResponse =
  /** status 200  */ OptimizerTemplateContainer;
export type PortfolioOptimizerTemplatesRetrieveApiArg = {
  /** A UUID string identifying this optimizer template container. */
  id: string;
};
export type PortfolioOptimizerTemplatesUpdateApiResponse =
  /** status 200  */ OptimizerTemplateContainer;
export type PortfolioOptimizerTemplatesUpdateApiArg = {
  /** A UUID string identifying this optimizer template container. */
  id: string;
  optimizerTemplateContainer: OptimizerTemplateContainer;
};
export type PortfolioOptimizerTemplatesPartialUpdateApiResponse =
  /** status 200  */ OptimizerTemplateContainer;
export type PortfolioOptimizerTemplatesPartialUpdateApiArg = {
  /** A UUID string identifying this optimizer template container. */
  id: string;
  patchedOptimizerTemplateContainer: PatchedOptimizerTemplateContainer;
};
export type PortfolioOptimizerTemplatesDestroyApiResponse = unknown;
export type PortfolioOptimizerTemplatesDestroyApiArg = {
  /** A UUID string identifying this optimizer template container. */
  id: string;
};
export type PortfolioOptimizerTemplatesLoadTemplateUpdateApiResponse =
  /** status 200  */ OptimizerTemplateContainer;
export type PortfolioOptimizerTemplatesLoadTemplateUpdateApiArg = {
  /** A UUID string identifying this optimizer template container. */
  id: string;
  optimizerTemplateContainerLoad: OptimizerTemplateContainerLoad;
};
export type PortfolioOptimizerTemplatesLoadJobUpdateApiResponse =
  /** status 200  */ OptimizerTemplateContainer;
export type PortfolioOptimizerTemplatesLoadJobUpdateApiArg = {
  optimizerTemplateContainerLoadFromJob: OptimizerTemplateContainerLoadFromJob;
};
export type PortfolioPortfolioGroupListApiResponse =
  /** status 200  */ PortfolioGroup[];
export type PortfolioPortfolioGroupListApiArg = void;
export type PortfolioPortfolioGroupCreateApiResponse =
  /** status 201  */ PortfolioGroup;
export type PortfolioPortfolioGroupCreateApiArg = {
  portfolioGroup: PortfolioGroup;
};
export type PortfolioPortfolioGroupRetrieveApiResponse =
  /** status 200  */ PortfolioGroup;
export type PortfolioPortfolioGroupRetrieveApiArg = {
  /** A UUID string identifying this portfolio group. */
  id: string;
};
export type PortfolioPortfolioGroupUpdateApiResponse =
  /** status 200  */ PortfolioGroup;
export type PortfolioPortfolioGroupUpdateApiArg = {
  /** A UUID string identifying this portfolio group. */
  id: string;
  portfolioGroup: PortfolioGroup;
};
export type PortfolioPortfolioGroupPartialUpdateApiResponse =
  /** status 200  */ PortfolioGroup;
export type PortfolioPortfolioGroupPartialUpdateApiArg = {
  /** A UUID string identifying this portfolio group. */
  id: string;
  patchedPortfolioGroup: PatchedPortfolioGroup;
};
export type PortfolioPortfolioGroupDestroyApiResponse = unknown;
export type PortfolioPortfolioGroupDestroyApiArg = {
  /** A UUID string identifying this portfolio group. */
  id: string;
};
export type PortfolioPortfolioGroupVersionListApiResponse =
  /** status 200  */ PortfolioGroupVersionUnnested[];
export type PortfolioPortfolioGroupVersionListApiArg = void;
export type PortfolioPortfolioGroupVersionRetrieveApiResponse =
  /** status 200  */ PortfolioGroupVersion;
export type PortfolioPortfolioGroupVersionRetrieveApiArg = {
  /** A UUID string identifying this portfolio group version. */
  id: string;
};
export type PortfolioPortfolioGroupVersionUpdateApiResponse =
  /** status 200  */ PortfolioGroupVersion;
export type PortfolioPortfolioGroupVersionUpdateApiArg = {
  /** A UUID string identifying this portfolio group version. */
  id: string;
  portfolioGroupVersion: PortfolioGroupVersion;
};
export type PortfolioPortfolioGroupVersionPartialUpdateApiResponse =
  /** status 200  */ PortfolioGroupVersion;
export type PortfolioPortfolioGroupVersionPartialUpdateApiArg = {
  /** A UUID string identifying this portfolio group version. */
  id: string;
  patchedPortfolioGroupVersion: PatchedPortfolioGroupVersion;
};
export type PortfolioPortfolioGroupVersionAssetClassesListApiResponse =
  /** status 200  */ PortfolioAssetClassOptions[];
export type PortfolioPortfolioGroupVersionAssetClassesListApiArg = {
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionAssetClassesRetrieveApiResponse =
  /** status 200  */ PortfolioAssetClassOptions;
export type PortfolioPortfolioGroupVersionAssetClassesRetrieveApiArg = {
  /** A UUID string identifying this portfolio asset class options. */
  id: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionAssetClassesUpdateApiResponse =
  /** status 200  */ PortfolioAssetClassOptions;
export type PortfolioPortfolioGroupVersionAssetClassesUpdateApiArg = {
  /** A UUID string identifying this portfolio asset class options. */
  id: string;
  portfolioGroupVersionId: string;
  portfolioAssetClassOptions: PortfolioAssetClassOptions;
};
export type PortfolioPortfolioGroupVersionAssetClassesPartialUpdateApiResponse =
  /** status 200  */ PortfolioAssetClassOptions;
export type PortfolioPortfolioGroupVersionAssetClassesPartialUpdateApiArg = {
  /** A UUID string identifying this portfolio asset class options. */
  id: string;
  portfolioGroupVersionId: string;
  patchedPortfolioAssetClassOptions: PatchedPortfolioAssetClassOptions;
};
export type PortfolioPortfolioGroupVersionModulesListApiResponse =
  /** status 200  */ PortfolioModule[];
export type PortfolioPortfolioGroupVersionModulesListApiArg = {
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionModulesCreateApiResponse =
  /** status 201  */ PortfolioModule;
export type PortfolioPortfolioGroupVersionModulesCreateApiArg = {
  portfolioGroupVersionId: string;
  portfolioModule: PortfolioModule;
};
export type PortfolioPortfolioGroupVersionModulesConstraintsListApiResponse =
  /** status 200  */ PortfolioModuleConstraints[];
export type PortfolioPortfolioGroupVersionModulesConstraintsListApiArg = {
  moduleId: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionModulesConstraintsRetrieveApiResponse =
  /** status 200  */ PortfolioModuleConstraints;
export type PortfolioPortfolioGroupVersionModulesConstraintsRetrieveApiArg = {
  /** A UUID string identifying this Portfolio Module Constraints. */
  id: string;
  moduleId: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionModulesConstraintsUpdateApiResponse =
  /** status 200  */ PortfolioModuleConstraints;
export type PortfolioPortfolioGroupVersionModulesConstraintsUpdateApiArg = {
  /** A UUID string identifying this Portfolio Module Constraints. */
  id: string;
  moduleId: string;
  portfolioGroupVersionId: string;
  portfolioModuleConstraints: PortfolioModuleConstraints;
};
export type PortfolioPortfolioGroupVersionModulesConstraintsPartialUpdateApiResponse =
  /** status 200  */ PortfolioModuleConstraints;
export type PortfolioPortfolioGroupVersionModulesConstraintsPartialUpdateApiArg =
  {
    /** A UUID string identifying this Portfolio Module Constraints. */
    id: string;
    moduleId: string;
    portfolioGroupVersionId: string;
    patchedPortfolioModuleConstraints: PatchedPortfolioModuleConstraints;
  };
export type PortfolioPortfolioGroupVersionModulesConstraintsAcListApiResponse =
  /** status 200  */ PortfolioModuleConstraintsAc[];
export type PortfolioPortfolioGroupVersionModulesConstraintsAcListApiArg = {
  moduleId: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionModulesConstraintsAcRetrieveApiResponse =
  /** status 200  */ PortfolioModuleConstraintsAc;
export type PortfolioPortfolioGroupVersionModulesConstraintsAcRetrieveApiArg = {
  /** A UUID string identifying this portfolio module constraints ac. */
  id: string;
  moduleId: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionModulesConstraintsAcUpdateApiResponse =
  /** status 200  */ PortfolioModuleConstraintsAc;
export type PortfolioPortfolioGroupVersionModulesConstraintsAcUpdateApiArg = {
  /** A UUID string identifying this portfolio module constraints ac. */
  id: string;
  moduleId: string;
  portfolioGroupVersionId: string;
  portfolioModuleConstraintsAc: PortfolioModuleConstraintsAc;
};
export type PortfolioPortfolioGroupVersionModulesConstraintsAcPartialUpdateApiResponse =
  /** status 200  */ PortfolioModuleConstraintsAc;
export type PortfolioPortfolioGroupVersionModulesConstraintsAcPartialUpdateApiArg =
  {
    /** A UUID string identifying this portfolio module constraints ac. */
    id: string;
    moduleId: string;
    portfolioGroupVersionId: string;
    patchedPortfolioModuleConstraintsAc: PatchedPortfolioModuleConstraintsAc;
  };
export type PortfolioPortfolioGroupVersionModulesDataListApiResponse =
  /** status 200  */ PortfolioModuleData[];
export type PortfolioPortfolioGroupVersionModulesDataListApiArg = {
  moduleId: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionModulesDataAllocationsListApiResponse =
  /** status 200  */ PortfolioModuleDataAllocation[];
export type PortfolioPortfolioGroupVersionModulesDataAllocationsListApiArg = {
  dataId: string;
  moduleId: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionModulesDataAllocationsRetrieveApiResponse =
  /** status 200  */ PortfolioModuleDataAllocation;
export type PortfolioPortfolioGroupVersionModulesDataAllocationsRetrieveApiArg =
  {
    dataId: string;
    /** A UUID string identifying this portfolio module data allocation. */
    id: string;
    moduleId: string;
    portfolioGroupVersionId: string;
  };
export type PortfolioPortfolioGroupVersionModulesDataAllocationsUpdateApiResponse =
  /** status 200  */ PortfolioModuleDataAllocation;
export type PortfolioPortfolioGroupVersionModulesDataAllocationsUpdateApiArg = {
  dataId: string;
  /** A UUID string identifying this portfolio module data allocation. */
  id: string;
  moduleId: string;
  portfolioGroupVersionId: string;
  portfolioModuleDataAllocation: PortfolioModuleDataAllocation;
};
export type PortfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdateApiResponse =
  /** status 200  */ PortfolioModuleDataAllocation;
export type PortfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdateApiArg =
  {
    dataId: string;
    /** A UUID string identifying this portfolio module data allocation. */
    id: string;
    moduleId: string;
    portfolioGroupVersionId: string;
    patchedPortfolioModuleDataAllocation: PatchedPortfolioModuleDataAllocation;
  };
export type PortfolioPortfolioGroupVersionModulesDataRetrieveApiResponse =
  /** status 200  */ PortfolioModuleData;
export type PortfolioPortfolioGroupVersionModulesDataRetrieveApiArg = {
  /** A UUID string identifying this portfolio module data. */
  id: string;
  moduleId: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionModulesDataUpdateApiResponse =
  /** status 200  */ PortfolioModuleData;
export type PortfolioPortfolioGroupVersionModulesDataUpdateApiArg = {
  /** A UUID string identifying this portfolio module data. */
  id: string;
  moduleId: string;
  portfolioGroupVersionId: string;
  portfolioModuleData: PortfolioModuleData;
};
export type PortfolioPortfolioGroupVersionModulesDataPartialUpdateApiResponse =
  /** status 200  */ PortfolioModuleData;
export type PortfolioPortfolioGroupVersionModulesDataPartialUpdateApiArg = {
  /** A UUID string identifying this portfolio module data. */
  id: string;
  moduleId: string;
  portfolioGroupVersionId: string;
  patchedPortfolioModuleData: PatchedPortfolioModuleData;
};
export type PortfolioPortfolioGroupVersionModulesDataMakeSelectedUpdateApiResponse =
  /** status 200  */ PortfolioModuleData;
export type PortfolioPortfolioGroupVersionModulesDataMakeSelectedUpdateApiArg =
  {
    /** A UUID string identifying this portfolio module data. */
    id: string;
    moduleId: string;
    portfolioGroupVersionId: string;
  };
export type PortfolioPortfolioGroupVersionModulesRetrieveApiResponse =
  /** status 200  */ PortfolioModule;
export type PortfolioPortfolioGroupVersionModulesRetrieveApiArg = {
  /** A UUID string identifying this portfolio module. */
  id: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionModulesUpdateApiResponse =
  /** status 200  */ PortfolioModule;
export type PortfolioPortfolioGroupVersionModulesUpdateApiArg = {
  /** A UUID string identifying this portfolio module. */
  id: string;
  portfolioGroupVersionId: string;
  portfolioModule: PortfolioModule;
};
export type PortfolioPortfolioGroupVersionModulesPartialUpdateApiResponse =
  /** status 200  */ PortfolioModule;
export type PortfolioPortfolioGroupVersionModulesPartialUpdateApiArg = {
  /** A UUID string identifying this portfolio module. */
  id: string;
  portfolioGroupVersionId: string;
  patchedPortfolioModule: PatchedPortfolioModule;
};
export type PortfolioPortfolioGroupVersionModulesDestroyApiResponse = unknown;
export type PortfolioPortfolioGroupVersionModulesDestroyApiArg = {
  /** A UUID string identifying this portfolio module. */
  id: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionOptimizerAllConsListApiResponse =
  /** status 200  */ PortfolioModuleConsNested[];
export type PortfolioPortfolioGroupVersionOptimizerAllConsListApiArg = {
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionOptimizerAllConsRetrieveApiResponse =
  /** status 200  */ PortfolioModuleConsNested;
export type PortfolioPortfolioGroupVersionOptimizerAllConsRetrieveApiArg = {
  /** A UUID string identifying this portfolio module. */
  id: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionOptimizerJobsListApiResponse =
  /** status 200  */ PortfolioGroupVersionOptimizationJobRoot[];
export type PortfolioPortfolioGroupVersionOptimizerJobsListApiArg = {
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionOptimizerJobsCreateApiResponse =
  /** status 201  */ PortfolioGroupVersionOptimizationJobRoot;
export type PortfolioPortfolioGroupVersionOptimizerJobsCreateApiArg = {
  portfolioGroupVersionId: string;
  portfolioGroupVersionOptimizationJobRoot: PortfolioGroupVersionOptimizationJobRoot;
};
export type PortfolioPortfolioGroupVersionOptimizerJobsRetrieveApiResponse =
  /** status 200  */ PortfolioGroupVersionOptimizationJobRoot;
export type PortfolioPortfolioGroupVersionOptimizerJobsRetrieveApiArg = {
  /** A UUID string identifying this portfolio group version optimization job root. */
  id: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionOptimizerJobsUpdateApiResponse =
  /** status 200  */ PortfolioGroupVersionOptimizationJobRoot;
export type PortfolioPortfolioGroupVersionOptimizerJobsUpdateApiArg = {
  /** A UUID string identifying this portfolio group version optimization job root. */
  id: string;
  portfolioGroupVersionId: string;
  portfolioGroupVersionOptimizationJobRoot: PortfolioGroupVersionOptimizationJobRoot;
};
export type PortfolioPortfolioGroupVersionOptimizerJobsPartialUpdateApiResponse =
  /** status 200  */ PortfolioGroupVersionOptimizationJobRoot;
export type PortfolioPortfolioGroupVersionOptimizerJobsPartialUpdateApiArg = {
  /** A UUID string identifying this portfolio group version optimization job root. */
  id: string;
  portfolioGroupVersionId: string;
  patchedPortfolioGroupVersionOptimizationJobRoot: PatchedPortfolioGroupVersionOptimizationJobRoot;
};
export type PortfolioPortfolioGroupVersionOptimizerJobsDestroyApiResponse =
  unknown;
export type PortfolioPortfolioGroupVersionOptimizerJobsDestroyApiArg = {
  /** A UUID string identifying this portfolio group version optimization job root. */
  id: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionOptimizerSettingsListApiResponse =
  /** status 200  */ PortfolioGroupVersionOptimizerSettings[];
export type PortfolioPortfolioGroupVersionOptimizerSettingsListApiArg = {
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionOptimizerSettingsCreateApiResponse =
  /** status 201  */ PortfolioGroupVersionOptimizerSettings;
export type PortfolioPortfolioGroupVersionOptimizerSettingsCreateApiArg = {
  portfolioGroupVersionId: string;
  portfolioGroupVersionOptimizerSettings: PortfolioGroupVersionOptimizerSettings;
};
export type PortfolioPortfolioGroupVersionOptimizerSettingsRetrieveApiResponse =
  /** status 200  */ PortfolioGroupVersionOptimizerSettings;
export type PortfolioPortfolioGroupVersionOptimizerSettingsRetrieveApiArg = {
  /** A UUID string identifying this Portfolio Group Version Optimizer Settings. */
  id: string;
  portfolioGroupVersionId: string;
};
export type PortfolioPortfolioGroupVersionOptimizerSettingsUpdateApiResponse =
  /** status 200  */ PortfolioGroupVersionOptimizerSettings;
export type PortfolioPortfolioGroupVersionOptimizerSettingsUpdateApiArg = {
  /** A UUID string identifying this Portfolio Group Version Optimizer Settings. */
  id: string;
  portfolioGroupVersionId: string;
  portfolioGroupVersionOptimizerSettings: PortfolioGroupVersionOptimizerSettings;
};
export type PortfolioPortfolioGroupVersionOptimizerSettingsPartialUpdateApiResponse =
  /** status 200  */ PortfolioGroupVersionOptimizerSettings;
export type PortfolioPortfolioGroupVersionOptimizerSettingsPartialUpdateApiArg =
  {
    /** A UUID string identifying this Portfolio Group Version Optimizer Settings. */
    id: string;
    portfolioGroupVersionId: string;
    patchedPortfolioGroupVersionOptimizerSettings: PatchedPortfolioGroupVersionOptimizerSettings;
  };
export type PortfolioPortfolioGroupVersionStatusListApiResponse =
  /** status 200  */ PortfolioGroupVersionStatus[];
export type PortfolioPortfolioGroupVersionStatusListApiArg = void;
export type PortfolioPortfolioGroupVersionStatusRetrieveApiResponse =
  /** status 200  */ PortfolioGroupVersionStatus;
export type PortfolioPortfolioGroupVersionStatusRetrieveApiArg = {
  /** A UUID string identifying this portfolio group version status. */
  id: string;
};
export type PortfolioPortfolioModuleDataEquityStyleBoxWeightsListApiResponse =
  /** status 200  */ {
    [key: string]: string;
  }[];
export type PortfolioPortfolioModuleDataEquityStyleBoxWeightsListApiArg = {
  portModuleDataId: string;
};
export type PortfolioPortfolioModuleDataTypeListApiResponse =
  /** status 200  */ PortfolioModuleDataType[];
export type PortfolioPortfolioModuleDataTypeListApiArg = void;
export type PortfolioPortfolioModuleDataTypeRetrieveApiResponse =
  /** status 200  */ PortfolioModuleDataType;
export type PortfolioPortfolioModuleDataTypeRetrieveApiArg = {
  /** A UUID string identifying this portfolio module data type. */
  id: string;
};
export type PortfolioRunAutoOptimizationsRetrieveApiResponse =
  /** status 200  */ BuildRuleOptimizations;
export type PortfolioRunAutoOptimizationsRetrieveApiArg = {
  portModuleId: string;
};
export type PortfolioRunDynamicOptimizationsCreateApiResponse =
  /** status 200  */ UuidList;
export type PortfolioRunDynamicOptimizationsCreateApiArg = {
  portModuleId: string;
  processOptimizerDynamicRules: ProcessOptimizerDynamicRules;
};
export type QuestionnaireClientTierListApiResponse =
  /** status 200  */ ClientTier[];
export type QuestionnaireClientTierListApiArg = void;
export type QuestionnaireClientTierRetrieveApiResponse =
  /** status 200  */ ClientTier;
export type QuestionnaireClientTierRetrieveApiArg = {
  /** A UUID string identifying this client tier. */
  id: string;
};
export type QuestionnaireClientsListApiResponse = /** status 200  */ Client[];
export type QuestionnaireClientsListApiArg = {
  /** A search term. */
  search?: string;
};
export type QuestionnaireClientsCreateApiResponse = /** status 201  */ Client;
export type QuestionnaireClientsCreateApiArg = {
  client: Client;
};
export type QuestionnaireClientsNotesListApiResponse =
  /** status 200  */ ClientNote[];
export type QuestionnaireClientsNotesListApiArg = {
  clientId: string;
};
export type QuestionnaireClientsNotesCreateApiResponse =
  /** status 201  */ ClientNote;
export type QuestionnaireClientsNotesCreateApiArg = {
  clientId: string;
  clientNote: ClientNote;
};
export type QuestionnaireClientsNotesRetrieveApiResponse =
  /** status 200  */ ClientNote;
export type QuestionnaireClientsNotesRetrieveApiArg = {
  clientId: string;
  /** A UUID string identifying this client note. */
  id: string;
};
export type QuestionnaireClientsNotesUpdateApiResponse =
  /** status 200  */ ClientNote;
export type QuestionnaireClientsNotesUpdateApiArg = {
  clientId: string;
  /** A UUID string identifying this client note. */
  id: string;
  clientNote: ClientNote;
};
export type QuestionnaireClientsNotesPartialUpdateApiResponse =
  /** status 200  */ ClientNote;
export type QuestionnaireClientsNotesPartialUpdateApiArg = {
  clientId: string;
  /** A UUID string identifying this client note. */
  id: string;
  patchedClientNote: PatchedClientNote;
};
export type QuestionnaireClientsNotesDestroyApiResponse = unknown;
export type QuestionnaireClientsNotesDestroyApiArg = {
  clientId: string;
  /** A UUID string identifying this client note. */
  id: string;
};
export type QuestionnaireClientsRetrieveApiResponse = /** status 200  */ Client;
export type QuestionnaireClientsRetrieveApiArg = {
  /** A UUID string identifying this client. */
  id: string;
};
export type QuestionnaireClientsUpdateApiResponse = /** status 200  */ Client;
export type QuestionnaireClientsUpdateApiArg = {
  /** A UUID string identifying this client. */
  id: string;
  client: Client;
};
export type QuestionnaireClientsPartialUpdateApiResponse =
  /** status 200  */ Client;
export type QuestionnaireClientsPartialUpdateApiArg = {
  /** A UUID string identifying this client. */
  id: string;
  patchedClient: PatchedClient;
};
export type QuestionnaireClientsDestroyApiResponse = unknown;
export type QuestionnaireClientsDestroyApiArg = {
  /** A UUID string identifying this client. */
  id: string;
};
export type QuestionnaireClientsCurrentAllocationDetailRetrieveApiResponse =
  /** status 200  */ Client;
export type QuestionnaireClientsCurrentAllocationDetailRetrieveApiArg = {
  /** A UUID string identifying this client. */
  id: string;
};
export type QuestionnaireExternalBlackDiamondConnectionCreateApiResponse =
  /** status 200  */ BlackDiamondIntegration;
export type QuestionnaireExternalBlackDiamondConnectionCreateApiArg = {
  blackDiamondIntegration: BlackDiamondIntegration;
};
export type QuestionnaireExternalBlackDiamondConnectionDestroyApiResponse =
  unknown;
export type QuestionnaireExternalBlackDiamondConnectionDestroyApiArg = void;
export type QuestionnaireExternalBlackDiamondPortfolioAccountsListApiResponse =
  /** status 200  */ ExternalBlackDiamondAccount[];
export type QuestionnaireExternalBlackDiamondPortfolioAccountsListApiArg = {
  externalPortfolioId: string;
};
export type QuestionnaireExternalBlackDiamondSearchAccountsListApiResponse =
  /** status 200  */ ExternalBlackDiamondAccount[];
export type QuestionnaireExternalBlackDiamondSearchAccountsListApiArg = {
  /** The string to search by. */
  searchCriterion: string;
};
export type QuestionnaireExternalBlackDiamondSearchPortfoliosListApiResponse =
  /** status 200  */ ExternalBlackDiamondPortfolio[];
export type QuestionnaireExternalBlackDiamondSearchPortfoliosListApiArg = {
  /** The string to search by. */
  searchCriterion: string;
};
export type QuestionnaireExternalCollegeDataRetrieveApiResponse =
  /** status 200  */ CollegeSimpleStatistics;
export type QuestionnaireExternalCollegeDataRetrieveApiArg = void;
export type QuestionnaireExternalCollegeStatisticsRetrieveApiResponse =
  /** status 200  */ CollegeStatistics;
export type QuestionnaireExternalCollegeStatisticsRetrieveApiArg = {
  /** A UUID string identifying this college statistics. */
  id: string;
};
export type QuestionnaireExternalListAvailableIntegratorsListApiResponse =
  /** status 200  */ Integrator[];
export type QuestionnaireExternalListAvailableIntegratorsListApiArg = void;
export type QuestionnaireGetLifeExpectancyRetrieveApiResponse =
  /** status 200  */ GetLifeExpectancy;
export type QuestionnaireGetLifeExpectancyRetrieveApiArg = void;
export type QuestionnairePlanListApiResponse = /** status 200  */ ClientPlan[];
export type QuestionnairePlanListApiArg = void;
export type QuestionnairePlanCreateApiResponse = /** status 201  */ ClientPlan;
export type QuestionnairePlanCreateApiArg = {
  clientPlan: ClientPlan;
};
export type QuestionnairePlanRetrieveApiResponse =
  /** status 200  */ ClientPlan;
export type QuestionnairePlanRetrieveApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
};
export type QuestionnairePlanUpdateApiResponse = /** status 200  */ ClientPlan;
export type QuestionnairePlanUpdateApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
  clientPlan: ClientPlan;
};
export type QuestionnairePlanPartialUpdateApiResponse =
  /** status 200  */ ClientPlan;
export type QuestionnairePlanPartialUpdateApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
  patchedClientPlan: PatchedClientPlan;
};
export type QuestionnairePlanDestroyApiResponse = unknown;
export type QuestionnairePlanDestroyApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
};
export type QuestionnairePlanCopyUpdateApiResponse =
  /** status 200  */ ClientPlan;
export type QuestionnairePlanCopyUpdateApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
  clientPlanCopyRequest: ClientPlanCopyRequest;
};
export type QuestionnairePlanOptimizedSocialSecurityRetrieveApiResponse =
  /** status 200  */ OptimizedSocialSecurity;
export type QuestionnairePlanOptimizedSocialSecurityRetrieveApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
};
export type QuestionnairePlanRiskRetrieveApiResponse =
  /** status 200  */ ClientPlanRisk;
export type QuestionnairePlanRiskRetrieveApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
};
export type QuestionnairePlanRiskUpdateApiResponse =
  /** status 200  */ ClientPlanRisk;
export type QuestionnairePlanRiskUpdateApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
  clientPlanRisk: ClientPlanRisk;
};
export type QuestionnairePlanSavingsThisYearRetrieveApiResponse =
  /** status 200  */ SavingsThisYear;
export type QuestionnairePlanSavingsThisYearRetrieveApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
};
export type QuestionnairePlanSpousalBenefitRetrieveApiResponse =
  /** status 200  */ ClientPlanSpousalBenefit;
export type QuestionnairePlanSpousalBenefitRetrieveApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
};
export type QuestionnairePlanSpousalBenefitUpdateApiResponse =
  /** status 200  */ ClientPlanSpousalBenefit;
export type QuestionnairePlanSpousalBenefitUpdateApiArg = {
  /** A UUID string identifying this client plan. */
  id: string;
  clientPlanSpousalBenefit: ClientPlanSpousalBenefit;
};
export type QuestionnairePlanAccountsLinkExternalCreateApiResponse = unknown;
export type QuestionnairePlanAccountsLinkExternalCreateApiArg = {
  planId: string;
  linkBlackDiamondAccounts: LinkBlackDiamondAccounts;
};
export type QuestionnairePlanAccountsListApiResponse =
  /** status 200  */ Account[];
export type QuestionnairePlanAccountsListApiArg = {
  planId: string;
};
export type QuestionnairePlanAccountsCreateApiResponse =
  /** status 201  */ Account;
export type QuestionnairePlanAccountsCreateApiArg = {
  planId: string;
  account: Account;
};
export type QuestionnairePlanAccountsRetrieveApiResponse =
  /** status 200  */ Account;
export type QuestionnairePlanAccountsRetrieveApiArg = {
  /** A UUID string identifying this account. */
  id: string;
  planId: string;
};
export type QuestionnairePlanAccountsUpdateApiResponse =
  /** status 200  */ Account;
export type QuestionnairePlanAccountsUpdateApiArg = {
  /** A UUID string identifying this account. */
  id: string;
  planId: string;
  account: Account;
};
export type QuestionnairePlanAccountsPartialUpdateApiResponse =
  /** status 200  */ Account;
export type QuestionnairePlanAccountsPartialUpdateApiArg = {
  /** A UUID string identifying this account. */
  id: string;
  planId: string;
  patchedAccount: PatchedAccount;
};
export type QuestionnairePlanAccountsDestroyApiResponse = unknown;
export type QuestionnairePlanAccountsDestroyApiArg = {
  /** A UUID string identifying this account. */
  id: string;
  planId: string;
};
export type QuestionnairePlanAdditionalIncomeListApiResponse =
  /** status 200  */ ClientPlanAdditionalIncome[];
export type QuestionnairePlanAdditionalIncomeListApiArg = {
  planId: string;
};
export type QuestionnairePlanAdditionalIncomeCreateApiResponse =
  /** status 201  */ ClientPlanAdditionalIncome;
export type QuestionnairePlanAdditionalIncomeCreateApiArg = {
  planId: string;
  clientPlanAdditionalIncome: ClientPlanAdditionalIncome;
};
export type QuestionnairePlanAdditionalIncomeRetrieveApiResponse =
  /** status 200  */ ClientPlanAdditionalIncome;
export type QuestionnairePlanAdditionalIncomeRetrieveApiArg = {
  /** A UUID string identifying this client plan additional income. */
  id: string;
  planId: string;
};
export type QuestionnairePlanAdditionalIncomeUpdateApiResponse =
  /** status 200  */ ClientPlanAdditionalIncome;
export type QuestionnairePlanAdditionalIncomeUpdateApiArg = {
  /** A UUID string identifying this client plan additional income. */
  id: string;
  planId: string;
  clientPlanAdditionalIncome: ClientPlanAdditionalIncome;
};
export type QuestionnairePlanAdditionalIncomePartialUpdateApiResponse =
  /** status 200  */ ClientPlanAdditionalIncome;
export type QuestionnairePlanAdditionalIncomePartialUpdateApiArg = {
  /** A UUID string identifying this client plan additional income. */
  id: string;
  planId: string;
  patchedClientPlanAdditionalIncome: PatchedClientPlanAdditionalIncome;
};
export type QuestionnairePlanAdditionalIncomeDestroyApiResponse = unknown;
export type QuestionnairePlanAdditionalIncomeDestroyApiArg = {
  /** A UUID string identifying this client plan additional income. */
  id: string;
  planId: string;
};
export type QuestionnairePlanDeriskListApiResponse =
  /** status 200  */ ClientPlanDeRiskDetails[];
export type QuestionnairePlanDeriskListApiArg = {
  planId: string;
};
export type QuestionnairePlanDeriskCreateApiResponse =
  /** status 201  */ ClientPlanDeRiskDetails;
export type QuestionnairePlanDeriskCreateApiArg = {
  planId: string;
  clientPlanDeRiskDetails: ClientPlanDeRiskDetails;
};
export type QuestionnairePlanDeriskRetrieveApiResponse =
  /** status 200  */ ClientPlanDeRiskDetails;
export type QuestionnairePlanDeriskRetrieveApiArg = {
  /** A UUID string identifying this client plan de risk details. */
  id: string;
  planId: string;
};
export type QuestionnairePlanDeriskUpdateApiResponse =
  /** status 200  */ ClientPlanDeRiskDetails;
export type QuestionnairePlanDeriskUpdateApiArg = {
  /** A UUID string identifying this client plan de risk details. */
  id: string;
  planId: string;
  clientPlanDeRiskDetails: ClientPlanDeRiskDetails;
};
export type QuestionnairePlanDeriskPartialUpdateApiResponse =
  /** status 200  */ ClientPlanDeRiskDetails;
export type QuestionnairePlanDeriskPartialUpdateApiArg = {
  /** A UUID string identifying this client plan de risk details. */
  id: string;
  planId: string;
  patchedClientPlanDeRiskDetails: PatchedClientPlanDeRiskDetails;
};
export type QuestionnairePlanDeriskDestroyApiResponse = unknown;
export type QuestionnairePlanDeriskDestroyApiArg = {
  /** A UUID string identifying this client plan de risk details. */
  id: string;
  planId: string;
};
export type QuestionnairePlanGoalsListApiResponse =
  /** status 200  */ ClientPlanGoal[];
export type QuestionnairePlanGoalsListApiArg = {
  planId: string;
};
export type QuestionnairePlanGoalsCreateApiResponse =
  /** status 201  */ ClientPlanGoal;
export type QuestionnairePlanGoalsCreateApiArg = {
  planId: string;
  clientPlanGoal: ClientPlanGoal;
};
export type QuestionnairePlanGoalsRetrieveApiResponse =
  /** status 200  */ ClientPlanGoal;
export type QuestionnairePlanGoalsRetrieveApiArg = {
  /** A UUID string identifying this client plan goal. */
  id: string;
  planId: string;
};
export type QuestionnairePlanGoalsUpdateApiResponse =
  /** status 200  */ ClientPlanGoal;
export type QuestionnairePlanGoalsUpdateApiArg = {
  /** A UUID string identifying this client plan goal. */
  id: string;
  planId: string;
  clientPlanGoal: ClientPlanGoal;
};
export type QuestionnairePlanGoalsPartialUpdateApiResponse =
  /** status 200  */ ClientPlanGoal;
export type QuestionnairePlanGoalsPartialUpdateApiArg = {
  /** A UUID string identifying this client plan goal. */
  id: string;
  planId: string;
  patchedClientPlanGoal: PatchedClientPlanGoal;
};
export type QuestionnairePlanGoalsDestroyApiResponse = unknown;
export type QuestionnairePlanGoalsDestroyApiArg = {
  /** A UUID string identifying this client plan goal. */
  id: string;
  planId: string;
};
export type QuestionnairePlanHealthcareListApiResponse =
  /** status 200  */ ClientPlanHealthcare[];
export type QuestionnairePlanHealthcareListApiArg = {
  planId: string;
};
export type QuestionnairePlanHealthcareCreateApiResponse =
  /** status 201  */ ClientPlanHealthcare;
export type QuestionnairePlanHealthcareCreateApiArg = {
  planId: string;
  clientPlanHealthcare: ClientPlanHealthcare;
};
export type QuestionnairePlanHealthcareRetrieveApiResponse =
  /** status 200  */ ClientPlanHealthcare;
export type QuestionnairePlanHealthcareRetrieveApiArg = {
  /** A UUID string identifying this Client Plan Healthcare. */
  id: string;
  planId: string;
};
export type QuestionnairePlanHealthcareUpdateApiResponse =
  /** status 200  */ ClientPlanHealthcare;
export type QuestionnairePlanHealthcareUpdateApiArg = {
  /** A UUID string identifying this Client Plan Healthcare. */
  id: string;
  planId: string;
  clientPlanHealthcare: ClientPlanHealthcare;
};
export type QuestionnairePlanHealthcarePartialUpdateApiResponse =
  /** status 200  */ ClientPlanHealthcare;
export type QuestionnairePlanHealthcarePartialUpdateApiArg = {
  /** A UUID string identifying this Client Plan Healthcare. */
  id: string;
  planId: string;
  patchedClientPlanHealthcare: PatchedClientPlanHealthcare;
};
export type QuestionnairePlanHealthcareDestroyApiResponse = unknown;
export type QuestionnairePlanHealthcareDestroyApiArg = {
  /** A UUID string identifying this Client Plan Healthcare. */
  id: string;
  planId: string;
};
export type QuestionnairePlanRealEstateListApiResponse =
  /** status 200  */ ClientPlanRealEstate[];
export type QuestionnairePlanRealEstateListApiArg = {
  planId: string;
};
export type QuestionnairePlanRealEstateCreateApiResponse =
  /** status 201  */ ClientPlanRealEstate;
export type QuestionnairePlanRealEstateCreateApiArg = {
  planId: string;
  clientPlanRealEstate: ClientPlanRealEstate;
};
export type QuestionnairePlanRealEstateRetrieveApiResponse =
  /** status 200  */ ClientPlanRealEstate;
export type QuestionnairePlanRealEstateRetrieveApiArg = {
  /** A UUID string identifying this client plan real estate. */
  id: string;
  planId: string;
};
export type QuestionnairePlanRealEstateUpdateApiResponse =
  /** status 200  */ ClientPlanRealEstate;
export type QuestionnairePlanRealEstateUpdateApiArg = {
  /** A UUID string identifying this client plan real estate. */
  id: string;
  planId: string;
  clientPlanRealEstate: ClientPlanRealEstate;
};
export type QuestionnairePlanRealEstatePartialUpdateApiResponse =
  /** status 200  */ ClientPlanRealEstate;
export type QuestionnairePlanRealEstatePartialUpdateApiArg = {
  /** A UUID string identifying this client plan real estate. */
  id: string;
  planId: string;
  patchedClientPlanRealEstate: PatchedClientPlanRealEstate;
};
export type QuestionnairePlanRealEstateDestroyApiResponse = unknown;
export type QuestionnairePlanRealEstateDestroyApiArg = {
  /** A UUID string identifying this client plan real estate. */
  id: string;
  planId: string;
};
export type QuestionnairePlanRelationshipsListApiResponse =
  /** status 200  */ ClientPlanRelationship[];
export type QuestionnairePlanRelationshipsListApiArg = {
  planId: string;
  /** A search term. */
  search?: string;
};
export type QuestionnairePlanRelationshipsCreateApiResponse =
  /** status 201  */ ClientPlanRelationship;
export type QuestionnairePlanRelationshipsCreateApiArg = {
  planId: string;
  clientPlanRelationship: ClientPlanRelationship;
};
export type QuestionnairePlanRelationshipsRetrieveApiResponse =
  /** status 200  */ ClientPlanRelationship;
export type QuestionnairePlanRelationshipsRetrieveApiArg = {
  /** A UUID string identifying this client plan relationship. */
  id: string;
  planId: string;
};
export type QuestionnairePlanRelationshipsUpdateApiResponse =
  /** status 200  */ ClientPlanRelationship;
export type QuestionnairePlanRelationshipsUpdateApiArg = {
  /** A UUID string identifying this client plan relationship. */
  id: string;
  planId: string;
  clientPlanRelationship: ClientPlanRelationship;
};
export type QuestionnairePlanRelationshipsPartialUpdateApiResponse =
  /** status 200  */ ClientPlanRelationship;
export type QuestionnairePlanRelationshipsPartialUpdateApiArg = {
  /** A UUID string identifying this client plan relationship. */
  id: string;
  planId: string;
  patchedClientPlanRelationship: PatchedClientPlanRelationship;
};
export type QuestionnairePlanRelationshipsDestroyApiResponse = unknown;
export type QuestionnairePlanRelationshipsDestroyApiArg = {
  /** A UUID string identifying this client plan relationship. */
  id: string;
  planId: string;
};
export type QuestionnairePlanSocialSecurityListApiResponse =
  /** status 200  */ ClientPlanSocialSecurity[];
export type QuestionnairePlanSocialSecurityListApiArg = {
  planId: string;
};
export type QuestionnairePlanSocialSecurityCreateApiResponse =
  /** status 201  */ ClientPlanSocialSecurity;
export type QuestionnairePlanSocialSecurityCreateApiArg = {
  planId: string;
  clientPlanSocialSecurity: ClientPlanSocialSecurity;
};
export type QuestionnairePlanSocialSecurityRetrieveApiResponse =
  /** status 200  */ ClientPlanSocialSecurity;
export type QuestionnairePlanSocialSecurityRetrieveApiArg = {
  /** A UUID string identifying this client plan social security. */
  id: string;
  planId: string;
};
export type QuestionnairePlanSocialSecurityUpdateApiResponse =
  /** status 200  */ ClientPlanSocialSecurity;
export type QuestionnairePlanSocialSecurityUpdateApiArg = {
  /** A UUID string identifying this client plan social security. */
  id: string;
  planId: string;
  clientPlanSocialSecurity: ClientPlanSocialSecurity;
};
export type QuestionnairePlanSocialSecurityPartialUpdateApiResponse =
  /** status 200  */ ClientPlanSocialSecurity;
export type QuestionnairePlanSocialSecurityPartialUpdateApiArg = {
  /** A UUID string identifying this client plan social security. */
  id: string;
  planId: string;
  patchedClientPlanSocialSecurity: PatchedClientPlanSocialSecurity;
};
export type QuestionnairePlanSocialSecurityDestroyApiResponse = unknown;
export type QuestionnairePlanSocialSecurityDestroyApiArg = {
  /** A UUID string identifying this client plan social security. */
  id: string;
  planId: string;
};
export type QuestionnaireRelationTypeListApiResponse =
  /** status 200  */ HouseholdRelationType[];
export type QuestionnaireRelationTypeListApiArg = {
  /** A search term. */
  search?: string;
};
export type QuestionnaireRelationTypeRetrieveApiResponse =
  /** status 200  */ HouseholdRelationType;
export type QuestionnaireRelationTypeRetrieveApiArg = {
  code: string;
};
export type QuestionnaireRiskQuestionListApiResponse =
  /** status 200  */ RiskQuestion[];
export type QuestionnaireRiskQuestionListApiArg = void;
export type QuestionnaireRiskQuestionRetrieveApiResponse =
  /** status 200  */ RiskQuestion;
export type QuestionnaireRiskQuestionRetrieveApiArg = {
  /** A UUID string identifying this risk question. */
  id: string;
};
export type QuestionnaireTaxPurposeListApiResponse =
  /** status 200  */ GoalTaxPurpuse[];
export type QuestionnaireTaxPurposeListApiArg = {
  /** A search term. */
  search?: string;
};
export type QuestionnaireTaxPurposeRetrieveApiResponse =
  /** status 200  */ GoalTaxPurpuse;
export type QuestionnaireTaxPurposeRetrieveApiArg = {
  code: string;
};
export type SimulationJobHistoryListCreateApiResponse =
  /** status 200  */ SimulationJobTypeDict;
export type SimulationJobHistoryListCreateApiArg = void;
export type SimulationMarketDataFixedIncomeRetrieveApiResponse =
  /** status 200  */ SimMarketDataFixedIncome;
export type SimulationMarketDataFixedIncomeRetrieveApiArg = {
  assetClassId: string;
  simulationJobId: string;
};
export type SimulationQaReportCorrelationsCreateApiResponse =
  /** status 200  */ SimulationQaSummaryReport;
export type SimulationQaReportCorrelationsCreateApiArg = {
  simulationQaReportRequestBase: SimulationQaReportRequestBase;
};
export type SimulationQaReportReturnsNominalCreateApiResponse =
  /** status 200  */ SimulationQaReturnsYieldReport;
export type SimulationQaReportReturnsNominalCreateApiArg = {
  simulationQaSummaryReportRequestAssetClass: SimulationQaSummaryReportRequestAssetClass;
};
export type SimulationQaReportReturnsRealCreateApiResponse =
  /** status 200  */ SimulationQaWithCpiReport;
export type SimulationQaReportReturnsRealCreateApiArg = {
  simulationQaSummaryReportRequestAssetClass: SimulationQaSummaryReportRequestAssetClass;
};
export type SimulationQaReportSimAnalyzePathsCreateApiResponse =
  /** status 200  */ SimulationDataFilterOutput;
export type SimulationQaReportSimAnalyzePathsCreateApiArg = {
  simulationAnalyzePathsInput: SimulationAnalyzePathsInput;
};
export type SimulationQaReportSimDataFilterCreateApiResponse =
  /** status 200  */ SimulationDataFilterOutput;
export type SimulationQaReportSimDataFilterCreateApiArg = {
  simulationDataFilterInput: SimulationDataFilterInput;
};
export type SimulationQaReportSimPathCalcualtionDataCreateApiResponse =
  /** status 200  */ SimualtionPathCalculationDetails;
export type SimulationQaReportSimPathCalcualtionDataCreateApiArg = {
  simulationAnalyzePathCalcFieldsInput: SimulationAnalyzePathCalcFieldsInput;
};
export type SimulationQaReportSummaryCreateApiResponse =
  /** status 200  */ SimulationQaSummaryReport;
export type SimulationQaReportSummaryCreateApiArg = {
  simulationQaReportRequestBase: SimulationQaReportRequestBase;
};
export type SimulationQaReportYieldsCreateApiResponse =
  /** status 200  */ SimulationQaWithCpiReport;
export type SimulationQaReportYieldsCreateApiArg = {
  simulationQaSummaryReportRequestAssetClass: SimulationQaSummaryReportRequestAssetClass;
};
export type SimulationSettingInflationExpectedMeanListApiResponse =
  /** status 200  */ SettingInflationExpectedMean[];
export type SimulationSettingInflationExpectedMeanListApiArg = {
  id: string;
};
export type SimulationSettingInflationExpectedMeanCreateApiResponse =
  /** status 201  */ SettingInflationExpectedMean;
export type SimulationSettingInflationExpectedMeanCreateApiArg = {
  id: string;
  settingInflationExpectedMean: SettingInflationExpectedMean;
};
export type SimulationSettingInflationExpectedMeanRetrieveApiResponse =
  /** status 200  */ SettingInflationExpectedMean;
export type SimulationSettingInflationExpectedMeanRetrieveApiArg = {
  id: string;
};
export type SimulationSettingInflationExpectedMeanUpdateApiResponse =
  /** status 200  */ SettingInflationExpectedMean;
export type SimulationSettingInflationExpectedMeanUpdateApiArg = {
  id: string;
  settingInflationExpectedMean: SettingInflationExpectedMean;
};
export type SimulationSettingInflationExpectedMeanPartialUpdateApiResponse =
  /** status 200  */ SettingInflationExpectedMean;
export type SimulationSettingInflationExpectedMeanPartialUpdateApiArg = {
  id: string;
  patchedSettingInflationExpectedMean: PatchedSettingInflationExpectedMean;
};
export type SimulationSimJobYieldCurvesByPercentileListApiResponse =
  /** status 200  */ SimulationYieldCurveDetails[];
export type SimulationSimJobYieldCurvesByPercentileListApiArg = {
  percentile: number;
  simJobId: string;
};
export type SimulationSimJobYieldCurvesByYearListApiResponse =
  /** status 200  */ SimulationYieldCurveDetails[];
export type SimulationSimJobYieldCurvesByYearListApiArg = {
  simJobId: string;
  year: number;
};
export type SimulationSimYieldCurvesListApiResponse =
  /** status 200  */ YieldCurve[];
export type SimulationSimYieldCurvesListApiArg = {
  simJobId: string;
};
export type SimulationSimulartionGroupDetailsCreateApiResponse =
  /** status 200  */ SimulationGroupDetail;
export type SimulationSimulartionGroupDetailsCreateApiArg = void;
export type SimulationSimulationJobListApiResponse =
  /** status 200  */ SimulationJob[];
export type SimulationSimulationJobListApiArg = void;
export type SimulationSimulationJobRetrieveApiResponse =
  /** status 200  */ SimulationJob;
export type SimulationSimulationJobRetrieveApiArg = {
  /** A UUID string identifying this simulation job. */
  id: string;
};
export type SimulationSimulationJobGetCondensedSummaryStatisticsListApiResponse =
  /** status 200  */ SimulationJobCondensedSummaryStatistics[];
export type SimulationSimulationJobGetCondensedSummaryStatisticsListApiArg = {
  /** A UUID string identifying this simulation job. */
  id: string;
};
export type SimulationSimulationJobGetExtendedStatisticsRetrieveApiResponse =
  /** status 200  */ SimulationJobStatistics;
export type SimulationSimulationJobGetExtendedStatisticsRetrieveApiArg = {
  /** A UUID string identifying this simulation job. */
  id: string;
};
export type SimulationSimulationJobGetFiftyPercentileListApiResponse =
  /** status 200  */ SimulationJobResultAssetClassYearPercentile[];
export type SimulationSimulationJobGetFiftyPercentileListApiArg = {
  /** A UUID string identifying this simulation job. */
  id: string;
};
export type SimulationSimulationJobGetSummaryStatisticsListApiResponse =
  /** status 200  */ SimulationJobSummaryStatistics[];
export type SimulationSimulationJobGetSummaryStatisticsListApiArg = {
  /** A UUID string identifying this simulation job. */
  id: string;
};
export type SimulationSimulationJobAvailableSimulationGroupsListApiResponse =
  /** status 200  */ SimulationGroupApi[];
export type SimulationSimulationJobAvailableSimulationGroupsListApiArg = void;
export type SimulationSimulationJobLatestSimulationRetrieveApiResponse =
  /** status 200  */ SimulationJob;
export type SimulationSimulationJobLatestSimulationRetrieveApiArg = void;
export type SimulationSimulationJobAdminListApiResponse =
  /** status 200  */ SimulationJob[];
export type SimulationSimulationJobAdminListApiArg = void;
export type SimulationSimulationJobAdminCreateApiResponse =
  /** status 201  */ SimulationJob;
export type SimulationSimulationJobAdminCreateApiArg = {
  simulationJobCreate: SimulationJobCreate;
};
export type SimulationSimulationJobAdminRetrieveApiResponse =
  /** status 200  */ SimulationJob;
export type SimulationSimulationJobAdminRetrieveApiArg = {
  /** A UUID string identifying this simulation job. */
  id: string;
};
export type SimulationSimulationJobAdminUpdateApiResponse =
  /** status 200  */ SimulationJob;
export type SimulationSimulationJobAdminUpdateApiArg = {
  /** A UUID string identifying this simulation job. */
  id: string;
  simulationJob: SimulationJob;
};
export type SimulationSimulationJobAdminPartialUpdateApiResponse =
  /** status 200  */ SimulationJob;
export type SimulationSimulationJobAdminPartialUpdateApiArg = {
  /** A UUID string identifying this simulation job. */
  id: string;
  patchedSimulationJob: PatchedSimulationJob;
};
export type SimulationSimulationJobAdminPublishUpdateApiResponse =
  /** status 200  */ SimulationJob;
export type SimulationSimulationJobAdminPublishUpdateApiArg = {
  /** A UUID string identifying this simulation job. */
  id: string;
  simulationJobPublish: SimulationJobPublish;
};
export type SimulationSimulationModelTypeListApiResponse =
  /** status 200  */ SimualtionModelTypeDetail[];
export type SimulationSimulationModelTypeListApiArg = {
  id: string;
};
export type SimulationSimulationModelTypeRetrieveApiResponse =
  /** status 200  */ SimualtionModelTypeDetail;
export type SimulationSimulationModelTypeRetrieveApiArg = {
  id: string;
};
export type SimulationSimulationModelVersionListApiResponse =
  /** status 200  */ SimualtionModelVersionDetail[];
export type SimulationSimulationModelVersionListApiArg = {
  id: string;
};
export type SimulationSimulationOutputDebugFieldsListApiResponse =
  /** status 200  */ SimualtionOutputDebugFields[];
export type SimulationSimulationOutputDebugFieldsListApiArg = void;
export type SimulationSimulationSettingListApiResponse =
  /** status 200  */ SimulationSetting[];
export type SimulationSimulationSettingListApiArg = void;
export type SimulationSimulationSettingRetrieveApiResponse =
  /** status 200  */ SimulationSetting;
export type SimulationSimulationSettingRetrieveApiArg = {
  /** A UUID string identifying this simulation setting. */
  id: string;
};
export type SimulationSimulationSettingUpdateApiResponse =
  /** status 200  */ SimulationSetting;
export type SimulationSimulationSettingUpdateApiArg = {
  /** A UUID string identifying this simulation setting. */
  id: string;
  simulationSetting: SimulationSetting;
};
export type SimulationSimulationSettingPartialUpdateApiResponse =
  /** status 200  */ SimulationSetting;
export type SimulationSimulationSettingPartialUpdateApiArg = {
  /** A UUID string identifying this simulation setting. */
  id: string;
  patchedSimulationSetting: PatchedSimulationSetting;
};
export type FirmAssetClass = {
  id?: string;
  asset_class?: string;
  is_active?: boolean;
  created_at?: string;
  updated_at?: string;
};
export type FirmSecurityAssetClassPercentage = {
  id?: string;
  firm_asset_class?: FirmAssetClass;
  percentage?: number;
  is_active?: boolean;
};
export type FirmSecurity = {
  id?: string;
  firm: string;
  name: string;
  ticker?: string | null;
  cusip: string | null;
  asset_classes?: FirmSecurityAssetClassPercentage[];
  is_active?: boolean;
};
export type AccountHoldingIntegrated = {
  id?: string;
  firm_security: FirmSecurity;
  description?: string | null;
  shares?: number;
  market_value?: number;
  cost_basis?: number;
  ui_key?: string;
};
export type AccountTaxType = {
  id?: string;
  code: string;
  name: string;
  desc: string;
  is_taxable?: boolean;
  is_tax_deferred?: boolean;
  is_tax_exempt?: boolean;
  tax_free_withdrawal?: boolean;
  is_active?: boolean;
  can_have_employer_match?: boolean;
  max_contribution_available?: boolean;
  sort_order: number;
  show_on_screen?: boolean;
  asset_tax_category?: string | null;
};
export type AccountType = {
  id?: string;
  code: string;
  name: string;
  desc: string;
  tax_type: AccountTaxType;
  is_active?: boolean;
  sort_order: number;
  show_on_screen?: boolean;
};
export type TaxTypeRulesDetail = {
  id?: string;
  tax_rules_ds: string;
  tax_type_name?: string;
  flow_type: string;
  family_status: string;
  desc: string;
  start_age_limit?: number;
  end_age_limit?: number;
  is_calculation?: boolean;
  amount?: number;
  percentage?: number;
};
export type AlResultAssetType = {
  id?: string;
  code: string;
};
export type PlanCashFlowResult = {
  plan_result?: string;
  year: number;
  inflow_total: number;
  expenditure_total: number;
  distribution_total: number;
};
export type ModuleAllocationResult = {
  id?: string;
  module_id?: string;
  asset_class_level4_id: string;
  percentage: number;
};
export type ModuleResult = {
  id: string;
  plan_result?: string;
  module_name: string;
  module_year: number;
  total_amount_invested: number;
  allocations?: ModuleAllocationResult[];
};
export type GoalStatusEnum = "success" | "partial" | "failure" | "disabled";
export type GoalPeriodResult = {
  goal_result?: string;
  period_index: number;
  year: number;
  amount_requested_without_inflation: number;
  amount_requested_with_inflation: number;
  amount_funded_without_inflation: number;
  amount_funded_with_inflation: number;
  amount_remaining_without_inflation: number;
  amount_remaining_with_inflation: number;
};
export type GoalPeriodCurrentAssetResult = {
  asset_result_id: string;
  goal_result?: string;
  period_index: number;
  year: number;
  current_asset_amount_contributed?: number | null;
  current_asset_amount_contributed_with_inflation?: number | null;
  current_asset_amount_contributed_without_inflation?: number | null;
  goal_amount_funded_with_inflation_savings?: number;
  goal_amount_funded_with_inflation_balance?: number;
  goal_amount_funded_without_inflation_savings?: number;
  goal_amount_funded_without_inflation_balance?: number;
  goal_amount_funded_with_inflation?: number;
  goal_amount_funded_without_inflation?: number;
};
export type GoalResult = {
  plan_result?: string;
  base_goal_id: string;
  base_goal_priority: number;
  base_goal_start_date: string;
  base_goal_end_date: string;
  base_goal_name: string;
  pii_base_goal_name: string;
  input_annual_amount_without_inflation: number;
  input_total_amount_without_inflation: number;
  amount_remaining_without_inflation: number;
  amount_remaining_with_inflation: number;
  goal_annual_amount_met_hint?: number | null;
  goal_status: GoalStatusEnum;
  period_results?: GoalPeriodResult[];
  asset_results?: GoalPeriodCurrentAssetResult[];
};
export type AssetAllocationByModuleResult = {
  asset_result_id?: string;
  module_id: string;
  dollars_contributed: number;
  percentage_contributed: number;
};
export type AssetAllocationByAssetClassResult = {
  asset_result_id?: string;
  asset_class_level4_id: string;
  proposed_dollars: number;
  proposed_percentage: number;
  current_dollars: number;
  current_percentage: number;
};
export type SingleAssetAllocation = {
  asset_result_id?: string;
  module_id: string;
  equity_percentage: number;
  fixed_income_percentage: number;
  cash_percentage: number;
};
export type AssetResult = {
  id: string;
  plan_result?: string;
  base_asset_type: string;
  base_asset_id: string;
  pii_base_asset_name: string;
  portfolio_modules_available: boolean;
  total_pv_amount: number;
  surplus_amount: number;
  surplus_amount_deflated: number;
  savings_next_year: number;
  allocation_by_module_results?: AssetAllocationByModuleResult[];
  allocation_by_asset_class_results?: AssetAllocationByAssetClassResult[];
  goal_results?: GoalPeriodCurrentAssetResult[];
  single_portfolio_allocation?: SingleAssetAllocation | null;
};
export type DeRiskResult = {
  plan_result?: string;
  portfolio_module: string;
  portfolio_module_name: string;
  equity_weight?: number;
};
export type PlanYearStats = {
  plan_result?: string;
  year: number;
  period: number;
  total_net_worth: number;
  total_net_worth_deflated: number;
  beginning_total_portfolio_value: number;
  beginning_total_portfolio_value_deflated: number;
  ending_total_portfolio_value: number;
  ending_total_portfolio_value_deflated: number;
};
export type PlanResultRiskStatResult = {
  risk_stat_container?: string;
  risk_stat_type_id: string;
  risk_stat_value?: number;
  risk_stat_percentile?: number | null;
  extra_data?: any | null;
};
export type PlanResultRiskStatScenarioResult = {
  risk_stat_container?: string;
  scenario_id: string;
  observed_return?: number;
};
export type PlanResultRiskContainer = {
  plan_result?: string;
  fee_pct_annual?: number;
  risk_stat_results: PlanResultRiskStatResult[];
  historic_results: PlanResultRiskStatScenarioResult[];
};
export type PlanResult = {
  id?: string;
  created_at?: string;
  root: string;
  result_type: string;
  plan_score: number;
  outflow_total: number;
  outflow_total_deflated: number;
  outflow_pre_inflation: number;
  outflow_total_goal_amount_inflated: number;
  outflow_inflation_adjustment: number;
  outflow_inflation_adjustment_without_inflation?: number;
  outflow_withdrawl_taxes: number;
  outflow_withdrawl_taxes_deflated: number;
  inflow_total: number;
  inflow_total_deflated?: number;
  inflow_current_assets: number;
  inflow_nominal_investment_return: number;
  inflow_nominal_investment_return_deflated?: number;
  inflow_savings_other_inflows_net_tax: number;
  inflow_savings_other_inflows_net_tax_deflated: number;
  inflow_social_security_other_income_net_tax: number;
  inflow_social_security_other_income_net_tax_deflated?: number;
  inflow_real_estate_net_sale_proceeds: number;
  inflow_real_estate_net_sale_proceeds_deflated: number;
  taxable_surplus_total: number;
  taxable_surplus_total_deflated?: number;
  taxexempt_surplus_total: number;
  taxexempt_surplus_total_deflated?: number;
  unfunded_goals_total: number;
  unfunded_goals_total_deflated: number;
  social_security_add_income_surplus_total: number;
  social_security_add_income_surplus_total_deflated?: number;
  real_estate_surplus_total?: number;
  real_estate_surplus_total_deflated?: number;
  surplus_deficit_total: number;
  surplus_deficit_total_deflated: number;
  initial_net_worth: number;
  cap_gains_current_invested_amount: number;
  cap_gains_cg_taxes_paid: number;
  cap_gains_after_tax_portfolio_balance: number;
  risk_stats_equity_weight: number;
  cash_flow_results: PlanCashFlowResult[];
  modules: ModuleResult[];
  goal_results: GoalResult[];
  asset_results: AssetResult[];
  stress_tests?: string[];
  derisking_results: DeRiskResult[];
  year_stats: PlanYearStats[];
  risk_stat_containers: PlanResultRiskContainer[];
};
export type ActionPlanSavings = {
  base_asset_id: string;
  pii_base_asset_name: string;
  savings_next_year: number;
};
export type ActionPlanGoalAsset = {
  asset_result_id: string;
  pii_base_asset_name: string;
  current_asset_amount_contributed: number;
};
export type ActionPlanGoal = {
  pii_base_goal_name: string;
  base_goal_id: string;
  goal_asset_res: ActionPlanGoalAsset[];
  amount_next_year: number;
};
export type ActionPlanPlanResult = {
  savings_next_year: ActionPlanSavings[];
  goals_next_year: ActionPlanGoal[];
};
export type SimulationJobResultAssetClassFrontendDisclosure = {
  asset_class4?: string;
  output_real_return_mean?: number | null;
  output_real_return_sigma?: number | null;
  output_nominal_return_mean?: number | null;
  output_nominal_return_sigma?: number | null;
  created_at?: string;
  updated_at?: string;
};
export type SimulationJobDisclosure = {
  id?: string;
  asset_class_results: SimulationJobResultAssetClassFrontendDisclosure[];
  created_at?: string;
  updated_at?: string;
};
export type StressTestGoalData = {
  stress_scenario?: string;
  base_goal_id: string;
  base_goal_priority: number;
  base_goal_start_date: string;
  base_goal_end_date: string;
  base_goal_name: string;
  pii_base_goal_name: string;
  input_annual_amount_without_inflation: number;
  input_total_amount_without_inflation: number;
  amount_remaining_without_inflation: number;
  amount_remaining_with_inflation: number;
  goal_annual_amount_met_hint?: number | null;
  goal_status: GoalStatusEnum;
};
export type StressTestData = {
  stress_scenario?: string;
  period: number;
  year: number;
  year_label: string;
  observed_inflation_rate: number;
  observed_ten_year_treasury_yield: number;
  observed_ten_year_treasury_return: number;
  observed_large_cap_return: number;
  beginning_total_portfolio_value?: number;
  beginning_total_portfolio_value_deflated?: number;
  return_on_asset_dollar?: number;
  return_on_asset_dollar_deflated?: number;
  return_on_asset?: number;
  return_on_asset_deflated?: number;
  cash_in_portfolio?: number;
  cash_in_portfolio_deflated?: number;
  cash_out_portfolio?: number;
  cash_out_portfolio_deflated?: number;
  net_worth_savings?: number;
  net_worth_savings_deflated?: number;
  net_worth_cash_flow?: number;
  net_worth_cash_flow_deflated?: number;
  net_worth_goals_met?: number;
  net_worth_goals_met_deflated?: number;
  net_worth_goals_not_met?: number;
  net_worth_goals_not_met_deflated?: number;
  ending_total_portfolio_value?: number;
  ending_total_portfolio_value_deflated?: number;
  total_net_worth: number;
  total_net_worth_deflated?: number;
  beginning_taxable_portfolio_balance: number;
  beginning_taxable_portfolio_balance_deflated?: number;
  social_security_pension_etc: number;
  social_security_pension_etc_deflated?: number;
  taxable_dividend_interest: number;
  taxable_dividend_interest_deflated?: number;
  taxable_withdrawl: number;
  taxable_withdrawl_deflated?: number;
  capital_gains: number;
  capital_gains_deflated?: number;
  net_rental_income: number;
  net_rental_income_deflated?: number;
  total_taxable_income: number;
  total_taxable_income_deflated?: number;
  income_taxes_paid: number;
  income_taxes_paid_deflated?: number;
  capital_gains_taxes_paid: number;
  capital_gains_taxes_paid_deflated?: number;
  effective_tax_rate: number;
  effective_tax_rate_deflated?: number;
  total_aftertax_income_and_withdrawls: number;
  total_aftertax_income_and_withdrawls_deflated?: number;
  retirement_spending: number;
  retirement_spending_deflated?: number;
  medical_expenses: number;
  medical_expenses_deflated?: number;
  other_goals_funded: number;
  other_goals_funded_deflated?: number;
  goals_met: number;
  goals_met_deflated?: number;
  goals_not_met: number;
  goals_not_met_deflated?: number;
  real_estate: number;
  mortgage: number;
  real_estate_cost_basis: number;
  real_estate_income_taxes?: number;
  real_estate_income_taxes_deflated?: number;
  real_estate_annual_total_payment: number;
  rental_payment?: number;
  rental_payment_deflated?: number;
  rental_expenses?: number;
  rental_expenses_deflated?: number;
  rental_depreciation?: number;
  rental_income?: number;
  rental_income_deflated?: number;
  aftertax_rental_income?: number;
  aftertax_rental_income_deflated?: number;
  real_estate_cap_gains_taxes: number;
  real_estate_cap_gains_taxes_deflated?: number;
  real_estate_cap_gains: number;
  real_estate_cap_gains_deflated?: number;
  real_estate_net_sale_proceeds: number;
  real_estate_net_sale_proceeds_deflated?: number;
  real_estate_cashflow: number;
  real_estate_cashflow_deflated?: number;
};
export type StressTestContainer = {
  plan_result: string;
  stress_scenario_type: string;
  goal_data: StressTestGoalData[];
  stress_test_data: StressTestData[];
  surplus_deficit_total?: number;
};
export type PlanResultStress = {
  stress_tests: StressTestContainer[];
};
export type PlanResultType = {
  id?: string;
  code: string;
  label: string;
  description: string;
  run_stress_tests?: boolean;
  show_in_list?: boolean;
  sort_order?: number;
};
export type PlanResultRootSimulationSerilizer = {
  is_static?: boolean;
};
export type PlanResultRoot = {
  id?: string;
  created_at?: string;
  results: PlanResult[];
  simulation?: PlanResultRootSimulationSerilizer;
  progress_monitoring_flag?: boolean;
};
export type JobStatusEnum = "P" | "R" | "Q" | "C" | "E";
export type PlanResultJob = {
  id?: string;
  root: string;
  result_type: string;
  stress_scenario_type: string;
  job_id: string;
  status: JobStatusEnum;
  created_at?: string;
  updated_at?: string;
};
export type PlanResultRootStandalone = {
  id?: string;
  created_at?: string;
  results: string[];
  simulation?: string | null;
  progress_monitoring_flag?: boolean;
};
export type PlanPublishedDataModel = {
  published_plan_type: PlanResultType;
};
export type PlanPriorityStatsSerialzier = {
  total: number;
  passing: number;
  percentage: number;
};
export type PlanStats = {
  date: string;
  needs: PlanPriorityStatsSerialzier;
  wants: PlanPriorityStatsSerialzier;
  dreams: PlanPriorityStatsSerialzier;
};
export type PlanResultProgress = {
  progress_monitoring_runs: PlanResultRootStandalone[];
  target_strategy: PlanPublishedDataModel;
  chart_results: PlanStats[];
  render_results: PlanResultRoot;
};
export type StressScenarioType = {
  id?: string;
  code: string;
  label: string;
  sort_order?: number;
};
export type AssetClassGicsSubIndustryWeights = {
  id?: string;
  ac_level4?: string;
  gics_sub_industry_group?: string;
  weight?: number;
  created_at?: string;
  updated_at?: string;
};
export type AssetClassList = {
  id: string;
  code: string;
  name: string;
  desc: string;
  display_name: string;
  sort_order: number;
};
export type BoundaryTypeEnum = 0 | 1;
export type Boundary = {
  id?: string;
  boundary_type: BoundaryTypeEnum;
  year: number;
  value: number;
  manual?: boolean;
  created_at?: string;
  updated_at?: string;
};
export type PatchedBoundary = {
  id?: string;
  boundary_type?: BoundaryTypeEnum;
  year?: number;
  value?: number;
  manual?: boolean;
  created_at?: string;
  updated_at?: string;
};
export type BoundaryGroup = {
  id?: string;
  name: string;
  boundaries?: Boundary[];
  created_at?: string;
  updated_at?: string;
};
export type PatchedBoundaryGroup = {
  id?: string;
  name?: string;
  boundaries?: Boundary[];
  created_at?: string;
  updated_at?: string;
};
export type FirmAssetClassImplementation = {
  id?: string;
  firm?: string;
  asset_class?: string;
  target_ticker?: string | null;
  target_name: string;
  created_at?: string;
  updated_at?: string;
};
export type PatchedFirmAssetClassImplementation = {
  id?: string;
  firm?: string;
  asset_class?: string;
  target_ticker?: string | null;
  target_name?: string;
  created_at?: string;
  updated_at?: string;
};
export type AssetClassLevel1 = {
  id?: string;
  code?: string;
  name?: string;
  display_short_name?: string;
  desc?: string;
  is_equity?: boolean;
  is_fixed_inc?: boolean;
  is_comm?: boolean;
  is_cash?: boolean;
  sort_order?: number;
  color_code?: string;
};
export type AssetClassLevel2 = {
  id?: string;
  code?: string;
  name?: string;
  desc?: string;
  ac_level1?: AssetClassLevel1;
  is_us?: boolean;
  is_intl?: boolean;
  sort_order?: number;
  color_code?: string;
};
export type AssetClassLevel3 = {
  id?: string;
  code?: string;
  name?: string;
  desc?: string;
  ac_level2?: AssetClassLevel2;
  sort_order?: number;
  color_code?: string;
};
export type AssetClassLevel4 = {
  id?: string;
  code?: string;
  name?: string;
  desc?: string;
  display_name?: string;
  display_short_name?: string;
  ac_level3?: AssetClassLevel3;
  is_required?: boolean;
  is_required_for_asset_allocation?: boolean;
  ticker?: string | null;
  is_active?: boolean;
  is_simplified_asset_class?: boolean;
  show_in_list?: boolean;
  useable_in_portfolios?: boolean;
  sort_order?: number;
  color_code?: string;
  is_driver?: boolean;
};
export type FirmAssetClassDetail = {
  id?: string;
  asset_class?: AssetClassLevel4;
  is_active?: boolean;
  created_at?: string;
  updated_at?: string;
};
export type PatchedFirmAssetClassDetail = {
  id?: string;
  asset_class?: AssetClassLevel4;
  is_active?: boolean;
  created_at?: string;
  updated_at?: string;
};
export type SimulationBehaviorCash = {
  id?: string;
  name: string;
  distribution_trim_left: number;
  distribution_trim_right: number;
  absolute_lower_boundary: number;
  absolute_upper_boundary: number;
  max_step: number;
  min_yield: number;
  sigma: number;
  new_model_intercept: number;
  new_model_beta: number;
  crisis_mode_returns?: number;
  model_slope_override?: number | null;
  model_slope_override_rationale?: string | null;
  created_at?: string;
  updated_at?: string;
};
export type PatchedSimulationBehaviorCash = {
  id?: string;
  name?: string;
  distribution_trim_left?: number;
  distribution_trim_right?: number;
  absolute_lower_boundary?: number;
  absolute_upper_boundary?: number;
  max_step?: number;
  min_yield?: number;
  sigma?: number;
  new_model_intercept?: number;
  new_model_beta?: number;
  crisis_mode_returns?: number;
  model_slope_override?: number | null;
  model_slope_override_rationale?: string | null;
  created_at?: string;
  updated_at?: string;
};
export type SimulationBehaviorEquity = {
  id?: string;
  name: string;
  trend_growth: number;
  trend_initial: number;
  trend_start_date: string;
  boundary_group?: BoundaryGroup;
  intercept: number;
  slope: number;
  new_model_intercept?: number;
  new_model_beta?: number;
  new_model_beta2?: number;
  updated_at?: string;
  assetclasslevel4_set?: string[];
};
export type PatchedSimulationBehaviorEquity = {
  id?: string;
  name?: string;
  trend_growth?: number;
  trend_initial?: number;
  trend_start_date?: string;
  boundary_group?: BoundaryGroup;
  intercept?: number;
  slope?: number;
  new_model_intercept?: number;
  new_model_beta?: number;
  new_model_beta2?: number;
  updated_at?: string;
  assetclasslevel4_set?: string[];
};
export type SimulationBehaviorFixedIncomeSpread = {
  id?: string;
  name: string;
  mu_intercept: number;
  mu_slope: number;
  model_slope_override?: number | null;
  model_slope_override_rationale?: string | null;
  distribution_trim_left: number;
  distribution_trim_right: number;
  absolute_lower_boundary: number;
  absolute_upper_boundary: number;
  crisis_mode_returns?: number;
  spread_boundary_group?: BoundaryGroup;
  created_at?: string;
  updated_at?: string;
  assetclasslevel4_set?: string[];
};
export type PatchedSimulationBehaviorFixedIncomeSpread = {
  id?: string;
  name?: string;
  mu_intercept?: number;
  mu_slope?: number;
  model_slope_override?: number | null;
  model_slope_override_rationale?: string | null;
  distribution_trim_left?: number;
  distribution_trim_right?: number;
  absolute_lower_boundary?: number;
  absolute_upper_boundary?: number;
  crisis_mode_returns?: number;
  spread_boundary_group?: BoundaryGroup;
  created_at?: string;
  updated_at?: string;
  assetclasslevel4_set?: string[];
};
export type SimulationBehaviorInflation = {
  id?: string;
  name: string;
  absolute_lower_boundary: number;
  absolute_upper_boundary: number;
  created_at?: string;
  updated_at?: string;
};
export type PatchedSimulationBehaviorInflation = {
  id?: string;
  name?: string;
  absolute_lower_boundary?: number;
  absolute_upper_boundary?: number;
  created_at?: string;
  updated_at?: string;
};
export type RecieveEvent = {
  lambda_task_id: string;
  event: string;
  job_id: string;
  doc_id?: string;
  exception_message?: string;
  is_final?: boolean;
};
export type AssetClassRegressionModelFe = {
  intercept: number;
  beta: number;
  r_squared: number;
  mu: number;
  sigma: number;
};
export type RegressionField = {
  name: string;
  data: {
    [key: string]: any;
  };
};
export type AssetClassRegressionInputs = {
  description: string;
  list_of_fields: RegressionField[];
};
export type AssetClassRegressionDetails = {
  data: {
    [key: string]: any;
  };
};
export type AssetClassRegressionResponse = {
  model_stats: AssetClassRegressionModelFe;
  regression_input_data: AssetClassRegressionInputs[];
  regression_results: AssetClassRegressionDetails;
};
export type DataSource = {
  id?: string;
  name: string;
  description?: string | null;
  url?: string | null;
};
export type GenericCmaGroup = {
  id?: string;
  data_source: DataSource;
  name: string;
  series_id: string;
  start_year: number;
};
export type GenericCmaData = {
  id?: string;
  group?: GenericCmaGroup;
  date: string;
  value: number;
};
export type GenModelsAssetClassRunStatus = {
  status: string;
  asset_class_id: string;
};
export type GenModelsAssetClassList = {
  asset_class_id: string[];
};
export type YieldCurveTypeDataPoint = {
  id?: string;
  label: string;
};
export type YieldCurveSetsPostion = {
  id?: string;
  label?: string;
  position?: number;
  month?: number;
  year?: number;
};
export type YieldCurveIndexDataPoint = {
  id?: string;
  type: YieldCurveTypeDataPoint;
  position: YieldCurveSetsPostion;
};
export type YieldCurveRate = {
  index: YieldCurveIndexDataPoint;
  yield_date: string;
  rate: number;
};
export type ReturnScenarios = {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
  is_active?: boolean;
  start_date?: string;
  end_date?: string;
  show_in_graphs?: boolean;
};
export type DisplayTypeEnum2 = "PERCENTAGE" | "INTEGER" | "FLOAT";
export type RiskStatTypeCalculation = {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
  sort_order?: number;
};
export type RiskStatType = {
  id?: string;
  code?: string;
  name?: string;
  display_type?: DisplayTypeEnum2;
  calculation_type?: RiskStatTypeCalculation;
  sort_order?: number;
  is_frontend_derrived?: boolean;
  use_for_asset_allocation?: boolean;
  use_for_planning_tool?: boolean;
};
export type DataTypeEnum = 1 | 2;
export type SAndPCmaGroup = {
  id?: string;
  name: string;
  data_type: DataTypeEnum;
  spice_no_dividend_column?: boolean;
  spice_total_return_key: string;
  spice_price_return_key: string;
  spice_index_code: string;
  spice_index_name: string;
  currency_code: string;
  country_region_code: string;
};
export type SAndPDataEquity = {
  id?: string;
  group?: SAndPCmaGroup;
  date: string;
  gics_sector_name?: string | null;
  gics_sector_code?: string | null;
  dji_industry_name?: string | null;
  dji_industry_code?: string | null;
  index_level_total_return?: number | null;
  index_level_price_return?: number | null;
  adjusted_market_cap?: number | null;
  adjusted_divisior?: number | null;
  index_dividend?: number | null;
  trailing_pe?: number | null;
  one_year_forward_pe?: number | null;
  two_year_forward_pe?: number | null;
  one_year_trailing_pe?: number | null;
  price_to_book?: number | null;
  price_to_cash_flow?: number | null;
  price_to_sales?: number | null;
  return_on_equity?: number | null;
  mtd_index_dividend?: number | null;
  dividend_yield?: number | null;
  indicated_dividend_yield?: number | null;
  mtd_total_return?: number | null;
};
export type SAndPDataFixedIncome = {
  id?: string;
  group?: SAndPCmaGroup;
  date: string;
  gics_sector_name?: string | null;
  gics_sector_code?: string | null;
  dji_industry_name?: string | null;
  dji_industry_code?: string | null;
  index_level_total_return?: number | null;
  index_level_price_return?: number | null;
  market_cap?: number | null;
  total_par_value?: number | null;
  days_to_maturity?: number | null;
  weighted_average_coupon?: number | null;
  yield_to_maturity?: number | null;
  yield_to_worst?: number | null;
  modified_duration?: number | null;
  effective_duration?: number | null;
  convexity?: number | null;
  option_adjusted_spread?: number | null;
  option_adjusted_yield?: number | null;
  sandp_rating?: string | null;
  moody_rating?: string | null;
  fitch_rating?: string | null;
  mtd_total_return?: number | null;
};
export type YieldCurvePosition = {
  id?: string;
  label?: string;
  position?: number;
};
export type IncompleteReasonEnum = "success" | "email_verify" | "two_factor";
export type CustomTokenObtainPair = {
  email: string;
  password: string;
  access?: string;
  refresh?: string;
  is_complete?: boolean;
  incomplete_reason?: IncompleteReasonEnum;
};
export type AcceptInvitation = {
  secret_string: string;
  first_name: string;
  last_name: string;
  password: string;
};
export type CustomTokenRefresh = {
  refresh: string;
  access?: string;
};
export type RequestResetPassword = {
  email: string;
  status?: string;
};
export type ResetPassword = {
  token: string;
  password: string;
  status?: string;
};
export type Firm = {
  id?: string;
  name: string;
  logo?: string | null;
  debug?: boolean;
  allow_caravel_support?: boolean;
  custom_disclosure?: string | null;
  max_fee?: number;
  stripe_customer_id?: string;
  stripe_subscription_id?: string;
  subscription_status?: string;
  trial_optimizations_remaining?: number;
  trial_portfolio_modules_remaining?: number;
  is_paid?: boolean;
  is_trial?: boolean;
};
export type User = {
  id?: string;
  firm?: Firm;
  email?: string;
  first_name?: string;
  last_name?: string;
  old_password?: string;
  new_password?: string;
  profile_image?: string | null;
  email_confirmed?: boolean;
  intercom_hmac?: string;
  two_factor_enabled?: boolean;
  last_active?: string;
  role?: string;
  work_phone_num?: string | null;
  cell_phone_num?: string | null;
  address?: string | null;
};
export type PatchedUser = {
  id?: string;
  firm?: Firm;
  email?: string;
  first_name?: string;
  last_name?: string;
  old_password?: string;
  new_password?: string;
  profile_image?: string | null;
  email_confirmed?: boolean;
  intercom_hmac?: string;
  two_factor_enabled?: boolean;
  last_active?: string;
  role?: string;
  work_phone_num?: string | null;
  cell_phone_num?: string | null;
  address?: string | null;
};
export type PatchedFirm = {
  id?: string;
  name?: string;
  logo?: string | null;
  debug?: boolean;
  allow_caravel_support?: boolean;
  custom_disclosure?: string | null;
  max_fee?: number;
  stripe_customer_id?: string;
  stripe_subscription_id?: string;
  subscription_status?: string;
  trial_optimizations_remaining?: number;
  trial_portfolio_modules_remaining?: number;
  is_paid?: boolean;
  is_trial?: boolean;
};
export type FreeTrialStart = {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  fnra_number?: number | null;
  firm_name: string;
};
export type UserTwoFactorAuth = {
  code: string;
};
export type PatchedUserTwoFactorAuth = {
  code?: string;
};
export type UserTwoFactorSettings = {
  code?: string;
  verification?: boolean;
  deactivate?: boolean;
  friendly_url?: string;
};
export type PatchedUserTwoFactorSettings = {
  code?: string;
  verification?: boolean;
  deactivate?: boolean;
  friendly_url?: string;
};
export type UserEmailConfirmation = {
  token: number;
};
export type PatchedUserEmailConfirmation = {
  token?: number;
};
export type ActivateBilling = {
  stripe_customer_id?: string;
  stripe_subscription_id?: string;
  stripe_invoice_amount_due?: number;
  stripe_invoice_payment_intent_client_secret?: string;
};
export type License = {
  role_id: number;
  role_code: string;
  role_name: string;
  lookup_key: string;
  product_id: string;
  active_price_id: string;
  used_qty: number;
  paid_qty: number;
  effective_qty: number;
};
export type LicenseRequest = {
  role_code: string;
  seats: number;
};
export type ActivateBillingV2 = {
  licenses: LicenseRequest[];
  stripe_customer_id?: string;
  stripe_subscription_id?: string;
  stripe_invoice_amount_due?: number;
  stripe_invoice_payment_intent_client_secret?: string;
};
export type BillingCheckStatus = {
  subscription_status?: string;
};
export type TypeEnum =
  | "acss_debit"
  | "affirm"
  | "afterpay_clearpay"
  | "alipay"
  | "au_becs_debit"
  | "bacs_debit"
  | "bancontact"
  | "blik"
  | "boleto"
  | "card"
  | "card_present"
  | "cashapp"
  | "customer_balance"
  | "eps"
  | "fpx"
  | "giropay"
  | "grabpay"
  | "ideal"
  | "interac_present"
  | "klarna"
  | "konbini"
  | "link"
  | "oxxo"
  | "p24"
  | "paynow"
  | "paypal"
  | "pix"
  | "promptpay"
  | "sepa_debit"
  | "sofort"
  | "us_bank_account"
  | "wechat_pay"
  | "zip";
export type PaymentMethod = {
  id?: string;
  type?: TypeEnum;
  card?: any | null;
  created?: string | null;
};
export type BillingReasonEnum =
  | "automatic_pending_invoice_item_invoice"
  | "manual"
  | "subscription"
  | "subscription_create"
  | "subscription_cycle"
  | "subscription_threshold"
  | "subscription_update"
  | "upcoming";
export type StripeInvoiceStatusEnum =
  | "draft"
  | "open"
  | "paid"
  | "uncollectible"
  | "void";
export type StripeInvoice = {
  id?: string;
  amount_due?: string;
  amount_paid?: string | null;
  amount_remaining?: string | null;
  attempt_count?: number;
  billing_reason?: BillingReasonEnum;
  due_date?: string | null;
  hosted_invoice_url?: string;
  invoice_pdf?: string;
  paid?: boolean;
  status?: StripeInvoiceStatusEnum;
  created?: string | null;
};
export type StripeCustomer = {
  id?: string;
  payment_methods?: PaymentMethod[];
  email?: string;
  name?: string;
  invoices?: StripeInvoice[];
  created?: string | null;
};
export type StripeSubscriptionStatusEnum =
  | "active"
  | "canceled"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "trialing"
  | "unpaid";
export type StripePrice = {
  id?: string;
  lookup_key?: string | null;
  unit_amount?: number | null;
  currency?: string;
  human_readable_price?: string;
};
export type StripeSubscriptionItems = {
  id?: string;
  price?: StripePrice;
  quantity?: number | null;
};
export type StripeSubscription = {
  id?: string;
  customer?: StripeCustomer;
  billing_cycle_anchor?: string | null;
  cancel_at?: string | null;
  canceled_at?: string | null;
  current_period_end?: string;
  status?: StripeSubscriptionStatusEnum;
  items?: StripeSubscriptionItems[];
};
export type CmpasModule = {
  id?: string;
  code: string;
  short_id: string;
  name: string;
  description?: string | null;
  is_active?: boolean;
  sort_order?: number;
};
export type PatchedCmpasModule = {
  id?: string;
  code?: string;
  short_id?: string;
  name?: string;
  description?: string | null;
  is_active?: boolean;
  sort_order?: number;
};
export type PageSectionBlockType = {
  id?: string;
  code: string;
  display_label: string;
};
export type PageSectionBlockHierarchy = {
  id?: string;
  block_type?: PageSectionBlockType;
  content?: string | null;
  is_active?: boolean;
  sort_order?: number;
};
export type PageSectionHeirarchy = {
  id?: string;
  code?: string;
  title: string;
  section_blocks?: PageSectionBlockHierarchy[];
  is_active?: boolean;
  sort_order?: number;
};
export type CmpasModuleSectionDetailHierarchy = {
  id?: string;
  code: string;
  name: string;
  description?: string | null;
  is_active?: boolean;
  page_sections?: PageSectionHeirarchy[];
  front_end_url?: string | null;
  full_front_end_url?: {
    [key: string]: any;
  };
  sort_order?: number;
};
export type CmpasModuleHierarchy = {
  id?: string;
  code: string;
  short_id: string;
  name: string;
  description?: string | null;
  module_sections?: CmpasModuleSectionDetailHierarchy[];
  is_active?: boolean;
  sort_order?: number;
};
export type CmpasModuleSectionDetail = {
  id?: string;
  code: string;
  name: string;
  description?: string | null;
  cmpas_module: string;
  is_active?: boolean;
  front_end_url?: string | null;
  full_front_end_url?: {
    [key: string]: any;
  };
  sort_order?: number;
};
export type PatchedCmpasModuleSectionDetail = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  cmpas_module?: string;
  is_active?: boolean;
  front_end_url?: string | null;
  full_front_end_url?: {
    [key: string]: any;
  };
  sort_order?: number;
};
export type FirmMember = {
  id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  profile_image?: string | null;
  email_confirmed?: boolean;
  intercom_hmac?: string;
  last_active?: string;
  role: string;
  system_roles?: string[];
};
export type PatchedFirmMember = {
  id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  profile_image?: string | null;
  email_confirmed?: boolean;
  intercom_hmac?: string;
  last_active?: string;
  role?: string;
  system_roles?: string[];
};
export type Invitation = {
  id?: string;
  email: string;
  role: string;
  created_at?: string;
  updated_at?: string;
};
export type PatchedInvitation = {
  id?: string;
  email?: string;
  role?: string;
  created_at?: string;
  updated_at?: string;
};
export type PageSection = {
  id?: string;
  code?: string;
  title: string;
  page: string;
  is_active?: boolean;
  sort_order?: number;
};
export type PatchedPageSection = {
  id?: string;
  code?: string;
  title?: string;
  page?: string;
  is_active?: boolean;
  sort_order?: number;
};
export type PageSectionBlock = {
  id?: string;
  page_section: string;
  block_type?: string;
  content?: string | null;
  is_active?: boolean;
  sort_order?: number;
};
export type PatchedPageSectionBlock = {
  id?: string;
  page_section?: string;
  block_type?: string;
  content?: string | null;
  is_active?: boolean;
  sort_order?: number;
};
export type PatchedPageSectionBlockType = {
  id?: string;
  code?: string;
  display_label?: string;
};
export type CmpasModuleSection = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  cmpas_module?: string;
};
export type RoleAllowedCmpasSections = {
  id?: string;
  role?: string;
  cmpas_module_section?: CmpasModuleSection;
};
export type RoleHierarchy = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  role_type?: string | null;
  module_access?: CmpasModule[];
  is_active?: boolean;
  sort_order?: number;
};
export type RoleType = {
  id?: string;
  code?: string;
  name: string;
  description?: string | null;
  is_primary?: boolean;
  is_operational?: boolean;
  is_admin?: boolean;
  is_support?: boolean;
  is_active?: boolean;
  show_in_dropdown?: boolean;
  show_for_client_firms?: boolean;
  sort_order?: number;
};
export type PatchedRoleType = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  is_primary?: boolean;
  is_operational?: boolean;
  is_admin?: boolean;
  is_support?: boolean;
  is_active?: boolean;
  show_in_dropdown?: boolean;
  show_for_client_firms?: boolean;
  sort_order?: number;
};
export type Role = {
  id?: string;
  code: string;
  name: string;
  description?: string | null;
  role_type?: string | null;
  module_access?: string[];
  is_active?: boolean;
  sort_order?: number;
  stripe_price_code?: string | null;
};
export type PatchedRole = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  role_type?: string | null;
  module_access?: string[];
  is_active?: boolean;
  sort_order?: number;
  stripe_price_code?: string | null;
};
export type DisclosureSubCategory = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  code: string;
  name: string;
  description?: string | null;
  is_active?: boolean;
  content?: string;
  category: string;
};
export type FirmDisclosureOverride = {
  id?: string;
  disclosure_sub_category?: DisclosureSubCategory;
  content?: string;
};
export type FirmDisclosureSectionBlockOverride = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  section?: string;
  disclosure_sub_category?: DisclosureSubCategory;
  block_order?: number;
};
export type FirmTargetBaseContentOverride = {
  id?: string;
  base_content?: string;
  content?: string;
};
export type TargetBaseContent = {
  id?: string;
  content_base_code?: string;
  section: string;
  content?: string;
};
export type TargetSection = {
  id?: string;
  target_base_content?: TargetBaseContent[];
  created_at?: string;
  updated_at?: string;
  code: string;
  name: string;
  description: string;
  is_active?: boolean;
  order_by?: number;
  target_base: string;
};
export type TargetBase = {
  id?: string;
  target_sections?: TargetSection[];
  created_at?: string;
  updated_at?: string;
  code: string;
  name: string;
  description: string;
  is_active?: boolean;
};
export type PageSectionBlockSerialzer = {
  page_section: string;
  block_type?: PageSectionBlockType;
  content?: string | null;
  sort_order?: number;
};
export type PageContent = {
  id?: string;
  code: string;
  page_contents?: PageSectionBlockSerialzer[];
};
export type SectionContentLookup = {
  id?: string;
  code?: string;
  page_section?: PageContent[];
  name?: string;
};
export type TargetBaseNested = {
  id?: string;
  sections?: TargetSection[];
  created_at?: string;
  updated_at?: string;
  code: string;
  name: string;
  description: string;
  is_active?: boolean;
};
export type GicsSector = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
};
export type GicsIndustryGroup = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  gics_sector?: GicsSector;
};
export type GicsIndustry = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  gics_industry_group?: GicsIndustryGroup;
};
export type GicsIndustrySubGroup = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  gics_industry?: GicsIndustry;
};
export type LinkExternalAccounts = {
  integrator: string;
  account_ids: string[];
};
export type ExternalAccountSearch = {
  external_account_id: string;
  name: string;
};
export type ExternalRaymondJamesHousehold = {
  external_household_id: string;
  name: string;
};
export type StyleBox = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  is_equity?: boolean;
  is_fixed_income?: boolean;
  style_box_location?: number;
};
export type Notification = {
  id?: string;
  notification_type?: string;
  notification_title?: string;
  notification_message?: string;
  notification_extended_data?: any | null;
  is_read?: boolean;
  is_snoozed?: boolean;
  snooze_time?: string | null;
  created_at?: string;
  updated_at?: string;
};
export type PatchedNotification = {
  id?: string;
  notification_type?: string;
  notification_title?: string;
  notification_message?: string;
  notification_extended_data?: any | null;
  is_read?: boolean;
  is_snoozed?: boolean;
  snooze_time?: string | null;
  created_at?: string;
  updated_at?: string;
};
export type NotificationLevel = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  code: string;
  name: string;
  display: string;
  description: string;
  is_active?: boolean;
  sort_order: number;
  color_code?: string;
};
export type NotificationSetting = {
  id?: string;
  client_tier: string;
  notification_type: string;
  is_enabled?: boolean;
};
export type PatchedNotificationSetting = {
  id?: string;
  client_tier?: string;
  notification_type?: string;
  is_enabled?: boolean;
};
export type NotificationTierThreshold = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  user: string;
  client_tier: string;
  delta_surplus_drop_percentage?: number;
  delta_surplus_increase_percentage?: number;
  total_delta_allocation_difference_threshold?: number;
  review_reminder_months?: number;
};
export type PatchedNotificationTierThreshold = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  user?: string;
  client_tier?: string;
  delta_surplus_drop_percentage?: number;
  delta_surplus_increase_percentage?: number;
  total_delta_allocation_difference_threshold?: number;
  review_reminder_months?: number;
};
export type ThresholdFieldEnum =
  | "delta_surplus_drop_percentage"
  | "delta_surplus_increase_percentage"
  | "total_delta_allocation_difference_threshold"
  | "review_reminder_months"
  | "not_applicable";
export type NotificationType = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  code: string;
  name: string;
  display: string;
  description: string;
  level?: string | null;
  threshold_field?: ThresholdFieldEnum;
  is_active?: boolean;
  sort_order?: number;
};
export type ConstraintChoices = {
  id?: string;
  code?: string;
  name?: string;
  sort_order?: number;
  advanced_only?: boolean;
  has_percentile?: boolean;
  input_type?: DisplayTypeEnum2;
  min_val?: number | null;
  max_val?: number | null;
};
export type PortfolioModuleDataStatisticValue = {
  id?: string;
  portfolio_module_data_statistic_set_id?: string;
  risk_stat_type_id?: string;
  risk_stat_value?: number;
  risk_stat_percentile?: number | null;
  extra_data?: any | null;
};
export type PortfolioModuleDataStatisticScenarioValue = {
  id?: string;
  portfolio_module_data_statistic_set_id?: string;
  scenario_id?: string;
  observed_return?: number;
};
export type PortfolioModuleDataStatisticSet = {
  id?: string;
  portfolio_module_data_id?: string;
  simulation_job_id?: string;
  extended_statistics?: any | null;
  risk_stat_results?: PortfolioModuleDataStatisticValue[];
  historic_results?: PortfolioModuleDataStatisticScenarioValue[];
  fee_pct_annual?: number;
};
export type PortfolioModuleDataStatisticSetBatchViewRequests = {
  portfolio_module_data_ids: string[];
};
export type OptimizerDynamicRules = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  is_active?: boolean;
  created_at?: string;
  updated_at?: string;
};
export type OptimizerTemplateModuleContainer = {
  id?: string;
  module_name?: string;
  sort_order?: number;
};
export type OptimizerTemplateContainer = {
  id?: string;
  name: string;
  caravel_managed?: boolean;
  modules?: OptimizerTemplateModuleContainer[];
  optimizer_job_id?: string;
};
export type PatchedOptimizerTemplateContainer = {
  id?: string;
  name?: string;
  caravel_managed?: boolean;
  modules?: OptimizerTemplateModuleContainer[];
  optimizer_job_id?: string;
};
export type OptimizerTemplateContainerLoad = {
  portfolio_group_version_id: string;
  mapping: {
    [key: string]: string;
  };
};
export type OptimizerTemplateContainerLoadFromJob = {
  source_job_id?: string;
  portfolio_group_version_id: string;
  mapping: {
    [key: string]: string;
  };
};
export type PortfolioGroupVersionUnnested = {
  id?: string;
  group_id?: string;
  status_id?: string;
  title?: string;
  created_at?: string;
  updated_at?: string;
  portfolio_modules: string[];
};
export type PortfolioGroup = {
  id?: string;
  title: string;
  is_single_portfolio_module?: boolean;
  is_active?: boolean;
  active_date?: string | null;
  simulation_group?: string | null;
  created_at?: string;
  updated_at?: string;
  versions?: PortfolioGroupVersionUnnested[];
  do_publish?: boolean;
  mark_active?: boolean;
};
export type PatchedPortfolioGroup = {
  id?: string;
  title?: string;
  is_single_portfolio_module?: boolean;
  is_active?: boolean;
  active_date?: string | null;
  simulation_group?: string | null;
  created_at?: string;
  updated_at?: string;
  versions?: PortfolioGroupVersionUnnested[];
  do_publish?: boolean;
  mark_active?: boolean;
};
export type PortfolioModuleDataAllocation = {
  id?: string;
  portfolio_module_data_id?: string;
  portfolio_asset_class_options_id?: string;
  portfolio_asset_class_options_code?: string;
  allocation?: number;
  created_at?: string;
  updated_at?: string;
  rounded_allocation?: number;
};
export type PortfolioModuleData = {
  id?: string;
  portfolio_module_id?: string;
  type_id?: string;
  type_code?: string;
  optimizer_job_root?: string | null;
  created_at?: string;
  updated_at?: string;
  allocations?: PortfolioModuleDataAllocation[];
  selected?: boolean;
};
export type PortfolioModule = {
  id?: string;
  portfolio_version_id?: string;
  title: string;
  sort_order?: number;
  is_cash?: boolean;
  overridden_assigned_al_horizon?: number | null;
  created_at?: string;
  updated_at?: string;
  portfolio_module_data?: PortfolioModuleData[];
};
export type PortfolioGroupVersion = {
  id?: string;
  group_id?: string;
  status_id?: string;
  title?: string;
  created_at?: string;
  updated_at?: string;
  portfolio_modules?: PortfolioModule[];
};
export type PatchedPortfolioGroupVersion = {
  id?: string;
  group_id?: string;
  status_id?: string;
  title?: string;
  created_at?: string;
  updated_at?: string;
  portfolio_modules?: PortfolioModule[];
};
export type PortfolioAssetClassOptions = {
  id?: string;
  portfolio_version_id?: string;
  firm_asset_class_id?: string;
  firm_asset_class__asset_class__id?: string;
  is_active?: boolean;
  created_at?: string;
  updated_at?: string;
};
export type PatchedPortfolioAssetClassOptions = {
  id?: string;
  portfolio_version_id?: string;
  firm_asset_class_id?: string;
  firm_asset_class__asset_class__id?: string;
  is_active?: boolean;
  created_at?: string;
  updated_at?: string;
};
export type PortfolioModuleConstraints = {
  id?: string;
  portfolio_module_id?: string;
  constraint_type?: string;
  constraint_type_code?: string;
  value?: number;
  percentile?: number;
  enabled?: boolean;
  updated_at?: string;
  created_at?: string;
};
export type PatchedPortfolioModuleConstraints = {
  id?: string;
  portfolio_module_id?: string;
  constraint_type?: string;
  constraint_type_code?: string;
  value?: number;
  percentile?: number;
  enabled?: boolean;
  updated_at?: string;
  created_at?: string;
};
export type PortfolioModuleConstraintsAc = {
  id?: string;
  portfolio_module_id?: string;
  asset_class_4?: string;
  min_allocation?: number;
  max_allocation?: number;
  created_at?: string;
  updated_at?: string;
};
export type PatchedPortfolioModuleConstraintsAc = {
  id?: string;
  portfolio_module_id?: string;
  asset_class_4?: string;
  min_allocation?: number;
  max_allocation?: number;
  created_at?: string;
  updated_at?: string;
};
export type PatchedPortfolioModuleDataAllocation = {
  id?: string;
  portfolio_module_data_id?: string;
  portfolio_asset_class_options_id?: string;
  portfolio_asset_class_options_code?: string;
  allocation?: number;
  created_at?: string;
  updated_at?: string;
  rounded_allocation?: number;
};
export type PatchedPortfolioModuleData = {
  id?: string;
  portfolio_module_id?: string;
  type_id?: string;
  type_code?: string;
  optimizer_job_root?: string | null;
  created_at?: string;
  updated_at?: string;
  allocations?: PortfolioModuleDataAllocation[];
  selected?: boolean;
};
export type PatchedPortfolioModule = {
  id?: string;
  portfolio_version_id?: string;
  title?: string;
  sort_order?: number;
  is_cash?: boolean;
  overridden_assigned_al_horizon?: number | null;
  created_at?: string;
  updated_at?: string;
  portfolio_module_data?: PortfolioModuleData[];
};
export type PortfolioModuleConsNested = {
  id?: string;
  portfolio_version_id?: string;
  title: string;
  sort_order?: number;
  is_cash?: boolean;
  overridden_assigned_al_horizon?: number | null;
  optimizer_constraints?: PortfolioModuleConstraints[];
  optimizer_asset_class_constraints?: PortfolioModuleConstraintsAc[];
  created_at?: string;
  updated_at?: string;
  portfolio_module_data: string[];
};
export type PortfolioGroupVersionOptimizationSubJobStatusEnum =
  | "PENDING"
  | "RUNNING"
  | "QUEUED_SAVE"
  | "COMPLETE"
  | "ERROR";
export type PortfolioGroupVersionOptimizationSubJob = {
  id?: string;
  root?: string;
  module?: string;
  module_title?: string;
  job_id?: string;
  status?: PortfolioGroupVersionOptimizationSubJobStatusEnum;
  created_at?: string;
  updated_at?: string;
};
export type PortfolioGroupVersionOptimizationJobRoot = {
  id?: string;
  portfolio_version_id?: string;
  simulation_job?: string;
  jobs?: PortfolioGroupVersionOptimizationSubJob[];
  title?: string | null;
  was_auto_run?: boolean;
  rule_base?: OptimizerDynamicRules;
  created_at?: string;
  updated_at?: string;
};
export type PatchedPortfolioGroupVersionOptimizationJobRoot = {
  id?: string;
  portfolio_version_id?: string;
  simulation_job?: string;
  jobs?: PortfolioGroupVersionOptimizationSubJob[];
  title?: string | null;
  was_auto_run?: boolean;
  rule_base?: OptimizerDynamicRules;
  created_at?: string;
  updated_at?: string;
};
export type ObjectiveFunctionEnum = "future_value" | "active_return";
export type PortfolioGroupVersionOptimizerSettings = {
  id?: string;
  portfolio_group_version_id?: string;
  return_scenario: string;
  objective_function?: ObjectiveFunctionEnum;
  minimum_allocation?: number;
  created_at?: string;
  updated_at?: string;
};
export type PatchedPortfolioGroupVersionOptimizerSettings = {
  id?: string;
  portfolio_group_version_id?: string;
  return_scenario?: string;
  objective_function?: ObjectiveFunctionEnum;
  minimum_allocation?: number;
  created_at?: string;
  updated_at?: string;
};
export type PortfolioGroupVersionStatus = {
  id?: string;
  code?: string;
  name?: string;
  editable?: boolean;
  sort_order?: number;
  created_at?: string;
  updated_at?: string;
};
export type PortfolioModuleDataType = {
  id?: string;
  code?: string;
  name?: string;
  sort_order?: number;
  is_active?: boolean;
  is_user_editable?: boolean;
  created_at?: string;
  updated_at?: string;
};
export type BuildRuleOptimizations = {
  uuids: string[];
};
export type UuidList = {
  uuids: string[];
};
export type ProcessOptimizerDynamicRules = {
  rule_ids: string[];
};
export type ClientTier = {
  id?: string;
  name: string;
  sort_order?: number;
  is_active?: boolean;
};
export type ClientStatusEnum = 1 | 2 | 3 | 4;
export type NullEnum = null;
export type ClientPlanStatusEnum = "DRAFT" | "PUBLISHED" | "ARCHIVED";
export type PlanPublishedData = {
  published_plan_type_id: string;
};
export type ClientPlan = {
  id?: string;
  client: string;
  name: string;
  created_at?: string;
  needs_percentile?: number;
  wants_percentile?: number;
  dreams_percentile?: number;
  status?: ClientPlanStatusEnum;
  is_workbench_accessible?: boolean;
  published_data?: PlanPublishedData;
};
export type SuffixEnum = "Jr." | "Sr." | "I" | "II" | "III";
export type BlankEnum = "";
export type UsStateEnum =
  | "FED"
  | "AL"
  | "AK"
  | "AS"
  | "AZ"
  | "AR"
  | "CA"
  | "CO"
  | "CT"
  | "DE"
  | "DC"
  | "FM"
  | "FL"
  | "GA"
  | "GU"
  | "HI"
  | "ID"
  | "IL"
  | "IN"
  | "IA"
  | "KS"
  | "KY"
  | "LA"
  | "ME"
  | "MH"
  | "MD"
  | "MA"
  | "MI"
  | "MN"
  | "MS"
  | "MO"
  | "MT"
  | "NE"
  | "NV"
  | "NH"
  | "NJ"
  | "NM"
  | "NY"
  | "NC"
  | "ND"
  | "MP"
  | "OH"
  | "OK"
  | "OR"
  | "PW"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VT"
  | "VI"
  | "VA"
  | "WA"
  | "WV"
  | "WI"
  | "WY";
export type ClientPlanRelationship = {
  id?: string;
  household?: string;
  first_name: string;
  last_name: string;
  suffix?: (SuffixEnum | BlankEnum | NullEnum) | null;
  date_of_birth: string;
  relation_type: string;
  is_active?: boolean;
  income?: number | null;
  state_of_residence?: (UsStateEnum | BlankEnum | NullEnum) | null;
  retirement_age?: number | null;
  retirement_state?: (UsStateEnum | BlankEnum | NullEnum) | null;
  live_until?: number | null;
  ui_key?: string;
};
export type Client = {
  id?: string;
  address?: string | null;
  email?: string | null;
  phone_num?: string | null;
  advisor_notes?: string | null;
  tier?: string | null;
  status?: (ClientStatusEnum | NullEnum) | null;
  last_reviewed?: string | null;
  created_at?: string;
  updated_at?: string;
  plans?: ClientPlan[];
  active_relationships: ClientPlanRelationship[];
};
export type ClientNote = {
  id?: string;
  note_source: string;
  note_text: string;
  created_at?: string;
  updated_at?: string;
};
export type PatchedClientNote = {
  id?: string;
  note_source?: string;
  note_text?: string;
  created_at?: string;
  updated_at?: string;
};
export type PatchedClient = {
  id?: string;
  address?: string | null;
  email?: string | null;
  phone_num?: string | null;
  advisor_notes?: string | null;
  tier?: string | null;
  status?: (ClientStatusEnum | NullEnum) | null;
  last_reviewed?: string | null;
  created_at?: string;
  updated_at?: string;
  plans?: ClientPlan[];
  active_relationships?: ClientPlanRelationship[];
};
export type BlackDiamondIntegration = {
  username: string;
  password: string;
  black_diamond_sandbox_mode?: boolean | null;
};
export type ExternalBlackDiamondAccount = {
  external_account_id: string;
  name: string;
};
export type ExternalBlackDiamondPortfolio = {
  external_portfolio_id: string;
  name: string;
};
export type CollegeSimpleStatistics = {
  id: string;
  name: string;
  city: string;
  cost: number;
};
export type CollegeStatistics = {
  id?: string;
  opeid: string;
  name: string;
  city: string;
  is_main_campus?: boolean;
  branch_number?: number | null;
  year: number;
  program_type: number;
  tuitionfee_in_state: number;
  tuitionfee_out_of_state: number;
  expenses_oncampus: number;
  expenses_offcampus: number;
};
export type Integrator = {
  id: string;
  code: string;
  enabled: boolean;
};
export type GetLifeExpectancy = {
  life_expectancy: number;
};
export type PatchedClientPlan = {
  id?: string;
  client?: string;
  name?: string;
  created_at?: string;
  needs_percentile?: number;
  wants_percentile?: number;
  dreams_percentile?: number;
  status?: ClientPlanStatusEnum;
  is_workbench_accessible?: boolean;
  published_data?: PlanPublishedData;
};
export type ClientPlanCopyRequest = {
  name: string;
  client: string;
};
export type OptimizedSocialSecuritySingle = {
  birth_date: string;
  benefit_age: number;
  death: number;
  fra: number;
  fra_benefit: number;
  income_after_retirement: number;
  individual_benefit: number;
  spousal_benefit_age: number;
  spousal_benefit: number;
  current_spousal_benefit: number;
  spousal_benefit_start_period: number;
  optimal_benefit_age: number;
  optimal_spousal_benefit_age: number;
  Total: number;
  optimal_total: number;
  optimal_individual_benefit: number;
  optimal_spousal_benefit: number;
  benefit_pre_cola_at_input_age: number;
  total_pre_cola: number;
  optimial_benefit_pre_cola_at_input_age: number;
  optimial_total_pre_cola: number;
};
export type OptimizedSocialSecurity = {
  primary: OptimizedSocialSecuritySingle;
  secondary: OptimizedSocialSecuritySingle;
  primary_original: OptimizedSocialSecuritySingle;
  secondary_original: OptimizedSocialSecuritySingle;
};
export type ClientPlanRiskQuestionAnswer = {
  question_object_id: string;
  answer_id: string;
};
export type ClientPlanRisk = {
  id?: string;
  using_questions?: boolean;
  eq_weight?: number;
  fi_weight?: number;
  question_answers?: ClientPlanRiskQuestionAnswer[];
  created_at?: string;
  calculated_risk_score?: number;
};
export type SavingsThisYear = {
  savings_this_year_all_accounts: number;
  savings_this_year_integrated: number;
  savings_this_year_manual: number;
};
export type ClientPlanSpousalBenefit = {
  spousal_benefit_start_age: number;
};
export type LinkBlackDiamondAccounts = {
  integrator: string;
  account_ids: string[];
};
export type AccountInputTypeEnum =
  | "integrated"
  | "manual_detailed"
  | "manual_percent"
  | "manual_dollar"
  | "not_available";
export type AccountUiInputTypeEnum =
  | "integrated"
  | "manual_summary_simple"
  | "manual_summary_advanced"
  | "manual_detailed_holdings"
  | "not_available";
export type KindEnum =
  | "YEAR"
  | "AGE"
  | "PRIMARY_RETIREMENT"
  | "SECONDARY_RETIREMENT"
  | "PRIMARY_DEATH"
  | "SECONDARY_DEATH"
  | "SECOND_DEATH";
export type CaravelDate = {
  id?: string;
  year?: number | null;
  age?: number | null;
  age_relationship?: string | null;
  kind: KindEnum;
  plan: string;
};
export type SavingsContributionData = {
  id?: string;
  amount_dollar?: number | null;
  amount_percent?: number | null;
  max_contribution?: boolean | null;
};
export type Savings = {
  id?: string;
  start_date_caravel: CaravelDate;
  end_date_caravel: CaravelDate;
  base_contribution: SavingsContributionData;
  employer_match?: SavingsContributionData;
  ui_key?: string;
  get_data?: {
    [key: string]: any;
  };
};
export type AccountManualSimpleHolding = {
  id?: string;
  firm_asset_class_id: string;
  percentage?: number;
  market_value?: number;
  cost_basis?: number;
  ui_key?: string;
};
export type AccountHolding = {
  id?: string;
  symbol?: string | null;
  firm_asset_class_id?: string;
  description?: string | null;
  shares?: number;
  market_value?: number;
  cost_basis?: number;
  ui_key?: string;
};
export type Account = {
  id?: string;
  client?: string;
  created_at?: string;
  last_four?: string | null;
  custodian?: string | null;
  market_value?: number;
  cost_basis?: number;
  max_contribution_available?: boolean;
  description?: string | null;
  account_type: string;
  is_saving?: boolean;
  account_input_type?: AccountInputTypeEnum;
  account_ui_input_type?: AccountUiInputTypeEnum;
  manual_percent_total_market_value?: number | null;
  relationship_type?: string;
  savings?: Savings[];
  simple_holdings?: AccountManualSimpleHolding[];
  detailed_holdings?: AccountHolding[];
  ui_key?: string;
};
export type PatchedAccount = {
  id?: string;
  client?: string;
  created_at?: string;
  last_four?: string | null;
  custodian?: string | null;
  market_value?: number;
  cost_basis?: number;
  max_contribution_available?: boolean;
  description?: string | null;
  account_type?: string;
  is_saving?: boolean;
  account_input_type?: AccountInputTypeEnum;
  account_ui_input_type?: AccountUiInputTypeEnum;
  manual_percent_total_market_value?: number | null;
  relationship_type?: string;
  savings?: Savings[];
  simple_holdings?: AccountManualSimpleHolding[];
  detailed_holdings?: AccountHolding[];
  ui_key?: string;
};
export type InflationEnum = -1 | 0 | 1 | 2 | 3 | 4 | 5;
export type TaxesEnum = "TAXABLE" | "TAX_FREE";
export type ClientPlanAdditionalIncome = {
  id?: string;
  start_date_caravel: CaravelDate;
  end_date_caravel: CaravelDate;
  created_at?: string;
  updated_at?: string;
  inflation?: (InflationEnum | NullEnum) | null;
  description: string;
  frequency: number;
  amount: number;
  taxes: TaxesEnum;
  ui_key?: string;
};
export type PatchedClientPlanAdditionalIncome = {
  id?: string;
  start_date_caravel?: CaravelDate;
  end_date_caravel?: CaravelDate;
  created_at?: string;
  updated_at?: string;
  inflation?: (InflationEnum | NullEnum) | null;
  description?: string;
  frequency?: number;
  amount?: number;
  taxes?: TaxesEnum;
  ui_key?: string;
};
export type ClientPlanDeRiskDetails = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  portfolio_module: string;
};
export type PatchedClientPlanDeRiskDetails = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  portfolio_module?: string;
};
export type ClientPlanGoalCollege = {
  id?: string;
  goal_id?: string;
  college: string;
  ui_key?: string;
};
export type PriorityEnum = "NEED" | "WANT" | "DREAM";
export type IconEnum =
  | "GENERIC"
  | "RETIREMENT_SPENDING"
  | "HEALTHCARE"
  | "EDUCATION"
  | "SECOND_HOME"
  | "VACATION"
  | "RENOVATION"
  | "LUXURY_ITEM"
  | "CHARITY"
  | "LEGACY"
  | "CELEBRATION";
export type ClientPlanGoal = {
  id?: string;
  start_date_caravel: CaravelDate;
  end_date_caravel: CaravelDate;
  tax_purpose: string;
  college_link?: ClientPlanGoalCollege | null;
  created_at?: string;
  updated_at?: string;
  inflation?: (InflationEnum | NullEnum) | null;
  description: string;
  pii_description?: string | null;
  frequency: number;
  amount: number;
  total_amount_across_plan?: number;
  priority: PriorityEnum;
  defer_goal?: boolean;
  defer_how_long?: number;
  position?: number;
  icon?: (IconEnum | BlankEnum | NullEnum) | null;
  ui_key?: string;
  enabled?: boolean;
};
export type PatchedClientPlanGoal = {
  id?: string;
  start_date_caravel?: CaravelDate;
  end_date_caravel?: CaravelDate;
  tax_purpose?: string;
  college_link?: ClientPlanGoalCollege | null;
  created_at?: string;
  updated_at?: string;
  inflation?: (InflationEnum | NullEnum) | null;
  description?: string;
  pii_description?: string | null;
  frequency?: number;
  amount?: number;
  total_amount_across_plan?: number;
  priority?: PriorityEnum;
  defer_goal?: boolean;
  defer_how_long?: number;
  position?: number;
  icon?: (IconEnum | BlankEnum | NullEnum) | null;
  ui_key?: string;
  enabled?: boolean;
};
export type ClientPlanHealthcare = {
  id?: string;
  relationship: string;
  start_age: number;
  assumed_percent_above_average?: number | null;
  inflation?: (InflationEnum | NullEnum) | null;
  ui_key?: string;
  created_at?: string;
  income_level?: number;
  annual_cost_75?: number;
  annual_cost_85?: number;
  annual_cost_past_85?: number;
};
export type PatchedClientPlanHealthcare = {
  id?: string;
  relationship?: string;
  start_age?: number;
  assumed_percent_above_average?: number | null;
  inflation?: (InflationEnum | NullEnum) | null;
  ui_key?: string;
  created_at?: string;
  income_level?: number;
  annual_cost_75?: number;
  annual_cost_85?: number;
  annual_cost_past_85?: number;
};
export type RealEstateTypeEnum = "RENTAL" | "OCCUPIED";
export type PaymentPlanEnum = "NO_CHANGE" | "YEAR_15" | "YEAR_30";
export type ClientPlanRealEstateRefinance = {
  id?: string;
  refinance_amount: number;
  real_estate_property_id?: string | null;
  refinance_date: CaravelDate;
  interest_rate?: number;
  payment_plan?: PaymentPlanEnum;
  ui_key?: string;
};
export type ClientPlanRealEstate = {
  id?: string;
  start_date_caravel: CaravelDate;
  end_date_caravel: CaravelDate;
  real_estate_type?: RealEstateTypeEnum;
  name: string;
  real_estate_value: number;
  mortgage_balance?: number;
  interest_rate?: number | null;
  maturity_year?: number | null;
  mortgage_pi_payment?: number | null;
  mortgage_monthly_total_payment?: number | null;
  cost_basis: number;
  primary_residence_ind?: boolean;
  existing_property_ind?: boolean;
  rental_monthly_rental_income?: number | null;
  rental_monthly_expenses?: number | null;
  rental_monthly_depreciation?: number | null;
  rental_inflation_adj_rent?: number | null;
  rental_inflation_adj_expenses?: number | null;
  refinance_data?: ClientPlanRealEstateRefinance | null;
  ui_key?: string;
};
export type PatchedClientPlanRealEstate = {
  id?: string;
  start_date_caravel?: CaravelDate;
  end_date_caravel?: CaravelDate;
  real_estate_type?: RealEstateTypeEnum;
  name?: string;
  real_estate_value?: number;
  mortgage_balance?: number;
  interest_rate?: number | null;
  maturity_year?: number | null;
  mortgage_pi_payment?: number | null;
  mortgage_monthly_total_payment?: number | null;
  cost_basis?: number;
  primary_residence_ind?: boolean;
  existing_property_ind?: boolean;
  rental_monthly_rental_income?: number | null;
  rental_monthly_expenses?: number | null;
  rental_monthly_depreciation?: number | null;
  rental_inflation_adj_rent?: number | null;
  rental_inflation_adj_expenses?: number | null;
  refinance_data?: ClientPlanRealEstateRefinance | null;
  ui_key?: string;
};
export type PatchedClientPlanRelationship = {
  id?: string;
  household?: string;
  first_name?: string;
  last_name?: string;
  suffix?: (SuffixEnum | BlankEnum | NullEnum) | null;
  date_of_birth?: string;
  relation_type?: string;
  is_active?: boolean;
  income?: number | null;
  state_of_residence?: (UsStateEnum | BlankEnum | NullEnum) | null;
  retirement_age?: number | null;
  retirement_state?: (UsStateEnum | BlankEnum | NullEnum) | null;
  live_until?: number | null;
  ui_key?: string;
};
export type ClientPlanSocialSecurity = {
  id?: string;
  relationship: string;
  created_at?: string;
  updated_at?: string;
  start_age_of_benefit: number;
  benefit_yearly: number;
  ui_key?: string;
};
export type PatchedClientPlanSocialSecurity = {
  id?: string;
  relationship?: string;
  created_at?: string;
  updated_at?: string;
  start_age_of_benefit?: number;
  benefit_yearly?: number;
  ui_key?: string;
};
export type HouseholdRelationType = {
  id?: string;
  code: string;
  label: string;
  is_household_head?: boolean;
  is_primary?: boolean;
};
export type RiskQuestionAnswer = {
  id?: string;
  text?: string;
  score?: number;
};
export type RiskQuestion = {
  id?: string;
  text?: string;
  sort_order?: number;
  scored?: boolean;
  answers: RiskQuestionAnswer[];
};
export type GoalTaxPurpuse = {
  id?: string;
  code: string;
  name: string;
  desc: string;
  is_active?: boolean;
  sort_order: number;
  show_on_screen?: boolean;
};
export type SimCreator = {
  id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
};
export type SimulationGroup = {
  id?: string;
  name: string;
  created_at?: string;
  updated_at?: string;
};
export type RhoStartMonthEnum =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;
export type RhoStartYearEnum =
  | 1989
  | 1990
  | 1991
  | 1992
  | 1993
  | 1994
  | 1995
  | 1996
  | 1997
  | 1998
  | 1999
  | 2000
  | 2001
  | 2002
  | 2003
  | 2004
  | 2005
  | 2006
  | 2007
  | 2008
  | 2009
  | 2010
  | 2011
  | 2012
  | 2013
  | 2014;
export type JobTypeEnum = 0 | 1 | 2;
export type UseEmpiricalDistributionEnum = true | false;
export type OnOffEnum = true | false;
export type SimulationSetting = {
  id?: string;
  group?: string;
  path_count?: number;
  years_in_simulation?: number;
  rho_start_month?: RhoStartMonthEnum;
  rho_start_year?: RhoStartYearEnum;
  job_type?: JobTypeEnum;
  simulation_model_type?: string | null;
  use_empirical_distribution?: UseEmpiricalDistributionEnum;
  use_boundaries?: OnOffEnum;
  use_crisis_mode?: OnOffEnum;
  crisis_mode_years_between?: number;
  crisis_mode_duration?: number;
  version?: number;
  model_version?: string | null;
  created_at?: string;
  updated_at?: string;
};
export type SimulationJobRun = {
  id?: string;
  created_at?: string;
  status?: JobStatusEnum;
  created_by: SimCreator;
  published?: boolean;
  group: SimulationGroup;
  settings_hist: SimulationSetting;
};
export type SimulationJobTypeDict = {
  key?: string;
  value: SimulationJobRun[];
};
export type SimMarketDataFixedIncome = {
  group?: string;
  date?: string;
  index_level_total_return?: number | null;
  index_level_price_return?: number | null;
  days_to_maturity?: number | null;
  weighted_average_coupon?: number | null;
  current_yield?: number;
  duration?: number;
  convexity?: number | null;
  option_adjusted_spread?: number | null;
  option_adjusted_yield?: number | null;
};
export type SimulationJob = {
  id?: string;
  published?: boolean;
  prior_published?: boolean;
  group?: string;
  asset_class4s: string[];
  settings: string;
  status?: JobStatusEnum;
  created_at?: string;
  updated_at?: string;
  is_static?: boolean;
};
export type SimulationJobResultAssetClass = {
  id?: string;
  job: SimulationJob;
  asset_class4?: string;
  input_return_sigma?: number | null;
  output_real_return_mean?: number | null;
  output_real_return_sigma?: number | null;
  output_nominal_return_mean?: number | null;
  output_nominal_return_sigma?: number | null;
  ten_year_expected_return?: number | null;
  input_yield_sigma?: number | null;
  output_yield_sigma?: number | null;
  input_spread_mean?: number | null;
  output_spread_mean?: number | null;
  input_spread_sigma?: number | null;
  output_spread_sigma?: number | null;
  created_at?: string;
  updated_at?: string;
};
export type AssetClassLevel4WithSimulationJobResultAssetClass = {
  id?: string;
  stat_result: SimulationJobResultAssetClass;
};
export type SimulationQaSummaryReport = {
  report_type: string;
  static_simulation: boolean;
  simulation_id: string;
  simulation_date: string;
  simulation_group_id: string;
  simulation_name: string;
  asset_classes: AssetClassLevel4WithSimulationJobResultAssetClass[];
};
export type SimulationQaReportRequestBase = {
  simulation_job_id: string;
};
export type ResultEntry = {
  sim: number;
  sim_lower_bound: number;
  sim_upper_bound: number;
  his: number | null;
};
export type SimulationPercentileYearsData = {
  years: number[];
  results: {
    [key: string]: {
      [key: string]: ResultEntry;
    };
  };
  years_extended: number[];
  results_extended: {
    [key: string]: {
      [key: string]: ResultEntry;
    };
  };
};
export type AssetClassLevel4WithStatsAndPercentileData = {
  id?: string;
  stat_result: SimulationJobResultAssetClass;
  sim_percentile: SimulationPercentileYearsData;
};
export type SimulationQaReturnsYieldReport = {
  report_type: string;
  static_simulation: boolean;
  simulation_id: string;
  simulation_date: string;
  simulation_group_id: string;
  simulation_name: string;
  asset_classes: AssetClassLevel4WithStatsAndPercentileData[];
};
export type SimulationQaSummaryReportRequestAssetClass = {
  simulation_job_id: string;
  asset_class_code: string;
};
export type SimulationJobResultCpi = {
  output_return_mean?: number | null;
  output_return_sigma?: number | null;
  initial_yield?: number | null;
  input_yield_sigma?: number | null;
  output_yield_sigma?: number | null;
};
export type CpiStats = {
  stat_result: SimulationJobResultCpi;
  sim_percentile: SimulationPercentileYearsData;
};
export type SimulationQaWithCpiReport = {
  report_type: string;
  static_simulation: boolean;
  simulation_id: string;
  simulation_date: string;
  simulation_group_id: string;
  simulation_name: string;
  asset_classes: AssetClassLevel4WithStatsAndPercentileData[];
  cpi: CpiStats;
};
export type SimulationDataFilterOutput = {
  path: number[];
  data: number[][];
  field: string;
};
export type SimulationAnalyzePathsInput = {
  simulation_job_id?: string;
  ac_code: string;
  filter_data_type: string;
  paths: number[];
};
export type SimulationDataFilterInput = {
  simulation_job_id?: string;
  ac_code: string;
  filter_data_type: string;
  filter_year: number;
  filter_value_gt: number;
  filter_value_lt: number;
};
export type SimulationIntermediateCalculationField = {
  id?: string;
  code?: string;
};
export type SimualtionPathFieldCalculation = {
  field: SimulationIntermediateCalculationField;
  data: number[][];
};
export type SimualtionPathCalculationDetails = {
  simulation_job_id?: string;
  path: number;
  fields: SimualtionPathFieldCalculation[];
};
export type SimulationAnalyzePathCalcFieldsInput = {
  simulation_job_id?: string;
  ac_code: string;
  path: number;
};
export type SettingInflationExpectedMean = {
  id?: string;
  group: string;
  year: number;
  percentage: number;
  created_at?: string;
  updated_at?: string;
};
export type PatchedSettingInflationExpectedMean = {
  id?: string;
  group?: string;
  year?: number;
  percentage?: number;
  created_at?: string;
  updated_at?: string;
};
export type AssetClassLevel4IdOnly = {
  id?: string;
};
export type SimulationYieldCurveRate = {
  asset_class: AssetClassLevel4IdOnly;
  position: YieldCurvePosition;
  rate: number;
};
export type SimulationYieldCurveDetails = {
  description: string;
  rates: SimulationYieldCurveRate[];
};
export type YieldCurveTypeCurve = {
  id?: string;
  name: string;
};
export type YieldCurve = {
  yield_curve_type: YieldCurveTypeCurve;
  rates: YieldCurveRate[];
};
export type SimulationGroupDetail = {
  id?: string;
  name: string;
  asset_classes?: any[];
};
export type SimulationJobCondensedSummaryStatistics = {
  asset_class4?: string;
  real_static_expected_return?: number | null;
  real_dynamic_expected_return?: number | null;
  nominal_static_expected_return?: number | null;
  nominal_dynamic_expected_return?: number | null;
  volatility?: number | null;
};
export type SimulationJobStatistics = {
  id?: string;
  simulation_job?: string;
  extended_statistics?: any;
  updated_at?: string;
  created_at?: string;
};
export type SimulationJobResultAssetClassYearPercentile = {
  id?: string;
  job?: string;
  asset_class4?: string;
  year?: number;
  percentile?: number;
  sim_return_nominal?: number | null;
  created_at?: string;
  updated_at?: string;
};
export type CmaModelChartData = {
  id?: string;
  job_id?: string;
  asset_class?: string;
  trend_title?: string;
  trend_x?: string[];
  trend_y_trend_line?: number[];
  trend_y_historic_line?: number[];
  label_start_date?: string;
  label_start_dft?: number;
  label_end_dft?: number;
  label_expected_return?: number;
  label_expected_return_formula?: string;
  boundary_title?: string;
  boundary_x?: number[];
  boundary_y_upper?: number[];
  boundary_y_expected_ret?: number[];
  boundary_y_lower?: number[];
  boundary_scatter_x?: number[];
  boundary_scatter_y?: number[];
};
export type SimulationJobSummaryStatistics = {
  id?: string;
  simulation_job?: string;
  asset_class4?: string;
  asset_class_as_of_date?: string | null;
  dft?: number | null;
  current_yield?: number | null;
  real_static_expected_return?: number | null;
  real_dynamic_expected_return?: number | null;
  nominal_static_expected_return?: number | null;
  nominal_dynamic_expected_return?: number | null;
  volatility?: number | null;
  model_chart_data?: CmaModelChartData;
  alpha?: number | null;
  beta?: number | null;
};
export type SimulationGroupApi = {
  id?: string;
  name?: string;
  asset_classes?: any[];
  latest_simulation?: SimulationJob;
  created_at?: string;
  updated_at?: string;
};
export type SimulationJobCreate = {
  simulation_group_id: string;
};
export type PatchedSimulationJob = {
  id?: string;
  published?: boolean;
  prior_published?: boolean;
  group?: string;
  asset_class4s?: string[];
  settings?: string;
  status?: JobStatusEnum;
  created_at?: string;
  updated_at?: string;
  is_static?: boolean;
};
export type SimulationJobPublish = {
  send_notifications?: boolean;
};
export type SimualtionModelTypeDetail = {
  id?: string;
  code?: string;
  name?: string;
  description?: string;
  is_static?: boolean;
  is_dynamic?: boolean;
  sort_order?: number;
};
export type SimualtionModelVersionDetail = {
  id?: string;
  name?: string;
  description?: string;
  is_available_for_prodcution?: boolean;
  full_version?: string;
  primary_version_number?: number;
  secondary_version_number?: number;
};
export type GraphScaleEnum = "linear" | "logarithmic";
export type FieldTypeEnum = "number" | "percentage" | "boolean";
export type SimualtionOutputDebugFields = {
  id?: string;
  code?: string;
  name?: string;
  description?: string | null;
  display_lable?: string;
  filter_code?: string;
  is_equity?: boolean;
  is_fixed_income?: boolean;
  is_cash?: boolean;
  graph_scale?: GraphScaleEnum;
  field_type?: FieldTypeEnum;
  sort_order?: number;
};
export type PatchedSimulationSetting = {
  id?: string;
  group?: string;
  path_count?: number;
  years_in_simulation?: number;
  rho_start_month?: RhoStartMonthEnum;
  rho_start_year?: RhoStartYearEnum;
  job_type?: JobTypeEnum;
  simulation_model_type?: string | null;
  use_empirical_distribution?: UseEmpiricalDistributionEnum;
  use_boundaries?: OnOffEnum;
  use_crisis_mode?: OnOffEnum;
  crisis_mode_years_between?: number;
  crisis_mode_duration?: number;
  version?: number;
  model_version?: string | null;
  created_at?: string;
  updated_at?: string;
};
export const {
  useAccountsAccountHoldingsRetrieveQuery,
  useAccountsAccountTypeListQuery,
  useAccountsAccountTypeRetrieveQuery,
  useAccountsTaxTypeRulesDetailListQuery,
  useAlEngineAlAssetTypeListQuery,
  useAlEngineAlAssetTypeRetrieveQuery,
  useAlEngineDebugInputJsonRetrieveQuery,
  useAlEngineDebugOutputJsonRetrieveQuery,
  useAlEnginePlanResultRetrieveQuery,
  useAlEnginePlanResultActionPlanRetrieveQuery,
  useAlEnginePlanResultPublishPlanResultUpdateMutation,
  useAlEnginePlanResultSimulationJobDisclosureRetrieveQuery,
  useAlEnginePlanResultStressTestContainerRetrieveQuery,
  useAlEnginePlanResultTypeListQuery,
  useAlEnginePlanResultTypeRetrieveQuery,
  useAlEngineRenderRetrieveQuery,
  useAlEngineRenderPlanResultJobStatusListQuery,
  useAlEngineRenderProgressMonitoringResultsRetrieveQuery,
  useAlEngineStressTestContainerRetrieveQuery,
  useAlEngineStressTestScenarioTypeListQuery,
  useAlEngineStressTestScenarioTypeRetrieveQuery,
  useAssetClassesAssetClassGicsSubIndustryWeightsListQuery,
  useAssetClassesAssetClassLevel4ListRetrieveQuery,
  useAssetClassesAssetClassStatisticViewRetrieveQuery,
  useAssetClassesBoundaryListQuery,
  useAssetClassesBoundaryCreateMutation,
  useAssetClassesBoundaryRetrieveQuery,
  useAssetClassesBoundaryUpdateMutation,
  useAssetClassesBoundaryPartialUpdateMutation,
  useAssetClassesBoundaryDestroyMutation,
  useAssetClassesBoundaryGroupListQuery,
  useAssetClassesBoundaryGroupCreateMutation,
  useAssetClassesBoundaryGroupRetrieveQuery,
  useAssetClassesBoundaryGroupUpdateMutation,
  useAssetClassesBoundaryGroupPartialUpdateMutation,
  useAssetClassesBoundaryGroupDestroyMutation,
  useAssetClassesFirmAssetClassImplementationListQuery,
  useAssetClassesFirmAssetClassImplementationRetrieveQuery,
  useAssetClassesFirmAssetClassImplementationUpdateMutation,
  useAssetClassesFirmAssetClassImplementationPartialUpdateMutation,
  useAssetClassesFirmAssetClassesListQuery,
  useAssetClassesFirmAssetClassesRetrieveQuery,
  useAssetClassesFirmAssetClassesUpdateMutation,
  useAssetClassesFirmAssetClassesPartialUpdateMutation,
  useAssetClassesSimulationBehaviorCashListQuery,
  useAssetClassesSimulationBehaviorCashRetrieveQuery,
  useAssetClassesSimulationBehaviorCashUpdateMutation,
  useAssetClassesSimulationBehaviorCashPartialUpdateMutation,
  useAssetClassesSimulationBehaviorEquityListQuery,
  useAssetClassesSimulationBehaviorEquityRetrieveQuery,
  useAssetClassesSimulationBehaviorEquityUpdateMutation,
  useAssetClassesSimulationBehaviorEquityPartialUpdateMutation,
  useAssetClassesSimulationBehaviorFixedIncomeSpreadListQuery,
  useAssetClassesSimulationBehaviorFixedIncomeSpreadRetrieveQuery,
  useAssetClassesSimulationBehaviorFixedIncomeSpreadUpdateMutation,
  useAssetClassesSimulationBehaviorFixedIncomeSpreadPartialUpdateMutation,
  useAssetClassesSimulationBehaviorInflationListQuery,
  useAssetClassesSimulationBehaviorInflationRetrieveQuery,
  useAssetClassesSimulationBehaviorInflationUpdateMutation,
  useAssetClassesSimulationBehaviorInflationPartialUpdateMutation,
  useAutomationReceiveLambdaEventCreateMutation,
  useCmaAssetClassRegressionRetrieveQuery,
  useCmaCmaDataListQuery,
  useCmaCmaDataRetrieveQuery,
  useCmaCmaGroupListQuery,
  useCmaCmaGroupRetrieveQuery,
  useCmaDataSourceListQuery,
  useCmaDataSourceRetrieveQuery,
  useCmaGenerateAssetClassSimBehaviorCreateMutation,
  useCmaGetCurrentCpiDefinitionsListQuery,
  useCmaReturnScenariosListQuery,
  useCmaReturnScenariosRetrieveQuery,
  useCmaRiskStatTypeListQuery,
  useCmaRiskStatTypeRetrieveQuery,
  useCmaSandpCmaGroupListQuery,
  useCmaSandpCmaGroupRetrieveQuery,
  useCmaSandpDataEquityListQuery,
  useCmaSandpDataEquityRetrieveQuery,
  useCmaSandpDataFixedIncomeListQuery,
  useCmaSandpDataFixedIncomeRetrieveQuery,
  useCmaYieldCurvePostionsListQuery,
  useCommonAuthAcceptInvitationCreateMutation,
  useCommonAuthLoginCreateMutation,
  useCommonAuthRefreshCreateMutation,
  useCommonAuthRequestResetPasswordCreateMutation,
  useCommonAuthResetPasswordCreateMutation,
  useCommonAuthSelfRetrieveQuery,
  useCommonAuthSelfUpdateMutation,
  useCommonAuthSelfPartialUpdateMutation,
  useCommonAuthSelfFirmRetrieveQuery,
  useCommonAuthSelfFirmUpdateMutation,
  useCommonAuthSelfFirmPartialUpdateMutation,
  useCommonAuthStartFreeTrialCreateMutation,
  useCommonAuthTwoFactorLoginUpdateMutation,
  useCommonAuthTwoFactorLoginPartialUpdateMutation,
  useCommonAuthTwoFactorSettingsCreateMutation,
  useCommonAuthTwoFactorSettingsUpdateMutation,
  useCommonAuthTwoFactorSettingsPartialUpdateMutation,
  useCommonAuthVerifyEmailUpdateMutation,
  useCommonAuthVerifyEmailPartialUpdateMutation,
  useCommonAuthVerifyEmailResendUpdateMutation,
  useCommonAuthVerifyEmailResendPartialUpdateMutation,
  useCommonBillingActivateBillingCreateQuery,
  useCommonBillingActivateBillingV2RetrieveQuery,
  useCommonBillingActivateBillingV2CreateMutation,
  useCommonBillingBillingCheckStatusCreateQuery,
  useCommonBillingBillingGetInfoRetrieveQuery,
  useCommonBillingStripePriceListQuery,
  useCommonBillingStripePriceRetrieveQuery,
  useCommonCmpasModuleListQuery,
  useCommonCmpasModuleCreateMutation,
  useCommonCmpasModuleRetrieveQuery,
  useCommonCmpasModuleUpdateMutation,
  useCommonCmpasModulePartialUpdateMutation,
  useCommonCmpasModuleHierarchyListQuery,
  useCommonCmpasModuleHierarchyRetrieveQuery,
  useCommonCmpasModuleSectionListQuery,
  useCommonCmpasModuleSectionCreateMutation,
  useCommonCmpasModuleSectionRetrieveQuery,
  useCommonCmpasModuleSectionUpdateMutation,
  useCommonCmpasModuleSectionPartialUpdateMutation,
  useCommonFirmMembersListQuery,
  useCommonFirmMembersRetrieveQuery,
  useCommonFirmMembersUpdateMutation,
  useCommonFirmMembersPartialUpdateMutation,
  useCommonFirmMembersDestroyMutation,
  useCommonHealthRetrieveQuery,
  useCommonInvitationsListQuery,
  useCommonInvitationsCreateMutation,
  useCommonInvitationsRetrieveQuery,
  useCommonInvitationsUpdateMutation,
  useCommonInvitationsPartialUpdateMutation,
  useCommonInvitationsDestroyMutation,
  useCommonPageSectionListQuery,
  useCommonPageSectionCreateMutation,
  useCommonPageSectionRetrieveQuery,
  useCommonPageSectionUpdateMutation,
  useCommonPageSectionPartialUpdateMutation,
  useCommonPageSelectionBlockListQuery,
  useCommonPageSelectionBlockCreateMutation,
  useCommonPageSelectionBlockRetrieveQuery,
  useCommonPageSelectionBlockUpdateMutation,
  useCommonPageSelectionBlockPartialUpdateMutation,
  useCommonPageSelectionBlockTypeListQuery,
  useCommonPageSelectionBlockTypeCreateMutation,
  useCommonPageSelectionBlockTypeRetrieveQuery,
  useCommonPageSelectionBlockTypeUpdateMutation,
  useCommonPageSelectionBlockTypePartialUpdateMutation,
  useCommonRoleAllowedSectionsListQuery,
  useCommonRoleHierarchyListQuery,
  useCommonRoleHierarchyRetrieveQuery,
  useCommonRoleTypesListQuery,
  useCommonRoleTypesCreateMutation,
  useCommonRoleTypesRetrieveQuery,
  useCommonRoleTypesUpdateMutation,
  useCommonRoleTypesPartialUpdateMutation,
  useCommonRolesListQuery,
  useCommonRolesCreateMutation,
  useCommonRolesRetrieveQuery,
  useCommonRolesUpdateMutation,
  useCommonRolesPartialUpdateMutation,
  useContentDiclosureSubCategoriesListQuery,
  useContentDiclosureSubCategoriesRetrieveQuery,
  useContentFirmDisclosureOverrideListQuery,
  useContentFirmDisclosureOverrideRetrieveQuery,
  useContentFirmDisclosureSectionBlockOverrideListQuery,
  useContentFirmDisclosureSectionBlockOverrideRetrieveQuery,
  useContentFirmTargetBaseContentOverrideListQuery,
  useContentFirmTargetBaseContentOverrideRetrieveQuery,
  useContentGetTargetDocumentRetrieveQuery,
  useContentPageContentListQuery,
  useContentTargetsListQuery,
  useContentTargetsRetrieveQuery,
  useDataProvidersGicsIndustrySubGroupListQuery,
  useDataProvidersPlanAccountsLinkRayJayExternalCreateMutation,
  useDataProvidersPlanAccountsLinkSchwabExternalCreateMutation,
  useDataProvidersSearchRaymondAccountsListQuery,
  useDataProvidersSearchRaymondAccountsByHouseholdListQuery,
  useDataProvidersSearchRaymondHouseholdsListQuery,
  useDataProvidersSearchSchwabAccountsListQuery,
  useDataProvidersStyleBoxListQuery,
  useNotificationsNotificationListQuery,
  useNotificationsNotificationRetrieveQuery,
  useNotificationsNotificationUpdateMutation,
  useNotificationsNotificationPartialUpdateMutation,
  useNotificationsNotificationGetReadNotificationsListQuery,
  useNotificationsNotificationGetSnoozedNotificationsListQuery,
  useNotificationsNotificationLevelListQuery,
  useNotificationsNotificationLevelRetrieveQuery,
  useNotificationsNotificationSettingListQuery,
  useNotificationsNotificationSettingRetrieveQuery,
  useNotificationsNotificationSettingUpdateMutation,
  useNotificationsNotificationSettingPartialUpdateMutation,
  useNotificationsNotificationTierThresholdListQuery,
  useNotificationsNotificationTierThresholdRetrieveQuery,
  useNotificationsNotificationTierThresholdUpdateMutation,
  useNotificationsNotificationTierThresholdPartialUpdateMutation,
  useNotificationsNotificationTypeListQuery,
  useNotificationsNotificationTypeRetrieveQuery,
  usePortfolioBenchmarkEquityStyleBoxHeatmapListQuery,
  usePortfolioConstraintChoicesListQuery,
  usePortfolioConstraintChoicesRetrieveQuery,
  usePortfolioGetPortfolioModuleDataCreateQuery,
  usePortfolioOptimizerDynamicRulesListQuery,
  usePortfolioOptimizerTemplatesListQuery,
  usePortfolioOptimizerTemplatesCreateMutation,
  usePortfolioOptimizerTemplatesRetrieveQuery,
  usePortfolioOptimizerTemplatesUpdateMutation,
  usePortfolioOptimizerTemplatesPartialUpdateMutation,
  usePortfolioOptimizerTemplatesDestroyMutation,
  usePortfolioOptimizerTemplatesLoadTemplateUpdateMutation,
  usePortfolioOptimizerTemplatesLoadJobUpdateMutation,
  usePortfolioPortfolioGroupListQuery,
  usePortfolioPortfolioGroupCreateMutation,
  usePortfolioPortfolioGroupRetrieveQuery,
  usePortfolioPortfolioGroupUpdateMutation,
  usePortfolioPortfolioGroupPartialUpdateMutation,
  usePortfolioPortfolioGroupDestroyMutation,
  usePortfolioPortfolioGroupVersionListQuery,
  usePortfolioPortfolioGroupVersionRetrieveQuery,
  usePortfolioPortfolioGroupVersionUpdateMutation,
  usePortfolioPortfolioGroupVersionPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionAssetClassesListQuery,
  usePortfolioPortfolioGroupVersionAssetClassesRetrieveQuery,
  usePortfolioPortfolioGroupVersionAssetClassesUpdateMutation,
  usePortfolioPortfolioGroupVersionAssetClassesPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesListQuery,
  usePortfolioPortfolioGroupVersionModulesCreateMutation,
  usePortfolioPortfolioGroupVersionModulesConstraintsListQuery,
  usePortfolioPortfolioGroupVersionModulesConstraintsRetrieveQuery,
  usePortfolioPortfolioGroupVersionModulesConstraintsUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesConstraintsPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesConstraintsAcListQuery,
  usePortfolioPortfolioGroupVersionModulesConstraintsAcRetrieveQuery,
  usePortfolioPortfolioGroupVersionModulesConstraintsAcUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesConstraintsAcPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesDataListQuery,
  usePortfolioPortfolioGroupVersionModulesDataAllocationsListQuery,
  usePortfolioPortfolioGroupVersionModulesDataAllocationsRetrieveQuery,
  usePortfolioPortfolioGroupVersionModulesDataAllocationsUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesDataRetrieveQuery,
  usePortfolioPortfolioGroupVersionModulesDataUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesDataPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesDataMakeSelectedUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesRetrieveQuery,
  usePortfolioPortfolioGroupVersionModulesUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesDestroyMutation,
  usePortfolioPortfolioGroupVersionOptimizerAllConsListQuery,
  usePortfolioPortfolioGroupVersionOptimizerAllConsRetrieveQuery,
  usePortfolioPortfolioGroupVersionOptimizerJobsListQuery,
  usePortfolioPortfolioGroupVersionOptimizerJobsCreateMutation,
  usePortfolioPortfolioGroupVersionOptimizerJobsRetrieveQuery,
  usePortfolioPortfolioGroupVersionOptimizerJobsUpdateMutation,
  usePortfolioPortfolioGroupVersionOptimizerJobsPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionOptimizerJobsDestroyMutation,
  usePortfolioPortfolioGroupVersionOptimizerSettingsListQuery,
  usePortfolioPortfolioGroupVersionOptimizerSettingsCreateMutation,
  usePortfolioPortfolioGroupVersionOptimizerSettingsRetrieveQuery,
  usePortfolioPortfolioGroupVersionOptimizerSettingsUpdateMutation,
  usePortfolioPortfolioGroupVersionOptimizerSettingsPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionStatusListQuery,
  usePortfolioPortfolioGroupVersionStatusRetrieveQuery,
  usePortfolioPortfolioModuleDataEquityStyleBoxWeightsListQuery,
  usePortfolioPortfolioModuleDataTypeListQuery,
  usePortfolioPortfolioModuleDataTypeRetrieveQuery,
  usePortfolioRunAutoOptimizationsRetrieveMutation,
  usePortfolioRunDynamicOptimizationsCreateMutation,
  useQuestionnaireClientTierListQuery,
  useQuestionnaireClientTierRetrieveQuery,
  useQuestionnaireClientsListQuery,
  useQuestionnaireClientsCreateMutation,
  useQuestionnaireClientsNotesListQuery,
  useQuestionnaireClientsNotesCreateMutation,
  useQuestionnaireClientsNotesRetrieveQuery,
  useQuestionnaireClientsNotesUpdateMutation,
  useQuestionnaireClientsNotesPartialUpdateMutation,
  useQuestionnaireClientsNotesDestroyMutation,
  useQuestionnaireClientsRetrieveQuery,
  useQuestionnaireClientsUpdateMutation,
  useQuestionnaireClientsPartialUpdateMutation,
  useQuestionnaireClientsDestroyMutation,
  useQuestionnaireClientsCurrentAllocationDetailRetrieveQuery,
  useQuestionnaireExternalBlackDiamondConnectionCreateMutation,
  useQuestionnaireExternalBlackDiamondConnectionDestroyMutation,
  useQuestionnaireExternalBlackDiamondPortfolioAccountsListQuery,
  useQuestionnaireExternalBlackDiamondSearchAccountsListQuery,
  useQuestionnaireExternalBlackDiamondSearchPortfoliosListQuery,
  useQuestionnaireExternalCollegeDataRetrieveQuery,
  useQuestionnaireExternalCollegeStatisticsRetrieveQuery,
  useQuestionnaireExternalListAvailableIntegratorsListQuery,
  useQuestionnaireGetLifeExpectancyRetrieveQuery,
  useQuestionnairePlanListQuery,
  useQuestionnairePlanCreateMutation,
  useQuestionnairePlanRetrieveQuery,
  useQuestionnairePlanUpdateMutation,
  useQuestionnairePlanPartialUpdateMutation,
  useQuestionnairePlanDestroyMutation,
  useQuestionnairePlanCopyUpdateMutation,
  useQuestionnairePlanOptimizedSocialSecurityRetrieveQuery,
  useQuestionnairePlanRiskRetrieveQuery,
  useQuestionnairePlanRiskUpdateMutation,
  useQuestionnairePlanSavingsThisYearRetrieveQuery,
  useQuestionnairePlanSpousalBenefitRetrieveQuery,
  useQuestionnairePlanSpousalBenefitUpdateMutation,
  useQuestionnairePlanAccountsLinkExternalCreateMutation,
  useQuestionnairePlanAccountsListQuery,
  useQuestionnairePlanAccountsCreateMutation,
  useQuestionnairePlanAccountsRetrieveQuery,
  useQuestionnairePlanAccountsUpdateMutation,
  useQuestionnairePlanAccountsPartialUpdateMutation,
  useQuestionnairePlanAccountsDestroyMutation,
  useQuestionnairePlanAdditionalIncomeListQuery,
  useQuestionnairePlanAdditionalIncomeCreateMutation,
  useQuestionnairePlanAdditionalIncomeRetrieveQuery,
  useQuestionnairePlanAdditionalIncomeUpdateMutation,
  useQuestionnairePlanAdditionalIncomePartialUpdateMutation,
  useQuestionnairePlanAdditionalIncomeDestroyMutation,
  useQuestionnairePlanDeriskListQuery,
  useQuestionnairePlanDeriskCreateMutation,
  useQuestionnairePlanDeriskRetrieveQuery,
  useQuestionnairePlanDeriskUpdateMutation,
  useQuestionnairePlanDeriskPartialUpdateMutation,
  useQuestionnairePlanDeriskDestroyMutation,
  useQuestionnairePlanGoalsListQuery,
  useQuestionnairePlanGoalsCreateMutation,
  useQuestionnairePlanGoalsRetrieveQuery,
  useQuestionnairePlanGoalsUpdateMutation,
  useQuestionnairePlanGoalsPartialUpdateMutation,
  useQuestionnairePlanGoalsDestroyMutation,
  useQuestionnairePlanHealthcareListQuery,
  useQuestionnairePlanHealthcareCreateMutation,
  useQuestionnairePlanHealthcareRetrieveQuery,
  useQuestionnairePlanHealthcareUpdateMutation,
  useQuestionnairePlanHealthcarePartialUpdateMutation,
  useQuestionnairePlanHealthcareDestroyMutation,
  useQuestionnairePlanRealEstateListQuery,
  useQuestionnairePlanRealEstateCreateMutation,
  useQuestionnairePlanRealEstateRetrieveQuery,
  useQuestionnairePlanRealEstateUpdateMutation,
  useQuestionnairePlanRealEstatePartialUpdateMutation,
  useQuestionnairePlanRealEstateDestroyMutation,
  useQuestionnairePlanRelationshipsListQuery,
  useQuestionnairePlanRelationshipsCreateMutation,
  useQuestionnairePlanRelationshipsRetrieveQuery,
  useQuestionnairePlanRelationshipsUpdateMutation,
  useQuestionnairePlanRelationshipsPartialUpdateMutation,
  useQuestionnairePlanRelationshipsDestroyMutation,
  useQuestionnairePlanSocialSecurityListQuery,
  useQuestionnairePlanSocialSecurityCreateMutation,
  useQuestionnairePlanSocialSecurityRetrieveQuery,
  useQuestionnairePlanSocialSecurityUpdateMutation,
  useQuestionnairePlanSocialSecurityPartialUpdateMutation,
  useQuestionnairePlanSocialSecurityDestroyMutation,
  useQuestionnaireRelationTypeListQuery,
  useQuestionnaireRelationTypeRetrieveQuery,
  useQuestionnaireRiskQuestionListQuery,
  useQuestionnaireRiskQuestionRetrieveQuery,
  useQuestionnaireTaxPurposeListQuery,
  useQuestionnaireTaxPurposeRetrieveQuery,
  useSimulationJobHistoryListCreateQuery,
  useSimulationMarketDataFixedIncomeRetrieveQuery,
  useSimulationQaReportCorrelationsCreateQuery,
  useSimulationQaReportReturnsNominalCreateQuery,
  useSimulationQaReportReturnsRealCreateQuery,
  useSimulationQaReportSimAnalyzePathsCreateQuery,
  useSimulationQaReportSimDataFilterCreateMutation,
  useSimulationQaReportSimPathCalcualtionDataCreateQuery,
  useSimulationQaReportSummaryCreateQuery,
  useSimulationQaReportYieldsCreateQuery,
  useSimulationSettingInflationExpectedMeanListQuery,
  useSimulationSettingInflationExpectedMeanCreateMutation,
  useSimulationSettingInflationExpectedMeanRetrieveQuery,
  useSimulationSettingInflationExpectedMeanUpdateMutation,
  useSimulationSettingInflationExpectedMeanPartialUpdateMutation,
  useSimulationSimJobYieldCurvesByPercentileListQuery,
  useSimulationSimJobYieldCurvesByYearListQuery,
  useSimulationSimYieldCurvesListQuery,
  useSimulationSimulartionGroupDetailsCreateQuery,
  useSimulationSimulationJobListQuery,
  useSimulationSimulationJobRetrieveQuery,
  useSimulationSimulationJobGetCondensedSummaryStatisticsListQuery,
  useSimulationSimulationJobGetExtendedStatisticsRetrieveQuery,
  useSimulationSimulationJobGetFiftyPercentileListQuery,
  useSimulationSimulationJobGetSummaryStatisticsListQuery,
  useSimulationSimulationJobAvailableSimulationGroupsListQuery,
  useSimulationSimulationJobLatestSimulationRetrieveQuery,
  useSimulationSimulationJobAdminListQuery,
  useSimulationSimulationJobAdminCreateMutation,
  useSimulationSimulationJobAdminRetrieveQuery,
  useSimulationSimulationJobAdminUpdateMutation,
  useSimulationSimulationJobAdminPartialUpdateMutation,
  useSimulationSimulationJobAdminPublishUpdateMutation,
  useSimulationSimulationModelTypeListQuery,
  useSimulationSimulationModelTypeRetrieveQuery,
  useSimulationSimulationModelVersionListQuery,
  useSimulationSimulationOutputDebugFieldsListQuery,
  useSimulationSimulationSettingListQuery,
  useSimulationSimulationSettingRetrieveQuery,
  useSimulationSimulationSettingUpdateMutation,
  useSimulationSimulationSettingPartialUpdateMutation,
} = injectedRtkApi;
