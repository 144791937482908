import { MenuItem } from "@mui/material";
import { useRelationTypes } from "app/useRelationTypes";
import { CarSelectField, CarSelectFieldProps } from "./Inputs";

export interface CarRelationFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  showPrimary?: boolean;
  showSecondary?: boolean;
  value?: string;
  onChange: (value?: string) => void;
}

export const CarRelationField = ({
  value,
  onChange,
  showPrimary,
  showSecondary,
  ...props
}: CarRelationFieldProps) => {
  const data = useRelationTypes();

  return (
    <CarSelectField {...props} value={value} onChange={onChange}>
      {data.items
        .filter((i) => {
          switch (i.id) {
            case data.primaryTypeId:
              return showPrimary;
            case data.secondaryTypeId:
              return showSecondary;
            default:
              return true;
          }
        })
        .map((i) => (
          <MenuItem key={i.id} value={i.id}>
            {i.label}
          </MenuItem>
        ))}
    </CarSelectField>
  );
};
