import {
  CarAccordion,
  CarAccordionDetails,
  CarAccordionSummary,
} from "components/Accordion";
import { Box, BoxProps, MenuItem, Typography, styled } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { PropsWithChildren, memo } from "react";
import { CarSelectField } from "components/Inputs";
import { CarDownloadButton } from "components/Buttons";
import {
  CarSimulationSummaryTable,
  useSimulationABTestSummary,
} from "./useSimulationABTestSummary";
import { SimulationABTestSummaryTable } from "./SimulationABTestSummaryTable";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";

const StyledAccordionSummary = styled(CarAccordionSummary)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(2),
  },
}));

type CollapseSectionProps = PropsWithChildren<{
  sx?: BoxProps["sx"];
  label: string;
}>;

export const CollapseSection = ({
  label,
  children,
  sx,
}: CollapseSectionProps) => (
  <CarAccordion
    sx={sx}
    defaultExpanded
    TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
  >
    <StyledAccordionSummary sx={{ backgroundColor: "gray1" }}>
      <Typography variant="h6SemiBold">{label}</Typography>
    </StyledAccordionSummary>
    <CarAccordionDetails
      sx={{
        p: 4,
        backgroundColor: "white",
        border: "solid 1px",
        borderColor: "gray3",
        borderTopWidth: 0,
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
      }}
    >
      {children}
    </CarAccordionDetails>
  </CarAccordion>
);

interface SimulationABTestSummaryProps {
  simulationJobIdA: string;
  simulationJobIdB: string;
}

const TableSection = ({ table }: { table: CarSimulationSummaryTable }) => {
  return (
    <CollapseSection label={table.name}>
      <SimulationABTestSummaryTable table={table} />
    </CollapseSection>
  );
};

export const SimulationABTestSummary = memo(
  (props: SimulationABTestSummaryProps) => {
    const simulationSummary = useSimulationABTestSummary({
      simulationJobIdA: props.simulationJobIdA,
      simulationJobIdB: props.simulationJobIdB,
    });

    const pageContent = usePageContent();

    return (
      <TablePlaceHolder
        sx={{ mx: 7, mt: 5 }}
        isLoading={simulationSummary.isLoading || pageContent.isLoading}
        rows={8}
      >
        <CarPageContent
          sx={{ mb: 3 }}
          content={pageContent.getContent(
            CarPageContentCode.SIMULATION_ANALYSIS_AB_TEST_SUMMARY,
          )}
          isWhiteContext
        />
        <Box sx={{ mt: 1, display: "flex", gap: 5, alignItems: "flex-end" }}>
          <CarSelectField
            label="Asset Class Groupings"
            sx={{
              width: 216,
              ".MuiInputLabel-root": {
                fontSize: "16px",
              },
              ".MuiOutlinedInput-root": {
                marginTop: "28px",
                bgcolor: "white",
              },
            }}
            value={simulationSummary.assetClassGroupSelector.value}
            onChange={simulationSummary.assetClassGroupSelector.onChange}
          >
            {simulationSummary.assetClassGroupSelector.items.map((i) => (
              <MenuItem key={i.code} value={i.code}>
                {i.name}
              </MenuItem>
            ))}
          </CarSelectField>
          <CarDownloadButton
            sx={{ ml: "auto", mb: 0.5 }}
            onClick={simulationSummary.handleDownloadReports}
          >
            Download Reports
          </CarDownloadButton>
        </Box>
        {simulationSummary.tables.map((table, idx) => (
          <TableSection key={idx} table={table} />
        ))}
      </TablePlaceHolder>
    );
  },
);
