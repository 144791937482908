import {
  usePortfolioPortfolioGroupVersionModulesConstraintsAcUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesConstraintsUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionOptimizerJobsCreateMutation,
  usePortfolioPortfolioGroupVersionOptimizerSettingsCreateMutation,
  usePortfolioPortfolioGroupVersionOptimizerSettingsListQuery,
  usePortfolioPortfolioGroupVersionOptimizerSettingsUpdateMutation,
} from "api/carApi.generated";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import {
  CarPortfolioConstrAcValue,
  CarPortfolioConstrHorizonValue,
  CarPortfolioConstrValue,
  usePortfolioGroupVersionConstraints,
} from "./usePortfolioGroupVersionConstraints";
import { useEffect, useRef } from "react";
import { useReturnScenarios } from "app/useReturnScenarios";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { checkDefined } from "utils";
import { useDialog } from "app/useDialog";
import {
  OptimizePortfolioGroupOverlay,
  OptimizePortfolioGroupOverlayRef,
} from "./OptimizePortfolioGroupOverlay";

export const MAX_TOTAL_EQUITY = "MAX_TOTAL_EQUITY";

export const usePortfolioGroupNewOptimizer = () => {
  const dialog = useDialog();
  const overlayRef = useRef<OptimizePortfolioGroupOverlayRef>();

  const { portfolioGroupId } = usePortfolioGroupParams();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;

  const [createJob] =
    usePortfolioPortfolioGroupVersionOptimizerJobsCreateMutation();

  const returnScenarios = useReturnScenarios();
  const optSettingsList =
    usePortfolioPortfolioGroupVersionOptimizerSettingsListQuery(
      {
        portfolioGroupVersionId: portfolioGroupVersionId ?? "",
      },
      { skip: !portfolioGroupVersionId },
    );
  const [optSettingsCreate] =
    usePortfolioPortfolioGroupVersionOptimizerSettingsCreateMutation();

  const isOptSettingsCreated = useRef<boolean>(false);

  useEffect(() => {
    if (!portfolioGroupVersionId) {
      return;
    }

    const return_scenario = returnScenarios.items[0]?.id ?? ""; // per Jorgen: fill with the first item for now

    if (
      !isOptSettingsCreated.current &&
      !optSettingsList.isLoading &&
      !optSettingsList.isError &&
      return_scenario &&
      (optSettingsList.data?.length ?? 0) === 0
    ) {
      isOptSettingsCreated.current = true;
      optSettingsCreate({
        portfolioGroupVersionId,
        portfolioGroupVersionOptimizerSettings: {
          return_scenario,
          objective_function: "future_value",
        },
      });
    }
  }, [
    portfolioGroupVersionId,
    optSettingsCreate,
    optSettingsList.isLoading,
    optSettingsList.data,
    optSettingsList.isError,
    returnScenarios.items,
  ]);

  const [optSettingsUpdate] =
    usePortfolioPortfolioGroupVersionOptimizerSettingsUpdateMutation();

  const optSettingsItem = optSettingsList.data?.[0];
  const isMaxReturn = optSettingsItem?.objective_function === "future_value";
  const handleIsMaxReturnChange = (value: boolean) => {
    if (optSettingsItem) {
      optSettingsUpdate({
        portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
        id: optSettingsItem.id ?? "",
        portfolioGroupVersionOptimizerSettings: {
          ...optSettingsItem,
          objective_function: value ? "future_value" : "active_return",
        },
      });
    }
  };

  const minAllocation = optSettingsItem?.minimum_allocation;

  const handleMinAllocationChange = (minimum_allocation?: number) => {
    if (optSettingsItem) {
      optSettingsUpdate({
        portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
        id: optSettingsItem.id ?? "",
        portfolioGroupVersionOptimizerSettings: {
          ...optSettingsItem,
          minimum_allocation,
        },
      });
    }
  };

  const versionConstr = usePortfolioGroupVersionConstraints(
    portfolioGroupVersionId,
  );

  const [constModulePartialUpdate] =
    usePortfolioPortfolioGroupVersionModulesPartialUpdateMutation();

  const handleConstrHorizonChange = (value: CarPortfolioConstrHorizonValue) => {
    constModulePartialUpdate({
      id: value.moduleId,
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      patchedPortfolioModule: {
        overridden_assigned_al_horizon: value.value,
      },
    });
  };

  const [constrUpdate] =
    usePortfolioPortfolioGroupVersionModulesConstraintsUpdateMutation();

  const handleConstrChange = (value: CarPortfolioConstrValue) => {
    constrUpdate({
      id: value.id,
      moduleId: value.moduleId,
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      portfolioModuleConstraints: {
        id: value.id,
        portfolio_module_id: value.moduleId,
        constraint_type: value.constraintTypeId,
        constraint_type_code: value.constraintTypeCode,
        value: value.value,
        percentile: value.percentile, // Math.min(value.percentile, 1), // temp fix
        enabled: value.enabled,
      },
    });
  };

  const [acConstrUpdate] =
    usePortfolioPortfolioGroupVersionModulesConstraintsAcUpdateMutation();

  const handleAcConstrChange = (value: CarPortfolioConstrAcValue) => {
    acConstrUpdate({
      id: value.id,
      moduleId: value.moduleId,
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      portfolioModuleConstraintsAc: {
        id: value.id,
        asset_class_4: value.assetClass4Id,
        portfolio_module_id: value.moduleId,
        max_allocation: value.maxAllocation,
        min_allocation: value.minAllocation,
      },
    });
  };

  const handleLaunch = async (title: string) => {
    dialog(OptimizePortfolioGroupOverlay, {
      portfolioGroupId,
      customRef: (value: OptimizePortfolioGroupOverlayRef | null) => {
        overlayRef.current = value ? value : undefined;
      },
    });

    const createJobResult = await createJob({
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      portfolioGroupVersionOptimizationJobRoot: {
        portfolio_version_id: portfolioGroupVersionId,
        title,
      },
    });

    if ("data" in createJobResult && createJobResult.data.id) {
      overlayRef.current?.waitAndClose(createJobResult.data.id);
    } else {
      overlayRef.current?.close();
    }

    return createJobResult;
  };

  return {
    isLoading:
      groupInfo.isLoading ||
      returnScenarios.isLoading ||
      optSettingsList.isLoading ||
      versionConstr.isLoading,

    simulationSuffix: groupInfo.getSimulationSuffix(true),

    isMaxReturn,
    handleIsMaxReturnChange,

    minAllocation,
    handleMinAllocationChange,

    table: versionConstr.table,
    handleConstrHorizonChange,
    handleConstrChange,
    handleAcConstrChange,

    canLaunch: true, // CU-86851uqjx versionConstr.table.horizonRow.values.every((i) => i.value > 0),
    handleLaunch,
  };
};
