import { Navigate, Route, Routes } from "react-router-dom";
import {
  RootPage,
  RootPageHeader,
  RootPageTransparentPaper,
  RootPageHeaderLink,
} from "components/RootPage";
import { assetClassAnalysisAnalyzePages } from "route.const";
import { AssetClassAnalysisSummary } from "./AssetClassAnalysisSummary";
import { AssetClassAnalysisPerformanceRisk } from "./AssetClassAnalysisPerformanceRisk";
import { AssetClassAnalysisSimulatedForwardProbabilities } from "./AssetClassAnalysisSimulatedForwardProbabilities";
import { AssetClassAnalysisDistanceFromTrend } from "./AssetClassAnalysisDistanceFromTrend";
import { AssetClassAnalysisSectorAnalysis } from "./AssetClassAnalysisSectorAnalysis";
import { AssetClassAnalysisMultiPeriodReturnComparison } from "./AssetClassAnalysisMultiPeriodReturnComparison";
import { SourceDataAsOf } from "pages/assetAllocation/SourceDataAsOf";

export const AssetClassAnalysisAnalyze = () => {
  return (
    <RootPage sx={{ backgroundColor: "white" }}>
      <RootPageHeader
        isSmall
        title="Asset Class Analysis - Analyze"
        links={
          <>
            <RootPageHeaderLink
              label="Summary"
              url={assetClassAnalysisAnalyzePages.summary}
            />
            <RootPageHeaderLink
              label="Performance Risk"
              url={assetClassAnalysisAnalyzePages.performanceRisk}
            />
            <RootPageHeaderLink
              label="Multi-Period Return"
              url={assetClassAnalysisAnalyzePages.multiPeriodReturnComparison}
            />
            <RootPageHeaderLink
              label="Simulated Forward Probabilities"
              url={assetClassAnalysisAnalyzePages.simulatedForwardProbabilities}
            />
            <RootPageHeaderLink
              label="Distance From Trend"
              url={assetClassAnalysisAnalyzePages.distanceFromTrend}
            />
            <RootPageHeaderLink
              label="Sector Analysis"
              url={assetClassAnalysisAnalyzePages.sectorAnalysis}
            />
          </>
        }
      />
      <RootPageTransparentPaper>
        <SourceDataAsOf />
        <Routes>
          <Route
            path={assetClassAnalysisAnalyzePages.summary}
            element={<AssetClassAnalysisSummary />}
          />
          <Route
            path={assetClassAnalysisAnalyzePages.performanceRisk}
            element={<AssetClassAnalysisPerformanceRisk />}
          />
          <Route
            path={assetClassAnalysisAnalyzePages.multiPeriodReturnComparison}
            element={<AssetClassAnalysisMultiPeriodReturnComparison />}
          />
          <Route
            path={assetClassAnalysisAnalyzePages.simulatedForwardProbabilities}
            element={<AssetClassAnalysisSimulatedForwardProbabilities />}
          />
          <Route
            path={assetClassAnalysisAnalyzePages.distanceFromTrend}
            element={<AssetClassAnalysisDistanceFromTrend />}
          />
          <Route
            path={assetClassAnalysisAnalyzePages.sectorAnalysis}
            element={<AssetClassAnalysisSectorAnalysis />}
          />
          <Route
            path="*"
            element={
              <Navigate to={assetClassAnalysisAnalyzePages.summary} replace />
            }
          />
        </Routes>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
