import { Box, Fade, Typography } from "@mui/material";
import { UseLogin } from "./useLogin";
import { LoginButton, LoginField } from "./LoginComponents";
import { useDialog } from "app/useDialog";
import { TermsOfUseDialog } from "./TermsOfUseDialog";

export const StartTrialForm = ({ data }: { data: UseLogin }) => {
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Enter") {
      data.startTrial();
    }
  };

  const dialog = useDialog();

  const handleClickTermsOfUse = () => dialog(TermsOfUseDialog, {});

  return (
    <Fade in>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h1SSemiBold">Start Free Trial</Typography>
        <Typography variant="h6SRegular" sx={{ mt: 1, fontSize: 15 }}>
          Trial lasts for two weeks, then your account wil be unavailable until
          payment is received.
        </Typography>
        <Box
          sx={{
            mt: 3,
            display: "grid",
            columnGap: 8.25,
            rowGap: 2.25,
            gridTemplateRows: "1fr 1fr 1fr",
            gridTemplateColumns: "1fr 1fr",
            gridAutoFlow: "column",
          }}
        >
          <LoginField
            placeholder="First Name"
            value={data.trialForm.firstName}
            onChange={(firstName) =>
              data.setTrialForm((value) => ({ ...value, firstName }))
            }
            required={data.trialForm.requiredMode}
            inputProps={{
              type: "text",
              autoComplete: "new-user-full-name",
            }}
            onKeyDown={handleKeyDown}
          />
          <LoginField
            placeholder="Last Name"
            value={data.trialForm.lastName}
            onChange={(lastName) =>
              data.setTrialForm((value) => ({ ...value, lastName }))
            }
            required={data.trialForm.requiredMode}
            inputProps={{
              type: "text",
              autoComplete: "new-user-full-name",
            }}
            onKeyDown={handleKeyDown}
          />
          <LoginField
            placeholder="Firm Name"
            value={data.trialForm.firmName}
            onChange={(firmName) =>
              data.setTrialForm((value) => ({ ...value, firmName }))
            }
            required={data.trialForm.requiredMode}
            inputProps={{
              type: "text",
            }}
            onKeyDown={handleKeyDown}
          />
          {/* <LoginField
            placeholder="CRD #"
            value={data.trialForm.crdNumber?.toString()}
            onChange={(value) => {
              const parsedValue = value
                ? Number.parseInt(value, 10)
                : Number.NaN;

              data.setTrialForm((value) => ({
                ...value,
                crdNumber: Number.isNaN(parsedValue) ? undefined : parsedValue,
              }));
            }}
            required={data.trialForm.requiredMode}
            inputProps={{
              type: "text",
            }}
            onKeyDown={handleKeyDown}
          /> */}
          <LoginField
            placeholder="Email"
            value={data.trialForm.email}
            onChange={(email) =>
              data.setTrialForm((value) => ({ ...value, email }))
            }
            required={data.trialForm.requiredMode}
            autoFocus
            inputProps={{
              type: "email",
              autoComplete: "new-username",
            }}
            onKeyDown={handleKeyDown}
          />
          <LoginField
            placeholder="Password"
            value={data.trialForm.password}
            onChange={(password) =>
              data.setTrialForm((value) => ({ ...value, password }))
            }
            required={data.trialForm.requiredMode}
            inputProps={{
              type: "password",
              autoComplete: "off",
              spellCheck: false,
              autoCapitalize: "off",
            }}
            onKeyDown={handleKeyDown}
          />
          <LoginField
            placeholder="Confirm Password"
            value={data.trialForm.confirmPassword}
            onChange={(confirmPassword) =>
              data.setTrialForm((value) => ({
                ...value,
                confirmPassword,
              }))
            }
            required={data.trialForm.requiredMode}
            inputProps={{
              type: "password",
              autoComplete: "off",
              spellCheck: false,
              autoCapitalize: "off",
            }}
            onKeyDown={handleKeyDown}
          />
        </Box>
        <LoginButton
          sx={{
            mt: 11.5,
            alignSelf: "center",
            width: 200,
          }}
          onClick={data.startTrial}
        >
          Next
        </LoginButton>
        <Typography
          variant="par01Regular"
          sx={{
            mt: 1,
            mb: -2,
            alignSelf: "center",
            color: "gray9",
            fontSize: "13px",
            maxWidth: 220,
            textAlign: "center",
          }}
        >
          By clicking “Next”, you are agreeing to our{" "}
          <Box
            component="span"
            sx={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={handleClickTermsOfUse}
          >
            Terms of Use
          </Box>{" "}
          policy.
        </Typography>
      </Box>
    </Fade>
  );
};
