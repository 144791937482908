import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ComponentType } from "react";
import { RootState } from "./store";

interface IDialog {
  comp: ComponentType;
  props: Record<string, any>;
  id: string;
}

interface IDialogState {
  items: IDialog[];
}
const initialState: IDialogState = { items: [] };

export const dialogSlice = createSlice({
  name: "dialog",
  initialState,
  reducers: {
    showDialog: (state, action: PayloadAction<IDialog>) => {
      state.items.push(action.payload);
    },

    closeDialog: (state, action: PayloadAction<IDialog["id"]>) => {
      state.items = state.items.filter((i) => i.id !== action.payload);
    },
  },
});

export const { showDialog, closeDialog } = dialogSlice.actions;

export const selectDialog = (state: RootState) => state.dialog;

export const dialogReducer = dialogSlice.reducer;
