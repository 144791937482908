import jwtDecode, { JwtPayload } from "jwt-decode";
import { apiUrl } from "const";
import { clearAuth, refreshAuth } from "features/auth/authSlice";
import { CarAuthInfo } from "types";
import { debugLog } from "utils";
import { api, CustomTokenRefresh } from "./carApi.generated";

let _isRefreshingToken = false;

let _dispatch: any = undefined;

export const registerDispatch = (dispatch: any) => (_dispatch = dispatch);

const timeout = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const withoutTrailingSlash = (url: string) => url.replace(/\/$/, "");
const withoutLeadingSlash = (url: string) => url.replace(/^\//, "");
const isAbsoluteUrl = (url: string) => new RegExp(`(^|:)//`).test(url);

const joinUrls = (base: string | undefined, url: string | undefined) => {
  if (!base) {
    return url!;
  }
  if (!url) {
    return base;
  }

  if (isAbsoluteUrl(url)) {
    return url;
  }

  base = withoutTrailingSlash(base);
  url = withoutLeadingSlash(url);

  return `${base}/${url}`;
};

const refreshAccessToken = async (refreshToken: string) => {
  const decoded = jwtDecode<JwtPayload>(refreshToken);
  const refreshTokenExpireAt = new Date((decoded.exp ?? 0) * 1000);
  debugLog("Refresh token expiration time:", refreshTokenExpireAt);

  if (new Date() >= refreshTokenExpireAt) {
    debugLog("Refresh token expired.");
    throw new Error("Refresh token is expired");
  }

  const rawResponse = await fetch(
    joinUrls(apiUrl, `/api/common/auth/refresh/`),
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    }
  );

  return (await rawResponse.json()) as CustomTokenRefresh;
};

export const getAccessToken = async (store: any) => {
  do {
    await timeout(10); // let parallel requests wait until refresh is done
  } while (_isRefreshingToken);

  _isRefreshingToken = true;
  try {
    const auth = store.getState().auth as CarAuthInfo;
    if (auth.access) {
      const decoded = jwtDecode<JwtPayload>(auth.access);
      const accessTokenExpireAt = new Date((decoded.exp ?? 0) * 1000);
      // debugLog("Access token expiration time:", accessTokenExpireAt);

      if (new Date() >= accessTokenExpireAt) {
        debugLog("Access token expired. Refreshing...");
        try {
          const refreshResult = await refreshAccessToken(auth.refresh ?? "");
          const newAuth: CarAuthInfo = {
            ...auth,
            access: refreshResult.access,
          };
          _dispatch(refreshAuth(newAuth));
          return newAuth.access;
        } catch (e) {
          debugLog("Refresh token failed. Clearing authorization.", e);
          _dispatch(clearAuth());
          _dispatch(api.util.resetApiState());
          window.location.reload(); // reload will redirect us to login page
        }
      }
    }
    return auth.access;
  } finally {
    _isRefreshingToken = false;
  }
};
