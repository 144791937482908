import {
  SimulationPercentileYearsData,
  useSimulationQaReportReturnsNominalCreateQuery,
  useSimulationQaReportReturnsRealCreateQuery,
} from "api/carApi.generated";
import { useDialog } from "app/useDialog";
import {
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";
import { useLocalStorage } from "features/general/useLocalStorage";
import { useMemo } from "react";
import { DecimalOptions, formatDateTime, formatNumber, isDefined } from "utils";
import { SimulationDataExplorerDialog } from "./SimulationDataExplorerDialog";
import { useSimulationAssetClassSelector } from "./useSimulationAssetClassSelector";

export interface CarSimulationPercentileColumn {
  year: number;
  isExtended: boolean;
}

export interface CarSimulationPercentileValue {
  percentile: number;
  isExtended: boolean;
  year: number;
  sim?: number | null;
  his?: number | null;
  simLowerBound?: number | null;
  simUpperBound?: number | null;
}

export interface CarSimulationPercentileRow {
  percentile: number;
  values: CarSimulationPercentileValue[];
}

export interface CarSimulationPercentileTable {
  simulationJobId: string;
  assetClassCode?: string;
  columns: CarSimulationPercentileColumn[];
  rows: CarSimulationPercentileRow[];
}

export const simPercentileToTable = ({
  simulationJobId,
  assetClassCode,
  value,
}: {
  simulationJobId: string;
  assetClassCode?: string;
  value?: SimulationPercentileYearsData;
}): CarSimulationPercentileTable => {
  const columns: CarSimulationPercentileColumn[] = [
    ...(value?.years.map((year) => ({
      year,
      isExtended: false,
    })) ?? []),
    ...(value?.years_extended.map((year) => ({
      year,
      isExtended: true,
    })) ?? []),
  ];
  const table: CarSimulationPercentileTable = {
    simulationJobId,
    assetClassCode,
    columns,
    rows: Object.keys(value?.results ?? {}).map<CarSimulationPercentileRow>(
      (key) => ({
        percentile: Number.parseFloat(key),
        values: columns.map<CarSimulationPercentileValue>((col) => {
          const val = col.isExtended
            ? value?.results_extended[key]?.[col.year.toString()]
            : value?.results[key]?.[col.year.toString()];

          return {
            percentile: Number.parseFloat(key),
            isExtended: col.isExtended,
            year: col.year,
            his: val?.his,
            sim: val?.sim,
            simLowerBound: val?.sim_lower_bound,
            simUpperBound: val?.sim_upper_bound,
          };
        }),
      }),
    ),
  };

  return table;
};

export const getSimulationPercentileExcelTable = (params: {
  name: string;
  table: CarSimulationPercentileTable;
}): ExcelTable => {
  return {
    name: params.name,
    bands: [
      { label: "" },
      ...params.table.columns.flatMap((i) => [
        {
          label: i.year.toString(),
          span: 2,
        },
        {
          label: "",
        },
      ]),
    ],
    columns: [
      {
        label: "",
        format: ExcelDataFormat.percent,
      },
      ...params.table.columns.flatMap((i) => [
        {
          label: "Sim",
          format: ExcelDataFormat.percent,
        },
        {
          label: "Hist",
          format: ExcelDataFormat.percent,
        },
      ]),
    ],
    rows: params.table.rows.map((row) => [
      (row.percentile ?? 0) / 100,
      ...row.values.flatMap((val) => [val.sim, val.his]),
    ]),
  };
};

export interface CarSimulationStatItem {
  label: string;
  value?: number | null;
  format?: ExcelDataFormat;
  decimalOptions?: DecimalOptions;
}

export const useSimulationReturns = (params: { simulationJobId: string }) => {
  const assetClassSelector = useSimulationAssetClassSelector({
    storageKey: "useSimulationReturns_assetClassSelector",
    simulationJobId: params.simulationJobId,
  });

  const dialog = useDialog();

  const [isReal, setIsReal] = useLocalStorage<boolean>(
    "useSimulationReturns_isReal",
    true,
  );

  const assetClassCode = assetClassSelector.value ?? "";

  const returnsReal = useSimulationQaReportReturnsRealCreateQuery(
    {
      simulationQaSummaryReportRequestAssetClass: {
        simulation_job_id: params.simulationJobId,
        asset_class_code: assetClassCode ?? "",
      },
    },
    {
      skip: !isReal || !assetClassCode,
    },
  );

  const returnsNominal = useSimulationQaReportReturnsNominalCreateQuery(
    {
      simulationQaSummaryReportRequestAssetClass: {
        simulation_job_id: params.simulationJobId,
        asset_class_code: assetClassCode ?? "",
      },
    },
    {
      skip: isReal || !assetClassCode,
    },
  );

  const excelExport = useExcelExport();

  return useMemo(() => {
    const realData = returnsReal.data;
    const nominalData = returnsNominal.data;

    const table = simPercentileToTable({
      simulationJobId: params.simulationJobId,
      assetClassCode,
      value: isReal
        ? realData?.asset_classes.at(0)?.sim_percentile
        : nominalData?.asset_classes.at(0)?.sim_percentile,
    });

    const statResult = isReal
      ? realData?.asset_classes.at(0)?.stat_result
      : undefined;

    const tableStat: CarSimulationStatItem[] | undefined = statResult
      ? [
          {
            label: "10 - Year Expected Return",
            value: statResult?.ten_year_expected_return,
          },
          {
            label: "10 - Year Simulated Return (Mean) (Real)",
            value: statResult?.output_real_return_mean,
          },
          {
            label: "10 - Year Simulated Return (Mean) (Nominal)",
            value: statResult?.output_nominal_return_mean,
          },
          {
            label: "Input Volatility",
            value: statResult?.input_return_sigma,
          },
          {
            label: "Output Volatility (Nominal)",
            value: statResult?.output_nominal_return_sigma,
          },
        ]
      : undefined;

    const inflationTable = isReal
      ? simPercentileToTable({
          simulationJobId: params.simulationJobId,
          // assetClassCode: params.assetClassCode,
          value: realData?.cpi.sim_percentile,
        })
      : undefined;

    const inflationStatResult = isReal ? realData?.cpi.stat_result : undefined;

    const inflationTableStat: CarSimulationStatItem[] | undefined =
      inflationStatResult
        ? [
            {
              label: "10 Year Simulated Return (Mean)",
              value: inflationStatResult?.output_return_mean,
            },
            {
              label: "Output Volatility",
              value: inflationStatResult?.output_return_sigma,
            },
          ]
        : undefined;

    const assetClassName = assetClassSelector.selectedItem?.name ?? "";

    const handleDownloadReports = () => {
      const data = isReal ? realData : nominalData;
      excelExport.exportAllExcel({
        fileName: `Simulation Returns (${
          isReal ? "Real" : "Nominal"
        }) - ${data?.simulation_name} - ${formatDateTime(data?.simulation_date)
          .replaceAll("/", "-")
          .replaceAll(":", "-")} - ${assetClassName}`,
        tables: [
          getSimulationPercentileExcelTable({
            name: assetClassName,
            table,
          }),
          inflationTable
            ? getSimulationPercentileExcelTable({
                name: `Inflation`,
                table: inflationTable,
              })
            : undefined,
        ].filter(isDefined),
      });
    };

    const handleValueClick = (value: CarSimulationPercentileValue) => {
      const assetClass = assetClassSelector.items.find(
        (i) => i.code === table.assetClassCode,
      );

      if (!assetClass) {
        return;
      }

      const filterDataType = assetClass.isEquity
        ? "tri_sim"
        : isReal
        ? "ret_annual_real"
        : "ret_annual_nominal";

      dialog(SimulationDataExplorerDialog, {
        title: assetClassName,
        subTitle: `${formatNumber(
          value.percentile,
        )}th percentile for Returns (${isReal ? "Real" : "Nominal"}) in Year ${
          value.year
        }`,
        simulationJobId: table.simulationJobId,
        assetClassCode: table.assetClassCode,
        filterDataType,
        filterYear: value.year,
        filterValueGt: value.simLowerBound ?? undefined,
        filterValueLt: value.simUpperBound ?? undefined,
      });
    };

    return {
      isLoading: returnsReal.isLoading || returnsNominal.isLoading,
      isFetching: returnsReal.isFetching || returnsNominal.isLoading,
      isReal,
      setIsReal,
      table,
      tableStat,
      inflationTable,
      inflationTableStat,
      assetClassSelector,
      assetClassName,
      handleDownloadReports,
      handleValueClick,
    };
  }, [
    params.simulationJobId,
    assetClassCode,
    isReal,
    setIsReal,
    returnsReal,
    returnsNominal,
    assetClassSelector,
    excelExport,
    dialog,
  ]);
};
