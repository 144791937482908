import { usePortfolioPortfolioGroupVersionRetrieveQuery } from "api/carApi.generated";

export const usePortfolioGroupVersionModules = (params: {
  portfolioGroupVersionId?: string;
  hideCash?: boolean;
}) => {
  const version = usePortfolioPortfolioGroupVersionRetrieveQuery(
    {
      id: params.portfolioGroupVersionId ?? "",
    },
    {
      skip: !params.portfolioGroupVersionId,
    },
  );

  const isLoading = version.isLoading;

  const isFetching = version.isFetching;

  const items = Array.from(version.data?.portfolio_modules ?? [])
    .filter((i) => (params.hideCash ? !i.is_cash : true))
    .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0));

  return {
    isLoading,
    isFetching,
    items,
  };
};
