import { Collapse, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { CarIconAdd } from "icons";
import { UseClientSummary } from "./useClientSummary";
import { useNotes } from "./useNotes";
import { CarClientNote } from "types";
import { useRef, useState } from "react";
import { CarTextField } from "components/Inputs";
import { CarButton, CarLoadingButton } from "components/Buttons";
import { formatDate } from "utils";
import SimpleBar from "simplebar-react";
interface ClientSummaryNotesProps {
  clientSummary: UseClientSummary;
}

interface NoteItemProps {
  item: CarClientNote;
}

const NoteItem = (props: NoteItemProps) => (
  <Box
    sx={{
      border: "solid 1px",
      borderColor: "gray6",
      borderRadius: "5px",
      backgroundColor: "gray1",
      p: 2,
      minHeight: 100,
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Typography
      variant="mediumItalic"
      sx={{ fontSize: "11px", fontWeight: 600 }}
    >{`${formatDate(props.item.created_at)} - ${
      props.item.note_source
    }`}</Typography>
    <Typography variant="par01Regular" sx={{ mt: 2, whiteSpace: "pre-line" }}>
      {props.item.note_text}
    </Typography>
  </Box>
);

const EmptyNote = () => (
  <Box
    sx={{
      border: "solid 1px",
      borderColor: "gray6",
      borderRadius: "5px",
      backgroundColor: "gray1",
      p: 2,
      minHeight: 180,
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Typography variant="mediumItalic" sx={{ fontSize: "11px" }}>
      No information at this time
    </Typography>
  </Box>
);

export const ClientSummaryNotes = ({
  clientSummary,
}: ClientSummaryNotesProps) => {
  const notes = useNotes();
  const textRef = useRef<HTMLInputElement>(null);

  const [isAdding, setIsAdding] = useState(false);
  const [text, setText] = useState<string | undefined>("");
  const [isUpdating, setIsUpdating] = useState(false);

  const handleAdd = () => {
    setIsAdding(true);
    setText("");
    setTimeout(() => {
      textRef.current?.focus();
    }, 100);
  };

  const handleCancel = () => {
    setIsAdding(false);
  };

  const handleSave = async () => {
    if (text) {
      setIsUpdating(true);
      try {
        await notes.addNote("Client Summary", text.trim());
      } finally {
        setIsUpdating(false);
      }
      setIsAdding(false);
    }
  };

  const isLoading = clientSummary.isLoading || notes.isLoading;

  return (
    <RootPageBox sx={{ px: 2.5, py: 1.5, flexGrowth: "auto" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: 28,
          mb: 1,
        }}
      >
        <Typography variant="h5SSemiBold">Notes</Typography>
        {!isAdding && (
          <IconButton size="small" color="inherit" onClick={handleAdd}>
            <CarIconAdd
              sx={{ color: "caravelOrangePrimary", fontSize: "18px" }}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          position: "relative",
          flex: "auto",
          minHeight: 190,
        }}
      >
        <SimpleBar
          autoHide={false}
          forceVisible={notes.items.length > 1 ? "y" : undefined}
          style={{
            position: "absolute",
            width: "calc(100% + 14px)",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            paddingRight: "14px",
          }}
        >
          {!isLoading && (
            <Collapse in={!isAdding && notes.items.length === 0}>
              <EmptyNote />
            </Collapse>
          )}
          <Collapse in={isAdding}>
            <Box
              sx={{
                border: "solid 1px",
                borderColor: "gray6",
                borderRadius: "5px",
                backgroundColor: "gray1",
                p: 2,
                minHeight: 100,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 1.5,
                mb: notes.items.length > 0 ? 1.5 : 0,
              }}
            >
              <CarTextField
                sx={{
                  gridColumn: "1 / span 2",
                  ".MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                }}
                fullWidth
                value={text}
                onChange={setText}
                multiline
                rows={3}
                inputRef={textRef}
                onKeyDown={(e) => {
                  if (e.ctrlKey && e.key === "Enter") {
                    handleSave();
                  }
                }}
              />
              <CarLoadingButton
                sx={{ width: "auto" }}
                onClick={handleSave}
                isLoading={isUpdating}
              >
                Save
              </CarLoadingButton>
              <CarButton
                sx={{ width: "auto" }}
                variant="outlined"
                onClick={handleCancel}
              >
                Cancel
              </CarButton>
            </Box>
          </Collapse>
          <TablePlaceHolder isLoading={isLoading} rows={3}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
              {notes.items.map((i) => (
                <NoteItem key={i.id} item={i} />
              ))}
            </Box>
          </TablePlaceHolder>
        </SimpleBar>
      </Box>
    </RootPageBox>
  );
};
