import { CarDateExtraField } from "components/DateExtraField";
import { CarSavingsExtraField } from "components/SavingsExtraField";
import { CarAccount, CarSavings } from "types";
import { CarTable, CarTableColumn } from "components/Table";
import {
  changeAnnualSavings,
  deleteAnnualSavings,
  getAnnualSavingsValue,
} from "features/assets/account.utils";
import { CarCurrencyCalcField } from "components/NumberField";
import { useAccountTypes } from "app/useAccountTypes";

export interface SavingsTableProps {
  item: CarAccount;
  onChange: (item: CarAccount) => void;
}

export const SavingsTable = (props: SavingsTableProps) => {
  const items: Array<CarSavings> = props.item.savings ?? [];
  const { canAccountHaveEmployerMatch } = useAccountTypes();

  const handleChange = (item: CarSavings) => {
    props.onChange(changeAnnualSavings(props.item, item));
  };
  const handleDelete = (item: CarSavings) => {
    props.onChange(deleteAnnualSavings(props.item, item));
  };

  const columns: Array<CarTableColumn<CarSavings>> = [
    {
      id: "base_contribution",
      label: "*Client Contribution",
      fraction: 1,
      renderCell: ({ item, onChange }) => (
        <CarSavingsExtraField
          required
          value={item.base_contribution}
          onChange={(base_contribution) =>
            onChange({ ...item, base_contribution })
          }
          showMaxContribution={!!props.item.max_contribution_available}
        />
      ),
    },
    {
      id: "get_data",
      label: "Annual Savings",
      fraction: 1,
      renderCell: ({ item }) => (
        <CarCurrencyCalcField value={getAnnualSavingsValue(item)} />
      ),
    },
    {
      id: "start_date_caravel",
      label: "*Start Date",
      fraction: 1.1,
      renderCell: ({ item, onChange }) => (
        <CarDateExtraField
          required
          value={item.start_date_caravel}
          onChange={(start_date_caravel) =>
            onChange({ ...item, start_date_caravel })
          }
        />
      ),
    },
    {
      id: "end_date_caravel",
      label: "*End Date",
      fraction: 1.1,
      renderCell: ({ item, onChange }) => (
        <CarDateExtraField
          required
          value={item.end_date_caravel}
          onChange={(end_date_caravel) =>
            onChange({ ...item, end_date_caravel })
          }
        />
      ),
    },
  ];
  if (canAccountHaveEmployerMatch(props.item)) {
    columns.splice(2, 0, {
      id: "employer_match",
      label: "Employer Match",
      fraction: 1,
      renderCell: ({ item, onChange }) => (
        <CarSavingsExtraField
          value={item.employer_match}
          onChange={(employer_match) => onChange({ ...item, employer_match })}
          showMaxContribution={!!props.item.max_contribution_available}
        />
      ),
    });
  }
  return (
    <CarTable
      noGaps
      items={items}
      timeout={items.length <= 1 ? 0 : undefined}
      columns={columns}
      onChange={handleChange}
      onDelete={handleDelete}
      pendoPrefix="savings"
    />
  );
};
