import { Box } from "@mui/material";
import { FullScreenBackground } from "components/FullScreenBackground";
import { CarFooter } from "components/Footer";
import { useNavigate } from "react-router-dom";
import { LoginTransparentBox } from "pages/login/LoginComponents";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useCommonBillingBillingCheckStatusCreateQuery } from "api/carApi.generated";
import { useEffect } from "react";
import { rootPages } from "route.const";
import { useAppDispatch } from "app/hooks";
import { api } from "api/carApi.manual";

export const PaymentCompletePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, data } = useCommonBillingBillingCheckStatusCreateQuery();

  useEffect(() => {
    dispatch(api.util.invalidateTags(["BillingCheckStatus"]));
  }, [dispatch]);

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    if (data.subscription_status === "active") {
      navigate(rootPages.home);
    } else {
      navigate(rootPages.payment);
    }
  }, [isLoading, data, navigate]);

  return (
    <FullScreenBackground
      sx={{
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          m: "auto",
          py: 2,
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
        }}
      >
        <LoginTransparentBox sx={{ px: "77px", py: "54px", minWidth: 680 }}>
          <TablePlaceHolder isLoading={isLoading} rows={5} />
        </LoginTransparentBox>
        <CarFooter
          sx={{ mt: 1.5, alignSelf: "center" }}
          isWhite
          isCopyrightOnly
          hasPrivacyPolicy
        />
      </Box>
    </FullScreenBackground>
  );
};
