import { SvgIcon, SvgIconProps } from "@mui/material";

interface CarSortIconProps extends Pick<SvgIconProps, "sx" | "onClick"> {
  isSorted: boolean;
}

export const CarSortIcon = ({ isSorted, sx, ...props }: CarSortIconProps) => (
  <SvgIcon
    sx={{
      fontSize: "0.8em",
      color: isSorted ? "caravelOrangePrimary" : "gray7",
      ...sx,
    }}
    {...props}
    width="8"
    height="11"
    viewBox="0 0 8 11"
  >
    <path
      d="M8 6.72222L4 11L-1.86988e-07 6.72222L8 6.72222ZM8 4.27778L-2.93838e-07 4.27778L4 -1.74846e-07L8 4.27778Z"
      fill="currentColor"
    />
  </SvgIcon>
);
