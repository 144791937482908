import { MenuItem } from "@mui/material";
import { useAccountTypes } from "app/useAccountTypes";
import { CarSelectField, CarSelectFieldProps } from "./Inputs";

export const CarAccountTypeField = (props: CarSelectFieldProps) => {
  const { items } = useAccountTypes();
  return (
    <CarSelectField {...props}>
      {items.map((i) => (
        <MenuItem key={i.id} value={i.id}>
          {i.name}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};
