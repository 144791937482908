import { Fragment } from "react";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { RootPageBox } from "components/RootPage";
import { Box, BoxProps, Typography } from "@mui/material";
import { CarDisclosure } from "components/Disclosure";
import { RiskBarometer } from "components/RiskBarometer";
import { formatNumber } from "utils";

import { RiskSlider } from "components/RiskSlider";
import { PlanTypeCode } from "const";
import { RiskBarometerLegend } from "components/RiskBarometerLegend";
import { useClientId, usePlanId, usePlanTypeId } from "app/usePlanId";
import { CarRadioYesNo } from "components/Radio";
import { usePlanTypes } from "app/usePlanTypes";
import { useLocalStorage } from "features/general/useLocalStorage";
import { CarPlanTypeFieldField } from "components/PlanTypeField";
import { useNavigate } from "react-router-dom";
import { financialPlanningPages } from "route.const";
import { CarDonut } from "components/Donut";
import { PlanResultsGoalSummaryTable } from "./PlanResultsGoalSummaryTable";
import { RiskTableReport } from "features/planResults/RiskTableReport";
import { WorkbenchInflationSmall } from "../WorkbenchInflation";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarTooltipBox } from "components/TooltipBox";

interface PlanResultsRiskProps {
  data: UsePlanResults;
  sx?: BoxProps["sx"];
}

export const PlanResultsRisk = ({ data, sx }: PlanResultsRiskProps) => {
  const { items: planTypes, getPlanTypeLabel } = usePlanTypes();
  const clientId = useClientId();
  const planId = usePlanId();
  const planTypeId = usePlanTypeId();
  const planTypeLabel = getPlanTypeLabel(planTypeId);
  const selectedPlanTypeCode = planTypes.find((i) => i.id === planTypeId)?.code;
  const notDerisked =
    selectedPlanTypeCode === PlanTypeCode.proposed ||
    selectedPlanTypeCode === PlanTypeCode.existing ||
    selectedPlanTypeCode === PlanTypeCode.taxDefer;
  const hideSelectedPlan =
    selectedPlanTypeCode === PlanTypeCode.proposed ||
    selectedPlanTypeCode === PlanTypeCode.existing;
  const [allocationInPercent, setAllocationInPercent] =
    useLocalStorage<boolean>("RiskControlPanel-allocationInPercent", true);

  const navigate = useNavigate();

  const handleDeriskClick = () =>
    navigate(
      financialPlanningPages.getWorkbenchUrl(
        clientId,
        planId,
        planTypes.find((i) => i.code === PlanTypeCode.derisked)?.id || "",
      ),
    );

  return (
    <RootPageBox
      sx={{
        p: 5,
        pb: 10,
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h4SSemiBold"
          className={pendoClasses.workbenchSectionRisk}
        >
          Risk
        </Typography>
        <WorkbenchInflationSmall sx={{ mr: 2 }} />
      </Box>
      <Box sx={{ my: 3, display: "flex", flexDirection: "column", gap: 2.2 }}>
        <Typography variant="par02Regular">
          Caravel’s Risk Barometer provides investors a visual representation of
          the potential investment risk for alternative investment strategies.
          The indicators for each portfolio strategy are shown in box to the
          right of the gauge.
        </Typography>
        <Typography variant="par02Regular">
          Risk on the Risk Barometer is measured by the percentage of equity
          asset classes in the portfolio. The chart below provides additional
          measures of risk. These additional measures of risk are intended to
          help provide a more comprehensive understanding of overall investment
          risk. Please consult our Glossary for definitions of these terms.
        </Typography>
        <Typography variant="par02Regular">
          The Risk Barometer also helps you explore tradeoffs between risk and
          the potential for achieving financial goals. By selecting different
          strategies, you can see how the goals you can achieve may vary with
          the amount of investment risk within your portfolio.
        </Typography>
        <Typography variant="par02Regular">
          Caravel also offers the option to derisk your portfolio strategy. In
          order to derisk, click on the orange radio button to lower the maximum
          risk level.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CarDonut
          needPercent={data.donuts.needs.percentage}
          wantPercent={data.donuts.wants.percentage}
          dreamPercent={data.donuts.dreams.percentage}
          sx={{
            width: 190,
            height: 190,
          }}
        />
        <Box
          sx={{
            mt: 3.5,
            display: "flex",
            gap: 12,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="dis02SBold">
              {formatNumber(data.donuts.score)}
              <Typography
                component="span"
                variant="h1Bold"
                sx={{ color: "gray6", ml: 0.5 }}
              >
                %
              </Typography>
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 0.25,
              }}
            >
              <Typography variant="subhRegular">Plan Score</Typography>
              <CarTooltipBox
                className={pendoClasses.workbenchPlanResultsPlanScore}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="dis02SBold">
              {`${data.donuts.total.successCount}`}
              <Typography
                component="span"
                variant="h1Bold"
                sx={{ color: "gray6", ml: 0.5 }}
              >
                {`/${data.donuts.total.totalCount}`}
              </Typography>
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 0.25,
              }}
            >
              <Typography variant="subhRegular">Pass/Total</Typography>
              <CarTooltipBox
                className={pendoClasses.workbenchPlanResultsPassTotal}
              />
            </Box>
          </Box>
        </Box>
        <PlanResultsGoalSummaryTable
          sx={{ mt: 4, maxWidth: 850 }}
          data={data}
        />
      </Box>

      <Box
        sx={{
          mt: 12,
          display: "flex",
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: 500,
            alignItems: "center",
          }}
        >
          {!data.isPlanPublished && (
            <CarPlanTypeFieldField
              label="Allocation Strategy"
              sx={{ width: 200 }}
            />
          )}
          <RiskBarometerLegend
            sx={{
              mt: 3.5,
              width: 200,
              height: 189,
              flexDirection: "column",
              alignItems: "flex-start",
              paddingLeft: 5,
              gap: 2,
            }}
          />
          {!data.isPlanPublished &&
            (notDerisked ? (
              <Typography
                variant="par01Regular"
                sx={{
                  fontSize: 15,
                  textAlign: "center",
                  mt: 5,

                  ".click-here": {
                    color: "caravelOrangePrimary",
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
              >
                To derisk the plan please{" "}
                <span className="click-here" onClick={handleDeriskClick}>
                  click here
                </span>
              </Typography>
            ) : (
              <>
                <Typography
                  sx={{
                    mt: 4,
                  }}
                  variant="par02Bold"
                >
                  Derisking:
                </Typography>
                <RiskSlider
                  // YS need to force remount component due to some bug in slider in with old mark labels stays in place for some reason
                  // todo check after next mui update if bug is still there
                  // key={data.riskMode}
                  sx={{ mt: 2, mb: 2, mx: "auto", width: 450 }}
                  value={data.risk.fakeRiskValue}
                  onChange={data.risk.setFakeRiskValue}
                  marks={data.risk.marks}
                />
              </>
            ))}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="par02Bold" sx={{ mb: 3, textAlign: "center" }}>
            {planTypeLabel} Strategy
          </Typography>

          <RiskBarometer
            size={320}
            value={data.risk.riskValue}
            valueRed={data.risk.valueRed}
            valueWhite={data.risk.valueWhite}
            valueBlue={data.risk.valueBlue}
          />
        </Box>
      </Box>
      <Box
        sx={{
          mt: 11,
          ml: 2,
          display: "flex",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Typography variant="par02Regular">
          How would you like the allocation displayed?{" "}
        </Typography>
        <CarRadioYesNo
          sx={{
            gap: 2,
          }}
          value={allocationInPercent}
          row
          yesLabel="%"
          noLabel="$"
          onChange={setAllocationInPercent}
        />
      </Box>
      <RiskTableReport
        sx={{ mx: 2 }}
        title="Simulation Based Risk Report"
        hideSelectedPlan={hideSelectedPlan}
        selectedPlanLabel={data.risk.selectedPlanLabel}
        data={data.risk.simulationBasedRiskReport}
        convertToDollarFromTotal={
          allocationInPercent ? undefined : data.beginningTotalPortfolio
        }
      />
      <RiskTableReport
        sx={{ mx: 2 }}
        title="Historical Risk Report"
        hideSelectedPlan={hideSelectedPlan}
        selectedPlanLabel={data.risk.selectedPlanLabel}
        data={data.risk.historicalRiskReport}
        convertToDollarFromTotal={
          allocationInPercent ? undefined : data.beginningTotalPortfolio
        }
      />
      <CarDisclosure sx={{ mt: 3, mx: 5 }}>
        The Proposed portfolio mathematically aligns investment risk with the
        calculated needs of your financial goals. However, your risk tolerance
        is determined by far more than math. The CMPAS Derisking feature allows
        you to set a limit on the amount of risk in the Derisked portfolio
        alternative. Putting a limit on portfolio risk may mean you will need to
        lower your financial goals consistent with that lower level of portfolio
        risk. The right balance between risk and financial reward is unique to
        each investor. CMPAS provides this tool to help you find the balance
        that is right for you.
      </CarDisclosure>
    </RootPageBox>
  );
};
