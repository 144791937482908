import {
  SvgIcon,
  SvgIconProps,
  Typography,
  BoxProps,
  Box,
  TypographyProps,
} from "@mui/material";
import { Fragment, ReactNode, useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { TablePlaceHolder } from "./PlaceHolder";
import { isEvenOdd } from "utils";

interface GridSort<T extends object> {
  field: keyof T;
  direction: "asc" | "desc";
}

interface ColumnHeaderProps<T extends object> {
  label?: string;
  field?: keyof T;
  headerAlign?: TypographyProps["textAlign"];
  sortField?: GridSort<T>;
  setSortField?: (tableSort: GridSort<T>) => void;
}

interface CarGridColumn<T extends object> {
  fraction?: number | string;
  label?: string;
  field?: keyof T;
  headerAlign?: TypographyProps["textAlign"];
  textAlign?: TypographyProps["textAlign"];
  onCellRender?: (row: T, rowIndex: number, colIndex: number) => ReactNode;
  onGetValue?: (row: T, rowIndex: number) => ReactNode;
}

type SortItemFunc<T extends object> = (
  gridSort: GridSort<T>,
  left: T,
  right: T,
) => number;

const SortIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="11" viewBox="0 0 8 11">
    <path
      d="M8 6.72222L4 11L-1.86988e-07 6.72222L8 6.72222ZM8 4.27778L-2.93838e-07 4.27778L4 -1.74846e-07L8 4.27778Z"
      fill="currentColor"
    />
  </SvgIcon>
);

function ColumnHeader<T extends object>(props: ColumnHeaderProps<T>) {
  const handleClick = () => {
    props.setSortField &&
      props.field &&
      props.setSortField({
        field: props.field,
        direction:
          props.sortField?.field === props.field
            ? props.sortField.direction === "asc"
              ? "desc"
              : "asc"
            : "asc",
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      alignItems={props.headerAlign}
    >
      <Typography
        variant="caption"
        color="gray7"
        textAlign={props.headerAlign}
        sx={{
          cursor: "pointer",
          userSelect: "none",
          display: "flex",
          flexWrap: "nowrap",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        onClick={handleClick}
      >
        {props.label}
        {props.setSortField && props.field && (
          <SortIcon
            sx={{
              ml: 0.3,
              mt: 0.25,
              fontSize: "0.8em",
              color:
                props.sortField?.field === props.field
                  ? "caravelOrangePrimary"
                  : "gray7",
            }}
          />
        )}
      </Typography>
    </Box>
  );
}

function getGridTemplateColumns<T extends object>(
  columns: Array<CarGridColumn<T>>,
) {
  return columns
    .map((i) =>
      typeof i.fraction === "number" ? `${i.fraction}fr` : i.fraction || "1fr",
    )
    .join(" ");
}

interface CarGridProps<T extends object> {
  sx?: BoxProps["sx"];
  headerSx?: BoxProps["sx"];
  showHeaderDivider?: boolean;
  isEvenOdd?: boolean;
  isWhiteContext?: boolean;
  isLoading?: boolean;
  noScroll?: boolean;
  columns: Array<CarGridColumn<T>>;
  cellVariant?: TypographyProps["variant"];
  rows: Array<T>;
  defaultGridSort?: GridSort<T>;
  onSortItems?: SortItemFunc<T>;
  getRowSx?: (row: T, index: number) => BoxProps["sx"];
  onRowClick?: (row: T, index: number) => void;
}

export function CarGrid<T extends object>(props: CarGridProps<T>) {
  const ColumnHeaderOfT = (props: ColumnHeaderProps<T>) => ColumnHeader(props);

  const [sortField, setSortField] = useState<GridSort<T> | undefined>(
    props.defaultGridSort,
  );

  const [sortedItems, setSortedItems] = useState<Array<T>>(props.rows);

  useEffect(() => {
    let sorted = props.rows;
    if (props.onSortItems && sortField) {
      sorted = Array.from(props.rows).sort((left, right) =>
        (props.onSortItems as SortItemFunc<T>)(sortField, left, right),
      );
    }
    setSortedItems(sorted);
  }, [sortField, props.rows, props.onSortItems]);

  const rows = sortedItems.map((row, rowIndex) => {
    return (
      <Box
        key={rowIndex}
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(props.columns),
          width: props.noScroll ? "100%" : "calc(100% - 10px)",
          minHeight: 50,
          alignItems: "center",
          px: 3,
          cursor: props.onRowClick ? "pointer" : undefined,
          backgroundColor: props.isEvenOdd
            ? isEvenOdd(rowIndex)
              ? "white"
              : "gray2"
            : props.isWhiteContext
            ? "gray1"
            : "white",
          border: "solid 1px",
          borderColor: "gray3",
          borderRadius: "5px",
          "&:hover": {
            backgroundColor: props.onRowClick ? "lightOrange" : undefined,
          },
          mb: 1,
          "& > *": {
            flex: "none",
          },
          ...props.getRowSx?.(row, rowIndex),
        }}
        onClick={(e) => {
          props.onRowClick && props.onRowClick(row, rowIndex);
        }}
      >
        {props.columns.map((col, colIndex) => {
          if (col.onCellRender) {
            return (
              <Fragment key={colIndex}>
                {col.onCellRender(row, rowIndex, colIndex)}
              </Fragment>
            );
          }
          return (
            <Typography
              key={colIndex}
              variant={props.cellVariant ?? "par02Regular"}
              textAlign={col.textAlign}
            >
              {col.onGetValue
                ? (col.onGetValue(row, rowIndex) as any)
                : (col.field && row[col.field]) || ""}
            </Typography>
          );
        })}
      </Box>
    );
  });

  return (
    <TablePlaceHolder isLoading={props.isLoading} sx={props.sx}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: props.noScroll ? undefined : "auto",
          ...props.sx,
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: getGridTemplateColumns<T>(props.columns),
            width: "100%",
            px: 3,
            ...props.headerSx,
          }}
        >
          {props.columns.map((col, colIndex) => (
            <ColumnHeaderOfT
              key={colIndex}
              label={col.label}
              field={col.field}
              headerAlign={col.headerAlign}
              {...(props.onSortItems ? { sortField, setSortField } : {})}
            />
          ))}
        </Box>
        {props.showHeaderDivider && (
          <Box
            sx={{
              borderBottom: "1px solid",
              borderBottomColor: "gray6",
              my: 1,
            }}
          />
        )}
        <Box
          sx={{
            position: "relative",
            flex: "auto",
          }}
        >
          {props.noScroll ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {rows}
            </Box>
          ) : (
            <SimpleBar
              autoHide={false}
              forceVisible="y"
              style={{
                position: "absolute",
                width: "calc(100% + 18px)",
                height: "100%",
                display: "flex",
                paddingRight: 8,
              }}
            >
              {rows}
            </SimpleBar>
          )}
        </Box>
      </Box>
    </TablePlaceHolder>
  );
}
