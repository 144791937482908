import {
  usePortfolioPortfolioGroupVersionListQuery,
  usePortfolioPortfolioGroupVersionStatusListQuery,
} from "api/carApi.generated";

export const usePortfolioGroupVersions = (
  selectedPortfolioGroupId?: string
) => {
  const statuses = usePortfolioPortfolioGroupVersionStatusListQuery();
  const versions = usePortfolioPortfolioGroupVersionListQuery();

  const versionItems =
    versions.data
      ?.filter((i) => i.group_id === selectedPortfolioGroupId)
      .map((i) => ({
        ...i,
        status: statuses.data?.find((st) => st.id === i.status_id)?.name,
        isEditable: statuses.data?.find((st) => st.id === i.status_id)
          ?.editable,
      })) ?? [];
  versionItems.reverse();

  return {
    isLoading: versions.isFetching || statuses.isLoading,
    versionItems,
  };
};
