import { Dialog, Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useCommonAuthTwoFactorSettingsUpdateMutation } from "api/carApi.generated";
import { CarButton, CarLoadingButton } from "components/Buttons";
import { CarTextField } from "components/Inputs";
import { CarClose } from "icons";
export interface TwoFactorDeactivateDialogProps {
  onApply: (value: boolean) => void;
  onCancel: () => void;
}

export const TwoFactorDeactivateDialog = (
  props: TwoFactorDeactivateDialogProps
) => {
  const [code, setCode] = useState<string | undefined>();
  const [isCodeEmpty, setIsCodeEmpty] = useState(false);

  const [verifyCode, { isLoading: verifyCodeIsLoading }] =
    useCommonAuthTwoFactorSettingsUpdateMutation();

  const handleScan = async () => {
    setIsCodeEmpty(!code);

    if (!code) {
      return;
    }

    const response = await verifyCode({
      userTwoFactorSettings: {
        code,
        deactivate: true,
      },
    });

    if ("data" in response) {
      props.onApply(true);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "6px",
          p: 5,
          position: "relative",
        },
      }}
      maxWidth="lg"
      open={true}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          props.onCancel();
        }
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: 10, top: 10, color: "gray6" }}
        onClick={props.onCancel}
      >
        <CarClose />
      </IconButton>
      <Typography variant="h4SSemiBold">
        Enter code from your authentication app
      </Typography>
      <Divider sx={{ mt: 0.5, mb: 1, borderColor: "gray6" }} />
      <Box
        sx={{
          p: 5,
          width: 450,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <CarTextField
            sx={{
              ".MuiInputBase-root": {
                mt: 2.75,
              },
              label: {
                fontSize: "16px",
              },
            }}
            label="Enter your verification code"
            value={code}
            onChange={(value) =>
              value ? setCode(value.replace(/\D/g, "")) : setCode(undefined)
            }
            autoFocus
            error={isCodeEmpty}
          />
          <Box sx={{ display: "flex", gap: 2 }}>
            <CarButton
              sx={{ fontSize: "18px" }}
              variant="outlined"
              onClick={props.onCancel}
            >
              Back
            </CarButton>
            <CarLoadingButton
              sx={{ fontSize: "18px" }}
              onClick={handleScan}
              isLoading={verifyCodeIsLoading}
            >
              Deactivate
            </CarLoadingButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
