import { Typography } from "@mui/material";
import { useAlerts } from "./useSettingsNotifications";
import { TablePlaceHolder } from "components/PlaceHolder";
import { SettingsList } from "./SettingsList";
import { SectionTierSettings } from "./SectionTierSettings";
import { useSettingsTiers } from "./useSettingsTiers";
import { SectionAlerts } from "./SectionAlerts";

const tierSettingsDescription = `Assign a percentage to surplus increase or decrease for each tier. This means if your tier # clients surplus drops or increase by the percentage you input, you will receive a notification.

Total Allocation Difference is how far off your current accounts are vs. the proposed account allocation the system recommended.

Also select how often you want to review each tier’s plan. A notification will generate at the appropriate time.`;

export const SettingsNotificationSettings = () => {
  const alerts = useAlerts();
  const tierSettings = useSettingsTiers();

  return (
    <TablePlaceHolder
      sx={{ mt: 5 }}
      isLoading={alerts.isLoading || tierSettings.isLoading}
      rows={8}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Notification Settings
      </Typography>
      <SettingsList
        items={[
          {
            label: "Tier Settings",
            description: tierSettingsDescription, // "Set thresholds for each tier level.",
            content: <SectionTierSettings data={tierSettings} />,
          },
          {
            label: "Alerts",
            description:
              "Decide which alerts to receive for each client tier level.",
            content: <SectionAlerts data={alerts} />,
          },
        ]}
      />
    </TablePlaceHolder>
  );
};
