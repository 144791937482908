import { useRef, useState } from "react";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { useLocalStorage } from "features/general/useLocalStorage";
import { usePortfolioGroupInfo } from "pages/assetAllocation/usePortfolioGroupInfo";
import { usePortfolioGroupOptimizerRuns } from "pages/assetAllocation/editPortfolioGroup/usePortfolioGroupOptimizerRuns";
import { showToast } from "app/toastSlice";
import { useAppDispatch } from "app/hooks";
import { useOptimizerDynamicRules } from "./useOptimizerDynamicRules";
import { PleaseWaitOverlay, PleaseWaitOverlayRef } from "./PleaseWaitOverlay";
import { useDialog } from "app/useDialog";
import { usePortfolioRunDynamicOptimizationsCreateMutation } from "api/carApi.generated";

const NEW_OPTIMIZATION_ID = "new_opt_id";

export const useEditPortfolioGroupOptimization = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const data = usePortfolioGroupInfo({ portfolioGroupId });
  const dispatch = useAppDispatch();

  const optimizerRuns = usePortfolioGroupOptimizerRuns({ portfolioGroupId });
  const optimizerDynamicRules = useOptimizerDynamicRules();

  const pleaseWaitOverlayRef = useRef<PleaseWaitOverlayRef>();
  const dialog = useDialog();

  const [checkedDynamicRules, setCheckedDynamicRules] = useState<string[]>([]);

  const [runDynamicOptimizations] =
    usePortfolioRunDynamicOptimizationsCreateMutation();

  const [_isAdvanced, setIsAdvanced] = useLocalStorage<boolean>(
    "EditPortfolioGroupOptimization_isAdvanced",
    false,
  );

  const isAdvanced = data.isSinglePortfolioMode ? _isAdvanced : true;

  const [currentOptimizerId, setCurrentOptimizerId] = useState<
    string | undefined
  >();

  const handleSetIsAdvanced = (value: boolean) => {
    setIsAdvanced(value);
    setCurrentOptimizerId(undefined);
  };

  const handleCurrentOptimizerIdChange = (value?: string) => {
    const item = optimizerRuns.items.find((i) => i.id === value);

    if (item) {
      if (data.isSinglePortfolioMode) {
        setCurrentOptimizerId(value); // set this immediately
        optimizerRuns.handleLoadSingle(item).then((result) => {
          if (!result) {
            setCurrentOptimizerId(undefined);
            dispatch(
              showToast({
                kind: "error",
                message: "Something went wrong",
              }),
            );
          }
        });
      } else {
        optimizerRuns.handleLoad(item).then((loaded) => {
          if (loaded) {
            setCurrentOptimizerId(value); // set this only if dialog has not been canceled
          }
        });
      }
    }
  };

  const currentOptimizerItem = optimizerRuns.items.find(
    (i) => i.id === currentOptimizerId,
  );

  const currentOptimizerTitle = currentOptimizerItem
    ? currentOptimizerItem.title
    : currentOptimizerId === NEW_OPTIMIZATION_ID
    ? "New Optimization"
    : "";

  const canRerun = data.isSinglePortfolioMode && !!checkedDynamicRules.length;

  const handleRerun = async () => {
    const portModuleId = optimizerRuns.getDraftModuleId();
    if (
      !data.isSinglePortfolioMode ||
      !checkedDynamicRules.length ||
      !portModuleId
    ) {
      return;
    }

    dialog(PleaseWaitOverlay, {
      portfolioGroupId,
      onPublished: () => {},
      customRef: (value: PleaseWaitOverlayRef | null) => {
        pleaseWaitOverlayRef.current = value ? value : undefined;
      },
    });

    let result = false;

    try {
      // console.log(
      //   `Calling runDynamicOptimizations('${portModuleId}', ${checkedDynamicRules.join(
      //     ",",
      //   )})`,
      // );
      const dynamicOptimizationsResponse = await runDynamicOptimizations({
        portModuleId,
        processOptimizerDynamicRules: {
          rule_ids: checkedDynamicRules,
        },
      });

      result = !("error" in dynamicOptimizationsResponse);
      // console.log(`runDynamicOptimizations Response`, dynamicOptimizationsResponse);

      if ("data" in dynamicOptimizationsResponse) {
        pleaseWaitOverlayRef.current?.waitAndClose(
          dynamicOptimizationsResponse.data.uuids,
        );
      }
    } finally {
      if (!result) {
        pleaseWaitOverlayRef.current?.close();
      }
    }
  };

  return {
    isLoading:
      data.isLoading ||
      optimizerRuns.isLoading ||
      optimizerDynamicRules.isLoading,
    isSinglePortfolioMode: data.isSinglePortfolioMode,
    optimizerRuns,

    optimizerDynamicRules,
    checkedDynamicRules,
    setCheckedDynamicRules,

    canRerun,
    handleRerun,

    isAdvanced,
    handleSetIsAdvanced,

    currentOptimizerId,
    handleCurrentOptimizerIdChange,

    currentOptimizerItem,
    currentOptimizerTitle,

    canAnalyze: !currentOptimizerItem?.isRunning,

    handleAnalyze: () => {
      optimizerRuns.handleAnalyze(currentOptimizerItem?.id ?? "");
    },

    handleAddOptimization: () => {
      setCurrentOptimizerId(NEW_OPTIMIZATION_ID);
    },

    handleRenameOptimization: () => {
      if (currentOptimizerItem) {
        optimizerRuns.handleRename(currentOptimizerItem);
      }
    },

    handleDeleteOptimization: () => {
      if (currentOptimizerItem) {
        optimizerRuns.handleDelete(currentOptimizerItem).then((result) => {
          if (result) {
            setCurrentOptimizerId(undefined);
          }
        });
      }
    },
    handleOptimizationRunFinished: setCurrentOptimizerId,
  };
};
