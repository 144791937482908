import { StressTest, StressTestItem } from "features/stressTest/useStressTest";
import { CarInfoTable, CarInfoCell, CarInfoHeader } from "components/InfoTable";
import { Box, BoxProps, Typography } from "@mui/material";
import { formatCurrency, formatPercent, isOddEven } from "utils";
import { CarStatusFail, CarStatusPartial, CarStatusSuccess } from "icons";
import { CarTooltip } from "components/Tooltip";
import { pendoClasses } from "app/thirdParty/pendo";

const HeaderRow = (props: { labels: Array<[string, string]> }) => (
  <>
    {[
      [
        "Goals & Priority",
        pendoClasses.workbenchStressTestStressScenariosGoalsAndPriority,
      ],
      ["Years", pendoClasses.workbenchStressTestStressScenariosYears],
      [
        "*Annual Amount",
        pendoClasses.workbenchStressTestStressScenariosAnnualAmount,
      ],
      [
        "*Total Amount",
        pendoClasses.workbenchStressTestStressScenariosTotalAmount,
      ],
      ...props.labels.map(([label, code]) => [
        label,
        pendoClasses.workbenchStressTestStressScenariosScenarioType(code),
      ]),
    ].map(([label, pendoClass], idx) => (
      <CarInfoHeader
        key={idx}
        pendoClass={pendoClass}
        sx={{
          justifyContent: idx === 0 ? "start" : "center",
          textAlign: idx === 0 ? "start" : "center",
        }}
        text={label}
      />
    ))}
  </>
);

interface ItemRowProps {
  item: StressTestItem;
  isOdd: boolean;
}

const ItemRow = ({ item, isOdd }: ItemRowProps) => (
  <>
    <CarInfoCell text={item.goalName} justifyContent="start" isOdd={isOdd} />
    <CarInfoCell text={item.years.toString()} isOdd={isOdd} />
    <CarInfoCell text={formatCurrency(item.annualAmount)} isOdd={isOdd} />
    <CarInfoCell text={formatCurrency(item.totalAmount)} isOdd={isOdd} />
    {item.stressTasks.map((i, idx) => (
      <CarTooltip
        key={idx}
        title={
          <>
            <Typography variant="par02SemiBold" sx={{ mb: 0.5 }}>
              {item.goalName}
            </Typography>
            <Typography variant="par01Regular">
              {`Funded ${formatPercent(
                i.percentFunded * 100,
              )} (${formatCurrency(i.amountFunded)})`}
            </Typography>
          </>
        }
        placement="bottom"
        disableInteractive
      >
        <Box>
          <CarInfoCell
            key={idx}
            sx={{
              height: "100%",
              svg: {
                fontSize: "32px",
              },
            }}
            isOdd={isOdd}
          >
            {i.status === "success" ? (
              <CarStatusSuccess />
            ) : i.status === "partial" ? (
              <CarStatusPartial />
            ) : (
              <CarStatusFail />
            )}
          </CarInfoCell>
        </Box>
      </CarTooltip>
    ))}
  </>
);

const SurplusDeficitRow = (props: { data: Array<number> }) => (
  <>
    <CarInfoCell
      sx={{
        gridColumn: "1 / span 4",
        backgroundColor: "table.background.odd",
        fontWeight: 700,
      }}
      text="Surplus/Deficit:"
    />
    {props.data.map((i, idx) => (
      <CarInfoCell
        key={idx}
        sx={{
          backgroundColor: "table.background.odd",
          fontWeight: 700,
        }}
        text={formatCurrency(i)}
      />
    ))}
  </>
);

export const StressTestTableLegend = ({ sx }: { sx?: BoxProps["sx"] }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      svg: {
        fontSize: "32px",
      },
      gap: 10,
      "& > *": {
        display: "flex",
        alignItems: "center",
      },
      ".MuiTypography-root": {
        ml: 1.5,
      },
      ...sx,
    }}
  >
    <Box>
      <CarStatusSuccess />
      <Typography variant="par02Regular">Goal passed test scenario</Typography>
    </Box>
    <Box>
      <CarStatusPartial />
      <Typography variant="par02Regular">
        Goal met part of test scenario
      </Typography>
    </Box>
    <Box>
      <CarStatusFail />
      <Typography variant="par02Regular">Goal failed test scenario</Typography>
    </Box>
  </Box>
);

export const StressTestTable = (props: {
  value: StressTest;
  sx?: BoxProps["sx"];
}) => (
  <CarInfoTable
    sx={{
      ...props.sx,
      gridTemplateColumns: `2fr 1fr 1fr 1fr repeat(${props.value.taskLabels.length}, 1fr)`,
    }}
  >
    <HeaderRow labels={props.value.taskLabels} />
    {props.value.items.map((i, idx) => (
      <ItemRow key={i.id} item={i} isOdd={isOddEven(idx)} />
    ))}
    <SurplusDeficitRow data={props.value.surplusDeficits} />
  </CarInfoTable>
);
