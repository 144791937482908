import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { apiUrl } from "const";
import { debugLog } from "utils";
import { getAccessToken } from "./getAccessToken";

const skipAuthRequests = [
  "commonAuthLoginCreate",
  "commonAuthStartFreeTrialCreate",
  "commonAuthAcceptInvitationCreate",
];

debugLog("baseUrl: ", apiUrl);

export const carBaseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: async (headers, api) => {
    debugLog("fetching", api.endpoint);

    if (!skipAuthRequests.some((v) => v === api.endpoint)) {
      const accessToken = await getAccessToken(api);
      headers.set("Authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },
});
