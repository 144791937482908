import {
  CarAccordion,
  CarAccordionDetails,
  CarAccordionSummary,
} from "components/Accordion";
import { Box, BoxProps, MenuItem, Typography, styled } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { Fragment, PropsWithChildren, memo } from "react";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { isOddEven } from "utils";
import { CarSelectField } from "components/Inputs";
import { CarDownloadButton } from "components/Buttons";
import { useSimulationSummary } from "./useSimulationSummary";
import {
  ExcelDataFormat,
  ExcelTable,
  excelTableFormatValue,
  excelTableToGridTemplateColumns,
} from "app/useExcelExport";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

const StyledAccordionSummary = styled(CarAccordionSummary)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(2),
  },
}));

type CollapseSectionProps = PropsWithChildren<{
  sx?: BoxProps["sx"];
  label: string;
}>;

export const CollapseSection = ({
  label,
  children,
  sx,
}: CollapseSectionProps) => (
  <CarAccordion
    sx={sx}
    defaultExpanded
    TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
  >
    <StyledAccordionSummary sx={{ backgroundColor: "gray1" }}>
      <Typography variant="h6SemiBold">{label}</Typography>
    </StyledAccordionSummary>
    <CarAccordionDetails
      sx={{
        p: 4,
        backgroundColor: "white",
        border: "solid 1px",
        borderColor: "gray3",
        borderTopWidth: 0,
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
      }}
    >
      {children}
    </CarAccordionDetails>
  </CarAccordion>
);

interface SimulationSummaryProps {
  simulationJobId: string;
  isStatic: boolean;
}

const TableSection = ({ table }: { table: ExcelTable }) => {
  return (
    <CollapseSection label={table.name}>
      <CarInfoTable
        sx={{ gridTemplateColumns: excelTableToGridTemplateColumns(table) }}
      >
        {table.columns.map((col, idx) => (
          <CarInfoHeader key={idx} text={col.label} />
        ))}
        {table.rows.map((row, rowIdx) => (
          <Fragment key={rowIdx}>
            {row.map((val, valIdx) => (
              <CarInfoCell
                key={valIdx}
                isOdd={isOddEven(rowIdx)}
                justifyContent={
                  table.columns.at(valIdx)?.format === ExcelDataFormat.general
                    ? "start"
                    : undefined
                }
                text={excelTableFormatValue(val, table.columns.at(valIdx))}
              />
            ))}
          </Fragment>
        ))}
      </CarInfoTable>
    </CollapseSection>
  );
};

export const SimulationSummary = memo((props: SimulationSummaryProps) => {
  const simulationSummary = useSimulationSummary({
    simulationJobId: props.simulationJobId,
  });

  const pageContent = usePageContent();

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 5 }}
      isLoading={simulationSummary.isLoading || pageContent.isLoading}
      rows={8}
    >
      <CarPageContent
        sx={{ mb: 3 }}
        content={pageContent.getContent(
          props.isStatic
            ? CarPageContentCode.SIMULATION_ANALYSIS_STATIC_SUMMARY
            : CarPageContentCode.SIMULATION_ANALYSIS_SUMMARY,
        )}
        isWhiteContext
      />
      <Box sx={{ mt: 1, display: "flex", gap: 5, alignItems: "flex-end" }}>
        <CarSelectField
          label="Asset Class Groupings"
          sx={{
            width: 216,
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              marginTop: "28px",
              bgcolor: "white",
            },
          }}
          value={simulationSummary.assetClassGroupSelector.value}
          onChange={simulationSummary.assetClassGroupSelector.onChange}
        >
          {simulationSummary.assetClassGroupSelector.items.map((i) => (
            <MenuItem key={i.code} value={i.code}>
              {i.name}
            </MenuItem>
          ))}
        </CarSelectField>
        <CarDownloadButton
          sx={{ ml: "auto", mb: 0.5 }}
          onClick={simulationSummary.handleDownloadReports}
        >
          Download Reports
        </CarDownloadButton>
      </Box>
      {simulationSummary.tables.map((table, idx) => (
        <TableSection key={idx} table={table} />
      ))}
    </TablePlaceHolder>
  );
});
