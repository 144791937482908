import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { CarIconNotification, CarIconSettings } from "icons";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { rootPages } from "route.const";
import { CarAvatar } from "components/Avatar";
import { useUser } from "pages/settings/useUser";
import { useRoles } from "./useRoles";
import { useLocalStorage } from "features/general/useLocalStorage";
import { useNotificationCount } from "./useNotificationCount";

export const UserBadge = memo(() => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { user, isLoading } = useUser();
  const roles = useRoles();
  const count = useNotificationCount();
  const navigate = useNavigate();

  const [lastProfileImage, setLastProfileImage] = useLocalStorage<
    string | undefined
  >("lastProfileImage", user.profile_image ?? undefined);

  useEffect(() => {
    if (!isLoading) {
      setLastProfileImage(user.profile_image ?? undefined);
    }
  }, [isLoading, user.profile_image, setLastProfileImage]);

  const accountLogo = isLoading ? lastProfileImage : user.profile_image;
  const fullName = `${user.first_name ?? ""} ${user.last_name ?? ""}`.trim();
  const role = roles.getRole(user.role)?.name ?? "";

  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      className={isLoading || roles.isLoading ? "loading" : undefined}
      sx={{
        top: 32,
        right: 30,
        position: "absolute",
        display: "flex",
        gap: 2.5,
        alignItems: "center",
        zIndex: 1000,
        transition: "opacity 0.3s",
        opacity: 1,
        "&.loading": {
          opacity: 0,
        },
      }}
    >
      <Button
        size="small"
        onClick={() => navigate(rootPages.notificationsUrl)}
        variant="contained"
        sx={{
          position: "relative",
          backgroundColor: "gray1",
          boxShadow: "0px 4px 4px 0px #5555551A",
          borderRadius: "7px",
          border: "1px solid",
          borderColor: "gray3",
          minWidth: 30,
          width: 30,
          height: 30,
          "&:hover": {
            backgroundColor: "gray2",
            boxShadow: "0px 4px 4px 0px #5555551A",
          },
        }}
      >
        {count > 0 && (
          <Typography
            sx={{
              top: -7,
              right: -7,
              backgroundColor: "red",
              position: "absolute",
              width: 14,
              height: 14,
              fontSize: "9px",
              lineHeight: "16px",
              color: "white",
              borderRadius: "50%",
              pointerEvents: "none",
            }}
            variant="h6SRegular"
          >
            {count < 100 ? count : "99"}
          </Typography>
        )}
        <CarIconNotification sx={{ color: "primary.main" }} />
      </Button>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 1,
          gap: 1,
          zIndex: 1,
          borderRadius: "7px",
          backgroundColor: "gray1",
          boxShadow: "0px 4px 4px 0px #5555551A",
          cursor: "pointer",
        }}
        onClick={handleMenuClick}
      >
        <CarAvatar
          firstName={user.first_name}
          lastName={user.last_name}
          email={user.email}
          src={accountLogo}
          sx={{
            width: 40,
            height: 40,
            borderRadius: "7px",
            fontSize: "1rem",
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", mr: 1 }}>
          <Typography
            variant="par02Bold"
            sx={{ fontSize: "12px", lineHeight: "14px", color: "softBlack" }}
          >
            {fullName}
          </Typography>
          <Typography
            variant="par01Regular"
            sx={{
              fontSize: "9px",
              lineHeight: "14px",
              color: "gray6",
              fontStyle: "italic",
            }}
          >
            {role}
          </Typography>
        </Box>
        <svg
          width="11"
          height="6"
          viewBox="0 0 11 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.2925 0L5.5 3.7085L9.7075 0L11 1.1417L5.5 6L-2.67662e-08 1.1417L1.2925 0Z"
            fill="#6B6C6D"
          />
        </svg>
      </Box>
      {open && (
        <Menu
          sx={{
            mt: 1,
            ".MuiPaper-root": {
              minWidth: 166,
            },
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={(e: any) => {
            e.stopPropagation?.();
            e.preventDefault?.();
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(null);
              navigate(rootPages.settingsUrl);
            }}
          >
            <ListItemIcon>
              <CarIconSettings />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                variant: "par01Regular",
              }}
            >
              Settings
            </ListItemText>
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
});
