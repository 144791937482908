import { api } from "api/carApi.generated";
import { usePlanId } from "app/usePlanId";
import { isDefined } from "utils";

export const useSchwabSearch = () => {
  const planId = usePlanId();
  const [searchAccounts, { isFetching: isAccountsLoading, data: accounts }] =
    api.useLazyDataProvidersSearchSchwabAccountsListQuery();

  const [linkRel, { isLoading: linkIsLoading }] =
    api.useDataProvidersPlanAccountsLinkSchwabExternalCreateMutation();

  const handleLink = (accountIds: string[]) => {
    linkRel({
      planId,
      linkExternalAccounts: {
        integrator: "schwab",
        account_ids: accountIds,
      },
    });
  };

  const handleSearch = (searchCriterion: string) => {
    searchAccounts({ searchCriterion });
  };

  return {
    accounts: accounts ?? [],
    isAccountsLoading,
    isSearchExecuted: isAccountsLoading || isDefined(accounts),
    handleSearch,
    linkIsLoading,
    handleLink,
  };
};
