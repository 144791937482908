import { api } from "api/carApi.manual";
import { useAppDispatch } from "app/hooks";
import { isDebug } from "const";
import { useEffect, useRef } from "react";
import { useOptimizationJobs } from "./useOptimizationJobs";

const JOB_LIST_PULL_INTERVAL = isDebug ? 1000 : 5000;

export const useOptimizationJobsPuller = (portfolioGroupVersionId?: string) => {
  const {
    refetch: jobListRefetch,
    hasRunningJobs,
    isFetching: jobListIsFetching,
  } = useOptimizationJobs({
    portfolioGroupVersionId,
  });

  const dispatch = useAppDispatch();

  const notFirstRun = useRef<boolean>();

  useEffect(() => {
    if (jobListIsFetching) {
      return;
    }

    const isAllJobsDone = !jobListIsFetching && !hasRunningJobs;

    if (isAllJobsDone) {
      // not a first run
      if (notFirstRun.current) {
        dispatch(api.util.invalidateTags(["PortfolioGroupVersion"]));
      }
      notFirstRun.current = true;
    } else {
      notFirstRun.current = true;

      const timeout = setTimeout(jobListRefetch, JOB_LIST_PULL_INTERVAL);
      return () => clearTimeout(timeout);
    }
  }, [jobListIsFetching, jobListRefetch, hasRunningJobs, dispatch]);
};
