import {
  PageSection,
  useCommonPageSectionRetrieveQuery,
  useCommonPageSectionCreateMutation,
  useCommonPageSectionUpdateMutation,
  useCommonCmpasModuleSectionListQuery,
} from "api/carApi.generated";
import { useForm } from "./useForm";
import { FormEditorDialog } from "./FormEditorDialog";
import { CarSelectOption } from "components/Inputs";
import { checkDefined, sortBySortOrder } from "utils";

interface PageSectionEditorDialogProps {
  id?: string;
  pageId: string;
  nextSortOrder?: number;
  onApply: () => void;
  onCancel: () => void;
}

export const PageSectionEditorDialog = (
  props: PageSectionEditorDialogProps,
) => {
  const query = useCommonPageSectionRetrieveQuery(
    {
      id: props.id ?? "",
    },
    { skip: !props.id },
  );

  const [create] = useCommonPageSectionCreateMutation();
  const [update] = useCommonPageSectionUpdateMutation();

  const moduleSections = useCommonCmpasModuleSectionListQuery();

  const form = useForm<PageSection>({
    entityLabel: "Page Section",
    getItemName: (item) => `${item.title} (${item.code})`,
    isLoading: query.isLoading || moduleSections.isLoading,
    item: query.data,
    getNewItem: () => ({
      code: "",
      title: "",
      page: props.pageId,
      is_active: true,
      sort_order: props.nextSortOrder,
    }),
    onClose: props.onCancel,
    onCreate: (item) =>
      create({
        pageSection: item,
      }),

    onUpdate: (item) =>
      update({
        id: item.id ?? "",
        pageSection: item,
      }),
    onDelete: async (item) => {
      // todo
    },
    fieldDefs: [
      {
        key: "code",
        type: "string",
        label: "Code",
        description: "Unique code for the page section",
        isRequired: true,
      },
      {
        key: "title",
        type: "string",
        label: "Title",
        description: "Title of the page section",
        isRequired: true,
      },
      {
        key: "page",
        type: "select",
        options:
          sortBySortOrder(moduleSections.data)
            .filter((i) => i.is_active)
            .map<CarSelectOption<string>>((i) => ({
              label: `${i.name} (${i.code})`,
              value: checkDefined(i.id),
            })) ?? [],
        label: "Page",
        description: "The page this content belongs to",
        isRequired: true,
      },
      {
        key: "is_active",
        type: "boolean",
        label: "Is Active",
        description: "Is this page section active",
      },
      {
        key: "sort_order",
        type: "integer",
        label: "Sort Order",
        description: "Sort order of page section",
      },
    ],
  });

  return <FormEditorDialog form={form} />;
};
