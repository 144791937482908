import { ChartConfiguration } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { useTheme } from "@mui/material";
import {
  CarPortfolioChartItem,
  CarPortfolioStatPeriod,
} from "../asset-allocation-types";
import {
  OutUnderPerfChartDataPoint,
  getPortfolioGroupOutUnderPerfChartData,
} from "../editPortfolioGroup/ChartOutUnder";
import { formatPercentFactor } from "utils";
import { CarPdfChart, CarPdfChartProps } from "components/PdfChart";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfTypography } from "components/PdfTypography";

interface PortfolioGroupOutUnderPerfPdfChartProps
  extends Omit<CarPdfChartProps, "type" | "options" | "plugins" | "data"> {
  data: CarPortfolioChartItem[];
  periods: CarPortfolioStatPeriod[];
  data1Id: string;
  data2Id: string;
  isRelative: boolean;
}

const PdfChartLegend = ({
  data,
}: {
  data: ChartConfiguration<
    "line" | "scatter",
    OutUnderPerfChartDataPoint[]
  >["data"];
}) => {
  return (
    <CarPdfBox
      style={{
        // marginTop: 12,
        marginLeft: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {data.datasets.map((ds, idx) => {
        return (
          <CarPdfBox
            key={idx}
            style={{
              marginLeft: 16,
              marginRight: 16,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CarPdfBox
              style={{
                width: 10,
                height: 10,
                backgroundColor: String(
                  ds.borderColor ?? ds.pointBorderColor ?? "black",
                ),
                borderRadius: ds.type === "scatter" ? 5 : 0,
                marginRight: 4,
              }}
            />
            <CarPdfTypography
              variant="body1"
              style={{
                fontSize: 10,
              }}
            >
              {ds.label}
            </CarPdfTypography>
          </CarPdfBox>
        );
      })}
    </CarPdfBox>
  );
};

export const PortfolioGroupOutUnderPerfPdfChart = ({
  data,
  periods,
  data1Id,
  data2Id,
  isRelative,
  ...props
}: PortfolioGroupOutUnderPerfPdfChartProps) => {
  const theme = useTheme();

  const calcData = getPortfolioGroupOutUnderPerfChartData({
    data,
    data1Id,
    data2Id,
    isRelative,
  });

  if (!calcData) {
    return <></>;
  }

  const {
    line1,
    line2,
    lineOutUnder,
    y1Label,
    y2Label,
    minY,
    maxY,
    minY2,
    maxY2,
  } = calcData;

  const font = {
    size: 10,
    weight: "400",
    lineHeight: 1,
  };

  const config: ChartConfiguration<
    "line" | "scatter",
    OutUnderPerfChartDataPoint[]
  > = {
    type: "line",
    options: {
      devicePixelRatio: 4,
      responsive: false,
      animation: false,
      borderColor: "transparent",

      scales: {
        x: {
          type: "time",
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
          time: {
            unit: "year",
          },
        },
        y: {
          offset: true,
          ticks: {
            font,
            color: theme.palette.black,
            padding: 6,
            callback: (value) =>
              typeof value === "number" ? formatPercentFactor(value) : value,
          },
          grid: {
            display: true,
            drawTicks: false,
            color: (ctx) =>
              ctx.tick.value === 0 ? "#B1B1B3" : "rgba(0, 0, 0, 0)",

            lineWidth: 1,
          },
          title: {
            display: true,
            text: y1Label,
            color: theme.palette.black,
            font,
          },
          min: minY,
          max: maxY,
        },
        y2: {
          offset: true,
          position: "right",
          border: { display: false },
          grid: {
            display: false,
          },
          ticks: {
            font,
            color: theme.palette.black,
            padding: 6,
            callback: (value) =>
              typeof value === "number" ? formatPercentFactor(value) : value,
          },
          title: {
            display: true,
            text: y2Label,
            color: theme.palette.black,
            font,
          },
          min: minY2,
          max: maxY2,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        roundedBackground: {
          contextColor: theme.palette.white,
          backgroundColor: "#FAFAFA", //theme.palette.gray1,
          borderColor: "#B1B1B3", // theme.palette.gray7,
          borderRadius: 0,
        },
        periods: {
          backgroundColor: "#EDEEF0", // "rgba(236, 238, 240, 0.5)",
          borderColor: "#E1E2E3", // "rgba(178, 179, 181, 0.5)",
          font: {
            family: theme.typography.fontFamily,
            size: 14,
          },
          textColor: "#88898A", // theme.palette.softBlack,
          items: periods.map((p) => ({
            start: new Date(p.startDate).getTime(),
            end: new Date(p.endDate).getTime(),
            label: p.label,
          })),
        },
        yearTicks: {
          color: "#B1B1B3",
          font: {
            family: theme.typography.fontFamily,
            size: 11,
          },
          marginTop: 30,
        },
      },
    },
    data: {
      datasets: [
        {
          ...line1,
          borderColor: theme.palette.chartsColor.green,
          pointBorderWidth: 0,
          pointRadius: 0,
          borderWidth: 1,
        },
        {
          ...line2,
          borderColor: theme.palette.chartsColor.blue,
          pointBorderWidth: 0,
          pointRadius: 0,
          borderWidth: 1,
        },
        {
          ...lineOutUnder,
          yAxisID: "y2",
          borderColor: theme.palette.chartsColor.orange,
          pointBorderColor: theme.palette.chartsColor.orange,
          pointBackgroundColor: theme.palette.chartsColor.orange,
          pointBorderWidth: 1,
          pointRadius: 1,
        },
      ],
    },
    plugins: [
      ChartPlugins.roundedBackground,
      ChartPlugins.periods,
      ChartPlugins.yearTicks,
    ],
  };

  return (
    <>
      <CarPdfChart {...config} {...props} />
      <PdfChartLegend data={config.data} />
    </>
  );
};
