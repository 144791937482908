import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import {
  ChartTooltip,
  RenderTooltip,
  defaultChartTooltipData,
  useChartTooltip,
} from "components/ChartTooltip";
import { useCallback, useMemo, useRef } from "react";
import { Scatter } from "react-chartjs-2";
import { isDefined } from "utils";
import {
  CarAssetClassAnalysisItem,
  CarAssetClassAnalysisItemKey,
} from "./useAssetClassSummaryStat";
import { ChartPlugins } from "chartUtils";
import {
  getAssetClassAnalysisFormatValue,
  getAssetClassAnalysisLabel,
} from "./AssetClassAnalysisTable";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { UseChartHighlight, useChartHighlight } from "app/useChartHighlight";
import { CarChartLegend } from "components/ChartLegend";

export type ChartRiskAndReturnData = Array<{
  name: string;
  risk: number;
  return: number;
}>;
interface UseAssetClassAnalysisChartDataParams {
  items: CarAssetClassAnalysisItem[];
  xStatId: CarAssetClassAnalysisItemKey;
  yStatId: CarAssetClassAnalysisItemKey;
}

const useAssetClassAnalysisChartData = ({
  items,
  xStatId,
  yStatId,
}: UseAssetClassAnalysisChartDataParams) => {
  const theme = useTheme();

  const colorSet = useMemo(
    () => ({
      colors: [
        theme.palette.chartsColor.green,
        theme.palette.chartsColor.aqua,
        theme.palette.chartsColor.orange,
        theme.palette.chartsColor.blue,
        theme.palette.chartsColor.purple,
        theme.palette.chartsColor.yellow,
        theme.palette.chartsColor.red,
        theme.palette.chartsColor.darkPurple,
        theme.palette.chartsColor.darkOrange,
        theme.palette.chartsColor.darkGreen,
        theme.palette.chartsColor.darkRed,
        theme.palette.chartsColor.darkBlue,
        theme.palette.chartsColor.lightBlue,
        theme.palette.chartsColor.lightOrange,
        theme.palette.chartsColor.lightGreen,
        theme.palette.chartsColor.lightRed,
        theme.palette.chartsColor.lightAqua,
        theme.palette.chartsColor.lightPurple,
        theme.palette.chartsColor.dirtyGreen,
        theme.palette.chartsColor.dirtyYellow,
        theme.palette.chartsColor.dirtyOrange,
      ],
      getColor(index: number) {
        return this.colors[index++ % this.colors.length];
      },
    }),
    [theme],
  );

  return useMemo(() => {
    const data = items
      .map((i, idx) => {
        const x = i[xStatId];
        const y = i[yStatId];
        return isDefined(x) && isDefined(y)
          ? {
              name: i.name,
              x,
              y,
              color: i.color || colorSet.getColor(idx),
            }
          : undefined;
      })
      .filter(isDefined);

    return {
      data,
      xLabel: getAssetClassAnalysisLabel(xStatId),
      xFormatValue: getAssetClassAnalysisFormatValue(xStatId),
      yLabel: getAssetClassAnalysisLabel(yStatId),
      yFormatValue: getAssetClassAnalysisFormatValue(yStatId),
    };
  }, [items, xStatId, yStatId, colorSet]);
};

type UseAssetClassAnalysisChartData = ReturnType<
  typeof useAssetClassAnalysisChartData
>;

export interface AssetClassAnalysisChartProps {
  items: CarAssetClassAnalysisItem[];
  xStatId: CarAssetClassAnalysisItemKey;
  yStatId: CarAssetClassAnalysisItemKey;
}
type ChartRef = ChartJSOrUndefined<
  "scatter",
  {
    x: number;
    y: number;
  }[],
  unknown
>;

interface InternalChartProps {
  sx?: BoxProps["sx"];
  chartData: UseAssetClassAnalysisChartData;
  chartHighlight: UseChartHighlight;
  showPeriods: boolean;
  isOvervalued?: boolean;
  isUndervalued?: boolean;
}

const InternalChart = ({
  sx,
  chartData,
  showPeriods,
  chartHighlight,
  isOvervalued,
  isUndervalued,
}: InternalChartProps) => {
  const theme = useTheme();

  const { data, xLabel, yLabel, xFormatValue, yFormatValue } = chartData;

  const { tooltipPlugin, tooltipData, setTooltipData } = useChartTooltip({
    chartHighlight,
  });
  const chartRef = useRef<ChartRef | null>();

  const chartRefCallback = useCallback(
    (value: ChartRef | null) => {
      chartHighlight.unsubscribe(chartRef.current);
      chartRef.current = value;

      chartHighlight.subscribe(chartRef.current, (hlValue) => {
        if (!isDefined(hlValue.dataIndex)) {
          setTooltipData(defaultChartTooltipData);
          return;
        }

        if (chartRef.current) {
          const { width, height } = chartRef.current.canvas;
          const data = chartRef.current.data.datasets[0].data;

          const x = chartRef.current.scales.x.getPixelForValue(
            data[hlValue.dataIndex].x,
          );
          const y = chartRef.current.scales.y.getPixelForValue(
            data[hlValue.dataIndex].y,
          );
          setTooltipData({
            opacity: 1,
            x,
            y,
            canvasSize: { width, height },
            datasetIndex: 0,
            dataIndex: hlValue.dataIndex,
          });
        }

        chartRef.current?.update();
      });
    },
    [chartHighlight, setTooltipData],
  );

  const renderTooltip: RenderTooltip = useCallback(
    (datasetIndex, dataIndex) => {
      const item = data[dataIndex];
      if (!item) {
        return null;
      }

      return (
        <>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: 600,
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
              {item.name}
            </Typography>
            <br />
            <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
              {`${xLabel}: ${xFormatValue(item.x)}`}
            </Typography>
            <br />
            <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
              {`${yLabel}: ${yFormatValue(item.y)}`}
            </Typography>
          </Box>
        </>
      );
    },
    [data, xLabel, yLabel, xFormatValue, yFormatValue],
  );

  return (
    <ChartTooltip
      sx={sx}
      tooltipData={tooltipData}
      renderTooltip={renderTooltip}
    >
      <Scatter
        ref={chartRefCallback}
        data={{
          datasets: [
            {
              label: "data",
              data: data.map((d) => ({
                x: d.x,
                y: d.y,
              })),
              pointBorderColor(ctx) {
                return data[ctx.dataIndex]?.color;
              },
              pointBackgroundColor(ctx) {
                return data[ctx.dataIndex]?.color;
              },
              pointBorderWidth: 5,
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          animation: false,
          scales: {
            x: {
              min: isOvervalued ? 0 : undefined,
              max: isUndervalued ? 0 : undefined,
              offset: !isOvervalued && !isUndervalued,
              title: {
                display: true,
                text: xLabel,
                color: theme.palette.softBlack,
                font: {
                  family: theme.typography.fontFamily,
                  size: 15,
                  weight: "600",
                  lineHeight: 1,
                },
                padding: {
                  top: 14,
                },
              },
              ticks: {
                font: {
                  family: theme.typography.fontFamily,
                  size: 15,
                  weight: "600",
                },
                callback: (value) =>
                  typeof value === "number" ? xFormatValue(value) : value,
              },
              border: { display: false },
              grid: {
                display: true,
                color: theme.palette.gray2,
                tickColor: theme.palette.white,
              },
            },
            y: {
              title: {
                display: true,
                text: yLabel,
                color: theme.palette.softBlack,
                font: {
                  family: theme.typography.fontFamily,
                  size: 15,
                  weight: "600",
                  lineHeight: 1,
                },
                padding: {
                  bottom: 20,
                },
              },
              ticks: {
                font: {
                  family: theme.typography.fontFamily,
                  size: 15,
                  weight: "600",
                },
                callback: (value) =>
                  typeof value === "number" ? yFormatValue(value) : value,
              },
              border: { display: false },
              grid: {
                display: true,
                color: theme.palette.gray2,
                tickColor: theme.palette.white,
              },
              offset: true,
            },
          },
          interaction: {
            mode: "point",
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: tooltipPlugin,
            roundedBackground: {
              contextColor: theme.palette.gray1,
              backgroundColor: theme.palette.white,
              borderColor: theme.palette.gray7,
              borderRadius: 5,
            },
            periods: showPeriods
              ? {
                  labelPosition: "horizontal",
                  borderColor: theme.palette.gray4,
                  font: {
                    family: theme.typography.fontFamily,
                    size: 14,
                    weight: "600",
                  },
                  textColor: theme.palette.softBlack,
                  items: [
                    {
                      start: -10,
                      end: -0.1,
                      label: "Undervalued",
                    },
                    {
                      start: -0.1,
                      end: 0,
                      label: "Below Trend",
                      backgroundColor: "rgba(236, 238, 240, 0.5)",
                    },
                    {
                      start: 0,
                      end: 0.1,
                      label: "Above Trend",
                      backgroundColor: "rgba(236, 238, 240, 0.5)",
                    },
                    {
                      start: 0.1,
                      end: 10,
                      label: "Overvalued",
                    },
                  ],
                }
              : undefined,
          },
        }}
        plugins={[ChartPlugins.roundedBackground, ChartPlugins.periods]}
      />
    </ChartTooltip>
  );
};

export interface AssetClassAnalysisChartProps {
  items: CarAssetClassAnalysisItem[];
  xStatId: CarAssetClassAnalysisItemKey;
  yStatId: CarAssetClassAnalysisItemKey;
}

export const AssetClassAnalysisChart = ({
  items,
  xStatId,
  yStatId,
}: AssetClassAnalysisChartProps) => {
  const theme = useTheme();

  const chartData = useAssetClassAnalysisChartData({ items, xStatId, yStatId });
  const chartHighlight = useChartHighlight();

  const showPeriods =
    xStatId === "dft" &&
    (yStatId === "nominalPriceDrivenExpectedReturn" ||
      yStatId === "realPriceDrivenExpectedReturn");

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="mediumItalic"
        sx={{
          color: "black",
          alignSelf: "flex-end",
          mr: 1,
        }}
      >
        INTERNAL USE ONLY
      </Typography>
      <InternalChart
        sx={{
          height: 614,
        }}
        chartData={chartData}
        showPeriods={showPeriods}
        chartHighlight={chartHighlight}
      />
      <CarChartLegend
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          rowGap: 1,
          width: "100%",
          padding: theme.spacing(0, 2.5, 0, 7),
          mt: 4,
        }}
        items={chartData.data.map((d, idx) => ({
          label: d.name,
          color: d.color,
          datasetIndex: 0,
          dataIndex: idx,
        }))}
        chartHighlight={chartHighlight}
      />
    </Box>
  );
};

export interface AssetClassAnalysisDashboardChartProps {
  items: CarAssetClassAnalysisItem[];
  xStatId: CarAssetClassAnalysisItemKey;
  yStatId: CarAssetClassAnalysisItemKey;
  isOvervalued?: boolean;
  isUndervalued?: boolean;
}

export const AssetClassAnalysisDashboardChart = ({
  items,
  xStatId,
  yStatId,
  isOvervalued,
  isUndervalued,
}: AssetClassAnalysisDashboardChartProps) => {
  const chartData = useAssetClassAnalysisChartData({
    items,
    xStatId,
    yStatId,
  });

  const showPeriods =
    xStatId === "dft" &&
    (yStatId === "nominalPriceDrivenExpectedReturn" ||
      yStatId === "realPriceDrivenExpectedReturn");

  const chartHighlight = useChartHighlight();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <InternalChart
        sx={{
          height: 335,
        }}
        isOvervalued={isOvervalued}
        isUndervalued={isUndervalued}
        chartData={chartData}
        showPeriods={showPeriods}
        chartHighlight={chartHighlight}
      />
    </Box>
  );
};
