import { PlanYearStats } from "api/carApi.generated";
import { GetInflatedValue } from "app/usePlanId";
import { PriorityNumber } from "const";
import { CarGoalResult } from "types";
import { priorityNumToString, roundCurrency } from "utils";

export interface BarChartYearGoal {
  goalId: string;
  label: string;
  amount: number;
  successAmount: number;
  failedAmount: number;
}

interface BarChartYearAmount {
  need: number;
  want: number;
  dream: number;
  failed: number;
  halfFailed: number;
  disabled: number;
  totalNetWorth: number;
  endingTotalPortfolioValue: number;
}
export interface BarChartYear {
  year: number;
  amount: BarChartYearAmount;
  goals: BarChartYearGoal[];
}

export interface BarChartGoal {
  goalId: string;
  label: string;
}

export interface UseBarChart {
  goals: BarChartGoal[];
  years: BarChartYear[];
}

export const getEmptyBarChartYear = (year: number) => ({
  year,
  amount: {
    disabled: 0,
    need: 0,
    want: 0,
    dream: 0,
    failed: 0,
    halfFailed: 0,
    totalNetWorth: 0,
    endingTotalPortfolioValue: 0,
  },
  goals: [],
});

interface UseBarChartParams {
  getInflatedValue: GetInflatedValue;
  goalResults: CarGoalResult[];
  yearStats: PlanYearStats[];
}

export const useBarChart = ({
  getInflatedValue,
  goalResults,
  yearStats,
}: UseBarChartParams): UseBarChart => {
  const yearMap = new Map<number, BarChartYear>();

  const getYear = (year: number) => {
    let item = yearMap.get(year);
    if (!item) {
      item = getEmptyBarChartYear(year);
      yearMap.set(year, item);
    }
    return item;
  };

  const goals: BarChartGoal[] = [];

  goalResults.forEach((gr) => {
    const isGoalEnabled = gr.goal_status !== "disabled";

    const label = isGoalEnabled
      ? `${gr.base_goal_name}, ${priorityNumToString(gr.base_goal_priority)}`
      : `${gr.base_goal_name}, Disabled`;

    goals.push({
      goalId: gr.base_goal_id,
      label,
    });

    gr.period_results?.forEach((goalPeriodResult) => {
      const amountRequested = getInflatedValue(
        goalPeriodResult.amount_requested_with_inflation,
        goalPeriodResult.amount_requested_without_inflation,
      );

      const amountFunded = getInflatedValue(
        goalPeriodResult.amount_funded_with_inflation,
        goalPeriodResult.amount_funded_without_inflation,
      );

      // do not show a goal in the list if nothing has been requested
      if (amountRequested <= 0) {
        return;
      }

      const item = getYear(goalPeriodResult.year);

      if (!isGoalEnabled) {
        if (amountRequested > 0) {
          item.amount.disabled += amountRequested;
          const goalInfo: BarChartYearGoal = {
            goalId: gr.base_goal_id,
            label,
            amount: amountRequested,
            successAmount: 0,
            failedAmount: 0,
          };
          item.goals.push(goalInfo);
        }
        return;
      }

      const success = roundCurrency(amountFunded);
      const failed = roundCurrency(amountRequested) - success;

      if (Math.round(success) > 0) {
        item.amount.halfFailed += failed;
      } else {
        item.amount.failed += failed;
      }

      if (success < 0 || failed < 0) {
        console.warn(
          "useBarChart data is not correct",
          success,
          failed,
          goalPeriodResult,
        );
      }

      switch (gr.base_goal_priority) {
        case PriorityNumber.Need:
          item.amount.need += success;
          break;
        case PriorityNumber.Want:
          item.amount.want += success;
          break;
        case PriorityNumber.Dream:
          item.amount.dream += success;
          break;
      }

      const goalInfo: BarChartYearGoal = {
        goalId: gr.base_goal_id,
        label,
        amount: amountRequested,
        successAmount: success,
        failedAmount: failed,
      };
      item.goals.push(goalInfo);
    });
  });

  yearStats.forEach((yearStatItem) => {
    const item = getYear(yearStatItem.year);
    item.amount.totalNetWorth = getInflatedValue(
      yearStatItem.total_net_worth,
      yearStatItem.total_net_worth_deflated,
    );
    item.amount.endingTotalPortfolioValue = getInflatedValue(
      yearStatItem.ending_total_portfolio_value,
      yearStatItem.ending_total_portfolio_value_deflated,
    );
  });

  // fill gap years
  const minYear = Math.min(...Array.from(yearMap.keys()));
  const maxYear = Math.max(...Array.from(yearMap.keys()));

  for (let year = minYear; year < maxYear; year++) {
    if (!yearMap.get(year)) {
      yearMap.set(year, getEmptyBarChartYear(year));
    }
  }

  return {
    goals,
    years: Array.from(yearMap.values()).sort((a, b) => a.year - b.year),
  };
};
