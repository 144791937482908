import { Typography, useTheme } from "@mui/material";
import { BoxProps, Box } from "@mui/system";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { CarStyleBoxItem, UsePortfolioSummary } from "./usePortfolioSummary";
import { formatPercentFactor } from "utils";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

const StyleBoxItem = (props: { value: number; isHeatmap?: boolean }) => {
  const theme = useTheme();

  const getValueColor = () => {
    if (props.value === 0) {
      return theme.palette.gray6;
    }

    if (props.value > 0.05) {
      return theme.palette.chartsColor.green;
    }

    if (props.value < -0.05) {
      return theme.palette.chartsColor.red;
    }

    return props.value > 0
      ? theme.palette.chartsColor.lightGreen
      : theme.palette.chartsColor.lightRed;
  };

  const color = props.isHeatmap
    ? getValueColor()
    : theme.palette.chartsColor.orange;

  return (
    <Box
      sx={{
        width: 78,
        height: 78,
        border: "1px solid",
        borderColor: "gray3",
        borderRadius: "5px",
        backgroundColor: color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6SSemiBold" sx={{ color: "white" }}>
        {formatPercentFactor(props.value)}
      </Typography>
    </Box>
  );
};

const StyleBox = (props: {
  sx?: BoxProps["sx"];
  items: CarStyleBoxItem[];
  isHeatmap?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content 1fr 1fr 1fr",
        gridTemplateRows: "min-content 1fr 1fr 1fr",
        gap: "5px",
        ...props.sx,
      }}
    >
      <div />
      <Typography variant="par01Regular" sx={{ textAlign: "center" }}>
        Value
      </Typography>
      <Typography variant="par01Regular" sx={{ textAlign: "center" }}>
        Blend
      </Typography>
      <Typography variant="par01Regular" sx={{ textAlign: "center" }}>
        Growth
      </Typography>
      <Typography
        variant="par01Regular"
        sx={{
          gridRow: 4,
          gridColumn: 0,
          writingMode: "vertical-lr",
          transform: "rotate(180deg)",
          textAlign: "center",
        }}
      >
        Small
      </Typography>
      <Typography
        variant="par01Regular"
        sx={{
          gridRow: 3,
          gridColumn: 0,
          writingMode: "vertical-lr",
          transform: "rotate(180deg)",
          textAlign: "center",
        }}
      >
        Mid
      </Typography>
      <Typography
        variant="par01Regular"
        sx={{
          gridRow: 2,
          gridColumn: 0,
          writingMode: "vertical-lr",
          transform: "rotate(180deg)",
          textAlign: "center",
        }}
      >
        Large
      </Typography>
      {props.items.map((i, idx) => (
        <StyleBoxItem key={idx} value={i.weight} isHeatmap={props.isHeatmap} />
      ))}
    </Box>
  );
};

interface PortfolioStyleBoxProps {
  portfolioSummary: UsePortfolioSummary;
  sx?: BoxProps["sx"];
}

export const PortfolioStyleBox = ({
  portfolioSummary,
  sx,
}: PortfolioStyleBoxProps) => {
  return (
    <RootPageBox
      sx={{
        p: 3,
        pb: 5,
        ...sx,
      }}
    >
      <TablePlaceHolder isLoading={portfolioSummary.isLoading} rows={5}>
        <Typography variant="h5SSemiBold">
          {`Style Box - ${portfolioSummary.title}`}
          <CarTooltipBox
            sx={{ display: "inline-block", ml: 0.5, mb: -0.25 }}
            className={pendoClasses.portfolioAnalysisDashboardStyleBox}
          />
        </Typography>
        <Box
          sx={{
            mt: 5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6SRegular" sx={{ ml: "24px" }}>
              Portfolio
            </Typography>
            <StyleBox
              sx={{ mt: 3 }}
              items={portfolioSummary.styleBoxPortfolio}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h6SRegular" sx={{ ml: "24px" }}>
              Portfolio vs. Benchmark
            </Typography>
            <StyleBox
              sx={{ mt: 3 }}
              items={portfolioSummary.styleBoxPortfolioVsBenchmark}
              isHeatmap
            />
          </Box>
        </Box>
      </TablePlaceHolder>
    </RootPageBox>
  );
};
