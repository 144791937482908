import { ChangeEvent, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  RadioGroupProps,
  Typography,
  styled,
} from "@mui/material";
import { CarRiskQuestionAnswers, CarRiskQuestion } from "types";
import { CarRadio } from "components/Radio";

interface CarRadioItem {
  label: string;
  value: string;
}
interface RiskRadioGroupProps extends RadioGroupProps {
  items: Array<CarRadioItem>;
}

const RiskRadioGroup = ({ items, ...props }: RiskRadioGroupProps) => {
  return (
    <FormControl component="div">
      <RadioGroup {...props}>
        {items.map((i, idx) => (
          <FormControlLabel
            sx={{
              mx: 0,
              minHeight: 55,
              mb: 1,
              alignItems: "flex-start",
              "& > span:last-child": {
                ml: 1,
                mt: 0.8,
              },
            }}
            componentsProps={{
              typography: { variant: "par02Regular" },
            }}
            key={idx}
            value={i.value}
            control={<CarRadio sx={{ p: 0.4 }} />}
            label={i.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

interface RiskDetailQuestionareProps {
  answers: CarRiskQuestionAnswers;
  canFinish: boolean;
  onChange: (answers: CarRiskQuestionAnswers) => void;
  onFinish: () => void;
  questions: Array<CarRiskQuestion>;
}

const NavButton = styled(Button)(({ theme }) => ({
  ...theme.typography.h6SBold,
}));

export const RiskDetailQuestionare = (props: RiskDetailQuestionareProps) => {
  const [currentQuestionId, setCurrentQuestionId] = useState(
    props.questions[0]?.id
  );

  const currentQuestionIdx = props.questions.findIndex(
    (i) => i.id === currentQuestionId
  );
  const currentQuestion = props.questions[currentQuestionIdx];

  const prevQuestionId = props.questions[currentQuestionIdx - 1]?.id;
  const nextQuestionId = props.questions[currentQuestionIdx + 1]?.id;

  if (!props.questions || !currentQuestion) {
    return <></>;
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (currentQuestionId) {
      props.onChange([
        ...props.answers.filter(
          (i) => i.question_object_id !== currentQuestionId
        ),
        {
          question_object_id: currentQuestionId,
          answer_id: e.target.value,
        },
      ]);
    }
  };

  const handleFinish = () => {
    setCurrentQuestionId(props.questions[0]?.id);
    props.onFinish();
  };

  const questionCount = Object.keys(props.questions).length;

  return (
    <Box
      sx={{
        backgroundColor: "white",
        border: "solid 1px",
        borderColor: "gray3",
        borderRadius: "5px",
        px: 3.5,
        py: 4,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography variant="h5SemiBold">
        Question {currentQuestionIdx + 1} of {questionCount}
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="par02Regular">{currentQuestion.text}</Typography>
      <RiskRadioGroup
        sx={{ ml: 2, mt: 5 }}
        items={(currentQuestion.answers ?? []).map((a) => ({
          label: a.text ?? "",
          value: a.id ?? "",
        }))}
        value={
          props.answers.find((i) => i.question_object_id === currentQuestionId)
            ?.answer_id
        }
        onChange={handleChange}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <NavButton
          disabled={!prevQuestionId}
          onClick={(e) => setCurrentQuestionId(prevQuestionId)}
        >{`< Previous`}</NavButton>

        {nextQuestionId ? (
          <NavButton
            disabled={!nextQuestionId}
            onClick={(e) => setCurrentQuestionId(nextQuestionId)}
          >{`Continue >`}</NavButton>
        ) : (
          <NavButton disabled={!props.canFinish} onClick={handleFinish}>
            Finish
          </NavButton>
        )}
      </Box>
    </Box>
  );
};
