import {
  useQuestionnairePlanRiskRetrieveQuery,
  useQuestionnairePlanRiskUpdateMutation,
  useQuestionnaireRiskQuestionListQuery,
} from "api/carApi.generated";
import { CarRisk, SectionState } from "types";
import { refetchOnFocus } from "const";
import { usePlanId } from "app/usePlanId";
import { useDispatch } from "react-redux";
import { pendingActions } from "../general/pendingSlice";
import { isDefined } from "utils";

export const useRisk = () => {
  const planId = usePlanId();
  const dispatch = useDispatch();
  const { data: item, isLoading } = useQuestionnairePlanRiskRetrieveQuery(
    { id: planId },
    { refetchOnFocus }
  );

  const [updateRel] = useQuestionnairePlanRiskUpdateMutation();
  const { data: questions, isLoading: isQuestionsLoading } =
    useQuestionnaireRiskQuestionListQuery();

  const handleChange = async (item: CarRisk) => {
    dispatch(pendingActions.setIsUpdating(true));
    try {
      return await updateRel({
        id: planId,
        clientPlanRisk: item as Required<CarRisk>,
      });
    } finally {
      dispatch(pendingActions.setIsUpdating(false));
    }
  };

  const canFinish =
    isDefined(item?.calculated_risk_score) &&
    isDefined(questions) &&
    questions.every((i) =>
      item?.question_answers?.some((qa) => qa.question_object_id === i.id)
    );

  const handleFinish = () => {
    if (!item) {
      return;
    }

    const calcRisk = item.calculated_risk_score;

    if (!isDefined(calcRisk)) {
      return;
    }

    const calcRounded = Math.round(calcRisk / 5) * 5;
    handleChange({
      ...item,
      using_questions: false,
      question_answers: [],
      eq_weight: calcRounded,
      fi_weight: 100 - calcRounded,
    });
  };

  const isRiskValid = () => {
    return item?.using_questions
      ? !!questions?.every((i) =>
          item?.question_answers?.some((qa) => qa.question_object_id === i.id)
        )
      : !!item?.eq_weight || item?.fi_weight;
  };

  const getState = () => {
    if (isRiskValid()) {
      return SectionState.complete;
    }
    if (item?.using_questions && item.question_answers?.length === 0) {
      return SectionState.notTouched;
    }
    return SectionState.workInProgress;
  };

  return {
    item,
    isLoading,
    questions,
    isQuestionsLoading,
    isRiskValid,
    handleChange,
    handleFinish,
    canFinish,
    state: getState(),
  };
};
