import { TypographyVariantsOptions } from "@mui/material";
import { caravelColors } from "./palette";

declare module "@mui/material/styles" {
  interface TypographyVariantsOptions {
    par01Regular: React.CSSProperties;
    par01SemiBold: React.CSSProperties;
    par02Regular: React.CSSProperties;
    par02SemiBold: React.CSSProperties;
    par02Bold: React.CSSProperties;
    par03Regular: React.CSSProperties;
    par03SemiBold: React.CSSProperties;
    dis01SemiBold: React.CSSProperties;
    dis02SemiBold: React.CSSProperties;
    dis02SBold: React.CSSProperties;
    h1SemiBold: React.CSSProperties;
    h1Bold: React.CSSProperties;
    h2SemiBold: React.CSSProperties;
    h2Bold: React.CSSProperties;
    h1SSemiBold: React.CSSProperties;
    h1SBold: React.CSSProperties;
    h3SMedium: React.CSSProperties;
    h3SemiBold: React.CSSProperties;
    h3SBold: React.CSSProperties;
    h2SSemiBold: React.CSSProperties;
    h3SSemiBold: React.CSSProperties;
    h4SemiBold: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h4SSemiBold: React.CSSProperties;
    h4SBold: React.CSSProperties;
    h5SBold: React.CSSProperties;
    h5SRegular: React.CSSProperties;
    h5SMedium: React.CSSProperties;
    h5SSemiBold: React.CSSProperties;
    h5SemiBold: React.CSSProperties;
    h5Bold: React.CSSProperties;
    h6SemiBold: React.CSSProperties;
    h6Bold: React.CSSProperties;
    h6SRegular: React.CSSProperties;
    h6SSemiBold: React.CSSProperties;
    h6SBold: React.CSSProperties;
    mediumItalic: React.CSSProperties;
    subhRegular: React.CSSProperties;
    subhSemiBold: React.CSSProperties;
  }

  interface TypographyVariants {
    par01Regular: React.CSSProperties;
    par01SemiBold: React.CSSProperties;
    par02Regular: React.CSSProperties;
    par02SemiBold: React.CSSProperties;
    par02Bold: React.CSSProperties;
    par03Regular: React.CSSProperties;
    par03SemiBold: React.CSSProperties;
    dis01SemiBold: React.CSSProperties;
    dis02SemiBold: React.CSSProperties;
    dis02SBold: React.CSSProperties;
    h1SemiBold: React.CSSProperties;
    h1Bold: React.CSSProperties;
    h2SemiBold: React.CSSProperties;
    h2Bold: React.CSSProperties;
    h1SSemiBold: React.CSSProperties;
    h2SSemiBold: React.CSSProperties;
    h3SSemiBold: React.CSSProperties;
    h1SBold: React.CSSProperties;
    h3SMedium: React.CSSProperties;
    h3SemiBold: React.CSSProperties;
    h3SBold: React.CSSProperties;
    h4SemiBold: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h4SSemiBold: React.CSSProperties;
    h4SBold: React.CSSProperties;
    h5SBold: React.CSSProperties;
    h5SRegular: React.CSSProperties;
    h5SMedium: React.CSSProperties;
    h5SSemiBold: React.CSSProperties;
    h5SemiBold: React.CSSProperties;
    h5Bold: React.CSSProperties;
    h6SemiBold: React.CSSProperties;
    h6Bold: React.CSSProperties;
    h6SRegular: React.CSSProperties;
    h6SSemiBold: React.CSSProperties;
    h6SBold: React.CSSProperties;
    mediumItalic: React.CSSProperties;
    subhRegular: React.CSSProperties;
    subhSemiBold: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    par01Regular: true;
    par01SemiBold: true;
    par02Regular: true;
    par02SemiBold: true;
    par02Bold: true;
    par03Regular: true;
    par03SemiBold: true;
    dis01SemiBold: true;
    dis02SemiBold: true;
    dis02SBold: true;
    h1SemiBold: true;
    h1Bold: true;
    h1SSemiBold: true;
    h2SSemiBold: true;
    h3SSemiBold: true;
    h1SBold: true;
    h2SemiBold: true;
    h2Bold: true;
    h3SMedium: true;
    h3SemiBold: true;
    h3SBold: true;
    h4SemiBold: true;
    h4Bold: true;
    h4SSemiBold: true;
    h4SBold: true;
    h5SBold: true;
    h5SRegular: true;
    h5SMedium: true;
    h5SSemiBold: true;
    h5SemiBold: true;
    h5Bold: true;
    h6SemiBold: true;
    h6Bold: true;
    h6SRegular: true;
    h6SSemiBold: true;
    h6SBold: true;
    mediumItalic: true;
    subhRegular: true;
    subhSemiBold: true;
    // h3: false;
  }
}

export const typographyOptions: TypographyVariantsOptions = {
  fontFamily: "proximanova,arial",
  //Paragraph 01 Regular
  par01Regular: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: caravelColors.softBlack,
  },
  //Paragraph 01 Semi Bold;
  par01SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    color: caravelColors.softBlack,
  },
  //Paragraph 02 Regular
  par02Regular: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: caravelColors.softBlack,
  },

  //Paragraph 02 Semi Bold
  par02SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: caravelColors.softBlack,
  },

  //Paragraph 02 Bold
  par02Bold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19px",
    color: caravelColors.softBlack,
  },

  //Paragraph 03 Regular
  par03Regular: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "19px",
    color: caravelColors.softBlack,
  },

  //Paragraph 03 Semi Bold;
  par03SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    color: caravelColors.softBlack,
  },
  //Display 01 Semi Bold
  dis01SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "72px",
    lineHeight: "80px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //Display 02 Semi Bold
  dis02SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "60px",
    lineHeight: "72px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //Display 02 (S) Bold
  dis02SBold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "60px",
    lineHeight: "72px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //H1 Semi Bold
  h1SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "48px",
    lineHeight: "56px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //H1 Bold
  h1Bold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "48px",
    lineHeight: "56px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //H1 (S) Semi Bold
  h1SSemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "34px",
    lineHeight: "40px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //H2 (S) Semi Bold
  h2SSemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "30px",
    lineHeight: "40px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //H3 (S) Medium
  h3SMedium: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "28px",
    lineHeight: "34px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H3 (S) Semi Bold
  h3SSemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "34px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //H3 (S) Bold
  h3SBold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "34px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H1 (S) Bold
  h1SBold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "34px",
    lineHeight: "40px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //H2 Bold
  h2SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "39px",
    lineHeight: "47px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //H2 Bold
  h2Bold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "39px",
    lineHeight: "47px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.04em",
  },

  //H3 Semi Bold
  h3SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "33px",
    lineHeight: "40px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H4 Semi Bold
  h4SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "28px",
    lineHeight: "34px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H4 Bold
  h4Bold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "34px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H4 (S) Semi Bold
  h4SSemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "23px",
    lineHeight: "28px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H4 (S) Bold
  h4SBold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "23px",
    lineHeight: "28px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H5 (S) Regular
  h5SRegular: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "19px",
    lineHeight: "23px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H5 (S) Medium
  h5SMedium: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px", //  ??? other H5S have 19px. todo clarify
    lineHeight: "23px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H5 (S) Semi Bold
  h5SSemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "23px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },
  //H5 (S) Bold
  h5SBold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "19px",
    lineHeight: "23px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H5 Semi Bold
  h5SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "23px",
    lineHeight: "28px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H5 Bold
  h5Bold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "23px",
    lineHeight: "28px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H6 Semi Bold
  h6SemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "19px",
    lineHeight: "23px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H6 Bold
  h6Bold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "19px",
    lineHeight: "23px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H6 (S) Regular
  h6SRegular: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H6 (S) Bold
  h6SBold: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "19px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //H6 (S) Semi Bold
  h6SSemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: caravelColors.softBlack,
    letterSpacing: "-0.02em",
  },

  //CAPTION
  caption: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14px",
    color: caravelColors.gray7,
    letterSpacing: "0.08em",
    textTransform: "uppercase",
  },

  //Medium Italic
  mediumItalic: {
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: caravelColors.softBlack,
  },

  // Subheading Regular
  subhRegular: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "24px",
    color: caravelColors.softBlack,
  },

  // Subheading Semi-Bold
  subhSemiBold: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "24px",
    color: caravelColors.softBlack,
  },

  // deprecated
  h1: {
    fontSize: 25,
    fontWeight: 700,
    color: "#FF0000",
  },
  h2: {
    fontSize: 20,
    fontWeight: 700,
    color: "#FF0000",
  },
  h3: {
    fontSize: 18,
    fontWeight: 700,
    color: "#FF0000",
  },
  h4: {
    fontSize: 16,
    fontWeight: 700,
    color: "#FF0000",
  },
  h5: {
    fontSize: 16,
    fontWeight: 400,
    // lineHeight: "18.4px",
    color: "#FF0000",
  },
  h6: {
    fontSize: 14,
    fontWeight: 700,
    color: "#FF0000",
  },
  body1: {
    // default
    fontSize: 14,
    fontWeight: 400,
    color: "#FF0000",
  },
  body2: {
    fontSize: 13,
    fontWeight: 400,
    color: "#FF0000",
  },
};

// /* Text-size styles */
// --display-01--semi--bold: 72px;
// --display-02--semi--bold: 60px;
// --h1--semi--bold: 48px;
// --h1--s--semi--bold: 34px;
// --h2--semi--bold: 39px;
// --h2--s--semi--bold: 33px;
// --h3--semi--bold: 33px;
// --h3--semi--bold: 28px;
// --h4--semi--bold: 28px;
// --h4--s--semi--bold: 23px;
// --h5--semi--bold: 23px;
// --h5--s--semi--bold: 19px;
// --h6--semi--bold: 19px;
// --h6--s--semi--bold: 16px;
// --display-01--bold: 72px;
// --display-02--s--bold: 60px;
// --h1--bold: 48px;
// --h1--s--bold: 34px;
// --h2--bold: 39px;
// --h2--s--bold: 33px;
// --h3--bold: 33px;
// --h3--s--bold: 28px;
// --h4--bold: 28px;
// --h4--s--bold: 23px;
// --h5--bold: 23px;
// --h5--s--bold: 19px;
// --h6--bold: 19px;
// --h6--s--bold: 16px;
// --display-01--extra--bold: 72px;
// --display-02--extra--bold: 60px;
// --h1--extra--bold: 48px;
// --h1--s--extra--bold: 34px;
// --h2--extra--bold: 39px;
// --h2--s--extra--bold: 33px;
// --h3--extra--bold: 33px;
// --h3--s--extra--bold: 28px;
// --h4--extra--bold: 28px;
// --h4--s--extra--bold: 23px;
// --h5--extra--bold: 23px;
// --h5--s--extra--bold: 19px;
// --h6--extra--bold: 19px;
// --h6--s--extra--bold: 16px;
// --subheading--underlined: 20px;
// --subheading--semi--bold: 20px;
// --subheading--regular: 20px;
// --paragraph-01--light: 14px;
// --paragraph-01--semi--bold: 14px;
// --paragraph-01--regular: 14px;
// --paragraph-02--regular: 16px;
// --paragraph-02--semi--bold: 16px;
// --paragraph-02--light: 16px;
// --paragraph-03--light: 18px;
// --paragraph-03--semi--bold: 18px;
// --paragraph-03--regular: 18px;
// --c-a-p-t-i-o-n: 12px;
// --caption--semi--bold: 12px;
// --caption--light: 12px;
// --f-o-o-t-e-r: 10px;
// --footer--semi--bold: 10px;
// --footer--light: 10px;
// --medium--italic: 12px;

// /* Effect styles */
// --shadow-behind-large-graphs:  4px 4px 20px rgba(0, 0, 0, 0.15);
