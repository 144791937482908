import clsx from "clsx";
import { Box, BoxProps, Typography, styled } from "@mui/material";
import { CarIconRename, CarIconDelete, CarIconLoad } from "icons";
import { CarMenuButton } from "components/MenuButton";
import { isOddEven } from "utils";
import { TablePlaceHolder } from "components/PlaceHolder";
import SimpleBar from "simplebar-react";
import { usePortfolioGroupOptimizerTemplates } from "./usePortfolioGroupOptimizerTemplates";
// import { CarSwitch } from "components/Switch";

const Header = (props: BoxProps) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "2fr 1fr 1fr",
      mb: 0.5,
      ...props.sx,
    }}
  >
    <Typography
      variant="par01Regular"
      textAlign="start"
      sx={{ pl: 2, fontSize: "13px", color: "gray6" }}
    >
      Name
    </Typography>
    {/* <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Run Monthly
    </Typography> */}
    <div />
    <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Actions
    </Typography>
  </Box>
);
const Row = styled(Box)(({ theme }) => ({
  height: 62,
  border: "solid 1px",
  borderColor: theme.palette.gray6,
  backgroundColor: theme.palette.white,
  "&.odd": {
    backgroundColor: theme.palette.gray2,
  },
  borderRadius: "5px",
  display: "grid",
  gridTemplateColumns: "2fr 1fr 1fr",
  alignItems: "center",
  width: "calc(100% - 10px)",
  marginBottom: theme.spacing(1.5),
  "&:last-of-type": {
    marginBottom: 0,
  },
}));

export const PortfolioGroupOptimizerTemplates = () => {
  const data = usePortfolioGroupOptimizerTemplates();

  if (!data.isLoading && data.items.length === 0) {
    return (
      <Box
        sx={{
          flex: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 311,
        }}
      >
        <Typography variant="par01Regular" sx={{ color: "gray6" }}>
          No templates have been created yet
        </Typography>
      </Box>
    );
  }

  return (
    <TablePlaceHolder
      isLoading={data.isLoading}
      sx={{ height: 311, flex: "auto" }}
      rows={5}
    >
      <Box
        sx={{
          flex: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />
        <Box
          sx={{
            position: "relative",
            flex: "auto",
            height: 288,
          }}
        >
          <SimpleBar
            autoHide={false}
            forceVisible="y"
            style={{
              position: "absolute",
              width: "calc(100% + 18px)",
              height: "100%",
              paddingRight: 8,
            }}
          >
            {data.items.map((row, idx) => {
              return (
                <Row
                  key={row.id}
                  className={clsx({
                    odd: isOddEven(idx),
                  })}
                >
                  <Typography
                    variant="par02Regular"
                    sx={{ pl: 2, textAlign: "start" }}
                  >
                    {row.name}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <CarSwitch value={true} size="small" /> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CarMenuButton
                      items={[
                        {
                          icon: <CarIconLoad />,
                          label: "Load",
                          onClick: () => data.handleLoad(row),
                        },
                        {
                          label: "Rename",
                          icon: <CarIconRename />,
                          onClick: () => data.handleRename(row),
                          hidden: row.caravel_managed,
                        },
                        {
                          label: "Delete",
                          icon: <CarIconDelete />,
                          onClick: () => data.handleDelete(row),
                          hidden: row.caravel_managed,
                        },
                      ]}
                      position="left"
                    />
                  </Box>
                </Row>
              );
            })}
          </SimpleBar>
        </Box>
      </Box>
    </TablePlaceHolder>
  );
};
