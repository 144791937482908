import { RootPagePaperLinkBox } from "components/RootPage";
import { Typography } from "@mui/material";
import { formatDate } from "utils";
import { useLatestSimulationDate } from "pages/assetAllocation/useLatestSimulationDate";

export const SourceDataAsOf = () => {
  const latestSimulationDate = useLatestSimulationDate();

  return (
    <RootPagePaperLinkBox sx={{ width: "100%" }}>
      {latestSimulationDate.latestSimulationDate && (
        <Typography
          sx={{ ml: "auto", alignSelf: "center", color: "white", mb: 1.5 }}
          variant="mediumItalic"
        >{`Source Data as of: ${formatDate(
          latestSimulationDate.latestSimulationDate,
        )}`}</Typography>
      )}
    </RootPagePaperLinkBox>
  );
};
