import {
  CmpasModuleSectionDetail,
  useCommonCmpasModuleSectionRetrieveQuery,
  useCommonCmpasModuleSectionCreateMutation,
  useCommonCmpasModuleSectionUpdateMutation,
  useCommonCmpasModuleListQuery,
} from "api/carApi.generated";
import { useForm } from "./useForm";
import { FormEditorDialog } from "./FormEditorDialog";
import { CarSelectOption } from "components/Inputs";
import { checkDefined, sortBySortOrder } from "utils";

interface ModuleSectionEditorDialogProps {
  id?: string;
  moduleId: string;
  nextSortOrder?: number;
  onApply: () => void;
  onCancel: () => void;
}

export const ModuleSectionEditorDialog = (
  props: ModuleSectionEditorDialogProps,
) => {
  const query = useCommonCmpasModuleSectionRetrieveQuery(
    {
      id: props.id ?? "",
    },
    { skip: !props.id },
  );

  const [create] = useCommonCmpasModuleSectionCreateMutation();
  const [update] = useCommonCmpasModuleSectionUpdateMutation();

  const modules = useCommonCmpasModuleListQuery();

  const form = useForm<CmpasModuleSectionDetail>({
    entityLabel: "Page",
    getItemName: (item) => `${item.name} (${item.code})`,
    isLoading: query.isLoading || modules.isLoading,
    item: query.data,
    getNewItem: () => ({
      code: "",
      name: "",
      cmpas_module: props.moduleId,
      is_active: true,
      sort_order: props.nextSortOrder,
    }),
    onClose: props.onCancel,
    onCreate: (item) =>
      create({
        cmpasModuleSectionDetail: item,
      }),

    onUpdate: (item) =>
      update({
        id: item.id ?? "",
        cmpasModuleSectionDetail: item,
      }),
    onDelete: async (item) => {
      // todo
    },
    fieldDefs: [
      {
        key: "code",
        type: "string",
        label: "Code",
        description: "Unique code for the page",
        isRequired: true,
      },
      {
        key: "name",
        type: "string",
        label: "Name",
        description: "Name of the page",
        isRequired: true,
      },
      {
        key: "description",
        type: "memo",
        label: "Description",
        description: "Description of the page",
      },
      {
        key: "cmpas_module",
        type: "select",
        options:
          sortBySortOrder(modules.data)
            .filter((i) => i.is_active)
            .map<CarSelectOption<string>>((i) => ({
              label: `${i.name} (${i.code})`,
              value: checkDefined(i.id),
            })) ?? [],
        label: "Module",
        description: "The app module this page belongs to",
        isRequired: true,
      },
      {
        key: "front_end_url",
        type: "string",
        label: "Front End Url",
        description:
          "The front end URL for this page. It can include placeholders for dynamic data",
      },
      {
        key: "is_active",
        type: "boolean",
        label: "Is Active",
        description: "Is this page active",
      },
      {
        key: "sort_order",
        type: "integer",
        label: "Sort Order",
        description: "Sort order of page",
      },
    ],
  });

  return <FormEditorDialog form={form} />;
};
