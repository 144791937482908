import { useRef } from "react";
import { Box, BoxProps, Typography } from "@mui/material";
import { formatPercent, formatPercentFactor } from "utils";
import {
  CarSimulationPercentileColumn,
  CarSimulationPercentileRow,
  CarSimulationPercentileTable,
  CarSimulationPercentileValue,
} from "./useSimulationReturns";
import { chartsColor } from "theme";

const formatValue = (value?: number | null) =>
  value
    ? formatPercentFactor(value, {
        decimalPlaces: 2,
        forceShowDecimals: true,
      })
    : "-";

interface LabelHeaderProps {
  sx?: BoxProps["sx"];
  label?: string;
}

const LabelHeader = (props: LabelHeaderProps) => {
  return (
    <Box
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "table.background.header",
        "&:last-of-type": {
          borderRightWidth: 1,
        },
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
            backgroundColor: "white",
          },
        },
        ...props.sx,
      }}
    >
      <Box
        sx={{
          height: 60,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {props.label && (
          <Typography variant="h6SBold">{props.label}</Typography>
        )}
      </Box>
      <Box sx={{ flex: "auto" }} />
    </Box>
  );
};

const getGridTemplateColumns = (columnCount: number) =>
  `1fr repeat(${columnCount}, 1fr)`;

interface ColumnHeaderProps {
  sx?: BoxProps["sx"];
  column: CarSimulationPercentileColumn;
  onYearClick?: (year: number) => void;
}

const ColumnHeader = (props: ColumnHeaderProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Box
      ref={ref}
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        borderLeftWidth: 2,
        borderRightWidth: 2,
        backgroundColor: "table.background.header",
        "&:last-of-type": {
          borderRightWidth: 1,
        },
        "& > *": {
          borderBottom: 1,
          borderBottomColor: "table.border",
          "&:last-of-type": {
            borderBottom: "none",
          },
        },
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        position: "relative",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          px: 1,
          py: 2,
          height: 60,
          width: "100%",
          cursor: props.onYearClick ? "pointer" : undefined,
        }}
        onClick={() => props.onYearClick?.(props.column.year)}
      >
        <Typography
          variant="h6SRegular"
          sx={{
            textDecoration: props.onYearClick ? "underline" : undefined,
            "&.MuiTypography-root": {
              color: props.onYearClick ? chartsColor.blue : undefined,
            },
          }}
        >
          {props.column.year.toString()}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: "auto",
          display: "grid",
          gridTemplateColumns: `repeat(2, 1fr)`,
          "& > *": {
            backgroundColor: "white",
            borderStyle: "solid",
            borderColor: "table.border",
            borderWidth: 0,
            borderLeftWidth: 1,
            "&:first-of-type": {
              borderLeftWidth: 0,
            },
            py: 1.5,
            px: 0.5,
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
          },
        }}
      >
        <Typography variant="par02Regular">Sim</Typography>
        <Typography variant="par02Regular">Hist</Typography>
      </Box>
    </Box>
  );
};

interface RowProps {
  sx?: BoxProps["sx"];
  row: CarSimulationPercentileRow;
  isExtended: boolean;
  isClickable: boolean;
  onPercentileClick?: (percentile: number) => void;
  onValueClick?: (value: CarSimulationPercentileValue) => void;
}

const Row = (props: RowProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        minHeight: 60,
        gridTemplateColumns: "1fr",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(
            props.row.values.filter((i) => i.isExtended === props.isExtended)
              .length,
          ),
        }}
      >
        <Box
          sx={{
            marginTop: "-1px",
            marginLeft: "-1px",
            border: 1,
            borderColor: "table.border",
            p: 1.5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            cursor: props.onPercentileClick ? "pointer" : undefined,
          }}
          onClick={() => props.onPercentileClick?.(props.row.percentile)}
        >
          <Typography
            variant="par02Regular"
            sx={{
              textDecoration: props.onPercentileClick ? "underline" : undefined,
              "&.MuiTypography-root": {
                color: props.onPercentileClick ? chartsColor.blue : undefined,
              },
            }}
          >
            {formatPercent(props.row.percentile, 1)}
          </Typography>
        </Box>
        {props.row.values
          .filter((i) => i.isExtended === props.isExtended)
          .map((val, idx) => {
            return (
              <Box
                key={idx}
                sx={{
                  marginTop: "-1px",
                  marginLeft: "-1px",
                  border: 1,
                  borderColor: "table.border",
                  borderLeftWidth: 2,
                  borderRightWidth: 2,
                  "&:last-of-type": {
                    borderRightWidth: 1,
                  },

                  display: "grid",
                  gridTemplateColumns: `repeat(2, 1fr)`,

                  "& > *": {
                    borderStyle: "solid",
                    borderColor: "table.border",
                    borderWidth: 0,
                    borderLeftWidth: 1,
                    "&:first-of-type": {
                      borderLeftWidth: 0,
                    },

                    overflow: "hidden",
                    textOverflow: "ellipsis",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                    "& > *": {
                      maxWidth: 54,
                    },
                  },
                }}
              >
                <Typography
                  variant="par02Regular"
                  sx={
                    props.isClickable && props.onValueClick
                      ? {
                          "&.MuiTypography-root": {
                            color: chartsColor.blue,
                          },
                          textDecoration: "underline",
                          cursor: "pointer",
                        }
                      : undefined
                  }
                  onClick={
                    props.isClickable
                      ? () => props.onValueClick?.(val)
                      : undefined
                  }
                >
                  {formatValue(val.sim)}
                </Typography>
                <Typography variant="par02Regular">
                  {formatValue(val.his)}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export interface SimulationPercentileTableProps {
  sx?: BoxProps["sx"];
  table: CarSimulationPercentileTable;
  isExtended: boolean;
  onValueClick?: (value: CarSimulationPercentileValue) => void;
  onYearClick?: (year: number) => void;
  onPercentileClick?: (percentile: number) => void;
}

export const SimulationPercentileTable = ({
  sx,
  table,
  isExtended,
  onValueClick,
  onYearClick,
  onPercentileClick,
}: SimulationPercentileTableProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        ".MuiTypography-root": {
          color: "table.text",
        },
        "& > *:first-of-type > *:first-of-type": {
          borderTopLeftRadius: "5px",
        },
        "& > *:first-of-type > *:last-of-type": {
          borderTopRightRadius: "5px",
        },
        "& > *:last-of-type > * > *:first-of-type": {
          borderBottomLeftRadius: "5px",
        },
        "& > *:last-of-type > * > *:last-of-type": {
          borderBottomRightRadius: "5px",
        },
        "& > *:nth-of-type(even)": {
          backgroundColor: "table.background.even",
        },
        "& > *:nth-of-type(odd)": {
          backgroundColor: "table.background.odd",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: getGridTemplateColumns(
            table.columns.filter((i) => i.isExtended === isExtended).length,
          ),
          position: "sticky",
          top: 0,
          backgroundColor: "table.header",
          zIndex: 10,
        }}
      >
        <LabelHeader />
        {table.columns
          .filter((i) => i.isExtended === isExtended)
          .map((i) => (
            <ColumnHeader key={i.year} column={i} onYearClick={onYearClick} />
          ))}
      </Box>
      {table.rows.map((i) => (
        <Row
          key={i.percentile}
          row={i}
          isExtended={isExtended}
          isClickable={!!table.assetClassCode}
          onValueClick={onValueClick}
          onPercentileClick={onPercentileClick}
        />
      ))}
    </Box>
  );
};
