import { MenuItem } from "@mui/material";
import { PortfolioGroupVersionOptimizationJobRoot } from "api/carApi.generated";
import { CarSelectField, CarSelectFieldProps } from "components/Inputs";

interface SelectOptimizationTypeProps extends CarSelectFieldProps {
  items: Array<PortfolioGroupVersionOptimizationJobRoot>;
}

export const SelectOptimizationType = ({
  items,
  sx,
  ...props
}: SelectOptimizationTypeProps) => (
  <CarSelectField
    {...props}
    sx={{
      "& .MuiOutlinedInput-root": {
        backgroundColor: "gray1",
      },
      ...sx,
    }}
  >
    {items.map((i) => (
      <MenuItem key={i.id} value={i.id}>
        {i.title}
      </MenuItem>
    ))}
  </CarSelectField>
);
