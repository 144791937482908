import { CarAssetClassHierarchy, CarPlanResult } from "types";

export interface CarCapitalGainsAllocAsset {
  parentId?: string;
  id: string;
  name: string;
  code: string;
  sort: number;
  children: Array<CarCapitalGainsAllocAsset>;
  values: Array<number>;
}

const filterZeroValue = (item: CarCapitalGainsAllocAsset) =>
  item.values.some((v) => v);

export function calcCapitalGainsAllocAssets(
  items: Array<CarAssetClassHierarchy>,
  data: CarPlanResult[]
) {
  const getAssetClassProposedPercent = (
    planResult: CarPlanResult,
    assetClassLevel4Id?: string
  ) => {
    let gtProposedAmount = 0;
    planResult.asset_results.forEach((ar) => {
      ar.allocation_by_asset_class_results?.forEach((item) => {
        gtProposedAmount += item.proposed_dollars;
      });
    });

    let acProposedAmount = 0;
    planResult.asset_results.forEach((ar) => {
      const item = ar.allocation_by_asset_class_results?.find(
        (i) => i.asset_class_level4_id === assetClassLevel4Id
      );

      if (item) {
        acProposedAmount += item.proposed_dollars;
      }
    });

    return (acProposedAmount / gtProposedAmount) * 100 || 0;
  };

  const extractAssetItem = (
    items: Array<CarAssetClassHierarchy>,
    extractor: (
      i: CarAssetClassHierarchy
    ) => CarCapitalGainsAllocAsset | undefined
  ) => {
    return items
      .reduce((acc, i) => {
        const item = extractor(i);
        return item ? [...acc.filter((v) => v.id !== item.id), item] : acc;
      }, [] as Array<CarCapitalGainsAllocAsset>)
      .sort((a, b) => a.sort - b.sort);
  };

  const getTotalValues = (children: Array<CarCapitalGainsAllocAsset>) => {
    return children.reduce(
      (acc, i) => {
        return acc.map((v, idx) => v + (i.values[idx] ?? 0));
      },
      data.map(() => 0)
    );
  };

  const level4 = extractAssetItem(items, (i) => {
    const v = i.asset_class;

    if (!i.is_active || !v?.is_active) {
      return undefined;
    }

    return (
      v && {
        parentId: v.ac_level3?.id,
        id: v.id ?? "",
        name: v.display_name ?? "",
        code: v.code ?? "",
        sort: v.sort_order ?? 0,
        values: data.map((pr) => getAssetClassProposedPercent(pr, v.id)),
        children: [],
      }
    );
  }).filter(filterZeroValue);

  const level3 = extractAssetItem(items, (i) => {
    const v = i.asset_class?.ac_level3;
    const children = level4.filter((i) => i.parentId === v?.id);
    return (
      v && {
        parentId: i.asset_class?.ac_level3?.ac_level2?.id,
        id: v.id ?? "",
        name: v.name ?? "",
        code: v.code ?? "",
        sort: v.sort_order ?? 0,
        values: getTotalValues(children),
        children,
      }
    );
  }).filter((i) => i.children.length);

  const level2 = extractAssetItem(items, (i) => {
    const v = i.asset_class?.ac_level3?.ac_level2;
    const children = level3.filter((i) => i.parentId === v?.id);
    return (
      v && {
        parentId: i.asset_class?.ac_level3?.ac_level2?.ac_level1?.id,
        id: v.id ?? "",
        name: v.name ?? "",
        code: v.code ?? "",
        sort: v.sort_order ?? 0,
        values: getTotalValues(children),
        children,
      }
    );
  }).filter((i) => i.children.length);

  const level1 = extractAssetItem(items, (i) => {
    const v = i.asset_class?.ac_level3?.ac_level2?.ac_level1;
    const children = level2.filter((i) => i.parentId === v?.id);
    return (
      v && {
        id: v.id ?? "",
        name: v.name ?? "",
        code: v.code ?? "",
        sort: v.sort_order ?? 0,
        values: getTotalValues(children),
        children,
      }
    );
  }).filter((i) => i.children.length);

  return {
    level1,
    level2,
    level3,
    level4,
  };
}
