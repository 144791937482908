import React from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { AccordionProps, AccordionSummaryProps } from "@mui/material";

export const CarAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:before": {
    display: "none",
  },
}));

export const CarAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMore fontSize="small" />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "unset",
  borderBottom: `1px solid ${theme.palette.gray6}`,
  paddingLeft: 0,
  paddingRight: theme.spacing(1.25),
  minHeight: 0,
  alignItems: "baseline",
  ".MuiAccordionSummary-expandIconWrapper": {
    color: theme.palette.softBlack,
    svg: {
      fontSize: "1.4em",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
}));

export const CarAccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
  display: "flex",
  flexDirection: "column",
});
