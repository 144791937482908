import { ReactNode } from "react";
import { Box, BoxProps, Collapse, Typography, styled } from "@mui/material";
import { ExpandLess } from "@mui/icons-material";
import { formatPercent, isDefined } from "utils";
import { CarFactorPercentFieldDelayed } from "components/NumberField";
import { UseExpand, useExpand } from "features/general/useExpand";
import {
  getKeyNavProps,
  useKeyboardNavigation,
} from "app/useKeyboardNavigation";
import {
  CarPortfolioConstrAcRow,
  CarPortfolioConstrAcValue,
  CarPortfolioConstrBand,
  CarPortfolioConstrTable,
  CarPortfolioConstrValue,
} from "./usePortfolioGroupVersionConstraints";
import { PortfolioGroupConstrRow } from "./PortfolioGroupConstrTable";
import { MAX_TOTAL_EQUITY } from "./usePortfolioGroupNewOptimizer";

const StyledField = styled(CarFactorPercentFieldDelayed)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  ".MuiOutlinedInput-root:hover fieldset": {
    borderColor: `${theme.palette.gray7} !important`,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.gray6,
  },
  ".MuiInputBase-root": {
    backgroundColor: theme.palette.white,
  },
}));

const StyledCellBox = styled(Box)(({ theme }) => ({
  marginLeft: "-1px",

  borderStyle: "solid",
  borderColor: theme.palette.gray6,
  borderTopWidth: 0,
  borderBottomWidth: 1,
  borderLeftWidth: 2,
  borderRightWidth: 2,
  "&:last-of-type": {
    borderRightWidth: 1,
  },

  display: "grid",
  gridTemplateColumns: "1fr 1fr",

  "& > *": {
    borderStyle: "solid",
    borderColor: theme.palette.gray6,
    borderWidth: 0,
    "&:nth-of-type(2)": {
      borderLeftWidth: 1,
    },
    "&:nth-of-type(3)": {
      borderLeftWidth: 1,
    },

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const StyledLabelBox = styled(Box)(({ theme }) => ({
  borderStyle: "solid",
  borderWidth: 1,
  borderTopWidth: 0,
  borderColor: theme.palette.gray6,

  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),

  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  overflow: "hidden",
}));

const getGridTemplateColumns = (bandCount: number) =>
  `1.5fr repeat(${bandCount}, 1fr)`;

const Header = (props: { bands: CarPortfolioConstrBand[] }) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: getGridTemplateColumns(props.bands.length),
    }}
  >
    <Box
      sx={{
        border: 1,
        borderColor: "table.border",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "table.background.header",
        borderTopLeftRadius: "5px",
      }}
    />
    {props.bands.map((i, idx, arr) => (
      <Box
        key={i.moduleId}
        sx={{
          marginLeft: "-1px",
          border: 1,
          borderColor: "table.border",
          borderLeftWidth: 2,
          borderRightWidth: 2,
          backgroundColor: "table.background.header",
          "&:last-of-type": {
            borderRightWidth: 1,
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          px: 1,
          py: 2,
          height: 60,
          borderTopRightRadius: idx === arr.length - 1 ? "5px" : undefined,
        }}
      >
        <Typography variant="h6SBold">{i.label}</Typography>
      </Box>
    ))}
  </Box>
);

const AcHeaderRow = (props: { bands: CarPortfolioConstrBand[] }) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: getGridTemplateColumns(props.bands.length),
      backgroundColor: "gray2",
    }}
  >
    <StyledLabelBox>
      <Typography variant="par02Regular">Asset Class Constraints</Typography>
    </StyledLabelBox>
    {props.bands.map((i, idx, arr) => (
      <StyledCellBox
        key={i.moduleId}
        sx={{
          height: 40,
        }}
      >
        <Box>
          <Typography variant="mediumItalic">Min.</Typography>
        </Box>
        <Box>
          <Typography variant="mediumItalic">Max.</Typography>
        </Box>
      </StyledCellBox>
    ))}
  </Box>
);

interface RowProps {
  expand: UseExpand;
  item: CarPortfolioConstrAcRow;
  level: number;
  details?: ReactNode[];
  isLastRow: boolean;
  sx?: BoxProps["sx"];
  onConstrAcValueChange: (value: CarPortfolioConstrAcValue) => void;
  readOnly?: boolean;
}

const Row = (props: RowProps) => {
  const [isExpanded, setIsExpanded] = props.expand.getState(
    props.item.id ?? "",
  );

  const isLastRow = props.isLastRow && !isExpanded;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          minHeight: 60,
          gridTemplateColumns: getGridTemplateColumns(props.item.values.length),
        }}
      >
        <StyledLabelBox
          sx={{
            py: 1.5,
            cursor: props.details?.length ? "pointer" : undefined,
            borderBottomLeftRadius: isLastRow ? "5px" : undefined,
            backgroundColor:
              props.level === 0
                ? `table.background.level1`
                : `table.background.level4`,
          }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <Typography variant="par02Regular" sx={{ ml: props.level * 2 }}>
            {props.item.name}
          </Typography>
          {!!props.details?.length && (
            <ExpandLess
              fontSize="small"
              sx={{
                ml: "auto",
                color: "softBlack",
                transform: `rotate(${isExpanded ? 0 : 180}deg)`,
                transition: "transform 0.3s",
              }}
            />
          )}
        </StyledLabelBox>
        {props.item.values.map((val, idx, arr) => {
          return (
            <StyledCellBox
              key={val.moduleId}
              sx={{
                "& > div > *": {
                  maxWidth: 54,
                },
                borderBottomRightRadius:
                  isLastRow && idx === arr.length - 1 ? "5px" : undefined,
                backgroundColor: `table.background.level${props.level + 1}`,
              }}
            >
              {!props.readOnly && val.isEditable ? (
                <>
                  <Box>
                    <StyledField
                      defaultValue={0}
                      placeholder="0%"
                      decimalPlaces={1}
                      value={val.minAllocation}
                      inputProps={getKeyNavProps({
                        y: `${val.moduleId}_min`,
                        x: props.item.id,
                      })}
                      onChange={(value) => {
                        props.onConstrAcValueChange({
                          ...val,
                          minAllocation: value ?? 0,
                        });
                      }}
                    />
                  </Box>
                  <Box>
                    <StyledField
                      defaultValue={100}
                      placeholder="100%"
                      decimalPlaces={1}
                      value={val.maxAllocation}
                      inputProps={getKeyNavProps({
                        y: `${val.moduleId}_max`,
                        x: props.item.id,
                      })}
                      onChange={(value) => {
                        props.onConstrAcValueChange({
                          ...val,
                          maxAllocation: value ?? 0,
                        });
                      }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Typography variant="par02Regular">
                    {isDefined(val.minAllocation)
                      ? formatPercent(val.minAllocation * 100, 1)
                      : "-"}
                  </Typography>
                  <Typography variant="par02Regular">
                    {isDefined(val.maxAllocation)
                      ? formatPercent(val.maxAllocation * 100, 1)
                      : "-"}
                  </Typography>
                </>
              )}
            </StyledCellBox>
          );
        })}
      </Box>
      {!!props.details?.length && (
        <Collapse in={isExpanded} unmountOnExit>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
            }}
          >
            {props.details}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

interface PortfolioGroupConstrAcTableProps {
  sx?: BoxProps["sx"];
  table: CarPortfolioConstrTable;
  onConstrAcValueChange: (value: CarPortfolioConstrAcValue) => void;
  onConstrValueChange: (value: CarPortfolioConstrValue) => void;
  storageKey: string;
  readOnly?: boolean;
}

export const PortfolioGroupConstrAcTable = ({
  storageKey,
  table,
  onConstrAcValueChange,
  onConstrValueChange,
  sx,
  readOnly,
}: PortfolioGroupConstrAcTableProps) => {
  const expand = useExpand(`PortfolioGroupConstrAcTable_${storageKey}`);

  const { ref } = useKeyboardNavigation();

  const getRenderItem =
    (level: number, isParentLastRow: boolean) =>
    (i: CarPortfolioConstrAcRow, idx: number, arr: Array<unknown>) => {
      return (
        <Row
          key={i.id}
          item={i}
          level={level}
          details={i.children.map(getRenderItem(level + 1, isParentLastRow))}
          onConstrAcValueChange={onConstrAcValueChange}
          expand={expand}
          isLastRow={isParentLastRow && idx === arr.length - 1}
          readOnly={readOnly}
        />
      );
    };

  const maxTotalEquityItem = table.rows.find(
    (i) => i.code === MAX_TOTAL_EQUITY,
  );

  return (
    <Box
      ref={ref}
      sx={{
        display: "grid",
        ".MuiTypography-root": {
          color: "table.text",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "table.background.level1",
          zIndex: 10,
        }}
      >
        <Header bands={table.bands} />
      </Box>
      {maxTotalEquityItem && (
        <PortfolioGroupConstrRow
          hidePercentile
          item={maxTotalEquityItem}
          onConstrValueChange={onConstrValueChange}
          readOnly={readOnly}
        />
      )}
      <AcHeaderRow bands={table.bands} />
      {table.acRows.map((i, idx, arr) =>
        getRenderItem(0, idx === arr.length - 1)(i, idx, arr),
      )}
    </Box>
  );
};
