import { Box } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo } from "react";
import { useSimulationDistanceFromTrend } from "./useSimulationDistanceFromTrend";
import { CarAssetClassSelector } from "components/AssetClassSelector";
import { useChartActiveElement } from "components/ChartTooltip";
import { ChartDftTotalReturnsSummary } from "pages/assetAllocation/editPortfolioGroup/ChartDftTotalReturnsSummary";
import { ChartDftTotalReturns } from "pages/assetAllocation/editPortfolioGroup/ChartDftTotalReturns";
import { ChartDftVersusTenYearReturn } from "pages/assetAllocation/editPortfolioGroup/ChartDftVersusTenYearReturn";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { CarDownloadButton } from "components/Buttons";

interface SimulationDistanceFromTrendProps {
  simulationJobId: string;
}

export const SimulationDistanceFromTrend = memo(
  (props: SimulationDistanceFromTrendProps) => {
    const data = useSimulationDistanceFromTrend({
      simulationJobId: props.simulationJobId,
    });

    const pageContent = usePageContent();

    const activeElement = useChartActiveElement();

    return (
      <TablePlaceHolder
        sx={{ mx: 7, mt: 5 }}
        isLoading={data.assetClasses.isLoading || pageContent.isLoading}
        rows={8}
      >
        <CarPageContent
          sx={{ mb: 3 }}
          content={pageContent.getContent(
            CarPageContentCode.SIMULATION_ANALYSIS_PRICE_DRIVEN_DISTANCE_FROM_TREND,
          )}
          isWhiteContext
        />
        <Box sx={{ mt: 1, display: "flex", gap: 5, alignItems: "flex-end" }}>
          <CarAssetClassSelector
            label="Asset Class"
            sx={{
              width: 250,
              ".MuiInputLabel-root": {
                fontSize: "16px",
              },
              ".MuiOutlinedInput-root": {
                marginTop: "28px",
                bgcolor: "white",
              },
            }}
            {...data.assetClassSelector}
          />
          <CarDownloadButton
            sx={{ ml: "auto", mb: 0.5 }}
            onClick={data.handleDownloadReports}
          >
            Download Reports
          </CarDownloadButton>
        </Box>
        <TablePlaceHolder sx={{ mt: 5 }} isLoading={data.isLoading} rows={5}>
          {data.distanceFromTrendItem?.modelChartData && (
            <>
              <ChartDftTotalReturnsSummary
                sx={{ mt: 5 }}
                assetClassName={data.distanceFromTrendItem.name}
                data={data.distanceFromTrendItem?.modelChartData}
                realStaticExpectedReturn={
                  data.distanceFromTrendItem?.realStaticExpectedReturn ?? 0
                }
                showFormula
                isWhiteContext={false}
              />

              <ChartDftTotalReturns
                sx={{ mt: 6.5 }}
                assetClassName={data.distanceFromTrendItem.name}
                data={data.distanceFromTrendItem.modelChartData}
                pendoPrefix="asset_class_analysis_dft_total_returns"
                activeElement={activeElement}
                isWhiteContext={false}
              />
              <ChartDftVersusTenYearReturn
                sx={{ mt: 6.5 }}
                assetClassName={data.distanceFromTrendItem.name}
                data={data.distanceFromTrendItem.modelChartData}
                pendoPrefix="asset_class_analysis_dft_versus_ten_year_return"
                activeElement={activeElement}
                isWhiteContext={false}
              />
            </>
          )}
        </TablePlaceHolder>
      </TablePlaceHolder>
    );
  },
);
