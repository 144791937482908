import { Dialog, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { QRCodeSVG } from "qrcode.react";
import { useState } from "react";
import {
  useCommonAuthTwoFactorSettingsCreateMutation,
  useCommonAuthTwoFactorSettingsUpdateMutation,
} from "api/carApi.generated";
import { useMount } from "app/useMount";
import { CarButton, CarLoadingButton } from "components/Buttons";
import { CarTextField } from "components/Inputs";
import { PlaceHolder } from "components/PlaceHolder";
import { CarClose } from "icons";
export interface TwoFactorQRCodeDialogProps {
  onApply: (value: boolean) => void;
  onCancel: () => void;
}

export const TwoFactorQRCodeDialog = (props: TwoFactorQRCodeDialogProps) => {
  const [code, setCode] = useState<string | undefined>();
  const [isCodeEmpty, setIsCodeEmpty] = useState(false);

  const [getQrUrl, { isLoading: qrIsLoading }] =
    useCommonAuthTwoFactorSettingsCreateMutation();

  const [qrUrl, setQrUrl] = useState("");

  useMount(() => {
    (async () => {
      const response = await getQrUrl({ userTwoFactorSettings: {} });
      if ("data" in response) {
        setQrUrl(response.data.friendly_url ?? "");
      }
    })();
  });

  const [verifyCode, { isLoading: verifyCodeIsLoading }] =
    useCommonAuthTwoFactorSettingsUpdateMutation();

  const handleScan = async () => {
    setIsCodeEmpty(!code);

    if (!code) {
      return;
    }

    const response = await verifyCode({
      userTwoFactorSettings: {
        code,
        verification: true,
      },
    });

    if ("data" in response) {
      props.onApply(true);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "6px",
          p: 5,
          position: "relative",
          width: 710,
        },
      }}
      maxWidth="lg"
      open={true}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          props.onCancel();
        }
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: 10, top: 10, color: "gray6" }}
        onClick={props.onCancel}
      >
        <CarClose />
      </IconButton>
      <Typography sx={{ mt: 1 }} variant="h4SSemiBold">
        Scan the QR code with your authentication app
      </Typography>
      <Box
        sx={{
          // p: 5,
          // pl: 8.3,
          mt: 3,
          gap: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        {qrIsLoading ? (
          <PlaceHolder sx={{ width: 204, height: 204 }} />
        ) : (
          <QRCodeSVG
            value={qrUrl}
            bgColor="#FFFFFF"
            level="L"
            size={204}
            includeMargin
          />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <CarTextField
            sx={{
              maxWidth: 250,
              ".MuiInputBase-root": {
                mt: 2.75,
              },
              label: {
                fontSize: "16px",
              },
            }}
            label="Enter your verification code"
            value={code}
            onChange={(value) =>
              value ? setCode(value.replace(/\D/g, "")) : setCode(undefined)
            }
            autoFocus
            error={isCodeEmpty}
          />
          <Box sx={{ display: "flex", gap: 2 }}>
            <CarButton
              sx={{ fontSize: "18px" }}
              variant="outlined"
              onClick={props.onCancel}
            >
              Back
            </CarButton>
            <CarLoadingButton
              sx={{ fontSize: "18px" }}
              onClick={handleScan}
              isLoading={verifyCodeIsLoading}
            >
              Enter
            </CarLoadingButton>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
