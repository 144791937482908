import { Text, View, Image } from "@react-pdf/renderer";
import { primaryColor } from "../../theme";
import bull from "assets/bull.png";
import { format } from "date-fns";
import { FooterProps, PdfPage } from "components/Pdf";

export interface TitleActionPdfPageProps {
  clientName: string;
  advisorName: string;
  portfolioLabel: string;
  logo?: string;
  footerProps: FooterProps;
}

export const TitleActionPdfPage = (props: TitleActionPdfPageProps) => (
  <PdfPage
    hideFooter
    style={{
      paddingTop: 35,
      paddingBottom: 0,
      paddingLeft: 63,
      paddingRight: 56,
    }}
    footerProps={props.footerProps}
  >
    <View
      style={{
        marginTop: 30,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Image
        style={{
          height: 60,
        }}
        src={props.logo}
      />
      <Text style={{ fontSize: 16 }}>{format(new Date(), "PP")}</Text>
    </View>
    <Image
      style={{
        height: 252,
        marginTop: 20,
      }}
      src={bull}
    />
    <Text
      style={{
        marginTop: 64,
        width: 360,
        fontSize: 40,
        // lineHeight: 40,
      }}
    >
      Action Plan
    </Text>
    <View
      style={{
        backgroundColor: primaryColor,
        marginTop: 3,
        width: 130,
        height: 2,
      }}
    />
    <Text
      style={{
        fontSize: 15,
        marginTop: 16,
        width: 320,
      }}
    >
      {`Financial plan for the next 12 months with the ${props.portfolioLabel} strategy.`}
    </Text>
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 120,
      }}
    >
      <View style={{ width: 187 }}>
        <Text style={{ fontSize: 12 }}>Created for:</Text>
        <Text style={{ fontSize: 16, marginTop: 5 }}>{props.clientName}</Text>
      </View>
      <View>
        <Text style={{ fontSize: 12 }}>Created by:</Text>
        <Text style={{ fontSize: 16, marginTop: 5 }}>{props.advisorName}</Text>
      </View>
    </View>
  </PdfPage>
);
