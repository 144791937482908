import {
  FooterProps,
  PdfPage,
  PdfMarkdown,
  PdfSectionLabel,
} from "components/Pdf";

import { UsePublishPlanActionPlan } from "../publishPlanDialog/usePublishPlanActionPlan";
import { Text, View } from "@react-pdf/renderer";

export interface ActionPlanActionPdfPageProps {
  footerProps: FooterProps;
  publishPlanActionPlan: UsePublishPlanActionPlan;
}

export const ActionPlanActionPdfPage = (
  props: ActionPlanActionPdfPageProps
) => {
  return (
    <PdfPage footerProps={props.footerProps}>
      <PdfSectionLabel label="Action Plan" />
      <PdfMarkdown style={{ marginBottom: 30 }}>
        This is you and your advisor’s action plan for the next 12 months. Here
        we list out how much you will be spending and saving as well as the new
        portfolio strategy your advisor will be implementing on your behalf.
      </PdfMarkdown>
      {props.publishPlanActionPlan.items.map((i) => (
        <View style={{ marginBottom: 10 }}>
          <Text style={{ fontWeight: "bold" }}>{i.name}</Text>
          {i.childItems.map((ch) => (
            <View>
              <Text>{`•    ${ch.name}`}</Text>
              {ch.fundedItems && (
                <View style={{ marginLeft: 35 }}>
                  <Text>- Funded by:</Text>
                  <View style={{ marginLeft: 35 }}>
                    {ch.fundedItems.map((fi) => (
                      <Text>{`• ${fi}`}</Text>
                    ))}
                  </View>
                </View>
              )}
            </View>
          ))}
        </View>
      ))}
      {/* • */}
    </PdfPage>
  );
};
