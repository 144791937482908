import React from "react";
import { Box, Typography } from "@mui/material";
import { AddRowButton } from "components/Buttons";
import {
  CarCurrencyFieldDelayed,
  CarNumberFieldDelayed,
} from "components/NumberField";
import { CarSuffixField, CarTextFieldDelayed } from "components/Inputs";
import {
  CarTableColumn,
  CarTableMemoTable,
  CarTableRow,
  CarTableSpecificRowProps,
} from "components/Table";
import { CarRelationship } from "types";
import {
  setDob,
  setFirstName,
  setLastName,
  setRelationType,
  setSuffix,
  useRelationship,
} from "features/clientFamily/useRelationship";
import { CarDateFieldDelayed } from "components/DateField";
import { CarStateField } from "components/ComboField";
import { useRelationTypes } from "app/useRelationTypes";
import { CarRelationField } from "components/RelationField";
import { TablePlaceHolder } from "components/PlaceHolder";
import { setDisplayName } from "utils";

const ClientFamilyRow = React.memo(
  (
    props: CarTableSpecificRowProps<CarRelationship> & {
      hasPrimary?: boolean;
      hasSecondary?: boolean;
    } // todo make proper typing
  ) => {
    const { isPrimary, isSecondary } = useRelationTypes();

    function blankIfNotClient(
      primaryOnly: boolean,
      renderCell: CarTableColumn<CarRelationship>["renderCell"]
    ): CarTableColumn<CarRelationship>["renderCell"] {
      return (props) => {
        if (
          isPrimary(props.item) ||
          (isSecondary(props.item) && !primaryOnly)
        ) {
          return renderCell(props);
        } else {
          return (
            <Box
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="par01Regular">-</Typography>
            </Box>
          );
        }
      };
    }

    return (
      <CarTableRow
        {...props}
        onCanDelete={(item) => !isPrimary(item)}
        columns={[
          {
            id: "first_name",
            label: "*First Name",
            fraction: 1.8,
            renderCell: ({ item, onChange }) => (
              <CarTextFieldDelayed
                required
                value={item.first_name}
                onChange={(value) => onChange(setFirstName(item, value))}
              />
            ),
          },
          {
            id: "last_name",
            label: "*Last Name",
            fraction: 1.8,
            renderCell: ({ item, onChange }) => (
              <CarTextFieldDelayed
                required
                value={item.last_name}
                onChange={(value) => onChange(setLastName(item, value))}
              />
            ),
          },
          {
            id: "suffix",
            label: "Suffix",
            fraction: 1.1,
            renderCell: ({ item, onChange }) => (
              <CarSuffixField
                value={item.suffix ?? undefined}
                onChange={(value) => onChange(setSuffix(item, value))}
              />
            ),
          },
          {
            id: "date_of_birth",
            label: "*DOB",
            fraction: 1.8,
            renderCell: ({ item, onChange }) => (
              <CarDateFieldDelayed
                required
                disableFuture
                openTo="year"
                views={["year", "month", "day"]}
                value={item.date_of_birth}
                onChange={(value) => onChange(setDob(item, value))}
              />
            ),
          },
          {
            id: "relation_type",
            label: "*Relation",
            fraction: 1.8,
            renderCell: ({ item, onChange }) => (
              <CarRelationField
                required
                disabled={isPrimary(item)}
                showPrimary={!props.hasPrimary || isPrimary(item)}
                showSecondary={!props.hasSecondary || isSecondary(item)}
                value={item.relation_type}
                onChange={(value) => onChange(setRelationType(item, value))}
              />
            ),
          },
          {
            id: "income",
            label: "*Income",
            fraction: 2,
            renderCell: blankIfNotClient(false, ({ item, onChange }) => (
              <CarCurrencyFieldDelayed
                required
                removeZeroOnFocus
                value={item.income}
                onChange={(income) => onChange({ ...item, income })}
              />
            )),
          },
          {
            id: "state_of_residence",
            label: "*State",
            fraction: 1.2,
            renderCell: blankIfNotClient(true, ({ item, onChange }) => (
              <CarStateField
                required
                value={item.state_of_residence || undefined}
                short
                onChange={(state_of_residence) =>
                  onChange({ ...item, state_of_residence })
                }
              />
            )),
          },
          {
            id: "retirement_age",
            label: "*Ret. Age",
            fraction: 1,
            renderCell: blankIfNotClient(false, ({ item, onChange }) => (
              <CarNumberFieldDelayed
                required
                value={item.retirement_age}
                onChange={(retirement_age) =>
                  onChange({ ...item, retirement_age })
                }
              />
            )),
          },
          {
            id: "retirement_state",
            label: "*Ret. State",
            fraction: 1.2,
            renderCell: blankIfNotClient(true, ({ item, onChange }) => (
              <CarStateField
                required
                value={item.retirement_state || undefined}
                short
                onChange={(retirement_state) =>
                  onChange({ ...item, retirement_state })
                }
              />
            )),
          },
          {
            id: "live_until",
            label: "*Live Until",
            fraction: 1,
            renderCell: blankIfNotClient(false, ({ item, onChange }) => (
              <CarNumberFieldDelayed
                required
                value={item.live_until}
                onChange={(live_until) => onChange({ ...item, live_until })}
              />
            )),
          },
        ]}
      />
    );
  }
);

setDisplayName({ ClientFamilyRow });

export const ClientFamilyQuick = React.memo(() => {
  const data = useRelationship();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <TablePlaceHolder isLoading={data.isLoading}>
        <CarTableMemoTable
          items={data.items}
          rowComponent={ClientFamilyRow}
          rowComponentExtraProps={{
            hasPrimary: !!data.primaryItem,
            hasSecondary: !!data.secondaryItem,
          }}
          onChange={data.handleChange}
          onDelete={data.handleDelete}
          pendoPrefix="client_family"
        />
      </TablePlaceHolder>
      <AddRowButton sx={{ mt: 1.5 }} onClick={data.addRow}>
        Add Relation
      </AddRowButton>
    </Box>
  );
});
