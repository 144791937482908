import ReactPDF, { Image } from "@react-pdf/renderer";
import {
  Chart,
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartType,
  DefaultDataPoint,
  Plugin,
} from "chart.js";

export interface CarPdfChartProps<
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown,
> extends Omit<ReactPDF.ImageProps, "source"> {
  width: number;
  height: number;
  backgroundColor?: string;
  type: TType;
  data: ChartData<TType, TData, TLabel>;
  options?: ChartOptions<TType>;
  plugins?: Plugin<TType>[];
}

export const CarPdfChart = <
  TType extends ChartType = ChartType,
  TData = DefaultDataPoint<TType>,
  TLabel = unknown,
>({
  style,
  width,
  height,
  backgroundColor,
  type,
  data,
  plugins = [],
  options = {},
  ...props
}: CarPdfChartProps<TType, TData, TLabel>) => {
  const anyOptions = options as any;
  const config: ChartConfiguration<TType, TData, TLabel> = {
    type,
    plugins: [
      {
        id: "fill-background",
        beforeDraw: ({ ctx, width, height }) => {
          ctx.save();
          ctx.globalCompositeOperation = "destination-over";
          ctx.fillStyle = backgroundColor ?? "white";
          ctx.fillRect(0, 0, width, height);
          ctx.restore();
        },
      },
      ...plugins,
    ],
    options: {
      ...anyOptions,
      layout: {
        ...anyOptions.layout,
        padding: { right: 1 }, // for some reason right border is cropped, so add right padding to fit it in image
      },
      devicePixelRatio: 4,
      responsive: false,
      animation: false,
    },
    data,
  };

  const canvas = document.createElement("canvas");

  canvas.setAttribute("width", width.toString());
  canvas.setAttribute("height", height.toString());

  const myChart = new Chart(canvas, config);
  const dataString = myChart.toBase64Image("image/png", 1);

  myChart.destroy();

  return (
    <Image style={{ ...style, width, height }} source={dataString} {...props} />
  );
};
