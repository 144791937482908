import { StressTestData } from "api/carApi.generated";
import { writeFile, utils, BookType } from "xlsx-js-style";
import {
  getMarketEnvironmentTable,
  getNetWorthTable,
  getPlanCashflowTable,
  getRealEstateTable,
  getTaxableIncomeAnalysisTable,
} from "./stressTest.utils";
import { StressTestProjectionType } from "const";
import { formatCurrency, formatPercentFactor } from "utils";
import { useClientName } from "app/useClientName";
import { useIsInflated } from "app/usePlanId";
import {
  createExcelWorkSheet,
  ExcelColumn,
  ExcelDataFormat,
  ExcelTable,
} from "../../app/useExcelExport";

export const getGridTemplateColumns = (columns: ExcelColumn[]) =>
  columns.map((i) => `${i.fraction ?? 1}fr`).join(" ");

export const formatProjectionValue = (
  value: unknown,
  dataFormat: ExcelDataFormat,
) => {
  switch (dataFormat) {
    case ExcelDataFormat.currency:
      return formatCurrency(value as number, 0);
    case ExcelDataFormat.percent:
      return formatPercentFactor(value as number, 1);
    case ExcelDataFormat.percentRounded:
      return formatPercentFactor(value as number, 0);

    // ExcelDataFormat.general
    default:
      return String(value);
  }
};

export const useProjectionExport = () => {
  const client = useClientName();
  const { getInflatedValue } = useIsInflated();

  const exportAllExcel = (items: StressTestData[]) => {
    const workbook = utils.book_new();

    const appendTable = (table: ExcelTable) => {
      utils.book_append_sheet(
        workbook,
        createExcelWorkSheet(table),
        table.name,
      );
    };

    appendTable(getMarketEnvironmentTable(items, getInflatedValue));
    appendTable(getPlanCashflowTable(items, getInflatedValue));
    appendTable(getNetWorthTable(items, getInflatedValue));
    appendTable(getTaxableIncomeAnalysisTable(items, getInflatedValue));
    appendTable(getRealEstateTable(items, getInflatedValue));

    writeFile(
      workbook,
      `${client.clientFullName} ${new Date().toISOString().split("T")[0]}.xlsx`,
      {
        bookType: "xlsx",
        compression: true,
      },
    );
  };

  const exportTable = ({
    type,
    items,
    bookType,
  }: {
    type: StressTestProjectionType;
    items: StressTestData[];
    bookType: BookType;
  }) => {
    const workbook = utils.book_new();

    let table: ExcelTable | undefined = undefined;
    switch (type) {
      case StressTestProjectionType.MarketEnvironment:
        table = getMarketEnvironmentTable(items, getInflatedValue);
        break;
      case StressTestProjectionType.NetWorth:
        table = getNetWorthTable(items, getInflatedValue);
        break;
      case StressTestProjectionType.PlanCashflow:
        table = getPlanCashflowTable(items, getInflatedValue);
        break;
      case StressTestProjectionType.TaxableIncomeAnalysis:
        table = getTaxableIncomeAnalysisTable(items, getInflatedValue);
        break;
      case StressTestProjectionType.RealEstate:
        table = getRealEstateTable(items, getInflatedValue);
        break;
      default:
        break;
    }

    if (!table) {
      return;
    }

    utils.book_append_sheet(workbook, createExcelWorkSheet(table), table.name);

    writeFile(
      workbook,
      `${client.clientFullName} ${new Date().toISOString().split("T")[0]} - ${
        table.name
      }.${bookType}`,
      { bookType, compression: true },
    );
  };

  const exportAllCsv = (items: StressTestData[]) => {
    exportTable({
      type: StressTestProjectionType.MarketEnvironment,
      items,
      bookType: "csv",
    });
    exportTable({
      type: StressTestProjectionType.NetWorth,
      items,
      bookType: "csv",
    });
    exportTable({
      type: StressTestProjectionType.PlanCashflow,
      items,
      bookType: "csv",
    });
    exportTable({
      type: StressTestProjectionType.TaxableIncomeAnalysis,
      items,
      bookType: "csv",
    });
  };

  return {
    exportAllExcel,
    exportTable,
    exportAllCsv,
  };
};
