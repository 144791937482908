import { IconButton, IconButtonProps } from "@mui/material";
import { useRef } from "react";

interface CarFileSelectButtonProps extends IconButtonProps {
  onFileSelected: (selection: Array<File>) => void;
  multiple?: boolean;
  accept?: string;
}

export const CarFileSelectButton = ({
  onFileSelected,
  multiple,
  accept,
  ...buttonProps
}: CarFileSelectButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const selectFileClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const fileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files },
    } = event;

    if (!files) {
      return;
    }
    onFileSelected(Array.from(files));
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        onChange={fileInputChange}
        accept={accept}
        multiple={multiple}
      />
      <IconButton {...buttonProps} onClick={selectFileClick} />
    </>
  );
};
