import { ExpandMore } from "@mui/icons-material";
import { Typography, styled } from "@mui/material";
import React, { ReactElement } from "react";
import {
  CarAccordion,
  CarAccordionDetails,
  CarAccordionSummary,
} from "components/Accordion";
import { useExpandSingle } from "features/general/useExpand";
import { CarTooltipBox } from "components/TooltipBox";
export interface SectionProps {
  id: string;
  label: string;
  pendoClassName: string;
  content: ReactElement;
}

export const quickInputSectionPaddingX = 5.5;
export const quickInputSectionPaddingY = 5;

const StyledAccordionSummary = styled(CarAccordionSummary)(({ theme }) => ({
  // [theme.breakpoints.down("lg")]: {
  // paddingLeft: theme.spacing(2),
  // },
  ".MuiAccordionSummary-content": {
    marginBottom: theme.spacing(0.5),
  },
}));

const StyledAccordionDetails = styled(CarAccordionDetails)(({ theme }) => ({
  paddingTop: theme.spacing(quickInputSectionPaddingY),
  paddingBottom: theme.spacing(quickInputSectionPaddingY),
  paddingLeft: theme.spacing(quickInputSectionPaddingX),
  paddingRight: theme.spacing(quickInputSectionPaddingX),
  backgroundColor: theme.palette.gray1,

  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(2),
  },
}));

export const QuickInputSection = React.memo((props: SectionProps) => {
  const expand = useExpandSingle(`QuickInputSection-${props.id}`, false);
  return (
    <CarAccordion square {...expand} TransitionProps={{ unmountOnExit: true }}>
      <StyledAccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          "& div.MuiAccordionSummary-content": {
            mt: 5,
          },
        }}
      >
        <Typography variant="h5SSemiBold">{props.label}</Typography>
        <CarTooltipBox
          sx={{ display: "inline", ml: 0.5, mt: 0.25 }}
          className={props.pendoClassName}
        />
      </StyledAccordionSummary>
      <StyledAccordionDetails>{props.content}</StyledAccordionDetails>
    </CarAccordion>
  );
});
