import {
  alpha,
  Button,
  ButtonProps,
  CircularProgress,
  IconButton,
  IconButtonProps,
  lighten,
} from "@mui/material";
import { styled } from "@mui/material";
import {
  CarDownload,
  CarIconAdd,
  CarIconDelete,
  CarIconDeleteFilled,
  CarIconEdit,
} from "icons";
import { useState } from "react";

export const AddRowButton = styled((props: ButtonProps) => (
  <Button
    startIcon={<CarIconAdd color="primary" />}
    {...props}
    size="large"
    color="primary"
  />
))(({ theme }) => ({
  ...theme.typography.par01SemiBold,
  ".MuiButton-startIcon": {
    translate: "1px -1px",
    svg: {
      fontSize: "1.4em",
    },
  },
  "&.Mui-disabled .MuiButton-startIcon": {
    opacity: 0.5,
  },
}));

export const CarDownloadButton = styled((props: ButtonProps) => (
  <Button
    startIcon={<CarDownload color="primary" />}
    {...props}
    size="large"
    color="primary"
  />
))(({ theme }) => ({
  ...theme.typography.par01SemiBold,
  ".MuiButton-startIcon": {
    margin: "-2px",
    marginRight: 6,
    svg: {
      fontSize: "24px",
    },
  },
  "&.Mui-disabled .MuiButton-startIcon": {
    opacity: 0.5,
  },
}));

export const DeleteRowButton = styled((props: IconButtonProps) => (
  <IconButton size="small" {...props}>
    <CarIconDelete fontSize="inherit" />
  </IconButton>
))({});

export const DeleteRowButtonRed = styled((props: IconButtonProps) => (
  <IconButton size="small" {...props} sx={{ color: "red", ...props.sx }}>
    <CarIconDeleteFilled fontSize="inherit" />
  </IconButton>
))({});

export const EditRowButton = (props: IconButtonProps) => (
  <IconButton size="small" {...props} sx={{ color: "#555555", ...props.sx }}>
    <CarIconEdit fontSize="inherit" />
  </IconButton>
);

export const CarButton = styled((props: ButtonProps) => (
  <Button
    variant="contained"
    color="primary"
    {...props}
    sx={{
      width: 150,
      px: 2, // todo check that nothing is broken !!!
      ...props.sx,
    }}
  />
))(({ theme }) => ({
  fontWeight: 600,
  fontSize: theme.typography.pxToRem(16),
  lineHeight: theme.typography.pxToRem(21),
  borderRadius: 7,
  height: 48,

  "&.MuiButton-outlinedPrimary": {
    color: theme.palette.caravelOrangePrimary,
    boxShadow: `3px 3px 10px 0px ${alpha(theme.palette.primary.main, 0.3)}`,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.9),
      boxShadow: `3px 3px 10px 0px ${alpha(theme.palette.primary.main, 0.4)}`,
    },
  },

  "&.MuiButton-outlinedSecondary": {
    color: theme.palette.caravelOrangePrimary,
    boxShadow: `3px 3px 10px 0px ${alpha(theme.palette.secondary.main, 0.2)}`,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: lighten(theme.palette.secondary.main, 0.9),
      boxShadow: `3px 3px 10px 0px ${alpha(theme.palette.secondary.main, 0.3)}`,
    },
  },

  "&.MuiButton-containedPrimary": {
    color: theme.palette.common.white,
    boxShadow: `3px 3px 10px 0px ${alpha(theme.palette.primary.main, 0.3)}`,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.main, 0.9),
    },
  },

  "&.MuiButton-containedSecondary": {
    color: theme.palette.common.white,
    boxShadow: `3px 3px 10px 0px ${alpha(theme.palette.secondary.main, 0.2)}`,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.9),
    },
  },

  "&.Mui-disabled": {
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.gray2,
    boxShadow: `3px 3px 10px 0px ${alpha(
      theme.palette.text.secondary,
      0.3,
    )} !important`,
  },
}));

export interface CarLoadingButtonProps extends ButtonProps {
  isLoading?: boolean;
}

export const CarLoadingButton = styled(
  ({ isLoading, onClick, ...props }: CarLoadingButtonProps) => (
    <CarButton
      startIcon={
        isLoading ? (
          <CircularProgress color="inherit" size={20} className="spinner" />
        ) : null
      }
      onClick={(e) => {
        if (!isLoading) {
          onClick?.(e);
        }
      }}
      {...props}
    />
  ),
)({
  ".MuiButton-startIcon": {
    marginLeft: 0,
    marginRight: 0,
  },
  ".spinner": {
    marginLeft: -26,
  },
});

export interface CarLoadingPromiseButtonProps
  extends Omit<CarLoadingButtonProps, "isLoading" | "onClick"> {
  onClick: () => Promise<unknown>;
}

export const CarLoadingPromiseButton = ({
  onClick,
  ...props
}: CarLoadingPromiseButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (!onClick || isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      return await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <CarLoadingButton isLoading={isLoading} {...props} onClick={handleClick} />
  );
};

export const CarLoadingPromiseButtonAddRow = ({
  onClick,
  ...props
}: CarLoadingPromiseButtonProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (!onClick || isLoading) {
      return;
    }

    setIsLoading(true);
    try {
      return await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AddRowButton
      startIcon={
        isLoading ? (
          <CircularProgress color="primary" size={20} />
        ) : (
          <CarIconAdd color="primary" />
        )
      }
      {...props}
      onClick={handleClick}
    />
  );
};
