import clsx from "clsx";
import { Box, BoxProps, Typography, styled } from "@mui/material";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { Fragment } from "react";
import { formatCurrency, formatPercent, priorityNumToString } from "utils";
import { PriorityNumber } from "const";

interface PlanResultsGoalSummaryTableProps {
  sx?: BoxProps["sx"];
  data: UsePlanResults;
}

const Divider = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  borderBottom: `solid 1px ${theme.palette.gray3}`,
  height: 1,
}));

const Priority = (props: { className?: string; priority: number }) => (
  <Box
    sx={{
      display: "flex",
      gap: 1.5,
      alignItems: "center",
      "&.disabled": {
        ".square": {
          backgroundColor: "gray6",
        },
        ".label": {
          color: "gray6",
        },
      },
    }}
    className={props.className}
  >
    <Box
      className="square"
      sx={{
        width: 25,
        height: 25,
        borderRadius: "4px",
        backgroundColor:
          props.priority === PriorityNumber.Need
            ? "caravelBlueSecondary"
            : props.priority === PriorityNumber.Want
            ? "caravelOrangePrimary"
            : "yellow",
      }}
    />
    <Typography variant="par03SemiBold" className="label">
      {`${priorityNumToString(props.priority)}s`}
    </Typography>
  </Box>
);

export const PlanResultsGoalSummaryTable = ({
  sx,
  data,
}: PlanResultsGoalSummaryTableProps) => {
  return (
    <Box
      sx={{
        gridColumn: "1 / span 3",
        display: "grid",
        gridTemplateColumns: `repeat(8, 1fr)`,
        columnGap: 1,
        rowGap: 4,
        textAlign: "center",
        position: "relative",
        ".disabled": {
          color: "gray6",
        },
        ...sx,
      }}
    >
      <Typography variant="caption">Priority</Typography>
      <Typography variant="caption">Pass/Total</Typography>
      <Typography variant="caption">Prob. of Success</Typography>
      <Typography variant="caption">Funds Required</Typography>
      <Typography variant="caption">Initial Balance</Typography>
      <Typography variant="caption">Savings & Income </Typography>
      <Typography variant="caption">Exp. Return </Typography>
      <Typography variant="caption">Shortfall</Typography>

      {data.donuts.summaryItems.map((i, idx) => {
        const props = { className: clsx({ disabled: i.disabled }) };
        return (
          <Fragment key={idx}>
            <Priority priority={i.priority} {...props} />
            <Typography variant="par03SemiBold" {...props}>
              {i.passFail}
            </Typography>
            <Typography variant="par03SemiBold" {...props}>
              {formatPercent(i.probOfSuccess)}
            </Typography>
            <Typography variant="par03SemiBold" {...props}>
              {formatCurrency(i.fundsRequired)}
            </Typography>
            <Typography variant="par03SemiBold" {...props}>
              {formatCurrency(i.initialBalance)}
            </Typography>
            <Typography variant="par03SemiBold" {...props}>
              {formatCurrency(i.savingsAndIncome)}
            </Typography>
            <Typography variant="par03SemiBold" {...props}>
              {formatCurrency(i.expReturn)}
            </Typography>
            <Typography
              variant="par03SemiBold"
              sx={{
                color: Math.round(i.shortfall) ? "red" : undefined,
              }}
              {...props}
            >
              {formatCurrency(i.shortfall)}
            </Typography>
          </Fragment>
        );
      })}
      <Divider sx={{ top: 25, position: "absolute", width: "100%" }} />
    </Box>
  );
};
