import { errorColor } from "../../theme";
import { formatCurrency } from "utils";
import { PlanResultsTotals } from "../planResults/usePlanResults";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfSubHeading } from "./PdfHeading";
import { CarPdfMarkdown } from "components/PdfMarkdown";
import { CarPdfTypography } from "components/PdfTypography";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfHeaderProps,
  CarPdfRow,
  CarPdfRowProps,
  CarPdfTable,
  CarPdfTextCell,
} from "components/PdfTable";

interface HeaderProps extends Omit<CarPdfHeaderProps, "children"> {}

const Header = (props: HeaderProps) => (
  <CarPdfHeader paddingVertical={5} {...props}>
    <CarPdfHeaderCell />
    <CarPdfHeaderCell>Amount</CarPdfHeaderCell>
  </CarPdfHeader>
);

interface RowProps extends Omit<CarPdfRowProps, "children"> {
  label: string;
  value: number;
  isBold?: boolean;
  isRedForNegative?: boolean;
}

const Row = ({
  label,
  value,
  isBold,
  isRedForNegative,
  ...props
}: RowProps) => (
  <CarPdfRow paddingVertical={4} {...props}>
    <CarPdfTextCell
      variant={isBold ? "tableBody2" : "tableBody1"}
      style={{
        textAlign: "left",
        paddingHorizontal: 5,
      }}
    >
      {label}
    </CarPdfTextCell>
    <CarPdfTextCell
      variant={isBold ? "tableBody2" : "tableBody1"}
      style={{
        color: isRedForNegative && value < 0 ? errorColor : undefined,
      }}
    >
      {formatCurrency(value)}
    </CarPdfTextCell>
  </CarPdfRow>
);

interface InflowsAndOutflowsPdfPageProps {
  portfolioLabel: string;
  totals: PlanResultsTotals;
  disclosures: UseDisclosures;
  pageProps: CarPdfPageProps;
}

export const InflowsAndOutflowsPdfPage = (
  props: InflowsAndOutflowsPdfPageProps,
) => {
  const text1 = props.disclosures.getContent(
    CarDisclosureContentCode.CMPAS_CLIENT_REPORT_WORKBENCH_PLAN_RESULTS_part_10,
  );

  return (
    <CarPdfPage {...props.pageProps}>
      <CarPdfSubHeading label={`Inflows & Outflows`} />
      <CarPdfMarkdown style={{ marginTop: 10 }}>{text1}</CarPdfMarkdown>
      <CarPdfTypography variant="h6" style={{ marginTop: 15, marginBottom: 5 }}>
        Inflows
      </CarPdfTypography>
      <CarPdfTable>
        <Header />
        <Row
          label="Current Investments"
          value={props.totals.inflows.currentInvestments}
        />
        <Row
          label="Nominal Investment Returns"
          value={props.totals.inflows.investmentReturns}
        />
        <Row
          label="Savings/Other Inflows"
          value={props.totals.inflows.savingsOtherInflowsNetOfTax}
        />
        <Row
          label="Social Security with COLA/Other Inflows (Pretax)"
          value={props.totals.inflows.socialSecurityOtherInflowsNetOfTax}
        />
        <Row
          label="Real Estate Sale (Pretax)"
          value={props.totals.inflows.realEstateNetSaleProceeds}
        />
        <Row
          label="Total Plan Inflows"
          value={props.totals.inflows.totalPlanInflow}
          isBold
        />
      </CarPdfTable>
      <CarPdfTypography variant="h6" style={{ marginTop: 25, marginBottom: 5 }}>
        Outflows
      </CarPdfTypography>
      <CarPdfTable>
        <Header />
        <Row
          label="Total Goal Funded"
          value={props.totals.goals.totalGoalFunded}
        />
        <Row label="Inflation" value={props.totals.goals.inflation} />
        <Row
          label="Total Taxes Paid"
          value={props.totals.goals.totalTaxesPaid}
        />
        <Row
          label="Total Plan Outflows"
          value={props.totals.goals.outflows}
          isBold
        />
      </CarPdfTable>
      <CarPdfTypography variant="h6" style={{ marginTop: 25, marginBottom: 5 }}>
        Surplus/Deficit
      </CarPdfTypography>
      <CarPdfTable>
        <Header />
        <Row
          label="Total Unfunded Goals"
          value={-props.totals.surplus.unfundedGoals}
          isRedForNegative
        />
        <Row
          label="Taxable Account Surplus"
          value={props.totals.surplus.taxableAccountSurplus}
        />
        <Row
          label="Tax Deferred Account Surplus"
          value={props.totals.surplus.taxDeferredAccountSurplus}
        />
        <Row
          label="Social Security/ Additional Income"
          value={props.totals.surplus.socialSecurityAdditionalIncome}
        />
        <Row label="Real Estate" value={props.totals.surplus.realEstate} />
        <Row
          label="Surplus/Deficit"
          value={props.totals.surplus.surplusDeficit}
          isBold
        />
      </CarPdfTable>
    </CarPdfPage>
  );
};
