import { useTheme } from "@mui/material";
import {
  usePortfolioPortfolioGroupVersionAssetClassesListQuery,
  usePortfolioPortfolioGroupVersionRetrieveQuery,
  usePortfolioPortfolioModuleDataTypeListQuery,
  useSimulationSimulationJobLatestSimulationRetrieveQuery,
} from "api/carApi.generated";

import { isDefined } from "utils";
import { usePortfolioModuleData } from "./usePortfolioModuleData";
import {
  CarPortfolioChartItem,
  CarPortfolioHeatmapChartDataset,
  CarPortfolioHeatmapChartItem,
  CarPortfolioStatPeriod,
} from "./asset-allocation-types";
import { useReturnScenarios } from "app/useReturnScenarios";
import { useOptimizationJobs } from "./editPortfolioGroup/useOptimizationJobs";

export const usePortfolioGroupVersionStatData = (params: {
  versionId?: string;
  publishedVersionId?: string;
  filterByModuleId?: string;
}) => {
  const dataTypes = usePortfolioPortfolioModuleDataTypeListQuery();

  const version = usePortfolioPortfolioGroupVersionRetrieveQuery(
    {
      id: params.versionId ?? "",
    },
    {
      skip: !params.versionId,
    },
  );

  const publishedVersion = usePortfolioPortfolioGroupVersionRetrieveQuery(
    {
      id: params.publishedVersionId ?? "",
    },
    {
      skip: !params.publishedVersionId,
    },
  );

  const versionClasses = usePortfolioPortfolioGroupVersionAssetClassesListQuery(
    {
      portfolioGroupVersionId: params.versionId ?? "",
    },
    {
      skip: !params.versionId,
    },
  );

  const optimizationJobs = useOptimizationJobs({
    portfolioGroupVersionId: params.versionId,
  });

  const latestSimulation =
    useSimulationSimulationJobLatestSimulationRetrieveQuery();

  const returnScenarios = useReturnScenarios();

  const dataTypeItems = Array.from(dataTypes.data ?? []).sort(
    (a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0),
  );

  const userInputDataTypeId = dataTypeItems.find((i) => i.code === "USER_INPUT")
    ?.id;
  const benchmarkDataTypeId =
    dataTypes.data?.find((i) => i.code === "BENCHMARK")?.id ?? "";

  let sortedModuleItems = Array.from(
    version.data?.portfolio_modules ?? [],
  ).sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0));

  if (params.filterByModuleId) {
    sortedModuleItems = sortedModuleItems.filter(
      (i) => i.id === params.filterByModuleId,
    );
  }

  const modToPublishedModMap = new Map<
    string | undefined,
    string | undefined
  >();

  sortedModuleItems.forEach((mod) => {
    modToPublishedModMap.set(
      mod.id,
      publishedVersion.data?.portfolio_modules?.find(
        (md) => md.title === mod.title,
      )?.id,
    );
  });

  const getDataLabel = (dataTypeId?: string, optimizerJobId?: string) => {
    if (dataTypeId === userInputDataTypeId) {
      return "Draft";
    } else if (dataTypeId === benchmarkDataTypeId) {
      return "Benchmark";
    } else {
      return optimizationJobs.items.find((job) => job.id === optimizerJobId)
        ?.title;
    }
  };

  const getPublishedDataByTypeId = (moduleId?: string, dataTypeId?: string) => {
    const publishedModuleId = modToPublishedModMap.get(moduleId);

    return publishedModuleId
      ? publishedVersion.data?.portfolio_modules
          ?.find((md) => md.id === publishedModuleId)
          ?.portfolio_module_data?.find((d) => d.type_id === dataTypeId)
      : undefined;
  };

  // fill portfolioModuleDataIds for all data
  const portfolioModuleDataIds = new Set<string>();

  sortedModuleItems
    .filter((i) => !i.is_cash)
    .forEach((portModule) => {
      version.data?.portfolio_modules
        ?.find((i) => i.id === portModule.id)
        ?.portfolio_module_data?.forEach((di) => {
          if (di.id) {
            portfolioModuleDataIds.add(di.id);
          }
        });
      const publishedItem = getPublishedDataByTypeId(
        portModule.id,
        userInputDataTypeId,
      );

      if (publishedItem?.id) {
        portfolioModuleDataIds.add(publishedItem.id);
      }
    });

  const portfolioModuleData = usePortfolioModuleData({
    portfolioModuleDataIds: Array.from(portfolioModuleDataIds),
    skip: version.isLoading || publishedVersion.isLoading,
  });

  const getChartItems = (type: string, tooltipType?: string) => {
    const portModule = sortedModuleItems.find(
      (i) => i.id === params.filterByModuleId,
    );

    if (!portModule) {
      return [];
    }

    const dataItems = Array.from(
      version.data?.portfolio_modules?.find((i) => i.id === portModule.id)
        ?.portfolio_module_data ?? [],
    ).sort((a, b) => (a.created_at ?? "").localeCompare(b.created_at ?? ""));

    const children =
      dataItems
        .map<CarPortfolioChartItem | undefined>((dataItem) => {
          const statItemResult = portfolioModuleData.getStatItemResult({
            moduleDataId: dataItem.id,
            type: type,
            maxFee: false,
          });

          // const statItemResultMaxFee =
          //   portfolioModuleData.getStatItemResult({
          //     moduleDataId: dataItem.id,
          //     type: type,
          //     maxFee: true,
          //   });

          const tooltipStatItemResult = tooltipType
            ? portfolioModuleData.getStatItemResult({
                moduleDataId: dataItem.id,
                type: tooltipType,
              })
            : undefined;

          const label = getDataLabel(
            dataItem.type_id,
            dataItem.optimizer_job_root ?? undefined,
          );

          return statItemResult && label
            ? {
                id: dataItem.id ?? "",
                label,
                fullLabel: `${portModule.title}: ${label}`,
                isSelected: !!dataItem.selected,
                isDraft: dataItem.type_id === userInputDataTypeId,
                isBenchmark: dataItem.type_id === benchmarkDataTypeId,
                x: statItemResult.index,
                y: statItemResult.values,
                // yMaxFee: statItemResultMaxFee?.values,
                // yMaxFee: statItemResult.values.map(
                //   (i) => i * 1 + Math.random()
                // ), // temp
                tooltipValues: tooltipStatItemResult?.values,
              }
            : undefined;
        })
        .filter(isDefined) ?? [];

    const publishedItem = getPublishedDataByTypeId(
      portModule.id,
      userInputDataTypeId,
    );

    const publishedItemResult = portfolioModuleData.getStatItemResult({
      moduleDataId: publishedItem?.id,
      type: type,
    });

    if (publishedItem && publishedItemResult) {
      children.unshift({
        id: publishedItem.id ?? "",
        label: "Existing",
        fullLabel: `${portModule.title}: Existing`,
        isSelected: false,
        x: publishedItemResult.index,
        y: publishedItemResult.values,
      });
    }

    return children;
  };

  const oneYearUnderPerf = getChartItems("oneyr_hist_rolling_returns");
  const threeYearUnderPerf = getChartItems(
    "threeyr_hist_rolling_returns_annual",
    "threeyr_hist_rolling_returns",
  );
  const cumRelPerf = getChartItems("annual_fv");
  const multiPeriodReturnComparison = getChartItems(
    "month_hist_rolling_returns",
  );

  const theme = useTheme();

  const getHeatmapChartItems = (isReal: boolean) => {
    const keyPrefix = isReal ? "real" : "nominal";
    interface Type {
      label: string;
      color: string;
      type: string;
      fill?: string;
    }

    const types: Type[] = [
      {
        label: "95th - 75th",
        color: theme.palette.gray2,
        type: `${keyPrefix}_heatmap_95`,
        fill: "+1",
      },
      {
        label: "75th - 60th",
        color: theme.palette.gray4,
        type: `${keyPrefix}_heatmap_75`,
        fill: "+1",
      },
      {
        label: "60th - 50th",
        color: theme.palette.gray6,
        type: `${keyPrefix}_heatmap_60`,
        fill: "+1",
      },
      {
        label: "50th",
        color: theme.palette.softBlack,
        type: `${keyPrefix}_heatmap_50`,
      },
      {
        label: "50th - 40th",
        color: theme.palette.gray6,
        type: `${keyPrefix}_heatmap_40`,
        fill: "-1",
      },
      {
        label: "40th - 25th",
        color: theme.palette.gray4,
        type: `${keyPrefix}_heatmap_25`,
        fill: "-1",
      },
      {
        label: "25th - 5th",
        color: theme.palette.gray2,
        type: `${keyPrefix}_heatmap_5`,
        fill: "-1",
      },
    ];

    const portModule = sortedModuleItems.find(
      (i) => i.id === params.filterByModuleId,
    );

    if (!portModule) {
      return [];
    }
    const dataItems = Array.from(
      version.data?.portfolio_modules?.find((i) => i.id === portModule.id)
        ?.portfolio_module_data ?? [],
    ).sort((a, b) => (a.created_at ?? "").localeCompare(b.created_at ?? ""));

    const children =
      dataItems
        .map<CarPortfolioHeatmapChartItem | undefined>((dataItem) => {
          const label = getDataLabel(
            dataItem.type_id,
            dataItem.optimizer_job_root ?? undefined,
          );

          return label
            ? {
                id: dataItem.id ?? "",
                label,
                fullLabel: `${portModule.title}: ${label}`,
                isSelected: !!dataItem.selected,
                isDraft: dataItem.type_id === userInputDataTypeId,
                isBenchmark: dataItem.type_id === benchmarkDataTypeId,
                datasets: types
                  .map<CarPortfolioHeatmapChartDataset | undefined>(
                    ({ type, ...item }) => {
                      const statItemResult =
                        portfolioModuleData.getStatItemResult({
                          moduleDataId: dataItem.id,
                          type: type,
                        });

                      return statItemResult
                        ? {
                            ...item,
                            x: statItemResult.index,
                            y: statItemResult.values.map((i) => i * 100),
                          }
                        : undefined;
                    },
                  )
                  .filter(isDefined),
              }
            : undefined;
        })
        .filter(isDefined)
        .filter((i) => i.datasets.length) ?? [];

    const publishedItem = getPublishedDataByTypeId(
      portModule.id,
      userInputDataTypeId,
    );

    if (publishedItem) {
      children.unshift({
        id: publishedItem.id ?? "",
        label: "Existing",
        fullLabel: `${portModule.title}: Existing`,
        isSelected: false,
        datasets: types
          .map<CarPortfolioHeatmapChartDataset | undefined>(
            ({ type, ...item }) => {
              const publishedItemResult = portfolioModuleData.getStatItemResult(
                {
                  moduleDataId: publishedItem.id,
                  type: type,
                },
              );

              return publishedItemResult
                ? {
                    ...item,
                    x: publishedItemResult.index,
                    y: publishedItemResult.values.map((i) => i * 100),
                  }
                : undefined;
            },
          )
          .filter(isDefined),
      });
    }

    return children;
  };

  const realHeatmap = getHeatmapChartItems(true);
  const nominalHeatmap = getHeatmapChartItems(false);

  const getDefaultDataIdsForModule = (moduleId?: string) => {
    return {
      data1DefaultId:
        oneYearUnderPerf.find((i) => i.isSelected)?.id ??
        oneYearUnderPerf.find((i) => i.isDraft)?.id,
      data2DefaultId: oneYearUnderPerf.find((i) => i.isBenchmark)?.id,
    };
  };

  const getModuleNameById = (id?: string) =>
    sortedModuleItems.find((i) => i.id === id)?.title;

  const periods =
    returnScenarios.items
      .filter((rs) => rs.show_in_graphs)
      .map<CarPortfolioStatPeriod>((rs) => ({
        label: rs.name ?? "",
        startDate: rs.start_date ?? "",
        endDate: rs.end_date ?? "",
      })) ?? [];

  const isLoading =
    dataTypes.isLoading ||
    version.isLoading ||
    publishedVersion.isLoading ||
    portfolioModuleData.isLoading ||
    optimizationJobs.isLoading ||
    versionClasses.isLoading ||
    latestSimulation.isLoading ||
    returnScenarios.isLoading;
  // ||
  // assetClassesStatData.isLoading;

  return {
    isLoading,
    // assetClasses: assetClassesStatData.assetClasses,
    periods,
    // periods: assetClassesStatData.periods,

    oneYearUnderPerf: [
      // ...assetClassesStatData.oneYearUnderPerf,
      ...oneYearUnderPerf,
    ],
    threeYearUnderPerf: [
      // ...assetClassesStatData.threeYearUnderPerf,
      ...threeYearUnderPerf,
    ],
    cumRelPerf: [
      // ...assetClassesStatData.cumRelPerf,
      ...cumRelPerf,
    ],
    multiPeriodReturnComparison: [
      // ...assetClassesStatData.multiPeriodReturnComparison,
      ...multiPeriodReturnComparison,
    ],

    realHeatmap: [
      // ...assetClassesStatData.realHeatmap,
      ...realHeatmap,
    ],
    nominalHeatmap: [
      // ...assetClassesStatData.nominalHeatmap,
      ...nominalHeatmap,
    ],

    getDefaultDataIdsForModule,
    getModuleNameById,
  };
};
