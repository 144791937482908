import { useEffect, useRef } from "react";

export const useMount = (effect: React.EffectCallback) => {
  const isMounted = useRef<boolean>(false);
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
