import { ComponentProps, ComponentType, useCallback } from "react";
import { v4 } from "uuid";
import { closeDialog, showDialog } from "./dialogSlice";
import { useAppDispatch } from "./hooks";

export interface IDialogProps<T> {
  onCancel: () => void;
  onApply: (value: T) => void;
}

export const useDialog = () => {
  const dispatch = useAppDispatch();

  return useCallback(
    async function dialog<
      T,
      C extends ComponentType<any & IDialogProps<T>> = ComponentType<
        any & IDialogProps<T>
      >,
      V = Parameters<ComponentProps<C>["onApply"]>[0],
    >(comp: C, props: Omit<ComponentProps<C>, "onCancel" | "onApply">) {
      return new Promise<V | undefined>((resolve) => {
        const id = v4();

        dispatch(
          showDialog({
            id,
            comp,
            props: {
              ...props,

              onApply: (value: V) => {
                dispatch(closeDialog(id));
                resolve(value);
              },

              onCancel: () => {
                dispatch(closeDialog(id));
                resolve(undefined);
              },
            },
          }),
        );
      });
    },
    [dispatch],
  );
};
