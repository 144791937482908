import { Children, ReactElement, cloneElement } from "react";
import { CarPdfBox, CarPdfBoxProps } from "./PdfBox";
import { CarPdfTypography, CarPdfTypographyProps } from "./PdfTypography";
import { formatPercentFactor } from "utils";

export interface CarPdfTableProps extends CarPdfBoxProps {
  // children: ReactElement[];
  colWidths?: number[];
}

export const CarPdfTable = ({
  children,
  colWidths,
  ...props
}: CarPdfTableProps) => {
  const totalWidth = colWidths?.reduce((acc, i) => acc + i, 0);

  const convertedWidths = colWidths?.map((i) => i / (totalWidth ?? 1));

  return (
    <CarPdfBox {...props}>
      {Children.map(children, (child, index) =>
        cloneElement(child as any, {
          colWidths: convertedWidths,
        }),
      )}
    </CarPdfBox>
  );
};

export interface CarPdfHeaderProps extends Omit<CarPdfBoxProps, "children"> {
  children: ReactElement[];
  colWidths?: number[];
  paddingVertical?: number;
}

export const CarPdfHeader = ({
  children,
  style,
  colWidths,
  paddingVertical,
  ...props
}: CarPdfHeaderProps) => (
  <CarPdfBox
    style={{
      flexDirection: "row",
      ...style,
    }}
    fixed
    {...props}
  >
    {Children.map(children, (child, index) =>
      cloneElement(child, {
        isLast: index === children.length - 1,
        colWidth: colWidths?.[index],
        paddingVertical,
      }),
    )}
  </CarPdfBox>
);

export interface CarPdfHeaderCellProps extends CarPdfTypographyProps {
  isLast?: boolean;
  colWidth?: number;
  paddingVertical?: number;
}

export const CarPdfHeaderCell = ({
  children,
  style,
  isLast,
  colWidth,
  paddingVertical,
  ...props
}: CarPdfHeaderCellProps) => (
  <CarPdfTypography
    variant="tableHeading"
    {...props}
    style={{
      borderLeftWidth: 1,
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: isLast ? 1 : 0,
      borderColor: "#c3c3c3",
      backgroundColor: "#e1e2e3",
      width: colWidth ? formatPercentFactor(colWidth, 1) : "100%",
      paddingHorizontal: 2,
      paddingVertical: paddingVertical ?? 2,
      textAlign: "center",
      ...style,
    }}
  >
    {children}
  </CarPdfTypography>
);

export interface CarPdfRowProps extends Omit<CarPdfBoxProps, "children"> {
  children: ReactElement[];
  colWidths?: number[];
  paddingVertical?: number;
  textVariant?: CarPdfTypographyProps["variant"];
}

export const CarPdfRow = ({
  children,
  style,
  colWidths,
  paddingVertical,
  textVariant,
  ...props
}: CarPdfRowProps) => (
  <CarPdfBox
    style={{
      flexDirection: "row",
      ...style,
    }}
    wrap={false}
    {...props}
  >
    {Children.map(children, (child, index) =>
      cloneElement(child, {
        isLast: index === children.length - 1,
        colWidth: colWidths?.[index],
        paddingVertical,
        textVariant,
      }),
    )}
  </CarPdfBox>
);

export interface CarPdfTextCellProps extends CarPdfTypographyProps {
  isLast?: boolean;
  colWidth?: number;
  paddingVertical?: number;
  textVariant?: CarPdfTypographyProps["variant"];
}

export const CarPdfTextCell = ({
  children,
  style,
  isLast,
  colWidth,
  paddingVertical,
  variant,
  textVariant,
  ...props
}: CarPdfTextCellProps) => (
  <CarPdfTypography
    variant={variant ?? textVariant ?? "tableBody1"}
    style={{
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: isLast ? 1 : 0,
      borderColor: "#c3c3c3",
      overflow: "hidden",
      paddingVertical: paddingVertical ?? 4,
      textAlign: "center",
      width: colWidth ? formatPercentFactor(colWidth, 1) : "100%",
      ...style,
    }}
    {...props}
  >
    {children}
  </CarPdfTypography>
);

export interface CarPdfViewCellProps extends CarPdfBoxProps {
  isLast?: boolean;
  colWidth?: number;
  paddingVertical?: number;
}

export const CarPdfViewCell = ({
  children,
  style,
  isLast,
  colWidth,
  paddingVertical,
  ...props
}: CarPdfViewCellProps) => (
  <CarPdfBox
    style={{
      borderLeftWidth: 1,
      borderBottomWidth: 1,
      borderRightWidth: isLast ? 1 : 0,
      borderColor: "#c3c3c3",
      overflow: "hidden",
      paddingVertical: paddingVertical ?? 0,
      textAlign: "center",
      width: colWidth ? formatPercentFactor(colWidth, 1) : "100%",
      flexDirection: "row",
      ...style,
    }}
    {...props}
  >
    {children}
  </CarPdfBox>
);
