import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  FormGroupProps,
  styled,
} from "@mui/material";
import { ChangeEvent } from "react";
import { CheckboxOutline } from "icons";
import { CarTooltipBox } from "./TooltipBox";

export interface CarCheckboxProps
  extends Omit<FormControlLabelProps, "onChange" | "control"> {
  name?: string;
  checked: CheckboxProps["checked"];
  onChange: CheckboxProps["onChange"];
  CheckboxProps?: Omit<CheckboxProps, "checked" | "onChange">;
}

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.gray6,
  padding: 5,
  "&.Mui-checked": {
    color: theme.palette.caravelOrangePrimary,
  },
}));

export const CarCheckbox = styled(
  ({ name, checked, onChange, CheckboxProps, ...props }: CarCheckboxProps) => (
    <FormControlLabel
      {...props}
      sx={props.sx}
      componentsProps={{
        typography: { variant: "par02Regular" },
      }}
      control={
        <StyledCheckbox
          name={name}
          checked={checked}
          onChange={onChange}
          checkedIcon={<CheckboxOutline />}
          {...CheckboxProps}
        />
      }
    />
  ),
)(({ theme }) => ({
  marginLeft: 0,
  ".MuiFormControlLabel-label": {
    marginLeft: theme.spacing(0.5),
  },
}));

export interface CarCheckboxItem {
  label: string;
  id: string;
  pendoClassName?: string;
}
export interface CarCheckboxGroupProps
  extends Omit<FormGroupProps, "onChange"> {
  columnCount?: number;
  items: Array<CarCheckboxItem>;
  checkedItems: Array<string>;
  onChange: (checkedItems: Array<string>) => void;
  showCheckAll?: boolean;
}

export const CarCheckboxGroup = ({
  columnCount = 2,
  items,
  checkedItems,
  onChange,
  sx,
  className,
  showCheckAll,
  ...props
}: CarCheckboxGroupProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const withoutCurrent = checkedItems.filter((i) => i !== e.target.name);
    if (e.target.checked) {
      onChange([...withoutCurrent, e.target.name]);
    } else {
      onChange(withoutCurrent);
    }
  };

  return (
    <FormControl component="div" className={className} sx={sx}>
      {showCheckAll && (
        <CarCheckbox
          sx={{ mb: 2 }}
          name="check_all"
          checked={items.every((i) => checkedItems.some((c) => c === i.id))}
          onChange={(e, checked) => {
            if (checked) {
              onChange(items.map((i) => i.id));
            } else {
              onChange([]);
            }
          }}
          label="Select All"
        />
      )}
      <FormGroup {...props} sx={{ ml: showCheckAll ? 4.5 : 0 }}>
        {items.map((i) => (
          <CarCheckbox
            key={i.id}
            name={i.id}
            checked={checkedItems.some((c) => c === i.id)}
            onChange={handleChange}
            label={
              i.pendoClassName ? (
                <>
                  <>
                    {i.label}
                    <CarTooltipBox
                      sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                      className={i.pendoClassName}
                    />
                  </>
                </>
              ) : (
                i.label
              )
            }
          />
        ))}
      </FormGroup>
    </FormControl>
  );
};
