import { Search } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  InputAdornment,
  ListItemText,
  MenuItem,
  MenuList,
  styled,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useAppTitle } from "app/useAppTitle";
// import { CarButton } from "components/Buttons";
import { CarTextField } from "components/Inputs";
import { CarBurgerMenuButton } from "components/BurgerMenuButton";
import { useClients } from "../financialPlanning/clientList/useClients";
import { FullScreenBackground } from "components/FullScreenBackground";
import { UserBadge } from "app/UserBadge";
import { usePortfolioGroupList } from "pages/assetAllocation/portfolioGroupList/usePortfolioGroupList";

const SearchField = styled(CarTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "5px",
    backgroundColor: theme.palette.white,
    "& input": {
      paddingTop: theme.spacing(2.65),
      paddingBottom: theme.spacing(2.65),
      ...theme.typography.par03Regular,
      color: theme.palette.black,
    },
    "& fieldset": {
      borderRadius: "5px",
    },
    ".MuiSvgIcon-root": {
      width: 24,
      height: 24,
      color: theme.palette.softBlack,
    },
  },
}));

const TransparentBox = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.8)",
  border: "1px solid",
  borderColor: theme.palette.gray3,
  boxShadow: "4px 4px 10px 0px #00000026",
  borderRadius: "5px",
  margin: "auto",
  marginTop: "30vh",
  width: "90%",
  maxWidth: 845,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  paddingLeft: theme.spacing(7.5),
  paddingRight: theme.spacing(7.5),
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
  gap: theme.spacing(3),
}));

// const SearchButton = styled(CarButton)(({ theme }) => ({
//   height: 64,
//   width: 200,
//   ...theme.typography.par03SemiBold,
// }));

const StyledBurgerButton = styled(CarBurgerMenuButton)(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(2.5),
  top: theme.spacing(1.75),
}));

interface SearchItem {
  title: string;
  onClick: () => void;
}

export const HomePage = () => {
  useAppTitle("Home");

  const [search, setSearch] = useState<string | undefined>();
  const [showLoading, setShowLoading] = useState(false);
  const clients = useClients(true);
  const portfolioGroups = usePortfolioGroupList();

  const isLoading = clients.isLoading || portfolioGroups.isLoading;

  const listRef = useRef<HTMLUListElement | null>(null);

  const searchItems: SearchItem[] = [
    ...clients.items.map<SearchItem>((i) => ({
      title: i.name,
      onClick: () => clients.gotoClient(i),
    })),
    ...portfolioGroups.items.map<SearchItem>((i) => ({
      title: i.title,
      onClick: () =>
        i.id ? portfolioGroups.gotoPortfolioGroup(i.id) : undefined,
    })),
  ].filter(
    (i) =>
      search &&
      i.title.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );

  return (
    <FullScreenBackground
      sx={{
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <StyledBurgerButton />
      <UserBadge />
      <TransparentBox>
        <Typography variant="h1SSemiBold">Search</Typography>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "stretch",
            alignItems: "center",
            width: "100%",
            gap: 4.5,
          }}
        >
          <Box
            sx={{
              flex: "auto",
              display: "flex",
              position: "relative",
            }}
          >
            <SearchField
              value={search}
              onChange={(value) => {
                setSearch(value);
                setShowLoading(true);
              }}
              fullWidth
              autoFocus
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ ml: 1, mr: 0 }}>
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" sx={{ ml: 1, mr: 2 }}>
                    {isLoading && showLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === "ArrowDown") {
                  e.preventDefault();
                  (listRef.current?.firstChild as any)?.focus();
                }
                // if (e.key === "Enter") {
                //   e.preventDefault();
                //   clients.gotoFinancialPlanning(search);
                // }
              }}
            />
            {searchItems.length > 0 && (
              <MenuList
                ref={listRef}
                sx={{
                  mt: 1,
                  position: "absolute",
                  left: 0,
                  top: "100%",
                  bgcolor: "white",
                  width: "100%",
                  overflow: "auto",
                  maxHeight: 300,
                  border: "solid 2px",
                  borderColor: "gray3",
                }}
              >
                {searchItems.map((i, idx) => (
                  <MenuItem key={idx} onClick={i.onClick}>
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "par03Regular",
                        sx: { color: "black" },
                      }}
                    >
                      {i.title}
                    </ListItemText>
                  </MenuItem>
                ))}
              </MenuList>
            )}
          </Box>
          {/* <SearchButton onClick={() => clients.gotoFinancialPlanning(search)}>
            Search
          </SearchButton> */}
        </Box>
      </TransparentBox>
    </FullScreenBackground>
  );
};
