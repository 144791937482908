import { formatCurrency } from "utils";
import { StressTestItem, UseStressTest } from "../stressTest/useStressTest";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfSubHeading } from "./PdfHeading";
import { CarPdfMarkdown } from "components/PdfMarkdown";
import { CarPdfGoalsLegend } from "./PdfGoalsLegend";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfHeaderProps,
  CarPdfRow,
  CarPdfRowProps,
  CarPdfTable,
  CarPdfTextCell,
  CarPdfViewCell,
} from "components/PdfTable";
import {
  CarPdfIconFailure,
  CarPdfIconPartial,
  CarPdfIconSuccess,
} from "components/PdfIcon";
interface HeaderProps extends Omit<CarPdfHeaderProps, "children"> {
  labels: string[];
}

const Header = ({ labels, ...props }: HeaderProps) => (
  <CarPdfHeader paddingVertical={14} {...props}>
    {[
      <CarPdfHeaderCell key={-4}>Goal Name (Priority)</CarPdfHeaderCell>,
      <CarPdfHeaderCell key={-3}>Years</CarPdfHeaderCell>,
      <CarPdfHeaderCell key={-2}>*Annual Amount</CarPdfHeaderCell>,
      <CarPdfHeaderCell key={-1}>Total Amount</CarPdfHeaderCell>,
      ...labels.map((i, idx) => (
        <CarPdfHeaderCell key={idx}>{i}</CarPdfHeaderCell>
      )),
    ]}
  </CarPdfHeader>
);

interface RowProps extends Omit<CarPdfRowProps, "children"> {
  item: StressTestItem;
}

const Row = ({ item, ...props }: RowProps) => (
  <CarPdfRow paddingVertical={10} textVariant="tableBody3" {...props}>
    {[
      <CarPdfTextCell
        key={-4}
        style={{
          textAlign: "left",
          paddingHorizontal: 5,
        }}
      >
        {item.goalName}
      </CarPdfTextCell>,
      <CarPdfTextCell key={-3}>{item.years.toString()}</CarPdfTextCell>,
      <CarPdfTextCell key={-2}>
        {formatCurrency(item.annualAmount)}
      </CarPdfTextCell>,
      <CarPdfTextCell key={-1}>
        {formatCurrency(item.totalAmount)}
      </CarPdfTextCell>,
      ...item.stressTasks.map((i, idx) => (
        <CarPdfViewCell
          key={idx}
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingVertical: 0,
          }}
        >
          {i.status === "success" ? (
            <CarPdfIconSuccess />
          ) : i.status === "failure" ? (
            <CarPdfIconFailure />
          ) : (
            <CarPdfIconPartial />
          )}
        </CarPdfViewCell>
      )),
    ]}
  </CarPdfRow>
);

export interface StressTestPdfPageProps {
  portfolioLabel: string;
  stressTest: UseStressTest;
  disclosures: UseDisclosures;
  pageProps: CarPdfPageProps;
}

export const StressTestPdfPage = (props: StressTestPdfPageProps) => {
  const { stressTest } = props.stressTest;

  return (
    <CarPdfPage {...props.pageProps}>
      <CarPdfSubHeading label="Stress Test" />
      <CarPdfMarkdown style={{ marginTop: 10, marginBottom: 30 }}>
        {props.disclosures.getContent(
          CarDisclosureContentCode.CMPAS_CLIENT_REPORT_STRESS_TEST_part_10,
        )}
      </CarPdfMarkdown>
      <CarPdfTable
        colWidths={[2.5, 0.9, 1.6, 1.8, ...stressTest.taskLabels.map((i) => 1)]}
      >
        <Header labels={stressTest.taskLabels.map(([label]) => label)} />
        {stressTest.items.map((i, idx) => (
          <Row item={i} key={idx} />
        ))}
      </CarPdfTable>
      <CarPdfGoalsLegend
        style={{
          marginTop: 20,
        }}
      />
      <CarPdfTable break>
        <CarPdfHeader paddingVertical={14}>
          <CarPdfHeaderCell style={{ paddingLeft: 5, textAlign: "left" }}>
            Market Environment
          </CarPdfHeaderCell>
          <CarPdfHeaderCell>Surplus</CarPdfHeaderCell>
          <CarPdfHeaderCell>Deficit</CarPdfHeaderCell>
        </CarPdfHeader>
        {stressTest.surplusDeficits.map((i, idx) => (
          <CarPdfRow key={idx} textVariant="tableBody3" paddingVertical={10}>
            <CarPdfTextCell style={{ paddingLeft: 5, textAlign: "left" }}>
              {stressTest.taskLabels[idx]}
            </CarPdfTextCell>
            <CarPdfTextCell>{formatCurrency(i > 0 ? i : 0)}</CarPdfTextCell>
            <CarPdfTextCell>{formatCurrency(i < 0 ? i : 0)}</CarPdfTextCell>
          </CarPdfRow>
        ))}
      </CarPdfTable>
    </CarPdfPage>
  );
};
