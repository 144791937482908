import { useDispatch } from "react-redux";
import { api } from "api/carApi.manual";
import { clearAuth } from "features/auth/authSlice";
import { useMemo } from "react";

export const useLogout = () => {
  const dispatch = useDispatch();

  return useMemo(() => {
    const logout = () => {
      dispatch(clearAuth());
      dispatch(api.util.resetApiState());
      localStorage.clear();
      sessionStorage.clear();
    };

    return {
      logout,
    };
  }, [dispatch]);
};
