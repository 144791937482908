import { fontPdf } from "components/Pdf";
import { chartsColor } from "../../theme";
import { formatPercent, splitByWord } from "utils";
import { CarPortfolioAssetTableData } from "pages/assetAllocation/usePortfolioGroupVersionData";
import { AssetClassLevel, assetClassLevelItems } from "const";
import { PortfolioModule } from "api/carApi.generated";
import { getChartModuleSummaryData } from "pages/assetAllocation/editPortfolioGroup/ChartModuleSummary";
import { CarPdfPage } from "components/PdfPage";
import { CarPdfChart } from "components/PdfChart";
import { ChartPlugins } from "chartUtils";
import { ReactElement } from "react";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfTypography } from "components/PdfTypography";
import { CarPdfInternalUse } from "features/financialPlanningReport/PdfHeading";

interface ModuleChartProps {
  width: number;
  height: number;
  moduleId: string;
  tableData: CarPortfolioAssetTableData;
  level: AssetClassLevel;
  data1Id: string;
  data2Id: string;
  moduleName: string;
}

const ModuleChartVertical = ({
  width,
  height,
  moduleId,
  tableData,
  level,
  data1Id,
  data2Id,
  moduleName,
}: ModuleChartProps) => {
  const data = getChartModuleSummaryData({
    moduleId,
    tableData,
    level,
    data1Id,
    data2Id,
  });

  return (
    <CarPdfChart
      type="bar"
      width={width}
      height={height}
      options={{
        devicePixelRatio: 4,
        responsive: false,
        animation: false,
        scales: {
          x: {
            ticks: {
              color: "black",
            },
            border: { display: false },
            grid: {
              display: false,
              tickColor: "white",
              offset: false,
            },
          },
          y: {
            title: {
              display: true,
              text: "Benchmark",
              color: "black",
              font: {
                size: 10,
              },
            },
            ticks: {
              color: "black",
              callback: (value) =>
                typeof value === "number" ? formatPercent(value) : value,
            },
            border: { display: false },
            grid: {
              display: true,
              lineWidth: 1,
              tickColor: "white",
            },
            offset: true,
          },
        },
        interaction: {
          mode: "point",
          intersect: false,
        },
        plugins: {
          roundedBackground: {
            backgroundColor: "#fafafa",
            contextColor: "white",
            borderColor: "#b3b1b1",
            borderRadius: 0,
          },
          legend: {
            position: "bottom",
            labels: {
              boxHeight: 10,
              boxWidth: 10,
              padding: 20,
              color: "black",
              font: {
                size: fontPdf.table.normal.headerSize,
              },
            },
          },
        },
      }}
      data={{
        labels: data.xLabels,
        datasets: data.datasets.map((i, idx) => ({
          label: `${moduleName}: ${i.label}`,
          data: i.data,
          borderWidth: 0,
          backgroundColor: idx === 0 ? chartsColor.blue : chartsColor.orange,
          barPercentage: 0.9,
          categoryPercentage: 0.45,
        })),
      }}
      plugins={[ChartPlugins.roundedBackground]}
    />
  );
};

const ModuleChartHorizontal = ({
  width,
  height,
  moduleId,
  tableData,
  level,
  data1Id,
  data2Id,
  moduleName,
}: ModuleChartProps) => {
  const data = getChartModuleSummaryData({
    moduleId,
    tableData,
    level,
    data1Id,
    data2Id,
  });

  return (
    <CarPdfChart
      type="bar"
      width={width}
      height={height}
      options={{
        indexAxis: "y",
        devicePixelRatio: 4,
        responsive: false,
        animation: false,
        scales: {
          y: {
            ticks: {
              color: "black",
              autoSkip: false,
            },
            border: { display: false },
            grid: {
              display: false,
              tickColor: "white",
              offset: false,
            },
          },
          x: {
            position: "top",
            title: {
              display: true,
              text: "Benchmark",
              color: "black",
              font: {
                size: 10,
              },
            },
            ticks: {
              color: "black",
              callback: (value) =>
                typeof value === "number" ? formatPercent(value) : value,
            },
            border: { display: false },
            grid: {
              display: true,
              lineWidth: 1,
              tickColor: "white",
            },
            offset: true,
          },
        },
        interaction: {
          mode: "point",
          intersect: false,
        },
        plugins: {
          roundedBackground: {
            backgroundColor: "#fafafa",
            contextColor: "white",
            borderColor: "#b3b1b1",
            borderRadius: 0,
          },
          legend: {
            position: "bottom",
            labels: {
              boxHeight: 10,
              boxWidth: 10,
              padding: 20,
              color: "black",
              font: {
                size: fontPdf.table.normal.headerSize,
              },
            },
          },
        },
      }}
      data={{
        labels: data.xLabels.map((l) => splitByWord(l, 20)),
        datasets: data.datasets.map((i, idx) => ({
          label: `${moduleName}: ${i.label}`,
          data: i.data,
          borderWidth: 0,
          backgroundColor: idx === 0 ? chartsColor.blue : chartsColor.orange,
          barPercentage: 0.9,
          categoryPercentage: [
            AssetClassLevel.level1,
            AssetClassLevel.level2,
          ].includes(level)
            ? 0.45
            : 0.9,
        })),
      }}
      plugins={[ChartPlugins.roundedBackground]}
    />
  );
};

export interface ModulesPdfPageProps {
  title?: ReactElement;
  modules: PortfolioModule[];
  tableData: CarPortfolioAssetTableData;
  level: AssetClassLevel;
  data1Id: string;
  data2Id: string;
}

export const ModulesPdfPage = (props: ModulesPdfPageProps) => {
  return (
    <CarPdfPage
      style={{
        paddingTop: 35,
        paddingBottom: 35,
      }}
      hideStripe
      footerBottom={10}
      footerDisclosures=""
    >
      {props.title}
      {props.modules.map((module, idx) => (
        <CarPdfBox key={idx} style={{ marginTop: 16 }}>
          <CarPdfTypography variant="body1">
            {module.title} -{" "}
            {assetClassLevelItems.find((i) => i.value === props.level)?.label ??
              props.level}
          </CarPdfTypography>
          <CarPdfInternalUse />
          {props.level === AssetClassLevel.level1 ? (
            <ModuleChartVertical
              width={518}
              height={306}
              moduleId={module.id ?? ""}
              tableData={props.tableData}
              level={props.level}
              data1Id={props.data1Id}
              data2Id={props.data2Id}
              moduleName={module.title}
            />
          ) : (
            <ModuleChartHorizontal
              width={518}
              height={650}
              moduleId={module.id ?? ""}
              tableData={props.tableData}
              level={props.level}
              data1Id={props.data1Id}
              data2Id={props.data2Id}
              moduleName={module.title}
            />
          )}
        </CarPdfBox>
      ))}
    </CarPdfPage>
  );
};
