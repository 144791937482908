import { usePortfolioGroupVersionStatData } from "../../usePortfolioGroupVersionStatData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "../../usePortfolioGroupInfo";
import { useChartDataSelector } from "./../useChartDataSelector";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { ChartModuleSummary } from "./../ChartModuleSummary";
import { usePortfolioGroupVersionData } from "../../usePortfolioGroupVersionData";
import { AssetClassLevel } from "const";
import { CarAssetClassLevelField } from "components/Inputs";
import { useSessionStorage } from "features/general/useLocalStorage";
import { AnalyzeModuleStyledPage } from "./AnalyzeModuleStyledPage";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

interface AnalyzeModuleSummaryProps {
  moduleId: string;
  assetClassLevelStorageKey: string;
}

export const AnalyzeModuleSummary = (props: AnalyzeModuleSummaryProps) => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const pageContent = usePageContent();

  const { moduleId } = props;

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const data = usePortfolioGroupVersionData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    show: {
      benchmark: true,
      existing: true,
      draft: true,
      selected: false,
    },
    hideCash: true,
  });

  const statData = usePortfolioGroupVersionStatData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    filterByModuleId: moduleId,
  });

  const defaultIds = statData.getDefaultDataIdsForModule(moduleId);

  const chartDataSelector = useChartDataSelector({
    storageKey: `EditPortfolioGroupAnalyze_${portfolioGroupVersionId}`,
    ...defaultIds,
  });

  const [assetClassLevel, setAssetClassLevel] =
    useSessionStorage<AssetClassLevel>(
      props.assetClassLevelStorageKey,
      AssetClassLevel.level1,
    );

  return (
    <AnalyzeModuleStyledPage>
      <TablePlaceHolder
        isLoading={
          groupInfo.isLoading || data.isLoading || pageContent.isLoading
        }
        rows={5}
      >
        <CarPageContent
          sx={{ mb: 5 }}
          isWhiteContext
          content={pageContent.getContent(
            groupInfo.isSinglePortfolioMode
              ? CarPageContentCode.PORTFOLIO_ANALYSIS_ANALYZE_SUMMARY
              : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SUMMARY,
          )}
        />
        <CarAssetClassLevelField
          sx={{
            width: 150,
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              mt: 3,
            },
            ".MuiInputBase-input": {
              backgroundColor: "white",
            },
          }}
          label={
            <>
              Asset Class Level
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={pendoClasses.editPortfolioAnalyzeAssetClassLevel}
              />
            </>
          }
          value={assetClassLevel}
          onChange={setAssetClassLevel}
        />
        <ChartModuleSummary
          moduleId={props.moduleId}
          tableData={data.tableData}
          level={assetClassLevel}
          chartDataSelector={chartDataSelector}
        />
      </TablePlaceHolder>
    </AnalyzeModuleStyledPage>
  );
};
