import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfHeading } from "./PdfHeading";
import { CarPdfMarkdown } from "components/PdfMarkdown";

export interface GlossaryPdfPageProps {
  disclosures: UseDisclosures;
  pageProps: CarPdfPageProps;
}

export const GlossaryPdfPage = (props: GlossaryPdfPageProps) => (
  <CarPdfPage {...props.pageProps}>
    <CarPdfHeading label="Glossary" style={{ marginBottom: 14 }} />
    <CarPdfMarkdown>
      {props.disclosures.getContent(
        CarDisclosureContentCode.CMPAS_CLIENT_REPORT_GLOSSARY_part_100,
      )}
    </CarPdfMarkdown>
  </CarPdfPage>
);
