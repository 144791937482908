import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { CarAuthInfo } from "types";
import { shutdownIntercom } from "app/thirdParty/intercom";
import { shutdownPendo } from "app/thirdParty/pendo";
import { clearSentryUser } from "app/thirdParty/sentry";

const initialState: CarAuthInfo = {
  access: "",
  incomplete_reason: "success",
  is_complete: false,
  refresh: "",
};

export const AUTH_INFO_KEY = "auth_info";

export const authSlice = createSlice({
  name: "auth",
  initialState: () => {
    let result: CarAuthInfo = initialState;

    try {
      const authInfoStr =
        sessionStorage.getItem(AUTH_INFO_KEY) ||
        localStorage.getItem(AUTH_INFO_KEY);
      if (authInfoStr) {
        result = JSON.parse(authInfoStr);
      }
    } catch (error) {
      console.error("authSlice.initialState", error);
    }

    return result;
  },
  reducers: {
    // save auth to the session storage (remember me unchecked)
    setAuthSession: (state, action: PayloadAction<CarAuthInfo>) => {
      Object.assign(state, action.payload);
      sessionStorage.setItem(AUTH_INFO_KEY, JSON.stringify(action.payload));
      localStorage.removeItem(AUTH_INFO_KEY);
    },

    // save auth to the local storage (remember me checked)
    setAuthLocal: (state, action: PayloadAction<CarAuthInfo>) => {
      Object.assign(state, action.payload);
      localStorage.setItem(AUTH_INFO_KEY, JSON.stringify(action.payload));
      sessionStorage.removeItem(AUTH_INFO_KEY);
    },

    // when refreshing auth - saving it to the same storage
    refreshAuth: (state, action: PayloadAction<CarAuthInfo>) => {
      Object.assign(state, action.payload);
      if (sessionStorage.getItem(AUTH_INFO_KEY)) {
        sessionStorage.setItem(AUTH_INFO_KEY, JSON.stringify(action.payload));
      } else {
        localStorage.setItem(AUTH_INFO_KEY, JSON.stringify(action.payload));
      }
    },

    clearAuth: (state, action: PayloadAction<undefined>) => {
      // Update Sentry & Intercom
      clearSentryUser();
      shutdownIntercom();
      shutdownPendo();
      sessionStorage.removeItem(AUTH_INFO_KEY);
      localStorage.removeItem(AUTH_INFO_KEY);
      return Object.assign(state, initialState);
    },
  },
});

export const { refreshAuth, setAuthSession, setAuthLocal, clearAuth } =
  authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export const authReducer = authSlice.reducer;
