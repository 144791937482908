import { Box, Slider, SliderProps, SliderThumb, styled } from "@mui/material";
import { CircleSlice } from "icons";

const CustomSliderThumb = ({ ...props }: any) => {
  return (
    <SliderThumb {...props}>
      {props.children}
      <CircleSlice sx={{ width: 18, height: 18 }} />
    </SliderThumb>
  );
};

interface SliderValueLabelProps {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

const SliderValueLabel = ({ children, open, value }: SliderValueLabelProps) => {
  return (
    <Box position="absolute" style={children.props.style}>
      <Box
        top={-50}
        left={-50}
        width={100}
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        hidden={!open}
      >
        {value}
      </Box>
      {children}
    </Box>
  );
};

const StyledSlider = styled(Slider)(({ theme }) => ({
  ".MuiSlider-rail": {
    height: 12,
    borderRadius: 6,
    backgroundColor: "#DCDFE2",
  },
  ".MuiSlider-track": {
    borderRadius: 6,
    borderWidth: 0,
    height: 12,
  },
  ".MuiSlider-mark": {
    height: 31,
    width: 1,
    borderRadius: 0,
    backgroundColor: "white",
    border: "1px solid #C4C4C4",
  },
  ".MuiSlider-markLabel": {
    marginTop: 2,
    fontSize: "10px",
    fontStyle: "italic",
    fontWeight: 400,
    color: "#555555",
    maxWidth: 100,
    whiteSpace: "pre-line",
    textAlign: "center",
  },
  ".MuiSlider-thumb": {
    width: 16,
    height: 16,
    top: 2,
  },
}));

interface RiskSliderProps
  extends Omit<
    SliderProps,
    | "value"
    | "onChange"
    | "valueLabelDisplay"
    | "min"
    | "max"
    | "valueLabelFormat"
  > {
  value: number;
  onChange: (value: number) => void;
}

export const RiskSlider = ({ value, onChange, ...props }: RiskSliderProps) => {
  const handleChange = ((e: any, newValue: number) => {
    onChange(newValue);
  }) as any;
  return (
    <StyledSlider
      components={{
        ValueLabel: SliderValueLabel,
        Thumb: CustomSliderThumb,
      }}
      value={value}
      onChange={handleChange}
      step={props.marks ? null : undefined}
      min={0}
      max={100}
      valueLabelDisplay="auto"
      valueLabelFormat={(v: number, index: number) => null}
      {...props}
    />
  );
};
