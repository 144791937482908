import { BoxProps, Stack, styled } from "@mui/material";
import { ClipboardEvent, useEffect, useRef, useState } from "react";
import { PatternFormat } from "react-number-format";

const CharInput = styled("input")(({ theme }) => ({
  backgroundColor: "transparent",
  width: 77,
  outline: "none",
  border: "none",
  borderBottom: `solid 4px ${theme.palette.softBlack}`,
  color: theme.palette.softBlack,
  textAlign: "center",
  fontFamily: theme.typography.fontFamily,
  ...theme.typography.dis02SemiBold,
  lineHeight: "60px",
}));

export interface VerificationCodeProps {
  sx?: BoxProps["sx"];
  value?: string;
  onChange: (value?: string) => void;
}

export const VerificationCode = ({ sx, onChange }: VerificationCodeProps) => {
  const [char1, setChar1] = useState<string | undefined>();
  const [char2, setChar2] = useState<string | undefined>();
  const [char3, setChar3] = useState<string | undefined>();
  const [char4, setChar4] = useState<string | undefined>();
  const ref1 = useRef<HTMLInputElement>();
  const ref2 = useRef<HTMLInputElement>();
  const ref3 = useRef<HTMLInputElement>();
  const ref4 = useRef<HTMLInputElement>();
  useEffect(() => {
    const value =
      char1 && char2 && char3 && char4
        ? char1 + char2 + char3 + char4
        : undefined;
    onChange(value);
  }, [onChange, char1, char2, char3, char4]);

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    let paste = e.clipboardData.getData("text");
    paste = paste.match(/(\d\d\d\d)/)?.[1] ?? "";

    if (paste) {
      setChar1(paste[0]);
      setChar2(paste[1]);
      setChar3(paste[2]);
      setChar4(paste[3]);
    }
  };
  return (
    <Stack sx={sx} direction="row" spacing={3}>
      <PatternFormat
        getInputRef={ref1}
        format="#"
        value={char1}
        onValueChange={({ value }) => {
          setChar1(value);
        }}
        onKeyUp={(e: any) => {
          if (e.key.match(/\d/) || e.key === "Delete") {
            const nextInput = ref2.current;
            if (nextInput) {
              nextInput.focus();
              nextInput.selectionStart = 0;
            }
          }
        }}
        onPaste={handlePaste}
        customInput={CharInput}
      />
      <PatternFormat
        getInputRef={ref2}
        format="#"
        value={char2}
        onValueChange={({ value }) => {
          setChar2(value);
        }}
        onKeyUp={(e: any) => {
          if (e.key.match(/\d/) || e.key === "Delete") {
            const nextInput = ref3.current;
            if (nextInput) {
              nextInput.focus();
              nextInput.selectionStart = 0;
            }
          }
          if (e.key === "Backspace") {
            ref1.current?.focus();
          }
        }}
        onPaste={handlePaste}
        customInput={CharInput}
      />
      <PatternFormat
        getInputRef={ref3}
        format="#"
        value={char3}
        onValueChange={({ value }) => {
          setChar3(value);
        }}
        onKeyUp={(e: any) => {
          if (e.key.match(/\d/) || e.key === "Delete") {
            const nextInput = ref4.current;
            if (nextInput) {
              nextInput.focus();
              nextInput.selectionStart = 0;
            }
          }
          if (e.key === "Backspace") {
            ref2.current?.focus();
          }
        }}
        onPaste={handlePaste}
        customInput={CharInput}
      />
      <PatternFormat
        getInputRef={ref4}
        format="#"
        value={char4}
        onValueChange={({ value }) => {
          setChar4(value);
        }}
        onKeyUp={(e: any) => {
          if (e.key === "Backspace") {
            ref3.current?.focus();
          }
        }}
        onPaste={handlePaste}
        customInput={CharInput}
      />
    </Stack>
  );
};
