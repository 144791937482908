import { usePortfolioGroupVersionStatData } from "../../usePortfolioGroupVersionStatData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "../../usePortfolioGroupInfo";
import { useChartDataSelector } from "./../useChartDataSelector";
import { TablePlaceHolder } from "components/PlaceHolder";
import { ChartSectorSummary } from "./../ChartSectorSummary";
import { usePortfolioGroupVersionSectorSummary } from "../../usePortfolioGroupVersionSectorSummary";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { AnalyzeModuleStyledPage } from "./AnalyzeModuleStyledPage";
import {
  AssetClassAnalysisSectorAnalysisTable,
  SectorAnalysisLegendItem,
} from "pages/assetClassAnalysis/AssetClassAnalysisSectorAnalysisTable";
import { Box, MenuItem, Typography } from "@mui/material";
import { CarSelectField } from "components/Inputs";
import { useEffect, useState } from "react";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

const legendItems: Array<{ level: number; label: string }> = [
  { level: 0, label: "Industry Group" },
  { level: 1, label: "Industry" },
  { level: 2, label: "Subgroup" },
];

interface AnalyzeModuleSectorAnalysisProps {
  moduleId: string;
  moduleIdx: number;
}

export const AnalyzeModuleSectorAnalysis = (
  props: AnalyzeModuleSectorAnalysisProps,
) => {
  const { moduleId, moduleIdx } = props;

  const { portfolioGroupId } = usePortfolioGroupParams();

  const [sectorFocusId, setSectorFocusId] = useState<string | undefined>(
    undefined,
  );

  const [highlightLevel, setHighlightLevel] = useState<number | undefined>(
    undefined,
  );

  const pageContent = usePageContent();

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const statData = usePortfolioGroupVersionStatData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    filterByModuleId: moduleId,
  });

  const defaultIds = statData.getDefaultDataIdsForModule(moduleId);

  const chartDataSelector = useChartDataSelector({
    storageKey: `EditPortfolioGroupAnalyze_${portfolioGroupVersionId}`,
    ...defaultIds,
  });

  const sectorSummary = usePortfolioGroupVersionSectorSummary({
    portfolioGroupId,
    data1Id: chartDataSelector.data1Id,
    data2Id: chartDataSelector.data2Id,
  });

  const label1 =
    statData.oneYearUnderPerf.find((i) => i.id === chartDataSelector.data1Id)
      ?.label ?? "";
  const label2 =
    statData.oneYearUnderPerf.find((i) => i.id === chartDataSelector.data2Id)
      ?.label ?? "";

  useEffect(() => {
    if (!sectorFocusId && sectorSummary.report.level1.length > 0) {
      setSectorFocusId(sectorSummary.report.level1[0].id);
    }
  }, [sectorFocusId, sectorSummary.report.level1]);

  const sectorFocusItem = sectorSummary.report.level1.find(
    (i) => i.id === sectorFocusId,
  );

  return (
    <AnalyzeModuleStyledPage>
      <TablePlaceHolder
        isLoading={
          groupInfo.isLoading ||
          statData.isLoading ||
          sectorSummary.isLoading ||
          pageContent.isLoading
        }
        rows={5}
      >
        <CarPageContent
          sx={{ mb: 5 }}
          isWhiteContext
          content={pageContent.getContent(
            groupInfo.isSinglePortfolioMode
              ? CarPageContentCode.PORTFOLIO_ANALYSIS_ANALYZE_SECTOR_ANALYSIS
              : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SECTOR_ANALYSIS,
          )}
        />

        <ChartSectorSummary
          selectorLabel1="Portfolio Variation 1"
          selectorLabel2="Portfolio Variation 2"
          chartItems={statData.multiPeriodReturnComparison} // this needed just for combobox selectors
          report={sectorSummary.report}
          chartDataSelector={chartDataSelector}
          pendoPrefix={`module_${moduleIdx}_sector_summary`}
        />

        <Typography variant="h6SemiBold" sx={{ mt: 5.5 }}>
          Summary
        </Typography>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "gray3",
            p: 5,
          }}
        >
          <AssetClassAnalysisSectorAnalysisTable
            label1={label1}
            label2={label2}
            items={sectorSummary.report.level1}
            isExpandable={false}
          />
        </Box>

        <Box sx={{ mt: 7.5, display: "flex", alignItems: "flex-start" }}>
          <CarSelectField
            label={
              <>
                Sector Focus
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                  className={pendoClasses.editPortfolioAnalyzeSectorFocus}
                />
              </>
            }
            sx={{
              width: 200,
              ".MuiInputBase-input": {
                backgroundColor: "white",
              },
            }}
            value={sectorFocusId}
            onChange={setSectorFocusId}
          >
            {sectorSummary.report.level1.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </CarSelectField>
          <Box sx={{ mt: 9, ml: "auto", mb: 0.5, display: "flex", gap: 2 }}>
            {legendItems.map((i) => (
              <SectorAnalysisLegendItem
                key={i.level}
                level={i.level}
                label={i.label}
                isHighlighted={highlightLevel === i.level}
                onHighlightChange={(value) =>
                  setHighlightLevel(value ? i.level : undefined)
                }
              />
            ))}
          </Box>
        </Box>
        {sectorFocusItem && (
          <>
            <Typography variant="h6SemiBold" sx={{ mt: 3 }}>
              {sectorFocusItem?.name}
            </Typography>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "gray3",
                p: 5,
                pt: 3.5,
              }}
            >
              <CarPageContent
                content={pageContent.getContent(
                  CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SECTOR_ANALYSIS_GICS_DETAIL,
                )}
              />
              <AssetClassAnalysisSectorAnalysisTable
                sx={{ mt: 4 }}
                label1={label1}
                label2={label2}
                items={sectorFocusItem.children}
                isExpandable
                showTotal
                highlightLevel={highlightLevel}
              />
            </Box>
          </>
        )}
      </TablePlaceHolder>
    </AnalyzeModuleStyledPage>
  );
};
