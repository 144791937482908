import { Clear } from "@mui/icons-material";
import { Box, BoxProps, Chip, Typography, styled } from "@mui/material";
import { isDefined } from "utils";

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: theme.palette.caravelOrangePrimary,
  height: 22,
  "&:hover": {
    backgroundColor: theme.palette.gray1,
  },
  "&.Mui-disabled": {
    opacity: 0.5,
  },
  ".MuiChip-label": {
    ...theme.typography.par01Regular,
    fontSize: "12px",
    color: theme.palette.caravelOrangePrimary,
  },
  ".MuiChip-deleteIcon": {
    marginLeft: theme.spacing(-0.5),
    marginRight: theme.spacing(0.5),
    width: 16,
    height: 16,
    color: theme.palette.caravelOrangePrimary,
    opacity: 0.7,
    "&:hover": {
      color: theme.palette.caravelOrangePrimary,
      opacity: 1,
    },
  },
  "&.chip-unchecked": {
    borderColor: theme.palette.gray6,
    ".MuiChip-label": {
      color: theme.palette.gray6,
    },
    ".MuiChip-deleteIcon": {
      color: theme.palette.gray6,
      "&:hover": {
        color: theme.palette.gray6,
      },
    },
  },
}));

export interface CarChipItem {
  value: string;
  label: string;
  isChecked?: boolean;
  isDisabled?: boolean;
}
export interface CarChipGroupProps extends Omit<BoxProps, "onClick"> {
  items: Array<CarChipItem>;
  label?: string;
  isReadOnly?: boolean;
  onClick?: (value: string) => void;
  onDelete?: (value: string) => void;
  direction?: "row" | "column";
}

export const CarChipGroup = ({
  items,
  label,
  onDelete,
  onClick,
  isReadOnly,
  sx,
  direction = "row",
  ...props
}: CarChipGroupProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        ...sx,
      }}
      {...props}
    >
      {label && <Typography variant="par02Regular">{label}</Typography>}
      <Box
        sx={{
          display: "flex",
          flexDirection: direction,
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {items.map((i) => (
          <StyledChip
            key={i.value}
            label={i.label}
            onDelete={
              onDelete && !i.isDisabled && !isReadOnly
                ? () => onDelete(i.value)
                : undefined
            }
            onClick={
              onClick && !i.isDisabled && !isReadOnly
                ? () => onClick(i.value)
                : undefined
            }
            disabled={i.isDisabled}
            className={
              isDefined(i.isChecked) && !i.isChecked
                ? "chip-unchecked"
                : undefined
            }
            color="secondary"
            deleteIcon={<Clear />}
          />
        ))}
      </Box>
    </Box>
  );
};
