import { Menu } from "@mui/icons-material";
import { IconButton, IconButtonProps, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { openDrawer } from "app/drawerSlice";

export const CarBurgerMenuButton = styled((props: IconButtonProps) => {
  const dispatch = useDispatch();
  return (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(openDrawer());
      }}
      {...props}
    >
      <Menu fontSize="large" />
    </IconButton>
  );
})(({ theme }) => ({
  color: theme.palette.white,
  [theme.breakpoints.up("xl")]: {
    display: "none",
  },
}));
