import { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { clearAuth } from "features/auth/authSlice";
import { showToast } from "app/toastSlice";

const ignoreList = [
  "questionnaireExternalBlackDiamondConnectionCreate",
  "alEngineRenderPlanResultJobStatusList",
];

const loginList = [
  "commonAuthLoginCreate",
  "commonAuthTwoFactorLoginUpdate",
  "commonAuthVerifyEmailUpdate",
  "commonAuthVerifyEmailPartialUpdate",
];

export const errorMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (action.meta?.requestStatus === "rejected" && !action.meta?.condition) {
      let message = action.payload?.data?.detail;

      if (!message) {
        message = action.payload?.data?.token; // verify email error
      }

      if (!message) {
        message = action.payload?.data;
      }

      if (Array.isArray(message)) {
        message = message[0];
      }

      if (message instanceof Object) {
        if ("__all__" in message) {
          message = message["__all__"]?.[0];
        } else {
          message = Object.keys(message)
            .map((key) => {
              let value = message[key];

              if (Array.isArray(value)) {
                value = value[0];
              }

              if (typeof value !== "string") {
                value = JSON.stringify(value);
              }

              return `${key}: ${value}`;
            })
            .join("\n");
        }
      }

      if (!message) {
        message = action.payload?.error;
      }

      if (!message) {
        message = action.error.message;
      }
      console.error("API Error", message, action);

      switch (action.payload?.originalStatus ?? action.payload?.status) {
        case 401:
          if (loginList.includes(action?.meta?.arg?.endpointName)) {
            api.dispatch(
              showToast({
                kind: "errorMessage",
                title: "Error",
                message,
              })
            );
          } else {
            api.dispatch(clearAuth());
          }
          break;
        case 500:
          api.dispatch(
            showToast({
              kind: "errorMessage",
              title: "Error",
              message:
                "An error has occurred. Please contact Caravel Concepts for support.",
            })
          );
          break;
        default:
          if (!ignoreList.includes(action?.meta?.arg?.endpointName)) {
            api.dispatch(
              showToast({
                kind: "error",
                message,
              })
            );
          }
          break;
      }
    }

    return next(action);
  };
