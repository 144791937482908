import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCommonAuthResetPasswordCreateMutation } from "api/carApi.generated";
import { debugLog } from "utils";
import { showToast } from "app/toastSlice";
import { useParams } from "react-router";
import { rootPages } from "route.const";
import { setAuthSession } from "features/auth/authSlice";

export interface CarResetPasswordForm {
  requiredMode?: boolean;
  password?: string;
  confirmPassword?: string;
}

export const useResetPassword = () => {
  const navigate = useNavigate();

  const { resetPasswordToken } = useParams();

  const dispatch = useDispatch();

  const [resetPasswordReq] = useCommonAuthResetPasswordCreateMutation();

  const [resetPasswordForm, setResetPasswordForm] =
    useState<CarResetPasswordForm>({});

  const resetPassword = async () => {
    if (!resetPasswordToken) {
      return;
    }

    if (
      [
        resetPasswordForm.password?.trim(),
        resetPasswordForm.confirmPassword?.trim(),
      ].some((i) => !i)
    ) {
      setResetPasswordForm((value) => ({ ...value, requiredMode: true }));
      dispatch(
        showToast({
          kind: "error",
          message: "Please complete all fields",
        })
      );

      return;
    }
    if (
      resetPasswordForm.password?.trim() !==
      resetPasswordForm.confirmPassword?.trim()
    ) {
      dispatch(
        showToast({
          kind: "error",
          message: "Passwords do not match",
        })
      );

      return;
    }

    const response = await resetPasswordReq({
      resetPassword: {
        token: resetPasswordToken,
        password: resetPasswordForm.password?.trim() ?? "",
      },
    });

    if ("data" in response) {
      debugLog(
        "resetPassword response",
        JSON.stringify(response.data, null, 5)
      );
      dispatch(setAuthSession(response.data));

      navigate(rootPages.home);
    }
  };

  return {
    resetPasswordForm,
    setResetPasswordForm,
    resetPassword,
  };
};
