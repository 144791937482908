import { differenceInYears, parseISO } from "date-fns";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { api } from "api/carApi.manual";
import { useMount } from "app/useMount";
import { useRender } from "app/useRender";
import { CarGoalStatusEnum } from "types";
import { debugLog, isDefined, priorityNumToString } from "utils";
import { useIsInflated } from "app/usePlanId";

interface StressTaskItem {
  status: CarGoalStatusEnum;
  amountFunded: number;
  percentFunded: number;
}
export interface StressTestItem {
  id: string;
  priority: number;
  goalName: string;
  years: number;
  annualAmount: number;
  totalAmount: number;
  stressTasks: Array<StressTaskItem>;
}
export interface StressTest {
  taskLabels: Array<[string, string]>;
  surplusDeficits: Array<number>;
  items: Array<StressTestItem>;
}

export const useStressTest = () => {
  const { getInflatedValue } = useIsInflated();
  const scenarioList = api.useAlEngineStressTestScenarioTypeListQuery();
  const [fetchStress, { data, isLoading: stressIsLoading }] =
    api.useLazyAlEnginePlanResultStressTestContainerRetrieveQuery({});

  const { planResult, isLoading: renderIsLoading } = useRender();

  useEffect(() => {
    if (planResult?.id) {
      fetchStress({
        id: planResult.id,
      });
    }
  }, [fetchStress, planResult]);

  const dispatch = useDispatch();

  useMount(() => {
    return () => {
      debugLog("invalidate AlEngineRender");
      dispatch(api.util.invalidateTags(["AlEngineRender"]));
    };
  });

  const scenarios =
    scenarioList.data
      ?.filter(
        (i) =>
          data?.stress_tests.some((st) => st.stress_scenario_type === i.id),
      )
      .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0)) ?? [];

  const sortedTests = scenarios
    .map(
      (i) => data?.stress_tests.find((st) => st.stress_scenario_type === i.id),
    )
    .filter(isDefined);

  const getTasks = (goalId?: string) =>
    sortedTests.map<StressTaskItem>((i) => {
      const goal = i.goal_data.find((g) => g.base_goal_id === goalId);
      if (!goal) {
        return {
          amountFunded: 0,
          percentFunded: 0,
          status: "failure",
        };
      }

      const inputTotalAmount = getInflatedValue(
        goal.input_total_amount_without_inflation, // todo !!! replace to with_inflation
        goal.input_total_amount_without_inflation,
      );

      const amountRemaining = getInflatedValue(
        goal.amount_remaining_with_inflation,
        goal.amount_remaining_without_inflation,
      );

      const amountFunded = inputTotalAmount - amountRemaining;
      const percentFunded = inputTotalAmount
        ? amountFunded / inputTotalAmount
        : 0;

      return {
        amountFunded,
        percentFunded,
        status: goal.goal_status,
      };
    });

  const items: StressTestItem[] =
    sortedTests[0]?.goal_data.map<StressTestItem>((i) => ({
      id: i.base_goal_id,
      priority: i.base_goal_priority,
      goalName: `${i.base_goal_name} (${
        priorityNumToString(i.base_goal_priority)[0]
      })`,
      years: differenceInYears(
        parseISO(i.base_goal_end_date),
        parseISO(i.base_goal_start_date),
      ),
      annualAmount: getInflatedValue(
        i.input_annual_amount_without_inflation, // todo !!! replace to with_inflation
        i.input_annual_amount_without_inflation,
      ),
      totalAmount: getInflatedValue(
        i.input_total_amount_without_inflation, // todo !!! replace to with_inflation
        i.input_total_amount_without_inflation,
      ),
      stressTasks: getTasks(i.base_goal_id),
    })) ?? [];

  const stressTest: StressTest = {
    taskLabels: scenarios.map((i) => [i.label, i.code]),
    surplusDeficits: sortedTests.map((i) => i.surplus_deficit_total ?? 0),
    items,
  };

  const stressTasks = sortedTests.map((i) => ({
    value: i.stress_scenario_type,
    label:
      scenarios.find((sc) => sc.id === i.stress_scenario_type)?.label ?? "",
    projection: i.stress_test_data,
  }));

  return {
    stressTest,
    refetch: () => {
      if (planResult?.id) {
        fetchStress({
          id: planResult.id,
        });
      }
    },
    isCalculating: false, // todo stressData?.success === false,
    isLoading: renderIsLoading || stressIsLoading || scenarioList.isLoading,
    stressTasks,
  };
};

export type UseStressTest = ReturnType<typeof useStressTest>;
