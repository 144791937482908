import { Box, Typography } from "@mui/material";
import { FullScreenBackground } from "components/FullScreenBackground";
import { CarFooter } from "components/Footer";
import {
  LoginButton,
  LoginField,
  LoginLogo,
  LoginTransparentBox,
} from "pages/login/LoginComponents";
import { useAcceptInvitation } from "./useAcceptInvitation";

export const AcceptInvitationPage = () => {
  const data = useAcceptInvitation();

  const handleRegisterKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (
    e,
  ) => {
    if (e.key === "Enter") {
      data.register();
    }
  };

  return (
    <FullScreenBackground
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
        }}
      >
        <LoginTransparentBox
          sx={{
            width: 531,
            // height: 618
          }}
        >
          <LoginLogo sx={{ mt: -2 }} />
          <Typography variant="h1SSemiBold" sx={{ mt: -1 }}>
            Register
          </Typography>
          <Box sx={{ mt: 5, display: "flex", flexDirection: "column", gap: 2 }}>
            <LoginField
              placeholder="First Name"
              value={data.registerForm.firstName}
              onChange={(firstName) =>
                data.setRegisterForm((value) => ({ ...value, firstName }))
              }
              required={data.registerForm.requiredMode}
              inputProps={{
                type: "text",
                autoComplete: "new-user-first-name",
              }}
              onKeyDown={handleRegisterKeyDown}
            />
            <LoginField
              placeholder="Last Name"
              value={data.registerForm.lastName}
              onChange={(lastName) =>
                data.setRegisterForm((value) => ({ ...value, lastName }))
              }
              required={data.registerForm.requiredMode}
              inputProps={{
                type: "text",
                autoComplete: "new-user-last-name",
              }}
              onKeyDown={handleRegisterKeyDown}
            />

            <LoginField
              placeholder="Password"
              value={data.registerForm.password}
              onChange={(password) =>
                data.setRegisterForm((value) => ({ ...value, password }))
              }
              required={data.registerForm.requiredMode}
              inputProps={{
                type: "password",
                autoComplete: "new-password",
                spellCheck: false,
                autoCapitalize: "off",
              }}
              onKeyDown={handleRegisterKeyDown}
            />
            <LoginField
              placeholder="Confirm Password"
              value={data.registerForm.confirmPassword}
              onChange={(confirmPassword) =>
                data.setRegisterForm((value) => ({
                  ...value,
                  confirmPassword,
                }))
              }
              required={data.registerForm.requiredMode}
              inputProps={{
                type: "password",
                autoComplete: "new-password",
                spellCheck: false,
                autoCapitalize: "off",
              }}
              onKeyDown={handleRegisterKeyDown}
            />
          </Box>
          <LoginButton
            sx={{
              mt: 5.5,
              mb: -2,
              alignSelf: "center",
              width: 200,
            }}
            onClick={data.register}
          >
            Join Now
          </LoginButton>
        </LoginTransparentBox>
        <CarFooter
          sx={{
            mt: 2.5,
            alignSelf: "center",
            ".copyright": {
              fontSize: "12px",
            },
          }}
          isWhite
          isCopyrightOnly
          hasPrivacyPolicy
        />
      </Box>
    </FullScreenBackground>
  );
};
