import {
  useQuestionnairePlanAccountsListQuery,
  useQuestionnairePlanSavingsThisYearRetrieveQuery,
  useQuestionnairePlanAccountsCreateMutation,
  useQuestionnairePlanAccountsDestroyMutation,
  useQuestionnairePlanAccountsUpdateMutation,
} from "api/carApi.generated";
import {
  CarAccount,
  CarPosition,
  CarRequiredAccount,
  CarSavings,
  CarSimplePosition,
} from "types";
import { AccountType, isDebug, refetchOnFocus } from "const";
import { usePendingProxy } from "../general/usePendingProxy";
import { debugLog, isDefined } from "utils";
import {
  isSummaryAccount,
  isPercentAccount,
  convertToPercentAccount,
  convertToDollarAccount,
  summaryPercentageValid,
  PercentageValidationResult,
  isIntegratorAccount,
} from "./account.utils";
import { AssetClassLoadType, useAssetClasses } from "./useAssetClasses";
import { usePlanId } from "app/usePlanId";
import { useAccountTypes } from "app/useAccountTypes";
import { useRelationshipLight } from "app/useRelationshipLight";

const getRequiredFields = (isTaxable: boolean, item: CarAccount) => {
  let account: Array<keyof CarAccount> = ["description", "account_type"];
  const savings: Array<keyof CarSavings> = [
    "start_date_caravel",
    "end_date_caravel",
    "base_contribution",
  ];
  let position: Array<keyof CarPosition> = [];
  let simplePosition: Array<keyof CarSimplePosition> = [];

  if (!isIntegratorAccount(item)) {
    account = [...account, "relationship_type"];
    position = [
      ...position,
      "symbol",
      "description",
      "shares",
      "market_value",
      "firm_asset_class_id",
    ];
    simplePosition = [...simplePosition, "firm_asset_class_id"];

    if (isPercentAccount(item)) {
      account = [...account, "manual_percent_total_market_value"];
      simplePosition = [...simplePosition, "percentage"];
    } else {
      simplePosition = [...simplePosition, "market_value"];
    }

    if (isTaxable) {
      position = [...position, "cost_basis"];
    }
  }
  return {
    account,
    position,
    simplePosition,
    savings,
  };
};

export const useAccount = () => {
  const planId = usePlanId();
  const { isAccountTaxable } = useAccountTypes();
  const { data, isLoading } = useQuestionnairePlanAccountsListQuery(
    { planId },
    { refetchOnFocus },
  );

  const { data: annualSavings } =
    useQuestionnairePlanSavingsThisYearRetrieveQuery({
      id: planId,
    });

  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.allFirm,
  });

  const relationship = useRelationshipLight();

  const [createRel] = useQuestionnairePlanAccountsCreateMutation();
  const [updateRel] = useQuestionnairePlanAccountsUpdateMutation();
  const [deleteRel] = useQuestionnairePlanAccountsDestroyMutation();

  const handleCreate = (item: CarAccount) =>
    createRel({
      planId,
      account: item as CarRequiredAccount,
    });

  const handleChange = (item: CarAccount) =>
    updateRel({
      planId,
      id: item.id ?? "",
      account: item as CarRequiredAccount,
    });

  const handleDelete = (item: CarAccount) =>
    deleteRel({ planId, id: item.id ?? "" });

  const { items, ...proxy } = usePendingProxy<CarAccount>({
    name: "account",
    items: data ?? [],
    handleCreate,
    handleChange,
    handleDelete,
    normalizeItem: (item: CarAccount, oldItem) => {
      if (!isIntegratorAccount(item)) {
        if (item.account_ui_input_type === "manual_detailed_holdings") {
          item.account_input_type = AccountType.ManualDetailed;
          item.simple_holdings = [];
        } else {
          item.detailed_holdings = [];
          if (
            ![AccountType.ManualDollar, AccountType.ManualPercent].some(
              (i) => i === item.account_input_type,
            )
          ) {
            item.account_input_type = AccountType.ManualPercent;
          }

          if (item.account_ui_input_type === "manual_summary_simple") {
            // filter out advanced items
            item.simple_holdings =
              item.simple_holdings?.filter((i) =>
                assetClasses.simpleItems.some(
                  (ac) => ac.firmAssetClassId === i.firm_asset_class_id,
                ),
              ) ?? [];
          }

          // filter out zero items
          const isPercentAccountMode = isPercentAccount(item);
          item.simple_holdings =
            item.simple_holdings?.filter((i) =>
              isPercentAccountMode ? !!i.percentage : !!i.market_value,
            ) ?? [];
        }
      }

      // Server may return employer_match: null. should clear nulls for this field before an update
      if (item.is_saving) {
        item.savings = item.savings?.map(({ employer_match, ...rest }) => ({
          ...rest,
          employer_match: employer_match ?? undefined,
        }));
      } else {
        item.savings = [];
      }

      item.is_saving = (item.savings?.length ?? 0) > 0;

      if (!item.manual_percent_total_market_value) {
        item.manual_percent_total_market_value = null;
      }

      if (
        isSummaryAccount(item) &&
        oldItem &&
        item.account_input_type !== oldItem.account_input_type
      ) {
        if (isPercentAccount(item)) {
          convertToPercentAccount(item);
        } else {
          convertToDollarAccount(item);
        }
      }
    },
    validate: (item) => {
      const required = getRequiredFields(isAccountTaxable(item), item);

      const accountValid = (item: CarAccount) =>
        required.account.every((i) => !!item[i]);
      const positionValid = (item: CarPosition) =>
        required.position.every((i) => !!item[i]);
      const simplePositionValid = (item: CarSimplePosition) =>
        required.simplePosition.every((i) => isDefined(item[i]));
      const savingsValid = (item: CarSavings) => {
        return required.savings.every((i) => !!item[i]);
      };
      const percentageValid = (item: CarAccount) =>
        summaryPercentageValid(item) === PercentageValidationResult.Valid;

      if (isDebug) {
        const errors: string[] = [];
        if (!accountValid(item)) {
          errors.push(
            `Error in account [${required.account
              .filter((i) => !item[i])
              .join(",")}]`,
          );
        }
        //   `positionValid: ${
        //     item.detailed_holdings?.every(positionValid) ?? true
        //   }`,
        //   `simplePositionValid: ${
        //     item.simple_holdings?.every(simplePositionValid) ?? true
        //   }`,
        //   `savingsValid: ${item.savings?.every(savingsValid) ?? true}`,
        //   `percentageValid: ${percentageValid(item)}`
        // );

        if (errors) {
          debugLog("useAccount.validate", item, required);
          debugLog(...errors);
        }
      }

      return (
        accountValid(item) &&
        percentageValid(item) &&
        (item.detailed_holdings?.every(positionValid) ?? true) &&
        (item.simple_holdings?.every(simplePositionValid) ?? true) &&
        (item.savings?.every(savingsValid) ?? true)
      );
    },
  });
  const integrationItems = items.filter(
    (i) => i.account_input_type === AccountType.Integrated,
  );
  const manualItems = items.filter(
    (i) => i.account_input_type !== AccountType.Integrated,
  );

  const addRow = () =>
    proxy.handleCreate({
      client: "",
      account_ui_input_type: "manual_summary_simple",
      account_input_type: AccountType.ManualDollar,
      relationship_type: relationship.primaryItem?.id,
      savings: [],
      simple_holdings: [],
      detailed_holdings: [],
    });

  return {
    isLoading: isLoading || assetClasses.isLoading || relationship.isLoading,
    ...proxy,

    integrationItems,
    manualItems,
    addRow,
    annualSavings: {
      total: annualSavings?.savings_this_year_all_accounts ?? 0,
      integrated: annualSavings?.savings_this_year_integrated ?? 0,
      manual: annualSavings?.savings_this_year_manual ?? 0,
    },
  };
};
