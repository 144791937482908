import { Navigate, Route, Routes } from "react-router-dom";
import { assetAllocationPages } from "route.const";
import { PortfolioGroupDashboardPage } from "./portfolioGroupList/PortfolioGroupDashboardPage";
import { EditPortfolioGroupPage } from "./editPortfolioGroup/EditPortfolioGroupPage";
import { ViewPortfolioGroupPage } from "./viewPortfolioGroup/ViewPortfolioGroupPage";

export const AssetAllocationPage = () => {
  return (
    <Routes>
      <Route
        path={assetAllocationPages.viewPortfolioGroup}
        element={<ViewPortfolioGroupPage />}
      />
      <Route
        path={assetAllocationPages.editPortfolioGroup}
        element={<EditPortfolioGroupPage />}
      />
      <Route
        path={assetAllocationPages.dashboard}
        element={<PortfolioGroupDashboardPage />}
      />
      <Route
        path="*"
        element={<Navigate to={assetAllocationPages.dashboard} replace />}
      />
    </Routes>
  );
};
