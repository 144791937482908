import {
  Box,
  Button,
  LinearProgress,
  MenuItem,
  Typography,
} from "@mui/material";
import { useStressTest } from "features/stressTest/useStressTest";
import { useEffect, useState } from "react";
import { StressTestTable, StressTestTableLegend } from "./StressTestTable";
import { CarSelectField } from "components/Inputs";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useLocalStorage } from "features/general/useLocalStorage";
import { StressTestProjectionType, stressTestProjectionTypes } from "const";
import { useProjectionExport } from "features/stressTest/useProjectionExport";
import {
  CarDownload,
  DownloadExcel,
  DownloadCsv,
  DownloadExcelAll,
  DownloadAllCsv,
} from "icons";
import { CarDisclosure } from "components/Disclosure";
import {
  getMarketEnvironmentTable,
  getNetWorthTable,
  getPlanCashflowTable,
  getRealEstateTable,
  getTaxableIncomeAnalysisTable,
} from "features/stressTest/stressTest.utils";
import { StressTestProjectionTable } from "./StressTestProjectionTable";
import { CarMenuButton } from "components/MenuButton";
import { CarFlatPageControl } from "components/FlatPageControl";
import { useIsInflated } from "app/usePlanId";
import { WorkbenchInflationSmall } from "../WorkbenchInflation";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarTooltipBox } from "components/TooltipBox";
import { ExcelTable } from "app/useExcelExport";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";

export const StressTest = () => {
  const pageContent = usePageContent();
  const { isCalculating, refetch, ...data } = useStressTest();
  const { exportAllExcel, exportTable, exportAllCsv } = useProjectionExport();
  const { getInflatedValue } = useIsInflated();

  const [currentTask, setCurrentTask] = useState<string | undefined>();
  const [projectionType, setProjectionType] =
    useLocalStorage<StressTestProjectionType>(
      "StressTest-projectionType",
      StressTestProjectionType.MarketEnvironment,
    );

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (isCalculating) {
      intervalId = setInterval(refetch, 5000);
    } else {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = undefined;
      }
    }

    return () => intervalId && clearInterval(intervalId);
  }, [isCalculating, refetch]);

  useEffect(() => {
    if (!currentTask && data.stressTasks[0]?.value) {
      setCurrentTask(data.stressTasks[0].value);
    }
  }, [currentTask, data.stressTasks]);

  const projectionItems =
    data.stressTasks.find((i) => i.value === currentTask)?.projection ?? [];

  const handleExportAllExcel = () => {
    exportAllExcel(projectionItems);
  };

  const handleExportAllCsv = () => {
    exportAllCsv(projectionItems);
  };

  const handleExportExcel = () => {
    exportTable({
      items: projectionItems,
      type: projectionType,
      bookType: "xlsx",
    });
  };

  const handleExportCsv = () => {
    exportTable({
      items: projectionItems,
      type: projectionType,
      bookType: "csv",
    });
  };

  if (isCalculating) {
    return (
      <Box sx={{ p: 5, display: "flex", flexDirection: "column" }}>
        <Typography variant="h2">Stress Test in Progress</Typography>
        {/* <Typography sx={{ mt: 2 }}>{data.message}</Typography> */}
        <Typography
          variant="h6SSemiBold"
          sx={{ my: 2, mx: "auto", fontSize: 18 }}
        >
          Loading...
        </Typography>
        <LinearProgress
          sx={{ mx: "auto", width: 680, height: 12, borderRadius: 6 }}
          variant="indeterminate"
        />
      </Box>
    );
  }

  const getProjectionTable = (): ExcelTable => {
    switch (projectionType) {
      case StressTestProjectionType.MarketEnvironment:
        return getMarketEnvironmentTable(projectionItems, getInflatedValue);
      case StressTestProjectionType.NetWorth:
        return getNetWorthTable(projectionItems, getInflatedValue);
      case StressTestProjectionType.PlanCashflow:
        return getPlanCashflowTable(projectionItems, getInflatedValue);
      case StressTestProjectionType.TaxableIncomeAnalysis:
        return getTaxableIncomeAnalysisTable(projectionItems, getInflatedValue);
      case StressTestProjectionType.RealEstate:
        return getRealEstateTable(projectionItems, getInflatedValue);
    }
  };

  return (
    <TablePlaceHolder
      isLoading={pageContent.isLoading || data.isLoading}
      rows={10}
    >
      <CarPageContent
        isWhiteContext
        content={pageContent.getContent(
          CarPageContentCode.PLAN_WORKBENCH_STRESS_TEST_DIRECTIONS,
        )}
      />
      <Box
        sx={{
          mt: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography variant="h6SemiBold">Stress Scenarios</Typography>
          <CarTooltipBox
            sx={{ ml: 0.5, mt: 0.25 }}
            className={pendoClasses.workbenchStressTestStressScenarios}
          />
        </Box>
        <WorkbenchInflationSmall />
      </Box>
      <StressTestTableLegend sx={{ mt: 2, mb: 3 }} />
      <StressTestTable value={data.stressTest} />
      <CarDisclosure sx={{ ml: "auto", mr: 1, mt: 3 }}>
        *Amounts are represented in today's dollars (pre-inflation).
      </CarDisclosure>

      <Box sx={{ mt: 8, mb: 3, display: "flex" }}>
        <Typography variant="h6SemiBold">Stress Scenario Reports</Typography>
        <CarTooltipBox
          sx={{ ml: 0.5, mt: 0.25 }}
          className={pendoClasses.workbenchStressTestStressScenarios}
        />
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CarFlatPageControl
          value={projectionType}
          onChange={setProjectionType}
          items={stressTestProjectionTypes.map((i) => ({
            ...i,
            className: pendoClasses.stressScenarioReports(i.value),
          }))}
        />
        <CarMenuButton
          position="left"
          renderButton={(props) => (
            <Button
              startIcon={<CarDownload fontSize="large" />}
              variant="text"
              sx={{
                ml: "auto",
                svg: { color: "primary.main" },
                color: "softBlack",
                fontWeight: 600,
              }}
              {...props}
            >
              Download Reports
            </Button>
          )}
          items={[
            {
              icon: <DownloadCsv color="primary" />,
              label: "CSV - Export Current Table",
              onClick: handleExportCsv,
            },
            {
              icon: <DownloadAllCsv color="primary" />,
              label: "CSV - Export All",
              onClick: handleExportAllCsv,
            },
            {
              icon: <DownloadExcel color="primary" />,
              label: "Excel - Export Current Table",
              onClick: handleExportExcel,
            },
            {
              icon: <DownloadExcelAll color="primary" />,
              label: "Excel - Export All",
              onClick: handleExportAllExcel,
            },
          ]}
        />
      </Box>
      <Box
        sx={{
          border: "solid 1px",
          borderColor: "gray3",
          borderRadius: "5px",
          backgroundColor: "white",
          p: 4.25,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 5,
          }}
        >
          <CarSelectField
            sx={{ width: 220 }}
            value={currentTask}
            onChange={setCurrentTask}
          >
            {data.stressTasks.map((i) => (
              <MenuItem key={i.value} value={i.value}>
                {i.label}
              </MenuItem>
            ))}
          </CarSelectField>
          <WorkbenchInflationSmall />
        </Box>
        <StressTestProjectionTable content={getProjectionTable()} />
      </Box>
    </TablePlaceHolder>
  );
};
