import { BaseDialog } from "./BaseDialog";
import { Box, Typography } from "@mui/material";
import { useMount } from "app/useMount";
import { JobStatus } from "pages/assetAllocation/editPortfolioGroup/JobStatus";

export interface InProgressDialogProps {
  onApply: (value: boolean) => void;
  onCancel: () => void;
  message: string;
  run: () => Promise<unknown>;
}

export const InProgressDialog = (props: InProgressDialogProps) => {
  useMount(() => {
    props.run().then(() => props.onCancel());
  });

  return (
    <BaseDialog onClose={props.onCancel} hideCloseButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2.5,
        }}
      >
        <JobStatus
          sx={{
            width: 100,
            height: 100,
            "circle.percent": {
              stroke: "rgba(241, 150, 73, 1)",
            },
          }}
          completedCount={0}
          failedCount={0}
          jobCount={1}
          hideLabel
        />
        <Typography
          sx={{
            whiteSpace: "pre-line",
            textAlign: "center",
            lineHeight: "31px",
          }}
          variant="par03Regular"
        >
          {props.message}
        </Typography>
      </Box>
    </BaseDialog>
  );
};
