import { useMemo } from "react";
import {
  AssetResult,
  useAlEngineAlAssetTypeListQuery,
  useQuestionnairePlanAccountsListQuery,
} from "api/carApi.generated";
import { CarAllocAssetReport, useAllocAssets } from "app/useAllocAssets";
import { AssetTypeCode } from "const";
import { isDefined, priorityNumToString } from "utils";
import {
  AllocationBreakdownItem,
  AllocationChartItem,
} from "../householdAllocation/useHouseholdAllocation";
import { useIsInflated, usePlanId } from "app/usePlanId";
import { useRelationshipLight } from "app/useRelationshipLight";
import { getAnnualSavingsValue } from "features/assets/account.utils";

export interface CarAllocationAccountGoal {
  name: string;
  priority: string;
  goal_id: string;
  dollars: number;
  goal_status: string;
}

export interface CarAllocationAccount {
  id: string;
  name: string;
  isPortfolioModulesAvailable: boolean;
  type?: string;
  surplusAmount: number;
  currentAccountBalanceToFundGoals: Array<CarAllocationAccountGoal>;
  totalAmountToFundEachGoal: Array<CarAllocationAccountGoal>;
  multiModuleBreakdown: Array<AllocationBreakdownItem>;
  singleModuleBreakdown: Array<AllocationBreakdownItem> | undefined;
  allocAssets: CarAllocAssetReport;
}

export interface CarAllocationExtraAsset {
  id: string;
  name: string;
  surplusAmount: number;
  totalAmountToFundEachGoal: Array<CarAllocationAccountGoal>;
}

export interface CarAllocationSummaryItem {
  accountName: string;
  accountOwner: string;
  currentAmount?: number;
  lifetimeAmount?: number;
  annualSavings?: number;
  surplus?: number;
}

export const useAllocationByAccount = () => {
  const planId = usePlanId();
  const assetTypes = useAlEngineAlAssetTypeListQuery();

  const currentAssetId =
    assetTypes.data?.find((i) => i.code === AssetTypeCode.currentAsset)?.id ??
    "";

  const data = useAllocAssets();

  const { getInflatedValue } = useIsInflated();

  const relationship = useRelationshipLight();
  const accountsQuery = useQuestionnairePlanAccountsListQuery({ planId });

  return useMemo(() => {
    const goalResultIdToInfoMap = new Map<
      string,
      { name: string; status: string; priority: number }
    >();
    data.planResult?.goal_results.forEach((gr) => {
      gr.asset_results?.forEach((ar) => {
        if (ar.goal_result) {
          goalResultIdToInfoMap.set(ar.goal_result, {
            name: gr.base_goal_name,
            status: gr.goal_status,
            priority: gr.base_goal_priority,
          });
        }
      });
    });

    const getModuleName = (moduleId?: string) =>
      data.planResult?.modules.find((i) => i.id === moduleId)?.module_name ??
      "";

    const getAssetGoals = (
      assetResult: AssetResult,
      isAmountFunded: boolean,
    ): CarAllocationAccountGoal[] => {
      const goalResultIdToAmountMap = new Map<string, number>();

      assetResult.goal_results?.forEach((i) => {
        if (!i.goal_result) {
          return;
        }

        goalResultIdToAmountMap.set(
          i.goal_result,
          (goalResultIdToAmountMap.get(i.goal_result) ?? 0) +
            (isAmountFunded
              ? getInflatedValue(
                  i.goal_amount_funded_with_inflation,
                  i.goal_amount_funded_without_inflation,
                )
              : i.current_asset_amount_contributed ?? 0),
        );
      });

      return (
        Array.from(goalResultIdToAmountMap.entries())
          .filter((i) => Math.round(i[1]))
          .map<CarAllocationAccountGoal>((i) => ({
            goal_id: i[0],
            name: goalResultIdToInfoMap.get(i[0])?.name ?? "",
            priority: priorityNumToString(
              goalResultIdToInfoMap.get(i[0])?.priority ?? 0,
            ),
            goal_status: goalResultIdToInfoMap.get(i[0])?.status ?? "",
            dollars: i[1],
          })) ?? []
      );
    };

    const extraAssets =
      data.planResult?.asset_results
        .filter((ar) => ar.base_asset_type !== currentAssetId)
        .map<CarAllocationExtraAsset>((ar) => {
          return {
            id: ar.base_asset_id,
            name: ar.pii_base_asset_name,
            type: ar.base_asset_type,
            totalAmountToFundEachGoal: getAssetGoals(ar, true), // https://app.clickup.com/t/2e5qmam
            surplusAmount: getInflatedValue(
              ar.surplus_amount,
              ar.surplus_amount_deflated,
            ),
          };
        }) ?? [];

    const accounts =
      data.planResult?.asset_results
        .filter((ar) => ar.base_asset_type === currentAssetId)
        .map<CarAllocationAccount>((ar) => {
          const allocAssets = data.calcAllocAssets(ar.base_asset_id);

          const totalModuleAmount =
            ar.allocation_by_module_results?.reduce(
              (acc, i) => acc + i.dollars_contributed,
              0,
            ) ?? 0;

          const multiModuleBreakdown: Array<AllocationBreakdownItem> =
            ar.allocation_by_module_results?.map<AllocationBreakdownItem>(
              (i) => ({
                moduleId: i.module_id,
                label: getModuleName(i.module_id),
                money: i.dollars_contributed,
                percentage: (i.dollars_contributed / totalModuleAmount) * 100,
                allocation: [],
              }),
            ) ?? [];

          multiModuleBreakdown.push({
            moduleId: "total",
            label: "Total",
            money: multiModuleBreakdown.reduce(
              (acc, i) => acc + (i.money ?? 0),
              0,
            ),
            percentage: multiModuleBreakdown.reduce(
              (acc, i) => acc + (i.percentage ?? 0),
              0,
            ),
            allocation: [],
          });

          multiModuleBreakdown.forEach((i, idx) => {
            i.allocation = allocAssets.level2.map<AllocationChartItem>((ac) => {
              return {
                label: ac.name,
                color: ac.color,
                angle: ac.years[idx]?.percentage,
              };
            });
          });

          const singleAlloc = ar.single_portfolio_allocation;

          const singleModuleBreakdown = singleAlloc
            ? multiModuleBreakdown.map<AllocationBreakdownItem>((i) =>
                i.moduleId === singleAlloc.module_id
                  ? i
                  : {
                      moduleId: i.moduleId,
                      label: i.label,
                      allocation: [],
                    },
              )
            : undefined;

          const result: CarAllocationAccount = {
            id: ar.base_asset_id,
            name: ar.pii_base_asset_name,
            type: ar.base_asset_type,
            isPortfolioModulesAvailable: ar.portfolio_modules_available,
            currentAccountBalanceToFundGoals: getAssetGoals(ar, false),
            totalAmountToFundEachGoal: getAssetGoals(ar, true),
            surplusAmount: getInflatedValue(
              ar.surplus_amount,
              ar.surplus_amount_deflated,
            ),
            multiModuleBreakdown,
            singleModuleBreakdown,
            allocAssets,
          };

          return result;
        }) ?? [];

    const accountsSummary: CarAllocationSummaryItem[] =
      data.planResult?.asset_results
        .filter((ar) => ar.base_asset_type === currentAssetId)
        .map<CarAllocationSummaryItem | undefined>((assetResult) => {
          const account = accountsQuery.data?.find(
            (ac) => ac.id === assetResult.base_asset_id,
          );

          if (!account) {
            return undefined;
          }

          return {
            accountName: account.description ?? "",
            accountOwner: relationship.getFirstName(account.relationship_type),
            currentAmount: assetResult.total_pv_amount,
            lifetimeAmount: assetResult.goal_results?.reduce(
              (acc, gr) =>
                acc +
                (gr.current_asset_amount_contributed_without_inflation ?? 0),
              0,
            ),
            annualSavings: account.savings?.reduce(
              (acc, i) => acc + getAnnualSavingsValue(i),
              0,
            ),
            surplus: assetResult.surplus_amount,
          };
        })
        .filter(isDefined) ?? [];

    return {
      isLoading:
        data.isLoading ||
        assetTypes.isLoading ||
        accountsQuery.isLoading ||
        relationship.isLoading,
      accounts,
      extraAssets,
      accountsSummary,
    };
  }, [
    data,
    currentAssetId,
    accountsQuery,
    relationship,
    assetTypes.isLoading,
    getInflatedValue,
  ]);
};

export type UseAllocationByAccount = ReturnType<typeof useAllocationByAccount>;
