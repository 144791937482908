import { Box, BoxProps, styled, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { Fragment } from "react";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { formatNumber, isOddEven } from "utils";
import {
  SimulationBehaviorFixedIncomeSpread,
  useAssetClassesSimulationBehaviorFixedIncomeSpreadListQuery,
  useAssetClassesSimulationBehaviorFixedIncomeSpreadUpdateMutation,
} from "api/carApi.generated";
import { CarNumberFieldDelayed } from "components/NumberField";
import { CarTextFieldDelayed } from "components/Inputs";

const StyledBox = styled(Box)(({ theme }) => ({
  border: "solid 1px",
  borderColor: theme.palette.gray6,
  borderRadius: "5px",
  backgroundColor: theme.palette.white,
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(5.5),
}));

enum BoundaryTypes {
  TYPE_UPPER = 0,
  TYPE_LOWER = 1,
}

interface BoundaryItem {
  year: number;
  upperValue?: number;
  lowerValue?: number;
}

const BoundaryTable = (props: { items: BoundaryItem[] }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", mt: 5 }}>
      <Typography variant="par02Regular" sx={{ mb: 1 }}>
        Spread Boundary Group
      </Typography>
      <StyledBox>
        <CarInfoTable sx={{ gridTemplateColumns: "0.5fr 1fr 1fr" }}>
          <CarInfoHeader text="" />
          <CarInfoHeader text="Lower Boundary" />
          <CarInfoHeader text="Upper Boundary" />
          {props.items.map((row, rowIdx) => (
            <Fragment key={rowIdx}>
              <CarInfoCell
                isOdd={isOddEven(rowIdx)}
                justifyContent="start"
                text={row.year.toString()}
              />
              <CarInfoCell
                isOdd={isOddEven(rowIdx)}
                text={
                  row.lowerValue
                    ? formatNumber(row.lowerValue, { decimalPlaces: 6 })
                    : "-"
                }
              />
              <CarInfoCell
                isOdd={isOddEven(rowIdx)}
                text={
                  row.upperValue
                    ? formatNumber(row.upperValue, { decimalPlaces: 6 })
                    : "-"
                }
              />
            </Fragment>
          ))}
        </CarInfoTable>
      </StyledBox>
    </Box>
  );
};

interface SimulationModelsBehaviorFixedIncomeSpreadProps {
  assetClassId: string;
  sx?: BoxProps["sx"];
}

export const SimulationModelsBehaviorFixedIncomeSpread = (
  props: SimulationModelsBehaviorFixedIncomeSpreadProps,
) => {
  const data = useAssetClassesSimulationBehaviorFixedIncomeSpreadListQuery({
    id: "", // todo fix in swagger, id is redundant parameter here
  });

  const [updateMut] =
    useAssetClassesSimulationBehaviorFixedIncomeSpreadUpdateMutation();

  const item = data.data?.find(
    (be) => be.assetclasslevel4_set?.includes(props.assetClassId),
  );

  const updateItem = (value: SimulationBehaviorFixedIncomeSpread) => {
    updateMut({
      id: value.id ?? "",
      simulationBehaviorFixedIncomeSpread: value,
    });
  };

  const getBoundaryItems = (): BoundaryItem[] => {
    const boundaries = item?.spread_boundary_group?.boundaries ?? [];

    const years = Array.from(new Set(boundaries.map((i) => i.year))).sort(
      (a, b) => a - b,
    );

    return years.map<BoundaryItem>((year) => ({
      year,
      lowerValue: boundaries.find(
        (i) => i.boundary_type === BoundaryTypes.TYPE_LOWER && i.year === year,
      )?.value,
      upperValue: boundaries.find(
        (i) => i.boundary_type === BoundaryTypes.TYPE_UPPER && i.year === year,
      )?.value,
    }));
  };

  const boundaryItems = getBoundaryItems();

  return (
    <Box sx={{ ...props.sx, display: "flex", flexDirection: "column" }}>
      <TablePlaceHolder isLoading={data.isLoading} rows={8}>
        {item && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "180px 180px",
              columnGap: 11,
              rowGap: 5,
              ".MuiInputBase-root": {
                backgroundColor: "white",
              },
            }}
          >
            <CarNumberFieldDelayed
              label="Model Slope"
              decimalPlaces={10}
              value={item.mu_slope}
              onChange={(mu_slope) =>
                updateItem({
                  ...item,
                  mu_slope: mu_slope ?? 0,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Model Intercept"
              decimalPlaces={10}
              value={item.mu_intercept}
              onChange={(mu_intercept) =>
                updateItem({ ...item, mu_intercept: mu_intercept ?? 0 })
              }
            />
            <CarNumberFieldDelayed
              label="Absolute Lower Boundary"
              decimalPlaces={2}
              value={item.absolute_lower_boundary}
              onChange={(absolute_lower_boundary) =>
                updateItem({
                  ...item,
                  absolute_lower_boundary: absolute_lower_boundary ?? 0,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Absolute Upper Boundary"
              decimalPlaces={10}
              value={item.absolute_upper_boundary}
              onChange={(absolute_upper_boundary) =>
                updateItem({
                  ...item,
                  absolute_upper_boundary: absolute_upper_boundary ?? 0,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Model Slope Override"
              decimalPlaces={10}
              value={item.model_slope_override}
              onChange={(model_slope_override) =>
                updateItem({
                  ...item,
                  model_slope_override: model_slope_override ?? 0,
                })
              }
            />
            <div />
            <CarTextFieldDelayed
              label="Model Slope Override Rational"
              sx={{ gridColumn: "1 / span 2" }}
              fullWidth
              multiline
              rows={5}
              value={item.model_slope_override_rationale}
              onChange={(model_slope_override_rationale) =>
                updateItem({
                  ...item,
                  model_slope_override_rationale:
                    model_slope_override_rationale,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Crisis Mode Returns"
              decimalPlaces={10}
              value={item.crisis_mode_returns}
              onChange={(crisis_mode_returns) =>
                updateItem({ ...item, crisis_mode_returns })
              }
            />
          </Box>
        )}
        {boundaryItems.length > 0 && <BoundaryTable items={boundaryItems} />}
      </TablePlaceHolder>
    </Box>
  );
};
