import { useMemo } from "react";
import { useQuestionnairePlanOptimizedSocialSecurityRetrieveQuery } from "api/carApi.generated";
import { usePlanId } from "app/usePlanId";
import { refetchOnFocus } from "const";

export const useOptimizedSocialSecurity = () => {
  const planId = usePlanId();
  const optimized = useQuestionnairePlanOptimizedSocialSecurityRetrieveQuery(
    { id: planId },
    { refetchOnFocus }
  );

  return useMemo(() => {
    const inputAge = {
      primary: optimized.data?.primary?.benefit_age ?? 0,
      secondary: optimized.data?.secondary?.benefit_age ?? 0,
      spousal: optimized.data?.secondary?.spousal_benefit_age ?? 0,
      totalPrecola:
        (optimized.data?.primary?.total_pre_cola ?? 0) +
        (optimized.data?.secondary?.total_pre_cola ?? 0),
      total:
        (optimized.data?.primary?.Total ?? 0) +
        (optimized.data?.secondary?.Total ?? 0),
    };
    const recommendedAge = {
      primary: optimized.data?.primary?.optimal_benefit_age ?? 0,
      secondary: optimized.data?.secondary?.optimal_benefit_age ?? 0,
      spousal: optimized.data?.secondary?.optimal_spousal_benefit_age ?? 0,
      totalPrecola:
        (optimized.data?.primary?.optimial_total_pre_cola ?? 0) +
        (optimized.data?.secondary?.optimial_total_pre_cola ?? 0),
      total:
        (optimized.data?.primary?.optimal_total ?? 0) +
        (optimized.data?.secondary?.optimal_total ?? 0),
    };
    const benefitPreCola = {
      primary: optimized.data?.primary?.benefit_pre_cola_at_input_age ?? 0,
      secondary: optimized.data?.secondary?.benefit_pre_cola_at_input_age ?? 0,
      spousal: optimized.data?.secondary?.spousal_benefit ?? 0,
    };
    const optimalBenefitPreCola = {
      primary:
        optimized.data?.primary?.optimial_benefit_pre_cola_at_input_age ?? 0,
      secondary:
        optimized.data?.secondary?.optimial_benefit_pre_cola_at_input_age ?? 0,
      spousal: optimized.data?.secondary?.spousal_benefit ?? 0,
    };

    const annualBenefitAtRecommendedAge = {
      primary:
        optimized.data?.primary?.optimial_benefit_pre_cola_at_input_age ?? 0,
      secondary: optimized.data?.secondary?.optimal_individual_benefit ?? 0,
      spousal: optimized.data?.secondary?.optimal_spousal_benefit ?? 0,
    };

    const difference = {
      primary: recommendedAge.primary
        ? recommendedAge.primary - inputAge.primary
        : "N/A",
      secondary: recommendedAge.secondary
        ? recommendedAge.secondary - inputAge.secondary
        : "N/A",
      spousal: recommendedAge.spousal
        ? recommendedAge.spousal - inputAge.spousal
        : "N/A",
      totalPrecola: recommendedAge.totalPrecola
        ? recommendedAge.totalPrecola - inputAge.totalPrecola
        : 0,
      total:
        recommendedAge.total && inputAge.total
          ? recommendedAge.total - inputAge.total
          : "N/A",
    };

    const currentSpousalBenefit = optimized.data?.secondary
      ?.current_spousal_benefit
      ? Math.round(optimized.data?.secondary?.current_spousal_benefit)
      : 0;

    return {
      isLoading: optimized.isLoading,
      inputAge,
      recommendedAge,
      difference,
      currentSpousalBenefit,
      benefitPreCola,
      optimalBenefitPreCola,
      annualBenefitAtRecommendedAge,
    };
  }, [optimized.data, optimized.isLoading]);
};

export type CarOptimizedSocialSecurity = ReturnType<
  typeof useOptimizedSocialSecurity
>;
