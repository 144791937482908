import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { CarUser } from "types";

export const startSentry = () => {
  Sentry.init({
    dsn: "https://a1c2064398524e1bb59093f6f9543963@o332516.ingest.sentry.io/6007821",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
};

export const setSentryUser = (user: CarUser) => {
  Sentry.setUser({ id: user.id, email: user.email, firm_id: user.firm?.id });
};

export const clearSentryUser = () => {
  Sentry.setUser(null);
};

export const validatePercentile = (values: number[]): number[] => {
  if (!values.every((val, idx, arr) => val === arr[0])) {
    Sentry.captureMessage("Percentile are not equal");
  }

  return values;
};
