import {
  RootPage,
  RootPageHeader,
  RootPageTransparentPaper,
  RootPageHeaderLink,
} from "components/RootPage";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { EditPortfolioGroupAssetClassSelection } from "./EditPortfolioGroupAssetClassSelection";
import { EditPortfolioGroupEditCompare } from "./EditPortfolioGroupEditCompare";
import { EditPortfolioGroupPublish } from "./EditPortfolioGroupPublish";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { Navigate, Route, Routes } from "react-router-dom";
import { useOptimizationJobsPuller } from "./useOptimizationJobsPuller";
import { editPortfolioGroupPages } from "route.const";
import { EditPortfolioGroupCreateAllocations } from "./EditPortfolioGroupCreateAllocations";
import { EditPortfolioGroupSelection } from "./EditPortfolioGroupSelection";
import { EditPortfolioGroupAnalyze } from "./EditPortfolioGroupAnalyze";
import { EditPortfolioGroupOptimization } from "./EditPortfolioGroupOptimization";
import { EditPortfolioGroupOptimizer } from "./EditPortfolioGroupOptimizer";
import { SourceDataAsOf } from "../SourceDataAsOf";

export const EditPortfolioGroupPage = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;

  useOptimizationJobsPuller(portfolioGroupVersionId);

  return (
    <RootPage sx={{ backgroundColor: "white", paddingBottom: 0 }}>
      <RootPageHeader
        isSmall
        title={`Asset Allocation - ${groupInfo.title}`}
        isLoading={groupInfo.isLoading}
        links={
          groupInfo.isLoading ? undefined : (
            <>
              <RootPageHeaderLink
                label="Asset Class Selection"
                url={editPortfolioGroupPages.assetClassSelection}
              />
              <RootPageHeaderLink
                label="Create Allocations"
                url={editPortfolioGroupPages.createAllocations}
              />
              {groupInfo.isPublished && (
                <>
                  <RootPageHeaderLink
                    label="Optimization"
                    url={editPortfolioGroupPages.optimization}
                  />
                  <RootPageHeaderLink
                    label="Edit/Compare"
                    url={editPortfolioGroupPages.editCompare}
                  />
                  <RootPageHeaderLink
                    label="Analyze"
                    url={editPortfolioGroupPages.analyze}
                  />
                  <RootPageHeaderLink
                    label="Selection"
                    url={editPortfolioGroupPages.selection}
                  />
                  {/* <RootPageHeaderLink
                    label="Reports"
                    url={editPortfolioGroupPages.reportsUrl}
                    /> */}
                  <RootPageHeaderLink
                    label="Publish"
                    url={editPortfolioGroupPages.publish}
                  />
                </>
              )}
            </>
          )
        }
      />
      <RootPageTransparentPaper>
        <SourceDataAsOf />
        <Routes>
          <Route
            path={editPortfolioGroupPages.assetClassSelection}
            element={<EditPortfolioGroupAssetClassSelection />}
          />
          <Route
            path={editPortfolioGroupPages.createAllocations}
            element={<EditPortfolioGroupCreateAllocations />}
          />
          <Route
            path={editPortfolioGroupPages.optimization}
            element={<EditPortfolioGroupOptimization />}
          />
          <Route
            path={"optimization_old"}
            element={<EditPortfolioGroupOptimizer />}
          />
          <Route
            path={editPortfolioGroupPages.editCompare}
            element={<EditPortfolioGroupEditCompare />}
          />
          <Route
            path={editPortfolioGroupPages.analyze}
            element={<EditPortfolioGroupAnalyze />}
          />
          <Route
            path={editPortfolioGroupPages.selection}
            element={<EditPortfolioGroupSelection />}
          />
          {/* <Route
            path={editPortfolioGroupPages.reportsPath}
            element={<EditPortfolioGroupReports />}
          /> */}
          <Route
            path={editPortfolioGroupPages.publish}
            element={<EditPortfolioGroupPublish />}
          />
          <Route
            path="*"
            element={
              <Navigate
                to={editPortfolioGroupPages.assetClassSelection}
                replace
              />
            }
          />
        </Routes>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
