import { Box } from "@mui/system";
import {
  RootPage,
  RootPageHeader,
  RootPageHeaderLink,
  RootPageTransparentPaper,
  paperShiftProps,
} from "components/RootPage";
import { ClientSummaryPlans } from "./ClientSummaryPlans";
import { CarIconDelete, CarIconRename } from "icons";
import { useClientSummary } from "./useClientSummary";
import { CarMenuButton } from "components/MenuButton";
import { ClientSummaryProfile } from "./ClientSummaryProfile";
import { PlanParamsContextProvider, useClientId } from "app/usePlanId";
import {
  ClientSummaryTotalNetWorth,
  ClientSummaryTotalNetWorthPlaceholder,
} from "./ClientSummaryTotalNetWorth";
import {
  ClientSummaryPlanResults,
  ClientSummaryPlanResultsPlaceholder,
} from "./ClientSummaryPlanResults";
import { ClientSummaryNotes } from "./ClientSummaryNotes";
import { financialPlanningPages } from "route.const";
import { useClientUpdateLastReviewed } from "app/useClientUpdateLastReviewed";

export const ClientSummaryPage = () => {
  const clientSummary = useClientSummary();
  const clientId = useClientId();
  useClientUpdateLastReviewed();

  return (
    <RootPage>
      <RootPageHeader
        title={`The ${clientSummary.familyName} Family`}
        isLoading={clientSummary.isLoading}
        appTitle={clientSummary.clientFullName}
        titleActions={
          <CarMenuButton
            sx={{
              ml: 1,
              color: "white",
              svg: {
                fontSize: "28px",
              },
            }}
            items={[
              {
                label: "Rename",
                icon: <CarIconRename />,
                onClick: clientSummary.renameClient,
              },
              {
                label: "Delete",
                icon: <CarIconDelete />,
                onClick: clientSummary.deleteClient,
              },
            ]}
          />
        }
        links={
          <>
            <RootPageHeaderLink
              label="Client Summary"
              url={financialPlanningPages.getClientSummaryUrl(clientId)}
            />
            <RootPageHeaderLink
              label="Progress Monitoring"
              url={financialPlanningPages.getProgressMonitoringUrl(clientId)}
            />
          </>
        }
      />
      <RootPageTransparentPaper
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 7,
          justifyContent: "center",
          maxWidth: "100%",
          flex: "none",
          marginBottom: "auto",
        }}
      >
        <PlanParamsContextProvider value={clientSummary.publishedPlanParams}>
          <Box
            sx={{
              flexBasis: 325,
              flex: "none",
              width: 325,
              display: "flex",
              flexDirection: "column",
              gap: 3,
              ...paperShiftProps,
            }}
          >
            {clientSummary.publishedPlanParams ? (
              <ClientSummaryTotalNetWorth />
            ) : (
              <ClientSummaryTotalNetWorthPlaceholder
                isLoading={clientSummary.isLoading}
              />
            )}
            <ClientSummaryProfile clientSummary={clientSummary} />
            <ClientSummaryNotes clientSummary={clientSummary} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              width: 890,
              flex: "none",
              ...paperShiftProps,
            }}
          >
            {clientSummary.publishedPlanParams ? (
              <ClientSummaryPlanResults />
            ) : (
              <ClientSummaryPlanResultsPlaceholder
                isLoading={clientSummary.isLoading}
              />
            )}
            <ClientSummaryPlans clientSummary={clientSummary} />
          </Box>
        </PlanParamsContextProvider>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
