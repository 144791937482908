import { Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { SettingsList } from "./SettingsList";
import { useSettingsFirmUsers } from "./useSettingsFirmUsers";
import { SectionFirmUsers } from "./SectionFirmUsers";
import { SectionFirmProfile } from "./SectionFirmProfile";
import { useFirm } from "./useFirm";
import { useSettingsBilling } from "./useSettingsBilling";
import { SectionBilling } from "./SectionBilling";

export const SettingsFirmSettings = () => {
  const firm = useFirm();
  const firmUsers = useSettingsFirmUsers();
  const billing = useSettingsBilling();

  return (
    <TablePlaceHolder
      sx={{ mt: 5 }}
      isLoading={firm.isLoading || firmUsers.isLoading || billing.isLoading}
      rows={8}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Firm Settings
      </Typography>
      <SettingsList
        items={[
          {
            label: "Firm Profile",
            description:
              "Edit firm name and logo and allow for Caravel support.",
            content: <SectionFirmProfile data={firm} />,
          },
          {
            label: "Users",
            description: "Add, delete or assign new roles to your team.",
            content: <SectionFirmUsers data={firmUsers} />,
          },
          {
            label: "Billing",
            description:
              "Add or edit billing information and add additional seat license.",
            content: <SectionBilling data={billing} />,
          },
        ]}
      />
    </TablePlaceHolder>
  );
};
