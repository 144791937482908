import { useMemo } from "react";
import { useAssetClassesFirmAssetClassesListQuery } from "api/carApi.generated";
import { CarAssetClassHierarchy } from "types";
import { useRender } from "./useRender";

export interface CarAllocAssetYear {
  label: string;
  percentage: number;
  dollar: number;
}

export interface CarAllocAsset {
  parentId?: string;
  id: string;
  name: string;
  displayShortName: string;
  code: string;
  sort: number;
  color?: string;
  proposed_percent: number;
  current_percent: number;
  proposed_dollars: number;
  current_dollars: number;
  diff_percent: number;
  diff_dollars: number;
  children: Array<CarAllocAsset>;
  years: Array<CarAllocAssetYear>;
}

export type CarAllocAssetReport = {
  level1: CarAllocAsset[];
  level2: CarAllocAsset[];
  level3: CarAllocAsset[];
  level4: CarAllocAsset[];
};

export type UseAllocAssets = ReturnType<typeof useAllocAssets>;

const filterZeroValue = (value: CarAllocAsset) =>
  Math.round(value.current_dollars) ||
  Math.round(value.current_percent) ||
  Math.round(value.proposed_dollars) ||
  Math.round(value.proposed_percent) ||
  Math.round(value.diff_dollars) ||
  Math.round(value.diff_percent);

export const useAllocAssets = () => {
  const { data, isLoading } = useAssetClassesFirmAssetClassesListQuery();
  const { planResult, isLoading: renderIsLoading } = useRender();

  return useMemo(() => {
    const items = data ?? [];

    const colorMap = new Map<string | undefined, string | undefined>();
    data?.forEach((i) => {
      colorMap.set(
        i.asset_class?.ac_level3?.ac_level2?.id,
        i.asset_class?.ac_level3?.ac_level2?.color_code,
      );
    });

    const calcAllocAssets = (assetId?: string): CarAllocAssetReport => {
      let gtCurrentAmount = 0;
      let gtProposedAmount = 0;
      planResult?.asset_results.forEach((ar) => {
        if (assetId && ar.base_asset_id !== assetId) {
          return;
        }
        ar.allocation_by_asset_class_results?.forEach((item) => {
          gtCurrentAmount += item.current_dollars;
          gtProposedAmount += item.proposed_dollars;
        });
      });

      const getAssetClassTotal = (assetClassLevel4Id?: string) => {
        let acCurrentAmount = 0;
        let acProposedAmount = 0;

        planResult?.asset_results.forEach((ar) => {
          if (assetId && ar.base_asset_id !== assetId) {
            return;
          }

          const item = ar.allocation_by_asset_class_results?.find(
            (i) => i.asset_class_level4_id === assetClassLevel4Id,
          );

          if (item) {
            acCurrentAmount += item.current_dollars;
            acProposedAmount += item.proposed_dollars;
          }
        });

        const acCurrentPercent = (acCurrentAmount / gtCurrentAmount) * 100 || 0;
        const acProposedPercent =
          (acProposedAmount / gtProposedAmount) * 100 || 0;

        return {
          current_dollars: acCurrentAmount,
          proposed_dollars: acProposedAmount,
          diff_dollars: acProposedAmount - acCurrentAmount,
          current_percent: acCurrentPercent,
          proposed_percent: acProposedPercent,
          diff_percent: acProposedPercent - acCurrentPercent,
        };
      };

      function getAssetClassYears(
        assetClassLevel4Id?: string,
      ): Array<CarAllocAssetYear> {
        const grandTotalAmount =
          planResult?.modules.reduce(
            (acc, i) => acc + i.total_amount_invested,
            0,
          ) ?? 0;

        const result =
          planResult?.modules.map<CarAllocAssetYear>((m) => {
            const allocationItem = m.allocations?.find(
              (a) => a.asset_class_level4_id === assetClassLevel4Id,
            );

            return {
              label: m.module_name,
              dollar:
                m.total_amount_invested * (allocationItem?.percentage ?? 0),
              percentage: (allocationItem?.percentage ?? 0) * 100,
            };
          }) ?? [];

        const assetTotalAmount = result.reduce((acc, i) => acc + i.dollar, 0);

        result.push({
          label: "Total",
          dollar: assetTotalAmount,
          percentage: grandTotalAmount
            ? (assetTotalAmount / grandTotalAmount) * 100
            : 0,
        });

        return result;
      }

      const extractAssetItem = (
        items: Array<CarAssetClassHierarchy>,
        extractor: (i: CarAssetClassHierarchy) => CarAllocAsset | undefined,
      ) => {
        return items
          .reduce((acc, i) => {
            const item = extractor(i);
            return item ? [...acc.filter((v) => v.id !== item.id), item] : acc;
          }, [] as Array<CarAllocAsset>)
          .sort((a, b) => a.sort - b.sort);
      };

      const calcTotals = (items: Array<CarAllocAsset>) => {
        return items.reduce(
          (acc, i) => ({
            proposed_percent: acc.proposed_percent + i.proposed_percent,
            current_percent: acc.current_percent + i.current_percent,
            diff_percent: acc.diff_percent + i.diff_percent,
            proposed_dollars: acc.proposed_dollars + i.proposed_dollars,
            current_dollars: acc.current_dollars + i.current_dollars,
            diff_dollars: acc.diff_dollars + i.diff_dollars,
          }),
          {
            proposed_percent: 0,
            current_percent: 0,
            diff_percent: 0,
            proposed_dollars: 0,
            current_dollars: 0,
            diff_dollars: 0,
          },
        );
      };

      const calcYears = (items: Array<CarAllocAsset>) => {
        function sumYears(
          a: CarAllocAssetYear,
          b?: CarAllocAssetYear,
        ): CarAllocAssetYear {
          return {
            label: a.label,
            dollar: a.dollar + (b?.dollar ?? 0),
            percentage: a.percentage + (b?.percentage ?? 0),
          };
        }
        return items
          .map((i) => i.years)
          .reduce((acc, i) => {
            const [a, b] = acc.length > i.length ? [acc, i] : [i, acc];
            return a.map((yearItem, idx) => sumYears(yearItem, b[idx]));
          }, [] as Array<CarAllocAssetYear>);
      };

      const level4 = extractAssetItem(items, (i) => {
        const v = i.asset_class;

        if (!i.is_active || !v?.is_active) {
          return undefined;
        }

        return (
          v && {
            parentId: i.asset_class?.ac_level3?.id,
            id: v.id ?? "",
            name: v.display_name ?? "",
            displayShortName: v.display_name ?? "",
            code: v.code ?? "",
            sort: v.sort_order ?? 0,
            color: colorMap.get(v.id),
            children: [],
            ...getAssetClassTotal(v.id),
            years: getAssetClassYears(v.id),
          }
        );
      }).filter(filterZeroValue);

      const level3 = extractAssetItem(items, (i) => {
        const v = i.asset_class?.ac_level3;
        const children = level4.filter((i) => i.parentId === v?.id);
        return (
          v && {
            parentId: i.asset_class?.ac_level3?.ac_level2?.id,
            id: v.id ?? "",
            name: v.name ?? "",
            displayShortName: v.name ?? "",
            code: v.code ?? "",
            sort: v.sort_order ?? 0,
            color: colorMap.get(v.id),
            children,
            ...calcTotals(children),
            years: calcYears(children),
          }
        );
      }).filter((i) => i.children.length);

      const level2 = extractAssetItem(items, (i) => {
        const v = i.asset_class?.ac_level3?.ac_level2;
        const children = level3.filter((i) => i.parentId === v?.id);
        return (
          v && {
            parentId: i.asset_class?.ac_level3?.ac_level2?.ac_level1?.id,
            id: v.id ?? "",
            name: v.name ?? "",
            displayShortName: v.name ?? "",
            code: v.code ?? "",
            sort: v.sort_order ?? 0,
            color: colorMap.get(v.id),
            children,
            ...calcTotals(children),
            years: calcYears(children),
          }
        );
      }).filter((i) => i.children.length);

      const level1 = extractAssetItem(items, (i) => {
        const v = i.asset_class?.ac_level3?.ac_level2?.ac_level1;
        const children = level2.filter((i) => i.parentId === v?.id);
        return (
          v && {
            id: v.id ?? "",
            name: v.name ?? "",
            displayShortName: v.display_short_name ?? "",
            code: v.code ?? "",
            sort: v.sort_order ?? 0,
            color: colorMap.get(v.id),
            children,
            ...calcTotals(children),
            years: calcYears(children),
          }
        );
      }).filter((i) => i.children.length);

      return {
        level1,
        level2,
        level3,
        level4,
      };
    };

    return {
      isLoading: isLoading || renderIsLoading,
      planResult,
      calcAllocAssets,
    };
  }, [data, isLoading, planResult, renderIsLoading]);
};
