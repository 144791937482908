import clsx from "clsx";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { Box, BoxProps, ButtonBase, styled } from "@mui/material";
import { ReactElement, useState } from "react";
import { CarButton } from "./Buttons";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import { virtualize } from "react-swipeable-views-utils";
import { CarTooltipBox } from "./TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

// Extended version of % with negative integer support.
const mod = (n: number, m: number) => {
  const q = n % m;
  return q < 0 ? q + m : q;
};

const VirtualizeSwipeableViews = virtualize(SwipeableViews);

export interface CarCarouselPage {
  id: string;
  label: string;
  render: () => ReactElement;
}

export interface CarCarouselProps extends Omit<BoxProps, "children"> {
  isCompact?: boolean;
  showTabs?: boolean;
  isDisabled?: boolean;
  pages: CarCarouselPage[];
}

const StyledRoot = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  ".content-root": {
    overflow: "hidden",
    background: theme.palette.white,
    border: `1px solid ${theme.palette.gray3}`,
    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "15px",
  },
  ".content-page": {
    height: 581,
    display: "flex",
    justifyContent: "stretch",
    alignItems: "stretch",
  },
  "&.compact .content-page": {
    height: 482,
  },
  ".nav-left": {
    boxShadow: "4px 4px 10px 0px #00000026",
    position: "absolute",
    translate: "-50% -50%",
    left: 0,
    top: "50%",
  },
  ".nav-right": {
    boxShadow: "4px 4px 10px 0px #00000026",
    position: "absolute",
    translate: "-50% -50%",
    left: "100%",
    top: "50%",
  },
}));

const StyledButton = styled(CarButton)(({ theme }) => ({
  width: 70,
  height: 70,
  ".compact &": {
    width: 50,
    height: 50,
    minWidth: 50,
    minHeight: 50,
    svg: {
      fontSize: "18px",
    },
  },
  borderRadius: "50%",
  "&.Mui-disabled": {
    color: theme.palette.white,
    backgroundColor: theme.palette.gray6,
  },
}));

const StyledTabButton = styled(ButtonBase)(({ theme }) => ({
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  ...theme.typography.par02Regular,
  borderBottom: `solid 2px rgba(0, 0, 0, 0)`,
  "&.selected": {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
  },
}));

const StyledTabBox = styled(Box)(({ theme }) => ({
  width: "100%",
  zIndex: 0,
  position: "relative",
  display: "flex",
  justifyContent: "space-around",
  gap: theme.spacing(3),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
}));

export const CarCarousel = ({
  pages,
  showTabs,
  isCompact,
  isDisabled,
  ...props
}: CarCarouselProps) => {
  const [index, setIndex] = useState(0);
  const pageIndex = mod(index, pages.length);

  return (
    <StyledRoot {...props} className={clsx({ compact: isCompact })}>
      {showTabs && (
        <StyledTabBox>
          {pages.map((i, idx) => (
            <StyledTabButton
              key={idx}
              className={clsx({ selected: pageIndex === idx })}
              onClick={(e) => setIndex(idx)}
            >
              {i.label}
              <CarTooltipBox
                sx={{ position: "absolute", right: -4, top: 8 }}
                className={pendoClasses.workbenchPlanResultsCarousel(i.id)}
              />
            </StyledTabButton>
          ))}
        </StyledTabBox>
      )}
      <Box className="content-root">
        <VirtualizeSwipeableViews
          axis="x"
          index={index}
          onChangeIndex={setIndex}
          slideRenderer={({ index, key }) => (
            <Box key={key} className="content-page">
              {pages[mod(index, pages.length)]?.render()}
            </Box>
          )}
        />
      </Box>
      <StyledButton
        color="primary"
        className="nav-left"
        disabled={isDisabled}
        onClick={() => setIndex((i) => i - 1)}
      >
        <ArrowBackIosNew />
      </StyledButton>
      <StyledButton
        color="primary"
        disabled={isDisabled}
        className="nav-right"
        onClick={() => setIndex((i) => i + 1)}
      >
        <ArrowForwardIos />
      </StyledButton>
    </StyledRoot>
  );
};
