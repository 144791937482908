import { Box, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CarButton, CarDownloadButton } from "components/Buttons";
import { TablePlaceHolder } from "components/PlaceHolder";
import {
  RootPage,
  RootPageHeader,
  RootPageTransparentPaper,
} from "components/RootPage";
import { assetAllocationPages } from "route.const";
import { PortfolioGroupAssetAllocationTable } from "../PortfolioGroupAssetAllocationTable";
import { useViewPortfolioGroup } from "./useViewPortfolioGroup";
import { PortfolioGroupAssetStatTable } from "../PortfolioGroupAssetStatTable";
import { PortfolioGroupAssetStatChart } from "../PortfolioGroupAssetStatChart";
import { useStatAxisSelector } from "../editPortfolioGroup/useStatAxisSelector";
import { useExportAssetAllocation } from "../useExportAssetAllocation";
import { InternalUseWithDisclosures } from "../editPortfolioGroup/InternalUse";
// import { CarPageContentCode, usePageContent } from "app/usePageContent";
// import { CarPageContent } from "components/PageContent";
import { StatAxisSelector } from "../editPortfolioGroup/StatAxisSelector";
import {
  CarFlatPageControl,
  CarFlatPageItem,
} from "components/FlatPageControl";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarTooltipBox } from "components/TooltipBox";
import { CarSwitch } from "components/Switch";
import { useState } from "react";
import { AssetClassStatisticsTable } from "../editPortfolioGroup/AssetClassStatisticsTable";

const StyledPage = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingLeft: theme.spacing(4.25),
  paddingRight: theme.spacing(4.25),
  backgroundColor: theme.palette.gray1,
  borderRadius: "5px",
  borderColor: theme.palette.gray6,
  borderStyle: "solid",
  borderWidth: "1px",
}));

export const ViewPortfolioGroupPage = () => {
  const data = useViewPortfolioGroup();

  const statAxis = useStatAxisSelector();

  // const pageContent = usePageContent();

  const [currentTab, setCurrentTab] = useState<string | undefined>("assets");

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(assetAllocationPages.getPortfolioGroupsUrl());
  };

  const exportAssetAllocation = useExportAssetAllocation();

  const handleExport = () => {
    exportAssetAllocation({
      tableData: data.tableData,
    });
  };

  return (
    <RootPage sx={{ backgroundColor: "white", paddingBottom: 0 }}>
      <RootPageHeader
        isSmall
        title={`View - ${data.title}`}
        isLoading={data.isLoading}
      />
      <RootPageTransparentPaper>
        <TablePlaceHolder isLoading={data.isLoading} rows={8} sx={{ mt: 5 }}>
          <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
            View
          </Typography>
          {/* <CarPageContent
            sx={{ mt: 3 }}
            content={pageContent.getContent(
              CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_PUBLISHED_PORTFOLIOS, // todo replace with proper code
            )}
          /> */}
          <StatAxisSelector
            statAxisSelector={statAxis}
            sx={{ mt: 5 }}
            isWhiteContext
            items={data.statTableData.statGroups.flatMap((i) => i.rows)}
          />
          <Box
            sx={{
              mt: 5,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "gray1",
              border: "1px solid",
              borderColor: "gray3",
              p: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <InternalUseWithDisclosures sx={{ mr: 2 }} />
              <PortfolioGroupAssetStatChart
                statTableData={data.statTableData}
                {...statAxis}
              />
            </Box>
          </Box>
          <CarFlatPageControl
            sx={{
              mt: 7,
              ".flat-page-control-header": {
                ml: 3,
                gap: 3,
              },
              ".flat-page-control-button": {
                p: 1,
                fontSize: "18px",
              },
            }}
            items={[
              {
                label: "Assets",
                value: "assets",
                pendoClassName: pendoClasses.editPortfolioEditCompareTabAssets,
                content: (
                  <StyledPage>
                    <Box
                      sx={{
                        mt: -1,
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "flex-end",
                        gap: 5,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="par02Regular">
                          Show Input Only
                        </Typography>
                        <CarTooltipBox
                          sx={{ ml: 0.5 }}
                          className={
                            pendoClasses.editPortfolioPublishShowInputOnly
                          }
                        />
                        <CarSwitch
                          size="small"
                          checked={!data.isTreeView}
                          onChange={(e, value) => data.setIsTreeView(!value)}
                        />
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="par02Regular">
                          Hide Empty Values
                        </Typography>
                        <CarTooltipBox
                          sx={{ ml: 0.5 }}
                          className={
                            pendoClasses.editPortfolioPublishHideEmptyValues
                          }
                        />
                        <CarSwitch
                          size="small"
                          checked={data.hideZeroValues}
                          onChange={(e, value) => data.setHideZeroValues(value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        mb: 1,
                        gap: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      <CarDownloadButton onClick={handleExport}>
                        Download
                      </CarDownloadButton>
                    </Box>

                    <PortfolioGroupAssetAllocationTable
                      storageKey={data.portfolioGroupId}
                      tableData={data.tableData}
                      onAllocationChange={() => {}}
                      onModuleRename={() => Promise.resolve()}
                      onModuleDelete={() => {}}
                      onModuleMove={() => {}}
                      isReadOnly={true}
                      hideZeroValues={data.hideZeroValues}
                      isTreeView={data.isTreeView}
                    />
                  </StyledPage>
                ),
              },
              ...data.statTableData.statGroups
                .filter((i) => !i.isHistorical)
                .map<CarFlatPageItem<string>>((group) => ({
                  label: `${group.name}${
                    group.isHistorical ? "" : data.simulationSuffix
                  }`,
                  pendoClassName:
                    pendoClasses.analyzePortfolioGroupEditCompareTabStat(
                      group.code,
                    ),
                  value: group.code,
                  content: (
                    <StyledPage>
                      <PortfolioGroupAssetStatTable
                        tableData={data.tableData}
                        rows={group.rows}
                        showPercentile={group.code === "SIMULATION"}
                      />
                    </StyledPage>
                  ),
                })),
              {
                label: "Asset Class Statistics",
                value: "assetClassStatistics",
                pendoClassName:
                  pendoClasses.editPortfolioEditCompareTabAssetClassStatistics,
                content: (
                  <StyledPage>
                    <AssetClassStatisticsTable
                      simulationGroupName={data.simulationGroupName}
                      items={data.assetClassStatisticsItems}
                    />
                  </StyledPage>
                ),
              },
            ]}
            value={currentTab}
            onChange={setCurrentTab}
          />
          <Box
            sx={{ mt: 5, display: "flex", gap: 2.5, justifyContent: "center" }}
          >
            <CarButton variant="outlined" onClick={handleBack}>
              Back
            </CarButton>
          </Box>
        </TablePlaceHolder>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
