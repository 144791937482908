import ReactPDF, { PDFViewer } from "@react-pdf/renderer";
import { usePdfFontLoad as usePdfFontLoadOld } from "./Pdf";
import { usePdfFontLoad } from "../pdfUtils";
import { useTheme } from "@emotion/react";
import { Box, ThemeProvider, Typography } from "@mui/material";
// import { useEffect } from "react";

// //@ts-ignore
// import debugReport from "assets/debugReport.pdf";
// import { useLocalStorage } from "features/general/useLocalStorage";

interface CarPdfViewerProps {
  children?: React.ReactElement<ReactPDF.DocumentProps>;
  isLoading: boolean;
  hasRenderError?: boolean;
}

// interface PDFViewerProps {
//   title?: string;
//   width?: number | string;
//   height?: number | string;
//   style?: any;
//   className?: string;
//   children?: React.ReactElement<ReactPDF.DocumentProps>;
//   innerRef?: React.Ref<HTMLIFrameElement>;
//   showToolbar?: boolean;
// }

// const ZOOM = 75;
// const PAGE = 1;
// const DEBUG_PAGE = 23;
// const opacity = 0.6;

// const PDFViewer = ({
//   title,
//   style,
//   className,
//   children,
//   innerRef,
//   ...props
// }: PDFViewerProps) => {
//   const [instance, updateInstance] = usePDF({ document: children });

//   useEffect(() => {
//     updateInstance(children as any);
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [children]);

//   const src = instance.url
//     ? `${instance.url}#zoom=${ZOOM}&page=${PAGE}`
//     : undefined;

//   return <iframe src={src} title={title} style={style} {...props} />;
// };

export const CarPdfViewer = (props: CarPdfViewerProps) => {
  const pdfFontOld = usePdfFontLoadOld();
  const pdfFont = usePdfFontLoad();

  const theme = useTheme();

  const isLoading =
    pdfFontOld.isLoading || pdfFont.isLoading || props.isLoading;

  // const [showDebug, setShowDebug] = useLocalStorage("showDebug", true);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(255,255,254, 0.9)",
      }}
    >
      {props.hasRenderError ? (
        <Typography variant="h4SSemiBold">
          An error has occurred. Please contact Caravel Concepts for support.
        </Typography>
      ) : (
        <>
          <Typography variant="h4SemiBold">
            {isLoading ? "Loading Data..." : "Rendering Document..."}
          </Typography>
          {!isLoading && (
            <PDFViewer
              style={{ position: "absolute", borderWidth: 0 }}
              width={"100%"}
              height={"100%"}
            >
              <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
            </PDFViewer>
          )}
          {/* {showDebug && (
            <iframe
              src={`${debugReport}#zoom=${ZOOM}&page=${DEBUG_PAGE}`}
              title={"debug"}
              style={{
                position: "absolute",
                borderWidth: 0,
                opacity,
                pointerEvents: "none",
                userSelect: "none",
                zIndex: 10,
              }}
              width={"100%"}
              height={"100%"}
            />
          )}
          <Button
            sx={{ position: "absolute", zIndex: 1000, right: 130, top: 10 }}
            variant="contained"
            onClick={() => {
              setShowDebug(!showDebug);
            }}
          >
            Debug
          </Button> */}
        </>
      )}
    </Box>
  );
};
