import {
  useQuestionnairePlanRetrieveQuery,
  useQuestionnairePlanUpdateMutation,
} from "api/carApi.generated";
import { CarClientPlan } from "types";
import { refetchOnFocus } from "const";
import { usePlanId } from "app/usePlanId";
import { useClientName } from "app/useClientName";

export const useClientPlan = () => {
  const planId = usePlanId();
  const clientName = useClientName();
  const { data, isLoading } = useQuestionnairePlanRetrieveQuery(
    { id: planId },
    { refetchOnFocus }
  );

  const [updateClientPlan] = useQuestionnairePlanUpdateMutation();

  const handleChange = (item: CarClientPlan) =>
    updateClientPlan({
      id: planId,
      clientPlan: item as Required<CarClientPlan>,
    });

  const planName = data?.name ?? "";

  return {
    isLoading: isLoading || clientName.isLoading,
    planId,
    planName: `The ${clientName.familyName} Family  -  ${planName}`,
    publishedPlanTypeId: data?.published_data?.published_plan_type_id,
    handleChange,
  };
};
