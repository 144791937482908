import { Box, MenuItem, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo } from "react";
import { CarSelectField } from "components/Inputs";
import { useSimulationReturns } from "./useSimulationReturns";
import { SimulationPercentileTable } from "./SimulationPercentileTable";
import { CarDownloadButton } from "components/Buttons";
import { useLocalStorage } from "features/general/useLocalStorage";
import { SimulationStatBox } from "./SimulationStatBox";
import { CarAssetClassSelector } from "components/AssetClassSelector";
import { CarSwitch } from "components/Switch";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

interface SimulationReturnsProps {
  simulationJobId: string;
  isStatic: boolean;
}

const timeHorizonItems: { id: string; label: string }[] = [
  {
    id: "short",
    label: "Short",
  },
  {
    id: "extended",
    label: "Extended",
  },
];

export const SimulationReturns = memo((props: SimulationReturnsProps) => {
  const [timeHorizon, setTimeHorizon] = useLocalStorage<string | undefined>(
    "SimulationReturns_timeHorizon",
    "short",
  );

  const data = useSimulationReturns({
    simulationJobId: props.simulationJobId,
  });

  const pageContent = usePageContent();

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 5 }}
      isLoading={data.assetClassSelector.isLoading || pageContent.isLoading}
      rows={8}
    >
      <CarPageContent
        sx={{ mb: 3 }}
        content={pageContent.getContent(
          props.isStatic
            ? CarPageContentCode.SIMULATION_ANALYSIS_STATIC_RETURNS
            : CarPageContentCode.SIMULATION_ANALYSIS_RETURNS_REAL,
        )}
        isWhiteContext
      />
      <Box sx={{ mt: 1, display: "flex", gap: 5, alignItems: "flex-end" }}>
        <CarAssetClassSelector
          label="Asset Class"
          sx={{
            width: 250,
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              marginTop: "28px",
              bgcolor: "white",
            },
          }}
          {...data.assetClassSelector}
        />
        <CarSelectField
          label="Time Horizon"
          sx={{
            width: 216,
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              marginTop: "28px",
              bgcolor: "white",
            },
          }}
          value={timeHorizon}
          onChange={setTimeHorizon}
        >
          {timeHorizonItems.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.label}
            </MenuItem>
          ))}
        </CarSelectField>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
          <Typography variant="par01Regular">Real</Typography>
          <CarSwitch
            size="small"
            checked={!data.isReal}
            onChange={(e, value) => data.setIsReal(!value)}
          />
          <Typography variant="par01Regular">Nominal</Typography>
        </Box>
        <CarDownloadButton
          sx={{ ml: "auto", mb: 0.5 }}
          onClick={data.handleDownloadReports}
        >
          Download Reports
        </CarDownloadButton>
      </Box>
      <TablePlaceHolder sx={{ mt: 5 }} isLoading={data.isFetching} rows={5}>
        {data.assetClassSelector.value && (
          <>
            {data.tableStat && (
              <SimulationStatBox
                sx={{
                  mt: 5,
                }}
                items={data.tableStat}
              />
            )}
            <Typography variant="subhSemiBold" sx={{ mt: 5, mb: 1 }}>
              {data.assetClassName}
            </Typography>
            <SimulationPercentileTable
              table={data.table}
              isExtended={timeHorizon === "extended"}
              onValueClick={props.isStatic ? undefined : data.handleValueClick}
            />
            {data.inflationTableStat && (
              <SimulationStatBox
                sx={{
                  mt: 5,
                }}
                items={data.inflationTableStat}
              />
            )}
            {data.inflationTable && (
              <>
                <Typography variant="subhSemiBold" sx={{ mt: 5, mb: 1 }}>
                  Inflation
                </Typography>
                <SimulationPercentileTable
                  table={data.inflationTable}
                  isExtended={timeHorizon === "extended"}
                  onValueClick={
                    props.isStatic ? undefined : data.handleValueClick
                  }
                />
              </>
            )}
          </>
        )}
      </TablePlaceHolder>
    </TablePlaceHolder>
  );
});
