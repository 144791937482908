import { Fragment } from "react";
import { CarPortfolioChartItem } from "../asset-allocation-types";
import { UseChartDataSelector } from "./useChartDataSelector";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { formatPercentFactor, isOddEven } from "utils";
import { BoxProps } from "@mui/material";

interface TableMultiPeriodReturnComparisonProps {
  sx?: BoxProps["sx"];
  data: CarPortfolioChartItem[];
  chartDataSelector: UseChartDataSelector;
}

const labels = ["3 Month", "1 Year", "3 Year", "5 Year", "10 Year"];

export const TableMultiPeriodReturnComparison = ({
  sx,
  data,
  chartDataSelector: { data1Id, data2Id },
}: TableMultiPeriodReturnComparisonProps) => {
  const item1 = data.find((i) => i.id === data1Id);
  const item2 = data.find((i) => i.id === data2Id);

  return item1 && item2 ? (
    <CarInfoTable sx={{ gridTemplateColumns: "repeat(4, 1fr)", ...sx }}>
      <CarInfoHeader variant="par02Regular" text="" />
      <CarInfoHeader variant="par02Regular" text={item1.fullLabel} />
      <CarInfoHeader variant="par02Regular" text={item2.fullLabel} />
      <CarInfoHeader variant="par02Regular" text="Difference:" />
      {labels.map((i, idx) => (
        <Fragment key={idx}>
          <CarInfoCell isOdd={isOddEven(idx)} text={i} justifyContent="start" />
          <CarInfoCell
            isOdd={isOddEven(idx)}
            text={formatPercentFactor(item1.y[idx], 1)}
          />
          <CarInfoCell
            isOdd={isOddEven(idx)}
            text={formatPercentFactor(item2.y[idx], 1)}
          />
          <CarInfoCell
            isOdd={isOddEven(idx)}
            text={formatPercentFactor(item1.y[idx] - item2.y[idx], 1)}
          />
        </Fragment>
      ))}
    </CarInfoTable>
  ) : null;
};
