import { Box, InputAdornment, Typography } from "@mui/material";
import { FullScreenBackground } from "components/FullScreenBackground";
import { CarFooter } from "components/Footer";
import {
  LoginButton,
  LoginField,
  LoginLogo,
  LoginTransparentBox,
} from "pages/login/LoginComponents";
import { useResetPassword } from "./useResetPassword";
import { Lock } from "@mui/icons-material";

export const ResetPasswordPage = () => {
  const data = useResetPassword();

  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Enter") {
      data.resetPassword();
    }
  };

  return (
    <FullScreenBackground
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
        }}
      >
        <LoginTransparentBox
          sx={{
            width: 531,
            // height: 618
          }}
        >
          <LoginLogo sx={{ mt: -2 }} />
          <Typography variant="h1SSemiBold" sx={{ mt: -1 }}>
            Create a new password
          </Typography>
          <Box sx={{ mt: 5, display: "flex", flexDirection: "column", gap: 2 }}>
            <LoginField
              placeholder="New Password"
              value={data.resetPasswordForm.password}
              onChange={(password) =>
                data.setResetPasswordForm((value) => ({ ...value, password }))
              }
              required={data.resetPasswordForm.requiredMode}
              inputProps={{
                type: "password",
                autoComplete: "new-password",
                spellCheck: false,
                autoCapitalize: "off",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 0 }}>
                    <Lock />
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeyDown}
            />
            <LoginField
              placeholder="Confirm Password"
              value={data.resetPasswordForm.confirmPassword}
              onChange={(confirmPassword) =>
                data.setResetPasswordForm((value) => ({
                  ...value,
                  confirmPassword,
                }))
              }
              required={data.resetPasswordForm.requiredMode}
              inputProps={{
                type: "password",
                autoComplete: "new-password",
                spellCheck: false,
                autoCapitalize: "off",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 0 }}>
                    <Lock />
                  </InputAdornment>
                ),
              }}
              onKeyDown={handleKeyDown}
            />
          </Box>
          <LoginButton
            sx={{
              mt: 4,
              mb: -2,
              alignSelf: "flex-start",
              width: 200,
            }}
            onClick={data.resetPassword}
          >
            Create Password
          </LoginButton>
        </LoginTransparentBox>
        <CarFooter
          sx={{
            mt: 2.5,
            alignSelf: "center",
            ".copyright": {
              fontSize: "12px",
            },
          }}
          isWhite
          isCopyrightOnly
          hasPrivacyPolicy
        />
      </Box>
    </FullScreenBackground>
  );
};
