import {
  CarStateKind,
  CarTaxesKind,
  CarDateExtraKind,
  CarInflationKind,
  CarPriorityKind,
  CarGoalIcon,
  CarSavingsExtraKind,
  CarSuffix,
  CarClientPlanStatus,
  CarAccountInputKind,
  CarPaymentPlanKind,
  CarRealEstateType,
} from "./types";
import {
  AddGoal,
  CarGoalIconComponent,
  CelebrationGoal,
  CharityGoal,
  EducationGoal,
  GenericGoal,
  HealthcareGoal,
  LegacyGoal,
  LuxuryItemGoal,
  RenovationGoal,
  RetirementSpendingGoal,
  SecondHomeGoal,
  VacationGoal,
} from "./components/GoalIcons";
import { DummyTest } from "global";

const debugParam = new URLSearchParams(window.location.search ?? "").get(
  "debug",
);

if (debugParam) {
  sessionStorage.setItem("caravel-debug", debugParam);
}

const storageDebug = sessionStorage.getItem("caravel-debug");

export const isDebugBuild = process.env.NODE_ENV !== "production";

export const isDebug =
  storageDebug === null ? isDebugBuild : !!JSON.parse(storageDebug);

export const refetchOnFocus = !isDebug;

export const apiUrl = process.env.REACT_APP_API_URL ?? "";
export interface State {
  label: string;
  value: CarStateKind;
}

export const states: Array<State> = [
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "American Samoa",
    value: "AS",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "District Of Columbia",
    value: "DC",
  },
  {
    label: "Federated States Of Micronesia",
    value: "FM",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Guam",
    value: "GU",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Marshall Islands",
    value: "MH",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Northern Mariana Islands",
    value: "MP",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Palau",
    value: "PW",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Puerto Rico",
    value: "PR",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virgin Islands",
    value: "VI",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

export interface Inflation {
  label: string;
  value: CarInflationKind | undefined;
}

export const inflationItems: Array<Inflation> = [
  { value: null as any, label: "None" },
  { value: -1, label: "SI - 1%" },
  { value: 0, label: "Simulated Inflation" },
  { value: 1, label: "SI + 1%" },
  { value: 2, label: "SI + 2%" },
  { value: 3, label: "SI + 3%" },
  { value: 4, label: "SI + 4%" },
  { value: 5, label: "SI + 5%" },
];

export interface Tax {
  label: string;
  value: CarTaxesKind;
}

export const taxItems: Array<Tax> = [
  { value: "TAXABLE", label: "Taxable" },
  { value: "TAX_FREE", label: "Tax Free" },
];

export interface Priority {
  label: string;
  value: CarPriorityKind;
  numValue: number;
}

export enum PriorityNumber {
  Need = 0,
  Want = 1,
  Dream = 2,
}

export const priorityItems: Array<Priority> = [
  { value: "NEED", label: "Need", numValue: 0 },
  { value: "WANT", label: "Want", numValue: 1 },
  { value: "DREAM", label: "Dream", numValue: 2 },
];

export interface Frequency {
  label: string;
  value: number;
}

export const frequencyItems: Array<Frequency> = Array(10)
  .fill(0)
  .map((i, idx) => idx + 1)
  .map((i) => ({ label: i.toString(), value: i }));

export interface GoalItem {
  label: string;
  icon: CarGoalIconComponent;
  icon2?: CarGoalIconComponent;
  value: CarGoalIcon;
}

export const goalItems: Array<GoalItem> = [
  { value: "LEGACY", label: "Legacy", icon: LegacyGoal },
  { value: "VACATION", label: "Vacation", icon: VacationGoal },
  { value: "SECOND_HOME", label: "2nd Home", icon: SecondHomeGoal },
  { value: "CHARITY", label: "Charity", icon: CharityGoal },
  { value: "LUXURY_ITEM", label: "Luxury Item", icon: LuxuryItemGoal },
  { value: "RENOVATION", label: "Renovation", icon: RenovationGoal },
  {
    value: "RETIREMENT_SPENDING",
    label: "Retirement Spending",
    icon: RetirementSpendingGoal,
  },
  { value: "HEALTHCARE", label: "Healthcare", icon: HealthcareGoal },
  { value: "EDUCATION", label: "Education", icon: EducationGoal },
  { value: "CELEBRATION", label: "Celebration", icon: CelebrationGoal },
  { value: "GENERIC", label: "Generic", icon: GenericGoal, icon2: AddGoal },
];

export const RISK_ASSESSMENT_ID = "assessment";
export const riskItems = [
  { value: "0,100", label: "0 / 100" },
  { value: "5,95", label: "5 / 95" },
  { value: "10,90", label: "10 / 90" },
  { value: "15,85", label: "15 / 85" },
  { value: "20,80", label: "20 / 80" },
  { value: "25,75", label: "25 / 75" },
  { value: "30,70", label: "30 / 70" },
  { value: "35,65", label: "35 / 65" },
  { value: "40,60", label: "40 / 60" },
  { value: "45,55", label: "45 / 55" },
  { value: "50,50", label: "50 / 50" },
  { value: "55,45", label: "55 / 45" },
  { value: "60,40", label: "60 / 40" },
  { value: "65,35", label: "65 / 35" },
  { value: "70,30", label: "70 / 30" },
  { value: "75,25", label: "75 / 25" },
  { value: "80,20", label: "80 / 20" },
  { value: "85,15", label: "85 / 15" },
  { value: "90,10", label: "90 / 10" },
  { value: "95,5", label: "95 / 5" },
  { value: "100,0", label: "100 / 0" },
  { value: RISK_ASSESSMENT_ID, label: "Use Risk Assessment" },
];

interface DateExtra {
  value: CarDateExtraKind;
  label: string;
}

export const dateExtraItems: Array<DateExtra> = [
  { value: "AGE", label: "Age" },
  { value: "YEAR", label: "Year" },
  { value: "PRIMARY_RETIREMENT", label: "Client Retirement" },
  { value: "SECONDARY_RETIREMENT", label: "Spouse Retirement" },
  { value: "PRIMARY_DEATH", label: "Client Death" },
  { value: "SECONDARY_DEATH", label: "Spouse Death" },
  { value: "SECOND_DEATH", label: "2nd Death" },
];

export const DEFAULT_KIND = "AGE";
export const DEFAULT_AGE = 50;
export const DEFAULT_YEAR = 2070;

export interface YearItem {
  label: string;
  value: number;
}

export const yearItems: Array<YearItem> = Array(100)
  .fill(2021)
  .map((i, idx) => i + idx)
  .map((i) => ({ label: i.toString(), value: i }));

interface AnnualSavings {
  value: CarSavingsExtraKind;
  label: string;
}

export const annualSavingsItems: Array<AnnualSavings> = [
  { value: "PERCENT", label: "Percentage" },
  { value: "DOLLAR", label: "Dollar Amount" },
  { value: "MAX", label: "Maximum Contribution" },
];

// interface RiskMode {
//   value: string;
//   label: string;
// }

// export const riskModeItems: Array<RiskMode> = [
//   { value: "eq_wt", label: "Equity Weighting" },
//   { value: "1yr_downside", label: "One Year Downside Risk (1st Percentile)" },
// ];

export enum AssetClassLevel {
  level1 = "level1",
  level2 = "level2",
  level3 = "level3",
  level4 = "level4",
}

interface AssetClassLevelItem {
  value: AssetClassLevel;
  label: string;
}

export const assetClassLevelItems: Array<AssetClassLevelItem> = [
  { value: AssetClassLevel.level1, label: "Level 1" },
  { value: AssetClassLevel.level2, label: "Level 2" },
  { value: AssetClassLevel.level3, label: "Level 3" },
  { value: AssetClassLevel.level4, label: "Level 4" },
];

export enum AccountType {
  Integrated = "integrated",
  ManualDetailed = "manual_detailed",
  ManualPercent = "manual_percent",
  ManualDollar = "manual_dollar",
}

export interface SuffixItem {
  label: string;
  value: CarSuffix;
}

export const suffixItems: Array<SuffixItem> = [
  { value: "-" as CarSuffix, label: "-" },
  { value: "Jr.", label: "Jr." },
  { value: "Sr.", label: "Sr." },
  { value: "I", label: "I" },
  { value: "II", label: "II" },
  { value: "III", label: "III" },
];

export enum StressTestProjectionType {
  MarketEnvironment = "marketEnvironment",
  PlanCashflow = "planCashflow",
  NetWorth = "netWorth",
  TaxableIncomeAnalysis = "taxableIncomeAnalysis",
  RealEstate = "realEstate",
}
interface StressTestProjectionTypeItem {
  value: StressTestProjectionType;
  label: string;
}

export const stressTestProjectionTypes: Array<StressTestProjectionTypeItem> = [
  {
    value: StressTestProjectionType.MarketEnvironment,
    label: "Market Environment",
  },
  { value: StressTestProjectionType.PlanCashflow, label: "Plan Cashflow" },
  { value: StressTestProjectionType.NetWorth, label: "Net Worth" },
  {
    value: StressTestProjectionType.TaxableIncomeAnalysis,
    label: "Taxable Income Analysis",
  },
  {
    value: StressTestProjectionType.RealEstate,
    label: "Real Estate",
  },
];

export enum PlanTypeCode {
  baseNoCg = "BASE_NO_CG",
  proposed = "PROPOSED",
  existing = "EXISTING",
  taxDefer = "TAX_DEFER",
  derisked = "DERISK_CG",
}

export interface PlanStatusItem {
  label: string;
  value: CarClientPlanStatus;
}

export const planStatusItems: Array<PlanStatusItem> = [
  { value: "DRAFT", label: "Draft" },
  { value: "PUBLISHED", label: "Published" },
  { value: "ARCHIVED", label: "Archived" },
];

export enum AssetTypeCode {
  socialSecurity = "SOCIAL_SECURITY",
  currentAsset = "CURRENT_ASSET",
  savings = "SAVINGS",
  realEstate = "REAL_ESTATE",
  additionalIncome = "ADDITIONAL_INCOME",
}

export interface CarRealEstateTypeItem {
  value: CarRealEstateType;
  label: string;
}

export const realEstateTypes: CarRealEstateTypeItem[] = [
  {
    value: "OCCUPIED",
    label: "Owner Occupied",
  },
  {
    value: "RENTAL",
    label: "Rental",
  },
];
export interface CarManualAccountKindItem {
  value: CarAccountInputKind;
  label: string;
}

export const manualAccountKinds: CarManualAccountKindItem[] = [
  {
    value: "manual_summary_simple",
    label: "Summary - Simple",
  },
  {
    value: "manual_summary_advanced",
    label: "Summary - Advanced",
  },
  {
    value: "manual_detailed_holdings",
    label: "Detailed Holdings",
  },
];

export interface CarPaymentPlanKindItem {
  value: CarPaymentPlanKind;
  label: string;
}

export const paymentPlanKinds: CarPaymentPlanKindItem[] = [
  {
    value: "NO_CHANGE",
    label: "No Change",
  },
  {
    value: "YEAR_15",
    label: "15 Years",
  },
  {
    value: "YEAR_30",
    label: "30 Years",
  },
];

export const markdownSample = `
# h1 Heading
## h2 Heading
### h3 Heading
#### h4 Heading
##### h5 Heading
###### h6 Heading


## Horizontal Rules

___

---

***

Emphasis, aka italics, with *asterisks* or _underscores_.

Strong emphasis, aka bold, with **asterisks** or __underscores__.

Combined emphasis with **asterisks and _underscores_**.

Strikethrough uses two tildes. ~~Scratch this.~~

**This is bold text**

__This is bold text__

*This is italic text*

_This is italic text_

~~Strikethrough~~


Ordered List:
1. First item
2. Second item
3. Third item

Unordered List:
- First item
- Second item
- Third item

Code	\`code\`

Horizontal Rule
---
[link_text](https://www.example.com)

![alt text](image.jpg)
`;

export const dummyTest: DummyTest = {
  name: "dummyTest",
};
