import { useMemo } from "react";
import {
  getDistanceFromTrendExcelTable,
  useAssetClassSummaryStat,
} from "pages/assetClassAnalysis/useAssetClassSummaryStat";
import { useExcelExport } from "app/useExcelExport";
import { useSimulationAssetClassSelector } from "./useSimulationAssetClassSelector";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

export const useSimulationDistanceFromTrend = (params: {
  simulationJobId: string;
}) => {
  const excelExport = useExcelExport();
  const assetClassSelector = useSimulationAssetClassSelector({
    storageKey: "useSimulationDistanceFromTrend_assetClassCode",
    simulationJobId: params.simulationJobId,
  });

  const assetClassAnalysis = useAssetClassSummaryStat({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: params.simulationJobId,
  });

  return useMemo(() => {
    const selectedAssetClass = assetClassSelector.selectedItem;

    const distanceFromTrendItem = assetClassAnalysis.acSummaryItems.find(
      (i) => i.level4Id === selectedAssetClass?.level4Id,
    );

    const handleDownloadReports = () => {
      if (!distanceFromTrendItem) {
        return;
      }
      excelExport.exportAllExcel({
        fileName: `Simulation Distance From Trend - ${selectedAssetClass?.name}`,
        tables: [
          getDistanceFromTrendExcelTable({
            name: selectedAssetClass?.name ?? "",
            item: distanceFromTrendItem,
          }),
        ],
      });
    };

    return {
      isLoading: assetClassAnalysis.isLoading,
      assetClasses: assetClassSelector,
      distanceFromTrendItem,
      assetClassSelector,
      handleDownloadReports,
    };
  }, [
    assetClassAnalysis.acSummaryItems,
    assetClassAnalysis.isLoading,
    assetClassSelector,
    excelExport,
  ]);
};
