import clsx from "clsx";
import { Box, Collapse, IconButton, styled, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useCommonCmpasModuleHierarchyListQuery } from "api/carApi.generated";
import { useDialog } from "app/useDialog";
import {
  checkDefined,
  getNextSortOrder,
  openInNewTab,
  sortBySortOrder,
} from "utils";
import { PropsWithChildren } from "react";
import { UseExpand, useExpand } from "features/general/useExpand";
import { CarIconAdd, CarIconEdit } from "icons";
import { ModuleEditorDialog } from "./ModuleEditorDialog";
import { ModuleSectionEditorDialog } from "./ModuleSectionEditorDialog";
import { PageSectionEditorDialog } from "./PageSectionEditorDialog";
import { PageSectionBlockEditorDialog } from "./PageSectionBlockEditorDialog";
import { Link } from "@mui/icons-material";
import { isDebug } from "const";

const StyledRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  userSelect: "none",
  marginBottom: theme.spacing(1),
  ".edit": {
    opacity: 0,
    transition: "opacity 0.3s",
  },
  ".header": {
    "&:hover": {
      ".edit": {
        opacity: 1,
      },
    },
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
    cursor: "pointer",
    alignSelf: "flex-start",
  },
  "&.is-expanded > .header > .triangle": {
    color: theme.palette.primary.main,
    transform: "rotate(90deg)",
  },
  "&.is-leaf > .header > .triangle": {
    display: "none",
  },
  ".triangle": {
    transition: "transform 0.3s",
    color: theme.palette.softBlack,
  },
  ".MuiCollapse-wrapperInner": {
    display: "flex",
    flexDirection: "column",
    // border: "1px solid",
    // backgroundColor: "pink",
  },
}));

const Triangle = () => (
  <svg
    className="triangle"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 8L3 14.9282L3 1.0718L15 8Z" fill="currentColor" />
  </svg>
);

const Row = (
  props: PropsWithChildren<{
    id: string;
    label: string;
    onEdit: (id: string) => void;
    level: number;
    expand: UseExpand;
    isLeaf?: boolean;
    link?: string | null;
  }>,
) => {
  const isExpanded = props.expand.getExpanded(props.id);
  return (
    <StyledRow
      style={{ paddingLeft: props.level ? 40 : 0 }}
      className={clsx({
        "is-expanded": isExpanded,
        "is-leaf": props.isLeaf,
      })}
    >
      <div
        className="header"
        onClick={() => props.expand.onExpand(props.id, !isExpanded)}
      >
        <Triangle />
        <Typography variant={props.level === 0 ? "h6SemiBold" : "h6SRegular"}>
          {props.label}
        </Typography>
        {props.link && (
          <IconButton
            sx={{ mx: -0.5 }}
            size="small"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              openInNewTab(props.link ?? "");
            }}
          >
            <Link fontSize="small" />
          </IconButton>
        )}
        <IconButton
          className="edit"
          size="small"
          color="primary"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.onEdit(props.id);
          }}
        >
          <CarIconEdit fontSize="small" />
        </IconButton>
      </div>
      {props.children && (
        <Collapse in={isExpanded} mountOnEnter unmountOnExit>
          {props.children}
        </Collapse>
      )}
    </StyledRow>
  );
};

const Header = (
  props: PropsWithChildren<{
    label: string;
    onAdd: () => void;
    level: number;
  }>,
) => {
  return (
    <StyledRow style={{ paddingLeft: props.level ? 40 : 0 }}>
      <Box sx={{ display: "flex", alignItems: "center", mt: 1, mb: -1 }}>
        <Typography variant="caption">{props.label}</Typography>
        <IconButton
          size="small"
          color="inherit"
          sx={{ color: "softBlack" }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            props.onAdd();
          }}
        >
          <CarIconAdd
            fontSize="small"
            sx={{ width: 14, height: 14, mb: "1px" }}
          />
        </IconButton>
      </Box>
    </StyledRow>
  );
};

export const SettingsBackendModules = () => {
  const moduleHierarchyList = useCommonCmpasModuleHierarchyListQuery();

  const dialog = useDialog();

  const handleEditModule = (params: {
    id?: string;
    nextSortOrder?: number;
  }) => {
    dialog(ModuleEditorDialog, params);
  };
  const handleEditModuleSection = (params: {
    id?: string;
    moduleId: string;
    nextSortOrder?: number;
  }) => {
    dialog(ModuleSectionEditorDialog, params);
  };

  const handleEditPageSection = (params: {
    id?: string;
    pageId: string;
    nextSortOrder?: number;
  }) => {
    dialog(PageSectionEditorDialog, params);
  };

  const handleEditSectionBlock = (params: {
    id?: string;
    pageSectionId: string;
    nextSortOrder?: number;
  }) => {
    dialog(PageSectionBlockEditorDialog, params);
  };

  const expand = useExpand("Settings-BackEnd");

  return (
    <TablePlaceHolder isLoading={moduleHierarchyList.isLoading} rows={8}>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header
          level={0}
          label="Modules"
          onAdd={() =>
            handleEditModule({
              nextSortOrder: getNextSortOrder(moduleHierarchyList.data),
            })
          }
        />
        {sortBySortOrder(moduleHierarchyList.data).map((module) => (
          <Row
            key={module.id}
            id={checkDefined(module.id)}
            expand={expand}
            label={module.name}
            onEdit={(id) => handleEditModule({ id })}
            level={0}
          >
            <Header
              level={1}
              label="Pages"
              onAdd={() =>
                handleEditModuleSection({
                  moduleId: checkDefined(module.id),
                  nextSortOrder: getNextSortOrder(module.module_sections),
                })
              }
            />
            {sortBySortOrder(module.module_sections)?.map((moduleSection) => (
              <Row
                key={moduleSection.id}
                id={checkDefined(moduleSection.id)}
                expand={expand}
                label={moduleSection.name}
                link={moduleSection.front_end_url}
                onEdit={(id) =>
                  handleEditModuleSection({
                    id,
                    moduleId: checkDefined(module.id),
                  })
                }
                level={1}
              >
                <Header
                  level={2}
                  label="Page Sections"
                  onAdd={() =>
                    handleEditPageSection({
                      pageId: checkDefined(moduleSection.id),
                      nextSortOrder: getNextSortOrder(
                        moduleSection.page_sections,
                      ),
                    })
                  }
                />
                {sortBySortOrder(moduleSection.page_sections)?.map(
                  (pageSection) => (
                    <Row
                      key={pageSection.id}
                      id={checkDefined(pageSection.id)}
                      expand={expand}
                      label={
                        isDebug
                          ? `${pageSection.title} - ${pageSection.code}`
                          : pageSection.title
                      }
                      onEdit={(id) =>
                        handleEditPageSection({
                          id,
                          pageId: checkDefined(moduleSection.id),
                        })
                      }
                      level={2}
                    >
                      <Header
                        level={3}
                        label="Page Section Blocks"
                        onAdd={() =>
                          handleEditSectionBlock({
                            pageSectionId: checkDefined(pageSection.id),
                            nextSortOrder: getNextSortOrder(
                              pageSection.section_blocks,
                            ),
                          })
                        }
                      />
                      {sortBySortOrder(pageSection.section_blocks)?.map(
                        (sectionBlock) => (
                          <Row
                            key={sectionBlock.id}
                            id={checkDefined(sectionBlock.id)}
                            expand={expand}
                            label={sectionBlock.block_type?.display_label ?? ""}
                            onEdit={(id) =>
                              handleEditSectionBlock({
                                id,
                                pageSectionId: checkDefined(pageSection.id),
                              })
                            }
                            level={3}
                            isLeaf
                          />
                        ),
                      )}
                    </Row>
                  ),
                )}
              </Row>
            ))}
          </Row>
        ))}
      </Box>
    </TablePlaceHolder>
  );
};
