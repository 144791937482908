import {
  GenModelsAssetClassRunStatus,
  useCmaGenerateAssetClassSimBehaviorCreateMutation,
} from "api/carApi.generated";
import { useMemo, useState } from "react";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { CarSelectOption } from "components/Inputs";
import { isDefined } from "utils";

interface UseSimulationModelsRunParams {}

interface SimulationModelRunItem {
  id: string;
  name: string;
  status: string;
}

export const useSimulationModelsRun = (
  params: UseSimulationModelsRunParams,
) => {
  const [selection, setSelection] = useState<string | undefined>("all");

  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.allFirm,
  });

  const [req, { data, isLoading }] =
    useCmaGenerateAssetClassSimBehaviorCreateMutation();

  return useMemo(() => {
    const selectionOptions: CarSelectOption<string>[] = [
      {
        value: "equities",
        label: "Equities",
      },
      {
        value: "fixed_income",
        label: "Fixed Income",
      },
      {
        value: "cash",
        label: "Cash",
      },
      {
        value: "all",
        label: "All Asset Classes",
      },
    ];

    const statusItems = (data ??
      []) as unknown as GenModelsAssetClassRunStatus[]; // todo fix in swagger
    const items = assetClasses.items
      .map<SimulationModelRunItem | undefined>((i) => {
        const statusItem = statusItems.find(
          (si) => si.asset_class_id === i.level4Id,
        );

        return statusItem
          ? {
              id: i.firmAssetClassId ?? "",
              name: i.name,
              status: statusItem.status,
            }
          : undefined;
      })
      .filter(isDefined);

    let selectedAssetClassIds: string[] = assetClasses.items
      .filter((i) => {
        switch (selection) {
          case "equities":
            return i.isEquity;
          case "fixed_income":
            return i.isFixedIncome;
          case "cash":
            return i.isCash;
          default:
            return true;
        }
      })
      .map((i) => i.level4Id);

    const handleRun = () => {
      if (!selectedAssetClassIds.length) {
        return;
      }
      req({
        genModelsAssetClassList: {
          asset_class_id: selectedAssetClassIds,
        },
      });
    };

    return {
      canRun: selectedAssetClassIds.length > 0,
      handleRun,
      selectionOptions,
      selection,
      setSelection,
      items,
      isLoading: assetClasses.isLoading,
      isRunning: isLoading,
    };
  }, [
    assetClasses.isLoading,
    assetClasses.items,
    data,
    isLoading,
    req,
    selection,
  ]);
};
