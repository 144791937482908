import { BoxProps } from "@mui/material";
import { CarInfoExpandableRow, CarInfoTable } from "components/InfoTable";
import { formatPercent } from "utils";
import { CarCapitalGainsAllocAsset } from "./calcCapitalGainsAllocAssets";
import { ReactElement } from "react";

export interface CapitalGainsSummaryAssetAllocationProps {
  header: ReactElement;
  items: Array<CarCapitalGainsAllocAsset>;
  sx?: BoxProps["sx"];
}
export const CapitalGainsSummaryAssetAllocation = ({
  header,
  items,
  sx,
}: CapitalGainsSummaryAssetAllocationProps) => {
  const getRenderItem = (level: number) => (i: CarCapitalGainsAllocAsset) => {
    const values = i.values.map((v) => formatPercent(v));
    return (
      <CarInfoExpandableRow
        key={i.id}
        caption={`${i.name}:`}
        values={values}
        level={level}
        gridTemplateColumns={`1.5fr repeat(${values.length}, 1fr)`}
        childContent={i.children.map(getRenderItem(level + 1))}
      />
    );
  };
  return (
    <CarInfoTable sx={{ gridTemplateColumns: "1fr", ...sx }}>
      {header}
      {items.map(getRenderItem(0))}
    </CarInfoTable>
  );
};
