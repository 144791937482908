export const colorByStatus = (status?: string) => {
  switch (status) {
    case "success":
      return "#52BC5D";
    case "partial":
      return "#E0CD1F";
    case "failure":
      return "#DA3232";
    default:
      return undefined;
  }
};
