import React from "react";
import { Box, Typography } from "@mui/material";
import { CarTableMemoTable } from "components/Table";
import { getAccountsTotals } from "features/assets/account.utils";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarTotalLabel } from "components/TotalLabel";
import { useAccount } from "features/assets/useAccount";
import { useExpandSingle } from "features/general/useExpand";
import {
  CarAccordion,
  CarAccordionDetails,
  CarAccordionSummary,
} from "components/Accordion";
import { IntegratorAccountRow } from "./IntegratorAccountRow";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

export const IntegratorAccountsSection = React.memo(() => {
  const expand = useExpandSingle(`IntegratorAccountsSection`, false);
  const data = useAccount();
  const totals = getAccountsTotals(data.integrationItems);

  return (
    <CarAccordion {...expand}>
      <CarAccordionSummary>
        <Typography variant="h5SSemiBold">
          Integrator Accounts
          <CarTooltipBox
            sx={{ display: "inline", ml: 0.5, mt: 0.25 }}
            className={pendoClasses.planInputIntegratorAccounts}
          />
        </Typography>
      </CarAccordionSummary>
      <CarAccordionDetails
        sx={{
          backgroundColor: "gray1",
          pl: 7,
          pr: 3,
          py: 6,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TablePlaceHolder isLoading={data.isLoading}>
          <CarTableMemoTable
            items={data.integrationItems}
            rowComponent={IntegratorAccountRow}
            onChange={data.handleChange}
            onDelete={data.handleDelete}
            pendoPrefix="integrator_accounts"
          />
        </TablePlaceHolder>
        <Box
          sx={{
            ml: 1.5,
            mt: 6,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <CarTotalLabel
            variant="h5SBold"
            label="Total Value of Integrator Accounts"
            value={totals.marketValue}
          />
          <CarTotalLabel
            variant="h5SBold"
            label="Total Annual Savings of Integrator Accounts"
            value={data.annualSavings.integrated}
          />
        </Box>
      </CarAccordionDetails>
    </CarAccordion>
  );
});
