import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import { CarGicsItem, CarGicsReport } from "app/useGics";
import { UseChartDataSelector } from "./useChartDataSelector";
import { CarSelectChartField } from "./SelectChartField";
import { CarPortfolioChartItem } from "../asset-allocation-types";
import { ComponentProps, useCallback, useMemo } from "react";
import { Chart } from "react-chartjs-2";
import { ChartDataset } from "chart.js";
import {
  ChartTooltip,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { formatPercentFactor } from "utils";
import { ChartPlugins } from "chartUtils";
import { createPendoClassName } from "app/thirdParty/pendo";
import { CarChartLegend } from "components/ChartLegend";
import { chartsColor } from "theme";

type Options = ComponentProps<typeof Chart>["options"];

interface SectorSummaryChartData {
  bar1: ChartDataset<"bar", number[]>;
  bar2: ChartDataset<"bar", number[]>;
  yLabel: string;
  xLabels: string[];
}

export const getSectorSummaryChartData = ({
  data,
  label1,
  label2,
}: {
  data: CarGicsItem[];
  label1: string;
  label2: string;
}): SectorSummaryChartData => {
  return {
    bar1: {
      data: data.map((i) => i.value1),
      label: label1,
    },
    bar2: {
      data: data.map((i) => i.value2),
      label: label2,
    },
    yLabel: "Sector Weight",
    xLabels: data.map((i) => i.name),
  };
};

interface InternalChartProps {
  sx?: BoxProps["sx"];
  data: CarGicsItem[];
  label1: string;
  label2: string;
  pendoPrefix: string;
  isWhiteContext?: boolean;
  isVertical?: boolean;
}

const InternalChart = ({
  sx,
  data,
  label1,
  label2,
  pendoPrefix,
  isWhiteContext,
  isVertical,
}: InternalChartProps) => {
  const theme = useTheme();
  const { tooltipPlugin, tooltipData } = useChartTooltip();
  const { bar1, bar2, yLabel, xLabels } = getSectorSummaryChartData({
    data,
    label1,
    label2,
  });

  const renderTooltip: RenderTooltip = useCallback(
    (datasetIndex, dataIndex) => {
      const ds = datasetIndex === 0 ? bar1 : bar2;
      const value = ds?.data?.[dataIndex] ?? 0;
      const periodStr = xLabels[dataIndex] ?? "";
      return (
        <Box
          sx={{
            fontSize: 15,
            fontWeight: 600,
            color: "black",
            whiteSpace: "nowrap",
          }}
        >
          <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
            {ds?.label}
          </Typography>
          <br />
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {periodStr}
          </Typography>
          <br />
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {formatPercentFactor(value, 1)}
          </Typography>
        </Box>
      );
    },
    [bar1, bar2, xLabels],
  );

  const options = useMemo<Options>(() => {
    const result: Options = {
      maintainAspectRatio: false,
      animation: {
        duration: 500,
      },
      indexAxis: isVertical ? "y" : "x",
      scales: {
        x: {
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 13,
              weight: "600",
            },
            color: theme.palette.softBlack,
          },
          grid: {
            display: false,
          },
          type: "category",
          labels: xLabels.map((i) => (i.length > 11 ? i.split(" ") : [i])),
        },
        y: {
          position: isVertical ? "top" : undefined,
          title: {
            display: !isVertical,
            text: yLabel,
            font: {
              family: theme.typography.fontFamily,
              size: 16,
              weight: "600",
            },
            color: theme.palette.softBlack,
          },
          beginAtZero: true,
          offset: false,
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 15,
              weight: "600",
            },
            color: theme.palette.softBlack,
            callback: (value) =>
              typeof value === "number" ? formatPercentFactor(value, 1) : value,
          },
          border: { display: false },
          grid: {
            display: true,
            color: (ctx) => {
              return ctx.tick?.value === 0
                ? theme.palette.gray7
                : theme.palette.gray2;
            },
            lineWidth: 1,
            tickColor: theme.palette.white,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltipPlugin,
        roundedBackground: {
          contextColor: isWhiteContext
            ? theme.palette.white
            : theme.palette.gray1,
          backgroundColor: isWhiteContext
            ? theme.palette.gray1
            : theme.palette.white,
          borderColor: theme.palette.gray7,
          borderRadius: 5,
        },
      },
    };

    if (isVertical) {
      const y = result.scales?.x;
      const x = result.scales?.y;
      result.scales = { ...result.scales, x, y };
    }
    return result;
  }, [theme, tooltipPlugin, isWhiteContext, xLabels, yLabel, isVertical]);

  return (
    <ChartTooltip
      tooltipData={tooltipData}
      renderTooltip={renderTooltip}
      sx={{
        width: "100%",
        height: isVertical ? "100%" : 470,
        ...sx,
      }}
    >
      <Chart
        className={createPendoClassName(`${pendoPrefix}_chart`)}
        type="bar"
        datasetIdKey="id"
        data={{
          datasets: [
            {
              id: "left",
              type: "sharpBar" as "bar",
              label: bar1.label,
              data: bar1.data,
              backgroundColor: theme.palette.chartsColor.green,
              barPercentage: 0.8,
              categoryPercentage: 0.4,
            },
            {
              id: "right",
              type: "sharpBar" as "bar",
              label: bar2.label,
              data: bar2.data,
              backgroundColor: theme.palette.chartsColor.orange,
              barPercentage: 0.8,
              categoryPercentage: 0.4,
            },
          ],
        }}
        options={options}
        plugins={[ChartPlugins.roundedBackground]}
      />
    </ChartTooltip>
  );
};

export const ChartSectorSummary = ({
  selectorLabel1,
  selectorLabel2,
  report,
  chartItems,
  chartDataSelector: { data1Id, data2Id, onData1IdChange, onData2IdChange },
  pendoPrefix,
  isWhiteContext,
}: {
  selectorLabel1: string;
  selectorLabel2: string;
  report: CarGicsReport;
  chartDataSelector: UseChartDataSelector;
  chartItems: CarPortfolioChartItem[];
  pendoPrefix: string;
  isWhiteContext?: boolean;
}) => {
  const theme = useTheme();

  const label1 = chartItems.find((i) => i.id === data1Id)?.fullLabel ?? "";
  const label2 = chartItems.find((i) => i.id === data2Id)?.fullLabel ?? "";

  return (
    <>
      <InternalChart
        pendoPrefix={pendoPrefix}
        isWhiteContext={isWhiteContext}
        data={report.level1}
        label1={label1}
        label2={label2}
      />
      <Box
        sx={{
          mt: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 3,
        }}
      >
        <CarSelectChartField
          sx={{
            alignSelf: "flex-start",
            width: 200,
            ".MuiInputBase-root": {
              backgroundColor: "white",
            },
          }}
          label={selectorLabel1}
          value={data1Id}
          onChange={onData1IdChange}
          items={chartItems}
          chartColor={theme.palette.chartsColor.green}
        />
        <CarSelectChartField
          sx={{
            alignSelf: "flex-start",
            width: 200,
            ".MuiInputBase-root": {
              backgroundColor: "white",
            },
          }}
          label={selectorLabel2}
          value={data2Id}
          onChange={onData2IdChange}
          items={chartItems}
          chartColor={theme.palette.chartsColor.orange}
        />
      </Box>
    </>
  );
};

export const ChartSectorSummaryDashboard = ({
  report,
  label1,
  label2,
  isWhiteContext,
}: {
  report: CarGicsReport;
  label1: string;
  label2: string;
  isWhiteContext?: boolean;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: "auto" }}>
      <InternalChart
        sx={{ flex: "auto" }}
        pendoPrefix={"todo"}
        isWhiteContext={isWhiteContext}
        isVertical
        data={report.level1}
        label1={label1}
        label2={label2}
      />
      <CarChartLegend
        items={[
          {
            color: chartsColor.green,
            label: label1,
            datasetIndex: 0,
            dataIndex: 0,
          },
          {
            color: chartsColor.orange,
            label: label2,
            datasetIndex: 0,
            dataIndex: 1,
          },
        ]}
      />
    </Box>
  );
};
