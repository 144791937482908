import clsx from "clsx";
import {
  Box,
  Button,
  MenuItem,
  styled,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AgeSliderSingle, YearSlider } from "./Slider";
import { CarSelectField } from "./Inputs";
import { CarDateExtra, CarDateExtraKind } from "types";
import { dateExtraItems, DEFAULT_AGE, DEFAULT_KIND, DEFAULT_YEAR } from "const";
import { getFullName } from "features/clientFamily/useRelationship";
import _ from "lodash";
import { v4 } from "uuid";
import { usePlanId } from "app/usePlanId";
import { isDateExtraValid, isDefined } from "utils";
import { useRelationshipLight } from "app/useRelationshipLight";

const StyledMenuItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  width: 332,
  borderBottom: `solid 1px ${theme.palette.gray3}`,
  "&.selected": {
    backgroundColor: theme.palette.lightOrange,
  },
}));

interface AgeMenuItemProps {
  kind: CarDateExtraKind;
  onKindChange: (value: CarDateExtraKind) => void;
  relationshipId?: string;
  onRelationshipIdChange: (value?: string) => void;
  age: number;
  onAgeChange: (value: number) => void;
  onApplyChanges: () => void;
}

const AgeMenuItem = (props: AgeMenuItemProps) => {
  const relationship = useRelationshipLight();

  const [pendingChange, setPendingChange] = useState(false);

  const isSelected = props.kind === "AGE";

  const minAge = relationship.getMinAge(props.relationshipId);
  const maxAge = relationship.getMaxAge(props.relationshipId);

  useEffect(() => {
    if (pendingChange && props.relationshipId && isSelected) {
      setPendingChange(false);
      props.onApplyChanges();
    }
  }, [pendingChange, props, isSelected]);

  const handleRelationshipIdChange = (value?: string) => {
    props.onRelationshipIdChange(value);
    props.onKindChange("AGE");

    const newMinAge = relationship.getMinAge(value);
    const newMaxAge = relationship.getMaxAge(value);

    if (props.age < newMinAge) {
      props.onAgeChange(newMinAge);
    } else if (props.age > newMaxAge) {
      props.onAgeChange(newMaxAge);
    }
  };

  const handleAgeChange = (value: number) => {
    props.onAgeChange(value);
    props.onKindChange("AGE");
  };

  const year = relationship.getDateExtraInfo({
    age_relationship: props.relationshipId,
    age: props.age,
    kind: "AGE",
  }).year;

  return (
    <StyledMenuItem className={clsx({ selected: isSelected })}>
      <CarSelectField
        sx={{
          width: 220,
          mt: -1,
          mb: 2,
        }}
        value={props.relationshipId}
        onChange={handleRelationshipIdChange}
        required={isSelected}
        SelectProps={{
          displayEmpty: true,
          renderValue: (v: unknown) => {
            const rel = relationship.items.find((i) => i.id === v);
            return rel ? getFullName(rel) : "Whose age determines goal?";
          },
        }}
      >
        {relationship.items.map((i) => (
          <MenuItem key={i.id} value={i.id}>
            {getFullName(i)}
          </MenuItem>
        ))}
      </CarSelectField>
      <Box sx={{ display: "flex" }}>
        <Typography variant="par01Regular">Age: {props.age}</Typography>
        {year && (
          <Typography
            variant="par01Regular"
            sx={{
              ml: "auto",
              color: "gray6",
              fontSize: "12px",
              // to prevent label from jumping when slider moves left and right
              minWidth: 60,
            }}
          >
            Year: {year}
          </Typography>
        )}
      </Box>
      <AgeSliderSingle
        value={props.age}
        minAge={minAge}
        maxAge={maxAge}
        onChange={handleAgeChange}
        onClick={() => setPendingChange(true)}
      />
    </StyledMenuItem>
  );
};

interface YearMenuItemProps {
  kind: CarDateExtraKind;
  onKindChange: (value: CarDateExtraKind) => void;
  year: number;
  onYearChange: (value: number) => void;
  onApplyChanges: () => void;
}

const YearMenuItem = (props: YearMenuItemProps) => {
  const handleYearChange = (value: number) => {
    props.onYearChange(value);
    props.onKindChange("YEAR");
  };

  return (
    <StyledMenuItem className={clsx({ selected: props.kind === "YEAR" })}>
      <Typography variant="par01Regular">Year: {props.year}</Typography>
      <YearSlider
        value={props.year}
        onChange={handleYearChange}
        onChangeCommitted={props.onApplyChanges}
      />
    </StyledMenuItem>
  );
};

interface SimpleMenuItemProps {
  className?: string;
  id: CarDateExtraKind;
  label: string;
  kind: CarDateExtraKind;
  year?: number;
  onKindChange: (value: CarDateExtraKind) => void;
  onApplyChanges: () => void;
}

const SimpleMenuItem = styled((props: SimpleMenuItemProps) => {
  const handleClick = () => {
    props.onKindChange(props.id);
    props.onApplyChanges();
  };

  return (
    <Button
      className={clsx(props.className, {
        selected: props.kind === props.id,
      })}
      onClick={handleClick}
    >
      {props.label}
      {props.year && (
        <Typography
          variant="par01Regular"
          sx={{
            ml: "auto",
            color: "gray6",
            fontSize: "12px",
          }}
        >
          Year: {props.year}
        </Typography>
      )}
    </Button>
  );
})(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  borderRadius: 0,
  borderBottom: `solid 1px ${theme.palette.gray3}`,
  color: theme.palette.softBlack,
  "&:hover": {
    backgroundColor: theme.palette.gray1,
  },
  "&.selected": {
    backgroundColor: theme.palette.lightOrange,
  },
}));

export interface CarDateExtraFieldProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  defaultValue?: CarDateExtra;
  minValue?: CarDateExtra;
  value?: CarDateExtra;
  onChange: (value: CarDateExtra) => void;
}

export const CarDateExtraField = ({
  defaultValue,
  value,
  onChange,
  SelectProps,
  error,
  ...props
}: CarDateExtraFieldProps) => {
  const defaultAge = defaultValue?.age ?? DEFAULT_AGE;
  const defaultYear = defaultValue?.year ?? DEFAULT_YEAR;
  const defaultKind = defaultValue?.kind ?? DEFAULT_KIND;
  const defaultAgeRelationshipId = defaultValue?.age_relationship;

  const planId = usePlanId();

  const [open, setOpen] = useState(false);
  const [id, setId] = useState(value?.id ?? v4());
  const [age, setAge] = useState(value?.age ?? defaultAge);
  const [year, setYear] = useState(value?.year ?? defaultYear);
  const [kind, setKind] = useState(value?.kind ?? defaultKind);
  const [relationshipId, setRelationshipId] = useState(
    value?.age_relationship ?? defaultAgeRelationshipId,
  );

  const [pendingChange, setPendingChange] = useState(false);

  const setInternalValue = useCallback(
    (value?: CarDateExtra) => {
      setId(value?.id ?? v4());
      setAge(value?.age ?? defaultAge);
      setYear(value?.year ?? defaultYear);
      setKind(value?.kind ?? defaultKind);
      setRelationshipId(value?.age_relationship ?? defaultAgeRelationshipId);
    },
    [defaultAge, defaultAgeRelationshipId, defaultKind, defaultYear],
  );

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  const getNewValue = useCallback<() => CarDateExtra | undefined>(() => {
    const value = {
      id,
      plan: planId,
      age,
      year,
      kind,
      age_relationship: relationshipId,
    };

    return isDateExtraValid(value) ? value : undefined;
  }, [planId, id, age, year, kind, relationshipId]);

  useEffect(() => {
    if (pendingChange) {
      setOpen(false);
      setPendingChange(false);
      const newValue = getNewValue();
      if (newValue) {
        onChange(newValue);
      } else {
        // restoring previous value
        setInternalValue(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingChange]);

  const handleKindChange = (kind: CarDateExtraKind) => {
    setKind(kind);
  };

  const relationship = useRelationshipLight();
  const secondaryClient = relationship.secondaryItem;
  const valueInfo = relationship.getDateExtraInfo(getNewValue());
  const firstName = valueInfo.relationship?.first_name;

  const currentYear = valueInfo.year;
  const minYear = relationship.getDateExtraInfo(props.minValue)?.year;

  let yearIsValid = true;

  if (isDefined(currentYear) && isDefined(minYear)) {
    yearIsValid = currentYear >= minYear;
  }

  const newProps = {
    ...props,
    error: error || !yearIsValid,
    SelectProps: {
      ...SelectProps,
      renderValue: (v: unknown) => {
        let label = dateExtraItems.find((i) => i.value === v)?.label ?? "";
        if (v === "YEAR") {
          return `${label}: ${year}`;
        }

        if (v === "AGE") {
          label = `${label}: ${age} - ${firstName}`;
        }

        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "baseline",
              justifyContent: "space-between",
              overflow: "hidden",
            }}
          >
            <Typography variant="par01Regular">{label}</Typography>
            {currentYear && (
              <Typography
                sx={{
                  ml: "auto",
                  pl: 0.5,
                  color: yearIsValid ? "gray6" : "red",
                  fontSize: "12px",
                }}
                variant="par01Regular"
              >
                {currentYear}
              </Typography>
            )}
          </Box>
        );
      },
    },
  };

  return (
    <CarSelectField
      {...newProps}
      sx={{
        ".MuiInputBase-input": {
          display: "flex",
          alignItems: "baseline",
        },
        ...newProps.sx,
      }}
      value={value?.kind}
      onChange={(value) => handleKindChange(value as CarDateExtraKind)}
      SelectProps={{
        ...newProps.SelectProps,
        open: open,
        onOpen: (e) => setOpen(true),
        onClose: (e) => {
          setOpen(false);
          if (!_.isEqual(value, getNewValue())) {
            setPendingChange(true);
          }
        },
      }}
    >
      <AgeMenuItem
        kind={kind}
        onKindChange={setKind}
        age={age}
        onAgeChange={setAge}
        relationshipId={relationshipId ?? undefined}
        onRelationshipIdChange={setRelationshipId}
        onApplyChanges={() => setPendingChange(true)}
      />
      <YearMenuItem
        kind={kind}
        onKindChange={setKind}
        year={year}
        onYearChange={setYear}
        onApplyChanges={() => setPendingChange(true)}
      />
      {dateExtraItems
        .filter((i) => i.value !== "AGE" && i.value !== "YEAR")
        .filter((i) => {
          // Remove secondary-type options if no secondary client
          if (i.value.startsWith("SECOND")) {
            return !!secondaryClient;
          }
          return true;
        })
        .map((i) => {
          const year = relationship.getDateExtraInfo({
            kind: i.value,
            plan: planId,
          }).year;

          return (
            <SimpleMenuItem
              key={i.value}
              id={i.value}
              kind={kind}
              onKindChange={setKind}
              onApplyChanges={() => setPendingChange(true)}
              label={i.label}
              year={year}
            />
          );
        })}
    </CarSelectField>
  );
};
