import {
  // useCommonBillingActivateBillingV2RetrieveQuery,
  useCommonBillingBillingGetInfoRetrieveQuery,
} from "api/carApi.generated";
import { isDefined, openInNewTab } from "utils";
import { PaymentCard, paymentCards } from "./PaymentCards";

const manageSubscriptionUrl =
  process.env.REACT_APP_MANAGE_SUBSCRIPTION_URL ?? "";

// "payment_method_details": {
//   "card": {
//     "brand": null,
//     "checks": null,
//     "country": null,
//     "exp_month": null,
//     "exp_year": null,
//     "fingerprint": null,
//     "funding": null,
//     "last4": null,
//     "network": null,
//     "three_d_secure": null,
//     "wallet": null
//   },
//   "type": "card"
// },

interface PaymentMethod {
  card: PaymentCard;
  cardNumber: string;
  expDate: string;
}

export const useSettingsBilling = () => {
  const info = useCommonBillingBillingGetInfoRetrieveQuery();

  // const activateBilling = useCommonBillingActivateBillingV2RetrieveQuery();

  const handleManageSubscription = () => {
    openInNewTab(manageSubscriptionUrl);
  };

  const invoices = Array.from(info.data?.customer?.invoices ?? []).sort(
    (a, b) => b.created?.localeCompare(a.created ?? "") ?? 0,
  );

  const nextBillingPeriod = info.data?.current_period_end;
  const subscriptionItem = info.data?.items?.[0];
  const unitAmount = (subscriptionItem?.price?.unit_amount ?? 0) / 100;
  const seats = subscriptionItem?.quantity ?? 0;
  const totalAmount = unitAmount * seats;

  const paymentMethods =
    info.data?.customer?.payment_methods
      ?.map<PaymentMethod | undefined>((i) => {
        const card = paymentCards.find((c) => c.brand === i.card?.brand);

        if (!card) {
          return undefined;
        }

        return {
          card,
          cardNumber: `•••• ${i.card?.last4}`,
          expDate: `${String(i.card?.exp_month).padStart(2, "0")}/${i.card
            ?.exp_year}`,
        };
      })
      .filter(isDefined) ?? [];

  return {
    isLoading: info.isLoading,
    seats,
    totalAmount,
    nextBillingPeriod,
    invoices,
    paymentMethods,
    handleManageSubscription,
  };
};

export type UseSettingsBilling = ReturnType<typeof useSettingsBilling>;
