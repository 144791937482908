import { BarElement, BarController } from "chart.js";

function getBarBounds(bar: BarElement, useFinalPosition?: boolean) {
  const { x, y, base, width, height } = bar.getProps(
    ["x", "y", "base", "width", "height"],
    useFinalPosition,
  );

  let left, right, top, bottom, half;

  half = width / 2;
  left = x - half + 0.5;
  right = x + half - 0.5;
  top = Math.min(y, base);
  bottom = Math.max(y, base);

  if ((bar as unknown as { horizontal: boolean }).horizontal) {
    half = height / 2;
    left = Math.min(x, base);
    right = Math.max(x, base);
    top = y - half;
    bottom = y + half;
  } else {
    half = width / 2;
    left = x - half;
    right = x + half;
    top = Math.min(y, base);
    bottom = Math.max(y, base);
  }

  return {
    left: Math.floor(left) + 0.5,
    top: Math.floor(top) + 0.5,
    right: Math.floor(right) + 0.5,
    bottom: Math.floor(bottom) + 0.5, // align borders
  };
}

export class SharpBarElement extends BarElement {
  static id = "sharpBarElement";

  draw(ctx: CanvasRenderingContext2D) {
    const {
      options: { backgroundColor, borderColor },
    } = this;
    const borderWidth = Number(this.options.borderWidth) || 0;

    const bounds = getBarBounds(this);

    ctx.save();

    ctx.beginPath();
    ctx.lineTo(bounds.left, bounds.top);
    ctx.lineTo(bounds.left, bounds.bottom);
    ctx.lineTo(bounds.right, bounds.bottom);
    ctx.lineTo(bounds.right, bounds.top);
    ctx.closePath();

    ctx.fillStyle = backgroundColor;
    ctx.fill();

    ctx.strokeStyle = borderWidth ? borderColor : backgroundColor;
    ctx.lineWidth = Number(borderWidth);
    ctx.stroke();

    ctx.restore();
  }
}

export class SharpBarController extends BarController {
  static id = "sharpBar";

  static defaults = {
    ...BarController.defaults,
    dataElementType: "sharpBarElement",
  };

  draw() {
    return super.draw();
  }
}
