import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
  styled,
} from "@mui/material";
import { RadioChecked, RadioUnchecked } from "icons";

export const CarRadio = styled((props: RadioProps) => (
  <Radio
    color="primary"
    checkedIcon={<RadioChecked />}
    icon={<RadioUnchecked />}
    size="small"
    {...props}
  />
))(({ theme }) => ({
  color: theme.palette.gray6,
  padding: theme.spacing(0.5),
  "&.Mui-checked": {
    color: theme.palette.caravelOrangePrimary,
    "&.Mui-disabled": {
      color: theme.palette.gray6,
    },
  },
  ".MuiSvgIcon-root": {
    width: 28,
    height: 28,
  },
}));

export interface CarRadioItem {
  label: string;
  value: any;
  sx?: FormControlLabelProps["sx"];
}
interface CarRadioGroupProps extends RadioGroupProps {
  items: Array<CarRadioItem>;
  disabled?: boolean;
}

export const CarRadioGroup = styled(
  ({ items, sx, className, disabled, ...props }: CarRadioGroupProps) => (
    <FormControl component="div" className={className} sx={sx}>
      <RadioGroup {...props}>
        {items.map((i, idx) => (
          <FormControlLabel
            sx={i.sx}
            key={idx}
            value={i.value}
            control={<CarRadio />}
            label={i.label}
            disabled={disabled}
            componentsProps={{
              typography: { variant: "par02Regular" },
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  ),
)(({ theme }) => ({
  ".MuiFormControlLabel-root": {
    marginLeft: 0,
  },
  ".MuiFormControlLabel-label": {
    marginLeft: theme.spacing(0.5),
  },
}));

interface CarRadioYesNoProps
  extends Omit<CarRadioGroupProps, "value" | "onChange" | "items"> {
  yesLabel?: string;
  noLabel?: string;
  value?: boolean;
  onChange: (value: boolean) => void;
}

export const CarRadioYesNo = ({
  value,
  yesLabel,
  noLabel,
  onChange,
  ...props
}: CarRadioYesNoProps) => {
  return (
    <CarRadioGroup
      items={[
        { label: yesLabel ?? "Yes", value: "1" },
        { label: noLabel ?? "No", value: "0" },
      ]}
      value={value ? "1" : "0"}
      onChange={(e) => onChange(e.target.value === "1")}
      {...props}
    />
  );
};
