import { Box, BoxProps, Typography } from "@mui/material";
import {
  CarPortfolioAssetColumnId,
  CarPortfolioAssetTableData,
} from "../usePortfolioGroupVersionData";
import { AssetClassLevel } from "const";
import { AssetAllocationModulePieChart } from "./AssetAllocationModulePieChart";
import { pendoClasses } from "app/thirdParty/pendo";

interface AssetAllocationPerModuleChartProps {
  sx?: BoxProps["sx"];
  columnId: CarPortfolioAssetColumnId;
  level: AssetClassLevel;
  tableData: CarPortfolioAssetTableData;
}

export const AssetAllocationPerModuleChart = ({
  sx,
  columnId,
  level,
  tableData,
}: AssetAllocationPerModuleChartProps) => {
  const rows = tableData.assetRows[level];

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        rowGap: 1,
        columnGap: 20,
        justifyContent: "center",
        maxWidth: 1200,
        ...sx,
      }}
    >
      {tableData.bands.map((band, idx) => (
        <Box
          key={band.moduleId}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="par02Bold" sx={{ mb: 3 }}>
            {band.label}
          </Typography>
          <AssetAllocationModulePieChart
            size={220}
            moduleId={band.moduleId}
            rows={rows}
            dataId={band.columns.find((c) => c.columnId === columnId)?.dataId}
            pendoClassName={pendoClasses.analyzePortfolioGroupAllocationPerModuleReportChart(
              idx,
            )}
          />
        </Box>
      ))}
    </Box>
  );
};
