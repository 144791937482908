import { Dispatch, SetStateAction, useState, useCallback } from "react";

export function useLocalStorage<S>(
  key: string | undefined,
  initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>] {
  let savedValue = key ? localStorage.getItem(key) : undefined;

  if (savedValue === "undefined") {
    savedValue = undefined;
  }

  initialState = savedValue ? (JSON.parse(savedValue) as S) : initialState;
  const [state, setState] = useState<S>(initialState);

  const setStorageState = useCallback(
    (action: SetStateAction<S>) => {
      setState((prevState) => {
        let newState: S;
        if (action instanceof Function) {
          newState = action(prevState);
        } else {
          newState = action;
        }
        if (key) {
          localStorage.setItem(key, JSON.stringify(newState));
        }
        return newState;
      });
    },
    [key],
  );

  return [state, setStorageState];
}

export function useSessionStorage<S>(
  key: string | undefined,
  initialState: S | (() => S),
): [S, Dispatch<SetStateAction<S>>] {
  let savedValue = key ? sessionStorage.getItem(key) : undefined;

  if (savedValue === "undefined") {
    savedValue = undefined;
  }

  initialState = savedValue ? (JSON.parse(savedValue) as S) : initialState;
  const [state, setState] = useState<S>(initialState);

  const setStorageState = useCallback(
    (action: SetStateAction<S>) => {
      setState((prevState) => {
        let newState: S;
        if (action instanceof Function) {
          newState = action(prevState);
        } else {
          newState = action;
        }
        if (key) {
          sessionStorage.setItem(key, JSON.stringify(newState));
        }
        return newState;
      });
    },
    [key],
  );

  return [state, setStorageState];
}
