import {
  StripeElements,
  StripePaymentElementOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { api } from "api/carApi.manual";
import { useAppDispatch } from "app/hooks";
import { useErrorToast } from "app/useSuccessToast";
import { useUser } from "pages/settings/useUser";
import { useState } from "react";
import { rootPages } from "route.const";
import { debugLog } from "utils";

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? "";

export const usePayment = () => {
  const [stripePromise] = useState(loadStripe(stripePublicKey));
  const errorToast = useErrorToast();
  const dispatch = useAppDispatch();
  const [isPaying, setIsPaying] = useState(false);

  const user = useUser();

  const handlePay = async (elements: StripeElements) => {
    setIsPaying(true);
    try {
      if (!stripePromise) {
        return;
      }

      const stripe = await stripePromise;

      if (!stripe || !elements) {
        return;
      }

      dispatch(api.util.invalidateTags(["Firm", "BillingCheckStatus"]));

      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}${rootPages.paymentComplete}`,
        },
      });

      debugLog({ stripeResult: result });
      if (result.error.message) {
        errorToast({
          message: result.error.message,
        });
      }
    } finally {
      setIsPaying(false);
    }
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    defaultValues: {
      billingDetails: {
        email: user.user.email,
        name: user.userFullName,
      },
    },
  };

  return {
    isLoading: !stripePromise || user.isLoading,
    handlePay,
    isPaying,
    stripePromise,
    paymentElementOptions,
  };
};

export type UsePayment = ReturnType<typeof usePayment>;
