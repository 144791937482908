import { Image, Text, View } from "@react-pdf/renderer";
import {
  fontPdf,
  FooterProps,
  HeaderCell,
  PdfCheckbox,
  PdfMarkdown,
  PdfPage,
  PdfSectionLabel,
  TextCell,
} from "components/Pdf";
import { formatCurrency, formatYear, priorityNumToString } from "utils";
import { PlanResultDonuts } from "../planResults/calcPlanResultDonuts";
import { SummaryGoal } from "../planSummary/usePlanSummary";
import { getPdfDonutChart } from "./getPdfDonutChart";

export interface GoalsActionPdfPageProps {
  footerProps: FooterProps;
  goals: SummaryGoal[];
  donuts: PlanResultDonuts;
}

export const GoalsActionPdfPage = (props: GoalsActionPdfPageProps) => {
  return (
    <PdfPage footerProps={props.footerProps}>
      <PdfSectionLabel label="Goals" />
      <PdfMarkdown style={{ paddingBottom: 10 }}>
        Displayed on this page is your list of goals (sorted by their priority)
        in your updated financial plan. _*Please note that all dollar amounts
        are represented in today’s dollars, or pre-inflation._
      </PdfMarkdown>
      <View
        style={{
          marginTop: 18,
          width: 180,
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            marginTop: 0,
            marginBottom: 5,
            textAlign: "center",
            fontFamily: fontPdf.table.family,
            fontWeight: "bold",
            fontSize: 16,
            color: "#4b4b4b",
          }}
        >
          {`Total Goals in Plan: ${props.donuts.total.totalCount}`}
        </Text>
        <Image
          style={{ width: 144, height: 144 }}
          source={getPdfDonutChart({
            color: "#00a45b",
            item: props.donuts.total,
            title: "Total Goals Funded",
          })}
        />
      </View>
      <View
        style={{
          marginTop: 40,
          fontFamily: fontPdf.table.family,
          fontSize: fontPdf.table.normal.size,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            fontSize: fontPdf.table.normal.headerSize,
          }}
          fixed
        >
          <HeaderCell style={{ flexShrink: 0.9, paddingVertical: 10 }}>
            Goals:
          </HeaderCell>
          <HeaderCell style={{ paddingVertical: 10 }}>Priority:</HeaderCell>
          <HeaderCell style={{ paddingVertical: 10 }}>
            * Annual Amount:
          </HeaderCell>
          <HeaderCell style={{ paddingVertical: 10 }}>Start:</HeaderCell>
          <HeaderCell style={{ paddingVertical: 10 }}>End:</HeaderCell>
          <HeaderCell
            style={{
              borderRightWidth: 1,
              paddingVertical: 10,
              paddingHorizontal: 5,
            }}
          >
            * Total Amount:
          </HeaderCell>
        </View>
        {props.goals.map((i, idx) => (
          <View
            key={idx}
            wrap={false}
            style={{
              flexDirection: "row",
            }}
          >
            <TextCell
              style={{
                textAlign: "left",
                paddingHorizontal: 5,
                flexShrink: 0.9,
              }}
            >
              {i.description}
            </TextCell>
            <TextCell>
              {priorityNumToString(i.priority).toLocaleUpperCase()}
            </TextCell>
            <TextCell>{formatCurrency(i.annualAmount)}</TextCell>
            <TextCell>{formatYear(i.startDate)}</TextCell>
            <TextCell>{formatYear(i.endDate)}</TextCell>
            <TextCell
              style={{
                borderRightWidth: 1,
              }}
            >
              {formatCurrency(i.totalAmountBeforeInflation)}
            </TextCell>
          </View>
        ))}
      </View>
      <PdfCheckbox style={{ marginTop: 25, alignSelf: "center", width: 380 }}>
        I/We approve the financial plan summarized above, and believe that the
        goals expressed and the estimated probability of achieving those goals
        are consistent with our long term financial plans.
      </PdfCheckbox>
    </PdfPage>
  );
};
