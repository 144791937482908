import { Box, styled, Typography } from "@mui/material";
import { CarIconDelete, CarIconDeleteFilled } from "icons";
import { CarTextField } from "components/Inputs";
import { CarButton, CarLoadingButton } from "components/Buttons";
import { useState, useEffect } from "react";
import { CarGrid } from "components/Grid";
import { useRoles } from "app/useRoles";
import { CarMenuButton } from "components/MenuButton";
import { UseSettingsFirmUsers } from "./useSettingsFirmUsers";
import { useDialog } from "app/useDialog";
import { AddSeatsDialog } from "./AddSeatsDialog";
import { CarRoleButton } from "./RoleButton";

const StyledField = styled(CarTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.white,
    borderRadius: "5px",
    "& input": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      ...theme.typography.par01Regular,
      color: theme.palette.black,
    },
    "& fieldset": {
      borderRadius: "5px",
    },
    ".MuiSvgIcon-root": {
      width: 24,
      height: 24,
      color: theme.palette.softBlack,
    },
  },
}));

const StyledButton = styled(CarLoadingButton)(({ theme }) => ({
  height: 50,
  width: 150,
  flex: "none",
  ...theme.typography.par02SemiBold,
}));

export const SectionFirmUsers = ({ data }: { data: UseSettingsFirmUsers }) => {
  const roles = useRoles();
  const dialog = useDialog();

  const [inviteEmail, setInviteEmail] = useState<string | undefined>("");
  const [inviteRole, setInviteRole] = useState<string | undefined>();

  useEffect(() => {
    if (!inviteRole && roles.items.length > 0) {
      setInviteRole(roles.items[0].id);
    }
  }, [inviteRole, roles.items]);

  const handleAddSeats = () => {
    dialog(AddSeatsDialog, {});
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          px: 3,
          py: 2.25,
          border: "1px solid",
          borderColor: "primary.main",
          borderRadius: "5px",
          backgroundColor: "lightOrange",
          alignItems: "center",
        }}
      >
        <Typography variant="par02Regular">
          <Typography component="span" variant="par02SemiBold">
            Your seats are maxed out.
          </Typography>{" "}
          Would you like to add more seat licenses?
        </Typography>
        <CarButton
          variant="outlined"
          sx={{
            ml: "auto",
            height: 33,
            fontSize: "13px",
            width: 110,
            borderColor: "primary.main",
            "&.MuiButton-outlinedPrimary:hover": {
              backgroundColor: "white",
            },
          }}
          onClick={handleAddSeats}
        >
          Add Seats
        </CarButton>
      </Box>
      <Box
        sx={{
          mr: 1.25,
          display: "flex",
          justifyContent: "stretch",
          alignItems: "center",
          width: 540,
          gap: 2,
        }}
      >
        <StyledField
          value={inviteEmail}
          onChange={setInviteEmail}
          placeholder="Invite by email"
          fullWidth
          inputProps={{
            type: "email",
            autoComplete: "off",
            spellCheck: false,
            autoCapitalize: "off",
          }}
          InputProps={{
            endAdornment: (
              // <Box
              //   sx={{
              //     display: "flex",
              //     alignItems: "center",
              //     justifyContent: "center",
              //     gap: 2,
              //   }}
              // >
              //   <Box
              //     sx={{
              //       my: -1,
              //       borderLeft: "solid 1px black",
              //       borderColor: "gray3",
              //       width: "1px",
              //       alignSelf: "stretch",
              //     }}
              //   />
              <CarRoleButton
                sx={{
                  flex: "none",
                  alignSelf: "stretch",
                  borderLeft: "solid 1px black",
                  borderColor: "gray3",
                  pl: 2,
                  ".MuiButton-endIcon > svg.MuiSvgIcon-root": {
                    color: "caravelOrangePrimary",
                  },
                }}
                roles={data.roles}
                value={inviteRole}
                onChange={setInviteRole}
              />
              // </Box>
            ),
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              data.handleSendInvite(inviteEmail, inviteRole);
            }
          }}
        />
        <StyledButton
          isLoading={data.isInviting}
          onClick={() => {
            data.handleSendInvite(inviteEmail, inviteRole);
          }}
        >
          Invite
        </StyledButton>
      </Box>
      {data.users.length > 0 && (
        <CarGrid
          noScroll
          headerSx={{ pl: 1.5, mb: 1 }}
          getRowSx={() => ({ borderWidth: "2px", py: 1.5, px: 1.5 })}
          rows={data.users}
          cellVariant="par01Regular"
          columns={[
            {
              fraction: 1.5,
              label: "Name",
              field: "name",
              // onGetValue: (row) =>
            },
            {
              fraction: 1.5,
              label: "Email Address",
              field: "email",
            },
            {
              label: "Status",
              field: "statusName",
            },
            {
              label: "User Role",
              field: "roleName",
              onCellRender: (row) => (
                <Box sx={{ display: "flex", position: "relative", height: 34 }}>
                  <Box
                    sx={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      bottom: 0,
                      right: 0,
                    }}
                  >
                    <CarRoleButton
                      sx={{
                        ml: -1.25,
                        height: 34,
                        maxWidth: "100%",
                        ".MuiTypography-root": {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          minWidth: 0,
                        },
                      }}
                      roles={data.roles}
                      disabled={row.isCurrentUser}
                      value={row.role}
                      onChange={(role) => {
                        if (row.isInvitation) {
                          data.handleChangeInvitationRole(row.id, role);
                        } else {
                          data.handleChangeUserRole(row.id, role);
                        }
                      }}
                    />
                  </Box>
                </Box>
              ),
            },
            {
              label: "Actions",
              fraction: "40px",
              onCellRender: (row) => {
                return row.isCurrentUser ? undefined : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CarMenuButton
                      items={[
                        {
                          label: "Remove Invitation",
                          icon: <CarIconDelete />,
                          onClick: () => data.handleRemoveInvitation(row.id),
                          hidden: !row.isInvitation,
                        },
                        {
                          label: "Delete",
                          icon: <CarIconDeleteFilled />,
                          onClick: () => data.handleDeleteUser(row.id),
                          hidden: row.isInvitation,
                        },
                      ]}
                      position="left"
                    />
                  </Box>
                );
              },
            },
          ]}
          defaultGridSort={{
            field: "name",
            direction: "asc",
          }}
          onSortItems={(gridSort, a, b) => {
            const getSortValue = (): number => {
              switch (gridSort.field) {
                case "name":
                  return a.name.localeCompare(b.name);
                case "email":
                  return a.email?.localeCompare(b.email ?? "") ?? 0;
                case "roleName":
                  return a.roleName.localeCompare(b.roleName);
                case "statusName":
                  return a.statusName.localeCompare(b.statusName);
                case "last_active":
                  return a.last_active?.localeCompare(b.last_active ?? "") ?? 0;

                default:
                  return 0;
              }
            };

            return gridSort.direction === "asc"
              ? getSortValue()
              : getSortValue() * -1;
          }}
        />
      )}
    </Box>
  );
};
