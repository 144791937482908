import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "../../usePortfolioGroupInfo";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { usePortfolioGroupVersionData } from "../../usePortfolioGroupVersionData";
import { AnalyzeModuleStyledPage } from "./AnalyzeModuleStyledPage";
import { useShowConfigSelector } from "../useShowConfigSelector";
import { ShowConfigSelector } from "../ShowConfigSelector";
import { ChartHistoricalMarketSummary } from "../ChartHistoricalMarketsSummary";
import { Typography } from "@mui/material";
import { PortfolioGroupAssetStatTable } from "pages/assetAllocation/PortfolioGroupAssetStatTable";
import { usePortfolioGroupVersionStatTableData } from "pages/assetAllocation/usePortfolioGroupVersionStatTableData";

interface AnalyzeModuleScenarioAnalysisProps {
  moduleId: string;
}

export const AnalyzeModuleScenarioAnalysis = (
  props: AnalyzeModuleScenarioAnalysisProps,
) => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const pageContent = usePageContent();

  const { moduleId } = props;

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const showConfigSelector = useShowConfigSelector({
    portfolioGroupId,
    portfolioGroupVersionId,
    publishedVersionId,
  });

  const data = usePortfolioGroupVersionData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    show: showConfigSelector.config,
    hideCash: true,
    filterByModuleId: moduleId,
  });

  const versionStatTableData = usePortfolioGroupVersionStatTableData({
    bands: data.tableData.bands,
    skip: data.assetAllocationIsLoading,
  });

  return (
    <AnalyzeModuleStyledPage>
      <TablePlaceHolder
        isLoading={
          groupInfo.isLoading ||
          data.isLoading ||
          versionStatTableData.isLoading ||
          pageContent.isLoading
        }
        rows={5}
      >
        <CarPageContent
          sx={{ mb: 5 }}
          isWhiteContext
          content={pageContent.getContent(
            groupInfo.isSinglePortfolioMode
              ? CarPageContentCode.PORTFOLIO_ANALYSIS_ANALYZE_SCENARIO_ANALYSIS
              : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SCENARIO_ANALYSIS,
          )}
        />
        <ShowConfigSelector showConfigSelector={showConfigSelector} />
        <Typography variant="h6SemiBold" sx={{ mt: 5, ml: 10 }}>
          Historical Markets Summary
        </Typography>
        <ChartHistoricalMarketSummary
          sx={{ mt: 1 }}
          data={versionStatTableData.statTableData}
          moduleId={moduleId}
        />
        <PortfolioGroupAssetStatTable
          sx={{ mt: 5 }}
          tableData={data.tableData}
          rows={versionStatTableData.statTableData.historicalStatRows}
          showPercentile={false}
        />
      </TablePlaceHolder>
    </AnalyzeModuleStyledPage>
  );
};
