import { CSSTransition } from "react-transition-group";
import { PlaceHolder } from "./PlaceHolder";
import { Box, BoxProps, styled } from "@mui/material";
import { PropsWithChildren } from "react";

interface StyleProps {
  needPercent: number;
  wantPercent: number;
  dreamPercent: number;
}

const size = 218;
const margin = size / 2;
const lineWidth = 16;

const needRadius = 101;
const calcNeedDashArray = (percent: number) =>
  (percent / 100) * 2 * Math.PI * needRadius;

const wantRadius = 74;
const calcWantDashArray = (percent: number) =>
  (percent / 100) * 2 * Math.PI * wantRadius;

const dreamRadius = 47.54;
const calcDreamDashArray = (percent: number) =>
  (percent / 100) * 2 * Math.PI * dreamRadius;

const DonutCircle = styled("svg")<StyleProps>(
  ({ theme, needPercent, wantPercent, dreamPercent }) => ({
    position: "absolute",
    width: "100%",
    height: "100%",

    ".ring": {
      stroke: theme.palette.gray2,
      fill: "transparent",
    },
    ".need": {
      fill: "transparent",
      stroke: theme.palette.caravelBlueSecondary,
      transition: `stroke-dasharray 1s`,
      strokeDasharray: `${calcNeedDashArray(0)}, ${calcNeedDashArray(100)}`,
      strokeDashoffset: calcNeedDashArray(25),
    },
    ".needDone": {
      strokeDasharray: `${calcNeedDashArray(needPercent)}, ${calcNeedDashArray(
        100 - needPercent
      )}`,
      opacity: needPercent ? 1 : 0,
    },
    ".want": {
      fill: "transparent",
      stroke: theme.palette.caravelOrangePrimary,
      transition: `stroke-dasharray 1s`,
      strokeDasharray: `${calcWantDashArray(0)}, ${calcWantDashArray(100)}`,
      strokeDashoffset: calcWantDashArray(25),
    },
    ".wantDone": {
      strokeDasharray: `${calcWantDashArray(wantPercent)}, ${calcWantDashArray(
        100 - wantPercent
      )}`,
      opacity: wantPercent ? 1 : 0,
    },
    ".dream": {
      fill: "transparent",
      stroke: theme.palette.yellow,
      transition: `stroke-dasharray 1s`,
      strokeDasharray: `${calcDreamDashArray(0)}, ${calcDreamDashArray(100)}`,
      strokeDashoffset: calcDreamDashArray(25),
    },
    ".dreamDone": {
      strokeDasharray: `${calcDreamDashArray(
        dreamPercent
      )}, ${calcDreamDashArray(100 - dreamPercent)}`,
      opacity: dreamPercent ? 1 : 0,
    },
  })
);

interface CircleProps {
  radius: number;
  className: string;
  classNameDone: string;
}

const Circle = (props: CircleProps) => (
  <>
    <circle
      className="ring"
      cx={margin}
      cy={margin}
      r={props.radius}
      strokeWidth={lineWidth}
    />
    <CSSTransition
      in
      timeout={1}
      classNames={{
        enterActive: props.classNameDone,
        enterDone: props.classNameDone,
      }}
      appear
    >
      <circle
        className={props.className}
        cx={margin}
        cy={margin}
        r={props.radius}
        strokeWidth={lineWidth}
        strokeLinecap="round"
      />
    </CSSTransition>
  </>
);

export interface CarDonutProps {
  needPercent: number;
  wantPercent: number;
  dreamPercent: number;
  isLoading?: boolean;
  sx?: BoxProps["sx"];
}

export const CarDonut = (props: PropsWithChildren<CarDonutProps>) => (
  <Box
    sx={{
      position: "relative",
      width: size,
      height: size,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      ...props.sx,
    }}
  >
    {props.isLoading ? (
      <PlaceHolder
        sx={{ width: "100%", height: "100%", borderRadius: "50%" }}
      />
    ) : (
      <>
        <DonutCircle
          viewBox={`0 0 ${size} ${size}`}
          needPercent={props.needPercent}
          wantPercent={props.wantPercent}
          dreamPercent={props.dreamPercent}
        >
          <Circle
            radius={needRadius}
            className="need"
            classNameDone="needDone"
          />
          <Circle
            radius={wantRadius}
            className="want"
            classNameDone="wantDone"
          />
          <Circle
            radius={dreamRadius}
            className="dream"
            classNameDone="dreamDone"
          />
        </DonutCircle>
        {props.children}
      </>
    )}
  </Box>
);
