import { BaseDialog } from "components/BaseDialog";
import { Box, Typography } from "@mui/material";
import { JobStatus } from "./JobStatus";
import { usePortfolioGroupOptimizerRuns } from "./usePortfolioGroupOptimizerRuns";
import { Ref, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { caravelColors } from "theme";
import { OptimizationSubJob } from "./useOptimizationJobs";
import { isDefined } from "utils";

export interface OptimizePortfolioGroupOverlayRef {
  close: () => void;
  waitAndClose: (jobId: string) => void;
}

export interface OptimizePortfolioGroupOverlayProps {
  portfolioGroupId: string;
  onApply: () => void;
  onCancel: () => void;
  customRef?: Ref<OptimizePortfolioGroupOverlayRef>;
}

export const OptimizePortfolioGroupOverlay = (
  props: OptimizePortfolioGroupOverlayProps,
) => {
  const portfolioGroupOptimizerRuns = usePortfolioGroupOptimizerRuns({
    portfolioGroupId: props.portfolioGroupId,
  });

  const completedJobIds = useMemo(
    () =>
      portfolioGroupOptimizerRuns.items
        .filter((i) => i.jobs.every((j) => j.isCompleted || j.isError))
        .map((i) => i.id),

    [portfolioGroupOptimizerRuns.items],
  );

  const [jobId, setJobId] = useState<string | undefined>();

  useEffect(() => {
    if (isDefined(jobId) && completedJobIds.includes(jobId)) {
      props.onCancel();
    }
  }, [completedJobIds, jobId, props, portfolioGroupOptimizerRuns]);

  useImperativeHandle(props.customRef, () => ({
    close: () => {
      props.onCancel();
    },
    waitAndClose: (jobId) => {
      setJobId(jobId);
    },
  }));

  const getStatus = (i: OptimizationSubJob) => {
    let statusText = "";
    let statusColor = "";
    let statusDescription = "";
    let inProgress = false;
    if (i.isCompleted) {
      statusText = "Success:";
      statusColor = caravelColors.green;
      statusDescription = "Solution found";
    } else if (i.isError) {
      statusText = "Failed:";
      statusColor = caravelColors.red;
      statusDescription = "No solution found";
    } else {
      statusText = "In Progress:";
      statusColor = caravelColors.softBlack;
      statusDescription = "Searching for solution";
      inProgress = true;
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: inProgress ? "gray3" : undefined,
        }}
      >
        <Typography variant="h6SRegular">
          <Box sx={{ color: statusColor, fontWeight: "600" }} component="span">
            {statusText}
          </Box>{" "}
          {statusDescription}
        </Typography>
      </Box>
    );
  };

  const currentJob = portfolioGroupOptimizerRuns.items.find(
    (i) => i.id === jobId,
  );

  return (
    <BaseDialog
      onClose={props.onCancel}
      sx={{
        width: "100vw",
        maxWidth: "100vw",
        height: "100vh",
        maxHeight: "100vh",
        borderRadius: "0",
        m: 0,
        p: 0,
      }}
      actions={null}
      hideCloseButton
    >
      <Box
        sx={{
          flex: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2.5,
          }}
        >
          <JobStatus
            sx={{
              width: 100,
              height: 100,
              "circle.percent": {
                stroke: "rgba(241, 150, 73, 1)",
              },
            }}
            completedCount={0}
            failedCount={0}
            jobCount={1}
            hideLabel
          />
          <Typography
            sx={{
              whiteSpace: "pre-line",
              textAlign: "center",
              lineHeight: "31px",
            }}
            variant="par03Regular"
          >
            {`Please wait.\nWe’re optimizing your portfolio group.\nThis may take a few minutes.`}
          </Typography>
        </Box>
        <Box sx={{ flexBasis: 100, flexShrink: 1 }} />
        {currentJob && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 743,
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                borderBottom: "1px solid",
                borderBottomColor: "gray6",
                pb: 1,
              }}
            >
              <Typography sx={{ textAlign: "center" }} variant="caption">
                PORTFOLIO MODEL
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="caption">
                STATUS
              </Typography>
            </Box>
            {currentJob.jobs.map((i) => (
              <Box
                key={i.id}
                sx={{
                  height: 60,
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  border: "1px solid",
                  borderColor: "gray6",
                  borderRadius: "5px",
                  overflow: "hidden",
                  backgroundColor: "gray1",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRight: "1px solid",
                    borderRightColor: "gray6",
                  }}
                >
                  <Typography variant="h6SSemiBold">{i.moduleTitle}</Typography>
                </Box>
                {getStatus(i)}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </BaseDialog>
  );
};
