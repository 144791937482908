import { Box, styled } from "@mui/material";
import { CarTableMultiRow } from "components/Table";
import { columns } from "./RealEstateColumns";
import { RealEstateRowProps } from "./real-estate-types";
import { memo } from "react";
import { setDisplayName } from "utils";

const StyledRoot = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

export const RealEstateRowFutureOwnerOccupied = memo(
  (props: RealEstateRowProps) => (
    <StyledRoot className={props.className}>
      <CarTableMultiRow
        item={props.item}
        rows={[
          [
            columns.realEstateType,
            columns.description,
            columns.realEstateValue,
            columns.costBasis,
            columns.mortgageBalance,
            columns.interestRate,
            columns.sellDate,
          ],
          [
            columns.purchaseDate,
            columns.primaryResidence,
            columns.piPayment,
            columns.monthlyTotalPayment,
            columns.maturityYear,
          ],
        ]}
        onChange={props.onChange}
        onDelete={props.onDelete}
        pendoPrefix={props.pendoPrefix}
      />
    </StyledRoot>
  )
);

setDisplayName({ RealEstateRowFutureOwnerOccupied });
