import { Typography } from "@mui/material";
import { BoxProps, Box } from "@mui/system";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { useState } from "react";
import { CarRadioYesNo } from "components/Radio";
import { RiskStatTypeCodeEnum, useRiskStatType } from "app/useRiskStatType";
import { PortfolioGroupComparisonChart } from "./PortfolioGroupComparisonChart";
import { UsePortfolioGroupSummary } from "./usePortfolioGroupSummary";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

export interface PortfolioGroupComparisonProps {
  portfolioGroupSummary: UsePortfolioGroupSummary;
  sx?: BoxProps["sx"];
}

export const PortfolioGroupComparison = ({
  portfolioGroupSummary,
  sx,
}: PortfolioGroupComparisonProps) => {
  const riskStatType = useRiskStatType();
  const [isTimeHorizon, setIsTimeHorizon] = useState(false);

  const xStatId = isTimeHorizon
    ? riskStatType.getRiskStatTypeIdByCode(
        RiskStatTypeCodeEnum.BREAKEVEN_HORIZON,
      )
    : riskStatType.getRiskStatTypeIdByCode(RiskStatTypeCodeEnum.VOLATILITY);

  const yStatId = riskStatType.getRiskStatTypeIdByCode(
    RiskStatTypeCodeEnum.EXPECTED_RETURN,
  );

  return (
    <RootPageBox sx={{ p: 3.5, pb: 2, ...sx }}>
      <TablePlaceHolder
        isLoading={riskStatType.isLoading || portfolioGroupSummary.isLoading}
        rows={5}
      >
        <>
          <Typography variant="h5SSemiBold" sx={{ mb: 0.5 }}>
            Efficient Frontier
            <CarTooltipBox
              sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
              className={pendoClasses.assetAllocationDashboardEfficientFrontier}
            />
          </Typography>
          {portfolioGroupSummary.isEmpty ? (
            <Box
              sx={{
                border: "1px solid",
                borderColor: "gray6",
                backgroundColor: "gray1",
                p: 2,
                flex: "1 1 auto",
                mb: 2,
              }}
            >
              <Typography variant="par01Regular">No Data Available</Typography>
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <PortfolioGroupComparisonChart
                statTableData={portfolioGroupSummary.statTableData}
                xStatId={xStatId}
                yStatId={yStatId}
                selectedModuleId={portfolioGroupSummary.selectedModuleId}
                onSelectedModuleIdChange={
                  portfolioGroupSummary.setSelectedModuleId
                }
              />
              <CarRadioYesNo
                sx={{
                  ml: 6,
                  alignSelf: "center",
                  gap: 2,
                  ".MuiFormControlLabel-label": {
                    fontSize: "13px",
                  },

                  ".MuiRadio-root .MuiSvgIcon-root": {
                    width: "25px",
                    height: "25px",
                  },
                }}
                value={isTimeHorizon}
                row
                yesLabel="B/E time horizon"
                noLabel="Volatility"
                onChange={setIsTimeHorizon}
              />
            </Box>
          )}
        </>
      </TablePlaceHolder>
    </RootPageBox>
  );
};
