import { Box, Typography } from "@mui/material";
import { useHouseholdAllocation } from "features/householdAllocation/useHouseholdAllocation";
import { CarRadioYesNo } from "components/Radio";
import { useState } from "react";
import { AllocationBreakdownTable } from "components/AllocationBreakdown";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarDisclosure } from "components/Disclosure";
import { usePlanTypes } from "app/usePlanTypes";
import { AssetClassLevel, PlanTypeCode } from "const";
import { usePlanTypeId } from "app/usePlanId";
import { CarInfoExpandableRow } from "components/InfoTable";
import { CarAllocAsset } from "app/useAllocAssets";
import { formatCurrency, formatPercent } from "utils";
import { CarAssetClassLevelField } from "components/Inputs";
import { CarAllocationChart } from "components/AllocationChart";
import { PlanProposedAllocationTable } from "features/householdAllocation/PlanProposedAllocationTable";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";

export const HouseholdAllocation = () => {
  const pageContent = usePageContent();
  const data = useHouseholdAllocation();
  const { items } = usePlanTypes();
  const planTypeId = usePlanTypeId();
  const selectedPlanTypeCode = items.find((i) => i.id === planTypeId)?.code;
  const showBreakdown =
    selectedPlanTypeCode !== PlanTypeCode.existing &&
    selectedPlanTypeCode !== PlanTypeCode.taxDefer;

  const { getPlanTypeLabel } = usePlanTypes();
  const portfolioLabel = getPlanTypeLabel(planTypeId);

  const [isPercentMode, setIsPercentMode] = useState(true);

  const [assetClassLevel, setAssetClassLevel] = useState<AssetClassLevel>(
    AssetClassLevel.level1,
  );

  let letSelectedLevelItems: Array<CarAllocAsset> = [];
  switch (assetClassLevel) {
    case "level1":
      letSelectedLevelItems = data.allocAssets.level1;
      break;
    case "level2":
      letSelectedLevelItems = data.allocAssets.level2;
      break;
    case "level3":
      letSelectedLevelItems = data.allocAssets.level3;
      break;
    case "level4":
      letSelectedLevelItems = data.allocAssets.level4;
      break;
  }

  const getAssetBreakdownRow = (level: number) => (i: CarAllocAsset) => {
    const values = i.years.map((v) =>
      isPercentMode ? formatPercent(v.percentage) : formatCurrency(v.dollar),
    );
    return (
      <CarInfoExpandableRow
        key={i.id}
        caption={i.name}
        values={values}
        level={level}
        childContent={i.children.map(getAssetBreakdownRow(level + 1))}
        gridTemplateColumns={`2fr repeat(${values.length}, 1fr)`}
      />
    );
  };

  const breakdownSection = (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}></Box>
      {data.breakdown.length > 0 && (
        <AllocationBreakdownTable
          sx={{ mt: 3 }}
          items={data.breakdown}
          footer={
            <Box
              sx={{
                gridColumn: `1 / span ${data.breakdown.length + 1}`,
                display: "grid",
                gridTemplateColumns: "1fr",
              }}
            >
              {data.allocAssets.level1.map(getAssetBreakdownRow(0))}
            </Box>
          }
        />
      )}
      <CarDisclosure sx={{ mt: 3.5, ml: 1 }}>
        Recommended allocations to achieve the best plan results by time horizon
        are included. Recommendations include allocation of assets to taxable
        and non-taxable accounts is for informational purposes only. Consult
        your tax professional before implementing any tax strategy.
      </CarDisclosure>
      <CarDisclosure sx={{ mt: 2, ml: "auto" }}>
        *Inflated Future Value
      </CarDisclosure>
    </>
  );

  return (
    <TablePlaceHolder
      isLoading={pageContent.isLoading || data.isLoading}
      rows={10}
    >
      <CarPageContent
        isWhiteContext
        content={pageContent.getContent(
          CarPageContentCode.PLAN_WORKBENCH_HOUSEHOLD_ALLOCATION_DIRECTIONS,
        )}
      />

      <Box sx={{ display: "flex", mt: 4, gap: 1, alignItems: "center" }}>
        <Typography variant="par02Regular">
          How would you like the allocation displayed?
        </Typography>
        <CarRadioYesNo
          sx={{ my: 1 }}
          row
          yesLabel="%"
          noLabel="$"
          value={isPercentMode}
          onChange={setIsPercentMode}
        />
      </Box>
      {showBreakdown && breakdownSection}
      <Box
        sx={{
          position: "relative",
          mt: showBreakdown ? 7.5 : 4,
          mb: 5,
          alignSelf: "flex-start",
        }}
      >
        <CarAssetClassLevelField
          sx={{
            width: 150,
            ".MuiInputBase-input": {
              backgroundColor: "white",
            },
          }}
          label="Asset Class Level:"
          hideLevel4
          value={assetClassLevel}
          onChange={setAssetClassLevel}
        />
        <CarTooltipBox
          sx={{ position: "absolute", top: -2, right: 20 }}
          className={pendoClasses.workbenchHouseholdAllocationAssetClassLevel}
        />
      </Box>
      <CarAllocationChart
        items={letSelectedLevelItems}
        isPercentMode={isPercentMode}
        isLarge={assetClassLevel === "level1"}
        portfolioLabel={portfolioLabel}
      />
      <PlanProposedAllocationTable
        sx={{ mt: 8 }}
        items={data.allocAssets.level1}
        isPercentMode={isPercentMode}
        portfolioLabel={portfolioLabel}
      />
    </TablePlaceHolder>
  );
};
