import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import { AssetAllocationPerModuleChart } from "../AssetAllocationPerModuleChart";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import {
  CarPortfolioAssetColumnEnum,
  usePortfolioGroupVersionData,
} from "../../usePortfolioGroupVersionData";
import { usePortfolioGroupInfo } from "../../usePortfolioGroupInfo";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarAssetClassLevelField } from "components/Inputs";
import { useSessionStorage } from "features/general/useLocalStorage";
import { useChartDataSelector } from "../useChartDataSelector";
import { AssetAllocationPerModuleTable } from "../AssetAllocationPerModuleTable";
import { AssetClassLevel } from "const";
import { SelectAssetColumnField } from "../SelectAssetColumnField";
import { InternalUseWithDisclosures } from "../InternalUse";
import { CarSwitch } from "components/Switch";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

export const AnalyzeSummary_assetClassLevel = "AnalyzeSummary_assetClassLevel";
interface AnalyzeSummaryProps {
  reportFocusSelector: ReactElement;
}

export const AnalyzeSummary = (props: AnalyzeSummaryProps) => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const data = usePortfolioGroupVersionData({
    versionId: groupInfo.portfolioGroupVersionId,
    publishedVersionId: groupInfo.publishedVersionId,
    hideCash: true,
  });

  const chartDataSelector = useChartDataSelector({
    storageKey: "AnalyzeSummary",
    data1DefaultId: CarPortfolioAssetColumnEnum.selected,
    data2DefaultId: CarPortfolioAssetColumnEnum.benchmark,
  });

  const [assetClassLevel, setAssetClassLevel] =
    useSessionStorage<AssetClassLevel>(
      AnalyzeSummary_assetClassLevel,
      AssetClassLevel.level1,
    );

  const [hideZeroValues, setHideZeroValues] = useSessionStorage<boolean>(
    "AnalyzeSummary_hideZeroValues",
    false,
  );

  const selectorItems =
    data.tableData.bands[0]?.columns.map((col) => ({
      label:
        col.columnId === CarPortfolioAssetColumnEnum.selected
          ? "Selected"
          : col.label,
      value: col.columnId,
    })) ?? [];

  // const handleShowPdf = useCallback(() => {
  //   if (portfolioGroupId && portfolioGroupVersionId) {
  //     openInNewTab(
  //       rootPages.getAssetAllocationSummaryReportUrl({
  //         portfolioGroupId,
  //         portfolioGroupVersionId,
  //         assetClassLevel,
  //         column1Id: chartDataSelector.data1Id ?? "",
  //         column2Id: chartDataSelector.data2Id ?? "",
  //       }),
  //     );
  //   }
  // }, [
  //   assetClassLevel,
  //   chartDataSelector.data1Id,
  //   chartDataSelector.data2Id,
  //   portfolioGroupId,
  //   portfolioGroupVersionId,
  // ]);

  return (
    <TablePlaceHolder isLoading={data.isLoading} rows={8} sx={{ mt: 5 }}>
      {/* <CarDownloadButton
        onClick={handleShowPdf}
        sx={{ zIndex: 1, mt: -1, mb: -3, alignSelf: "flex-end" }}
      >
        Download Reports
      </CarDownloadButton> */}

      <Box sx={{ mt: 5, display: "flex", gap: 3.5 }}>
        {props.reportFocusSelector}
        <CarAssetClassLevelField
          sx={{
            width: 150,
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              mt: 3,
            },
            ".MuiInputBase-input": {
              backgroundColor: "gray1",
            },
          }}
          label={
            <>
              Asset Class Level
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={pendoClasses.editPortfolioAnalyzeAssetClassLevel}
              />
            </>
          }
          value={assetClassLevel}
          onChange={setAssetClassLevel}
        />
        <SelectAssetColumnField
          sx={{
            width: 150,
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              mt: 3,
            },
            ".MuiInputBase-input": {
              backgroundColor: "gray1",
            },
          }}
          label={
            <>
              Data
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={pendoClasses.editPortfolioAnalyzeData}
              />
            </>
          }
          value={chartDataSelector.data1Id}
          onChange={chartDataSelector.onData1IdChange}
          items={selectorItems}
        />
        <InternalUseWithDisclosures sx={{ ml: "auto" }} />
      </Box>

      <AssetAllocationPerModuleChart
        sx={{ mt: 8, alignSelf: "center" }}
        columnId={chartDataSelector.data1Id ?? ""}
        level={assetClassLevel}
        tableData={data.tableData}
      />
      <Box
        sx={{
          mt: 4,
          ml: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="par02Regular">Hide 0 Values</Typography>
        <CarSwitch
          checked={hideZeroValues}
          onChange={(e, value) => setHideZeroValues(value)}
        />
      </Box>
      <AssetAllocationPerModuleTable
        sx={{ mt: 1 }}
        columnId={chartDataSelector.data1Id ?? ""}
        level={assetClassLevel}
        hideZeroValues={hideZeroValues}
        tableData={data.tableData}
      />
    </TablePlaceHolder>
  );
};
