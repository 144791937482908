import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo } from "react";
import { InternalUseWithDisclosures } from "../assetAllocation/editPortfolioGroup/InternalUse";
import { CarDownloadButton } from "components/Buttons";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { ChartSimulatedForwardProbabilitiesAC } from "./ChartSimulatedForwardProbabilitiesAC";
import { useSimulatedForwardProbabilitiesAC } from "./useSimulatedForwardProbabilitiesAC";
import { useSimulationGroups } from "pages/assetAllocation/useSimulationGroups";
import { useSimulationSimulationJobLatestSimulationRetrieveQuery } from "api/carApi.generated";

export const AssetClassAnalysisSimulatedForwardProbabilities = memo(() => {
  const simulationGroups = useSimulationGroups();
  const latestSimulation =
    useSimulationSimulationJobLatestSimulationRetrieveQuery();
  const simulationJobId1 = latestSimulation.data?.id;
  const simulationJobId2 = latestSimulation.data
    ? latestSimulation.data?.is_static
      ? simulationGroups.lastPriceDrivenSimulationJobId
      : simulationGroups.lastStaticSimulationJobId
    : undefined;

  const data = useSimulatedForwardProbabilitiesAC({
    simulationJobId1,
    simulationJobId2,
  });

  const pageContent = usePageContent();

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 10 }}
      isLoading={
        simulationGroups.isLoading ||
        latestSimulation.isLoading ||
        data.isLoading ||
        pageContent.isLoading
      }
      rows={8}
    >
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
          Simulated Forward Probabilities
        </Typography>
        <CarDownloadButton onClick={data.handleShowPdf} sx={{ ml: "auto" }}>
          Download Current
        </CarDownloadButton>
        <CarDownloadButton onClick={data.handleShowPdfAll}>
          Download All
        </CarDownloadButton>
      </Box>

      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_SIMULATED_FORWARD_PROBABILITY_DIRECTIONS,
        )}
      />
      <Box
        sx={{
          mt: 3,
          alignSelf: "stretch",
          display: "flex",
          alignItems: "center",
        }}
      >
        <InternalUseWithDisclosures sx={{ ml: "auto" }} />
      </Box>
      <ChartSimulatedForwardProbabilitiesAC data={data} isWhiteContext />
    </TablePlaceHolder>
  );
});
