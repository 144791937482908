import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo } from "react";
import { useAssetClassExtendedStat } from "./useAssetClassExtendedStat";
import { InternalUseWithDisclosures } from "../assetAllocation/editPortfolioGroup/InternalUse";
import { useChartDataSelector } from "../assetAllocation/editPortfolioGroup/useChartDataSelector";
import { ChartMultiPeriodReturnComparison } from "../assetAllocation/editPortfolioGroup/ChartMultiPeriodReturnComparison";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

export const AssetClassAnalysisMultiPeriodReturnComparison = memo(() => {
  const pageContent = usePageContent();

  const data = useAssetClassExtendedStat({
    type: AssetClassLoadType.byLatestSimulationJob,
    filterByUsableInPortfolios: true,
  });

  const mprcItems = data.multiPeriodReturnComparison;
  const enabledMprcItems = mprcItems.filter((i) => !i.isDisabled);

  const chartDataSelector = useChartDataSelector({
    storageKey:
      "AssetClassAnalysisMultiPeriodReturnComparison_chartDataSelector",
    data1DefaultId: enabledMprcItems.at(0)?.id,
    data2DefaultId: enabledMprcItems.at(1)?.id,
  });

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 10 }}
      isLoading={data.isLoading || pageContent.isLoading}
      rows={8}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Multi-Period Return Comparison
      </Typography>
      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_MULTI_PERIOD_RETURN_RETURN_REPORT,
        )}
      />
      <Box
        sx={{
          mt: 5,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "gray1",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "gray3",
          py: 5,
          px: 12.5,
        }}
      >
        <InternalUseWithDisclosures />
        <ChartMultiPeriodReturnComparison
          selectorLabel1="Asset Class 1"
          selectorLabel2="Asset Class 2"
          data={mprcItems}
          chartDataSelector={chartDataSelector}
          pendoPrefix="asset_class_analysis_multi_period_return_comparison"
        />
      </Box>
    </TablePlaceHolder>
  );
});
