import {
  SimulationJobRun,
  useSimulationJobHistoryListCreateQuery,
} from "api/carApi.generated";
import { CarSelectOption, CarSelectOptionGroup } from "components/Inputs";
import { refetchOnFocus } from "const";
import { useMemo } from "react";
import { checkDefined, formatDateTime } from "utils";

export interface CarSimulationJobOption extends CarSelectOption<string> {
  groupId?: string;
}

export const useSimulationList = (params: { skip?: boolean }) => {
  const { data, isLoading } = useSimulationJobHistoryListCreateQuery(
    undefined,
    {
      refetchOnFocus,
      skip: params.skip,
    },
  );

  return useMemo(() => {
    const typedData: Record<string, SimulationJobRun[]> | undefined =
      data as any;

    const getName = (item: SimulationJobRun) => {
      if (item.created_by.first_name || item.created_by.last_name) {
        return `${item.created_by.first_name} ${item.created_by.last_name}`;
      } else {
        return item.created_by.email;
      }
    };

    const sortRuns = (a: SimulationJobRun, b: SimulationJobRun) => {
      const sortA = a.published ? 0 : 1;
      const sortB = b.published ? 0 : 1;
      return (
        sortA - sortB || (b.created_at ?? "").localeCompare(a.created_at ?? "")
      );
    };

    const staticOptions = Array.from(typedData?.["STATIC"] ?? [])
      .sort(sortRuns)
      .map<CarSimulationJobOption>((i) => ({
        value: checkDefined(i.id),
        label: `${formatDateTime(checkDefined(i.created_at))} ${getName(i)}`,
        isBold: i.published,
        groupId: i.group.id,
      }));

    const dynamicOptions = Array.from(typedData?.["DYNAMIC"] ?? [])
      .sort(sortRuns)
      .map<CarSimulationJobOption>((i) => ({
        value: checkDefined(i.id),
        label: `${formatDateTime(checkDefined(i.created_at))} ${getName(i)}`,
        isBold: i.published,
        groupId: i.group.id,
      }));

    const groups: CarSelectOptionGroup<string>[] = [
      {
        label: "Price - Driven",
        items: dynamicOptions,
      },
      {
        label: "Static",
        items: staticOptions,
      },
    ];

    return {
      staticOptions,
      dynamicOptions,
      groups,
      isSimulationStatic: (id: string) =>
        staticOptions.some((i) => i.value === id),
      getSimulationName: (id: string) =>
        staticOptions.find((i) => i.value === id)?.label ??
        dynamicOptions.find((i) => i.value === id)?.label,
      isLoading,
    };
  }, [data, isLoading]);
};

export type UseSimulationList = ReturnType<typeof useSimulationList>;
