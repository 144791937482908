import { format } from "date-fns";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfTypography } from "components/PdfTypography";
import { CarPdfImage } from "components/PdfImage";
import { CarPdfBox } from "components/PdfBox";
import { useTheme } from "@mui/material";
import { CarFirm } from "types";

export interface TitlePdfPageProps {
  pageProps: CarPdfPageProps;
  clientName: string;
  spouseName?: string;
  advisorName: string;
  advisorWorkPhone: string;
  advisorCellPhone: string;
  advisorEmail: string;
  advisorAddress: string;
  firm?: CarFirm;
}

export const TitlePdfPage = ({
  pageProps,
  clientName,
  spouseName,
  advisorName,
  advisorWorkPhone,
  advisorCellPhone,
  advisorEmail,
  advisorAddress,
  firm,
}: TitlePdfPageProps) => {
  const theme = useTheme();
  return (
    <CarPdfPage
      style={{
        paddingLeft: 63,
        paddingRight: 63,
      }}
      {...pageProps}
    >
      <CarPdfBox
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CarPdfImage
          style={{
            height: 60,
          }}
          src={firm?.logo ?? undefined}
        />
        <CarPdfTypography variant="body1">
          {format(new Date(), "PP")}
        </CarPdfTypography>
      </CarPdfBox>
      <CarPdfTypography
        variant="body2"
        style={{
          marginTop: 138,
        }}
      >
        REPORT PREPARED FOR
      </CarPdfTypography>
      <CarPdfTypography
        variant="h1"
        style={{
          marginTop: 14,
        }}
      >
        {clientName + (spouseName ? " &" : "")}
      </CarPdfTypography>
      {spouseName && (
        <CarPdfTypography variant="h1">{spouseName}</CarPdfTypography>
      )}
      <CarPdfBox
        style={{
          backgroundColor: theme.palette.primary.main,
          width: 130,
          height: 2,
        }}
      />
      <CarPdfTypography
        variant="body1"
        style={{ marginTop: 16 }}
      >{`by ${advisorName}`}</CarPdfTypography>
      <CarPdfTypography variant="body1" style={{ marginTop: 4 }}>
        {firm?.name ?? ""}
      </CarPdfTypography>
      {advisorAddress && (
        <CarPdfTypography variant="body1" style={{ marginTop: 140 }}>
          {advisorAddress}
        </CarPdfTypography>
      )}
      {advisorWorkPhone && (
        <CarPdfTypography variant="body1" style={{ marginTop: 14 }}>
          {`Work: ${advisorWorkPhone}`}
        </CarPdfTypography>
      )}
      {advisorCellPhone && (
        <CarPdfTypography variant="body1">{`Cell: ${advisorCellPhone}`}</CarPdfTypography>
      )}
      {advisorEmail && (
        <CarPdfTypography variant="body1" style={{ marginTop: 14 }}>
          {advisorEmail}
        </CarPdfTypography>
      )}
    </CarPdfPage>
  );
};
