import clsx from "clsx";
import { Box, BoxProps, darken, styled } from "@mui/material";

interface AssetsLogoProps extends Omit<BoxProps, "onChange"> {
  code: string;
  value?: string;
  backgroundColor?: string;
  image: string;
  onChange: (value?: string) => void;
}

export const AssetsLogo = styled(
  ({
    code,
    value,
    onChange,
    image,
    className,
    backgroundColor,
    onClick,
    ...props
  }: AssetsLogoProps) => (
    <Box
      {...props}
      className={clsx({ selected: value === code }, className)}
      onClick={(e) => onChange(code)}
      sx={{
        backgroundColor: backgroundColor ?? "white",
        "&:hover": {
          backgroundColor: backgroundColor
            ? darken(backgroundColor, 0.05)
            : "gray1",
        },
      }}
    >
      <Box
        className="inner-box"
        sx={{
          backgroundImage: `url(${image})`,
        }}
      />
    </Box>
  ),
)(({ theme }) => ({
  width: 291,
  height: 146,
  borderRadius: "12px",
  cursor: "pointer",
  position: "relative",
  display: "flex",
  "&::after": {
    content: '""',
    position: "absolute",
    width: "100%",
    height: "100%",
    border: "solid 1px",
    borderColor: theme.palette.caravelOrangePrimary,
    borderRadius: "12px",
  },
  "&.selected::after": {
    borderWidth: 5,
  },
  ".inner-box": {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
}));
