import { refetchOnFocus } from "const";
import { CarClient, CarClientTier } from "types";
import { useLocation, useNavigate } from "react-router-dom";
import { financialPlanningPages } from "route.const";
import { api } from "api/carApi.manual";
import { useMount } from "app/useMount";
import { useRelationTypes } from "app/useRelationTypes";
import { useQuestionnaireClientsDestroyMutation } from "api/carApi.generated";
import { useSuccessToast } from "app/useSuccessToast";
import { useTiers } from "app/useTiers";
import { useDialog } from "app/useDialog";
import { DeleteItemDialog } from "components/DeleteItemDialog";

export interface CarClientDisplay extends CarClient {
  name: string;
  tierName: string;
  tierSort: number;
}

const clientToClientDisplay =
  ({
    isPrimary,
    getTier,
  }: {
    isPrimary: (value: CarClient) => boolean;
    getTier: (tierId?: string | null) => CarClientTier | undefined;
  }) =>
  (client: CarClient): CarClientDisplay => {
    const primary = client.active_relationships?.find(isPrimary);

    const tier = getTier(client.tier);

    return {
      ...client,
      name: `${primary?.first_name ?? ""} ${primary?.last_name ?? ""}`.trim(),
      tierName: tier?.name ?? "-",
      tierSort: tier?.sort_order ?? 0,
    };
  };

export const useClients = (searchOnMount: boolean) => {
  const [searchClients, { data: clientsData, isFetching }] =
    api.useLazyQuestionnaireClientsListQuery({
      refetchOnFocus,
    });
  const [delClientReq, delClientResponse] =
    useQuestionnaireClientsDestroyMutation();

  const { state } = useLocation();

  const initialSearch = Object(state)?.search as string | undefined;
  useMount(() => {
    if (searchOnMount) {
      searchClients({
        search: initialSearch,
      });
    }
  });

  const navigate = useNavigate();
  const toast = useSuccessToast();
  const dialog = useDialog();

  const relationTypes = useRelationTypes();
  const tiers = useTiers();

  const items: Array<CarClientDisplay> =
    clientsData?.map(
      clientToClientDisplay({
        isPrimary: relationTypes.isPrimary,
        getTier: tiers.getTier,
      }),
    ) ?? [];

  const gotoClient = (item: CarClient) => {
    const clientId = item.id ?? "";

    if (clientId) {
      navigate(financialPlanningPages.getClientSummaryUrl(clientId));
    }
  };

  const gotoFinancialPlanning = (search?: string) => {
    navigate(financialPlanningPages.clientListUrl, { state: { search } });
  };

  const deleteClient = async (id?: string) => {
    if (id) {
      if (
        !(await dialog(DeleteItemDialog, {
          itemName: items.find((i) => i.id === id)?.name ?? "",
          itemTypeName: "client",
        }))
      ) {
        return;
      }

      await delClientReq({ id });
      toast({
        kind: "success",
        message: "Success! This client has been deleted",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
  };

  return {
    isLoading:
      isFetching ||
      delClientResponse.isLoading ||
      relationTypes.isLoading ||
      tiers.isLoading,
    isFetching,
    items,
    gotoClient,
    gotoFinancialPlanning,
    searchClients,
    initialSearch,
    deleteClient,
  };
};
