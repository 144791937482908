import { Box, BoxProps } from "@mui/material";
import { UseChartHighlight } from "app/useChartHighlight";
import { ChartTypeRegistry, TooltipOptions } from "chart.js";
import { _DeepPartialObject } from "chart.js/dist/types/utils";
import { PropsWithChildren, ReactNode, useMemo, useState } from "react";

export interface ChartActiveElementData {
  datasetIndex: number;
  index: number;
  chartName?: string;
}

export function useChartActiveElement() {
  const [element, setElement] = useState<ChartActiveElementData | undefined>();

  return useMemo(
    () => ({
      element,
      setElement,
    }),
    [element],
  );
}

export type ChartActiveElement = ReturnType<typeof useChartActiveElement>;

export interface ChartTooltipData {
  opacity: number;
  x: number;
  y: number;
  canvasSize: { width: number; height: number };
  datasetIndex?: number;
  dataIndex?: number;
}

export type RenderTooltip = (
  datasetIndex: number,
  dataIndex: number,
) => ReactNode;

export const defaultChartTooltipData: ChartTooltipData = {
  opacity: 0,
  x: 0,
  y: 0,
  canvasSize: { width: 0, height: 0 },
};

export type OnTooltipChange = (params: {
  datasetIndex: number;
  dataIndex: number;
}) => void;

export function useChartTooltip(params?: {
  chartName?: string;
  activeElement?: ChartActiveElement;
  onTooltipChange?: OnTooltipChange;
  chartHighlight?: UseChartHighlight;
}) {
  const [tooltipData, setTooltipData] = useState<ChartTooltipData>(
    defaultChartTooltipData,
  );
  const setActiveElement = params?.activeElement?.setElement; // deprecated
  const chartName = params?.chartName;
  const onTooltipChange = params?.onTooltipChange;
  const chartHighlight = params?.chartHighlight;

  const tooltipPlugin = useMemo(() => {
    const plugin:
      | _DeepPartialObject<TooltipOptions<keyof ChartTypeRegistry>>
      | undefined = {
      enabled: false,
      external: (context) => {
        const { chart, tooltip } = context;
        // const { clientWidth: width, clientHeight: height } = chart.canvas;
        const { width, height } = chart;
        const [x, y] = [tooltip.caretX, tooltip.caretY];
        const lines = tooltip?.body?.[0]?.lines ?? [];
        const [datasetIndex, dataIndex] = [
          parseInt(lines[0]),
          parseInt(lines[1]),
        ];
        onTooltipChange?.({ datasetIndex, dataIndex });

        setTooltipData((old) =>
          old.opacity !== tooltip.opacity || old.x !== x || old.y !== y
            ? {
                opacity: tooltip.opacity,
                x,
                y,
                canvasSize: { width, height },
                datasetIndex,
                dataIndex,
              }
            : old,
        );
        setActiveElement?.((old) =>
          Boolean(tooltip.opacity) !== Boolean(old) ||
          old?.datasetIndex !== datasetIndex ||
          old?.index !== dataIndex ||
          old?.chartName !== chartName
            ? tooltip.opacity
              ? {
                  chartName: chartName,
                  datasetIndex,
                  index: dataIndex,
                }
              : undefined
            : old,
        );
        tooltip.opacity
          ? chartHighlight?.setValue({
              datasetIndex,
              dataIndex,
            })
          : chartHighlight?.resetValue();
      },
      position: "nearest",
      yAlign: "top",
      callbacks: {
        label: (item) => {
          return [`${item.datasetIndex}`, `${item.dataIndex}`];
        },
      },
    };
    return plugin;
  }, [setActiveElement, chartName, onTooltipChange, chartHighlight]);

  return { tooltipPlugin, tooltipData, setTooltipData };
}

interface ChartTooltipProps {
  sx?: BoxProps["sx"];
  tooltipData: ChartTooltipData;
  renderTooltip: RenderTooltip;
  shiftY?: number;
}

export const ChartTooltip = ({
  sx,
  tooltipData,
  renderTooltip,
  shiftY,
  children,
}: PropsWithChildren<ChartTooltipProps>) => {
  let { opacity, x, y, canvasSize, datasetIndex, dataIndex } = tooltipData;
  y = y + (shiftY ?? 0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(70);
  let [left, top] = [x - 20, y - height - 12];
  const swapLeft = left + width > canvasSize.width;
  const swapBottom = top < 0;
  if (swapLeft) {
    left = x - width + 20;
  }
  if (swapBottom) {
    top = y + 12;
  }

  const tooltip =
    datasetIndex === undefined ||
    isNaN(datasetIndex) ||
    dataIndex === undefined ||
    isNaN(dataIndex) ? null : (
      <Box
        ref={(el?: HTMLElement) => {
          setWidth(el?.clientWidth ?? 0);
          setHeight(el?.clientHeight ?? 0);
        }}
        sx={{
          opacity,
          pointerEvents: "none",
          position: "absolute",
          left,
          top,
          border: "solid 1px",
          borderColor: "gray6",
          borderRadius: "5px",
          backgroundColor: "gray1",
          padding: "12px",
          zIndex: 100000,
          "& .tooltip-title": {
            fontSize: 15,
            fontWeight: 600,
            color: "black",
            whiteSpace: "nowrap",
          },
          "& .tooltip-body": {
            fontSize: 12,
            fontWeight: 400,
            color: "black",
            display: "flex",
            columnGap: "4px",
            flexWrap: "nowrap",
            whiteSpace: "nowrap",
            paddingLeft: "12px",
          },
          "& .tooltip-green": { color: "green" },
          "& .tooltip-red": { color: "red" },
          "&::after": {
            content: "''",
            position: "absolute",
            width: 16,
            height: 16,
            top: swapBottom ? -8 : undefined,
            bottom: swapBottom ? undefined : -8,
            right: swapLeft ? 13 : undefined,
            borderRight: swapBottom ? undefined : "solid 1px",
            borderBottom: swapBottom ? undefined : "solid 1px",
            borderLeft: swapBottom ? "solid 1px" : undefined,
            borderTop: swapBottom ? "solid 1px" : undefined,
            borderColor: "gray6",
            backgroundColor: "gray1",
            transform: "rotate(45deg)",
          },
        }}
      >
        {renderTooltip(datasetIndex, dataIndex)}
      </Box>
    );

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        ...sx,
      }}
    >
      {children}
      {tooltip}
    </Box>
  );
};
