import { CarPdfDocument } from "components/PdfDocument";
import { CarPdfPage } from "components/PdfPage";
import { CarPdfTypography } from "components/PdfTypography";
import { CarPdfViewer } from "components/PdfViewer";

// import { Box } from "@mui/material";

export const PdfDebug = () => {
  return (
    // <Box
    //   sx={{
    //     display: "flex",
    //     flexDirection: "column",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     width: "100%",
    //     height: "100%",
    //     backgroundColor: "red",
    //   }}
    // >
    //    <iframe
    //     src={`${debugReport}#zoom=75&page=5`}
    //     title={"debug"}
    //     style={{
    //       position: "absolute",
    //       borderWidth: 0,
    //     }}
    //     width={"100%"}
    //     height={"100%"}
    //   />
    // </Box>
    <CarPdfViewer isLoading={false}>
      <CarPdfDocument>
        <CarPdfPage
          style={{
            rowGap: 17,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
          footerDisclosures=""
        >
          <CarPdfTypography variant="h1">Sarah Tobey</CarPdfTypography>
          <CarPdfTypography variant="h2">Heading 2</CarPdfTypography>
          <CarPdfTypography variant="h3">Heading 3</CarPdfTypography>
          <CarPdfTypography variant="h4">Heading 4</CarPdfTypography>
          <CarPdfTypography variant="h5">Heading 5</CarPdfTypography>
          <CarPdfTypography variant="h6">Heading 6</CarPdfTypography>
          <CarPdfTypography variant="body1">Body 1</CarPdfTypography>
          <CarPdfTypography variant="body2">Body 2</CarPdfTypography>
          <CarPdfTypography variant="tableHeading">
            Table Heading
          </CarPdfTypography>
          <CarPdfTypography variant="tableBody1">Table Body 1</CarPdfTypography>
          <CarPdfTypography variant="tableBody2">Table Body 2</CarPdfTypography>
          <CarPdfTypography variant="tableBody3">Table Body 3</CarPdfTypography>
          <CarPdfTypography variant="tableBody4">Table Body 4</CarPdfTypography>
          <CarPdfTypography variant="barGraph1">Bar Graph 1</CarPdfTypography>
          <CarPdfTypography variant="barGraph2">Bar Graph 2</CarPdfTypography>
          <CarPdfTypography variant="disclosures">Disclosures</CarPdfTypography>
          <CarPdfTypography variant="pageNumber">Page #</CarPdfTypography>
        </CarPdfPage>
        <CarPdfPage
          style={{
            rowGap: 17,
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
          }}
          footerDisclosures=""
        ></CarPdfPage>
      </CarPdfDocument>
    </CarPdfViewer>
  );
};
