import { isDefined, isOddEven } from "utils";
import { CarInfoHeader, CarInfoTable, CarInfoCell } from "components/InfoTable";
import { Fragment } from "react";
import { styled } from "@mui/material";
import {
  formatProjectionValue,
  getGridTemplateColumns,
} from "features/stressTest/useProjectionExport";
import { ExcelTable } from "app/useExcelExport";

const StyledHeader = styled(CarInfoHeader)({
  position: "sticky",
  top: 0,
});

interface StressTestProjectionTableProps {
  content: ExcelTable;
}

export const StressTestProjectionTable = ({
  content,
}: StressTestProjectionTableProps) => (
  <CarInfoTable
    sx={{
      mt: 0,
      mb: 1,
      gridTemplateColumns: getGridTemplateColumns(content.columns),
    }}
  >
    {content.columns
      .map((col, colIdx) =>
        col.span === 0 ? null : (
          <StyledHeader
            key={colIdx}
            text={col.label}
            pendoClass={col.pendoClassName}
            sx={{
              gridColumn: col.span
                ? `${colIdx + 1} / span ${col.span}`
                : undefined,
            }}
          />
        ),
      )
      .filter(isDefined)}
    {content.rows.map((row, rowIdx) => (
      <Fragment key={rowIdx}>
        {row.map((val, valIdx) => {
          return (
            <CarInfoCell
              key={valIdx}
              isOdd={isOddEven(rowIdx)}
              text={formatProjectionValue(val, content.columns[valIdx].format)}
            />
          );
        })}
      </Fragment>
    ))}
  </CarInfoTable>
);
