import { useCallback, useMemo } from "react";
import { useLocalStorage, useSessionStorage } from "./useLocalStorage";

export const useExpand = (key?: string) => {
  const [expandedSections, setExpandedSections] = useSessionStorage<
    Array<String>
  >(key ? `${key}-expandedSections` : undefined, []);

  return useMemo(() => {
    const getExpanded = (id?: string) => expandedSections.some((i) => i === id);

    const onExpand = (id: string, isExpanded: boolean) => {
      if (id) {
        setExpandedSections((state) => {
          const withoutId = state.filter((i) => i !== id);
          return isExpanded ? [...withoutId, id] : withoutId;
        });
      }
    };

    const getState = (id: string): [boolean, (isExpanded: boolean) => void] => [
      getExpanded(id),
      (isExpanded: boolean) => onExpand(id, isExpanded),
    ];

    return { getExpanded, onExpand, getState };
  }, [expandedSections, setExpandedSections]);
};

export const useCollapse = (key?: string) => {
  const [collapsedSections, setCollapsedSections] = useSessionStorage<
    Array<String>
  >(key ? `${key}-collapsedSections` : undefined, []);

  return useMemo(() => {
    const getExpanded = (id?: string) =>
      !collapsedSections.some((i) => i === id);

    const onExpand = (id: string, isExpanded: boolean) => {
      if (id) {
        setCollapsedSections((state) => {
          const withoutId = state.filter((i) => i !== id);
          return isExpanded ? withoutId : [...withoutId, id];
        });
      }
    };

    const getState = (id: string): [boolean, (isExpanded: boolean) => void] => [
      getExpanded(id),
      (isExpanded: boolean) => onExpand(id, isExpanded),
    ];

    return { getExpanded, onExpand, getState };
  }, [collapsedSections, setCollapsedSections]);
};

export type UseExpand = ReturnType<typeof useExpand>;

export const useExpandSingle = (
  key?: string,
  defaultExpanded: boolean = true,
) => {
  const [expanded, setExpanded] = useLocalStorage<boolean>(
    key ? `useExpandSingle-${key}` : undefined,
    defaultExpanded,
  );

  const onChange = useCallback(
    (e: any, isExpanded: boolean) => setExpanded(isExpanded),
    [setExpanded],
  );

  return { expanded, onChange };
};
