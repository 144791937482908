import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarButton, CarDownloadButton } from "components/Buttons";
import { useSimulationDataExplorer } from "./useSimulationDataExplorer";
import { SimulationDataExplorerChart } from "./SimulationDataExplorerChart";
import { SimulationDataExplorerFilter } from "./SimulationDataExplorerFilter";
import { SimulationDataExplorerTable } from "./SimulationDataExplorerTable";
import {
  CarAccordion,
  CarAccordionDetails,
  CarAccordionSummary,
} from "components/Accordion";
import { SimulationDataExplorerDisplay } from "./SimulationDataExplorerDisplay";
import { CarSelectOptionField } from "components/Inputs";
import { CarSwitch } from "components/Switch";
import { CarIconShare } from "icons";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

interface SimulationDataExplorerProps {
  simulationJobId: string;
}

export const SimulationDataExplorer = (props: SimulationDataExplorerProps) => {
  const { input, output, handleCopyUrl } = useSimulationDataExplorer({
    simulationJobId: props.simulationJobId,
    storageKey: "SimulationDataExplorer",
  });

  const pageContent = usePageContent();

  return (
    <TablePlaceHolder
      sx={{ mx: 5, mt: 10 }}
      isLoading={input.isLoading || pageContent.isLoading}
      rows={8}
    >
      <CarPageContent
        content={pageContent.getContent(
          CarPageContentCode.SIMULATION_ANALYSIS_DATA_EXPLORER,
        )}
        isWhiteContext
      />
      <CarAccordion
        defaultExpanded
        TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
      >
        <CarAccordionSummary sx={{ backgroundColor: "gray1" }}>
          <Typography variant="h6SemiBold">Filter</Typography>
        </CarAccordionSummary>
        <CarAccordionDetails
          sx={{
            p: 4,
            backgroundColor: "white",
            border: "solid 1px",
            borderColor: "gray3",
            borderTopWidth: 0,
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
          }}
        >
          <SimulationDataExplorerFilter simulationDataExplorerInput={input} />
        </CarAccordionDetails>
      </CarAccordion>
      <SimulationDataExplorerDisplay sx={{ mt: 2 }} output={output} />
      {output.isLoading ? (
        <Box
          sx={{
            mt: 5,
            p: 5,
            flex: "auto",
            backgroundColor: "white",
            border: "1px solid",
            borderColor: "gray3",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TablePlaceHolder isLoading={true} rows={8} />
        </Box>
      ) : output.table ? (
        <>
          <Box
            sx={{
              mt: 5,
              backgroundColor: "white",
              border: "1px solid",
              borderColor: "gray3",
              display: "flex",
              flexDirection: "column",
              p: 5,
            }}
          >
            <SimulationDataExplorerChart isWhiteContext output={output} />
          </Box>
          <Box
            sx={{
              mt: 5,
              backgroundColor: "white",
              border: "1px solid",
              borderColor: "gray3",
              display: "flex",
              flexDirection: "column",
              p: 5,
              pt: 2.5,
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <CarButton
                sx={{
                  height: 32,
                  width: 90,
                  fontSize: "13px",
                  fontWeight: 600,
                }}
                variant="outlined"
                onClick={output.handleClearSelection}
                size="small"
              >
                Clear All
              </CarButton>
              <Box
                sx={{
                  ml: 4,
                  mb: 0.5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 1,
                }}
              >
                <Typography variant="par01Regular">View All</Typography>
                <CarSwitch
                  size="small"
                  checked={output.presentation.viewSelected}
                  onChange={(e, checked) =>
                    output.handleViewSelectedChange(checked)
                  }
                />
                <Typography variant="par01Regular">View Selected</Typography>
              </Box>
              <Typography
                variant="par02SemiBold"
                sx={{
                  alignSelf: "center",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translateX(-50%) translateY(-50%)",
                }}
              >
                Years
              </Typography>
              <CarDownloadButton
                sx={{ ml: "auto" }}
                onClick={output.handleDownloadSelected}
              >
                Download Selection
              </CarDownloadButton>
              <CarDownloadButton
                startIcon={<CarIconShare color="primary" />}
                onClick={handleCopyUrl}
              >
                Copy Url
              </CarDownloadButton>
              <CarSelectOptionField
                sx={{ ml: 2, width: 130 }}
                label="Show"
                options={output.itemsPerPageSelectorOptions}
                value={output.presentation.itemsPerPage}
                onChange={output.handleItemsPerPageChange}
              />
            </Box>
            <SimulationDataExplorerTable
              sx={{
                mt: 2.5,
                maxHeight: "calc(100vh - 210px)",
              }}
              output={output}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            mt: 5,
            flex: "auto",
            backgroundColor: "white",
            border: "1px solid",
            borderColor: "gray3",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 448,
          }}
        >
          <Typography variant="par02Regular">No data loaded yet.</Typography>
        </Box>
      )}
    </TablePlaceHolder>
  );
};
