import { formatCurrency, formatDisplayTypeValue, isDefined } from "utils";
import { PlanResultsRiskTableRow } from "./usePlanResults";
import {
  CarAccordion,
  CarAccordionDetails,
  CarAccordionSummary,
} from "components/Accordion";
import { BoxProps, Typography } from "@mui/material";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { Fragment } from "react";
import { DisplayTypeEnum2 } from "api/carApi.generated";

const formatReportRowValue = (
  value: number | undefined,
  displayType: DisplayTypeEnum2,
  convertToDollarFromTotal: number | undefined
) =>
  displayType === "PERCENTAGE" && isDefined(convertToDollarFromTotal)
    ? formatCurrency(((value ?? 0) / 100) * convertToDollarFromTotal) // TODO Yevhen - check if we still need this and how it works
    : formatDisplayTypeValue(displayType)(value);
interface RiskTableReportProps {
  sx?: BoxProps["sx"];
  title: string;
  selectedPlanLabel: string;
  hideSelectedPlan: boolean;
  data: PlanResultsRiskTableRow[];
  convertToDollarFromTotal: number | undefined;
}

export const RiskTableReport = (props: RiskTableReportProps) => {
  return (
    <CarAccordion sx={props.sx} defaultExpanded>
      <CarAccordionSummary
        sx={{
          borderBottomColor: "gray6",
          clipPath: "inset(0 round 6px 6px 6px 6px)",
          ".Mui-expanded": {
            borderBottomWidth: 0,
          },
        }}
      >
        <Typography variant="h6SBold">{props.title}</Typography>
      </CarAccordionSummary>
      <CarAccordionDetails>
        <CarInfoTable
          sx={{
            mt: "-1px",
            borderColor: "gray9",
            "& > *": {
              borderColor: "gray9",
            },
            gridTemplateColumns: `2fr repeat(${
              props.hideSelectedPlan ? 2 : 3
            }, 1fr)`,
          }}
        >
          <CarInfoHeader text="" />
          <CarInfoHeader text="Existing" />
          <CarInfoHeader text="Proposed" />
          {!props.hideSelectedPlan && (
            <CarInfoHeader text={props.selectedPlanLabel} />
          )}
          {props.data.map((i, idx) => (
            <Fragment key={idx}>
              <CarInfoCell text={`${i.label}:`} justifyContent="start" isOdd />
              <CarInfoCell
                text={formatReportRowValue(
                  i.existingPortfolio,
                  i.displayType,
                  props.convertToDollarFromTotal
                )}
                isOdd
              />
              <CarInfoCell
                text={formatReportRowValue(
                  i.proposedPortfolio,
                  i.displayType,
                  props.convertToDollarFromTotal
                )}
                isOdd
              />
              {!props.hideSelectedPlan && (
                <CarInfoCell
                  text={formatReportRowValue(
                    i.selectedPortfolio,
                    i.displayType,
                    props.convertToDollarFromTotal
                  )}
                  isOdd
                />
              )}
            </Fragment>
          ))}
        </CarInfoTable>
      </CarAccordionDetails>
    </CarAccordion>
  );
};
