import {
  CarMenuButtonProps,
  StyledMenuButtonMenu,
} from "components/MenuButton";
import { useState } from "react";
import { CarRoleDisplay } from "./useSettingsFirmUsers";
import { Box, Button, ListItemText, MenuItem, Typography } from "@mui/material";
import { RadioChecked, RadioUnchecked, SelectArrow } from "icons";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

export interface CarRoleButtonProps
  extends Omit<CarMenuButtonProps, "items" | "renderButton" | "onChange"> {
  position?: "left" | "right";
  value?: string;
  onChange: (value: string) => void;
  roles: CarRoleDisplay[];
}

export const CarRoleButton = (props: CarRoleButtonProps) => {
  const { value, onChange, position = "left", ...rest } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const horizontal = position === "left" ? "right" : "left";

  return (
    <>
      <Button
        onClick={handleMenuClick}
        color="inherit"
        size="small"
        {...rest}
        sx={{
          color: "softBlack",
          svg: {
            color: rest.disabled ? "gray1" : "caravelOrangePrimary",
          },
          ...rest.sx,
        }}
        endIcon={<SelectArrow />}
        variant="text"
      >
        <Typography
          variant="par01Regular"
          sx={{ minWidth: 70, whiteSpace: "nowrap" }}
        >
          {props.roles.find((r) => r.id === value)?.name}
        </Typography>
      </Button>

      {open && (
        <StyledMenuButtonMenu
          anchorEl={anchorEl}
          open={open}
          sx={{
            ".MuiPaper-root": {
              width: 294,
            },
            ".MuiList-root": {
              // m: 1.5,
              mb: 0.5,
            },
          }}
          onClose={(e: any) => {
            e.stopPropagation?.();
            e.preventDefault?.();
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal,
          }}
        >
          <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
            <Typography variant="par02SemiBold" sx={{ fontSize: "15px" }}>
              Seats
            </Typography>
            <Typography variant="par02SemiBold" sx={{ fontSize: "15px" }}>
              Used
            </Typography>
          </Box>
          {props.roles.map((i, idx, arr) => (
            <>
              <MenuItem
                key={i.id}
                selected={i.id === value}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setAnchorEl(null);
                  if (i.id) {
                    onChange(i.id);
                  }
                }}
                sx={{
                  alignItems: "center",
                  py: 1,
                  gap: 0.5,
                  "&:hover": {
                    backgroundColor: "gray1",
                  },
                  "&.Mui-selected": {
                    backgroundColor: "gray2",
                    "&:hover": {
                      backgroundColor: "gray2",
                    },
                  },
                }}
              >
                {i.id === value ? (
                  <RadioChecked
                    color="primary"
                    sx={{ mb: 0.2, width: 20, height: 20 }}
                  />
                ) : (
                  <RadioUnchecked
                    sx={{ color: "gray6", mb: 0.2, width: 20, height: 20 }}
                  />
                )}
                <ListItemText
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    ".MuiPaper-root & span.MuiListItemText-primary": {
                      fontWeight: 400,
                      fontSize: "14px",
                    },
                    ".MuiPaper-root & p.MuiListItemText-secondary": {
                      fontSize: "12px",
                      color: "gray6",
                    },
                  }}
                  primary={
                    <>
                      {i.name}
                      <CarTooltipBox
                        sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                        className={pendoClasses.role(i.code)}
                      />
                    </>
                  }
                  secondary={`${i.seats}/${i.usedSeats}`}
                />
              </MenuItem>
            </>
          ))}
        </StyledMenuButtonMenu>
      )}
    </>
  );
};
