import { useQuestionnairePlanRelationshipsListQuery } from "api/carApi.generated";
import { refetchOnFocus } from "const";
import { isDefined } from "utils";
import { usePlanId } from "app/usePlanId";
import { useRelationTypes } from "app/useRelationTypes";
import { useMemo } from "react";
import { differenceInYears, getYear, parseISO } from "date-fns";
import { CarDateExtra, CarRelationship } from "types";

const getBirthYear = (item?: CarRelationship) =>
  item?.date_of_birth ? getYear(parseISO(item?.date_of_birth)) : 0;
const getDeathYear = (item?: CarRelationship) =>
  getBirthYear(item) + (item?.live_until ?? 0);
const getRetirementYear = (item?: CarRelationship) =>
  getBirthYear(item) + (item?.retirement_age ?? 0);

export const useRelationshipLight = () => {
  const planId = usePlanId();
  const { isPrimary, isSecondary } = useRelationTypes();
  const { data, isLoading } = useQuestionnairePlanRelationshipsListQuery(
    { planId },
    { refetchOnFocus },
  );

  return useMemo(() => {
    let items = data ?? [];

    const primaryItem = items.find(isPrimary);
    const secondaryItem = items.find(isSecondary);
    items = [
      primaryItem,
      secondaryItem,
      ...items.filter((i) => !isPrimary(i) && !isSecondary(i)),
    ].filter(isDefined);

    const getMinAge = (relationshipId?: string) => {
      const relItem = items.find((i) => i.id === relationshipId);
      let res = 45;
      if (relItem && relItem.date_of_birth) {
        res =
          differenceInYears(Date.now(), Date.parse(relItem.date_of_birth)) + 1; // forbid current year selection https://app.clickup.com/t/1tz1apr
      }
      return res;
    };

    const getMaxAge = (relationshipId?: string) => {
      const relItem = items.find((i) => i.id === relationshipId);
      let res = 110;
      if (relItem && relItem.live_until) {
        res = relItem.live_until;
      }
      return res;
    };

    const getFullName = (relationshipId?: string) => {
      const relItem = items.find((i) => i.id === relationshipId);
      return relItem?.first_name
        ? `${relItem?.first_name} ${relItem?.last_name}`
        : "";
    };

    const getFirstName = (relationshipId?: string) =>
      items.find((i) => i.id === relationshipId)?.first_name ?? "";

    const getDateExtraInfo = (date?: Partial<CarDateExtra>) => {
      if (!date) {
        return {};
      }
      let year: number | undefined;
      let relationshipId: string | undefined | null;

      const primaryDeathYear = getDeathYear(primaryItem);
      const secondaryDeathYear = getDeathYear(secondaryItem);

      switch (date.kind) {
        case "AGE":
          relationshipId = date.age_relationship;
          const birthYear = getBirthYear(
            items.find((i) => i.id === relationshipId),
          );
          year = date.age && birthYear ? birthYear + date.age : undefined;
          break;
        case "YEAR":
          relationshipId = undefined;
          year = date.year ?? 0;
          break;
        case "PRIMARY_RETIREMENT":
          relationshipId = primaryItem?.id;
          year = getRetirementYear(primaryItem);
          break;
        case "SECONDARY_RETIREMENT":
          relationshipId = secondaryItem?.id;
          year = getRetirementYear(secondaryItem);
          break;
        case "PRIMARY_DEATH":
          relationshipId = primaryItem?.id;
          year = primaryDeathYear;
          break;
        case "SECONDARY_DEATH":
          relationshipId = secondaryItem?.id;
          year = secondaryDeathYear;
          break;
        case "SECOND_DEATH":
          relationshipId =
            getDeathYear(primaryItem) >= getDeathYear(secondaryItem)
              ? primaryItem?.id
              : secondaryItem?.id;
          year = Math.max(primaryDeathYear, secondaryDeathYear);
          break;
      }

      return {
        relationship: items.find((i) => i.id === relationshipId),
        year,
      };
    };

    return {
      items,
      primaryItem,
      secondaryItem,
      getMinAge,
      getMaxAge,
      getFullName,
      getFirstName,
      getDateExtraInfo,
      isLoading,
    };
  }, [data, isLoading, isPrimary, isSecondary]);
};
