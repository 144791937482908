import { CarPortfolioChartItem } from "pages/assetAllocation/asset-allocation-types";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { useGics } from "app/useGics";
import { useChartDataSelector } from "../assetAllocation/editPortfolioGroup/useChartDataSelector";
import { getAssetClassIcon } from "components/AssetClassSelector";

export const useAssetClassAnalysisSectorAnalysis = () => {
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.allFirm,
  });

  const enabledItems = assetClasses.items.filter((i) => !i.isDisabled);

  const chartDataSelector = useChartDataSelector({
    storageKey: "useAssetClassAnalysisSectorAnalysis_chartDataSelector",
    data1DefaultId: enabledItems.at(0)?.level4Id,
    data2DefaultId: enabledItems.at(1)?.level4Id,
  });

  const gics = useGics({
    onGetValue1: (groupWeights) =>
      groupWeights.find((i) => i.ac_level4 === chartDataSelector.data1Id)
        ?.weight ?? 0,
    onGetValue2: (groupWeights) =>
      groupWeights.find((i) => i.ac_level4 === chartDataSelector.data2Id)
        ?.weight ?? 0,
  });

  const isLoading = gics.isLoading || assetClasses.isLoading;
  const acItems = assetClasses.items;

  const chartItems = acItems
    .filter((ac) => gics.hasWeightForAC(ac.level4Id))
    .map<CarPortfolioChartItem>((ac) => ({
      id: ac.level4Id,
      label: ac.name,
      fullLabel: `AC: ${ac.name}`,
      isDisabled: ac.isDisabled,
      icon: getAssetClassIcon(ac),
      x: [],
      y: [],
    }));

  const label1 =
    assetClasses.items.find((i) => i.level4Id === chartDataSelector.data1Id)
      ?.name ?? "";

  const label2 =
    assetClasses.items.find((i) => i.level4Id === chartDataSelector.data2Id)
      ?.name ?? "";

  return {
    isLoading,
    report: gics.report,
    chartDataSelector,
    chartItems,
    label1,
    label2,
  };
};
