import { AssetClassLevel4 } from "api/carApi.generated";
import { useAssetClassesAssetClassLevel4ListRetrieveQuery } from "api/carApi.generated";
import { useMemo } from "react";

interface AssetClassLevel4GroupsResponse {
  simple: AssetClassLevel4[];
  equity: AssetClassLevel4[];
  us_equity: AssetClassLevel4[];
  intl_equity: AssetClassLevel4[];
  fixed_income: AssetClassLevel4[];
  firm: AssetClassLevel4[];
  all: AssetClassLevel4[];
}

export interface AssetClassLevel4Group {
  code: string;
  name: string;
  items: AssetClassLevel4[];
}

export const useAssetClassGroups = () => {
  const { data, isLoading } =
    useAssetClassesAssetClassLevel4ListRetrieveQuery();

  return useMemo(() => {
    const rawData = data as unknown as
      | AssetClassLevel4GroupsResponse
      | undefined; // todo fix in swagger

    const groups: AssetClassLevel4Group[] = rawData
      ? [
          {
            code: "simple",
            name: "Simple",
            items: rawData.simple,
          },
          {
            code: "equity",
            name: "Equity",
            items: rawData.equity,
          },
          {
            code: "us_equity",
            name: "US Equity",
            items: rawData.us_equity,
          },
          {
            code: "intl_equity",
            name: "International Equity",
            items: rawData.intl_equity,
          },
          {
            code: "fixed_income",
            name: "Fixed Income",
            items: rawData.fixed_income,
          },
          {
            code: "firm",
            name: "Firm Selected",
            items: rawData.firm,
          },
          {
            code: "all",
            name: "All",
            items: rawData.all,
          },
        ]
      : [];

    return {
      isLoading: isLoading,
      groups,
    };
  }, [data, isLoading]);
};
