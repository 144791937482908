import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import {
  CarInfoExpandableRow,
  CarInfoHeader,
  CarInfoTable,
} from "components/InfoTable";
import { formatPercentFactor, isOddEven } from "utils";
import { CarGicsItem } from "app/useGics";

interface SectorAnalysisLegendItemProps {
  label: string;
  level: number;
  isHighlighted: boolean;
  onHighlightChange: (value: boolean) => void;
}

export const SectorAnalysisLegendItem = (
  props: SectorAnalysisLegendItemProps,
) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        p: 1,
        borderRadius: "4px",
        backgroundColor: props.isHighlighted ? "lightOrange" : "undefined",
      }}
      onMouseEnter={() => props.onHighlightChange(true)}
      onMouseLeave={() => props.onHighlightChange(false)}
      onWheel={() => props.onHighlightChange(false)}
    >
      <Box
        className={`level${props.level}`}
        sx={{
          width: 20,
          height: 20,
          borderRadius: "4px",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "gray6",
          "&.level0": {
            backgroundColor: theme.palette.white,
          },
          "&.level1": {
            backgroundColor: theme.palette.gray1,
          },
          "&.level2": {
            backgroundColor: theme.palette.gray2,
          },
          "&.level3": {
            backgroundColor: theme.palette.gray3,
          },
          "&.level4": {
            backgroundColor: theme.palette.gray4,
          },
        }}
      />
      <Typography variant="par02Regular" sx={{ pointerEvents: "none" }}>
        {props.label}
      </Typography>
    </Box>
  );
};

export interface AssetClassAnalysisSectorAnalysisTableProps {
  sx?: BoxProps["sx"];
  label1: string;
  label2: string;
  items: Array<CarGicsItem>;
  isExpandable: boolean;
  showTotal?: boolean;
  highlightLevel?: number;
}
export const AssetClassAnalysisSectorAnalysisTable = ({
  sx,
  label1,
  label2,
  items,
  isExpandable,
  showTotal,
  highlightLevel,
}: AssetClassAnalysisSectorAnalysisTableProps) => {
  const getRenderItem = (level: number) => (i: CarGicsItem, idx: number) => {
    return (
      <CarInfoExpandableRow
        key={i.id}
        isOdd={isOddEven(idx)}
        isForceEvenOdd={!isExpandable}
        caption={i.name}
        captionIsRegular
        defaultIsExpanded
        isHighlighted={level === highlightLevel}
        values={[
          formatPercentFactor(i.value1, {
            decimalPlaces: 1,
          }),
          formatPercentFactor(i.value2, {
            decimalPlaces: 1,
          }),
          formatPercentFactor(i.diff, {
            decimalPlaces: 1,
          }),
        ]}
        level={level}
        gridTemplateColumns={`1.5fr repeat(3, 1fr)`}
        childContent={
          isExpandable ? i.children.map(getRenderItem(level + 1)) : undefined
        }
      />
    );
  };
  return (
    <CarInfoTable sx={{ gridTemplateColumns: "1fr", ...sx }}>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: `1.5fr repeat(3, 1fr)`,
        }}
      >
        <CarInfoHeader text="" />
        <CarInfoHeader text={label1} />
        <CarInfoHeader text={label2} />
        <CarInfoHeader text="Difference" />
      </Box>

      {items.map(getRenderItem(0))}
      {showTotal && (
        <CarInfoExpandableRow
          key="total"
          caption="Sector Total:"
          isTotal
          values={[
            formatPercentFactor(
              items.reduce((acc, i) => acc + i.value1, 0),
              {
                decimalPlaces: 1,
              },
            ),
            formatPercentFactor(
              items.reduce((acc, i) => acc + i.value2, 0),
              {
                decimalPlaces: 1,
              },
            ),
            formatPercentFactor(
              items.reduce((acc, i) => acc + i.diff, 0),
              {
                decimalPlaces: 1,
              },
            ),
          ]}
          level={0}
          gridTemplateColumns={`1.5fr repeat(3, 1fr)`}
        />
      )}
    </CarInfoTable>
  );
};
