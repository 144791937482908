import { useMemo } from "react";
import { useQuestionnaireRelationTypeListQuery } from "api/carApi.generated";
import { CarRelationship } from "types";

export const useRelationTypes = () => {
  const { data, isLoading } = useQuestionnaireRelationTypeListQuery({
    search: "",
  });

  return useMemo(() => {
    const items = data ?? [];
    const primaryTypeId = items.find((i) => i.is_primary)?.id ?? "";
    const secondaryTypeId =
      items.find((i) => !i.is_primary && i.is_household_head)?.id ?? "";

    const isPrimary = (item: CarRelationship) =>
      item.relation_type === primaryTypeId;
    const isSecondary = (item: CarRelationship) =>
      item.relation_type === secondaryTypeId;
    return {
      isLoading,
      items,
      primaryTypeId,
      secondaryTypeId,
      isPrimary,
      isSecondary,
    };
  }, [data, isLoading]);
};
