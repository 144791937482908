import { CarCarousel } from "components/Carousel";
import { usePlanResults } from "features/planResults/usePlanResults";
import { PlanResultsCarousel } from "../workbench/planResults/PlanResultsCarousel";
import { PlanResultsCardSummaryCompactPlaceHolder } from "../workbench/planResults/PlanResultsCardSummaryCompact";

export const ClientSummaryPlanResultsPlaceholder = (props: {
  isLoading?: boolean;
}) => {
  return (
    <CarCarousel
      showTabs={false}
      isCompact
      isDisabled
      pages={[
        {
          id: "empty",
          label: "empty",
          render: () => (
            <PlanResultsCardSummaryCompactPlaceHolder
              isLoading={props.isLoading}
            />
          ),
        },
      ]}
    />
  );
};

export const ClientSummaryPlanResults = () => {
  const data = usePlanResults();

  return data.isLoading ? (
    <ClientSummaryPlanResultsPlaceholder isLoading />
  ) : (
    <PlanResultsCarousel data={data} showTabs={false} isCompact />
  );
};
