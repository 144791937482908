import { PriorityNumber } from "const";
import { CarClientPlan, CarPlanResult } from "types";
import { isDefined } from "utils";

export interface PlanResultDonutItem {
  successCount: number;
  totalCount: number;
  percentage: number;
}

export interface PlanResultGoalSummaryItem {
  priority: number;
  passFail: string;
  probOfSuccess: number;
  fundsRequired: number;
  funded: number;
  initialBalance: number;
  savingsAndIncome: number;
  expReturn: number;
  shortfall: number;
  disabled: boolean;
}
export interface PlanResultDonuts {
  needs: PlanResultDonutItem;
  wants: PlanResultDonutItem;
  dreams: PlanResultDonutItem;
  total: PlanResultDonutItem;
  score: number;
  summaryItems: PlanResultGoalSummaryItem[];
}

export const calcPlanResultDonuts = (
  isInflated: boolean,
  planResult?: CarPlanResult,
  clientPlan?: CarClientPlan
): PlanResultDonuts => {
  const calcDonutItem = (priority?: number): PlanResultDonutItem => {
    const totalGoals =
      planResult?.goal_results.filter(
        (i) => !isDefined(priority) || i.base_goal_priority === priority
      ) ?? [];

    const totalCount = totalGoals.length;
    const successCount = totalGoals.filter(
      (i) => i.goal_status === "success"
    ).length;

    return {
      totalCount,
      successCount,
      percentage: totalCount > 0 ? (successCount / totalCount) * 100 : 0,
    };
  };

  const calcSummaryItem = (priority: number): PlanResultGoalSummaryItem => {
    const totalGoals =
      planResult?.goal_results.filter(
        (i) => i.base_goal_priority === priority
      ) ?? [];

    const totalCount = totalGoals.length;
    const successCount = totalGoals.filter(
      (i) => i.goal_status === "success"
    ).length;

    const probOfSuccess =
      (priority === PriorityNumber.Dream
        ? clientPlan?.dreams_percentile
        : priority === PriorityNumber.Need
        ? clientPlan?.needs_percentile
        : clientPlan?.wants_percentile) ?? 0;

    const fundsRequired = totalGoals.reduce(
      (acc, goal) =>
        acc +
        (goal.period_results?.reduce(
          (sum, pr) =>
            sum +
            (isInflated
              ? pr.amount_requested_with_inflation ?? 0
              : pr.amount_requested_without_inflation ?? 0),
          0
        ) ?? 0),
      0
    );

    const funded = totalGoals.reduce(
      (acc, goal) =>
        acc +
        (goal.period_results?.reduce(
          (sum, pr) =>
            sum +
            (isInflated
              ? pr.amount_funded_with_inflation
              : pr.amount_funded_without_inflation),
          0
        ) ?? 0),
      0
    );

    const shortfall = totalGoals.reduce(
      (acc, goal) =>
        acc +
        (goal.period_results?.reduce(
          (sum, pr) =>
            sum +
            (isInflated
              ? pr.amount_remaining_with_inflation
              : pr.amount_remaining_without_inflation),
          0
        ) ?? 0),
      0
    );

    const initialBalance = totalGoals.reduce(
      (acc, goal) =>
        acc +
        (goal.asset_results?.reduce(
          (sum, ar) =>
            sum +
            (isInflated
              ? ar.current_asset_amount_contributed_with_inflation ?? 0
              : ar.current_asset_amount_contributed_without_inflation ?? 0),
          0
        ) ?? 0),
      0
    );

    const savingsAndIncome = totalGoals.reduce(
      (acc, goal) =>
        acc +
        (goal.asset_results?.reduce(
          (sum, ar) =>
            sum +
            (isInflated
              ? ar.goal_amount_funded_with_inflation_savings ?? 0
              : ar.goal_amount_funded_without_inflation_savings ?? 0),
          0
        ) ?? 0),
      0
    );

    return {
      priority,
      passFail: `${successCount}/${totalCount}`,
      probOfSuccess,
      fundsRequired,
      funded,
      initialBalance,
      savingsAndIncome,
      expReturn: fundsRequired - initialBalance - savingsAndIncome - shortfall,
      shortfall: -shortfall,
      disabled: totalCount === 0,
    };
  };

  return {
    needs: calcDonutItem(PriorityNumber.Need),
    wants: calcDonutItem(PriorityNumber.Want),
    dreams: calcDonutItem(PriorityNumber.Dream),
    total: calcDonutItem(),
    score: (planResult?.plan_score ?? 0) * 100,
    summaryItems: [
      calcSummaryItem(PriorityNumber.Need),
      calcSummaryItem(PriorityNumber.Want),
      calcSummaryItem(PriorityNumber.Dream),
    ],
  };
};
