import { useCallback } from "react";
import {
  useQuestionnairePlanGoalsCreateMutation,
  useQuestionnairePlanGoalsDestroyMutation,
  useQuestionnairePlanGoalsListQuery,
  useQuestionnairePlanGoalsUpdateMutation,
  useQuestionnairePlanRetrieveQuery,
  useQuestionnairePlanUpdateMutation,
} from "api/carApi.generated";
import { CarClientPlan, CarGoal, CarGoalIcon } from "types";
import { refetchOnFocus } from "const";
import { usePendingProxy } from "../general/usePendingProxy";
import { usePlanId } from "app/usePlanId";
import { useTaxPurposes } from "app/useTaxPurposes";
import { useDispatch } from "react-redux";
import { pendingActions } from "../general/pendingSlice";
import { isDateExtraSame } from "utils";
import { useRelationshipLight } from "app/useRelationshipLight";

export const useGoal = () => {
  const dispatch = useDispatch();
  const planId = usePlanId();
  const { data: goalData, isLoading: goalIsLoading } =
    useQuestionnairePlanGoalsListQuery({ planId }, { refetchOnFocus });

  const { data: clientPlanData, isLoading: clientPlanIsLoading } =
    useQuestionnairePlanRetrieveQuery({ id: planId }, { refetchOnFocus });

  const relationship = useRelationshipLight();

  const [createRel] = useQuestionnairePlanGoalsCreateMutation();
  const [updateRel] = useQuestionnairePlanGoalsUpdateMutation();
  const [deleteRel] = useQuestionnairePlanGoalsDestroyMutation();
  const [updateClientPlanRel] = useQuestionnairePlanUpdateMutation();

  const { getTaxPurposeIdByCode } = useTaxPurposes();

  const handleChange = (item: CarGoal) =>
    updateRel({
      planId,
      id: item.id ?? "",
      clientPlanGoal: item as Required<CarGoal>,
    });

  const handleDelete = (item: CarGoal) =>
    deleteRel({ planId, id: item.id ?? "" });

  const handleCreate = (item: CarGoal) =>
    createRel({
      planId,
      clientPlanGoal: item as Required<CarGoal>,
    });

  const handleClientPlanChange = async (item: CarClientPlan) => {
    dispatch(pendingActions.setIsUpdating(true));
    try {
      return await updateClientPlanRel({
        id: planId,
        clientPlan: item as Required<CarClientPlan>,
      });
    } finally {
      dispatch(pendingActions.setIsUpdating(false));
    }
  };

  const { items: proxyItems, ...proxy } = usePendingProxy<CarGoal>({
    name: "goal",
    items: goalData ?? [],
    handleCreate,
    handleChange,
    handleDelete,
    normalizeItem: (item, oldItem) => {
      if (!item.amount) {
        delete item.amount;
      }
      if (item.amount && item.amount < 0) {
        delete item.amount;
      }
      if (!item.defer_goal) {
        item.defer_how_long = 0;
      }

      if ((item.defer_how_long ?? 0) < 0) {
        item.defer_how_long = 0;
      }

      if (isDateExtraSame(item.start_date_caravel, item.end_date_caravel)) {
        item.frequency = 1;
      }

      item.description = item.pii_description ?? undefined;
    },
    required: [
      "pii_description",
      "start_date_caravel",
      "end_date_caravel",
      "amount",
      "frequency",
      "tax_purpose",
    ],
    validate: (item) => {
      const start = relationship.getDateExtraInfo(item.start_date_caravel);
      const end = relationship.getDateExtraInfo(item.end_date_caravel);

      if (start && end) {
        return (end.year ?? 0) >= (start.year ?? 0);
      } else {
        return false;
      }
    },
  });

  const items = [...proxyItems].sort((a, b) => {
    let result = (a.position ?? 0) - (b.position ?? 0);
    if (result !== 0) {
      return result;
    } else {
      return (a.created_at ?? "").localeCompare(b.created_at ?? "");
    }
  });

  const addRow = useCallback(
    (icon: CarGoalIcon) => {
      let tax_purpose: string | undefined;

      switch (icon) {
        case "LEGACY":
          tax_purpose = getTaxPurposeIdByCode("LEGACY");
          break;

        case "CHARITY":
          tax_purpose = getTaxPurposeIdByCode("CHARITY");
          break;

        case "RETIREMENT_SPENDING":
          tax_purpose = getTaxPurposeIdByCode("RETIREMENT");
          break;

        case "HEALTHCARE":
          tax_purpose = getTaxPurposeIdByCode("HEALTHCARE");
          break;

        case "EDUCATION":
          tax_purpose = getTaxPurposeIdByCode("EDUCATION");
          break;

        default:
          tax_purpose = getTaxPurposeIdByCode("OTHER");
          break;
      }

      return proxy.handleCreate({
        icon,
        start_date_caravel: undefined,
        end_date_caravel: undefined,
        enabled: true,
        inflation: 0,
        priority: "NEED",
        frequency: 1,
        tax_purpose,
        defer_goal: false,
        position:
          items.reduce((max, i) => Math.max(i.position ?? 0, max), 0) + 1,
      });
    },
    [getTaxPurposeIdByCode, items, proxy]
  );

  const isLoading = goalIsLoading || clientPlanIsLoading;

  const addSingleGoal = useCallback(() => {
    if (isLoading || items.length > 0) {
      return false;
    }
    addRow("GENERIC");
    return true;
  }, [items, addRow, isLoading]);

  const handleMove = (startIndex: number, endIndex: number) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((i, idx) => {
      if (i.position !== idx) {
        proxy.handleChange({ ...i, position: idx });
      }
    });
  };

  const totalAmount = items.reduce((acc, i) => acc + (i.amount ?? 0), 0);
  const totalAmountAcrossPlan = items.reduce(
    (acc, i) => acc + (i.total_amount_across_plan ?? 0),
    0
  );

  return {
    ...proxy,
    items,
    isLoading,
    clientPlanData,
    handleMove,
    addRow,
    handleClientPlanChange,
    totalAmount,
    totalAmountAcrossPlan,
    addSingleGoal,
  };
};
