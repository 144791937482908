import { Box, Collapse } from "@mui/material";
import blackDiamondLogo from "assets/black_diamond_logo.png";
import raymondJamesLogo from "assets/raymond_james_logo.png";
import schwabLogo from "assets/schwab_logo.png";
import React, { useState } from "react";
import { BlackDiamondSearch } from "./BlackDiamondSearch";
import { AssetsLogo } from "../AssetsLogo";
import { CarLinkExternalCreate } from "types";
import { CarDisclosure } from "components/Disclosure";
import { useQuestionnaireExternalListAvailableIntegratorsListQuery } from "api/carApi.generated";
import { RaymondJamesSearch } from "./RaymondJamesSearch";
import { SchwabSearch } from "./SchwabSearch";

interface CarIntegrator {
  code: string;
  logo: string;
  backgroundColor?: string;
  component: React.ComponentType;
}

export const AssetsIntegration = () => {
  const [integrator, setIntegrator] =
    useState<CarLinkExternalCreate["integrator"]>();

  const integrators =
    useQuestionnaireExternalListAvailableIntegratorsListQuery();

  const integratorItems: CarIntegrator[] = [
    {
      code: "black_diamond",
      logo: blackDiamondLogo,
      component: BlackDiamondSearch,
    },
    {
      code: "raymond_james",
      logo: raymondJamesLogo,
      component: RaymondJamesSearch,
    },
    {
      code: "schwab",
      logo: schwabLogo,
      backgroundColor: "#00A0DF",
      component: SchwabSearch,
    },
  ].filter(
    (i) => integrators.data?.find((item) => item.code === i.code)?.enabled,
  );

  if (integrators.isLoading || integratorItems.length === 0) {
    return <></>;
  }

  const Component = integratorItems.find((i) => i.code === integrator)
    ?.component;

  return (
    <Box display="flex" flexDirection="column">
      <Box sx={{ display: "flex", justifyContent: "center", gap: 10 }}>
        {integratorItems.map((i) => (
          <AssetsLogo
            key={i.code}
            code={i.code}
            image={i.logo}
            backgroundColor={i.backgroundColor}
            value={integrator}
            onChange={(value) =>
              value === integrator
                ? setIntegrator(undefined)
                : setIntegrator(value as CarLinkExternalCreate["integrator"])
            }
          />
        ))}
      </Box>
      <CarDisclosure sx={{ mx: 1, mt: 3 }}>
        We rely on the information provided by you or your financial advisor to
        calculate projected outcomes. If the information provided is not
        accurate and complete, the projections may be impacted in a positive or
        negative manner. Please see our disclosures for more information.
      </CarDisclosure>
      <Collapse in={!!integrator}>
        <Box
          sx={{
            m: 1,
            mt: 4,
          }}
        >
          {Component && <Component />}
        </Box>
      </Collapse>
    </Box>
  );
};
