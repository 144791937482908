import { formatCurrency, isDefined } from "utils";
import {
  CarAllocationAccount,
  CarAllocationAccountGoal,
  UseAllocationByAccount,
} from "../allocationByAccount/useAllocationByAccount";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfAllocationChart } from "./PdfAllocationChart";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfSubHeading } from "./PdfHeading";
import { CarPdfMarkdown } from "components/PdfMarkdown";
import { CarPdfTypography } from "components/PdfTypography";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTable,
  CarPdfTextCell,
} from "components/PdfTable";
import { CarPdfBox, CarPdfBoxProps } from "components/PdfBox";
import { colorByStatus } from "utils/colorUtils";
import { useTheme } from "@mui/material";
import { CarPdfModulesTable } from "./PdfModulesTable";
import { CarPdfAllocationTable } from "./PdfAllocationTable";

interface GoalItemProps extends Omit<CarPdfBoxProps, "children" | "style"> {
  goal: CarAllocationAccountGoal;
}
const GoalItem = ({ goal, ...props }: GoalItemProps) => (
  <CarPdfBox style={{ flexDirection: "row", gap: 6 }} {...props}>
    <CarPdfTypography style={{ marginLeft: 18 }} variant="body1">
      {`\u2022 ${goal.name}`}
    </CarPdfTypography>
    <CarPdfTypography
      variant="body1"
      style={{ color: colorByStatus(goal.goal_status) }}
    >
      {formatCurrency(goal.dollars)}
    </CarPdfTypography>
  </CarPdfBox>
);

const TotalWithSurplus = (props: { label: string; surplusAmount?: number }) => {
  const theme = useTheme();
  return (
    <CarPdfBox
      style={{
        flexDirection: "row",
        width: "100%",
        marginBottom: 5,
      }}
    >
      <CarPdfTypography variant="h6">{props.label}</CarPdfTypography>
      {!!props.surplusAmount && (
        <CarPdfBox style={{ flexDirection: "row", flexGrow: 1 }}>
          <CarPdfBox
            style={{
              marginLeft: 6,
              marginRight: 6,
              flexGrow: 1,
              borderBottomWidth: 1,
              alignSelf: "center",
              borderColor: "#c3c3c3",
            }}
          />
          <CarPdfTypography variant="h6">Surplus:</CarPdfTypography>
          <CarPdfTypography
            style={{
              marginLeft: 5,
              color: theme.palette.green,
            }}
            variant="h6"
          >
            {formatCurrency(props.surplusAmount)}
          </CarPdfTypography>
        </CarPdfBox>
      )}
    </CarPdfBox>
  );
};

interface AccountPageProps {
  portfolioLabel: string;
  account: CarAllocationAccount;
  pageProps: CarPdfPageProps;
}

const AccountPage = (props: AccountPageProps) => {
  return (
    <CarPdfPage {...props.pageProps}>
      <CarPdfTypography style={{ marginBottom: 5 }} variant="h5">
        {props.account.name}
      </CarPdfTypography>
      <CarPdfBox
        style={{
          backgroundColor: "#fafafa",
          padding: 20,
          marginBottom: 5,
          flexGrow: 1,
          justifyContent: "space-between",
          // gap: 20,
        }}
      >
        <CarPdfBox wrap={false}>
          <TotalWithSurplus
            label={`Goals funded by this account`}
            surplusAmount={props.account.surplusAmount}
          />
          {props.account.currentAccountBalanceToFundGoals.map((i, idx) => (
            <GoalItem goal={i} key={idx} />
          ))}
          <CarPdfTypography
            style={{ marginTop: 10, marginBottom: 10 }}
            variant="h6"
          >
            Modules
          </CarPdfTypography>
          <CarPdfModulesTable
            breakdownItems={props.account.multiModuleBreakdown}
          />
        </CarPdfBox>
        <CarPdfAllocationChart
          style={{ marginTop: 10 }}
          width={478}
          height={180}
          items={props.account.allocAssets.level1}
          portfolioLabel={props.portfolioLabel}
          isGray
        />
        <CarPdfBox wrap={false}>
          <CarPdfTypography style={{ marginBottom: 10 }} variant="h6">
            Allocation
          </CarPdfTypography>
          <CarPdfAllocationTable
            portfolioLabel={props.portfolioLabel}
            items={props.account.allocAssets.level1}
          />
        </CarPdfBox>
      </CarPdfBox>
    </CarPdfPage>
  );
};

export interface PortfolioModuleBreakdownAccountPdfPageProps {
  pageProps: CarPdfPageProps;
  portfolioLabel: string;
  allocationByAccount: UseAllocationByAccount;
  disclosures: UseDisclosures;
}

const formatCurrencyOrDash = (value?: number) =>
  isDefined(value) ? formatCurrency(value) : "-";

export const PortfolioModuleBreakdownAccountPdfPage = (
  props: PortfolioModuleBreakdownAccountPdfPageProps,
) => {
  const text1 = props.disclosures.getContent(
    CarDisclosureContentCode.CMPAS_CLIENT_REPORT_PORTFOLIO_MODULE_BREAKDOWN_BY_ACCOUNT_part_10,
  );

  return (
    <>
      <CarPdfPage {...props.pageProps}>
        <CarPdfSubHeading label="Portfolio Module Breakdown: Account" />
        <CarPdfMarkdown style={{ marginTop: 10 }}>{text1}</CarPdfMarkdown>
        <CarPdfTypography
          style={{ marginTop: 20, marginBottom: 10 }}
          variant="h6"
        >
          Accounts Summary
        </CarPdfTypography>
        <CarPdfTable colWidths={[1.3, 0.7, 1, 1.2, 1, 1]}>
          <CarPdfHeader paddingVertical={14}>
            <CarPdfHeaderCell>Assets / Account Name</CarPdfHeaderCell>
            <CarPdfHeaderCell>Account Owner</CarPdfHeaderCell>
            <CarPdfHeaderCell>Current Amount</CarPdfHeaderCell>
            <CarPdfHeaderCell>Lifetime Amount</CarPdfHeaderCell>
            <CarPdfHeaderCell>Annual Savings</CarPdfHeaderCell>
            <CarPdfHeaderCell>Surplus</CarPdfHeaderCell>
          </CarPdfHeader>
          {props.allocationByAccount.accountsSummary.map((i, idx) => (
            <CarPdfRow key={idx} paddingVertical={10} textVariant="tableBody3">
              <CarPdfTextCell
                style={{
                  textAlign: "left",
                  paddingHorizontal: 5,
                }}
              >
                {i.accountName}
              </CarPdfTextCell>
              <CarPdfTextCell>{i.accountOwner}</CarPdfTextCell>
              <CarPdfTextCell>
                {formatCurrencyOrDash(i.currentAmount)}
              </CarPdfTextCell>
              <CarPdfTextCell>
                {formatCurrencyOrDash(i.lifetimeAmount)}
              </CarPdfTextCell>
              <CarPdfTextCell>
                {formatCurrencyOrDash(i.annualSavings)}
              </CarPdfTextCell>
              <CarPdfTextCell>{formatCurrencyOrDash(i.surplus)}</CarPdfTextCell>
            </CarPdfRow>
          ))}
        </CarPdfTable>
        {props.allocationByAccount.extraAssets.length && (
          <CarPdfBox>
            <CarPdfTypography
              style={{ marginTop: 20, marginBottom: 10 }}
              variant="h5"
            >
              Assets
            </CarPdfTypography>
            <CarPdfBox
              style={{
                backgroundColor: "#fafafa",
                padding: 20,
                marginBottom: 5,
                gap: 20,
              }}
            >
              {props.allocationByAccount.extraAssets.map((item) => (
                <CarPdfBox
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  key={item.id}
                  wrap={false}
                >
                  <TotalWithSurplus
                    label={`Total $ from ${item.name} to fund goal(s)`}
                    surplusAmount={item.surplusAmount}
                  />
                  {item.totalAmountToFundEachGoal?.length > 0 ? (
                    item.totalAmountToFundEachGoal?.map((i, idx) => (
                      <GoalItem goal={i} key={idx} />
                    ))
                  ) : (
                    <CarPdfTypography
                      style={{ marginLeft: 18 }}
                      variant="body1"
                    >
                      -
                    </CarPdfTypography>
                  )}
                </CarPdfBox>
              ))}
            </CarPdfBox>
          </CarPdfBox>
        )}
      </CarPdfPage>
      {props.allocationByAccount.accounts.map((i, idx) => (
        <AccountPage
          key={idx}
          portfolioLabel={props.portfolioLabel}
          account={i}
          pageProps={props.pageProps}
        />
      ))}
    </>
  );
};
