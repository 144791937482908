import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarStressTestData } from "types";
import { formatCurrency, formatPercentFactor } from "utils";
import { CarPdfSubHeading } from "./PdfHeading";
import {
  CarPdfHeader,
  CarPdfTable,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfViewCell,
  CarPdfTextCell,
} from "components/PdfTable";

export interface MarketEnvironmentPdfPageProps {
  pageProps: CarPdfPageProps;
  items: Array<CarStressTestData>;
}

export const MarketEnvironmentPdfPage = (
  props: MarketEnvironmentPdfPageProps,
) => (
  <CarPdfPage {...props.pageProps}>
    <CarPdfSubHeading label="Market Environment" />
    <CarPdfTable
      style={{
        marginTop: 15,
      }}
      colWidths={[1, 1, 1, 1, 1.2, 2, 1, 1, 1.2]}
    >
      <CarPdfHeader paddingVertical={14}>
        <CarPdfHeaderCell />
        <CarPdfHeaderCell>CPI</CarPdfHeaderCell>
        <CarPdfHeaderCell>Large Cap</CarPdfHeaderCell>
        <CarPdfHeaderCell>10-Year Treasury</CarPdfHeaderCell>
        <CarPdfHeaderCell>Beginning Portfolio</CarPdfHeaderCell>
        <CarPdfHeaderCell>Return on Assets</CarPdfHeaderCell>
        <CarPdfHeaderCell>Cash in Portfolio</CarPdfHeaderCell>
        <CarPdfHeaderCell>Cash out Portfolio</CarPdfHeaderCell>
        <CarPdfHeaderCell>Ending Portfolio</CarPdfHeaderCell>
      </CarPdfHeader>
      {props.items.map((i, idx) => (
        <CarPdfRow
          key={i.year}
          style={{
            backgroundColor: idx % 2 === 0 ? "white" : "#f3f6f8",
          }}
          textVariant="tableBody3"
          paddingVertical={10}
        >
          <CarPdfTextCell variant="tableBody4">
            {i.year.toString()}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatPercentFactor(i.observed_inflation_rate ?? 0, 1)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatPercentFactor(i.observed_large_cap_return ?? 0, 1)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatPercentFactor(i.observed_ten_year_treasury_return ?? 0, 1)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.beginning_total_portfolio_value ?? 0)}
          </CarPdfTextCell>
          <CarPdfViewCell style={{ paddingVertical: 0 }}>
            <CarPdfTextCell
              variant="tableBody3"
              paddingVertical={10}
              style={{
                borderLeftWidth: 0,
                borderBottomWidth: 0,
              }}
            >
              {formatCurrency(i.return_on_asset_dollar ?? 0)}
            </CarPdfTextCell>
            <CarPdfTextCell
              variant="tableBody3"
              paddingVertical={10}
              style={{
                borderBottomWidth: 0,
                borderRightWidth: 0,
              }}
            >
              {formatPercentFactor(i.return_on_asset ?? 0, 1)}
            </CarPdfTextCell>
          </CarPdfViewCell>
          <CarPdfTextCell>
            {formatCurrency(i.cash_in_portfolio ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.cash_out_portfolio ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.ending_total_portfolio_value ?? 0)}
          </CarPdfTextCell>
        </CarPdfRow>
      ))}
    </CarPdfTable>
  </CarPdfPage>
);
