import { PdfChart, PdfChartProps } from "components/Pdf";
import { ChartConfiguration, ChartDataset, FontSpec } from "chart.js";
import { ChartPlugins } from "chartUtils";
import { Theme, useTheme } from "@mui/material";
import ReactPDF, { Text, View } from "@react-pdf/renderer";
import { typographyOptions } from "theme/typography";
import {
  CarPortfolioHeatmapChartDataset,
  CarPortfolioHeatmapChartItem,
} from "../asset-allocation-types";
import { SimulatedForwardProbabilitiesACData } from "pages/assetClassAnalysis/ChartSimulatedForwardProbabilitiesAC";

interface InternalPdfChartProps extends Omit<PdfChartProps, "config"> {
  label: string;
  datasets: CarPortfolioHeatmapChartDataset[];
  isDownside?: boolean;
  yLabelPosition: "right" | "left";
  theme: Theme;
}

const InternalPdfChart = ({
  label,
  datasets,
  isDownside,
  yLabelPosition,
  theme,
  ...props
}: InternalPdfChartProps) => {
  const font: Partial<FontSpec> = {
    size: 14,
    weight: "400",
    lineHeight: 1,
    family: typographyOptions.fontFamily,
  };

  const config: ChartConfiguration<"line", number[]> = {
    type: "line",
    options: {
      devicePixelRatio: 4,
      responsive: false,
      animation: false,
      borderColor: "transparent",

      layout: {
        padding: {
          right: 1, // for some reason right border is cropped, so add right padding to fit it in image
        },
      },

      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: "Year",
            font,
            color: theme.palette.black,
          },
          border: { display: false },
          grid: {
            display: true,
            color: theme.palette.gray2,
            tickWidth: 0,
          },
          ticks: {
            font,
            color: theme.palette.black,
            maxRotation: 0,
          },
        },
        y: {
          position: yLabelPosition,
          ticks: {
            font,
            color: theme.palette.black,
          },
          border: { display: false },
          grid: {
            display: true,
            color: theme.palette.gray2,
            tickWidth: 0,
          },
          title: {
            display: true,
            text: "Value ($)",
            color: theme.palette.black,
            font,
          },
          max: isDownside ? 100 : undefined,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          align: "start",
          fullSize: false,
          text: label,
          font,
          color: theme.palette.black,
        },

        roundedBackground: {
          contextColor: theme.palette.white,
          backgroundColor: "#FAFAFA", //theme.palette.gray1,
          borderColor: "#B1B1B3", // theme.palette.gray7,
          borderRadius: 0,
        },
      },
    },
    data: {
      labels: datasets[0]?.x,
      datasets: datasets.map<ChartDataset<"line", number[]>>((ds) => ({
        label: ds.label,
        data: ds.y,
        borderColor: ds.color,
        backgroundColor: ds.color,
        fill: ds.fill,
        pointRadius: 0,
        borderWidth: 2,
      })),
    },
    plugins: [ChartPlugins.roundedBackground],
  };

  return <PdfChart {...props} config={config} />;
};

const PdfChartLegend = ({
  datasets,
}: {
  datasets: CarPortfolioHeatmapChartDataset[];
}) => {
  return (
    <View
      style={{
        marginTop: 10,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {datasets.map((ds, idx) => {
        return (
          <View
            key={idx}
            style={{
              marginLeft: 4,
              marginRight: 4,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: 10,
                height: 10,
                backgroundColor: String(ds.color ?? "black"),
                marginRight: 4,
              }}
            />
            <Text
              style={{
                fontSize: 10,
              }}
            >
              {ds.label}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

interface SimulatedForwardProbabilitiesPdfChartProps
  extends Omit<ReactPDF.ViewProps, "children"> {
  data: CarPortfolioHeatmapChartItem[];
  label: string;
  dataId: string;
  isNominal: boolean;
  chartDateRange: number;
}

export const SimulatedForwardProbabilitiesPdfChart = ({
  data,
  label,
  dataId,
  isNominal,
  chartDateRange,
  style,
  ...props
}: SimulatedForwardProbabilitiesPdfChartProps) => {
  const theme = useTheme();

  const datasets =
    data
      .find((i) => i.id === dataId)
      ?.datasets.map<CarPortfolioHeatmapChartDataset>((ds) => ({
        ...ds,
        x: ds.x.slice(0, chartDateRange),
        y: ds.y.slice(0, chartDateRange),
      })) ?? [];

  const realLabel = isNominal ? "Nominal" : "Real";

  return (
    <View style={{ ...style }} {...props}>
      <View style={{ flexDirection: "row" }}>
        <InternalPdfChart
          theme={theme}
          label={`${label} Simulated Outcome (${realLabel})`}
          datasets={datasets}
          width={270}
          height={250}
          yLabelPosition="left"
        />
        <InternalPdfChart
          style={{ marginLeft: 5 }}
          theme={theme}
          label={`${label} Simulated Downside (${realLabel})`}
          datasets={datasets}
          width={270}
          height={250}
          isDownside
          yLabelPosition="right"
        />
      </View>
      <PdfChartLegend datasets={datasets} />
    </View>
  );
};

interface SimulatedForwardProbabilitiesPdfChartACProps
  extends Omit<ReactPDF.ViewProps, "children"> {
  data: SimulatedForwardProbabilitiesACData;
}

export const SimulatedForwardProbabilitiesPdfChartAC = ({
  data,
  style,
  ...props
}: SimulatedForwardProbabilitiesPdfChartACProps) => {
  const theme = useTheme();

  return (
    <View style={{ ...style }} {...props}>
      <View style={{ flexDirection: "row" }}>
        <InternalPdfChart
          theme={theme}
          label={data.label1}
          datasets={data.datasets1}
          width={270}
          height={250}
          yLabelPosition="left"
        />
        <InternalPdfChart
          style={{ marginLeft: 5 }}
          theme={theme}
          label={data.label2}
          datasets={data.datasets2}
          width={270}
          height={250}
          yLabelPosition="left"
        />
      </View>
      <View style={{ flexDirection: "row", marginTop: 10 }}>
        <InternalPdfChart
          theme={theme}
          label={data.label1Downside}
          datasets={data.datasets1}
          width={270}
          height={250}
          isDownside
          yLabelPosition="left"
        />
        <InternalPdfChart
          style={{ marginLeft: 5 }}
          theme={theme}
          label={data.label2Downside}
          datasets={data.datasets2}
          width={270}
          height={250}
          isDownside
          yLabelPosition="left"
        />
      </View>
      <PdfChartLegend datasets={data.datasets1} />
    </View>
  );
};
