import { CarAccount, CarPosition, CarSavings } from "types";
import { AccountType } from "const";
import { v4 } from "uuid";
import { epsilonEquals } from "utils";

export const isIntegratorAccount = (item: CarAccount) =>
  item.account_input_type === AccountType.Integrated;

export const isPercentAccount = (item: CarAccount) =>
  item.account_input_type === AccountType.ManualPercent;

export const isDollarAccount = (item: CarAccount) =>
  item.account_input_type === AccountType.ManualDollar;

export const isSummaryAccount = (item: CarAccount) =>
  isPercentAccount(item) || isDollarAccount(item);

export const isDetailedHoldingsAccount = (item: CarAccount) =>
  item.account_ui_input_type === "manual_detailed_holdings";

export const getPositionsTotals = (item: CarAccount) => {
  // todo simple_holdings in totals?
  const accountMarketValue = item.market_value ?? 0;
  const accountCostBasis = item.cost_basis ?? 0;
  const detailedHoldings: Array<CarPosition> = item.detailed_holdings ?? [];

  const marketPrice = detailedHoldings.reduce((acc, i) => {
    const shares = i.shares ?? 0;
    return acc + (shares ? (i.market_value ?? 0) / shares : 0);
  }, 0);

  const marketValue = detailedHoldings.reduce(
    (acc, i) => acc + (i.market_value ?? 0),
    0
  );

  const marketPricePercent = accountMarketValue
    ? (marketPrice / accountMarketValue) * 100
    : 0;

  const costBasis = detailedHoldings.reduce(
    (acc, i) => acc + (i.cost_basis ?? 0),
    0
  );

  const costBasisPercent = accountCostBasis
    ? (costBasis / accountCostBasis) * 100
    : 0;

  return {
    marketPrice,
    marketPricePercent,
    costBasis,
    costBasisPercent,
    marketValue,
  };
};

export const addDetailedHolding = (item: CarAccount) => ({
  ...item,
  detailed_holdings: [
    ...(item.detailed_holdings ?? []),
    {
      id: v4(),
      ui_key: v4(),
    },
  ],
});

export const getAccountsTotals = (items: Array<CarAccount>) => {
  const marketValue = items.reduce((acc, i) => acc + (i.market_value ?? 0), 0);
  return {
    marketValue,
  };
};

export const addAnnualSavings = (item: CarAccount): CarAccount => {
  const savings = item.savings ?? [];

  return {
    ...item,
    savings: [
      ...savings,
      {
        id: v4(),
        ui_key: v4(),
      },
    ],
  };
};

export const addAnnualSavingsIfNeeded = (item: CarAccount): CarAccount => {
  return item.is_saving && !item.savings?.length
    ? addAnnualSavings(item)
    : item;
};

export const changeAnnualSavings = (
  item: CarAccount,
  savings: CarSavings
): CarAccount => {
  return {
    ...item,
    savings:
      item.savings?.map((i) => (i.id === savings.id ? savings : i)) ?? [],
  };
};

export const deleteAnnualSavings = (
  item: CarAccount,
  savings: CarSavings
): CarAccount => {
  return {
    ...item,
    savings: item.savings?.filter((i) => i.id !== savings.id) ?? [],
  };
};

export const getAnnualSavingsValue = (item: CarSavings) => {
  return item.get_data?.[1]?.[0];
};

const roundTo = (value: number) =>
  Math.round(value * 100 + Number.EPSILON) / 100; // round to 2 decimals

export const convertToPercentAccount = (item: CarAccount) => {
  const totalValue =
    item.simple_holdings?.reduce((acc, i) => (acc += i.market_value ?? 0), 0) ??
    0;
  item.simple_holdings = item.simple_holdings?.map((i) => {
    const percentage = i.market_value
      ? roundTo((i.market_value ?? 0) / totalValue)
      : 0;
    return { ...i, percentage, market_value: undefined };
  });

  item.manual_percent_total_market_value = totalValue || null;
};

export const convertToDollarAccount = (item: CarAccount) => {
  const totalValue = item.manual_percent_total_market_value ?? 0;
  item.simple_holdings = item.simple_holdings?.map((i) => {
    const market_value = roundTo(totalValue * (i.percentage ?? 0));
    return { ...i, market_value, percentage: undefined };
  });

  item.manual_percent_total_market_value = null;
};

export enum PercentageValidationResult {
  Valid = 0,
  Less = 1,
  More = 2,
}
export const summaryPercentageValid = (item: CarAccount) => {
  if (isSummaryAccount(item) && isPercentAccount(item)) {
    const percentage =
      item.simple_holdings?.reduce((acc, i) => acc + (i.percentage ?? 0), 0) ??
      0;
    if (epsilonEquals(percentage, 1)) {
      // 1 equals 100%
      return PercentageValidationResult.Valid;
    } else {
      return percentage < 1
        ? PercentageValidationResult.Less
        : PercentageValidationResult.More;
    }
  } else {
    return PercentageValidationResult.Valid;
  }
};
