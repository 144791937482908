import { useDispatch } from "react-redux";
import { useAppSelector } from "app/hooks";
import { controlPanelActions, CpDeRiskDetails } from "app/controlPanelSlice";
import { isDefined } from "utils";
import { useGoal } from "features/goals/useGoal";
import { IdObj } from "types";
import { useDerisk } from "features/planResults/useDerisk";

type UpdateItemFunc = () => Promise<unknown>;

export type UpdateProgressData = { current: number; total: number };

function delay(t: number) {
  return new Promise((resolve) => setTimeout(resolve, t));
}

const cleanCpObject = (obj?: IdObj) => {
  if (!obj) {
    return undefined;
  }

  const resultObj: Record<string, any> = {};

  Object.keys(obj)
    .filter((key) => (obj as any)[key] !== undefined)
    .forEach((key) => {
      resultObj[key] = (obj as any)[key];
    });

  // return undefined if only id field left
  return Object.keys(resultObj).filter((key) => key !== "id").length
    ? resultObj
    : undefined;
};

export const useControlPanelSave = () => {
  const dispatch = useDispatch();
  const cpData = useAppSelector((state) => state.controlPanel);

  const goals = useGoal();
  const derisk = useDerisk();

  function getGoalItems(): Array<UpdateItemFunc> {
    return cpData.goals
      .map(cleanCpObject)
      .filter(isDefined)
      .map((i) => async () => {
        const orig = goals.items.find((v) => v.id === i.id);

        if (!orig) {
          return;
        }

        const result = await goals.handleChange({
          ...orig,
          ...i,
        });

        if (result?.hasOwnProperty("data")) {
          dispatch(controlPanelActions.removeGoal(i.id));
        }
      })
      .filter(isDefined);
  }

  function getClientPlanItem(): UpdateItemFunc | undefined {
    const cpItem = cleanCpObject(cpData.clientPlan);
    if (!goals.clientPlanData || !cpItem) {
      return;
    }
    return async () => {
      const result = await goals.handleClientPlanChange({
        ...goals.clientPlanData,
        ...cpItem,
      });

      if (result?.hasOwnProperty("data")) {
        dispatch(controlPanelActions.setClientPlan(undefined));
      }
    };
  }

  function getDeriskItem(): UpdateItemFunc | undefined {
    const cpItem = cleanCpObject(cpData.derisk);
    if (!cpItem) {
      return;
    }
    return async () => {
      const result = await derisk.setDeriskModuleId(
        (cpItem as CpDeRiskDetails).portfolio_module
      );

      if (result?.hasOwnProperty("data")) {
        dispatch(controlPanelActions.setDerisk(undefined));
      }
    };
  }

  const items = [
    ...getGoalItems(),
    getClientPlanItem(),
    getDeriskItem(),
  ].filter(isDefined);

  const handleReset = () => dispatch(controlPanelActions.reset());

  const handleUpdate = async (
    progressUpdate: (progress: UpdateProgressData) => void
  ) => {
    for (let i = 0; i < items.length; i++) {
      progressUpdate({ current: i, total: items.length });
      await items[i]();
    }
    progressUpdate({ current: items.length, total: items.length });
    await delay(500);
  };

  return {
    isLoading: goals.isLoading,
    isUpdateAvailable: items.length > 0,
    handleReset,
    handleUpdate,
  };
};
