import { Typography } from "@mui/material";
import { TwoFactorCode } from "./TwoFactorCode";
import { UseLogin } from "./useLogin";
import { LoginButton, LoginTransparentBox } from "./LoginComponents";

export const TwoFactorForm = ({ data }: { data: UseLogin }) => {
  return (
    <LoginTransparentBox
      sx={{
        pt: 8,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Typography variant="dis01SemiBold">******</Typography>
      <Typography variant="h3SemiBold" sx={{ mt: -3 }}>
        Enter your code.
      </Typography>
      <Typography variant="par02Regular" sx={{ ml: 1, mt: 1.5 }}>
        Please enter the code in your authentication app.
      </Typography>
      <Typography
        sx={{
          mt: 5.5,
          ml: 1,
          fontStyle: "italic",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "14px",
        }}
      >
        Authentication Code
      </Typography>
      <TwoFactorCode
        sx={{ mt: 3, mx: "auto" }}
        value={data.twoFactorCode}
        onChange={data.setTwoFactorCode}
      />
      <LoginButton
        sx={{
          mt: 6,
          mx: "auto",
          minWidth: 200,
        }}
        disabled={!data.canVerifyTwoFactor}
        onClick={data.verifyTwoFactor}
      >
        Verify
      </LoginButton>
      <Typography
        variant="par03Regular"
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
          mt: 2,
          mx: "auto",
        }}
        onClick={data.logout}
      >
        Logout
      </Typography>
    </LoginTransparentBox>
  );
};
