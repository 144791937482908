import { Box, BoxProps } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import {
  SimulationBehaviorInflation,
  useAssetClassesSimulationBehaviorInflationListQuery,
  useAssetClassesSimulationBehaviorInflationUpdateMutation,
} from "api/carApi.generated";
import { CarNumberFieldDelayed } from "components/NumberField";

interface SimulationModelsBehaviorInflationProps {
  sx?: BoxProps["sx"];
}

export const SimulationModelsBehaviorInflation = (
  props: SimulationModelsBehaviorInflationProps,
) => {
  const data = useAssetClassesSimulationBehaviorInflationListQuery({
    id: "",
  });

  const [updateMut] =
    useAssetClassesSimulationBehaviorInflationUpdateMutation();

  const item = data.data?.at(0);

  const updateItem = (value: SimulationBehaviorInflation) => {
    updateMut({
      id: value.id ?? "",
      simulationBehaviorInflation: value,
    });
  };

  return (
    <Box sx={{ ...props.sx, display: "flex", flexDirection: "column" }}>
      <TablePlaceHolder isLoading={data.isLoading} rows={8}>
        {item && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "180px 180px",
              columnGap: 11,
              rowGap: 5,
              ".MuiInputBase-root": {
                backgroundColor: "white",
              },
            }}
          >
            <CarNumberFieldDelayed
              label="Absolute Upper Boundary"
              decimalPlaces={3}
              value={item.absolute_upper_boundary}
              onChange={(absolute_upper_boundary) =>
                updateItem({
                  ...item,
                  absolute_upper_boundary: absolute_upper_boundary ?? 0,
                })
              }
            />
            <CarNumberFieldDelayed
              label="Absolute Lower Boundary"
              decimalPlaces={3}
              value={item.absolute_lower_boundary}
              onChange={(absolute_lower_boundary) =>
                updateItem({
                  ...item,
                  absolute_lower_boundary: absolute_lower_boundary ?? 0,
                })
              }
            />
          </Box>
        )}
      </TablePlaceHolder>
    </Box>
  );
};
