import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { useFirm } from "pages/settings/useFirm";
import { usePortfolioGroupInfo } from "pages/assetAllocation/usePortfolioGroupInfo";
import { CarPdfViewer } from "components/PdfViewer";
import { PortfolioGroupOutUnderPerfPdfChart } from "./PortfolioGroupOutUnderPerfPdfChart";
import { useParams, useSearchParams } from "react-router-dom";
import { usePortfolioGroupVersionStatData } from "../usePortfolioGroupVersionStatData";
import { PortfolioGroupMultiPeriodReturnComparisonPdfChart } from "./PortfolioGroupMultiPeriodReturnComparisonPdfChart";
import { PortfolioGroupSectorSummaryPdfChart } from "./PortfolioGroupSectorSummaryPdfChart";
import { usePortfolioGroupVersionSectorSummary } from "../usePortfolioGroupVersionSectorSummary";
import { format } from "date-fns";
import { CarPdfDocument } from "components/PdfDocument";
import { CarPdfPage } from "components/PdfPage";
import { useUser } from "pages/settings/useUser";
import {
  CarPdfHeadingAC,
  CarPdfInternalUse,
} from "features/financialPlanningReport/PdfHeading";
import { CarPdfTypography } from "components/PdfTypography";

export const PortfolioGroupModulePdfReportPage = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();
  const { moduleId } = useParams();

  const [searchParams] = useSearchParams();
  const data1Id = searchParams.get("data1Id") ?? "";
  const data2Id = searchParams.get("data2Id") ?? "";

  const firm = useFirm();
  const user = useUser();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const data = usePortfolioGroupVersionStatData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    filterByModuleId: moduleId,
  });

  const sectorSummary = usePortfolioGroupVersionSectorSummary({
    portfolioGroupId,
    data1Id,
    data2Id,
  });

  const isLoading =
    user.isLoading || firm.isLoading || groupInfo.isLoading || data.isLoading;

  const label1 =
    data.multiPeriodReturnComparison.find((i) => i.id === data1Id)?.label ?? "";
  const label2 =
    data.multiPeriodReturnComparison.find((i) => i.id === data2Id)?.label ?? "";

  const date = format(new Date(), "MM/dd/yyyy");
  const documentTitle = `${groupInfo.title} - ${date}`;
  const pageTitle = `${data.getModuleNameById(moduleId) ?? ""} - ${date}`;

  return (
    <CarPdfViewer isLoading={isLoading}>
      <CarPdfDocument
        title={documentTitle}
        author={`${user.userFullName}, ${firm.firm.name ?? ""}`}
      >
        <CarPdfPage
          style={{
            paddingTop: 35,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          hideStripe
          footerBottom={10}
          footerDisclosures=""
        >
          <CarPdfHeadingAC firmName={firm.firm.name ?? ""} label={pageTitle} />
          <CarPdfTypography variant="body1" style={{ marginTop: 30 }}>
            1 Year Under Performance Risk
          </CarPdfTypography>
          <CarPdfInternalUse style={{ marginRight: 54 }} />
          <PortfolioGroupOutUnderPerfPdfChart
            width={530}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.oneYearUnderPerf}
            periods={data.periods}
            data1Id={data1Id}
            data2Id={data2Id}
            isRelative={false}
          />
          <CarPdfTypography variant="body1" style={{ marginTop: 36 }}>
            3 Year Under Performance Risk
          </CarPdfTypography>
          <CarPdfInternalUse style={{ marginRight: 54 }} />
          <PortfolioGroupOutUnderPerfPdfChart
            width={530}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.threeYearUnderPerf}
            periods={data.periods}
            data1Id={data1Id}
            data2Id={data2Id}
            isRelative={false}
          />
        </CarPdfPage>
        <CarPdfPage
          style={{
            paddingTop: 35,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          hideStripe
          footerBottom={10}
          footerDisclosures=""
        >
          <CarPdfHeadingAC firmName={firm.firm.name ?? ""} label={pageTitle} />
          <CarPdfTypography variant="body1" style={{ marginTop: 30 }}>
            Cumulative Relative Performance 1998 - 2023
          </CarPdfTypography>
          <CarPdfInternalUse style={{ marginRight: 54 }} />
          <PortfolioGroupOutUnderPerfPdfChart
            width={530}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.cumRelPerf}
            periods={data.periods}
            data1Id={data1Id}
            data2Id={data2Id}
            isRelative={true}
          />
          <CarPdfTypography variant="body1" style={{ marginTop: 36 }}>
            Multi-Period Return Comparison
          </CarPdfTypography>
          <CarPdfInternalUse style={{ marginRight: 54 }} />
          <PortfolioGroupMultiPeriodReturnComparisonPdfChart
            width={520}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.multiPeriodReturnComparison}
            data1Id={data1Id}
            data2Id={data2Id}
          />
        </CarPdfPage>
        <CarPdfPage
          style={{
            paddingTop: 35,
            paddingBottom: 35,
            paddingLeft: 35,
            paddingRight: 35,
          }}
          hideStripe
          footerBottom={10}
          footerDisclosures=""
        >
          <CarPdfHeadingAC firmName={firm.firm.name ?? ""} label={pageTitle} />
          <CarPdfTypography variant="body1" style={{ marginTop: 30 }}>
            Sector Analysis
          </CarPdfTypography>
          <CarPdfInternalUse style={{ marginRight: 54 }} />
          <PortfolioGroupSectorSummaryPdfChart
            width={520}
            height={250}
            style={{ marginLeft: 10 }}
            data={sectorSummary.report.level1}
            label1={label1}
            label2={label2}
          />
        </CarPdfPage>
      </CarPdfDocument>
    </CarPdfViewer>
  );
};
