import { AssetClass } from "features/assets/useAssetClasses";
import { CarSelectField, CarTextFieldProps } from "./Inputs";
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { BarChart, LocalAtm, Money } from "@mui/icons-material";
import { CarIconInflation } from "icons";
import { ReactElement } from "react";
export interface CarAssetClassSelectorProps
  extends Omit<CarTextFieldProps, "value" | "onChange" | "children"> {
  value?: string;
  onChange: (value: string) => void;
  items: AssetClass[];
  valueField: "firmAssetClassId" | "code" | "level4Id";
}

export const getAssetClassIcon = (ac: AssetClass): ReactElement => {
  if (ac.isEquity) return <BarChart fontSize="small" />;
  if (ac.isFixedIncome) return <LocalAtm fontSize="small" />;
  if (ac.isCash) return <Money fontSize="small" />;
  if (ac.isInflation) return <CarIconInflation />;

  return <></>;
};

export const CarAssetClassSelector = ({
  value,
  onChange,
  items,
  valueField,
  ...props
}: CarAssetClassSelectorProps) => {
  return (
    <CarSelectField
      value={value}
      onChange={(value: unknown) => onChange(value as string)}
      SelectProps={{
        renderValue: (v) => items.find((i) => i[valueField] === v)?.name,
      }}
      {...props}
    >
      {items.map((i) => (
        <MenuItem
          key={i.firmAssetClassId}
          value={i[valueField]}
          disabled={i.isDisabled}
        >
          <ListItemIcon>{getAssetClassIcon(i)}</ListItemIcon>
          <ListItemText>
            <Typography variant="par01Regular">{i.name}</Typography>
          </ListItemText>
        </MenuItem>
      ))}
    </CarSelectField>
  );
};
