import clsx from "clsx";
import { styled, TextFieldProps, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { CustomSlider } from "./Slider";
import { CarSelectField } from "./Inputs";
import { CarSavingsExtraKind, CarSavingsExtra } from "types";
import _ from "lodash";
import { formatCurrency, formatPercent, isDefined } from "utils";
import { CarRadioYesNo } from "./Radio";
import { CarCurrencyField, CarPercentField } from "./NumberField";
import { Box } from "@mui/system";
import { CarDisclosure } from "./Disclosure";
import { v4 } from "uuid";

const StyledMenuItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  width: 360,
  borderBottom: `solid 1px ${theme.palette.gray3}`,
  "&.selected": {
    backgroundColor: theme.palette.lightOrange,
  },
}));

interface MenuItemProps<T> {
  kind?: CarSavingsExtraKind;
  onKindChange: (value?: CarSavingsExtraKind) => void;
  itemValue?: T; // can't use "value" for menu items (it will be overwritten)
  onValueChange: (value?: T) => void;
  onApplyChanges: () => void;
}

const PercentMenuItem = (props: MenuItemProps<number>) => {
  const handlePercentChange = (value?: number) => {
    props.onValueChange(value);
    if (value !== undefined) {
      props.onKindChange("PERCENT");
    }
  };

  return (
    <StyledMenuItem className={clsx({ selected: props.kind === "PERCENT" })}>
      <Box display="flex">
        <Typography variant="par01Regular" sx={{ mt: 1.3, width: 100 }}>
          Percentage:
        </Typography>
        <CarPercentField
          sx={{ alignSelf: "start", width: 120, mb: 1 }}
          minValue={0}
          maxValue={25}
          decimalPlaces={2}
          value={props.itemValue || undefined}
          onChange={handlePercentChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              props.onApplyChanges();
            }
          }}
        />
      </Box>
      <CustomSlider
        min={0}
        max={25}
        marks={[0, 5, 10, 15, 20, 25].map((i) => ({ value: i, label: i }))}
        value={props.itemValue ?? 0}
        onChange={handlePercentChange}
        onChangeCommitted={props.onApplyChanges}
      />
    </StyledMenuItem>
  );
};

const DollarMenuItem = (props: MenuItemProps<number>) => {
  const handleDollarChange = (value?: number) => {
    props.onValueChange(value);
    if (value !== undefined) {
      props.onKindChange("DOLLAR");
    }
  };

  return (
    <StyledMenuItem className={clsx({ selected: props.kind === "DOLLAR" })}>
      <Box display="flex">
        <Typography variant="par01Regular" sx={{ mt: 1.3, width: 100 }}>
          Dollar Amount:
        </Typography>
        <CarCurrencyField
          sx={{ alignSelf: "start", width: 120, mb: 1 }}
          minValue={0}
          value={props.itemValue || undefined}
          autoFocus
          onChange={handleDollarChange}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              props.onApplyChanges();
            }
          }}
        />
      </Box>
      <CustomSlider
        value={props.itemValue ?? 0}
        min={0}
        max={26000}
        marks={[0, 26000].map((i) => ({
          value: i,
          label: i ? `${formatCurrency(i / 1000)}K` : formatCurrency(0),
        }))}
        onChange={handleDollarChange}
        onChangeCommitted={props.onApplyChanges}
      />
    </StyledMenuItem>
  );
};

const MaxContributionMenuItem = (props: MenuItemProps<boolean>) => {
  const handleChange = (value: boolean) => {
    props.onValueChange(value);
    props.onKindChange(value ? "MAX" : undefined);
    props.onApplyChanges();
  };

  return (
    <StyledMenuItem className={clsx({ selected: props.kind === "MAX" })}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="par01Regular">Maximum contribution?</Typography>
        <CarRadioYesNo
          sx={{
            "& label": {
              ml: 2,
            },
          }}
          row
          value={props.itemValue}
          onChange={handleChange}
        />
      </Box>
      <CarDisclosure>
        Max contributions are for your convenience - calculations are based on
        current tax laws. Tax laws change and you should consult with your tax
        advisor before relying on estimates in this application. Please see our
        disclosures for more information.
      </CarDisclosure>
    </StyledMenuItem>
  );
};

export interface CarSavingsExtraFieldProps
  extends Omit<TextFieldProps, "value" | "onChange"> {
  value?: CarSavingsExtra;
  onChange: (value?: CarSavingsExtra) => void;
  showMaxContribution: boolean;
}

function getCurrentKind(
  value?: Omit<CarSavingsExtra, "id">,
): CarSavingsExtraKind | undefined {
  switch (true) {
    case isDefined(value?.amount_percent):
      return "PERCENT";
    case isDefined(value?.amount_dollar):
      return "DOLLAR";
    case isDefined(value?.max_contribution):
      return "MAX";
    default:
      return undefined;
  }
}

export const CarSavingsExtraField = ({
  value,
  onChange,
  showMaxContribution,
  SelectProps,
  ...props
}: CarSavingsExtraFieldProps) => {
  const [open, setOpen] = useState(false);
  const [kind, setKind] = useState<CarSavingsExtraKind | undefined>(
    getCurrentKind(value),
  );

  const [id, setId] = useState(value?.id);

  const [amountDollar, setAmountDollar] = useState<number | undefined>(
    value?.amount_dollar ?? 0,
  );
  const [amountPercent, setAmountPercent] = useState<number | undefined>(
    value?.amount_percent ?? 0,
  );
  const [maxContribution, setMaxContribution] = useState<boolean | undefined>(
    value?.max_contribution ?? false,
  );

  const [pendingChange, setPendingChange] = useState(false);

  const handleKindChange = (kind: CarSavingsExtraKind | undefined) => {
    setKind(kind);
    if (kind !== "MAX") {
      setMaxContribution(false);
    }
  };

  const {
    id: currentId,
    amount_dollar,
    amount_percent,
    max_contribution,
  } = value ?? {};

  useEffect(() => {
    setId(currentId);
    setAmountDollar(amount_dollar ?? 0);
    setAmountPercent(amount_percent ?? 0);
    setMaxContribution(max_contribution ?? false);
    setKind(
      getCurrentKind({ amount_dollar, amount_percent, max_contribution }),
    );
  }, [amount_dollar, amount_percent, max_contribution, currentId]);

  const getNewValue = useCallback<() => CarSavingsExtra | undefined>(() => {
    const newValue: CarSavingsExtra = {
      id: id ?? v4(),
      amount_percent:
        kind === "PERCENT" && amountPercent ? amountPercent : undefined,
      amount_dollar:
        kind === "DOLLAR" && amountDollar ? amountDollar : undefined,
      max_contribution:
        kind === "MAX" && maxContribution ? maxContribution : undefined,
    };

    return newValue.amount_dollar ||
      newValue.amount_percent ||
      newValue.max_contribution
      ? newValue
      : undefined;
  }, [id, kind, amountDollar, amountPercent, maxContribution]);

  useEffect(() => {
    if (pendingChange) {
      setOpen(false);
      setPendingChange(false);
      onChange(getNewValue());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingChange]);

  const newProps = {
    ...props,
    SelectProps: {
      ...SelectProps,
      renderValue: (v: unknown) => {
        switch (v) {
          case "PERCENT":
            return formatPercent(amountPercent ?? 0, 2);
          case "DOLLAR":
            return formatCurrency(amountDollar ?? 0);
          case "MAX":
            return maxContribution ? "Max: Yes" : "Max: No";
          default:
            return undefined;
        }
      },
    },
  };

  return (
    <CarSelectField
      {...newProps}
      value={getNewValue() ? kind : undefined}
      onChange={() => null}
      SelectProps={{
        ...newProps.SelectProps,
        open: open,
        onOpen: (e) => setOpen(true),
        onClose: (e) => {
          setOpen(false);
          if (!_.isEqual(value, getNewValue())) {
            setPendingChange(true);
          }
        },
        style: { textAlign: kind === "MAX" ? undefined : "center" },
      }}
    >
      <DollarMenuItem
        kind={kind}
        onKindChange={handleKindChange}
        itemValue={amountDollar}
        onValueChange={setAmountDollar}
        onApplyChanges={() => setPendingChange(true)}
      />
      <PercentMenuItem
        kind={kind}
        onKindChange={handleKindChange}
        itemValue={amountPercent}
        onValueChange={setAmountPercent}
        onApplyChanges={() => setPendingChange(true)}
      />
      {showMaxContribution && (
        <MaxContributionMenuItem
          kind={kind}
          onKindChange={handleKindChange}
          itemValue={maxContribution}
          onValueChange={setMaxContribution}
          onApplyChanges={() => setPendingChange(true)}
        />
      )}
    </CarSelectField>
  );
};
