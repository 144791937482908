import {
  useContentDiclosureSubCategoriesListQuery,
  useContentFirmDisclosureOverrideListQuery,
  useContentFirmTargetBaseContentOverrideListQuery,
  useContentTargetsListQuery,
} from "api/carApi.generated";

export enum CarDisclosureContentCode {
  "CMPAS_CLIENT_REPORT_FOOTER_DISCLOSURES_10_part_10" = "CMPAS_CLIENT_REPORT_FOOTER_DISCLOSURES_10_part_10",
  "ASSET_ALLOCATION_MODULE_SPREADSHEET_part_100" = "ASSET_ALLOCATION_MODULE_SPREADSHEET_part_100",
  "AA_CUMULATIVE_REL_RETURN_PERF_MAIN_part_100" = "AA_CUMULATIVE_REL_RETURN_PERF_MAIN_part_100",
  "ASSET_ALLOCATION_COMPREHENSIVE_DISCLOSURE_MAIN_part_10" = "ASSET_ALLOCATION_COMPREHENSIVE_DISCLOSURE_MAIN_part_10",
  "TERMS_OF_USE_MAIN_part_10" = "TERMS_OF_USE_MAIN_part_10",
  "GLOSSARY_part_10" = "GLOSSARY_part_10",
  "CMPAS_CLIENT_REPORT_IMPORTANT_INFORMATION_part_10" = "CMPAS_CLIENT_REPORT_IMPORTANT_INFORMATION_part_10",
  "AA_SIM_FORWARD_PROB_MAIN_part_100" = "AA_SIM_FORWARD_PROB_MAIN_part_100",
  "CMPAS_CLIENT_REPORT_STRESS_TEST_part_10" = "CMPAS_CLIENT_REPORT_STRESS_TEST_part_10",
  "CMPAS_CLIENT_REPORT_WORKBENCH_PLAN_RESULTS_part_10" = "CMPAS_CLIENT_REPORT_WORKBENCH_PLAN_RESULTS_part_10",
  "AA_MULTI_PERIOD_RETURN_COMP_MAIN_part_100" = "AA_MULTI_PERIOD_RETURN_COMP_MAIN_part_100",
  "AA_1_YEAR_UNDERPERF_RISK_MAIN_part_100" = "AA_1_YEAR_UNDERPERF_RISK_MAIN_part_100",
  "AA_PORT_MODULE_AC_BREAKDOWN_MAIN_part_100" = "AA_PORT_MODULE_AC_BREAKDOWN_MAIN_part_100",
  "CMPAS_CLIENT_REPORT_DISCLOSURES_part_10" = "CMPAS_CLIENT_REPORT_DISCLOSURES_part_10",
  "CMPAS_CLIENT_REPORT_DISCLOSURES_part_20" = "CMPAS_CLIENT_REPORT_DISCLOSURES_part_20",
  "AA_AA_PER_MODULE_MAIN_part_100" = "AA_AA_PER_MODULE_MAIN_part_100",
  "CMPAS_CLIENT_REPORT_PORTFOLIO_MODULE_BREAKDOWN_BY_ACCOUNT_part_10" = "CMPAS_CLIENT_REPORT_PORTFOLIO_MODULE_BREAKDOWN_BY_ACCOUNT_part_10",
  "CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_10" = "CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_10",
  "CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_20" = "CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_20",
  "CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_30" = "CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_30",
  "CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_40" = "CMPAS_CLIENT_REPORT_SUMMARY:_RISK_part_40",
  "AA_EFFICIENT_FRONTIER_ANALYSIS_MAIN_part_100" = "AA_EFFICIENT_FRONTIER_ANALYSIS_MAIN_part_100",
  "CMPAS_CLIENT_REPORT_SUMMARY:_AM_I_GOING_TO_BE_OK?_part_10" = "CMPAS_CLIENT_REPORT_SUMMARY:_AM_I_GOING_TO_BE_OK?_part_10",
  "CMPAS_CLIENT_REPORT_SUMMARY:_AM_I_GOING_TO_BE_OK?_part_20" = "CMPAS_CLIENT_REPORT_SUMMARY:_AM_I_GOING_TO_BE_OK?_part_20",
  "CMPAS_CLIENT_REPORT_SUMMARY:_AM_I_GOING_TO_BE_OK?_part_30" = "CMPAS_CLIENT_REPORT_SUMMARY:_AM_I_GOING_TO_BE_OK?_part_30",
  "PLANNING_COMPREHENSIVE_DISCLOSURE_MAIN_part_10" = "PLANNING_COMPREHENSIVE_DISCLOSURE_MAIN_part_10",
  "CMPAS_ACTION_PLAN_REPORT_DISCLOSURES_-_ACTION_PLAN_part_10" = "CMPAS_ACTION_PLAN_REPORT_DISCLOSURES_-_ACTION_PLAN_part_10",
  "AA_DISTANCE_FROM_TREND_MAIN_part_100" = "AA_DISTANCE_FROM_TREND_MAIN_part_100",
  "CMPAS_CLIENT_REPORT_SUMMARY:_GOALS_part_10" = "CMPAS_CLIENT_REPORT_SUMMARY:_GOALS_part_10",
  "CMPAS_CLIENT_REPORT_PORTFOLIO_MODULE_BREAKDOWN_BY_HOUSEHOLD_part_10" = "CMPAS_CLIENT_REPORT_PORTFOLIO_MODULE_BREAKDOWN_BY_HOUSEHOLD_part_10",
  "AA_3_YEARS_UNDERPERF_RISK_MAIN_part_100" = "AA_3_YEARS_UNDERPERF_RISK_MAIN_part_100",
  "CMPAS_CLIENT_REPORT_GLOSSARY_part_100" = "CMPAS_CLIENT_REPORT_GLOSSARY_part_100",
}

export const useDisclosures = (params?: { skipOverrides?: boolean }) => {
  const baseContent = useContentTargetsListQuery();
  const firmContentOverride = useContentFirmTargetBaseContentOverrideListQuery(
    undefined,
    { skip: params?.skipOverrides },
  );

  const baseDisclosure = useContentDiclosureSubCategoriesListQuery();
  const firmDisclosureOverride = useContentFirmDisclosureOverrideListQuery(
    undefined,
    { skip: params?.skipOverrides },
  );

  const contentMap = new Map<string, string>();
  const disclosureMap = new Map<string, string>();

  const getFirmDisclosureOverride = (subCategoryId?: string) =>
    firmDisclosureOverride.data?.find(
      (i) => i.disclosure_sub_category?.id === subCategoryId,
    )?.content;

  baseDisclosure.data?.forEach((bd) => {
    if (bd.code && bd.content) {
      disclosureMap.set(
        bd.code,
        getFirmDisclosureOverride(bd.id) ?? bd.content,
      );
    }
  });

  const getFirmContentOverride = (id?: string) =>
    firmContentOverride.data?.find((i) => i.base_content === id)?.content;

  baseContent.data?.forEach((target) => {
    target.target_sections?.forEach((section) => {
      section.target_base_content?.forEach((content) => {
        if (content.content_base_code && content.content) {
          let contentValue =
            getFirmContentOverride(content.id) ?? content.content;

          Array.from(disclosureMap.entries()).forEach(([key, value]) => {
            contentValue = contentValue.replaceAll(`[${key}]`, value);
          });

          contentValue = contentValue.replaceAll(`\r`, "");
          contentMap.set(content.content_base_code, contentValue);
        }
      });
    });
  });

  return {
    getContent: (code: CarDisclosureContentCode) => contentMap.get(code) ?? "",
    isLoading:
      baseContent.isLoading ||
      firmContentOverride.isLoading ||
      baseDisclosure.isLoading ||
      firmDisclosureOverride.isLoading,
  };
};

export type UseDisclosures = ReturnType<typeof useDisclosures>;
