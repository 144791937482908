import { ReactElement } from "react";
import { Box, BoxProps, ButtonBase, styled } from "@mui/material";
import {
  NavLink,
  NavLinkProps,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { forwardRef } from "react";
import { CarTooltipBox } from "./TooltipBox";

const LinkBehavior = forwardRef<
  HTMLAnchorElement,
  Omit<NavLinkProps, "to"> & { href: NavLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <NavLink ref={ref} to={href} {...other} end />;
});

const StyledRoot = styled(Box)(({ theme }) => ({
  flex: "auto",
  display: "flex",
  flexDirection: "column",
}));

const StyledTabButton = styled(ButtonBase)(({ theme }) => ({
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  ...theme.typography.par03Regular,
  borderBottom: `solid 2px rgba(0, 0, 0, 0)`,
  "&.active": {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main,
  },
}));

const StyledTabBox = styled(Box)(({ theme }) => ({
  width: "100%",
  zIndex: 0,
  display: "flex",
  justifyContent: "space-between",
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
}));

const StyledTabContent = styled(Box)(({ theme }) => ({
  flex: "auto",
  marginTop: -1,
  border: `solid 1px ${theme.palette.gray6}`,
  borderRadius: "5px",
  backgroundColor: theme.palette.gray1,
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(7),
  paddingLeft: theme.spacing(7),
  paddingRight: theme.spacing(7),
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
}));

export interface FlatPageRouteTab {
  url: string;
  path?: string;
  label: string;
  className?: string;
  pendoClassName?: string;
  content: ReactElement;
}

interface FlatPageRouteControlProps {
  sx?: BoxProps["sx"];
  tabs: FlatPageRouteTab[];
  defaultPath?: string;
}

export const flatPageRouteControlClasses = {
  tabBox: "tab-box",
  content: "content",
};

export const FlatPageRouteControl = ({
  sx,
  tabs,
  defaultPath,
}: FlatPageRouteControlProps) => (
  <StyledRoot sx={sx}>
    <StyledTabBox className={flatPageRouteControlClasses.tabBox}>
      {tabs.map((i, idx) => (
        <StyledTabButton
          className={i.className}
          key={idx}
          LinkComponent={LinkBehavior}
          {...{ href: i.url }}
        >
          {i.label}
          {i.pendoClassName && (
            <CarTooltipBox
              sx={{ position: "absolute", right: -4, top: 11 }}
              className={i.pendoClassName}
            />
          )}
        </StyledTabButton>
      ))}
    </StyledTabBox>
    <StyledTabContent className={flatPageRouteControlClasses.content}>
      <Routes>
        {tabs.map((i, idx) => (
          <Route
            key={i.path ?? i.url}
            path={i.path ?? i.url}
            element={i.content}
          />
        ))}
        <Route
          path="*"
          element={<Navigate to={defaultPath ?? tabs[0]?.url} replace />}
        />
      </Routes>
    </StyledTabContent>
  </StyledRoot>
);
