import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/hooks";
import { useConfirm } from "app/useConfirm";
import { CarGoal } from "types";
import { formatCurrency, isDefined } from "utils";

import { useGoal } from "features/goals/useGoal";
import { useHealthcare } from "features/healthcare/useHealthcare";
import {
  PlanResultsGoal,
  UsePlanResults,
} from "features/planResults/usePlanResults";
import { CpGoal, controlPanelActions } from "app/controlPanelSlice";
import { RootPageBox } from "components/RootPage";
import { Box, BoxProps, IconButton, Typography, styled } from "@mui/material";
import { GoalStatusEnum } from "api/carApi.generated";
import { CarStatusFail, CarStatusPartial, CarStatusSuccess } from "icons";
import { CarCurrencyCalcField, CarCurrencyField } from "components/NumberField";
import { CarTooltip } from "components/Tooltip";
import {
  DragDropContext,
  Draggable,
  DraggableChildrenFn,
  DraggableProvidedDragHandleProps,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { DragIndicator } from "@mui/icons-material";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

interface PlanResultsGoalsProps {
  data: UsePlanResults;
  sx?: BoxProps["sx"];
}

const StyledRowRoot = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  display: "flex",
  alignItems: "center",
  justifyContent: "stretch",
  ".drag-indicator": {
    display: "flex",
    alignItems: "center",
    width: 20,
    color: theme.palette.gray6,
    svg: {
      marginLeft: -6,
      fontSize: "2.3em",
    },
  },
  ".controls": {
    width: 65,
    display: "flex",
    alignItems: "center",
  },
  ".content": {
    flex: "auto",
    display: "grid",
    gridTemplateColumns: "1fr 1px 1fr 1px 1fr",
    backgroundColor: theme.palette.gray1,
    alignItems: "center",
    border: "solid 1px",
    borderColor: theme.palette.gray6,
    borderRadius: "5px",
    height: 60,
  },
  ".divider": {
    alignSelf: "stretch",
    borderRight: "solid 1px",
    borderRightColor: theme.palette.gray6,
  },
}));

const StyledCurrencyField = styled(CarCurrencyField)(({ theme }) => ({
  width: 110,
  margin: "auto",
  ".MuiInputBase-root": {
    backgroundColor: theme.palette.white,
  },
}));

const getStatusIcon = (status?: GoalStatusEnum) => {
  switch (status) {
    case "success":
      return <CarStatusSuccess sx={{ width: "1.2em", height: "1.2em" }} />;
    case "partial":
      return <CarStatusPartial sx={{ width: "1.2em", height: "1.2em" }} />;
    case "failure":
      return <CarStatusFail sx={{ width: "1.2em", height: "1.2em" }} />;
    default:
      return null;
  }
};

const getGoalStatus = (goal?: PlanResultsGoal) => (
  <CarTooltip
    title={
      <>
        <Typography variant="par02SemiBold" sx={{ mb: 0.5 }}>
          Goal funded by:
        </Typography>
        {goal?.fundedByAssets.map((i, idx) => (
          <Typography variant="par01Regular" key={idx}>
            {`${i.label}: ${formatCurrency(i.amount)}`}
          </Typography>
        ))}
        {goal?.hintValue ? (
          <Typography variant="mediumItalic" sx={{ mt: 2 }}>
            {`We would suggest reducing this goal to ${formatCurrency(
              goal?.hintValue ?? 0,
            )}.`}
          </Typography>
        ) : undefined}
      </>
    }
    placement="top"
  >
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      {getStatusIcon(goal?.status)}
    </Box>
  </CarTooltip>
);

interface HealthcareRowProps {
  goal: PlanResultsGoal;
}

const HealthcareRow = (props: HealthcareRowProps) => (
  <StyledRowRoot>
    <Box className="controls" />
    <Box className="content">
      <Typography variant="h6SSemiBold" sx={{ px: 3 }}>
        {props.goal.nameWithPriority}
      </Typography>
      <Box className="divider" />
      {getGoalStatus(props.goal)}
      <Box className="divider" />
      <CarCurrencyCalcField value={props.goal.annualAmountWithoutInflation} />
    </Box>
  </StyledRowRoot>
);

interface GoalRowProps {
  goal?: PlanResultsGoal;
  dragHandleProps?: DraggableProvidedDragHandleProps | null;
  cpGoal: CpGoal;
  onChange?: (value: CpGoal) => void;
  onDelete?: (value: CpGoal) => void;
}

const GoalRow = (props: GoalRowProps) => (
  <StyledRowRoot>
    <Box className="controls">
      {props.onDelete && (
        <IconButton
          color="primary"
          onClick={() => props.onDelete?.(props.cpGoal)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.2 13.75L8.95 10L5.2 6.25L6.25 5.2L10 8.95L13.75 5.2L14.8 6.25L11.05 10L14.8 13.75L13.75 14.8L10 11.05L6.25 14.8L5.2 13.75ZM-5.16587e-07 10C-4.56848e-07 11.3667 0.262501 12.6583 0.787501 13.875C1.3125 15.0917 2.02917 16.1542 2.9375 17.0625C3.84583 17.9708 4.90833 18.6875 6.125 19.2125C7.34167 19.7375 8.63333 20 10 20C11.3833 20 12.6833 19.7375 13.9 19.2125C15.1167 18.6875 16.175 17.9708 17.075 17.0625C17.975 16.1542 18.6875 15.0917 19.2125 13.875C19.7375 12.6583 20 11.3667 20 10C20 8.61667 19.7375 7.31667 19.2125 6.1C18.6875 4.88333 17.975 3.825 17.075 2.925C16.175 2.025 15.1167 1.3125 13.9 0.787501C12.6833 0.262501 11.3833 2.97174e-07 10 3.57641e-07C8.63333 4.1738e-07 7.34167 0.262501 6.125 0.787501C4.90833 1.3125 3.84583 2.025 2.9375 2.925C2.02917 3.825 1.3125 4.88333 0.7875 6.1C0.2625 7.31667 -5.77054e-07 8.61667 -5.16587e-07 10ZM1.5 10C1.5 7.63333 2.32917 5.625 3.9875 3.975C5.64583 2.325 7.65 1.5 10 1.5C12.3667 1.5 14.375 2.325 16.025 3.975C17.675 5.625 18.5 7.63333 18.5 10C18.5 12.35 17.675 14.3542 16.025 16.0125C14.375 17.6708 12.3667 18.5 10 18.5C7.65 18.5 5.64583 17.6708 3.9875 16.0125C2.32917 14.3542 1.5 12.35 1.5 10Z"
              fill="currentColor"
            />
          </svg>
        </IconButton>
      )}
      {props.dragHandleProps && (
        <Box className="drag-indicator" {...props.dragHandleProps}>
          <DragIndicator />
        </Box>
      )}
    </Box>
    <Box className="content">
      <Typography variant="h6SSemiBold" sx={{ px: 3 }}>
        {props.goal?.nameWithPriority}
      </Typography>
      <Box className="divider" />
      {getGoalStatus(props.goal)}
      <Box className="divider" />
      {props.onChange ? (
        <StyledCurrencyField
          sx={{ backgroundColor: "white" }}
          value={
            props.cpGoal.amount ?? props.goal?.annualAmountWithoutInflation
          }
          onChange={(amount) =>
            props.onChange?.({ ...props.cpGoal, amount: amount || undefined })
          }
        />
      ) : (
        <CarCurrencyCalcField
          value={
            props.cpGoal.amount ?? props.goal?.annualAmountWithoutInflation
          }
        />
      )}
    </Box>
  </StyledRowRoot>
);

export const PlanResultsGoals = ({ data, sx }: PlanResultsGoalsProps) => {
  const dispatch = useDispatch();
  const { refetch } = data;
  const cpGoals = useAppSelector((state) => state.controlPanel.goals);
  const goals = useGoal();
  const healthcare = useHealthcare();
  const { handleDelete: handleGoalDelete } = goals;

  const confirm = useConfirm();

  const getCpItem = (item: CarGoal) => {
    return cpGoals.find((v) => v.id === item.id) ?? { id: item.id };
  };

  const getGoalResult = (id?: string) => {
    return data.goalResults.find((v) => v.goalId === id);
  };

  const handleChange = useCallback(
    (item: CpGoal) => {
      dispatch(controlPanelActions.setGoal(item));
    },
    [dispatch],
  );

  const handleDelete = useCallback(
    (item: CpGoal) => {
      (async () => {
        if (
          !data.isPlanPublished &&
          (await confirm({
            label: "Delete Goal",
            message:
              "Are you sure you want to delete this goal from your plan?",
            applyLabel: "Delete",
          }))
        ) {
          dispatch(controlPanelActions.removeGoal(item.id));
          await handleGoalDelete(item);
          refetch();
        }
      })();
    },
    [dispatch, confirm, handleGoalDelete, refetch, data.isPlanPublished],
  );

  const sortedItems = goals.items
    .map((item) => ({
      item,
      cpItem: getCpItem(item),
      goalResult: getGoalResult(item.id),
    }))
    .sort(
      (a, b) =>
        (a.cpItem.position ?? a.item.position ?? 0) -
        (b.cpItem.position ?? b.item.position ?? 0),
    );

  const healthcareItems = healthcare.items
    .map((i) => getGoalResult(i.id))
    .filter(isDefined);

  const renderChild: DraggableChildrenFn = (provided, snapshot, rubric) => {
    const index = rubric.source.index;
    const item = sortedItems[index];

    return (
      <Box
        {...provided.draggableProps}
        ref={provided.innerRef}
        style={{
          ...provided.draggableProps.style,
        }}
      >
        <GoalRow
          key={item.goalResult?.goalId}
          dragHandleProps={
            data.isPlanPublished ? undefined : provided.dragHandleProps
          }
          goal={item.goalResult}
          cpGoal={item.cpItem}
          onChange={data.isPlanPublished ? undefined : handleChange}
          onDelete={data.isPlanPublished ? undefined : handleDelete}
        />
      </Box>
    );
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const cpItems = sortedItems.map((i) => i.cpItem);
    const [removed] = cpItems.splice(result.source.index, 1);
    cpItems.splice(result.destination.index, 0, removed);
    cpItems.forEach((i, idx) => {
      if (i.position !== idx) {
        handleChange({ ...i, position: idx });
      }
    });
  };

  return (
    <RootPageBox
      sx={{
        p: 5,
        pl: 3,
        ...sx,
      }}
    >
      <Box sx={{ display: "flex", gap: 0.5 }}>
        <Typography variant="h4SSemiBold" sx={{ ml: 2 }}>
          Goals
        </Typography>
        <CarTooltipBox
          sx={{ mt: 0.6 }}
          className={pendoClasses.workbenchPlanResultsGoalsGoals}
        />
      </Box>
      <Box
        sx={{
          marginLeft: "65px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          borderBottom: "solid 1px",
          borderBottomColor: "gray3",
          mt: 5,
          pb: 1,
          mb: 0.5,
        }}
      >
        <Typography variant="caption" sx={{ ml: 3 }}>
          Description
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 0.25,
          }}
        >
          <Typography variant="caption" sx={{ textAlign: "center" }}>
            Pass/Partial/Fail
          </Typography>
          <CarTooltipBox
            className={pendoClasses.workbenchPlanResultsGoalsPassPartialFail}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 0.25,
          }}
        >
          <Typography variant="caption" sx={{ textAlign: "center" }}>
            Annual Amount
          </Typography>
          <CarTooltipBox
            className={pendoClasses.workbenchPlanResultsGoalsAnnualAmount}
          />
        </Box>
      </Box>
      {healthcareItems.map((i) => (
        <HealthcareRow goal={i} key={i.goalId} />
      ))}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="list" renderClone={renderChild}>
          {(provided) => (
            <Box
              sx={{ display: "flex", flexDirection: "column" }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {sortedItems.map((i, idx) => (
                <Draggable
                  key={i.item.id ?? ""}
                  draggableId={i.item.id ?? ""}
                  index={idx}
                  children={renderChild}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </RootPageBox>
  );
};
