import { AccountCircle, Close, Lock, Warning } from "@mui/icons-material";
import {
  Dialog,
  Divider,
  IconButton,
  InputAdornment,
  styled,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useQuestionnaireExternalBlackDiamondConnectionCreateMutation } from "api/carApi.generated";
import { CarLoadingButton } from "components/Buttons";
import { CarCheckbox } from "components/Checkbox";
import { CarTextField } from "components/Inputs";
export interface BlackDiamondConnectDialogProps {
  onApply: (value: boolean) => void;
  onCancel: () => void;
}

const DialogField = styled(CarTextField)({
  "& .MuiOutlinedInput-root": {
    fontSize: 16,
    backgroundColor: "#F0F3F6",
    "& input": {
      fontSize: 16,
      fontColor: "black",
      color: "#555555",
      paddingTop: 9,
      paddingBottom: 9,
    },
    "& fieldset": {
      borderRadius: "3px",
      fontSize: 16,
    },
  },
});

export const BlackDiamondConnectDialog = (
  props: BlackDiamondConnectDialogProps
) => {
  const [username, setUsername] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();
  const [isSandbox, setIsSandbox] = useState(false);
  const [isError, setIsError] = useState(false);

  const [connectBlackDiamond, { isLoading }] =
    useQuestionnaireExternalBlackDiamondConnectionCreateMutation();

  const handleConnect = async () => {
    if (isLoading) {
      return;
    }

    if (!username || !password) {
      return;
    }

    const response = await connectBlackDiamond({
      blackDiamondIntegration: {
        username,
        password,
        black_diamond_sandbox_mode: isSandbox,
      },
    });

    if ("data" in response) {
      props.onApply(true);
    } else {
      setIsError(true);
    }
  };

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "6px",
          p: 5,
          position: "relative",
          width: 392,
        },
      }}
      maxWidth="lg"
      open={true}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          props.onCancel();
        }
      }}
    >
      <IconButton
        sx={{ position: "absolute", right: 10, top: 10, color: "gray6" }}
        onClick={props.onCancel}
      >
        <Close />
      </IconButton>
      <Typography variant="h4SBold">Connect to Black Diamond</Typography>
      <Divider sx={{ mt: 0.5, mb: 1.5, borderColor: "gray6" }} />
      <Typography variant="par02Regular">
        Enter your username and password to connect Black Diamond to CMPAS.
      </Typography>

      {isError && (
        <Box sx={{ display: "flex", flexDirection: "column", mt: 3 }}>
          <Typography
            sx={{
              fontSize: "11px",
              fontWeight: 700,
              lineHeight: "12.65px",
              color: "error.main",
            }}
          >
            <Warning color="error" fontSize="inherit" sx={{ mr: 0.6 }} />
            Could not connect to Black Diamond.
          </Typography>
          <Typography
            sx={{
              fontSize: "11px",
              fontWeight: 700,
              lineHeight: "12.65px",
              color: "error.main",
            }}
          >
            Please check that username and password are correct.
          </Typography>
        </Box>
      )}
      <DialogField
        sx={{ mt: 2 }}
        placeholder="Username"
        value={username}
        onChange={setUsername}
        autoFocus
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ mr: 0 }}>
              <AccountCircle />
            </InputAdornment>
          ),
        }}
      />
      <DialogField
        sx={{ mt: 3 }}
        placeholder="Password"
        value={password}
        onChange={setPassword}
        inputProps={{ type: "password" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ mr: 0 }}>
              <Lock />
            </InputAdornment>
          ),
        }}
      />
      <CarCheckbox
        sx={{ mt: 1, ml: 1.4 }}
        label="Use black diamond sandbox"
        checked={isSandbox}
        onChange={(e, checked) => setIsSandbox(checked)}
      />
      <CarLoadingButton
        sx={{ mt: 3, alignSelf: "center", fontSize: "18px" }}
        onClick={handleConnect}
        isLoading={isLoading}
      >
        Connect
      </CarLoadingButton>
    </Dialog>
  );
};
