import { Box, BoxProps, Typography, styled, useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import { formatDisplayTypeValue, isDefined } from "utils";
import { chartsColor } from "theme";
import { memo, useCallback, useRef } from "react";
import {
  ChartTooltip,
  defaultChartTooltipData,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { ChartPlugins } from "chartUtils";
import { useChartHighlight } from "app/useChartHighlight";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { ChartDataset } from "chart.js";
import { CarChartLegend } from "components/ChartLegend";
import { CarChipGroup } from "components/Chip";

const chartHeight = 460;

export interface YieldCurvePosition {
  id: string;
  label: string;
  position: number;
}

export interface YieldCurveRate {
  position: YieldCurvePosition;
  rate: number;
}

export interface YieldCurveItem {
  id: string;
  label: string;
  rates: YieldCurveRate[];
}

export const getYieldCurveChartData = (
  data: YieldCurveItem[],
  hiddenDatasetIds: string[],
) => {
  const positions: YieldCurvePosition[] = [];
  data
    .filter((i) => !hiddenDatasetIds.includes(i.id))
    .forEach(
      (c) =>
        c?.rates.forEach((r) => {
          if (!positions.some((i) => i.id === r.position.id)) {
            positions.push(r.position);
          }
        }),
    );

  positions.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));

  const labels = positions.map((i) => i.label ?? "");
  const datasets =
    data.map<ChartDataset<"line", (number | null)[]> & { color: string }>(
      (row, rowIdx) => ({
        id: row.id,
        label: row.label,
        data: positions.map((p) => {
          const rate = row.rates.find((r) => r.position.id === p.id);
          return rate?.rate ?? null;
        }),
        color: Object.values(chartsColor)[rowIdx] ?? "#A0A0A0",
        hidden: hiddenDatasetIds.includes(row.id),
      }),
    ) ?? [];

  return {
    datasets,
    labels,
    xLabel: "Years",
    yLabel: "Current Yield",
  };
};
export interface SimulationYieldsChartProps {
  sx?: BoxProps["sx"];
  data: YieldCurveItem[];
  isWhiteContext?: boolean;
  hiddenDatasetIds: string[];
  setHiddenDatasetIds: (value: string[]) => void;
  datasetsLabel: string;
}

const StyledLine = styled(Line)(({ theme }) => ({
  width: "100%",
  height: chartHeight,
  maxHeight: chartHeight,
})) as typeof Line;

type ChartRef = ChartJSOrUndefined<"line", (number | null)[], unknown>;

export const SimulationYieldsChart2d = memo(
  (props: SimulationYieldsChartProps) => {
    const chartHighlight = useChartHighlight();

    const { datasets, yLabel, labels } = getYieldCurveChartData(
      props.data,
      props.hiddenDatasetIds,
    );

    const { tooltipPlugin, tooltipData, setTooltipData } = useChartTooltip({
      chartHighlight,
    });
    const chartRef = useRef<ChartRef | null>();

    const chartRefCallback = useCallback(
      (value: ChartRef | null) => {
        chartHighlight.unsubscribe(chartRef.current);
        chartRef.current = value;

        chartHighlight.subscribe(chartRef.current, (hlValue) => {
          console.log({ hlValue });
          if (
            !isDefined(hlValue.datasetIndex) ||
            !isDefined(hlValue.dataIndex)
          ) {
            setTooltipData(defaultChartTooltipData);
            return;
          }

          if (chartRef.current) {
            const { width, height } = chartRef.current.canvas;
            const data = chartRef.current.data.datasets.at(hlValue.datasetIndex)
              ?.data;

            const value = data?.at(hlValue.dataIndex);

            if (value) {
              const x = 0; // todo // chartRef.current.scales.x.getPixelForValue(value.x);
              const y = 0; // todo // chartRef.current.scales.y.getPixelForValue(value.y);
              setTooltipData({
                opacity: 1,
                x,
                y,
                canvasSize: { width, height },
                datasetIndex: hlValue.datasetIndex,
                dataIndex: hlValue.dataIndex,
              });
            }
          }

          chartRef.current?.update();
        });
      },
      [chartHighlight, setTooltipData],
    );

    const theme = useTheme();

    const yFormatter = formatDisplayTypeValue("PERCENTAGE");

    const formatYValue = (value: number | string) =>
      typeof value === "number" ? yFormatter(value) : "";

    const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
      const ds = datasets[datasetIndex];
      if (!ds) {
        return <></>;
      }

      const xLabel = labels.at(dataIndex) ?? "";
      const yValue = ds.data.at(dataIndex) ?? 0;
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
            <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
              {ds.label}
            </Typography>
          </Box>
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {xLabel}
          </Typography>
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {`${yLabel}: ${formatYValue(yValue)}`}
          </Typography>
        </Box>
      );
    };

    const finalDatasets = datasets.map<
      ChartDataset<"line", (number | null)[]> & { color: string }
    >((ds) => ({
      id: ds.id,
      label: ds.label,
      data: ds.data,
      borderColor: ds.color,
      backgroundColor: ds.color,
      pointBackgroundColor: ds.color,
      pointBorderColor: ds.color,
      borderWidth: 2,
      pointRadius: 3,
      color: ds.color,
      tension: 0.4,
      hidden: props.hiddenDatasetIds.includes(ds.id ?? ""),
    }));

    return (
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box
          sx={{
            mt: 1.5,

            width: 220,
            display: "flex",
            flexDirection: "column",
            backgroundColor: props.isWhiteContext ? "gray1" : "white",
            border: "1px solid",
            borderColor: "gray3",
            borderRadius: "5px",
            px: 3,
            py: 3,
            gap: 1,
          }}
        >
          <CarChipGroup
            label="Curves"
            direction="column"
            items={datasets.map((ds) => ({
              label: ds.label ?? "",
              value: ds.id ?? "",
              isChecked: !props.hiddenDatasetIds.includes(ds.id ?? ""),
            }))}
            onClick={(value) => {
              if (props.hiddenDatasetIds.includes(value)) {
                props.setHiddenDatasetIds(
                  props.hiddenDatasetIds.filter((i) => i !== value),
                );
              } else {
                props.setHiddenDatasetIds([...props.hiddenDatasetIds, value]);
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <ChartTooltip
            tooltipData={tooltipData}
            renderTooltip={renderTooltip}
            sx={{
              ...props.sx,
            }}
          >
            <StyledLine
              ref={chartRefCallback}
              datasetIdKey="id"
              data={{
                datasets: finalDatasets,
                labels,
              }}
              options={{
                // animation: true,
                maintainAspectRatio: false,
                scales: {
                  x: {
                    type: "category",
                    ticks: {
                      font: {
                        family: theme.typography.fontFamily,
                        size: 15,
                        weight: "400",
                      },
                    },
                    border: { display: false },
                    grid: {
                      color: (ctx) => {
                        const { min, max } = chartRef.current?.scales["x"] ?? {
                          min: 0,
                          max: 0,
                        };

                        return ctx.tick.value <= min || ctx.tick.value >= max
                          ? "transparent"
                          : theme.palette.gray2;
                      },
                      lineWidth: 2,
                      tickColor: theme.palette.white,
                    },
                  },
                  y: {
                    type: "linear",
                    ticks: {
                      font: {
                        family: theme.typography.fontFamily,
                        size: 15,
                        weight: "400",
                      },
                      callback: formatYValue,
                    },
                    border: { display: false },
                    grid: {
                      color: (ctx) => {
                        const { min, max } = chartRef.current?.scales["y"] ?? {
                          min: 0,
                          max: 0,
                        };

                        return ctx.tick.value <= min || ctx.tick.value >= max
                          ? "transparent"
                          : theme.palette.gray2;
                      },
                      lineWidth: 2,
                      tickColor: theme.palette.white,
                    },
                    title: {
                      display: true,
                      text: yLabel,
                      color: theme.palette.softBlack,
                      font: {
                        family: theme.typography.fontFamily,
                        size: 15,
                        weight: "600",
                        lineHeight: 1,
                      },
                      padding: {
                        bottom: 20,
                      },
                    },
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: tooltipPlugin,
                  roundedBackground: {
                    contextColor: props.isWhiteContext
                      ? theme.palette.white
                      : theme.palette.gray1,
                    backgroundColor: props.isWhiteContext
                      ? theme.palette.gray1
                      : theme.palette.white,
                    borderColor: theme.palette.gray3,
                    borderRadius: 5,
                  },
                },
              }}
              plugins={[ChartPlugins.roundedBackground]}
            />
          </ChartTooltip>
          <CarChartLegend
            sx={{ mt: 1, ml: 9 }}
            labelVariant="caption"
            items={finalDatasets.map((ds, idx) => ({
              label: ds.label ?? "",
              color: ds.color,
              datasetIndex: idx,
              hidden: ds.hidden,
            }))}
            chartHighlight={chartHighlight}
            disableHover
          />
        </Box>
      </Box>
    );
  },
);
