import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useSimulationSimulationJobGetFiftyPercentileListQuery } from "api/carApi.generated";
import { BaseDialog } from "components/BaseDialog";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { Fragment } from "react";
import { formatPercentFactor, isOddEven } from "utils";
import { CarPortfolioAssetTableData } from "./usePortfolioGroupVersionData";

interface ExpectedReturnItem {
  id: string;
  assetClassName: string;
  cumulativeXYearReturn: number;
  weight: number;
  weightedReturn: number;
}

interface PortfolioGroupFiftyPercentileDialogProps {
  dataId: string;
  moduleId: string;
  tableData: CarPortfolioAssetTableData;
  simulationJobId: string;
  horizonYear: number;

  onApply: () => void;
  onCancel: () => void;
}

export const PortfolioGroupFiftyPercentileDialog = (
  props: PortfolioGroupFiftyPercentileDialogProps,
) => {
  const percentiles = useSimulationSimulationJobGetFiftyPercentileListQuery({
    id: props.simulationJobId,
  });

  const items: ExpectedReturnItem[] = props.tableData.assetRows.level4
    .map((i) => {
      const cumulativeXYearReturn =
        percentiles.data?.find(
          (p) => p.asset_class4 === i.id && p.year === props.horizonYear,
        )?.sim_return_nominal ?? 0;
      const weight =
        i.modules
          .find((md) => md.moduleId === props.moduleId)
          ?.values.find((v) => v.dataId === props.dataId)?.allocation ?? 0;
      return {
        id: i.id,
        assetClassName: i.name,
        cumulativeXYearReturn,
        weight,
        weightedReturn: cumulativeXYearReturn * weight,
      };
    })
    .filter((i) => i.weight > 0);

  const band = props.tableData.bands.find((i) => i.moduleId === props.moduleId);
  const column = band?.columns.find((i) => i.dataId === props.dataId);

  return (
    <BaseDialog
      sx={{ width: "1200px", maxWidth: "100vw" }}
      onClose={props.onCancel}
    >
      <TablePlaceHolder isLoading={percentiles.isLoading} rows={8}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <Typography variant="h2SSemiBold">{`Simulation: Expected Return of ${column?.label}`}</Typography>
          <Typography variant="par02SemiBold" sx={{ mt: 5, fontWeight: 700 }}>
            {band?.label}
          </Typography>
          <CarInfoTable
            sx={{ mt: 1, gridTemplateColumns: "1.5fr 1fr 1fr 1fr" }}
          >
            <CarInfoHeader text="" />
            <CarInfoHeader text="Cumulative X Year Return" />
            <CarInfoHeader text="Weight" />
            <CarInfoHeader text="Weighted Return" />
            {items.map((i, idx) => (
              <Fragment key={i.id}>
                <CarInfoCell
                  text={i.assetClassName}
                  justifyContent="start"
                  isOdd={isOddEven(idx)}
                />
                <CarInfoCell
                  text={formatPercentFactor(i.cumulativeXYearReturn, {
                    decimalPlaces: 1,
                  })}
                  isOdd={isOddEven(idx)}
                />
                <CarInfoCell
                  text={formatPercentFactor(i.weight, {
                    decimalPlaces: 1,
                  })}
                  isOdd={isOddEven(idx)}
                />
                <CarInfoCell
                  text={formatPercentFactor(i.weightedReturn, {
                    decimalPlaces: 1,
                  })}
                  isOdd={isOddEven(idx)}
                />
              </Fragment>
            ))}
          </CarInfoTable>
        </Box>
      </TablePlaceHolder>
    </BaseDialog>
  );
};
