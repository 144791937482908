import { usePortfolioPortfolioGroupVersionOptimizerJobsListQuery } from "api/carApi.generated";
import { useMemo } from "react";

export interface OptimizationSubJob {
  id: string;
  moduleId: string;
  moduleTitle: string;
  isCompleted: boolean;
  isError: boolean;
  isRunning: boolean;
}

export interface OptimizationJobRoot {
  id: string;
  title: string;
  createdOn: string;
  isAutoRun: boolean;
  // isCompleted: boolean;
  isRunning: boolean;
  hasErrors: boolean;
  allErrors: boolean;

  jobs: OptimizationSubJob[];
}

export const useOptimizationJobs = ({
  portfolioGroupVersionId,
  skip,
}: {
  portfolioGroupVersionId?: string;
  skip?: boolean;
}) => {
  const { refetch, isFetching, isLoading, data } =
    usePortfolioPortfolioGroupVersionOptimizerJobsListQuery(
      {
        portfolioGroupVersionId: portfolioGroupVersionId ?? "",
      },
      { skip: !portfolioGroupVersionId || skip },
    );

  return useMemo(() => {
    const items = Array.from(data ?? [])
      .sort((a, b) => b.created_at?.localeCompare(a.created_at ?? "") ?? 0)
      .map<OptimizationJobRoot>((i) => {
        const subJobs =
          i.jobs?.map<OptimizationSubJob>((j) => ({
            id: j.id ?? "",
            moduleId: j.module ?? "",
            moduleTitle: j.module_title ?? "",
            isCompleted: (j.status as unknown) === "C",
            isError: (j.status as unknown) === "E",
            isRunning: !(
              (j.status as unknown) === "C" || (j.status as unknown) === "E"
            ),
          })) ?? [];

        return {
          id: i.id ?? "",
          title: i.title ?? "",
          createdOn: i.created_at ?? "",
          isAutoRun: !!i.was_auto_run,
          jobs: subJobs,
          // isCompleted: subJobs.every((i) => i.isCompleted),
          hasErrors: subJobs.some((i) => i.isError),
          allErrors: subJobs.length > 0 && subJobs.every((i) => i.isError),
          isRunning: subJobs.some((i) => i.isRunning),
        };
      });

    return {
      isLoading,
      isFetching,
      refetch,
      items,
      hasRunningJobs: items.some((j) => j.isRunning),
    };
  }, [isLoading, data, refetch, isFetching]);
};

// export type PortfolioGroupVersionOptimizationSubJobStatusEnum =
//   | "PENDING"
//   | "RUNNING"
//   | "QUEUED_SAVE"
//   | "COMPLETE"
//   | "ERROR";
