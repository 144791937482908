import { MenuItem } from "@mui/material";
import { CarSelectField, CarSelectFieldProps } from "./Inputs";
import { useTiers } from "app/useTiers";

export interface CarTierFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value?: string | null;
  onChange: (value?: string | null) => void;
}

export const CarTierField = ({
  value,
  onChange,
  ...props
}: CarTierFieldProps) => {
  const { items } = useTiers();

  return (
    <CarSelectField {...props} value={value} onChange={onChange}>
      {items.map((i) => (
        <MenuItem key={i.id} value={i.id}>
          {i.name}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};
