import { Box, BoxProps, IconButton, Typography, useTheme } from "@mui/material";
import { ComponentProps, useEffect, useMemo, useRef } from "react";
import { Chart } from "react-chartjs-2";
import { formatPercent } from "utils";
import {
  ChartActiveElement,
  ChartTooltip,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { ChartDataset, ChartTypeRegistry } from "chart.js";
import { CmaModelChartData } from "api/carApi.generated";
import { createPendoClassName, pendoClasses } from "app/thirdParty/pendo";
import { ChartPlugins } from "chartUtils";
import { CarIconZoomIn, CarIconZoomOut } from "icons";
import { CarChartLegend } from "components/ChartLegend";
import { CarTooltipBox } from "components/TooltipBox";

type Options = ComponentProps<typeof Chart>["options"];

export interface DftVersusTenYearReturnDataPoint {
  x: number;
  y: number;
}

interface DftVersusTenYearReturnData {
  xLabel: string;
  yLabel: string;
  upperBondLine: ChartDataset<"line", DftVersusTenYearReturnDataPoint[]>;
  lowerBondLine: ChartDataset<"line", DftVersusTenYearReturnDataPoint[]>;
  expectedReturnTrendLine: ChartDataset<
    "line",
    DftVersusTenYearReturnDataPoint[]
  >;
  realReturnScatter: ChartDataset<"scatter", DftVersusTenYearReturnDataPoint[]>;
  range: {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;
  };
}

export const getDftVersusTenYearReturnData = ({
  data,
  assetClassName,
  upperBondColor,
  lowerBondColor,
  expectedReturnColor,
  realReturnColor,
}: {
  data: CmaModelChartData;
  assetClassName: string;
  upperBondColor: string;
  lowerBondColor: string;
  expectedReturnColor: string;
  realReturnColor: string;
}): DftVersusTenYearReturnData => {
  const getMinMax = (data: { x: number; y: number }[]) => {
    const sortedData = Array.from(data).sort((a, b) => a.x - b.x);
    return sortedData.splice(1, sortedData.length - 2);
  };
  const result: DftVersusTenYearReturnData = {
    upperBondLine: {
      type: "line",
      label: "Upper Bound",
      data: getMinMax(
        data.boundary_x?.map((i, idx) => ({
          x: i,
          y: data.boundary_y_upper?.[idx] ?? 0,
        })) ?? [],
      ),
      borderColor: upperBondColor,
      pointBorderColor: upperBondColor,
      pointBackgroundColor: upperBondColor,
      pointBorderWidth: 1,
      pointRadius: 0,
      borderWidth: 1,
    },
    lowerBondLine: {
      type: "line",
      label: "Lower Bound",
      data: getMinMax(
        data.boundary_x?.map((i, idx) => ({
          x: i,
          y: data.boundary_y_lower?.[idx] ?? 0,
        })) ?? [],
      ),
      borderColor: lowerBondColor,
      pointBorderColor: lowerBondColor,
      pointBackgroundColor: lowerBondColor,
      pointBorderWidth: 1,
      pointRadius: 0,
      borderWidth: 1,
    },
    expectedReturnTrendLine: {
      type: "line",
      label: "Expected Return Trend",
      data: getMinMax(
        data.boundary_x?.map((i, idx) => ({
          x: i,
          y: data.boundary_y_expected_ret?.[idx] ?? 0,
        })) ?? [],
      ),
      borderColor: expectedReturnColor,
      pointBorderColor: expectedReturnColor,
      pointBackgroundColor: expectedReturnColor,
      pointBorderWidth: 1,
      pointRadius: 0,
      borderWidth: 1,
    },
    realReturnScatter: {
      type: "scatter",
      label: `${assetClassName} 10 Year Real Return`,
      data:
        data.boundary_scatter_x?.map((i, idx) => ({
          x: i,
          y: data.boundary_scatter_y?.[idx] ?? 0,
        })) ?? [],
      borderColor: realReturnColor,
      pointBorderColor: realReturnColor,
      pointBackgroundColor: realReturnColor,
      pointBorderWidth: 1,
      pointRadius: 3,
      borderWidth: 1,
      hoverBorderColor: "black",
      hoverBackgroundColor: "black",
    },
    xLabel: "Real 10 Year Return vs. Distance from Trend",
    yLabel: "Compound Annual Return",
    range: {
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    },
  };

  const dataSets = [
    ...result.upperBondLine.data,
    ...result.lowerBondLine.data,
    ...result.expectedReturnTrendLine.data,
    ...result.realReturnScatter.data,
  ];

  const minX = Math.min(...dataSets.map((i) => i.x));
  const maxX = Math.max(...dataSets.map((i) => i.x));
  const offsetX = ((maxX - minX) / 100) * 2; // 2%
  const minY = Math.min(...dataSets.map((i) => i.y));
  const maxY = Math.max(...dataSets.map((i) => i.y));
  const offsetY = ((maxY - minY) / 100) * 2; // 2%

  return {
    ...result,
    range: {
      minX: minX - offsetX,
      maxX: maxX + offsetX,
      minY: minY - offsetY,
      maxY: maxY + offsetY,
    },
  };
};

interface ChartDftVersusTenYearReturnProps {
  sx?: BoxProps["sx"];
  data: CmaModelChartData;
  assetClassName: string;
  pendoPrefix: string;
  activeElement: ChartActiveElement;
  isWhiteContext: boolean;
}

export const ChartDftVersusTenYearReturn = ({
  sx,
  assetClassName,
  data,
  pendoPrefix,
  activeElement,
  isWhiteContext,
}: ChartDftVersusTenYearReturnProps) => {
  const theme = useTheme();

  const chartData = useMemo(
    () =>
      getDftVersusTenYearReturnData({
        data,
        assetClassName,
        upperBondColor: theme.palette.softBlack,
        lowerBondColor: theme.palette.softBlack,
        expectedReturnColor: theme.palette.chartsColor.green,
        realReturnColor: theme.palette.chartsColor.blue,
      }),
    [theme, assetClassName, data],
  );

  const {
    upperBondLine,
    lowerBondLine,
    expectedReturnTrendLine,
    realReturnScatter,
    range,
    yLabel,
    xLabel,
  } = chartData;
  const { tooltipPlugin, tooltipData } = useChartTooltip({
    chartName: "ChartDftVersusTenYearReturn",
    activeElement,
  });

  const chartRef = useRef<ChartJSOrUndefined<
    keyof ChartTypeRegistry,
    {
      x: number;
      y: number;
    }[],
    unknown
  > | null>();

  const interactiveDatasetLength = realReturnScatter.data.length ?? 0;

  const datasets = [
    upperBondLine,
    lowerBondLine,
    expectedReturnTrendLine,
    realReturnScatter,
  ];

  const dftEnd = data.label_end_dft ?? 0;

  useEffect(() => {
    if (chartRef.current) {
      if (
        activeElement.element &&
        activeElement.element.chartName === "ChartTotalReturns" &&
        activeElement.element.datasetIndex === 1 &&
        activeElement.element.index < interactiveDatasetLength
      ) {
        chartRef.current.setActiveElements([
          { datasetIndex: 3, index: activeElement.element.index },
        ]);
        chartRef.current.tooltip?.setActiveElements(
          [{ datasetIndex: 3, index: activeElement.element.index }],
          { x: 0, y: 0 },
        );
      } else if (!activeElement.element) {
        chartRef.current.setActiveElements([]);
        chartRef.current.tooltip?.setActiveElements([], { x: 0, y: 0 });
      }
    }
  }, [activeElement, interactiveDatasetLength]);

  const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
    const ds = datasets[datasetIndex];

    const xLabel = `Distance from Trend: ${formatPercent(
      ds?.data?.[dataIndex]?.x ?? 0,
      2,
    )}`;
    const yLabel = `Real 10 Year Return: ${formatPercent(
      ds?.data?.[dataIndex]?.y ?? 0,
      2,
    )}`;
    return (
      <>
        <Box
          sx={{
            fontSize: 15,
            fontWeight: 600,
            color: "black",
            maxWidth: 300,
            gap: 0.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
            {ds?.label}
          </Typography>
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {xLabel}
          </Typography>
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {yLabel}
          </Typography>
        </Box>
      </>
    );
  };

  const options = useMemo(() => {
    const result: Options = {
      maintainAspectRatio: false,
      animation: {
        duration: 500,
      },
      scales: {
        x: {
          offset: false,
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 13,
              weight: "400",
            },
            color: theme.palette.softBlack,
            callback: (value) =>
              typeof value === "number" ? formatPercent(value, 1) : value,
          },
          grid: {
            display: false,
          },
          type: "linear",
          min: range.minX,
          max: range.maxX,
        },
        y: {
          offset: false,
          type: "linear",
          title: {
            text: yLabel,
            display: true,
            font: {
              family: theme.typography.fontFamily,
              size: 16,
              weight: "600",
            },
          },

          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 13,
              weight: "400",
            },
            color: theme.palette.softBlack,
            callback: (value) =>
              typeof value === "number" ? formatPercent(value, 1) : value,
          },
          border: { display: false },
          grid: {
            display: true,
            color: (ctx) => {
              return ctx.tick.value === 0
                ? theme.palette.gray7
                : theme.palette.gray2;
            },
            lineWidth: 1,
            tickColor: theme.palette.white,
          },
          min: range.minY,
          max: range.maxY,
        },
      },
      interaction: {
        mode: "point",
        intersect: false,
      },
      plugins: {
        title: {
          display: true,
          align: "start",
          fullSize: false,
          text: "", // xLabel,
          font: {
            family: theme.typography.fontFamily,
            size: 19,
            weight: "600",
          },
          color: theme.palette.softBlack,
          padding: {
            bottom: 6,
          },
        },
        roundedBackground: {
          contextColor: isWhiteContext
            ? theme.palette.white
            : theme.palette.gray1,
          backgroundColor: isWhiteContext
            ? theme.palette.gray1
            : theme.palette.white,

          borderColor: theme.palette.gray7,
          borderRadius: 5,
        },
        legend: {
          display: false,
        },
        rangeHighlight: {
          labelPosition: "horizontal",
          borderColor: theme.palette.gray4,
          font: {
            family: theme.typography.fontFamily,
            size: 14,
            weight: "600",
          },
          textColor: theme.palette.softBlack,
          items: [
            {
              value: dftEnd,
              maxDelta: 5,
              minDelta: -5,
              minLabel: "DFT",
              maxLabel: "DFT",
              backgroundColor: "#fdf5ee",
            },
            // {
            //   value: "tooltip",
            //   minDelta: -5,
            //   maxDelta: 5,
            //   minLabel: "-5",
            //   maxLabel: "+5",
            //   backgroundColor: "#f6f7f8",
            // },
          ],
        },
        tooltip: tooltipPlugin,
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          limits: {
            x: {
              min: "original",
              max: "original",
              // minRange: 5 * 365 * 24 * 60 * 60 * 1000, // max zoom level - 5 years
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
        },
      },
    };
    return result;
  }, [range, theme, tooltipPlugin, yLabel, isWhiteContext, dftEnd]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: 440,
        ...sx,
      }}
    >
      <ChartTooltip
        tooltipData={tooltipData}
        renderTooltip={renderTooltip}
        shiftY={0}
        /* Some bug: can't specify height on chart because it starts to grow, so put chart in the box */
        sx={{ display: "flex", height: 440, position: "relative" }}
      >
        <CarTooltipBox
          sx={{ position: "absolute", left: 5, top: 70 }}
          className={pendoClasses.assetClassAnalysisDftCompoundAnnualReturn}
        />
        <Typography
          variant="par03SemiBold"
          sx={{ position: "absolute", fontSize: "19px", top: 1, left: 80 }}
        >
          {xLabel}
          <CarTooltipBox
            sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
            className={pendoClasses.assetClassAnalysisDftRealTenYearReturn}
          />
        </Typography>

        <IconButton
          sx={{ position: "absolute", top: -20, right: 72 }}
          size="small"
          onClick={() => {
            chartRef.current?.zoom(1.5, "zoom");
          }}
        >
          <CarIconZoomIn fontSize="large" color="primary" />
        </IconButton>
        <IconButton
          sx={{ position: "absolute", top: -20, right: 26 }}
          size="small"
          onClick={() => {
            chartRef.current?.zoom(-1.5, "zoom");
          }}
        >
          <CarIconZoomOut fontSize="large" color="primary" />
        </IconButton>
        <Chart
          className={createPendoClassName(`${pendoPrefix}_chart`)}
          ref={chartRef}
          onDoubleClick={() => {
            chartRef.current && chartRef.current.resetZoom();
          }}
          type="line"
          data={{ datasets }}
          options={options}
          plugins={[
            ChartPlugins.roundedBackground,
            ChartPlugins.rangeHighlight,
          ]}
        />
      </ChartTooltip>

      <CarChartLegend
        sx={{
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
        labelVariant="caption"
        items={[
          {
            label: (
              <>
                Expected return trend line
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.6 }}
                  className={
                    pendoClasses.assetClassAnalysisDftExpectedReturnTrendLine
                  }
                />
              </>
            ),
            color: theme.palette.chartsColor.green,
            dataIndex: 0,
            datasetIndex: 0,
          },
          {
            label: (
              <>
                Upper & Lower Bounds
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.6 }}
                  className={
                    pendoClasses.assetClassAnalysisDftUpperAndLowerBounds
                  }
                />
              </>
            ),
            color: theme.palette.softBlack,
            dataIndex: 0,
            datasetIndex: 1,
          },
          {
            label: (
              <>
                Asset Class 10-Year Real Return
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.6 }}
                  className={
                    pendoClasses.assetClassAnalysisDftAssetClassTenYearRealReturn
                  }
                />
              </>
            ),
            color: theme.palette.chartsColor.blue,
            isCircle: true,
            dataIndex: 0,
            datasetIndex: 2,
          },
        ]}
      />
    </Box>
  );
};
