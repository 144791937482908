import { Box } from "@mui/material";
import { AddRowButton } from "components/Buttons";
import {
  CarFrequencyField,
  CarInflationField,
  CarTaxesField,
  CarTextFieldDelayed,
} from "components/Inputs";
import { CarDateExtraField } from "components/DateExtraField";
import { CarCurrencyFieldDelayed } from "components/NumberField";
import {
  CarTableMemoTable,
  CarTableRow,
  CarTableSpecificRowProps,
} from "components/Table";
import { useAdditionalIncome } from "features/additionalIncome/useAdditionalIncome";
import React from "react";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarAdditionalIncome } from "types";
import { setDisplayName } from "utils";

const AdditionalIncomeRow = React.memo(
  (props: CarTableSpecificRowProps<CarAdditionalIncome>) => (
    <CarTableRow
      {...props}
      columns={[
        {
          id: "description",
          label: "*Description",
          fraction: 2.5,
          renderCell: ({ item, onChange }) => (
            <CarTextFieldDelayed
              required
              value={item.description}
              onChange={(description) => onChange({ ...item, description })}
            />
          ),
        },
        {
          id: "start_date_caravel",
          label: "*Start Date",
          fraction: 2,
          renderCell: ({ item, onChange }) => (
            <CarDateExtraField
              required
              value={item.start_date_caravel}
              onChange={(start_date_caravel) =>
                onChange({
                  ...item,
                  start_date_caravel,
                })
              }
            />
          ),
        },
        {
          id: "end_date_caravel",
          label: "*End Date",
          fraction: 2,
          renderCell: ({ item, onChange }) => (
            <CarDateExtraField
              required
              value={item.end_date_caravel}
              onChange={(end_date_caravel) =>
                onChange({
                  ...item,
                  end_date_caravel,
                })
              }
            />
          ),
        },
        {
          id: "frequency",
          label: "*Frequency",
          fraction: 1,
          renderCell: ({ item, onChange }) => (
            <CarFrequencyField
              required
              value={item.frequency}
              onChange={(frequency) =>
                onChange({
                  ...item,
                  frequency,
                })
              }
            />
          ),
        },
        {
          id: "amount",
          label: "*Annual Amount",
          fraction: 1.2,
          renderCell: ({ item, onChange }) => (
            <CarCurrencyFieldDelayed
              required
              value={item.amount}
              onChange={(amount) => onChange({ ...item, amount })}
            />
          ),
        },
        {
          id: "inflation",
          label: "*Inflation",
          fraction: 1,
          renderCell: ({ item, onChange }) => (
            <CarInflationField
              value={item.inflation}
              onChange={(inflation) => onChange({ ...item, inflation })}
            />
          ),
        },
        {
          id: "taxes",
          label: "*Taxes",
          fraction: 1,
          renderCell: ({ item, onChange }) => (
            <CarTaxesField
              required
              value={item.taxes}
              onChange={(taxes) => onChange({ ...item, taxes })}
            />
          ),
        },
      ]}
    />
  )
);

setDisplayName({ AdditionalIncomeRow });

export const AdditionalIncomeQuick = React.memo(() => {
  const data = useAdditionalIncome();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TablePlaceHolder isLoading={data.isLoading}>
        <CarTableMemoTable
          items={data.items}
          rowComponent={AdditionalIncomeRow}
          onChange={data.handleChange}
          onDelete={data.handleDelete}
          pendoPrefix="additional_income"
        />
      </TablePlaceHolder>
      <AddRowButton
        sx={{ alignSelf: "flex-start", mt: 1.5 }}
        onClick={data.addRow}
      >
        Add Additional Income
      </AddRowButton>
    </Box>
  );
});
