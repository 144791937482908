import {
  Box,
  CheckboxProps,
  MenuItem,
  Typography,
  lighten,
  styled,
} from "@mui/material";
import { CarCheckbox } from "components/Checkbox";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useAssetClassSelection } from "../useAssetClassSelection";
import { useAssetClassGroupSelector } from "app/useAssetClassGroupSelector";
import { CarChipGroup } from "components/Chip";
import { CarSelectField } from "components/Inputs";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { useNavigate } from "react-router-dom";
import { useScrollTo } from "app/useScrollToTop";
import { editPortfolioGroupPages, editPortfolioPages } from "route.const";
import { CarButton } from "components/Buttons";

const StyledCheckbox = styled(CarCheckbox)(({ theme }) => ({
  ".MuiFormControlLabel-label": {
    fontSize: "18px",
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
  },
  ".Mui-disabled svg": {
    color: lighten(theme.palette.caravelOrangePrimary, 0.5),
  },
  svg: {
    width: "0.9em",
    height: "0.9em",
  },
}));

export const EditPortfolioGroupAssetClassSelection = () => {
  const data = useAssetClassSelection();
  const assetClassGroupSelector = useAssetClassGroupSelector();
  const pageContent = usePageContent();
  const navigate = useNavigate();
  const scrollTo = useScrollTo();

  const checkboxItems = data.items.filter((i) =>
    assetClassGroupSelector.isAssetClassInGroup(i.level4Id),
  );

  const selectAllValue = checkboxItems.every((i) => i.isActive);

  const handleSelectAllValueChange: CheckboxProps["onChange"] = (
    e,
    checked,
  ) => {
    checkboxItems.forEach((i) => {
      if (!i.isRequired && i.isActive !== checked) {
        data.handleItemChange(i.firmAssetClassId, checked);
      }
    });
  };

  const handleNext = () => {
    if (data.isSinglePortfolioMode) {
      navigate(`..\\${editPortfolioPages.createModel}`);
    } else {
      navigate(`..\\${editPortfolioGroupPages.createAllocations}`);
    }
    scrollTo("top");
  };

  return (
    <TablePlaceHolder
      sx={{ mt: 5 }}
      isLoading={
        data.isLoading ||
        assetClassGroupSelector.isLoading ||
        pageContent.isLoading
      }
      rows={8}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Asset Class Selection
      </Typography>
      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          data.isSinglePortfolioMode
            ? CarPageContentCode.PORTFOLIO_ANALYSIS_ASSET_CLASS_SELECTION
            : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_SELECT_ASSET_CLASS_OVERVIEW,
        )}
      />
      <Box sx={{ display: "flex", mt: 5, gap: 8 }}>
        <CarSelectField
          label={
            <>
              Asset Class Groupings
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={
                  pendoClasses.editPortfolioAssetClassSelectionAssetClassGroupings
                }
              />
            </>
          }
          sx={{
            width: 216,
            flex: "none",
            ".MuiInputLabel-root": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              mt: 3.5,
            },
            ".MuiInputBase-input": {
              backgroundColor: "gray1",
            },
          }}
          value={assetClassGroupSelector.value}
          onChange={assetClassGroupSelector.onChange}
        >
          {assetClassGroupSelector.items.map((i) => (
            <MenuItem key={i.code} value={i.code}>
              {i.name}
            </MenuItem>
          ))}
        </CarSelectField>
        <CarChipGroup
          label="Selected Asset Classes"
          items={data.items
            .filter((i) => i.isActive)
            .map((i) => ({
              value: i.firmAssetClassId,
              label: i.name,
              isDisabled: i.isRequired,
            }))}
          onDelete={(value) => {
            data.handleItemChange(value, false);
          }}
        />
      </Box>
      <Box
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          backgroundColor: "gray1",
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "gray3",
          p: 2.5,
          mb: 4,
          flex: "auto",
        }}
      >
        <StyledCheckbox
          label="Select All"
          checked={selectAllValue}
          disabled={data.isReadOnly}
          onChange={handleSelectAllValueChange}
        />

        <Box
          sx={{
            m: 2,
            mt: 5,
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            rowGap: 2,
            columnGap: 1,
            width: "100%",
            maxHeight: "100%",
          }}
        >
          {checkboxItems.map((i) => (
            <StyledCheckbox
              key={i.firmAssetClassId}
              label={i.name}
              checked={i.isActive ?? false}
              disabled={i.isRequired || data.isReadOnly}
              onChange={(e, checked) =>
                data.handleItemChange(i.firmAssetClassId, checked)
              }
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ mt: 5, display: "flex", gap: 2.5, justifyContent: "center" }}>
        <CarButton onClick={handleNext} sx={{ fontSize: "18px" }}>
          Next
        </CarButton>
      </Box>
    </TablePlaceHolder>
  );
};
