import { Box, BoxProps, Theme, useTheme } from "@mui/material";
import { ChartDataset } from "chart.js";
import { Fragment, useMemo } from "react";
import { formatCurrency } from "utils";
import {
  PlanResultCardChartCombo,
  PlanResultCardChartComboProps,
} from "./PlanResultCardChartCombo";
import { RenderTooltip } from "components/ChartTooltip";
import {
  BarChartYearGoal,
  UseBarChart,
} from "features/planResults/useBarChart";

interface PlanResultCardGoalTimelineProps {
  sx?: BoxProps["sx"];
  data: UseBarChart;
}

const isYearGoalFailed = (goal: BarChartYearGoal) =>
  goal.failedAmount > 0 && goal.successAmount === 0;

const isYearGoalHalfFailed = (goal: BarChartYearGoal) =>
  goal.failedAmount > 0 && goal.successAmount > 0;

export const getGoalTimelineChartData = (data: UseBarChart, theme: Theme) => {
  const failedDataSet: ChartDataset = {
    type: "sharpBar" as "bar",
    label: "Failed Goal",
    data: data.years.map((y) => y.amount.failed),
    stack: "bar-stacked",
    backgroundColor: theme.palette.chartsColor.failed,
    borderColor: theme.palette.black,
    borderWidth: 1,
  };
  const halfFailedDataSet: ChartDataset = {
    type: "sharpBar" as "bar",
    label: "Partially Funded Goal",
    data: data.years.map((y) => y.amount.halfFailed),
    backgroundColor: theme.palette.chartsColor.halfFailed,
    borderColor: theme.palette.black,
    borderWidth: 1,
    stack: "bar-stacked",
  };

  const colors = [
    theme.palette.chartsColor.blue,
    theme.palette.chartsColor.purple,
    theme.palette.chartsColor.yellow,
    theme.palette.chartsColor.aqua,
    theme.palette.chartsColor.green,
    theme.palette.chartsColor.red,
    theme.palette.chartsColor.orange,
    theme.palette.chartsColor.darkPurple,
    theme.palette.chartsColor.lightBlue,
    theme.palette.chartsColor.lightOrange,
    theme.palette.chartsColor.lightGreen,
    theme.palette.chartsColor.lightRed,
  ];

  const datasets: ChartDataset[] = data.goals.map((g, idx) => {
    return {
      type: "sharpBar" as "bar",
      label: g.label,
      data: data.years.map(
        (y) => y.goals.find((i) => i.goalId === g.goalId)?.successAmount ?? 0,
      ),
      backgroundColor: colors[idx % colors.length],
      stack: "bar-stacked",
    };
  });

  const halfFailedIndex = datasets.push(halfFailedDataSet) - 1;
  const failedIndex = datasets.push(failedDataSet) - 1;

  const labels = data.years.map((y) => y.year);

  return {
    datasets,
    halfFailedIndex,
    failedIndex,
    labels,
  };
};

export const PlanResultCardGoalTimeline = ({
  sx,
  data,
}: PlanResultCardGoalTimelineProps) => {
  const theme = useTheme();
  const chartProps: PlanResultCardChartComboProps = useMemo(() => {
    const chartData = getGoalTimelineChartData(data, theme);

    const getItems = (
      datasetIndex: number,
      yearIndex: number,
    ): BarChartYearGoal[] => {
      const year = data.years[yearIndex];

      if (datasetIndex === chartData.failedIndex) {
        return year.goals.filter(isYearGoalFailed);
      } else if (datasetIndex === chartData.halfFailedIndex) {
        return year.goals.filter(isYearGoalHalfFailed);
      } else {
        const goal = year?.goals.find(
          (i) => i.goalId === data.goals[datasetIndex]?.goalId,
        );
        return goal ? [goal] : [];
      }
    };

    const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
      const total = chartData.datasets.reduce(
        (acc, ds) => acc + ((ds.data[dataIndex] as number) ?? 0),
        0,
      );
      const items = getItems(datasetIndex, dataIndex);
      return (
        <>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: 600,
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            {`${chartData.labels[dataIndex]} - ${formatCurrency(
              total / 1000,
            )}K`}
          </Box>
          <Box
            sx={{
              fontSize: 12,
              fontWeight: 400,
              color: "black",
              display: "flex",
              flexDirection: "column",
              paddingLeft: "12px",
              "& .green": { color: "green" },
              "& .red": { color: "red" },
            }}
          >
            {items.map((item) => (
              <Box
                sx={{
                  display: "flex",
                  columnGap: "4px",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
                key={item.goalId}
              >
                <span>{item.label}</span>
                {item.successAmount > 0 && (
                  <span className="green">{`${formatCurrency(
                    item.successAmount / 1000,
                  )}K`}</span>
                )}
                {item.failedAmount > 0 && (
                  <span className="red">{`-${formatCurrency(
                    item?.failedAmount / 1000,
                  )}K`}</span>
                )}
              </Box>
            ))}
          </Box>
        </>
      );
    };

    return {
      pendoPrefix: "goal_timeline",
      datasets: chartData.datasets,
      yearLabels: chartData.labels,
      renderTooltip,
    };
  }, [data, theme]);

  return <PlanResultCardChartCombo sx={sx} {...chartProps} />;
};
