import { Box, BoxProps, Typography } from "@mui/material";
import {
  FlatPageRouteControl,
  flatPageRouteControlClasses,
  FlatPageRouteTab,
} from "components/FlatPageRouteControl";
import { useMemo } from "react";
import { SettingsBackEndRoles } from "./backEnd/SettingsBackendRoles";
import { TablePlaceHolder } from "components/PlaceHolder";
import { SettingsBackendModules } from "./backEnd/SettingsBackendModules";

interface PagesProps {
  sx?: BoxProps["sx"];
}

const Pages = (props: PagesProps) => {
  const tabs = useMemo<Array<FlatPageRouteTab>>(() => {
    return [
      {
        url: "roles",
        label: "Roles",
        content: <SettingsBackEndRoles />,
      },
      {
        url: "modules",
        label: "Modules",
        content: <SettingsBackendModules />,
      },
    ];
  }, []);
  return <FlatPageRouteControl tabs={tabs} sx={props.sx} />;
};

export const SettingsBackEnd = () => {
  return (
    <TablePlaceHolder sx={{ mx: 7, mt: 10 }} isLoading={false} rows={8}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
          Back End
        </Typography>
      </Box>
      <Pages
        sx={{
          mt: 4,
          [`.${flatPageRouteControlClasses.tabBox}`]: {
            justifyContent: "flex-start",
            gap: 2,
          },
          [`.${flatPageRouteControlClasses.content}`]: {
            backgroundColor: "white",
          },
        }}
      />
    </TablePlaceHolder>
  );
};
