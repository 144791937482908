import { formatPercent } from "utils";
import { CmaTableRow, UseCma } from "./useCma";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfHeading } from "./PdfHeading";
import { CarPdfMarkdown } from "components/PdfMarkdown";
import { CarPdfTypography } from "components/PdfTypography";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTable,
  CarPdfTableProps,
  CarPdfTextCell,
} from "components/PdfTable";

interface CmaTableProps extends CarPdfTableProps {
  rows: CmaTableRow[];
}

const CmaTable = ({ rows, ...props }: CmaTableProps) => (
  <CarPdfTable colWidths={[1.7, 1, 1]} {...props}>
    <CarPdfHeader paddingVertical={14}>
      <CarPdfHeaderCell />
      <CarPdfHeaderCell>Simulated Median 10 Year Return</CarPdfHeaderCell>
      <CarPdfHeaderCell>Simulated Volatility</CarPdfHeaderCell>
    </CarPdfHeader>
    {rows.map((i, idx) => (
      <CarPdfRow
        key={idx}
        paddingVertical={10}
        textVariant="tableBody3"
        style={{
          backgroundColor: idx % 2 === 0 ? "white" : "#f3f6f8",
        }}
      >
        <CarPdfTextCell
          style={{
            textAlign: "left",
            paddingHorizontal: 5,
          }}
        >
          {i.label}
        </CarPdfTextCell>
        <CarPdfTextCell>
          {formatPercent(i.simulatedMedianTenYearReturn * 100, 1)}
        </CarPdfTextCell>
        <CarPdfTextCell>
          {formatPercent(i.simulatedVolatility * 100, 1)}
        </CarPdfTextCell>
      </CarPdfRow>
    ))}
  </CarPdfTable>
);

export interface CapitalMarketAssumptionsPdfPageProps {
  pageProps: CarPdfPageProps;
  disclosures: UseDisclosures;
  cma: UseCma;
}

export const CapitalMarketAssumptionsPdfPage = (
  props: CapitalMarketAssumptionsPdfPageProps,
) => (
  <CarPdfPage {...props.pageProps}>
    <CarPdfHeading
      label="Capital Market Assumptions (CMAs)"
      style={{ marginBottom: 20 }}
    />
    <CarPdfMarkdown>
      {props.disclosures.getContent(
        CarDisclosureContentCode.CMPAS_CLIENT_REPORT_DISCLOSURES_part_20,
      )}
    </CarPdfMarkdown>
    <CarPdfTypography style={{ marginTop: 20, marginBottom: 10 }} variant="h6">
      Equities (Nominal Returns)
    </CarPdfTypography>
    <CmaTable rows={props.cma.equityRows} />
    <CarPdfTypography style={{ marginTop: 20, marginBottom: 10 }} variant="h6">
      Fixed Income (Nominal Returns)
    </CarPdfTypography>
    <CmaTable rows={props.cma.fixedRows} />
  </CarPdfPage>
);
