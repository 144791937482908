import { Box, BoxProps, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { CarSwitch } from "components/Switch";
import {
  selectIsInflated,
  setIsInflated,
} from "features/general/settingsSlice";
import React from "react";

export const WorkbenchInflation = React.memo(() => {
  const isInflated = useAppSelector(selectIsInflated);
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ml: "auto",
        mr: 3,
        alignSelf: "center",
      }}
    >
      <Typography
        variant="par01Regular"
        sx={{
          fontWeight: 700,
          fontSize: "20px",
          lineHeight: "24px",
          color: "white",
          textShadow: "1px 1px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        Inflation
      </Typography>
      <CarSwitch
        checked={isInflated}
        color="success"
        onChange={(_, checked) => dispatch(setIsInflated(checked))}
      />
    </Box>
  );
});

export const WorkbenchInflationSmall = (props: BoxProps) => {
  const isInflated = useAppSelector(selectIsInflated);
  const dispatch = useAppDispatch();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        alignSelf: "center",
        ...props.sx,
      }}
      {...props}
    >
      <Typography
        variant="mediumItalic"
        sx={{
          fontSize: "15px",
          lineHeight: "18px",
          mr: 1,
        }}
      >
        Inflation:
      </Typography>
      <CarSwitch
        checked={isInflated}
        color="success"
        size="small"
        onChange={(_, checked) => dispatch(setIsInflated(checked))}
      />
    </Box>
  );
};
