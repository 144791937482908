import { Box } from "@mui/material";
import { usePlanResults } from "features/planResults/usePlanResults";
import { PlanResultsCarousel } from "./PlanResultsCarousel";
import { PlanResultsInflows } from "./PlanResultsInflows";
import { TablePlaceHolder } from "components/PlaceHolder";
import { PlanResultsGoals } from "./PlanResultsGoals";
import { ControlPanelUpdate } from "./ControlPanelUpdate";
import { PlanResultsRisk } from "./PlanResultsRisk";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

export const PlanResults = () => {
  const data = usePlanResults();
  const pageContent = usePageContent();

  return (
    <TablePlaceHolder
      isLoading={pageContent.isLoading || data.isLoading}
      rows={10}
    >
      <CarPageContent
        isWhiteContext
        content={pageContent.getContent(
          CarPageContentCode.PLAN_WORKBENCH_PLAN_RESULTS_DIRECTIONS,
        )}
      />
      <Box
        sx={{
          mt: 4,
          width: 1088,
          alignSelf: "center",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <PlanResultsCarousel data={data} showTabs />
        <PlanResultsInflows data={data} sx={{ mt: 6 }} />
        <PlanResultsGoals data={data} sx={{ mt: 9 }} />
        <PlanResultsRisk data={data} sx={{ mt: 9 }} />
        <ControlPanelUpdate data={data} />
      </Box>
    </TablePlaceHolder>
  );
};
