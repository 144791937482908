import {
  usePortfolioPortfolioGroupVersionAssetClassesListQuery,
  usePortfolioPortfolioGroupVersionAssetClassesUpdateMutation,
} from "api/carApi.generated";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "./usePortfolioGroupInfo";

export const useAssetClassSelection = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;

  const versionClasses = usePortfolioPortfolioGroupVersionAssetClassesListQuery(
    {
      portfolioGroupVersionId: portfolioGroupVersionId ?? "",
    },
    {
      skip: !portfolioGroupVersionId,
    },
  );

  const [update] =
    usePortfolioPortfolioGroupVersionAssetClassesUpdateMutation();

  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.bySimulationGroupId,
    simulationGroupId: groupInfo.simulationGroupId,
    filterByUsableInPortfolios: true,
  });

  return {
    isLoading:
      groupInfo.isLoading || versionClasses.isLoading || assetClasses.isLoading,
    isSinglePortfolioMode: groupInfo.isSinglePortfolioMode,
    isReadOnly: groupInfo.isVersionReadOnly(portfolioGroupVersionId),
    title: groupInfo.title,
    items: assetClasses.items.map((i) => ({
      ...i,
      isActive: versionClasses.data?.find(
        (ac) => i.firmAssetClassId === ac.firm_asset_class_id,
      )?.is_active,
    })),
    handleItemChange: (id: string, value: boolean) => {
      const item = versionClasses.data?.find(
        (i) => i.firm_asset_class_id === id,
      );

      if (item) {
        update({
          id: item.id ?? "",
          portfolioGroupVersionId: portfolioGroupVersionId ?? "",
          portfolioAssetClassOptions: {
            ...item,
            is_active: value,
          },
        });
      }
    },
  };
};
