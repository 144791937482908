import { Box, BoxProps, Typography, styled } from "@mui/material";
import { PropsWithChildren } from "react";
import {
  CarAccordion,
  CarAccordionDetails,
  CarAccordionSummary,
} from "components/Accordion";
import { useExpandSingle } from "features/general/useExpand";
import { CarTooltipBox } from "components/TooltipBox";

interface CarAssetAllocationSectionProps {
  storageKey: string;
  label: string;
  sx?: BoxProps["sx"];
  defaultExpanded?: boolean;
  pendoClassName?: string;
}

const StyledAccordionDetails = styled(CarAccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.gray1,
  padding: theme.spacing(4),
}));

export const CarAssetAllocationSection = (
  props: PropsWithChildren<CarAssetAllocationSectionProps>,
) => {
  const expand = useExpandSingle(
    `CarAssetAllocationSection-${props.storageKey}`,
    props.defaultExpanded,
  );

  return (
    <CarAccordion
      sx={props.sx}
      {...expand}
      TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
    >
      <CarAccordionSummary>
        <Typography variant="h6SemiBold" sx={{ ml: 1 }}>
          {props.label}
        </Typography>
        {props.pendoClassName && (
          <CarTooltipBox
            sx={{ mt: 0.3, ml: 0.5 }}
            className={props.pendoClassName}
          />
        )}
      </CarAccordionSummary>
      <StyledAccordionDetails>{props.children}</StyledAccordionDetails>
    </CarAccordion>
  );
};

interface CarAssetAllocationSectionBoxProps {
  label: string;
  sx?: BoxProps["sx"];
  pendoClassName?: string;
}

export const CarAssetAllocationSectionBox = (
  props: PropsWithChildren<CarAssetAllocationSectionBoxProps>,
) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, ...props.sx }}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h5SSemiBold">{props.label}</Typography>
        {props.pendoClassName && (
          <CarTooltipBox
            sx={{ mt: 0.3, ml: 0.5 }}
            className={props.pendoClassName}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "gray1",
          border: "1px solid",
          borderColor: "gray3",
          px: 4.5,
          py: 6,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
