import { formatCurrency, formatPercent } from "utils";
import { CalAllocationItem } from "components/AllocationChart";
import { theme } from "theme";
import { CarPdfChart, CarPdfChartProps } from "components/PdfChart";
import { proximanovaCondensedFontFamily } from "pdfUtils";
import { ChartPlugins } from "chartUtils";

const legendFontSize = 12;
const xAxisFontSize = 10;
const yAxisFontSize = 10;
const barPercentage = 0.7;

export interface PdfAllocationChartProps
  extends Omit<CarPdfChartProps, "type" | "data" | "options" | "plugins"> {
  items: Array<CalAllocationItem>;
  portfolioLabel: string;
  isGray?: boolean;
}

export const CarPdfAllocationChart = ({
  items,
  portfolioLabel,
  isGray,
  ...props
}: PdfAllocationChartProps) => {
  const filteredItems = items.filter(
    (i) => i.current_percent > 0.1 || i.proposed_percent > 0.1,
  );

  const current = filteredItems.map((i) => i.current_dollars);
  const proposed = filteredItems.map((i) => i.proposed_dollars);
  const labels = filteredItems.map((i) => i.name);

  const maxFactor = 1.1;

  const minDollars = 0;
  const maxDollars = Math.max(
    items.reduce((acc, i) => (acc += i.current_dollars), 0),
    items.reduce((acc, i) => (acc += i.proposed_dollars), 0),
  );

  const minPercent = 0;
  const maxPercent = Math.max(
    items.reduce((acc, i) => (acc += i.current_percent), 0),
    items.reduce((acc, i) => (acc += i.proposed_percent), 0),
  );

  return (
    <CarPdfChart
      type="bar"
      backgroundColor={isGray ? "#fafafa" : "white"}
      options={{
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              boxHeight: 10,
              boxWidth: 10,
              padding: 20,
              color: "black",
              font: {
                family: proximanovaCondensedFontFamily,
                size: legendFontSize,
              },
            },
          },
          roundedBackground: {
            backgroundColor: isGray ? "white" : "#fafafa",
            contextColor: isGray ? "#fafafa" : "white",
            borderColor: "#b3b1b1",
            borderRadius: 0,
          },
        },
        scales: {
          x: {
            ticks: {
              color: "black",
              font: {
                size: xAxisFontSize,
              },
            },
            border: { display: false },
            grid: {
              color: "#f0eeed",
              drawTicks: false,
            },
          },
          y: {
            ticks: {
              callback: (val) =>
                typeof val === "number" && val <= maxDollars
                  ? formatCurrency(val as any)
                  : "",
              color: "black",
              font: {
                size: yAxisFontSize,
              },
            },
            border: { display: false },
            grid: {
              color: "#f0eeed",
              drawTicks: false,
            },
            min: minDollars,
            max: maxDollars * maxFactor,
          },
          y2: {
            position: "right",
            ticks: {
              callback: (val) =>
                typeof val === "number" && val <= 100
                  ? formatPercent(val as any)
                  : "",
              color: "black",
              font: {
                size: yAxisFontSize,
              },
            },
            border: { display: false },
            grid: {
              display: false,
            },
            min: minPercent,
            max: maxPercent * maxFactor,
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            label: "Existing Portfolio",
            data: current,
            backgroundColor: theme.palette.chartsColor.orange,
            barPercentage,
          },
          {
            label: portfolioLabel,
            data: proposed,
            backgroundColor: theme.palette.chartsColor.green,
            barPercentage,
          },
        ],
      }}
      plugins={[ChartPlugins.roundedBackground]}
      {...props}
    />
  );
};
