import {
  useQuestionnairePlanRealEstateCreateMutation,
  useQuestionnairePlanRealEstateDestroyMutation,
  useQuestionnairePlanRealEstateListQuery,
  useQuestionnairePlanRealEstateUpdateMutation,
} from "api/carApi.generated";
import { CarRealEstate, CarRealEstateMortgage } from "types";
import { refetchOnFocus } from "const";
import { deleteEmpty, deleteZero, setZeroIfUndefined } from "utils";
import { usePendingProxy } from "../general/usePendingProxy";
import { usePlanId } from "app/usePlanId";
import { getYear } from "date-fns";
import { useRelationship } from "../clientFamily/useRelationship";
import { v4 } from "uuid";

export const addMortgage = (item: CarRealEstate): CarRealEstate => {
  return {
    ...item,
    refinance_data: {
      id: v4(),
      ui_key: v4(),
      interest_rate: 0,
      refinance_amount: undefined as any,
      refinance_date: undefined as any,
      payment_plan: "NO_CHANGE",
    },
  };
};

export const changeMortgage = (
  item: CarRealEstate,
  mortgage: CarRealEstateMortgage
): CarRealEstate => {
  return {
    ...item,
    refinance_data: mortgage,
  };
};

export const deleteMortgage = (
  item: CarRealEstate,
  mortgage: CarRealEstateMortgage
): CarRealEstate => {
  return {
    ...item,
    refinance_data: null,
  };
};

const currentYear = getYear(new Date());
export const isFutureItem = (item: CarRealEstate) =>
  item.start_date_caravel?.kind !== "YEAR" ||
  item.start_date_caravel?.year !== currentYear;

export const useRealEstate = () => {
  const planId = usePlanId();
  const relationship = useRelationship();
  const { data, isLoading } = useQuestionnairePlanRealEstateListQuery(
    { planId },
    { refetchOnFocus }
  );

  const [createRel] = useQuestionnairePlanRealEstateCreateMutation();
  const [updateRel] = useQuestionnairePlanRealEstateUpdateMutation();
  const [deleteRel] = useQuestionnairePlanRealEstateDestroyMutation();

  const handleChange = (item: CarRealEstate) =>
    updateRel({
      planId,
      id: item.id ?? "",
      clientPlanRealEstate: item as Required<CarRealEstate>,
    });

  const handleDelete = (item: CarRealEstate) =>
    deleteRel({ planId, id: item.id ?? "" });

  const handleCreate = (item: CarRealEstate) =>
    createRel({
      planId,
      clientPlanRealEstate: item as Required<CarRealEstate>,
    });

  const proxy = usePendingProxy<CarRealEstate>({
    name: "RealEstate",
    items: data ?? [],
    handleCreate,
    handleChange,
    handleDelete,
    normalizeItem: (item, oldItem) => {
      deleteEmpty(item, "name");
      deleteZero(item, "real_estate_value");
      deleteZero(item, "rental_monthly_rental_income");
      // todo
      // deleteZero(item, "rental_monthly_total_payment");
      deleteZero(item, "rental_monthly_expenses");

      setZeroIfUndefined(item, "mortgage_balance");
      if (item.mortgage_balance && item.mortgage_balance > 0) {
        // set undefined to show validation error on ui
        deleteZero(item, "interest_rate");
        deleteZero(item, "mortgage_pi_payment");
      } else {
        // it is disabled if mortgage_balance is zero. let clear it.
        item["interest_rate"] = 0;
        item["mortgage_pi_payment"] = 0;
      }
    },
    validate: (item) => {
      let required: Array<keyof CarRealEstate> = [
        "real_estate_type",
        "name",
        "real_estate_value",
        "cost_basis",
        "start_date_caravel",
        "end_date_caravel",
        "mortgage_monthly_total_payment",
      ];

      if (item.mortgage_balance && item.mortgage_balance > 0) {
        required = [...required, "interest_rate", "mortgage_pi_payment"];
      }

      if (item.real_estate_type === "RENTAL") {
        required = [
          ...required,
          "rental_monthly_rental_income",
          "rental_monthly_expenses",
          "rental_inflation_adj_rent",
          "rental_inflation_adj_expenses",
        ];
      }

      let requiredMortgage: Array<keyof CarRealEstateMortgage> = [
        "refinance_date",
        "refinance_amount",
        "interest_rate",
      ];

      const mortgageItem = item.refinance_data;

      return (
        required.every((i) => item[i] !== undefined) &&
        (mortgageItem
          ? requiredMortgage.every((i) => mortgageItem[i] !== undefined)
          : true)
      );
    },
  });

  const addCurrentRow = () =>
    proxy.handleCreate({
      real_estate_type: "OCCUPIED",
      start_date_caravel: {
        plan: planId,
        kind: "YEAR",
        year: currentYear,
      },
      end_date_caravel: {
        plan: planId,
        kind: relationship.secondaryItem ? "SECOND_DEATH" : "PRIMARY_DEATH",
      },
    });

  const addFutureRow = () =>
    proxy.handleCreate({
      real_estate_type: "OCCUPIED",
      end_date_caravel: {
        plan: planId,
        kind: relationship.secondaryItem ? "SECOND_DEATH" : "PRIMARY_DEATH",
      },
    });

  return {
    isLoading,
    ...proxy,
    currentItems: proxy.items.filter((i) => !isFutureItem(i)),
    futureItems: proxy.items.filter(isFutureItem),
    addCurrentRow,
    addFutureRow,
  };
};
