import { usePortfolioGroupVersionData } from "../usePortfolioGroupVersionData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { useLocalStorage } from "features/general/useLocalStorage";
import { usePortfolioGroupVersionStatTableData } from "../usePortfolioGroupVersionStatTableData";
import { useAssetClassStatistics } from "../editPortfolioGroup/useAssetClassStatistics";

export const useViewPortfolioGroup = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;

  const data = usePortfolioGroupVersionData({
    versionId: portfolioGroupVersionId,
    show: {
      draft: true,
      benchmark: true,
      existing: false,
      selected: false,
      optimizationIds: [],
    },
    isBenchmarkEditable: false,
    isPublishedOnly: true,
  });

  const versionStatTableData = usePortfolioGroupVersionStatTableData({
    bands: data.tableData.bands,
    skip: data.assetAllocationIsLoading,
  });

  const assetClassStatistics = useAssetClassStatistics({
    simulationGroupId: groupInfo.simulationGroupId,
  });

  const [hideZeroValues, setHideZeroValues] = useLocalStorage<boolean>(
    "ViewPortfolioGroup_hideZeroValues",
    true,
  );

  const [isTreeView, setIsTreeView] = useLocalStorage<boolean>(
    "ViewPortfolioGroup_isTreeView",
    true,
  );

  return {
    portfolioGroupVersionId,
    portfolioGroupId,

    title: groupInfo.title,
    simulationSuffix: groupInfo.getSimulationSuffix(true),
    simulationGroupName: groupInfo.getSimulationGroupName(),

    isReadOnly: true,
    isError: data.isError,
    tableData: data.tableData,
    statTableData: versionStatTableData.statTableData,
    assetClassStatisticsItems: assetClassStatistics.items,

    isLoading:
      data.isLoading ||
      versionStatTableData.isLoading ||
      groupInfo.isLoading ||
      assetClassStatistics.isLoading,

    hideZeroValues,
    setHideZeroValues,

    isTreeView,
    setIsTreeView,
  };
};
