import { useAppDispatch } from "app/hooks";
import { showToast } from "app/toastSlice";
import { useConfirm } from "app/useConfirm";
import { CarSelectOption } from "components/Inputs";
import { useEffect, useRef, useState } from "react";

export interface FormFieldDef<T extends Object> {
  key: keyof T;
  type:
    | "string"
    | "integer"
    | "boolean"
    | "memo"
    | "select"
    | "multiselect"
    | "markdown";
  label: string;
  description: string;
  isRequired?: boolean;
  options?: CarSelectOption<number | string>[];
}

interface UseFormParams<T extends Object> {
  fieldDefs: FormFieldDef<T>[];
  entityLabel: string;
  getItemName: (item: T) => string;
  isLoading: boolean;
  item?: T | null;
  getNewItem: () => T;
  onCreate: (
    item: T,
  ) => Promise<boolean | { data: unknown } | { error: unknown }>;
  onUpdate: (
    item: T,
  ) => Promise<boolean | { data: unknown } | { error: unknown }>;
  onDelete: (item: T) => Promise<unknown>;
  // boolean | { data: unknown } | { error: unknown }
  onClose: () => void;
}

export interface UseForm<T extends Object> {
  fieldDefs: FormFieldDef<T>[];
  entityLabel: string;
  name: string;
  isLoading: boolean;
  value: T;
  onChange: (value: T) => void;

  handleSave: () => Promise<void>;
  handleSaveAndAdd: () => Promise<void>;
  handleSaveAndKeep: () => Promise<void>;
  handleDelete: () => Promise<void>;
  handleClose: () => void;
}

export const useForm = <T extends Object>(
  params: UseFormParams<T>,
): UseForm<T> => {
  const isSaving = useRef<boolean>(false);
  const isNewMode = useRef<boolean>(false);
  const [value, setValue] = useState<T>(params.item ?? params.getNewItem());
  const confirm = useConfirm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (params.item && !isNewMode.current) {
      setValue(params.item);
    }
  }, [params.item]);

  const name = params.getItemName(value);

  const _validate = () => {
    const result = params.fieldDefs.every(
      (i) => !i.isRequired || !!value[i.key],
    );
    if (!result) {
      dispatch(
        showToast({
          kind: "error",
          message: "Please fill all required fields",
        }),
      );
    }
    return result;
  };

  const _handleSave = async () => {
    if (isSaving.current) {
      return false;
    }

    if (!_validate()) {
      return false;
    }

    isSaving.current = true;
    try {
      const result =
        "id" in value && !!value.id
          ? await params.onUpdate(value)
          : await params.onCreate(value);

      return typeof result === "object" ? "data" in result : result;
    } finally {
      isSaving.current = false;
    }
  };

  return {
    fieldDefs: params.fieldDefs,
    isLoading: params.isLoading,
    entityLabel: params.entityLabel,
    name,
    value,
    onChange: (value) => {
      if (isSaving.current) {
        return;
      }
      setValue(value);
    },
    handleSave: async () => {
      if (await _handleSave()) {
        params.onClose();
      }
    },
    handleSaveAndAdd: async () => {
      if (await _handleSave()) {
        isNewMode.current = true;
        setValue(params.getNewItem());
      }
    },
    handleSaveAndKeep: async () => {
      await _handleSave();
    },
    handleDelete: async () => {
      if (isSaving.current) {
        return;
      }
      isSaving.current = false;
      try {
        await params.onDelete(value);
        const result = await confirm({
          label: "Delete User",
          message: `Are you sure you want to delete "${name}"?`,
          applyLabel: "Delete",
          onApplying: async () => {
            await params.onDelete(value);
            return true;
          },
        });
        if (result) {
          params.onClose();
        }
      } finally {
        isSaving.current = false;
      }
    },
    handleClose: params.onClose,
  };
};
