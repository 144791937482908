import { TitlePdfPage } from "./TitlePdfPage";
import { useUser } from "../../pages/settings/useUser";
import { DisclosuresPdfPage } from "./DisclosuresPdfPage";
import { PlanCashflowPdfPage } from "./PlanCashflowPdfPage";
import { GoalTimelinePdfPage } from "./GoalTimelinePdfPage";
import { useStressTest } from "../stressTest/useStressTest";
import { NetWorthPdfPage } from "./NetWorthPdfPage";
import { usePlanResults } from "../planResults/usePlanResults";
import { usePlanSummary } from "../planSummary/usePlanSummary";
import { TaxableIncomeAnalysisPdfPage } from "./TaxableIncomeAnalysisPdfPage";
import { GoalsPdfPage } from "./GoalsPdfPage";
import { StressTestPdfPage } from "./StressTestPdfPage";
import { useRef } from "react";
import { PageId, TableOfContentPdfPage } from "./TableOfContentPdfPage";
import { InflowsAndOutflowsPdfPage } from "./InflowsAndOutflowsPdfPage";
import { RiskPdfPage } from "./RiskPdfPage";
import { PortfolioModuleBreakdownHouseholdPdfPage } from "./PortfolioModuleBreakdownHouseholdPdfPage";
import { useHouseholdAllocation } from "../householdAllocation/useHouseholdAllocation";
import { usePlanTypes } from "app/usePlanTypes";
import { PortfolioModuleBreakdownAccountPdfPage } from "./PortfolioModuleBreakdownAccountPdfPage";
import { useAllocationByAccount } from "../allocationByAccount/useAllocationByAccount";
import { useFirm } from "../../pages/settings/useFirm";
import { MarketEnvironmentPdfPage } from "./MarketEnvironmentPdfPage";
import { GlossaryPdfPage } from "./GlossaryPdfPage";
import { useSearchParams } from "react-router-dom";
import { RealEstatePdfPage } from "./RealEstatePdfPage";
import { usePlanTypeId } from "app/usePlanId";
import { useClientName } from "app/useClientName";
import { useCma } from "./useCma";
import { CarDisclosureContentCode, useDisclosures } from "app/useDisclosures";
import { CarPdfViewer } from "components/PdfViewer";
import { CarPdfDocument } from "components/PdfDocument";
import { useAppTitle } from "app/useAppTitle";
import { CarPdfPageProps } from "components/PdfPage";
import { ImportantInformationPdfPage } from "./ImportantInformationPdfPage";
import { PublishedPlanPdfPage } from "./PublishedPlanPdfPage";
import { CapitalMarketAssumptionsPdfPage } from "./CapitalMarketAssumptionsPdfPage";

export const FinancialPlanningReport = () => {
  const [searchParams] = useSearchParams();
  const client = useClientName();
  const user = useUser();
  const firm = useFirm();

  const disclosures = useDisclosures();

  const stressTest = useStressTest();
  const planResults = usePlanResults();
  const planSummary = usePlanSummary();
  const householdAllocation = useHouseholdAllocation();
  const allocationByAccount = useAllocationByAccount();
  const cma = useCma();

  const planTypes = usePlanTypes();
  const planTypeId = usePlanTypeId();
  const portfolioLabel = planTypes.getPlanTypeLabel(planTypeId);

  const stressItems = stressTest.stressTasks[0]?.projection; // todo

  const pageNumberMap = useRef(new Map<PageId, number>());

  const title = `Planning Report for ${client.clientAndSpouseFullName}`;

  useAppTitle(title);

  const isLoading =
    disclosures.isLoading ||
    client.isLoading ||
    user.isLoading ||
    firm.isLoading ||
    stressTest.isLoading ||
    planResults.isLoading ||
    planSummary.isLoading ||
    householdAllocation.isLoading ||
    allocationByAccount.isLoading ||
    cma.isLoading ||
    planTypes.isLoading ||
    !stressItems;

  // const { hasRenderError } = planResults;

  const isDraft = searchParams.get("draft") === "true";

  const pageProps = (pageId?: PageId): CarPdfPageProps => ({
    isDraft,
    footerDisclosures: disclosures.getContent(
      CarDisclosureContentCode.CMPAS_CLIENT_REPORT_FOOTER_DISCLOSURES_10_part_10,
    ),
    onRenderPageNumber: pageId
      ? (pageNumber: number) => {
          if (!pageNumberMap.current.has(pageId)) {
            pageNumberMap.current.set(pageId, pageNumber);
          }
        }
      : undefined,
  });

  return (
    <CarPdfViewer isLoading={isLoading}>
      <CarPdfDocument title={title} author={user.userFullName}>
        <TitlePdfPage
          clientName={client.clientFullName}
          spouseName={client.spouseFullName}
          advisorName={user.userFullName}
          advisorAddress={user.user.address ?? ""}
          advisorWorkPhone={user.user.work_phone_num ?? ""}
          advisorCellPhone={user.user.cell_phone_num ?? ""}
          advisorEmail={user.user.email ?? ""}
          firm={firm.firm}
          pageProps={{ ...pageProps(), hideFooter: true }}
        />
        <ImportantInformationPdfPage
          pageProps={pageProps()}
          disclosures={disclosures}
        />
        <TableOfContentPdfPage
          pageProps={{ ...pageProps(), hideFooter: true }}
          pageNumberMap={pageNumberMap.current}
        />
        <PublishedPlanPdfPage
          pageProps={pageProps()}
          disclosures={disclosures}
          planResults={planResults}
          publishedPlan={portfolioLabel}
        />
        <GoalsPdfPage
          pageProps={pageProps("goals")}
          disclosures={disclosures}
          planSummary={planSummary}
        />
        <RiskPdfPage
          planResults={planResults}
          disclosures={disclosures}
          pageProps={pageProps("risk")}
        />
        <InflowsAndOutflowsPdfPage
          portfolioLabel={portfolioLabel}
          totals={planResults.totals}
          disclosures={disclosures}
          pageProps={pageProps("inflowsAndOutflows")}
        />
        <GoalTimelinePdfPage
          planResults={planResults}
          planSummary={planSummary}
          pageProps={pageProps("goalTimeline")}
        />
        <PortfolioModuleBreakdownHouseholdPdfPage
          portfolioLabel={portfolioLabel}
          householdAllocation={householdAllocation}
          disclosures={disclosures}
          pageProps={pageProps("portfolioModuleBreakdownHousehold")}
        />
        <PortfolioModuleBreakdownAccountPdfPage
          portfolioLabel={portfolioLabel}
          allocationByAccount={allocationByAccount}
          disclosures={disclosures}
          pageProps={pageProps("portfolioModuleBreakdownAccount")}
        />
        <StressTestPdfPage
          portfolioLabel={portfolioLabel}
          stressTest={stressTest}
          disclosures={disclosures}
          pageProps={pageProps("stressTest")}
        />
        <MarketEnvironmentPdfPage
          items={stressItems}
          pageProps={pageProps("marketEnvironment")}
        />
        <PlanCashflowPdfPage
          items={stressItems}
          pageProps={pageProps("planCashflow")}
        />
        <NetWorthPdfPage
          items={stressItems}
          pageProps={pageProps("netWorth")}
        />
        <TaxableIncomeAnalysisPdfPage
          items={stressItems}
          pageProps={pageProps("taxableIncomeAnalysis")}
        />
        <RealEstatePdfPage
          items={stressItems}
          pageProps={pageProps("realEstate")}
        />
        <DisclosuresPdfPage
          disclosures={disclosures}
          pageProps={pageProps("disclosures")}
        />
        <CapitalMarketAssumptionsPdfPage
          cma={cma}
          disclosures={disclosures}
          pageProps={pageProps("capitalMarketAssumptions")}
        />
        <GlossaryPdfPage
          disclosures={disclosures}
          pageProps={pageProps("glossary")}
        />
      </CarPdfDocument>
    </CarPdfViewer>
  );
};
