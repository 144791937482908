import {
  usePortfolioPortfolioGroupRetrieveQuery,
  usePortfolioPortfolioGroupVersionStatusListQuery,
} from "api/carApi.generated";
import { useSimulationGroups } from "./useSimulationGroups";

export const usePortfolioGroupInfo = ({
  portfolioGroupId,
}: {
  portfolioGroupId?: string;
}) => {
  const statuses = usePortfolioPortfolioGroupVersionStatusListQuery();
  const simulationGroups = useSimulationGroups();

  const portfolioGroup = usePortfolioPortfolioGroupRetrieveQuery(
    {
      id: portfolioGroupId ?? "",
    },
    {
      skip: !portfolioGroupId,
    },
  );

  const versions = portfolioGroup.data?.versions;

  const isVersionReadOnly = (versionId?: string) => {
    const versionItem = versions?.find((i) => i.id === versionId);

    return !statuses.data?.find((i) => i.id === versionItem?.status_id)
      ?.editable;
  };

  const isSinglePortfolioMode =
    !!portfolioGroup.data?.is_single_portfolio_module;

  const getStatusIdByCode = (code: "DRAFT" | "PUBLISHED") =>
    statuses.data?.find((i) => i.code === code)?.id;

  const publishedStatusId = getStatusIdByCode("PUBLISHED");
  const publishedVersionId = versions?.find(
    (i) => i.status_id === publishedStatusId,
  )?.id;

  const draftStatusId = getStatusIdByCode("DRAFT");

  const draftVersionId = versions?.find((i) => i.status_id === draftStatusId)
    ?.id;

  return {
    isLoading:
      portfolioGroup.isLoading ||
      statuses.isLoading ||
      simulationGroups.isLoading,
    isFetching: portfolioGroup.isFetching || statuses.isFetching,
    title: portfolioGroup.data?.title ?? "",
    isActive: portfolioGroup.data?.is_active ?? false,
    isPublished: !!publishedVersionId,
    activeDate: portfolioGroup.data?.active_date,
    simulationGroupId: portfolioGroup.data?.simulation_group ?? undefined,
    isVersionReadOnly,
    isSinglePortfolioMode,
    getSimulationSuffix: (isLong?: boolean) =>
      simulationGroups.getSimulationSuffix(
        portfolioGroup.data?.simulation_group,
        isLong,
      ),
    getSimulationGroupName: () =>
      simulationGroups.getSimulationGroupName(
        portfolioGroup.data?.simulation_group,
      ),
    draftVersionId,
    publishedVersionId,
    portfolioGroupVersionId: draftVersionId,
  };
};
