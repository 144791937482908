import { CarTableMultiRow } from "components/Table";
import { columns } from "./RealEstateColumns";
import { RealEstateRowProps } from "./real-estate-types";
import { memo } from "react";
import { setDisplayName } from "utils";

export const RealEstateRowOwnerOccupied = memo((props: RealEstateRowProps) => (
  <CarTableMultiRow
    className={props.className}
    item={props.item}
    rows={[
      [
        columns.realEstateType,
        columns.description,
        columns.realEstateValue,
        columns.costBasis,
        columns.mortgageBalance,
        columns.interestRate,
        columns.sellDate,
      ],
      [
        columns.primaryResidence,
        columns.piPayment,
        columns.monthlyTotalPayment,
        columns.maturityYear,
      ],
      [
        columns.secondMortgage,
        ...(props.item.refinance_data
          ? [
              columns.secondMortgageAmount,
              columns.secondMortgageStartDate,
              columns.secondMortgageInterestRate,
              columns.secondMortgageLoadDuration,
            ]
          : []),
      ],
    ]}
    onChange={props.onChange}
    onDelete={props.onDelete}
    pendoPrefix={props.pendoPrefix}
  />
));

setDisplayName({ RealEstateRowOwnerOccupied });
