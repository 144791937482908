import { Typography, Box, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { fileToBase64 } from "utils/fileUtils";
import { CarSwitch } from "components/Switch";
import { showToast } from "app/toastSlice";
import { CarAvatarField } from "components/AvatarField";
import { CarTextFieldDelayed } from "components/Inputs";
import { UseFirm } from "./useFirm";

const StyledTextField = styled(CarTextFieldDelayed)(({ theme }) => ({
  ".MuiInputLabel-root": {
    fontSize: "16px",
  },
  ".MuiOutlinedInput-root": {
    backgroundColor: theme.palette.white,
    borderRadius: "5px",
    marginTop: 22,
    "& input": {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      ...theme.typography.par01Regular,
      color: theme.palette.black,
    },
    "& fieldset": {
      borderRadius: "5px",
    },
    ".MuiSvgIcon-root": {
      width: 16,
      height: 16,
      color: theme.palette.softBlack,
    },
  },
}));

export const SectionFirmProfile = ({ data }: { data: UseFirm }) => {
  const dispatch = useDispatch();

  const handleImageSelected = async (selection: Array<File>) => {
    if (selection.length === 0) {
      return;
    }

    if (selection[0].size > 50 * 1024) {
      dispatch(
        showToast({
          kind: "errorMessage",
          message: "File size should be less than 50KB",
        }),
      );
      return;
    }

    const base64 = await fileToBase64(selection[0]);
    data.updateFirm({ ...data.firm, logo: base64 });
  };

  return (
    <Box
      sx={{
        mt: -1,
        display: "flex",
        flexDirection: "column",
        gap: 5,
      }}
    >
      <CarAvatarField
        sx={{ alignSelf: "flex-start", mb: 1 }}
        value={data.firm.logo ?? undefined}
        onFileSelected={handleImageSelected}
      />
      <StyledTextField
        sx={{ width: 380 }}
        required={true}
        value={data.firm.name}
        onChange={(name) => {
          if (name) {
            data.updateFirm({
              ...data.firm,
              name,
            });
          }
        }}
        label="*Firm Name"
      />
      <Typography variant="par02Regular">
        <Box component="span" sx={{ fontWeight: 600 }}>
          Firm ID:
        </Box>
        {` ${data.firm.id}`}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Typography variant="par02SemiBold">Allow Caravel Support</Typography>
        <CarSwitch
          checked={data.firm.allow_caravel_support}
          size="small"
          onChange={(e) =>
            data.updateFirm({
              ...data.firm,
              allow_caravel_support: e.target.checked,
            })
          }
        />
      </Box>
    </Box>
  );
};
