import { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { BaseDialog } from "components/BaseDialog";
import { CarButton, CarLoadingButton } from "components/Buttons";
import {
  // useCommonBillingActivateBillingCreateQuery,
  useCommonBillingActivateBillingV2CreateMutation,
  useCommonBillingStripePriceListQuery,
} from "api/carApi.generated";
import { useRoles } from "app/useRoles";
import { TablePlaceHolder } from "components/PlaceHolder";
import { LoginLogo } from "pages/login/LoginComponents";
import { CarNumberField } from "components/NumberField";
import { formatCurrency } from "utils";
import { Add, Remove } from "@mui/icons-material";
import { PaymentForm } from "pages/payment/PaymentForm";

interface AddSeatsDialogProps {
  onApply: (value: boolean) => void;
  onCancel: () => void;
}

interface PurchaseItem {
  id: string;
  label: string;
  roleCode: string;
  description: string;
  amount: number;
  seats: number;
}

const PurchaseRow = (props: {
  value: PurchaseItem;
  onChange: (value: PurchaseItem) => void;
}) => {
  const disabled = props.value.id === dataEntryKey;
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: 540 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h4SSemiBold">{props.value.label}</Typography>
        <Typography
          variant="par01Regular"
          color="gray9"
          sx={{ fontSize: "15px" }}
        >
          {props.value.description}
        </Typography>
      </Box>
      <Box
        sx={{ ml: "auto", width: 170, display: "flex", alignItems: "center" }}
      >
        <IconButton
          color="primary"
          disabled={disabled || props.value.seats <= 0}
          onClick={() =>
            props.onChange({
              ...props.value,
              seats: props.value.seats - 1,
            })
          }
        >
          <Remove />
        </IconButton>
        <CarNumberField
          sx={{
            ".MuiOutlinedInput-input": {
              fontSize: "16px",
            },
            ".MuiOutlinedInput-root": {
              height: 50,
            },
          }}
          disabled={disabled}
          value={props.value.seats}
          minValue={0}
          onChange={(seats) =>
            props.onChange({ ...props.value, seats: seats ?? 0 })
          }
        />
        <IconButton
          color="primary"
          disabled={disabled}
          onClick={() =>
            props.onChange({
              ...props.value,
              seats: props.value.seats + 1,
            })
          }
        >
          <Add />
        </IconButton>
      </Box>
    </Box>
  );
};

const dataEntryKey = "cmpas_data_entry";

export const AddSeatsDialog = (props: AddSeatsDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [paymentInfo, setPaymentInfo] = useState<
    { amount: number; clientSecret: string } | undefined
  >(undefined);

  const roles = useRoles();

  const priceList = useCommonBillingStripePriceListQuery();

  const [getPayment] = useCommonBillingActivateBillingV2CreateMutation();

  // const temp = useCommonBillingActivateBillingCreateQuery();

  const [items, setItems] = useState<PurchaseItem[]>([]);

  const totalAmount =
    items.reduce((acc, i) => acc + i.amount * i.seats, 0) / 100;

  useEffect(() => {
    if (roles.isLoading || priceList.isLoading || items.length > 0) {
      return;
    }

    setItems(
      priceList.data
        ?.filter((i) => !!i.lookup_key)
        .map<PurchaseItem>((i) => {
          const role = roles.items.find(
            (r) => r.stripe_price_code === i.lookup_key,
          );

          return {
            id: i.lookup_key ?? "",
            label: role?.name ?? i.lookup_key ?? "",
            roleCode: role?.code ?? "",
            description:
              i.lookup_key === dataEntryKey
                ? "1 free seat with any seat license above."
                : `This seat cost is ${i.human_readable_price}`,
            amount: i.lookup_key === dataEntryKey ? 0 : i.unit_amount ?? 0,
            seats: 0,
          };
        }) ?? [],
    );
  }, [roles, priceList, items]);

  const handleContinue = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      const payment = await getPayment({
        activateBillingV2: {
          licenses: items
            .filter((i) => i.id !== dataEntryKey && i.seats > 0)
            .map((i) => ({
              role_code: i.roleCode,
              seats: i.seats,
            })),
        },
      });

      if ("data" in payment) {
        setPaymentInfo({
          amount: payment.data.stripe_invoice_amount_due ?? 0,
          clientSecret:
            payment.data.stripe_invoice_payment_intent_client_secret ?? "",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const selectedSummary = items
    .filter((i) => i.id !== dataEntryKey && i.seats > 0)
    .map((i) => `${i.seats}\u00A0${i.label}`)
    .join(", ");

  const freeLicenseCount = items.find((i) => i.id === dataEntryKey)?.seats ?? 0;

  const handleBack = () => {
    setPaymentInfo(undefined);
  };

  return (
    <BaseDialog
      sx={{ minWidth: paymentInfo ? undefined : 1210, maxWidth: "100vw" }}
      onClose={props.onCancel}
    >
      <TablePlaceHolder
        sx={{ mx: 5, mt: 10 }}
        isLoading={roles.isLoading || priceList.isLoading}
        rows={8}
      >
        {paymentInfo ? (
          <PaymentForm
            amount={paymentInfo.amount}
            clientSecret={paymentInfo.clientSecret}
            onBack={handleBack}
            isWhiteContext
          />
        ) : (
          <>
            <LoginLogo sx={{ mb: 3 }} />
            <Typography variant="h1SSemiBold">Purchase Seats</Typography>
            <Box sx={{ mt: 7, display: "flex", gap: 7 }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
                {items.map((i) => (
                  <PurchaseRow
                    key={i.id}
                    value={i}
                    onChange={(v) =>
                      setItems((value) => {
                        let newValue = value.map((old) =>
                          old.id === v.id ? v : old,
                        );

                        const dataEntrySeats = newValue
                          .filter((i) => i.id !== dataEntryKey)
                          .reduce((acc, i) => acc + i.seats, 0);

                        newValue = newValue.map((old) =>
                          old.id === dataEntryKey
                            ? { ...old, seats: dataEntrySeats }
                            : old,
                        );

                        return newValue;
                      })
                    }
                  />
                ))}
              </Box>
              <Box
                sx={{
                  width: 500,
                  alignSelf: "center",
                  display: "flex",
                  flexDirection: "column",
                  border: "2px solid",
                  borderColor: "gray3",
                  borderRadius: "15px",
                  backgroundColor: "gray1",
                  boxShadow: "4px 4px 10px 0px #00000026",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    px: 3,
                    py: 4,
                    pb: 8,
                    borderBottom: "2px solid",
                    borderColor: "gray3",
                    gap: 1.5,
                  }}
                >
                  <Typography variant="h3SSemiBold">Summary</Typography>
                  {totalAmount > 0 ? (
                    <Typography variant="h6SRegular">
                      You are purchasing{" "}
                      <Typography variant="h6SSemiBold">
                        {selectedSummary}
                      </Typography>{" "}
                      seat license(s). With this purchase you will receive{" "}
                      <Typography variant="h6SSemiBold">
                        {`${freeLicenseCount}\u00A0free `}
                      </Typography>
                      Data Entry license(s)
                    </Typography>
                  ) : (
                    <Typography variant="h6SRegular">
                      Please select how many seats you want to buy
                    </Typography>
                  )}
                </Box>
                <Box sx={{ display: "flex", px: 3, py: 4 }}>
                  <Typography variant="h3SSemiBold">Total Due Today</Typography>
                  <Typography
                    variant="h3SSemiBold"
                    sx={{ ml: "auto", color: "caravelOrangePrimary" }}
                  >
                    {formatCurrency(totalAmount, 2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: 10, mx: "auto", display: "flex", gap: 5 }}>
              <CarButton
                sx={{ alignSelf: "center", fontSize: "18px", width: 170 }}
                onClick={() => {
                  if (!isLoading) {
                    props.onCancel();
                  }
                }}
                variant="outlined"
              >
                Back
              </CarButton>
              <CarLoadingButton
                sx={{ alignSelf: "center", fontSize: "18px", width: 170 }}
                onClick={handleContinue}
                autoFocus
                disabled={!totalAmount}
                isLoading={isLoading}
              >
                Continue
              </CarLoadingButton>
            </Box>
          </>
        )}
      </TablePlaceHolder>
    </BaseDialog>
  );
};
