import { Dialog, Divider, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CarButton } from "components/Buttons";
import googleAuth from "assets/google-auth.jpg";
import microsoftAuth from "assets/microsoft-auth.jpg";
import { CarClose } from "icons";

export interface TwoFactorInstallAppDialogProps {
  isEnforce?: boolean;
  onApply: (value: boolean) => void;
  onCancel: () => void;
}

export const TwoFactorInstallAppDialog = (
  props: TwoFactorInstallAppDialogProps
) => {
  const message = props.isEnforce
    ? "In order to comply with your firm’s client information protection policies, you must install a 2 factor authentication app at this time."
    : "You will need to install an authentication app on your phone to set up two-factor authentication. Please select one of the following apps and download it onto your smartphone. When the app is downloaded, please click “Continue”.";

  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "6px",
          p: 5,
          position: "relative",
        },
      }}
      maxWidth="lg"
      open={true}
      onClose={(e, reason) => {
        if (reason !== "backdropClick") {
          props.onCancel();
        }
      }}
    >
      {!props.isEnforce && (
        <IconButton
          sx={{ position: "absolute", right: 10, top: 10, color: "gray6" }}
          onClick={props.onCancel}
        >
          <CarClose />
        </IconButton>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", maxWidth: 690 }}>
        <Typography variant="h4SSemiBold">
          Install an authentication app on your smartphone
        </Typography>
        <Divider sx={{ mt: 0.5, mb: 1, borderColor: "gray6" }} />

        <Typography variant="par02Regular">{message}</Typography>
        <Box
          sx={{
            mt: 2.75,
            mb: 5,
            backgroundColor: "gray1",
            border: "solid 1px",
            borderColor: "gray3",
            borderRadius: "5px",
            p: 4,
            pl: "50px",
            display: "flex",
            gap: "125px",
          }}
        >
          <Box sx={{ display: "flex", gap: 1.5, alignItems: "center" }}>
            <Box
              sx={{
                width: 50,
                height: 50,
                backgroundImage: `url("${googleAuth}")`,
                borderRadius: "5px",
                border: "solid 1px",
                borderColor: "gray3",
              }}
            ></Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6SBold">Google Authenticator</Typography>
              <Typography variant="par01Regular">from Google inc.</Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", gap: 1.5, alignItems: "center" }}>
            <Box
              sx={{
                width: 50,
                height: 50,
                backgroundImage: `url("${microsoftAuth}")`,
                borderRadius: "5px",
                border: "solid 1px",
                borderColor: "gray3",
              }}
            ></Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h6SBold">Microsoft Authenticator</Typography>
              <Typography variant="par01Regular">
                from Microsoft Corporation
              </Typography>
            </Box>
          </Box>
        </Box>
        <CarButton
          sx={{ alignSelf: "center", fontSize: "18px" }}
          onClick={() => props.onApply(true)}
        >
          Continue
        </CarButton>
      </Box>
    </Dialog>
  );
};
