import { Typography, BoxProps, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { RootPageBox } from "components/RootPage";
import { assetAllocationPages } from "route.const";
import { formatDate } from "utils";
import { usePortfolioGroupVersions } from "./usePortfolioGroupVersions";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { CarButton } from "components/Buttons";
import { CarGrid } from "components/Grid";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

export interface PortfolioGroupVersionsProps {
  selectedPortfolioGroupId?: string;
  sx?: BoxProps["sx"];
}

export const PortfolioGroupVersions = ({
  selectedPortfolioGroupId,
  sx,
}: PortfolioGroupVersionsProps) => {
  const navigate = useNavigate();

  const groupInfo = usePortfolioGroupInfo({
    portfolioGroupId: selectedPortfolioGroupId,
  });

  const data = usePortfolioGroupVersions(selectedPortfolioGroupId);

  const isLoading = groupInfo.isLoading || data.isLoading;

  const versionItems = data.versionItems.slice(0, 6); // draft, published and up to 4 archived

  const handleEditClick = () => {
    if (!selectedPortfolioGroupId) {
      return;
    }

    navigate(
      assetAllocationPages.getEditPortfolioGroupUrl({
        portfolioGroupId: selectedPortfolioGroupId,
        isPublished: data.versionItems.length > 1,
      }),
    );
  };

  const handleViewClick = (id: string) =>
    navigate(
      assetAllocationPages.getViewPortfolioGroupUrl(
        selectedPortfolioGroupId ?? "",
        id,
      ),
    );

  return (
    <RootPageBox
      sx={{
        p: 4,
        minHeight: 326,
        ...sx,
      }}
    >
      <Typography variant="h5SSemiBold" sx={{ mb: 2.5 }}>
        Revisions
        <CarTooltipBox
          sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
          className={pendoClasses.assetAllocationDashboardRevisions}
        />
      </Typography>
      <CarGrid
        isLoading={isLoading}
        sx={{ height: "100%" }}
        showHeaderDivider
        isEvenOdd
        columns={[
          {
            field: "status",
            textAlign: "center",
            headerAlign: "center",
            label: "Status",
          },
          {
            field: "created_at",
            textAlign: "center",
            headerAlign: "center",
            label: "Created On",
            onGetValue: (row) => formatDate(row.created_at),
          },
          {
            field: "actions" as any,
            textAlign: "center",
            headerAlign: "center",
            label: "Actions",
            fraction: 0.5,
            onCellRender: (row) => (
              <Box
                sx={{
                  my: 1.25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CarButton
                  sx={{
                    width: 100,
                    height: 40,
                  }}
                  variant={row.isEditable ? "contained" : "outlined"}
                  onClick={() =>
                    row.isEditable
                      ? handleEditClick()
                      : handleViewClick(row.id ?? "")
                  }
                >
                  {row.isEditable ? "Edit" : "View"}
                </CarButton>
              </Box>
            ),
          },
        ]}
        rows={versionItems}
      />
    </RootPageBox>
  );
};
