import { useTheme } from "@mui/material";
import { CarPdfBox, CarPdfBoxProps } from "components/PdfBox";
import {
  CarPdfTypography,
  CarPdfTypographyProps,
} from "components/PdfTypography";

export interface CarPdfHeadingProps extends CarPdfBoxProps {
  label: string;
}
export const CarPdfHeading = ({ label, ...props }: CarPdfHeadingProps) => {
  const theme = useTheme();

  return (
    <CarPdfBox {...props}>
      <CarPdfTypography variant="h2">{label}</CarPdfTypography>
      <CarPdfBox
        style={{
          backgroundColor: theme.palette.primary.main,
          marginTop: 7,
          width: 52,
          height: 2,
        }}
      />
    </CarPdfBox>
  );
};

export interface CarPdfSubHeadingProps extends CarPdfBoxProps {
  label: string;
}
export const CarPdfSubHeading = ({
  label,
  style,
  ...props
}: CarPdfSubHeadingProps) => {
  const theme = useTheme();

  return (
    <CarPdfBox
      style={{ flexDirection: "row", alignItems: "flex-end", ...style }}
      {...props}
    >
      <CarPdfBox
        style={{
          backgroundColor: theme.palette.primary.main,
          width: 26,
          height: 2,
          marginRight: 5,
          marginBottom: 14,
        }}
      />
      <CarPdfTypography variant="h3">{label}</CarPdfTypography>
    </CarPdfBox>
  );
};

export interface CarPdfHeadingACProps extends CarPdfBoxProps {
  firmName: string;
  label: string;
}
export const CarPdfHeadingAC = ({
  firmName,
  label,
  ...props
}: CarPdfHeadingACProps) => {
  const theme = useTheme();

  return (
    <CarPdfBox {...props}>
      <CarPdfTypography variant="h6">{firmName}</CarPdfTypography>
      <CarPdfTypography
        variant="h6"
        style={{ color: theme.palette.caravelOrangePrimary }}
      >
        {label}
      </CarPdfTypography>
    </CarPdfBox>
  );
};

export const CarPdfInternalUse = (props: CarPdfTypographyProps) => {
  const theme = useTheme();
  return (
    <CarPdfTypography
      {...props}
      variant="internalUse"
      style={{
        textAlign: "right",
        color: theme.palette.softBlack,
        ...props.style,
      }}
    >
      Internal Use Only
    </CarPdfTypography>
  );
};
