import {
  api as generatedApi,
  ClientPlanDeRiskDetails,
  PatchedPortfolioModule,
  PortfolioPortfolioGroupVersionAssetClassesListApiResponse,
  PortfolioPortfolioGroupVersionModulesDataListApiResponse,
  // PortfolioPortfolioGroupVersionModulesDataRetrieveApiArg,
  // PortfolioPortfolioGroupVersionModulesDataRetrieveApiResponse,
  // PortfolioPortfolioGroupVersionModulesDataStatisticsRetrieveApiArg,
  QuestionnairePlanAccountsListApiResponse,
  QuestionnairePlanAdditionalIncomeListApiResponse,
  QuestionnairePlanGoalsListApiResponse,
  QuestionnairePlanHealthcareListApiResponse,
  QuestionnairePlanRealEstateListApiResponse,
  QuestionnairePlanRelationshipsListApiResponse,
  QuestionnairePlanRetrieveApiResponse,
  QuestionnairePlanRiskUpdateApiResponse,
  QuestionnairePlanSocialSecurityListApiResponse,
  QuestionnairePlanSpousalBenefitUpdateApiResponse,
  QuestionnaireClientsNotesListApiResponse,
  PortfolioPortfolioGroupVersionOptimizerSettingsListApiResponse,
  PortfolioModuleConstraints,
  PortfolioModuleConstraintsAc,
  NotificationsNotificationSettingListApiResponse,
  NotificationsNotificationListApiResponse,
  NotificationsNotificationTierThresholdListApiResponse,
  CommonFirmMembersListApiResponse,
  CommonInvitationsListApiResponse,
  AssetClassesFirmAssetClassImplementationListApiResponse,
  AssetClassesSimulationBehaviorEquityListApiResponse,
  AssetClassesSimulationBehaviorCashListApiResponse,
  AssetClassesSimulationBehaviorFixedIncomeSpreadListApiResponse,
  CommonAuthSelfFirmRetrieveApiResponse,
} from "./carApi.generated";
// import { carBaseQuery } from "./carBaseQuery";

// const getPortfolioGroupVersionModulesDataQuery = (
//   queryArg: PortfolioPortfolioGroupVersionModulesDataRetrieveApiArg
// ) => ({
//   url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.id}/`,
// });

// const getStatisticQuery = (
//   queryArg: PortfolioPortfolioGroupVersionModulesDataStatisticsRetrieveApiArg
// ) => ({
//   url: `/api/portfolio/portfolio_group_version/${queryArg.portfolioGroupVersionId}/modules/${queryArg.moduleId}/data/${queryArg.dataId}/statistics/${queryArg.id}/`,
// });

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: [
    "User",
    "Firm",
    "Accounts",
    "AccountSavings",
    "OptimizedSocialSecurity",
    "Healthcare",
    "Clients",
    "Client",
    "ClientPlan",
    "ClientNotes",
    "AlEngineRender",
    "AvailableIntegrators",
    "PortfolioGroupList",
    "PortfolioGroup",
    "PortfolioGroupVersion",
    "PortfolioVersionAssetClasses",
    "PortfolioGroupVersionModule",
    "PortfolioGroupVersionModuleData",
    "PortfolioGroupVersionOptimizerAllCons",
    "PortfolioGroupVersionOptimizerSettings",
    "PortfolioGroupVersionOptimizerJobs",
    "PortfolioGetPortfolioModuleData",
    "PortfolioOptimizerTemplates",
    "RenderJobStatus",
    "ReadNotifications",
    "FirmMembers",
    "Invitations",
    "BillingCheckStatus",
    "Role",
    "ModuleHierarchy",
    "Module",
    "ModuleSection",
    "PageSection",
    "PageSelectionBlock",
    "SimulationSetting",
    "SimulationSettingInflationExpectedMean",
  ],
  endpoints: {
    questionnairePlanCopyUpdate: {
      invalidatesTags: ["Clients", "Client"],
    },

    questionnairePlanDestroy: {
      invalidatesTags: ["Clients", "Client"],
    },

    questionnairePlanRelationshipsCreate: {
      invalidatesTags: [
        "Healthcare",
        "OptimizedSocialSecurity",
        "RenderJobStatus",
        "Clients",
        "Client",
      ],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "questionnairePlanRelationshipsList",
            { planId },
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    questionnairePlanRelationshipsUpdate: {
      invalidatesTags: [
        "Healthcare",
        "OptimizedSocialSecurity",
        "RenderJobStatus",
        "Clients",
        "Client",
      ],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: QuestionnairePlanRelationshipsListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanRelationshipsList",
              { planId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlanRelationship);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanRelationshipsDestroy: {
      invalidatesTags: [
        "Healthcare",
        "OptimizedSocialSecurity",
        "RenderJobStatus",
        "Clients",
        "Client",
      ],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "questionnairePlanRelationshipsList",
            { planId },
            (draft) => {
              return draft.filter((i) => i.id !== patch.id);
            },
          ),
        );
        await queryFulfilled.catch(() => patchResult.undo());
      },
    },

    questionnairePlanSocialSecurityCreate: {
      invalidatesTags: ["OptimizedSocialSecurity", "RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "questionnairePlanSocialSecurityList",
            { planId },
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    questionnairePlanSocialSecurityUpdate: {
      invalidatesTags: ["OptimizedSocialSecurity", "RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: QuestionnairePlanSocialSecurityListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanSocialSecurityList",
              { planId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlanSocialSecurity);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanSocialSecurityDestroy: {
      invalidatesTags: ["OptimizedSocialSecurity", "RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "questionnairePlanSocialSecurityList",
            { planId },
            (draft) => {
              return draft.filter((i) => i.id !== patch.id);
            },
          ),
        );
        await queryFulfilled.catch(() => patchResult.undo());
      },
    },

    questionnairePlanSpousalBenefitUpdate: {
      invalidatesTags: ["OptimizedSocialSecurity", "RenderJobStatus"],
      onQueryStarted: async (
        { id, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: QuestionnairePlanSpousalBenefitUpdateApiResponse,
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanSpousalBenefitRetrieve",
              { id },
              (draft) => {
                return item;
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlanSpousalBenefit);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanOptimizedSocialSecurityRetrieve: {
      providesTags: ["OptimizedSocialSecurity"],
    },

    questionnairePlanGoalsCreate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "questionnairePlanGoalsList",
            { planId },
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    questionnairePlanGoalsUpdate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: QuestionnairePlanGoalsListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanGoalsList",
              { planId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlanGoal);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanGoalsDestroy: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "questionnairePlanGoalsList",
            { planId },
            (draft) => {
              return draft.filter((i) => i.id !== patch.id);
            },
          ),
        );
        await queryFulfilled.catch(() => patchResult.undo());
      },
    },

    questionnairePlanAdditionalIncomeCreate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "questionnairePlanAdditionalIncomeList",
            { planId },
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    questionnairePlanAdditionalIncomeUpdate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: QuestionnairePlanAdditionalIncomeListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanAdditionalIncomeList",
              { planId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlanAdditionalIncome);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanAdditionalIncomeDestroy: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "questionnairePlanAdditionalIncomeList",
            { planId },
            (draft) => {
              return draft.filter((i) => i.id !== patch.id);
            },
          ),
        );
        await queryFulfilled.catch(() => patchResult.undo());
      },
    },

    questionnairePlanRealEstateCreate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "questionnairePlanRealEstateList",
            { planId },
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    questionnairePlanRealEstateUpdate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: QuestionnairePlanRealEstateListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanRealEstateList",
              { planId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlanRealEstate);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanRealEstateDestroy: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "questionnairePlanRealEstateList",
            { planId },
            (draft) => {
              return draft.filter((i) => i.id !== patch.id);
            },
          ),
        );
        await queryFulfilled.catch(() => patchResult.undo());
      },
    },

    questionnairePlanRiskUpdate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { id, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (item: QuestionnairePlanRiskUpdateApiResponse) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanRiskRetrieve",
              { id },
              (draft) => {
                return item;
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlanRisk);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanUpdate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { id, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (item: QuestionnairePlanRetrieveApiResponse) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanRetrieve",
              { id },
              (draft) => {
                return item;
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlan);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanPartialUpdate: {
      invalidatesTags: ["Clients", "Client"],
    },

    questionnairePlanSavingsThisYearRetrieve: {
      providesTags: ["AccountSavings"],
    },

    questionnairePlanAccountsList: {
      providesTags: ["Accounts"],
    },

    questionnairePlanAccountsLinkExternalCreate: {
      invalidatesTags: ["Accounts", "AccountSavings", "RenderJobStatus"],
    },

    dataProvidersPlanAccountsLinkRayJayExternalCreate: {
      invalidatesTags: ["Accounts", "AccountSavings", "RenderJobStatus"],
    },

    dataProvidersPlanAccountsLinkSchwabExternalCreate: {
      invalidatesTags: ["Accounts", "AccountSavings", "RenderJobStatus"],
    },

    questionnairePlanAccountsCreate: {
      invalidatesTags: ["AccountSavings", "RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "questionnairePlanAccountsList",
            { planId },
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    questionnairePlanAccountsUpdate: {
      invalidatesTags: ["AccountSavings", "RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: QuestionnairePlanAccountsListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanAccountsList",
              { planId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.account);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanAccountsDestroy: {
      invalidatesTags: ["AccountSavings", "RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "questionnairePlanAccountsList",
            { planId },
            (draft) => {
              return draft.filter((i) => i.id !== patch.id);
            },
          ),
        );
        await queryFulfilled.catch(() => patchResult.undo());
      },
    },

    questionnairePlanHealthcareList: {
      providesTags: ["Healthcare"],
    },

    questionnairePlanHealthcareRetrieve: {
      providesTags: ["Healthcare"],
    },

    questionnairePlanHealthcareCreate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "questionnairePlanHealthcareList",
            { planId },
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    questionnairePlanHealthcareUpdate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: QuestionnairePlanHealthcareListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanHealthcareList",
              { planId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlanHealthcare);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnairePlanHealthcareDestroy: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "questionnairePlanHealthcareList",
            { planId },
            (draft) => {
              return draft.filter((i) => i.id !== patch.id);
            },
          ),
        );
        await queryFulfilled.catch(() => patchResult.undo());
      },
    },

    questionnairePlanDeriskCreate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "questionnairePlanDeriskList",
            { planId },
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    questionnairePlanDeriskUpdate: {
      invalidatesTags: ["RenderJobStatus"],
      onQueryStarted: async (
        { planId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (item: ClientPlanDeRiskDetails) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnairePlanDeriskList",
              { planId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientPlanDeRiskDetails);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    alEngineRenderPlanResultJobStatusList: {
      providesTags: ["RenderJobStatus"],
    },

    alEngineRenderRetrieve: {
      providesTags: ["AlEngineRender"],
    },

    portfolioPortfolioGroupList: {
      providesTags: ["PortfolioGroupList"],
    },

    portfolioPortfolioGroupRetrieve: {
      providesTags: ["PortfolioGroup"],
    },

    portfolioPortfolioGroupCreate: {
      invalidatesTags: ["PortfolioGroupVersion"],
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "portfolioPortfolioGroupList",
            undefined,
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    portfolioPortfolioGroupPartialUpdate: {
      invalidatesTags: [
        "PortfolioGroupList",
        "PortfolioGroup",
        "PortfolioGroupVersion",
      ], // do_publish: true - creates new version and modifies existing!
    },

    portfolioPortfolioGroupDestroy: {
      invalidatesTags: ["PortfolioGroupList"],
    },

    portfolioPortfolioGroupVersionList: {
      providesTags: ["PortfolioGroupVersion"],
    },

    portfolioPortfolioGroupVersionRetrieve: {
      providesTags: ["PortfolioGroupVersion"],
    },

    portfolioPortfolioGroupVersionModulesList: {
      providesTags: ["PortfolioGroupVersionModule"],
    },

    portfolioPortfolioGroupVersionModulesRetrieve: {
      providesTags: ["PortfolioGroupVersionModule"],
    },

    portfolioPortfolioGroupVersionOptimizerAllConsList: {
      providesTags: ["PortfolioGroupVersionOptimizerAllCons"],
    },

    // when we update module we should patch respective portfolio group version
    // start
    portfolioPortfolioGroupVersionModulesCreate: {
      invalidatesTags: ["PortfolioGroupVersionOptimizerAllCons"],
      onQueryStarted: async (
        { portfolioGroupVersionId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "portfolioPortfolioGroupVersionRetrieve",
            { id: portfolioGroupVersionId },
            (draft) => {
              draft.portfolio_modules?.push(res.data);
            },
          ),
        );
      },
    },

    portfolioPortfolioGroupVersionModulesPartialUpdate: {
      onQueryStarted: async (
        { portfolioGroupVersionId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatchToVersion = (item: PatchedPortfolioModule) => {
          return dispatch(
            api.util.updateQueryData(
              "portfolioPortfolioGroupVersionRetrieve",
              {
                id: portfolioGroupVersionId,
              },
              (draft) => {
                draft.portfolio_modules = draft.portfolio_modules?.map((md) =>
                  md.id === patch.id ? { ...md, ...item } : md,
                );
              },
            ),
          );
        };

        const applyPatchToOptimization = (item: PatchedPortfolioModule) => {
          return dispatch(
            api.util.updateQueryData(
              "portfolioPortfolioGroupVersionOptimizerAllConsList",
              {
                portfolioGroupVersionId,
              },
              (draft) => {
                // portfolio_module_data is not compatible so just excluding it
                const { portfolio_module_data, ...sanitizedItem } = item;
                return draft.map((md) =>
                  md.id === patch.id
                    ? {
                        ...md,
                        ...sanitizedItem,
                      }
                    : md,
                );
              },
            ),
          );
        };

        const versionPatchResult = applyPatchToVersion(
          patch.patchedPortfolioModule,
        );
        const optimizationPatchResult = applyPatchToOptimization(
          patch.patchedPortfolioModule,
        );
        try {
          const res = await queryFulfilled;
          applyPatchToVersion(res.data);
          applyPatchToOptimization(res.data);
        } catch {
          versionPatchResult.undo();
          optimizationPatchResult.undo();
        }
      },
    },

    portfolioPortfolioGroupVersionModulesDestroy: {
      invalidatesTags: ["PortfolioGroupVersionOptimizerAllCons"],
      onQueryStarted: async (
        { portfolioGroupVersionId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "portfolioPortfolioGroupVersionRetrieve",
            { id: portfolioGroupVersionId },
            (draft) => {
              draft.portfolio_modules = draft.portfolio_modules?.filter(
                (i) => i.id !== patch.id,
              );
            },
          ),
        );
        await queryFulfilled.catch(() => patchResult.undo());
      },
    },

    portfolioPortfolioGroupVersionAssetClassesList: {
      providesTags: ["PortfolioVersionAssetClasses"],
    },

    portfolioPortfolioGroupVersionAssetClassesUpdate: {
      onQueryStarted: async (
        { portfolioGroupVersionId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: PortfolioPortfolioGroupVersionAssetClassesListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "portfolioPortfolioGroupVersionAssetClassesList",
              { portfolioGroupVersionId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.portfolioAssetClassOptions);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    portfolioPortfolioGroupVersionModulesDataList: {
      providesTags: (result) =>
        result
          ? [
              ...result.map((i) => ({
                type: "PortfolioGroupVersionModuleData" as const,
                id: i.id,
              })),
              { type: "PortfolioGroupVersionModuleData", id: "LIST" },
            ]
          : [{ type: "PortfolioGroupVersionModuleData", id: "LIST" }],
    },

    portfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdate: {
      invalidatesTags: ["PortfolioGetPortfolioModuleData"],
      onQueryStarted: async (
        { portfolioGroupVersionId, moduleId, dataId, id, ...patch },
        { dispatch, queryFulfilled, getState, extra },
      ) => {
        const applyPatch = (
          item: PortfolioPortfolioGroupVersionModulesDataListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "portfolioPortfolioGroupVersionRetrieve",
              { id: portfolioGroupVersionId },
              (draft) => {
                const alloc = draft.portfolio_modules
                  ?.find((md) => md.id === moduleId)
                  ?.portfolio_module_data?.find((dt) => dt.id === dataId)
                  ?.allocations?.find((al) => al.id === id);

                if (alloc) {
                  alloc.allocation =
                    patch.patchedPortfolioModuleDataAllocation.allocation;
                }
              },
            ),
          );
        };

        const patchResult = applyPatch({
          id,
          ...patch.patchedPortfolioModuleDataAllocation,
        });
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    portfolioPortfolioGroupVersionModulesDataMakeSelectedUpdate: {
      onQueryStarted: async (
        { portfolioGroupVersionId, moduleId, id },
        { dispatch, queryFulfilled, getState, extra },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "portfolioPortfolioGroupVersionRetrieve",
            { id: portfolioGroupVersionId },
            (draft) => {
              draft.portfolio_modules
                ?.find((md) => md.id === moduleId)
                ?.portfolio_module_data?.forEach((d) => {
                  d.selected = d.id === id;
                });
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnaireClientsNotesCreate: {
      onQueryStarted: async (
        { clientId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const res = await queryFulfilled;
        dispatch(
          api.util.updateQueryData(
            "questionnaireClientsNotesList",
            { clientId },
            (draft) => {
              draft.push(res.data);
            },
          ),
        );
      },
    },

    questionnaireClientsNotesUpdate: {
      onQueryStarted: async (
        { clientId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: QuestionnaireClientsNotesListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "questionnaireClientsNotesList",
              { clientId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.clientNote);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnaireClientsNotesDestroy: {
      onQueryStarted: async (
        { clientId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "questionnaireClientsNotesList",
            { clientId },
            (draft) => {
              return draft.filter((i) => i.id !== patch.id);
            },
          ),
        );
        await queryFulfilled.catch(() => patchResult.undo());
      },
    },

    questionnaireClientsList: {
      providesTags: ["Clients"],
    },

    questionnaireClientsRetrieve: {
      providesTags: ["Client"],
    },

    questionnaireClientsCreate: {
      invalidatesTags: ["Clients"],
    },

    questionnaireClientsPartialUpdate: {
      // for now partial update is used only for update last_reviewed date, so just update client list
      invalidatesTags: ["Clients"],
    },

    questionnaireClientsUpdate: {
      invalidatesTags: ["Clients", "Client"],
    },

    questionnaireClientsDestroy: {
      invalidatesTags: ["Clients"],
    },

    questionnairePlanCreate: {
      invalidatesTags: ["Clients", "Client"],
    },

    commonAuthSelfRetrieve: {
      providesTags: ["User"],
    },

    commonAuthSelfUpdate: {
      invalidatesTags: ["User"],
    },

    commonAuthSelfPartialUpdate: {
      invalidatesTags: ["User"],
    },

    commonAuthSelfFirmRetrieve: {
      providesTags: ["Firm"],
    },

    commonAuthSelfFirmUpdate: {
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const applyPatch = (item: CommonAuthSelfFirmRetrieveApiResponse) => {
          return dispatch(
            api.util.updateQueryData(
              "commonAuthSelfFirmRetrieve",
              undefined,
              (draft) => {
                return { ...item, logo: item.logo ?? draft.logo };
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.firm);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    questionnaireExternalListAvailableIntegratorsList: {
      providesTags: ["AvailableIntegrators"],
    },

    questionnaireExternalBlackDiamondConnectionDestroy: {
      invalidatesTags: ["AvailableIntegrators"],
    },

    questionnaireExternalBlackDiamondConnectionCreate: {
      invalidatesTags: ["AvailableIntegrators"],
    },

    alEnginePlanResultPublishPlanResultUpdate: {
      invalidatesTags: ["Client"],
    },

    portfolioGetPortfolioModuleDataCreate: {
      providesTags: ["PortfolioGetPortfolioModuleData"],
    },
    portfolioPortfolioGroupVersionModulesDataAllocationsList: {
      providesTags: ["PortfolioGetPortfolioModuleData"],
    },
    portfolioPortfolioGroupVersionOptimizerSettingsList: {
      providesTags: ["PortfolioGroupVersionOptimizerSettings"],
    },
    portfolioPortfolioGroupVersionOptimizerSettingsCreate: {
      invalidatesTags: ["PortfolioGroupVersionOptimizerSettings"],
    },
    portfolioPortfolioGroupVersionOptimizerSettingsUpdate: {
      onQueryStarted: async (
        { portfolioGroupVersionId, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: PortfolioPortfolioGroupVersionOptimizerSettingsListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "portfolioPortfolioGroupVersionOptimizerSettingsList",
              { portfolioGroupVersionId },
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(
          patch.portfolioGroupVersionOptimizerSettings,
        );
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    portfolioPortfolioGroupVersionModulesConstraintsUpdate: {
      onQueryStarted: async (
        { portfolioGroupVersionId, moduleId, id, portfolioModuleConstraints },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (item: PortfolioModuleConstraints) => {
          return dispatch(
            api.util.updateQueryData(
              "portfolioPortfolioGroupVersionOptimizerAllConsList",
              { portfolioGroupVersionId },
              (draft) => {
                const constraintItem = draft
                  .find((md) => md.id === moduleId)
                  ?.optimizer_constraints?.find((ct) => ct.id === id);

                if (constraintItem) {
                  constraintItem.value = item.value;
                  constraintItem.percentile = item.percentile;
                  constraintItem.enabled = item.enabled;
                }
              },
            ),
          );
        };
        const patchResult = applyPatch(portfolioModuleConstraints);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },
    portfolioPortfolioGroupVersionModulesConstraintsAcUpdate: {
      onQueryStarted: async (
        { portfolioGroupVersionId, moduleId, id, portfolioModuleConstraintsAc },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (item: PortfolioModuleConstraintsAc) => {
          return dispatch(
            api.util.updateQueryData(
              "portfolioPortfolioGroupVersionOptimizerAllConsList",
              { portfolioGroupVersionId },
              (draft) => {
                const constraintAcItem = draft
                  .find((md) => md.id === moduleId)
                  ?.optimizer_asset_class_constraints?.find(
                    (ct) => ct.id === id,
                  );

                if (constraintAcItem) {
                  constraintAcItem.min_allocation = item.min_allocation;
                  constraintAcItem.max_allocation = item.max_allocation;
                }
              },
            ),
          );
        };
        const patchResult = applyPatch(portfolioModuleConstraintsAc);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    portfolioPortfolioGroupVersionOptimizerJobsList: {
      providesTags: ["PortfolioGroupVersionOptimizerJobs"],
    },

    portfolioPortfolioGroupVersionOptimizerJobsCreate: {
      invalidatesTags: ["PortfolioGroupVersionOptimizerJobs"],
    },

    portfolioPortfolioGroupVersionOptimizerJobsPartialUpdate: {
      invalidatesTags: ["PortfolioGroupVersionOptimizerJobs"],
    },

    portfolioPortfolioGroupVersionOptimizerJobsDestroy: {
      invalidatesTags: ["PortfolioGroupVersionOptimizerJobs"],
    },

    portfolioRunDynamicOptimizationsCreate: {
      invalidatesTags: ["PortfolioGroupVersionOptimizerJobs"],
    },

    portfolioOptimizerTemplatesList: {
      providesTags: ["PortfolioOptimizerTemplates"],
    },

    portfolioOptimizerTemplatesCreate: {
      invalidatesTags: ["PortfolioOptimizerTemplates"],
    },

    portfolioOptimizerTemplatesPartialUpdate: {
      invalidatesTags: ["PortfolioOptimizerTemplates"],
    },

    portfolioOptimizerTemplatesDestroy: {
      invalidatesTags: ["PortfolioOptimizerTemplates"],
    },

    portfolioOptimizerTemplatesLoadTemplateUpdate: {
      invalidatesTags: [
        "PortfolioGroupVersionOptimizerAllCons",
        "PortfolioGroupVersionOptimizerSettings",
      ],
    },

    portfolioOptimizerTemplatesLoadJobUpdate: {
      invalidatesTags: [
        "PortfolioGroupVersionOptimizerAllCons",
        "PortfolioGroupVersionOptimizerSettings",
      ],
    },

    notificationsNotificationSettingUpdate: {
      onQueryStarted: async (
        { id, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: NotificationsNotificationSettingListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "notificationsNotificationSettingList",
              undefined,
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.notificationSetting);

        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    notificationsNotificationTierThresholdUpdate: {
      onQueryStarted: async (
        { id, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: NotificationsNotificationTierThresholdListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "notificationsNotificationTierThresholdList",
              undefined,
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.notificationTierThreshold);

        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    notificationsNotificationGetReadNotificationsList: {
      providesTags: ["ReadNotifications"],
    },

    notificationsNotificationUpdate: {
      invalidatesTags: ["ReadNotifications"],
      onQueryStarted: async (
        { id, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: NotificationsNotificationListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "notificationsNotificationList",
              undefined,
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.notification);

        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    commonFirmMembersList: {
      providesTags: ["FirmMembers"],
    },
    commonFirmMembersDestroy: {
      invalidatesTags: ["FirmMembers"],
    },
    commonFirmMembersUpdate: {
      onQueryStarted: async (
        { id, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (item: CommonFirmMembersListApiResponse[number]) => {
          return dispatch(
            api.util.updateQueryData(
              "commonFirmMembersList",
              undefined,
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };

        const patchResult = applyPatch(patch.firmMember);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },
    commonBillingBillingCheckStatusCreate: {
      providesTags: ["BillingCheckStatus"],
    },
    commonInvitationsList: {
      providesTags: ["Invitations"],
    },
    commonInvitationsDestroy: {
      invalidatesTags: ["Invitations"],
    },
    commonInvitationsCreate: {
      invalidatesTags: ["Invitations"],
    },
    commonInvitationsUpdate: {
      onQueryStarted: async (
        { id, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (item: CommonInvitationsListApiResponse[number]) => {
          return dispatch(
            api.util.updateQueryData(
              "commonInvitationsList",
              undefined,
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };

        const patchResult = applyPatch(patch.invitation);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    assetClassesFirmAssetClassImplementationUpdate: {
      onQueryStarted: async (
        { id, ...patch },
        { dispatch, queryFulfilled },
      ) => {
        const applyPatch = (
          item: AssetClassesFirmAssetClassImplementationListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "assetClassesFirmAssetClassImplementationList",
              undefined,
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.firmAssetClassImplementation);

        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },
    assetClassesSimulationBehaviorEquityUpdate: {
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const applyPatch = (
          item: AssetClassesSimulationBehaviorEquityListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "assetClassesSimulationBehaviorEquityList",
              { id: "" }, // todo fix in swagger
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.simulationBehaviorEquity);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    assetClassesSimulationBehaviorCashUpdate: {
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const applyPatch = (
          item: AssetClassesSimulationBehaviorCashListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "assetClassesSimulationBehaviorCashList",
              { id: "" }, // todo fix in swagger
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(patch.simulationBehaviorCash);
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },
    assetClassesSimulationBehaviorFixedIncomeSpreadUpdate: {
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const applyPatch = (
          item: AssetClassesSimulationBehaviorFixedIncomeSpreadListApiResponse[number],
        ) => {
          return dispatch(
            api.util.updateQueryData(
              "assetClassesSimulationBehaviorFixedIncomeSpreadList",
              { id: "" }, // todo fix in swagger
              (draft) => {
                return draft.map((i) => (i.id === item.id ? item : i));
              },
            ),
          );
        };
        const patchResult = applyPatch(
          patch.simulationBehaviorFixedIncomeSpread,
        );
        try {
          const res = await queryFulfilled;
          applyPatch(res.data);
        } catch {
          patchResult.undo();
        }
      },
    },

    commonCmpasModuleHierarchyList: {
      providesTags: ["ModuleHierarchy"],
    },

    commonRolesList: {
      providesTags: ["Role"],
    },
    commonRolesRetrieve: {
      providesTags: ["Role"],
    },
    commonRolesCreate: {
      invalidatesTags: ["Role"],
    },
    commonRolesUpdate: {
      invalidatesTags: ["Role"],
    },

    commonCmpasModuleList: {
      providesTags: ["Module"],
    },
    commonCmpasModuleRetrieve: {
      providesTags: ["Module"],
    },
    commonCmpasModuleCreate: {
      invalidatesTags: ["ModuleHierarchy", "Module"],
    },
    commonCmpasModuleUpdate: {
      invalidatesTags: ["ModuleHierarchy", "Module"],
    },

    commonCmpasModuleSectionList: {
      providesTags: ["ModuleSection"],
    },
    commonCmpasModuleSectionRetrieve: {
      providesTags: ["ModuleSection"],
    },
    commonCmpasModuleSectionCreate: {
      invalidatesTags: ["ModuleHierarchy", "ModuleSection"],
    },
    commonCmpasModuleSectionUpdate: {
      invalidatesTags: ["ModuleHierarchy", "ModuleSection"],
    },

    commonPageSectionList: {
      providesTags: ["PageSection"],
    },
    commonPageSectionCreate: {
      invalidatesTags: ["ModuleHierarchy", "PageSection"],
    },
    commonPageSectionUpdate: {
      invalidatesTags: ["ModuleHierarchy", "PageSection"],
    },

    commonPageSelectionBlockList: {
      providesTags: ["PageSelectionBlock"],
    },
    commonPageSelectionBlockCreate: {
      invalidatesTags: ["ModuleHierarchy", "PageSelectionBlock"],
    },
    commonPageSelectionBlockUpdate: {
      invalidatesTags: ["ModuleHierarchy", "PageSelectionBlock"],
    },

    simulationSimulationSettingList: {
      providesTags: ["SimulationSetting"],
    },
    simulationSimulationSettingRetrieve: {
      providesTags: ["SimulationSetting"],
    },
    simulationSimulationSettingUpdate: {
      invalidatesTags: ["SimulationSetting"],
    },

    simulationSettingInflationExpectedMeanList: {
      providesTags: ["SimulationSettingInflationExpectedMean"],
    },
    simulationSettingInflationExpectedMeanRetrieve: {
      providesTags: ["SimulationSettingInflationExpectedMean"],
    },
    simulationSettingInflationExpectedMeanCreate: {
      invalidatesTags: ["SimulationSettingInflationExpectedMean"],
    },
    simulationSettingInflationExpectedMeanUpdate: {
      invalidatesTags: ["SimulationSettingInflationExpectedMean"],
    },
    simulationSettingInflationExpectedMeanPartialUpdate: {
      invalidatesTags: ["SimulationSettingInflationExpectedMean"],
    },
  },
});
