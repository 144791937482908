import clsx from "clsx";
import { PageContent } from "api/carApi.generated";
import { CarMarkdown } from "./Markdown";
import { Box, BoxProps, Collapse, IconButton, styled } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { CarFlatPageControlTab } from "./FlatPageControl";
import { useExpandSingle } from "features/general/useExpand";
import { ExpandMore } from "@mui/icons-material";

export const todoContent = {
  id: "TODO",
  code: "TODO",
  page_contents: [
    {
      page_section: "TODO",
      block_type: {
        id: "TODO",
        code: "TODO",
        display_label: "Overview",
      },
      content: "TODO",
      sort_order: 0,
    },
    {
      page_section: "TODO1",
      block_type: {
        id: "TODO1",
        code: "TODO1",
        display_label: "Functionality",
      },
      content: "TODO",
      sort_order: 1,
    },
  ],
};

const StyledMarkdown = styled(CarMarkdown)(({ theme }) => ({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.palette.gray1,
  borderColor: theme.palette.gray3,
  borderStyle: "solid",
  borderWidth: "1px",
  "&.white-context": {
    backgroundColor: theme.palette.white,
  },
  "&.hide-border": {
    borderWidth: 0,
  },
}));

const StyledFlatPageControlTab = styled(CarFlatPageControlTab)(({ theme }) => ({
  padding: theme.spacing(0.5),
  fontSize: "16px",
}));

interface CarPageContentProps {
  className?: string;
  sx?: BoxProps["sx"];
  content?: PageContent;
  isWhiteContext?: boolean;
  hideBorder?: boolean;
}

export const CarPageContent = ({
  content,
  isWhiteContext,
  hideBorder,
  ...props
}: CarPageContentProps) => {
  const [blockId, setBlockId] = useState<string | undefined>();

  const expand = useExpandSingle(
    `CarPageContent-${content?.id}-expanded`,
    true,
  );

  const blockItems = useMemo(
    () =>
      content?.page_contents
        ?.filter((i) => !!i.content)
        .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0)) ?? [],
    [content],
  );

  useEffect(() => {
    if (!blockId) {
      setBlockId(blockItems[0]?.block_type?.id);
    }
  }, [blockId, blockItems]);

  return blockItems.length ? (
    <Box
      sx={{ display: "flex", flexDirection: "column", ...props.sx }}
      className={props.className}
    >
      <Box
        sx={{
          ml: 1,
          display: "flex",
          justifyContent: "start",
          marginLeft: 0,
          gap: 3,
        }}
      >
        {blockItems.map((i, idx) => (
          <StyledFlatPageControlTab
            key={idx}
            className={clsx({
              selected: expand.expanded && blockId === i.block_type?.id,
            })}
            onClick={(e) => {
              setBlockId(i.block_type?.id);
              expand.onChange(e, true);
            }}
          >
            {i.block_type?.display_label ?? ""}
          </StyledFlatPageControlTab>
        ))}
        <IconButton
          size="small"
          sx={{
            ml: expand.expanded ? "auto" : -2,
            color: "softBlack",
            mr: 0.5,
          }}
          onClick={(e) => expand.onChange(e, !expand.expanded)}
        >
          <ExpandMore
            fontSize="small"
            sx={{
              transform: `rotate(${expand.expanded ? 180 : 0}deg)`,
              transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            }}
          />
        </IconButton>
      </Box>
      <Collapse in={expand.expanded}>
        <StyledMarkdown
          className={clsx({
            "white-context": isWhiteContext,
            "hide-border": hideBorder,
          })}
        >
          {blockItems.find((i) => i.block_type?.id === blockId)?.content}
        </StyledMarkdown>
      </Collapse>
    </Box>
  ) : (
    <></>
  );
};
