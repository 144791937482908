import { useMemo } from "react";
import { useAlEnginePlanResultTypeListQuery } from "api/carApi.generated";

export const usePlanTypes = () => {
  const { data, isLoading } = useAlEnginePlanResultTypeListQuery();

  return useMemo(
    () => ({
      items:
        data
          ?.filter((i) => i.show_in_list)
          .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0)) ?? [],
      getPlanTypeLabel: (id?: string) =>
        data?.find((i) => i.id === id)?.label ?? "",
      getPlanTypeIdByCode: (code?: string) =>
        data?.find((i) => i.code === code)?.id,
      isLoading,
    }),
    [data, isLoading]
  );
};
