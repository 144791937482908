import clsx from "clsx";
import { Box } from "@mui/system";
import { Typography, styled } from "@mui/material";
import { RootPageBox } from "components/RootPage";
import { CarIconRename, CarIconView, CarIconEdit, CarIconDelete } from "icons";
import { LocalPrintshopOutlined } from "@mui/icons-material";
import { CarMenuButton } from "components/MenuButton";
import { UseClientSummary } from "./useClientSummary";
import { formatDate, isOddEven } from "utils";
import { planStatusItems } from "const";
import { AddRowButton } from "components/Buttons";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

const Header = () => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 0.6fr",
      borderBottom: "solid 1px",
      borderColor: "gray6",
      pb: 1.5,
      mb: 1,
    }}
  >
    <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Name of Plan
    </Typography>
    <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Status
    </Typography>
    <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Strategy
    </Typography>
    <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Last Viewed
    </Typography>
    <Typography
      variant="par01Regular"
      textAlign="center"
      sx={{ fontSize: "13px", color: "gray6" }}
    >
      Actions
    </Typography>
  </Box>
);
const Row = styled(Box)(({ theme }) => ({
  height: 62,
  border: "solid 1px",
  borderColor: theme.palette.gray6,
  backgroundColor: theme.palette.white,
  "&.odd": {
    backgroundColor: theme.palette.gray2,
  },
  cursor: "pointer",
  borderRadius: "5px",
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr 1fr 0.6fr",
  alignItems: "center",
}));

interface ClientSummaryPlansProps {
  clientSummary: UseClientSummary;
}

export const ClientSummaryPlans = ({
  clientSummary,
}: ClientSummaryPlansProps) => (
  <RootPageBox sx={{ p: 3.5, flexGrowth: "auto" }}>
    <Box sx={{ display: "flex", alignItems: "flex-start", mb: 3 }}>
      <Typography variant="h5SSemiBold" color="softBlack">
        Client Plans
      </Typography>
      <CarTooltipBox
        sx={{ ml: 0.25, mt: 0.25 }}
        className={pendoClasses.clientSummaryClientPlans}
      />
      <AddRowButton
        sx={{
          ml: "auto",
          mt: -1,
        }}
        onClick={clientSummary.addDraftPlan}
      >
        Add Draft
      </AddRowButton>
    </Box>
    <TablePlaceHolder isLoading={clientSummary.isLoading} rows={2}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        <Header />
        {clientSummary.plans.map((row, idx, arr) => {
          const isDraft = row.status === "DRAFT";
          const isOnlyPlan = arr.length <= 1;
          return (
            <Row
              key={row.id}
              className={clsx({
                odd: isOddEven(idx),
              })}
              onClick={() =>
                clientSummary.navigateToPlan({
                  planId: row.id,
                  isDraft,
                  planTypeId: row.published_data?.published_plan_type_id,
                })
              }
            >
              <Typography variant="par02Regular" sx={{ textAlign: "center" }}>
                {row.name}
              </Typography>
              <Typography variant="par02Regular" sx={{ textAlign: "center" }}>
                {planStatusItems.find((i) => i.value === row.status)?.label ??
                  "-"}
              </Typography>
              <Typography variant="par02Regular" sx={{ textAlign: "center" }}>
                {clientSummary.getPlanTypeLabel(
                  row.published_data?.published_plan_type_id,
                ) || "-"}
              </Typography>
              <Typography variant="par02Regular" sx={{ textAlign: "center" }}>
                {formatDate(row.created_at)}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CarMenuButton
                  items={[
                    {
                      label: "Edit",
                      icon: <CarIconEdit />,
                      onClick: () =>
                        clientSummary.navigateToPlan({
                          planId: row.id,
                          isDraft: true,
                        }),
                      hidden: !isDraft,
                    },
                    {
                      label: "View",
                      icon: <CarIconView />,
                      onClick: () =>
                        clientSummary.navigateToPlan({
                          planId: row.id,
                          isDraft: false,
                          planTypeId:
                            row.published_data?.published_plan_type_id,
                        }),
                      hidden: isDraft,
                    },
                    {
                      label: "Rename",
                      icon: <CarIconRename />,
                      onClick: () => clientSummary.renamePlan(row),
                      hidden: false,
                    },
                    {
                      label: "View PDF",
                      icon: <CarIconView />,
                      onClick: () => clientSummary.viewPlanPdf(row.id),
                      hidden: isDraft || !row.is_workbench_accessible,
                    },
                    {
                      label: "Delete",
                      icon: <CarIconDelete />,
                      onClick: () => clientSummary.deletePlan(row),
                      hidden: !isDraft || isOnlyPlan,
                    },
                    {
                      label: "Print Action Report",
                      icon: <LocalPrintshopOutlined />,
                      onClick: () =>
                        clientSummary.viewPlanActionReportPdf(row.id),
                      hidden: isDraft || !row.is_workbench_accessible,
                    },
                  ]}
                  position="left"
                />
              </Box>
            </Row>
          );
        })}
      </Box>
    </TablePlaceHolder>
  </RootPageBox>
);
