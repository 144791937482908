import { useMemo } from "react";
import { useQuestionnaireTaxPurposeListQuery } from "api/carApi.generated";

export const useTaxPurposes = () => {
  const { data, isLoading } = useQuestionnaireTaxPurposeListQuery({
    search: "",
  });

  return useMemo(() => {
    const items = (data ?? [])
      .filter((i) => i.show_on_screen)
      .sort((a, b) => a.sort_order - b.sort_order);

    const getTaxPurposeIdByCode = (code?: string) =>
      items.find((i) => i.code === code)?.id;

    return {
      isLoading,
      items,
      getTaxPurposeIdByCode,
    };
  }, [data, isLoading]);
};
