import { useCallback, useMemo } from "react";

interface NavElementCoordinate {
  x: string;
  y: string;
}

export const getKeyNavProps = (coordinate: NavElementCoordinate) => ({
  "data-key-nav-x": coordinate.x,
  "data-key-nav-y": coordinate.y,
});

interface FocusElementParams {
  parent: HTMLElement;
  current: HTMLElement;
  axis: "x" | "y";
  direction: "prev" | "next";
  coordinate: NavElementCoordinate;
}

const focusElement = ({
  parent,
  axis,
  direction,
  coordinate,
}: FocusElementParams) => {
  const mainAxisElements =
    axis === "x"
      ? parent.querySelectorAll<HTMLElement>(
          `[data-key-nav-x="${coordinate.x}"]`
        )
      : parent.querySelectorAll<HTMLElement>(
          `[data-key-nav-y="${coordinate.y}"]`
        );

  const crossAxisIndex =
    axis === "x"
      ? Array.from(mainAxisElements).findIndex(
          (i) => i.dataset.keyNavY === coordinate.y
        )
      : Array.from(mainAxisElements).findIndex(
          (i) => i.dataset.keyNavX === coordinate.x
        );

  if (crossAxisIndex < 0) {
    console.error(
      "focusElement element not found",
      mainAxisElements,
      crossAxisIndex,
      coordinate
    );
  }

  const focusElement =
    direction === "next"
      ? mainAxisElements[crossAxisIndex + 1]
      : mainAxisElements[crossAxisIndex - 1];

  focusElement?.focus();
};

export const useKeyboardNavigation = () => {
  const ref = useCallback((element: HTMLElement | null) => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.target && e.currentTarget) {
        const current = e.target as HTMLElement;
        const params: Omit<FocusElementParams, "axis" | "direction"> = {
          parent: e.currentTarget as HTMLElement,
          current,
          coordinate: {
            x: current.dataset.keyNavX ?? "",
            y: current.dataset.keyNavY ?? "",
          },
        };

        if (e.key === "ArrowDown" || e.key === "ArrowUp") {
          focusElement({
            ...params,
            axis: "y",
            direction: e.key === "ArrowDown" ? "next" : "prev",
          });
          e.preventDefault();
          e.stopImmediatePropagation();
        } else if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
          focusElement({
            ...params,
            parent: e.currentTarget as HTMLElement,
            current: e.target as HTMLElement,
            axis: "x",
            direction: e.key === "ArrowRight" ? "next" : "prev",
          });
          e.preventDefault();
          e.stopImmediatePropagation();
        }
      }
    };

    let removeHandler = () => {};

    if (element) {
      element.addEventListener("keydown", keyDownHandler, { capture: true });
      removeHandler = () =>
        element.removeEventListener("keydown", keyDownHandler);
    } else {
      removeHandler();
    }
  }, []);

  return useMemo(() => ({ ref }), [ref]);
};
