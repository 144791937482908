import { useMemo, useState } from "react";
import { CarSelectOption } from "components/Inputs";

export enum TimeHorizonType {
  short = "short",
  extended = "extended",
  all = "all",
}

const options: CarSelectOption<TimeHorizonType>[] = [
  {
    label: "Short",
    value: TimeHorizonType.short,
  },
  {
    label: "Extended",
    value: TimeHorizonType.extended,
  },
  {
    label: "All",
    value: TimeHorizonType.all,
  },
];

export const useTimeHorizonSelector = () => {
  const [value, setValue] = useState<TimeHorizonType>(TimeHorizonType.all);

  return useMemo(() => {
    const sliceTimeHorizon = <T>(values: T[]): T[] => {
      if (value === TimeHorizonType.all) {
        return values;
      } else if (value === TimeHorizonType.short) {
        return values.slice(0, 20);
      } else {
        return values.slice(20).filter((i, idx) => idx % 5 === 0);
      }
    };

    return {
      options,
      value,
      onChange: (value?: TimeHorizonType) =>
        setValue(value ?? TimeHorizonType.all),
      sliceTimeHorizon,
    };
  }, [value]);
};
