import { Box, BoxProps, Popper, TextField, styled } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useState } from "react";
import { states } from "const";
import { SelectArrow } from "icons";
import { CarComboItem, CarStateKind } from "types";

export interface CarComboFieldProps {
  className?: string;
  sx?: BoxProps["sx"];
  value?: string;
  onChange: (value?: string) => void;
  required?: boolean;
  onBlur?: () => void;
  label?: string;
  items: Array<CarComboItem>;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  ".MuiOutlinedInput-root": {
    overflow: "hidden",
    flexWrap: "nowrap",
    padding: "0px !important",
    input: {
      padding: "11px 10px !important",
      paddingRight: "0px !important",
      minWidth: 10,
      "&::placeholder": {
        opacity: 0,
      },
    },
  },
}));

const ArrowButton = styled((props: BoxProps) => (
  <Box {...props}>
    <SelectArrow />
  </Box>
))(({ theme }) => ({
  color: theme.palette.gray9,
  display: "flex",
  alignSelf: "stretch",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  // minWidth: 150,
  ".MuiAutocomplete-option": {
    ...theme.typography.par01Regular,
  },
}));

export const CarComboField = ({
  value,
  onChange,
  items,
  required,
  ...props
}: CarComboFieldProps) => {
  const currentItem = items.find((i) => i.value === value);
  const handleChange = (e: unknown, newItem: CarComboItem | null) => {
    onChange(newItem?.value);
  };

  const [open, setOpen] = useState(false);
  return (
    <Autocomplete
      options={items}
      getOptionLabel={(i) => i.label}
      // renderOption={(i) => i.label}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      {...props}
      value={currentItem}
      onChange={handleChange}
      disableClearable
      popupIcon={<SelectArrow />}
      PopperComponent={StyledPopper}
      renderInput={(params) => (
        <StyledTextField
          placeholder="Type to Search" // This is needed to block Chrome autofill
          variant="outlined"
          label={props.label}
          error={required && !value}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: <ArrowButton onClick={() => setOpen(true)} />,
          }}
        />
      )}
    />
  );
};
export interface CarStateFieldProps
  extends Omit<CarComboFieldProps, "value" | "onChange" | "items"> {
  value?: CarStateKind;
  short?: boolean;
  onChange: (value?: CarStateKind) => void;
}

export const CarStateField = ({
  value,
  short,
  onChange,
  ...props
}: CarStateFieldProps) => (
  <CarComboField
    {...props}
    value={value}
    items={states.map((i) => ({ ...i, label: i.value }))}
    onChange={(value) => onChange(value ? (value as CarStateKind) : undefined)}
    // SelectProps={{ renderValue: short ? (value: any) => value : undefined }}
  />
);
