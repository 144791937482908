import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Search } from "@mui/icons-material";
import { Box, Collapse, Divider, styled } from "@mui/material";
import { CarRadioGroup } from "components/Radio";
import { CarButton, CarLoadingButton } from "components/Buttons";
import { FoundPortfolios } from "./FoundPortfolios";
import { FoundAccounts } from "./FoundAccounts";
import { useBlackDiamondSearch } from "../useBlackDiamondSearch";
import { useLocalStorage } from "features/general/useLocalStorage";

const SearchField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.white,
    paddingLeft: 0,
    width: 260,
    height: 43,
    "& input": {
      paddingLeft: 0,
      ...theme.typography.par02Regular,
      color: theme.palette.softBlack,
    },
    ".icon": {
      color: theme.palette.gray7,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

export const BlackDiamondSearch = () => {
  const [text, setText] = useState("");

  const [filter, setFilter] = useLocalStorage(
    "BlackDiamondSearch-filter",
    "portfolios"
  );

  const [checkedAccounts, setCheckedAccounts] = useState<Array<string>>([]);

  const data = useBlackDiamondSearch();

  const handleSelectClick = () => {
    if (data.showPortfolios) {
      data.handleSelect();
    } else {
      data.handleLink(checkedAccounts);
    }
  };

  const handleSearch = () => data.handleSearch(text, filter === "accounts");

  const showBackButton =
    data.showPortfolios || (data.showAccounts && data.selectedPortfolioId);
  const backIsDisabled = !data.showAccounts || !data.selectedPortfolioId;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <SearchField
          variant="outlined"
          autoFocus
          placeholder="Search (integration portfolios)"
          InputProps={{
            startAdornment: <Search className="icon" />,
          }}
          value={text}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          onChange={(e) => setText(e.target.value)}
        />
        <CarLoadingButton isLoading={data.isSearching} onClick={handleSearch}>
          Search
        </CarLoadingButton>

        <CarRadioGroup
          sx={{
            ml: 1,
            ".MuiFormGroup-root": {
              gap: 6,
            },
          }}
          value={filter}
          row
          onChange={(e, value) => {
            setFilter(value);
          }}
          items={[
            { value: "portfolios", label: "Portfolio" },
            { value: "accounts", label: "Account" },
          ]}
        />
      </Box>
      <Divider
        sx={{
          mt: 1.5,
          mb: 2,
        }}
      />
      <Collapse in={data.showAccounts || data.showPortfolios}>
        <Collapse in={data.showPortfolios}>
          <FoundPortfolios
            label="Found Portfolios:"
            isLoading={data.isPortfoliosLoading}
            value={data.selectedPortfolioId}
            onChange={data.handleSelectedPortfolioIdChange}
            items={data.portfolios.map((i) => ({
              label: i.name,
              value: i.external_portfolio_id,
            }))}
          />
        </Collapse>
        <Collapse in={data.showAccounts}>
          <FoundAccounts
            isLoading={
              data.isAccountsLoading || data.isPortfolioAccountsLoading
            }
            items={data.accounts.map((i) => ({
              label: i.name,
              value: i.external_account_id,
            }))}
            portfolioName={
              data.portfolios.find(
                (i) => i.external_portfolio_id === data.selectedPortfolioId
              )?.name
            }
            checkedItems={checkedAccounts}
            onChange={setCheckedAccounts}
          />
        </Collapse>
        <Box sx={{ mt: 3, display: "flex", gap: 2, justifyContent: "center" }}>
          {showBackButton && (
            <CarButton
              disabled={backIsDisabled}
              onClick={data.handleBack}
              variant="outlined"
            >
              Back
            </CarButton>
          )}
          <CarLoadingButton
            onClick={handleSelectClick}
            isLoading={data.isSelecting || data.linkIsLoading}
          >
            Select
          </CarLoadingButton>
        </Box>
      </Collapse>
    </Box>
  );
};
