import { Box, Typography } from "@mui/material";
import * as icons from "./icons";

export const DebugIcons = () => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        rowGap: 3,
        backgroundColor: "gray1",
        maxWidth: 700,
        my: 2,
        p: 2,
        mx: "auto",
      }}
    >
      {Object.entries(icons).map(([key, Value]) => (
        <Box
          sx={{
            color: "primary.main",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box
            sx={{
              border: "solid 1px",
              borderColor: "gray4",
              display: "flex",
              color: "softBlack",
            }}
          >
            <Value />
          </Box>
          <Typography variant="par01Regular">{key}</Typography>
        </Box>
      ))}
    </Box>
  );
};
