import { BoxProps, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RootPageBox } from "components/RootPage";
import { CarSelectField, CarTextField } from "components/Inputs";
import { useEffect, useState } from "react";
import {
  PortfolioGroup,
  usePortfolioPortfolioGroupCreateMutation,
  usePortfolioPortfolioGroupVersionModulesCreateMutation,
  useSimulationSimulationJobLatestSimulationRetrieveQuery,
} from "api/carApi.generated";
import { CarLoadingButton } from "components/Buttons";
import { pendoClasses } from "app/thirdParty/pendo";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useSimulationGroups } from "pages/assetAllocation/useSimulationGroups";
import { v4 } from "uuid";
import { CarTooltipBox } from "components/TooltipBox";

interface AddPortfolioModelProps {
  sx?: BoxProps["sx"];
  onPortfolioGroupCreated: (value: PortfolioGroup) => void;
}

export const AddPortfolioModel = ({
  sx,
  onPortfolioGroupCreated,
}: AddPortfolioModelProps) => {
  const simulationGroups = useSimulationGroups();
  const latestSimulation =
    useSimulationSimulationJobLatestSimulationRetrieveQuery();

  const [portfolioGroup, setPortfolioGroup] = useState<PortfolioGroup>({
    title: "",
    is_single_portfolio_module: true,
  });

  const [showErrors, setShowErrors] = useState(false);

  const simGroupId = simulationGroups.getSimulationGroupId(
    !!latestSimulation.data?.is_static,
  );

  useEffect(() => {
    if (
      !portfolioGroup.simulation_group &&
      simGroupId &&
      !latestSimulation.isLoading
    ) {
      setPortfolioGroup({
        ...portfolioGroup,
        simulation_group: simGroupId,
      });
    }
  }, [portfolioGroup, simGroupId, latestSimulation]);

  const [createPortfolioGroup] = usePortfolioPortfolioGroupCreateMutation();
  const [createVersionModule] =
    usePortfolioPortfolioGroupVersionModulesCreateMutation();

  const [isLoading, setIsLoading] = useState(false);

  const handleApply = async () => {
    if (isLoading) {
      return;
    }

    setShowErrors(true);

    if (!portfolioGroup.title.trim()) {
      return;
    }

    setIsLoading(true);
    try {
      const resp1 = await createPortfolioGroup({
        portfolioGroup: {
          ...portfolioGroup,
          title: portfolioGroup.title.trim(),
        },
      });

      const portfolioGroupVersionId =
        "data" in resp1 ? resp1.data.versions?.[0].id : undefined;

      if (!portfolioGroupVersionId) {
        return;
      }

      const resp2 = await createVersionModule({
        portfolioGroupVersionId,
        portfolioModule: {
          id: v4(),
          title: portfolioGroup.title.trim(),
          sort_order: 0,
        },
      });

      if ("data" in resp1 && "data" in resp2) {
        onPortfolioGroupCreated(resp1.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RootPageBox sx={{ px: 4, py: 3, ...sx, minWidth: 805 }}>
      <TablePlaceHolder isLoading={simulationGroups.isLoading} rows={1}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            justifyContent: "stretch",
          }}
        >
          <Typography
            variant="h5SSemiBold"
            sx={{ flex: "none", mb: 0.5, mr: -1.5 }}
          >
            Add Portfolio Model -
            <CarTooltipBox
              sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
              className={
                pendoClasses.portfolioAnalysisDashboardAddPortfolioModel
              }
            />
          </Typography>
          <CarTextField
            sx={{ flex: "auto" }}
            value={portfolioGroup.title}
            error={showErrors && !portfolioGroup.title.trim()}
            onChange={(value) =>
              setPortfolioGroup((old) => ({
                ...old,
                title: value ?? "",
              }))
            }
          />
          <CarSelectField
            sx={{
              width: 131,
            }}
            value={portfolioGroup.simulation_group}
            onChange={(simulation_group) =>
              setPortfolioGroup({ ...portfolioGroup, simulation_group })
            }
            fullWidth
          >
            {simulationGroups.options.map((i) => (
              <MenuItem key={i.value} value={i.value}>
                {i.label}
              </MenuItem>
            ))}
          </CarSelectField>
          <CarLoadingButton
            className={pendoClasses.portfolioGroupNameAddButton}
            sx={{ alignSelf: "center", fontSize: "18px", width: 151 }}
            onClick={handleApply}
            isLoading={isLoading}
          >
            Save
          </CarLoadingButton>
        </Box>
      </TablePlaceHolder>
    </RootPageBox>
  );
};
