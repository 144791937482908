import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarPageContent } from "components/PageContent";
import { CarSwitch } from "components/Switch";
import { PortfolioGroupNewOptimizer } from "pages/assetAllocation/editPortfolioGroup/PortfolioGroupNewOptimizer";
import { SelectOptimizationType } from "./SelectOptimizationType";
import { AddRowButton, CarButton } from "components/Buttons";
import {
  CarIconDelete,
  CarIconRename,
  CarStatusFail,
  CarStatusSuccess,
} from "icons";
import { JobStatus } from "pages/assetAllocation/editPortfolioGroup/JobStatus";
import { formatDate } from "utils";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarMenuButton } from "components/MenuButton";
import { CarAssetAllocationSectionBox } from "../viewPortfolioGroup/AssetAllocationSection";
import { CarCheckboxGroup } from "components/Checkbox";
import { useEditPortfolioGroupOptimization } from "./useEditPortfolioGroupOptimization";

interface OptJobStatus {
  label: string;
  icon: ReactElement;
}

export const EditPortfolioGroupOptimization = () => {
  const pageContent = usePageContent();

  const data = useEditPortfolioGroupOptimization();

  const getStatus = () => {
    const calcStatus = (): OptJobStatus => {
      if (!data.currentOptimizerItem) {
        return {
          label: "Not Running",
          icon: (
            <JobStatus
              completedCount={0}
              failedCount={0}
              jobCount={0}
              hideLabel
            />
          ),
        };
      }

      const labelParams = {
        jobCount: data.currentOptimizerItem.jobs.length,
        completedCount: data.currentOptimizerItem.jobs.filter(
          (i) => !i.isRunning,
        ).length,
        failedCount: data.currentOptimizerItem.jobs.filter((i) => i.isError)
          .length,
        hideLabel: data.currentOptimizerItem.jobs.length === 1,
      };

      if (data.currentOptimizerItem.isRunning) {
        return {
          label: "Running",
          icon: <JobStatus isLarge {...labelParams} />,
        };
      }

      if (data.currentOptimizerItem.allErrors) {
        return {
          label: "Error",
          icon: <CarStatusFail />,
        };
      }

      if (data.currentOptimizerItem.hasErrors) {
        return {
          label: "Error",
          icon: <JobStatus isLarge {...labelParams} />,
        };
      }

      return {
        label: "Completed",
        icon: <CarStatusSuccess />,
      };
    };

    const { label, icon } = calcStatus();

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          alignItems: "center",
        }}
      >
        <Typography variant="par02Regular">
          <Box component="span" sx={{ fontWeight: 600 }}>
            Status:
          </Box>{" "}
          {label}
        </Typography>
        <Box
          sx={{
            width: 100,
            height: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid",
            borderColor: "gray3",
            backgroundColor: "white",
            svg: {
              width: 90,
              height: 90,
            },
          }}
        >
          {icon}
        </Box>
        <CarButton
          variant="outlined"
          sx={{
            width: 140,
            opacity: data.currentOptimizerItem?.jobs.length ? 1 : 0,
          }}
          disabled={!data.canAnalyze}
          onClick={data.handleAnalyze}
        >
          Analyze
        </CarButton>
      </Box>
    );
  };

  return (
    <TablePlaceHolder
      isLoading={data.isLoading || pageContent.isLoading}
      rows={8}
      sx={{ mt: 5 }}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Optimization
      </Typography>
      <CarPageContent
        sx={{ mt: 3 }}
        content={
          data.isSinglePortfolioMode
            ? pageContent.getContent(
                CarPageContentCode.PORTFOLIO_ANALYSIS_OPTIMIZATION,
              )
            : pageContent.getContent(
                CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_OPTIMIZER,
              )
        }
      />
      {data.isSinglePortfolioMode && (
        <CarAssetAllocationSectionBox sx={{ mt: 5 }} label="Optimization Types">
          <Box sx={{ display: "flex" }}>
            <CarCheckboxGroup
              sx={{
                alignSelf: "flex-start",
                ".MuiFormGroup-root": {
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  columnGap: 9,
                  rowGap: 1,
                },
                ".MuiCheckbox-root.MuiCheckbox-colorPrimary": {
                  color: "gray9",
                  "&.Mui-checked": {
                    color: "primary.main",
                  },
                },
                ".MuiTypography-root.MuiFormControlLabel-label": {
                  ml: 1,
                  fontSize: "18px",
                },
              }}
              items={data.optimizerDynamicRules.items.map((i) => ({
                ...i,
                pendoClassName:
                  pendoClasses.editPortfolioCreateModelOptimizationType(i.code),
              }))}
              checkedItems={data.checkedDynamicRules}
              onChange={data.setCheckedDynamicRules}
              showCheckAll
            />

            <CarButton
              sx={{ ml: "auto", mr: 2, width: 180 }}
              disabled={!data.canRerun}
              onClick={data.handleRerun}
            >
              Rerun
            </CarButton>
          </Box>
        </CarAssetAllocationSectionBox>
      )}
      <Box sx={{ mt: 5, display: "flex", alignItems: "flex-end", gap: 4 }}>
        {data.optimizerRuns.items.length > 0 && (
          <SelectOptimizationType
            sx={{ width: 250 }}
            label={
              data.isAdvanced ? (
                <>
                  Load Prior Optimization
                  <CarTooltipBox
                    sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                    className={
                      pendoClasses.editPortfolioOptimizationLoadPreviousOptimization
                    }
                  />
                </>
              ) : (
                <>
                  View Optimization Types
                  <CarTooltipBox
                    sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                    className={
                      pendoClasses.editPortfolioOptimizationViewOptimizationTypes
                    }
                  />
                </>
              )
            }
            items={data.optimizerRuns.items}
            value={data.currentOptimizerId}
            onChange={data.handleCurrentOptimizerIdChange}
          />
        )}
        {data.isAdvanced && (
          <AddRowButton onClick={data.handleAddOptimization}>
            New Optimization
          </AddRowButton>
        )}
        {data.isSinglePortfolioMode && (
          <Box
            sx={{
              ml: "auto",
              mb: 0.75,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography variant="par01Regular">Simple</Typography>
            <CarSwitch
              size="small"
              checked={data.isAdvanced}
              onChange={(e, value) => data.handleSetIsAdvanced(value)}
            />
            <Typography variant="par01Regular">Advanced</Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", mt: 5, alignItems: "center", gap: 1 }}>
        <Typography variant="h5SSemiBold">
          {data.currentOptimizerTitle}
        </Typography>
        {data.currentOptimizerItem && (
          <CarMenuButton
            items={[
              {
                label: "Rename",
                icon: <CarIconRename />,
                onClick: data.handleRenameOptimization,
              },
              {
                label: "Delete",
                icon: <CarIconDelete />,
                onClick: data.handleDeleteOptimization,
              },
            ]}
            position="left"
          />
        )}
        <Typography
          variant="par02Regular"
          sx={{ ml: "auto", opacity: data.currentOptimizerItem ? 1 : 0 }}
        >
          <Box component="span" sx={{ fontWeight: 600 }}>
            Created on:
          </Box>{" "}
          {formatDate(data.currentOptimizerItem?.createdOn)}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          border: "1px solid",
          borderColor: "gray3",
          backgroundColor: "gray1",
          px: 5,
          py: 3,
          flex: "auto",
        }}
      >
        {data.currentOptimizerId && (
          <PortfolioGroupNewOptimizer
            viewMode={!data.isAdvanced}
            status={getStatus()}
            onOptimizationRunFinished={data.handleOptimizationRunFinished}
          />
        )}
      </Box>
    </TablePlaceHolder>
  );
};
