import { Box, Typography } from "@mui/material";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { CarDonut } from "components/Donut";
import { PlanResultsGoalSummaryTable } from "./PlanResultsGoalSummaryTable";
import { formatNumber } from "utils";
import { WorkbenchInflationSmall } from "../WorkbenchInflation";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

interface PlanResultsCardSummaryProps {
  data: UsePlanResults;
}

export const PlanResultsCardSummary = ({
  data,
}: PlanResultsCardSummaryProps) => {
  return (
    <Box
      sx={{
        // m: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        gap: 1.5,
        position: "relative",
      }}
    >
      <WorkbenchInflationSmall
        sx={{ position: "absolute", right: 24, top: 34 }}
      />
      <Box
        sx={{
          mt: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 16,
        }}
      >
        <CarDonut
          needPercent={data.donuts.needs.percentage}
          wantPercent={data.donuts.wants.percentage}
          dreamPercent={data.donuts.dreams.percentage}
        />
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="dis02SBold">
            {formatNumber(data.donuts.score)}
            <Typography
              component="span"
              variant="h1Bold"
              sx={{ color: "gray6", ml: 0.5 }}
            >
              %
            </Typography>
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.25,
            }}
          >
            <Typography variant="subhRegular">Plan Score</Typography>
            <CarTooltipBox
              className={pendoClasses.workbenchPlanResultsPlanScore}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="dis02SBold">
            {`${data.donuts.total.successCount}`}
            <Typography
              component="span"
              variant="h1Bold"
              sx={{ color: "gray6", ml: 0.5 }}
            >
              {`/${data.donuts.total.totalCount}`}
            </Typography>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 0.25,
            }}
          >
            <Typography variant="subhRegular">Pass/Total</Typography>
            <CarTooltipBox
              className={pendoClasses.workbenchPlanResultsPassTotal}
            />
          </Box>
        </Box>
      </Box>
      <PlanResultsGoalSummaryTable
        sx={{
          mt: 6,
          mx: 5,
        }}
        data={data}
      />
    </Box>
  );
};
