import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { appListenerMiddleware } from "app/hooks";
import { RootState } from "app/store";

const settingsKey = "settings";
export interface SettingsState {
  isInflated: boolean;
}

const initialState = (): SettingsState => {
  try {
    const savedValue = localStorage.getItem(settingsKey);
    if (savedValue) {
      return JSON.parse(savedValue);
    }
  } catch (e) {
    console.error("Cannot restore settings", e);
  }

  return {
    isInflated: true,
  };
};

export const settingsSlice = createSlice({
  name: settingsKey,
  initialState,
  reducers: {
    setIsInflated: (state, { payload }: PayloadAction<boolean>) => {
      state.isInflated = payload;
    },
  },
});

export const settingsSliceReducer = settingsSlice.reducer;
export const { setIsInflated } = settingsSlice.actions;
export const selectIsInflated = (state: RootState) => state.settings.isInflated;

appListenerMiddleware.startListening({
  matcher: isAnyOf(setIsInflated),
  effect: (action, listenerApi) => {
    localStorage.setItem(
      settingsKey,
      JSON.stringify(listenerApi.getState().settings)
    );
  },
});
