import { AssetClassLevel, assetClassLevelItems } from "const";
import { caravelColors } from "theme";
import {
  CarPortfolioAssetColumnId,
  CarPortfolioAssetRow,
  CarPortfolioAssetTableData,
} from "pages/assetAllocation/usePortfolioGroupVersionData";
import { CSSProperties, ReactElement } from "react";
import { formatPercent, isDefined } from "utils";
import { CarPdfPage } from "components/PdfPage";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfTypography } from "components/PdfTypography";
import { CarPdfChart } from "components/PdfChart";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTable,
  CarPdfTextCell,
  CarPdfViewCell,
} from "components/PdfTable";

interface ModuleChartItem {
  label: string;
  value: number;
  color: CSSProperties["color"];
}

interface ModuleChartProps {
  rows: CarPortfolioAssetRow[];
  moduleId: string;
  label: string;
  columnId: CarPortfolioAssetColumnId;
}

const ModuleChart = ({ rows, moduleId, label, columnId }: ModuleChartProps) => {
  const data = rows
    .map<ModuleChartItem>((row) => ({
      label: row.name,
      color: row.color,
      value:
        row.modules
          .find((mod) => mod.moduleId === moduleId)
          ?.values.find((val) => val.columnId === columnId)?.allocation ?? 0,
    }))
    .filter((i) => i.value > 0);

  return (
    <CarPdfBox
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: 130,
        height: 138,
        margin: "8px 16px 8px 16px",
      }}
    >
      <CarPdfTypography
        variant="body1"
        style={{ fontSize: 8, marginBottom: 8 }}
      >
        {label}
      </CarPdfTypography>
      <CarPdfChart
        width={120}
        height={120}
        type="pie"
        options={{
          devicePixelRatio: 4,
          responsive: false,
          animation: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          borderColor: "transparent",
        }}
        data={{
          labels: data.map((i) => i.label),
          datasets: [
            {
              label: "data",
              data: data.map((i) => i.value),
              backgroundColor: data.map((i) => i.color),
            },
          ],
        }}
      />
    </CarPdfBox>
  );
};
interface ACPerModuleTableProps {
  tableData: CarPortfolioAssetTableData;
  columnId: CarPortfolioAssetColumnId;
  level: AssetClassLevel;
}

const ACPerModuleTable = ({
  tableData,
  columnId,
  level,
}: ACPerModuleTableProps) => {
  const rows = tableData.assetRows[level];
  let totalRow: CarPortfolioAssetRow | undefined;
  switch (level) {
    case AssetClassLevel.level1:
      totalRow = tableData.assetRows.level1Total;
      break;
    case AssetClassLevel.level2:
      totalRow = tableData.assetRows.level2Total;
      break;
    case AssetClassLevel.level3:
      totalRow = tableData.assetRows.level3Total;
      break;
    case AssetClassLevel.level4:
      totalRow = tableData.assetRows.level4Total;
      break;
    default:
      break;
  }

  return (
    <CarPdfTable
      style={{ marginTop: 15 }}
      colWidths={[3, ...tableData.bands.map((i) => 1)]}
    >
      <CarPdfHeader paddingVertical={10}>
        {[
          <CarPdfHeaderCell></CarPdfHeaderCell>,
          ...tableData.bands.map((band, idx, arr) => (
            <CarPdfHeaderCell key={band.moduleId}>
              {band.label}
            </CarPdfHeaderCell>
          )),
        ]}
      </CarPdfHeader>

      {[...rows, totalRow].filter(isDefined).map((i) => (
        <CarPdfRow key={i.id} paddingVertical={10}>
          {[
            <CarPdfViewCell
              style={{
                paddingHorizontal: 5,
              }}
            >
              <CarPdfBox
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: i.color,
                  marginRight: 4,
                }}
              />
              <CarPdfTypography>{i.name}</CarPdfTypography>
            </CarPdfViewCell>,
            ...i.modules.map((mod) => {
              const value = mod.values.find((val) => val.columnId === columnId)
                ?.allocation;
              return (
                <CarPdfTextCell key={mod.moduleId}>
                  {isDefined(value) ? formatPercent(value * 100, 1) : "-"}
                </CarPdfTextCell>
              );
            }),
          ]}
        </CarPdfRow>
      ))}
    </CarPdfTable>
  );
};

interface AСPerModulePdfPageProps {
  title?: ReactElement;
  level: AssetClassLevel;
  tableData: CarPortfolioAssetTableData;
  columnId: CarPortfolioAssetColumnId;
}

export const AСPerModulePdfPage = (props: AСPerModulePdfPageProps) => {
  const rows = props.tableData.assetRows[props.level];

  return (
    <CarPdfPage
      style={{
        paddingTop: 35,
        paddingBottom: 35,
      }}
      hideStripe
      footerBottom={10}
      footerDisclosures=""
    >
      {props.title}
      <CarPdfBox style={{ marginTop: 16 }}>
        <CarPdfTypography variant="body1">
          Asset Allocation per Module -{" "}
          {assetClassLevelItems.find((i) => i.value === props.level)?.label ??
            props.level}
        </CarPdfTypography>
        <CarPdfTypography
          variant="internalUse"
          style={{
            textAlign: "right",
            color: caravelColors.softBlack,
          }}
        >
          Internal Use Only
        </CarPdfTypography>
        <CarPdfBox
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            width: 520,
            marginTop: 20,
          }}
        >
          {props.tableData.bands.map((band, idx) => (
            <ModuleChart
              key={band.moduleId}
              columnId={props.columnId}
              moduleId={band.moduleId}
              label={band.label}
              rows={rows}
            />
          ))}
        </CarPdfBox>
      </CarPdfBox>
      <ACPerModuleTable
        columnId={props.columnId}
        level={props.level}
        tableData={props.tableData}
      />
    </CarPdfPage>
  );
};
