import { Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo, useEffect, useState } from "react";
import { CarSelectOptionField } from "components/Inputs";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { CarButton, CarLoadingPromiseButton } from "components/Buttons";
import {
  api,
  useSimulationSimulationJobAdminCreateMutation,
  useSimulationSimulationJobAdminListQuery,
} from "api/carApi.generated";
import { useSimulationGroups } from "pages/assetAllocation/useSimulationGroups";
import { useDialog } from "app/useDialog";
import { SimulationSettingsDialog } from "./SimulationSettingsDialog";
import { getTimeoutPromise } from "utils";
import { InProgressDialog } from "components/InProgressDialog";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";

export const SimulationRun = memo(() => {
  const dialog = useDialog();
  const [simulationGroupId, setSimulationGroupId] = useState<
    string | undefined
  >();
  const simulationGroups = useSimulationGroups();

  useEffect(() => {
    if (!simulationGroupId && simulationGroups.defOptionValue) {
      setSimulationGroupId(simulationGroups.defOptionValue);
    }
  }, [simulationGroups.defOptionValue, simulationGroupId]);

  const [runRequest] = useSimulationSimulationJobAdminCreateMutation();

  const [checkQuery] = api.useLazySimulationSimulationJobRetrieveQuery();

  useSimulationSimulationJobAdminListQuery();

  const waitTillJobIsRunning = async (simulationJobId: string) => {
    let isRunning = true;
    while (isRunning) {
      await getTimeoutPromise(1000);

      const checkResponse = await checkQuery({
        id: simulationJobId,
      });

      if ("data" in checkResponse) {
        const status = checkResponse.data?.status;
        if (status === "C" || status === "E") {
          isRunning = false;
        }
      } else {
        isRunning = false;
      }
    }
  };

  const handleLaunch = async () => {
    if (!simulationGroupId) {
      return;
    }

    const runResponse = await runRequest({
      simulationJobCreate: {
        simulation_group_id: simulationGroupId,
      },
    });

    if (!("data" in runResponse)) {
      return;
    }
    const simulationJobId = runResponse.data.id;

    if (!simulationJobId) {
      return;
    }

    dialog(InProgressDialog, {
      message: `The simulation is running.\nPlease wait.`,
      run: () => waitTillJobIsRunning(simulationJobId),
    });
  };

  const handleRunSettings = () => {
    if (simulationGroupId) {
      dialog(SimulationSettingsDialog, { simulationGroupId });
    }
  };

  const pageContent = usePageContent();

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 10 }}
      isLoading={simulationGroups.isLoading || pageContent.isLoading}
      rows={8}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4, mb: 5 }}>
        Run
      </Typography>

      <CarPageContent
        sx={{ mb: 5 }}
        content={pageContent.getContent(
          CarPageContentCode.SIMULATION_ANALYSIS_RUN,
        )}
      />

      <CarInfoTable sx={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
        <CarInfoHeader text="Simulation Group" />
        <CarInfoHeader text="Settings" sx={{ py: 4 }} />
        <CarInfoHeader text="Launch" />
        <CarInfoCell isOdd>
          <CarSelectOptionField
            sx={{ width: 240 }}
            options={simulationGroups.options}
            value={simulationGroupId}
            onChange={setSimulationGroupId}
          />
        </CarInfoCell>
        <CarInfoCell isOdd sx={{ py: 2.5 }}>
          <CarButton variant="outlined" onClick={handleRunSettings}>
            Run Settings
          </CarButton>
        </CarInfoCell>
        <CarInfoCell isOdd>
          <CarLoadingPromiseButton variant="contained" onClick={handleLaunch}>
            Launch
          </CarLoadingPromiseButton>
        </CarInfoCell>
      </CarInfoTable>
    </TablePlaceHolder>
  );
});
