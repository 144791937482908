import { styled } from "@mui/material";
import ReactMarkdown, { Options } from "react-markdown";

const Markdown = (props: Readonly<Options>) => {
  return (
    <ReactMarkdown
      {...props}
      components={{
        a: ({ node, href, children, ...props }) => (
          <a href={href} target="_blank" rel="noopener noreferrer" {...props}>
            {children}
          </a>
        ),
      }}
    />
  );
};

export const CarMarkdown = styled(Markdown)(({ theme }) => ({
  li: {
    color: theme.palette.softBlack,
  },
  h1: {
    margin: 0,
    ...theme.typography.h1SSemiBold,
  },
  h2: {
    margin: 0,
    ...theme.typography.h2SSemiBold,
  },
  h3: {
    margin: 0,
    ...theme.typography.h3SSemiBold,
  },
  h4: {
    margin: 0,
    ...theme.typography.h4SSemiBold,
  },
  h5: {
    margin: 0,
    ...theme.typography.h5SSemiBold,
  },
  h6: {
    margin: 0,
    ...theme.typography.h6SSemiBold,
  },
  hr: {
    margin: 0,
    borderColor: theme.palette.gray3,
  },
  p: {
    margin: 0,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    ...theme.typography.par01Regular,
  },
  a: {
    color: theme.palette.softBlack,
  },
}));
