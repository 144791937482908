import { useCallback, useRef, useState } from "react";
import { v4 } from "uuid";
import {
  usePortfolioPortfolioGroupVersionModulesCreateMutation,
  usePortfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesDestroyMutation,
  usePortfolioPortfolioGroupPartialUpdateMutation,
  usePortfolioPortfolioGroupVersionModulesPartialUpdateMutation,
  usePortfolioRunDynamicOptimizationsCreateMutation,
} from "api/carApi.generated";
import { useConfirm } from "app/useConfirm";
import {
  CarPortfolioAssetValue,
  usePortfolioGroupVersionData,
} from "./usePortfolioGroupVersionData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "./usePortfolioGroupInfo";
import { useLocalStorage } from "features/general/useLocalStorage";
import { checkDefined } from "utils";
import { useDialog } from "app/useDialog";
import {
  PleaseWaitOverlay,
  PleaseWaitOverlayRef,
} from "./editPortfolioGroup/PleaseWaitOverlay";
import { usePortfolioGroupVersionStatTableData } from "./usePortfolioGroupVersionStatTableData";
import { useAssetClassStatistics } from "./editPortfolioGroup/useAssetClassStatistics";

const MAX_MODULE_COUNT = 5;

export const usePortfolioGroupCreateAllocations = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();
  const confirm = useConfirm();
  const dialog = useDialog();

  const [checkedDynamicRules, setCheckedDynamicRules] = useState<string[]>([]);

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;
  const pleaseWaitOverlayRef = useRef<PleaseWaitOverlayRef>();

  // First version - show draft and bench allow editing
  // After publish - show existing and bench read only
  const data = usePortfolioGroupVersionData({
    versionId: portfolioGroupVersionId,
    publishedVersionId: groupInfo.publishedVersionId,
    show: {
      draft: !groupInfo.isPublished,
      benchmark: true,
      existing: groupInfo.isPublished,
      selected: false,
      optimizationIds: [],
    },
    hideCash: groupInfo.isSinglePortfolioMode,
    isBenchmarkEditable: true,
  });

  const versionStatTableData = usePortfolioGroupVersionStatTableData({
    bands: data.tableData.bands,
    skip: data.assetAllocationIsLoading,
  });

  const assetClassStatistics = useAssetClassStatistics({
    simulationGroupId: groupInfo.simulationGroupId,
  });

  const [createVersionModule] =
    usePortfolioPortfolioGroupVersionModulesCreateMutation();

  const [updateVersionModule] =
    usePortfolioPortfolioGroupVersionModulesPartialUpdateMutation();

  const [deleteVersionModule] =
    usePortfolioPortfolioGroupVersionModulesDestroyMutation();

  const [allocUpdate] =
    usePortfolioPortfolioGroupVersionModulesDataAllocationsPartialUpdateMutation();

  const [updatePortfolioGroup] =
    usePortfolioPortfolioGroupPartialUpdateMutation();

  const [runDynamicOptimizations] =
    usePortfolioRunDynamicOptimizationsCreateMutation();

  const [isTreeView, setIsTreeView] = useLocalStorage<boolean>(
    "AddPortfolioGroupAssetAllocation_isTreeView",
    true,
  );

  const handleModuleAdd = async () => {
    const maxModuleIndex = data.tableData.bands.reduce((acc, band) => {
      const labelMatch = /model\s+(\d+)/gim.exec(band.label);

      const num = Number.parseInt(labelMatch?.[1] ?? "", 10) || 0;

      return Math.max(acc, num);
    }, 0);

    return createVersionModule({
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      portfolioModule: {
        id: v4(),
        title: `Model ${maxModuleIndex + 1}`,
        sort_order: data.sortedModuleItems.length,
      },
    });
  };

  const handleModuleRename = async (moduleId: string, newLabel: string) => {
    const moduleItem = data.sortedModuleItems.find((i) => i.id === moduleId);

    if (!moduleItem) {
      return;
    }

    await updateVersionModule({
      id: moduleItem.id ?? "",
      portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
      patchedPortfolioModule: {
        title: newLabel,
      },
    });
  };

  const handleModuleDelete = async (moduleId: string) => {
    await confirm({
      label: "Delete Model",
      message: `Are you sure you want to delete model "${data.sortedModuleItems.find(
        (i) => i.id === moduleId,
      )?.title}"?`,
      applyLabel: "Delete",
      onApplying: async () => {
        await deleteVersionModule({
          portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
          id: moduleId,
        });
        return true;
      },
    });
  };

  const handleModuleMove = async (fromModuleId: string, toModuleId: string) => {
    if (fromModuleId === toModuleId) {
      return;
    }

    const startIndex = data.sortedModuleItems.findIndex(
      (i) => i.id === fromModuleId,
    );
    const endIndex = data.sortedModuleItems.findIndex(
      (i) => i.id === toModuleId,
    );

    const result = Array.from(data.sortedModuleItems);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((i, idx) => {
      if (i.sort_order !== idx) {
        updateVersionModule({
          id: i.id ?? "",
          portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
          patchedPortfolioModule: {
            sort_order: idx,
          },
        });
      }
    });
  };

  const handleAllocationChange = useCallback(
    (value: CarPortfolioAssetValue) => {
      allocUpdate({
        id: value.id,
        dataId: value.dataId,
        moduleId: value.moduleId,
        portfolioGroupVersionId: checkDefined(portfolioGroupVersionId),
        patchedPortfolioModuleDataAllocation: {
          allocation: value.allocation,
        },
      });
    },
    [portfolioGroupVersionId, allocUpdate],
  );

  const canPublish = () =>
    !data.tableData.bands.some((b) => b.columns.some((c) => c.hasError));

  const handlePublish = async (
    onPublished: (successJobIds: string[]) => void,
  ) => {
    if (!canPublish() || !portfolioGroupVersionId) {
      throw new Error("Cannot publish portfolio group");
    }

    dialog(PleaseWaitOverlay, {
      portfolioGroupId,
      onPublished,
      customRef: (value: PleaseWaitOverlayRef | null) => {
        pleaseWaitOverlayRef.current = value ? value : undefined;
      },
    });

    let result = false;

    try {
      const portModuleId = data.tableData.bands.at(0)?.moduleId ?? "";
      const publishResult = await updatePortfolioGroup({
        id: portfolioGroupId,
        patchedPortfolioGroup: {
          do_publish: true,
        },
      });

      result = !("error" in publishResult);

      if (!result) {
        return false;
      }

      if (
        groupInfo.isSinglePortfolioMode &&
        checkedDynamicRules.length &&
        portModuleId
      ) {
        // console.log(
        //   `Calling runDynamicOptimizations('${portModuleId}', ${checkedDynamicRules.join(
        //     ",",
        //   )})`,
        // );
        const dynamicOptimizationsResponse = await runDynamicOptimizations({
          portModuleId,
          processOptimizerDynamicRules: {
            rule_ids: checkedDynamicRules,
          },
        });

        result = !("error" in dynamicOptimizationsResponse);
        // console.log(`runDynamicOptimizations Response`, dynamicOptimizationsResponse);

        if ("data" in dynamicOptimizationsResponse) {
          pleaseWaitOverlayRef.current?.waitAndClose(
            dynamicOptimizationsResponse.data.uuids,
          );
        }
      } else {
        pleaseWaitOverlayRef.current?.close();
        onPublished([]);
      }
    } finally {
      if (!result) {
        pleaseWaitOverlayRef.current?.close();
      }
    }
  };

  return {
    portfolioGroupVersionId,
    portfolioGroupId,

    title: groupInfo.title,
    simulationSuffix: groupInfo.getSimulationSuffix(true),
    simulationGroupName: groupInfo.getSimulationGroupName(),

    isReadOnly: data.isReadOnly,
    isError: data.isError,
    tableData: data.tableData,
    statTableData: versionStatTableData.statTableData,
    assetClassStatisticsItems: assetClassStatistics.items,

    isLoading:
      data.isLoading ||
      versionStatTableData.isLoading ||
      groupInfo.isLoading ||
      assetClassStatistics.isLoading,

    isSinglePortfolioMode: groupInfo.isSinglePortfolioMode,
    isPublished: groupInfo.isPublished,

    checkedDynamicRules,
    setCheckedDynamicRules,

    canAddModule:
      data.sortedModuleItems.filter((i) => !i.is_cash).length <
      MAX_MODULE_COUNT,
    handleModuleAdd,
    handleModuleRename,
    handleModuleMove,
    handleModuleDelete,

    handleAllocationChange,
    handlePublish,
    canPublish,

    isTreeView,
    setIsTreeView,
  };
};
