import { Box, BoxProps, styled } from "@mui/material";
import { CarLoadingButton, CarLoadingPromiseButton } from "components/Buttons";
import { CarCheckbox } from "components/Checkbox";
import { CarTextField } from "components/Inputs";
import ccLogo from "assets/cc_logo.png";

export const LoginButton = styled(CarLoadingPromiseButton)(({ theme }) => ({
  flex: "auto",
  height: 64,
  ...theme.typography.par03SemiBold,
}));

export const PayButton = styled(CarLoadingButton)(({ theme }) => ({
  flex: "auto",
  height: 64,
  ...theme.typography.par03SemiBold,
}));

export const LoginField = styled(CarTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: 18,
    backgroundColor: theme.palette.white,
    "& input": {
      fontSize: 18,
      color: theme.palette.softBlack,
      padding: 14,
      height: 25,
    },
    "& fieldset": {
      borderRadius: "5px",
      fontSize: 18,
      borderWidth: 2,
      borderColor: theme.palette.caravelOrangePrimary,
    },
    ".MuiSvgIcon-root": {
      width: 30,
      height: 30,
      color: theme.palette.gray8,
    },
    "&:hover fieldset": {
      borderColor: `${theme.palette.caravelOrangePrimary} !important`,
    },
    "&.Mui-error:hover fieldset": {
      borderColor: `${theme.palette.red} !important`,
    },
  },
}));

export const LoginCheckbox = styled(CarCheckbox)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
  ".MuiTypography-root": {
    fontWeight: 600,
  },
  svg: {
    color: theme.palette.softBlack,
  },
}));

export const LoginTransparentBox = styled(Box)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.8)",
  border: "1px solid",
  borderColor: theme.palette.gray3,
  boxShadow: "4px 4px 10px 0px #00000026",
  borderRadius: "15px",
  display: "flex",
  flexDirection: "column",
  padding: 53,
  paddingTop: 50,
  minWidth: 531,
  transition: "width 0.2s",
}));

export const LoginLogo = (props: BoxProps) => (
  <Box
    component="img"
    alt=""
    src={ccLogo}
    {...props}
    sx={{ width: 59, mb: 5, ...props.sx }}
  />
);
