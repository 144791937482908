import { createContext, useContext } from "react";

export type ScrollToDestination = "top" | "bottom";

const ScrollContext = createContext((destination: ScrollToDestination) => {});

export const ScrollContextProvider = ScrollContext.Provider;

export const useScrollTo = () => useContext(ScrollContext);

export function scrollTo(
  destination: ScrollToDestination,
  ref: HTMLDivElement
) {
  if (destination === "top") {
    if (ref.scrollTop > 0) {
      window.requestAnimationFrame(() => scrollTo(destination, ref));
      ref.scrollTo(0, Math.floor(ref.scrollTop - ref.scrollTop / 5));
    }
  } else {
    const finalScroll = ref.scrollHeight - ref.clientHeight;
    if (ref.scrollTop < finalScroll) {
      const delta = (finalScroll - ref.scrollTop) / 5;

      if (delta > 1) {
        ref.scrollTo(0, Math.ceil(ref.scrollTop + delta));
        window.requestAnimationFrame(() => scrollTo(destination, ref));
      }
    }
  }
}
