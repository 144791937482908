import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarStressTestData } from "types";
import { formatCurrency, formatPercentFactor } from "utils";
import { CarPdfSubHeading } from "./PdfHeading";
import {
  CarPdfHeader,
  CarPdfTable,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfViewCell,
  CarPdfTextCell,
} from "components/PdfTable";

export interface NetWorthPdfPageProps {
  pageProps: CarPdfPageProps;
  items: Array<CarStressTestData>;
}

export const NetWorthPdfPage = (props: NetWorthPdfPageProps) => (
  <CarPdfPage {...props.pageProps}>
    <CarPdfSubHeading label="Net Worth" />
    <CarPdfTable
      style={{
        marginTop: 15,
      }}
      colWidths={[0.8, 1, 2, 1, 1, 1, 1, 1, 1, 1]}
    >
      <CarPdfHeader paddingVertical={14}>
        <CarPdfHeaderCell />
        <CarPdfHeaderCell>Beginning Total Portfolio</CarPdfHeaderCell>
        <CarPdfHeaderCell>Return on Assets</CarPdfHeaderCell>
        <CarPdfHeaderCell>Savings</CarPdfHeaderCell>
        <CarPdfHeaderCell>Net A/T Cash Flow into Portfolio</CarPdfHeaderCell>
        <CarPdfHeaderCell>Goals Funded</CarPdfHeaderCell>
        <CarPdfHeaderCell>Ending Total Portfolio</CarPdfHeaderCell>
        <CarPdfHeaderCell>Real Estate</CarPdfHeaderCell>
        <CarPdfHeaderCell>Mortgage</CarPdfHeaderCell>
        <CarPdfHeaderCell>Total Net Worth</CarPdfHeaderCell>
      </CarPdfHeader>
      {props.items.map((i, idx) => (
        <CarPdfRow
          key={i.year}
          style={{
            backgroundColor: idx % 2 === 0 ? "white" : "#f3f6f8",
          }}
          textVariant="tableBody3"
          paddingVertical={10}
        >
          <CarPdfTextCell variant="tableBody4">
            {i.year.toString()}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.beginning_total_portfolio_value ?? 0)}
          </CarPdfTextCell>
          <CarPdfViewCell style={{ paddingVertical: 0 }}>
            <CarPdfTextCell
              variant="tableBody3"
              paddingVertical={10}
              style={{
                borderLeftWidth: 0,
                borderBottomWidth: 0,
              }}
            >
              {formatCurrency(i.return_on_asset_dollar ?? 0)}
            </CarPdfTextCell>
            <CarPdfTextCell
              variant="tableBody3"
              paddingVertical={10}
              style={{
                borderBottomWidth: 0,
                borderRightWidth: 0,
              }}
            >
              {formatPercentFactor(i.return_on_asset ?? 0, 1)}
            </CarPdfTextCell>
          </CarPdfViewCell>
          <CarPdfTextCell>
            {formatCurrency(i.net_worth_savings ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.net_worth_cash_flow ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(
              (i.net_worth_goals_met ?? 0) + (i.net_worth_goals_not_met ?? 0),
            )}
          </CarPdfTextCell>
          <CarPdfTextCell>
            {formatCurrency(i.ending_total_portfolio_value ?? 0)}
          </CarPdfTextCell>
          <CarPdfTextCell>{formatCurrency(i.real_estate)}</CarPdfTextCell>
          <CarPdfTextCell>{formatCurrency(i.mortgage)} </CarPdfTextCell>
          <CarPdfTextCell>{formatCurrency(i.total_net_worth)}</CarPdfTextCell>
        </CarPdfRow>
      ))}
    </CarPdfTable>
  </CarPdfPage>
);
