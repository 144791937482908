import { useMemo } from "react";
import {
  SimulationJob,
  useAssetClassesFirmAssetClassesListQuery,
  useSimulationSimulationJobLatestSimulationRetrieveQuery,
  useSimulationSimulationJobRetrieveQuery,
} from "api/carApi.generated";
import { useSimulationGroups } from "pages/assetAllocation/useSimulationGroups";
import { debugLog, isDefined } from "utils";

export interface AssetClass {
  level4Id: string;
  level1Id: string;
  firmAssetClassId: string;
  name: string;
  code: string;
  color: string;
  sort: number;
  isSimplified: boolean;
  isRequired: boolean;
  isEquity: boolean;
  isFixedIncome: boolean;
  isCash: boolean;
  isInflation: boolean;
  isDisabled: boolean;
}
export interface Level1AssetClass {
  id: string;
  name: string;
  sort: number;
}

export enum AssetClassLoadType {
  allFirm = 0,
  bySimulationJobId = 1,
  bySimulationGroupId = 2,
  byLatestSimulationJob = 3,
}

interface UseAssetClassesParams {
  type: AssetClassLoadType;
  simulationJobId?: string;
  simulationGroupId?: string;
  filterByUsableInPortfolios?: boolean;
  filter?: (value: AssetClass) => boolean;
  includeInflationItem?: boolean;
}

export const useAssetClasses = ({
  type,
  simulationJobId: paramsSimulationJobId,
  simulationGroupId: paramsSimulationGroupId,
  filterByUsableInPortfolios,
  filter,
  includeInflationItem,
}: UseAssetClassesParams) => {
  const assetClassList = useAssetClassesFirmAssetClassesListQuery();

  const latestSimulationJob =
    useSimulationSimulationJobLatestSimulationRetrieveQuery(undefined, {
      skip: type !== AssetClassLoadType.byLatestSimulationJob,
    });

  const paramsSimulationJob = useSimulationSimulationJobRetrieveQuery(
    {
      id: paramsSimulationJobId ?? "",
    },
    {
      skip:
        type !== AssetClassLoadType.bySimulationJobId || !paramsSimulationJobId,
    },
  );

  const simulationGroups = useSimulationGroups();

  const isLoading =
    assetClassList.isLoading ||
    paramsSimulationJob.isLoading ||
    latestSimulationJob.isLoading ||
    simulationGroups.isLoading;

  return useMemo(() => {
    let simulationJobItem: SimulationJob | undefined;

    switch (type) {
      case AssetClassLoadType.bySimulationJobId:
        simulationJobItem = paramsSimulationJob.data;
        break;
      case AssetClassLoadType.bySimulationGroupId:
        simulationJobItem = simulationGroups.items.find(
          (i) => i.id === paramsSimulationGroupId,
        )?.latest_simulation;
        break;
      case AssetClassLoadType.byLatestSimulationJob:
        simulationJobItem = latestSimulationJob.data;
        break;
      case AssetClassLoadType.allFirm:
        simulationJobItem = undefined;
        break;
      default:
        throw new Error(`useAssetClasses unknown type: ${type}`);
    }

    const simGroupFilter = simulationGroups.getAssetClass4Filter(
      simulationJobItem?.group,
    );

    const simulationGroup = simulationGroups.items.find(
      (i) => i.id === simulationJobItem?.group,
    );

    if (!isLoading) {
      if (type === AssetClassLoadType.allFirm) {
        debugLog(`useAssetClasses type: ${AssetClassLoadType[type]}`);
      } else if (simulationGroup) {
        debugLog(
          `useAssetClasses type: ${
            AssetClassLoadType[type]
          } ${simulationGroup?.name} ${simulationGroups.getSimulationSuffix(
            simulationGroup?.id,
          )}`,
          {
            simulationJob: simulationJobItem,
            simulationGroup: simulationGroups.items.find(
              (i) => i.id === simulationJobItem?.group,
            ),
          },
        );
      }
    }

    const filteredItems =
      assetClassList.data?.filter(
        (i) =>
          i.is_active &&
          (!filterByUsableInPortfolios ||
            i.asset_class?.useable_in_portfolios) &&
          (simulationGroup ? simGroupFilter(i.asset_class?.id ?? "") : true),
      ) ?? [];

    let items = filteredItems
      .map<AssetClass>((i) => ({
        firmAssetClassId: i.id ?? "",
        level4Id: i.asset_class?.id ?? "",
        name: i.asset_class?.display_name ?? "",
        code: i.asset_class?.code ?? "",
        color: i.asset_class?.color_code ?? "",
        level1Id: i.asset_class?.ac_level3?.ac_level2?.ac_level1?.id ?? "",
        sort:
          (i.asset_class?.ac_level3?.sort_order ?? 0) * 1000 +
          (i.asset_class?.sort_order ?? 0),
        isSimplified: !!i.asset_class?.is_simplified_asset_class,
        isRequired: !!i.asset_class?.is_required,
        isEquity: !!i.asset_class?.ac_level3?.ac_level2?.ac_level1?.is_equity,
        isFixedIncome:
          !!i.asset_class?.ac_level3?.ac_level2?.ac_level1?.is_fixed_inc,
        isCash: !!i.asset_class?.ac_level3?.ac_level2?.ac_level1?.is_cash,
        isInflation: false,
        isDisabled:
          isDefined(simulationJobItem) &&
          !simulationJobItem?.asset_class4s.includes(i.asset_class?.id ?? ""),
      }))
      .sort((a, b) => a.sort - b.sort);

    items = items.filter((ac) => filter?.(ac) ?? true);

    if (includeInflationItem) {
      items = [
        {
          firmAssetClassId: "inflation",
          level4Id: "inflation",
          name: "Inflation",
          code: "inflation",
          color: "",
          sort: -1,
          level1Id: "inflation",
          isSimplified: false,
          isRequired: false,
          isEquity: false,
          isFixedIncome: false,
          isInflation: true,
          isCash: false,
          isDisabled: false,
        },
        ...items,
      ];
    }

    const level1Map = new Map<string, Level1AssetClass>();

    filteredItems.forEach((i) => {
      if (items.some((ac) => ac.level4Id === i.asset_class?.id)) {
        level1Map.set(
          i.asset_class?.ac_level3?.ac_level2?.ac_level1?.id ?? "",
          {
            id: i.asset_class?.ac_level3?.ac_level2?.ac_level1?.id ?? "",
            sort:
              i.asset_class?.ac_level3?.ac_level2?.ac_level1?.sort_order ?? 0,
            name: i.asset_class?.ac_level3?.ac_level2?.ac_level1?.name ?? "",
          },
        );
      }
    });

    const sortedItems = Array.from(items).sort((a, b) => a.sort - b.sort);

    const level1Items = Array.from(level1Map.values()).sort(
      (a, b) => a.sort - b.sort,
    );

    return {
      level1Items,
      items: sortedItems,
      simpleItems: sortedItems.filter((i) => i.isSimplified),
      simulationJobId: simulationJobItem?.id ?? paramsSimulationJobId,
      simulationJobIsStatic: simulationJobItem?.is_static,
      isLoading,
    };
  }, [
    simulationGroups,
    paramsSimulationJob.data,
    latestSimulationJob.data,
    assetClassList.data,
    includeInflationItem,
    filterByUsableInPortfolios,
    paramsSimulationJobId,
    paramsSimulationGroupId,
    type,
    filter,
    isLoading,
  ]);
};
