import { Box, BoxProps, Typography, styled } from "@mui/material";
import {
  formatNumber,
  formatPercent,
  formatPercentFactor,
  isDefined,
  isOddEven,
} from "utils";
import {
  CarFactorPercentFieldDelayed,
  CarNumberFieldDelayed,
} from "components/NumberField";
import {
  getKeyNavProps,
  useKeyboardNavigation,
} from "app/useKeyboardNavigation";
import {
  CarPortfolioConstrBand,
  CarPortfolioConstrHorizonRow,
  CarPortfolioConstrHorizonValue,
  CarPortfolioConstrRow,
  CarPortfolioConstrTable,
  CarPortfolioConstrValue,
} from "./usePortfolioGroupVersionConstraints";
import { MAX_TOTAL_EQUITY } from "./usePortfolioGroupNewOptimizer";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

const StyledPercentField = styled(CarFactorPercentFieldDelayed)(
  ({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    ".MuiOutlinedInput-root:hover fieldset": {
      borderColor: `${theme.palette.gray7} !important`,
    },
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.gray6,
    },
    ".MuiInputBase-root": {
      backgroundColor: theme.palette.white,
    },
  }),
);

const StyledNumberField = styled(CarNumberFieldDelayed)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  ".MuiOutlinedInput-root:hover fieldset": {
    borderColor: `${theme.palette.gray7} !important`,
  },
  ".MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.gray6,
  },
  ".MuiInputBase-root": {
    backgroundColor: theme.palette.white,
  },
}));

const StyledCellBox = styled(Box)(({ theme }) => ({
  marginLeft: "-1px",

  borderStyle: "solid",
  borderColor: theme.palette.gray6,
  borderTopWidth: 0,
  borderBottomWidth: 1,
  borderLeftWidth: 2,
  borderRightWidth: 2,
  "&:last-of-type": {
    borderRightWidth: 1,
  },

  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledLabelBox = styled(Box)(({ theme }) => ({
  borderStyle: "solid",
  borderWidth: 1,
  borderTopWidth: 0,
  borderColor: theme.palette.gray6,

  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),

  display: "flex",
  justifyContent: "start",
  alignItems: "center",
  overflow: "hidden",
}));

const getGridTemplateColumns = (bandCount: number, hidePercentile?: boolean) =>
  `1.5fr ${hidePercentile ? "" : "0.5fr"} repeat(${bandCount}, 1fr)`;

const Header = (props: { bands: CarPortfolioConstrBand[] }) => (
  <Box
    sx={{
      display: "grid",
      gridTemplateColumns: getGridTemplateColumns(props.bands.length),
    }}
  >
    <Box
      sx={{
        border: 1,
        borderColor: "table.border",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "table.background.header",
        borderTopLeftRadius: "5px",
      }}
    />
    <Box
      sx={{
        marginLeft: "-1px",
        border: 1,
        borderColor: "table.border",
        borderLeftWidth: 2,
        borderRightWidth: 1,
        backgroundColor: "table.background.header",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        px: 1,
        py: 2,
        height: 60,
      }}
    >
      <Typography variant="h6SBold">PCTL</Typography>
    </Box>
    {props.bands.map((i, idx, arr) => (
      <Box
        key={i.moduleId}
        sx={{
          marginLeft: "-1px",
          border: 1,
          borderColor: "table.border",
          borderLeftWidth: 2,
          borderRightWidth: 2,
          backgroundColor: "table.background.header",
          "&:last-of-type": {
            borderRightWidth: 1,
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          px: 1,
          py: 2,
          height: 60,
          borderTopRightRadius: idx === arr.length - 1 ? "5px" : undefined,
        }}
      >
        <Typography variant="h6SBold">{i.label}</Typography>
      </Box>
    ))}
  </Box>
);

export interface PortfolioGroupConstrRowProps {
  sx?: BoxProps["sx"];
  item: CarPortfolioConstrRow;
  isGray?: boolean;
  isLastRow?: boolean;
  onConstrValueChange: (value: CarPortfolioConstrValue) => void;
  hidePercentile?: boolean;
  readOnly?: boolean;
}

export const PortfolioGroupConstrRow = (
  props: PortfolioGroupConstrRowProps,
) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        backgroundColor: props.isGray ? "gray2" : "white",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          minHeight: 60,
          gridTemplateColumns: getGridTemplateColumns(
            props.item.values.length,
            props.hidePercentile,
          ),
        }}
      >
        <StyledLabelBox
          sx={{
            py: 1.5,
            borderBottomLeftRadius: props.isLastRow ? "5px" : undefined,
          }}
        >
          <Typography variant="par02Regular">
            {props.item.name}
            <CarTooltipBox
              sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
              className={pendoClasses.editPortfolioOptimizationConstraint(
                props.item.code,
              )}
            />
          </Typography>
        </StyledLabelBox>
        {!props.hidePercentile && (
          <StyledLabelBox sx={{ justifyContent: "center" }}>
            <Typography variant="par02Regular">
              {isDefined(props.item.percentile)
                ? formatPercent(props.item.percentile)
                : "-"}
            </Typography>
          </StyledLabelBox>
        )}
        {props.item.values.map((val, idx, arr) => {
          if (props.readOnly) {
            let strValue = "-";

            if (val.value > 0) {
              strValue =
                props.item.inputType === "PERCENTAGE"
                  ? formatPercentFactor(val.value, { decimalPlaces: 1 })
                  : formatNumber(val.value, {
                      decimalPlaces: props.item.inputType === "FLOAT" ? 1 : 0,
                    });
            }
            return (
              <StyledLabelBox
                key={val.moduleId}
                sx={{ justifyContent: "center" }}
              >
                <Typography variant="par02Regular">{strValue}</Typography>
              </StyledLabelBox>
            );
          }
          return (
            <StyledCellBox
              key={val.moduleId}
              sx={{
                "& > *": {
                  maxWidth: 138,
                },
                borderBottomRightRadius:
                  props.isLastRow && idx === arr.length - 1 ? "5px" : undefined,
              }}
            >
              {props.item.inputType === "PERCENTAGE" ? (
                <StyledPercentField
                  defaultValue={0}
                  decimalPlaces={1}
                  minFactorValue={props.item.minValue}
                  maxFactorValue={props.item.maxValue}
                  inputProps={getKeyNavProps({
                    y: val.moduleId,
                    x: props.item.constraintTypeId,
                  })}
                  value={val.value}
                  onChange={(value) => {
                    props.onConstrValueChange({
                      ...val,
                      value: value ?? 0,
                    });
                  }}
                />
              ) : (
                <StyledNumberField
                  defaultValue={0}
                  decimalPlaces={props.item.inputType === "FLOAT" ? 1 : 0}
                  minValue={props.item.minValue}
                  maxValue={props.item.maxValue}
                  inputProps={getKeyNavProps({
                    y: val.moduleId,
                    x: props.item.constraintTypeId,
                  })}
                  value={val.value}
                  onChange={(value) => {
                    props.onConstrValueChange({
                      ...val,
                      value: value ?? 0,
                    });
                  }}
                />
              )}
            </StyledCellBox>
          );
        })}
      </Box>
    </Box>
  );
};

interface PortfolioGroupConstrRowHorizonProps {
  sx?: BoxProps["sx"];
  item: CarPortfolioConstrHorizonRow;
  isGray?: boolean;
  onConstrHorizonValueChange: (value: CarPortfolioConstrHorizonValue) => void;
  readOnly?: boolean;
}

const PortfolioGroupConstrHorizonRow = (
  props: PortfolioGroupConstrRowHorizonProps,
) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        backgroundColor: props.isGray ? "gray2" : "white",
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "grid",
          minHeight: 60,
          gridTemplateColumns: getGridTemplateColumns(props.item.values.length),
        }}
      >
        <StyledLabelBox
          sx={{
            py: 1.5,
          }}
        >
          <Typography variant="par02Regular">
            {props.item.name}
            <CarTooltipBox
              sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
              className={pendoClasses.editPortfolioOptimizationConstraint(
                "TARGET_BE_HORIZON",
              )}
            />
          </Typography>
        </StyledLabelBox>
        <StyledLabelBox
          sx={{
            justifyContent: "center",
          }}
        >
          <Typography variant="par02Regular">-</Typography>
        </StyledLabelBox>
        {props.item.values.map((val, idx) => {
          if (props.readOnly) {
            return (
              <StyledLabelBox
                key={val.moduleId}
                sx={{ justifyContent: "center" }}
              >
                <Typography variant="par02Regular">
                  {val.value
                    ? formatNumber(val.value, {
                        decimalPlaces: 0,
                      })
                    : "-"}
                </Typography>
              </StyledLabelBox>
            );
          }
          return (
            <StyledCellBox
              key={val.moduleId}
              sx={{
                "& > *": {
                  maxWidth: 138,
                },
              }}
            >
              <StyledNumberField
                defaultValue={0}
                value={val.value}
                minValue={1}
                maxValue={50}
                inputProps={getKeyNavProps({
                  y: val.moduleId,
                  x: "horizon_row",
                })}
                onChange={(value) => {
                  props.onConstrHorizonValueChange({
                    ...val,
                    value: value ?? 0,
                  });
                }}
              />
            </StyledCellBox>
          );
        })}
      </Box>
    </Box>
  );
};

export interface PortfolioGroupConstrTableProps {
  sx?: BoxProps["sx"];
  table: CarPortfolioConstrTable;
  onConstrHorizonValueChange: (value: CarPortfolioConstrHorizonValue) => void;
  onConstrValueChange: (value: CarPortfolioConstrValue) => void;
  readOnly?: boolean;
}

export const PortfolioGroupConstrTable = ({
  table,
  onConstrHorizonValueChange,
  onConstrValueChange,
  sx,
  readOnly,
}: PortfolioGroupConstrTableProps) => {
  const { ref } = useKeyboardNavigation();

  return (
    <Box
      ref={ref}
      sx={{
        display: "grid",
        ".MuiTypography-root": {
          color: "table.text",
        },
        ...sx,
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "table.background.level1",
          zIndex: 10,
        }}
      >
        <Header bands={table.bands} />
      </Box>
      <PortfolioGroupConstrHorizonRow
        item={table.horizonRow}
        isGray={false}
        onConstrHorizonValueChange={onConstrHorizonValueChange}
        readOnly={readOnly}
      />
      {table.rows
        .filter((i) => i.code !== MAX_TOTAL_EQUITY)
        .map((i, idx, arr) => (
          <PortfolioGroupConstrRow
            key={i.constraintTypeId}
            item={i}
            isGray={isOddEven(idx)}
            isLastRow={idx === arr.length - 1}
            onConstrValueChange={onConstrValueChange}
            readOnly={readOnly}
          />
        ))}
    </Box>
  );
};
