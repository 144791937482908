import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import {
  Typography,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  styled,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CarButton } from "components/Buttons";
import { CarTextField } from "components/Inputs";
import { CarSwitch } from "components/Switch";
import { CarUser } from "types";
import { useUser } from "./useUser";
import { fileToBase64 } from "utils/fileUtils";
import { showToast } from "app/toastSlice";
import { CarAvatarField } from "components/AvatarField";
import { useFirm } from "./useFirm";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useRoles } from "app/useRoles";
import { SettingsList } from "./SettingsList";
import { SettingsIntegrations } from "./SettingsIntegrations";

const StyledTextField = styled(CarTextField)(({ theme }) => ({
  ".MuiInputLabel-root": {
    fontSize: "16px",
  },
  ".MuiOutlinedInput-root": {
    backgroundColor: theme.palette.white,
    borderRadius: "5px",
    marginTop: 22,
    "& input": {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      ...theme.typography.par01Regular,
      color: theme.palette.black,
    },
    "& fieldset": {
      borderRadius: "5px",
    },
    ".MuiSvgIcon-root": {
      width: 16,
      height: 16,
      color: theme.palette.softBlack,
    },
  },
}));

export const SettingsUserSettings = () => {
  const data = useUser();
  const firm = useFirm();
  const roles = useRoles();
  const [user, setUser] = useState<CarUser>(data.user);
  const [showErrors, setShowErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setUser(data.user);
  }, [data.user]);

  const handleSave = () => {
    const canUpdateUser =
      !!user.email &&
      !!user.first_name &&
      !!user.last_name &&
      !!user.work_phone_num &&
      !!user.cell_phone_num &&
      !!user.address;

    if (data.isUpdating) {
      return;
    }

    if (!canUpdateUser) {
      setShowErrors(true);
      return;
    }

    data.updateUser(user);
  };

  const handleImageSelected = async (selection: Array<File>) => {
    if (selection.length === 0) {
      return;
    }

    if (selection[0].size > 50 * 1024) {
      dispatch(
        showToast({
          kind: "errorMessage",
          message: "File size should be less than 50KB",
        }),
      );
      return;
    }

    const base64 = await fileToBase64(selection[0]);
    setUser({ ...user, profile_image: base64 });
  };

  const profile = (
    <>
      <Box
        sx={{
          mt: -1,
          display: "flex",
          gap: 5.5,
        }}
      >
        <CarAvatarField
          value={user.profile_image ?? undefined}
          sx={{
            alignSelf: "start",
          }}
          onFileSelected={handleImageSelected}
        />
        <Box
          sx={{ display: "flex", flexDirection: "column", mt: 2.5, gap: 2.5 }}
        >
          <Typography variant="par03Regular">
            <Box component="span" sx={{ fontWeight: 600 }}>
              Company:
            </Box>
            {` ${firm.firm.name}`}
          </Typography>
          <Typography variant="par03Regular">
            <Box component="span" sx={{ fontWeight: 600 }}>
              Role:
            </Box>
            <Box component="span" sx={{ fontStyle: "italic" }}>
              {` ${roles.getRole(user.role)?.name}`}
            </Box>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 5,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          rowGap: 6,
          columnGap: 7,
          alignItems: "start",
          width: 600,
        }}
      >
        <StyledTextField
          required={showErrors}
          value={user.first_name}
          onChange={(first_name) => setUser({ ...user, first_name })}
          label="*First Name"
        />
        <StyledTextField
          required={showErrors}
          value={user.last_name}
          onChange={(last_name) => setUser({ ...user, last_name })}
          label="*Last Name"
        />
        <StyledTextField
          required={showErrors}
          value={user.email}
          onChange={(email) => setUser({ ...user, email })}
          label="*Email"
        />
        <StyledTextField
          required={showErrors}
          value={user.work_phone_num}
          onChange={(work_phone_num) => setUser({ ...user, work_phone_num })}
          label="*Work Phone"
        />
        <StyledTextField
          required={showErrors}
          value={user.cell_phone_num}
          onChange={(cell_phone_num) => setUser({ ...user, cell_phone_num })}
          label="*Cell Phone"
        />
        <StyledTextField
          required={showErrors}
          value={user.address}
          onChange={(address) => setUser({ ...user, address })}
          multiline
          maxRows={6}
          rows={3}
          label="*Address"
        />
      </Box>
    </>
  );

  const security = (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-end", gap: 7 }}>
        <Box
          sx={{
            mt: 5,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: 7,
            alignItems: "start",
            width: 600,
          }}
        >
          <StyledTextField
            value={user.old_password}
            onChange={(old_password) => setUser({ ...user, old_password })}
            label="Old Password"
            inputProps={{
              autoComplete: "new-password",
              type: showOldPassword ? "text" : "password",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ mr: 0.5 }}>
                  <IconButton
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    onMouseDown={() => setShowOldPassword(!showOldPassword)}
                    edge="end"
                    size="small"
                  >
                    {showOldPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            value={user.new_password}
            onChange={(new_password) => setUser({ ...user, new_password })}
            label="New Password"
            inputProps={{
              autoComplete: "new-password",
              type: showPassword ? "text" : "password",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ mr: 0.5 }}>
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    edge="end"
                    size="small"
                  >
                    {showPassword ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <CarButton
          sx={{ mb: -0.25 }}
          onClick={handleSave}
          startIcon={
            data.isUpdating ? (
              <CircularProgress size={24} sx={{ color: "common.white" }} />
            ) : undefined
          }
        >
          Save
        </CarButton>
      </Box>
      <Box sx={{ mt: 6, display: "flex", gap: 1.5 }}>
        <CarSwitch
          size="small"
          checked={data.isTwoFactorEnabled}
          onChange={(e) => data.onTwoFactorEnabledChange(e.target.checked)}
        />
        <Box sx={{ display: "flex", flexDirection: "column", mt: "1px" }}>
          <Typography variant="h6SemiBold">
            Two-Factor Authentication (2FA)
          </Typography>
          <Typography variant="par02Regular" sx={{ mt: 1, maxWidth: 826 }}>
            Caravel is committed to you and your client’s security which is why
            we recommend using a two-factor authentication app to secure your
            account. With an authenticator app you will receive a once-time
            passcode each time you log in to CMPAS.
          </Typography>
        </Box>
      </Box>
    </>
  );

  return (
    <TablePlaceHolder
      sx={{ mt: 5 }}
      isLoading={data.isLoading || firm.isLoading || roles.isLoading}
      rows={8}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        User Settings
      </Typography>
      <SettingsList
        items={[
          {
            label: "Profile",
            description:
              "Personal information that will populate on printed reports.",
            content: profile,
          },
          {
            label: "Security",
            description: "Account security settings",
            content: security,
          },
          {
            label: "Integrations",
            description: "Connect with third-party integrations.",
            content: <SettingsIntegrations />,
          },
        ]}
      />
    </TablePlaceHolder>
  );
};
