import { Box, MenuItem, styled } from "@mui/material";
import {
  CarSelectField,
  CarTextCalcField,
  CarTextFieldDelayed,
} from "components/Inputs";
import {
  CarCurrencyFieldDelayed,
  CarNumberFieldDelayed,
} from "components/NumberField";
import { CarAccount, CarPosition } from "types";
import { CarTable, CarTableColumn } from "components/Table";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { useAccountTypes } from "app/useAccountTypes";
import { AddRowButton } from "components/Buttons";
import { addDetailedHolding } from "features/assets/account.utils";

function blankIfNotTaxable(
  isAccountTaxable: boolean,
  renderCell: NonNullable<CarTableColumn<CarPosition>["renderCell"]>,
): NonNullable<CarTableColumn<CarPosition>["renderCell"]> {
  return (props) => {
    if (isAccountTaxable) {
      return renderCell(props);
    } else {
      return (
        <CarTextCalcField
          value="-"
          valueProps={{ sx: { textAlign: "center" } }}
        />
      );
    }
  };
}

const StyledRoot = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4.5),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.palette.white,
  border: `solid 1px ${theme.palette.gray3}`,
  borderRadius: "5px",
  paddingTop: theme.spacing(6),
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(6.6),
  paddingRight: theme.spacing(6.6),
  ".add-row": {
    alignSelf: "flex-start",
  },
}));

export interface ManualAccountRowDetailedHoldingsPositionTableProps {
  item: CarAccount;
  onChange: (item: CarAccount) => void;
}

export const ManualAccountRowDetailedHoldingsPositionTable = (
  props: ManualAccountRowDetailedHoldingsPositionTableProps,
) => {
  const items: Array<CarPosition> = props.item.detailed_holdings ?? [];

  const { isAccountTaxable } = useAccountTypes();

  // const totals = getPositionsTotals(props.item);

  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.byLatestSimulationJob,
  });

  const handleChange = (item: CarPosition) => {
    props.onChange({
      ...props.item,
      detailed_holdings:
        props.item.detailed_holdings?.map((i) =>
          i.id === item.id ? item : i,
        ) ?? [],
    });
  };
  const handleDelete = (item: CarPosition) => {
    props.onChange({
      ...props.item,
      detailed_holdings:
        props.item.detailed_holdings?.filter((i) => i.id !== item.id) ?? [],
    });
  };

  return (
    <StyledRoot>
      <CarTable
        noGaps
        items={items}
        columns={[
          {
            id: "symbol",
            label: "*Ticker/CUSIP",
            fraction: 1,
            renderCell: ({ item, onChange }) => (
              <CarTextFieldDelayed
                required
                value={item.symbol}
                onChange={(symbol) => onChange({ ...item, symbol })}
              />
            ),
          },
          {
            id: "description",
            label: "*Description",
            fraction: 1,
            renderCell: ({ item, onChange }) => (
              <CarTextFieldDelayed
                required
                value={item.description}
                onChange={(description) => onChange({ ...item, description })}
              />
            ),
          },
          {
            id: "shares",
            label: "*Shares",
            fraction: 1,
            renderCell: ({ item, onChange }) => (
              <CarNumberFieldDelayed
                required
                removeZeroOnFocus
                value={item.shares}
                onChange={(shares) => {
                  const oldMarketPrice =
                    item.shares && item.market_value
                      ? item.market_value / item.shares
                      : 0;

                  onChange({
                    ...item,
                    shares,
                    market_value:
                      oldMarketPrice && shares
                        ? oldMarketPrice * shares
                        : item.market_value,
                  });
                }}
              />
            ),
          },
          {
            id: "cost_basis",
            label: "*Cost Basis",
            fraction: 1,
            renderCell: blankIfNotTaxable(
              isAccountTaxable(props.item),
              ({ item, onChange }) => (
                <CarCurrencyFieldDelayed
                  required
                  value={item.cost_basis}
                  decimalPlaces={2}
                  onChange={(cost_basis) => onChange({ ...item, cost_basis })}
                />
              ),
            ),
          },
          {
            id: "market_price" as any,
            label: "Market Price",
            fraction: 1,
            renderCell: ({ item, onChange }) => (
              <CarCurrencyFieldDelayed
                required
                removeZeroOnFocus
                value={
                  item.shares && item.market_value
                    ? item.market_value / item.shares
                    : undefined
                }
                decimalPlaces={2}
                onChange={(value) => {
                  if (!value) {
                    onChange({
                      ...item,
                      market_value: undefined,
                    });
                  } else {
                    const shares = item.shares || 1; // if we set market_value > 0 than we should set at least 1 share
                    onChange({
                      ...item,
                      shares,
                      market_value: value * shares,
                    });
                  }
                }}
              />
            ),
          },
          {
            id: "market_value",
            label: "Market Value",
            fraction: 1,
            renderCell: ({ item, onChange }) => (
              <CarCurrencyFieldDelayed
                required
                removeZeroOnFocus
                value={item.market_value}
                decimalPlaces={2}
                onChange={(market_value) => onChange({ ...item, market_value })}
              />
            ),
          },
          {
            id: "firm_asset_class_id",
            label: "*Asset Class",
            fraction: 1,
            renderCell: ({ item, onChange }) => (
              <CarSelectField
                required
                value={item.firm_asset_class_id}
                onChange={(firm_asset_class_id) =>
                  onChange({
                    ...item,
                    firm_asset_class_id,
                  })
                }
              >
                {assetClasses.items.map((i) => (
                  <MenuItem key={i.firmAssetClassId} value={i.firmAssetClassId}>
                    {i.name}
                  </MenuItem>
                ))}
              </CarSelectField>
            ),
          },
        ]}
        onCanDelete={(item) => items.length > 1}
        onChange={handleChange}
        onDelete={handleDelete}
        pendoPrefix="manual_accounts_positions"
      />
      <AddRowButton
        className="add-row"
        onClick={() => props.onChange(addDetailedHolding(props.item))}
      >
        Add Position
      </AddRowButton>
    </StyledRoot>
  );
};
