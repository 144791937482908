import { Document, Text } from "@react-pdf/renderer";
import { useFirm } from "pages/settings/useFirm";
import { PdfPage } from "components/Pdf";
import { CarPdfViewer } from "components/PdfViewer";
import { useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAssetClassSummaryStat } from "../../assetClassAnalysis/useAssetClassSummaryStat";
import {
  DftTotalReturnsPdfSummary,
  PortfolioGroupDftTotalReturnsPdfChart,
} from "../portfolioGroupAssetClassAnalysisPdfReport/PortfolioGroupDftTotalReturnsPdfChart";
import { PortfolioGroupDftVersusTenYearReturnPdfChart } from "../portfolioGroupAssetClassAnalysisPdfReport/PortfolioGroupDftVersusTenYearReturnPdfChart";
import {
  SectionHeader,
  PageTitle,
  PageNumber,
} from "../portfolioGroupAssetClassAnalysisPdfReport/PageElements";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

export const AssetClassDftPdfReportPage = () => {
  const { assetClassId } = useParams();

  const firm = useFirm();

  const assetClassAnalysis = useAssetClassSummaryStat({
    type: AssetClassLoadType.byLatestSimulationJob,
    filterByUsableInPortfolios: true,
  });

  const distanceFromTrendItem = assetClassAnalysis.distanceFromTrendItems.find(
    (i) => i.level4Id === assetClassId,
  );

  const dftChartData = distanceFromTrendItem?.modelChartData;

  const title = distanceFromTrendItem?.name;

  const isLoading = firm.isLoading || assetClassAnalysis.isLoading;

  const theme = useTheme();

  return (
    <CarPdfViewer isLoading={isLoading}>
      <Document>
        <PdfPage
          hideFooter
          style={{
            paddingTop: 37,
            paddingBottom: 50,
            paddingLeft: 36,
            paddingRight: 36,
          }}
          footerProps={{
            isDraft: false,
            advisorCompanyName: "",
            clientName: "",
          }}
        >
          <PageTitle firmName={firm.firm.name} title={title ?? ""} />
          {dftChartData && (
            <DftTotalReturnsPdfSummary
              style={{ marginTop: 10 }}
              data={dftChartData}
              realStaticExpectedReturn={
                distanceFromTrendItem.realStaticExpectedReturn ?? 0
              }
            />
          )}
          <SectionHeader
            style={{
              marginTop: 20,
            }}
          >
            {title}
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 10,
            }}
          >
            Internal Use Only
          </Text>
          {dftChartData && (
            <PortfolioGroupDftTotalReturnsPdfChart
              width={530}
              height={240}
              data={dftChartData}
              assetClassName={title ?? ""}
            />
          )}
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            Real 10 Year Return vs. Distance from Trend
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 10,
            }}
          >
            Internal Use Only
          </Text>
          {dftChartData && (
            <PortfolioGroupDftVersusTenYearReturnPdfChart
              width={550}
              height={240}
              data={dftChartData}
              assetClassName={title ?? ""}
            />
          )}
          <PageNumber />
        </PdfPage>
      </Document>
    </CarPdfViewer>
  );
};
