import { useEffect, useState } from "react";

export const useYearRangeSelector = ({ years }: { years: number[] }) => {
  const [startYear, setStartYear] = useState<number | undefined>();
  const [endYear, setEndYear] = useState<number | undefined>();

  const fromYear = years.reduce<number>((acc, i) => Math.min(acc, i), 3000);
  const toYear = years.reduce<number>((acc, i) => Math.max(acc, i), 1000);

  useEffect(() => {
    if (fromYear !== 3000 && !startYear) {
      setStartYear(fromYear);
    }
  }, [fromYear, startYear]);

  useEffect(() => {
    if (toYear !== 1000 && !endYear) {
      setEndYear(toYear);
    }
  }, [toYear, endYear]);

  return {
    years,
    fromYear,
    toYear,
    startYear,
    setStartYear,
    endYear,
    setEndYear,
  };
};

export type UseYearRangeSelector = ReturnType<typeof useYearRangeSelector>;
