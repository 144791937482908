import background from "assets/barometer/background.png";
import whiteNeedle from "assets/barometer/white-needle.svg";
import redNeedle from "assets/barometer/red-needle.svg";
import blueNeedle from "assets/barometer/blue-needle.svg";
import mainNeedle from "assets/barometer/main-needle.svg";
import cap from "assets/barometer/cap.svg";
import { PlanResultsRiskData } from "../planResults/usePlanResults";
import { formatPercent } from "utils";
import { fontPdf } from "components/Pdf";
import { CarPdfImage, CarPdfImageProps } from "components/PdfImage";

const loadImage = async (src: string): Promise<HTMLImageElement> => {
  const img = new Image();
  return new Promise((resolve) => {
    img.onload = () => resolve(img);
    img.src = src;
  });
};

const size = 640;

const getPdfRiskBarometer = async ({
  value,
  valueRed,
  valueWhite,
  valueBlue,
}: PlanResultsRiskData) => {
  const degree = value * 2.22 - 111;
  const degreeRed = valueRed * 2.22 - 111;
  const degreeWhite = valueWhite * 2.22 - 111;
  const degreeBlue = valueBlue * 2.22 - 111;

  const canvas = document.createElement("canvas");

  canvas.setAttribute("width", size.toString());
  canvas.setAttribute("height", size.toString());

  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("Can't get canvas context");
  }

  const drawRotatedImage = (img: HTMLImageElement, degrees: number) => {
    ctx.save();
    ctx.translate(size / 2, size / 2);
    ctx.rotate((degrees * Math.PI) / 180);
    ctx.drawImage(img, -img.width / 2, -img.width / 2);
    ctx.restore();
  };

  const [
    backgroundImg,
    mainNeedleImg,
    redNeedleImg,
    whiteNeedleImg,
    blueNeedleImg,
    capImg,
  ] = await Promise.all([
    loadImage(background),
    loadImage(mainNeedle),
    loadImage(redNeedle),
    loadImage(whiteNeedle),
    loadImage(blueNeedle),
    loadImage(cap),
  ]);

  ctx.drawImage(backgroundImg, 0, 0);
  ctx.font = `400 36px ${fontPdf.table.family}`;

  const percentStr = formatPercent(value);

  const textSize = ctx.measureText(percentStr);
  ctx.fillText(percentStr, size / 2 - textSize.width / 2, 560);

  ctx.shadowColor = "rgba(0, 0, 0, .7)";
  ctx.shadowBlur = 3;
  ctx.shadowOffsetX = 5;
  ctx.shadowOffsetY = 3;

  drawRotatedImage(mainNeedleImg, degree);
  drawRotatedImage(redNeedleImg, degreeRed);
  drawRotatedImage(whiteNeedleImg, degreeWhite);
  drawRotatedImage(blueNeedleImg, degreeBlue);
  ctx.drawImage(capImg, 0, 0);

  const data = canvas.toDataURL("image/png");
  return data;
};

interface CarPdfRiskBarometerProps extends Omit<CarPdfImageProps, "src"> {
  planResultsRiskData: PlanResultsRiskData;
}

export const CarPdfRiskBarometer = ({
  planResultsRiskData,
  ...props
}: CarPdfRiskBarometerProps) => (
  <CarPdfImage src={getPdfRiskBarometer(planResultsRiskData)} {...props} />
);
