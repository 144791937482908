import {
  FooterProps,
  PdfPage,
  PdfMarkdown,
  PdfSectionLabel,
} from "components/Pdf";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";

export interface DisclosuresActionPdfPageProps {
  footerProps: FooterProps;
  disclosures: UseDisclosures;
}

export const DisclosuresActionPdfPage = (
  props: DisclosuresActionPdfPageProps
) => (
  <PdfPage footerProps={props.footerProps}>
    <PdfSectionLabel label="Disclosures" />
    <PdfMarkdown>
      {props.disclosures.getContent(
        CarDisclosureContentCode[
          "CMPAS_ACTION_PLAN_REPORT_DISCLOSURES_-_ACTION_PLAN_part_10"
        ]
      )}
    </PdfMarkdown>
  </PdfPage>
);
