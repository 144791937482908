import { useEffect, useMemo, useRef, useState } from "react";
import {
  SimulationDataExplorerInput,
  useSimulationDataExplorerInput,
} from "./useSimulationDataExplorerInput";
import {
  SimulationDataExplorerDisplay,
  SimulationDataExplorerPresentation,
  useSimulationDataExplorerOutput,
} from "./useSimulationDataExplorerOutput";
import { useSearchParams } from "react-router-dom";
import { useMount } from "app/useMount";
import { TimeHorizonType } from "./useTimeHorizonSelector";
import { useSuccessToast } from "app/useSuccessToast";

interface UseSimulationDataExplorerParams {
  simulationJobId: string;
  defaultInput?: SimulationDataExplorerInput;
  storageKey?: string;
}

type UrlParams = {
  input: SimulationDataExplorerInput | undefined;
  display: SimulationDataExplorerDisplay;
  timeHorizon: TimeHorizonType;
  presentation: SimulationDataExplorerPresentation;
};

type ParamsLoadNextState =
  | "setInput"
  | "setDisplay"
  | "handleRun"
  | "setPresentation"
  | "finished";

export const useSimulationDataExplorer = ({
  simulationJobId,
  defaultInput,
  storageKey,
}: UseSimulationDataExplorerParams) => {
  const [searchParams] = useSearchParams();
  const toast = useSuccessToast();
  const input = useSimulationDataExplorerInput({
    simulationJobId,
    defaultInput,
    storageKey,
  });

  const output = useSimulationDataExplorerOutput({
    simulationJobId,
    isRunning: input.isRunning,
    defaultDisplay: {
      filterDataType:
        defaultInput?.filterDataType ?? input.input.filterDataType,
      assetClassCode:
        defaultInput?.assetClassCode ?? input.input.assetClassCode,
    },
    paths: input.paths,
  });

  const urlParamsRef = useRef<UrlParams>();

  const [paramsLoadNextState, setParamsLoadNextState] =
    useState<ParamsLoadNextState>("finished");

  useEffect(() => {
    const urlParams = urlParamsRef.current;
    if (!urlParams) {
      return;
    }

    if (input.isLoading || output.isLoading) {
      return;
    }

    switch (paramsLoadNextState) {
      case "setInput":
        if (urlParams.input) {
          input.setInput(urlParams.input);
          setParamsLoadNextState("setDisplay");
        }
        break;

      case "setDisplay":
        output.setDisplay(urlParams.display);
        output.timeHorizonSelector.onChange(urlParams.timeHorizon);
        setParamsLoadNextState("handleRun");
        break;

      case "handleRun":
        input.handleRun();
        setParamsLoadNextState("setPresentation");
        break;
      case "setPresentation":
        output.setPresentation(urlParams.presentation);
        setParamsLoadNextState("finished");
        break;

      default:
        break;
    }
  }, [input, output, paramsLoadNextState]);

  useMount(() => {
    if (urlParamsRef.current) {
      return;
    }

    const paramsStr = searchParams.get("params") ?? "";
    if (!paramsStr) {
      return;
    }

    window.history.replaceState({}, document.title, window.location.pathname);

    try {
      const params: UrlParams = JSON.parse(paramsStr);
      urlParamsRef.current = params;
      setParamsLoadNextState("setInput");
    } catch (error) {
      console.error(error);
    }
  });

  return useMemo(() => {
    const handleCopyUrl = () => {
      const params: UrlParams = {
        input: input.lastRunInput,
        display: output.display,
        timeHorizon: output.timeHorizonSelector.value,
        presentation: output.presentation,
      };

      const shareUrl = new URLSearchParams({
        simulationJobId,
        params: JSON.stringify(params),
      });

      const path = `${window.location.origin}${window.location.pathname
        .split("/")
        .slice(0, -1)
        .join("/")}/data-explorer?${shareUrl.toString()}`;
      navigator.clipboard
        .writeText(path)
        .then(() =>
          toast({
            kind: "success",
            message: "URL has been copied to clipboard!",
          }),
        )
        .catch((error) =>
          toast({
            kind: "error",
            message: error,
          }),
        );
    };

    return {
      input,
      output,
      handleCopyUrl,
    };
  }, [simulationJobId, input, output, toast]);
};

export type UseSimulationDataExplorer = ReturnType<
  typeof useSimulationDataExplorer
>;
