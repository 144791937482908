import { BoxProps, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { UsePortfolioList } from "./usePortfolioList";
import { CarSelectField } from "components/Inputs";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";

interface SelectPortfolioModelProps {
  sx?: BoxProps["sx"];
  portfolioGroupList: UsePortfolioList;
  selectedPortfolioGroupId?: string;
  onChange: (value?: string) => void;
}

export const SelectPortfolioModel = ({
  sx,
  selectedPortfolioGroupId,
  onChange,
  portfolioGroupList,
}: SelectPortfolioModelProps) => {
  return (
    <RootPageBox
      sx={{ px: 4, py: 3, ...sx, minWidth: 438, justifyContent: "center" }}
    >
      <TablePlaceHolder isLoading={portfolioGroupList.isLoading} rows={1}>
        {portfolioGroupList.items.length ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              justifyContent: "stretch",
            }}
          >
            <Typography variant="h5SSemiBold" sx={{ flex: "none", mb: 0.5 }}>
              Select Model -
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={pendoClasses.portfolioAnalysisDashboardSelectModel}
              />
            </Typography>
            <CarSelectField
              sx={{ flex: "auto" }}
              value={selectedPortfolioGroupId}
              onChange={(value) => onChange(value)}
            >
              {portfolioGroupList.items.map((i) => (
                <MenuItem
                  key={i.id}
                  value={i.id}
                  sx={{
                    fontWeight: i.is_active ? 700 : 400,
                    textTransform: i.is_active ? "uppercase" : undefined,
                  }}
                >
                  {`${i.title}${i.is_active ? "*" : ""}`}
                </MenuItem>
              ))}
            </CarSelectField>
          </Box>
        ) : (
          <Typography variant="h5SRegular" sx={{ alignSelf: "center" }}>
            No Portfolios
          </Typography>
        )}
      </TablePlaceHolder>
    </RootPageBox>
  );
};
