import { BaseDialog } from "components/BaseDialog";
import { Box, Typography } from "@mui/material";
import { JobStatus } from "./JobStatus";
import { usePortfolioGroupOptimizerRuns } from "./usePortfolioGroupOptimizerRuns";
import { Ref, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { caravelColors } from "theme";
import { OptimizationJobRoot } from "./useOptimizationJobs";

export interface PleaseWaitOverlayRef {
  close: () => void;
  waitAndClose: (jobIds: string[]) => void;
}

export interface PleaseWaitOverlayProps {
  portfolioGroupId: string;
  onApply: () => void;
  onCancel: () => void;
  onPublished: (successJobIds: string[]) => void;
  customRef?: Ref<PleaseWaitOverlayRef>;
}

export const PleaseWaitOverlay = (props: PleaseWaitOverlayProps) => {
  const portfolioGroupOptimizerRuns = usePortfolioGroupOptimizerRuns({
    portfolioGroupId: props.portfolioGroupId,
  });

  const completedJobIds = useMemo(
    () =>
      portfolioGroupOptimizerRuns.items
        .filter((i) => i.jobs.every((j) => j.isCompleted || j.isError))
        .map((i) => i.id),

    [portfolioGroupOptimizerRuns.items],
  );

  const [jobIds, setJobIds] = useState<string[]>([]);

  useEffect(() => {
    if (jobIds.length && jobIds.every((jid) => completedJobIds.includes(jid))) {
      props.onPublished(
        portfolioGroupOptimizerRuns.items
          .filter((i) => jobIds.includes(i.id) && !i.allErrors)
          .map((i) => i.id),
      );
      props.onCancel();
    }
  }, [completedJobIds, jobIds, props, portfolioGroupOptimizerRuns]);

  useImperativeHandle(props.customRef, () => ({
    close: () => {
      props.onCancel();
    },
    waitAndClose: (jobIds) => {
      setJobIds(jobIds);
    },
  }));

  const getStatus = (i: OptimizationJobRoot) => {
    let statusText = "";
    let statusColor = "";
    let statusDescription = "";
    let inProgress = false;
    if (i.jobs.every((j) => j.isCompleted)) {
      statusText = "Success:";
      statusColor = caravelColors.green;
      statusDescription = "Solution found";
    } else if (i.jobs.some((j) => j.isError)) {
      statusText = "Failed:";
      statusColor = caravelColors.red;
      statusDescription = "No solution found";
    } else {
      statusText = "In Progress:";
      statusColor = caravelColors.softBlack;
      statusDescription = "Searching for solution";
      inProgress = true;
    }

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: inProgress ? "gray3" : undefined,
        }}
      >
        <Typography variant="h6SRegular">
          <Box sx={{ color: statusColor, fontWeight: "600" }} component="span">
            {statusText}
          </Box>{" "}
          {statusDescription}
        </Typography>
      </Box>
    );
  };

  const displayItems = portfolioGroupOptimizerRuns.items.filter((i) =>
    jobIds.includes(i.id),
  );

  return (
    <BaseDialog
      onClose={props.onCancel}
      sx={{
        width: "100vw",
        maxWidth: "100vw",
        height: "100vh",
        maxHeight: "100vh",
        borderRadius: "0",
        m: 0,
        p: 0,
      }}
      actions={null}
      hideCloseButton
    >
      <Box
        sx={{
          flex: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2.5,
          }}
        >
          <JobStatus
            sx={{
              width: 100,
              height: 100,
              "circle.percent": {
                stroke: "rgba(241, 150, 73, 1)",
              },
            }}
            completedCount={0}
            failedCount={0}
            jobCount={1}
            hideLabel
          />
          <Typography
            sx={{
              whiteSpace: "pre-line",
              textAlign: "center",
              lineHeight: "31px",
            }}
            variant="par03Regular"
          >
            {`Please wait.\nWe’re optimizing your portfolio model.\nThis may take a few minutes.`}
          </Typography>
        </Box>
        <Box sx={{ flexBasis: 100, flexShrink: 1 }} />
        {displayItems.length > 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: 743,
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                borderBottom: "1px solid",
                borderBottomColor: "gray6",
                pb: 1,
              }}
            >
              <Typography sx={{ textAlign: "center" }} variant="caption">
                OPTIMIZATION TYPE
              </Typography>
              <Typography sx={{ textAlign: "center" }} variant="caption">
                STATUS
              </Typography>
            </Box>
            {displayItems.map((i) => (
              <Box
                key={i.id}
                sx={{
                  height: 60,
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  border: "1px solid",
                  borderColor: "gray6",
                  borderRadius: "5px",
                  overflow: "hidden",
                  backgroundColor: "gray1",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRight: "1px solid",
                    borderRightColor: "gray6",
                  }}
                >
                  <Typography variant="h6SSemiBold">{i.title}</Typography>
                </Box>
                {getStatus(i)}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </BaseDialog>
  );
};
