import { Box, BoxProps, styled } from "@mui/material";
import { CarYearField } from "components/Inputs";
import { UseYearRangeSelector } from "./useYearRangeSelector";

const StyledYearField = styled(CarYearField)(({ theme }) => ({
  width: 140,
  // margin: "auto",
  ".MuiInputLabel-root": {
    fontSize: "16px",
  },
  ".MuiInputBase-root": {
    backgroundColor: theme.palette.white,
  },
}));

interface YearRangeSelectorProps extends BoxProps {
  yearRangeSelector: UseYearRangeSelector;
}

export const YearRangeSelector = ({
  yearRangeSelector,
  ...props
}: YearRangeSelectorProps) => {
  const { fromYear, toYear, startYear, setStartYear, endYear, setEndYear } =
    yearRangeSelector;

  return (
    <Box {...props} sx={{ display: "flex", gap: 3.5, ...props.sx }}>
      <StyledYearField
        label="Start Year"
        range={{ fromYear, toYear }}
        value={startYear}
        onChange={setStartYear}
      />
      <StyledYearField
        label="End Year"
        range={{ fromYear, toYear }}
        value={endYear}
        onChange={setEndYear}
      />
    </Box>
  );
};
