import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { useParams, useSearchParams } from "react-router-dom";
import { AssetClassLevel } from "const";
import { useFirm } from "pages/settings/useFirm";
import { usePortfolioGroupInfo } from "pages/assetAllocation/usePortfolioGroupInfo";
import { usePortfolioGroupVersionData } from "pages/assetAllocation/usePortfolioGroupVersionData";
import { splitArrayIntoChunks } from "utils";
import { ModulesPdfPage } from "./ModulesPdfPage";
import { AСPerModulePdfPage } from "./AСPerModulePdfPage";
import { CarPdfViewer } from "components/PdfViewer";
import { CarPdfDocument } from "components/PdfDocument";
import { useUser } from "pages/settings/useUser";
import { CarPdfHeadingAC } from "features/financialPlanningReport/PdfHeading";
import { format } from "date-fns";

export const AssetAllocationSummaryReport = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const [searchParams] = useSearchParams();
  const data1Id = searchParams.get("data1Id") ?? "";
  const data2Id = searchParams.get("data2Id") ?? "";
  const params = useParams();
  const assetClassLevel = params.assetClassLevel as AssetClassLevel;
  const isVerticalCharts = assetClassLevel !== AssetClassLevel.level1;

  const firm = useFirm();
  const user = useUser();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;

  const data = usePortfolioGroupVersionData({
    versionId: portfolioGroupVersionId,
    publishedVersionId: groupInfo.publishedVersionId,
    hideCash: true,
  });

  const moduleChunks = Array.from(
    splitArrayIntoChunks(
      data.sortedModuleItems.filter((mod) => !mod.is_cash),
      isVerticalCharts ? 1 : 2,
    ),
  );

  const isLoading =
    user.isLoading || firm.isLoading || groupInfo.isLoading || data.isLoading;

  const date = format(new Date(), "MM/dd/yyyy");
  const title = `${groupInfo.title} - ${date}`;

  return (
    <CarPdfViewer isLoading={isLoading}>
      <CarPdfDocument
        title={title}
        author={`${user.userFullName}, ${firm.firm.name ?? ""}`}
      >
        {moduleChunks.map((chunk, idx) => (
          <ModulesPdfPage
            key={idx}
            title={
              idx === 0 ? (
                <CarPdfHeadingAC
                  firmName={firm.firm.name ?? ""}
                  label={`Summary Report - ${date}`}
                />
              ) : undefined
            }
            modules={chunk}
            tableData={data.tableData}
            level={assetClassLevel}
            data1Id={data1Id}
            data2Id={data2Id}
          />
        ))}
        <AСPerModulePdfPage
          title={
            <CarPdfHeadingAC
              firmName={firm.firm.name ?? ""}
              label={`Summary Report - ${date}`}
            />
          }
          level={assetClassLevel}
          tableData={data.tableData}
          columnId={data1Id}
        />
      </CarPdfDocument>
    </CarPdfViewer>
  );
};
