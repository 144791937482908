import { useMemo, useRef } from "react";

// uses ref to create memorized function, but always assigns the latest one to the result
export const useCallbackAsRef = <T extends (...args: any[]) => any>(
  callback: T
): T => {
  const callBackRef = useRef<T>();
  callBackRef.current = callback;

  return useMemo<T>(() => {
    const cb = (...args: any[]) => {
      if (!callBackRef.current) {
        throw new Error("callBackRef.current is not assigned");
      }

      return callBackRef.current(...args);
    };
    return cb as T;
  }, [callBackRef]);
};
