import { Box, BoxProps, Typography, useTheme } from "@mui/material";
import { CarPortfolioChartItem } from "../asset-allocation-types";
import { CarSelectChartField } from "./SelectChartField";
import { ComponentProps, useCallback, useMemo } from "react";
import { Chart } from "react-chartjs-2";
import { formatPercentFactor } from "utils";
import {
  ChartTooltip,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { UseChartDataSelector } from "./useChartDataSelector";
import { createPendoClassName } from "app/thirdParty/pendo";
import { ChartPlugins } from "chartUtils";
import { ChartDataset } from "chart.js";

type Options = ComponentProps<typeof Chart>["options"];

interface MultiPeriodReturnComparisonChartData {
  bar1: ChartDataset<"bar", number[]>;
  bar1MaxFee?: ChartDataset<"bar", number[]>;
  bar2: ChartDataset<"bar", number[]>;
  bar2MaxFee?: ChartDataset<"bar", number[]>;
  yLabel: string;
  xLabels: string[];
}

export const getMultiPeriodReturnComparisonChartData = ({
  data,
  data1Id,
  data2Id,
}: {
  data: CarPortfolioChartItem[];
  data1Id: string;
  data2Id: string;
}): MultiPeriodReturnComparisonChartData => {
  const item1 = data.find((i) => i.id === data1Id);
  const item2 = data.find((i) => i.id === data2Id);

  return {
    bar1: {
      data: item1?.y ?? [],
      label: `${item1?.label ?? ""}\n(No fee)`,
    },
    bar1MaxFee: item1?.yMaxFee
      ? {
          data: item1?.yMaxFee ?? [],
          label: `${item1?.label ?? ""}\n(Net of max. fee TODO)`,
        }
      : undefined,
    bar2: {
      data: item2?.y ?? [],
      label: `${item2?.label ?? ""}\n(No fee)`,
    },
    bar2MaxFee: item2?.yMaxFee
      ? {
          data: item2?.yMaxFee ?? [],
          label: `${item2?.label ?? ""}\n(Net of max. fee TODO)`,
        }
      : undefined,
    yLabel: "Cumulative Return (%)",
    xLabels: ["3 Month", "1 Year", "3 Year", "5 Year", "10 Year"],
  };
};

interface InternalChartProps {
  sx?: BoxProps["sx"];
  data: CarPortfolioChartItem[];
  data1Id: string;
  data2Id: string;
  pendoPrefix: string;
  isWhiteContext?: boolean;
}

const InternalChart = ({
  sx,
  data,
  data1Id,
  data2Id,
  pendoPrefix,
  isWhiteContext,
}: InternalChartProps) => {
  const theme = useTheme();
  const { tooltipPlugin, tooltipData } = useChartTooltip();
  const { bar1, bar2, yLabel, xLabels } =
    getMultiPeriodReturnComparisonChartData({
      data,
      data1Id,
      data2Id,
    });

  const renderTooltip: RenderTooltip = useCallback(
    (datasetIndex, dataIndex) => {
      const ds = datasetIndex === 0 ? bar1 : bar2;
      const value = ds?.data?.[dataIndex] ?? 0;
      const periodStr = xLabels[dataIndex] ?? "";
      return (
        <Box
          sx={{
            fontSize: 15,
            fontWeight: 600,
            color: "black",
            whiteSpace: "nowrap",
          }}
        >
          <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
            {ds?.label}
          </Typography>
          <br />
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {periodStr}
          </Typography>
          <br />
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {formatPercentFactor(value, 1)}
          </Typography>
        </Box>
      );
    },
    [bar1, bar2, xLabels],
  );

  const options: Options = useMemo(
    () => ({
      maintainAspectRatio: false,
      animation: {
        duration: 500,
      },
      scales: {
        x: {
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 13,
              weight: "600",
            },
            color: theme.palette.softBlack,
          },
          grid: {
            display: false,
          },
          type: "category",
          labels: xLabels,
        },
        y: {
          offset: true,
          title: {
            text: yLabel,
            display: true,
            font: {
              family: theme.typography.fontFamily,
              size: 15,
              weight: "600",
            },
          },
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 15,
              weight: "600",
            },
            color: theme.palette.softBlack,
            callback: (value) =>
              typeof value === "number" ? formatPercentFactor(value) : value,
          },
          border: { display: false },
          grid: {
            display: true,
            color: (ctx) => {
              return ctx.tick.value === 0
                ? theme.palette.gray7
                : theme.palette.gray2;
            },
            lineWidth: 1,
            tickColor: theme.palette.white,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltipPlugin,
        roundedBackground: {
          contextColor: isWhiteContext
            ? theme.palette.white
            : theme.palette.gray1,
          backgroundColor: isWhiteContext
            ? theme.palette.gray1
            : theme.palette.white,
          borderColor: theme.palette.gray7,
          borderRadius: 5,
        },
      },
    }),
    [theme, tooltipPlugin, isWhiteContext, yLabel, xLabels],
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: 460,
        position: "relative",
        display: "flex",
        ...sx,
      }}
    >
      <ChartTooltip tooltipData={tooltipData} renderTooltip={renderTooltip} />
      <Chart
        className={createPendoClassName(`${pendoPrefix}_chart`)}
        type="bar"
        datasetIdKey="id"
        data={{
          datasets: [
            {
              id: "bar1",
              type: "sharpBar" as "bar",
              label: bar1.label,
              data: bar1.data,
              backgroundColor: theme.palette.chartsColor.green,
              barPercentage: 0.95,
              categoryPercentage: 0.65,
            },
            {
              id: "bar2",
              type: "sharpBar" as "bar",
              label: bar2.label,
              data: bar2.data,
              backgroundColor: theme.palette.chartsColor.orange,
              barPercentage: 0.95,
              categoryPercentage: 0.65,
            },
          ],
        }}
        options={options}
        plugins={[ChartPlugins.roundedBackground]}
      />
    </Box>
  );
};

interface ChartMultiPeriodReturnComparisonProps {
  selectorLabel1: string;
  selectorLabel2: string;
  data: CarPortfolioChartItem[];
  chartDataSelector: UseChartDataSelector;
  pendoPrefix: string;
  isWhiteContext?: boolean;
}

export const ChartMultiPeriodReturnComparison = ({
  selectorLabel1,
  selectorLabel2,
  data,
  chartDataSelector: { data1Id, data2Id, onData1IdChange, onData2IdChange },
  pendoPrefix,
  isWhiteContext,
}: ChartMultiPeriodReturnComparisonProps) => {
  const theme = useTheme();
  return (
    <>
      <InternalChart
        data={data}
        data1Id={data1Id ?? ""}
        data2Id={data2Id ?? ""}
        pendoPrefix={pendoPrefix}
        isWhiteContext={isWhiteContext}
      />
      <Box
        sx={{
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 3,
        }}
      >
        <CarSelectChartField
          sx={{
            alignSelf: "flex-start",
            width: 200,
            ".MuiInputBase-root": {
              backgroundColor: "white",
            },
          }}
          label={selectorLabel1}
          value={data1Id}
          onChange={onData1IdChange}
          items={data}
          chartColor={theme.palette.chartsColor.green}
        />
        <CarSelectChartField
          sx={{
            alignSelf: "flex-start",
            width: 200,
            ".MuiInputBase-root": {
              backgroundColor: "white",
            },
          }}
          label={selectorLabel2}
          value={data2Id}
          onChange={onData2IdChange}
          items={data}
          chartColor={theme.palette.chartsColor.orange}
        />
      </Box>
    </>
  );
};
