import { TablePlaceHolder } from "components/PlaceHolder";
import { isOddEven } from "utils";
import { Fragment, memo } from "react";
import { CarInfoCell, CarInfoHeader, CarInfoTable } from "components/InfoTable";
import { Box, Typography } from "@mui/material";
import { CarSwitch } from "components/Switch";
import { useSimulationCorrelations } from "./useSimulationCorrelations";
import {
  ExcelDataFormat,
  excelTableFormatValue,
  excelTableToGridTemplateColumns,
} from "app/useExcelExport";
import { CarDownloadButton } from "components/Buttons";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

interface SimulationCorrelationsProps {
  simulationJobId: string;
  isStatic: boolean;
}

export const SimulationCorrelations = memo(
  (props: SimulationCorrelationsProps) => {
    const simulationCorrelations = useSimulationCorrelations({
      simulationJobId: props.simulationJobId,
    });

    const pageContent = usePageContent();

    return (
      <TablePlaceHolder
        sx={{ mx: 7, mt: 5 }}
        isLoading={simulationCorrelations.isLoading || pageContent.isLoading}
        rows={8}
      >
        <CarPageContent
          sx={{ mb: 3 }}
          content={pageContent.getContent(
            props.isStatic
              ? CarPageContentCode.SIMULATION_ANALYSIS_STATIC_CORRELATIONS
              : CarPageContentCode.SIMULATION_ANALYSIS_PRICE_DRIVEN_CORRELATIONS,
          )}
          isWhiteContext
        />
        {simulationCorrelations.table.rows.length > 0 && (
          <>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="par01Regular">Real</Typography>
                <CarSwitch
                  size="small"
                  checked={!simulationCorrelations.isReal}
                  onChange={(e, value) =>
                    simulationCorrelations.setIsReal(!value)
                  }
                />
                <Typography variant="par01Regular">Nominal</Typography>
              </Box>
              <CarDownloadButton
                sx={{ ml: "auto", mb: 0.5 }}
                onClick={simulationCorrelations.handleDownloadReports}
              >
                Download Reports
              </CarDownloadButton>
            </Box>
            <Box
              sx={{ mt: 2, overflow: "auto", maxHeight: "calc(100vh - 200px)" }}
            >
              <CarInfoTable
                sx={{
                  width:
                    200 +
                    (simulationCorrelations.table.columns.length - 1) * 100,
                  position: "relative",
                  gridTemplateColumns: excelTableToGridTemplateColumns(
                    simulationCorrelations.table,
                  ),
                }}
              >
                {simulationCorrelations.table.columns.map((i, idx) => (
                  <CarInfoHeader
                    key={idx}
                    text={i.label}
                    sx={{
                      top: 0,
                      position: "sticky",
                      py: 0.5,
                      fontWeight: 400,
                    }}
                  />
                ))}

                {simulationCorrelations.table.rows.map((row, rowIdx) => (
                  <Fragment key={rowIdx}>
                    {row.map((val, valIdx) => (
                      <CarInfoCell
                        isOdd={isOddEven(rowIdx)}
                        justifyContent={
                          simulationCorrelations.table.columns.at(valIdx)
                            ?.format === ExcelDataFormat.general
                            ? "start"
                            : undefined
                        }
                        text={excelTableFormatValue(
                          val,
                          simulationCorrelations.table.columns.at(valIdx),
                        )}
                      />
                    ))}
                  </Fragment>
                ))}
              </CarInfoTable>
            </Box>
          </>
        )}
      </TablePlaceHolder>
    );
  },
);
