import { useState } from "react";
import { usePortfolioGroupVersionStatData } from "../../usePortfolioGroupVersionStatData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "../../usePortfolioGroupInfo";
import { useChartDataSelector } from "./../useChartDataSelector";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { ChartSimulatedForwardProbabilities } from "./../ChartSimulatedForwardProbabilities";
import { AnalyzeModuleStyledPage } from "./AnalyzeModuleStyledPage";

interface AnalyzeModuleSimulatedForwardProbabilitiesProps {
  moduleId: string;
}

export const AnalyzeModuleSimulatedForwardProbabilities = (
  props: AnalyzeModuleSimulatedForwardProbabilitiesProps,
) => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const pageContent = usePageContent();

  const { moduleId } = props;

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const statData = usePortfolioGroupVersionStatData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    filterByModuleId: moduleId,
  });

  const defaultIds = statData.getDefaultDataIdsForModule(moduleId);

  const chartDataSelector = useChartDataSelector({
    storageKey: `EditPortfolioGroupAnalyze_${portfolioGroupVersionId}`,
    ...defaultIds,
  });

  const [isReal, setIsReal] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [chartDateRange1, setChartDateRange1] = useState(20);
  const [chartDateRange2, setChartDateRange2] = useState(20);

  return (
    <AnalyzeModuleStyledPage>
      <TablePlaceHolder
        isLoading={
          groupInfo.isLoading || statData.isLoading || pageContent.isLoading
        }
        rows={5}
      >
        <CarPageContent
          sx={{ mb: 5 }}
          isWhiteContext
          content={pageContent.getContent(
            groupInfo.isSinglePortfolioMode
              ? CarPageContentCode.PORTFOLIO_ANALYSIS_ANALYZE_SIMULATED_FORWARD_PROBABILITY
              : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_FORWARD_PROJECTIONS,
          )}
        />
        <ChartSimulatedForwardProbabilities
          realHeatmap={statData.realHeatmap}
          nominalHeatmap={statData.nominalHeatmap}
          chartDataSelector={chartDataSelector}
          isReal={isReal}
          setIsReal={setIsReal}
          isCompare={isCompare}
          setIsCompare={setIsCompare}
          chartDateRange1={chartDateRange1}
          setChartDateRange1={setChartDateRange1}
          chartDateRange2={chartDateRange2}
          setChartDateRange2={setChartDateRange2}
        />
      </TablePlaceHolder>
    </AnalyzeModuleStyledPage>
  );
};
