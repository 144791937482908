import { Document, Text } from "@react-pdf/renderer";
import { useFirm } from "pages/settings/useFirm";
import { PdfPage } from "components/Pdf";
import { CarPdfViewer } from "components/PdfViewer";
import { useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { PortfolioGroupMultiPeriodReturnComparisonPdfChart } from "../portfolioGroupModulePdfReport/PortfolioGroupMultiPeriodReturnComparisonPdfChart";
import { PortfolioGroupOutUnderPerfPdfChart } from "../portfolioGroupModulePdfReport/PortfolioGroupOutUnderPerfPdfChart";
import { useAssetClassExtendedStat } from "../../assetClassAnalysis/useAssetClassExtendedStat";
import {
  PageNumber,
  PageTitle,
  SectionHeader,
} from "../portfolioGroupAssetClassAnalysisPdfReport/PageElements";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

export const AssetClassPerformanceRiskPdfReport = () => {
  const [searchParams] = useSearchParams();
  const data1Id = searchParams.get("data1Id") ?? "";
  const data2Id = searchParams.get("data2Id") ?? "";

  const firm = useFirm();

  const data = useAssetClassExtendedStat({
    type: AssetClassLoadType.byLatestSimulationJob,
    filterByUsableInPortfolios: true,
  });

  const title = data.oneYearUnderPerf.find((i) => i.id === data1Id)?.label;

  const isLoading = firm.isLoading || data.isLoading;

  const theme = useTheme();

  return (
    <CarPdfViewer isLoading={isLoading}>
      <Document>
        <PdfPage
          hideFooter
          style={{
            paddingTop: 37,
            paddingBottom: 50,
            paddingLeft: 36,
            paddingRight: 36,
          }}
          footerProps={{
            isDraft: false,
            advisorCompanyName: "",
            clientName: "",
          }}
        >
          <PageTitle firmName={firm.firm.name} title={title ?? ""} />
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            1 Year Under Performance Risk
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 48,
            }}
          >
            Internal Use Only
          </Text>
          <PortfolioGroupOutUnderPerfPdfChart
            width={530}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.oneYearUnderPerf}
            periods={data.periods}
            data1Id={data1Id}
            data2Id={data2Id}
            isRelative={false}
          />
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            3 Year Under Performance Risk
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 48,
            }}
          >
            Internal Use Only
          </Text>
          <PortfolioGroupOutUnderPerfPdfChart
            width={530}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.threeYearUnderPerf}
            periods={data.periods}
            data1Id={data1Id}
            data2Id={data2Id}
            isRelative={false}
          />
          <PageNumber />
        </PdfPage>
        <PdfPage
          hideFooter
          style={{
            paddingTop: 37,
            paddingBottom: 50,
            paddingLeft: 36,
            paddingRight: 36,
          }}
          footerProps={{
            isDraft: false,
            advisorCompanyName: "",
            clientName: "",
          }}
        >
          <PageTitle firmName={firm.firm.name} title={title ?? ""} />
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            Cumulative Relative Performance 1998 - 2023
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 48,
            }}
          >
            Internal Use Only
          </Text>
          <PortfolioGroupOutUnderPerfPdfChart
            width={530}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.cumRelPerf}
            periods={data.periods}
            data1Id={data1Id}
            data2Id={data2Id}
            isRelative={true}
          />
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            Multi-Period Return Comparison
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 10,
            }}
          >
            Internal Use Only
          </Text>
          <PortfolioGroupMultiPeriodReturnComparisonPdfChart
            width={520}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.multiPeriodReturnComparison}
            data1Id={data1Id}
            data2Id={data2Id}
          />
          <PageNumber />
        </PdfPage>
      </Document>
    </CarPdfViewer>
  );
};
