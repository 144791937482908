import {
  useAssetClassesAssetClassStatisticViewRetrieveQuery,
  useSimulationSimulationJobGetCondensedSummaryStatisticsListQuery,
} from "api/carApi.generated";
import {
  AssetClass,
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { isDefined } from "utils";

// todo clear when swagger fixed
interface AssetClassStatisticView {
  duration: number;
  convexity: number;
  yield_to_worst: number;
  equity_yield: number;
}
export type AssetClassStatisticViewItemResponse = Record<
  string,
  AssetClassStatisticView
>;
export interface AssetClassStatisticsItem extends AssetClass {
  cmaModelExpectedReturn: number;
  volatility: number;
  fixedIncomeDuration: number;
  fixedIncomeConvexity: number;
  currentEquityDividendYield: number;
  currentFixedIncomeYield: number;
}

export const useAssetClassStatistics = (params: {
  simulationGroupId?: string;
}) => {
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.bySimulationGroupId,
    simulationGroupId: params.simulationGroupId,
    filterByUsableInPortfolios: true,
  });
  const acStatData = useAssetClassesAssetClassStatisticViewRetrieveQuery();

  const asSumStatData =
    useSimulationSimulationJobGetCondensedSummaryStatisticsListQuery(
      {
        id: assetClasses.simulationJobId ?? "",
      },
      { skip: !assetClasses.simulationJobId },
    );

  const acStatDataResponse =
    (acStatData.data as AssetClassStatisticViewItemResponse) ?? {};

  const items = assetClasses.items
    .map<AssetClassStatisticsItem | undefined>((ac) => {
      const viewItem = acStatDataResponse[ac.level4Id];
      const acSumItem = asSumStatData.data?.find(
        (item) => item.asset_class4 === ac.level4Id,
      );

      return viewItem
        ? {
            ...ac,

            cmaModelExpectedReturn: assetClasses.simulationJobIsStatic
              ? acSumItem?.nominal_static_expected_return ?? 0
              : acSumItem?.nominal_dynamic_expected_return ?? 0, // todo clarify

            volatility: acSumItem?.volatility ?? 0,
            fixedIncomeDuration: viewItem.duration,
            fixedIncomeConvexity: viewItem.convexity,
            currentEquityDividendYield: viewItem.equity_yield,
            currentFixedIncomeYield: viewItem.yield_to_worst,
          }
        : undefined;
    })
    .filter(isDefined);

  return {
    isLoading:
      assetClasses.isLoading || acStatData.isLoading || asSumStatData.isLoading,
    items,
  };
};
