import {
  FieldTypeEnum,
  GraphScaleEnum,
  useSimulationSimulationOutputDebugFieldsListQuery,
} from "api/carApi.generated";
import { useMemo } from "react";

interface SimulationDebugField {
  id: string;
  label: string;
  filterCode: string;
  isEquity: boolean;
  isFixedIncome: boolean;
  graphScale: GraphScaleEnum;
  fieldType: FieldTypeEnum;
}

export const useSimulationDebugFieldList = () => {
  const { data, isLoading } =
    useSimulationSimulationOutputDebugFieldsListQuery();

  return useMemo(() => {
    const items: SimulationDebugField[] = Array.from(data ?? [])
      .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0))
      .map((i) => ({
        id: i.id ?? "",
        label: i.display_lable ?? "",
        filterCode: i.filter_code ?? "",
        isEquity: !!i.is_equity,
        isFixedIncome: !!i.is_fixed_income,
        graphScale: i.graph_scale ?? "linear",
        fieldType: i.field_type ?? "number",
      }));

    return {
      isLoading,
      items,
      equityItems: items.filter((i) => i.isEquity),
      fixedIncomeItems: items.filter((i) => i.isFixedIncome),
    };
  }, [data, isLoading]);
};
