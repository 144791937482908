import {
  RiskStatTypeCalculation,
  useCmaRiskStatTypeListQuery,
} from "api/carApi.generated";
import { useMemo } from "react";

export enum RiskStatTypeCodeEnum {
  EXPECTED_RETURN = "EXPECTED_RETURN",
  EXPECTED_REL_PERF = "EXPECTED_REL_PERF",
  UNDERPERFORMANCE_RISK = "UNDERPERFORMANCE_RISK",
  POTENTIAL_ONEYR_LOSS = "POTENTIAL_ONEYR_LOSS",
  HIST_WORST_ONEYR_PERF = "HIST_WORST_ONEYR_PERF",
  VOLATILITY = "VOLATILITY",
  BREAKEVEN_HORIZON = "BREAKEVEN_HORIZON",
}

export const useRiskStatType = () => {
  const { isLoading, isFetching, data } = useCmaRiskStatTypeListQuery();

  return useMemo(() => {
    const sortedItems = Array.from(data ?? []).sort(
      (a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0),
    );

    const riskStatCalculationTypes = new Map<string, RiskStatTypeCalculation>();

    sortedItems.forEach((rst) => {
      if (rst.calculation_type?.id) {
        riskStatCalculationTypes.set(
          rst.calculation_type.id,
          rst.calculation_type,
        );
      }
    });

    const calcTypes = Array.from(riskStatCalculationTypes.values()).sort(
      (a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0),
    );

    return {
      isLoading,
      isFetching,
      xStatDefaultId: sortedItems.find((i) => i.code === "VOLATILITY")?.id,
      yStatDefaultId: sortedItems.find((i) => i.code === "EXPECTED_RETURN")?.id,
      planningToolItems: sortedItems.filter((i) => i.use_for_planning_tool),
      assetAllocationItems: sortedItems.filter(
        (i) => i.use_for_asset_allocation,
      ),
      calcTypes,
      getRiskStatTypeIdByCode: (code: RiskStatTypeCodeEnum) =>
        sortedItems.find((i) => i.code === code)?.id,
      getDisplayTypeById: (id?: string) =>
        sortedItems.find((i) => i.id === id)?.display_type,
    };
  }, [isLoading, isFetching, data]);
};

export type UseRiskStatType = ReturnType<typeof useRiskStatType>;
