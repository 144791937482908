import { Box, Typography } from "@mui/material";
import { useCommonRoleTypesListQuery } from "api/carApi.generated";
import { useDialog } from "app/useDialog";
import { useRoles } from "app/useRoles";
import { AddRowButton, CarButton } from "components/Buttons";
import { CarGrid } from "components/Grid";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarIconCheck } from "icons";
import { RoleEditorDialog } from "./RoleEditorDialog";
import { getNextSortOrder } from "utils";

export const SettingsBackEndRoles = () => {
  const roles = useRoles();
  const roleTypes = useCommonRoleTypesListQuery();

  const dialog = useDialog();

  const handleEditRole = (params: { id?: string; nextSortOrder?: number }) => {
    dialog(RoleEditorDialog, params);
  };

  return (
    <TablePlaceHolder
      isLoading={roles.isLoading || roleTypes.isLoading}
      rows={8}
    >
      <AddRowButton
        sx={{
          mt: 2,
          ml: -1,
          alignSelf: "flex-start",
        }}
        onClick={() =>
          handleEditRole({ nextSortOrder: getNextSortOrder(roles.items) })
        }
      >
        Add Role
      </AddRowButton>
      <CarGrid
        headerSx={{ mt: 3, mb: 0.5 }}
        isWhiteContext
        rows={roles.items}
        columns={[
          {
            fraction: 1,
            label: "Name",
            field: "name",
          },
          {
            label: "Code",
            field: "code",
          },
          {
            fraction: 0.5,
            label: "Type",
            field: "role_type",
            // headerAlign: "center",
            onCellRender: (row) => (
              <Typography variant="par02Regular">
                {roleTypes.data?.find((i) => i.id === row.role_type)?.name}
              </Typography>
            ),
          },
          {
            fraction: 1.5,
            label: "Description",
            field: "description",
          },
          {
            fraction: "120px",
            label: "Is Active",
            field: "is_active",
            textAlign: "center",
            headerAlign: "center",
            onCellRender: (row) => (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {row.is_active && <CarIconCheck sx={{ color: "green" }} />}
              </Box>
            ),
          },
          {
            label: "",
            fraction: "120px",
            onCellRender: (row) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    py: 1,
                  }}
                >
                  <CarButton
                    sx={{
                      width: 100,
                      height: 40,
                    }}
                    onClick={() => handleEditRole({ id: row.id })}
                  >
                    Edit
                  </CarButton>
                </Box>
              );
            },
          },
        ]}
      />
    </TablePlaceHolder>
  );
};
