import { Box, BoxProps, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { memo, useMemo } from "react";
import { CarSelectOptionField } from "components/Inputs";
import {
  FlatPageRouteControl,
  FlatPageRouteTab,
} from "components/FlatPageRouteControl";
import { SimulationABTestSimulatedForwardProbabilities } from "./SimulationABTestSimulatedForwardProbabilities";
import { SimulationABTestSummary } from "./SimulationABTestSummary";
import { SimulationABTestReturns } from "./SimulationABTestReturns";
import { SimulationABTestYields } from "./SimulationABTestYields";
import { useSimulationSelector } from "./useSimulationSelector";

interface PagesProps {
  sx?: BoxProps["sx"];
  simulationJobIdA: string;
  simulationJobIdB: string;
}

const Pages = (props: PagesProps) => {
  const tabs = useMemo<Array<FlatPageRouteTab>>(() => {
    return [
      {
        url: "summary",
        label: "Summary",
        content: (
          <SimulationABTestSummary
            simulationJobIdA={props.simulationJobIdA}
            simulationJobIdB={props.simulationJobIdB}
          />
        ),
      },
      {
        url: "simulated-forward-probabilities",
        label: "Simulated Forward Probabilities",
        content: (
          <SimulationABTestSimulatedForwardProbabilities
            simulationJobId1={props.simulationJobIdA}
            simulationJobId2={props.simulationJobIdB}
          />
        ),
      },
      {
        url: "returns",
        label: "Returns",
        content: (
          <SimulationABTestReturns
            simulationJobIdA={props.simulationJobIdA}
            simulationJobIdB={props.simulationJobIdB}
          />
        ),
      },
      {
        url: "yields",
        label: "Yields",
        content: (
          <SimulationABTestYields
            simulationJobIdA={props.simulationJobIdA}
            simulationJobIdB={props.simulationJobIdB}
          />
        ),
      },
    ];
  }, [props.simulationJobIdA, props.simulationJobIdB]);
  return <FlatPageRouteControl tabs={tabs} sx={props.sx} />;
};

export interface SimulationABTestProps {}

export const SimulationABTest = memo((props: SimulationABTestProps) => {
  const simulationSelector = useSimulationSelector({
    isStatic: false, // todo clarify
  });

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 10 }}
      isLoading={simulationSelector.isLoading}
      rows={8}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
          A/B Test
        </Typography>
        <Typography variant="mediumItalic">
          Source Data as of: MM/DD/YYYY
        </Typography>
      </Box>
      <CarSelectOptionField
        sx={{ width: 300, mt: 3.5 }}
        label="Simulation Group"
        options={simulationSelector.simulationGroupOptions}
        value={simulationSelector.simulationGroupId}
        onChange={simulationSelector.handleSimulationGroupIdChange}
      />
      <Box sx={{ display: "flex", mt: 5, alignItems: "flex-end", gap: 5 }}>
        <CarSelectOptionField
          sx={{ width: 300 }}
          label="Load Simulation A"
          options={simulationSelector.simulationJobOptions}
          value={simulationSelector.simulationJob1Id}
          onChange={simulationSelector.handleSimulationJob1IdChange}
        />
        <CarSelectOptionField
          sx={{ width: 300 }}
          label="Load Simulation B"
          options={simulationSelector.simulationJobOptions}
          value={simulationSelector.simulationJob2Id}
          onChange={simulationSelector.handleSimulationJob2IdChange}
        />
      </Box>
      {simulationSelector.simulationJob1Id &&
        simulationSelector.simulationJob2Id && (
          <Pages
            sx={{ mt: 5 }}
            simulationJobIdA={simulationSelector.simulationJob1Id}
            simulationJobIdB={simulationSelector.simulationJob2Id}
          />
        )}
    </TablePlaceHolder>
  );
});
