import { useContentPageContentListQuery } from "api/carApi.generated";

export enum CarPageContentCode {
  "ASSET_CLASS_MONTHLY_DISTANCE_FROM_TREND_DIRECTIONS" = "ASSET_CLASS_MONTHLY_DISTANCE_FROM_TREND_DIRECTIONS",
  "ASSET_CLASS_MONTHLY_REPORT_SIMULATED_FORWARD_PROBABILITY_DIRECTIONS" = "ASSET_CLASS_MONTHLY_REPORT_SIMULATED_FORWARD_PROBABILITY_DIRECTIONS",
  "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_DIRECTIONS" = "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_DIRECTIONS",
  "ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS_SECTOR_GRAPH" = "ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS_SECTOR_GRAPH",
  "ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS_ANALYSIS_DETAIL_SECTOR_GRAPH" = "ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS_ANALYSIS_DETAIL_SECTOR_GRAPH",
  "ASSET_CLASS_MONTHLY_REPORT_MULTI_PERIOD_RETURN_RETURN_REPORT" = "ASSET_CLASS_MONTHLY_REPORT_MULTI_PERIOD_RETURN_RETURN_REPORT",

  "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_STATIC_VIEW" = "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_STATIC_VIEW",
  "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_PRICE_DRIVEN_VIEW" = "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_PRICE_DRIVEN_VIEW",
  "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_OVERUNDER_VALUED_VIEW" = "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_OVERUNDER_VALUED_VIEW",
  "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_STATIC_VS_PRICE_DRIVEN_VIEW" = "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_STATIC_VS_PRICE_DRIVEN_VIEW",
  "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_ALPHA_BETA_VIEW" = "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_ALPHA_BETA_VIEW",
  "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_STATISTICS_VIEW" = "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_STATISTICS_VIEW",
  "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_ADVANCED_VIEW" = "ASSET_CLASS_MONTHLY_REPORT_SUMMARY_ADVANCED_VIEW",

  "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_OVERVIEW" = "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_OVERVIEW",
  "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_ONE_YEAR_UNDER_PERF_RISK" = "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_ONE_YEAR_UNDER_PERF_RISK",
  "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK_THREE_YEAR_UNDER_PERF_RISK" = "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK_THREE_YEAR_UNDER_PERF_RISK",
  "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK_CUMULATIVE_RETURN_PERF" = "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK_CUMULATIVE_RETURN_PERF",

  "ANALYZE_PORTFOLIO_GROUP_SELECT_ASSET_CLASS_OVERVIEW" = "ANALYZE_PORTFOLIO_GROUP_SELECT_ASSET_CLASS_OVERVIEW",
  "ANALYZE_PORTFOLIO_GROUP_CREATE_ALLOCATION" = "ANALYZE_PORTFOLIO_GROUP_CREATE_ALLOCATION",
  "ANALYZE_PORTFOLIO_GROUP_OPTIMIZER" = "ANALYZE_PORTFOLIO_GROUP_OPTIMIZER",
  "ANALYZE_PORTFOLIO_GROUP_EDIT_COMPARE" = "ANALYZE_PORTFOLIO_GROUP_EDIT_COMPARE",
  "PORTFOLIO_GROUP_PUBLISH_SELECTION" = "PORTFOLIO_GROUP_PUBLISH_SELECTION",
  "ANALYZE_PORTFOLIO_GROUP_PUBLISHED_PORTFOLIOS" = "ANALYZE_PORTFOLIO_GROUP_PUBLISHED_PORTFOLIOS",

  "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE",
  "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SUMMARY" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SUMMARY",
  "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_PERFORMANCE_RISK" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_PERFORMANCE_RISK",
  "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_MULT_PER_RETURN" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_MULT_PER_RETURN",
  "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_FORWARD_PROJECTIONS" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_FORWARD_PROJECTIONS",
  "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SCENARIO_ANALYSIS" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SCENARIO_ANALYSIS",
  "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SECTOR_ANALYSIS" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SECTOR_ANALYSIS",
  "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SECTOR_ANALYSIS_GICS_DETAIL" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_SECTOR_ANALYSIS_GICS_DETAIL",

  "PORTFOLIO_ANALYSIS_DASHBOARD" = "PORTFOLIO_ANALYSIS_DASHBOARD",
  "PORTFOLIO_ANALYSIS_ASSET_CLASS_SELECTION" = "PORTFOLIO_ANALYSIS_ASSET_CLASS_SELECTION",
  "PORTFOLIO_ANALYSIS_CREATE_MODEL" = "PORTFOLIO_ANALYSIS_CREATE_MODEL",
  "PORTFOLIO_ANALYSIS_CREATE_MODEL_AUTO_OPTIMIZATION" = "PORTFOLIO_ANALYSIS_CREATE_MODEL_AUTO_OPTIMIZATION",
  "PORTFOLIO_ANALYSIS_OPTIMIZATION" = "PORTFOLIO_ANALYSIS_OPTIMIZATION",
  "PORTFOLIO_ANALYSIS_EDIT_COMPARE" = "PORTFOLIO_ANALYSIS_EDIT_COMPARE",
  "PORTFOLIO_ANALYSIS_ANALYZE_SIMULATED_FORWARD_PROBABILITY" = "PORTFOLIO_ANALYSIS_ANALYZE_SIMULATED_FORWARD_PROBABILITY",
  "PORTFOLIO_ANALYSIS_ANALYZE_SUMMARY" = "PORTFOLIO_ANALYSIS_ANALYZE_SUMMARY",
  "PORTFOLIO_ANALYSIS_ANALYZE_PERFORMANCE_RISK" = "PORTFOLIO_ANALYSIS_ANALYZE_PERFORMANCE_RISK",
  "PORTFOLIO_ANALYSIS_ANALYZE_SECTOR_ANALYSIS" = "PORTFOLIO_ANALYSIS_ANALYZE_SECTOR_ANALYSIS",
  "PORTFOLIO_ANALYSIS_ANALYZE_MULTI_PERIOD_RETURN" = "PORTFOLIO_ANALYSIS_ANALYZE_MULTI_PERIOD_RETURN",
  "PORTFOLIO_ANALYSIS_ANALYZE_OVERVIEW" = "PORTFOLIO_ANALYSIS_ANALYZE_OVERVIEW",
  "PORTFOLIO_ANALYSIS_ANALYZE_SCENARIO_ANALYSIS" = "PORTFOLIO_ANALYSIS_ANALYZE_SCENARIO_ANALYSIS",

  "PLANNING_PLAN_INPUT_DIRECTIONS" = "PLANNING_PLAN_INPUT_DIRECTIONS",
  "PLANNING_WORKBENCH_PLAN_RESULTS_LANDING_PAGE" = "PLANNING_WORKBENCH_PLAN_RESULTS_LANDING_PAGE",
  "PLAN_WORKBENCH_PLAN_RESULTS_DIRECTIONS" = "PLAN_WORKBENCH_PLAN_RESULTS_DIRECTIONS",
  "PLAN_WORKBENCH_CAP_GAINS_DIRECTIONS" = "PLAN_WORKBENCH_CAP_GAINS_DIRECTIONS",
  "PLAN_WORKBENCH_STRESS_TEST_DIRECTIONS" = "PLAN_WORKBENCH_STRESS_TEST_DIRECTIONS",
  "PLAN_WORKBENCH_HOUSEHOLD_ALLOCATION_DIRECTIONS" = "PLAN_WORKBENCH_HOUSEHOLD_ALLOCATION_DIRECTIONS",
  "PLAN_WORKBENCH_ALLOCATION_BY_ACCOUNT_DIRECTIONS" = "PLAN_WORKBENCH_ALLOCATION_BY_ACCOUNT_DIRECTIONS",
  "PLAN_WORKBENCH_PUBLISH_PLAN_DIRECTIONS" = "PLAN_WORKBENCH_PUBLISH_PLAN_DIRECTIONS",

  "SIMULATION_ANALYSIS_SUMMARY" = "SIMULATION_ANALYSIS_SUMMARY",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_DISTANCE_FROM_TREND" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_DISTANCE_FROM_TREND",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_SIMULATED_FORWARD_PROBABILITIES" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_SIMULATED_FORWARD_PROBABILITIES",
  "SIMULATION_ANALYSIS_RETURNS_REAL" = "SIMULATION_ANALYSIS_RETURNS_REAL",
  "SIMULATION_ANALYSIS_YIELDS" = "SIMULATION_ANALYSIS_YIELDS",
  "SIMULATION_ANALYSIS_DATA_EXPLORER" = "SIMULATION_ANALYSIS_DATA_EXPLORER",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_CORRELATIONS" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_CORRELATIONS",

  "SIMULATION_ANALYSIS_STATIC_SUMMARY" = "SIMULATION_ANALYSIS_STATIC_SUMMARY",
  "SIMULATION_ANALYSIS_STATIC_RETURNS" = "SIMULATION_ANALYSIS_STATIC_RETURNS",
  "SIMULATION_ANALYSIS_STATIC_CORRELATIONS" = "SIMULATION_ANALYSIS_STATIC_CORRELATIONS",

  "SIMULATION_ANALYSIS_MODELS_MODELS" = "SIMULATION_ANALYSIS_MODELS_MODELS",
  "SIMULATION_ANALYSIS_MODELS_BEHAVIOR" = "SIMULATION_ANALYSIS_MODELS_BEHAVIOR",
  "SIMULATION_ANALYSIS_MODELS_RUN" = "SIMULATION_ANALYSIS_MODELS_RUN",

  "SIMULATION_ANALYSIS_AB_TEST_SUMMARY" = "SIMULATION_ANALYSIS_AB_TEST_SUMMARY",
  "SIMULATION_ANALYSIS_AB_TEST_SIMULATED_FORWARD_PROBABILITIES" = "SIMULATION_ANALYSIS_AB_TEST_SIMULATED_FORWARD_PROBABILITIES",
  "SIMULATION_ANALYSIS_AB_TEST_RETURNS" = "SIMULATION_ANALYSIS_AB_TEST_RETURNS",
  "SIMULATION_ANALYSIS_AB_TEST_YIELDS" = "SIMULATION_ANALYSIS_AB_TEST_YIELDS",

  "SIMULATION_ANALYSIS_RUN" = "SIMULATION_ANALYSIS_RUN",

  "SIMULATION_ANALYSIS_SIMULATION_RUN_SETTINGS" = "SIMULATION_ANALYSIS_SIMULATION_RUN_SETTINGS",

  "SIMULATION_ANALYSIS_X_RAY" = "SIMULATION_ANALYSIS_X_RAY",
  "SIMULATION_ANALYSIS_DATA_EXPLORER_FILTER_LB" = "SIMULATION_ANALYSIS_DATA_EXPLORER_FILTER_LB",
  "SIMULATION_ANALYSIS_SIMULATION_YIELD_CURVES" = "SIMULATION_ANALYSIS_SIMULATION_YIELD_CURVES",
}

export const usePageContent = () => {
  const pageContent = useContentPageContentListQuery();

  const items = pageContent.data?.flatMap((i) => i.page_section ?? []) ?? [];

  return {
    getContent: (code: CarPageContentCode) =>
      items.find((i) => i.code === code),
    isLoading: pageContent.isLoading,
  };
};

export type UsePageContent = ReturnType<typeof usePageContent>;
