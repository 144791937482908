import { styled, Typography } from "@mui/material";
import { CarVirtualTable } from "components/VirtualTable";
import { memo } from "react";
import { formatNumber, formatPercentFactor, isDefined } from "utils";
import { CarSimAnalyzePathTable } from "./SimulationAnalyzePathsDialog";
import { FieldTypeEnum } from "api/carApi.generated";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarTooltipBox } from "components/TooltipBox";

interface SimulationAnalyzePathTableProps {
  className?: string;
  table: CarSimAnalyzePathTable;
}

const formatValue = (value?: number | null, type?: FieldTypeEnum) =>
  isDefined(value)
    ? type === "percentage"
      ? formatPercentFactor(value, {
          decimalPlaces: 2,
          forceShowDecimals: true,
        })
      : formatNumber(value, {
          decimalPlaces: 2,
          forceShowDecimals: true,
        })
    : "-";

const Table = memo(({ className, table }: SimulationAnalyzePathTableProps) => {
  return (
    <CarVirtualTable
      className={className}
      columnCount={table.columns.length}
      rowWidth={80}
      rowCount={table.rows.length}
      rowHeaderWidth={200}
      onRenderColumnRowHeader={() => (
        <Typography
          sx={{ ml: 1, alignSelf: "flex-start" }}
          variant="h6SSemiBold"
        >
          Paths
        </Typography>
      )}
      sxColumnHeader={() => ({
        cursor: "pointer",
        userSelect: "none",
      })}
      onRenderColumnHeader={(col) => (
        <Typography variant="par02Regular">
          {table.columns.at(col)?.year}
        </Typography>
      )}
      onRenderRowHeader={(rowIndex) => {
        const row = table.rows.at(rowIndex);
        if (!row) {
          return null;
        }
        return (
          <Typography
            variant="par02Regular"
            sx={{ ml: 1, alignSelf: "flex-start" }}
          >
            {row.label}
            <CarTooltipBox
              sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
              className={pendoClasses.simulationFieldYAxis(row.code)}
            />
          </Typography>
        );
      }}
      onRenderCell={(col, row) =>
        formatValue(
          table.rows.at(row)?.values.at(col),
          table.rows.at(row)?.fieldType,
        )
      }
    />
  );
});

export const SimulationAnalyzePathTable = styled(Table)(() => ({}));
