import {
  RootPage,
  RootPageHeader,
  RootPageTransparentPaper,
  RootPageHeaderLink,
} from "components/RootPage";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { Navigate, Route, Routes } from "react-router-dom";
import { editPortfolioPages } from "route.const";
import { usePortfolioGroupInfo } from "pages/assetAllocation/usePortfolioGroupInfo";
import { useOptimizationJobsPuller } from "pages/assetAllocation/editPortfolioGroup/useOptimizationJobsPuller";
import { EditPortfolioGroupAssetClassSelection } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupAssetClassSelection";
import { EditPortfolioGroupEditCompare } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupEditCompare";
import { EditPortfolioGroupCreateAllocations } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupCreateAllocations";
import { EditPortfolioGroupAnalyze } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupAnalyze";
import { EditPortfolioGroupOptimization } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupOptimization";
import { SourceDataAsOf } from "pages/assetAllocation/SourceDataAsOf";

export const EditPortfolioPage = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;

  useOptimizationJobsPuller(portfolioGroupVersionId);

  return (
    <RootPage sx={{ backgroundColor: "white", paddingBottom: 0 }}>
      <RootPageHeader
        isSmall
        title={`Portfolio Analysis - ${groupInfo.title}`}
        isLoading={groupInfo.isLoading}
        links={
          <>
            <RootPageHeaderLink
              label="Asset Class Selection"
              url={editPortfolioPages.assetClassSelection}
            />
            <RootPageHeaderLink
              label="Create Model"
              url={editPortfolioPages.createModel}
            />
            {groupInfo.isPublished && (
              <>
                <RootPageHeaderLink
                  label="Optimization"
                  url={editPortfolioPages.optimization}
                />
                <RootPageHeaderLink
                  label="Edit/Compare"
                  url={editPortfolioPages.editCompare}
                />
                <RootPageHeaderLink
                  label="Analyze"
                  url={editPortfolioPages.analyze}
                />
              </>
            )}
          </>
        }
      />
      <RootPageTransparentPaper>
        <SourceDataAsOf />
        <Routes>
          <Route
            path={editPortfolioPages.assetClassSelection}
            element={<EditPortfolioGroupAssetClassSelection />}
          />
          <Route
            path={editPortfolioPages.createModel}
            element={<EditPortfolioGroupCreateAllocations />}
          />
          <Route
            path={editPortfolioPages.editCompare}
            element={<EditPortfolioGroupEditCompare />}
          />

          <Route
            path={editPortfolioPages.optimization}
            element={<EditPortfolioGroupOptimization />}
          />
          <Route
            path={editPortfolioPages.analyze}
            element={<EditPortfolioGroupAnalyze />}
          />
          <Route
            path="*"
            element={
              <Navigate to={editPortfolioPages.assetClassSelection} replace />
            }
          />
        </Routes>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
