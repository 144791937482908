import {
  Role,
  useCommonFirmMembersDestroyMutation,
  useCommonFirmMembersListQuery,
  useCommonFirmMembersUpdateMutation,
  useCommonInvitationsCreateMutation,
  useCommonInvitationsDestroyMutation,
  useCommonInvitationsListQuery,
  useCommonInvitationsUpdateMutation,
} from "api/carApi.generated";
import { useFirm } from "./useFirm";
import { CarInvitation, CarUser } from "types";
import { useRoles } from "app/useRoles";
import { useConfirm } from "app/useConfirm";
import { useUser } from "./useUser";
import { useLicenses } from "app/useLicenses";

export interface CarUserDisplay extends CarUser {
  name: string;
  isCurrentUser: boolean;
  roleName: string;
  roleCode: string;
  statusName: string;
  isInvitation: boolean;
}

export interface CarRoleDisplay extends Role {
  seats: number;
  usedSeats: number;
}

const userToUserDisplay =
  (params: {
    getRole: (roleId?: string) => Role | undefined;
    getIsCurrentUser: (userId?: string) => boolean;
  }) =>
  (user: CarUser): CarUserDisplay => {
    const role = params.getRole(user.role);

    return {
      ...user,
      name: `${user.first_name ?? ""} ${user.last_name ?? ""}`.trim() || "-",
      roleName: role?.name ?? "",
      roleCode: role?.code ?? "",
      isCurrentUser: params.getIsCurrentUser(user.id),
      statusName: "Active",
      isInvitation: false,
    };
  };

const invitationToDisplay =
  ({ getRole }: { getRole: (roleId?: string) => Role | undefined }) =>
  (invitation: CarInvitation): CarUserDisplay => {
    const role = getRole(invitation.role);

    return {
      ...invitation,
      name: `-`,
      roleName: role?.name ?? "",
      roleCode: role?.code ?? "",
      statusName: "Invited",
      isInvitation: true,
      isCurrentUser: false,
    };
  };

export const useSettingsFirmUsers = () => {
  const user = useUser();
  const firm = useFirm();
  const firmMembers = useCommonFirmMembersListQuery();
  const invitations = useCommonInvitationsListQuery();
  const roles = useRoles();
  const licenses = useLicenses();
  const confirm = useConfirm();

  const [deleteUser] = useCommonFirmMembersDestroyMutation();
  const [updateUser] = useCommonFirmMembersUpdateMutation();

  const [removeInvitation] = useCommonInvitationsDestroyMutation();
  const [updateInvitation] = useCommonInvitationsUpdateMutation();

  const [inviteReq, { isLoading: isInviting }] =
    useCommonInvitationsCreateMutation();

  const checkIsCurrentUser = (userId?: string) => userId === user.user.id;

  const activeUsers =
    firmMembers.data?.map(
      userToUserDisplay({
        getRole: (roleId?: string) => roles.getRole(roleId),
        getIsCurrentUser: checkIsCurrentUser,
      }),
    ) ?? [];

  const invites =
    invitations.data?.map(
      invitationToDisplay({
        getRole: (roleId?: string) => roles.getRole(roleId),
      }),
    ) ?? [];

  const users = [...activeUsers, ...invites];

  const handleSendInvite = async (email?: string, role?: string) => {
    const trimmedEmail = email?.trim();

    if (!trimmedEmail || !role) {
      return;
    }

    return inviteReq({
      invitation: {
        email: trimmedEmail,
        role,
      },
    });
  };

  const handleChangeUserRole = async (userId?: string, roleId?: string) => {
    const user = firmMembers.data?.find((i) => i.id === userId);

    if (checkIsCurrentUser(user?.id)) {
      return;
    }

    if (user && roleId) {
      await updateUser({
        id: user.id ?? "",
        firmMember: {
          ...user,
          role: roleId,
        },
      });
    }
  };

  const roleItems = roles.items.map<CarRoleDisplay>((r) => {
    const license = licenses.getRoleLicense(r);

    return {
      ...r,
      seats: license?.paid_qty ?? 0,
      usedSeats: license?.used_qty ?? 0,
      // usedSeats: users.filter((i) => i.roleCode === r.code).length,
    };
  });

  const handleChangeInvitationRole = async (
    invitationId?: string,
    roleId?: string,
  ) => {
    const invitation = invitations.data?.find((i) => i.id === invitationId);

    if (invitation && roleId) {
      await updateInvitation({
        id: invitation.id ?? "",
        invitation: {
          ...invitation,
          role: roleId,
        },
      });
    }
  };

  const handleDeleteUser = async (userId?: string) => {
    if (!userId) {
      return;
    }

    if (checkIsCurrentUser(userId)) {
      return;
    }

    await confirm({
      label: "Delete User",
      message: `Are you sure you want to delete user "${users.find(
        (i) => i.id === userId,
      )?.name}"?`,
      applyLabel: "Delete",
      onApplying: async () => {
        await deleteUser({
          id: userId,
        });
        return true;
      },
    });
  };

  const handleRemoveInvitation = async (invitationId?: string) => {
    if (!invitationId) {
      return;
    }

    await confirm({
      label: "Remove Invitation",
      message: `Are you sure you want to remove invite sent to "${users.find(
        (i) => i.id === invitationId,
      )?.email}"?`,
      applyLabel: "Remove",
      onApplying: async () => {
        await removeInvitation({
          id: invitationId,
        });
        return true;
      },
    });
  };

  return {
    ...firm,
    isLoading:
      user.isLoading ||
      firm.isLoading ||
      firmMembers.isLoading ||
      invitations.isLoading ||
      licenses.isLoading,
    handleSendInvite,
    isInviting,
    users,
    roles: roleItems,
    handleChangeUserRole,
    handleDeleteUser,

    handleChangeInvitationRole,
    handleRemoveInvitation,
  };
};

export type UseSettingsFirmUsers = ReturnType<typeof useSettingsFirmUsers>;
