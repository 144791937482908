import { IconButton, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import { useState, Fragment } from "react";
import { CarButton, CarLoadingButton } from "components/Buttons";
import { CarTextField } from "components/Inputs";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPageBox } from "components/RootPage";
import { CarClient } from "types";
import { CarIconEdit } from "icons";
import { UseClientSummary } from "./useClientSummary";
import { CarTierField } from "components/TierField";
import { useTiers } from "app/useTiers";

interface ClientSummaryProfileProps {
  clientSummary: UseClientSummary;
}

const Label = styled("span")(({ theme }) => ({
  ...theme.typography.par01SemiBold,
  marginRight: theme.spacing(1),
}));

export const ClientSummaryProfile = ({
  clientSummary,
}: ClientSummaryProfileProps) => {
  const [editMode, setEditMode] = useState(false);
  const [editingClient, setEditingClient] = useState<CarClient>({});

  const { getTier } = useTiers();

  const editClient = () => {
    if (clientSummary.client) {
      setEditingClient({ ...clientSummary.client });
      setEditMode(true);
    }
  };

  const cancelEdit = () => {
    setEditMode(false);
  };

  const saveClient = async () => {
    if (await clientSummary.updateClient(editingClient)) {
      setEditMode(false);
    }
  };

  const profile = editMode ? (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        rowGap: 2,
        ".MuiInputBase-root": {
          backgroundColor: "white",
        },
      }}
    >
      {/* <CarTextField
        label="Name"
        required
        value={editingClient.name}
        onChange={(name) =>
          setEditingClient({ ...editingClient, name: name ?? "" })
        }
      /> */}
      <CarTextField
        label="Address"
        multiline
        maxRows={6}
        rows={4}
        value={editingClient.address ?? undefined}
        onChange={(address) => setEditingClient({ ...editingClient, address })}
      />
      <CarTextField
        label="Phone"
        value={editingClient.phone_num ?? undefined}
        onChange={(phone_num) =>
          setEditingClient({ ...editingClient, phone_num })
        }
      />
      <CarTextField
        label="Email"
        value={editingClient.email ?? undefined}
        onChange={(email) => setEditingClient({ ...editingClient, email })}
      />
      <CarTierField
        fullWidth={true}
        label="Tier"
        value={editingClient.tier}
        onChange={(tier) => setEditingClient({ ...editingClient, tier })}
      />
      <Box
        sx={{
          mt: 2,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 2,
        }}
      >
        <CarLoadingButton
          sx={{ width: 100 }}
          onClick={saveClient}
          isLoading={clientSummary.isLoading}
          fullWidth
        >
          Save
        </CarLoadingButton>
        <CarButton
          fullWidth
          sx={{ width: 100 }}
          variant="outlined"
          onClick={cancelEdit}
        >
          Cancel
        </CarButton>
      </Box>
    </Box>
  ) : (
    <TablePlaceHolder isLoading={clientSummary.isLoading}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          flexWrap: "nowrap",
        }}
      >
        <Typography variant="par01Regular">
          <Label>Client 1:</Label>
          {clientSummary.clientFullName}
          {clientSummary.spouseFullName && (
            <>
              <br />
              <Label>Spouse:</Label>
              {clientSummary.spouseFullName}
            </>
          )}
        </Typography>

        {clientSummary.relationItems.length > 0 && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Label>Relations:</Label>
            <Box
              sx={{
                ml: 1,
                display: "grid",
                gridTemplateColumns: "auto auto",
                columnGap: 1,
                alignSelf: "flex-start",
              }}
            >
              {clientSummary.relationItems.map((i, idx) => (
                <Fragment key={idx}>
                  <Typography variant="par01Regular">{`\u2022  ${i.name}: ${i.relation},`}</Typography>
                  <Typography variant="par01Regular">{i.dob}</Typography>
                </Fragment>
              ))}
            </Box>
          </Box>
        )}

        <Typography variant="par01Regular">
          <Label>Address:</Label>
          {clientSummary.client?.address || "-"}
        </Typography>

        <Typography variant="par01Regular">
          <Label>Phone:</Label>
          {clientSummary.client?.phone_num || "-"}
        </Typography>
        <Typography variant="par01Regular" sx={{ color: "black" }}>
          <Label sx={{ color: "black" }}>Email:</Label>
          {clientSummary.client?.email ? (
            <Typography
              component="a"
              href={`mailto:${clientSummary.client?.email}`}
              variant="par01Regular"
              sx={{ color: "black" }}
            >
              {clientSummary.client?.email}
            </Typography>
          ) : (
            <Typography variant="par01Regular" sx={{ color: "black" }}>
              -
            </Typography>
          )}
        </Typography>

        <Typography variant="par01Regular" sx={{ color: "black" }}>
          <Label sx={{ color: "black" }}>Tier:</Label>
          {getTier(clientSummary.client?.tier)?.name ?? "-"}
        </Typography>
      </Box>
    </TablePlaceHolder>
  );

  return (
    <RootPageBox sx={{ px: 2.5, py: 2, flex: "none" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h5SSemiBold">Client Profile</Typography>
        {!editMode && (
          <IconButton size="small" color="inherit" onClick={editClient}>
            <CarIconEdit
              sx={{ color: "caravelOrangePrimary", fontSize: "18px" }}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          mt: 1.5,
          border: "solid 1px",
          borderColor: "gray6",
          borderRadius: "5px",
          backgroundColor: "gray1",
          p: 2,
          height: "100%",
        }}
      >
        {profile}
      </Box>
    </RootPageBox>
  );
};
