import {
  useCommonAuthSelfFirmRetrieveQuery,
  useCommonAuthSelfFirmUpdateMutation,
} from "api/carApi.generated";
import { CarFirm } from "types";

const defaultFirm: CarFirm = {
  id: "",
  name: "",
};

export const useFirm = () => {
  const { data, isLoading } = useCommonAuthSelfFirmRetrieveQuery();
  const [updateFirmMut] = useCommonAuthSelfFirmUpdateMutation();

  const updateFirm = async (firm: CarFirm) => {
    if (firm.logo?.startsWith("http")) {
      firm = { ...firm };
      delete firm.logo;
    }

    return await updateFirmMut({
      firm,
    });
  };

  const isTrialExpired =
    !isLoading && !data?.is_paid && !data?.is_trial && !data?.debug;

  const isPaid = !isLoading && data?.is_paid;

  return {
    isLoading,
    isPaid,
    isTrialExpired,
    firm: data ?? defaultFirm,
    updateFirm,
  };
};

export type UseFirm = ReturnType<typeof useFirm>;
