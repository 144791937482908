import {
  Box,
  BoxProps,
  Typography,
  TypographyProps,
  useTheme,
} from "@mui/material";
import { UseChartHighlight } from "app/useChartHighlight";
import { ReactNode, useEffect, useState } from "react";
import { isDefined } from "utils";

interface LegendItemProps {
  item: CarLegendItem;
  isLabelOnly?: boolean;
  chartHighlight?: UseChartHighlight;
  labelVariant?: TypographyProps["variant"];
  size?: "small" | "medium";
  disableHover?: boolean;
}

export const CarChartLegendItem = (props: LegendItemProps) => {
  const [isHighlighted, setIsHighLighted] = useState(false);

  const { datasetIndex, dataIndex } = props.item;

  useEffect(() => {
    const id = {};

    props.chartHighlight?.subscribe(id, () => {
      const value = props.chartHighlight?.getValue();

      setIsHighLighted(
        (!isDefined(datasetIndex) || datasetIndex === value?.datasetIndex) &&
          (!isDefined(dataIndex) || dataIndex === value?.dataIndex),
      );
    });

    return () => {
      props.chartHighlight?.unsubscribe(id);
    };
  }, [props.chartHighlight, datasetIndex, dataIndex]);

  const theme = useTheme();
  const isSmall = props.size === "small";
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: 1.5,
        backgroundColor: isHighlighted ? "lightOrange" : "unset",
        padding: theme.spacing(0, 1),
        borderRadius: 1,
        minHeight: isSmall ? 27 : 38,
        cursor: "default",
      }}
      onMouseEnter={() => {
        if (!props.disableHover) {
          props.chartHighlight?.setValue({
            datasetIndex,
            dataIndex,
          });
        }
      }}
      onMouseLeave={() => {
        if (!props.disableHover) {
          props.chartHighlight?.resetValue();
        }
      }}
    >
      <Box
        sx={{
          width: isSmall ? 15 : 20,
          height: isSmall ? 15 : 20,
          border: "1px solid",
          borderColor: "gray6",
          borderRadius: props.item.isCircle ? "50%" : "4px",
          backgroundColor: props.item.color,
          flexShrink: 0,
          display: props.isLabelOnly ? "none" : undefined,
        }}
      />
      <Typography
        variant={props.labelVariant ?? "par02Regular"}
        sx={{ color: "softBlack" }}
      >
        {props.item.label}
      </Typography>
    </Box>
  );
};

export interface CarLegendItem {
  label: ReactNode;
  color: string;
  isCircle?: boolean;
  dataIndex?: number;
  datasetIndex?: number;
  hidden?: boolean;
}

interface CarChartLegendProps extends BoxProps {
  label?: string;
  items: CarLegendItem[];
  labelVariant?: TypographyProps["variant"];
  chartHighlight?: UseChartHighlight;
  size?: "small" | "medium";
  disableHover?: boolean;
}

export const CarChartLegend = ({
  label,
  items,
  chartHighlight,
  labelVariant,
  size,
  disableHover,
  ...props
}: CarChartLegendProps) => {
  return (
    <Box
      {...props}
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "center",
        ...props.sx,
      }}
    >
      {label && (
        <CarChartLegendItem
          item={{ color: "", label }}
          labelVariant={labelVariant}
          isLabelOnly
          size={size}
          disableHover={disableHover}
        />
      )}
      {items.map((i, idx) =>
        i.hidden ? null : (
          <CarChartLegendItem
            key={idx}
            chartHighlight={chartHighlight}
            item={i}
            labelVariant={labelVariant}
            size={size}
            disableHover={disableHover}
          />
        ),
      )}
    </Box>
  );
};
