import { Box, Collapse, Typography } from "@mui/material";
import { AddRowButton } from "components/Buttons";
import { CarInflationField, CarTextCalcField } from "components/Inputs";
import {
  CarCurrencyCalcField,
  CarNumberFieldDelayed,
  CarPercentFieldDelayed,
} from "components/NumberField";
import {
  CarTableMemoTable,
  CarTableRow,
  CarTableSpecificRowProps,
} from "components/Table";
import { useHealthcare } from "features/healthcare/useHealthcare";
import React from "react";
import { CarDisclosure } from "components/Disclosure";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarHealthcare } from "types";
import { useRelationshipLight } from "app/useRelationshipLight";
import { setDisplayName } from "utils";

const HealthcareRow = React.memo(
  (props: CarTableSpecificRowProps<CarHealthcare>) => {
    const data = useRelationshipLight();
    return (
      <CarTableRow
        {...props}
        columns={[
          {
            id: "relationship",
            label: "Patient",
            fraction: 1.5,
            renderCell: ({ item, onChange }) => (
              <CarTextCalcField value={data.getFullName(item.relationship)} />
            ),
          },
          {
            id: "start_age",
            label: "*Start Age",
            fraction: 1,
            renderCell: ({ item, onChange }) => (
              <CarNumberFieldDelayed
                required
                value={item.start_age}
                onChange={(start_age) =>
                  onChange({
                    ...item,
                    start_age,
                  })
                }
              />
            ),
          },
          {
            id: "income_level",
            label: "Medicare Bracket",
            fraction: 1,
            renderCell: ({ item }) => (
              <CarCurrencyCalcField value={item.income_level} />
            ),
          },
          {
            id: "annual_cost_75",
            label: "Start - 75",
            headerSx: {
              backgroundColor: "gray3",
            },
            fraction: 1,
            renderCell: ({ item }) => (
              <CarCurrencyCalcField
                value={
                  (item.start_age ?? 0) <= 75 ? item.annual_cost_75 : undefined
                }
              />
            ),
          },
          {
            id: "annual_cost_85",
            label: "75 - 85",
            headerSx: {
              backgroundColor: "gray3",
            },
            fraction: 1,
            renderCell: ({ item }) => (
              <CarCurrencyCalcField
                value={
                  (item.start_age ?? 0) <= 85 ? item.annual_cost_85 : undefined
                }
              />
            ),
          },
          {
            id: "annual_cost_past_85",
            label: "85+",
            headerSx: {
              backgroundColor: "gray3",
            },
            fraction: 1,
            renderCell: ({ item }) => (
              <CarCurrencyCalcField value={item.annual_cost_past_85} />
            ),
          },
          {
            id: "assumed_percent_above_average",
            label: "Assumed\u00A0% above Avg.",
            fraction: 1,
            renderCell: ({ item, onChange }) => (
              <CarPercentFieldDelayed
                value={item.assumed_percent_above_average}
                onChange={(assumed_percent_above_average) =>
                  onChange({ ...item, assumed_percent_above_average })
                }
              />
            ),
          },
          {
            id: "inflation",
            label: "*Inflation",
            fraction: 1,
            renderCell: ({ item, onChange }) => (
              <CarInflationField
                value={item.inflation}
                onChange={(inflation) => onChange({ ...item, inflation })}
              />
            ),
          },
        ]}
      />
    );
  }
);

setDisplayName({ HealthcareRow });

export const HealthcareQuick = React.memo(() => {
  const data = useHealthcare();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TablePlaceHolder isLoading={data.isLoading}>
        <Collapse in={data.items.length > 0}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "3.5fr 3fr 2fr",
              height: 24,
            }}
          >
            <Box />
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ margin: "auto" }} variant="mediumItalic">
                Annual Cost
              </Typography>
            </Box>
            <Box />
          </Box>
          <CarTableMemoTable
            items={data.items}
            rowComponent={HealthcareRow}
            onChange={data.handleChange}
            onDelete={data.handleDelete}
            pendoPrefix="healthcare"
          />
        </Collapse>
      </TablePlaceHolder>
      <AddRowButton
        sx={{ alignSelf: "flex-start", my: 1.5 }}
        disabled={!data.canAddRow}
        onClick={data.addRow}
      >
        Add Healthcare
      </AddRowButton>
      <CarDisclosure>
        Estimates are derived from a variety of sources. Your results will be
        different. See our disclosures for more information. Assumed % above
        average should be used to reflect your specific health assumptions
        compared to averages. Inflation in healthcare costs will deviate from
        average inflation. See our disclosures for more information.
      </CarDisclosure>
    </Box>
  );
});
