import {
  BarChartGoal,
  BarChartYear,
  UseBarChart,
  getEmptyBarChartYear,
} from "../planResults/useBarChart";
import { splitArrayIntoChunks, formatCurrency } from "utils";
import { parseISO } from "date-fns";
import { CarGoalStatusEnum } from "types";
import { UsePlanSummary } from "../planSummary/usePlanSummary";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfSubHeading } from "./PdfHeading";
import { CarPdfGoalsLegend } from "./PdfGoalsLegend";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTable,
  CarPdfTextCell,
  CarPdfViewCell,
} from "components/PdfTable";
import {
  CarPdfIconFailure,
  CarPdfIconPartial,
  CarPdfIconSuccess,
} from "components/PdfIcon";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfChart } from "components/PdfChart";
import { getGoalTimelineChartData } from "pages/financialPlanning/workbench/planResults/PlanResultCardGoalTimeline";
import { useTheme } from "@mui/material";
import { ChartPlugins } from "chartUtils";
import { proximanovaCondensedFontFamily } from "pdfUtils";

interface PeriodGoal {
  id: string;
  nameWithPriority: string;
  status: CarGoalStatusEnum;
  startYear: number;
  endYear: number;
  amount: number;
}

const legendFontSize = 12;
const xAxisFontSize = 10;
const yAxisFontSize = 10;
const barPercentage = 0.5;

interface GoalTimelinePeriodProps {
  pageProps: CarPdfPageProps;
  years: BarChartYear[];
  goals: PeriodGoal[];
}

const GoalTimelinePeriod = (props: GoalTimelinePeriodProps) => {
  const theme = useTheme();
  const barChart: UseBarChart = {
    goals: props.goals.map<BarChartGoal>((i) => ({
      goalId: i.id,
      label: i.nameWithPriority,
    })),
    years: props.years,
  };

  const firstYear = props.years[0].year ?? 0;
  const lastYear = props.years[props.years.length - 1].year ?? 0;

  const label =
    firstYear === lastYear
      ? firstYear.toString()
      : `${firstYear} - ${lastYear}`;

  const chartData = getGoalTimelineChartData(barChart, theme);

  return (
    <CarPdfPage {...props.pageProps}>
      <CarPdfSubHeading label={`Goal Timeline: ${label}`} />
      <CarPdfChart
        width={518}
        height={320}
        style={{ marginTop: 15 }}
        type="bar"
        options={{
          plugins: {
            roundedBackground: {
              backgroundColor: "#fafafa",
              contextColor: "white",
              borderColor: "#b3b1b1",
              borderRadius: 0,
            },
            legend: {
              position: "bottom",
              labels: {
                boxHeight: 10,
                boxWidth: 10,
                padding: 20,
                color: "black",
                font: {
                  family: proximanovaCondensedFontFamily,
                  size: legendFontSize,
                },
              },
            },
          },
          scales: {
            x: {
              stacked: true,
              border: { display: false },
              grid: {
                color: "#f0eeed",
                drawTicks: false,
              },

              ticks: {
                color: "black",
                font: {
                  size: xAxisFontSize,
                },
              },
            },
            y: {
              stacked: true,
              border: { display: false },
              grid: {
                color: "#f0eeed",
                drawTicks: false,
              },
              ticks: {
                callback: (val) => formatCurrency(val as any),
                color: "black",
                font: {
                  size: yAxisFontSize,
                },
              },
            },
          },
        }}
        data={{
          datasets: chartData.datasets.map((i) => ({ ...i, barPercentage })),
          labels: chartData.labels,
        }}
        plugins={[ChartPlugins.roundedBackground]}
      />
      {props.goals.length > 0 && (
        <CarPdfBox style={{ marginTop: 20 }}>
          <CarPdfTable colWidths={[1.8, 0.8, 1, 1, 1.5]}>
            <CarPdfHeader paddingVertical={14}>
              <CarPdfHeaderCell>Goal Name (Priority)</CarPdfHeaderCell>
              <CarPdfHeaderCell>Status</CarPdfHeaderCell>
              <CarPdfHeaderCell>Start</CarPdfHeaderCell>
              <CarPdfHeaderCell>End</CarPdfHeaderCell>
              <CarPdfHeaderCell>*Annual Amount</CarPdfHeaderCell>
            </CarPdfHeader>
            {props.goals.map((i, idx) => (
              <CarPdfRow key={idx} paddingVertical={10}>
                <CarPdfTextCell
                  style={{
                    textAlign: "left",
                    paddingHorizontal: 5,
                  }}
                >
                  {i.nameWithPriority}
                </CarPdfTextCell>
                <CarPdfViewCell
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    paddingVertical: 0,
                  }}
                >
                  {i.status === "success" ? (
                    <CarPdfIconSuccess />
                  ) : i.status === "failure" ? (
                    <CarPdfIconFailure />
                  ) : (
                    <CarPdfIconPartial />
                  )}
                </CarPdfViewCell>
                <CarPdfTextCell>{i.startYear}</CarPdfTextCell>
                <CarPdfTextCell>{i.endYear}</CarPdfTextCell>
                <CarPdfTextCell>{formatCurrency(i.amount)}</CarPdfTextCell>
              </CarPdfRow>
            ))}
          </CarPdfTable>
          <CarPdfBox style={{ height: 20 }} />
          <CarPdfGoalsLegend />
        </CarPdfBox>
      )}
    </CarPdfPage>
  );
};

const yearBetween = (year: number, startDate: string, endDate: string) =>
  year >= parseISO(startDate).getFullYear() &&
  year <= parseISO(endDate).getFullYear();

interface GoalTimelinePdfPageProps {
  pageProps: CarPdfPageProps;
  planResults: UsePlanResults;
  planSummary: UsePlanSummary;
}

const periodSpan = 10;

export const GoalTimelinePdfPage = ({
  planResults,
  planSummary,
  pageProps,
}: GoalTimelinePdfPageProps) => {
  const periods = Array.from(
    splitArrayIntoChunks(planResults.barChart.years, periodSpan),
  );
  const lastPeriod = periods[periods.length - 1];

  // filling up last period to match full periodSpan
  if (lastPeriod.length > 0) {
    while (lastPeriod.length < periodSpan) {
      lastPeriod.push(
        getEmptyBarChartYear(lastPeriod[lastPeriod.length - 1].year + 1),
      );
    }
  }

  const periodProps = periods
    // .filter((i) => i.length > 0) // per Sarah: do not skip empty for now
    .map<GoalTimelinePeriodProps>((i) => {
      const periodStart = i[0].year ?? 0;
      const periodEnd = i[i.length - 1].year ?? 0;

      return {
        years: i,
        goals: planSummary.goals
          .filter((g) =>
            i.some((y) => yearBetween(y.year, g.startDate, g.endDate)),
          )
          .map<PeriodGoal>((g) => {
            const startYear = Math.max(
              periodStart,
              parseISO(g.startDate).getFullYear(),
            );

            const endYear = Math.min(
              periodEnd,
              parseISO(g.endDate).getFullYear(),
            );

            const period = g.calcPeriod(startYear, endYear);

            return {
              id: g.id,
              nameWithPriority: g.nameWithPriority,
              priority: g.priority,
              startYear,
              endYear,
              status: period.status,
              amount: period.amount,
            };
          }),
        pageProps,
      };
    });

  return (
    <>
      {periodProps.map((i, idx) => (
        <GoalTimelinePeriod key={idx} {...i} />
      ))}
    </>
  );
};
