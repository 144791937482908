import { Box, BoxProps, IconButton, Typography, useTheme } from "@mui/material";
import { ComponentProps, useCallback, useEffect, useMemo, useRef } from "react";
import { Chart } from "react-chartjs-2";
import { formatPercent } from "utils";
import {
  ChartActiveElement,
  ChartTooltip,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { addYears, format } from "date-fns";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { ChartTypeRegistry } from "chart.js";
import { CmaModelChartData } from "api/carApi.generated";
import { createPendoClassName, pendoClasses } from "app/thirdParty/pendo";
import { ChartPlugins } from "chartUtils";
import { CarIconZoomIn, CarIconZoomOut } from "icons";
import { CarTooltipBox } from "components/TooltipBox";

type Options = ComponentProps<typeof Chart>["options"];

export interface DftTotalReturnsDataPoint {
  x: string;
  y: number;
}

interface DftTotalReturnsData {
  trendLine: { label: string; data: DftTotalReturnsDataPoint[] };
  historicLine: { label: string; data: DftTotalReturnsDataPoint[] };
  xLabel: string;
  yLabel: string;
}

export const getPortfolioGroupDftTotalReturnsData = ({
  data,
  assetClassName,
}: {
  data: CmaModelChartData;
  assetClassName: string;
}): DftTotalReturnsData => {
  const result: DftTotalReturnsData = {
    trendLine: {
      label: "Trend Line",
      data:
        data.trend_x?.map((i, idx) => ({
          x: i ?? "",
          y: data.trend_y_trend_line?.[idx] ?? 0,
        })) ?? [],
    },
    historicLine: {
      label: "Historic Line",
      data:
        data.trend_x?.map((i, idx) => ({
          x: i ?? "",
          y: data.trend_y_historic_line?.[idx] ?? 0,
        })) ?? [],
    },
    xLabel: `${assetClassName} Total Returns`,
    yLabel: "Value Added Monthly Index",
  };
  return result;
};

interface ChartDftTotalReturnsProps {
  sx?: BoxProps["sx"];
  data: CmaModelChartData;
  assetClassName: string;
  pendoPrefix: string;
  activeElement: ChartActiveElement;
  isWhiteContext: boolean;
}

export const ChartDftTotalReturns = ({
  sx,
  assetClassName,
  data,
  pendoPrefix,
  activeElement,
  isWhiteContext,
}: ChartDftTotalReturnsProps) => {
  const chartData = useMemo(
    () => getPortfolioGroupDftTotalReturnsData({ assetClassName, data }),
    [assetClassName, data],
  );

  const { trendLine, historicLine, yLabel, xLabel } = chartData;
  const theme = useTheme();
  const { tooltipPlugin, tooltipData } = useChartTooltip({
    chartName: "ChartTotalReturns",
    activeElement,
  });
  const chartRef = useRef<ChartJSOrUndefined<
    keyof ChartTypeRegistry,
    {
      x: string;
      y: number;
    }[],
    unknown
  > | null>();

  useEffect(() => {
    if (chartRef.current) {
      if (
        activeElement.element &&
        activeElement.element.chartName === "ChartDftVersusTenYearReturn" &&
        activeElement.element.datasetIndex === 3 &&
        activeElement.element.index < historicLine.data.length
      ) {
        chartRef.current.setActiveElements([
          { datasetIndex: 1, index: activeElement.element.index },
        ]);
        chartRef.current.tooltip?.setActiveElements(
          [{ datasetIndex: 1, index: activeElement.element.index }],
          { x: 0, y: 0 },
        );
      } else if (!activeElement.element) {
        chartRef.current.setActiveElements([]);
        chartRef.current.tooltip?.setActiveElements([], { x: 0, y: 0 });
      }
    }
  }, [activeElement, historicLine.data.length]);

  const { minY, fromY } = useMemo(() => {
    const maxY = Math.max(
      ...trendLine.data.map((d) => d.y),
      ...historicLine.data.map((d) => d.y),
    );
    const toY = (v: number) => (maxY * 1 === 0 ? v : (v / 1) * maxY);
    const fromY = (v: number) => (maxY * 1 === 0 ? v : (v / maxY) * 1);
    const minY = Math.min(
      ...trendLine.data.map((d) => d.y),
      ...historicLine.data.map((d) => d.y),
    );

    return { minY, maxY, toY, fromY };
  }, [trendLine, historicLine]);

  const refForPlugin = useRef({ fromY });
  refForPlugin.current = { fromY };

  const renderTooltip: RenderTooltip = useCallback(
    (datasetIndex, dataIndex) => {
      const ds = datasetIndex === 0 ? trendLine : historicLine;
      const value = ds?.data?.[dataIndex]?.y ?? 0;
      const dateStr = ds?.data?.[dataIndex]?.x;
      const date = dateStr ? format(new Date(dateStr), "yyyy MMM") : undefined;
      return (
        <>
          <Box
            sx={{
              fontSize: 15,
              fontWeight: 600,
              color: "black",
              whiteSpace: "nowrap",
            }}
          >
            <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
              {ds?.label}
            </Typography>
            {Boolean(date) && (
              <>
                <br />
                <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
                  {date}
                </Typography>
              </>
            )}
            <br />
            <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
              {formatPercent(value, 1)}
            </Typography>
          </Box>
        </>
      );
    },
    [trendLine, historicLine],
  );

  const options = useMemo<Options>(
    () => ({
      maintainAspectRatio: false,
      animation: {
        duration: 500,
      },
      scales: {
        x: {
          // offset: true,
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 13,
              weight: "400",
            },
            color: theme.palette.softBlack,
          },
          grid: {
            display: false,
          },
          type: "time",
          time: {
            unit: "year",
          },
        },
        y: {
          offset: false,
          type: "logarithmic",
          title: {
            text: yLabel,
            display: true,
            font: {
              family: theme.typography.fontFamily,
              size: 16,
              weight: "600",
            },
          },

          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 13,
              weight: "400",
            },
            color: theme.palette.softBlack,
            callback: (value) => "       ", // this will roughly align charts on left side  //(value === minY ? "" : `${value} x 10⁰`),
          },

          border: { display: false },
          grid: {
            display: true,
            color: (ctx) => {
              return ctx.tick.value === 0
                ? theme.palette.gray7
                : theme.palette.gray2;
            },
            lineWidth: 1,
            tickColor: theme.palette.white,
          },
          min: minY,
        },
      },
      interaction: {
        mode: "point",
        intersect: false,
      },
      plugins: {
        title: {
          display: true,
          align: "start",
          fullSize: false,
          text: "", // xLabel, // real text in typography to position tooltip box
          font: {
            family: theme.typography.fontFamily,
            size: 19,
            weight: "600",
          },
          color: theme.palette.softBlack,
          padding: {
            bottom: 6,
          },
        },
        roundedBackground: {
          contextColor: isWhiteContext
            ? theme.palette.white
            : theme.palette.gray1,
          backgroundColor: isWhiteContext
            ? theme.palette.gray1
            : theme.palette.white,
          borderColor: theme.palette.gray7,
          borderRadius: 5,
        },
        legend: {
          display: false,
        },
        tooltip: tooltipPlugin,
        zoom: {
          pan: {
            enabled: true,
            mode: "x",
          },
          limits: {
            x: {
              min: "original",
              max: "original",
              minRange: 5 * 365 * 24 * 60 * 60 * 1000, // max zoom level - 5 years
            },
          },
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
        },
        periods: {
          backgroundColor: "rgba(236, 238, 240, 0.5)",
          borderColor: "rgba(178, 179, 181, 0.5)",
          font: {
            family: theme.typography.fontFamily,
            style: "italic",
            size: 12,
          },
          textColor: theme.palette.softBlack,
          labelPosition: "horizontal",
          items: [
            {
              start: addYears(new Date(), -10).getTime(),
              end: new Date().getTime(),
              label: "Timeframe too short for 10 year analysis",
            },
          ],
        },
      },
    }),
    [minY, theme, tooltipPlugin, yLabel, isWhiteContext],
  );

  return (
    <ChartTooltip
      tooltipData={tooltipData}
      renderTooltip={renderTooltip}
      shiftY={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        ...sx,
      }}
    >
      <CarTooltipBox
        sx={{ position: "absolute", left: 5, top: 88 }}
        className={pendoClasses.assetClassAnalysisDftValueAddedMonthlyIndex}
      />
      <Typography
        variant="par03SemiBold"
        sx={{ position: "absolute", fontSize: "19px", top: 1, left: 66 }}
      >
        {xLabel}
        <CarTooltipBox
          sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
          className={pendoClasses.assetClassAnalysisDftAssetClassTotalReturns}
        />
      </Typography>
      <IconButton
        sx={{ position: "absolute", top: -20, right: 72 }}
        size="small"
        onClick={() => {
          chartRef.current?.zoom(1.5, "zoom");
        }}
      >
        <CarIconZoomIn fontSize="large" color="primary" />
      </IconButton>
      <IconButton
        sx={{ position: "absolute", top: -20, right: 26 }}
        size="small"
        onClick={() => {
          chartRef.current?.zoom(-1.5, "zoom");
        }}
      >
        <CarIconZoomOut fontSize="large" color="primary" />
      </IconButton>
      <Chart
        height={440}
        className={createPendoClassName(`${pendoPrefix}_chart`)}
        ref={chartRef}
        onDoubleClick={() => {
          chartRef.current && chartRef.current.resetZoom();
        }}
        type="line"
        data={{
          datasets: [
            {
              type: "line",
              label: trendLine.label,
              data: trendLine.data,
              borderColor: theme.palette.caravelOrangePrimary,
              pointBorderColor: theme.palette.caravelOrangePrimary,
              pointBackgroundColor: theme.palette.caravelOrangePrimary,
              pointBorderWidth: 1,
              pointRadius: 0,
              borderWidth: 1,
            },
            {
              type: "line",
              label: historicLine.label,
              data: historicLine.data,
              borderColor: theme.palette.chartsColor.blue,
              pointBorderColor: theme.palette.chartsColor.blue,
              pointBackgroundColor: theme.palette.chartsColor.blue,
              pointBorderWidth: 0,
              pointRadius: 0,
              borderWidth: 1,
            },
          ],
        }}
        options={options}
        plugins={[ChartPlugins.roundedBackground, ChartPlugins.periods]}
      />
    </ChartTooltip>
  );
};
