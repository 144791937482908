import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled,
  Typography,
} from "@mui/material";
import { CarSelectField, CarSelectFieldProps } from "components/Inputs";
import { CSSProperties } from "react";
import { CarPortfolioChartItem } from "../asset-allocation-types";

const StyledItem = styled(MenuItem)(({ theme }) => ({
  "&.Mui-selected": {
    backgroundColor: theme.palette.lightOrange,
  },
}));

export interface CarSelectChartFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value?: string;
  onChange: (value?: string) => void;
  items: Omit<CarPortfolioChartItem, "x" | "y">[];
  chartColor?: CSSProperties["color"];
}

export const CarSelectChartField = ({
  value,
  onChange,
  items,
  chartColor,
  ...props
}: CarSelectChartFieldProps) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "flex-end",
      columnGap: 1.5,
      "& .MuiOutlinedInput-root": {
        backgroundColor: "white",
      },
    }}
  >
    {chartColor && (
      <Box
        sx={{
          width: 24,
          height: 24,
          backgroundColor: chartColor,
          border: "1px solid",
          borderColor: "gray6",
          borderRadius: "4px",
          mb: 1,
        }}
      />
    )}
    <CarSelectField
      {...props}
      value={value}
      onChange={onChange}
      SelectProps={{
        renderValue: (v) => items.find((i) => i.id === v)?.label,
      }}
    >
      {items.map((i) => (
        <StyledItem key={i.id} value={i.id} disabled={i.isDisabled}>
          {i.icon && <ListItemIcon>{i.icon}</ListItemIcon>}
          <ListItemText>
            <Typography variant="par01Regular">{i.label}</Typography>
          </ListItemText>
        </StyledItem>
      ))}
    </CarSelectField>
  </Box>
);
