import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { useEffect, useState } from "react";
import {
  api,
  RhoStartMonthEnum,
  RhoStartYearEnum,
  SimulationSetting,
  useSimulationSettingInflationExpectedMeanListQuery,
  useSimulationSettingInflationExpectedMeanUpdateMutation,
  useSimulationSimulationModelTypeListQuery,
  useSimulationSimulationModelVersionListQuery,
  useSimulationSimulationSettingListQuery,
  useSimulationSimulationSettingUpdateMutation,
} from "api/carApi.generated";
import { CarFactorPercentField, CarNumberField } from "components/NumberField";
import { CarRadioYesNo } from "components/Radio";
import { CarSelectOption, CarSelectOptionField } from "components/Inputs";
import { CarLoadingPromiseButton } from "components/Buttons";
import { BaseDialog } from "components/BaseDialog";
import { CarInfoCell, CarInfoTable } from "components/InfoTable";
import { isDefined } from "utils";
import { useErrorToast } from "app/useSuccessToast";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

interface SimulationSettingsDialogProps {
  simulationGroupId: string;
  onApply: () => void;
  onCancel: () => void;
}

const months: RhoStartMonthEnum[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const years: RhoStartYearEnum[] = [
  1989, 1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001,
  2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013,
];

interface MeanItem {
  id: string;
  group: string;
  year: number | undefined;
  percentage: number | undefined;
  isModified: boolean;
}

export const SimulationSettingsDialog = (
  props: SimulationSettingsDialogProps,
) => {
  const errorToast = useErrorToast();
  const pageContent = usePageContent();
  const { data, isLoading } = useSimulationSimulationSettingListQuery();

  const [updateSetting] = useSimulationSimulationSettingUpdateMutation();
  const [updateMean] =
    useSimulationSettingInflationExpectedMeanUpdateMutation();

  const expectedMeanList = useSimulationSettingInflationExpectedMeanListQuery({
    id: "", // todo fix in swagger, remove redundant param
  });

  const simulationModelTypes = useSimulationSimulationModelTypeListQuery({
    id: "", // todo fix in swagger, remove redundant param
  });
  const simulationModelVersions = useSimulationSimulationModelVersionListQuery({
    id: "", // todo fix in swagger, remove redundant param
  });

  const [getCpiDefinitions] = api.useLazyCmaGetCurrentCpiDefinitionsListQuery();

  const [settings, setSettings] = useState<SimulationSetting>({});

  const [means, setMeans] = useState<MeanItem[]>([]);

  useEffect(() => {
    if (expectedMeanList.isLoading) {
      return;
    }

    setMeans(
      expectedMeanList.data
        ?.filter((i) => i.group === props.simulationGroupId)
        .map<MeanItem>((i) => ({
          id: i.id ?? "",
          group: i.group,
          year: i.year,
          percentage: i.percentage,
          isModified: false,
        }))
        .sort((a, b) => (a.year ?? 0) - (b.year ?? 0)) ?? [],
    );
  }, [props.simulationGroupId, expectedMeanList]);

  useEffect(() => {
    const item = data?.find((i) => i.group === props.simulationGroupId);
    if (item) {
      setSettings(item);
    }
  }, [data, props.simulationGroupId]);

  const handleUseMarketExpectations = async () => {
    const result = await getCpiDefinitions();

    if ("data" in result) {
      setMeans((value) =>
        value.map((i, idx) => ({
          ...i,
          year: result.data?.at(idx)?.index.position.year,
          percentage: result.data?.at(idx)?.rate,
          isModified: true,
        })),
      );
    }
  };

  const handleSaveChanges = async () => {
    if (means.some((i) => !isDefined(i.year) || !isDefined(i.percentage))) {
      errorToast({
        message: "Please fill all required fields",
      });

      return;
    }

    if (settings && settings.id) {
      await updateSetting({
        id: settings.id,
        simulationSetting: settings,
      });

      await Promise.all(
        means
          .filter((i) => i.isModified)
          .map((i) =>
            updateMean({
              id: i.id,
              settingInflationExpectedMean: {
                id: i.id,
                group: i.group,
                year: i.year ?? 0,
                percentage: i.percentage ?? 0,
              },
            }),
          ),
      );
      props.onApply();
    }
  };

  const simulationModelTypeOptions = Array.from(simulationModelTypes.data ?? [])
    .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0))
    .map<CarSelectOption<string>>((i) => ({
      label: i.name ?? "",
      value: i.id ?? "",
    }));

  const getVersionSort = (primary?: number, secondary?: number) =>
    (primary ?? 0) * 1000 + (secondary ?? 0);
  const simulationModelVersionOptions = Array.from(
    simulationModelVersions.data ?? [],
  )
    .sort(
      (a, b) =>
        getVersionSort(a.primary_version_number, a.secondary_version_number) -
        getVersionSort(b.primary_version_number, b.secondary_version_number),
    )
    .map<CarSelectOption<string>>((i) => ({
      label: `${i.full_version}: ${i.name}`,
      value: i.id ?? "",
    }));

  return (
    <BaseDialog
      sx={{ width: "1000px", maxWidth: "100vw" }}
      onClose={props.onCancel}
      actions={
        <CarLoadingPromiseButton
          sx={{ alignSelf: "center", fontSize: "18px", width: 200 }}
          onClick={handleSaveChanges}
        >
          Save Changes
        </CarLoadingPromiseButton>
      }
    >
      <TablePlaceHolder
        sx={{ mx: 5, mt: 10 }}
        isLoading={
          isLoading ||
          expectedMeanList.isLoading ||
          simulationModelTypes.isLoading ||
          simulationModelVersions.isLoading ||
          pageContent.isLoading
        }
        rows={8}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 4,
            overflowY: "auto",
            mr: -1,
            pr: 1,
          }}
        >
          <Typography variant="h2SSemiBold">Settings</Typography>
          <CarPageContent
            sx={{ alignSelf: "stretch" }}
            content={pageContent.getContent(
              CarPageContentCode.SIMULATION_ANALYSIS_SIMULATION_RUN_SETTINGS,
            )}
          />
          <CarNumberField
            label={
              <>
                # Trials
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                  className={pendoClasses.simulationRunSettingsTrials}
                />
              </>
            }
            value={settings?.path_count}
            onChange={(path_count) =>
              setSettings((val) => ({ ...val, path_count }))
            }
          />
          <CarNumberField
            label={
              <>
                Years in Simulation
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                  className={
                    pendoClasses.simulationRunSettingsYearsInSimulation
                  }
                />
              </>
            }
            value={settings?.years_in_simulation}
            onChange={(years_in_simulation) =>
              setSettings((val) => ({ ...val, years_in_simulation }))
            }
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.25 }}>
            <Typography variant="par01Regular">
              Timeframe for calculating correlations & volatility
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={
                  pendoClasses.simulationRunSettingsTimeframeForCalculatingCorrelationsAndVolatility
                }
              />
            </Typography>
            <Box sx={{ display: "flex", gap: 4 }}>
              <CarSelectOptionField
                sx={{ width: 130 }}
                value={settings?.rho_start_month}
                options={months.map((i) => ({ label: i.toString(), value: i }))}
                onChange={(rho_start_month) =>
                  setSettings((val) => ({
                    ...val,
                    rho_start_month,
                  }))
                }
              />
              <CarSelectOptionField
                sx={{ width: 130 }}
                value={settings?.rho_start_year}
                options={years.map((i) => ({ label: i.toString(), value: i }))}
                onChange={(rho_start_year) =>
                  setSettings((val) => ({
                    ...val,
                    rho_start_year,
                  }))
                }
              />
            </Box>
          </Box>
          <CarSelectOptionField
            sx={{ width: 246 }}
            label={
              <>
                Capital Market Assumptions (CMAs)
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                  className={
                    pendoClasses.simulationRunSettingsCapitalMarketAssumptions
                  }
                />
              </>
            }
            value={settings?.simulation_model_type ?? undefined}
            options={simulationModelTypeOptions}
            onChange={(simulation_model_type) =>
              setSettings((val) => ({
                ...val,
                simulation_model_type,
              }))
            }
          />
          <CarSelectOptionField
            sx={{ width: 240 }}
            label={
              <>
                Model Version
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                  className={pendoClasses.simulationRunSettingsModelVersion}
                />
              </>
            }
            value={settings?.model_version ?? undefined}
            options={simulationModelVersionOptions}
            onChange={(model_version) =>
              setSettings((val) => ({
                ...val,
                model_version,
              }))
            }
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="par01Regular">
              Distributions
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={pendoClasses.simulationRunSettingsDistributions}
              />
            </Typography>
            <CarRadioYesNo
              row
              yesLabel="Empirical"
              noLabel="Normal"
              value={settings?.use_empirical_distribution}
              onChange={(use_empirical_distribution) =>
                setSettings((val) => ({ ...val, use_empirical_distribution }))
              }
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="par01Regular">
              Use Boundaries
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={pendoClasses.simulationRunSettingsUseBoundaries}
              />
            </Typography>
            <CarRadioYesNo
              row
              yesLabel="On"
              noLabel="Off"
              value={settings?.use_boundaries}
              onChange={(use_boundaries) =>
                setSettings((val) => ({ ...val, use_boundaries }))
              }
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="par01Regular">
              Use Crisis Mode
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={pendoClasses.simulationRunSettingsUseCrisisMode}
              />
            </Typography>
            <CarRadioYesNo
              row
              yesLabel="On"
              noLabel="Off"
              value={settings?.use_crisis_mode}
              onChange={(use_crisis_mode) =>
                setSettings((val) => ({ ...val, use_crisis_mode }))
              }
            />
          </Box>
          <CarNumberField
            sx={{
              overflow: "visible",
              ".MuiFormLabel-root": {
                overflow: "visible",
              },
            }}
            label={
              <>
                # Years between Crisis
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                  className={
                    pendoClasses.simulationRunSettingsYearsBetweenCrisis
                  }
                />
              </>
            }
            value={settings?.crisis_mode_years_between}
            onChange={(crisis_mode_years_between) =>
              setSettings((val) => ({ ...val, crisis_mode_years_between }))
            }
          />
          <CarNumberField
            sx={{
              overflow: "visible",
              ".MuiFormLabel-root": {
                overflow: "visible",
              },
            }}
            label={
              <>
                Duration of Crisis in Years
                <CarTooltipBox
                  sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                  className={
                    pendoClasses.simulationRunSettingsDurationOfCrisisInYears
                  }
                />
              </>
            }
            value={settings?.crisis_mode_duration}
            decimalPlaces={1}
            onChange={(crisis_mode_duration) =>
              setSettings((val) => ({ ...val, crisis_mode_duration }))
            }
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
            <Typography variant="par01Regular">
              Target Inflation Rates
              <CarTooltipBox
                sx={{ display: "inline-block", ml: 0.5, mb: -0.5 }}
                className={
                  pendoClasses.simulationRunSettingsTargetInflationRates
                }
              />
            </Typography>

            <CarLoadingPromiseButton
              sx={{ width: 250 }}
              variant="outlined"
              onClick={handleUseMarketExpectations}
            >
              Use Market Expectations
            </CarLoadingPromiseButton>
          </Box>
          <CarInfoTable
            sx={{
              alignSelf: "stretch",
              gridTemplateColumns: `repeat(${means.length + 1}, 1fr)`,
            }}
          >
            <CarInfoCell
              text="Year"
              justifyContent="start"
              isOdd
              pendoClass={pendoClasses.simulationRunSettingsYear}
            />
            {means.map((i) => (
              <CarInfoCell key={`year_${i.id}`} isOdd>
                <CarNumberField
                  value={i.year}
                  minValue={0}
                  maxValue={60}
                  required
                  onChange={(year) => {
                    setMeans(
                      means.map((v) =>
                        v.id === i.id ? { ...v, year, isModified: true } : v,
                      ),
                    );
                  }}
                />
              </CarInfoCell>
            ))}
            <CarInfoCell
              text="Rate (%)"
              justifyContent="start"
              pendoClass={pendoClasses.simulationRunSettingsRatePercent}
            />
            {means.map((i) => (
              <CarInfoCell key={`rate_${i.id}`}>
                <CarFactorPercentField
                  sx={{
                    ".MuiOutlinedInput-root": {
                      backgroundColor: "white",
                    },
                  }}
                  value={i.percentage}
                  required
                  decimalPlaces={2}
                  onChange={(percentage) => {
                    setMeans(
                      means.map((v) =>
                        v.id === i.id
                          ? { ...v, percentage, isModified: true }
                          : v,
                      ),
                    );
                  }}
                />
              </CarInfoCell>
            ))}
          </CarInfoTable>
        </Box>
      </TablePlaceHolder>
    </BaseDialog>
  );
};
