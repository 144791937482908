import {
  CarAccordion,
  CarAccordionDetails,
  CarAccordionSummary,
} from "components/Accordion";
import { Box, Typography, styled } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { PropsWithChildren, memo } from "react";
import { useAssetClassExtendedStat } from "./useAssetClassExtendedStat";
import { ChartOutUnderPerformance } from "../assetAllocation/editPortfolioGroup/ChartOutUnder";
import { useChartDataSelector } from "../assetAllocation/editPortfolioGroup/useChartDataSelector";
import { CarDownloadButton } from "components/Buttons";
import { openInNewTab } from "utils";
import { rootPages } from "route.const";
import { getYear, parseISO } from "date-fns";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { YearRangeSelector } from "pages/assetAllocation/editPortfolioGroup/YearRangeSelector";
import { useYearRangeSelector } from "pages/assetAllocation/editPortfolioGroup/useYearRangeSelector";
import { pendoClasses } from "app/thirdParty/pendo";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

const StyledAccordionSummary = styled(CarAccordionSummary)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    paddingLeft: theme.spacing(2),
  },
}));

type ReportSectionProps = PropsWithChildren<{
  label: string;
}>;

const ReportSection = ({ label, children }: ReportSectionProps) => (
  <CarAccordion
    defaultExpanded
    TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
  >
    <StyledAccordionSummary sx={{ backgroundColor: "white" }}>
      <Typography variant="h6SemiBold">{label}</Typography>
    </StyledAccordionSummary>
    <CarAccordionDetails
      sx={{
        p: 6,
        backgroundColor: "gray1",
        border: "solid 1px",
        borderColor: "gray3",
        borderTopWidth: 0,
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
      }}
    >
      {children}
    </CarAccordionDetails>
  </CarAccordion>
);

export const AssetClassAnalysisPerformanceRisk = memo(() => {
  const pageContent = usePageContent();

  const data = useAssetClassExtendedStat({
    type: AssetClassLoadType.byLatestSimulationJob,
    filterByUsableInPortfolios: true,
  });

  const perfItems = data.oneYearUnderPerf;
  const enabledPerfItems = perfItems.filter((i) => !i.isDisabled);

  const chartDataSelector = useChartDataSelector({
    storageKey: "AssetClassAnalysisPerformanceRisk_chartDataSelector",
    data1DefaultId: enabledPerfItems.at(0)?.id,
    data2DefaultId: enabledPerfItems.at(1)?.id,
  });

  const handleShowPdf = () => {
    if (chartDataSelector.data1Id && chartDataSelector.data2Id) {
      openInNewTab(
        rootPages.getAssetClassPerformanceRiskPdfReportUrl({
          data1Id: chartDataSelector.data1Id,
          data2Id: chartDataSelector.data2Id,
        }),
      );
    }
  };

  const years = Array.from(
    new Set(perfItems.at(0)?.x.map((i) => getYear(parseISO(i))) ?? []),
  );

  const yearRangeSelector = useYearRangeSelector({ years });

  return (
    <TablePlaceHolder
      sx={{ mx: 7, mt: 10 }}
      isLoading={data.isLoading}
      rows={8}
    >
      <Box sx={{ display: "flex" }}>
        <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
          Performance Risk
        </Typography>
        <CarDownloadButton
          onClick={handleShowPdf}
          sx={{ ml: "auto", alignSelf: "flex-end" }}
        >
          Download Reports
        </CarDownloadButton>
      </Box>

      <CarPageContent
        sx={{ mt: 3 }}
        content={pageContent.getContent(
          CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_OVERVIEW,
        )}
      />
      <ReportSection label="Under Performance Risk">
        <YearRangeSelector yearRangeSelector={yearRangeSelector} />
        <Typography variant="h6SemiBold" sx={{ mt: 6.5, mb: 2, mx: 6 }}>
          1 Year Underperformance Risk
        </Typography>

        <CarPageContent
          sx={{ mx: 6, mb: 2.5 }}
          isWhiteContext
          content={pageContent.getContent(
            CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_ONE_YEAR_UNDER_PERF_RISK,
          )}
        />
        <ChartOutUnderPerformance
          selectorLabel1="Asset Class 1"
          selectorLabel2="Asset Class 2"
          data={data.oneYearUnderPerf}
          startYear={yearRangeSelector.startYear}
          endYear={yearRangeSelector.endYear}
          isRelative={false}
          periods={data.periods}
          chartDataSelector={chartDataSelector}
          pendoClass={pendoClasses.assetClassAnalysisOneYearUnderPerformance}
        />

        <Typography variant="h6SemiBold" sx={{ mt: 9, mb: 2, mx: 6 }}>
          3 Year Underperformance Risk
        </Typography>

        <CarPageContent
          sx={{ mx: 6, mb: 2.5 }}
          isWhiteContext
          content={pageContent.getContent(
            CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK_THREE_YEAR_UNDER_PERF_RISK,
          )}
        />
        <ChartOutUnderPerformance
          selectorLabel1="Asset Class 1"
          selectorLabel2="Asset Class 2"
          data={data.threeYearUnderPerf}
          startYear={yearRangeSelector.startYear}
          endYear={yearRangeSelector.endYear}
          periods={data.periods}
          chartDataSelector={chartDataSelector}
          isRelative={false}
          pendoClass={pendoClasses.assetClassAnalysisThreeYearUnderPerformance}
        />
      </ReportSection>
      <ReportSection label="Cumulative Relative Performance">
        <CarPageContent
          sx={{ mx: 6, mt: -2, mb: 2.5 }}
          isWhiteContext
          content={pageContent.getContent(
            CarPageContentCode.ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK_CUMULATIVE_RETURN_PERF,
          )}
        />
        <ChartOutUnderPerformance
          selectorLabel1="Asset Class 1"
          selectorLabel2="Asset Class 2"
          data={data.cumRelPerf}
          periods={data.periods}
          chartDataSelector={chartDataSelector}
          isRelative={true}
          pendoClass={
            pendoClasses.assetClassAnalysisCumulativeRelativePerformance
          }
        />
      </ReportSection>
    </TablePlaceHolder>
  );
});
