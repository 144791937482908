import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfHeading } from "./PdfHeading";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfTypography } from "components/PdfTypography";

export type PageId =
  | "goals"
  | "risk"
  | "inflowsAndOutflows"
  | "goalTimeline"
  | "portfolioModuleBreakdownHousehold"
  | "portfolioModuleBreakdownAccount"
  | "stressTest"
  | "marketEnvironment"
  | "planCashflow"
  | "netWorth"
  | "taxableIncomeAnalysis"
  | "realEstate"
  | "capitalMarketAssumptions"
  | "disclosures"
  | "glossary";

const items: Array<{ id: PageId; label: string }> = [
  {
    id: "goals",
    label: "Goals",
  },
  {
    id: "risk",
    label: "Risk",
  },
  {
    id: "inflowsAndOutflows",
    label: "Inflows & Outflows",
  },
  {
    id: "goalTimeline",
    label: "Goal Timeline",
  },
  {
    id: "portfolioModuleBreakdownHousehold",
    label: "Portfolio Module Breakdown: Household",
  },
  {
    id: "portfolioModuleBreakdownAccount",
    label: "Portfolio Module Breakdown: Account",
  },
  {
    id: "stressTest",
    label: "Stress Test",
  },
  {
    id: "marketEnvironment",
    label: "Market Environment",
  },
  {
    id: "planCashflow",
    label: "Plan Cashflow",
  },
  {
    id: "netWorth",
    label: "Net Worth",
  },
  {
    id: "taxableIncomeAnalysis",
    label: "Taxable Income Analysis",
  },
  {
    id: "realEstate",
    label: "Real Estate",
  },
  {
    id: "disclosures",
    label: "Disclosures",
  },
  {
    id: "capitalMarketAssumptions",
    label: "Capital Market Assumptions (CMAs)",
  },
  {
    id: "glossary",
    label: "Glossary",
  },
];

export interface TableOfContentPdfPageProps {
  pageProps: CarPdfPageProps;
  pageNumberMap: Map<PageId, number>;
}

export const TableOfContentPdfPage = (props: TableOfContentPdfPageProps) => {
  return (
    <CarPdfPage style={{ paddingRight: 40 }} {...props.pageProps}>
      <CarPdfHeading label="Table of Content" style={{ marginBottom: 46 }} />
      {items.map((i) => (
        <CarPdfBox
          key={i.id}
          style={{ flexDirection: "row", marginBottom: 18 }}
        >
          <CarPdfTypography variant="h5" style={{ width: 477 }}>
            {i.label}
          </CarPdfTypography>
          <CarPdfTypography
            variant="h5"
            render={() => `pg ${props.pageNumberMap.get(i.id) ?? "-"}`}
          />
        </CarPdfBox>
      ))}
    </CarPdfPage>
  );
};
