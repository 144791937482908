import { TablePlaceHolder } from "components/PlaceHolder";
import { useSettingsAssetClassImplementation } from "./useSettingsAssetClassImplementation";
import { Typography } from "@mui/material";
import { SettingsList } from "./SettingsList";
import { SectionAssetClassImplementation } from "./SectionAssetClassImplementation";

export const SettingsAssetClassSettings = () => {
  const assetClassImplementation = useSettingsAssetClassImplementation();
  return (
    <TablePlaceHolder
      sx={{ mt: 5 }}
      isLoading={assetClassImplementation.isLoading}
      rows={8}
    >
      <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
        Asset Class Settings
      </Typography>
      <SettingsList
        items={[
          {
            label: "Asset Class Implementation",
            description:
              "The security that the user will use to represent the asset class when buying from the market.",
            content: (
              <SectionAssetClassImplementation
                data={assetClassImplementation}
              />
            ),
          },
        ]}
      />
    </TablePlaceHolder>
  );
};
