import {
  useNotificationsNotificationGetReadNotificationsListQuery,
  // useNotificationsNotificationGetSnoozedNotificationsListQuery,
  useNotificationsNotificationLevelListQuery,
  useNotificationsNotificationListQuery,
  useNotificationsNotificationTypeListQuery,
  useNotificationsNotificationUpdateMutation,
  usePortfolioPortfolioGroupListQuery,
} from "api/carApi.generated";
import { parseISO } from "date-fns";
import { useClients } from "pages/financialPlanning/clientList/useClients";

export interface CarNotification {
  id: string;
  levelName: string;
  levelCode: string;
  levelColor: string;
  label: string;
  description: string;
  date: Date;
  isCleared: boolean;

  clientId?: string;
  planId?: string;
  planResultId?: string;

  portfolioGroupId?: string;
  portfolioGroupVersionId?: string;
}

export const useNotifications = (isClearedMode: boolean) => {
  const notificationTypes = useNotificationsNotificationTypeListQuery();
  const notificationLevels = useNotificationsNotificationLevelListQuery();

  const newNotifications = useNotificationsNotificationListQuery(undefined, {
    skip: isClearedMode,
  });

  const clearedNotifications =
    useNotificationsNotificationGetReadNotificationsListQuery(undefined, {
      skip: !isClearedMode,
    });

  const clients = useClients(true);

  const portfolioGroups = usePortfolioPortfolioGroupListQuery(undefined);

  // useNotificationsNotificationGetSnoozedNotificationsListQuery(undefined, {});

  const [update] = useNotificationsNotificationUpdateMutation();

  const typeItems = notificationTypes.data?.filter((i) => i.is_active) ?? [];
  const levelItems = notificationLevels.data?.filter((i) => i.is_active) ?? [];

  const rawItems = Array.from(
    (isClearedMode ? clearedNotifications.data : newNotifications.data) ?? [],
  ).sort((a, b) => a.created_at?.localeCompare(b.created_at ?? "") ?? 0);

  const items: CarNotification[] = rawItems
    .map<CarNotification>((i) => {
      const typeItem = typeItems.find((ti) => ti.id === i.notification_type);
      const levelItem = levelItems.find((lv) => lv.id === typeItem?.level);
      const clientId = i.notification_extended_data?.["client_id"];
      const planId = i.notification_extended_data?.["base_plan_id"];
      const portfolioGroupId =
        i.notification_extended_data?.["portfolio_group_id"];

      let description = i.notification_message ?? "";

      const client = clients.items.find((cl) => cl.id === clientId);
      const portfolioGroup = portfolioGroups.data?.find(
        (pg) => pg.id === portfolioGroupId,
      );

      if (client) {
        description = `**${client.name} (${client.tierName}):** ${description
          .replace("Your", "")
          .trim()}`;
      } else if (portfolioGroup) {
        description = `**${portfolioGroup.title}:** ${description}`;
      }

      const result: CarNotification = {
        id: i.id ?? "",
        levelName: levelItem?.name ?? "",
        levelCode: levelItem?.code ?? "",
        levelColor: levelItem?.color_code ?? "",
        label: i.notification_title ?? "",
        description,
        date: parseISO(i.created_at ?? ""),
        isCleared: !!i.is_read || !!i.is_snoozed,

        clientId,
        planId,
        planResultId: i.notification_extended_data?.["plan_result_id"],

        portfolioGroupId,
        portfolioGroupVersionId:
          i.notification_extended_data?.["portfolio_version_id"],
      };

      return result;
    })
    .filter((i) => i.isCleared === isClearedMode);

  const handleClearNotification = (item: CarNotification) => {
    const dataItem = rawItems.find((i) => i.id === item.id);
    if (dataItem) {
      update({
        id: dataItem.id ?? "",
        notification: {
          ...dataItem,
          is_read: true,
        },
      });
    }
  };

  const handleSnoozeNotification = (
    item: CarNotification,
    snoozeTime: string,
  ) => {
    const dataItem = rawItems.find((i) => i.id === item.id);
    if (dataItem) {
      update({
        id: dataItem.id ?? "",
        notification: {
          ...dataItem,
          is_snoozed: true,
          snooze_time: snoozeTime,
        },
      });
    }
  };

  const handleRestoreNotification = (item: CarNotification) => {
    const dataItem = rawItems.find((i) => i.id === item.id);
    if (dataItem) {
      update({
        id: dataItem.id ?? "",
        notification: {
          ...dataItem,
          is_read: false,
        },
      });
    }
  };

  return {
    isLoading:
      notificationTypes.isLoading ||
      notificationLevels.isLoading ||
      clients.isLoading ||
      newNotifications.isLoading ||
      clearedNotifications.isLoading,
    items,
    handleClearNotification,
    handleRestoreNotification,
    handleSnoozeNotification,
  };
};
