import { useQuestionnaireClientsPartialUpdateMutation } from "api/carApi.generated";
import { useMount } from "./useMount";
import { useClientId } from "./usePlanId";

export const useClientUpdateLastReviewed = () => {
  const clientId = useClientId();

  const [patchClient] = useQuestionnaireClientsPartialUpdateMutation();

  useMount(() => {
    patchClient({
      id: clientId,
      patchedClient: {
        last_reviewed: new Date().toISOString(),
      },
    });
  });
};
