import { useRender } from "app/useRender";
import { CarGoalStatusEnum } from "types";
import { priorityNumToString } from "utils";
export interface SummaryGoalPeriod {
  status: CarGoalStatusEnum;
  amount: number;
}
export interface SummaryGoal {
  id: string;
  description: string;
  status: CarGoalStatusEnum;
  priority: number;
  nameWithPriority: string;
  annualAmount: number;
  startDate: string;
  endDate: string;
  totalAmountBeforeInflation: number;
  calcPeriod: (startYear: number, endYear: number) => SummaryGoalPeriod;
}

export interface CashFlowProjectionItem {
  yearNumber: number;
  incomeInflows: number;
  expenditures: number;
  savingsPortfolioDistributions: number;
}

export const usePlanSummary = () => {
  const render = useRender();

  const goals: SummaryGoal[] =
    render.planResult?.goal_results.map<SummaryGoal>((i) => {
      return {
        id: i.base_goal_id,
        description: i.base_goal_name,
        nameWithPriority: `${i.base_goal_name} (${
          priorityNumToString(i.base_goal_priority)[0]
        })`,
        status: i.goal_status,
        priority: i.base_goal_priority,
        annualAmount: i.input_annual_amount_without_inflation,
        startDate: i.base_goal_start_date,
        endDate: i.base_goal_end_date,
        totalAmountBeforeInflation: i.input_total_amount_without_inflation,
        calcPeriod: (startYear, endYear): SummaryGoalPeriod => {
          const sum = i.period_results
            ?.filter((i) => i.year >= startYear && i.year <= endYear)
            .reduce<{
              requested: number;
              funded: number;
            }>(
              (acc, i) => {
                acc.requested += i.amount_requested_without_inflation;
                acc.funded += i.amount_funded_without_inflation;
                return acc;
              },
              { requested: 0, funded: 0 },
            );

          const requested = sum?.requested ?? 0;
          const funded = sum?.funded ?? 0;

          return {
            status:
              funded >= requested
                ? "success"
                : funded > 0
                ? "partial"
                : "failure",
            amount: funded,
          };
        },
      };
    }) ?? [];

  const cashflowProjectionItems =
    render.planResult?.cash_flow_results.map<CashFlowProjectionItem>((i) => ({
      yearNumber: i.year,
      incomeInflows: i.inflow_total,
      expenditures: i.expenditure_total,
      savingsPortfolioDistributions: i.distribution_total,
    })) ?? [];

  const cashflowProjectionTotal =
    cashflowProjectionItems.reduce<CashFlowProjectionItem>(
      (acc, i) => ({
        ...acc,
        incomeInflows: acc.incomeInflows + i.incomeInflows,
        expenditures: acc.expenditures + i.expenditures,
        savingsPortfolioDistributions:
          acc.savingsPortfolioDistributions + i.savingsPortfolioDistributions,
      }),
      {
        yearNumber: 0,
        incomeInflows: 0,
        expenditures: 0,
        savingsPortfolioDistributions: 0,
      },
    );

  return {
    goals,
    cashflowProjection: {
      items: cashflowProjectionItems,
      total: cashflowProjectionTotal,
    },
    isLoading: render.isLoading,
    refetch: render.refetch,
  };
};

export type UsePlanSummary = ReturnType<typeof usePlanSummary>;
