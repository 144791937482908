import { formatCurrency, formatYear } from "utils";
import { UsePlanSummary } from "../planSummary/usePlanSummary";
import { CarDisclosureContentCode, UseDisclosures } from "app/useDisclosures";
import { CarPdfPage, CarPdfPageProps } from "components/PdfPage";
import { CarPdfSubHeading } from "./PdfHeading";
import { CarPdfMarkdown } from "components/PdfMarkdown";
import {
  CarPdfIconFailure,
  CarPdfIconPartial,
  CarPdfIconSuccess,
} from "components/PdfIcon";
import { CarPdfBox } from "components/PdfBox";
import { CarPdfTypography } from "components/PdfTypography";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTable,
  CarPdfTextCell,
  CarPdfViewCell,
} from "components/PdfTable";
import { CarPdfGoalsLegend } from "./PdfGoalsLegend";

export interface GoalsPdfPageProps {
  pageProps: CarPdfPageProps;
  planSummary: UsePlanSummary;
  disclosures: UseDisclosures;
}

export const GoalsPdfPage = (props: GoalsPdfPageProps) => {
  return (
    <CarPdfPage {...props.pageProps}>
      <CarPdfSubHeading label="Goals" />
      <CarPdfMarkdown style={{ marginTop: 10, marginBottom: 30 }}>
        {props.disclosures.getContent(
          CarDisclosureContentCode[
            "CMPAS_CLIENT_REPORT_SUMMARY:_GOALS_part_10"
          ],
        )}
      </CarPdfMarkdown>
      <CarPdfTable colWidths={[1.8, 0.8, 1, 1, 1.5, 1.2]}>
        <CarPdfHeader paddingVertical={14}>
          <CarPdfHeaderCell>Goal Name (Priority)</CarPdfHeaderCell>
          <CarPdfHeaderCell>Status</CarPdfHeaderCell>
          <CarPdfHeaderCell>Start</CarPdfHeaderCell>
          <CarPdfHeaderCell>End</CarPdfHeaderCell>
          <CarPdfHeaderCell>*Annual Amount</CarPdfHeaderCell>
          <CarPdfHeaderCell>*Total</CarPdfHeaderCell>
        </CarPdfHeader>
        {props.planSummary.goals.map((i, idx) => (
          <CarPdfRow key={idx} paddingVertical={10}>
            <CarPdfTextCell
              style={{
                textAlign: "left",
                paddingHorizontal: 5,
              }}
            >
              {i.nameWithPriority}
            </CarPdfTextCell>
            <CarPdfViewCell
              style={{
                justifyContent: "center",
                alignItems: "center",
                paddingVertical: 0,
              }}
            >
              {i.status === "success" ? (
                <CarPdfIconSuccess />
              ) : i.status === "failure" ? (
                <CarPdfIconFailure />
              ) : (
                <CarPdfIconPartial />
              )}
            </CarPdfViewCell>
            <CarPdfTextCell>{formatYear(i.startDate)}</CarPdfTextCell>
            <CarPdfTextCell>{formatYear(i.endDate)}</CarPdfTextCell>
            <CarPdfTextCell>{formatCurrency(i.annualAmount)}</CarPdfTextCell>
            <CarPdfTextCell>
              {formatCurrency(i.totalAmountBeforeInflation)}
            </CarPdfTextCell>
          </CarPdfRow>
        ))}
      </CarPdfTable>
      <CarPdfBox style={{ height: 10 }} />
      <CarPdfTypography
        variant="disclosures"
        style={{ width: "100%", textAlign: "right" }}
      >
        *Amounts are represented in today’s dollars (pre-inflation).
      </CarPdfTypography>
      <CarPdfGoalsLegend
        style={{
          marginTop: 20,
        }}
      />
    </CarPdfPage>
  );
};
