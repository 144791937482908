import ReactPDF, { Path, Svg } from "@react-pdf/renderer";

export interface CarPdfIconProps extends ReactPDF.SVGProps {
  size?: number;
}

const createPdfIcon =
  (
    data: ({ currentColor }: { currentColor?: string }) => React.ReactNode,
    viewBox: string,
    color?: string,
  ) =>
  ({ style, size = 24, ...props }: CarPdfIconProps) => (
    <Svg style={style} width={size} height={size} viewBox={viewBox} {...props}>
      {data({
        currentColor:
          color ?? (Array.isArray(style) ? style[0]?.color : style?.color),
      })}
    </Svg>
  );

export type CarPdfIcon = ReturnType<typeof createPdfIcon>;

export const CarPdfIconFailure = createPdfIcon(
  ({ currentColor }) => (
    <Path
      d="m330 768 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42Zm150 208q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340"
      fill={currentColor}
    />
  ),
  "0 96 960 960",
  "rgba(235, 87, 87, 1)",
);

export const CarPdfIconSuccess = createPdfIcon(
  ({ currentColor }) => (
    <Path
      d="M480 976q-85 0-158-30.5T195 861q-54-54-84.5-127T80 576q0-84 30.5-157T195 292q54-54 127-85t158-31q75 0 140 24t117 66l-43 43q-44-35-98-54t-116-19q-145 0-242.5 97.5T140 576q0 145 97.5 242.5T480 916q145 0 242.5-97.5T820 576q0-30-4.5-58.5T802 462l46-46q16 37 24 77t8 83q0 85-31 158t-85 127q-54 54-127 84.5T480 976Zm-59-218L256 592l45-45 120 120 414-414 46 45-460 460Z"
      fill={currentColor}
    />
  ),
  "0 96 960 960",
  "rgba(39, 174, 96, 1)",
);

export const CarPdfIconPartial = createPdfIcon(
  ({ currentColor }) => (
    <Path
      d="M480 976q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-156t86-127Q252 239 325 207.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 82-31.5 155T763 858.5q-54 54.5-127 86T480 976Zm0-60q142 0 241-99.5T820 576q0-142-99-241t-241-99q-141 0-240.5 99T140 576q0 141 99.5 240.5T480 916Zm0-340Z"
      fill={currentColor}
    />
  ),
  "0 96 960 960",
  "rgba(242, 201, 76, 1)",
);
