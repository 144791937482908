import { Box, BoxProps, styled } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Fragment, ReactNode, useRef } from "react";
import { isOddEven } from "utils";
import { useMount } from "app/useMount";

const StyledTable = styled("div")(({ theme }) => ({
  overflow: "auto",
  border: "1px solid",
  borderColor: theme.palette.gray6,
  borderRadius: "5px",
}));

const StyledColumnHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: theme.palette.gray3,
  borderRight: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: theme.palette.gray6,
  textAlign: "center",
  justifyContent: "center",
  whiteSpace: "pre-line",
  overflow: "hidden",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  ...theme.typography.par02Regular,
  zIndex: 10,
}));

const StyledRowHeader = styled("div")(({ theme }) => ({
  borderRight: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: theme.palette.gray6,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.white,
  ...theme.typography.par02Regular,
  "&.even": {
    backgroundColor: theme.palette.gray2,
  },
  zIndex: 5,
}));

const StyledCell = styled("div")(({ theme }) => ({
  borderRight: "solid 1px",
  borderBottom: "solid 1px",
  borderColor: theme.palette.gray6,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.white,
  ...theme.typography.par02Regular,
  "&.even": {
    backgroundColor: theme.palette.gray2,
  },
}));

interface CarVirtualTableProps {
  className?: string;
  sx?: BoxProps["sx"];
  columnHeaderHeight?: number;
  rowHeight?: number;
  rowHeaderWidth?: number;
  rowWidth?: number;
  columnCount: number;
  rowCount: number;
  onRenderColumnRowHeader?: () => ReactNode;
  sxColumnHeader?: (columnIndex: number) => BoxProps["sx"];
  onRenderColumnHeader: (columnIndex: number) => ReactNode;
  onClickColumnHeader?: (columnIndex: number) => void;
  onRenderRowHeader: (rowIndex: number) => ReactNode;
  onRenderCell: (columnIndex: number, rowIndex: number) => ReactNode;
}

export const CarVirtualTable = ({
  className,
  sx,
  columnHeaderHeight = 60,
  rowHeight = 60,
  rowHeaderWidth = 100,
  rowWidth = 60,
  columnCount,
  rowCount,
  onRenderColumnRowHeader = () => null,
  sxColumnHeader,
  onRenderColumnHeader,
  onClickColumnHeader,
  onRenderRowHeader,
  onRenderCell,
}: CarVirtualTableProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const requestRef = useRef<number>();

  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: columnCount + 1,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => (index === 0 ? rowHeaderWidth : rowWidth),
    overscan: 1,
  });

  const rowVirtualizer = useVirtualizer({
    count: rowCount + 1,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => (index === 0 ? columnHeaderHeight : rowHeight),
    overscan: 1,
  });

  const animate = () => {
    requestRef.current = requestAnimationFrame(animate);

    if (!parentRef.current) {
      return;
    }

    const columnHeaders =
      parentRef.current.querySelectorAll<HTMLElement>(`.column-header`);

    Array.from(columnHeaders).forEach(
      (i) => (i.style.top = `${parentRef.current?.scrollTop}px`),
    );

    const rowHeaders =
      parentRef.current.querySelectorAll<HTMLElement>(`.row-header`);

    Array.from(rowHeaders).forEach(
      (i) => (i.style.left = `${parentRef.current?.scrollLeft}px`),
    );
  };

  useMount(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  });

  return (
    <StyledTable ref={parentRef} className={className} sx={sx}>
      <Box
        sx={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: `${columnVirtualizer.getTotalSize()}px`,
          position: "relative",
        }}
      >
        <StyledColumnHeader
          className="column-header row-header"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: `${columnHeaderHeight}px`,
            width: `${rowHeaderWidth}px`,
            zIndex: 15,
          }}
        >
          {onRenderColumnRowHeader()}
        </StyledColumnHeader>

        {columnVirtualizer.getVirtualItems().map((virtualColumn) => {
          if (virtualColumn.index === 0) {
            return null;
          }
          return (
            <StyledColumnHeader
              key={virtualColumn.key}
              className="column-header"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: `${columnHeaderHeight}px`,
                width: `${virtualColumn.size}px`,
                transform: `translateX(${virtualColumn.start}px)`,
              }}
              sx={sxColumnHeader?.(virtualColumn.index - 1)}
              onClick={
                onClickColumnHeader
                  ? () => onClickColumnHeader(virtualColumn.index - 1)
                  : undefined
              }
            >
              {onRenderColumnHeader(virtualColumn.index - 1)}
            </StyledColumnHeader>
          );
        })}

        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          if (virtualRow.index === 0) {
            return null;
          }
          return (
            <StyledRowHeader
              key={virtualRow.key}
              className={[
                "row-header",
                isOddEven(virtualRow.index) ? "even" : "",
              ].join(" ")}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: `${rowHeaderWidth}px`,
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {onRenderRowHeader(virtualRow.index - 1)}
            </StyledRowHeader>
          );
        })}

        {rowVirtualizer.getVirtualItems().map((virtualRow) => (
          <Fragment key={virtualRow.key}>
            {columnVirtualizer.getVirtualItems().map((virtualColumn) => {
              if (virtualColumn.index === 0 || virtualRow.index === 0) {
                return null;
              }

              return (
                <StyledCell
                  key={virtualColumn.key}
                  className={isOddEven(virtualRow.index) ? "even" : ""}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: `${virtualColumn.size}px`,
                    height: `${virtualRow.size}px`,
                    transform: `translateX(${virtualColumn.start}px) translateY(${virtualRow.start}px)`,
                  }}
                >
                  {onRenderCell(virtualColumn.index - 1, virtualRow.index - 1)}
                </StyledCell>
              );
            })}
          </Fragment>
        ))}
      </Box>
    </StyledTable>
  );
};
