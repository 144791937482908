import { ReactElement, useState } from "react";
import { Box, BoxProps } from "@mui/material";
import { Pie } from "react-chartjs-2";
import { formatCurrency, formatPercent, isDefined } from "utils";
import { CarInfoHeader, CarInfoTable, CarInfoCell } from "./InfoTable";
import {
  AllocationBreakdownItem,
  AllocationChartItem,
} from "features/householdAllocation/useHouseholdAllocation";
import { CarTooltip } from "./Tooltip";
import { pendoClasses } from "app/thirdParty/pendo";

export interface AllocationBreakdownTableProps {
  sx?: BoxProps["sx"];
  items: Array<AllocationBreakdownItem>;
  footer?: ReactElement;
}

const ChartCell = (props: {
  item: AllocationBreakdownItem;
  isOdd?: boolean;
}) => {
  const [hintValue, setHintValue] = useState<AllocationChartItem | undefined>();
  const data = props.item.allocation.filter((v) => (v.angle ?? 0) > 0);

  return (
    <CarInfoCell isOdd={props.isOdd} sx={{ py: 3 }}>
      <CarTooltip
        title={
          hintValue
            ? `${hintValue.label} ${formatPercent(hintValue.angle ?? 0)}`
            : ""
        }
        followCursor
        arrow={false}
      >
        <Box>
          <Pie
            width={76}
            height={76}
            options={{
              responsive: false,
              animation: {
                duration: 0,
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              borderColor: "transparent",
              onHover(event, elements, chart) {
                setHintValue(data[elements[0]?.index]);
              },
            }}
            data={{
              labels: data.map((i) => i.label),
              datasets: [
                {
                  label: "data",
                  data: data.map((i) => i.angle),
                  backgroundColor: data.map((i) => i.color),
                },
              ],
            }}
          />
        </Box>
      </CarTooltip>
    </CarInfoCell>
  );
};

export const AllocationBreakdownTable = ({
  sx,
  items,
  footer,
}: AllocationBreakdownTableProps) => {
  return (
    <CarInfoTable
      sx={{
        gridTemplateColumns: `2fr repeat(${items.length}, 1fr)`,
        ...sx,
      }}
    >
      <CarInfoHeader
        text="Modules"
        justifyContent="start"
        pendoClass={pendoClasses.workbenchHouseholdAllocationModules}
      />
      {items.map((i, idx) => (
        <CarInfoHeader key={idx} text={i.label} />
      ))}

      <CarInfoCell
        text="Portfolio %"
        justifyContent="start"
        isOdd
        pendoClass={pendoClasses.workbenchHouseholdAllocationPortfolio}
      />
      {items.map((i, idx) => (
        <CarInfoCell
          key={idx}
          text={isDefined(i.percentage) ? formatPercent(i.percentage) : ""}
          isOdd
        />
      ))}

      <CarInfoCell
        text="Amount Invested"
        justifyContent="start"
        pendoClass={pendoClasses.workbenchHouseholdAllocationAmountInvested}
      />
      {items.map((i, idx) => (
        <CarInfoCell
          key={idx}
          text={isDefined(i.money) ? formatCurrency(i.money) : ""}
        />
      ))}

      <CarInfoCell text="Allocation" justifyContent="start" isOdd />
      {items.map((i, idx) => (
        <ChartCell key={idx} item={i} isOdd />
      ))}
      {footer}
    </CarInfoTable>
  );
};
