import { BoxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { usePlanTypeId } from "app/usePlanId";
import { usePlanTypes } from "app/usePlanTypes";
import { RiskBarometer } from "components/RiskBarometer";
import { CarRadioYesNo } from "components/Radio";
import { RootPageBox } from "components/RootPage";
import { PlanTypeCode } from "const";
import { RiskTableReport } from "features/planResults/RiskTableReport";
import { UsePlanResults } from "features/planResults/usePlanResults";
import { ReactElement, useState } from "react";
import { RiskBarometerLegend } from "components/RiskBarometerLegend";
import { pendoClasses } from "app/thirdParty/pendo";

const disclosure = `Caravel’s Risk Barometer provides investors a visual representation of the potential investment risk for alternative investment strategies. The indicators for each portfolio strategy are shown in box to the right of the gauge.

Risk on the Risk Barometer is measured by the percentage of equity asset classes in the portfolio. The chart below provides additional measures of risk. These additional measures of risk are intended to help provide a more comprehensive understanding of overall investment risk. Please consult our Glossary for definitions of these terms.

The Risk Barometer also helps you explore tradeoffs between risk and the potential for achieving financial goals. By selecting different strategies, you can see how the goals you can achieve may vary with the amount of investment risk within your portfolio.`;

interface PublishPlanRiskProps {
  sx?: BoxProps["sx"];
  data: UsePlanResults;
  footer: ReactElement;
}

export const PublishPlanRisk = ({ sx, data, footer }: PublishPlanRiskProps) => {
  const { items: planTypes, getPlanTypeLabel } = usePlanTypes();
  const planTypeId = usePlanTypeId();
  const planTypeLabel = getPlanTypeLabel(planTypeId);
  const selectedPlanTypeCode = planTypes.find((i) => i.id === planTypeId)?.code;
  const hideSelectedPlan =
    selectedPlanTypeCode === PlanTypeCode.proposed ||
    selectedPlanTypeCode === PlanTypeCode.existing;

  const [isPercentMode, setIsPercentMode] = useState(true);

  return (
    <RootPageBox sx={{ p: 5, pb: 8, ...sx }}>
      <Typography
        variant="h4SSemiBold"
        className={pendoClasses.workbenchSectionRisk}
      >
        Risk
      </Typography>
      <Typography variant="par02Regular" sx={{ mt: 2, whiteSpace: "pre-line" }}>
        {disclosure}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "stretch", mt: 3, gap: 4 }}>
        <Box
          sx={{
            flex: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="par02Bold" sx={{ mt: 6, mb: 4 }}>
            {planTypeLabel} Strategy
          </Typography>
          <RiskBarometer
            size={320}
            value={data.risk.riskValue}
            valueRed={data.risk.valueRed}
            valueWhite={data.risk.valueWhite}
            valueBlue={data.risk.valueBlue}
          />
          <RiskBarometerLegend sx={{ mt: 6, width: 382, height: 73 }} />
        </Box>
        <Box
          sx={{
            flex: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 4,
              alignItems: "center",
            }}
          >
            <Typography variant="par02Regular">
              How would you like the allocation displayed?
            </Typography>
            <CarRadioYesNo
              row
              yesLabel="%"
              noLabel="$"
              value={isPercentMode}
              onChange={setIsPercentMode}
            />
          </Box>
          <RiskTableReport
            title="Simulation Based Risk Report"
            hideSelectedPlan={hideSelectedPlan}
            selectedPlanLabel={data.risk.selectedPlanLabel}
            data={data.risk.simulationBasedRiskReport}
            convertToDollarFromTotal={
              isPercentMode ? undefined : data.beginningTotalPortfolio
            }
          />
          <RiskTableReport
            title="Historical Risk Report"
            hideSelectedPlan={hideSelectedPlan}
            selectedPlanLabel={data.risk.selectedPlanLabel}
            data={data.risk.historicalRiskReport}
            convertToDollarFromTotal={
              isPercentMode ? undefined : data.beginningTotalPortfolio
            }
          />
        </Box>
      </Box>

      {footer}
    </RootPageBox>
  );
};
