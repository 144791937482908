import { Fab } from "@mui/material";
import { showIntercom } from "app/thirdParty/intercom";

export const IntercomButton = () => (
  <Fab
    sx={{
      "&:hover": {
        backgroundColor: "secondary.light",
      },
      position: "absolute",
      right: 22,
      bottom: 22,
      width: 57,
      height: 57,
    }}
    color="secondary"
    onClick={showIntercom}
  >
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.1458 37.9981C34.2788 37.9981 34.4118 37.9634 34.5312 37.894C34.7702 37.7572 34.9167 37.5009 34.9167 37.2273V27.3226C36.9343 24.5458 38 21.3123 38 17.9575C38 8.6058 29.7001 1 19.5 1C9.29995 1 1 8.60773 1 17.9575C1 27.3072 9.29995 34.9149 19.5 34.9149C21.8857 34.9149 24.2214 34.4967 26.4471 33.6701L33.7604 37.8959C33.8799 37.9653 34.0129 38 34.1458 38V37.9981ZM19.5 2.54159C28.8502 2.54159 36.4583 9.45753 36.4583 17.9575C36.4583 21.0599 35.4447 24.0506 33.5292 26.6058C33.429 26.7387 33.375 26.9006 33.375 27.0682V35.8919L26.9 32.1516C26.6957 32.0341 26.451 32.0167 26.2332 32.1015C24.0845 32.9455 21.8183 33.3733 19.5 33.3733C10.1498 33.3733 2.54167 26.4574 2.54167 17.9575C2.54167 9.45753 10.1479 2.54159 19.5 2.54159Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  </Fab>
);
