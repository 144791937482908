import {
  useQuestionnairePlanRelationshipsListQuery,
  useQuestionnairePlanRelationshipsCreateMutation,
  useQuestionnairePlanRelationshipsDestroyMutation,
  useQuestionnairePlanRelationshipsUpdateMutation,
  useQuestionnairePlanSocialSecurityListQuery,
} from "api/carApi.generated";
import { CarRelationship } from "types";
import { refetchOnFocus } from "const";
import { differenceInYears } from "date-fns";
import { usePendingProxy } from "../general/usePendingProxy";
import { isDefined } from "utils";
import _ from "lodash";
import { useClientId, usePlanId } from "app/usePlanId";
import { useRelationTypes } from "app/useRelationTypes";
import { v4 } from "uuid";

export const getFullName = (item?: CarRelationship) => {
  return item?.first_name ? `${item?.first_name} ${item?.last_name}` : "";
};

export const setFirstName = (
  item: CarRelationship,
  value: CarRelationship["first_name"]
) => ({
  ...item,
  first_name: value,
});

export const setLastName = (
  item: CarRelationship,
  value: CarRelationship["last_name"]
) => ({
  ...item,
  last_name: value,
});

export const setSuffix = (
  item: CarRelationship,
  value: CarRelationship["suffix"]
) => ({
  ...item,
  suffix: value,
});

export const setDob = (
  item: CarRelationship,
  value: CarRelationship["date_of_birth"]
) => ({
  ...item,
  date_of_birth: value,
});

export const setRelationType = (
  item: CarRelationship,
  value: CarRelationship["relation_type"]
) => ({
  ...item,
  relation_type: value,
});

export const useRelationship = () => {
  const planId = usePlanId();
  const clientId = useClientId();
  const { isPrimary, isSecondary, primaryTypeId } = useRelationTypes();
  const { data, isLoading } = useQuestionnairePlanRelationshipsListQuery(
    { planId },
    { refetchOnFocus }
  );

  const { refetch: socialSecurityRefetch } =
    useQuestionnairePlanSocialSecurityListQuery({
      planId,
    });

  const [createRel] = useQuestionnairePlanRelationshipsCreateMutation();
  const [updateRel] = useQuestionnairePlanRelationshipsUpdateMutation();
  const [deleteRel] = useQuestionnairePlanRelationshipsDestroyMutation();

  const handleChange = (item: CarRelationship) => {
    return updateRel({
      planId,
      id: item.id ?? "",
      clientPlanRelationship: item as Required<CarRelationship>,
    });
  };

  const handleDelete = async (item: CarRelationship) => {
    const result = await deleteRel({ planId, id: item.id ?? "" });

    // if relationship has been deleted, it automatically removes associated social security item, so we need to refetch
    if (!result.hasOwnProperty("error")) {
      socialSecurityRefetch();
    }
    return result;
  };

  const handleCreate = (item: CarRelationship) => {
    return createRel({
      planId,
      clientPlanRelationship: item as Required<CarRelationship>,
    });
  };

  const proxy = usePendingProxy<CarRelationship>({
    name: "relationship",
    items: data ?? [],
    handleCreate,
    handleChange,
    handleDelete,
    normalizeItem: (item, oldItem) => {
      // set default values
      item.retirement_age = item.retirement_age ?? 70;
      item.live_until = item.live_until ?? 93;
      item.income = item.income ?? 0;

      // check for min values
      if (item.date_of_birth) {
        const currentAge = differenceInYears(
          Date.now(),
          Date.parse(item.date_of_birth)
        );
        item.live_until = Math.max(currentAge, item.live_until ?? 0);
      }
      item.retirement_age = Math.max(20, item.retirement_age ?? 0);

      if (!isPrimary(item)) {
        item.state_of_residence = null as unknown as undefined;
        item.retirement_state = null as unknown as undefined;
      }
      // if changed state_of_residence than copy value to retirement_state
      if (
        item.state_of_residence &&
        !_.isEqual(item.state_of_residence, oldItem?.state_of_residence)
      ) {
        item.retirement_state = item.state_of_residence;
      }
      if (!isPrimary(item) && !isSecondary(item)) {
        delete item.income;
        delete item.retirement_age;
        delete item.live_until;
      }
    },
    validate: (item: CarRelationship) => {
      let required: Array<keyof CarRelationship> = [
        "first_name",
        "last_name",
        "date_of_birth",
        "relation_type",
      ];

      if (isPrimary(item)) {
        required = [
          ...required,
          "state_of_residence",
          "retirement_age",
          "retirement_state",
          "live_until",
        ];
      } else if (isSecondary(item)) {
        required = [...required, "retirement_age", "live_until"];
      }

      return required.every((i) => !!item[i]);
    },
  });

  const addRow = () => {
    proxy.handleCreate({
      id: v4(),
      household: clientId,
      relation_type: proxy.items.some(isPrimary) ? undefined : primaryTypeId,
    });
  };

  const primaryItem = proxy.items.find(isPrimary);
  const secondaryItem = proxy.items.find(isSecondary);
  const items = [
    primaryItem,
    secondaryItem,
    ...proxy.items.filter((i) => !isPrimary(i) && !isSecondary(i)),
  ].filter(isDefined);

  return {
    ...proxy,
    items,
    primaryItem,
    secondaryItem,
    isLoading,
    addRow,
  };
};
