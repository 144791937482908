import { Document, Text, View } from "@react-pdf/renderer";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { useFirm } from "pages/settings/useFirm";
import { usePortfolioGroupInfo } from "pages/assetAllocation/usePortfolioGroupInfo";
import { PdfPage } from "components/Pdf";
import { CarPdfViewer } from "components/PdfViewer";
import { PageNumber, PageTitle, SectionHeader } from "./PageElements";
import { useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { usePortfolioGroupVersionStatData } from "../usePortfolioGroupVersionStatData";
import { PortfolioGroupMultiPeriodReturnComparisonPdfChart } from "../portfolioGroupModulePdfReport/PortfolioGroupMultiPeriodReturnComparisonPdfChart";
import { PortfolioGroupOutUnderPerfPdfChart } from "../portfolioGroupModulePdfReport/PortfolioGroupOutUnderPerfPdfChart";
import {
  DftTotalReturnsPdfSummary,
  PortfolioGroupDftTotalReturnsPdfChart,
} from "./PortfolioGroupDftTotalReturnsPdfChart";
import { useAssetClassSummaryStat } from "../../assetClassAnalysis/useAssetClassSummaryStat";
import { PortfolioGroupDftVersusTenYearReturnPdfChart } from "./PortfolioGroupDftVersusTenYearReturnPdfChart";
import { SimulatedForwardProbabilitiesPdfChart } from "./SimulatedForwardProbabilitiesPdfChart";
import { AssetClassLoadType } from "features/assets/useAssetClasses";

export const PortfolioGroupAssetClassAnalysisPdfReportPage = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const [searchParams] = useSearchParams();
  const data1Id = searchParams.get("data1Id") ?? "";
  const data2Id = searchParams.get("data2Id") ?? "";
  const isNominal = !!JSON.parse(searchParams.get("isNominal") ?? "false");
  const chartDateRange = Number.parseInt(
    searchParams.get("chartDateRange") ?? "20",
    10,
  );

  const firm = useFirm();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const simulationSuffix = groupInfo.getSimulationSuffix(true);

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const data = usePortfolioGroupVersionStatData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
  });

  const assetClassAnalysis = useAssetClassSummaryStat({
    type: AssetClassLoadType.byLatestSimulationJob,
    filterByUsableInPortfolios: true,
  });

  const distanceFromTrendItem = assetClassAnalysis.distanceFromTrendItems.find(
    (i) => i.level4Id === data1Id,
  );

  const title = data.oneYearUnderPerf.find((i) => i.id === data1Id)?.label;

  const isLoading =
    firm.isLoading ||
    groupInfo.isLoading ||
    data.isLoading ||
    assetClassAnalysis.isLoading;

  const theme = useTheme();

  return (
    <CarPdfViewer isLoading={isLoading}>
      <Document>
        <PdfPage
          hideFooter
          style={{
            paddingTop: 37,
            paddingBottom: 50,
            paddingLeft: 36,
            paddingRight: 36,
          }}
          footerProps={{
            isDraft: false,
            advisorCompanyName: "",
            clientName: "",
          }}
        >
          <PageTitle firmName={firm.firm.name} title={title ?? ""} />
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            1 Year Under Performance Risk
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 48,
            }}
          >
            Internal Use Only
          </Text>
          <PortfolioGroupOutUnderPerfPdfChart
            width={530}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.oneYearUnderPerf}
            periods={data.periods}
            data1Id={data1Id}
            data2Id={data2Id}
            isRelative={false}
          />
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            3 Year Under Performance Risk
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 48,
            }}
          >
            Internal Use Only
          </Text>
          <PortfolioGroupOutUnderPerfPdfChart
            width={530}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.threeYearUnderPerf}
            periods={data.periods}
            data1Id={data1Id}
            data2Id={data2Id}
            isRelative={false}
          />
          <PageNumber />
        </PdfPage>
        <PdfPage
          hideFooter
          style={{
            paddingTop: 37,
            paddingBottom: 50,
            paddingLeft: 36,
            paddingRight: 36,
          }}
          footerProps={{
            isDraft: false,
            advisorCompanyName: "",
            clientName: "",
          }}
        >
          <PageTitle firmName={firm.firm.name} title={title ?? ""} />
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            Cumulative Relative Performance 1998 - 2023
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 48,
            }}
          >
            Internal Use Only
          </Text>
          <PortfolioGroupOutUnderPerfPdfChart
            width={530}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.cumRelPerf}
            periods={data.periods}
            data1Id={data1Id}
            data2Id={data2Id}
            isRelative={true}
          />
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            Multi-Period Return Comparison
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 10,
            }}
          >
            Internal Use Only
          </Text>
          <PortfolioGroupMultiPeriodReturnComparisonPdfChart
            width={520}
            height={250}
            style={{ marginLeft: 10 }}
            data={data.multiPeriodReturnComparison}
            data1Id={data1Id}
            data2Id={data2Id}
          />
          <SectionHeader
            style={{
              marginTop: 36,
            }}
          >
            {`Simulated Forward Probabilities${simulationSuffix} - ${chartDateRange} Years`}
          </SectionHeader>
          <Text
            style={{
              fontSize: 8,
              fontStyle: "italic",
              textAlign: "right",
              color: theme.palette.softBlack,
              marginRight: 10,
            }}
          >
            Internal Use Only
          </Text>
          <SimulatedForwardProbabilitiesPdfChart
            data={isNominal ? data.nominalHeatmap : data.realHeatmap}
            isNominal={isNominal}
            chartDateRange={chartDateRange}
            dataId={data1Id}
            label={title ?? ""}
          />
          {distanceFromTrendItem?.modelChartData && (
            <>
              <View break />
              <DftTotalReturnsPdfSummary
                style={{ marginTop: 10 }}
                data={distanceFromTrendItem.modelChartData}
                realStaticExpectedReturn={
                  distanceFromTrendItem.realStaticExpectedReturn ?? 0
                }
              />
              <SectionHeader
                style={{
                  marginTop: 20,
                }}
              >
                {title}
              </SectionHeader>
              <Text
                style={{
                  fontSize: 8,
                  fontStyle: "italic",
                  textAlign: "right",
                  color: theme.palette.softBlack,
                  marginRight: 10,
                }}
              >
                Internal Use Only
              </Text>
              <PortfolioGroupDftTotalReturnsPdfChart
                width={530}
                height={240}
                data={distanceFromTrendItem.modelChartData}
                assetClassName={title ?? ""}
              />
              <SectionHeader
                style={{
                  marginTop: 36,
                }}
              >
                Real 10 Year Return vs. Distance from Trend
              </SectionHeader>
              <Text
                style={{
                  fontSize: 8,
                  fontStyle: "italic",
                  textAlign: "right",
                  color: theme.palette.softBlack,
                  marginRight: 10,
                }}
              >
                Internal Use Only
              </Text>
              <PortfolioGroupDftVersusTenYearReturnPdfChart
                width={550}
                height={240}
                data={distanceFromTrendItem.modelChartData}
                assetClassName={title ?? ""}
              />
            </>
          )}

          <PageNumber />
        </PdfPage>
      </Document>
    </CarPdfViewer>
  );
};
