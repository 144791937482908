import { Box, BoxProps, Typography, styled } from "@mui/material";
import {
  CarAllocationAccount,
  CarAllocationExtraAsset,
  useAllocationByAccount,
} from "features/allocationByAccount/useAllocationByAccount";
import { formatCurrency } from "utils";
import { CarRadioYesNo } from "components/Radio";
import React, { useState } from "react";
import { useExpandSingle } from "features/general/useExpand";
import {
  CarAccordion,
  CarAccordionSummary,
  CarAccordionDetails,
} from "components/Accordion";
import { AllocationBreakdownTable } from "components/AllocationBreakdown";
import { TablePlaceHolder } from "components/PlaceHolder";
import { PlanProposedAllocationTable } from "features/householdAllocation/PlanProposedAllocationTable";
import { colorByStatus } from "utils/colorUtils";
import { usePlanTypes } from "app/usePlanTypes";
import { usePlanTypeId } from "app/usePlanId";
import { WorkbenchInflationSmall } from "../WorkbenchInflation";
import { CarTooltipBox } from "components/TooltipBox";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";

const StyledAccordion = styled(CarAccordion)(({ theme }) => ({
  backgroundColor: "unset",
}));

const StyledAccordionDetails = styled(CarAccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.white,
  borderBottomLeftRadius: "5px",
  borderBottomRightRadius: "5px",
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

const TotalWithSurplus = (props: {
  label: string;
  pendoClass: string;
  surplusAmount?: number;
  sx?: BoxProps["sx"];
}) => (
  <Box sx={{ display: "flex", alignItems: "center", ...props.sx }}>
    <Typography variant="h6SSemiBold">{props.label}</Typography>
    <CarTooltipBox sx={{ ml: 0.25 }} className={props.pendoClass} />
    {!!props.surplusAmount && (
      <>
        <Box
          sx={{
            flex: "auto",
            borderBottom: "solid 1px",
            alignSelf: "center",
            borderColor: "gray3",
            mx: 2,
          }}
        />
        <Typography variant="par02Regular">
          {`You have a surplus of `}
          <Box
            component="span"
            sx={{
              // ml: 0.5,
              // mr: 0.5,
              fontWeight: 700,
              color: "green",
            }}
          >
            {formatCurrency(props.surplusAmount)}
          </Box>
          {` in this account.`}
        </Typography>
      </>
    )}
  </Box>
);

interface ExtraAssetsRowProps {
  items: CarAllocationExtraAsset[];
}

const ExtraAssetsRow = React.memo(({ items }: ExtraAssetsRowProps) => {
  const expand = useExpandSingle(`AllocationByAccount-assets`);
  return (
    <StyledAccordion {...expand} TransitionProps={{ unmountOnExit: true }}>
      <CarAccordionSummary>
        <Typography variant="h6SemiBold" sx={{ ml: 1 }}>
          Assets
        </Typography>
      </CarAccordionSummary>
      <StyledAccordionDetails sx={{ gap: 4 }}>
        <WorkbenchInflationSmall sx={{ alignSelf: "flex-end", mb: -3 }} />
        {items.map((item) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
            key={item.id}
          >
            <TotalWithSurplus
              label={`Total $ from ${item.name} to fund each goal`}
              surplusAmount={item.surplusAmount}
              pendoClass={
                pendoClasses.workbenchAllocationByAccountTotalToFundEachGoal
              }
            />
            {item.totalAmountToFundEachGoal?.length > 0 ? (
              item.totalAmountToFundEachGoal?.map((i, idx) => (
                <Typography key={idx} sx={{ mx: 3 }} variant="par02Regular">
                  {`${i.name}:`}
                  <Box
                    component="span"
                    sx={{ ml: 0.5, color: colorByStatus(i.goal_status) }}
                  >
                    {formatCurrency(i.dollars)}
                  </Box>
                </Typography>
              ))
            ) : (
              <Typography sx={{ mx: 3 }} variant="par02Regular">
                -
              </Typography>
            )}
          </Box>
        ))}
      </StyledAccordionDetails>
    </StyledAccordion>
  );
});

interface AccountRowProps {
  item: CarAllocationAccount;
}

const AccountRow = React.memo(({ item }: AccountRowProps) => {
  const expand = useExpandSingle(`AllocationByAccount-${item.id}`);
  const [isPercentMode, setIsPercentMode] = useState(true);
  const [
    isMultiModule,
    // , setIsMultiModule
  ] = useState(true);
  const { getPlanTypeLabel } = usePlanTypes();
  const planTypeId = usePlanTypeId();
  const portfolioLabel = getPlanTypeLabel(planTypeId);

  const breakdown = isMultiModule
    ? item.multiModuleBreakdown
    : item.singleModuleBreakdown ?? [];

  return (
    <StyledAccordion {...expand} TransitionProps={{ unmountOnExit: true }}>
      <CarAccordionSummary>
        <Typography variant="h6SemiBold" sx={{ ml: 1 }}>
          {item.name}
        </Typography>
      </CarAccordionSummary>
      <StyledAccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <WorkbenchInflationSmall sx={{ alignSelf: "flex-end", mb: -1.5 }} />
          <Box sx={{ display: "flex" }}>
            <Typography variant="h6SSemiBold">
              Current account balance to fund these goals
            </Typography>
            <CarTooltipBox
              sx={{ ml: 0.25 }}
              className={
                pendoClasses.workbenchAllocationByAccountCurrentAccountBalance
              }
            />
          </Box>
          {item.currentAccountBalanceToFundGoals?.map((i, idx) => (
            <Typography key={idx} sx={{ mx: 3 }} variant="par02Regular">
              {`${i.name}: `}
              <Box
                component="span"
                sx={{ color: colorByStatus(i.goal_status) }}
              >
                {formatCurrency(i.dollars)}
              </Box>
            </Typography>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {item.isPortfolioModulesAvailable && (
            <>
              <Box
                sx={{
                  mt: 6,
                  // mb: 2,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/*
              Hidden for now https://app.clickup.com/t/8688znng4
                {item.singleModuleBreakdown && (
                  <>
                    <Typography variant="par02Regular">
                      Multi-module or Single-module solution?
                    </Typography>

                    <CarRadioYesNo
                      row
                      sx={{
                        ml: 2,
                      }}
                      yesLabel="Multi-module"
                      noLabel="Single-module"
                      value={isMultiModule}
                      onChange={setIsMultiModule}
                    />
                  </>
                )}
                */}
              </Box>
              <AllocationBreakdownTable items={breakdown} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                }}
              >
                <TotalWithSurplus
                  sx={{ mt: 3 }}
                  label={`Total $ from ${item.name} to fund each goal`}
                  surplusAmount={item.surplusAmount}
                  pendoClass={
                    pendoClasses.workbenchAllocationByAccountTotalToFundEachGoal
                  }
                />
                {item.totalAmountToFundEachGoal?.map((i, idx) => (
                  <Typography key={idx} sx={{ mx: 3 }} variant="par02Regular">
                    {`${i.name}:`}
                    <Box
                      component="span"
                      sx={{ ml: 0.5, color: colorByStatus(i.goal_status) }}
                    >
                      {formatCurrency(i.dollars)}
                    </Box>
                  </Typography>
                ))}
              </Box>
            </>
          )}
          <Box sx={{ mt: 4, mb: 2, display: "flex", alignItems: "center" }}>
            <Typography variant="par02Regular">
              How would you like the allocation displayed?
            </Typography>
            <CarRadioYesNo
              row
              sx={{
                ml: 2,
              }}
              yesLabel="%"
              noLabel="$"
              value={isPercentMode}
              onChange={setIsPercentMode}
            />
          </Box>
          <PlanProposedAllocationTable
            sx={{ backgroundColor: "white" }}
            items={item.allocAssets.level1}
            isPercentMode={isPercentMode}
            portfolioLabel={portfolioLabel}
          />
        </Box>
      </StyledAccordionDetails>
    </StyledAccordion>
  );
});

export const AllocationByAccount = () => {
  const pageContent = usePageContent();
  const data = useAllocationByAccount();

  return (
    <TablePlaceHolder
      isLoading={pageContent.isLoading || data.isLoading}
      rows={10}
    >
      <CarPageContent
        isWhiteContext
        content={pageContent.getContent(
          CarPageContentCode.PLAN_WORKBENCH_ALLOCATION_BY_ACCOUNT_DIRECTIONS,
        )}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {data.extraAssets.length > 0 && (
          <ExtraAssetsRow items={data.extraAssets} />
        )}
        {data.accounts.map((i) => (
          <AccountRow key={i.id} item={i} />
        ))}
      </Box>
    </TablePlaceHolder>
  );
};
