import background from "assets/background.jpg";
import { Box, styled } from "@mui/material";

const APP_WIDTH = 1024;

export const FullScreenBackground = styled(Box)(({ theme }) => ({
  minWidth: APP_WIDTH,
  width: "100%",

  backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundAttachment: "fixed",
  backgroundSize: "cover",
  backgroundPosition: "right bottom",

  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  alignItems: "stretch",
}));
