import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { BaseDialog } from "components/BaseDialog";
import {
  useSimulationSimJobYieldCurvesByPercentileListQuery,
  useSimulationSimJobYieldCurvesByYearListQuery,
} from "api/carApi.generated";
import {
  getYieldCurveChartData,
  SimulationYieldsChart2d,
  YieldCurveItem,
  YieldCurveRate,
} from "./SimulationYieldsChart2d";
import { InternalUseWithDisclosures } from "pages/assetAllocation/editPortfolioGroup/InternalUse";
import { useEffect, useState } from "react";
import { useLocalStorage } from "features/general/useLocalStorage";
import { CarSwitch } from "components/Switch";
import { SimulationYieldsChart3d } from "./SimulationYieldsChart3d";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarDownloadButton } from "components/Buttons";
import {
  ExcelColumn,
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";

interface SimulationYieldsDialogProps {
  simulationJobId: string;
  title: string;
  subTitle: string;
  percentile?: number;
  year?: number;
  isExtended?: boolean;
  onApply: () => void;
  onCancel: () => void;
}

const parseSimulationYearLabel = (label: string) =>
  Number.parseInt(label.split(" ").at(-1) ?? "0", 10) || 0;

export const SimulationYieldsDialog = (props: SimulationYieldsDialogProps) => {
  const [hiddenDatasetIds, setHiddenDatasetIds] = useState<string[]>([]);

  const [isChart3d, setIsChart3d] = useLocalStorage(
    "SimulationYieldsDialog_isChart3d",
    true,
  );

  const excelExport = useExcelExport();

  const pageContent = usePageContent();

  const byPercentile = useSimulationSimJobYieldCurvesByPercentileListQuery(
    {
      simJobId: props.simulationJobId,
      percentile: props.percentile ?? 0,
    },
    { skip: !props.percentile },
  );
  const byYear = useSimulationSimJobYieldCurvesByYearListQuery(
    {
      simJobId: props.simulationJobId,
      year: props.year ?? 0,
    },
    { skip: !props.year },
  );

  const percentileData = byPercentile.data;

  useEffect(() => {
    if (percentileData && percentileData?.length > 0) {
      setHiddenDatasetIds(
        percentileData
          .map((i) => i.description)
          .filter((i) => {
            const isMoreThan20 = parseSimulationYearLabel(i) > 20;
            return props.isExtended !== isMoreThan20;
          }),
      );
    }
  }, [props.isExtended, percentileData]);

  const yieldCurveItems = (
    byPercentile.data ??
    byYear.data ??
    []
  ).map<YieldCurveItem>((i) => ({
    id: i.description,
    label: i.description,
    rates: i.rates.map<YieldCurveRate>((r) => ({
      position: {
        id: r.position.id ?? "",
        label: r.position.label ?? "",
        position: r.position.position ?? 0,
      },
      rate: r.rate,
    })),
  }));

  const datasetsLabel = props.percentile ? "Year" : "Percentile";

  const handleDownloadReports = () => {
    const chartData = getYieldCurveChartData(yieldCurveItems, hiddenDatasetIds);

    console.log(chartData);

    const filteredDatasets = chartData.datasets.filter((i) => !i.hidden);

    console.log({ filteredDatasets });

    const table: ExcelTable = {
      columns: [
        {
          label: datasetsLabel,
          format: ExcelDataFormat.general,
          charWidth: 20,
        },
        ...filteredDatasets.map<ExcelColumn>((i) => ({
          label: i.label ?? "",
          format: ExcelDataFormat.percent,
          charWidth: 20,
        })),
      ],
      name: props.subTitle,
      rows: chartData.labels.map((l, rowIdx) => [
        l,
        ...filteredDatasets.map((ds) => ds.data.at(rowIdx)),
      ]),
    };

    excelExport.exportAllExcel({
      filePrefix: `Simulation Yields - ${props.title} - ${props.subTitle}`,
      tables: [table],
    });
  };

  return (
    <BaseDialog
      sx={{ width: "1300px", maxWidth: "100vw" }}
      onClose={props.onCancel}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          mr: -1,
          pr: 1,
        }}
      >
        <Typography variant="h2SSemiBold">{props.title}</Typography>
        <Typography variant="h3SSemiBold" sx={{ fontWeight: 400, mt: 0.25 }}>
          {props.subTitle}
        </Typography>
        <TablePlaceHolder
          isLoading={byPercentile.isLoading || byYear.isLoading}
          rows={10}
          sx={{ mt: 4 }}
        >
          <CarPageContent
            sx={{ mt: 3 }}
            content={pageContent.getContent(
              CarPageContentCode.SIMULATION_ANALYSIS_DATA_EXPLORER_FILTER_LB,
            )}
          />
          <Box sx={{ display: "flex", mt: 4, alignItems: "center" }}>
            <Typography variant="h6SSemiBold">Interactive Chart</Typography>
            <CarDownloadButton
              sx={{ ml: "auto" }}
              onClick={handleDownloadReports}
            >
              Download Reports
            </CarDownloadButton>
          </Box>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "gray3",
              backgroundColor: "gray1",
              p: 5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="par01Regular">2D Chart</Typography>
                <CarSwitch
                  size="small"
                  checked={isChart3d}
                  onChange={(e, value) => setIsChart3d(value)}
                />
                <Typography variant="par01Regular">3D Chart</Typography>
              </Box>
              <InternalUseWithDisclosures sx={{ ml: "auto", mr: 2.5, mb: 1 }} />
            </Box>
            {isChart3d ? (
              <SimulationYieldsChart3d
                hiddenDatasetIds={hiddenDatasetIds}
                setHiddenDatasetIds={setHiddenDatasetIds}
                data={yieldCurveItems}
                datasetsLabel={datasetsLabel}
              />
            ) : (
              <SimulationYieldsChart2d
                hiddenDatasetIds={hiddenDatasetIds}
                setHiddenDatasetIds={setHiddenDatasetIds}
                data={yieldCurveItems}
                datasetsLabel={datasetsLabel}
              />
            )}
          </Box>
        </TablePlaceHolder>
      </Box>
    </BaseDialog>
  );
};
