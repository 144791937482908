import { useState } from "react";
import { CarButton, CarLoadingButton } from "./Buttons";
import { BaseDialog } from "./BaseDialog";
import { Box, Divider, Typography } from "@mui/material";

export interface ConfirmDialogProps {
  onApply: (value: boolean) => void;
  onCancel: () => void;
  label: string;
  message: string;
  cancelLabel?: string;
  applyLabel?: string;
  onApplying?: () => Promise<boolean>;
}

export const ConfirmDialog = (props: ConfirmDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleApply = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    try {
      if (!props.onApplying || (await props.onApplying())) {
        props.onApply(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BaseDialog
      onClose={props.onCancel}
      actions={
        <>
          <CarButton
            sx={{ alignSelf: "center", fontSize: "18px" }}
            onClick={() => {
              if (!isLoading) {
                props.onCancel();
              }
            }}
            variant="outlined"
          >
            {props.cancelLabel ?? "Cancel"}
          </CarButton>
          <CarLoadingButton
            sx={{ alignSelf: "center", fontSize: "18px" }}
            onClick={handleApply}
            autoFocus
            isLoading={isLoading}
          >
            {props.applyLabel ?? "Apply"}
          </CarLoadingButton>
        </>
      }
    >
      <Box
        sx={{
          gap: 1.5,
          minHeight: 140,
          maxWidth: 340,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h4SemiBold" sx={{ fontSize: "25px" }}>
          {props.label}
        </Typography>
        <Divider />
        <Typography variant="par01Regular">{props.message}</Typography>
      </Box>
    </BaseDialog>
  );
};
