import { usePortfolioGroupVersionStatData } from "../../usePortfolioGroupVersionStatData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "../../usePortfolioGroupInfo";
import { useChartDataSelector } from "./../useChartDataSelector";
import { TablePlaceHolder } from "components/PlaceHolder";
import { ChartMultiPeriodReturnComparison } from "./../ChartMultiPeriodReturnComparison";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarPageContent } from "components/PageContent";
import { TableMultiPeriodReturnComparison } from "./../TableMultiPeriodReturnComparison";
import { AnalyzeModuleStyledPage } from "./AnalyzeModuleStyledPage";

interface AnalyzeModuleMultiPeriodReturnProps {
  moduleId: string;
  moduleIdx: number;
}

export const AnalyzeModuleMultiPeriodReturn = (
  props: AnalyzeModuleMultiPeriodReturnProps,
) => {
  const { portfolioGroupId } = usePortfolioGroupParams();

  const pageContent = usePageContent();

  const { moduleId, moduleIdx } = props;

  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { portfolioGroupVersionId, publishedVersionId } = groupInfo;

  const statData = usePortfolioGroupVersionStatData({
    versionId: portfolioGroupVersionId,
    publishedVersionId,
    filterByModuleId: moduleId,
  });

  const defaultIds = statData.getDefaultDataIdsForModule(moduleId);

  const chartDataSelector = useChartDataSelector({
    storageKey: `EditPortfolioGroupAnalyze_${portfolioGroupVersionId}`,
    ...defaultIds,
  });

  return (
    <AnalyzeModuleStyledPage>
      <TablePlaceHolder
        isLoading={
          groupInfo.isLoading || statData.isLoading || pageContent.isLoading
        }
        rows={5}
      >
        <CarPageContent
          sx={{ mb: 5 }}
          isWhiteContext
          content={pageContent.getContent(
            groupInfo.isSinglePortfolioMode
              ? CarPageContentCode.PORTFOLIO_ANALYSIS_ANALYZE_MULTI_PERIOD_RETURN
              : CarPageContentCode.ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE_MULT_PER_RETURN,
          )}
        />

        <ChartMultiPeriodReturnComparison
          selectorLabel1="Portfolio Variation 1"
          selectorLabel2="Portfolio Variation 2"
          data={statData.multiPeriodReturnComparison}
          chartDataSelector={chartDataSelector}
          pendoPrefix={`module_${moduleIdx}_multi_period_return_comparison`}
        />
        <TableMultiPeriodReturnComparison
          sx={{ mt: 5 }}
          data={statData.multiPeriodReturnComparison}
          chartDataSelector={chartDataSelector}
        />
      </TablePlaceHolder>
    </AnalyzeModuleStyledPage>
  );
};
