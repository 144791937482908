import { CarAllocAsset } from "app/useAllocAssets";
import { formatCurrency, formatPercent } from "utils";
import {
  CarPdfHeader,
  CarPdfHeaderCell,
  CarPdfRow,
  CarPdfTable,
  CarPdfTableProps,
  CarPdfTextCell,
  CarPdfViewCell,
  CarPdfViewCellProps,
} from "components/PdfTable";

interface CurrencyPercentCellProps extends CarPdfViewCellProps {
  percent: number;
  currency: number;
}

const CurrencyPercentCell = ({
  percent,
  currency,
  ...props
}: CurrencyPercentCellProps) => (
  <CarPdfViewCell {...props} style={{ paddingVertical: 0 }}>
    <CarPdfTextCell
      variant="tableBody3"
      paddingVertical={10}
      style={{
        borderLeftWidth: 0,
        borderBottomWidth: 0,
      }}
    >
      {formatCurrency(currency)}
    </CarPdfTextCell>
    <CarPdfTextCell
      variant="tableBody3"
      paddingVertical={10}
      style={{
        borderBottomWidth: 0,
        borderRightWidth: 0,
      }}
    >
      {formatPercent(percent)}
    </CarPdfTextCell>
  </CarPdfViewCell>
);

interface CarPdfAllocationTableProps
  extends Omit<CarPdfTableProps, "children"> {
  portfolioLabel: string;
  items: CarAllocAsset[];
}

export const CarPdfAllocationTable = ({
  portfolioLabel,
  items,
  ...props
}: CarPdfAllocationTableProps) => (
  <CarPdfTable {...props}>
    <CarPdfHeader paddingVertical={14}>
      <CarPdfHeaderCell />
      <CarPdfHeaderCell>Existing Portfolio</CarPdfHeaderCell>
      <CarPdfHeaderCell>{portfolioLabel}</CarPdfHeaderCell>
      <CarPdfHeaderCell>Difference</CarPdfHeaderCell>
    </CarPdfHeader>
    {items.map((i, idx) => (
      <CarPdfRow key={idx} paddingVertical={10}>
        <CarPdfTextCell
          variant="tableBody3"
          style={{
            textAlign: "left",
            paddingHorizontal: 5,
          }}
        >
          {i.name}
        </CarPdfTextCell>
        <CurrencyPercentCell
          currency={i.current_dollars}
          percent={i.current_percent}
        />
        <CurrencyPercentCell
          currency={i.proposed_dollars}
          percent={i.proposed_percent}
        />
        <CurrencyPercentCell
          currency={i.diff_dollars}
          percent={i.diff_percent}
        />
      </CarPdfRow>
    ))}
  </CarPdfTable>
);
