import { usePortfolioOptimizerDynamicRulesListQuery } from "api/carApi.generated";
import { useMemo } from "react";

export interface OptimizerDynamicRuleItem {
  id: string;
  label: string;
  code: string;
}

export const useOptimizerDynamicRules = () => {
  const { isLoading, data } = usePortfolioOptimizerDynamicRulesListQuery();

  return useMemo(() => {
    return {
      isLoading,
      items:
        data
          ?.filter((i) => i.is_active)
          .map<OptimizerDynamicRuleItem>((i) => ({
            id: i.id ?? "",
            label: i.name ?? "",
            code: i.code ?? "",
          })) ?? [],
    };
  }, [data, isLoading]);
};
