import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Search } from "@mui/icons-material";
import { Box, Collapse, Divider, styled } from "@mui/material";
import { CarLoadingButton } from "components/Buttons";
import { FoundAccounts } from "./FoundAccounts";
import { useSchwabSearch } from "../useSchwabSearch";

const SearchField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    backgroundColor: theme.palette.white,
    paddingLeft: 0,
    width: 260,
    height: 43,
    "& input": {
      paddingLeft: 0,
      ...theme.typography.par02Regular,
      color: theme.palette.softBlack,
    },
    ".icon": {
      color: theme.palette.gray7,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
}));

export const SchwabSearch = () => {
  const [text, setText] = useState("");

  const [checkedAccounts, setCheckedAccounts] = useState<Array<string>>([]);

  const data = useSchwabSearch();

  const handleSelectClick = () => {
    data.handleLink(checkedAccounts);
  };

  const handleSearch = () => data.handleSearch(text);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        <SearchField
          variant="outlined"
          autoFocus
          placeholder="Search (integration portfolios)"
          InputProps={{
            startAdornment: <Search className="icon" />,
          }}
          value={text}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          onChange={(e) => setText(e.target.value)}
        />
        <CarLoadingButton
          isLoading={data.isAccountsLoading}
          onClick={handleSearch}
        >
          Search
        </CarLoadingButton>
      </Box>
      <Divider
        sx={{
          mt: 1.5,
          mb: 2,
        }}
      />
      <Collapse in={data.isSearchExecuted}>
        <FoundAccounts
          isLoading={data.isAccountsLoading}
          items={data.accounts.map((i) => ({
            label: i.name,
            value: i.external_account_id,
          }))}
          checkedItems={checkedAccounts}
          onChange={setCheckedAccounts}
        />
        <Box sx={{ mt: 3, display: "flex", gap: 2, justifyContent: "center" }}>
          <CarLoadingButton
            onClick={handleSelectClick}
            isLoading={data.linkIsLoading}
          >
            Select
          </CarLoadingButton>
        </Box>
      </Collapse>
    </Box>
  );
};
