import { Box, Typography, useTheme } from "@mui/material";
import {
  ChartTooltip,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { Chart } from "react-chartjs-2";
import { formatPercent } from "utils";
import { AssetClassLevel } from "const";
import {
  CarPortfolioAssetColumnEnum,
  CarPortfolioAssetTableData,
} from "../usePortfolioGroupVersionData";
import { ChartPlugins } from "chartUtils";
import { ComponentProps, useMemo } from "react";
import { UseChartDataSelector } from "./useChartDataSelector";
import { CarSelectChartField } from "./SelectChartField";
import { CarPortfolioChartItem } from "../asset-allocation-types";
import { InternalUseWithDisclosures } from "./InternalUse";
import { ChartDataset } from "chart.js";

export interface ChartModuleSummaryData {
  yLabel: string;
  xLabels: Array<string>;
  tooltipLabels: Array<string>;
  datasets: Array<ChartDataset<"bar", number[]>>;
}

export const getChartModuleSummaryData = ({
  moduleId,
  tableData,
  level,
  data1Id,
  data2Id,
}: {
  moduleId: string;
  tableData: CarPortfolioAssetTableData;
  level: AssetClassLevel;
  data1Id?: string;
  data2Id?: string;
}) => {
  const rows = tableData.assetRows[level].filter((row) => {
    const mod = row.modules.find((mod) => mod.moduleId === moduleId);

    const val1 =
      mod?.values.find((val) => val.dataId === data1Id)?.allocation ?? 0;
    const val2 =
      mod?.values.find((val) => val.dataId === data2Id)?.allocation ?? 0;

    return val1 > 0.001 || val2 > 0.001;
  });

  const data: ChartModuleSummaryData = {
    xLabels: rows.map((i) => i.shortName),
    yLabel: "Benchmark",

    tooltipLabels: rows.map((i) => i.name),
    datasets: [
      {
        id: "line1",
        type: "sharpBar" as "bar",
        label:
          tableData.bands[0]?.columns.find((c) => c.dataId === data1Id)
            ?.label ?? "",
        data: rows.map<number>(
          (row) =>
            (row.modules
              .find((mod) => mod.moduleId === moduleId)
              ?.values.find((val) => val.dataId === data1Id)?.allocation ?? 0) *
            100,
        ),
      },
      {
        id: "line2",
        type: "sharpBar" as "bar",
        label:
          tableData.bands[0]?.columns.find((c) => c.dataId === data2Id)
            ?.label ?? "",
        data: rows.map<number>(
          (row) =>
            (row.modules
              .find((mod) => mod.moduleId === moduleId)
              ?.values.find((val) => val.dataId === data2Id)?.allocation ?? 0) *
            100,
        ),
      },
    ],
  };

  return data;
};

type Options = ComponentProps<typeof Chart>["options"];

interface ChartModuleSummaryProps {
  moduleId: string;
  tableData: CarPortfolioAssetTableData;
  level: AssetClassLevel;
  chartDataSelector: UseChartDataSelector;
  pendoClass?: string;
  isWhiteContext?: boolean;
}

export const ChartModuleSummary = ({
  moduleId,
  tableData,
  level,
  chartDataSelector,
  pendoClass,
  isWhiteContext,
}: ChartModuleSummaryProps) => {
  const theme = useTheme();
  const { tooltipPlugin, tooltipData } = useChartTooltip();

  const data = getChartModuleSummaryData({
    moduleId,
    tableData,
    level,
    data1Id: chartDataSelector.data1Id,
    data2Id: chartDataSelector.data2Id,
  });

  const items =
    tableData.bands
      .find((b) => b.moduleId === moduleId)
      ?.columns.map<CarPortfolioChartItem>((col) => ({
        id: col.dataId,
        label: col.label,
        fullLabel: col.label,
        isBenchmark: col.columnId === CarPortfolioAssetColumnEnum.benchmark,
        isDraft: col.columnId === CarPortfolioAssetColumnEnum.draft,
        x: [],
        y: [],
      })) ?? [];

  const options = useMemo<Options>(
    () => ({
      maintainAspectRatio: false,
      animation: {
        duration: 500,
      },
      scales: {
        x: {
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 13,
              weight: "600",
            },
            color: theme.palette.softBlack,
          },
          border: { display: false },
          grid: {
            display: false,
          },
          labels: data.xLabels,
        },
        y: {
          title: {
            display: true,
            text: data.yLabel,
            font: {
              family: theme.typography.fontFamily,
              size: 16,
              weight: "600",
            },
            color: theme.palette.softBlack,
          },
          beginAtZero: true,
          offset: true,
          ticks: {
            font: {
              family: theme.typography.fontFamily,
              size: 15,
              weight: "400",
            },
            color: theme.palette.softBlack,
            callback: (value) =>
              typeof value === "number" ? formatPercent(value) : value,
          },
          border: { display: false },
          grid: {
            display: true,
            color: (ctx) => {
              return ctx.tick.value === 0
                ? theme.palette.gray7
                : theme.palette.gray2;
            },
            lineWidth: 1,
            tickColor: theme.palette.white,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: tooltipPlugin,
        roundedBackground: {
          contextColor: isWhiteContext
            ? theme.palette.white
            : theme.palette.gray1,
          backgroundColor: isWhiteContext
            ? theme.palette.gray1
            : theme.palette.white,
          borderColor: theme.palette.gray7,
          borderRadius: 5,
        },
      },
    }),
    [theme, tooltipPlugin, isWhiteContext, data.xLabels, data.yLabel],
  );

  const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
    const ds = data.datasets[datasetIndex];
    const value = ds?.data[dataIndex];
    return (
      <>
        <Box
          sx={{
            fontSize: 15,
            fontWeight: 600,
            color: "black",
            whiteSpace: "nowrap",
          }}
        >
          <Typography variant="par01SemiBold" sx={{ fontSize: 15 }}>
            {data.tooltipLabels[dataIndex]}
          </Typography>
          <br />
          <Typography variant="par01Regular" sx={{ fontSize: 13 }}>
            {typeof value === "number" ? `${formatPercent(value)}` : ""}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ mt: 3, ml: 10, display: "flex", alignItems: "flex-end" }}>
        <Typography
          variant="par01Regular"
          sx={{ color: "black" }}
        >{`Updated:  MM/DD/YYYY`}</Typography>
        <InternalUseWithDisclosures sx={{ ml: "auto" }} />
      </Box>
      <ChartTooltip
        tooltipData={tooltipData}
        renderTooltip={renderTooltip}
        sx={{
          width: "100%",
          height: 470,
        }}
      >
        <Chart
          type="bar"
          className={pendoClass}
          datasetIdKey="id"
          data={{
            labels: data.xLabels,
            datasets: data.datasets.map((i, idx) => ({
              ...i,
              borderWidth: 0,
              backgroundColor:
                idx === 0
                  ? theme.palette.chartsColor.green
                  : theme.palette.chartsColor.orange,
              barPercentage: 0.9,
              categoryPercentage: 0.45,
            })),
          }}
          options={options}
          plugins={[ChartPlugins.roundedBackground]}
        />
      </ChartTooltip>
      <Box
        sx={{
          mt: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap: 3,
        }}
      >
        <CarSelectChartField
          sx={{
            alignSelf: "flex-start",
            width: 200,
            ".MuiInputBase-root": {
              backgroundColor: "white",
            },
          }}
          value={chartDataSelector.data1Id}
          onChange={chartDataSelector.onData1IdChange}
          items={items}
          chartColor={theme.palette.chartsColor.green}
        />
        <CarSelectChartField
          sx={{
            alignSelf: "flex-start",
            width: 200,
            ".MuiInputBase-root": {
              backgroundColor: "white",
            },
          }}
          value={chartDataSelector.data2Id}
          onChange={chartDataSelector.onData2IdChange}
          items={items}
          chartColor={theme.palette.chartsColor.orange}
        />
      </Box>
    </Box>
  );
};
