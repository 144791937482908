import { useAlEnginePlanResultActionPlanRetrieveQuery } from "api/carApi.generated";
import { useRender } from "app/useRender";
import { formatCurrency, formatPercent, todoValue } from "utils";
import { useHouseholdAllocation } from "../householdAllocation/useHouseholdAllocation";
export interface CarActionPlanChildItem {
  name: string;
  fundedItems?: string[];
}
export interface CarActionPlanItem {
  name: string;
  childItems: CarActionPlanChildItem[];
}

export const usePublishPlanActionPlan = () => {
  const render = useRender();
  const householdAllocation = useHouseholdAllocation();
  const actionPlan = useAlEnginePlanResultActionPlanRetrieveQuery(
    {
      id: render.planResult?.id ?? "",
    },
    { skip: !render.planResult?.id }
  );

  const spendingValue =
    actionPlan.data?.goals_next_year.reduce(
      (acc, i) => acc + (i.amount_next_year ?? 0),
      0
    ) ?? 0;

  const savingsValue =
    actionPlan.data?.savings_next_year.reduce(
      (acc, i) => acc + (i.savings_next_year ?? 0),
      0
    ) ?? 0;

  const spending: CarActionPlanItem = {
    name: `Spending: ${formatCurrency(spendingValue)}`,
    childItems:
      actionPlan.data?.goals_next_year.map<CarActionPlanChildItem>((i) => ({
        name: `${i.pii_base_goal_name}: ${formatCurrency(i.amount_next_year)}`,
        fundedItems: i.goal_asset_res.map(
          (ar) =>
            `${ar.pii_base_asset_name}: ${formatCurrency(
              ar.current_asset_amount_contributed
            )}`
        ),
      })) ?? [],
  };

  const savings: CarActionPlanItem = {
    name: `Savings: ${formatCurrency(savingsValue)}`,
    childItems:
      actionPlan.data?.savings_next_year.map<CarActionPlanChildItem>((i) => ({
        name: `${i.pii_base_asset_name}: ${formatCurrency(
          i.savings_next_year
        )}`,
      })) ?? [],
  };

  const portfolio: CarActionPlanItem = {
    name: `Portfolio Strategy Adjustment:`,
    childItems:
      householdAllocation.allocAssets.level1.map<CarActionPlanChildItem>(
        (i) => ({
          name: `Total ${i.name.toLocaleLowerCase()} to be moved from ${formatPercent(
            i.current_percent
          )} to ${formatPercent(i.proposed_percent)}.`,
        })
      ) ?? [],
  };

  return {
    items: [spending, savings, portfolio],

    spending: spendingValue,
    spendingLastYear: todoValue,

    savings: savingsValue,
    savingsLastYear: todoValue,

    expectedReturn: todoValue,
    expectedReturnLastYear: todoValue,

    isLoading:
      render.isLoading || householdAllocation.isLoading || actionPlan.isLoading,
  };
};

export type UsePublishPlanActionPlan = ReturnType<
  typeof usePublishPlanActionPlan
>;
