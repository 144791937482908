import {
  Box,
  BoxProps,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedTextFieldProps,
  TextField,
  Typography,
  TypographyProps,
} from "@mui/material";
import { InflationEnum } from "api/carApi.generated";
import {
  AssetClassLevel,
  assetClassLevelItems,
  frequencyItems,
  inflationItems,
  manualAccountKinds,
  paymentPlanKinds,
  priorityItems,
  realEstateTypes,
  riskItems,
  suffixItems,
  taxItems,
  yearItems,
} from "const";
import { SelectArrow } from "icons";
import {
  CarAccountInputKind,
  CarPaymentPlanKind,
  CarPriorityKind,
  CarRealEstateType,
  CarSuffix,
  CarTaxesKind,
} from "types";
import { useDelayed } from "../useDelayed";
import { isDefined } from "utils";
import { createPendoClassName } from "app/thirdParty/pendo";
import { CarTooltipBox } from "./TooltipBox";

export interface CarTextFieldProps
  extends Omit<OutlinedTextFieldProps, "variant" | "value" | "onChange"> {
  value?: string | null;
  onChange: (value?: string) => void;
}

export const CarTextField = ({
  value,
  onChange,
  required,
  error,
  ...props
}: CarTextFieldProps) => (
  <TextField
    variant="outlined"
    error={error || (required && !value)}
    {...props}
    value={value ?? ""}
    onChange={(e) => onChange(e.target.value)}
  />
);

export const CarTextFieldDelayed = ({
  ...props
}: CarTextFieldProps & { onCancel?: () => void }) => {
  const delayed = useDelayed({ ...props, skipPostOnEnter: props.multiline });
  return <CarTextField {...props} {...delayed} />;
};

export interface CarTextCalcFieldProps {
  className?: string;
  sx?: BoxProps["sx"];
  value?: string | null;
  label?: string;
  valueProps?: TypographyProps;
}

export const CarTextCalcField = ({
  className,
  sx,
  label,
  value,
  valueProps,
}: CarTextCalcFieldProps) => {
  return (
    <FormControl className={className} sx={sx}>
      {label ? (
        <InputLabel htmlFor="car-text-calc-field">{label}</InputLabel>
      ) : undefined}
      <Box
        id="car-text-calc-field"
        sx={{
          mt: label ? "20px" : undefined,
          width: "100%",
          height: "42.6875px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography
          textAlign="left"
          variant="par01Regular"
          {...valueProps}
          sx={{ ml: 1, color: "black", ...valueProps?.sx }}
        >
          {isDefined(value) ? value : "-"}
        </Typography>
      </Box>
    </FormControl>
  );
};

export interface CarSelectFieldProps extends CarTextFieldProps {}
export const CarSelectField = (props: CarSelectFieldProps) => (
  <CarTextField
    select
    {...props}
    SelectProps={{
      IconComponent: SelectArrow,
      ...props.SelectProps,
    }}
  />
);

export interface CarFrequencyFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value?: number;
  onChange: (value?: number) => void;
}

export const CarFrequencyField = ({
  value,
  onChange,
  ...props
}: CarFrequencyFieldProps) => (
  <CarSelectField
    {...props}
    value={value?.toString()}
    onChange={(value) => onChange(value ? Number.parseInt(value) : undefined)}
  >
    {frequencyItems.map((i) => (
      <MenuItem key={i.value} value={i.value}>
        {i.label}
      </MenuItem>
    ))}
  </CarSelectField>
);

export interface CarInflationFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value?: InflationEnum | null;
  onChange: (value?: InflationEnum) => void;
}

export function render_inflation_input(value: any) {
  if (value === "") {
    return "None";
  }
  const value_int = Number.parseInt(value);
  const label = inflationItems.find((i) => i.value === value_int)?.label;
  return label ?? value;
}

export const CarInflationField = ({
  value,
  onChange,
  ...props
}: CarInflationFieldProps) => (
  <CarSelectField
    {...props}
    value={value?.toString() ?? (null as any)}
    onChange={(value) =>
      onChange(value == null ? (null as any) : Number.parseInt(value))
    }
    SelectProps={{
      displayEmpty: true,
      renderValue: render_inflation_input,
    }}
  >
    {inflationItems.map((i) => (
      <MenuItem key={i.value ?? "null"} value={i.value}>
        {i.label}
      </MenuItem>
    ))}
  </CarSelectField>
);

export interface CarTaxesFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value?: CarTaxesKind;
  onChange: (value?: CarTaxesKind) => void;
}

export const CarTaxesField = (props: CarTaxesFieldProps) => (
  <CarSelectField
    {...props}
    onChange={(value) => props.onChange(value as CarTaxesKind)}
  >
    {taxItems.map((i) => (
      <MenuItem key={i.value} value={i.value}>
        {i.label}
      </MenuItem>
    ))}
  </CarSelectField>
);

export const CarRiskField = (props: CarSelectFieldProps) => (
  <CarSelectField {...props}>
    {riskItems.map((i) => (
      <MenuItem key={i.value} value={i.value}>
        {i.label}
      </MenuItem>
    ))}
  </CarSelectField>
);

export interface CarYearFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  range?: { fromYear: number; toYear: number };
  value?: number;
  onChange: (value?: number) => void;
}

export const CarYearField = ({
  range,
  value,
  onChange,
  ...props
}: CarYearFieldProps) => {
  const years = range
    ? new Array(Math.max(range.toYear - range.fromYear + 1, 0))
        .fill(range.fromYear)
        .map((i, idx) => i + idx)
        .map((i) => ({ label: i.toString(), value: i }))
    : yearItems;

  return (
    <CarSelectField
      {...props}
      value={value as unknown as string}
      onChange={(value) =>
        onChange(value === undefined ? undefined : Number.parseInt(value))
      }
    >
      {years.map((i) => (
        <MenuItem key={i.value} value={i.value}>
          {i.label}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};

export interface CarPriorityFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value?: CarPriorityKind;
  onChange: (value?: CarPriorityKind) => void;
}

export const CarPriorityField = ({
  value,
  onChange,
  ...props
}: CarPriorityFieldProps) => {
  return (
    <CarSelectField
      {...props}
      value={value as unknown as CarPriorityKind}
      onChange={(value: unknown) => onChange(value as CarPriorityKind)}
    >
      {priorityItems.map((i) => (
        <MenuItem key={i.value} value={i.value}>
          {i.label}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};

// export const CarRiskModeField = (props: CarSelectFieldProps) => {
//   return (
//     <CarSelectField {...props}>
//       {riskModeItems.map((i) => (
//         <MenuItem key={i.value} value={i.value}>
//           {i.label}
//         </MenuItem>
//       ))}
//     </CarSelectField>
//   );
// };

export interface CarAssetClassLevelFieldProps
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  hideLevel4?: boolean;
  value: AssetClassLevel;
  onChange: (value: AssetClassLevel) => void;
}

export const CarAssetClassLevelField = ({
  value,
  onChange,
  hideLevel4,
  ...props
}: CarAssetClassLevelFieldProps) => {
  const items = assetClassLevelItems.filter(
    (i) => !hideLevel4 || i.value !== AssetClassLevel.level4,
  );

  return (
    <CarSelectField
      value={value}
      onChange={(value: unknown) => onChange(value as AssetClassLevel)}
      {...props}
    >
      {items.map((i) => (
        <MenuItem key={i.value} value={i.value}>
          {i.label}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};

export interface CarSuffixFieldProps
  extends Omit<CarTextFieldProps, "value" | "onChange"> {
  value?: CarSuffix;
  onChange: (value?: CarSuffix) => void;
}

export const CarSuffixField = ({
  value,
  onChange,
  ...props
}: CarSuffixFieldProps) => {
  return (
    <CarSelectField
      value={value || "-"}
      onChange={(value: unknown) =>
        onChange(value !== "-" ? (value as CarSuffix) : undefined)
      }
      {...props}
    >
      {suffixItems.map((i) => (
        <MenuItem key={i.value} value={i.value}>
          {i.label}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};

export interface CarYesNoFieldProps
  extends Omit<CarTextFieldProps, "value" | "onChange"> {
  value?: boolean;
  onChange: (value: boolean) => void;
  yesLabel?: string;
  noLabel?: string;
}

export const CarYesNoField = ({
  value,
  onChange,
  yesLabel,
  noLabel,
  ...props
}: CarYesNoFieldProps) => {
  return (
    <CarSelectField
      value={value ? "1" : "0"}
      onChange={(value) => onChange(value === "1")}
      {...props}
    >
      <MenuItem value="1">{yesLabel ?? "Yes"}</MenuItem>
      <MenuItem value="0">{noLabel ?? "No"}</MenuItem>
    </CarSelectField>
  );
};

export interface CarRealEstateTypeFieldProps
  extends Omit<CarTextFieldProps, "value" | "onChange"> {
  value?: CarRealEstateType;
  onChange: (value: CarRealEstateType) => void;
}

export const CarRealEstateTypeField = ({
  value,
  onChange,
  ...props
}: CarRealEstateTypeFieldProps) => {
  return (
    <CarSelectField
      value={value}
      onChange={(value: unknown) => onChange(value as CarRealEstateType)}
      {...props}
    >
      {realEstateTypes.map((i) => (
        <MenuItem
          key={i.value}
          value={i.value}
          className={createPendoClassName(i.value)}
        >
          {i.label}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};

export interface CarManualAccountKindFieldProps
  extends Omit<CarTextFieldProps, "value" | "onChange"> {
  value?: CarAccountInputKind;
  onChange: (value: CarAccountInputKind) => void;
}

export const CarManualAccountKindField = ({
  value,
  onChange,
  ...props
}: CarManualAccountKindFieldProps) => {
  return (
    <CarSelectField
      value={value}
      onChange={(value: unknown) => onChange(value as CarAccountInputKind)}
      {...props}
    >
      {manualAccountKinds.map((i) => (
        <MenuItem
          key={i.value}
          value={i.value}
          className={createPendoClassName(i.value)}
        >
          {i.label}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};

export interface CarPaymentPlanKindFieldProps
  extends Omit<CarTextFieldProps, "value" | "onChange"> {
  value?: CarPaymentPlanKind;
  onChange: (value: CarPaymentPlanKind) => void;
}

export const CarPaymentPlanKindField = ({
  value,
  onChange,
  ...props
}: CarPaymentPlanKindFieldProps) => {
  return (
    <CarSelectField
      value={value}
      onChange={(value: unknown) => onChange(value as CarPaymentPlanKind)}
      {...props}
    >
      {paymentPlanKinds.map((i) => (
        <MenuItem key={i.value} value={i.value}>
          {i.label}
        </MenuItem>
      ))}
    </CarSelectField>
  );
};
export interface CarSelectOption<V extends number | string> {
  value: V;
  label: string;
  pendoClass?: string;
  isBold?: boolean;
}

export interface CarSelectOptionGroup<V extends number | string> {
  label: string;
  items: CarSelectOption<V>[];
}

export interface CarSelectOptionFieldProps<V extends number | string>
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value?: V;
  onChange: (value?: V) => void;
  options: CarSelectOption<V>[];
}

export function CarSelectOptionField<V extends number | string>({
  value,
  onChange,
  options,
  ...props
}: CarSelectOptionFieldProps<V>) {
  return (
    <CarSelectField
      {...props}
      SelectProps={{
        renderValue: (v) => options.find((i) => i.value === value)?.label, // do not use v, because it always cast to string here!
      }}
      value={value?.toString()}
      onChange={(value) =>
        onChange(isDefined(value) ? (value as any as V) : undefined)
      }
    >
      {options.map((o, idx) => (
        <MenuItem
          key={idx}
          value={o.value}
          sx={{ fontWeight: o.isBold ? "600" : undefined }}
        >
          {o.label}
          {o.pendoClass && (
            <CarTooltipBox
              sx={{ ml: 0.25, mb: 0.25 }}
              className={o.pendoClass}
            />
          )}
        </MenuItem>
      ))}
    </CarSelectField>
  );
}

export interface CarSelectOptionGroupsFieldProps<V extends number | string>
  extends Omit<CarSelectFieldProps, "value" | "onChange"> {
  value?: V;
  onChange: (value?: V) => void;
  groups: CarSelectOptionGroup<V>[];
}

export function CarSelectOptionGroupsField<V extends number | string>({
  value,
  onChange,
  groups,
  ...props
}: CarSelectOptionGroupsFieldProps<V>) {
  return (
    <CarSelectField
      {...props}
      value={value?.toString()}
      onChange={(value) => onChange(value ? (value as any as V) : undefined)}
    >
      {groups.flatMap((g, groupIdx) => [
        <ListSubheader key={groupIdx}>{g.label}</ListSubheader>,
        g.items.map((o, idx) => (
          <MenuItem key={`${groupIdx}_${idx}`} value={o.value}>
            {o.label}
          </MenuItem>
        )),
      ])}
    </CarSelectField>
  );
}
