import {
  ExcelColumn,
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";
import {
  CarPortfolioAssetBand,
  CarPortfolioAssetTableData,
} from "./usePortfolioGroupVersionData";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { usePortfolioGroupInfo } from "./usePortfolioGroupInfo";
import { CarDisclosureContentCode, useDisclosures } from "app/useDisclosures";
import { useAssetClassesFirmAssetClassImplementationListQuery } from "api/carApi.generated";

export const useExportAssetAllocation = () => {
  const firmAssetClassImplementation =
    useAssetClassesFirmAssetClassImplementationListQuery();

  const { portfolioGroupId } = usePortfolioGroupParams();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });

  const { getContent } = useDisclosures();

  const excelExport = useExcelExport();

  const filePrefix = groupInfo.title;

  const findAcImplementation = (level4Id?: string) =>
    firmAssetClassImplementation.data?.find((i) => i.asset_class === level4Id);

  return (props: { tableData: CarPortfolioAssetTableData }) => {
    const bands = props.tableData.bands;
    const rows = props.tableData.assetRows.level4;

    const columns = bands.find((b) => !b.isCash)?.columns;

    const createTable = (label: string, columnIndex: number): ExcelTable => {
      return {
        columns: [
          {
            format: ExcelDataFormat.general,
            label: "Asset Class",
            charWidth: 50,
          },
          {
            format: ExcelDataFormat.general,
            label: "Name",
            charWidth: 50,
          },
          {
            format: ExcelDataFormat.general,
            label: "Ticker",
            charWidth: 20,
          },
          ...bands.map<ExcelColumn>((band) => ({
            format: ExcelDataFormat.percent,
            label: band.label,
          })),
        ],
        name: label.replace(/[\W]+/g, " ").slice(0, 31),
        rows: rows
          .map((row) => {
            const implItem = findAcImplementation(row.id);

            return [
              row.name,
              implItem?.target_name,
              implItem?.target_ticker,
              ...row.modules.map((mod) =>
                mod.isCash
                  ? mod.values[0].allocation
                  : mod.values[columnIndex].allocation,
              ),
            ];
          })
          .filter((row) => row.slice(3).some((val) => Number(val) > 0)), // 3 is for first value!, Asset Class - 0, Name - 1, Ticker - 2, Value - 3
      };
    };

    const createModuleTable = (band: CarPortfolioAssetBand): ExcelTable => {
      return {
        columns: [
          {
            format: ExcelDataFormat.general,
            label: "Asset Class",
            charWidth: 50,
          },
          {
            format: ExcelDataFormat.general,
            label: "Name",
            charWidth: 50,
          },
          {
            format: ExcelDataFormat.general,
            label: "Ticker",
            charWidth: 20,
          },
          {
            format: ExcelDataFormat.percent,
            label: band.label,
          },
        ],
        name: band.label.replace(/[\W]+/g, " ").slice(0, 31),
        rows: rows
          .map((row) => {
            const implItem = findAcImplementation(row.id);

            return [
              row.name,
              implItem?.target_name,
              implItem?.target_ticker,
              row.modules
                .find((mod) => mod.moduleId === band.moduleId)
                ?.values.find((val) => val.dataId === band.selectedDataId)
                ?.allocation,
            ];
          })
          .filter((row) => Number(row[3]) > 0), // 3 is for value!, Asset Class - 0, Name - 1, Ticker - 2, Value - 3
      };
    };

    const createDisclosuresTable = (): ExcelTable => {
      const result: ExcelTable = {
        columns: [
          {
            format: ExcelDataFormat.general,
            label: "Disclosures",
            charWidth: 100,
          },
        ],
        name: "Disclosures",
        rows: [
          [
            getContent(
              CarDisclosureContentCode.ASSET_ALLOCATION_MODULE_SPREADSHEET_part_100,
            ),
          ],
        ],
        onGetCellStyle: ({ cellStyle }) => ({
          ...cellStyle,
          alignment: {
            vertical: "top",
            wrapText: true,
          },
        }),
      };
      return result;
    };

    if (columns && columns.length) {
      excelExport.exportAllExcel({
        filePrefix,
        tables: [
          ...columns.map((col, idx) => createTable(col.label, idx)),
          ...bands
            .filter((band) => !band.isCash)
            .map((band) => createModuleTable(band)),
          createDisclosuresTable(),
        ],
      });
    }
  };
};
