import { Search } from "@mui/icons-material";
import { Box, InputAdornment, styled, Typography } from "@mui/material";
import { useState } from "react";
import { CarButton, DeleteRowButtonRed } from "components/Buttons";
import { CarTextField } from "components/Inputs";
import { useClients } from "./useClients";
import { formatDate } from "utils";
import {
  RootPage,
  RootPageHeader,
  RootPageHeaderLink,
  RootPageTransparentPaper,
} from "components/RootPage";
import { CarGrid } from "components/Grid";
import { TablePlaceHolder } from "components/PlaceHolder";
import { financialPlanningPages } from "route.const";
import { CarIconAlert } from "icons";

const SearchField = styled(CarTextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    backgroundColor: theme.palette.gray1,
    borderRadius: "5px",
    "& input": {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      ...theme.typography.par01Regular,
      color: theme.palette.black,
    },
    "& fieldset": {
      borderRadius: "5px",
    },
    ".MuiSvgIcon-root": {
      width: 24,
      height: 24,
      color: theme.palette.softBlack,
    },
  },
}));

const SearchButton = styled(CarButton)(({ theme }) => ({
  height: 50,
  width: 150,
  flex: "none",
  ...theme.typography.par03SemiBold,
}));

export const ClientListPage = () => {
  const data = useClients(true);
  const [search, setSearch] = useState<string | undefined>(data.initialSearch);

  return (
    <RootPage sx={{ paddingBottom: 0, backgroundColor: "white" }}>
      <RootPageHeader
        isSmall
        title="Financial Planning"
        links={
          <>
            <RootPageHeaderLink
              label="Client List"
              url={financialPlanningPages.clientListUrl}
            />
            <RootPageHeaderLink
              label="Add Client"
              url={financialPlanningPages.addClientUrl}
            />
          </>
        }
      />
      <RootPageTransparentPaper>
        <Typography variant="h2SSemiBold" sx={{ mt: 3 }}>
          Client List
        </Typography>
        <Box
          sx={{
            alignSelf: "flex-end",
            display: "flex",
            justifyContent: "stretch",
            alignItems: "center",
            width: 420,
            mb: 5,
            gap: 3,
          }}
        >
          <SearchField
            value={search}
            onChange={setSearch}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: 0 }}>
                  <Search sx={{ width: 30, height: 30 }} />
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                data.searchClients({ search });
              }
            }}
          />
          <SearchButton onClick={() => data.searchClients({ search })}>
            Search
          </SearchButton>
        </Box>
        <TablePlaceHolder isLoading={data.isLoading} rows={8}>
          <CarGrid
            headerSx={{
              mb: 1.5,
            }}
            isEvenOdd
            cellVariant="par01Regular"
            rows={data.items}
            columns={[
              {
                fraction: 1.5,
                label: "Name",
                field: "name",
              },
              {
                fraction: 1.5,
                label: "Email",
                field: "email",
                textAlign: "center",
                headerAlign: "center",
              },
              {
                label: "Alert",
                field: "created_at",
                headerAlign: "center",
                onCellRender: (row, rowIdx, colIdx) => (
                  <Box
                    key={colIdx}
                    sx={{ color: "softBlack", display: "flex" }}
                  >
                    <CarIconAlert sx={{ m: "auto", width: 32, height: 32 }} />
                  </Box>
                ),
              },
              {
                label: "Tier",
                field: "tierName",
                headerAlign: "center",
                textAlign: "center",
                onGetValue: (row) => row.tierName,
              },
              {
                label: "Last reviewed",
                field: "last_reviewed",
                headerAlign: "center",
                textAlign: "center",
                onGetValue: (row) =>
                  row.last_reviewed ? formatDate(row.last_reviewed) : "-",
              },
              {
                label: "",
                fraction: "28px",
                onCellRender: (row) => (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteRowButtonRed
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        data.deleteClient(row.id);
                      }}
                    />
                  </Box>
                ),
              },
            ]}
            defaultGridSort={{
              field: "name",
              direction: "asc",
            }}
            onSortItems={(gridSort, a, b) => {
              const getSortValue = (): number => {
                switch (gridSort.field) {
                  case "name":
                    return a.name.localeCompare(b.name);
                  case "email":
                    return (a.email ?? "").localeCompare(b.email ?? "");
                  case "tierName":
                    return a.tierSort - b.tierSort;
                  case "last_reviewed":
                    return (a.last_reviewed ?? "").localeCompare(
                      b.last_reviewed ?? "",
                    );

                  default:
                    return 0;
                }
              };

              return gridSort.direction === "asc"
                ? getSortValue()
                : getSortValue() * -1;
            }}
            onRowClick={(row) => {
              data.gotoClient(row);
            }}
          />
        </TablePlaceHolder>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
