import {
  BarController,
  DoughnutController,
  BarElement,
  CategoryScale,
  Chart,
  ArcElement,
  ChartConfiguration,
  Legend,
  LinearScale,
  Title,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  TimeScale,
  LogarithmicScale,
  Decimation,
  Plugin,
  ChartType,
  RoundedBackgroundPluginOptions,
  PeriodsPluginOptions,
  YearTicksPluginOptions,
  RangeHighlightPluginOptions,
  ScriptableScaleContext,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { fontPdf } from "./components/Pdf";
import zoomPlugin from "chartjs-plugin-zoom";
import { isDefined } from "utils";
import { FontSpec } from "chart.js";
import { theme } from "theme";
import { SharpBarController, SharpBarElement } from "SharpBarElement";

Chart.register(
  zoomPlugin,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  ArcElement,
  BarElement,
  Title,
  Legend,
  BarController,
  DoughnutController,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  TimeScale,
  Decimation,
  SharpBarElement,
  SharpBarController,
);

export const getChartData = (
  width: number,
  height: number,
  config: ChartConfiguration,
) => {
  const canvas = document.createElement("canvas");

  canvas.setAttribute("width", width.toString());
  canvas.setAttribute("height", height.toString());

  const myChart = new Chart(canvas, config);
  const data = myChart.toBase64Image("image/png", 1);
  myChart.destroy();
  return data;
};

const toFontString = (font: Partial<FontSpec>) => {
  if (!font || !isDefined(font.size) || !isDefined(font.family)) {
    return "";
  }

  return (
    (font.style ? font.style + " " : "") +
    (font.weight ? font.weight + " " : "") +
    font.size +
    "px " +
    font.family
  );
};

const roundedBackground: Plugin<ChartType, RoundedBackgroundPluginOptions> = {
  id: "roundedBackground",
  beforeDraw: (chart, args, options) => {
    if (!options) {
      throw new Error("roundedBackground options are not specified");
    }
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    ctx.save();

    // https://github.com/chartjs/Chart.js/issues/7351
    const _alignPixel = (pixel: number) => {
      var devicePixelRatio = chart.currentDevicePixelRatio;
      var halfWidth = 1 / 2;
      return (
        Math.round((pixel - halfWidth) * devicePixelRatio) / devicePixelRatio +
        halfWidth
      );
    };

    // background
    ctx.fillStyle = options.backgroundColor;

    ctx.beginPath();
    (ctx as any).roundRect?.(
      _alignPixel(chartArea.left),
      _alignPixel(chartArea.top),
      chartArea.right - chartArea.left,
      chartArea.bottom - chartArea.top,
      options.borderRadius,
    );
    ctx.fill();

    ctx.restore();
  },
  afterDraw: (chart, args, options) => {
    if (!options) {
      throw new Error("roundedBackground options are not specified");
    }

    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    ctx.save();

    ctx.strokeStyle = options.contextColor;
    ctx.fillStyle = options.contextColor;

    const _alignPixel = (pixel: number) => {
      var devicePixelRatio = chart.currentDevicePixelRatio;
      var halfWidth = 1 / 2;
      return (
        Math.round((pixel - halfWidth) * devicePixelRatio) / devicePixelRatio +
        halfWidth
      );
    };

    // fills space outside of rounded corner with white, to remove any residuals
    const fixCorner = (
      _x: number,
      _y: number,
      xShift: number,
      yShift: number,
    ) => {
      const x = _alignPixel(_x);
      const y = _alignPixel(_y);
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x + xShift, y);
      ctx.arcTo(x, y, x, y + yShift, options.borderRadius);
      ctx.closePath();
      ctx.stroke();
      ctx.fill();
    };

    fixCorner(
      chartArea.left,
      chartArea.top,
      options.borderRadius,
      options.borderRadius,
    );
    fixCorner(
      chartArea.right,
      chartArea.top,
      -options.borderRadius,
      options.borderRadius,
    );
    fixCorner(
      chartArea.left,
      chartArea.bottom,
      options.borderRadius,
      -options.borderRadius,
    );
    fixCorner(
      chartArea.right,
      chartArea.bottom,
      -options.borderRadius,
      -options.borderRadius,
    );

    // border
    ctx.strokeStyle = options.borderColor;
    ctx.beginPath();
    (ctx as any).roundRect?.(
      _alignPixel(chartArea.left),
      _alignPixel(chartArea.top),
      Math.round(chartArea.right - chartArea.left),
      Math.round(chartArea.bottom - chartArea.top),
      options.borderRadius,
    );
    ctx.stroke();

    ctx.restore();
  },
};

const wrapString = (
  ctx: CanvasRenderingContext2D,
  value: string,
  maxWidth: number,
) => {
  const words = value.split(" ");
  const line: string[] = [];
  const result: string[] = [];
  words.forEach((word) => {
    const lineSize = ctx.measureText([...line, word].join(" "));
    if (lineSize.width >= maxWidth) {
      result.push(line.join(" "));
      line.length = 0;
      line.push(word);
    } else {
      line.push(word);
    }
  });

  if (line.length) {
    result.push(line.join(" "));
  }

  return result;
};

const periods: Plugin<ChartType, PeriodsPluginOptions> = {
  id: "periods",
  beforeDraw(chart, args, options) {
    if (chart.scales.x.ticks.length < 2) return;

    const ctx = chart.ctx;
    const chartArea = chart.chartArea;

    ctx.save();

    options.items?.forEach((p) => {
      // fill rectangle
      let start = Math.max(
        chart.scales.x.getPixelForValue(p.start),
        chartArea.left,
      );
      let end = Math.min(
        chart.scales.x.getPixelForValue(p.end),
        chartArea.right,
      );

      if (start >= end) {
        return;
      }

      const backgroundColor = p.backgroundColor ?? options.backgroundColor;
      if (backgroundColor) {
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(start, chartArea.top, end - start, chartArea.height);
      }

      ctx.strokeStyle = options.borderColor;
      ctx.strokeRect(start, chartArea.top, end - start, chartArea.height);
    });

    ctx.restore();
  },
  beforeDatasetsDraw(chart, args, options) {
    if (chart.scales.x.ticks.length < 2) return;

    const ctx = chart.ctx;
    const chartArea = chart.chartArea;

    ctx.save();

    options.items?.forEach((p) => {
      let start = Math.max(
        chart.scales.x.getPixelForValue(p.start),
        chartArea.left,
      );
      let end = Math.min(
        chart.scales.x.getPixelForValue(p.end),
        chartArea.right,
      );

      if (start >= end) {
        return;
      }

      ctx.strokeStyle = options.borderColor;
      ctx.strokeRect(start, chartArea.top, end - start, chartArea.height);

      const pos = start + (end - start) / 2;

      ctx.save();

      // draw label
      ctx.textBaseline = "middle";
      ctx.fillStyle = options.textColor;
      ctx.font = toFontString(options.font);

      if (options.labelPosition === "horizontal") {
        let startY = chartArea.bottom - 28;
        const maxWidth = end - start;
        const lines = wrapString(ctx, p.label, end - start).reverse();

        if (lines.every((line) => ctx.measureText(line).width < maxWidth)) {
          lines.forEach((str) => {
            const textSize = ctx.measureText(str);

            ctx.fillText(str, pos - textSize.width / 2, startY);

            startY -=
              textSize.fontBoundingBoxAscent + textSize.fontBoundingBoxDescent;
          });
        }
      } else {
        const textSize = ctx.measureText(p.label);
        const textHeight =
          textSize.actualBoundingBoxAscent + textSize.actualBoundingBoxDescent;

        if (end - start > textHeight) {
          ctx.translate(pos, textSize.width + 24);
          ctx.rotate(-Math.PI / 2);
          ctx.fillText(p.label, 0, 0);
        }
      }

      ctx.restore();
    });

    ctx.restore();
  },
};

const rangeHighlight: Plugin<ChartType, RangeHighlightPluginOptions> = {
  id: "rangeHighlight",
  beforeDraw(chart, args, options) {
    if (chart.scales.x.ticks.length < 2) return;

    const ctx = chart.ctx;
    const chartArea = chart.chartArea;

    ctx.save();

    options.items?.forEach((p) => {
      let value = 0;
      if (p.value === "tooltip") {
        if (!chart.tooltip?.opacity) {
          return;
        }

        const tooltipX = chart.tooltip?.x;
        if (tooltipX) {
          const rawValue = chart.tooltip.dataPoints[0]?.raw;
          if (rawValue) {
            value = (rawValue as any).x;
          }
        } else {
          return;
        }
      } else {
        value = p.value;
      }

      let valuePixelX = chart.scales.x.getPixelForValue(value);
      let minPixelX = Math.max(
        chart.scales.x.getPixelForValue(value + p.minDelta),
        chartArea.left,
      );
      let maxPixelX = Math.min(
        chart.scales.x.getPixelForValue(value + p.maxDelta),
        chartArea.right,
      );

      if (minPixelX >= maxPixelX) {
        return;
      }

      // fill rectangle
      const backgroundColor = p.backgroundColor ?? options.backgroundColor;
      if (backgroundColor) {
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(
          minPixelX,
          chartArea.top,
          maxPixelX - minPixelX,
          chartArea.height,
        );
      }

      ctx.strokeStyle = options.borderColor;
      ctx.strokeRect(
        minPixelX,
        chartArea.top,
        valuePixelX - minPixelX,
        chartArea.height,
      );

      ctx.strokeStyle = options.borderColor;
      ctx.strokeRect(
        valuePixelX,
        chartArea.top,
        maxPixelX - valuePixelX,
        chartArea.height,
      );

      ctx.save();

      const drawLabel = (left: number, right: number, label: string) => {
        const pos = left + (right - left) / 2;
        // draw label
        ctx.textBaseline = "middle";
        ctx.fillStyle = options.textColor;
        ctx.font = toFontString(options.font);

        if (options.labelPosition === "horizontal") {
          let startY = chartArea.bottom - 28;
          const maxWidth = right - left;
          const lines = wrapString(ctx, label, right - left).reverse();

          if (lines.every((line) => ctx.measureText(line).width < maxWidth)) {
            lines.forEach((str) => {
              const textSize = ctx.measureText(str);

              ctx.fillText(str, pos - textSize.width / 2, startY);

              startY -=
                textSize.fontBoundingBoxAscent +
                textSize.fontBoundingBoxDescent;
            });
          }
        } else {
          const textSize = ctx.measureText(label);
          const textHeight =
            textSize.actualBoundingBoxAscent +
            textSize.actualBoundingBoxDescent;

          if (right - left > textHeight) {
            ctx.translate(pos, textSize.width + 24);
            ctx.rotate(-Math.PI / 2);
            ctx.fillText(label, 0, 0);
          }
        }
      };
      drawLabel(minPixelX, valuePixelX, p.minLabel);
      drawLabel(valuePixelX, maxPixelX, p.maxLabel);

      ctx.restore();
    });

    ctx.restore();
  },
};

const yearTicks: Plugin<ChartType, YearTicksPluginOptions> = {
  id: "yearTicks",
  beforeDatasetsDraw(chart, args, options) {
    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    const y0 = chart.scales.y.getPixelForValue(0) + (options.marginTop ?? 36);

    const positions: Array<number> =
      (chart.scales.x as any)._gridLineItems?.map((i: any) => i.x1) || [];

    const tickLabels = chart.scales.x.ticks.map((t) =>
      new Date(t.value).getFullYear().toString(),
    );

    if (positions.length === tickLabels.length) {
      ctx.save();
      ctx.textBaseline = "middle";
      ctx.fillStyle = options.color;
      ctx.font = toFontString(options.font);

      positions.forEach((pos, idx) => {
        const label = tickLabels[idx];
        const textSize = ctx.measureText(label);

        const textHeight =
          textSize.actualBoundingBoxAscent + textSize.actualBoundingBoxDescent;

        if (
          pos - textHeight / 2 > chartArea.left &&
          pos + textHeight / 2 < chartArea.right
        ) {
          ctx.save();
          ctx.translate(pos, y0);
          ctx.rotate(-Math.PI / 2);
          ctx.fillText(label, 0, 0);
          ctx.restore();
        }
      });

      ctx.restore();
    }
  },
};

export const ChartPlugins = {
  roundedBackground: roundedBackground as unknown as Plugin,
  periods: periods as unknown as Plugin,
  yearTicks: yearTicks as unknown as Plugin,
  rangeHighlight: rangeHighlight as unknown as Plugin,
};

const gridColor = (ctx: ScriptableScaleContext) => {
  if (!ctx.tick) {
    return "transparent";
  }

  const { min, max } = ctx.chart?.scales[ctx.scale.axis] ?? {
    min: 0,
    max: 0,
  };

  return ctx.tick.value <= min || ctx.tick.value >= max
    ? "transparent"
    : theme.palette.gray2;
};

export const ChartUtils = {
  gridColor,
};

Chart.defaults.font.family = fontPdf.table.family;
Chart.defaults.font.size = fontPdf.table.normal.size;
Chart.defaults.plugins.tooltip.enabled = false;
