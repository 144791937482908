import { Box, styled, SvgIcon, SvgIconProps, Typography } from "@mui/material";
import { CarVirtualTable } from "components/VirtualTable";
import { memo } from "react";
import { chartsColor } from "theme";
import { formatNumber, formatPercentFactor } from "utils";
import { CarCheckbox } from "components/Checkbox";
import { UseSimulationDataExplorerOutput } from "./useSimulationDataExplorerOutput";
interface SimulationDataExplorerTableProps {
  className?: string;
  output: UseSimulationDataExplorerOutput;
}

const SortIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="11" viewBox="0 0 8 11">
    <path
      d="M8 6.72222L4 11L-1.86988e-07 6.72222L8 6.72222ZM8 4.27778L-2.93838e-07 4.27778L4 -1.74846e-07L8 4.27778Z"
      fill="currentColor"
    />
  </SvgIcon>
);

const formatValue = (isPercentage: boolean, value?: number | null) =>
  value
    ? isPercentage
      ? formatPercentFactor(value, {
          decimalPlaces: 2,
          forceShowDecimals: true,
        })
      : formatNumber(value, {
          decimalPlaces: 2,
          forceShowDecimals: true,
        })
    : "-";

const Table = memo(
  ({ className, output }: SimulationDataExplorerTableProps) => {
    const { table } = output;
    return (
      <CarVirtualTable
        className={className}
        columnCount={table?.columns.length ?? 0}
        rowWidth={80}
        rowCount={table?.rows.length ?? 0}
        onRenderColumnRowHeader={() => (
          <Typography variant="h6SSemiBold">Paths</Typography>
        )}
        sxColumnHeader={() => ({
          cursor: "pointer",
          userSelect: "none",
        })}
        onRenderColumnHeader={(col) => (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="par02Regular">
              {table?.columns.at(col)?.year}
            </Typography>
            <SortIcon
              sx={{
                ml: 0.3,
                mt: 0,
                fontSize: "0.8em",
                color:
                  output.presentation.sortOrder?.colIndex === col
                    ? "caravelOrangePrimary"
                    : "gray7",
              }}
            />
          </Box>
        )}
        onClickColumnHeader={(col) => output.handleSortColumn(col)}
        onRenderRowHeader={(rowIndex) => {
          const row = table?.rows.at(rowIndex);
          if (!row) {
            return null;
          }
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="par02Regular"
                sx={{
                  ml: 3.5,
                  width: 35,
                  cursor: "pointer",
                  color: chartsColor.blue,
                  textDecoration: "underline",
                }}
                onClick={() => output.handlePathClick(row.path)}
              >
                {row.path.toString()}
              </Typography>
              <CarCheckbox
                label=""
                checked={output.isPathChecked(row.path)}
                onChange={() => output.handleCheckPath(row.path)}
              />
            </Box>
          );
        }}
        onRenderCell={(col, row) =>
          formatValue(output.isPercentage, table?.rows.at(row)?.values.at(col))
        }
      />
    );
  },
);

export const SimulationDataExplorerTable = styled(Table)(() => ({}));
